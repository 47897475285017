const Config = {
  myOrgsKey: 'organizations',
  personKey: 'person',
  currentOrgId: 'currentOrgId',
  personList: 'company.personList',
  users: 'company.users',
  configList: 'company.configList',
  configDiscriminators: 'company.configDiscriminators',
  appointmentList: 'company.appointmentList',
  proposalList: 'company.proposalList',
  // These keys will be cleared upon company switch
  companyCacheKeys: [
    'personList',
    'users',
    'configList',
    'appointmentList',
    'proposalList'
  ],
  HistoryKey: 'Router.History.Location'
}

export default Config
