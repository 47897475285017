import React, { useState, useEffect } from 'react'
import { Box, Button, CardMedia, Grid, IconButton, Stack, TextField, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption'
import NewIcon from '@mui/icons-material/FiberNew'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { ApiBase } from 'library/Api'
import Company from 'screens/Company'
import CompanyConfigEdit, { CompanyConfigBaseEditor } from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import DataGrid from 'components/Reusable/DataGrid'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import Helper from '@supportworks/helper'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { Topics } from 'config/topics'
import UploadOverlay from '../../components/Upload/UploadOverlay'
import '../../css/select-search.css'
import brokenImageSvg from '../../images/broken-image-128.png'

const config = {
  title: 'Assets',
  variant: 'Assets2',
  tag: 'assets2',
  name: 'Assets',
  lockTag: true,
  lockName: true
}

class CompanyConfigAssets extends CompanyConfigBaseEditor {
  subscription = null

  constructor (props) {
    super(props)
    let assetInfo
    if (typeof props.contentJson === 'string') {
      assetInfo = JSON.parse(props.contentJson)
    }

    this.state = {
      assetList: assetInfo ? assetInfo.sort((a, b) => (a.created < b.created ? 1 : -1)) : [],
      filter: '',
      page: 0,
      rowsSelected: [],
      showAssetEditor: false
    }
    this.handleDelete = this.handleDelete.bind(this)
    this.handleUpload = this.handleUpload.bind(this)
  }

  componentDidMount () {
    super.componentDidMount()

    this.state.assetList?.forEach((m) => {
      this.addExistingGuid(m.id)
    })

    const assetList = this.state.assetList || []
    for (let x = 0; x < assetList.length; x++) {
      if (assetList[x].new === true) {
        if (this.props.onSetStatus) {
          this.props.onSetStatus('unSavedChanges', true)
          break
        }
      }
    }

    this.subscription = Topics.subscribe('upload-event', async (response) => {
      /**
       * Push the new asset to state.  The record should already be saved to disk by the uploader.
       * Note that the created date here might be off a second or two since this is for smoke and mirrors display.
       */
      const meta = response.data
      // console.log("Received an upload event, adding to assetList... " + JSON.stringify(meta));
      const assetList = JSON.parse(JSON.stringify(this.state.assetList))
      const baseUrl = await ApiBase.callRegisteredMethod('getApiBaseUrl', {})
      // DEV-2060, flip all png to jpg so the first display is faked correctly.
      const n = meta.file.replace('.png$', '.jpg')
      assetList.unshift({
        id: meta.guid,
        guid: meta.guid,
        type: getTypeFromContentType(meta.type),
        title: n,
        filename: n,
        new: true,
        path: baseUrl + `/media-library/${meta.guid}`,
        previewURL: baseUrl + `/media-library/${meta.guid}/preview`,
        created: Helper.formatDate(new Date(), 'yyyy-mm-dd HH:MM:ss')
      })
      this.setState({ assetList }, () => {
        this.props.onChange(JSON.stringify(assetList))
      })
    })
  }

  componentWillUnmount () {
    super.componentWillUnmount()
    this.subscription.unsubscribe()
  }

  handleUpdate (a, idx) {
    const assetList = JSON.parse(JSON.stringify(this.state.assetList))
    assetList[idx] = a
    this.setState({
      assetList,
      showAssetEditor: false,
      selectedIndex: -1
    })
    this.props.onChange(JSON.stringify(assetList))
  }

  handleDelete (indexes) {
    const count = indexes.length
    const confirmMessage = count === 1 ? 'Are you sure you want to delete this item?' : `Are you sure you want to delete these ${count} items?`
    const r = window.confirm(confirmMessage)
    if (r) {
      const updatedAssetList = this.state.assetList.map((item, index) => {
        if (indexes.includes(index)) {
          return { ...item, deleted: true } // Set deleted to true for selected items
        }
        return item
      })
      this.setState({ assetList: updatedAssetList }, () => {
        this.props.onChange(JSON.stringify(JSON.parse(JSON.stringify(updatedAssetList))))
      })
    }
  }

  handleDefaultThumbnail = (e) => {
    e.target.src = brokenImageSvg
  }

  handleUpload () {
    UploadOverlay.message('arbitrary triggering mechanism')
  }

  finalize () {
    return JSON.stringify(this.state.assetList)
  }

  hasFinalize () {
    return true
  }

  render () {
    if (this.state.isLoading) return <LoadingPleaseWait />

    const columns = [
      {
        flex: 0,
        type: 'jsx',
        field: 'media',
        headerName: 'Media',
        editable: false,
        sortable: true,
        renderCell: (params) => {
          if (params.row) {
            if (params.row.filename.match(/\.vtt/)) {
              return (
                <Box key={`media-${params.row.id}`}>
                  <a target='_blank' href={params.row.raw} download rel='noreferrer'>
                    <ClosedCaptionIcon fontSize='large' style={{ color: 'rgba(0,0,0,.66)' }} />
                  </a>
                </Box>
              )
            } else if (params.row.type.match(/video/i)) {
              return (
                <Box key={`media-${params.row.id}`} sx={{ maxWidth: 72 }}>
                  <a target='_blank' href={params.row.raw} download rel='noreferrer'>
                    <img key={`img-${params.row.id}`} onError={this.handleDefaultThumbnail} src={params.row.preview} style={{ maxHeight: 50 }} alt={params.row.preview} />
                  </a>
                </Box>
              )
            } else if (params.row.filename.match(/\.svg/)) {
              return (
                <Box key={`media-${params.row.id}`}>
                  <a target='_blank' href={params.row.raw} download rel='noreferrer'>
                    <CardMedia component='img' sx={{ width: 48, objectFit: 'contain' }} image={params.row.preview} alt={params.row.preview} />
                  </a>
                </Box>
              )
            } else if (params.row.type.match(/image|pdf/i)) {
              return (
                <Box key={`media-${params.row.id}`}>
                  <a target='_blank' href={params.row.raw} download rel='noreferrer'>
                    <img key={`img-${params.row.id}`} onError={this.handleDefaultThumbnail} src={params.row.preview} style={{ maxHeight: 50 }} alt={params.row.preview} />
                  </a>
                </Box>
              )
            } else if (params.row.filename.match(/\.mp3/)) {
              return (
                <Box key={`media-${params.row.id}`}>
                  <a target='_blank' href={params.row.raw} download rel='noreferrer'>
                    <audio id={`audio-${params.row.id}`} style={{ display: 'none' }}>
                      <source src={params.row.raw} type='audio/mpeg' />
                      Your browser does not support the audio element.
                    </audio>
                    <PlayArrowIcon size='small' />
                  </a>
                </Box>
              )
            } else if (params.row.type.match(/icon/i)) {
              return (
                <Box key={`media-${params.row.id}`}>
                  <a target='_blank' href={params.row.raw} download rel='noreferrer'>
                    <ClosedCaptionIcon fontSize='large' style={{ color: 'rgba(0,0,0,.66)' }} />
                  </a>
                </Box>
              )
            }
          }
        }
      },
      {
        flex: 0,
        type: 'string',
        field: 'type',
        headerName: 'Type',
        editable: false,
        sortable: true,
        renderCell: (params) => {
          if (params.row.filename.match(/\.vtt/)) {
            return 'VTT'
          } else if (params.row.type.match(/video/i)) {
            return 'Video'
          } else if (params.row.filename.match(/\.svg/)) {
            return 'SVG'
          } else if (params.row.type.match(/image/i)) {
            return 'Image'
          } else if (params.row.type.match(/pdf/i)) {
            return 'PDF'
          } else if (params.row.filename.match(/\.mp3/) || params.row.type.match(/audio/i)) {
            return 'Audio'
          } else {
            return 'Other'
          }
        }
      },
      {
        flex: 2,
        type: 'string',
        field: 'title',
        headerName: 'Title',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'library',
        headerName: 'Library',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'created',
        headerName: 'Created Date',
        editable: false,
        sortable: true
      },
      {
        width: 128, // Do not allow truncation of the 3 icons when resizing
        field: 'action',
        headerName: 'Actions',
        headerAlign: 'right',
        align: 'right',
        editable: false,
        sortable: false,
        disableExport: true,
        renderCell: (params) => {
          if (params.row) {
            const asset = this.state.assetList[params.row.id]
            return (
              <Grid container wrap='nowrap' justifyContent='flex-end' style={{ overflow: 'visible' }}>
                <Grid item>
                  {asset.new === true ? <NewIcon size='small' /> : null}
                  <IconButton
                    title='Edit'
                    aria-label='edit'
                    onClick={() => {
                      this.setState({ showAssetEditor: !this.state.showAssetEditor, selectedIndex: params.row.id })
                    }}
                  >
                    <EditIcon fontSize='small' />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    title='Delete'
                    aria-label='delete'
                    disabled={params.row.isLibrary}
                    onClick={() => {
                      this.handleDelete([params.row.id])
                    }}
                  >
                    <DeleteIcon fontSize='small' />
                  </IconButton>
                </Grid>
              </Grid>
            )
          }
        }
      }
    ]

    const rows = []
    for (let x = 0; x < this.state.assetList.length; x++) {
      const al = this.state.assetList[x]
      if (al.deleted) {
        continue
      }
      const row = {
        id: x,
        type: al.type,
        title: al.title,
        library: al.library ? al.library.libraryName : Company.getCurrent().name,
        created: al.created,
        preview: al.previewURL,
        path: al.path,
        raw: al.path,
        filename: al.filename,
        isLibrary: !!al.library,
        isRowSelectable: !al.library
      }
      rows.push(row)
    }

    return (
      <Box>
        <DataGrid
          rows={rows}
          columns={columns}
          onDelete={(indexes) => {
            this.handleDelete(indexes)
          }}
          onCloudUpload={this.handleUpload}
          onCloudUploadText='Upload Assets'
        />
        <Box sx={{ pt: 1 }} style={{ position: 'float', top: '24px', right: '24px' }}>
          <Button variant='contained' color='primary' onClick={() => this.handleUpload()} startIcon={<CloudUploadIcon size='small' />}>
            Upload Assets
          </Button>
        </Box>

        {this.state.showAssetEditor
          ? (
            <DialogAssetEditor
              title={this.state.assetList[this.state.selectedIndex].library ? 'Library Asset' : 'Update Asset'}
              asset={this.state.assetList[this.state.selectedIndex]}
              selectedIndex={this.state.selectedIndex}
              onSave={(asset) => {
                this.handleUpdate(asset, this.state.selectedIndex)
              }}
              onClose={() => {
                this.setState({ showAssetEditor: false, selectedIndex: -1 })
              }}
              onDelete={() => {
                this.handleDelete([this.state.selectedIndex])
              }}
            />
            )
          : null}
      </Box>
    )
  }
}

export const DialogAssetEditor = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [title, setTitle] = useState('') // Can only change title
  const [tags, setTags] = useState([])
  const [isSvg, setIsSvg] = useState(false)
  const [isAudio, setIsAudio] = useState(false)

  useEffect(() => {
    if (isLoading) {
      setTitle(props.asset.title)
      if (props.asset.tags) {
        setTags(props.asset.tags)
      }
      if (props.asset.filename.match(/.svg/)) {
        setIsSvg(true)
      }
      if (props.asset.filename.match(/\.mp3$/i) || props.asset.type.match(/audio/i)) {
        setIsAudio(true)
      }
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, []);

  const handleSave = () => {
    const a = JSON.parse(JSON.stringify(props.asset))
    a.tags = tags
    a.title = title
    props.onSave(a)
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'title') {
      setTitle(value)
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  const asset = props.asset

  const jsx = (
    <Box sx={{ pt: 1 }}>
      <Grid container>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <Typography>
            <strong>{props.asset.type}</strong>
          </Typography>
        </Grid>

        {!props.asset.library && (
          <Grid item xs={12} sx={{ pb: 1 }}>
            <TextField
              fullWidth
              required
              id='title'
              name='title'
              variant='outlined'
              label='Title'
              helperText={title ? '' : 'Please enter the asset title.'}
              value={title || ''}
              onChange={handleChange}
            />
          </Grid>
        )}

        {asset.previewURL && (
          <Grid item xs={12} style={styles.imageContainer}>
            <a href={asset.path} target='_blank' rel='noreferrer'>
              {isAudio
                ? (
                  <Box sx={{ width: '50vw' }}>
                    <audio controls style={{ width: '100%' }}>
                      <source src={asset.path} type='audio/mpeg' />
                      Your browser does not support the audio element.
                    </audio>
                  </Box>
                  )
                : asset.type.match(/video/i)
                  ? (
                    <video
                      style={{ width: 'auto', height: '240px' }}
                      controls
                      height='160'
                      poster={asset.previewURL}
                      onClick={(event) => event.target.play()}
                      onMouseOut={(event) => event.target.pause()}
                      src={`${asset.path}#t=1`}
                    />
                    )
                  : isSvg
                    ? (
                      <SVGFile path={asset.previewURL} alignCenter={false} />
                      )
                    : props.asset.filename.match(/\.vtt/)
                      ? (
                        <VTTFile path={asset.path} />
                        )
                      : asset.type.match(/image/i)
                        ? (
                          <img src={asset.path} alt={asset.path} style={{ height: 240 }} />
                          )
                        : (
                          <img src={asset.previewURL} alt={asset.previewURL} style={{ height: '240px' }} />
                          )}
            </a>
          </Grid>
        )}

        <Grid item xs={12} sx={{ pt: 1, pb: 2 }}>
          <Stack>
            {props.asset.library && (
              <>
                <Typography variant='body'>
                  <strong>Title</strong>
                </Typography>
                <Typography variant='body' sx={{ pb: 1 }}>
                  {props.asset.title}
                </Typography>
              </>
            )}
            <Typography variant='body'>
              <strong>Created</strong>
            </Typography>
            <Typography variant='body' sx={{ pb: 1 }}>
              {props.asset.created}
            </Typography>
            <Typography variant='body'>
              <strong>File Name</strong>
            </Typography>
            <Typography variant='body' sx={{ pb: 1 }}>
              {props.asset.filename}
            </Typography>
            <Typography variant='body'>
              <strong>Id</strong>
            </Typography>
            <Typography variant='body'>{props.asset.id}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )

  return <DialogGeneric title={props.title} content={jsx} fullWidth maxWidth='md' onChange={handleSave} onClose={props.onClose} />
}

const VTTFile = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)

  useEffect(() => {
    // console.log(`props.path: ${props.path}`);
    fetch(props.path).then((r) => {
      r.text().then((d) => {
        setData(d)
        setIsLoading(false)
      })
    })
    // eslint-disable-next-line
  }, [])

  if (isLoading) return <LoadingPleaseWait />

  return (
    <Grid container sx={{ p: 2, border: '1px solid rgba(0,0,0,.08)', overflowY: 'scroll' }}>
      <Grid item xs={12}>
        <pre>{data}</pre>
      </Grid>
    </Grid>
  )
}

const SVGFile = (props) => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(false)
  }, [])

  if (isLoading) return <LoadingPleaseWait />

  return (
    <Grid container sx={{ overflowY: 'scroll' }}>
      <Grid item xs={12}>
        <a href={props.path} target='_blank' rel='noreferrer'>
          <CardMedia component='img' height='160' sx={{ objectFit: 'contain' }} image={props.path} alt={props.path} />
        </a>
      </Grid>
    </Grid>
  )
}

// Storing the conversion instead of the content-type is a bit odd but I'm going with it...
function getTypeFromContentType (contentType) {
  if (contentType.match(/.pdf/i)) {
    return 'PDF'
  } else if (contentType.match(/jpg|jpeg|gif|png|svg/i)) {
    return 'Image'
  } else if (contentType.match(/mov|mp4/i)) {
    return 'Video'
  } else if (contentType.match(/audio/i)) { // audio/mpeg
    return 'Audio'
  } else {
    return 'Other'
  }
}

const styles = {
  imageContainer: {
    display: 'inline-block',
    padding: '8px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(0,0,0,.04)',
    border: '1px dashed rgba(0,0,0,.16)',
    maxWidth: 'fit-content'
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} editorComponent={CompanyConfigAssets} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}
