import React, { useState, useEffect, createRef } from 'react'
import { Box, Button, Divider, FormControl, FormControlLabel, Grid, IconButton, Paper, Stack, Switch, Table, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EditIcon from '@mui/icons-material/Edit'
import UpIcon from '@mui/icons-material/KeyboardArrowUp'
import DownIcon from '@mui/icons-material/KeyboardArrowDown'
import Api from 'library/Api'
import CompanyConfigEdit, { CompanyConfigBaseEditor } from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import { AdditionalNotes } from './Contract/AdditionalNotes'
import { CustomerInitials } from './Contract/CustomerInitials'
import { DialogGeneric } from '../../components/Reusable/DialogGeneric'
import { GlobalWarranty } from './Contract/GlobalWarranty'
import DataGrid from '../../components/Reusable/DataGrid'
import { AppointmentNotes } from './Contract/AppointmentNotes'

const config = {
  title: 'Contract Details',
  variant: 'contract',
  tag: 'contract',
  name: 'Contract Details',
  lockTag: true,
  lockName: false
}

/**
 * Custom editor for Contracts
 */
class ContractEditor extends CompanyConfigBaseEditor {
  constructor (props) {
    super(props)
    let dealerInfo
    if (typeof props.contentJson === 'string') {
      // try catch
      try {
        dealerInfo = JSON.parse(props.contentJson)
      } catch (err) {
        console.log(err.message)
      }
    } else if (typeof props.contentJson === 'object') {
      // no need to parse
      dealerInfo = props.contentJson
    } else if (props.contentJson !== undefined) {
      // not string, object, or undefined. Necessary case?
      try {
        dealerInfo = JSON.parse(props.contentJson)
      } catch (err) {
        console.log(err.message)
      }
    } else {
      // set new dealer
      dealerInfo = {
        customer_will: [],
        contractor_will: [],
        additional_notes: [],
        appointment_notes: [],
        warranty: [],
        cancel: {
          intro: '',
          howto: '',
          close: '',
          sig: ''
        },
        signoffs: {
          section: '',
          company: {
            display: false,
            title: '',
            body: ''
          },
          customer: {
            display: false,
            title: '',
            body: ''
          }
        }
      }
    }

    // DEV-5707 JUNK CLEANER
    // Loop through dealerInfo customer_will, contractor_will, additional_notes.  If the array entry is not an object with an id, delete it.
    const keysToRemove = ['customer_will', 'contractor_will', 'additional_notes']
    for (const key of keysToRemove) {
      if (Array.isArray(dealerInfo[key])) {
        dealerInfo[key] = dealerInfo[key].filter(item => typeof item === 'object' && 'id' in item)
      }
    }

    // console.log(dealerInfo)
    this.state = {
      customer_will: dealerInfo.customer_will,
      contractor_will: dealerInfo.contractor_will,
      additional_notes: !(dealerInfo.additional_notes === undefined) ? dealerInfo.additional_notes : [],
      appointment_notes: !(dealerInfo.appointment_notes === undefined) ? dealerInfo.appointment_notes : [],
      warranty: !(dealerInfo.warranty === undefined) ? dealerInfo.warranty : [],
      cancel: dealerInfo.cancel,
      signoffs: dealerInfo.signoffs,
      customerPortalCopy: dealerInfo.customerPortalCopy ? dealerInfo.customerPortalCopy : {},
      errors: {
        customer_will: [],
        contractor_will: [],
        cancel: {
          intro: '',
          howto: '',
          close: '',
          sig: ''
        },
        signoffs: {
          section: '',
          company: {
            title: '',
            body: ''
          },
          customer: {
            title: '',
            body: ''
          }
        }
      },
      openCustomerWill: false,
      openContractorWill: false,
      openCancel: false,
      openSignOffs: false,
      openEditDialogCustomer: false,
      openEditDialogContractor: false,
      editItem: {},
      editIndex: 0,
      counter: 0,
      anchorEl: null,
      hoverBody: '',
      warrantyConfig: null
    }
    // console.log(this.state);
    if (typeof this.state.cancel === 'undefined') {
      this.state.cancel = { intro: '', howto: '', close: '', sig: '' }
    }
    if (typeof this.state.signoffs === 'undefined') {
      this.state.signoffs = { section: '', company: { title: '', body: '' }, customer: { title: '', body: '' } }
    }
    this.customerWillRef = []
    this.contractorWillRef = []
    this.cancelIntroRef = createRef()
    this.cancelHowtoRef = createRef()
    this.cancelCloseRef = createRef()
    this.cancelSigRef = createRef()
    this.signoffsSectionRef = createRef()
    this.signoffsCompanyTitle = createRef()
    this.signoffsCompanyBody = createRef()
    this.signoffsCustomerTitle = createRef()
    this.signoffsCustomerBody = createRef()

    this.handleChange = this.handleChange.bind(this)
    this.handleChangeCancel = this.handleChangeCancel.bind(this)
    this.handleChangeSignoffs = this.handleChangeSignoffs.bind(this)
    this.handleChangeSignoffsDisclaimer = this.handleChangeSignoffsDisclaimer.bind(this)
    this.handleChangeAddNotes = this.handleChangeAddNotes.bind(this)
    this.handleChangeAppNotes = this.handleChangeAppNotes.bind(this)
    this.handleChangeWarranty = this.handleChangeWarranty.bind(this)
    this.searchErrors = this.searchErrors.bind(this)
  }

  componentDidMount () {
    super.componentDidMount()

    if (!('display' in this.state.cancel)) {
      const newCancel = JSON.parse(JSON.stringify(this.state.cancel))
      newCancel.display = true
      this.setState({ cancel: newCancel })
    }

    Api.callRegisteredMethod('getCurrentConfig', { tag: 'all', startsWith: false }).then((currentConfig) => {
      if (currentConfig) {
        if ('productFeatures' in currentConfig) {
          if ('warranties' in currentConfig.productFeatures) {
            this.setState({ warrantyConfig: currentConfig.productFeatures.warranties })
          }
        }
      }
    })
  }

  // assumes id matches index location and name matches parent of array
  handleChange = (event, idx) => {
    if (event.target.name === 'customer_will_body') {
      const newCustomerWill = this.state.customer_will
      if (typeof newCustomerWill[idx] === 'string') {
        newCustomerWill[idx] = {}
        newCustomerWill[idx].id = this.guid()
      }
      newCustomerWill[idx].body = event.target.value
      this.setState({ customer_will: newCustomerWill })
    } else if (event.target.name === 'customer_will_display') {
      const newCustomerWill = this.state.customer_will
      if (typeof newCustomerWill[idx] === 'string') {
        const body = newCustomerWill[idx]
        newCustomerWill[idx] = {}
        newCustomerWill[idx].id = this.guid()
        newCustomerWill[idx].body = body
        newCustomerWill[idx].default = true
        this.setState({ customer_will: newCustomerWill })
      } else {
        newCustomerWill[idx].default = !newCustomerWill[idx].default
        this.setState({ customer_will: newCustomerWill })
      }
    } else if (event.target.name === 'contractor_will_body') {
      const newContractorWill = this.state.contractor_will
      if (typeof newContractorWill[idx] === 'string') {
        newContractorWill[idx] = {}
        newContractorWill[idx].id = this.guid()
      }
      newContractorWill[idx].body = event.target.value
      this.setState({ contractor_will: newContractorWill })
    } else if (event.target.name === 'contractor_will_display') {
      const newContractorWill = this.state.contractor_will
      if (typeof newContractorWill[idx] === 'string') {
        const body = newContractorWill[idx]
        newContractorWill[idx] = {}
        newContractorWill[idx].id = this.guid()
        newContractorWill[idx].body = body
        newContractorWill[idx].default = true
        this.setState({ contractor_will: newContractorWill })
      } else {
        newContractorWill[idx].default = !newContractorWill[idx].default
        this.setState({ contractor_will: newContractorWill })
      }
    } else {
      const clonedArr = Array.from(this.state[event.target.name])
      clonedArr[idx] = event.target.value

      this.setState({
        [event.target.name]: clonedArr
      })
    }
  }

  handleChangeCancel (event) {
    const cancel = this.state.cancel
    cancel[event.target.name] = event.target.value
    this.setState({ cancel })
  }

  handleChangeSignoffs (event) {
    const signoffs = this.state.signoffs

    const nameArr = event.target.id.split('-')

    if (event.target.name === 'section') {
      signoffs[event.target.name] = event.target.value
    } else {
      signoffs[nameArr[1]][nameArr[2]] = event.target.value
    }

    this.setState({ signoffs })
  }

  handleChangeSignoffsDisclaimer (event) {
    const newSignoffs = JSON.parse(JSON.stringify(this.state.signoffs))

    if ('disclaimer' in newSignoffs) {
      newSignoffs.disclaimer = event.target.value
    } else {
      newSignoffs.disclaimer = event.target.value
    }

    this.setState({ signoffs: newSignoffs })
  }

  handleDelete = (ind, stateName) => {
    const clonedArr = Array.from(this.state[stateName])
    clonedArr.splice(ind, 1)

    const clonedErrorsArr = this.state.errors
    clonedErrorsArr[stateName].splice(ind, 1)

    this.setState({
      [stateName]: clonedArr
    })
  }

  handleAdd = (stateName) => {
    // console.log(stateName)
    const clonedArr = Array.from(this.state[stateName])
    if (stateName === 'warranty') {
      clonedArr.push({ id: this.guid(), heading: '', body: '' })
    } else if (stateName === 'customer_will') {
      clonedArr.push({ id: this.guid(), body: '', default: true })
    } else if (stateName === 'contractor_will') {
      clonedArr.push({ id: this.guid(), body: '', default: true })
    } else {
      clonedArr.push('')
    }
    this.setState({
      [stateName]: clonedArr
    })
  }

  handleSetOpenCustomerWill = () => {
    this.setState({ openCustomerWill: !this.state.openCustomerWill })
  }

  handleSetOpenContractorWill = () => {
    this.setState({ openContractorWill: !this.state.openContractorWill })
  }

  handleSetOpenCancel = () => {
    this.setState({ openCancel: !this.state.openCancel })
  }

  handleSetOpenSignOffs = () => {
    this.setState({ openSignOffs: !this.state.openSignOffs })
  }

  handleChangeCompanyRequired = () => {
    const newSignoffs = JSON.parse(JSON.stringify(this.state.signoffs))
    if (!('display' in newSignoffs.company)) {
      newSignoffs.company.display = false
    }
    newSignoffs.company.display = !newSignoffs.company.display
    this.setState({ signoffs: newSignoffs })
  }

  handleChangeCustomerRequired = () => {
    const newSignoffs = JSON.parse(JSON.stringify(this.state.signoffs))
    if (!('display' in newSignoffs.customer)) {
      newSignoffs.customer.display = false
    }
    newSignoffs.customer.display = !newSignoffs.customer.display
    this.setState({ signoffs: newSignoffs })
  }

  handleChangeCancelRequired = () => {
    const newCancel = JSON.parse(JSON.stringify(this.state.cancel))
    newCancel.display = !this.state.cancel.display
    this.setState({ cancel: newCancel })
  }

  handleEditCustomerWill = (item, idx) => {
    this.setState({ openEditDialogCustomer: !this.state.openEditDialogCustomer, editItem: item, editIndex: idx })
  }

  handleEditContractorWill = (item, idx) => {
    this.setState({ openEditDialogContractor: !this.state.openEditDialogContractor, editItem: item, editIndex: idx })
  }

  handleChangeCustomerPortalCopy = (customerPortalCopy) => {
    this.setState({ customerPortalCopy })
  }

  handleMove = (idx, direction, stateName) => {
    const p = JSON.parse(JSON.stringify(this.state[stateName])) // p = copy of state
    if (direction === 'up') {
      if (p[idx - 1]) {
        const toSwitch = JSON.parse(JSON.stringify(p[idx]))
        const switchWith = JSON.parse(JSON.stringify(p[idx - 1]))
        p[idx - 1] = toSwitch
        p[idx] = switchWith
        this.setState({ [stateName]: p })
      }
    } else if (direction === 'down') {
      if (p[idx + 1]) {
        const switchWith = JSON.parse(JSON.stringify(p[idx + 1]))
        p[idx + 1] = JSON.parse(JSON.stringify(p[idx]))
        p[idx] = switchWith
        this.setState({ [stateName]: p })
      }
    }
  }

  handleCloseEditDialogCustomer = () => {
    this.setState({ openEditDialogCustomer: !this.state.openEditDialogCustomer })
  }

  handleSaveEditDialogCustomer = () => {
    this.setState({ openEditDialogCustomer: !this.state.openEditDialogCustomer })
  }

  handleCloseEditDialogContractor = () => {
    this.setState({ openEditDialogContractor: !this.state.openEditDialogContractor })
  }

  handleSaveEditDialogContractor = () => {
    this.setState({ openEditDialogContractor: !this.state.openEditDialogContractor })
  }

  toggle = (targetName) => {
    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: true
        }
      })
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: !this.state[targetName].tooltipOpen
        }
      })
    }
  }

  onChangeInitials = (initials) => {
    const newSignoffs = JSON.parse(JSON.stringify(this.state.signoffs))
    newSignoffs.initials = initials
    this.setState({ signoffs: newSignoffs })
  }

  handleChangeAddNotes = (addNotes) => {
    this.setState({ additional_notes: addNotes })
  }

  handleChangeAppNotes = (appNotes) => {
    this.setState({ appointment_notes: appNotes })
  }

  handleChangeWarranty = (warranty) => {
    this.setState({ warranty })
  }

  searchErrors (errors, query) {
    for (const key in errors) {
      const value = errors[key]

      if (typeof value === 'object') {
        this.searchErrors(value, query)
      }

      if (value === query) {
        this.setState({ counter: this.state.counter++ })
      }
    }
  }

  getRows = (type) => {
    if (type === 'customer' && this.state.customer_will) {
      const rowsCustomer = []
      this.state?.customer_will.forEach((entry, idx) => {
        rowsCustomer.push({
          id: entry.id,
          body: entry.body,
          index: idx,
          default: entry.default
        })
      })
      return rowsCustomer
    }

    if (type === 'contractor' && this.state.contractor_will) {
      const rowsContractor = []
      this.state?.contractor_will?.forEach((entry, idx) => {
        rowsContractor.push({
          id: entry.id,
          body: entry.body,
          index: idx,
          default: entry.default
        })
      })
      return rowsContractor
    }
  }

  finalize () {
    const newErrors = this.state.errors

    if (this.state.customer_will.length > 0) {
      // eslint-disable-next-line array-callback-return
      this.state.customer_will.map((item, idx) => {
        if (item === '') {
          newErrors.customer_will[idx] = 'Required'
        } else {
          newErrors.customer_will[idx] = ''
        }
      })
    }

    if (this.state.contractor_will.length > 0) {
      // eslint-disable-next-line array-callback-return
      this.state.contractor_will.map((item, idx) => {
        if (item === '') {
          newErrors.contractor_will[idx] = 'Required'
        } else {
          newErrors.contractor_will[idx] = ''
        }
      })
    }

    if (this.state.cancel.display && this.state.cancel.intro === '') {
      newErrors.cancel.intro = 'Required'
    } else {
      newErrors.cancel.intro = ''
    }

    if (this.state.cancel.display && this.state.cancel.howto === '') {
      newErrors.cancel.howto = 'Required'
    } else {
      newErrors.cancel.howto = ''
    }

    if (this.state.cancel.display && this.state.cancel.close === '') {
      newErrors.cancel.close = 'Required'
    } else {
      newErrors.cancel.close = ''
    }

    if (this.state.cancel.display && this.state.cancel.sig === '') {
      newErrors.cancel.sig = 'Required'
    } else {
      newErrors.cancel.sig = ''
    }

    if (this.state.signoffs.section === '') {
      newErrors.signoffs.section = 'Required'
    } else {
      newErrors.signoffs.section = ''
    }

    if (this.state.signoffs.company.display && this.state.signoffs.company.title === '') {
      newErrors.signoffs.company.title = 'Required'
    } else {
      newErrors.signoffs.company.title = ''
    }

    if (this.state.signoffs.company.body === '') {
      newErrors.signoffs.company.body = 'Required'
    } else {
      newErrors.signoffs.company.body = ''
    }

    if (this.state.signoffs.company.display && this.state.signoffs.customer.title === '') {
      newErrors.signoffs.customer.title = 'Required'
    } else {
      newErrors.signoffs.customer.title = ''
    }

    if (this.state.signoffs.customer.body === '') {
      newErrors.signoffs.customer.body = 'Required'
    } else {
      newErrors.signoffs.customer.body = ''
    }

    this.searchErrors(newErrors, 'Required')

    if (this.state.counter > 0) {
      this.setState({ counter: 0 })

      const errors = this.state.errors

      const indexCustomerWill = errors.customer_will.findIndex((x) => x === 'Required')
      const indexContractorWill = errors.contractor_will.findIndex((x) => x === 'Required')

      if (indexCustomerWill !== -1) {
        if (!this.state.openCustomerWill) {
          this.setState({ openCustomerWill: true })
        } else {
          this.customerWillRef[indexCustomerWill].scrollIntoView()
        }
      } else if (indexContractorWill !== -1) {
        if (!this.state.openContractorWill) {
          this.setState({ openContractorWill: true })
        } else {
          this.contractorWillRef[indexContractorWill].scrollIntoView()
        }
      } else if (errors.cancel.intro === 'Required') {
        if (!this.state.openCancel) {
          this.setState({ openCancel: true })
        } else {
          this.cancelIntroRef.current.scrollIntoView()
        }
      } else if (errors.cancel.howto === 'Required') {
        if (!this.state.openCancel) {
          this.setState({ openCancel: true })
        } else {
          this.cancelHowtoRef.current.scrollIntoView()
        }
      } else if (errors.cancel.close === 'Required') {
        if (!this.state.openCancel) {
          this.setState({ openCancel: true })
        } else {
          this.cancelCloseRef.current.scrollIntoView()
        }
      } else if (errors.cancel.sig === 'Required') {
        if (!this.state.openCancel) {
          this.setState({ openCancel: true })
        } else {
          this.cancelSigRef.current.scrollIntoView()
        }
      } else if (errors.signoffs.section === 'Required') {
        if (!this.state.openSignOffs) {
          this.setState({ openSignOffs: true })
        } else {
          this.signoffsSectionRef.current.scrollIntoView()
        }
      } else if (errors.signoffs.company.title === 'Required') {
        if (!this.state.openSignOffs) {
          this.setState({ openSignOffs: true })
        } else {
          this.signoffsCompanyTitle.current.scrollIntoView()
        }
      } else if (errors.signoffs.company.body === 'Required') {
        if (!this.state.openSignOffs) {
          this.setState({ openSignOffs: true })
        } else {
          this.signoffsCompanyBody.current.scrollIntoView()
        }
      } else if (errors.signoffs.customer.title === 'Required') {
        if (!this.state.openSignOffs) {
          this.setState({ openSignOffs: true })
        } else {
          this.signoffsCustomerTitle.current.scrollIntoView()
        }
      } else if (errors.signoffs.customer.body === 'Required') {
        if (!this.state.openSignOffs) {
          this.setState({ openSignOffs: true })
        } else {
          this.signoffsCustomerBody.current.scrollIntoView()
        }
      }

      return null
    } else {
      const newState = JSON.parse(JSON.stringify(this.state))

      delete newState.counter
      delete newState.errors
      delete newState.openCustomerWill
      delete newState.openContractorWill
      delete newState.openCancel
      delete newState.openSignOffs

      // console.log(newState);
      return JSON.stringify(newState)
    }
  }

  hasFinalize () {
    // console.log("hasFinalize");
    return true
  }

  render () {
    const customerColumns = [
      {
        flex: 1,
        type: 'string',
        field: 'body',
        headerName: 'Description',
        editable: false,
        sortable: false
      },
      {
        flex: 1,
        type: 'string',
        field: 'default',
        headerName: 'Add By Default',
        editable: false,
        sortable: false,
        renderCell: (params) => {
          if (params.row) {
            return (
              <Box>
                <FormControl sx={{ mt: 1 }}>
                  <FormControlLabel
                    sx={{ ml: 0 }}
                    control={
                      <Switch
                        name='customer_will_display'
                        color='primary'
                        sx={{ m: 0 }}
                        // checked={true}
                        onChange={(event) => this.handleChange(event, params.row.index)}
                        checked={params.row.default}
                      />
                    }
                  />
                </FormControl>
              </Box>
            )
          }
        }
      },
      {
        width: 168,
        field: 'action',
        headerName: 'Actions',
        headerAlign: 'right',
        align: 'right',
        editable: false,
        sortable: false,
        renderCell: (params) => {
          // console.log(params);
          if (params.row) {
            return (
              <Grid container wrap='nowrap' justifyContent='flex-end'>
                <Grid item>
                  <IconButton title='Edit' aria-label='edit' onClick={() => this.handleEditCustomerWill(params.row.body, params.row.index)}>
                    <EditIcon variant='icon' fontSize='small' />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton title='Up' aria-label='up' onClick={() => this.handleMove(params.row.index, 'up', 'customer_will')} disabled={params.row.index < 1}>
                    <UpIcon variant='icon' fontSize='small' />
                  </IconButton>
                  <IconButton title='Down' aria-label='down' onClick={() => this.handleMove(params.row.index, 'down', 'customer_will')} disabled={params.row.index === this.state.contractor_will.length - 1}>
                    <DownIcon variant='icon' fontSize='small' />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton title='Delete' aria-label='delete' onClick={() => this.handleDelete(params.row.index, 'customer_will')}>
                    <DeleteIcon variant='icon' fontSize='small' />
                  </IconButton>
                </Grid>
              </Grid>
            )
          }
        }
      }
    ]

    const contractorColumns = [
      {
        flex: 1,
        type: 'string',
        field: 'body',
        headerName: 'Description',
        editable: false,
        sortable: false
      },
      {
        flex: 1,
        type: 'string',
        field: 'default',
        headerName: 'Add By Default',
        editable: false,
        sortable: false,
        renderCell: (params) => {
          if (params.row) {
            return (
              <Box>
                <FormControl sx={{ mt: 1 }}>
                  <FormControlLabel
                    sx={{ ml: 0 }}
                    control={
                      <Switch
                        name='contractor_will_display'
                        color='primary'
                        sx={{ m: 0 }}
                        // checked={true}
                        onChange={(event) => this.handleChange(event, params.row.index)}
                        checked={params.row.default}
                      />
                    }
                  />
                </FormControl>
              </Box>
            )
          }
        }
      },
      {
        width: 168,
        field: 'action',
        headerName: 'Actions',
        headerAlign: 'right',
        align: 'right',
        editable: false,
        sortable: false,
        renderCell: (params) => {
          // console.log(params);
          if (params.row) {
            return (
              <Grid container wrap='nowrap' justifyContent='flex-end'>
                <Grid item>
                  <IconButton title='Edit' aria-label='edit' onClick={() => this.handleEditContractorWill(params.row.body, params.row.index)}>
                    <EditIcon variant='icon' fontSize='small' />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton title='Up' aria-label='up' onClick={() => this.handleMove(params.row.index, 'up', 'contractor_will')} disabled={params.row.index < 1}>
                    <UpIcon variant='icon' fontSize='small' />
                  </IconButton>
                  <IconButton title='Down' aria-label='down' onClick={() => this.handleMove(params.row.index, 'down', 'contractor_will')} disabled={params.row.index === this.state.contractor_will.length - 1}>
                    <DownIcon variant='icon' fontSize='small' />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton title='Delete' aria-label='delete' onClick={() => this.handleDelete(params.row.index, 'contractor_will')}>
                    <DeleteIcon variant='icon' fontSize='small' />
                  </IconButton>
                </Grid>
              </Grid>
            )
          }
        }
      }
    ]

    const jsxCustomer = (
      <>
        <TextField
          id='customer_will_edit'
          name='customer_will_body'
          label='Edit Description'
          multiline
          rows={8}
          variant='outlined'
          value={this.state.editIndex in this.state.customer_will ? this.state.customer_will[this.state.editIndex].body : ''}
          onChange={(event) => this.handleChange(event, this.state.editIndex)}
          inputProps={{
            style: {
              border: 'none',
              padding: '0px',
              resize: 'vertical'
            }
          }}
          sx={{ mt: 3 }}
          fullWidth
        />
      </>
    )

    const jsxContractor = (
      <>
        <TextField
          id='contractor_will_edit'
          name='contractor_will_body'
          label='Edit Description'
          multiline
          rows={8}
          variant='outlined'
          value={this.state.editIndex in this.state.contractor_will ? this.state.contractor_will[this.state.editIndex].body : ''}
          onChange={(event) => this.handleChange(event, this.state.editIndex)}
          inputProps={{
            style: {
              border: 'none',
              padding: '0px',
              resize: 'vertical'
            }
          }}
          sx={{ mt: 3 }}
          fullWidth
        />
      </>
    )

    return (
      <>
        <div>
          {
            this.state.appointment_notes &&
              <AppointmentNotes appNotes={this.state.appointment_notes} guid={this.guid} onChange={(appNotes) => this.handleChangeAppNotes(appNotes)} />
          }

          <TableContainer component={Paper} sx={{ mb: 1, mt: 1 }}>
            <Table aria-label='customized table' sx={{ borderBottom: '2px solid #2a3042' }}>
              <TableHead>
                <TableRow onClick={this.handleSetOpenCustomerWill}>
                  <TableCell>Customer Will ({this.state.customer_will.length})</TableCell>
                  <TableCell align='right'>{this.state.openCustomerWill ? <ExpandLessIcon /> : <ExpandMoreIcon />}</TableCell>
                </TableRow>
              </TableHead>
            </Table>

            {this.state.openCustomerWill
              ? <DataGrid
                  sx={{ border: 0, '& .MuiDataGrid-columnHeaders': { borderRadius: 0 } }}
                  rows={this.getRows('customer')}
                  columns={customerColumns}
                  showToolbar={false}
                  autosizeOptions={{
                    columns: ['action'],
                    includeOutliers: true,
                    includeHeaders: false
                  }}
                />
              : null}

            {this.state.openEditDialogCustomer && <DialogGeneric title='Edit Customer Will' onClose={() => this.handleCloseEditDialogCustomer()} onChange={() => this.handleSaveEditDialogCustomer()} content={jsxCustomer} fullWidth />}

            {this.state.openCustomerWill && (
              <Box sx={{ p: 1 }}>
                <Button id='asset-action' style={{ textTransform: 'uppercase' }} className='mui-btn' color='primary' variant='contained' onClick={() => this.handleAdd('customer_will')}>
                  Add Customer Will
                </Button>
              </Box>
            )}
          </TableContainer>

          <TableContainer component={Paper} sx={{ mb: 1 }}>
            <Table aria-label='customized table' sx={{ borderBottom: '2px solid #2a3042' }}>
              <TableHead>
                <TableRow onClick={this.handleSetOpenContractorWill}>
                  <TableCell>Contractor Will ({this.state.contractor_will.length})</TableCell>
                  <TableCell align='right'>{this.state.openContractorWill ? <ExpandLessIcon /> : <ExpandMoreIcon />}</TableCell>
                </TableRow>
              </TableHead>
            </Table>

            {this.state.openContractorWill &&
              <DataGrid
                sx={{ border: 0, '& .MuiDataGrid-columnHeaders': { borderRadius: 0 } }}
                rows={this.getRows('contractor')}
                columns={contractorColumns}
                showToolbar={false}
                autosizeOptions={{
                  columns: ['action'],
                  includeOutliers: true,
                  includeHeaders: false
                }}
              />}
            {this.state.openEditDialogContractor && (
              <DialogGeneric title='Edit Contractor Will' onClose={() => this.handleCloseEditDialogContractor()} onChange={() => this.handleSaveEditDialogContractor()} content={jsxContractor} fullWidth />
            )}

            {this.state.openContractorWill && (
              <Box sx={{ p: 1 }}>
                <Button id='asset-action' style={{ textTransform: 'uppercase' }} className='mui-btn' color='primary' variant='contained' onClick={() => this.handleAdd('contractor_will')}>
                  Add Contractor Will
                </Button>
              </Box>
            )}
          </TableContainer>

          {
            // this.state.additional_notes &&
            <AdditionalNotes addNotes={this.state.additional_notes} guid={this.guid} onChange={(addNotes) => this.handleChangeAddNotes(addNotes)} />
          }

          {this.state.warrantyConfig && <GlobalWarranty guid={this.guid} warranty={this.state.warranty} warrantyConfig={this.state.warrantyConfig} onChange={(warranty) => this.handleChangeWarranty(warranty)} />}

          <TableContainer component={Paper} sx={{ mb: 1 }}>
            <Table aria-label='customized table' sx={{ borderBottom: '2px solid #2a3042' }}>
              <TableHead>
                <TableRow onClick={this.handleSetOpenCancel}>
                  <TableCell>Right to Cancel Contract Language</TableCell>
                  <TableCell align='right'>{this.state.openCancel ? <ExpandLessIcon /> : <ExpandMoreIcon />}</TableCell>
                </TableRow>
              </TableHead>
            </Table>
            {this.state.openCancel && (
              <Box sx={{ p: 2, overflowX: 'hidden' }}>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      sx={{ m: 0, marginRight: '24px' }}
                      control={
                        <Switch
                          sx={{ display: 'flex', justifyContent: 'flex-end' }}
                          checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                          onChange={this.handleChangeCancelRequired}
                          checked={'display' in this.state.cancel ? this.state.cancel.display : true}
                        />
                      }
                      label={
                        <Typography variant='body2' sx={{ flex: 1 }}>
                          Include Right to Cancel
                        </Typography>
                      }
                      labelPlacement='start'
                    />
                  </Grid>
                  {this.state.cancel.display && (
                    <>
                      <Divider style={{ width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                      <Grid item xs={12}>
                        <Typography variant='h6' style={{ fontWeight: 500, marginBottom: '24px' }}>
                          Introduction
                        </Typography>
                        <TextField
                          ref={this.cancelIntroRef}
                          error={!!this.state.errors.cancel.intro}
                          id='cancel-intro'
                          name='intro'
                          label='Contract Language'
                          multiline
                          rows={5}
                          variant='outlined'
                          value={this.state.cancel.intro ? this.state.cancel.intro : ''}
                          onChange={this.handleChangeCancel}
                          helperText={this.state.errors.cancel.intro && this.state.errors.cancel.intro}
                          inputProps={{
                            style: {
                              border: 'none',
                              padding: '0px',
                              resize: 'vertical'
                            }
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Divider style={{ width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                      <Grid item xs={12}>
                        <Typography variant='h6' style={{ fontWeight: 500, marginBottom: '24px' }}>
                          How to Cancel
                        </Typography>
                        <TextField
                          ref={this.cancelHowtoRef}
                          error={!!this.state.errors.cancel.howto}
                          id='cancel-howto'
                          name='howto'
                          label='Contract Language'
                          multiline
                          rows={5}
                          variant='outlined'
                          value={this.state.cancel.howto ? this.state.cancel.howto : ''}
                          onChange={this.handleChangeCancel}
                          helperText={this.state.errors.cancel.howto && this.state.errors.cancel.howto}
                          inputProps={{
                            style: {
                              border: 'none',
                              padding: '0px',
                              resize: 'vertical'
                            }
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Divider style={{ width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                      <Grid item xs={12}>
                        <Typography variant='h6' style={{ fontWeight: 500, marginBottom: '24px' }}>
                          Closing
                        </Typography>
                        <TextField
                          ref={this.cancelCloseRef}
                          error={!!this.state.errors.cancel.close}
                          id='cancel-close'
                          name='close'
                          label='Contract Language'
                          multiline
                          rows={5}
                          variant='outlined'
                          value={this.state.cancel.close ? this.state.cancel.close : ''}
                          onChange={this.handleChangeCancel}
                          helperText={this.state.errors.cancel.close && this.state.errors.cancel.close}
                          inputProps={{
                            style: {
                              border: 'none',
                              padding: '0px',
                              resize: 'vertical'
                            }
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Divider style={{ width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                      <Grid item xs={12}>
                        <Typography variant='h6' style={{ fontWeight: 500, marginBottom: '24px' }}>
                          Customer Signature
                        </Typography>
                        <TextField
                          ref={this.cancelSigRef}
                          error={!!this.state.errors.cancel.sig}
                          id='cancel-sig'
                          name='sig'
                          label='Contract Language'
                          multiline
                          rows={5}
                          variant='outlined'
                          value={this.state.cancel.sig ? this.state.cancel.sig : ''}
                          onChange={this.handleChangeCancel}
                          helperText={this.state.errors.cancel.sig && this.state.errors.cancel.sig}
                          inputProps={{
                            style: {
                              border: 'none',
                              padding: '0px',
                              resize: 'vertical'
                            }
                          }}
                          fullWidth
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            )}
          </TableContainer>

          <TableContainer component={Paper} sx={{ mb: 1 }}>
            <Table aria-label='customized table' sx={{ borderBottom: '2px solid #2a3042' }}>
              <TableHead>
                <TableRow onClick={this.handleSetOpenSignOffs}>
                  <TableCell>Signoffs</TableCell>
                  <TableCell align='right'>{this.state.openSignOffs ? <ExpandLessIcon /> : <ExpandMoreIcon />}</TableCell>
                </TableRow>
              </TableHead>
            </Table>
            {this.state.openSignOffs && (
              <Box sx={{ p: 2, overflowX: 'hidden' }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='h6' sx={{ mb: 3 }}>
                      Contract Signoffs
                    </Typography>
                    <TextField
                      ref={this.signoffsSectionRef}
                      error={!!this.state.errors.signoffs.section}
                      id='signoffs-section'
                      name='section'
                      label='Section Title'
                      variant='outlined'
                      value={this.state.signoffs.section ? this.state.signoffs.section : ''}
                      onChange={this.handleChangeSignoffs}
                      helperText={this.state.errors.signoffs.section && this.state.errors.signoffs.section}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Divider sx={{ mt: 3, mb: 2 }} />

                <Grid container>
                  <Grid item xs={12} direction='column' container>
                    <Typography variant='h6' sx={{ mb: 3 }}>
                      Company Contract Language
                    </Typography>
                    <TextField
                      ref={this.signoffsCompanyBody}
                      error={!!this.state.errors.signoffs.company.body}
                      id='signoffs-company-body'
                      name='body'
                      label='Company Contract Language'
                      multiline
                      rows={6}
                      variant='outlined'
                      value={this.state.signoffs.company.body ? this.state.signoffs.company.body : ''}
                      onChange={this.handleChangeSignoffs}
                      helperText={this.state.errors.signoffs.company.body && this.state.errors.signoffs.company.body}
                      inputProps={{
                        style: {
                          border: 'none',
                          padding: '0px',
                          resize: 'vertical'
                        }
                      }}
                      sx={{ marginTop: '16px' }}
                      fullWidth
                    />
                    <Grid direction='row' style={{ marginTop: '24px' }} container>
                      <Grid item xs={6}>
                        <FormControlLabel
                          sx={{ m: 0, marginRight: '24px' }}
                          control={
                            <Switch
                              sx={{ display: 'flex', justifyContent: 'flex-end' }}
                              checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                              onChange={this.handleChangeCompanyRequired}
                              checked={'display' in this.state.signoffs.company ? this.state.signoffs.company.display : false}
                            />
                          }
                          label={
                            <Typography variant='body2' sx={{ flex: 1 }}>
                              Include Company Signature
                            </Typography>
                          }
                          labelPlacement='start'
                        />
                      </Grid>

                      {this.state.signoffs.company.display && (
                        <Grid item xs={6}>
                          <TextField
                            ref={this.signoffsCompanyTitle}
                            error={!!this.state.errors.signoffs.company.title}
                            id='signoffs-company-title'
                            name='title'
                            label='Company Signature Label'
                            variant='outlined'
                            value={this.state.signoffs.company.title ? this.state.signoffs.company.title : ''}
                            onChange={this.handleChangeSignoffs}
                            helperText={this.state.errors.signoffs.company.title && this.state.errors.signoffs.company.title}
                            // sx={{ width: '32.5%' }}
                            fullWidth
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Divider sx={{ mt: 3, mb: 2 }} />

                <Grid container>
                  <Grid item xs={12} direction='column' container>
                    <Typography variant='h6' sx={{ mb: 3 }}>
                      Contract Disclaimer
                    </Typography>
                    <TextField
                      id='signoffs-disclaimer-body'
                      name='disclaimer'
                      label='Disclaimer Language'
                      multiline
                      rows={6}
                      variant='outlined'
                      value={this.state.signoffs.disclaimer ? this.state.signoffs.disclaimer : ''}
                      onChange={this.handleChangeSignoffsDisclaimer}
                      inputProps={{
                        style: {
                          border: 'none',
                          padding: '0px',
                          resize: 'vertical'
                        }
                      }}
                      sx={{ marginTop: '16px' }}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Divider sx={{ mt: 3, mb: 2 }} />

                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='h6' sx={{ mb: 3 }}>
                      Customer Contract Language
                    </Typography>

                    <TextField
                      ref={this.signoffsCustomerBody}
                      error={!!this.state.errors.signoffs.customer.body}
                      id='signoffs-customer-body'
                      name='body'
                      label='Customer Contract Language'
                      multiline
                      rows={6}
                      variant='outlined'
                      value={this.state.signoffs.customer.body ? this.state.signoffs.customer.body : ''}
                      onChange={this.handleChangeSignoffs}
                      helperText={this.state.errors.signoffs.customer.body && this.state.errors.signoffs.customer.body}
                      inputProps={{
                        style: {
                          border: 'none',
                          padding: '0px',
                          resize: 'vertical'
                        }
                      }}
                      sx={{ mt: 2 }}
                      fullWidth
                    />

                    <Grid direction='row' style={{ marginTop: '24px' }} container>
                      <Grid item xs={6}>
                        <FormControlLabel
                          sx={{ m: 0, marginRight: '24px' }}
                          control={
                            <Switch
                              sx={{ display: 'flex', justifyContent: 'flex-end' }}
                              checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                              onChange={this.handleChangeCustomerRequired}
                              checked={'display' in this.state.signoffs.customer ? this.state.signoffs.customer.display : false}
                            />
                          }
                          label={
                            <Typography variant='body2' sx={{ flex: 1 }}>
                              Include Customer Signature
                            </Typography>
                          }
                          labelPlacement='start'
                        />
                      </Grid>

                      {this.state.signoffs.customer.display && (
                        <Grid item xs={6}>
                          <TextField
                            ref={this.signoffsCustomerTitle}
                            error={!!this.state.errors.signoffs.customer.title}
                            id='signoffs-customer-title'
                            name='title'
                            label='Customer Signature Label'
                            variant='outlined'
                            value={this.state.signoffs.customer.title ? this.state.signoffs.customer.title : ''}
                            onChange={this.handleChangeSignoffs}
                            helperText={this.state.errors.signoffs.customer.title && this.state.errors.signoffs.customer.title}
                            fullWidth
                          />
                        </Grid>
                      )}
                    </Grid>

                    <Divider sx={{ mt: 3, mb: 2 }} />

                    <CustomerInitials signoffs={this.state.signoffs} guid={this.guid} onChange={(initials) => this.onChangeInitials(initials)} />
                  </Grid>
                </Grid>
              </Box>
            )}
          </TableContainer>

          <CustomerPortalCopy config={this.state.customerPortalCopy} onChange={this.handleChangeCustomerPortalCopy} />
        </div>
      </>
    )
  }
}

const CustomerPortalCopy = ({ config, onChange }) => {
  const [open, setOpen] = useState(false)
  const [contentJson, setContentJson] = useState(config)

  useEffect(() => {
    setContentJson(config)
  }, [config])

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    const c = JSON.parse(JSON.stringify(contentJson))
    c[name] = value
    if (onChange) {
      onChange(c)
    }
    setContentJson(c)
  }

  const handleClickOpen = () => {
    setOpen(!open)
  }

  return (
    <Box>
      <TableContainer component={Paper} sx={{ mb: 1 }}>
        <Table aria-label='customized table' sx={{ borderBottom: '2px solid #2a3042' }}>
          <TableHead>
            <TableRow onClick={handleClickOpen}>
              <TableCell>Customer Portal Copy</TableCell>
              <TableCell align='right'>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {open
          ? (
            <Box sx={{ p: 2, overflowX: 'hidden' }}>
              <Box sx={{ pb: 3 }}>
                <Stack>
                  <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                    Custom Copy for PDF Signer
                  </Typography>
                  <Typography variant='body2'>
                    Set multiple copy elements on the portal for various things like terms and conditions, post PDF signage message, etc.
                  </Typography>
                </Stack>
              </Box>
              {/** Terms and Conditions */}
              <Box>
                <Stack>
                  <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                    Terms and Conditions
                  </Typography>
                  <Typography variant='body2'>
                    These terms and conditions are displayed when entering the PDF signer tool.  If left empty, a terms and conditions dialog will not appear when entering the PDF signer tool.
                  </Typography>
                </Stack>
              </Box>
              <Box sx={{ pb: 2 }}>
                <TextField
                  name='termsAndConditions'
                  label='Terms and Conditions'
                  multiline
                  rows={6} // Set the number of rows
                  variant='outlined'
                  fullWidth
                  value={contentJson.termsAndConditions}
                  onChange={handleChange}
                  sx={{ mt: 2 }}
                />
              </Box>
              {/** Signature Dialog Message */}
              <Box>
                <Stack>
                  <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                    Signature Dialog Message
                  </Typography>
                  <Typography variant='body2'>
                    This is a small message displayed at the bottom of the Sign/Type dialog that the customer uses to do the signing or typing action. If left empty, nothing will be displayed.
                  </Typography>
                </Stack>
              </Box>
              <Box sx={{ pb: 2 }}>
                <TextField
                  name='addSignatureDialogMessage'
                  label='Signature Dialog Message'
                  multiline
                  rows={6} // Set the number of rows
                  variant='outlined'
                  fullWidth
                  value={contentJson.addSignatureDialogMessage}
                  onChange={handleChange}
                  sx={{ mt: 2 }}
                />
              </Box>
              {/** Post everything signed message */}
              <Box>
                <Stack>
                  <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                    Complete All Signatures Message
                  </Typography>
                  <Typography variant='body2'>
                    This is a dialog that pops up that explains any next steps or custom message to the customer. You can add things like "You will be notified within 24 hours" or other messaging.
                    If left empty, the dialog will not pop up and the customer will be returned to the Customer Portal.
                  </Typography>
                </Stack>
              </Box>
              <Box>
                <TextField
                  name='postSignatureMessage'
                  label='Post Signature Message'
                  multiline
                  rows={6} // Set the number of rows
                  variant='outlined'
                  fullWidth
                  value={contentJson.postSignatureMessage}
                  onChange={handleChange}
                  sx={{ mt: 2 }}
                />
              </Box>
            </Box>
            )
          : null}
      </TableContainer>
    </Box>

  )
}

// eslint-disable-next-line
export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} editorComponent={ContractEditor} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}
