import React from 'react'
import { Subject } from 'rxjs'
import { filter } from 'rxjs/operators'

const mainSubject = new Subject()

/**
 *
 * @param topic
 * @param data
 */
export const publish = (topic, data) => {
  // console.log("Publishing data to '" + topic + "'", data);
  mainSubject.next({ topic, data })
}

/**
 *
 * @param {string|string[]} topic
 * @param {function} callback
 * @return {Subscription|Subscription[]}
 */
export const subscribe = (topic, callback) => {
  if (Array.isArray(topic)) {
    return topic.map(
      (topic) => mainSubject.pipe(filter(f => f.topic === topic))
        .subscribe(callback)
    )
  }
  return mainSubject.pipe(filter(f => f.topic === topic))
    .subscribe(callback)
}

/**
 * HOC Wrapper for pub-sub.
 *
 * @param WrappedComponent
 * @param props
 * @return {React.Component}
 */
export function withSubscription (WrappedComponent, props) {
  return class extends React.Component {
    mounted = false
    /**
         *
         * @type {Subscription}
         */
    unsub = null

    constructor (props) {
      super(props)

      this.state = { ...this.props }

      if (this.props.topic) {
        this.unsub = mainSubject
          .pipe(filter(f => f.topic === this.props.topic))
          .subscribe(s => this.setState({ ...s.data }))
      }
    }

    setState (props) {
      if (this.mounted) {
        return super.setState(props)
      }
    }

    componentDidMount () {
      this.mounted = true
    }

    componentWillUnmount () {
      this.unsub.unsubscribe()
    }

    render () {
      return (
        <WrappedComponent {...this.state} />
      )
    }
  }
}
