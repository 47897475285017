import React, { useState, useEffect } from 'react'
import { Box, Button, Drawer, Grid, MenuItem, FormControl, FormControlLabel, RadioGroup, Radio, TextField, Typography } from '@mui/material'
import DrawerHeader from './DrawerHeader'
import MapFormFieldNoAutomate from './MapFormFieldNoAutomate'
import MapDetermineQuantity from './MapDetermineQuantity'
import DrawerDebug from '../../../../components/Reusable/DrawerDebug'
import Permissions from '../../../../library/Permissions'
import AdvanceIcon from '@mui/icons-material/ArrowForwardIos'
import TreeIcon from '@mui/icons-material/AccountTree'
import Filter1Icon from '@mui/icons-material/Filter1'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import ProductInfo from './ProductInfo'
import DrawerProductAutomation from './DrawerProductAutomation'
import DrawerSpecAutomation from './DrawerSpecAutomation'
import DrawerProductSelect from './DrawerProductSelect'
import { guid } from '@supportworks/helper'

const DrawerSolutionEditor = (props) => {
  const { open, onSave, onClose, setSolution, solution, scratch, allFieldsSolutions, field } = props
  const formFields = allFieldsSolutions // Not sure why we just do not pass formFields
  const { productList } = props
  const { productConfig } = props

  const [isLoading, setIsLoading] = useState(true)
  const [productGroups, setProductGroups] = useState([])
  const [productGroupId, setProductGroupId] = useState('')
  const [product, setProduct] = useState({}) // Product being built for the solution
  const [productId, setProductId] = useState('')
  const [productMaster, setProductMaster] = useState('') // Product config master
  const [branch, setBranch] = useState()

  const [customTitle, setCustomTitle] = useState('customTitle' in solution ? solution.customTitle : '')
  const [radioAutomate, setRadioAutomate] = useState('')
  const [radioAssociate, setRadioAssociate] = useState('')
  const [mode, setMode] = useState('')

  const [showDrawerSpecAutomation, setShowDrawerSpecAutomation] = useState(false)
  const [showDrawerProductAutomation, setShowDrawerProductAutomation] = useState(false)
  const [showDrawerProductSelect, setShowDrawerProductSelect] = useState(false)

  useEffect(() => {
    // console.log('useEffect start [] triggered');
    if (isLoading) {
      for (let x = 0; x < productConfig.length; x++) {
        const config = productConfig[x].configuration
        if (config && config.length > 0) {
          const arr = getTriggerSpecifications(config[0])
          productConfig[x].specifications = arr
        }
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    // console.log('useEffect customTitle triggered');
    setSolution({ ...solution, customTitle, solutionTitle: scratch.solutionTitle })
  }, [customTitle]) // eslint-disable-line 

  useEffect(() => {
    // console.log('useEffect productId triggered');
    if (productId) {
      const productMaster = productList.find((o) => o.id === productId)
      setProductMaster(productMaster)
      const matches = getProductGroups(productId, productConfig)
      if (matches.length > 0) {
        setProductGroups(matches)
      } else {
        setProductGroups([])
      }
    }
  }, [productId]) // eslint-disable-line

  const handleChangeRadio = (event) => {
    const { name, value } = event.target
    if (name === 'radioAssociate') {
      if (value === 'no') {
        setRadioAssociate('no')
      } else {
        setRadioAssociate('yes')
      }
    }
  }

  const handleChangeProduct = (name, value) => {
    setProductId(value)
    if (name === 'product') {
      let type = 'product'
      const config = productConfig.filter((o) => o.id === value)
      if (config && config.length > 0) {
        type = 'productGroup'
        setProductGroupId(value)
        const b = productConfig.filter((c) => c.id === value)
        setBranch(b[0])
      }

      const product = { id: value, ruleId: guid(), type, originalId: value, config: [] }
      setProduct(product)
      const productMaster = productList.find((o) => o.id === value)
      setProductMaster(productMaster)
    }
    if (name === 'productGroup') {
      setProduct({ ...product, id: value, ruleId: guid(), type: 'productGroup', config: [] })
      setProductGroupId(value)
      const b = productConfig.filter((c) => c.id === value)
      setBranch(b[0])
    }
  }

  const handleSaveSolution = () => {
    onSave()
    onClose()
  }

  const handleSaveProduct = () => {
    let products = solution.products || []
    const index = products.map((o) => o.ruleId).indexOf(product.ruleId)

    // set trigger field
    if (field && product.config.length === 0) {
      product.config.push({ values: [], field: field.id })
    }
    if (field == null && product.config.length === 0) {
      product.config.push({ values: [], field: '' })
    }

    if (index !== -1) {
      products[index] = product
      // products.push(product);
    } else {
      if (radioAutomate === 'no') {
        // For radioAutomate "no" we only want the one product with no product id
        products = [product]
      } else {
        products.push(product)
      }
      solution.products = products
    }

    setSolution(solution)
  }

  const handleEditProduct = (id, idx) => {
    const arr = solution.products || []
    if (arr.length) {
      const p = arr.filter((item) => item.id === id)
      if (p && p.length > 0) {
        translateJSON(p[0])
        setMode('EDIT')
      }
    }
  }

  const handleRemoveProduct = (ruleId) => {
    const arr = solution.products || []
    const products = arr.filter((item) => item.ruleId !== ruleId)
    setSolution({ ...solution, products })
    if (products.length === 0) {
      setMode('')
    }
    initialize()
  }

  const handleEditSpecification = (id) => {
    let p
    if (product && productId) {
      p = product
    } else {
      const arr = solution.products || []
      p = arr.filter((item) => item.id === id)[0]
      translateJSON(p)
    }
    const [type] = getProductType(productConfig, p)
    if (type === 'product') {
      setShowDrawerProductAutomation(!showDrawerProductAutomation)
    } else {
      setShowDrawerSpecAutomation(!showDrawerSpecAutomation)
    }
  }

  const translateJSON = (p) => {
    setProduct(p)
    const [type, b] = getProductType(productConfig, p)
    if (type === 'product') {
      setProductId(p.id)
    } else {
      setRadioAssociate('yes')
      // let b = productConfig.filter((c) => c.id === p.id);
      setProductId(p.originalId)
      setProductGroupId(p.id)
      setBranch(b[0])
    }
    setRadioAutomate('yes')
  }

  const initialize = () => {
    setProduct({})
    setProductId('')
    setProductGroupId('')
    setProductGroups([])
    setProductMaster('')
    setRadioAssociate('')
    setRadioAutomate('')
    setBranch()
    setShowDrawerProductSelect(false)
    setShowDrawerSpecAutomation(false)
    setShowDrawerProductAutomation(false)
    setMode('')
  }

  const toggleDrawer = (event) => {
    event.preventDefault()
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    initialize()
    onClose()
  }

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const getProductGroups = (id, productConfig) => {
    const matches = []
    productConfig.forEach((c) => {
      const j = JSON.stringify(c)
      if (j.match(id)) {
        const arr = c.specifications
        if (arr.length) {
          matches.push(c)
        }
      }
    })
    return matches
  }

  if (isLoading) {
    return <>Please wait a minute...</>
  }

  /**
   * Figure out the different scenarios where the buttons can pop up based on the spec.
   */
  let showSaveButton = false
  let showSaveCloseButton = false
  let showClearButton = false
  let showBackButton = false
  if (!radioAutomate && solution) {
    // Display save & close when no radio is picked, and we have a solution.
    showSaveCloseButton = true
  }
  if (radioAutomate === 'yes') {
    if (product.id) {
      // Show save button if we have a product selected, regardless of setting config.
      showSaveButton = true
    } else {
      if (!solution.products) {
        // Show save and close for empty products with no products already in the solution.
        showSaveCloseButton = true
      }
    }
  }
  if (radioAutomate === 'no') {
    // I have an empty solution, and the config is set.
    if (product.config && product.config.length > 0) {
      showSaveCloseButton = true
    }
  }
  if (productId || radioAutomate) {
    if (mode === 'EDIT') {
      showBackButton = true
    } else {
      showClearButton = true
    }
  }

  return (
    <Drawer key='SE' anchor='right' variant='persistent' open={open} onKeyDown={stopPropagationForTab} onClose={toggleDrawer}>
      <DrawerHeader variant='solution' title={scratch.solutionTitle} onClose={toggleDrawer} />
      <div className='sa-container' style={{ marginBottom: '56px' }}>
        {mode === 'EDIT'
          ? (
            <>
              {product.id
                ? (
                  <div className='sa-section sa-top-padding'>
                    <ProductInfo product={product} productList={productList} productConfig={productConfig} />
                    <div className='sa-section'>
                      <MapDetermineQuantity key={`MDQ-${product.id}`} setProduct={setProduct} product={product} formFields={formFields} />
                    </div>
                  </div>
                  )
                : null}
            </>
            )
          : null}

        {mode !== 'EDIT' ? ( // eslint-disable-line
          <>
            <div className='sa-section'>
              <Box sx={{ pt: 2, pb: 2 }}>Description</Box>
              <TextField
                id='customTitle'
                name='customTitle'
                label='What makes this automation different from the others?'
                variant='outlined'
                defaultValue={customTitle || ''}
                onChange={(e) => {
                  setCustomTitle(e.target.value)
                }}
                required
                fullWidth
                sx={{
                  'input:focus': {
                    border: 'none'
                  }
                }}
              />
            </div>
            {/** If I have no product or product group selected, use radio buttons to prompt for automation.  Otherwise just use a button. */}
            {product.id
              ? null
              : solution.products && solution.products.length === 0
                ? (
                  <div className='sa-section'>
                    <div className='sa-heading'>Do you want to automate a product or product group?</div>
                    <div>
                      <FormControl component='fieldset'>
                        <RadioGroup name='radioAutomateGroup' value={radioAutomate || ''}>
                          <FormControlLabel
                            value='yes'
                            control={<Radio size='small' color='primary' />}
                            name='radioAutomate'
                            label='Yes'
                            onClick={() => {
                              initialize()
                              setRadioAutomate('yes')
                              setShowDrawerProductSelect(true)
                            }}
                          />
                          <FormControlLabel
                            value='no'
                            control={<Radio size='small' color='primary' />}
                            name='radioAutomate'
                            label='No'
                            onClick={() => {
                              initialize()
                              setRadioAutomate('no')
                            }}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  )
                : (
                  <div className='sa-section'>
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={() => {
                        initialize()
                        setRadioAutomate('yes')
                        setShowDrawerProductSelect(true)
                      }}
                      fullWidth
                    >
                      <Typography variant='body'>
                        {solution.products && solution.products.length > 0 ? 'Add Product Automation' : 'Add Product Automation'}
                      </Typography>
                    </Button>
                  </div>
                  )}

            {/** NEW product or product group automation */}
            {radioAutomate === 'yes'
              ? (
                <div className='sa-indent'>
                  <ProductInfo product={product} productList={productList} productConfig={productConfig} />
                  {product.id
                    ? (
                      <MapDetermineQuantity key={`MDQ-${product.id}`} setProduct={setProduct} product={product} formFields={formFields} variant='indent' />
                      )
                    : null}
                </div>
                )
              : null}

            {product.type !== 'productGroup' && productGroups.length
              ? (
                <div className='sa-section'>
                  <div className='sa-indent'>
                    <span className='sa-text12'>
                      NOTE: This product is associated with a specification, do you want to associate multiple answers with varying products?
                    </span>
                  </div>
                  <div className='sa-indent'>
                    <FormControl component='fieldset'>
                      <RadioGroup name='radioAssociateGroup' value={radioAssociate || ''}>
                        <FormControlLabel
                          value='yes'
                          control={<Radio size='small' color='primary' />}
                          label='Yes'
                          name='radioAssociate'
                          onClick={handleChangeRadio}
                        />
                        <FormControlLabel
                          value='no'
                          control={<Radio size='small' color='primary' />}
                          label='No'
                          name='radioAssociate'
                          onClick={handleChangeRadio}
                        />
                      </RadioGroup>
                    </FormControl>

                    {radioAssociate === 'yes'
                      ? (
                        <div className='sa-indent'>
                          <FormControl key='FormControl' variant='outlined' fullWidth>
                            <TextField
                              select
                              name='productGroup'
                              label='Select Product Group'
                              variant='outlined'
                              defaultValue={productGroupId || ''}
                              onChange={(e) => {
                                handleChangeProduct(e.target.name, e.target.value)
                              }}
                            >
                              {productGroups.map((row, idx) => {
                                return (
                                  <MenuItem key={'MenuItem-' + idx} value={row.id}>
                                    <span className='sa-product-text-title'>{row.title}</span>&nbsp;
                                    <span className='sa-product-text-id'>({row.id})</span>
                                  </MenuItem>
                                )
                              })}
                            </TextField>
                          </FormControl>
                        </div>
                        )
                      : null}
                  </div>
                </div>
                )
              : null}

            {/** I picked a regular product for automation that is associated with a product group specification... */}
            {radioAutomate === 'yes' && product.type === 'productGroup'
              ? (
                <div>
                  <div className='sa-section'>
                    <span className='sa-text12'>
                      This product grouping has additional choices that can be mapped to products. Click Advance to Set Triggers or Save to do it later.
                    </span>
                  </div>
                  <div className='sa-section'>
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={() => {
                        handleSaveProduct()
                        handleEditSpecification(product.id)
                      }}
                      fullWidth
                    >
                      <Typography variant='body'>
                        Advance to Set Triggers <AdvanceIcon sx={{ ml: 1 }} />
                      </Typography>
                    </Button>
                  </div>
                </div>
                )
              : null}
          </>
        ) : null}

        {/** NEW product or product group NO automation */}
        {radioAutomate === 'no'
          ? (
            <div className='sa-indent'>
              <MapFormFieldNoAutomate key={`MFFNA-${product.id}`} onSave={onSave} setProduct={setProduct} product={product} formFields={formFields} />
            </div>
            )
          : null}

        {/** Display all my products & product groups at the bottom */}
        {solution.products && !radioAutomate && !productId
          ? (
            <>
              <ProductsInSolution
                solution={solution}
                productList={productList}
                productConfig={productConfig}
                onRemoveProduct={handleRemoveProduct}
                onEditProduct={handleEditProduct}
                onEditSpecification={handleEditSpecification}
                formFields={formFields}
                field={field}
              />
            </>
            )
          : null}

        {/** Debug display stuff that only shows up with super_user permissions */}
        {Permissions.companyIsSelected() && Permissions.hasRole('super_user')
          ? (
            <DrawerDebug
              json={[
                { name: 'product', json: product },
                { name: 'solution', json: solution },
                { name: 'specifications', json: branch && branch.specifications ? branch.specifications : [] },
                { name: 'branch', json: branch },
                { name: 'scratch', json: scratch },
                { name: 'productList', json: productList }
              ]}
            />
            )
          : null}
      </div>

      <Box sx={{ mt: 6 }}>
        <Grid container sx={{ pt: 2, pb: 2, pl: 1, pr: 2 }} style={styles.footer}>
          {showClearButton
            ? (
              <Grid item xs style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Button sx={{ mr: 1 }} color='primary' variant='contained' onClick={initialize}>
                  <Typography variant='body1'>Clear</Typography>
                </Button>
              </Grid>
              )
            : null}
          {showBackButton
            ? (
              <Grid item xs style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Button sx={{ mr: 1 }} color='primary' variant='text' onClick={initialize}>
                  <Typography variant='body1'>&lt; Back</Typography>
                </Button>
              </Grid>
              )
            : null}
          {showSaveButton
            ? (
              <Grid item xs style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    handleSaveProduct()
                    initialize()
                  }}
                >
                  <Typography variant='body1'>Done</Typography>
                </Button>
              </Grid>
              )
            : null}
          {showSaveCloseButton
            ? (
              <Grid item xs style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    initialize()
                    handleSaveSolution()
                  }}
                >
                  <Typography variant='body1'>Done</Typography>
                </Button>
              </Grid>
              )
            : null}
        </Grid>
      </Box>

      {showDrawerProductSelect
        ? (
          <DrawerProductSelect
            open
            onChange={handleChangeProduct}
            onClose={() => {
              setShowDrawerProductSelect(!showDrawerProductSelect)
              initialize()
            }}
            setProduct={setProduct}
            product={product}
            productList={productList}
            productConfig={productConfig}
          />
          )
        : null}

      {showDrawerSpecAutomation
        ? (
          <DrawerSpecAutomation
            open
            onSave={() => {
              handleSaveProduct()
              initialize()
              setShowDrawerSpecAutomation(false)
            }}
            onClose={() => {
              setShowDrawerSpecAutomation(!showDrawerSpecAutomation)
              initialize()
            }}
            scratch={scratch}
            branch={branch}
            setProduct={setProduct}
            product={product}
            formFields={formFields}
          />
          )
        : null}

      {showDrawerProductAutomation
        ? (
          <DrawerProductAutomation
            open
            onSave={() => {
              handleSaveProduct()
              initialize()
              setShowDrawerProductAutomation(false)
            }}
            onClose={() => {
              setShowDrawerProductAutomation(false)
              initialize()
            }}
            scratch={scratch}
            setProduct={setProduct}
            product={product}
            productMaster={productMaster}
            formFields={formFields}
            field={field}
            solution={solution}
          />
          )
        : null}
    </Drawer>
  )
}
export default DrawerSolutionEditor

const ProductsInSolution = ({ solution, productList, productConfig, onEditProduct, onEditSpecification, onRemoveProduct, formFields, field }) => {
  if (solution && solution.products && solution.products.length === 0) {
    return <></>
  }

  const productsSelected = solution.products
  return (
    <div>
      <div className='sa-section'>
        <div className='sa-heading'>Product Automation</div>
      </div>
      <div className='sa-section'>
        <div>
          {productsSelected.map((product, idx) => {
            let type = 'product'
            let productMaster = productList.find((p) => p.id === product.id)
            if (!productMaster) {
              productMaster = productConfig.find((p) => p.id === product.id)
              if (productMaster) type = 'productGroup'
            }
            let quantity = product.quantity
            if (product.quantityType === 'field' && product.quantity) {
              const m = formFields.find((o) => o.id === product.quantity.field)
              quantity = `<i> ${m && m.title ? m.title : 'No form field found.'} </i>`
            }
            // Figure out if a trigger is set and build the appropriate text
            const triggers = [] // "Trigger not set";
            if (product && product.config.length > 0) {
              if (type === 'product') {
                if (product.config[0].field && formFields !== undefined) {
                  const ff = formFields.find((o) => o.id === product.config[0].field)
                  if (ff !== undefined && ff.title) {
                    triggers.push(`Product triggered by <i>${ff.title}</i>`)
                  } else {
                    triggers.push(`Product triggered by an orphaned form field: ${product.config[0].field}`)
                  }
                }
              } else {
                // Figure out the display for product groups...
                const pc = productConfig.find((o) => o.id === product.id)
                for (let x = 0; x < pc.specifications.length; x++) {
                  const pcTitle = pc.specifications[x].title
                  if (product.config[x]) {
                    if (typeof product.config[x] === 'string') {
                      triggers.push(`<i>${pcTitle}</i> triggered manually`)
                    } else {
                      if (!(formFields === undefined || formFields.length === 0)) {
                        const m = formFields.find((o) => o.id === product.config[x].field)
                        if (m) {
                          triggers.push(`<i>${pcTitle}</i> triggered by <i>${m.title}</i>`)
                        }
                      }
                    }
                  } else {
                    triggers.push(`<i>${pcTitle}</i> trigger not set`)
                  }
                }
              }
            }

            // console.log(`HERE IS THE PRODUCT MASTER: ${JSON.stringify(productMaster)}`);
            return (
              <div key={`PIS-${product.id}-${idx}`} className='sa-product-container'>
                <div className='sa-product-left'>{product.type === 'productGroup' ? <TreeIcon /> : <Filter1Icon />}</div>
                <div style={{ flex: 5 }}>
                  <div>
                    {productMaster !== undefined && productMaster.title ? productMaster.title : 'No product title found'} {product.type === 'productGroup' ? null : <>({product.id})</>}
                  </div>
                  <div className='sa-product-link'>
                    <span
                      className='sa-icon'
                      name={product.id}
                      onClick={() => {
                        onEditProduct(product.id, idx)
                      }}
                    >
                      {quantity
                        ? (
                          <span dangerouslySetInnerHTML={{ __html: `Custom quantity, set to ${quantity}` }} />
                          )
                        : (
                          <>
                            <b>Quantity not set</b>
                          </>
                          )}
                    </span>
                    <br />
                    <span
                      className='sa-icon'
                      name={product.id}
                      onClick={() => {
                        onEditSpecification(product.id)
                      }}
                    >
                      {triggers.length > 0
                        ? (
                            triggers.map((trigger, idx2) => {
                              return <div key={`dangerous-${idx2}`} dangerouslySetInnerHTML={{ __html: trigger }} />
                            })
                          )
                        : (
                          <>
                            <b>Trigger not set</b>
                          </>
                          )}
                    </span>
                  </div>
                </div>
                <div>
                  <span name={product.id} className='sa-icon'>
                    <DeleteIcon
                      onClick={() => {
                        onRemoveProduct(product.ruleId)
                      }}
                    />
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

/**
 * getTriggerSpecifications()
 *
 * This method checks all "related" types that have multiple entries for uniformity.
 * IE - All of the choices under each need to match for it to be considered uniform.
 *
 * @returns {array}
 */
function getTriggerSpecifications (branch) {
  const related = flattenConfig(branch, [])
  for (let x = 0; x < related.length; x++) {
    if (related[x].uniform === false) {
      return []
    }
  }
  return related
}
export { getTriggerSpecifications }

/**
 * flattenConfig()
 *
 * Recursively walk a giant JSON solution, down the left side (0 array position),
 * to flatten it into one array that we will use for solution mapping.
 *
 * @param {*} branch
 * @param {*} flattened
 * @returns
 */
function flattenConfig (branch, flattened) {
  if (branch.related && branch.related.length) {
    const related = branch.related
    const obj = { id: related[0].id, title: related[0].title, uniform: true }
    if (related[0].choices) {
      obj.count = related[0].choices.length
      obj.compareString = related[0].choices
        .sort((a, b) => a.id < b.id)
        .map((o) => {
          return o.id
        })
        .join('__')
      obj.choices = related[0].choices
    } else {
      obj.uniform = false
    }
    flattened.push(obj)
    flattenConfig(related[0], flattened)
  }

  if (branch && branch.choices && branch.choices.length) {
    const choices = branch.choices
    if (choices.length === 1) {
      // The only time choices should === 1 is if their are relateds under it.
      const obj = { id: choices[0].id, title: choices[0].title, uniform: true, count: choices.length }
      flattened.push(obj)
    } else {
      // DEV-2551 - If we have a single level spec like Extraction, with no related keys, and it's not a dupe, we want to count it.
      const relateds = choices.filter((o) => 'related' in o)
      const dupe = flattened.filter((o) => {
        return o.title === branch.title
      })
      if (relateds.length === 0 && dupe.length === 0) {
        const obj = { id: branch.id, title: branch.title, uniform: true }
        obj.count = choices.length
        obj.compareString = choices
          .sort((a, b) => a.id < b.id)
          .map((o) => {
            return o.id
          })
          .join('__')
        obj.choices = choices
        flattened.push(obj)
      }
    }
    flattenConfig(choices[0], flattened)
  }

  return flattened
}
export { flattenConfig }

const getProductType = (productConfig, product) => {
  const b = productConfig.filter((c) => c.id === product.id)
  if (b.length > 0) {
    return ['productGroup', b]
  } else {
    return ['product', {}]
  }
}

const styles = {
  footer: {
    position: 'fixed',
    bottom: 0,
    width: '560px',
    borderTop: '1px solid rgba(0,0,0,.08)',
    backgroundColor: 'white'
  }
}
