/* eslint-disable array-callback-return */
import React from 'react'
import { Box } from '@mui/material'
import Company from 'screens/Company'
import Api from '../../library/Api'
import { LoadingOverlay } from '../../components/Reusable'
import DataGrid from 'components/Reusable/DataGrid'
import Helper from '@supportworks/helper'
import { LoadingPleaseWait } from '@supportworks/react-components'
import withScreenWrapper from '../withScreenWrapper'

/**
 * Provides a list of existing configurations for the specified company, links to maintain them and create new configs.
 */
class AppointmentsIndex extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: true,
      page: 0
    }
  }

  componentDidMount () {
    this.refreshList()
  }

  refreshList () {
    this.setState({ isLoading: true }, () => {
      Api.callRegisteredMethod('getAppointments', { organizationId: this.props.organizationId }).then((appointments) => {
        this.setState({
          appointments,
          isLoading: false
        })
        LoadingOverlay.hide()
      })
    })
  }

  render () {
    const columns = [
      {
        flex: 1,
        type: 'string',
        field: 'customer',
        headerName: 'Customer',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'address',
        headerName: 'Address',
        editable: false,
        sortable: false
      },
      {
        flex: 1,
        type: 'string',
        field: 'telephone',
        headerName: 'Telephone',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'crmId',
        headerName: 'Id',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'personnel',
        headerName: 'Personnel',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'start',
        headerName: 'Start',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'status',
        headerName: 'Status',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'result',
        headerName: 'Result',
        editable: false,
        sortable: true
      }
    ]

    const appts = this.state.appointments || []
    const rows = appts.map((appt, idx) => {
      let fullName = ''
      let address = ''
      const phones = []
      fullName = [appt.lastName, appt.firstName].filter(Boolean).join(', ')
      address = [appt.address, appt.address1, appt.address2, appt.address3].filter(Boolean).join('\r\n')
      const mobile = Helper.formatPhoneNumber(appt.mobile)
      if (mobile) phones.push(`${mobile}M`)
      const landline = Helper.formatPhoneNumber(appt.landline)
      if (landline) phones.push(`${landline}H`)
      return {
        id: idx,
        customer: fullName,
        address,
        telephone: phones.join('\r\n'),
        crmId: appt.prospectNum,
        personnel: appt.repName,
        start: appt.start,
        status: appt.status,
        result: appt.result
      }
    })

    if (this.state.isLoading) {
      return <LoadingPleaseWait />
    }

    return (
      <Box>
        <Company.Header title='Appointments' />
        <DataGrid rows={rows} columns={columns} />
      </Box>
    )
  }
}
export default withScreenWrapper(AppointmentsIndex)
