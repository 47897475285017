import React from 'react'
import { IconButton, Box, Button, Grid } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import UpIcon from '@mui/icons-material/KeyboardArrowUp'
import DownIcon from '@mui/icons-material/KeyboardArrowDown'
import Api from '../../library/Api'
import CompanyConfigEdit, { CompanyConfigBaseEditor } from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import CompanyConfigModal from './CompanyConfigModal'
import DataGrid from 'components/Reusable/DataGrid'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { SelectSearch } from '../../components/Reusable/SelectSearch'
import Company from 'screens/Company'

const config = {
  title: 'Product Groups',
  variant: 'productConfig',
  tag: 'productConfig',
  name: 'Product Groups',
  lockTag: true,
  lockName: true
}

class ProductConfigEditor extends CompanyConfigBaseEditor {
  constructor (props) {
    super(props)
    let productsConfig = null
    if (typeof props.contentJson === 'string') {
      // try catch
      try {
        const jsonObj = JSON.parse(props.contentJson)
        productsConfig = []
        for (const i in jsonObj) {
          productsConfig.push(jsonObj[i])
        }
      } catch (err) {
        console.log(err.message)
      }
    } else if (typeof props.contentJson === 'object') {
      // no need to parse
      const jsonObj = props.contentJson
      productsConfig = []
      for (const i in jsonObj) {
        productsConfig.push(jsonObj[i])
      }
    } else if (props.contentJson !== undefined) {
      // not string, object, or undefined. Necessary case?
      try {
        const jsonObj = JSON.parse(props.contentJson)
        productsConfig = []
        for (const i in jsonObj) productsConfig.push(jsonObj[i])
      } catch (err) {
        console.log(err.message)
      }
    } else {
      productsConfig = []
    }

    productsConfig = productsConfig
      .filter((p) => !(p.auto === true))
      .map((p) => {
        return p
      })

    this.state = {
      isLoading: true,
      productsConfig: productsConfig.sort((a, b) => (a.title > b.title ? 1 : -1)),
      productsList: null,
      selectedIdx: -1,
      selectedRows: [],
      showProductEditor: false
    }
    this.handleAdd = this.handleAdd.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount () {
    super.componentDidMount()
    this.state.productsConfig.forEach((m) => {
      this.addExistingGuid(m.id)
      if ('configuration' in m) {
        m.configuration[0].choices.forEach((c) => {
          this.addConfig(c)
        })
      }
    })
    Api.callRegisteredMethod('getConfigListByTag', { tag: 'products', startsWith: false }).then((configs) => {
      const res = Math.max.apply(
        Math,
        configs.map(function (o) {
          return o.configId
        })
      ) // finds greatest configId value
      const mostRecent = configs.filter((obj) => {
        // gets most recent Product config //Might not be needed, only being used now for orgId
        return obj.configId === res
      })
      const org = mostRecent[0].organizationId
      console.log(res, org)

      Api.callRegisteredMethod('getConfigById', { configId: res, organization: org }).then((prodProps) => {
        const list = JSON.parse(prodProps.data[0].contentJson)
        const productsList = []
        const fullProductsList = []
        list.map((product, ind) => {
          if (product.id) {
            productsList.push({
              value: product.id,
              name: product.title + ', Price $' + product.price + (product.crmId !== '' ? ', SKU: ' + product.crmId : '')
            })
            fullProductsList.push(product)
          }
          return null
        })
        this.setState({
          isLoading: false,
          fullProductsList,
          productsList: productsList.sort((a, b) => (a.name > b.name ? 1 : -1))
        })
      })
    })
  }

  handleAdd = () => {
    const pc = JSON.parse(JSON.stringify(this.state.productsConfig))
    // pop to the top
    pc.unshift({
      newProduct: true,
      id: this.guid(),
      title: 'New Product'
    })
    this.setState({
      productsConfig: pc
    })
    this.props.onSetStatus('unSavedChanges', true)
  }

  handleUpdate = (idx, p) => {
    const pc = JSON.parse(JSON.stringify(this.state.productsConfig))
    pc[idx] = p
    this.setState({
      productsConfig: pc,
      selectedIdx: -1,
      showProductEditor: !this.state.showProductEditor
    })
    this.props.onSetStatus('unSavedChanges', true)
  }

  handleDelete = (indexes) => {
    const count = indexes.length
    const confirmMessage = count === 1 ? 'Are you sure you want to delete this item?' : `Are you sure you want to delete these ${count} items?`
    const r = window.confirm(confirmMessage)
    if (r) {
      const pc = this.state.productsConfig.filter((_, index) => !indexes.includes(index))
      this.setState({
        productsConfig: pc,
        selectedIdx: -1,
        showProductEditor: false
      })
      this.props.onSetStatus('unSavedChanges', true)
    }
  }

  handleShowProductEditor = (idx) => {
    this.setState({
      showProductEditor: !this.state.showProductEditor,
      selectedIdx: idx
    })
  }

  addConfig (c) {
    try {
      this.addExistingGuid(c.id)
      if (c.related && c.related[0] && c.related[0].choices) {
        c.related[0].choices.map((choice) => {
          this.addConfig(choice)
          return null
        })
      }
    } catch (err) {
      console.log(`Encountered an error parsing choices, but gracefully continuing.  Data: ${JSON.stringify(c)}`)
    }
  }

  getProductName (id) {
    const configProduct = this.state.productsList.filter((arr) => {
      return arr.value === id
    })
    if (configProduct.length !== 0) return configProduct[0].name
    return 'Missing Product'
  }

  containsProduct (id, product) {
    let contains = false
    if ('configuration' in product) {
      product.configuration[0].choices?.forEach((c) => {
        contains = contains || this.containsProduct(id, c)
      })
    }
    if ('product' in product) {
      product.product.forEach((p) => {
        // eslint-disable-next-line eqeqeq
        if (p == id) contains = true
      })
    }
    return contains
  }

  finalize () {
    const cRef = this
    const productsConfig = JSON.parse(JSON.stringify(this.state.productsConfig))
    // console.log("Finalize called")
    this.state.fullProductsList?.forEach((p) => {
      let exists = false
      for (let i = cRef.state.productsConfig.length - 1; i >= 0; i--) {
        if (cRef.containsProduct(p.id, cRef.state.productsConfig[i])) {
          exists = true
          break
        }
      }
      if (!exists) {
        productsConfig.push({
          id: '' + p.id,
          title: p.title,
          categories: p.categories,
          product: ['' + p.id],
          auto: true
        })
      }
    })
    return JSON.stringify(productsConfig)
  }

  hasFinalize () {
    return true // true to enable saving
  }

  render () {
    if (this.state.isLoading) {
      return <LoadingPleaseWait />
    }

    const columns = [
      {
        flex: 2,
        type: 'string',
        field: 'title',
        headerName: 'Title',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'products',
        headerName: 'Products',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'type',
        headerName: 'Type',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'library',
        headerName: 'Library',
        editable: false,
        sortable: true
      },
      {
        width: 124, // Do not allow truncation of the 3 icons when resizing
        field: 'action',
        headerName: 'Actions',
        headerAlign: 'right',
        align: 'right',
        editable: false,
        sortable: false,
        disableExport: true,
        renderCell: (params) => {
          if (params.row) {
            const idx = params.row.id
            return (
              <Grid container wrap='nowrap' justifyContent='flex-end' style={{ overflow: 'visible' }}>
                <Grid item>
                  <IconButton
                    title='Edit'
                    aria-label='edit'
                    onClick={() => {
                      this.handleShowProductEditor(idx)
                    }}
                  >
                    <EditIcon fontSize='small' />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    title='Delete'
                    aria-label='delete'
                    disabled={!params.row.isRowSelectable}
                    onClick={() => {
                      this.handleDelete([idx])
                    }}
                  >
                    <DeleteIcon fontSize='small' />
                  </IconButton>
                </Grid>
              </Grid>
            )
          }
        }
      }
    ]

    const productsConfig = this.state.productsConfig
    const rows = []
    for (let x = 0; x < productsConfig.length; x++) {
      const p = productsConfig[x]
      let prodNumber = 'Configure Products'
      if ('configuration' in p) {
        prodNumber = 'choices' in p.configuration[0] ? p.configuration[0].choices.length + ' Products' : 'Configure Products'
      } else if ('product' in p) {
        prodNumber = '1 Product'
      }
      prodNumber = prodNumber === '1 Products' ? '1 Product' : prodNumber
      const type = 'configuration' in p ? 'Multiple Options' : 'product' in p ? 'Product: ' + this.getProductName(p.product[0]) : 'Single Product'

      rows.push({
        id: x,
        isRowSelectable: !p.library,
        title: p.title,
        products: prodNumber,
        type,
        library: p.library ? p.library.libraryName : Company.getCurrent().name,
        product: p
      })
    }

    return (
      <Box>
        <DataGrid
          rows={rows}
          columns={columns}
          selectionModel={[]}
          onDelete={(indexes) => {
            this.handleDelete(indexes)
          }}
          onAdd={this.handleAdd}
        />
        <Box sx={{ pt: 1 }} style={{ position: 'float', top: '24px', right: '24px' }}>
          <Button color='success' variant='contained' onClick={this.handleAdd} startIcon={<AddIcon />}>
            Add Product
          </Button>
        </Box>
        {this.state.showProductEditor
          ? (
            <SingleProductConfigEditor
              open={this.state.showProductEditor}
              productsList={this.state.productsList}
              product={this.state.productsConfig[this.state.selectedIdx]}
              level={0}
              guid={this.guid}
              onUpdate={(p) => this.handleUpdate(this.state.selectedIdx, p)}
              onCancel={() => {
                this.handleShowProductEditor()
              }}
            />
            )
          : null}
      </Box>
    )
  }
}

class SingleProductRelatedEditor extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      product: JSON.parse(JSON.stringify(this.props.product))
    }
    this.handleChangeProduct = this.handleChangeProduct.bind(this)
    this.handleChangeChoice = this.handleChangeChoice.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.handleMove = this.handleMove.bind(this)
  }

  handleChangeProduct = (product) => {
    const p = JSON.parse(JSON.stringify(this.state.product))
    p.product = [product.value]
    this.setState({ product: p })
  }

  handleChangeChoice = (event) => {
    const p = JSON.parse(JSON.stringify(this.state.product))
    // p.configuration[0][event.target.name] = event.target.value;
    p.related[0][event.target.name] = event.target.value
    this.setState({ product: p })
  }

  handleChange = (event) => {
    const p = JSON.parse(JSON.stringify(this.state.product))
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    if (event.target.name === 'use_related') {
      if (event.target.checked) {
        if ('related_old' in p) {
          p.related = JSON.parse(JSON.stringify(p.related_old))
          delete p.related_old
        }
        if (!('related' in p)) {
          p.related = [
            {
              id: '',
              title: '',
              type: 'choice',
              choices: []
            }
          ]
        }
      } else {
        if ('related' in p) {
          p.related_old = JSON.parse(JSON.stringify(p.related))
          delete p.related
        }
      }
    }
    p[event.target.name] = value
    this.setState({ product: p })
  }

  handleUpdate = (ind, c) => {
    const p = JSON.parse(JSON.stringify(this.state.product))
    p.related[0].choices[ind] = JSON.parse(JSON.stringify(c))
    this.setState({ product: p })
  }

  handleMove = (ind, c, direction) => {
    // console.log(direction)
    const p = JSON.parse(JSON.stringify(this.state.product))
    // console.log(p.related[0].choices[ind])
    // console.log(c)
    if (direction === 'up') {
      if (p.related[0].choices[ind - 1]) {
        // let clonedArr = JSON.parse(JSON.stringify(p.related[0].choices))
        const toSwitch = JSON.parse(JSON.stringify(p.related[0].choices[ind]))
        const switchWith = JSON.parse(JSON.stringify(p.related[0].choices[ind - 1]))
        p.related[0].choices[ind - 1] = toSwitch
        p.related[0].choices[ind] = switchWith
        const cRef = this
        this.setState({ product: p, option: false }, function () {
          cRef.props.onUpdate(p)
        })
      }
    } else if (direction === 'down') {
      if (p.related[0].choices[ind + 1]) {
        // let clonedArr = JSON.parse(JSON.stringify(p.related[0].choices))
        // let toSwitch = clonedArr[ind]
        // let switchWith = clonedArr[ind+1]
        const switchWith = JSON.parse(JSON.stringify(p.related[0].choices[ind + 1]))
        const toSwitch = JSON.parse(JSON.stringify(p.related[0].choices[ind]))
        p.related[0].choices[ind + 1] = toSwitch
        p.related[0].choices[ind] = switchWith
        const cRef = this
        this.setState({ product: p, option: false }, function () {
          cRef.props.onUpdate(p)
        })
      }
    }
    // p.related[0].choices[ind] = JSON.parse(JSON.stringify(c));
    // this.setState({product: p});
  }

  handleDelete = (ind) => {
    const p = JSON.parse(JSON.stringify(this.state.product))
    p.related[0].choices.splice(ind, 1)
    this.setState({ product: p })
  }

  handleAdd = () => {
    const p = JSON.parse(JSON.stringify(this.state.product))
    p.related[0].choices.push({
      newProduct: true,
      id: this.props.guid()
    })
    this.setState({ product: p })
  }

  getProductName (id) {
    const configProduct = this.props.productsList.filter((arr) => {
      return arr.value === id
    })
    if (configProduct.length !== 0) return configProduct[0].name
    return 'Missing Product'
  }

  getSelectedProductObject () {
    if (this.state.product && this.state.product.product) {
      return this.props.productsList.find((o) => o.value === this.state.product.product[0])
    }
  }

  render () {
    const body = (
      <div>
        <div className='col-sm-12 form-group row' style={{ display: 'none' }}>
          <label>ID</label>
          <div className='col-sm-10'>
            <input type='text' name='id' className='form-control' value={this.state.product.id} onChange={this.handleChange} />
          </div>
        </div>
        <div className='col-sm-12 form-group row'>
          <label>Title</label>
          <div className='col-sm-10'>
            <input type='text' name='title' className='form-control' value={this.state.product.title} onChange={this.handleChange} />
          </div>
        </div>
        <div className='col-sm-12 form-group row'>
          <label>Configuration?</label>
          <div className='col-sm-10'>
            <label className='slider--wrapper'>
              <input type='checkbox' name='use_related' className='form-control' checked={'related' in this.state.product} onChange={this.handleChange} />
              <span className='slider__circle' />
            </label>
          </div>
        </div>
        {/* eslint-disable-next-line multiline-ternary */}
        {'related' in this.state.product ? (
          <>
            <div className='col-sm-12 form-group row'>
              <label>Option Title</label>
              <div className='col-sm-10'>
                <input type='text' name='title' className='form-control' value={this.state.product.related[0].title} onChange={this.handleChangeChoice} />
              </div>
            </div>
            <div className='actionsTable__wrapper'>
              <div className='actionsTable__options'>
                <Button id='asset-action' className='mui-btn' color='success' variant='contained' onClick={this.handleAdd} startIcon={<AddIcon />}>
                  Add
                </Button>
              </div>
              <div id='prodhidden'>
                <table className='actionsTable'>
                  <thead>
                    <tr className='actionsTable__header'>
                      <th className='actionsTable__label'>Title</th>
                      <th className='actionsTable__label'>Products</th>
                      <th className='actionsTable__label'>Type</th>
                      <th className='actionsTable__label'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.product.related[0].choices.map((p, idx) => {
                      const visible = true
                      const display = visible ? 'table-row' : 'none'
                      // console.log("related")
                      // console.log(p)
                      // COUNT FUNCTION
                      let prodNumber = 'Configure Products'
                      if ('related' in p) {
                        prodNumber = 'choices' in p.related[0] ? p.related[0].choices.length + ' Products' : 'Congigure Products'
                      } else if ('product' in p) {
                        prodNumber = '1 Product'
                      }
                      prodNumber = prodNumber === '1 Products' ? '1 Product' : prodNumber
                      return (
                        <tr key={idx + ':' + p.id} className='actionsTable__row' style={{ display: `${display}` }}>
                          <td className='actionsTable__title'>
                            <span>{p.title}</span>
                          </td>
                          <td className='actionsTable__title'>
                            <span>{prodNumber}</span>
                          </td>
                          <td className='actionsTable__title'>
                            <span>
                              {'related' in p ? 'Multiple Options' : 'product' in p ? 'Product: ' + this.getProductName(p.product[0]) : 'Single Product'}
                            </span>
                          </td>
                          <td className='actionsTable__actions'>
                            <SingleProductRelatedEditor
                              productsList={this.props.productsList}
                              product={p}
                              onUpdate={(p) => this.handleUpdate(idx, p)}
                              open={'newProduct' in p}
                              level={0}
                              path={this.props.path + ' / ' + this.state.product.title}
                              guid={this.props.guid}
                            >
                              <IconButton title='Edit' aria-label='edit'>
                                <EditIcon variant='icon' fontSize='small' />
                              </IconButton>
                            </SingleProductRelatedEditor>
                            <IconButton
                              title='Move Up'
                              aria-label='up'
                              onClick={() => {
                                this.handleMove(idx, p, 'up')
                              }}
                            >
                              <UpIcon variant='icon' fontSize='small' />
                            </IconButton>
                            <IconButton
                              title='Move Down'
                              aria-label='down'
                              onClick={() => {
                                this.handleMove(idx, p, 'down')
                              }}
                            >
                              <DownIcon variant='icon' fontSize='small' />
                            </IconButton>
                            <IconButton
                              title='Delete'
                              aria-label='delete'
                              onClick={() => {
                                this.handleDelete(idx)
                              }}
                            >
                              <DeleteIcon variant='icon' fontSize='small' />
                            </IconButton>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : (
          <div className='col-sm-12 form-group row'>
            <label>Product</label>
            <div className='col-sm-10'>
              <SelectSearch
                name='select-product'
                value={this.getSelectedProductObject()}
                options={this.props.productsList}
                onChange={this.handleChangeProduct}
                placeholder='Please Select the Product'
              />
            </div>
          </div>
        )}
      </div>
    )
    return (
      <CompanyConfigModal
        open={this.props.open}
        toggle={this.props.children}
        header={'Product Editor - ' + this.props.path}
        body={body}
        level={this.props.level}
        update={() => {
          const p = JSON.parse(JSON.stringify(this.state.product))
          delete p.newProduct
          this.props.onUpdate(p)
        }}
        /* cancel={() => {
          this.handleShowProductEditor();
        }} */
      />
    )
  }
}

class SingleProductConfigEditor extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      product: JSON.parse(JSON.stringify(this.props.product))
    }
    this.handleChangeProduct = this.handleChangeProduct.bind(this)
    this.handleChangeChoice = this.handleChangeChoice.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.handleMove = this.handleMove.bind(this)
  }

  handleChangeProduct = (product) => {
    const p = JSON.parse(JSON.stringify(this.state.product))
    p.product = [product.value]
    this.setState({ product: p })
  }

  handleChangeChoice = (event) => {
    const p = JSON.parse(JSON.stringify(this.state.product))
    p.configuration[0][event.target.name] = event.target.value
    this.setState({ product: p })
  }

  handleChange = (event) => {
    const p = JSON.parse(JSON.stringify(this.state.product))
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    if (event.target.name === 'use_configuration') {
      if (event.target.checked) {
        if ('configuration_old' in p) {
          p.configuration = JSON.parse(JSON.stringify(p.configuration_old))
          delete p.configuration_old
        }
        if (!('configuration' in p)) {
          p.configuration = [
            {
              id: '',
              title: '',
              type: 'choice',
              choices: []
            }
          ]
        }
      } else {
        if ('configuration' in p) {
          p.configuration_old = JSON.parse(JSON.stringify(p.configuration))
          delete p.configuration
        }
      }
    }
    p[event.target.name] = value
    this.setState({ product: p })
  }

  handleUpdate = (ind, c) => {
    const p = JSON.parse(JSON.stringify(this.state.product))
    p.configuration[0].choices[ind] = JSON.parse(JSON.stringify(c))
    this.setState({ product: p })
  }

  handleMove = (ind, c, direction) => {
    const p = JSON.parse(JSON.stringify(this.state.product))
    if (direction === 'up') {
      if (p.configuration[0].choices[ind - 1]) {
        // let clonedArr = JSON.parse(JSON.stringify(p.configuration[0].choices))
        const toSwitch = JSON.parse(JSON.stringify(p.configuration[0].choices[ind]))
        const switchWith = JSON.parse(JSON.stringify(p.configuration[0].choices[ind - 1]))
        p.configuration[0].choices[ind - 1] = toSwitch
        p.configuration[0].choices[ind] = switchWith
        const cRef = this
        this.setState({ product: p, option: false }, function () {
          cRef.props.onUpdate(p)
        })
      }
    } else if (direction === 'down') {
      if (p.configuration[0].choices[ind + 1]) {
        // let clonedArr = JSON.parse(JSON.stringify(p.configuration[0].choices))
        const toSwitch = JSON.parse(JSON.stringify(p.configuration[0].choices[ind]))
        const switchWith = JSON.parse(JSON.stringify(p.configuration[0].choices[ind + 1]))
        p.configuration[0].choices[ind + 1] = toSwitch
        p.configuration[0].choices[ind] = switchWith
        const cRef = this
        this.setState({ product: p, option: false }, function () {
          cRef.props.onUpdate(p)
        })
      }
    }
    // p.configuration[0].choices[ind] = JSON.parse(JSON.stringify(c));
    // this.setState({product: p});
  }

  handleDelete = (ind) => {
    const p = JSON.parse(JSON.stringify(this.state.product))
    p.configuration[0].choices.splice(ind, 1)
    this.setState({ product: p })
  }

  handleAdd = () => {
    const p = JSON.parse(JSON.stringify(this.state.product))
    p.configuration[0].choices.push({
      newProduct: true,
      id: this.props.guid()
    })
    this.setState({ product: p })
  }

  getProductName (id) {
    const configProduct = this.props.productsList.filter((arr) => {
      return arr.value === id
    })
    if (configProduct.length !== 0) return configProduct[0].name
    return 'Missing Product'
  }

  getSelectedProductObject () {
    if (this.state.product && this.state.product.product) {
      return this.props.productsList.find((o) => o.value === this.state.product.product[0])
    }
  }

  render () {
    if (!this.props.productsList) {
      return <LoadingPleaseWait />
    }

    const body = (
      <div>
        <div className='col-sm-12 form-group row' style={{ display: 'none' }}>
          <label>ID</label>
          <div className='col-sm-10'>
            <input type='text' name='id' className='form-control' value={this.state.product.id} onChange={this.handleChange} />
          </div>
        </div>
        <div className='col-sm-12 form-group row'>
          <label>Title</label>
          <div className='col-sm-10'>
            <input type='text' name='title' className='form-control' value={this.state.product.title} onChange={this.handleChange} />
          </div>
        </div>
        <div className='col-sm-12 form-group row'>
          <label>Configuration?</label>
          <div className='col-sm-10'>
            <label className='slider--wrapper'>
              <input
                type='checkbox'
                name='use_configuration'
                className='form-control'
                checked={'configuration' in this.state.product}
                onChange={this.handleChange}
              />
              <span className='slider__circle' />
            </label>
          </div>
        </div>
        {/* eslint-disable-next-line multiline-ternary */}
        {'configuration' in this.state.product ? (
          <>
            <div className='col-sm-12 form-group row'>
              <label>Option Title</label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='title'
                  className='form-control'
                  value={this.state.product.configuration[0].title}
                  onChange={this.handleChangeChoice}
                />
              </div>
            </div>
            <div className='actionsTable__wrapper'>
              <div className='actionsTable__options'>
                <Button id='asset-action' className='mui-btn' color='success' variant='contained' onClick={this.handleAdd} startIcon={<AddIcon />}>
                  Add
                </Button>
              </div>
              <div id='prodhidden'>
                <table className='actionsTable'>
                  <thead>
                    <tr className='actionsTable__header'>
                      <th className='actionsTable__label'>Title</th>
                      <th className='actionsTable__label'>Type</th>
                      <th className='actionsTable__label'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.product.configuration[0].choices.map((p, idx) => {
                      const visible = true
                      const display = visible ? 'table-row' : 'none'
                      // console.log("configuration")
                      // console.log(p)
                      return (
                        <tr key={idx + ':' + p.id} className='actionsTable__row' style={{ display: `${display}` }}>
                          <td className='actionsTable__title'>
                            <span>{p.title}</span>
                          </td>
                          <td className='actionsTable__title'>
                            <span>
                              {'related' in p ? 'Multiple Options' : 'product' in p ? 'Product: ' + this.getProductName(p.product[0]) : 'Single Product'}
                            </span>
                          </td>
                          <td className='actionsTable__actions'>
                            <SingleProductRelatedEditor
                              productsList={this.props.productsList}
                              product={p}
                              onUpdate={(p) => this.handleUpdate(idx, p)}
                              open={'newProduct' in p}
                              level={0}
                              guid={this.props.guid}
                              path={this.state.product.title}
                            >
                              <IconButton title='Edit' aria-label='edit'>
                                <EditIcon variant='icon' fontSize='small' />
                              </IconButton>
                            </SingleProductRelatedEditor>
                            <IconButton
                              title='Move Up'
                              aria-label='delete'
                              onClick={() => {
                                this.handleMove(idx, p, 'up')
                              }}
                            >
                              <UpIcon variant='icon' fontSize='small' />
                            </IconButton>
                            <IconButton
                              title='Move Down'
                              aria-label='delete'
                              onClick={() => {
                                this.handleMove(idx, p, 'down')
                              }}
                            >
                              <DownIcon variant='icon' fontSize='small' />
                            </IconButton>
                            <IconButton
                              title='Delete'
                              aria-label='delete'
                              onClick={() => {
                                this.handleDelete(idx)
                              }}
                            >
                              <DeleteIcon variant='icon' fontSize='small' />
                            </IconButton>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : (
          <div className='col-sm-12 form-group row'>
            <label>Product</label>
            <div className='col-sm-10'>
              <SelectSearch
                name='select-product'
                value={this.getSelectedProductObject()}
                options={this.props.productsList}
                onChange={this.handleChangeProduct}
                placeholder='Please Select the Product'
              />
            </div>
          </div>
        )}
      </div>
    )
    return (
      <CompanyConfigModal
        open={this.props.open}
        toggle={this.props.children}
        header='Product Editor'
        body={body}
        level={this.props.level}
        cancel={() => {
          this.props.onCancel()
        }}
        update={() => {
          const p = JSON.parse(JSON.stringify(this.state.product))
          delete p.newProduct
          this.props.onUpdate(p)
        }}
      />
    )
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} editorComponent={ProductConfigEditor} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}
