import React, { useEffect, useState } from 'react'
import { Button, Box, Grid, IconButton, Stack, TextField, Typography } from '@mui/material'
import DataGrid from 'components/Reusable/DataGrid'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import Helper from '@supportworks/helper'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Api from '../../library/Api' // defined in CompanyConfigEdit
import CompanyConfigEdit from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import { DialogAssetSelector } from 'components/Reusable/DialogAssetSelector'
import { LoadingPleaseWait } from '@supportworks/react-components'

const config = {
  title: 'About Us',
  variant: 'AboutUs',
  tag: 'aboutus',
  name: 'About Us',
  lockTag: true,
  lockName: false
}

const CompanyConfigAboutUs = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState([])
  const [currentConfig, setCurrentConfig] = useState({})

  useEffect(() => {
    if (props.config && props.config.contentJson) {
      Api.callRegisteredMethod('getCurrentConfig', { tag: 'all', startsWith: false }).then((currentConfig) => {
        // Fix the contentJson.
        const cj = JSON.parse(props.config.contentJson)
        if (!Array.isArray(cj)) {
          props.config.contentJson = '[]'
        }
        const l = JSON.parse(props.config.contentJson)
        let c = l[0] || {}
        if (!l.length) {
          c = {
            title: props.config.name
          }
        }
        if (!c.id) c.id = Helper.guid()
        if (!c.images) c.images = []
        if (!c.text || !Array.isArray(c.text)) c.text = []

        l[0] = c
        setList(l)
        props.onChange(JSON.stringify(l))
        setCurrentConfig(currentConfig)
        setIsLoading(false)
      })
    }
    // eslint-disable-next-line
  }, [])

  // DEV-3862 - set title to the config name if it changes...
  //
  useEffect(() => {
    const l = JSON.parse(props.config.contentJson)
    if (l && l.length) {
      if (l[0].title !== props.config.name) {
        l[0].title = props.config.name
        setList(l)
        props.onChange(JSON.stringify(l))
      }
    }
    // eslint-disable-next-line
  }, [props.config.name])

  const handleChange = (variant, value) => {
    const l = JSON.parse(JSON.stringify(list))
    if (variant.match(/images|text/)) {
      l[0][variant] = value
    }
    setList(l)
    if (props.onChange) {
      props.onChange(JSON.stringify(l))
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <Box sx={{ m: 0 }}>
      <ImageList {...props} list={list[0].images} assets={currentConfig.assets2} onChange={handleChange} />
      <HighlightList {...props} list={list[0].text} onChange={handleChange} />
    </Box>
  )
}

const ImageList = (props) => {
  const [pageSize, setPageSize] = useState(10)
  const [list, setList] = useState([])
  const [showEditDialog, setShowEditDialog] = useState(false)

  useEffect(() => {
    setList(props.list)
    // eslint-disable-next-line
  }, [])

  const handleAdd = (l) => {
    handleChange(l)
  }

  const handleChange = (l, field) => {
    const c = JSON.parse(JSON.stringify(l))
    // Prevent overriding title on existing items
    if (!field) {
      for (let x = 0; x < c.length; x++) {
        const idx = list.findIndex((o) => o.asset === c[x].asset)
        if (idx > -1) {
          c[x].title = list[idx].title
        }
      }
    }
    setList(c)
    props.onChange('images', c)
  }

  const handleDelete = (idx) => {
    const r = window.confirm('Are you sure you want to delete?')
    if (r) {
      const l = JSON.parse(JSON.stringify(list))
      l.splice(idx, 1)
      handleChange(l)
    }
  }

  const handleMoveUp = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx - 1, 0, ele)
    handleChange(l)
  }

  const handleMoveDown = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx + 1, 0, ele)
    handleChange(l)
  }

  const handleProcessRowUpdate = (row) => {
    if (row) {
      const l = JSON.parse(JSON.stringify(list))
      l[row.id].title = row.quantity
      setList(l)
      handleChange(l)
      return row
    }
  }

  const columns = [
    { field: 'id', headerName: 'Id', hide: true },
    { field: 'type', headerName: 'Type' },
    {
      field: 'previewURL',
      headerName: 'Preview',
      renderCell: (params) => {
        if (params.row.previewURL) {
          return (
            <a href={params.row.path} target='_blank' rel='noreferrer'>
              <img src={params.row.previewURL} alt={params.row.title} width='100%' />
            </a>
          )
        }
        return null
      }
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      editable: true,
      sortable: false,
      renderCell: (params) => {
        return <div>{params.row.title}</div>
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id)}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton size='small' disabled={!(params.row.id < list.length - 1)} onClick={() => handleMoveDown(params.row.id)}>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < list.length; x++) {
    const a = props.assets.find((o) => o.id === list[x].asset)
    if (a && a.path) {
      rows.push({
        id: x,
        type: a.type ? a.type : '-',
        previewURL: a.previewURL ? a.previewURL : null,
        path: a.path ? a.path : null,
        title: list[x].title,
        actions: ''
      })
    } else {
      console.log(`missing asset: ${list[x].asset}`)
    }
  }

  return (
    <>
      <Box sx={{ pb: 2 }}>
        <Stack sx={{ pb: 1 }}>
          <Typography variant='h6'>Assets</Typography>
          <Typography variant='body2'>Use the interface below to maintain image and video asset titles and ordering. Edit existing titles by double clicking on the cell.</Typography>
        </Stack>
        <Box>
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            pagination
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 25, 50]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onProcessRowUpdate={(row) => {
              handleProcessRowUpdate(row)
            }}
          />
        </Box>
        <Box sx={{ pt: 2 }}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setShowEditDialog(!showEditDialog)
            }}
          >
            Add Asset
          </Button>
        </Box>
      </Box>
      {showEditDialog
        ? (
          <DialogAssetSelector
            title='Select Assets'
            list={list}
            assets={props.assets}
            filterOverride={['Image', 'Video']}
            onSave={(l) => {
              handleAdd(l)
              setShowEditDialog(!showEditDialog)
            }}
            onClose={() => setShowEditDialog(!showEditDialog)}
          />
          )
        : null}
    </>
  )
}

const HighlightList = (props) => {
  const [pageSize, setPageSize] = useState(10)
  const [list, setList] = useState([])
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [selectedIdx, setSelectedIdx] = useState(-1)

  useEffect(() => {
    setList(props.list)
    // eslint-disable-next-line
  }, [])

  const handleAdd = (title, text) => {
    const l = JSON.parse(JSON.stringify(list))
    l.push({
      id: Helper.guid(),
      title,
      text
    })
    handleChange(l)
  }

  const handleChange = (l) => {
    const c = JSON.parse(JSON.stringify(l))
    setList(c)
    props.onChange('text', c)
  }

  const handleDelete = (idx) => {
    const r = window.confirm('Are you sure you want to delete?')
    if (r) {
      const l = JSON.parse(JSON.stringify(list))
      l.splice(idx, 1)
      handleChange(l)
    }
  }

  const handleMoveUp = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx - 1, 0, ele)
    handleChange(l)
  }

  const handleMoveDown = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx + 1, 0, ele)
    handleChange(l)
  }

  const handleProcessRowUpdate = (row) => {
    if (row) {
      const l = JSON.parse(JSON.stringify(list))
      l[row.id].title = row.title
      l[row.id].text = row.text
      setList(l)
      handleChange(l)
      return row
    }
  }

  const columns = [
    { field: 'id', headerName: 'Id', hide: true },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      editable: true,
      sortable: false
    },
    {
      field: 'text',
      headerName: 'Text',
      flex: 3,
      editable: true,
      sortable: false
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id)}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton size='small' disabled={!(params.row.id < list.length - 1)} onClick={() => handleMoveDown(params.row.id)}>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < list.length; x++) {
    rows.push({
      id: x,
      title: list[x].title,
      text: list[x].text,
      actions: ''
    })
  }

  return (
    <>
      <Box>
        <Stack sx={{ pb: 1 }}>
          <Typography variant='h6'>Company Highlights</Typography>
          <Typography variant='body2'>Use the interface below to maintain titles and text. Edit existing information by clicking on the cell.</Typography>
        </Stack>
        <Box>
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            pagination
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 25, 50]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onProcessRowUpdate={(row) => {
              handleProcessRowUpdate(row)
            }}
          />
        </Box>
        <Box sx={{ pt: 2 }}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setShowEditDialog(!showEditDialog)
            }}
          >
            Add Highlight
          </Button>
        </Box>
      </Box>

      {showEditDialog
        ? (
          <DialogEditHighlight
            variant={selectedIdx > -1 ? 'edit' : 'new'}
            title={selectedIdx > -1 ? list[selectedIdx].title : null}
            text={selectedIdx > -1 ? list[selectedIdx].text : null}
            onSave={(title, text) => {
              if (selectedIdx > -1) {
                list[selectedIdx].title = title
                list[selectedIdx].text = text
                handleChange(list)
              } else {
                handleAdd(title, text)
              }
              setSelectedIdx(-1)
              setShowEditDialog(!showEditDialog)
            }}
            onClose={() => {
              setSelectedIdx(-1)
              setShowEditDialog(!showEditDialog)
            }}
          />
          )
        : null}
    </>
  )
}

const DialogEditHighlight = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [title, setTitle] = useState(null)
  const [text, setText] = useState(null)

  useEffect(() => {
    if (isLoading) {
      setTitle(props.title)
      setText(props.text)
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleSave = () => {
    props.onSave(title, text)
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'title') {
      setTitle(value)
    } else if (name === 'text') {
      setText(value)
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  const jsx = (
    <Grid container>
      <Grid item xs={12} sx={{ pt: 2 }}>
        <TextField fullWidth id='title' name='title' variant='outlined' label='Title' helperText='Please enter the title.' value={title || ''} onChange={handleChange} />
      </Grid>
      <Grid item xs={12} sx={{ pt: 2 }}>
        <TextField fullWidth id='text' name='text' variant='outlined' multiline rows={8} label='Highlight Description' helperText='Please enter the highlight description.' value={text || ''} onChange={handleChange} />
      </Grid>
    </Grid>
  )

  return <DialogGeneric title={props.variant === 'edit' ? 'Edit Highlight' : 'Add New Highlight'} content={jsx} fullWidth onChange={handleSave} onClose={props.onClose} onDelete={props.onDelete ? props.onDelete : null} />
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} editorComponent={CompanyConfigAboutUs} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}
