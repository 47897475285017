import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DataGrid from 'components/Reusable/DataGrid'
import { GridToolbarContainer, GridToolbarQuickFilter, DataGrid as MuiDataGridX } from '@mui/x-data-grid'
import Company from 'screens/Company'
import Api from 'library/Api'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import Permissions from 'library/Permissions'
import withScreenWrapper from '../withScreenWrapper'
import { LoadingPleaseWait } from '@supportworks/react-components'
import UserEdit from './UserEdit'

const UsersIndex = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [persons, setPersons] = useState(null)
  const [crm, setCrm] = useState(null)
  const [authManifest, setAuthManifest] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(-1)

  const [showUserEdit, setShowUserEdit] = useState(false)
  const [showDialogCRM, setShowDialogCRM] = useState(false)
  const [crmUser, setCRMUser] = useState({})
  const [permissionDefaults, setPermissionDefaults] = useState()

  const refresh = () => {
    Api.callRegisteredMethod('getAuthorizationManifest').then((au) => {
      Api.callRegisteredMethod('getUsers').then((users) => {
        const permManifest = au.permissionManifest
        if ('see_all_company_calendar_users' in permManifest) {
          delete permManifest.see_all_company_calendar_users
        }
        au.permissionManifest = permManifest
        setAuthManifest(au)

        setPersons(users && users.persons ? users.persons : [])
        if (users && users.default) {
          setPermissionDefaults(users.default)
        }
        if (users && users.crm === undefined) {
          setCrm(null) // crm is NOT in the response
        } else if (users && users.crm !== undefined) {
          setCrm(users.crm)
        }
        setIsLoading(false)
      })
    })
  }

  useState(() => {
    if (isLoading) {
      refresh()
    }
  }, [])

  const handleSave = (user) => {
    const p = JSON.parse(JSON.stringify(persons))
    if (selectedIndex > -1) {
      p[selectedIndex] = user
    } else {
      // Fake out the stuff on the screen so we don't have to reload
      p.push(user)
      const idx = crm.findIndex((o) => o.userName === user.person.username)
      if (idx > -1) {
        const c = JSON.parse(JSON.stringify(crm))
        c.splice(idx, 1)
        setCrm(c)
      }
    }
    setPersons(p)
    setSelectedIndex(-1)
    setShowUserEdit(!showUserEdit)
    setIsLoading(true)
    refresh()
  }

  const handleCancel = () => {
    setSelectedIndex(-1)
  }

  const handleShowEdit = (idx) => {
    setSelectedIndex(idx)
    setShowUserEdit(!showUserEdit)
  }

  const handleShowCRMDialog = () => {
    setSelectedIndex(-1)
    setShowDialogCRM(!showDialogCRM)
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    {
      flex: 1,
      type: 'string',
      field: 'firstName',
      headerName: 'First Name',
      editable: false,
      sortable: true,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <Box>{params.value}</Box>
          </Tooltip>
        )
      }
    },
    {
      flex: 1,
      type: 'string',
      field: 'lastName',
      headerName: 'Last Name',
      editable: false,
      sortable: true,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <Box>{params.value}</Box>
          </Tooltip>
        )
      }
    },
    {
      flex: 1,
      type: 'string',
      field: 'email',
      headerName: 'Username',
      editable: false,
      sortable: true,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <Box>{params.value}</Box>
          </Tooltip>
        )
      }
    },
    {
      flex: 1,
      type: 'string',
      field: 'roles',
      headerName: 'Roles',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <Box>{params.value}</Box>
          </Tooltip>
        )
      }
    },
    {
      flex: 1,
      field: 'action',
      headerName: 'Actions',
      headerAlign: 'right',
      align: 'right',
      editable: false,
      sortable: false,
      disableExport: true,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton title='Edit' aria-label='edit' onClick={() => handleShowEdit(params.row.id)}>
                  <EditIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = persons.map((u, idx) => {
    return {
      id: idx,
      email: u.person.email,
      roles: u.authorizations.rippleAdmin && u.authorizations.rippleAdmin.roles ? formatRoles(u.authorizations.rippleAdmin.roles, authManifest) : null,
      lastName: u.person.nameLast,
      firstName: u.person.nameFirst
    }
  })

  return (
    <Box>
      {showUserEdit
        ? (
          <>
            <Company.Header title='User Edit' />
            <UserEdit
              {...props}
              authManifest={authManifest}
              users={persons}
              selectedIndex={selectedIndex}
              crmUser={crmUser}
              permissionDefaults={permissionDefaults}
              onSave={handleSave}
              onCancel={() => {
                handleShowEdit(-1)
                handleCancel()
              }}
            />
          </>
          )
        : (
          <>
            <Company.Header title='Users' />
            <DataGrid
              rows={rows}
              columns={columns}
            />

            {Permissions.hasPermission('add_users') && crm && crm.length === 0 && Permissions.canCreateUser()
              ? (
                <Box sx={{ pt: 2 }}>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => {
                      handleShowEdit(-1)
                    }}
                  >
                    Add User
                  </Button>
                </Box>
                )
              : null}

            {Permissions.hasPermission('add_users') && crm && crm.length > 0
              ? (
                <Grid container sx={{ pt: 2 }}>
                  <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <Button color='primary' variant='contained' onClick={handleShowCRMDialog}>
                      Add CRM User
                    </Button>
                    <Typography variant='body2' sx={{ pl: 1 }}>
                      There are {crm.length} users to add from your CRM.
                    </Typography>
                  </Grid>
                </Grid>
                )
              : null}
          </>
          )}

      {showDialogCRM && crm && crm.length
        ? (
          <DialogCRMUser
            title='Select CRM User to Add'
            list={crm}
            onSave={(obj) => {
              if (obj && obj.userName) {
                setCRMUser(obj)
                handleShowEdit(-1)
              }
              setShowDialogCRM(!showDialogCRM)
            }}
            onClose={() => {
              setShowDialogCRM(!showDialogCRM)
            }}
          />
          )
        : null}
    </Box>
  )
}
export default withScreenWrapper(UsersIndex)

const DialogCRMUser = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [pageSize, setPageSize] = useState(10)
  const [rows, setRows] = useState([]) // Array of row objects that can be selected
  const [selectedRows, setSelectedRows] = useState([]) // Array of row idx's of things that have been checked

  useEffect(() => {
    if (isLoading) {
      const sr = []
      const r = []
      for (let x = 0; x < props.list.length; x++) {
        r.push({
          id: x,
          lastName: props.list[x].lastName,
          firstName: props.list[x].firstName,
          userName: props.list[x].userName
        })
      }
      setRows(r)
      setSelectedRows(sr)
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleSave = () => {
    // Should only have 1 row selected.
    if (selectedRows.length) {
      const r = rows[selectedRows[0]]
      props.onSave(r)
    }
  }

  const handleChange = (r) => {
    const f = r.filter((x) => !selectedRows.includes(x))
    setSelectedRows(f)
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    { field: 'id', headerName: 'Id' },
    { field: 'firstName', headerName: 'First Name', flex: 1 },
    { field: 'lastName', headerName: 'Last Name', flex: 1 },
    { field: 'userName', headerName: 'User Name / E-mail', flex: 1 }
  ]

  const showSearch = () => {
    return (
      <GridToolbarContainer>
        <Grid container sx={{ p: 1 }}>
          <Grid item>
            <GridToolbarQuickFilter size='small' variant='outlined' />
          </Grid>
        </Grid>
      </GridToolbarContainer>
    )
  }

  const jsx = (
    <Box sx={{ height: 500 }}>
      <MuiDataGridX
        rows={rows}
        columns={columns}
        pagination
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 25, 50]}
        checkboxSelection
        rowSelectionModel={selectedRows}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onRowSelectionModelChange={(rows) => {
          handleChange(rows)
        }}
        keepNonExistentRowsSelected
        sx={{
          '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
            color: '#A6B0CF',
            backgroundColor: 'transparent',
            display: props.singleUseOnly ? 'none' : ''
          }
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false
            }
          },
          sorting: {
            sortModel: [{ field: 'lastName', sort: 'asc' }]
          }
        }}
        slots={{ toolbar: showSearch }}
      />
    </Box>
  )

  return (
    <DialogGeneric title={props.title} titleDone='Add' titleClose='Cancel' content={jsx} fullWidth onChange={handleSave} onClose={props.onClose} />
  )
}

const formatRoles = (roles, authManifest) => {
  let s = ''
  let prefix = ''
  Object.values(authManifest.roleManifest).map((role, idx) => {
    if (roles && roles[role.key] && role.label !== '') {
      s += prefix + role.label
      prefix = ', '
    }
    return s
  })
  return s
}
