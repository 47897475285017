import React, { useMemo, useCallback } from 'react'
import { Box, Grid } from '@mui/material'
import { Circle, Line } from 'rc-progress'
import Uploady, { useRequestPreSend, useItemProgressListener, useBatchProgressListener, useAbortItem, UPLOADER_EVENTS } from '@rpldy/uploady'
import UploadDropZone from '@rpldy/upload-drop-zone'
import UploadButton from '@rpldy/upload-button'
import retryEnhancer from '@rpldy/retry-hooks'
import CloseIcon from '@mui/icons-material/Close'
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MovieIcon from '@mui/icons-material/Movie'
import ImageIcon from '@mui/icons-material/Image'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import AttachmentIcon from '@mui/icons-material/Attachment'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import IconHover from '../Reusable/IconHover' // Named IconHover instead of HoverIcon because all of our other internal stuff starts with Icon...
import { ApiBase } from '../../library/Api'
import { Topics } from 'config/topics'
import './styles.css'

export class UploadOverlay extends React.Component {
  static topic

  /**
   * @type {Object.<string, Subscription>}
   */
  subs

  constructor (props) {
    super(props)

    this._isMounted = false

    this.state = {
      visible: !!this.props.visible,
      maxDuration: this.props.maxDuration ? this.props.maxDuration : 30,
      messages: [],
      calls: 0,

      minimized: props.minimized ? props.minimized : false,
      files: [], // Completed file data
      batchStartTime: null,
      batchData: [], // Current batch data
      fileStats: {},
      completed: 0
    }

    UploadOverlay.topic = this.props.topic

    this.processIncomingMessage = this.processIncomingMessage.bind(this)
    this.messageListener = this.messageListener.bind(this)

    this.onHandleUpload = this.onHandleUpload.bind(this)
    this.onHandleUpdateFileStatus = this.onHandleUpdateFileStatus.bind(this)
    this.handleMinimize = this.handleMinimize.bind(this)
    this.handleCancel = this.handleCancel.bind(this)

    if (props.message) {
      this.messageListener(props.message)
    }

    this.subs = {
      status: Topics.subscribe(this.props.topic, this.messageListener.bind(this))
    }
  }

  get isMounted () {
    return this._isMounted
  }

  /**
   * Unsubscribes from all subscriptions.
   */
  componentWillUnmount () {
    for (const sub in this.subs) {
      this.subs[sub].unsubscribe()
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  handleCancel () {
    let cancel = true
    if (this.state.fileStats && this.state.fileStats.percentCompleted < 1) {
      const r = window.confirm('This will cancel any pending uploads. Are you sure you close this window?')
      if (!r) cancel = false
    }
    if (cancel) {
      this.setState(
        {
          visible: false,
          completed: 0,
          batchStartTime: null,
          batchData: [],
          fileStats: {},
          minimized: false
        },
        () => {
          // console.log('visible set to ' + this.state.visible)
        }
      )
    }
  }

  handleMinimize () {
    this.setState(
      {
        minimized: !this.state.minimized
      },
      () => {
        // console.log('minimized set to ' + this.state.minimized)
      }
    )
  }

  onHandleUpdateFileStatus (batchData) {
    const completed = batchData.completed
    if (completed !== this.state.completed) {
      // loop thru my batchData, get file size and file counts for non aborted files
      const fileStats = { bytesTotal: 0, bytesCompleted: 0, cnt: 0, finished: 0, pending: 0, total: 0 }
      batchData.items.forEach((item) => {
        if (item.state !== 'aborted') {
          fileStats.bytesCompleted = fileStats.bytesCompleted + item.loaded
          fileStats.bytesTotal = fileStats.bytesTotal + item.file.size
          fileStats.cnt++
          if (item.state.match(/add|upload/)) {
            fileStats.pending++
          } else {
            fileStats.finished++
          }
          fileStats.total++
        } else {
          // console.log("Skipping aborted item...");
        }
      })
      fileStats.percentCompleted = fileStats.bytesCompleted / fileStats.bytesTotal
      this.setState({
        completed,
        batchData,
        fileStats
      })
    }
  }

  async onHandleUpload (action, result) {
    /*
                __      ___   ___ _  _ ___ _  _  ___
                \ \    / /_\ | _ \ \| |_ _| \| |/ __|
                 \ \/\/ / _ \|   / .` || || .` | (_ |
                  \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___|

        If you are in localhost dev, and getting a NaN error or your upload is not working, look in the storage_engine table!!!
        What most likely happened is you copied the test database over to localhost, and it has the test bucket, access key, and secret.
        You want the DEV values.
        */

    // The stuff below is mostly for showing results stuff on the screen DEBUGGING, since in the component on "finish" we fire off another API hit...
    switch (action) {
      case 'batchStart':
        // console.log(`BATCH START ` + JSON.stringify(result));
        this.setState({ batchStartTime: new Date() })
        break
      case 'batchFinish':
        // console.log(`BATCH FINISH ` + JSON.stringify(result));
        // Emergency override!  There's a glitch in uploady (?) that if you click lots of X's during an upload bad things happen.
        this.setState({ completed: 100 })
        break
      case 'start':
        // console.log(`ITEM START ` + JSON.stringify(result));
        break
      case 'finish':
      {
        // console.log(`Upload finish ` + JSON.stringify(result));
        // Notify Core API that this file is done uploading, using the guid...
        const meta = this.state.files[result.id]
        const n = meta.path.lastIndexOf('/')
        const guid = meta.path.substring(n + 1)
        // let t = meta.path.substring(n+1);
        // let [guid, junk] = t.split(/\./);

        await ApiBase.callRegisteredMethod('birthMediaLibraryUsingGUID', { guid })
        // Note that an event could be placed on the AWS bucket to do this Lambda execution automatically.
        const arr = meta.path.split('/')
        const key = arr.slice(2, arr.length).join('/')
        if (meta.type.match(/.mp4/)) {
          await ApiBase.callRegisteredMethod('invokeCloudMediaProcessor', { key, size: 100, seconds: 5 })
        } else {
          await ApiBase.callRegisteredMethod('invokeCloudMediaProcessor', { key, size: 100 })
        }
        meta.guid = guid.split('.')[0]
        meta.key = key
        Topics.publish('upload-event', meta)
        break
      }
      case 'preSignedUrl':
      {
        // console.log(`GET preSignedURL ` + JSON.stringify(result));
        // Add to a files array using the batch ID, so when it finishes we can match the finish result with the file meta.
        const files = this.state.files
        files[result.id] = result.meta
        this.setState({ files })
        break
      }
      default:
        console.log(`Unable to process ${action} ${JSON.stringify(result)}`)
        break
    }
  }

  /**
   * Normalizes message and adds it to the queue.
   *
   * @param message
   */
  processIncomingMessage (message) {
    if (typeof message === 'string') {
      message = { message }
    }
    this.setState({ visible: !this.state.visible, completed: 0, batchData: [], minimized: false }, () => {
      // console.log('visible set to ' + this.state.visible)
    })
    this.state.messages.push(message)
  }

  /**
   * Listens for messages to display.
   *
   * @param data
   */
  messageListener (data) {
    if (data) {
      let message = data
      if (message.topic === UploadOverlay.topic) {
        message = data.data
      }
      if (Array.isArray(message)) {
        // array of message objects or strings
        message.map(this.processIncomingMessage)
      } else {
        if (Object.prototype.hasOwnProperty.call(message, 'clear')) {
          this.setState({ messages: [] })
        } else {
          this.processIncomingMessage(message)
        }
      }
    }

    if (this.isMounted === true) {
      let calls = this.state.calls
      this.setState({ calls: calls++ })
    }
  }

  /**
   * Used to trigger the upload window to display from an external class.
   */
  static message (message) {
    const data = { message }
    Topics.publish(this.topic, data)
  }

  render () {
    // Figure out some display text
    let overallPercentCompleted = 0
    let timeLeft = null
    const fileStats = this.state.fileStats
    if (this.state.batchData && this.state.batchData.items) {
      const nowTime = new Date()
      let timeDiff = nowTime - this.state.batchStartTime // in ms
      timeDiff /= 1000
      const seconds = Math.round(timeDiff)
      const bytesPerSecond = fileStats.bytesCompleted / seconds
      const bytesLeft = fileStats.bytesTotal - fileStats.bytesCompleted
      let secondsLeft = parseFloat(bytesLeft / bytesPerSecond).toFixed(0)
      let minutesLeft = 0
      if (secondsLeft > 60) {
        minutesLeft = Math.floor(secondsLeft / 60)
        secondsLeft = secondsLeft - minutesLeft * 60
      }
      timeLeft = `${minutesLeft} min ${secondsLeft} sec`
      overallPercentCompleted = (fileStats.bytesCompleted / fileStats.bytesTotal) * 100
    }
    // Emergency override!  There's a glitch in uploady (?) that if you click lots of X's during an upload bad things happen.
    if (this.state.completed === 100) {
      overallPercentCompleted = 100
      timeLeft = '0 sec'
    }
    const perc = parseFloat(overallPercentCompleted).toFixed(0) + '%'

    // Figure out titleBarText and titleBarButtons stuff
    let titleBarText = null
    const titleBarButtons = (
      <Box display='flex' flexDirection='row' justifyContent='flex-end' alignItems='center'>
        <Grid container justifyContent='flex-end'>
          <Grid item sx={{ pr: 1 }}>
            <IconHover
              id={this.state.minimized ? 'ExpandMoreIcon' : 'ExpandLessIcon'}
              onClick={this.handleMinimize}
              icon={this.state.minimized ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            />
          </Grid>
          <Grid item>
            <IconHover
              id='CloseIcon'
              onClick={() => {
                this.handleCancel()
              }}
              icon={<CloseIcon />}
            />
          </Grid>
        </Grid>
      </Box>
    )
    if (overallPercentCompleted > 0 && overallPercentCompleted < 100) {
      titleBarText = (
        <>
          <span className='font-m-b'>
            Uploading {fileStats.finished + 1}/{fileStats.total} Assets
          </span>
          <br />
          <span className='font-xs-n'>
            {formatBytes(fileStats.bytesTotal, 2)} - {perc} Complete ({timeLeft} left)
          </span>
        </>
      )
    } else if (overallPercentCompleted >= 100) {
      titleBarText = (
        <>
          <CheckCircleIcon style={{ color: 'green', fontSize: 'medium', verticalAlign: 'middle', marginBottom: '2px', marginRight: '6px' }} />
          <span className='font-m-b'>
            {fileStats.total}/{fileStats.total} Assets Uploaded
          </span>
          <br />
          <span className='font-xs-n'>
            {formatBytes(fileStats.bytesTotal, 2)} - {perc} Complete
          </span>
        </>
      )
    } else if (overallPercentCompleted === 0) {
      titleBarText = (
        <span className='font-l-b' style={{ paddingLeft: '12px' }}>
          Upload Assets
        </span>
      )
    }

    return (
      <>
        {this.state.visible
          ? (
            <Box className={this.state.minimized ? null : 'modal-backdrop'}>
              <Box className={`${overallPercentCompleted === 0 && !this.state.minimized ? 'upload-overlay upload-overlay-dropzone' : 'upload-overlay'}`}>
                <Box className='upload-overlay-content-container'>
                  <Box className='upload-overlay-top-header'>
                    <Box className='upload-overlay-header-title-bar-content'>{titleBarText}</Box>
                    <Box className='upload-overlay-header-title-bar-buttons'>{titleBarButtons}</Box>
                  </Box>
                  <Box className='upload-overlay-bottom-header'>
                    <Box className='upload-overlay-header-percent'>
                      {overallPercentCompleted
                        ? (
                          <Line
                            className={this.state.minimized ? 'progress-line-minimized float-left' : 'progress-line float-left'}
                            strokeWidth='1'
                            strokeLinecap='square'
                            strokeColor={overallPercentCompleted >= 100 ? '#00a626' : '#00F'}
                            percent={overallPercentCompleted}
                          />
                          )
                        : null}
                    </Box>
                  </Box>
                  <Box className={this.state.minimized ? 'hidden' : 'upload-overlay-content'}>
                    <UploadPreSignedAWS handleUpload={this.onHandleUpload} handleUpdateFileStatus={this.onHandleUpdateFileStatus} {...this.state} />
                  </Box>
                </Box>
              </Box>
            </Box>
            )
          : null}
      </>
    )
  }
}
export default UploadOverlay

function UploadPreSignedAWS (props) {
  const listeners = useMemo(
    () => ({
      [UPLOADER_EVENTS.BATCH_START]: (batch) => {
        // console.log(`Batch Start - ${batch.id} - item count = ${batch.items.length}`);
        props.handleUpload('batchStart', batch)
      },
      [UPLOADER_EVENTS.BATCH_FINISH]: (batch) => {
        // console.log(`Batch Finish - ${batch.id} - item count = ${batch.items.length}`);
        props.handleUpload('batchFinish', batch)
      },
      [UPLOADER_EVENTS.BATCH_PROGRESS]: (batch) => {
        // console.log(`Batch Progress - ${batch.id} - item count = ${batch.items.length}`);
        // props.handleUpload('batchProgress', batch);
      },
      [UPLOADER_EVENTS.BATCH_ABORT]: (batch) => {
        // console.log(`Item Finish - ${item.id} : ${item.file.name}`);
        props.handleUpload('batchAbort', batch)
      },
      [UPLOADER_EVENTS.ITEM_START]: (item) => {
        // console.log(`Item Start - ${item.id} : ${item.file.name}`);
        props.handleUpload('start', item)
      },
      [UPLOADER_EVENTS.ITEM_FINISH]: (item) => {
        props.handleUpload('finish', item)
      },
      [UPLOADER_EVENTS.ITEM_ERROR]: (item) => {
        props.handleUpload('error', item)
      },
      [UPLOADER_EVENTS.ITEM_FINALIZED]: (item) => {
        props.handleUpload('finalized', item)
      }
    }), []) // eslint-disable-line

  const filterFiles = useCallback((file) => {
    if (file && file.name) {
      return file.name.match(/(.jpeg|.jpg|.gif|.png|.mp4|.pdf|.vtt|.svg|.mp3)$/i)
    } else {
      return true
    }
  }, [])

  return (
    <Uploady listeners={listeners} destination={{}} fileFilter={filterFiles} enhancer={retryEnhancer}>
      <SignedUploadDragAndDrop {...props} />
    </Uploady>
  )
}

const SignedUploadDragAndDrop = (props) => {
  const [itemHovered, setItemHovered] = React.useState({})
  useRequestPreSend(async ({ items, options }) => {
    const files = items.length > 0 ? items[0] : {}

    const { file } = files
    const { name, type, size } = file
    const result = await ApiBase.callRegisteredMethod('getPresignedRequestPutObject', { name, type, size })
    props.handleUpload('preSignedUrl', { id: files.id, meta: result })
    return {
      options: {
        sendWithFormData: false,
        destination: {
          url: result.uri,
          method: 'PUT',
          headers: {
            'Content-Type': type
          }
        }
      }
    }
  })

  return (
    <UploadDropZone onDragOverClassName='drag-over'>
      {props.completed === 0
        ? (
          <Box
            className='upload-dropzone-container'
            onMouseOver={() => {
              setItemHovered(true)
            }}
            onMouseLeave={() => {
              setItemHovered(false)
            }}
          >
            <Box className='upload-dropzone-sub-container'>
              <Box className='upload-dropzone-sub-container-text-top-row'>
                <CloudUploadOutlinedIcon className={itemHovered ? 'cloud-upload-outlined-icon-hover' : 'cloud-upload-outlined-icon'} />
              </Box>
              <Box className='upload-dropzone-sub-container-text-bottom-row'>
                <Box className='font-xl-b'>
                  Drag and drop files here, or
                  <UploadButton className={itemHovered ? 'upload-button upload-button-hover' : 'upload-button'}>browse</UploadButton>
                </Box>
                <Box className='font-m-n'>Files Supported: PDF, JPEG, PNG, VTT, MP3 audio and MP4 video (Max size per file: 200 MB)</Box>
              </Box>
            </Box>
          </Box>
          )
        : null}
      <Box>
        {/* eslint-disable-next-line react/jsx-handler-names */}
        <UploadProgress handleUpdateFileStatus={props.handleUpdateFileStatus} />
      </Box>
    </UploadDropZone>
  )
}

const UploadProgress = (props) => {
  const [uploads, setUploads] = React.useState({})
  const abortItem = useAbortItem()

  // To track progress, we want to count all of our current and pending uploads in the batch.
  //
  // batchData contains everything in the current BATCH, or files that are dragged over.
  //
  const batchData = useBatchProgressListener((batch) => {})
  if (batchData) {
    props.handleUpdateFileStatus(batchData)
    batchData.items.forEach((item) => {
      if (!uploads[item.id]) {
        const upload = { name: item.file.name, progress: [0], state: item.state }
        uploads[item.id] = upload
        setUploads({ ...uploads })
      } else {
        uploads[item.id].state = item.state
        if (item.state === 'aborted') {
          // delete uploads[item.id];
        }
      }
    })
  }

  // progressData contains information about the current upload.
  //
  const progressData = useItemProgressListener()
  if (progressData && progressData.completed) {
    const upload = uploads[progressData.id] || { name: progressData.url || progressData.file.name, progress: [0], state: 'uploading' }

    if (!~upload.progress.indexOf(progressData.completed)) {
      upload.progress.push(progressData.completed)
      setUploads({
        ...uploads,
        [progressData.id]: upload
      })
    }
  }

  const entries = Object.entries(uploads)
  const workingDiv = (
    <Box className={entries.length > 0 ? 'upload-overlay-content-scroll' : null}>
      {entries.map(([id, { progress, name, state }]) => {
        if (state === 'aborted') {
          return null
        }

        let Icon = <AttachmentIcon />
        if (name.match(/jpg|jpeg|png|gif/i)) {
          Icon = <ImageIcon />
        } else if (name.match(/mp4|avi|mov/i)) {
          Icon = <MovieIcon />
        } else if (name.match(/pdf/i)) {
          Icon = <PictureAsPdfIcon />
        } else if (name.match(/\.vtt/i)) {
          Icon = <ClosedCaptionIcon />
        } else if (name.match(/\.svg/i)) {
          Icon = <ImageIcon />
        }
        const completed = progress[progress.length - 1]
        const perc = parseFloat(completed).toFixed(0) + '%'

        // Figure out what icon to display
        let statusIcon = (
          <Circle
            className='progress-circle float-right'
            trailColor='#CCC'
            trailWidth='16'
            strokeWidth='16'
            strokeColor={completed === 100 ? '#00a626' : '#1871e7'}
            percent={completed}
          />
        )
        if (completed === 100) {
          statusIcon = <CheckCircleIcon style={{ color: 'green' }} />
        }
        return (
          <Box key={id} className='upload-item-container'>
            <Box className='upload-item-container-col1'>{Icon}</Box>
            <Box className={`upload-item-container-col2 ${state === 'aborted' ? 'grey' : null}`}>
              {perc} {name} {state}
            </Box>
            <Box className='upload-item-container-col3'>
              {completed < 100 && state.match(/added|uploading/)
                ? (
                  <IconHover
                    icon={statusIcon}
                    className='btn-hover-circle-cancel'
                    iconHovered={<CancelIcon onClick={() => abortItem(id)} style={{ color: '#F00' }} />}
                  />
                  )
                : (
                    statusIcon
                  )}
            </Box>
          </Box>
        )
      })}
    </Box>
  )

  return workingDiv
}

function formatBytes (bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
