import React, { useState } from 'react'
import { IconButton, Button, FormControlLabel, Typography, FormControl, Box, Paper, Table, TableCell, TableHead, TableRow, TableContainer, Grid } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import UpIcon from '@mui/icons-material/KeyboardArrowUp'
import DownIcon from '@mui/icons-material/KeyboardArrowDown'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import Switch from '@mui/material/Switch'
import { HoverDialog } from '../../../components/Reusable/HoverDialog'
import DataGrid from '../../../components/Reusable/DataGrid'

const AccountingDetailsDataTable = ({ title, type, items, headers, allowTaxExemptProposals, crmFieldsConfig, masterList, onAdd, onDelete, onEdit, onMove, onChangeTaxExempt, onChangeDefault, onSaveRowEdit }) => {
  const [open, setOpen] = useState(false)

  const getDiscountsDisplay = (item) => {
    let displayValue = ''
    if (item.type === 'overrideTotal') {
      displayValue = 'Override Total'
    }
    if (item.type === 'pct') {
      if (item.pct === '') {
        displayValue = 'N/A'
      } else {
        displayValue = item.pct + '% off'
      }
    }
    if (item.type === 'amount') {
      if (item.amount === '') {
        displayValue = 'N/A'
      } else {
        displayValue = '$' + item.amount + ' off'
      }
    }

    return displayValue
  }

  const getSurchargesDisplay = (item) => {
    let displayValue = ''
    if (item.type === 'amount') {
      if (item.amount === '') {
        displayValue = 'N/A'
      } else {
        displayValue = '$' + item.amount
      }
    }
    if (item.type === 'pct') {
      if (item.pct === '') {
        displayValue = 'N/A'
      } else {
        displayValue = item.pct + '%'
      }
    }

    return displayValue
  }

  const getCategories = (item) => {
    let category = ''
    if ('categories' in item && item.categories !== null) {
      const l = item.categories.length
      category = `${l} ` + (l === 1 ? 'category' : 'categories')
    } else {
      category = 'No categories'
    }

    return category
  }

  const handleProcessRowUpdate = (row, type) => {
    if (row) {
      if (type === 'commission') {
        const commission = JSON.parse(JSON.stringify(items))
        const index = commission.findIndex(o => o.id === row.id)
        commission[index].label = row.label

        onSaveRowEdit(commission, type)
      }
      if (type === 'taxes') {
        const taxes = JSON.parse(JSON.stringify(items))
        const index = taxes.findIndex(o => o.id === row.id)
        taxes[index].label = row.label

        onSaveRowEdit(taxes, type)
      }
      if (type === 'finance') {
        const finance = JSON.parse(JSON.stringify(items))
        const index = finance.findIndex(o => o.id === row.id)
        finance[index].label = row.label

        onSaveRowEdit(finance, type)
      }
      if (type === 'discounts') {
        const discounts = JSON.parse(JSON.stringify(items))
        const index = discounts.findIndex(o => o.id === row.id)
        discounts[index].label = row.label

        onSaveRowEdit(discounts, type)
      }
      if (type === 'surcharges') {
        const surcharges = JSON.parse(JSON.stringify(items))
        const index = surcharges.findIndex(o => o.id === row.id)
        surcharges[index].label = row.label

        onSaveRowEdit(surcharges, type)
      }
    }
  }

  const getColumns = (type) => {
    if (type === 'commission') {
      return columnsCommissions
    }
    if (type === 'taxes') {
      return columnsTaxes
    }
    if (type === 'finance') {
      return columnsFinance
    }
    if (type === 'discounts') {
      return columnsDiscount
    }
    if (type === 'surcharges') {
      return columnsSurcharges
    }
  }

  const getRows = (type) => {
    if (type === 'taxes') {
      const rowsTaxes = []
      for (let x = 0; x < items.length; x++) {
        let crm = 'Not Set'
        if (items[x].crmId !== null) {
          const arr = headers.filter((l) => {
            return l.value === items[x]
          })
          if (arr.length !== 0) {
            crm = arr[0].crmName
          }
        }

        let defaultToggleTax = false
        if ('default' in items[x]) {
          defaultToggleTax = items[x].default
        }

        rowsTaxes.push({
          id: items[x].id,
          label: items[x].label,
          amount: items[x].amount,
          crmId: crm,
          defaultToggle: defaultToggleTax
        })
      }
      return rowsTaxes
    } else if (type === 'finance') {
      const rowsFinance = []

      for (let x = 0; x < items.length; x++) {
        let defaultToggleFinance = false
        if ('default' in items[x]) {
          defaultToggleFinance = items[x].default
        }
        rowsFinance.push({
          id: items[x].id,
          detail: <HoverDialog jsx='financesJSX' icon={<TextSnippetOutlinedIcon />} />,
          label: items[x].label,
          months: items[x].months,
          apr: items[x].apr,
          aprMax: items[x].aprMax,
          url: items[x].url,
          // default: items[x].default,
          round_payment_factor: items[x].round_payment_factor,
          depositRequired: items[x].depositRequired,
          minJobTotal: items[x].minJobTotal,
          maxJobTotal: items[x].maxJobTotal,
          defaultToggle: defaultToggleFinance
        })
      }
      return rowsFinance
    } else if (type === 'discounts') {
      const rowsDiscounts = []
      for (let x = 0; x < items.length; x++) {
        let product = <>N/A</>
        if (masterList && masterList.length > 0) {
          const p = masterList.find((o) => o.crmId === items[x].crmID)
          if (p && p.name) {
            product = `${p.name}${p.crmId !== undefined ? `, ${p.crmId}` : ''}`
          }
        }

        rowsDiscounts.push({
          id: items[x].id,
          label: items[x].label,
          product,
          defaultDiscount: items[x].default,
          discountDisplay: getDiscountsDisplay(items[x]),
          categories: getCategories(items[x])
        })
      }
      return rowsDiscounts
    } else if (type === 'surcharges') {
      const rowsSurcharges = []
      for (let x = 0; x < items.length; x++) {
        let product = <>N/A</>
        if (masterList && masterList.length > 0) {
          const p = masterList.find((o) => o.crmId === items[x].crmID)
          if (p && p.name) {
            product = `${p.name}${p.crmId !== undefined ? `, ${p.crmId}` : ''}`
          }
        }

        const taxable = items[x].taxable === true ? 'Yes' : 'No'
        const separateLineItem = items[x].separateLineItem === true ? 'Yes' : 'No'

        rowsSurcharges.push({
          id: items[x].id,
          label: items[x].label,
          product,
          surchargesDisplay: getSurchargesDisplay(items[x]),
          taxable,
          separateLineItem
        })
      }
      return rowsSurcharges
    } else if (type === 'commission') {
      const rowsCommissions = []
      for (let x = 0; x < items.length; x++) {
        let commission = ''
        if (items[x].type === 'amount') {
          commission = items[x].amount
        }
        if (items[x].type === 'pct') {
          commission = items[x].pct
        }

        let defaultToggleCommission = false
        if ('default' in items[x]) {
          defaultToggleCommission = items[x].default
        }
        rowsCommissions.push({
          id: items[x].id,
          label: items[x].label,
          commission,
          type: items[x].type,
          defaultToggle: defaultToggleCommission,
          taxable: items[x].taxable,
          separateLineItem: items[x].separateLineItem,
          editable: items[x].editable
        })
      }
      return rowsCommissions
    }
  }

  const columnsCommissions = [
    {
      flex: 1,
      type: 'string',
      field: 'label',
      headerName: 'Title',
      editable: true,
      multiline: true,
      sortable: true
    },
    {
      flex: 1,
      type: 'string',
      field: 'commission',
      headerName: 'Commission',
      editable: false,
      sortable: true,
      renderCell: (params) => {
        if (params.row) {
          if (params.row.type === 'amount' && params.row.commission !== '') {
            const comVal = `$${params.row.commission}`
            return <Box>{comVal}</Box>
          }
          if (params.row.type === 'pct' && params.row.commission !== '') {
            const comVal = `${params.row.commission}%`
            return <Box>{comVal}</Box>
          }
        }
      }
    },
    {
      flex: 1,
      // type: 'string',
      field: 'defaultToggle',
      headerName: 'Default',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Box>
              <FormControl sx={{ mt: 1 }}>
                <FormControlLabel
                  sx={{ ml: 0 }}
                  control={
                    <Switch
                      color='primary'
                      sx={{ m: 0 }}
                      onChange={() => onChangeDefault(params.row.id, 'commission')}
                      checked={params.row.defaultToggle}
                    />
                  }
                />
              </FormControl>
            </Box>
          )
        }
      }
    },
    {
      flex: 1,
      type: 'string',
      field: 'taxable',
      headerName: 'Taxable',
      editable: false,
      sortable: true,
      renderCell: (params) => {
        if (params.row) {
          let taxableCell = 'No'
          if (typeof params.row.taxable !== 'undefined') {
            if (params.row.taxable === true) {
              taxableCell = 'Yes'
            }
          }
          return (
            <Box>{taxableCell}</Box>
          )
        }
      }
    },
    {
      flex: 1,
      type: 'string',
      field: 'separateLineItem',
      headerName: 'Separate Line Item',
      editable: false,
      sortable: true,
      renderCell: (params) => {
        if (params.row) {
          let separateLineItemCell = 'No'
          if (typeof params.row.separateLineItem !== 'undefined') {
            if (params.row.separateLineItem === true) {
              separateLineItemCell = 'Yes'
            }
          }
          return (
            <Box>{separateLineItemCell}</Box>
          )
        }
      }
    },
    {
      width: 168,
      field: 'action',
      headerName: 'Actions',
      headerAlign: 'right',
      align: 'right',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        // console.log(params);
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton
                  title='Edit'
                  aria-label='edit'
                  onClick={() => {
                    onEdit(params.row.id, 'commission')
                  }}
                >
                  <EditIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  title='Up'
                  aria-label='up'
                  onClick={() => onMove(params.row.id, 'up', 'commission')}
                  disabled={params.row.id < 1}
                >
                  <UpIcon variant='icon' fontSize='small' />
                </IconButton>
                <IconButton
                  title='Down'
                  aria-label='down'
                  onClick={() => onMove(params.row.id, 'down', 'commission')}
                  disabled={params.row.id === items.length - 1}
                >
                  <DownIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  title='Delete'
                  aria-label='delete'
                  onClick={() => {
                    onDelete(params.row.id, 'commission')
                  }}
                >
                  <DeleteIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const columnsTaxes = [
    {
      flex: 1,
      type: 'string',
      field: 'label',
      headerName: 'Title',
      editable: true,
      multiline: true,
      sortable: true
    },
    {
      flex: 1,
      type: 'string',
      field: 'amount',
      headerName: 'Amount',
      editable: false,
      sortable: true
    },
    {
      flex: 1,
      type: 'string',
      field: 'crmId',
      headerName: 'Crm Taxes',
      editable: false,
      sortable: false
    },
    {
      flex: 1,
      // type: 'string',
      field: 'defaultToggle',
      headerName: 'Default',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Box>
              <FormControl sx={{ mt: 1 }}>
                <FormControlLabel
                  sx={{ ml: 0 }}
                  control={
                    <Switch
                      color='primary'
                      sx={{ m: 0 }}
                      onChange={() => onChangeDefault(params.row.id, 'taxes')}
                      checked={params.row.defaultToggle}
                    />
                    }
                />
              </FormControl>
            </Box>
          )
        }
      }
    },
    {
      width: 168,
      field: 'action',
      headerName: 'Actions',
      headerAlign: 'right',
      align: 'right',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        // console.log(params);
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton
                  title='Edit'
                  aria-label='edit'
                  onClick={() => {
                    onEdit(params.row.id, 'taxes')
                  }}
                >
                  <EditIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  title='Up'
                  aria-label='up'
                  onClick={() => onMove(params.row.id, 'up', 'taxes')}
                  disabled={params.row.id < 1}
                >
                  <UpIcon variant='icon' fontSize='small' />
                </IconButton>
                <IconButton
                  title='Down'
                  aria-label='down'
                  onClick={() => onMove(params.row.id, 'down', 'taxes')}
                  disabled={params.row.id === items.length - 1}
                >
                  <DownIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  title='Delete'
                  aria-label='delete'
                  onClick={() => {
                    onDelete(params.row.id, 'taxes')
                  }}
                >
                  <DeleteIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const columnsFinance = [
    {
      width: 88,
      // type: 'string',
      field: 'detail',
      headerName: 'Detail',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          const item = params.row
          const financeJsx = (
            <Paper elevation={0} sx={{ p: 2 }} style={{ background: 'linear-gradient(90deg, #708090 50%, white 50%)' }}>
              <Typography variant='h5' style={{ color: 'white', marginBottom: '5px' }}>
                Detail
              </Typography>
              <Box style={{ fontSize: '16px', marginBottom: '16px' }}>
                <Grid container spacing={2} style={styles.containerGrid}>
                  <Grid item xs={6} style={styles.leftGrid}>
                    Title
                  </Grid>
                  <Grid item xs={6} style={styles.rightGrid}>
                    {item.label}
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={styles.containerGrid}>
                  <Grid item xs={6} style={styles.leftGrid}>
                    Months
                  </Grid>
                  <Grid item xs={6} style={styles.rightGrid}>
                    {item.months}
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={styles.containerGrid}>
                  <Grid item xs={6} style={styles.leftGrid}>
                    Min APR
                  </Grid>
                  <Grid item xs={4} style={styles.rightGrid}>
                    {item.apr}
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={styles.containerGrid}>
                  <Grid item xs={6} style={styles.leftGrid}>
                    Max APR
                  </Grid>
                  <Grid item xs={6} style={styles.rightGrid}>
                    {item.aprMax}
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={styles.containerGrid}>
                  <Grid item xs={6} style={styles.leftGrid}>
                    Website URL
                  </Grid>
                  <Grid item xs={6} style={styles.rightGrid}>
                    {item.url}
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={styles.containerGrid}>
                  <Grid item xs={6} style={styles.leftGrid}>
                    Default Financing Plan
                  </Grid>
                  <Grid item xs={6} style={styles.rightGrid}>
                    {item.default ? 'Yes' : 'No'}
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={styles.containerGrid}>
                  <Grid item xs={6} style={styles.leftGrid}>
                    Round To Payment Factor
                  </Grid>
                  <Grid item xs={6} style={styles.rightGrid}>
                    {item.round_payment_factor ? 'Yes' : 'No'}
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={styles.containerGrid}>
                  <Grid item xs={6} style={styles.leftGrid}>
                    Required Deposit
                  </Grid>
                  <Grid item xs={6} style={styles.rightGrid}>
                    {item.depositRequired ? 'Yes' : 'No'}
                  </Grid>
                </Grid>
              </Box>
              <Typography variant='h5' style={{ color: 'white', marginBottom: '5px' }}>
                Project Total Ranges
              </Typography>
              <Box style={{ fontSize: '16px' }}>
                <Grid container spacing={2} style={styles.containerGrid}>
                  <Grid item xs={6} style={styles.leftGrid}>
                    Minimun
                  </Grid>
                  <Grid item xs={6} style={styles.rightGrid}>
                    {item.minJobTotal}
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={styles.containerGrid}>
                  <Grid item xs={6} style={styles.leftGrid}>
                    Maximum
                  </Grid>
                  <Grid item xs={6} style={styles.rightGrid}>
                    {item.maxJobTotal}
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          )
          return (
            <Box>
              <HoverDialog jsx={financeJsx} icon={<TextSnippetOutlinedIcon />} />
            </Box>
          )
        }
      }
    },
    {
      flex: 2,
      type: 'string',
      field: 'label',
      headerName: 'Title',
      editable: true,
      multiline: true,
      sortable: false
    },
    {
      flex: 1,
      type: 'string',
      field: 'months',
      headerName: 'Months',
      editable: false,
      sortable: false
    },
    {
      flex: 1,
      type: 'string',
      field: 'apr',
      headerName: 'APR',
      editable: false,
      sortable: false
    },
    /* {
      flex: 1,
      type: 'string',
      field: 'round_payment_factor',
      headerName: 'Round to Payment Factor',
      editable: false,
      sortable: false
    }, */
    {
      flex: 1,
      // type: 'string',
      field: 'defaultToggle',
      headerName: 'Default',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Box>
              <FormControl sx={{ mt: 1 }}>
                <FormControlLabel
                  sx={{ ml: 0 }}
                  control={
                    <Switch
                      color='primary'
                      sx={{ m: 0 }}
                      onChange={() => onChangeDefault(params.row.id, 'finance')}
                      checked={params.row.defaultToggle}
                    />
                      }
                />
              </FormControl>
            </Box>
          )
        }
      }
    },
    {
      width: 168,
      field: 'action',
      headerName: 'Actions',
      headerAlign: 'right',
      align: 'right',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        // console.log(params);
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton
                  title='Edit'
                  aria-label='edit'
                  onClick={() => {
                    onEdit(params.row.id, 'finance')
                  }}
                >
                  <EditIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  title='Up'
                  aria-label='up'
                  onClick={() => onMove(params.row.id, 'up', 'finance')}
                  disabled={params.row.id < 1}
                >
                  <UpIcon variant='icon' fontSize='small' />
                </IconButton>
                <IconButton
                  title='Down'
                  aria-label='down'
                  onClick={() => onMove(params.row.id, 'down', 'finance')}
                  disabled={params.row.id === items.length - 1}
                >
                  <DownIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  title='Delete'
                  aria-label='delete'
                  onClick={() => {
                    onDelete(params.row.id, 'finance')
                  }}
                >
                  <DeleteIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const columnsDiscount = [
    {
      flex: 1,
      type: 'string',
      field: 'label',
      headerName: 'Title',
      editable: true,
      multiline: true,
      sortable: true
    },
    /* {
      flex: 1,
      type: 'string',
      field: 'product',
      headerName: 'Product',
      editable: false,
      sortable: true,
    }, */
    {
      flex: 1,
      type: 'string',
      field: 'discountDisplay',
      headerName: 'Discount',
      editable: false,
      sortable: true
    },
    {
      flex: 1,
      type: 'string',
      field: 'defaultDiscount',
      headerName: 'Default',
      editable: false,
      sortable: true,
      renderCell: (params) => {
        if (params.row) {
          let defaultDiscountCell = 'No'
          if (typeof params.row.defaultDiscount !== 'undefined') {
            if (params.row.defaultDiscount === true) {
              defaultDiscountCell = 'Yes'
            }
          }
          return (
            <Box>{defaultDiscountCell}</Box>
          )
        }
      }
    },
    {
      flex: 1,
      type: 'string',
      field: 'categories',
      headerName: 'Discount Categories',
      editable: false,
      sortable: true
    },
    {
      width: 168,
      field: 'action',
      headerName: 'Actions',
      headerAlign: 'right',
      align: 'right',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        // console.log(params);
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton
                  title='Edit'
                  aria-label='edit'
                  onClick={() => {
                    onEdit(params.row.id, 'discounts')
                  }}
                >
                  <EditIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  title='Up'
                  aria-label='up'
                  onClick={() => onMove(params.row.id, 'up', 'discounts')}
                  disabled={params.row.id < 1}
                >
                  <UpIcon variant='icon' fontSize='small' />
                </IconButton>
                <IconButton
                  title='Down'
                  aria-label='down'
                  onClick={() => onMove(params.row.id, 'down', 'discounts')}
                  disabled={params.row.id === items.length - 1}
                >
                  <DownIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  title='Delete'
                  aria-label='delete'
                  onClick={() => {
                    onDelete(params.row.id, 'discounts')
                  }}
                >
                  <DeleteIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const columnsSurcharges = [
    {
      flex: 1,
      type: 'string',
      field: 'label',
      headerName: 'Title',
      editable: true,
      multiline: true,
      sortable: true
    },
    /* {
      flex: 1,
      type: 'string',
      field: 'product',
      headerName: 'Product',
      editable: false,
      sortable: true
    }, */
    {
      flex: 1,
      type: 'string',
      field: 'surchargesDisplay',
      headerName: 'Surcharge',
      editable: false,
      sortable: true
    },
    {
      flex: 1,
      type: 'string',
      field: 'taxable',
      headerName: 'Taxable',
      editable: false,
      sortable: true
    },
    {
      flex: 1,
      type: 'string',
      field: 'separateLineItem',
      headerName: 'Itemized',
      editable: false,
      sortable: true
    },
    {
      width: 168,
      field: 'action',
      headerName: 'Actions',
      headerAlign: 'right',
      align: 'right',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        // console.log(params);
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton
                  title='Edit'
                  aria-label='edit'
                  onClick={() => {
                    onEdit(params.row.id, 'surcharges')
                  }}
                >
                  <EditIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  title='Up'
                  aria-label='up'
                  onClick={() => onMove(params.row.id, 'up', 'surcharges')}
                  disabled={params.row.id < 1}
                >
                  <UpIcon variant='icon' fontSize='small' />
                </IconButton>
                <IconButton
                  title='Down'
                  aria-label='down'
                  onClick={() => onMove(params.row.id, 'down', 'surcharges')}
                  disabled={params.row.id === items.length - 1}
                >
                  <DownIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  title='Delete'
                  aria-label='delete'
                  onClick={() => {
                    onDelete(params.row.id, 'surcharges')
                  }}
                >
                  <DeleteIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <TableContainer component={Paper} sx={{ marginBottom: '8px' }}>
          <Table aria-label='customized table' sx={{ borderBottom: '2px solid #2a3042' }}>
            <TableHead>
              <TableRow onClick={() => setOpen(!open)}>
                <TableCell>
                  {title} ({items.length})
                </TableCell>
                <TableCell align='right'>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</TableCell>
              </TableRow>
            </TableHead>
          </Table>
          {open
            ? (
              <DataGrid
                sx={{ border: 0, '& .MuiDataGrid-columnHeaders': { borderRadius: 0 } }}
                rows={getRows(type)}
                columns={getColumns(type)}
                showToolbar={false}
                onProcessRowUpdate={(row) => {
                  handleProcessRowUpdate(row, type)
                }}
                showRequired
                onAdd={() => {
                  onAdd(type)
                }}
                autosizeOptions={{
                  columns: ['action'],
                  includeOutliers: true,
                  includeHeaders: false
                }}
              />
              )
            : null}

          {open
            ? (
              <Box sx={{ p: 1 }}>
                <Button
                  id='asset-action'
                  style={{ textTransform: 'uppercase' }}
                  className='mui-btn'
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    onAdd(type)
                  }}
                >
                  Add {title}
                </Button>
                {/* {type === 'taxes' && (
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Switch
                          color='primary'
                          checked={allowTaxExemptProposals}
                          onChange={() => onChangeTaxExempt()}
                        />
                      }
                      label={<Typography>Allow Tax Exempt Proposals</Typography>}
                      labelPlacement='start'
                    />
                  </FormControl>
                )} */}
              </Box>
              )
            : null}
        </TableContainer>
      </Box>
    </>
  )
}
export default AccountingDetailsDataTable

const styles = {
  containerGrid: {
    marginBottom: '8px',
    fontWeight: '400'
  },
  leftGrid: {
    color: 'white'
  },
  rightGrid: {
    paddingLeft: '24px'
  },
  icon: {
    color: 'rgba(0,0,0,.66)'
  }
}
