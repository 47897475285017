import React, { useState } from 'react'
import { Menu, MenuItem } from '@mui/material'
import Button from '@mui/material/Button'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import PinIcon from '@mui/icons-material/Pin'
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'

export const AddValue = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [valueType, setValueType] = useState('')
  const open = Boolean(anchorEl)
  // console.log(props);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const changeHandler = (val) => {
    // console.log();
    setValueType(val)
    setAnchorEl(null)
    props.onChangeAddValue(val)
  }

  return (
    <>
      <Button
        onClick={handleClick}
        variant='text'
        startIcon={<AppRegistrationIcon />}
      >
        Add Value
      </Button>
      <div>
        <Menu
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}
          sx={{ width: '320px' }}
        >
          <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
            <MenuItem
              onClick={() => changeHandler('number')}
              value='number'
              selected={valueType === 'number'}
            >
              <span style={styles.icon}><PinIcon fontSize='small' /></span>
              numeric value
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('boolean')}
              value='boolean'
              selected={valueType === 'boolean'}
            >
              <span style={styles.icon}><ThumbsUpDownIcon fontSize='small' /></span>
              yes / no
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('string')}
              value='string'
              selected={valueType === 'string'}
            >
              <span style={styles.icon}><FormatQuoteIcon fontSize='small' /></span>
              custom expression
            </MenuItem>
          </div>
        </Menu>
      </div>
    </>
  )
}

const styles = {
  icon: {
    paddingRight: '16px'
  }
}
