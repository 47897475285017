import React from 'react'
import { Box, Grid } from '@mui/material'
import { LogoMark } from '../components/LogoMark'
import { LoadingPleaseWait } from '@supportworks/react-components'

const SplashScreen = () => {
  return (
    <Grid container sx={{ pt: 3 }}>
      <Grid item xs={4}>
        &nbsp;
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <LogoMark />
          <Box sx={{ pt: 2 }}>
            <LoadingPleaseWait />
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default SplashScreen
