import React from 'react'
import '../../../../css/forms.css'
import { Divider } from '@mui/material'

function FieldTypeExpandMarker (props) {
  return (
    <Divider
      style={styles.main}
      className='fieldtype-expand-divider'
      orientation='vertical'
      variant='fullWidth'
      flexItem
    />
  )
}

const styles = {
  main: {
    display: 'inline-block',
    marginLeft: '26px',
    marginRight: '26px',
    borderLeft: '3px solid #6495edab'
  }
}
export default FieldTypeExpandMarker
