import React, { useEffect, useState } from 'react'
import { Box, Button, Drawer, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@mui/material'
import DrawerHeader from 'screens/Config/Forms/Reusables/DrawerHeader'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { DataGrid } from '@mui/x-data-grid'
import { DialogSolutionSelector } from 'components/Reusable/DialogSolutionSelector'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import DrawerFooter from 'screens/Config/Forms/Reusables/DrawerFooter'

const DrawerInitialProjects = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [projectTitle, setProjectTitle] = useState('')
  const [hiddenFromCustomer, setHiddenFromCustomer] = useState(false)
  const [recommended, setRecommended] = useState('accepted')
  const [acceptedSolution, setAcceptedSolution] = useState([])
  const [declinedSolution, setDeclinedSolution] = useState([])
  const [hiddenSolution, setHiddenSolution] = useState([])
  const [showSolutionDialog, setShowSolutionDialog] = useState(false)
  const [currentSolutionType, setCurrentSolutionType] = useState('')

  useEffect(() => {
    if (isLoading) {
      const project = props.projectConfig
      if (project.name === undefined) {
        project.name = ''
      }
      setProjectTitle(project.name)

      if (project.hiddenFromCustomer === undefined) {
        project.hiddenFromCustomer = false
      }
      setHiddenFromCustomer(project.hiddenFromCustomer)

      if (project.solutions === undefined) {
        project.solutions = { accepted: [], declined: [], hidden: [], recommended: 'accepted' }
      }
      if (project.solutions.accepted) {
        setAcceptedSolution(project.solutions.accepted)
      }
      if (project.solutions.declined) {
        setDeclinedSolution(project.solutions.declined)
      }
      if (project.solutions.hidden) {
        setHiddenSolution(project.solutions.hidden)
      }
      if (project.solutions.recommended) {
        setRecommended(project.solutions.recommended)
      }

      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const stopPropagationForTab = (e) => {
    if (e.key === 'Tab') {
      e.stopPropagation()
    }
  }

  const handleClose = (e) => {
    props.onClose()
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    if (name === 'name') {
      setProjectTitle(value)
    }
    if (name === 'hiddenFromCustomer') {
      setHiddenFromCustomer(!hiddenFromCustomer)
    }
    if (name === 'recommended') {
      setRecommended(value)
    }
  }

  const handleAddSolution = (type) => {
    setCurrentSolutionType(type)
    setShowSolutionDialog(!showSolutionDialog)
  }

  const handleSolutionsChange = (l, type) => {
    switch (type) {
      case 'accepted':
        setAcceptedSolution(l)
        setCurrentSolutionType('')
        break
      case 'declined':
        setDeclinedSolution(l)
        setCurrentSolutionType('')
        break
      case 'hidden':
        setHiddenSolution(l)
        setCurrentSolutionType('')
        break
    }
  }

  const handleMoveUp = (idx, type) => {
    if (type === 'accepted') {
      const l = JSON.parse(JSON.stringify(acceptedSolution))
      const ele = l.splice(idx, 1)[0]
      l.splice(idx - 1, 0, ele)
      setAcceptedSolution(l)
    }
    if (type === 'declined') {
      const l = JSON.parse(JSON.stringify(declinedSolution))
      const ele = l.splice(idx, 1)[0]
      l.splice(idx - 1, 0, ele)
      setDeclinedSolution(l)
    }
    if (type === 'hidden') {
      const l = JSON.parse(JSON.stringify(hiddenSolution))
      const ele = l.splice(idx, 1)[0]
      l.splice(idx - 1, 0, ele)
      setHiddenSolution(l)
    }
  }

  const handleMoveDown = (idx, type) => {
    if (type === 'accepted') {
      const l = JSON.parse(JSON.stringify(acceptedSolution))
      const ele = l.splice(idx, 1)[0]
      l.splice(idx + 1, 0, ele)
      setAcceptedSolution(l)
    }
    if (type === 'declined') {
      const l = JSON.parse(JSON.stringify(declinedSolution))
      const ele = l.splice(idx, 1)[0]
      l.splice(idx + 1, 0, ele)
      setDeclinedSolution(l)
    }
    if (type === 'hidden') {
      const l = JSON.parse(JSON.stringify(hiddenSolution))
      const ele = l.splice(idx, 1)[0]
      l.splice(idx + 1, 0, ele)
      setHiddenSolution(l)
    }
  }

  const handleDelete = (idx, type) => {
    const r = window.confirm('Are you sure you want to delete?')
    if (r) {
      if (type === 'accepted') {
        const l = JSON.parse(JSON.stringify(acceptedSolution))
        l.splice(idx, 1)
        setAcceptedSolution(l)
      }
      if (type === 'declined') {
        const l = JSON.parse(JSON.stringify(declinedSolution))
        l.splice(idx, 1)
        setDeclinedSolution(l)
      }
      if (type === 'hidden') {
        const l = JSON.parse(JSON.stringify(hiddenSolution))
        l.splice(idx, 1)
        setHiddenSolution(l)
      }
    }
  }

  const getSolution = (s, type) => {
    const solution = props.config.solutions.filter((c, idx) => {
      if (c.id === s) {
        return c
      } else {
        return false
      }
    })
    if (0 in solution) {
      return solution[0].title
    }
  }

  const handleDone = () => {
    const project = JSON.parse(JSON.stringify(props.projectConfig))
    project.name = projectTitle
    project.hiddenFromCustomer = hiddenFromCustomer
    project.solutions.accepted = acceptedSolution
    project.solutions.declined = declinedSolution
    project.solutions.hidden = hiddenSolution
    project.solutions.recommended = recommended

    props.onDone(project)
    props.onClose()
  }

  const columnsAccepted = [
    { field: 'id', headerName: 'Id', hide: true },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: (params) => {
        return <>{params.row.title}</>
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id, 'accepted')}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton size='small' disabled={!(params.row.id < acceptedSolution.length - 1)} onClick={() => handleMoveDown(params.row.id, 'accepted')}>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id, 'accepted')}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]
  const columnsDeclined = [
    { field: 'id', headerName: 'Id', hide: true },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: (params) => {
        return <>{params.row.title}</>
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id, 'declined')}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton size='small' disabled={!(params.row.id < declinedSolution.length - 1)} onClick={() => handleMoveDown(params.row.id, 'declined')}>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id, 'declined')}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]
  const columnsHidden = [
    { field: 'id', headerName: 'Id', hide: true },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: (params) => {
        return <>{params.row.title}</>
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id, 'hidden')}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton size='small' disabled={!(params.row.id < hiddenSolution.length - 1)} onClick={() => handleMoveDown(params.row.id, 'hidden')}>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id, 'hidden')}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rowsAccepted = []
  for (let x = 0; x < acceptedSolution.length; x++) {
    rowsAccepted.push({
      id: x,
      title: getSolution(acceptedSolution[x], 'accepted'),
      actions: ''
    })
  }

  const rowsDeclined = []
  for (let x = 0; x < declinedSolution.length; x++) {
    rowsDeclined.push({
      id: x,
      title: getSolution(declinedSolution[x], 'declined'),
      actions: ''
    })
  }

  const rowsHidden = []
  for (let x = 0; x < hiddenSolution.length; x++) {
    rowsHidden.push({
      id: x,
      title: getSolution(hiddenSolution[x], 'hidden'),
      actions: ''
    })
  }

  return (
    <>
      <Drawer
        key='initialProjects-addSolution'
        open={props.open}
        PaperProps={{
          sx: { width: '560px !important' }
        }}
        anchor='right'
        variant='temporary'
        onKeyDown={stopPropagationForTab}
        onClose={handleClose}
        sx={{ zIndex: 1300 }}
      >
        <DrawerHeader title='Default Project' onClose={handleClose} />
        <Box sx={{ p: 2 }} style={styles.content}>
          <Grid container>
            <Grid item xs={6}>
              <TextField
                id='name'
                name='name'
                fullWidth
                variant='outlined'
                label='Project Title'
                size='small'
                value={projectTitle}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                name='hiddenFromCustomer'
                label={<Typography variant='body2'>Hidden From Customer</Typography>}
                labelPlacement='start'
                sx={{ pl: 2 }}
                control={
                  <Switch
                    checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                    id='hiddenFromCustomerPhoto'
                    checked={hiddenFromCustomer}
                    color='primary'
                    onClick={handleChange}
                    sx={{ display: 'flex' }}
                  />
                }
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Grid container sx={{ pt: 2 }}>
            <Grid item xs={12}>
              <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                Undefined Solutions
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ pt: 0.5 }}>
              <Typography variant='body2'>
                Solutions not marked as accepted, declined or hidden below will still be added
                to the project. Specify the default status of solutions as they are added to a project
                as either hidden or recommended.
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ pt: 2 }}>
              <FormControl size='small' sx={{ minWidth: '240px' }}>
                <InputLabel id='recommended'>Default Status of Solution Added</InputLabel>
                <Select
                  labelId='recommended'
                  name='recommended'
                  id='recommended'
                  value={recommended}
                  size='small'
                  label='Default Status of Solution Added'
                  onChange={handleChange}
                >
                  <MenuItem key='accepted' value='accepted'>Accepted</MenuItem>
                  <MenuItem key='hidden' value='hidden'>Hidden</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container sx={{ pt: 2 }}>
            <Grid item xs={12}>
              <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                Accepted Solutions
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' sx={{ pt: 0.5 }}>
                Solutions in this list will be marked automatically as accepted in this project
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {
                acceptedSolution.length > 0 &&
                  <DataGrid
                    autoHeight
                    rows={rowsAccepted}
                    columns={columnsAccepted}
                    pagination
                    pageSize={10}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          id: false
                        }
                      }
                    }}
                  />
              }
              <Button size='small' variant='contained' onClick={() => handleAddSolution('accepted')} sx={{ mt: 2, mb: 2 }}>
                Add Solution
              </Button>
            </Grid>
          </Grid>

          <Grid container sx={{ pt: 2 }}>
            <Grid item xs={12}>
              <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                Declined Solutions
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' sx={{ pt: 0.5 }}>
                Solutions in this list will be marked automatically as declined in this project
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {
                declinedSolution.length > 0 &&
                  <DataGrid
                    autoHeight
                    rows={rowsDeclined}
                    columns={columnsDeclined}
                    pagination
                    pageSize={10}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          id: false
                        }
                      }
                    }}
                  />
              }
              <Button size='small' variant='contained' onClick={() => handleAddSolution('declined')} sx={{ mt: 2, mb: 2 }}>
                Add Solution
              </Button>
            </Grid>
          </Grid>

          <Grid container sx={{ pt: 2 }}>
            <Grid item xs={12}>
              <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                Hidden Solutions
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' sx={{ pt: 0.5 }}>
                Solutions in this list will be automatically hidden in this project
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {
                hiddenSolution.length > 0 &&
                  <DataGrid
                    autoHeight
                    rows={rowsHidden}
                    columns={columnsHidden}
                    pagination
                    pageSize={10}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          id: false
                        }
                      }
                    }}
                  />
                }
              <Button size='small' variant='contained' onClick={() => handleAddSolution('hidden')} sx={{ mt: 2, mb: 2 }}>
                Add Solution
              </Button>
            </Grid>
          </Grid>
        </Box>
        <DrawerFooter onClose={handleClose} onDone={handleDone} />
      </Drawer>

      {
        showSolutionDialog && currentSolutionType === 'accepted' &&
          <DialogSolutionSelector
            title='Select Solutions'
            list={acceptedSolution}
            type={currentSolutionType}
            solutions={props.config.solutions}
            assets={props.config.assets2}
            onSave={(l, type) => {
              handleSolutionsChange(l, type)
              setShowSolutionDialog(!showSolutionDialog)
            }}
            onClose={() => setShowSolutionDialog(!showSolutionDialog)}
          />
      }

      {
        showSolutionDialog && currentSolutionType === 'declined' &&
          <DialogSolutionSelector
            title='Select Solutions'
            list={declinedSolution}
            type={currentSolutionType}
            solutions={props.config.solutions}
            assets={props.config.assets2}
            onSave={(l, type) => {
              handleSolutionsChange(l, type)
              setShowSolutionDialog(!showSolutionDialog)
            }}
            onClose={() => setShowSolutionDialog(!showSolutionDialog)}
          />
      }

      {
        showSolutionDialog && currentSolutionType === 'hidden' &&
          <DialogSolutionSelector
            title='Select Solutions'
            list={hiddenSolution}
            type={currentSolutionType}
            solutions={props.config.solutions}
            assets={props.config.assets2}
            onSave={(l, type) => {
              handleSolutionsChange(l, type)
              setShowSolutionDialog(!showSolutionDialog)
            }}
            onClose={() => setShowSolutionDialog(!showSolutionDialog)}
          />
      }
    </>
  )
}

export default DrawerInitialProjects

const styles = {
  content: {
    overflowX: 'hidden'
  },
  icon: {
    color: 'rgba(0,0,0,.66)'
  }
}
