import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import Api from 'library/Api'
import { DataGridProducts } from 'components/Reusable/DataGridProducts'
import { LoadingPleaseWait } from '@supportworks/react-components'

export const RightPanelProducts = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [product, setProduct] = useState([])
  const [library, setLibrary] = useState([])

  useEffect(() => {
    if (isLoading) {
      if (props.solution.product) {
        setProduct(props.solution.product || [])
        Api.callRegisteredMethod('getLatestConfigByTag', { tag: 'libraryProductMapping', startsWith: false }).then((c) => {
          if (c && c.contentJson) {
            setLibrary(JSON.parse(c.contentJson))
          } else {
            setLibrary([])
          }
          setIsLoading(false)
        })
      }
    }
    // eslint-disable-next-line
  }, [])

  const handleChange = (list) => {
    const s = JSON.parse(JSON.stringify(props.solution))
    s.product = list
    props.setSolution(s)
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Box sx={{ p: 2, borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Typography style={styles.title}>{props.title || 'Solution Default Products Setup'}</Typography>
      </Box>
      <Box style={styles.container} sx={{ pt: 2, pl: 2 }}>
        <Typography variant='body2'>Select products that will be attached to this solution.</Typography>
      </Box>
      <Box style={styles.container} sx={{ pt: 2, pl: 2, pr: 2 }}>
        <DataGridProducts {...props} variant='top' list={product} library={library} onChange={handleChange} />
      </Box>
    </>
  )
}

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'white'
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  }
}
