import React, { useState, useEffect } from 'react'
import { Button, Grid, Box, Paper, Dialog } from '@mui/material'
import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel'
import Canvas from './Canvas'
import { LoadingPleaseWait } from '@supportworks/react-components'

const PDFDocumentEditor = ({ forms, document, asset, pageCount, pages, onChange, onClose }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isDragging, setIsDragging] = useState(false)
  const [formId, setFormId] = useState(null)
  const [field, setField] = useState(null)
  const [fieldValue, setFieldValue] = useState(null)
  const [sforms, setSForms] = useState([])

  // Used for dragging fields that have multiple selections...
  const [selectionIndex, setSelectionIndex] = useState(-1)
  const [page, setPage] = useState({})
  const [pagenum, setPageNum] = useState(null)

  // Main useEffect that fires on page load
  useEffect(() => {
    setSForms(standardForms(document))
    setIsLoading(false)
    // eslint-disable-next-line
  }, [])

  const handleDragStart = ({ formId, field, fieldValue = null }) => {
    setFormId(formId)
    setField(field)
    setFieldValue(fieldValue)
    setIsDragging(true)
  }

  const handleDragEnd = () => {
    setFormId(null)
    setField({})
    setFieldValue(null)
    setIsDragging(false)
  }

  const handlePageSelect = (idx) => {
    if (pages && pages.length) {
      const page = pages[idx]
      setPage(page)
      setPageNum(idx + 1)
    }
  }

  const handleFieldSelect = (field, selectionIndex = -1, fieldValue = null) => {
    if (!field || (field && !field.id)) {
      setFormId(null)
      setField({})
      setSelectionIndex(-1)
      setFieldValue(null)
    } else {
      // Autofocus form type on field select
      let form
      form = findInForm(sforms, field, selectionIndex)
      if (!form) {
        form = findInForm(forms, field)
      }
      if (form) {
        setFormId(form.id)
        setField(field)
        setSelectionIndex(selectionIndex) // for selections, signatures, initials
        setFieldValue(fieldValue) // For multi-value types
      } else {
        setFormId(null)
        setField({})
        setSelectionIndex(-1) // -1 is used for fields that are not type selection
        setFieldValue(null)
      }
    }
  }

  /**
   * handleChange()
   *
   * Method mainly used for triggering a refresh of the LeftPanel when selections change, since they're built dynamically by the user.
   *
   * @param {*} variant
   * @param {*} field
   */
  const handleChange = (variant, value) => {
    if (variant === 'field') {
      if (value.type && value.type.match(/selection|signature|initial/)) {
        const idx = document.fields.findIndex((o) => o.id === value.id)
        document.fields[idx] = value
      } else {
        let arr = document.fields.filter((o) => o.id === value.id)
        for (let x = 0; x < arr.length; x++) {
          if (value.source) {
            arr[x].source = value.source
          }
        }
        arr = document.fields.filter((o) => o.editorId === value.id)
        for (let x = 0; x < arr.length; x++) {
          if (value.source) {
            arr[x].source = value.source
          }
        }
      }
    }
    setSForms(standardForms(document))
  }

  const handleClose = () => {
    if (window.confirm('You might lose changes.  Are you sure you want to close?')) {
      onClose()
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  let jsx
  try {
    jsx = (
      <>
        <Paper elevation={0} sx={{ p: 2, display: { xs: 'block', sm: 'block', md: 'none' } }}>
          <Grid container spacing={0} style={{ height: '100vh' }}>
            For the best user experience, please use a larger resolution.
          </Grid>
        </Paper>
        <Paper elevation={0} sx={{ pt: 0, pb: 0, pl: 0, pr: 0, display: { xs: 'none', sm: 'none', md: 'block' } }}>
          <Grid container spacing={0} style={{ height: '100vh' }} wrap='nowrap'>
            <Grid item width='336px' sx={{ pr: 0, pb: 8, mt: 8, ...styles.leftPanel }}>
              <LeftPanel
                document={document}
                forms={forms}
                sforms={sforms}
                selectedFormId={formId}
                pagenum={pagenum}
                field={field}
                fieldSubIndex={selectionIndex} // Separate from selectionIndex because selectionIndex is a state variable here & Canvas
                fieldValue={fieldValue}
                selectionIndex={selectionIndex}
                onFieldSelect={handleFieldSelect}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onChange={handleChange}
                onPageSelect={handlePageSelect}
              />
            </Grid>
            <Grid item xs={12} sm={7} sx={{ pr: 0, pb: 8, mt: 8, ...styles.centerNav }}>
              <Canvas
                document={document}
                forms={forms}
                sforms={sforms}
                asset={asset}
                formId={formId}
                page={page}
                pagenum={pagenum}
                field={field}
                fieldSubIndex={selectionIndex} // Separate from selectionIndex because selectionIndex is a state variable here & Canvas
                fieldValue={fieldValue}
                isDragging={isDragging}
                onFieldSelect={handleFieldSelect}
                onBack={() => {
                  onChange()
                  handleClose()
                }}
                onDelete={handleDragEnd} // Forces a refresh of the LeftPanel
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={2} sx={{ pl: 1, pr: 1, pb: 8, mt: 8, ...styles.rightPanel }}>
              <RightPanel document={document} asset={asset} pageCount={pageCount} pagenum={pagenum} pages={pages} onPageSelect={handlePageSelect} />
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', ...styles.bottomNavigation }}>
            <Button
              id='done'
              variant='contained'
              color='primary'
              sx={{ marginLeft: 'auto' }}
              onClick={() => {
                onChange()
                onClose()
              }}
            >
              Done
            </Button>
          </Box>
        </Paper>
      </>
    )
  } catch (err) {
    console.log(`PDFDocumentEditor error: ${err.stack}`)
    jsx = <div>Data is not available.</div>
  }
  return (
    <Dialog open onClose={onClose} fullScreen PaperProps={{ sx: { width: '100%', height: '100%' } }} scroll='paper'>
      {jsx}
    </Dialog>
  )
}
export default PDFDocumentEditor

export const findInForm = (forms, field) => {
  for (const form of forms) {
    if (form.formSection) {
      const formSection = form.formSection
      for (const s of formSection) {
        // let fields = s.fields;
        const fields = getFlattenedFields(s)
        for (const f of fields) {
          if (f.id && field.id && f.id === field.id) {
            return form
          }
          if (f.id && field.editorId && f.id === field.editorId) {
            return form
          }

          if (f.id && f.id.match(/dynamic-selection/)) {
            for (let y = 0; y < f.selections.length; y++) {
              if (f.selections[y].id === field.id) {
                return form
              }
            }
          }
          if (f.id && f.id.match(/dynamic-signature|dynamic-initial/)) {
            for (let y = 0; y < f.selections.length; y++) {
              if (f.selections[y].id === field.id) {
                return form
              }
              for (let z = 0; z < f.selections[y].additional.length; z++) {
                if (f.selections[y].additional[z].id === field.id) {
                  return form
                }
              }
            }
          }
          if (f.id && f.id.match(/dynamic-text-input/)) {
            if (field.type === 'text') {
              return form
            }
          }
        }
      }
    }
  }
  return false
}

export const getFlattenedFields = (fs) => {
  const fields = []
  fs.fields.forEach((field, idx) => {
    return _recurseRelated(fields, field)
  })
  return fields
}

const _recurseRelated = (fields, field) => {
  const nfield = JSON.parse(JSON.stringify(field))
  nfield.list = []
  let list = []
  if (field.type.match(/^(checkbox|radio)/)) {
    list = field.list
  } else if (field.type === 'choice') {
    list = field.choices.map((f) => f.title)
  } else if (field.type === 'boolean') {
    list = ['Yes', 'No']
  }
  list.forEach((l, idx) => {
    nfield.list.push(l)
  })
  if (field.related) {
    fields.push(nfield)
    // eslint-disable-next-line no-unreachable-loop
    for (let x = 0; x < field.related.length; x++) {
      return _recurseRelated(fields, field.related[x])
    }
  } else {
    fields.push(nfield)
  }
  return true
}

/**
 * Loop thru all my regular fields and all my sub fields until I match this ID.
 * For all selection types I want to count all the selections so the number is legit.
 *
 * @param {*} document
 * @param {*} field      the field from the FORM, not the document.
 * @param {*} fieldSubIndex
 * @returns
 */
export const findFieldNumber = (document, field, fieldSubIndex = -1) => {
  let counter = 0
  let number = -1
  for (let x = 0; x < document.fields.length; x++) {
    const f = document.fields[x]
    if (f.type && f.type === 'selection') {
      for (let y = 0; y < f.selections.length; y++) {
        if (f.selections[y].x > -1) {
          counter = counter + 1
        }
        if (f.id === field.id && y === fieldSubIndex && f.selections[y].x > -1) {
          number = counter
          break
        }
      }
    } else if (f.type && f.type.match(/signature|initial/)) {
      // Handle sig/inits.  The first sig/init is in the main json.  The other sig/inits are in the additional array.
      if (f.x > -1) {
        counter = counter + 1
        if (f.id === field.id && fieldSubIndex === -99) {
          number = counter
          break
        }
      }

      if (f.additional && f.additional.length) {
        for (let y = 0; y < f.additional.length; y++) {
          if (f.additional[y].x > -1) {
            counter = counter + 1
          }
          if (f.id === field.id && y === fieldSubIndex && f.additional[y].x > -1) {
            number = counter
            break
          }
        }
      }
    } else {
      // The normal stuff.
      counter = counter + 1
      if (f.editorId === field.id) {
        if (f.source && f.source.display) {
          if (f.source.value === fieldSubIndex) {
            // To match FieldNavMulti form fields, that have a value set
            number = counter
            break
          }
          if (fieldSubIndex === -1 && !f.source.value) {
            // To match display:"values" types where we do not have a value
            number = counter
            break
          }
        } else {
          // To match all regular FieldNav fields
          if (field.type === 'text') {
            if (field.x && field.y) {
              number = counter
              break
            }
          } else {
            number = counter
            break
          }
        }
      }
    }
  }
  return number
}

export const calculateXPos = (e, zoom) => {
  const x = e.pageX / zoom
  return x
}

export const calculateYPos = (e, zoom, pdfHeight) => {
  const y = pdfHeight - e.pageY / zoom
  return y
}

// I Capiche.  This isn't really a form, but I wanted to use the same structure as the forms JSON so I could re-use code in the LeftPanel.
const standardForms = (document) => {
  return [
    {
      id: 'standard',
      formTitle: 'Standard',
      formSection: [
        {
          id: 'standard-general',
          sectionTitle: 'General',
          formType: 'standard',
          fields: [
            {
              id: 'Terms_User_Date',
              title: 'Current Date',
              type: 'date',
              field: '$currentDate'
            },
            {
              id: 'dynamic-selection',
              title: 'Selection',
              type: 'selection',
              selections: document.fields.filter((o) => o.type === 'selection')
            },
            {
              id: 'dynamic-signature',
              title: 'Signatures',
              type: 'signature',
              selections: document.fields.filter((o) => o.type === 'signature')
            },
            {
              id: 'dynamic-initial',
              title: 'Initials',
              type: 'initials',
              selections: document.fields.filter((o) => o.type === 'initials')
            },
            {
              id: 'dynamic-text-input',
              title: 'Text Input',
              type: 'text-input'
            }
          ]
        },
        {
          id: 'standard-customer',
          sectionTitle: 'Customer',
          formType: 'standard',
          fields: [
            {
              id: 'customerFirst',
              title: 'Customer First Name',
              type: 'string',
              field: '$customerFirst'
            },
            {
              id: 'customerLast',
              title: 'Customer Last Name',
              type: 'string',
              field: '$customerLast'
            },
            {
              id: 'customerFullName',
              title: 'Customer Full Name',
              type: 'string',
              field: '$customerFullName'
            },
            {
              id: 'appointmentDate',
              title: 'Appointment Date',
              type: 'date',
              field: '$appointmentDate'
            },
            {
              id: 'appointmentTime',
              title: 'Appointment Start Time',
              type: 'time',
              field: '$appointmentStartTime'
            },
            {
              id: 'customerAddressStreet',
              title: 'Customer Address Street',
              type: 'string',
              field: '$customerAddressStreet'
            },
            {
              id: 'customerAddressCity',
              title: 'Customer Address City',
              type: 'string',
              field: '$customerAddressCity'
            },
            {
              id: 'customerAddressState',
              title: 'Customer Address State',
              type: 'string',
              field: '$customerAddressState'
            },
            {
              id: 'customerAddressZip',
              title: 'Customer Address Zip',
              type: 'string',
              field: '$customerAddressZip'
            }
          ]
        },
        {
          id: 'standard-company',
          sectionTitle: 'Company',
          formType: 'standard',
          fields: [
            {
              id: 'repFirstName',
              title: 'Rep First Name',
              type: 'string',
              field: '$repFirstName'
            },
            {
              id: 'repLast',
              title: 'Rep Last Name',
              type: 'string',
              field: '$repLast'
            },
            {
              id: 'repFullName',
              title: 'Rep Full Name',
              type: 'string',
              field: '$repFullName'
            },
            {
              id: 'companyName',
              title: 'Company Name',
              type: 'string',
              field: '$companyName'
            },
            {
              id: 'companyAddressStreet',
              title: 'Company Address Street',
              type: 'string',
              field: '$companyAddressStreet'
            },
            {
              id: 'companyAddressCity',
              title: 'Company Address City',
              type: 'string',
              field: '$companyAddressCity'
            },
            {
              id: 'companyAddressState',
              title: 'Company Address State',
              type: 'string',
              field: '$companyAddressState'
            },
            {
              id: 'companyAddressZip',
              title: 'Company Address Zip',
              type: 'string',
              field: '$companyAddressZip'
            }
          ]
        }
      ]
    }
  ]
}

const styles = {
  bottomNavigation: {
    position: 'fixed',
    width: '100%',
    left: 0,
    bottom: 0,
    padding: '16px',
    borderTop: 1,
    borderColor: 'rgba(0,0,0,.16)',
    backgroundColor: 'white'
  },
  leftPanel: {
    maxHeight: '100vh',
    overflow: 'auto',
    WebkitUserSelect: 'none'
  },
  centerNav: {
    display: 'flex',
    padding: '48px',
    width: '100vh',
    height: '100vh',
    maxHeight: 'calc(100vh - 128px)',
    overflow: 'auto',
    backgroundColor: '#c2c2c2',
    WebkitUserSelect: 'none'
  },
  rightPanel: {
    maxHeight: '100vh',
    overflow: 'auto',
    backgroundColor: '#c2c2c2',
    border: '4px solid rgba(0,0,0,.16)',
    WebkitUserSelect: 'none'
  }
}
