import React from 'react'
import { Grid, Typography } from '@mui/material'
import RightPanelCategories from './RightPanelCategories'
import RightPanelAssembly from './RightPanelAssembly'
import RightPanelProperties from './RightPanelProperties'
import RightPanelWarranties from './RightPanelWarranties'
import RightPanelTieredPricing from './RightPanelTieredPricing'
import RightPanelMargin from './RightPanelMargin'
import RightPanelEstimateProject from './RightPanelEstimateProject'
import RightPanelSalesCommission from './RightPanelSalesCommission'
import RightPanelDrawing from './RightPanelDrawing'
import RightPanelAdvancedFeatures from './RightPanelAdvancedFeatures'
import { RightPanelProductClause } from './RightPanelProductClause'

const RightPanel = (props) => {
  return (
    <Grid container style={styles.container}>
      {!props.rightPanelVariant
        ? (
          <Grid item xs={12} sx={{ p: 2 }}>
            <Typography variant='h7'>Please select an editor grouping on the left.</Typography>
          </Grid>
          )
        : null}
      <Grid item xs={12}>
        {props.rightPanelVariant === 'assembly' ? <RightPanelAssembly {...props} /> : null}
        {props.rightPanelVariant === 'categories' ? <RightPanelCategories {...props} /> : null}
        {props.rightPanelVariant === 'properties' ? <RightPanelProperties {...props} /> : null}
        {props.rightPanelVariant === 'warranties' ? <RightPanelWarranties {...props} /> : null}
        {props.rightPanelVariant === 'tiered-pricing' ? <RightPanelTieredPricing {...props} /> : null}
        {props.rightPanelVariant === 'margin' ? <RightPanelMargin {...props} /> : null}
        {props.rightPanelVariant === 'estimate-project' ? <RightPanelEstimateProject {...props} /> : null}
        {props.rightPanelVariant === 'sales-commission' ? <RightPanelSalesCommission {...props} /> : null}
        {props.rightPanelVariant === 'drawing' ? <RightPanelDrawing {...props} /> : null}
        {props.rightPanelVariant === 'advanced-features' ? <RightPanelAdvancedFeatures {...props} /> : null}
        {props.rightPanelVariant === 'productClause' ? <RightPanelProductClause {...props} /> : null}
      </Grid>
    </Grid>
  )
}
export default RightPanel

const styles = {
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white'
  }
}
