import React, { useState } from 'react'
import { Button, Grid, Popover, Typography } from '@mui/material'
import TableViewIcon from '@mui/icons-material/TableView'

const PopoverTables = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleTablePopoverOpen = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleTablePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Button disableRipple disableFocusRipple startIcon={<TableViewIcon style={styles.icon} />}>
      <Typography
        style={styles.title}
        onMouseEnter={handleTablePopoverOpen}
        onMouseLeave={handleTablePopoverClose}
        aria-owns={open ? 'mouse-over-modules-popover' : undefined}
        aria-haspopup='true'
      >
        {props.modules.length} Modules
      </Typography>
      <Popover
        id='mouse-over-modules-popover'
        sx={{
          pointerEvents: 'none',
          mt: 1
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handleTablePopoverClose}
        disableRestoreFocus
      >
        <Grid container sx={{ pt: 1, pb: 1, width: '300px' }}>
          <Grid item xs={12} style={{ borderBottom: '1px solid rgba(0,0,0,.08)' }}>
            <Typography sx={{ pl: 2, pt: 1, pb: 1 }} style={{ fontWeight: 'bold' }}>
              Modules
            </Typography>
          </Grid>
          {props.modules.map((t, idx) => {
            return (
              <Grid key={`popover-modules-grid-${idx}`} item xs={12} style={{ borderBottom: idx < props.modules.length - 1 ? '1px solid rgba(0,0,0,.08)' : null }}>
                <Typography variant='body2' sx={{ pl: 2, pt: 1, pb: 1 }}>
                  {t.title ? t.title : t.id}
                </Typography>
              </Grid>
            )
          })}
        </Grid>
      </Popover>
    </Button>
  )
}
export default PopoverTables

const styles = {
  title: {
    fontSize: '16px',
    letterSpacing: '.15px',
    lineHeight: '22px',
    color: 'rgba(0,0,0,.66)'
  },
  icon: {
    color: 'rgba(0,0,0,.66)'
  }
}
