import React, { useState, useEffect } from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { Box, Chip, Grid } from '@mui/material'
import { DialogGeneric } from './DialogGeneric'
import { LoadingPleaseWait } from '@supportworks/react-components'
import Company from 'screens/Company'
import H from '../../library/helper'

export const DialogAssetSelector = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [pageSize, setPageSize] = useState(10)
  const [selectedRows, setSelectedRows] = useState([]) // Array of row idx's of things that have been checked
  const [rows, setRows] = useState([]) // Array of row objects that can be selected
  const [filterChips, setFilterChips] = useState([]) // Array of unique library names and selected boolean status for filtering the display
  const [filteredRows, setFilteredRows] = useState([]) // Array of row objects filtered thru filterChips
  const [assets, setAssets] = useState([])

  useEffect(() => {
    if (isLoading) {
      const filterChip = [
        { id: 'All', selected: true },
        { id: 'Selected', selected: false },
        { id: 'Video', selected: false },
        { id: 'Image', selected: false },
        { id: 'Document', selected: false }
      ]

      // You can provide a filterOverride prop that is an array of filterChip id's.
      //
      let newAssets = []
      if (props.filterOverride) {
        // Filter my filterChip.  If I have a filterOverride, and the filterChip id is not in it, splice it out.
        // We'll do this in reverse order so we don't have to worry about array positioning getting out of whack.
        //
        const filterOverrideString = props.filterOverride.join('__')
        let i = filterChip.length
        while (i--) {
          if (filterChip[i].id.match(/Video|Image|Document/)) {
            if (props.filterOverride.indexOf(filterChip[i].id) < 0) {
              filterChip.splice(i, 1)
            }
          }
        }
        // Filter assets.  If I don't do this, the selectedRows and row array positions will not match.
        //
        for (let x = 0; x < props.assets.length; x++) {
          const type = props.assets[x].type
          const re = new RegExp(type, 'gi')
          if (filterOverrideString.match(re)) {
            newAssets.push(props.assets[x])
          }
        }
        setAssets(newAssets)
      } else {
        newAssets = props.assets
        setAssets(newAssets)
      }

      // Loop thru my assets list and set my selectedRows and rows.
      // S
      const r = []
      const sr = []
      let filter = []
      for (let x = 0; x < newAssets.length; x++) {
        const a = newAssets[x]
        const l = props.list.find((o) => o.asset === a.id)
        if (l && l.asset) {
          sr.push(x)
        }
        let chipName
        if (a.libraryName) {
          chipName = a.libraryName
        } else {
          const currentCompany = Company.getCurrent()
          chipName = currentCompany.name
        }
        const exists = filter.find((o) => o.id === chipName)
        if (!exists) {
          filter.push({
            id: chipName,
            selected: false
          })
        }
        r.push({
          id: x,
          type: a.type ? a.type : '-',
          path: a.path ? a.path : null,
          previewURL: a.previewURL ? a.previewURL : null,
          title: a.title ? a.title : '-',
          library: chipName
        })
      }
      filter.sort((a, b) => (a.id > b.id ? 1 : -1))
      filter = [...filterChip, ...filter]
      setRows(r)
      setFilteredRows(r)
      setSelectedRows(sr)
      setFilterChips(filter)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleSave = () => {
    const list = []
    for (let x = 0; x < selectedRows.length; x++) {
      const row = assets[selectedRows[x]]
      list.push({
        asset: row.id,
        title: row.title,
        poster: row.id
      })
    }
    props.onSave(list)
  }

  const handleChange = (r) => {
    if (props.singleUseOnly) {
      const f = r.filter((x) => !selectedRows.includes(x))
      setSelectedRows(f)
    } else {
      setSelectedRows(r)
    }
  }

  const handleChipSelect = (value) => {
    const [fr, fc] = H.filterDataGridUsingChipFilter({ value, rows, selectedRows, filterChips })
    setFilterChips(fc)
    setFilteredRows(fr)
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Grid container>
          <Grid item xs={12}>
            <Grid container sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <GridToolbarQuickFilter size='small' variant='outlined' xs={6} sx={{ pt: 1, pl: 2, pr: 2, pb: 1 }} />
            </Grid>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    )
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    { field: 'id', headerName: 'Id', hide: true },
    { field: 'type', headerName: 'Type' },
    {
      field: 'previewURL',
      headerName: 'Preview',
      renderCell: (params) => {
        if (params.row.previewURL) {
          return (
            <a href={params.row.path} target='_blank' rel='noreferrer'>
              <img src={params.row.previewURL} alt={params.row.title} width='100%' />
            </a>
          )
        }
        return null
      }
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 2,
      renderCell: (params) => {
        return (
          <Box>
            <strong>{params.row.title}</strong>
          </Box>
        )
      }
    },
    { field: 'library', headerName: 'Library', flex: 1 }
  ]

  const jsx = (
    <Box>
      <Box sx={{ pb: 1 }}>
        {filterChips.map((f) => {
          return (
            <Chip
              key={`Chip-${f.id}`}
              sx={{ p: 2, mr: 1 }}
              color={f.selected === true ? 'primary' : 'default'}
              label={f.id}
              onClick={() => handleChipSelect(f.id)}
            />
          )
        })}
      </Box>
      <Box sx={{ height: 500 }}>
        <DataGrid
          sx={{
            '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
              color: '#A6B0CF',
              backgroundColor: 'transparent'
            }
          }}
          density='comfortable'
          rows={filteredRows}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50]}
          checkboxSelection
          rowSelectionModel={selectedRows}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onRowSelectionModelChange={(rows) => {
            handleChange(rows)
          }}
          keepNonExistentRowsSelected
          slots={{ toolbar: CustomToolbar }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false
              }
            }
          }}
        />
      </Box>
    </Box>
  )

  return <DialogGeneric title={props.title} content={jsx} fullWidth onChange={handleSave} onClose={props.onClose} />
}
