import React, { useState, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CircleIcon from '@mui/icons-material/Circle'
import { FieldAction } from '../../components/FieldAction'
import { findFieldNumber } from '../PDFDocumentEditor'
import { fieldTypeConfig } from '../../../index'

const FieldNav = (props) => {
  const [selected, setSelected] = useState(false)

  useEffect(() => {
    if (props.selectedField && props.field && (props.field.id === props.selectedField.id || props.field.id === props.selectedField.editorId)) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }, [props.field, props.selectedField])

  const field = props.field
  let icon = <></>
  const f = fieldTypeConfig[field.type]
  if (f && f.icon) {
    icon = f.icon
  } else {
    // console.log(`Field has unknown type (${field.type}) ${JSON.stringify(field)}`);
  }

  const number = findFieldNumber(props.document, field)
  return (
    <div
      id={`fieldnav-${field.id}`}
      key={`fieldnav-${field.id}`}
      style={styles.pointer}
      draggable
      onDragStart={(e) => {
        props.onDragStart({ formId: props.formId, field })
      }}
      onDragEnd={props.onDragEnd}
      onDragExit={props.onDragExit}
    >
      <Box
        key={field.id}
        onClick={() => {
          props.onFieldSelect(field, -1)
        }}
      >
        <Grid container spacing={0} sx={{ pl: 2, pt: 1, pb: 1, pr: 1 }} style={styles.navContainer}>
          <Grid item sm={1}>
            {icon}
          </Grid>
          <Grid item sm={9} sx={{ pl: 1 }}>
            <Typography style={styles.navText}>
              {field.title}
            </Typography>
          </Grid>
          <Grid item sm={1}>
            {number > -1
              ? (
                <IconButton>
                  <CircleIcon fontSize='small' style={selected ? { color: 'blue' } : { color: 'black' }} />
                  <Typography style={styles.iconText}>{number}</Typography>
                </IconButton>
                )
              : null}
          </Grid>
          <Grid item sm={1}>
            <FieldAction {...props} />
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
export default FieldNav

const styles = {
  navContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    WebkitUserSelect: 'none'
  },
  navSubContainer: {
    backgroundColor: 'rgba(0,0,0,.04)'
  },
  navText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 14,
    paddingTop: '0px',
    userSelect: 'none'
  },
  iconText: {
    position: 'absolute',
    fontSize: 8,
    fontWidth: 'bold',
    fontFamily: 'sans-serif',
    color: 'white'
  },
  pointer: {
    cursor: 'pointer'
  }
}
