/* eslint-disable no-throw-literal */
import { ApiPlugin } from './ApiBase'
import Cookies from 'universal-cookie'
import { Topics } from 'config/topics'
import LocalStorage from '../LocalStorage'
import Config from '../../config'
// import { isArray } from 'jquery'

/**
 * Non-specific app Authenticator
 */
class ApiPluginAuth extends ApiPlugin {
  static COOKIE_NAME = 'ApiAuth_SessionID'

  static TOKEN_HEADER = 'X-Core-Authorization'

  static CORE_TYPE_FIELD = '$type'
  static CORE_SESSION_OBJECT_TYPE = 'CoreBundle\\Entity\\AppSession'

  /**
     * @type {Cookies}
     */
  cookies

  /**
     * @type {Object}
     */
  session

  /**
   * @type {Array}
   */
  sessionOrganizations = []

  /**
   * @type {Object} sessionUser
   *
   * @memberof ApiPluginAuth
   */
  sessionUser

  /**
     * @type {string}
     */
  token

  /**
     * Subscriptions.
     *
     * @type {Object}
     */
  subs

  contexts

  /**
     * @type {string|null}
     */
  orgId = null

  /**
     *
     * @param {ApiBase} apiBase
     * @param {Object} props
     */
  constructor (apiBase, props) {
    super(apiBase, props)

    this.cookies = new Cookies()
    this.token = this.cookies.get(ApiPluginAuth.COOKIE_NAME)
    if (this.token) {
      this.getApiBase().setHeader(ApiPluginAuth.TOKEN_HEADER, this.token)
    }

    if (props && props.baseUrl) {
      this.baseUrl = props.baseUrl
    }

    apiBase.registerMethod('authenticate', this.authenticate.bind(this))
    apiBase.registerMethod('canEditPermission', this.canEditPermissionCall.bind(this))
    apiBase.registerMethod('getAuthContexts', this.getAuthContexts.bind(this))
    apiBase.registerMethod('getAuthContext', this.getAuthContext.bind(this))
    apiBase.registerMethod('getSession', this.getSession.bind(this))
    apiBase.registerMethod('getSessionOrganizations', this.getSessionOrganizations.bind(this))
    apiBase.registerMethod('setSessionOrganizations', this.setSessionOrganizations.bind(this))
    apiBase.registerMethod('getSessionUser', this.getSessionUser.bind(this))
    apiBase.registerMethod('setSessionUser', this.setSessionUser.bind(this))
    apiBase.registerMethod('hasRole', this.hasRoleCall.bind(this))
    apiBase.registerMethod('hasPermission', this.hasPermissionCall.bind(this))
    apiBase.registerMethod('setSession', this.setSession.bind(this))
    apiBase.registerMethod('getToken', this.getToken.bind(this))
    apiBase.registerMethod('isAuthenticated', this.isAuthenticated.bind(this))
    apiBase.registerMethod('logout', this.logout.bind(this))
    apiBase.registerMethod('setOrgId', this.setOrgId.bind(this))
    apiBase.registerMethod('unsetOrgId', this.unsetOrgId.bind(this))
    apiBase.registerMethod('companyIsSelected', this.companyIsSelected.bind(this))

    this.subs = {
      validSession: Topics.subscribe(Topics.ValidateSession, this.setSession.bind(this))
    }
  }

  /**
     * Retrieves a list of authentication contexts.
     *
     * @param props
     */
  getAuthContexts (props) {
    if (!props) {
      props = {}
    }
    if (this.contexts && Object.keys(this.contexts).length > 0) {
      if (props.callback) {
        return Promise.resolve(props.callback(this.contexts))
      }
      return Promise.resolve(this.contexts)
    }

    this.contexts = {}
    const route = this.getBaseUrl() + '/getAuthContexts'
    return this.getApiBase()
      .get(route, [])
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */
        if (rsp && rsp.status === 'success') {
          for (const datum of rsp.data) {
            this.contexts[datum.shortcode] = datum
          }
        }
        if (props.callback) {
          props.callback(this.contexts)
        }
        return this.contexts
      })
  }

  /**
     * Retrieves a list of authentication contexts matching shortcode
     *
     * @param props
     */
  getAuthContext (props) {
    if (!props) {
      props = { shortcode: null }
    }
    const route = this.getBaseUrl() + '/getAuthContext/' + props.shortcode
    return this.getApiBase()
      .get(route, [])
      .then((rsp) => {
        /**
           * @type {ResponseEnvelope} rsp
           */
        const contexts = {}
        if (rsp && rsp.status === 'success') {
          for (const datum of rsp.data) {
            contexts[datum.shortcode] = datum
          }
        }
        if (props.callback) {
          props.callback(contexts)
        }
        return contexts
      })
  }

  /**
     *
     * @param {Object} props Object containing username, password
     * @param {string} props.username
     * @param {string} props.password
     * @param {string} [props.context=null]
     * @param {function} [props.callback=null] Callback upon successful login
     *
     * @return Promise
     */
  authenticate (props) {
    const payload = {}
    if (props.context) {
      payload.context = props.context
    }
    let context = null
    if (payload.context && Object.prototype.hasOwnProperty.call(this.contexts, payload.context)) {
      context = this.contexts[payload.context]
    } else {
      context = {
        shortcode: null,
        collectUsername: false,
        collectPassword: false
      }
    }

    if (context.collectUsername) {
      if (props.username) {
        payload.username = props.username
      } else {
        throw 'Username is required for authentication'
      }
      if (context.collectPassword) {
        if (props.password) {
          payload.password = props.password
        } else {
          throw 'Password is required for authentication'
        }
      }
    }
    if (props.redirectUrl) {
      payload.redirectUrl = props.redirectUrl
    }

    const route = this.getBaseUrl() + '/login'
    return this.getApiBase()
      .post(route, payload)
      .then((rsp) => {
        /**
                 * @type {ResponseEnvelope} rsp
                 */
        if (rsp && rsp.status === 'success') {
          for (const datum of rsp.data) {
            if (datum[ApiPluginAuth.CORE_TYPE_FIELD] === ApiPluginAuth.CORE_SESSION_OBJECT_TYPE) {
              this.session = datum
              this.token = this.session.token
              const expDate = new Date()
              expDate.setDate(expDate.getDate() + 7) // 7-day expiration on cookie;
              this.cookies.set(ApiPluginAuth.COOKIE_NAME, this.session.token, {
                expires: expDate,
                path: '/'
              })

              if (Object.prototype.hasOwnProperty.call(datum, 'flashData') && Object.prototype.hasOwnProperty.call(datum.flashData, 'requestDecoration') && Object.prototype.hasOwnProperty.call(datum.flashData.requestDecoration, 'headers')) {
                for (const hdr of Object.keys(datum.flashData.requestDecoration.headers)) {
                  this.getApiBase().setHeader(hdr, datum.flashData.requestDecoration.headers[hdr])
                }
              }
              this.getApiBase().setHeader(ApiPluginAuth.TOKEN_HEADER, this.session.token)
              if (Object.prototype.hasOwnProperty.call(datum, 'authRedirectUrl') && datum.authRedirectUrl) {
                window.location = datum.authRedirectUrl
                return null
              }
            }
          }
        }
        if (props.callback) {
          props.callback(rsp)
        }
        return rsp
      })
  }

  isAuthenticated () {
    return !!this.token
  }

  /**
     * Determines whether or not the current user is authenticated.
     *
     * @return {*}
     */
  getSession () {
    if (this.session) {
      return this.session
    }
    return {}
  }

  /**
     * Sets session data.
     *
     * @param session
     */
  setSession (session) {
    this.session = session
  }

  /**
   * Gets session user.
   *
   * @return {*}
   * @memberof ApiPluginAuth
   */
  getSessionUser () {
    if (this.sessionUser) {
      return this.sessionUser
    }
    return {}
  }

  /**
   * Sets user session data.
   *
   * @param {*} session
   * @memberof ApiPluginAuth
   */
  setSessionUser (session) {
    this.sessionUser = session
  }

  /**
     *
     * @return {*}
     */
  getSessionOrganizations () {
    if (this.sessionOrganizations) {
      return this.sessionOrganizations
    }
    return []
  }

  /**
     * Sets user session data.
     *
     * @param session
     */
  setSessionOrganizations (session) {
    if (Array.isArray(session)) {
      this.sessionOrganizations = session
    } else {
      this.sessionOrganizations = []
    }
  }

  /**
     * Gets session token.
     * @return {string|null}
     */
  getToken () {
    if (this.session) {
      return this.session.token
    }
    return null
  }

  logout () {
    if (this.token) {
      this.getApiBase().removeHeader(ApiPluginAuth.TOKEN_HEADER)
      this.cookies.remove(ApiPluginAuth.COOKIE_NAME, { path: '/' })
      this.token = null
    }
  }

  /**
     *
     * @param {Object} props
     * @param {string} props.orgId
     * @param {string} props.permission
     * @param {boolean} props.defaultValue
     * @param {string} props.appShortCode
     * @return {*|boolean}
     */
  canEditPermissionCall (props = {}) {
    let orgId = this.orgId
    let permission = ''
    let defaultValue = false
    let appShortCode = this.apiBase.appShortCode

    if ('orgId' in props) {
      orgId = props.orgId
    }
    if ('permission' in props) {
      permission = props.permission
    }
    if ('defaultValue' in props) {
      defaultValue = props.defaultValue
    }
    if ('appShortCode' in props && props.appShortCode) {
      appShortCode = props.appShortCode
    }

    if (!orgId || orgId === '') {
      throw 'Missing orgId parameter in call to Api.canEditPermission'
    }
    if (!permission || permission === '') {
      throw 'Missing permission parameter in call to Api.canEditPermission'
    }

    return this.canEditPermission(orgId, permission, defaultValue, appShortCode)
  }

  /**
     *
     * @param {string} orgId organization id
     * @param {string} permission name of permission to check
     * @param {*} defaultValue Default value if none found
     * @param {string} appShortCode App Identifier (shortcode)
     */
  canEditPermission (orgId, permission, defaultValue = false, appShortCode = null) {
    if (!this.session) {
      // This should throw an exception because the the authenticity of the user hasn't been verified
      throw "User authenticity has not been established. Call 'setSession' with a valid session object before this method."
    }

    if (!appShortCode) {
      appShortCode = this.apiBase.appShortCode
    }

    const myOrgs = LocalStorage.retrieve(Config.myOrgsKey)
    let authorization = null
    if (myOrgs && myOrgs.length) {
      for (const org of myOrgs) {
        if (org.id === orgId) {
          authorization = org.authorization
        }
      }
    }
    if (authorization == null) {
      return defaultValue
    }
    if (appShortCode in authorization) {
      if ('permissions' in authorization[appShortCode]) {
        if (permission in authorization[appShortCode].permissions) {
          if ('editability' in authorization[appShortCode].permissions[permission]) {
            let auth = true
            if ('roles' in authorization[appShortCode].permissions[permission].editability) {
              authorization[appShortCode].permissions[permission].editability.roles.map((r) => {
                auth &= this.hasRole(orgId, r)
                return false
              })
            }
            if (auth && 'permissions' in authorization[appShortCode].permissions[permission].editability) {
              authorization[appShortCode].permissions[permission].editability.permissions.map((p) => {
                auth &= this.hasPermission(orgId, p)
                return false
              })
            }
            return auth
          }
          return authorization[appShortCode].permissions[permission]
        }
      }
    }
    return defaultValue
  }

  /**
     *
     * @param {Object} props
     * @param {string} props.orgId
     * @param {string} props.permission
     * @param {string} props.appShortCode
     * @return {*|boolean}
     */
  hasPermissionCall (props = {}) {
    let orgId = this.orgId
    let permission = ''
    let appShortCode = this.apiBase.appShortCode

    if ('orgId' in props) {
      orgId = props.orgId
    }
    if ('permission' in props) {
      permission = props.permission
    }
    if ('appShortCode' in props && props.appShortCode) {
      appShortCode = props.appShortCode
    }

    if (!orgId || orgId === '') {
      throw 'Missing orgId parameter in call to Api.hasPermission'
    }
    if (!permission || permission === '') {
      throw 'Missing permission parameter in call to Api.hasPermission'
    }

    return this.hasPermission(orgId, permission, appShortCode)
  }

  /**
     *
     * @param {string} orgId organization id
     * @param {string} permission name of permission to check
     * @param {string} appShortCode App Identifier (shortcode)
     */
  hasPermission (orgId, permission, appShortCode) {
    if (!appShortCode) {
      appShortCode = this.apiBase.appShortCode
    }

    const myOrgs = LocalStorage.retrieve(Config.myOrgsKey)
    let authorization = null
    if (myOrgs && myOrgs.length) {
      for (const org of myOrgs) {
        if (org.id === orgId) {
          authorization = org.authorization
        }
      }
    }
    if (authorization == null) {
      return false
    }
    if (appShortCode in authorization) {
      if ('permissions' in authorization[appShortCode]) {
        if (permission in authorization[appShortCode].permissions) {
          return authorization[appShortCode].permissions[permission]
        }
      }
    }
    return false
  }

  /**
     *
     * @param {Object} props
     * @param {string} props.orgId
     * @param {string} props.permission
     * @param {string} props.appShortCode
     * @return {boolean}
     */
  hasRoleCall (props = {}) {
    let orgId = this.orgId
    let role = ''
    let appShortCode = this.apiBase.appShortCode

    if ('orgId' in props) {
      orgId = props.orgId
    }
    if ('role' in props) {
      role = props.role
    }
    if ('appShortCode' in props && props.appShortCode) {
      appShortCode = props.appShortCode
    }
    if (!orgId || orgId === '') {
      throw 'Missing orgId parameter in call to Api.hasRole'
    }
    if (!role || role === '') {
      throw 'Missing permission parameter in call to Api.hasRole'
    }

    return this.hasRole(orgId, role, appShortCode)
  }

  /**
     *
     * @param {string} orgId organization id
     * @param {string} role name of role to check
     * @param {string} appShortCode App Identifier (shortcode)
     */
  hasRole (orgId, role, appShortCode = null) {
    if (!appShortCode) {
      appShortCode = this.apiBase.appShortCode
    }

    const myOrgs = LocalStorage.retrieve(Config.myOrgsKey)
    let authorization = null
    if (myOrgs && myOrgs.length) {
      for (const org of myOrgs) {
        if (org.id === orgId) {
          authorization = org.authorization
        }
      }
    }
    if (authorization == null) {
      return false
    }
    if (appShortCode in authorization) {
      if ('roles' in authorization[appShortCode]) {
        if (role in authorization[appShortCode].roles) {
          return authorization[appShortCode].roles[role]
        }
      }
    }
    return false
  }

  /**
     *
     * @param {Object} props
     * @param {string} props.orgId
     */
  setOrgId (props = {}) {
    if (props.constructor === String) {
      this.orgId = props
    } else if (props.constructor === Object) {
      this.orgId = props.orgId
    }
  }

  /**
     *
     * @param {Object} props
     * @param {string} props.orgId
     * @return boolean
     */
  companyIsSelected (props = {}) {
    return this.orgId !== null && this.orgId !== undefined
  }

  /**
     *
     */
  unsetOrgId () {
    this.orgId = null
  }
}

export { ApiPluginAuth }
