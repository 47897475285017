import React, { useEffect, useState } from 'react'
import FieldTypeAddLogicPanel from '../FieldTypeAddLogicPanel'
import FieldTypeExpandMarker from '../Reusables/FieldTypeExpandMarker'
import { Paper } from '@mui/material'
import TextInputBasic from '../Reusables/TextInputBasic'

function RelatedInspectAreas (props) {
  // const [field, setField] = useState(props.field)
  const [area, setArea] = useState('fieldValues' in props ? props.field.fieldValues : '')

  const changeHandlerVisible = (visible) => {
    props.onChangeVisible(visible)
  }

  const changeHandlerArea = (val) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    newField.fieldValues = val
    setArea(val)
    props.onChange(newField)
  }

  /* useEffect(() => {
    setField(props.field)
  }, [props.field]) */

  useEffect(() => {
    setArea(props.field.fieldValues)
  }, [props.field.fieldValues])

  return (
    <>
      {
                props.expandMarker &&
                  <FieldTypeExpandMarker />
            }

      <div style={styles.logicPanel}>
        <FieldTypeAddLogicPanel
          field={props.field}
          onChangeVisible={(visible) => changeHandlerVisible(visible)}
        />
        <div style={{ display: 'flex' }}>
          <FieldTypeExpandMarker />
          <Paper elevation={0} square style={styles.addOptionPaper}>
            <div style={styles.areaTypeLabel}>Area Type</div>
            <div style={styles.optionsMainDiv}>
              <TextInputBasic
                placeholder='Enter Area Here'
                inputValue={area || ''}
                onChange={(value) => changeHandlerArea(value)}
              />
            </div>
          </Paper>
        </div>
      </div>
    </>
  )
}

const styles = {
  relatedMain: {
    display: 'flex'
  },
  logicPanel: {
    // width: "100%"
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%'
  },
  singleSelectionPaper: {
    borderTop: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    borderLeft: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    // marginRight: "96.19px",
    // width: "100%"
    height: '50px',
    flexBasis: '100%',
    paddingRight: '5px'
  },
  addOptionPaper: {
    borderTop: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    borderLeft: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    // marginRight: "96.19px",
    // width: "100%",
    height: '50px',
    flexBasis: '100%',
    paddingRight: '5px'
  },
  addOptionBtn: {
    textTransform: 'none',
    marginLeft: '5px',
    fontSize: '13px'
  },
  addOptionIconBtn: {
    padding: '8px'
  },
  addOptionIcon: {
    fontSize: '20px'
  },
  areaTypeLabel: {
    fontSize: '13px',
    color: '#000000a3',
    marginLeft: '10px',
    marginRight: '5px',
    width: '10%'
  },
  optionsMainDiv: {
    display: 'flex',
    flexBasis: '100%'
  },
  optionsMainDivIcon: {
    marginLeft: '12px',
    whiteSpace: 'nowrap'
  }
}
export default RelatedInspectAreas
