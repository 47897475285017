import React from 'react'
import { Header } from '../components/Reusable'
import ErrorBoundary from 'components/ErrorBoundary'
import HeaderMenus from '../components/HeaderMenus'

function withScreenWrapper (WrappedComponent) {
  return class extends React.Component {
    render () {
      return (
        <div className='PageContent'>
          <Header>
            <HeaderMenus organizationId={this.props.organizationId} />
          </Header>
          <ErrorBoundary>
            <WrappedComponent {...this.props} />
          </ErrorBoundary>
        </div>
      )
    }
  }
}

export default withScreenWrapper
