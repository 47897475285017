/* eslint-disable no-throw-literal */
/**
 * Contains base methods for interacting with a CoreApi.
 */
export default class ApiBase {
  /**
   * Singleton instance.
   *
   * @type ApiBase
   */
  static instance

  /**
   * Maps actions to method name executing them.
   * @type {Object.<string, string>}
   */
  static actionMap = {
    get: 'get',
    GET: 'get',
    post: 'post',
    POST: 'post',
    put: 'put',
    PUT: 'put',
    delete: 'delete',
    DELETE: 'delete'
  }

  /**
   * Global list of registered endpoints.
   *
   * @type {Object<string, ApiEndpoint>}
   */
  static endpoints = {}

  /**
   * Any methods plugins wish to register at the top-level.
   *
   * @type {Object<string, function>}
   */
  registeredMethods = {}

  /**
   * Structure used to determine headers to pass to requests
   * @type {{removed: {}, modified: {}, base: {Accept: string, dataType: string, 'Content-Type': string}}}
   */
  headers = {
    base: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      dataType: 'json'
    },
    modified: {},
    removed: {}
  }

  /**
   * @type {string}
   */
  baseUrl

  /**
   * Endpoints registered to this Api.
   *
   * @type {Object<string, ApiEndpoint>}
   */
  localEndpoints = {}

  /**
   * Any registered plugins
   * @type {Object}
   */
  plugins = {}

  /**
   * App Signature used to identify app on the server.
   *
   * @type {string}
   */
  appShortCode = null

  /**
   * Props with which the app was configured.
   * @type {Object}
   */
  props = {}

  /**
   * Object containing configuration properties.
   * @param {Object} props
   */
  constructor (props = {}) {
    this.props = { ...props }
    if (this.props.baseUrl) {
      console.log('ApiBase.baseUrl = "' + props.baseUrl + '"')
      this.baseUrl = props.baseUrl
    }
    if (this.props.appShortCode) {
      console.log('ApiBase.appShortCode = "' + props.appShortCode + '"')
      this.appShortCode = props.appShortCode
    }

    ApiBase.instance = this
  }

  cacheResponses () {
    return false
  }

  getBaseUrl () {
    return this.baseUrl
  }

  /**
   *
   * @param {string} header header to remove.
   */
  removeHeader (header) {
    if (this.headers && this.headers.removed) {
      this.headers.removed[header] = true
    }
  }

  /**
   * Restores a header to its initial state, as defined in this.headers.base
   * @param {string} header
   */
  resetHeader (header) {
    if (this.headers.removed(header)) {
      delete this.headers.removed[header]
    }
    if (this.headers.modified(header)) {
      delete this.headers.modified[header]
    }
  }

  setHeader (header, value) {
    if (this.headers.removed[header]) {
      delete this.headers.removed[header]
    }
    this.headers.modified[header] = value
  }

  /**
   * Calculates headers to send to an HTTP request.
   *
   * @return {{Accept: string, dataType: string, 'Content-Type': string}}
   */
  getHeaders () {
    const headers = { ...this.headers.base, ...this.headers.modified }
    for (const header in this.headers.removed) {
      if (this.headers.removed && Object.prototype.hasOwnProperty.call(headers, header)) {
        delete headers[header]
      }
    }

    return headers
  }

  headersRaw () {
    const headers = {}

    if (this.coreSession) {
      headers['X-Core-Authorization'] = this.coreSession.token
    }

    return headers
  }

  get (route, params) {
    return this.xhr(route, params, 'GET')
  }

  put (route, params) {
    return this.xhr(route, params, 'PUT')
  }

  /**
   *
   * @param route
   * @param params
   * @param {object} headers
   * @returns {Promise<Response>}
   */
  post (route, params, headers = {}) {
    return this.xhr(route, params, 'POST', headers)
  }

  delete (route, params) {
    return this.xhr(route, params, 'DELETE')
  }

  /**
   *
   * @param route
   * @param params
   * @param verb
   * @param {object} headers
   * @returns {Promise<Response>}
   */
  xhr (route, params, verb, headers = {}) {
    let url = route
    let options
    if (verb === 'GET' && params) {
      // Add params to the URL
      const p = Object.keys(params)
        .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&')
      url = url + '?' + p
      options = Object.assign({ method: verb }, null)
    } else {
      options = Object.assign({ method: verb }, params ? { body: JSON.stringify(params) } : null)
    }
    options.headers = { ...this.getHeaders(), ...headers }
    // ApiBase.dev_log("request", verb, url, options);
    return fetch(url, options)
      .then((response) => {
        if (response.ok) {
          // ApiBase.dev_log("response", verb, url, response);
          return response.json()
        } else if (response.status === 401) {
          ApiBase.dev_log(response.status + ' response', verb, url, response)
          return response.json()
          // throw new Error("Username or Password was not correct.");
        } else {
          ApiBase.dev_log(response.status + ' response', verb, url, response)
          return response.json()
          // throw new Error("An API error occurred.");
        }
      })
      .then((responseJson) => {
        // ApiBase.dev_log("response json", verb, url, responseJson);
        return responseJson
      })
      .catch((error) => {
        ApiBase.dev_log('API ERROR', '', '', error)
        // throw error;
      })
  }

  static dev_log (action, verb, route, data) {
    if (verb === undefined && route === undefined && data === undefined) {
      console.log(action)
    } else {
      console.log('API ' + action.toUpperCase() + ' : ' + verb.toUpperCase() + ' ' + route + ' ' + '>'.repeat(5))
      typeof data === 'object' ? console.dir(data) : console.log(JSON.stringify(data))
      console.log('^'.repeat(20))
    }
  }

  /**
   * Registers a plugin (calls its constructor)
   *
   * @param {function|ApiPlugin} plugin ApiPlugin class
   * @param {Object} props
   * @param {string} [props.alias]
   * @param {string} [props.baseUrl]
   */
  registerPlugin (plugin, props) {
    let alias = null
    if (props && props.alias) {
      alias = props.alias
    }
    if (!alias) {
      if (plugin.getPluginAlias) {
        alias = plugin.getPluginAlias()
      } else {
        alias = plugin.name
      }
    }
    if (this.plugins[alias]) {
      if (this.plugins[alias] === plugin) {
        return
      }
    }

    // eslint-disable-next-line new-cap
    this.plugins[alias] = new plugin(this, props)
  }

  /**
   * Adds a method to
   * @param methodName
   * @param method
   */
  registerMethod (methodName, method) {
    this.registeredMethods[methodName] = method
    return this
  }

  /**
   * Calls a registered method with the specified parameters.
   *
   * @param {string} methodName Name of the registered method
   * @param {Object} [parameters=null] Any parameters to pass.
   *
   * @return {*}
   */
  static async callRegisteredMethod (methodName, parameters = null) {
    if (this.instance.registeredMethods[methodName]) {
      return this.instance.registeredMethods[methodName](parameters)
    }
    return null
  }

  /**
   * Calls a registered method with the specified parameters.
   *
   * @param {string} methodName Name of the registered method
   * @param {Object} [parameters=null] Any parameters to pass.
   *
   * @return {*}
   */
  static callSyncRegisteredMethod (methodName, parameters = null) {
    if (this.instance.registeredMethods[methodName]) {
      return this.instance.registeredMethods[methodName](parameters)
    }
    return null
  }

  /**
   *
   * @param props
   * @return {ApiBase}
   */
  static factory (props) {
    return new ApiBase(props)
  }

  /**
   * Processes a parameterized path.
   *
   * @param {string} path
   * @param {Object} parameters
   */
  static resolvePath (path, parameters = {}) {
    if (!path) {
      return ''
    }
    return path.replace(/(:[^/]+)/g, (match) => {
      const field = match.substr(1)
      if (Object.prototype.hasOwnProperty.call(parameters, field)) {
        return parameters[field]
      }
      return match
    })
  }

  /**
   * Constructs a query string from passed parameters without a leading question mark.
   *
   * @param {Object} props Monifest of properties from which to build the query string
   * @param {String[]} [keys] keys (potentially) in the props object to include in the query string.
   * @param {boolean} [hideEmpty=true] Whether or not to hide query parameters that are empty.
   *
   * @return {string}
   */
  static buildQueryString (props, keys = null, hideEmpty = true) {
    const qs = []
    if (!keys) {
      keys = []
      for (const key in props) {
        keys[keys.length] = key
      }
    }
    if (keys) {
      for (const key of keys) {
        if (props && props[key]) {
          qs[qs.length] = key + '=' + encodeURIComponent(props[key])
        } else if (!hideEmpty) {
          qs[qs.length] = key
        }
      }
    }
    return qs.join('&', qs)
  }
}

/**
 * Endpoint registered with an API.  The names provided need to be globally unique, even across API instances.
 */
class ApiEndpoint {
  /**
   * @var {ApiPlugin}
   */
  plugin

  name

  /**
   * HTTP Action
   */
  action

  parameters

  /**
   *
   * @param {ApiPlugin} api
   * @param {Object} props
   */
  constructor (api, props) {
    if (props.action) {
      this.action = props.action
    } else {
      this.action = 'get'
    }

    if (props.name) {
      this.name = props.name
    }
    if (props.parameters) {
      this.parameters = props.parameters
    }
  }

  call (props) {
    const action = this.action
    const mappedAction = ApiBase.actionMap[action]
    if (!action) {
      throw 'Invalid action specified for ApiEndpoint call: ' + action
    }

    return this.apiBase[mappedAction](this.api)
  }
}

/**
 * Collections of endpoint methods that can register with the apiBase.
 *
 * These endpoints are registered when the plugin is constructed.
 */
class ApiPlugin {
  /**
   * @var {ApiBase} apiBase
   */
  apiBase

  /**
   * @var {string} baseUrl
   */
  baseUrl

  /**
   *
   * @param {ApiBase} apiBase
   * @param {Object} [props = null]
   */
  constructor (apiBase, props) {
    this.apiBase = apiBase
    if (!props) {
      props = {}
    }
    if (props.baseUrl) {
      this.baseUrl = props.baseUrl
    }
  }

  getApiBase () {
    return this.apiBase
  }

  getApiBaseUrl () {
    return this.apiBase.baseUrl
  }

  /**
   * Returns local base url if defined, or that of the containing ApiBase.
   * @return {string}
   */
  getBaseUrl () {
    if (this.baseUrl) {
      return this.baseUrl
    }
    return this.apiBase.getBaseUrl()
  }
}

export { ApiBase, ApiEndpoint, ApiPlugin }
