import React, { useState, useEffect } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { FormCardComplete } from './components/FormCardComplete'
import { DialogFormSelector } from 'components/Reusable/DialogFormSelector'

const RightPanelComplete = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [list, setList] = useState([])
  const [formsConfig, setFormsConfig] = useState([])

  useEffect(() => {
    if (isLoading) {
      if (props.meeting.conclusion && props.meeting.conclusion.formId) {
        setList([props.meeting.conclusion])
      }
      if (props.formsConfig) {
        // const l = props.formsConfig.filter((f) => f.formType.match(/conclusion/i))
        const l = props.formsConfig.filter((f) => f.formType.match(/complete/i))
        setFormsConfig(l)
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (l) => {
    // Conclusion can only have 1 form, but I wanted to use the same list pattern as the others...
    const m = JSON.parse(JSON.stringify(props.meeting))
    if (l.length) {
      m.conclusion = {
        title: l[0].title ? l[0].title : 'No Title',
        formId: l[0].formId ? l[0].formId : ''
      }
    } else {
      delete m.conclusion
    }
    props.setMeeting(m)
    setList(l)
  }

  const handleDelete = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    l.splice(idx, 1)
    handleChange(l)
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Box style={styles.container} sx={{ p: 2 }}>
        <Stack sx={{ pb: 2 }}>
          <Typography variant='h6'>Complete Form</Typography>
          <Typography variant='body2'>
            Only one form can be assigned in the complete section.
          </Typography>
        </Stack>

        <Box>
          {list.map((form, idx) => {
            const fc = formsConfig.find(o => o.id === form.formId)
            // console.log(fc)
            if (fc) {
              return (
                <FormCardComplete
                  key={`form-${form.formId}-${idx}`}
                  form={form}
                  formsConfig={fc}
                  onDelete={() => {
                    handleDelete(idx)
                  }}
                  singleUseOnly
                />
              )
            }
            if (fc === undefined) {
              return (
                <FormCardComplete
                  key={`form-undefined-${form.formId}-${idx}`}
                  form={form}
                  formsConfig={form}
                  onDelete={() => {
                    handleDelete(idx)
                  }}
                  singleUseOnly
                  formStatus='undefined'
                />
              )
            }
            return null
          })}
        </Box>

        <Box>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setShowAddDialog(!showAddDialog)
            }}
          >
            {list.length ? <>Replace Form</> : <>Add Form</>}
          </Button>
        </Box>
      </Box>

      {showAddDialog
        ? (
          <DialogFormSelector
            title='Select Form'
            list={list}
            formsConfig={formsConfig}
            onSave={(l) => {
              handleChange(l)
              setShowAddDialog(!showAddDialog)
            }}
            onClose={() => setShowAddDialog(!showAddDialog)}
            singleUseOnly
            type='complete'
          />
          )
        : null}
    </>
  )
}
export default RightPanelComplete

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'white'
  }
}
