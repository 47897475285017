import React, { useEffect, useState } from 'react'
import { Box, Grid, IconButton, Link, Tooltip, Typography } from '@mui/material'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import Api from 'library/Api'
import Company from 'screens/Company'
import DataGrid from 'components/Reusable/DataGrid'

import { LoadingPleaseWait } from '@supportworks/react-components'
import withScreenWrapper from 'screens/withScreenWrapper'

const Reports = props => {
  const [isLoading, setIsLoading] = useState(true)

  // console.log(Company.getCurrentOrgId())
  useEffect(() => {
    if (isLoading) {
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleExecuteReport = (id) => {
    if (id === 1) {
      // console.log(start, end)
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    {
      field: 'reportName',
      headerName: 'Name',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: params => {
        return (
          <Tooltip title={params.row[params.field]} placement='top-start'>
            <Box style={styles.nowrap}>{params.row[params.field]}</Box>
          </Tooltip>
        )
      }
    },
    {
      flex: 1,
      field: 'action',
      headerName: 'Actions',
      headerAlign: 'right',
      align: 'right',
      editable: false,
      sortable: false,
      disableExport: true,
      renderCell: params => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <Link
                  href={Api.resolvePath('/Company/:organizationId/Reports/:reportId', {
                    organizationId: Company.getCurrentOrgId(),
                    reportId: params.row.id
                  })}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <IconButton
                    onClick={() => {
                      handleExecuteReport(params.row.id)
                    }}
                  >
                    <FindInPageIcon fontSize='small' />
                  </IconButton>
                </Link>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  rows.push({ id: 'presentations-usage', reportName: 'Presentations Usage' })
  rows.push({ id: 'sales-rep-adl-ads', reportName: 'Sales Representative ADL / ADS' })
  rows.push({ id: 'sales-rep-averages', reportName: 'Sales Representative Averages' })
  rows.push({ id: 'solutions', reportName: 'Solutions' })

  return (
    <Box>
      <Company.Header title='Reporting' />
      <Box sx={{ pb: 2 }}>
        <Typography variant='body2'>Choose a report, and it will open in a new tab.</Typography>
      </Box>
      <Box sx={{ pb: 2 }}>
        <Typography variant='h6'>Appointments</Typography>
      </Box>
      <Box sx={{ pb: 2 }}>
        <DataGrid rows={rows} columns={columns} />
      </Box>
    </Box>
  )
}
export default withScreenWrapper(Reports)

const styles = {
  nowrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%'
  }
}
