import React, { useState, useEffect } from 'react'
import { Drawer, Button } from '@mui/material'
import DrawerHeader from './DrawerHeader'
import MapFormFieldChooseField from './MapFormFieldChooseField'
import MapFormFieldNumeric from './MapFormFieldNumeric'
import MapFormFieldNonNumeric from './MapFormFieldNonNumeric'
import DrawerFooter from './DrawerFooter'

const DrawerProductAutomation = ({ open: defaultOpen, onSave, onClose, scratch, setProduct, product, productMaster, formFields, solution, updateFieldType }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const [mappedFieldId, setMappedFieldId] = useState()
  const [mappedFieldObject, setMappedFieldObject] = useState()
  const [showMapFormField, setShowMapFormField] = useState(false)
  const [showSaveButton, setShowSaveButton] = useState(false) // eslint-disable-line

  useEffect(() => {
    // console.log("useEffect start [] triggered");
    // Initialize all things on the component with existing product data.
    if (isLoading) {
      if (product && product.config[0]) {
        setMappedFieldId(product.config[0].field)
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    // console.log("useEffect mappedFieldId triggered");
    if (mappedFieldId && !(formFields == null)) {
      const m = formFields.find(o => o.id === mappedFieldId)
      setMappedFieldObject(m)
    }
  }, [mappedFieldId]) // eslint-disable-line

  const handleChange = (event) => {
    const { name, value } = event.target

    // console.log(JSON.stringify(product));
    if (name === 'formField') {
      setMappedFieldId(value)
      const config = product.config[0] || {}
      if (config.field !== value) {
        config.values = []
      }
      config.field = value
      setProduct({ ...product, config: [config] })
    }
  }
  // console.log(mappedFieldId)

  const handleSave = () => {
    onSave()
  }

  const toggleMapping = () => {
    if (mappedFieldObject) {
      setShowMapFormField(true)
    }
  }

  const toggleDrawer = (event) => {
    event.preventDefault()
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    if (!showMapFormField) {
      // I really want to go back
      setIsOpen(false)
      onClose()
    } else {
      // Set the default display
      setShowMapFormField(false)
    }
  }

  const stopPropagationForTab = (event) => {
    // Tab in the Search and other input fields closes the whole drawer!!! Material UI bug or feature?
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  if (isLoading) {
    return <></>
  }

  let mapFormField = (
    <div className='sa-section'>
      <div className='sa-spec-paragraph'>That form field type is not yet supported.  Please choose another field.</div>
    </div>
  )
  if (showMapFormField) {
    if (mappedFieldObject && mappedFieldObject.type.match(/numeric/)) {
      mapFormField = (
        <MapFormFieldNumeric
          mappedFieldObject={mappedFieldObject}
          config={product.config[0]}
          setProduct={setProduct}
          product={product}
          setShowSaveButton={setShowSaveButton}
        />
      )
    }
    if (mappedFieldObject && mappedFieldObject.type.match(/radio|checkbox|choice|boolean|imageCheckbox/)) {
      mapFormField = (
        <MapFormFieldNonNumeric
          config={product.config[0]}
          mappedFieldObject={mappedFieldObject}
          setProduct={setProduct}
          product={product}
          productMaster={productMaster}
          setShowSaveButton={setShowSaveButton}
        />
      )
    }
    if (mappedFieldObject && mappedFieldObject.type.match(/calc/)) {
      mapFormField = (
        <MapFormFieldNumeric
          mappedFieldObject={mappedFieldObject}
          config={product.config[0]}
          setProduct={setProduct}
          product={product}
          setShowSaveButton={setShowSaveButton}
        />
      )
    }
  }

  return (
    <Drawer
      key='DPA'
      anchor='right'
      variant='persistent'
      open={isOpen}
      onKeyDown={stopPropagationForTab}
      onClose={(event) => {
        toggleDrawer(event)
      }}
    >
      <div className='sa-container'>
        <DrawerHeader variant='solution' title={scratch.solutionTitle} onClose={toggleDrawer} />

        <div className='sa-breadcrumb-container'>
          <Button color='primary' onClick={toggleDrawer}><span className='sa-button'>&lt; Back</span></Button>
        </div>

        {showMapFormField
          ? (
            <>
              {mapFormField}
            </>
            )
          : (
            <>
              <div className='sa-section'>
                <div className='sa-spec-paragraph'>
                  <span>
                    Select the field within the form that will trigger this product.  Once you've selected the field, complete any additional setup.
                  </span>
                </div>
              </div>
              {
                (!typeof productMaster === 'undefined' || !productMaster === null) &&
                  <div className='sa-section'>
                    <span className='sa-heading'>{productMaster.title} (SKU: {productMaster.id})</span>
                  </div>
              }
              <MapFormFieldChooseField
                product={product}
                formField={mappedFieldId}
                formFields={formFields}
                toggleMapping={toggleMapping}
                onChange={handleChange}
              />
            </>
            )}
      </div>
      <DrawerFooter onBack={toggleDrawer} onSave={handleSave} showSaveButton />
    </Drawer>
  )
}
export default DrawerProductAutomation
