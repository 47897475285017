/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { Button, Box, Grid, Paper, TextField, Typography } from '@mui/material'
import Api from 'library/Api'
import CompanyConfigEdit, { CompanyConfigBaseEditor } from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import SelectorWithPreview from 'components/Reusable/SelectorWithPreview'
import CompanyConfigModal from 'screens/Config/CompanyConfigModal'
import '../../css/select-search.css'
import '../../css/selector-material-ui.css'

const config = {
  title: 'Company Info',
  variant: 'contract.dealer',
  tag: 'contract.dealer',
  name: 'Company Info',
  lockTag: true,
  lockName: false
}

// properties of each input and label
const fillData = {
  // same structure as contentJson but without nests
  name: {
    label: 'Dealer Name',
    placeholder: 'Dealer Name',
    defaultValue: ''
  },
  // location: {
  address: {
    label: 'Main Address',
    placeholder: 'Street Address',
    defaultValue: ''
  },
  address2: {
    label: 'Secondary Address',
    placeholder: 'Street Address',
    defaultValue: ''
  },
  city: {
    label: 'City',
    placeholder: 'City Name',
    defaultValue: ''
  },
  state: {
    label: 'State',
    placeholder: 'State Name',
    defaultValue: ''
  },
  postal: {
    label: 'Postal Code',
    placeholder: 'Postal Code',
    defaultValue: ''
  },
  // },
  url: {
    label: 'Website',
    placeholder: 'Website Link',
    defaultValue: ''
  },
  email: {
    label: 'Email',
    placeholder: 'Email',
    defaultValue: ''
  },
  phone: {
    label: 'Phone Number',
    placeholder: '123-123-1234',
    defaultValue: ''
  },
  tollfree: {
    label: 'Toll-Free Number',
    placeholder: 'Toll-Free Number',
    defaultValue: ''
  },
  fax: {
    label: 'Fax Number',
    placeholder: 'Fax',
    defaultValue: ''
  },
  label: {
    label: 'License Label',
    placeholder: 'License Label',
    defaultValue: ''
  },
  body: {
    label: 'License Identifier',
    placeholder: 'License Identifier',
    defaultValue: ''
  }
}

class CompanyConfigDealerInfo extends CompanyConfigBaseEditor {
  constructor (props) {
    super(props)
    // console.log(props)
    // this.componentDidMount = this.componentDidMount.bind(this)
    let dealerInfo
    if (typeof props.contentJson === 'string') {
      // try catch
      try {
        dealerInfo = JSON.parse(props.contentJson)
      } catch (err) {
        console.log(err.message)
      }
    } else if (typeof props.contentJson === 'object') {
      // no need to parse
      dealerInfo = props.contentJson
    } else if (props.contentJson !== undefined) {
      // not string, object, or undefined. Necessary case?
      try {
        dealerInfo = JSON.parse(props.contentJson)
      } catch (err) {
        console.log(err.message)
      }
    } else {
      // set new dealer
      dealerInfo = {
        name: '',
        location: {
          address: '',
          address2: '',
          city: '',
          state: '',
          postal: ''
        },
        url: '',
        email: '',
        phone: '',
        tollfree: '',
        fax: '',
        // "contractorNumber": "",
        contractorNumber: {
          label: '',
          body: ''
        },
        logo: ''
      }
    }

    this.state = {
      // dealerInfo : dealerInfo,
      name: dealerInfo.name,
      address: dealerInfo.location ? dealerInfo.location.address : '',
      address2: dealerInfo.location ? dealerInfo.location.address2 : '',
      city: dealerInfo.location ? dealerInfo.location.city : '',
      state: dealerInfo.location ? dealerInfo.location.state : '',
      postal: dealerInfo.location ? dealerInfo.location.postal : '',
      url: dealerInfo.url,
      email: dealerInfo.email,
      phone: dealerInfo.phone,
      tollfree: dealerInfo.tollfree,
      fax: dealerInfo.fax,
      // contractorNumber : dealerInfo.contractorNumber,
      label: dealerInfo.contractorNumber ? dealerInfo.contractorNumber.label : '',
      body: dealerInfo.contractorNumber ? dealerInfo.contractorNumber.body : '',
      logo: dealerInfo.logo,
      assetsConfig: null
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleUpdateAsset = this.handleUpdateAsset.bind(this)
    this.handleAddAsset = this.handleAddAsset.bind(this)
    this.handleDeleteAsset = this.handleDeleteAsset.bind(this)
  }

  componentDidMount () {
    super.componentDidMount()

    Api.callRegisteredMethod('getAssetList', {}).then((assetList) => {
      const assets = assetList

      this.setState({
        assetsConfig: assets.sort((a, b) => (a.name > b.name ? 1 : -1))
      })
    })
  }

  handleChange = (event) => {
    const changes = {}
    changes[event.target.name] = event.target.value
    // console.log(changes)
    this.setState(changes)
  }

  handleUpdateAsset (asset) {
    let logo = this.state.logo
    // console.log(asset);
    if (asset === null) {
      logo = null
    } else {
      logo = asset.guid
    }

    this.setState({ logo })
  }

  handleAddAsset () {
    const cInfo = JSON.parse(JSON.stringify(this.state))
    cInfo.newAsset = true
    this.setState(cInfo)
  }

  handleDeleteAsset () {
    const cInfo = JSON.parse(JSON.stringify(this.state))
    cInfo.logo = ''
    this.setState(cInfo)
  }

  getAssetInfo (id) {
    const assetConfig = this.state.assetsConfig.filter((asset) => {
      return asset.id === id
    })
    if (assetConfig.length !== 0) {
      return assetConfig[0]
    } else {
      return id
    }
  }

  finalize () {
    // return with location repackaged
    const toStr = {
      name: this.state.name,
      location: {
        address: this.state.address,
        address2: this.state.address2,
        city: this.state.city,
        state: this.state.state,
        postal: this.state.postal
      },
      url: this.state.url,
      email: this.state.email,
      phone: this.state.phone,
      tollfree: this.state.tollfree,
      fax: this.state.fax,
      // "contractorNumber": this.state.contractorNumber,
      contractorNumber: {
        label: this.state.label,
        body: this.state.body
      },
      logo: this.state.logo
    }

    return JSON.stringify(toStr)
  }

  hasFinalize () {
    return true
  }

  render () {
    let selectedLogo = null
    if (this.state.assetsConfig !== null) {
      if (this.state.logo !== null || this.state.logo !== '') {
        selectedLogo = this.getAssetInfo(this.state.logo)
      }
    }

    return (
      <Paper sx={{ p: 0 }} elevation={0}>
        <Typography variant='h6' sx={{ pb: 2 }}>
          Your Company Details
        </Typography>
        <Grid container>
          {Object.keys(fillData).map((fieldName) => {
            return (
              <Grid item xs={12} key={fieldName} sx={{ pb: 2 }}>
                <TextField
                  id={'dealerinfo-' + fieldName}
                  type='text'
                  title={fillData[fieldName].label}
                  label={fillData[fieldName].label}
                  name={fieldName}
                  value={this.state[fieldName] ? this.state[fieldName] : fillData[fieldName].defaultValue}
                  onChange={this.handleChange}
                  placeholder={fillData[fieldName].placeholder}
                  fullWidth
                />
              </Grid>
            )
          })}

          {this.state.assetsConfig !== null && (
            <Grid item xs={12}>
              <Typography variant='h6' sx={{ pb: 2 }}>
                Logo
              </Typography>
              {this.state.logo !== '' && selectedLogo !== null && (
                <Grid container>
                  <Grid item sx={{ pr: 2 }}>
                    <Box style={styles.logo}>
                      {selectedLogo.type === 'Image' && <img src={selectedLogo.previewURL} style={{ maxHeight: '156px' }} className='selected-Asset' alt='' />}
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box style={{ margin: '12px' }}>
                      <code>{selectedLogo.title}</code>
                    </Box>
                    <div style={{ paddingTop: '50px' }}>
                      <span>
                        <Button id='asset-action' className='mui-btn' variant='contained' color='primary' onClick={this.handleAddAsset}>
                          Edit Logo
                        </Button>
                      </span>
                      <span style={{ marginLeft: '40px', marginBottom: '10px' }}>
                        <Button id='asset-action' className='mui-btn' variant='contained' color='error' onClick={this.handleDeleteAsset}>
                          Remove
                        </Button>
                      </span>
                    </div>
                  </Grid>
                </Grid>
              )}

              <div className='actionsTable__wrapper table__inset'>
                {(this.state.logo === '' || this.state.logo === null) && (
                  <div className='actionsTable__options'>
                    <span className='add__btn' onClick={this.handleAddAsset}>
                      <i className='fas fa-plus-circle' />
                      <span className='add__text'>Add Logo</span>
                    </span>
                  </div>
                )}

                {'newAsset' in this.state && (
                  <CompanyConfigModal
                    open={this.state.newAsset}
                    body={
                      <SelectorWithPreview
                        selectedConfig={this.state}
                        fieldName='logo'
                        config={this.state.assetsConfig}
                        assetType={['Image']}
                        onUpdate={(asset) => this.handleUpdateAsset(asset)}
                      />
                    }
                    header='Select Asset'
                    update={() => {
                      const cInfo = this.state
                      if ('newAsset' in cInfo) {
                        delete cInfo.newAsset
                        this.setState(cInfo)
                      }
                    }}
                    cancel={() => {
                      const cInfo = this.state
                      if ('newAsset' in cInfo) {
                        delete cInfo.newAsset
                        this.setState(cInfo)
                      }
                    }}
                  />
                )}
              </div>
            </Grid>
          )}
        </Grid>
      </Paper>
    )
  }
}

const styles = {
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // maxHeight: '160px',
    height: '160px',
    width: '249px',
    border: '1px solid #ced4da',
    borderRadius: '.25rem',
    backgroundColor: 'rgba(0,0,0,.02)'
  }
}

export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} editorComponent={CompanyConfigDealerInfo} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}
