/* eslint-disable react/no-direct-mutation-state */
import React from 'react'
import { Topics } from 'config/topics'

/**
 * Provides an inobtrusive bar at the bottom of the screen to show status messages.
 */
export class StatusOverlay extends React.Component {
  /**
     * TimerID -- active if the loading screen is visible.
     *
     * @type {int|null}
     */
  timerId

  static topic

  /**
     * @type {StatusOverlay}
     */
  static instance

  /**
     * @type {Object.<string, Subscription>}
     */
  subs

  messageStack = []

  /**
     *
     * @param {Object} props
     * @param {string} [props.message] Message to display
     * @param {boolean} [props.visible=false] Whether or not the loading overlay is visible
     * @param {number} [props.maxDuration=30] Maximum duration to show the status item -- useful if the
     *        background process doesn't return.
     */
  constructor (props) {
    super(props)

    this._isMounted = false

    this.state = {
      visible: !!this.props.visible,
      maxDuration: this.props.maxDuration ? this.props.maxDuration : 30,
      messages: [],
      calls: 0
    }

    StatusOverlay.topic = this.props.topic

    StatusOverlay.instance = this

    this.processIncomingMessage = this.processIncomingMessage.bind(this)
    this.messageListener = this.messageListener.bind(this)

    if (props.message) {
      this.messageListener(props.message)
    }

    this.subs = {
      status: Topics.subscribe(this.props.topic, this.messageListener.bind(this))
    }
  }

  get isMounted () {
    return this._isMounted
  }

  /**
     * Unsubscribes from all subscriptions.
     */
  componentWillUnmount () {
    for (const sub in this.subs) {
      this.subs[sub].unsubscribe()
    }
  }

  /**
     * Normalizes message and adds it to the queue.
     *
     * @param message
     */
  processIncomingMessage (message) {
    if (typeof message === 'string') {
      message = { message }
    }
    if (!message.timeout) {
      message.timeout = 30
    }
    message.timerId = window.setTimeout(() => {
      message.isExpired = true
      if (this.isMounted === true) {
        let calls = this.state.calls
        this.setState({ calls: calls++ })
      }
    }, message.timeout * 1000)
    this.state.messages.push(message)
  }

  /**
     * Listens for messages to display.
     *
     * @param data
     */
  messageListener (data) {
    if (data) {
      console.log(data)
      let message = data
      if (message.topic === StatusOverlay.topic) {
        message = data.data
      }
      console.log(message)
      if (Array.isArray(message)) {
        // array of message objects or strings
        message.map(this.processIncomingMessage)
      } else {
        if (Object.prototype.hasOwnProperty.call(message, 'clear')) {
          this.setState({ messages: [] })
        } else {
          this.processIncomingMessage(message)
        }
      }
    }

    if (this.isMounted === true) {
      let calls = this.state.calls
      this.setState({ calls: calls++ })
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  renderMessages () {
    if (!this.state.messages || this.state.messages.length === 0) {
      return null
    }
    this.state.messages = this.state.messages.filter((message) => !message.isExpired)
    return (
      this.state.messages.map((message, index) => {
        return (
          <div key={index} className={'StatusOverlayMessage' + (message.className ? ' ' + message.className : '')}>
            {message.message}
          </div>
        )
      })
    )
  }

  render () {
    const renderedMessages = this.renderMessages()
    if (!renderedMessages || renderedMessages.length === 0) {
      return null
    }
    return (
      <div className='StatusOverlay'>
        <div className='StatusOverlayContentContainer'>
          {this.renderMessages()}
        </div>
      </div>
    )
  }

  static hide () {
    Topics.publish(this.topic, { clear: true })
  }

  /**
     * Displays a loading message, optionally disappearing after specified seconds.
     *
     * @param {string} message
     * @param {int|null} [timeout=null]
     */
  static message (message, timeout = null) {
    const data = { message, timeout }
    Topics.publish(this.topic, data)
  }
}

export default StatusOverlay
