import { Grid, Typography, IconButton } from '@mui/material'
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

export const SelectionHeader = (props) => {
  return (
    <Grid item xs={12} style={{ ...styles.selectionContainer }}>
      <Grid container justifyContent='flex-start'>
        <Grid item xs={10} sx={{ p: 2 }}>
          <Typography style={{ ...styles.hideOverflow, ...styles.title }}>
            <strong>{props.title}</strong>
            {props.subtitle && (
              <Typography variant='subtitle2' style={{ display: 'inline', marginLeft: '8px' }}>
                {props.subtitle}
              </Typography>
            )}
          </Typography>

        </Grid>
        <Grid item xs={2} sx={{ pt: 1.5 }}>
          <Grid container wrap='nowrap' justifyContent='flex-end'>
            {props.onSort
              ? (
                <Grid item>
                  <IconButton onClick={props.onSort}>
                    <SortByAlphaIcon fontSize='small' />
                  </IconButton>
                </Grid>
                )
              : null}
            {props.onAdd
              ? (
                <Grid item sx={{ pr: 1 }}>
                  <IconButton onClick={props.onAdd}>
                    <AddCircleOutlineIcon fontSize='small' />
                  </IconButton>
                </Grid>
                )
              : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const styles = {
  selectionContainer: {
    backgroundColor: 'white',
    border: '1px solid rgba(0,0,0,.16)',
    userSelect: 'none',
    WebkitUserSelect: 'none'
  },
  hideOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  }
}
