import React, { useState, useEffect } from 'react'
import { FormControl, FormControlLabel, TextField, RadioGroup, Radio, MenuItem } from '@mui/material'

const MapDetermineQuantity = ({ setProduct, product, formFields, variant = '' }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [quantityType, setQuantityType] = useState('')
  const [quantity, setQuantity] = useState('')
  const [showQuantity, setShowQuantity] = useState(false)
  const [showMapFormFields, setShowMapFormFields] = useState(false)
  const [mappedFieldId, setMappedFieldId] = useState('')

  useEffect(() => {
    console.log('useEffect start [] triggered')
    // Initialize all things on the component with existing product data.
    if (isLoading) {
      if (product) {
        setQuantityType(product.quantityType)
        if (product.quantityType === 'field') {
          setShowMapFormFields(true)
          if (product.quantity && product.quantity.field) {
            setMappedFieldId(product.quantity.field)
          }
        }
        if (product.quantityType === 'assignedValue') {
          setQuantity(product.quantity)
          setShowQuantity(true)
        }
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (event) => {
    const { name, value } = event.target
    if (name === 'radioQuantity') {
      if (value === 'field') {
        if (quantityType === 'assignedValue') {
          delete product.quantity
          setMappedFieldId('')
        }
        setQuantityType('field')
        setShowMapFormFields(true)
        setShowQuantity(false)
        setProduct({ ...product, quantityType: 'field' })
      } else {
        if (quantityType === 'field') {
          delete product.quantity
        }
        setQuantityType('assignedValue')
        setShowQuantity(true)
        setShowMapFormFields(false)
        setProduct({ ...product, quantityType: 'assignedValue' })
      }
    }
    if (name === 'formField') {
      // delete product["quantity"];
      setMappedFieldId(value)
      setProduct({ ...product, quantity: { field: value } })
    }
    if (name === 'quantity') {
      // delete product["quantity"];
      setQuantity(value)
      setProduct({ ...product, quantity: value })
    }
  }

  return (
    <div className={variant === 'indent' ? 'sa-indent' : ''}>
      <div className='sa-heading'>
        <strong>How will quantity be determined?</strong>
      </div>
      <FormControl component='fieldset' fullWidth>
        <RadioGroup name='radioQuantityGroup' value={quantityType || ''}>
          <FormControlLabel
            name='radioQuantity'
            value='field'
            control={<Radio size='small' color='primary' />}
            label='Map to Form Field'
            onClick={handleChange}
          />
          {showMapFormFields
            ? (
              <div>
                <FormControl key='FormControl' fullWidth>
                  <TextField
                    key={`mapformfields-${product.id}`}
                    select
                    name='formField'
                    label='Choose Field to use for Quantity'
                    variant='outlined'
                    defaultValue={mappedFieldId || ''}
                    onChange={handleChange}
                  >
                    {
                    formFields !== undefined &&
                    formFields.map((row, idx) => {
                      if (row.type !== null && row.type.match(/numeric|calc/)) {
                        return (
                          <MenuItem key={`-${idx}`} value={row.id}>
                            {row.sectionTitle ? row.sectionTitle + ' - ' : null}
                            {row.title}
                          </MenuItem>
                        )
                      }
                      return null
                    })
                  }
                  </TextField>
                </FormControl>
              </div>
              )
            : null}
          <FormControlLabel
            name='radioQuantity'
            value='assignedValue'
            control={<Radio size='small' color='primary' />}
            label='Custom Quantity'
            onClick={handleChange}
          />
          {showQuantity
            ? (
              <div className='sa-indent'>
                <div style={{ paddingBottom: '16px' }}>
                  <TextField
                    name='quantity'
                    id='quantity'
                    value={quantity || ''}
                    label='Enter Numeric Value'
                    variant='outlined'
                    onChange={handleChange}
                  />
                </div>
              </div>
              )
            : null}
        </RadioGroup>
      </FormControl>
    </div>
  )
}
export default MapDetermineQuantity
