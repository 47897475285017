import React from 'react'
import { Box, Grid, Typography, Tooltip } from '@mui/material'
import FmdBadIcon from '@mui/icons-material/FmdBad'
import { fieldTypeConfig } from '../../../index'

const FieldNavNotAvailable = (props) => {
  const field = props.field
  let icon = <></>
  const f = fieldTypeConfig[field.type]
  if (f && f.icon) {
    icon = f.icon
  }
  return (
    <div id={`fieldnavNA-${field.id}`} key={`fieldnavNA-${field.id}`} style={styles.pointer}>
      <Box key={field.id}>
        <Grid container spacing={0} sx={{ p: 1 }} style={styles.navContainer}>
          <Grid item sm={1} sx={{ pl: 1 }}>
            {icon}
          </Grid>
          <Grid item sm={9} sx={{ pl: 1 }}>
            <Typography sx={{ pl: 1 }} style={styles.navText}>
              {field.title}
            </Typography>
          </Grid>
          <Grid item sm={2} sx={{ pl: 4 }}>
            <Tooltip title={`${f.label} unavailable for placement`}>
              <FmdBadIcon fontSize='small' />
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
export default FieldNavNotAvailable

const styles = {
  navContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    WebkitUserSelect: 'none'
  },
  navSubContainer: {
    backgroundColor: 'rgba(0,0,0,.04)'
  },
  navText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 14,
    paddingTop: '0px',
    userSelect: 'none'
  },
  pointer: {
    cursor: 'pointer'
  }
}
