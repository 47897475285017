import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Box, Chip } from '@mui/material'
import { DialogGeneric } from './DialogGeneric'
import { LoadingPleaseWait } from '@supportworks/react-components'
import Company from 'screens/Company'
import H from '../../library/helper'

export const DialogMeetingSelector = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [pageSize, setPageSize] = useState(10)
  const [filterChips, setFilterChips] = useState([]) // Array of unique library names and selected boolean status for filtering the display
  const [selectedRows, setSelectedRows] = useState([]) // Array of row idx's of things that have been checked
  const [rows, setRows] = useState([]) // Array of row objects that can be selected
  const [filteredRows, setFilteredRows] = useState([]) // Array of row objects filtered thru filterChips

  // console.log(props);
  useEffect(() => {
    if (isLoading) {
      const sr = []
      const r = []
      let filter = []
      const prefilter = [
        { id: 'All', selected: true },
        { id: 'Selected', selected: false }
      ]
      for (let x = 0; x < props.appointments.length; x++) {
        const fc = props.appointments[x]
        if (props.variant && props.variant === 'library') {
          const l = props.list.find((o) => o.id === fc.id)
          if (l && l.id && l.status.match(/^(added|edited)/)) {
            sr.push(x)
          }
        } else {
          const l = props.list.indexOf(fc.id)
          if (l > -1) {
            sr.push(x)
          }
        }

        let chipName
        if (fc.library) {
          chipName = fc.library.libraryName
        } else {
          const currentCompany = Company.getCurrent()
          chipName = currentCompany.name
        }
        const exists = filter.find((o) => o.id === chipName)
        if (!exists) {
          filter.push({
            id: chipName,
            selected: false
          })
        }
        r.push({
          id: x,
          title: fc.title ? fc.title : '-',
          library: chipName
          // created: fc.created ? fc.created : '-',
          // updated: fc.lastupdated ? fc.lastupdated : '-',
        })
      }
      filter.sort((a, b) => (a.id > b.id ? 1 : -1))
      filter = [...prefilter, ...filter]
      setRows(r)
      setFilteredRows(r)
      setSelectedRows(sr)
      setFilterChips(filter)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleSave = () => {
    const list = []
    for (let x = 0; x < selectedRows.length; x++) {
      const row = props.appointments[selectedRows[x]]
      list.push(row.id)
    }
    props.onSave(list)
  }

  const handleChange = (r) => {
    setSelectedRows(r)
  }

  const handleChipSelect = (value) => {
    const [fr, fc] = H.filterDataGridUsingChipFilter({ value, rows, selectedRows, filterChips })
    setFilterChips(fc)
    setFilteredRows(fr)
  }

  if (isLoading) return <LoadingPleaseWait />
  const columns = [
    { field: 'id', headerName: 'Id', hide: true },
    { field: 'title', headerName: 'Title', flex: 1 },
    { field: 'library', headerName: 'Library', flex: 1 }
    /* { field: 'created', headerName: 'Created', flex: 1 },
        { field: 'updated', headerName: 'Updated ', flex: 1 }, */
  ]

  const jsx = (
    <Box>
      <Box sx={{ pb: 1 }}>
        {filterChips.map((f) => {
          return (
            <Chip
              key={`Chip-${f.id}`}
              sx={{ p: 1, m: 0.5 }}
              color={f.selected === true ? 'primary' : 'default'}
              size='small'
              label={f.id}
              onClick={() => handleChipSelect(f.id)}
            />
          )
        })}
      </Box>
      <Box sx={{ height: 500 }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50]}
          checkboxSelection
          rowSelectionModel={selectedRows}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onRowSelectionModelChange={(rows) => {
            handleChange(rows)
          }}
          keepNonExistentRowsSelected
          sx={{
            '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
              color: '#A6B0CF',
              backgroundColor: 'transparent',
              display: props.singleUseOnly ? 'none' : ''
            }
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false
              }
            }
          }}
        />
      </Box>
    </Box>
  )

  return <DialogGeneric title={props.title} content={jsx} fullWidth onChange={handleSave} onClose={props.onClose} />
}
