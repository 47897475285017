import React, { useState, useEffect } from 'react'
import { Box, Button, CardMedia, Grid, IconButton, Stack, TextField, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { getAssetInfo } from 'screens/Config/CompanyConfigPresentations'
import { DialogGeneric } from '../../../components/Reusable/DialogGeneric'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { SelectionHeader } from '../Products/components/SelectionHeader'
import SelectorWithPreview from 'components/Reusable/SelectorWithPreview'
import Helper from '@supportworks/helper'

const Categories = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [categories, setCategories] = useState([]) // For object manipulation
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)

  useEffect(() => {
    if (isLoading) {
      const c = JSON.parse(JSON.stringify(props.categories)) || []
      setCategories(c)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleSave = () => {
    const resources = JSON.parse(JSON.stringify(props.resources))
    if (!props.isAdding) {
      for (let i = 0; i < resources.length; i++) {
        const r = resources[i]
        for (let j = 0; j < r.levelIds.length; j++) {
        // Using props.categories, find the category object that matches the resource.levelIds[j] and update the resource.levels[j] with the category name
          const category = categories.find((c) => c.id === r.levelIds[j])
          if (category) {
            r.levels[j] = category.category
          }
        }
      }
    }
    // call props.setResources with any resource updates
    props.setResources(resources)
    props.setCategories(categories)
    handleCloseDialog()
  }

  const handleDelete = (idx) => {
    const cat = JSON.parse(JSON.stringify(categories))
    if (window.confirm(`Are you sure you want to delete '${cat[idx].category}'?`)) {
      cat.splice(idx, 1)
      setCategories(cat)
      props.setCategories(cat)
    }
    handleCloseDialog()
  }

  const handleSort = () => {
    const c = JSON.parse(JSON.stringify(categories))

    // Check if the array is already sorted in ascending order
    const isSorted = c.every((category, i) => {
      if (i === 0) return true
      return c[i - 1].category.localeCompare(category.category) <= 0
    })

    // If sorted, reverse the array; otherwise, sort it in ascending order
    if (isSorted) {
      c.reverse()
    } else {
      c.sort((a, b) => a.category.localeCompare(b.category))
    }

    setCategories(c)
    props.setCategories(c)
  }

  const handleMoveUp = (idx) => {
    const c = JSON.parse(JSON.stringify(categories))
    const ele = c.splice(idx, 1)[0]
    c.splice(idx - 1, 0, ele)
    setCategories(c)
    props.setCategories(c)
  }

  const handleMoveDown = (idx) => {
    const c = JSON.parse(JSON.stringify(categories))
    const ele = c.splice(idx, 1)[0]
    c.splice(idx + 1, 0, ele)
    setCategories(c)
    props.setCategories(c)
  }

  const handleCloseDialog = () => {
    setSelectedIndex(-1)
    setShowEditDialog(false)
    setShowAddDialog(false)
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      {categories.length
        ? (
          <Grid container style={styles.gridContainer}>
            <SelectionHeader
              title='Categories'
              subtitle={categories.length ? '(' + categories.length + ')' : ''}
              onAdd={() => {
                setShowAddDialog(!showAddDialog)
              }}
              onSort={handleSort}
            />
            <Grid item xs={12}>
              {categories.map((category, idx) => {
                const a = getAssetInfo(category.asset, props.assetsConfig)
                return (
                  <Grid container key={`w-${idx}`} style={styles.selectionContainer}>
                    <Grid item xs={8} sx={{ pt: 2, pb: 2 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {a && a.previewURL
                          ? (
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mr: 1 }}>
                              <a href={a.path} target='_blank' rel='noreferrer'>
                                <CardMedia component='img' height='36' sx={{ maxWidth: 36, objectFit: 'contain' }} image={a.previewURL} alt={a.previewURL} />
                              </a>
                            </Box>
                            )
                          : null}

                        <Typography style={styles.textField}>
                          {category.category}
                        </Typography>
                      </Box>
                    </Grid>
                    <>
                      <Grid item sx={{ pt: 1, pl: 2.25 }}>
                        <IconButton disabled={idx === 0} onClick={() => handleMoveUp(idx)}>
                          <KeyboardArrowUpIcon style={styles.icon} />
                        </IconButton>
                      </Grid>
                      <Grid item sx={{ pt: 1 }}>
                        <IconButton disabled={idx === categories.length - 1} onClick={() => handleMoveDown(idx)}>
                          <KeyboardArrowDownIcon style={styles.icon} />
                        </IconButton>
                      </Grid>
                      <Grid item sx={{ pt: 1 }}>
                        <IconButton
                          onClick={() => {
                            setSelectedIndex(idx)
                            setShowEditDialog(!showEditDialog)
                          }}
                        >
                          <EditIcon style={styles.icon} />
                        </IconButton>
                      </Grid>
                    </>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
          )

        : (
          <Grid container spacing={0} direction='row' alignItems='center' justifyContent='center' style={{ minHeight: '50vh' }}>
            <Stack spacing={2}>
              <Typography style={styles.stackItem}>There are no resource categories.</Typography>
              <span style={styles.stackItem}>
                <Button variant='contained' onClick={() => setShowAddDialog(!showAddDialog)}>
                  Add One
                </Button>
              </span>
            </Stack>
          </Grid>
          )}

      {showAddDialog || showEditDialog
        ? (
          <DialogEdit
            {...props}
            variant={showAddDialog ? 'new' : 'edit'}
            selectedIndex={selectedIndex}
            categories={categories}
            onDelete={() => {
              handleDelete(selectedIndex)
            }}
            onSave={handleSave}
            onClose={handleCloseDialog}
          />
          )
        : null}

    </>
  )
}
export default Categories

const DialogEdit = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [title, setTitle] = useState('')
  const [category, setCategory] = useState({})
  const [asset, setAsset] = useState({})
  const [assetId, setAssetId] = useState(null)
  const [originalAssetId, setOriginalAssetId] = useState(null)
  const [showResourceDialog, setShowResourceDialog] = useState(false)

  useEffect(() => {
    if (isLoading) {
      if (props.variant === 'edit') {
        const c = props.categories[props.selectedIndex]
        setTitle(c.category)
        if (c.asset) {
          setAssetId(c.asset)
          setOriginalAssetId(c.asset)
        }
        setCategory(c)
      } else {
        // Create a category object
        const guid = Helper.guid()
        setCategory({
          id: guid,
          category: title,
          asset: null
        })
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  // If assetId changes, reload the asset
  useEffect(() => {
    if (assetId) {
      const a = getAssetInfo(assetId, props.assetsConfig)
      if (a && a.previewURL) {
        setAsset(a)
      }
    }
  }, [assetId]) // eslint-disable-line

  const handleSave = (e) => {
    if (props.variant === 'new') {
      const c = JSON.parse(JSON.stringify(category))
      c.category = title
      c.asset = assetId
      props.categories.unshift(c)
    } else {
      const c = props.categories[props.selectedIndex]
      c.category = title
      c.asset = assetId
    }
    props.onSave()
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'title') {
      setTitle(value)
    } else if (name === 'asset') {
      setAsset(value)
    }
  }

  const handleUpdateAsset = (asset) => {
    if (asset) {
      setAssetId(asset.id)
    } else {
      setAssetId(null)
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  const jsx = (
    <>
      <Grid container>
        <Grid item sm={12} sx={{ pb: 2 }}>
          <TextField
            fullWidth
            required
            id='title'
            name='title'
            variant='outlined'
            label='Category Title'
            helperText='Please enter the category title.'
            value={title || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack>
            <Box sx={{ pb: 1 }}>
              <Typography style={styles.title} sx={{ pb: 1 }}>Select Poster</Typography>
              <Typography variant='body2'>In the app, this resource will not be stored locally, unless the user downloads the resource locally for viewing later or offline.</Typography>
            </Box>

            {asset && asset.previewURL
              ? (
                <Box style={styles.imageContainer} sx={{ mr: 2 }}>
                  <a href={asset.path} target='_blank' rel='noreferrer'>
                    <CardMedia component='img' height='160' sx={{ objectFit: 'contain' }} image={asset.previewURL} alt={asset.previewURL} />
                  </a>
                </Box>
                )
              : (
                <Typography sx={{ pt: 2 }} variant='body2'>There is no resource asset. Please add one.</Typography>
                )}
            {asset && asset.filename
              ? (
                <Box sx={{ pt: 2 }}>
                  <code style={{ color: 'black' }}>{asset.filename}</code>
                </Box>
                )
              : null}

            <Box sx={{ pt: 2 }}>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setShowResourceDialog(true)
                }}
              >
                {assetId ? 'Replace' : 'Add'} Image
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>

      {showResourceDialog && (
        <DialogGeneric
          title='Select Resource Asset'
          content={
            <SelectorWithPreview
              selectedConfig={category}
              fieldName='asset'
              config={props.assetsConfig}
              assetType={['Image']}
              onUpdate={(a) => handleUpdateAsset(a)}
            />
          }
          fullWidth
          onChange={() => {
            setShowResourceDialog(false)
          }}
          onClose={() => {
            setAssetId(originalAssetId)
            setShowResourceDialog(false)
          }}
        />
      )}
    </>
  )

  return (
    <DialogGeneric
      title={props.variant === 'edit' ? 'Edit Category' : 'Add New Category'}
      content={jsx}
      fullWidth
      onChange={handleSave}
      onClose={props.onClose}
      onDelete={props.variant === 'edit' ? props.onDelete : null}
    />
  )
}

const styles = {
  gridContainer: {
    width: 450,
    maxWidth: 450
  },
  selectionContainer: {
    border: '1px solid rgba(0,0,0,.16)',
    marginTop: -1,
    paddingLeft: 16,
    backgroundColor: 'white',
    userSelect: 'none',
    WebkitUserSelect: 'none'
  },
  imageContainer: {
    display: 'inline-block',
    padding: '16px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(0,0,0,.04)',
    border: '1px dashed rgba(0,0,0,.16)',
    maxWidth: 'fit-content'
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  textField: {
    fontSize: 14
  },
  stackItem: {
    textAlign: 'center'
  }
}
