import Api, { ApiPlugin } from 'library/Api'

import Company from 'screens/Company'
import { Topics } from 'config/topics'

export class ApiPluginRippleProposal extends ApiPlugin {
  /**
   *
   * @param {ApiBase} apiBase
   * @param {Object} props
   */
  constructor (apiBase, props) {
    super(apiBase, props)

    if (props && props.baseUrl) {
      this.baseUrl = props.baseUrl
    }

    apiBase.registerMethod('getProposalSearchMeta', this.getProposalMeta.bind(this))
    apiBase.registerMethod('getProposals', this.getProposals.bind(this))
    apiBase.registerMethod('getProposal', this.getProposal.bind(this))
    apiBase.registerMethod('getProposalFull', this.getProposalFull.bind(this))
    apiBase.registerMethod('getProposalAccess', this.getProposalAccess.bind(this))
    apiBase.registerMethod('getProposalsSigned', this.getProposalsSigned.bind(this))
    apiBase.registerMethod('resetSignatures', this.resetSignatures.bind(this))
    apiBase.registerMethod('postSignPDFRepresentative', this.postSignPDFRepresentative.bind(this))
    apiBase.registerMethod('getSummarizedAppointments', this.getSummarizedAppointments.bind(this))
    apiBase.registerMethod('getSummarizedPresentations', this.getSummarizedPresentations.bind(this))
    apiBase.registerMethod('getSummarizedSolutions', this.getSummarizedSolutions.bind(this))
    apiBase.registerMethod('getSummarizedAppointmentsPresentations', this.getSummarizedAppointmentsPresentations.bind(this))
    apiBase.registerMethod('getSummarizedMoMSales', this.getSummarizedMoMSales.bind(this))
  }

  /**
   * Gets data about submitted proposals.
   */
  getProposalMeta (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/proposalSearchMeta', { organization: Company.getCurrentOrgId() })
    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */

        if (rsp && rsp.status === 'success') {
          if (props && props.callback) {
            props.callback(rsp.data[0])
          }
          if (props && props.topic) {
            Topics.publish(props.topic, rsp.data[0])
          }
          return rsp.data[0]
        }
        return rsp
      })
  }

  /**
   * Gets data about a single proposal.
   */
  getProposal (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/proposals/:proposalId', {
      organization: Company.getCurrentOrgId(),
      proposalId: props.proposalId
    })
    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */

        if (rsp && rsp.status === 'success') {
          if (props && props.callback) {
            props.callback(rsp.data[0])
          }
          if (props && props.topic) {
            Topics.publish(props.topic, rsp.data[0])
          }
          return rsp.data[0]
        }
        return rsp
      })
  }

  /**
   * Gets data about a single proposal.
   */
  getProposalFull (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/proposals/:proposalId/full', {
      organization: Company.getCurrentOrgId(),
      proposalId: props.proposalId
    })
    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        if (rsp && rsp.status === 'success') {
          if (props && props.callback) {
            props.callback(rsp.data[0])
          }
          if (props && props.topic) {
            Topics.publish(props.topic, rsp.data[0])
          }
          return rsp.data[0]
        }
        return rsp
      })
  }

  /**
   * Gets data about submitted proposals.
   */
  getProposals (props) {
    let route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/proposals', { organization: Company.getCurrentOrgId() })
    const qs = Api.buildQueryString(props, ['from', 'to', 'author', 'searchString'])
    if (qs.length) {
      route = route + '?' + qs
    }
    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */
        if (rsp && rsp.status === 'success') {
          if (props && props.callback) {
            props.callback(rsp.data[0])
          }
          if (props && props.topic) {
            Topics.publish(props.topic, rsp.data[0])
          }
          return rsp.data
        }
        return rsp
      })
  }

  /**
   * Gets an array of proposals that need signed by the representative
   */
  getProposalsSigned (props) {
    let route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/proposals-signed', { organization: Company.getCurrentOrgId() })
    const qs = Api.buildQueryString(props, ['from', 'to', 'representativeSigned'])
    if (qs.length) {
      route = route + '?' + qs
    }
    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        if (rsp && rsp.status === 'success') {
          if (props && props.callback) {
            props.callback(rsp.data[0])
          }
          return rsp.data // should be an array
        }
        return rsp
      })
  }

  /**
   * Gets an array of proposals that need signed by the representative
   */
  resetSignatures (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/proposals/:proposalId/reset-signatures', {
      organization: Company.getCurrentOrgId(),
      proposalId: props.proposalId
    })

    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */

        if (rsp && rsp.status === 'success') {
          return 1
        } else {
          return 0
        }
      })
  }

  getProposalsFullText (props) {
    let route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/proposals-search-fulltext', { organization: Company.getCurrentOrgId() })
    const qs = Api.buildQueryString(props, ['search', 'score'])
    if (qs.length) {
      route = route + '?' + qs
    }
    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */
        if (rsp && rsp.status === 'success') {
          if (props && props.callback) {
            props.callback(rsp.data[0])
          }
          if (props && props.topic) {
            Topics.publish(props.topic, rsp.data[0])
          }
          return rsp.data[0]
        }
        return rsp
      })
  }

  getSummarizedAppointments (props) {
    let route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/getSummarizedAppointments', { organization: Company.getCurrentOrgId() })
    const qs = Api.buildQueryString(props, ['start', 'end'])
    if (qs.length) {
      route = route + '?' + qs
    }
    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */
        if (rsp && rsp.status === 'success') {
          if (props && props.callback) {
            props.callback(rsp.data[0])
          }
          if (props && props.topic) {
            Topics.publish(props.topic, rsp.data[0])
          }
          return rsp.data[0]
        }
        return rsp
      })
  }

  getSummarizedPresentations (props) {
    let route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/getSummarizedPresentations', { organization: Company.getCurrentOrgId() })
    const qs = Api.buildQueryString(props, ['start', 'end', 'authorId', 'dispositionResult'])
    if (qs.length) {
      route = route + '?' + qs
    }
    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */
        if (rsp && rsp.status === 'success') {
          if (props && props.callback) {
            props.callback(rsp.data[0])
          }
          if (props && props.topic) {
            Topics.publish(props.topic, rsp.data[0])
          }
          return rsp.data[0]
        }
        return rsp
      })
  }

  getSummarizedSolutions (props) {
    let route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/getSummarizedSolutions', { organization: Company.getCurrentOrgId() })
    const qs = Api.buildQueryString(props, ['start', 'end', 'authorId', 'dispositionResult'])
    if (qs.length) {
      route = route + '?' + qs
    }
    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */
        if (rsp && rsp.status === 'success') {
          if (props && props.callback) {
            props.callback(rsp.data[0])
          }
          if (props && props.topic) {
            Topics.publish(props.topic, rsp.data[0])
          }
          return rsp.data[0]
        }
        return rsp
      })
  }

  getSummarizedAppointmentsPresentations (props) {
    let route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/getSummarizedAppointmentsPresentations', { organization: Company.getCurrentOrgId() })
    const qs = Api.buildQueryString(props, ['start', 'end', 'authorId', 'dispositionResult'])
    if (qs.length) {
      route = route + '?' + qs
    }
    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */
        if (rsp && rsp.status === 'success') {
          if (props && props.callback) {
            props.callback(rsp.data[0])
          }
          if (props && props.topic) {
            Topics.publish(props.topic, rsp.data[0])
          }
          return rsp.data[0]
        }
        return rsp
      })
  }

  getSummarizedMoMSales (props) {
    let route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/getSummarizedMoMSales', { organization: Company.getCurrentOrgId() })
    const qs = Api.buildQueryString(props, ['start', 'end', 'authorId', 'dispositionResult'])
    if (qs.length) {
      route = route + '?' + qs
    }
    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */
        if (rsp && rsp.status === 'success') {
          if (props && props.callback) {
            props.callback(rsp.data[0])
          }
          if (props && props.topic) {
            Topics.publish(props.topic, rsp.data[0])
          }
          return rsp.data[0]
        }
        return rsp
      })
  }

  /**
   * Grants access to a single proposal, and sends it to props.email.
   */
  async getProposalAccess (props) {
    const values = props.value.split(',').map((value) => value.trim())
    const rsps = []
    for (let x = 0; x < values.length; x++) {
      let route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/proposals/:proposalId/grant-access', {
        organization: Company.getCurrentOrgId(),
        proposalId: props.proposalId
      })
      const qs = Api.buildQueryString({ email: values[x] })
      if (qs.length) route = route + '?' + qs
      const rsp = await this.getApiBase().get(route).then((rsp) => {
        return rsp
      })
      rsps.push(rsp)
    }
    return rsps
  }

  async postSignPDFRepresentative (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/signPDFRepresentative/:guid', {
      organization: Company.getCurrentOrgId(),
      guid: props.guid
    })
    // If props.pdfTags is not provided or an empty array, return nothing.
    if (!props.pdfTags || !props.pdfTags.length) return null
    if (!props.guid) return null

    const payload = {
      proposalId: props.proposalId,
      pdfTags: props.pdfTags
    }

    return this.getApiBase()
      .post(route, payload)
      .then((rsp) => {
        return rsp
      })
  }
}
