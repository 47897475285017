import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import RightPanelFollowUpSolutions from './RightPanelFollowUpSolutions'
import RightPanelFollowPresentations from './RightPanelFollowUpPresentations'
import { RightPanelDetailsOverlay } from './RightPanelDetailsOverlay'
import { RightPanelDetailsSlideshow } from './RightPanelDetailsSlideshow'
import { RightPanelDetailsPresentation } from './RightPanelDetailsPresentation'
import { RightPanelDetailsPoster } from './RightPanelDetailsPoster'

const RightPanel = (props) => {
  return (
    <Grid container style={{ height: '100%' }}>
      {!props.rightPanelVariant
        ? (
          <Grid container spacing={0} direction='row' alignItems='center' justifyContent='center' style={{ minHeight: '50vh' }}>
            <Typography variant='h7'>Please select an editor step on the left.</Typography>
          </Grid>
          )
        : null}
      <Box style={styles.container}>
        {props.rightPanelVariant === 'fu-solutions' ? <RightPanelFollowUpSolutions {...props} /> : null}
        {props.rightPanelVariant === 'fu-presentations' ? <RightPanelFollowPresentations {...props} /> : null}
        {props.rightPanelVariant === 'presentation'
          ? (
            <>
              {props.presentation.type === 'overlay' ? <RightPanelDetailsOverlay {...props} /> : null}
              {props.presentation.type === 'video' ? <RightPanelDetailsPresentation {...props} variant='video' title='Video Presentation Details' /> : null}
              {props.presentation.type === 'slideshow' ? <RightPanelDetailsSlideshow {...props} /> : null}
              {props.presentation.type === 'document' ? (<RightPanelDetailsPresentation {...props} variant='document' title='Document Presentation Details' />) : null}
            </>
            )
          : null}
        {props.rightPanelVariant === 'poster' ? (<RightPanelDetailsPoster {...props} variant='video' title='Video Poster Details' />) : null}
      </Box>
    </Grid>
  )
}
export default RightPanel

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'white'
  }
}
