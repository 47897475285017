import React from 'react'

class IconHover extends React.Component {
  /**
     * HoverButton class.
     * @param {function} [props.onClick] Optional onClick callback
     * @param {JSX|*} [props.iconHovered] Optional Icon to use instead of hover circle effect
     * @param {string} [props.className] Optional override className for circle effect
     */
  constructor (props) {
    super(props)
    this.state = {
      hovered: false
    }
    this.hoverHandler = this.hoverHandler.bind(this)
    this.clickHandler = this.clickHandler.bind(this)
  }

  hoverHandler (bool) {
    this.setState({ hovered: bool })
    // console.log(`hover state: ${this.state.hovered}`)
  }

  clickHandler () {
    this.props.onClick()
    this.setState({ hovered: false })
  }

  render () {
    let className = 'btn-hover-circle'
    if (this.props.className) {
      className = this.props.className
    }
    return (
      <div className={`${this.state.hovered ? className : null}`} onMouseEnter={() => { this.hoverHandler(true) }} onMouseLeave={() => { this.hoverHandler(false) }}>
        <div onClick={this.props.onClick ? this.clickHandler : null}>
          {
                        this.state.hovered && this.props.iconHovered
                          ? this.props.iconHovered
                          : this.props.icon
                    }
        </div>
      </div>
    )
  }
}

export default IconHover
