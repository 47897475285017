import React, { useState, useEffect } from 'react'
import {
  Alert,
  Box,
  Button,
  CardMedia,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  InputAdornment,
  MenuItem,
  Select,
  Slider,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import SquareIcon from '@mui/icons-material/Square'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ColorPicker from 'components/Reusable/ColorPicker'
import { LoadingPleaseWait } from '@supportworks/react-components'
import SelectorWithPreview from 'components/Reusable/SelectorWithPreview'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import { getAssetInfo } from '../CompanyConfigPresentations'
import Helper from 'library/helper'

const RightPanelDrawing = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [assets, setAssets] = useState([])
  const [asset, setAsset] = useState({})
  const [assetId, setAssetId] = useState('') // tmp until save.
  const [type, setType] = useState('svg')
  const [defaultSystem, setDefaultSystem] = useState('imperial')
  const [unit, setUnit] = useState('in')

  useEffect(() => {
    if (isLoading) {
      let defaultSystem = 'imperial'
      if (props.productFeatures?.settings?.[0]?.drawingOptions?.measurementSystem) {
        defaultSystem = props.productFeatures.settings[0].drawingOptions?.measurementSystem
      }
      setDefaultSystem(defaultSystem)
      const p = JSON.parse(JSON.stringify(props.product))
      if (p.drawingConfig === undefined || Object.keys(p.drawingConfig).length === 0) {
        const di = {
          title: '',
          description: '',
          type: 'svg',
          extra: {
            asset: '',
            alignment: 'center',
            rotate: true,
            resize: false
          }
        }
        p.drawingConfig = di
      } else {
        if (p.drawingConfig.type === undefined || !p.drawingConfig?.type?.match(/svg|line|area/)) {
          p.drawingConfig.type = 'svg'
        }
        setType(p.drawingConfig.type)
        if (p.drawingConfig?.extra?.asset !== undefined) {
          const a = getAssetInfo(p.drawingConfig.extra.asset, props.assetsConfig)
          if (a && a.previewURL) {
            setAsset(a)
            setAssetId(a.id)
          }
        }
        if (p.drawingConfig.extra?.defaultUnit === undefined || !p.drawingConfig.extra?.defaultUnit.match(/in|cm/)) {
          p.drawingConfig.extra.defaultUnit = defaultSystem === 'imperial' ? 'in' : 'cm'
        }
        setUnit(p.drawingConfig.extra.defaultUnit)

        if (p.drawingConfig.extra?.scalingWidth === undefined && p.drawingConfig?.extra?.width !== undefined) {
          // need to check if the scalingWidth is set, if not set it to the width.
          // check which unit we should use.
          const scalingWidth = calculateOpposingUnit(unit, p.drawingConfig.extra.width)
          p.drawingConfig.extra.scalingWidth = scalingWidth
        }
      }
      // We only want SVG assets
      const a = []
      for (let x = 0; x < props.assetsConfig.length; x++) {
        if (props.assetsConfig[x].type === 'Image' && props.assetsConfig[x].filename.match(/(.svg)$/i)) {
          a.push(props.assetsConfig[x])
        }
      }
      props.setProduct(p)
      setAssets(a)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (e) => {
    const name = e.target.name
    let value = e.target.value
    const p = JSON.parse(JSON.stringify(props.product))
    if (name.match(/(title|description)/)) {
      p.drawingConfig[name] = value
    } else if (name === 'type') {
      if (value === 'image') value = 'svg'
      if (type !== value) {
        p.drawingConfig.extra = _getExtra(value) // reset the extra area on type change.
        setUnit(defaultSystem === 'imperial' ? 'in' : 'cm')
        setAsset(null)
        setAssetId(null)
      }
      p.drawingConfig.type = value
      setType(value)
    } else if (name === 'alignment') {
      p.drawingConfig.extra.alignment = value
    } else if (name.match(/^(stroke|label|scalingWidth)/)) {
      // have to check what unit is selected, save as an object with both in and cm.
      // if the unit is in, calculate the cm value and save it as well.
      value = Number(value)
      const scalingWidth = calculateOpposingUnit(unit, value)
      // save the default unit to know what the ppt should default to.
      p.drawingConfig.extra[name] = scalingWidth
    } else if (name === 'resize') {
      p.drawingConfig.extra.resize = !p.drawingConfig.extra.resize
    } else if (name === 'rotate') {
      p.drawingConfig.extra.rotate = !p.drawingConfig.extra.rotate
    } else if (name === 'unit') {
      setUnit(value)
      p.drawingConfig.extra.defaultUnit = value
    }
    props.setProduct(p)
  }

  const handleSaveAsset = () => {
    if (assetId) {
      const p = JSON.parse(JSON.stringify(props.product))
      const di = p.drawingConfig
      di.extra.asset = assetId
      const a = getAssetInfo(assetId, props.assetsConfig)
      if (a) {
        setIsLoading(true)
        fetch(a.path).then((r) => {
          r.text().then((d) => {
            const [w, h] = Helper.getSvgWidthHeight(d)
            p.drawingConfig.extra.width = w
            p.drawingConfig.extra.height = h
            // need to check unit and save the opposite unit as well.
            const scalingWidth = calculateOpposingUnit(unit, w)
            p.drawingConfig.extra.scalingWidth = scalingWidth
            setAsset(a)
            props.setProduct(p)
            setIsLoading(false)
          })
        })
      } else {
        setAssetId(null)
      }
    }
  }

  const handleChangeAsset = (a) => {
    if (a) {
      setAssetId(a.id)
    } else {
      setAssetId(null)
    }
  }

  const calculateOpposingUnit = (unit, value) => {
    const tempObject = {
      in: null,
      cm: null
    }
    if (unit === 'in') {
      tempObject.in = value
      tempObject.cm = value * 2.54
    } else {
      tempObject.cm = value
      tempObject.in = value / 2.54
    }
    return tempObject
  }

  const _getExtra = (type) => {
    if (type === 'svg') {
      return {
        asset: null,
        alignment: 'center',
        rotate: true,
        resize: false,
        defaultUnit: defaultSystem === 'imperial' ? 'in' : 'cm'
      }
    } else if (type === 'line') {
      return {
        line: {
          width: 1,
          color: '#000000'
        },
        label: {
          width: 1,
          text: 'Product',
          shortText: 'P',
          color: '#000000',
          fillColor: '#FFFFFF',
          borderColor: '#000000'
        },
        rotate: false,
        resize: false
      }
    } else if (type === 'area') {
      return {
        line: {
          width: 1,
          color: '#000000'
        },
        label: {
          width: 1,
          text: 'Product',
          color: '#000000',
          fillColor: '#FFFFFF',
          borderColor: '#000000',
          rotation: 45
        },
        rotate: false,
        resize: false
      }
    }
    return {}
  }

  if (isLoading) return <LoadingPleaseWait />

  let disabled = false
  if (props.product.drawingConfig_ovrd === false) {
    disabled = true
  }
  if (props.variant === 'pricing') {
    disabled = true
  }

  const drawingConfig = props.product.drawingConfig

  return (
    <Box style={{ backgroundColor: 'white' }}>
      <Box sx={{ p: 2, borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Typography style={styles.title}>{props.title || 'Drawing Configuration'}</Typography>
      </Box>
      {disabled
        ? (
          <Box sx={{ p: 2 }}>
            <Alert variant='filled' severity='info'>
              {props.variant === 'pricing' ? 'Please use the Master List to configure product drawing values.' : 'Please unlock the drawing configuration to make changes.'}
            </Alert>
          </Box>
          )
        : null}
      <Box sx={{ p: 2 }}>
        <Grid container item xs={12} md={12}>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ pb: 2 }}>
              <InputLabel id='unit-type'>Drawing Visual Type</InputLabel>
              <Select
                disabled={disabled}
                required
                fullWidth
                id='type'
                name='type'
                label='Drawing Visual Type'
                labelId='type'
                variant='outlined'
                value={type}
                onChange={handleChange}
                sx={{ height: 50 }}
              >
                <MenuItem key='type-image' value='svg'>
                  <Typography>Image</Typography>
                </MenuItem>
                <MenuItem key='type-line' value='line'>
                  <Typography>Line</Typography>
                </MenuItem>
                <MenuItem key='type-area' value='area'>
                  <Typography>Area</Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ pb: 2 }}>
            <TextField
              disabled={disabled}
              fullWidth
              id='title'
              name='title'
              variant='outlined'
              label='Title'
              value={drawingConfig.title}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <TextField
              fullWidth
              multiline
              rows={5}
              disabled={disabled}
              id='description'
              name='description'
              variant='outlined'
              label='Description'
              value={drawingConfig.description}
              onChange={handleChange}
              inputProps={{
                style: {
                  border: 'none',
                  padding: '0px',
                  resize: 'vertical'
                }
              }}
            />
          </Grid>

          {type === 'svg'
            ? (
              <>
                <Grid item xs={12} sx={{ pb: 2 }}>
                  <Box>
                    <Typography sx={{ fontWeight: 'bold', pb: 1 }}>Select SVG Asset</Typography>
                  </Box>
                  {asset?.previewURL
                    ? (
                      <Box style={styles.imageContainer} sx={{ mr: 2 }}>
                        <a href={asset.path} target='_blank' rel='noreferrer'>
                          <CardMedia component='img' height='160' sx={{ objectFit: 'contain' }} image={asset.previewURL} alt={asset.previewURL} />
                        </a>
                      </Box>
                      )
                    : (
                      <Typography variant='body2'>There is no svg asset. Please add one.</Typography>
                      )}
                  {asset?.filename
                    ? (
                      <Box sx={{ pt: 2 }}>
                        <code style={{ color: 'black' }}>
                          {asset.filename}, &nbsp;{' '}
                          {drawingConfig.extra.width
                            ? (
                              <>
                                {drawingConfig.extra.width} x {drawingConfig.extra.height}
                              </>
                              )
                            : null}
                        </code>
                      </Box>
                      )
                    : null}
                  <Box sx={{ pt: 2 }}>
                    <Button
                      disabled={disabled}
                      color='primary'
                      variant='contained'
                      onClick={() => {
                        setShowAddDialog(!showAddDialog)
                      }}
                    >
                      {drawingConfig.extra.asset ? 'Replace' : 'Add'} Asset
                    </Button>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ pt: 2, pb: 2 }}>
                    <Typography sx={{ fontWeight: 'bold' }}>Image Settings</Typography>
                  </Box>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <Stack spacing={2}>
                      <TextField
                        fullWidth
                        disabled={disabled}
                        id='scalingWidth'
                        name='scalingWidth'
                        variant='outlined'
                        label='Auto-Scaling Width'
                        type='number'
                        value={
                        props.product.drawingConfig.extra && unit === 'in'
                          ? props.product.drawingConfig.extra.scalingWidth?.in
                          : props.product.drawingConfig.extra.scalingWidth?.cm
                      }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <FormControl variant='outlined'>
                                <Select name='unit' id='unit-type' value={unit} onChange={handleChange} style={{ minWidth: '50px' }} size='small'>
                                  <MenuItem value='in'>in</MenuItem>
                                  <MenuItem value='cm'>cm</MenuItem>
                                </Select>
                              </FormControl>
                            </InputAdornment>
                          )
                        }}
                        onChange={handleChange}
                      />
                      <FormControl fullWidth>
                        <InputLabel id='unit-type'>Canvas Drop Alignment</InputLabel>
                        <Select
                          disabled={disabled}
                          required
                          id='alignment'
                          name='alignment'
                          label='Drop Alignment'
                          labelId='alignment'
                          variant='outlined'
                          value={drawingConfig.extra && drawingConfig.extra.alignment}
                          onChange={handleChange}
                        >
                          <MenuItem key='type-image' value='center'>
                            <Typography>Center</Typography>
                          </MenuItem>
                          <MenuItem key='type-line' value='none'>
                            <Typography>None</Typography>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={4} sx={{ alignItems: 'center' }}>
                      <FormControlLabel
                        name='resize'
                        control={
                          <Switch
                            checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                            id='resize'
                            checked={drawingConfig.extra && drawingConfig.extra.resize}
                            color='primary'
                            onClick={handleChange}
                          />
                      }
                        label={<Typography style={styles.textField}>Resizable</Typography>}
                        labelPlacement='start'
                        onChange={handleChange}
                        sx={{ pt: 1 }}
                      />
                      <FormControlLabel
                        name='rotate'
                        control={
                          <Switch
                            checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                            id='rotate'
                            checked={drawingConfig.extra && drawingConfig.extra.rotate}
                            color='primary'
                            onClick={handleChange}
                          />
                      }
                        label={<Typography style={styles.textField}>Rotatable</Typography>}
                        labelPlacement='start'
                        onChange={handleChange}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </>
              )
            : null}

          {type === 'line' ? <LineSettings {...props} /> : null}
          {type === 'area' ? <AreaSettings {...props} /> : null}
        </Grid>
      </Box>

      {showAddDialog
        ? (
          <DialogGeneric
            title='Select SVG'
            content={
              <SelectorWithPreview
                selectedConfig={drawingConfig}
                fieldName='asset'
                config={assets}
                assetType='Image'
                onUpdate={(asset) => {
                  handleChangeAsset(asset)
                }}
              />
          }
            fullWidth
            onChange={() => {
              handleSaveAsset()
              setShowAddDialog(false)
            }}
            onClose={() => setShowAddDialog(false)}
          />
          )
        : null}
    </Box>
  )
}
export default RightPanelDrawing

const LineSettings = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [showColorPickerLine, setShowColorPickerLine] = useState(false)
  const [showColorPickerText, setShowColorPickerText] = useState(false)
  const [showColorPickerFill, setShowColorPickerFill] = useState(false)
  const [showColorPickerBorder, setShowColorPickerBorder] = useState(false)
  const [lineColor, setLineColor] = useState('#000000')
  const [lineWidth, setLineWidth] = useState(1)
  const [text, setText] = useState()
  const [shortText, setShortText] = useState()
  const [labelWidth, setLabelWidth] = useState(1)
  const [textColor, setTextColor] = useState('#000000')
  const [fillColor, setFillColor] = useState('#000000')
  const [borderColor, setBorderColor] = useState('#000000')

  useEffect(() => {
    if (isLoading) {
      const dc = props.product.drawingConfig
      setShortText(dc.extra.line.shortText)
      setLineColor(dc.extra.line.color)
      setLineWidth(dc.extra.line.width)
      setText(dc.extra.label.text)
      setShortText(dc.extra.label.shortText)
      setTextColor(dc.extra.label.color)
      setFillColor(dc.extra.label.fillColor)
      setBorderColor(dc.extra.label.borderColor)
      setLabelWidth(dc.extra.label.width)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    const p = JSON.parse(JSON.stringify(props.product))
    if (name === 'lineWidth') {
      p.drawingConfig.extra.line.width = value
      setLineWidth(value)
    } else if (name === 'labelWidth') {
      p.drawingConfig.extra.label.width = value
      setLabelWidth(value)
    } else if (name === 'text') {
      p.drawingConfig.extra.label.text = value
      setText(value)
    } else if (name === 'shortText') {
      p.drawingConfig.extra.label.shortText = value
      setShortText(value)
    }
    props.setProduct(p)
  }

  const handleChangeColor = (name, color) => {
    const p = JSON.parse(JSON.stringify(props.product))
    if (name === 'lineColor') {
      setLineColor(color)
      p.drawingConfig.extra.line.color = color
    } else if (name === 'textColor') {
      setTextColor(color)
      p.drawingConfig.extra.label.color = color
    } else if (name === 'fillColor') {
      setFillColor(color)
      p.drawingConfig.extra.label.fillColor = color
    } else if (name === 'borderColor') {
      setBorderColor(color)
      p.drawingConfig.extra.label.borderColor = color
    }
    props.setProduct(p)
  }

  if (isLoading) return <LoadingPleaseWait />

  let disabled = false
  if (props.product.drawingConfig_ovrd === false) {
    disabled = true
  }
  if (props.variant === 'pricing') {
    disabled = true
  }

  return (
    <Grid item xs={12}>
      <Box sx={{ pb: 2 }}>
        <Typography sx={{ fontWeight: 'bold', pb: 1 }}>Line Settings</Typography>
      </Box>

      <Grid item xs={12} sm={6}>
        {showColorPickerLine && !disabled
          ? (
            <ColorPicker
              color={lineColor}
              onChange={(c) => {
                setShowColorPickerLine(!showColorPickerLine)
                if (!disabled) {
                  handleChangeColor('lineColor', c)
                }
              }}
              onClose={(e) => setShowColorPickerLine(!showColorPickerLine)}
            />
            )
          : (
            <TextField
              fullWidth
              disabled={disabled}
              id='lineColor'
              name='lineColor'
              variant='outlined'
              label='Color'
              helperText='Pick the color for the line.'
              defaultValue={lineColor}
              onClick={() => setShowColorPickerLine(!showColorPickerLine)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <IconButton sx={{ width: 24, height: 24, border: 1, borderColor: 'black', borderRadius: 0, backgroundColor: lineColor }}>
                      <SquareIcon sx={{ color: lineColor }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            )}
      </Grid>

      <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>
        <Grid container>
          <Grid item xs>
            <Typography sx={{ pt: 0.5 }} noWrap>
              Line Width
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Slider disabled={disabled} name='lineWidth' id='lineWidth' size='large' value={lineWidth} step={1} min={1} max={5} onChange={handleChange} />
          </Grid>
          <Grid item xs sx={{ pl: 2 }}>
            <Typography sx={{ pt: 0.5 }}>{lineWidth}px</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ pb: 2 }}>
        <Typography sx={{ fontWeight: 'bold', pb: 1 }}>Label Settings</Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ pb: 2 }}>
          <TextField
            disabled={disabled}
            fullWidth
            id='text'
            name='text'
            variant='outlined'
            label='Label Text'
            value={text || ''}
            onChange={handleChange}
            inputProps={{ maxLength: 40 }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            disabled={disabled}
            fullWidth
            id='shortText'
            name='shortText'
            variant='outlined'
            label='Short Text'
            value={shortText || ''}
            onChange={handleChange}
            inputProps={{ maxLength: 5 }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {showColorPickerText && !disabled
            ? (
              <ColorPicker
                color={textColor}
                onChange={(c) => {
                  setShowColorPickerText(!showColorPickerText)
                  handleChangeColor('textColor', c)
                }}
                onClose={(e) => setShowColorPickerText(!showColorPickerText)}
              />
              )
            : (
              <TextField
                fullWidth
                disabled={disabled}
                id='textColor'
                name='textColor'
                variant='outlined'
                label='Text Color'
                helperText='Pick the color for the label text.'
                value={textColor || '#000000'}
                onClick={() => setShowColorPickerText(!showColorPickerText)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <IconButton sx={{ width: 24, height: 24, border: 1, borderColor: 'black', borderRadius: 0, backgroundColor: textColor }}>
                        <SquareIcon sx={{ color: textColor }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              )}
        </Grid>

        <Grid item xs={12} sm={6}>
          {showColorPickerFill && !disabled
            ? (
              <ColorPicker
                color={textColor}
                onChange={(c) => {
                  setShowColorPickerFill(!showColorPickerFill)
                  handleChangeColor('fillColor', c)
                }}
                onClose={(e) => setShowColorPickerFill(!showColorPickerFill)}
              />
              )
            : (
              <TextField
                fullWidth
                disabled={disabled}
                id='fillColor'
                name='fillColor'
                variant='outlined'
                label='Fill Color'
                helperText='Pick the color for the label fill.'
                value={fillColor || '#000000'}
                onClick={() => setShowColorPickerFill(!showColorPickerFill)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <IconButton sx={{ width: 24, height: 24, border: 1, borderColor: 'black', borderRadius: 0, backgroundColor: fillColor }}>
                        <SquareIcon sx={{ color: fillColor }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              )}
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} sx={{ pt: 2 }}>
        {showColorPickerBorder && !disabled
          ? (
            <ColorPicker
              color={textColor}
              onChange={(c) => {
                setShowColorPickerBorder(!showColorPickerBorder)
                handleChangeColor('borderColor', c)
              }}
              onClose={(e) => setShowColorPickerBorder(!showColorPickerBorder)}
            />
            )
          : (
            <TextField
              fullWidth
              disabled={disabled}
              id='borderColor'
              name='borderColor'
              variant='outlined'
              label='Border Color'
              helperText='Pick the color for the label border.'
              value={borderColor || '#000000'}
              onClick={() => setShowColorPickerBorder(!showColorPickerBorder)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <IconButton sx={{ width: 24, height: 24, border: 1, borderColor: 'black', borderRadius: 0, backgroundColor: borderColor }}>
                      <SquareIcon sx={{ color: borderColor }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            )}
      </Grid>

      <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>
        <Grid container>
          <Grid item xs>
            <Typography sx={{ pt: 0.5 }} noWrap>
              Border Width
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Slider disabled={disabled} name='labelWidth' id='labelWidth' size='large' value={labelWidth} step={1} min={1} max={5} onChange={handleChange} />
          </Grid>
          <Grid item xs sx={{ pl: 2 }}>
            <Typography sx={{ pt: 0.5 }}>{labelWidth}px</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const AreaSettings = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [showColorPickerLine, setShowColorPickerLine] = useState(false)
  const [showColorPickerText, setShowColorPickerText] = useState(false)
  const [showColorPickerFill, setShowColorPickerFill] = useState(false)
  const [lineColor, setLineColor] = useState('#000000')
  const [lineWidth, setLineWidth] = useState(1)
  const [text, setText] = useState()
  const [rotation, setRotation] = useState()
  const [fontSize, setFontSize] = useState(16)
  const [textColor, setTextColor] = useState('#000000')
  const [fillColor, setFillColor] = useState('#000000')

  useEffect(() => {
    if (isLoading) {
      const dc = props.product.drawingConfig
      setLineColor(dc.extra.line.color)
      setLineWidth(dc.extra.line.width)
      setText(dc.extra.label.text)
      setRotation(dc.extra.label.rotation)
      setTextColor(dc.extra.label.color)
      setFillColor(dc.extra.label.fillColor)
      setFontSize(dc.extra.label.fontSize)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    const p = JSON.parse(JSON.stringify(props.product))
    if (name === 'lineWidth') {
      p.drawingConfig.extra.line.width = value
      setLineWidth(value)
    } else if (name === 'fontSize') {
      p.drawingConfig.extra.label.fontSize = value
      setFontSize(value)
    } else if (name === 'text') {
      p.drawingConfig.extra.label.text = value
      setText(value)
    } else if (name === 'rotation') {
      if (value >= -360 && value <= 360) {
        p.drawingConfig.extra.label.rotation = value
        setRotation(value)
      }
    }
    props.setProduct(p)
  }

  const handleChangeColor = (name, color) => {
    const p = JSON.parse(JSON.stringify(props.product))
    if (name === 'lineColor') {
      setLineColor(color)
      p.drawingConfig.extra.line.color = color
    } else if (name === 'textColor') {
      setTextColor(color)
      p.drawingConfig.extra.label.color = color
    } else if (name === 'fillColor') {
      setFillColor(color)
      p.drawingConfig.extra.label.fillColor = color
    }
    props.setProduct(p)
  }

  if (isLoading) return <LoadingPleaseWait />

  let disabled = false
  if (props.variant === 'pricing') {
    disabled = true
  }

  return (
    <Grid item xs={12}>
      <Box sx={{ pb: 2 }}>
        <Typography sx={{ fontWeight: 'bold', pb: 1 }}>Line Settings</Typography>
      </Box>

      <Grid item xs={12} sm={6}>
        {showColorPickerLine && !disabled
          ? (
            <ColorPicker
              color={lineColor}
              onChange={(c) => {
                setShowColorPickerLine(!showColorPickerLine)
                handleChangeColor('lineColor', c)
              }}
              onClose={(e) => setShowColorPickerLine(!showColorPickerLine)}
            />
            )
          : (
            <TextField
              disabled={disabled}
              fullWidth
              id='lineColor'
              name='lineColor'
              variant='outlined'
              label='Color'
              helperText='Pick the color for the line.'
              defaultValue={lineColor}
              onClick={() => setShowColorPickerLine(!showColorPickerLine)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <IconButton sx={{ width: 24, height: 24, border: 1, borderColor: 'black', borderRadius: 0, backgroundColor: lineColor }}>
                      <SquareIcon sx={{ color: lineColor }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            )}
      </Grid>

      <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>
        <Grid container>
          <Grid item xs>
            <Typography sx={{ pt: 0.5 }} noWrap>
              Line Width
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Slider disabled={disabled} name='lineWidth' id='lineWidth' size='large' value={lineWidth} step={1} min={1} max={5} onChange={handleChange} />
          </Grid>
          <Grid item xs sx={{ pl: 2 }}>
            <Typography sx={{ pt: 0.5 }}>{lineWidth}px</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ pb: 2 }}>
        <Typography sx={{ fontWeight: 'bold', pb: 1 }}>Label Settings</Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ pb: 2 }}>
          <TextField
            disabled={disabled}
            fullWidth
            id='text'
            name='text'
            variant='outlined'
            label='Label Text'
            value={text || ''}
            onChange={handleChange}
            inputProps={{ maxLength: 40 }}
          />
        </Grid>

        <Grid item xs={2}>
          <TextField
            disabled={disabled}
            fullWidth
            type='number'
            id='rotation'
            name='rotation'
            variant='outlined'
            label='Rotate Text'
            value={rotation || ''}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {showColorPickerText && !disabled
            ? (
              <ColorPicker
                color={textColor}
                onChange={(c) => {
                  setShowColorPickerText(!showColorPickerText)
                  handleChangeColor('textColor', c)
                }}
                onClose={(e) => setShowColorPickerText(!showColorPickerText)}
              />
              )
            : (
              <TextField
                disabled={disabled}
                fullWidth
                id='textColor'
                name='textColor'
                variant='outlined'
                label='Text Color'
                helperText='Pick the color for the label text.'
                value={textColor || '#000000'}
                onClick={() => setShowColorPickerText(!showColorPickerText)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <IconButton sx={{ width: 24, height: 24, border: 1, borderColor: 'black', borderRadius: 0, backgroundColor: textColor }}>
                        <SquareIcon sx={{ color: textColor }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              )}
        </Grid>

        <Grid item xs={12} sm={6}>
          {showColorPickerFill && !disabled
            ? (
              <ColorPicker
                color={textColor}
                onChange={(c) => {
                  setShowColorPickerFill(!showColorPickerFill)
                  handleChangeColor('fillColor', c)
                }}
                onClose={(e) => setShowColorPickerFill(!showColorPickerFill)}
              />
              )
            : (
              <TextField
                disabled={disabled}
                fullWidth
                id='fillColor'
                name='fillColor'
                variant='outlined'
                label='Fill Color'
                helperText='Pick the color for the label fill.'
                value={fillColor || '#000000'}
                onClick={() => setShowColorPickerFill(!showColorPickerFill)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <IconButton sx={{ width: 24, height: 24, border: 1, borderColor: 'black', borderRadius: 0, backgroundColor: fillColor }}>
                        <SquareIcon sx={{ color: fillColor }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              )}
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>
        <Grid container>
          <Grid item xs>
            <Typography sx={{ pt: 0.5 }} noWrap>
              Font Size
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Slider disabled={disabled} name='fontSize' id='fontSize' size='large' value={fontSize} step={2} min={8} max={128} onChange={handleChange} />
          </Grid>
          <Grid item xs sx={{ pl: 2 }}>
            <Typography sx={{ pt: 0.5 }}>{fontSize}px</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const styles = {
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  textField: {
    fontSize: 14
  },
  imageContainer: {
    display: 'flex',
    padding: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,.04)',
    border: '1px dashed rgba(0,0,0,.16)'
  }
}
