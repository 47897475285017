import React, { useState, useEffect } from 'react'
import { Grid, TextField, Typography, InputAdornment } from '@mui/material'
import { LoadingPleaseWait } from '@supportworks/react-components'

const RightPanelSalesCommission = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [product, setProduct] = useState({}) // For object manipulation
  const [spiff, setSpiff] = useState(null) // For screen display

  useEffect(() => {
    if (isLoading) {
      const p = _initialize(props.product)
      let spiff = 0
      if (p.pricingOptions.salesCommission.spiff) {
        spiff = p.pricingOptions.salesCommission.spiff
      } else {
        p.pricingOptions.salesCommission.spiff = spiff
      }
      setSpiff(spiff)
      setProduct(p)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line 

  useEffect(() => {
    setProduct(props.product)
    try {
      if (props.product.pricingOptions.salesCommission.spiff) {
        setSpiff(props.product.pricingOptions.salesCommission.spiff)
      }
    } catch (err) {
      // Avoiding a crappy multi-line if conditional for the nested JSON
    }
  }, [props.product]) // esline-disable-line

  const handleChange = (e) => {
    const p = _initialize(product)
    const name = e.target.name
    const value = Number(e.target.value)
    p.pricingOptions.salesCommission[name] = value
    setSpiff(value)
    setProduct(p)
    props.setProduct(p)
  }

  const _initialize = (product) => {
    const p = JSON.parse(JSON.stringify(product))
    if (!p.pricingOptions) p.pricingOptions = {}
    if (!p.pricingOptions.salesCommission) p.pricingOptions.salesCommission = {}
    return p
  }

  if (isLoading) return <LoadingPleaseWait />

  let disabled = false
  if (props.variant === 'pricing' && !product.salesCommission_ovrd) {
    disabled = true
  }

  return (
    <Grid container style={styles.gridContainer}>
      <Grid item xs={12} sx={{ p: 2, borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Typography style={styles.title}>Sales Commission</Typography>
      </Grid>
      <Grid item sm={12} sx={{ p: 2 }}>
        <TextField
          id='spiff'
          name='spiff'
          disabled={disabled}
          type='number'
          variant='outlined'
          label='SPIFF'
          value={spiff}
          InputProps={{
            style: styles.textField,
            startAdornment: (
              <InputAdornment position='start'>
                <Typography>$</Typography>
              </InputAdornment>
            )
          }}
          onChange={handleChange}
          sx={{ 'input:focus': { border: 'none' } }}
        />
      </Grid>
    </Grid>
  )
}
export default RightPanelSalesCommission

const styles = {
  gridContainer: {
    backgroundColor: 'white'
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  textField: {
    fontSize: 14
  }
}
