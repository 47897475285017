import { useEffect, useState } from 'react'
import { Badge, Box, Button, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { FormCard } from './FormCard'
import { DialogSolutionSelector } from 'components/Reusable/DialogSolutionSelector'

export const FormCardInspect = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [title, setTitle] = useState('No Title')
  const [subTitle, setSubTitle] = useState('')
  const [visible, setVisible] = useState(true)
  const [allowMultiple, setAllowMultiple] = useState(false)
  const [area, setArea] = useState('')
  const [solutions, setSolutions] = useState([])
  const [form, setForm] = useState()

  // From current config
  const [areas, setAreas] = useState([])

  useEffect(() => {
    if (props.form) {
      setForm(props.form)
      if (props.form.title) {
        setTitle(props.form.title)
      }
      if (props.form.subTitle) {
        setSubTitle(props.form.subTitle)
      }
      if (props.form.allowMultiple) {
        setAllowMultiple(props.form.allowMultiple)
      }
      if (typeof props.form.visible === 'boolean') {
        setVisible(props.form.visible)
      }
      if (props.form.area) {
        setArea(props.form.area)
      }
      if (props.form.solutions && props.form.solutions.default) {
        setSolutions(props.form.solutions.default)
      }
    }
    if (props.config) {
      if (props.config.areas) {
        setAreas(props.config.areas)
      }
    }
    setIsLoading(false)
  }, []) // eslint-disable-line

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    const f = JSON.parse(JSON.stringify(form))
    if (name === 'title') {
      f.title = value
      setTitle(value)
    } else if (name === 'subTitle') {
      f.subTitle = value
      setSubTitle(value)
    } else if (name === 'allowMultiple') {
      f.allowMultiple = !allowMultiple
      setAllowMultiple(!allowMultiple)
    } else if (name === 'visible') {
      f.visible = !visible
      setVisible(!visible)
    } else if (name === 'area') {
      f.area = value
      setArea(value)
    }
    const m = JSON.parse(JSON.stringify(props.meeting))
    // console.log(`${props.idx} ${JSON.stringify(f)}`);
    setForm(f)
    m.inspect[props.idx] = f
    props.setMeeting(m)
  }

  const handleSolutionsChange = (l) => {
    // console.log(`list of ids: ${JSON.stringify(l)}`);
    const f = JSON.parse(JSON.stringify(props.form))
    if (!f.solutions) {
      f.solutions = {}
    }
    f.solutions.default = l
    const m = JSON.parse(JSON.stringify(props.meeting))
    m.inspect[props.idx] = f
    setSolutions(l)
    props.setMeeting(m)
  }

  if (isLoading) return <LoadingPleaseWait />

  const jsx = (
    <>
      <Box sx={{ pt: 2, pl: 2, pb: 1, flex: 2 }}>
        <Grid container>
          <Grid item xs={6} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              id='title'
              name='title'
              variant='outlined'
              label={title ? 'Title' : null}
              placeholder='Please enter the title'
              value={title}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id='subTitle'
              name='subTitle'
              variant='outlined'
              label={subTitle ? 'Sub-Title' : null}
              placeholder='Please enter the sub-title.'
              value={subTitle}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ pt: 2 }}>
          <Grid item>
            <FormControl sx={{ minWidth: 160 }} size='small'>
              <InputLabel id='area'>Specify Area</InputLabel>
              <Select
                labelId='area'
                id='area'
                name='area'
                value={area}
                label='Area'
                onChange={handleChange}
              >
                {areas.map((a, idx) => {
                  return (
                    <MenuItem key={`area-${idx}`} value={a.id}>{a.title}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControlLabel
              name='visible'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='visible'
                  checked={!!visible}
                  color='primary'
                  onClick={handleChange}
                />
                }
              label={<Typography style={styles.textField}>Visible</Typography>}
              labelPlacement='start'
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              name='allowMultiple'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='allowMultiple'
                  checked={!!allowMultiple}
                  color='primary'
                  onClick={handleChange}
                />
                }
              label={<Typography style={styles.textField}>Allow Multiple</Typography>}
              labelPlacement='start'
            />
          </Grid>
          <Grid item sx={{ pl: 3 }}>
            <Button onClick={() => setShowAddDialog(!showAddDialog)}>
              Add Default Solutions {solutions && solutions.length ? <Badge badgeContent={solutions.length} color='primary' sx={{ pl: 2 }} /> : null}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {showAddDialog
        ? (
          <DialogSolutionSelector
            title='Select Default Solutions'
            list={solutions}
            solutions={props.config.solutions}
            assets={props.config.assets2}
            onSave={(l) => {
              handleSolutionsChange(l)
              setShowAddDialog(!showAddDialog)
            }}
            onClose={() => setShowAddDialog(!showAddDialog)}
          />
          )
        : null}
    </>
  )

  return (
    <FormCard {...props} jsx={jsx} />
  )
}

const styles = {
  textField: {
    fontSize: 14
  }
}
