import React, { useState, useEffect } from 'react'
import { Box, Button, CardMedia, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import SelectorWithPreview from 'components/Reusable/SelectorWithPreview'
import { getAssetInfo } from 'screens/Config/CompanyConfigPresentations'

export const RightPanelResource = (props) => {
  const [levels, setLevels] = useState([])
  const [levelIds, setLevelIds] = useState([])
  const [asset, setAsset] = useState({})
  const [assetId, setAssetId] = useState('')
  const [type, setType] = useState('')
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [showAddLevelDropDown, setShowAddLevelDropDown] = useState(false)
  const [newLevelId, setNewLevelId] = useState('')

  useEffect(() => {
    if (props.resource.levels) {
      setLevels(props.resource.levels)
    }
    if (props.resource.levelIds) {
      setLevelIds(props.resource.levelIds)
    }
    if (props.resource.asset) {
      const a = getAssetInfo(props.resource.asset, props.assetsConfig)
      if (a && a.previewURL) {
        setAsset(a)
      }
    }
  }, []); // eslint-disable-line

  const handleUpdate = (asset) => {
    if (asset) {
      setAssetId(asset.id)
      // Get the asset.title without the extension
      // If title has an extension, use what is to the left.  Otherwise use the title.
      const title = asset.title.includes('.') ? asset.title.split('.').slice(0, -1).join('.') : asset.title
      if ((!props.resource?.title || props.resource?.title === 'New Resource') && props.isAdding) {
        const c = JSON.parse(JSON.stringify(props.resource))
        c.title = title
        props.setResource(c)
      }
    } else {
      setAssetId(null)
    }
  }

  const handleSave = () => {
    if (assetId) {
      const c = JSON.parse(JSON.stringify(props.resource))
      c[type] = assetId
      const a = getAssetInfo(assetId, props.assetsConfig)
      if (type === 'asset') {
        setAsset(a)
      }
      props.setResource(c)
    }
  }

  const handleShowAddLevelDropDown = () => {
    setShowAddLevelDropDown(true)
  }

  const handleAddLevel = (e) => {
    const selectedCategoryId = e.target.value
    const selectedCategory = props.categoriesConfig.find(c => c.id === selectedCategoryId)

    if (selectedCategory) {
      const c = JSON.parse(JSON.stringify(props.resource))
      c.levels = [...levels, selectedCategory.category]
      c.levelIds = [...levelIds, selectedCategory.id]
      setLevels([...levels, selectedCategory.category])
      setLevelIds([...levelIds, selectedCategory.id])
      setNewLevelId('') // Reset the dropdown
      setShowAddLevelDropDown(false) // Hide the dropdown after selection
      props.setResource(c)
    }
  }

  const handleDeleteLevel = (idx) => {
    const updatedLevels = levels.filter((_, i) => i !== idx)
    const updatedLevelIds = levelIds.filter((_, i) => i !== idx)

    setLevels(updatedLevels)
    setLevelIds(updatedLevelIds)

    const updatedResource = {
      ...props.resource,
      levels: updatedLevels,
      levelIds: updatedLevelIds
    }
    props.setResource(updatedResource)
  }

  let assetTypes = ['Video']
  if (props.resource.type === 'audio') {
    assetTypes = ['Audio']
  } else if (props.resource.type === 'pdf') {
    assetTypes = ['PDF']
  }

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Grid container wrap='nowrap'>
          <Grid item xs={12}>
            <Stack>
              <Box sx={{ pb: 2 }}>
                <Typography variant='h6'>Select Resource</Typography>
                <Typography variant='body2'>In the app, this resource will not be stored locally, unless the user downloads the resource locally for viewing later or offline.</Typography>
              </Box>
              {props.resource?.asset && asset.previewURL
                ? (
                  <Box style={styles.imageContainer} sx={{ mr: 2 }}>
                    {asset.type === 'Video'
                      ? (
                        <video
                          style={{ width: 'auto', minWidth: '100%' }}
                          controls
                          height='160'
                          poster={asset.previewURL}
                          onClick={(event) => event.target.play()}
                          onMouseOut={(event) => event.target.pause()}
                          src={`${asset.path}#t=1`}
                        />
                        )
                      : asset.type === 'Audio'
                        ? (
                          <Box sx={{ p: 1, width: '50vw' }}>
                            <audio controls style={{ width: '100%' }}>
                              <source src={asset.path} type='audio/mpeg' />
                              Your browser does not support the audio element.
                            </audio>
                          </Box>
                          )
                        : (
                          <a href={asset.path} target='_blank' rel='noreferrer'>
                            <CardMedia component='img' height='160' sx={{ objectFit: 'contain' }} image={asset.previewURL} alt={asset.previewURL} />
                          </a>
                          )}
                  </Box>
                  )
                : (
                  <Typography sx={{ pt: 2 }} variant='body2'>There is no resource asset. Please add one.</Typography>
                  )}
              {props.resource.asset && asset.filename
                ? (
                  <Box sx={{ pt: 2, pl: 2 }}>
                    <Typography variant='body1'>{asset.title}</Typography>
                    <Typography variant='body2'><code style={{ color: 'black' }}>{asset.filename}</code></Typography>
                  </Box>
                  )
                : null}
              <Box sx={{ pt: 2 }}>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    setType('asset')
                    setShowAddDialog(!showAddDialog)
                  }}
                >
                  {props.resource.asset ? 'Replace' : 'Add'} Resource
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ p: 2 }}>
        <Typography variant='h6'>Organize</Typography>
        <Typography variant='body2' sx={{ pb: 2 }}>
          Determine where this resource will be placed in the resource organization.
        </Typography>

        {levels.map((l, idx) => {
          return (
            <Box key={`level-${idx}`} sx={{ pt: 1, display: 'flex', alignItems: 'center', width: '50%' }}>
              <FormControl sx={{ pb: { sm: 1 }, 'input:focus': { border: 'none' } }}>
                <InputLabel id={`level-label-${idx}`}>Level {idx + 1}</InputLabel>
                <Select
                  id={`category-${idx}`}
                  name={`category-${idx}`}
                  label='Category'
                  labelId={`level-label-${idx}`}
                  variant='outlined'
                  value={levelIds[idx]}
                  onChange={(e) => {
                    const updatedLevelIds = [...levelIds]
                    updatedLevelIds[idx] = e.target.value
                    setLevelIds(updatedLevelIds)

                    const updatedLevels = [...levels]
                    const selectedCategory = props.categoriesConfig.find(c => c.id === e.target.value)
                    if (selectedCategory) {
                      updatedLevels[idx] = selectedCategory.category
                    }
                    setLevels(updatedLevels)
                    const c = JSON.parse(JSON.stringify(props.resource))
                    c.levels = updatedLevels
                    c.levelIds = updatedLevelIds
                    props.setResource(c)
                  }}
                >
                  {props.categoriesConfig.map((c) => (
                    <MenuItem key={`cat-${c.id}`} value={c.id}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='caption' sx={{ mr: 1 }}>{getCategoryPreviewURL(c, props.assetsConfig)}</Typography>
                        <Typography variant='subtitle'>{c.category}</Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/** Show a 48px version of the asset.path so I can see if it is working */}
              <IconButton
                aria-label='delete'
                color='default'
                onClick={() => handleDeleteLevel(idx)}
              >
                <DeleteIcon variant='icon' />
              </IconButton>
            </Box>
          )
        })}

        {showAddLevelDropDown && props.categoriesConfig && (
          <Box sx={{ pt: 1, width: '50%' }}>
            <FormControl fullWidth sx={{ pb: { sm: 1 }, 'input:focus': { border: 'none' } }}>
              <InputLabel id='new-level-label'>Select Category</InputLabel>
              <Select
                id='new-level'
                name='new-level'
                label='Category'
                labelId='new-level-label'
                variant='outlined'
                value={newLevelId}
                onChange={handleAddLevel}
              >
                {props.categoriesConfig.map((c) => (
                  <MenuItem key={`cat-${c.id}`} value={c.id}>
                    <Typography variant='subtitle'>{c.category}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        <Box sx={{ pt: 1 }}>
          <Button
            color='primary'
            variant='contained'
            onClick={handleShowAddLevelDropDown}
          >
            Add Level
          </Button>
          <Button
            color='primary'
            variant='outlined'
            sx={{ ml: 1 }}
            onClick={() => {
              props.onEditCategories && props.onEditCategories()
            }}
          >
            Edit Categories
          </Button>
        </Box>
      </Box>

      {showAddDialog && (
        <DialogGeneric
          title='Select Resource Asset'
          content={
            <SelectorWithPreview
              selectedConfig={props.resource}
              fieldName={type}
              config={props.assetsConfig}
              assetType={assetTypes}
              onUpdate={(asset) => handleUpdate(asset)}
            />
          }
          fullWidth
          onChange={() => {
            handleSave()
            setShowAddDialog(false)
          }}
          onClose={() => setShowAddDialog(false)}
        />
      )}
    </>
  )
}

const getCategoryPreviewURL = (category, assetsConfig) => {
  const a = getAssetInfo(category.asset, assetsConfig)
  if (a && a.type === 'Audio') {
    return <PlayArrowIcon />
  } else if (a && a.previewURL) {
    return <Box sx={{ maxWidth: 36 }}><CardMedia component='img' image={a.previewURL} alt={a.previewURL} /></Box>
  }
}

const styles = {
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  textField: {
    fontSize: 14
  },
  imageContainer: {
    display: 'inline-block',
    padding: '16px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(0,0,0,.04)',
    border: '1px dashed rgba(0,0,0,.16)',
    maxWidth: 'fit-content'
  }
}
