import React, { useEffect, useState } from 'react'
import FieldTypeExpandMarker from '../Reusables/FieldTypeExpandMarker'
import FieldTypeAddLogicPanel from '../FieldTypeAddLogicPanel'
import { Button, CardMedia, Paper, Typography } from '@mui/material'
import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined'
import { DialogGeneric } from '../../../../components/Reusable/DialogGeneric'
import SelectorWithPreview from '../../../../components/Reusable/SelectorWithPreview'

function Image (props) {
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [asset, setAsset] = useState({})
  const [assetId, setAssetId] = useState('')

  const changeHandlerVisible = (visible) => {
    props.onChangeVisible(visible)
  }

  const handleSave = () => {
    if (assetId) {
      const a = getAssetInfo(assetId, props.assetsConfig)
      const newField = JSON.parse(JSON.stringify(props.field))
      newField.asset = assetId
      setAsset(a)
      props.onChange(newField)
    }
  }

  const getAssetInfo = (id, config) => {
    const x = config.find((o) => o.id === id)
    if (x) {
      return x
    }
    return null
  }

  const handleUpdate = (asset) => {
    // SelectorWithPreview does not have a cancel, so I'm using the DialogGeneric exit strategy.
    if (asset) {
      setAssetId(asset.id)
    } else {
      setAssetId(null)
    }
  }

  const assetDeleteHandler = () => {
    const newField = JSON.parse(JSON.stringify(props.field))
    delete newField.asset
    setAsset({})
    props.onChange(newField)
  }

  useEffect(() => {
    if (props.field.asset) {
      const a = getAssetInfo(props.field.asset, props.assetsConfig)
      if (a && a.previewURL) {
        setAsset(a)
      }
    }
  }, []) // eslint-disable-line

  return (
    <>
      {
                props.expandMarker &&
                  <FieldTypeExpandMarker />
            }

      <div style={styles.logicPanel}>
        <FieldTypeAddLogicPanel
          {...props}
          field={props.field}
          onChangeVisible={(visible) => changeHandlerVisible(visible)}
        />

        <div style={{ display: 'flex' }} key='video'>
          <FieldTypeExpandMarker />
          <Paper elevation={0} square style={styles.singleSelectionPaper}>
            <div style={styles.addVideo}>
              {
                                ('asset' in props.field && props.field.asset !== '') &&
                                  <div>
                                    <a href={asset.path} target='_blank' rel='noreferrer'>
                                      <CardMedia component='img' height='160' sx={{ objectFit: 'contain' }} image={asset.previewURL} alt={asset.previewURL} />
                                    </a>
                                  </div>
                            }

              {
                                !('asset' in props.field) &&
                                  <Button
                                    style={styles.btnVideo}
                                    onClick={() => {
                                      setShowAddDialog(!showAddDialog)
                                    }}
                                  >
                                    <MovieOutlinedIcon
                                      style={styles.playIcon}
                                      fontSize='large'
                                      color='primary'
                                    />
                                    Choose Video from Assets
                                  </Button>
                            }

              {
                                showAddDialog
                                  ? (
                                    <DialogGeneric
                                      title='Select Document'
                                      content={
                                        <SelectorWithPreview
                                          selectedConfig={props.field}
                                          config={props.assetsConfig}
                                          fieldName='asset'
                                          assetType={['Video']}
                                          onUpdate={(asset) => {
                                            handleUpdate(asset)
                                          }}
                                        />
                                        }
                                      fullWidth
                                      onChange={() => {
                                        handleSave()
                                        setShowAddDialog(false)
                                      }}
                                      onClose={() => setShowAddDialog(false)}
                                    />
                                    )
                                  : null
                            }
            </div>

            {
                            ('asset' in props.field && props.field.asset !== '') &&
                              <div style={{ float: 'left' }}>
                                <Paper style={{ backgroundColor: '#e9ecef87', padding: '8px 16px 8px 8px', borderRadius: 0, boxShadow: 'none', marginLeft: '8px' }}>
                                  <Typography variant='subtitle1' style={{ fontSize: '14px', color: 'grey', marginLeft: '8px' }}>Asset Name</Typography>
                                  <div style={{ marginLeft: '8px' }}>{asset.title}</div>
                                </Paper>
                                <div>
                                  <Button
                                    variant='text'
                                    color='primary'
                                    onClick={() => {
                                      setShowAddDialog(!showAddDialog)
                                    }}
                                  >
                                    Edit Asset
                                  </Button>
                                  <Button
                                    variant='text'
                                    color='error'
                                    onClick={() => assetDeleteHandler()}
                                  >
                                    Remove
                                  </Button>
                                </div>
                              </div>
                        }
          </Paper>
        </div>
      </div>
    </>
  )
}
const styles = {
  relatedMain: {
    display: 'flex'
  },
  relatedMainHide: {
    display: 'none'
  },
  logicPanel: {
    // width: "100%"
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%'
  },
  singleSelectionPaper: {
    borderLeft: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F7F7F7',
    display: 'flex',
    // alignItems: "center",
    width: '1002.11px',
    height: '250px',
    paddingTop: '24px'
  },
  btnVideo: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column'
  },
  playIcon: {
    marginBottom: '15px'
  },
  addVideo: {
    width: '320px',
    height: '200px',
    backgroundColor: '#fff',
    marginLeft: '24px',
    // marginTop: "25px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px dotted #ced4da'
  }
}

export default Image
