import React from 'react'
import Api from '../../library/Api'
import CompanyConfig from './index'
import Company from '../Company'
import ResourceNotFound from '../Company/ResourceNotFound'

/**
 * Loads requested configuration object and chooses appropriate editor for it.
 */
class CompanyConfigTagDiscriminator extends React.Component {
  /**
   *
   * @param {Object} props
   * @param {int} props.organizationId
   * @param {int} props.configId
   */
  constructor (props) {
    super(props)
    this.mounted = false
    this.state = {
      isLoading: true,
      organizationId: props.organizationId,
      configId: props.configId,
      config: null
    }
  }

  componentDidMount () {
    this.mounted = true
    if (this.state.configId) {
      Api.callRegisteredMethod('getConfigById', { configId: this.state.configId }).then((rsp) => {
        if (rsp.status === 'success') {
          const changes = {
            isLoading: false,
            config: rsp.data[0]
          }
          this.setState(changes)
        }
      })
    } else {
      this.setState({ isLoading: false })
    }
  }

  render () {
    if (!this.mounted) {
      return null
    }

    if (this.state.isLoading) {
      return <p>Loading configuration, please wait.</p>
    }
    if (!this.state.config && this.mounted) {
      return <ResourceNotFound />
    }
    const variant = this.state.config.tag ? this.state.config.tag : CompanyConfig.DEFAULT_VARIANT
    const flavor = 'Edit'

    if (!CompanyConfig[variant] || !CompanyConfig[variant][flavor]) {
      return <p>We can't find an appropriate editor for the tag "{this.state.config.tag}".</p>
    }
    const CfgComponent = CompanyConfig[variant][flavor]
    return <CfgComponent organizationId={Company.getCurrentOrgId()} config={this.state.config} />
  }
}

export default CompanyConfigTagDiscriminator
