import React, { useState, useEffect } from 'react'
import { Button, Checkbox, FormGroup, FormControlLabel, Grid, IconButton, Stack, TextField, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { SelectionHeader } from './components/SelectionHeader'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { DialogGeneric } from '../../../components/Reusable/DialogGeneric'
import Helper from '@supportworks/helper'

const RightPanelContractGroups = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [product, setProduct] = useState({}) // For object manipulation
  const [contractGroup, setContractGroup] = useState([]) // For screen manipulation
  const [contractGroups, setContractGroups] = useState([])
  const [variant, setVariant] = useState('solo-editor')
  const [selectedIdx, setSelectedIdx] = useState(-1)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)

  useEffect(() => {
    if (isLoading) {
      if (!props.productFeatures.contract_groups) {
        props.productFeatures.contract_groups = []
      }
      setContractGroups(props.productFeatures.contract_groups)
      if (props.product) {
        const p = props.product
        if (!p.contractGroup) p.contractGroup = []
        setContractGroup(p.contractGroup)
        setProduct(p)
        setVariant('product-editor')
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (variant === 'product-editor') {
      setContractGroup(props.product.contractGroup)
      setProduct(props.product)
    }
  // eslint-disable-next-line
  }, [props.product])

  const handleChange = (e) => {
    if (variant === 'product-editor') {
      const p = JSON.parse(JSON.stringify(product))
      if (p.contractGroup.includes(e.target.id)) {
        const idx = p.contractGroup.indexOf(e.target.id)
        p.contractGroup.splice(idx, 1)
      } else {
        p.contractGroup.push(e.target.id)
      }
      setContractGroup(p.contractGroup)
      setProduct(p)
      props.setProduct(p)
    }
  }

  const handleDelete = (idx) => {
    const obj = JSON.parse(JSON.stringify(contractGroups))
    if (window.confirm(`Are you sure you want to delete '${obj[idx].title}'?`)) {
      obj.splice(idx, 1)
      props.productFeatures.contract_groups = obj
      setContractGroups(obj)
    }
    handleCloseDialog()
  }

  const handleSort = () => {
    const obj = JSON.parse(JSON.stringify(contractGroups))
    obj.sort((a, b) => a.title.localeCompare(b.title))
    props.productFeatures.contract_groups = obj
    setContractGroups(obj)
  }

  const handleMoveUp = (idx) => {
    const obj = JSON.parse(JSON.stringify(contractGroups))
    const ele = obj.splice(idx, 1)[0]
    obj.splice(idx - 1, 0, ele)
    props.productFeatures.contract_groups = obj
    setContractGroups(obj)
  }

  const handleMoveDown = (idx) => {
    const obj = JSON.parse(JSON.stringify(contractGroups))
    const ele = obj.splice(idx, 1)[0]
    obj.splice(idx + 1, 0, ele)
    props.productFeatures.contract_groups = obj
    setContractGroups(obj)
  }

  const handleCloseDialog = () => {
    setSelectedIdx(-1)
    setShowEditDialog(false)
    setShowAddDialog(false)
  }

  if (isLoading) return <LoadingPleaseWait />

  const disabled = false
  return (
    <>
      {contractGroups.length
        ? (
          <Grid container style={styles.gridContainer}>
            <SelectionHeader
              title='Contract Groups'
              onAdd={
                !disabled
                  ? () => {
                      setShowAddDialog(!showAddDialog)
                    }
                  : null
              }
              onSort={!disabled ? handleSort : null}
            />
            <Grid item xs={12}>
              {contractGroups.map((w, idx) => {
                let checked = false
                if (contractGroup.includes(w.id)) checked = true
                return (
                  <Grid container key={`w-${idx}`} style={styles.selectionContainer}>
                    <Grid item xs={8} sx={{ pt: 2, pb: 2 }}>
                      {variant === 'product-editor'
                        ? (
                          <FormGroup>
                            <FormControlLabel
                              sx={{ m: 0, p: 0 }}
                              disabled={disabled}
                              control={<Checkbox sx={{ m: 0, p: 0 }} id={w.id} name={w.id} checked={checked} onClick={handleChange} />}
                              label={<Typography sx={{ pl: 1 }} style={styles.textField}>{w.title}</Typography>}
                            />
                          </FormGroup>
                          )
                        : (
                          <Typography style={styles.textField}>
                            {w.title}
                          </Typography>
                          )}
                    </Grid>
                    {!disabled
                      ? (
                        <>
                          <Grid item sx={{ pt: 1, pl: 2.25 }}>
                            <IconButton disabled={idx === 0} onClick={() => handleMoveUp(idx)}>
                              <KeyboardArrowUpIcon style={styles.icon} />
                            </IconButton>
                          </Grid>
                          <Grid item sx={{ pt: 1 }}>
                            <IconButton disabled={idx === contractGroups.length - 1} onClick={() => handleMoveDown(idx)}>
                              <KeyboardArrowDownIcon style={styles.icon} />
                            </IconButton>
                          </Grid>
                          <Grid item sx={{ pt: 1 }}>
                            <IconButton
                              onClick={() => {
                                setSelectedIdx(idx)
                                setShowEditDialog(!showEditDialog)
                              }}
                            >
                              <EditIcon style={styles.icon} />
                            </IconButton>
                          </Grid>
                        </>
                        )

                      : null}
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
          )
        : (
          <Grid container spacing={0} direction='row' alignItems='center' justifyContent='center' style={{ minHeight: '50vh' }}>
            <Stack spacing={2}>
              <Typography style={styles.stackItem}>There are no contract groups.</Typography>
              <span style={styles.stackItem}>
                <Button variant='contained' onClick={() => setShowAddDialog(!showAddDialog)}>
                  Add One
                </Button>
              </span>
            </Stack>
          </Grid>
          )}
      {showAddDialog || showEditDialog
        ? (
          <DialogEdit
            {...props}
            variant={showAddDialog ? 'new' : 'edit'}
            selectedIdx={selectedIdx}
            contractGroups={contractGroups}
            onDelete={() => {
              handleDelete(selectedIdx)
            }}
            onSave={handleCloseDialog}
            onClose={handleCloseDialog}
          />
          )
        : null}

    </>
  )
}
export default RightPanelContractGroups

const DialogEdit = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')

  useEffect(() => {
    if (isLoading) {
      let p
      if (props.variant === 'edit') {
        p = props.contractGroups[props.selectedIdx]
        setTitle(p.title || 'No title')
        setBody(p.body || 'No description')
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleSave = (e) => {
    let p
    if (props.variant === 'new') {
      const guid = Helper.guid()
      p = {
        id: guid,
        title: title || 'No title',
        body: body || 'No description',
        custom: true // To track in case we want to prevent edits on non-custom values
      }
      props.contractGroups.unshift(p)
    } else {
      p = props.contractGroups[props.selectedIdx]
      p.title = title
      p.body = body
    }
    props.onSave()
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'title') {
      setTitle(value)
    } else if (name === 'body') {
      setBody(value)
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  const contractGroup = props.contractGroups[props.selectedIdx]

  const jsx = (
    <>
      <Grid container>
        <Grid item sm={12} sx={{ pb: 2 }}>
          <TextField
            fullWidth
            required
            id='title'
            name='title'
            variant='outlined'
            label='Title'
            helperText='Please enter the title.'
            value={title || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            fullWidth
            multiline
            rows={3}
            id='body'
            name='body'
            variant='outlined'
            label='Description'
            helperText='Please enter the description.'
            value={body || ''}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </>
  )

  return (
    <DialogGeneric
      title={props.variant === 'edit' ? 'Edit Contract Group' : 'Add New Contract Group'}
      content={jsx}
      fullWidth
      onChange={handleSave}
      onClose={props.onClose}
      onDelete={props.onDelete && contractGroup && contractGroup.custom ? props.onDelete : null}
    />
  )
}

const styles = {
  gridContainer: {
    width: 450,
    maxWidth: 450
  },
  selectionContainer: {
    border: '1px solid rgba(0,0,0,.16)',
    marginTop: -1,
    paddingLeft: 16,
    backgroundColor: 'white',
    userSelect: 'none',
    WebkitUserSelect: 'none'
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  textField: {
    fontSize: 14
  },
  stackItem: {
    textAlign: 'center'
  }
}
