import React, { useState, useEffect } from 'react'
import { Box, Button, FormControlLabel, Grid, Switch, Typography, Radio, IconButton } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import LogoutIcon from '@mui/icons-material/Logout'
import SaveIcon from '@mui/icons-material/Save'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import { Link, useHistory } from 'react-router-dom'
import Api, { ApiBase } from '../../library/Api'
import Config from 'config'
import { DialogGeneric } from './DialogGeneric'
import { DataGrid } from '@mui/x-data-grid'
import Helper from 'library/helper'
import { LoadingPleaseWait } from '@supportworks/react-components'
import LocalStorage from '../../library/LocalStorage'
import Permissions from '../../library/Permissions'
import { useLocalStorage } from '@supportworks/react-hooks'

const FooterLeftPanel = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [session, setSession] = useState({})
  const [isCRM, setIsCRM] = useState(false)
  const [currentScreen, setCurrentScreen] = useLocalStorage('currentScreen', '')
  const [impersonate, setImpersonate] = useState(null)
  const [showDialogCRM, setShowDialogCRM] = useState(false)
  const [persons, setPersons] = useState(null)
  const [myEmail, setMyEmail] = useState(null)
  const [impEmail, setImpEmail] = useState(null)
  const [sessionUser, setSessionUser] = useState({})
  const history = useHistory()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsCRM(await Helper.isCRM())
        const response = await Api.callRegisteredMethod('getSession')
        if (response && response.extra) {
          setSession(response)
          if (response.extra.impersonate) {
            setImpersonate(response.extra.impersonate)
          }
        }
        const sessionUser = await Api.callRegisteredMethod('getSessionUser')
        if (sessionUser) setSessionUser(sessionUser)
        setIsLoading(false)
      } catch (error) {
        console.error('Error:', error)
      }
    }

    fetchData()
    refresh()
  }, []) // eslint-disable-line

  useEffect(() => {
    const reload = async () => {
      setIsCRM(await Helper.isCRM())
      const session = await Api.callRegisteredMethod('getSession')
      if (session && session.extra) {
        setSession(session)
        setImpersonate(session.extra.impersonate)
      }
      setIsLoading(false)
    }
    reload()
    refresh()
  }, [showDialogCRM]) // eslint-disable-line

  const handleShowSelfEdit = () => {
    if (sessionUser) {
      setCurrentScreen('SelfUserEdit')
      history.push('/Company/:organizationId/Users/edit')
    }
  }

  const refresh = () => {
    Api.callRegisteredMethod('getAuthorizationManifest').then((au) => {
      Api.callRegisteredMethod('getUsers').then((users) => {
        let myEmail = null
        let impEmail = null
        if (session.extra) {
          myEmail = session.extra.email
          if (session.extra.impersonate) {
            impEmail = session.extra.impersonate.email
          }
        } else if (session.data?.data[0]?.person?.email) {
          myEmail = session.data.data[0].person.email
          impEmail = session.data.data[0].person.email
        }

        setPersons(users && users.persons ? users.persons : [])
        setMyEmail(myEmail)
        setImpEmail(impEmail)
        setIsLoading(false)
      })
    })
  }

  const handleShowCRMDialog = () => {
    setShowDialogCRM(!showDialogCRM)
  }

  const handleCompanySelect = async () => {
    await Api.callRegisteredMethod('stopImpersonation') // DEV-5590
    history.push('/Company')
  }

  const handleOnSave = async (person) => {
    await Api.callRegisteredMethod('impersonateUser', { personId: person.userId })
    setImpEmail(person.email)
    setShowDialogCRM(!showDialogCRM)
  }

  const handleOnClose = async (event) => {
    if (event && event.target.innerText === 'Disconnect') {
      await Api.callRegisteredMethod('stopImpersonation')
      setImpEmail(null)
    }
    setShowDialogCRM(!showDialogCRM)
  }

  if (isLoading) return <LoadingPleaseWait />

  const gitRevision = process.env.REACT_APP_SV_VERSION
  const myOrgs = LocalStorage.retrieve(Config.myOrgsKey)

  return (
    <Grid container sx={{ p: 2, backgroundColor: '#494E5E' }}>
      {Permissions.hasRole('super_user') && isCRM && (impersonate === null || impersonate === undefined)
        ? (
          <Grid item xs={12} sx={{ pt: 1 }}>
            <Button
              fullWidth
              sx={{
                ':hover': {
                  bgcolor: '#7B7F8A !important'
                }
              }}
              variant='contained'
              color='error'
              onClick={handleShowCRMDialog}
            >
              <Typography variant='button' style={styles.buttonText}>
                Not Connected to CRM
              </Typography>
            </Button>
          </Grid>
          )
        : null}

      {showDialogCRM && persons && persons.length
        ? (
          <DialogCRMUser
            title={'Connect to Organization\'s CRM with Org User'}
            list={persons}
            myEmail={myEmail}
            impEmail={impEmail}
            onSave={(row) => handleOnSave(row)}
            onClose={handleOnClose}
          />
          )
        : null}

      {Permissions.hasRole('super_user') && impersonate && impersonate.email
        ? (
          <Grid item xs={12} sx={{ pt: 1 }}>
            <Button
              fullWidth
              sx={{
                ':hover': {
                  bgcolor: '#7B7F8A !important'
                }
              }}
              variant='contained'
              color='error'
              onClick={handleShowCRMDialog}
            >
              Impersonating {impersonate.email}
            </Button>
          </Grid>
          )
        : null}

      {myOrgs && myOrgs.length > 1
        ? (
          <Grid item xs={12} sx={{ pt: 1 }}>
            <Button
              fullWidth
              sx={{
                ':hover': {
                  bgcolor: '#7B7F8A !important'
                }
              }}
              style={styles.button}
              variant='contained'
              onClick={handleCompanySelect}
            >
              <Typography variant='button' style={styles.buttonText}>
                Switch Companies
              </Typography>
            </Button>
          </Grid>
          )
        : null}

      {Api.callRegisteredMethod('isAuthenticated')
        ? (
          <Grid item xs={12} sx={{ pt: 2 }}>
            <Button
              fullWidth
              sx={{
                ':hover': {
                  bgcolor: '#7B7F8A !important'
                }
              }}
              style={styles.button}
              variant='contained'
              component={Link}
              to={ApiBase.resolvePath('/Logout')}
              startIcon={<LogoutIcon sx={{ color: 'white' }} />}
            >
              <Typography variant='button' sx={{ color: 'white' }}>
                Logout
              </Typography>
            </Button>
          </Grid>
          )
        : null}

      {sessionUser.id && (
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: 2 }}>
          <IconButton
            onClick={handleShowSelfEdit}
            sx={{
              backgroundColor: currentScreen === 'SelfUserEdit' ? '#7B7F8A' : '#494E5E'
            }}
          >
            <AccountCircleIcon sx={{ color: currentScreen === 'SelfUserEdit' ? 'white' : '#EDEDED' }} />
          </IconButton>
          <Typography variant='caption' sx={{ color: currentScreen === 'SelfUserEdit' ? 'white' : '#999999', textAlign: 'center' }}>
            {sessionUser.nameLast}
          </Typography>
        </Grid>
      )}

      {Permissions.hasRole('super_user')
        ? (
          <Grid item xs={12} sx={{ pt: 2, display: 'flex', justifyContent: 'center' }}>
            <Typography variant='caption'>{gitRevision}</Typography>
          </Grid>
          )
        : null}
    </Grid>
  )
}

const FooterRightPanel = ({
  isNew = false,
  isSaving = false,
  isDataLoading = false,
  lockName = false,
  useFormSubmit = false,
  status = undefined,
  useBaseEditor = false,
  onCancel,
  onDelete,
  onSave,
  onResetToDefault,
  onPublish = undefined,
  onLibraryAdd = undefined,
  onChangeEditor = undefined
}) => {
  const jsx = (
    <Grid container justifyContent='flex-start'>
      {onCancel
        ? (
          <Grid item xs sx={{ pr: 1 }}>
            <Button variant='contained' disabled={isDataLoading} color='primary' onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
          )
        : null}

      {onChangeEditor && Permissions.hasRole('super_user')
        ? (
          <>
            <Grid item sx={{ pr: 1 }}>
              <Button variant={!useBaseEditor ? 'contained' : 'outlined'} color='error' onClick={onChangeEditor} sx={{ mr: 1 }}>
                Editor
              </Button>
              <Button variant={useBaseEditor ? 'contained' : 'outlined'} color='error' onClick={onChangeEditor}>
                JSON
              </Button>
            </Grid>
          </>
          )
        : null}

      {onResetToDefault && Permissions.hasRole('super_user')
        ? (
          <Grid item sx={{ pr: 1 }}>
            <Button variant='contained' disabled={isDataLoading} color='error' onClick={onResetToDefault} startIcon={<AdminPanelSettingsIcon />}>
              {isNew ? 'Populate with Defaults' : 'Reset to Default'}
            </Button>
          </Grid>
          )
        : null}

      {onDelete && Permissions.hasRole('super_user') && !lockName
        ? (
          <Grid item sx={{ pr: 1 }}>
            <Button variant='contained' disabled={isDataLoading} color='error' onClick={onDelete} startIcon={<AdminPanelSettingsIcon />}>
              Delete
            </Button>
          </Grid>
          )
        : null}

      {onLibraryAdd
        ? (
          <Grid item sx={{ pr: 1 }}>
            <Button variant='contained' disabled={isDataLoading} color='inherit' onClick={onLibraryAdd} startIcon={<LibraryBooksIcon />}>
              Import from Libraries
            </Button>
          </Grid>
          )
        : null}

      <Grid item xs>
        <Grid container justifyContent='flex-end' sx={{ pr: 2 }}>
          {onPublish
            ? (
              <Grid item>
                <FormControlLabel
                  name='published'
                  control={
                    <Switch
                      checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                      id='tax'
                      checked={status === 'Published'}
                      color='primary'
                      onClick={() => {
                        if (status === 'Published') {
                          onPublish('status', 'Unpublished')
                        } else {
                          onPublish('status', 'Published')
                        }
                      }}
                    />
                }
                  label={<Typography style={styles.textField}>Active</Typography>}
                  labelPlacement='start'
                  sx={{ mr: 2, p: 0 }}
                />
              </Grid>
              )
            : null}

          {onSave && !useFormSubmit
            ? (
              <Grid item>
                <Button variant='contained' disabled={isDataLoading} color='primary' onClick={onSave} startIcon={<SaveIcon />}>
                  {isSaving ? 'Saving...' : 'Save'}
                </Button>
              </Grid>
              )
            : null}

          {useFormSubmit
            ? (
              <Grid item>
                <Button type='submit' variant='contained' disabled={isDataLoading} color='primary' startIcon={<SaveIcon />}>
                  {isSaving ? 'Saving...' : 'Save'}
                </Button>
              </Grid>
              )
            : null}
        </Grid>
      </Grid>
    </Grid>
  )

  return (
    <>
      <Box style={styles.footer} sx={{ pl: 33, pr: 1, display: { xs: 'none', sm: 'none', md: 'block' } }}>
        {jsx}
      </Box>
      <Box style={styles.footer} sx={{ pl: 1, pr: 1, display: { xs: 'block', sm: 'block', md: 'none' } }}>
        {jsx}
      </Box>
    </>
  )
}

export { FooterRightPanel, FooterLeftPanel }

const DialogCRMUser = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [rows, setRows] = useState([]) // Array of row objects that can be selected
  const [selectedRows, setSelectedRows] = useState([]) // Array of row idx's of things that have been checked
  const [radioChecked, setRadioChecked] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [impersonated, setImpersonated] = useState('impEmail' in props ? props.impEmail : '')

  useEffect(() => {
    if (isLoading) {
      const sr = []
      const r = []
      for (let x = 0; x < props.list.length; x++) {
        const person = props.list?.[x]?.person
        const hasOauth = props.list?.[x]?.hasOauth
        const personEmail = person?.email

        if (hasOauth) {
          r.push({
            id: x,
            userId: person.id,
            email: person.email,
            lastName: person.nameLast,
            firstName: person.nameFirst,
            hasOauth,
            action: personEmail === props.myEmail ? 'myEmail' : personEmail === props.impEmail ? 'impEmail' : 'user'
          })
        }
      }
      setImpersonated(props.impEmail)
      setRows(r)
      setSelectedRows(sr)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleSave = () => {
    props.onSave(selectedRows)
  }

  const changeHandleRadio = (row) => {
    setRadioChecked(row.id)
    setSelectedRows(row)
  }

  const columns = [
    {
      field: 'radiobutton',
      headerName: '',
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return <Radio checked={!!(radioChecked !== null && params.id === radioChecked)} value='' onClick={() => changeHandleRadio(params.row)} />
      }
    },
    {
      flex: 1,
      type: 'string',
      field: 'firstName',
      headerName: 'First Name',
      editable: false,
      sortable: true
    },
    {
      flex: 1,
      type: 'string',
      field: 'lastName',
      headerName: 'Last Name',
      editable: false,
      sortable: true
    },
    {
      flex: 2,
      type: 'string',
      field: 'email',
      headerName: 'Username',
      editable: false,
      sortable: true,
      renderCell: (params) => {
        let userIcon = null
        const color = 'primary'
        if (props.impEmail === params.row.email) {
          userIcon = <PersonOutlineIcon variant='icon' color={color} fontSize='small' />
        }
        return (
          <Grid container wrap='nowrap'>
            <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant='body2'>{params.row.email}</Typography>
            </Grid>
            <Grid item>
              <IconButton title='Impersonate User' aria-label='Impersonate User'>
                {userIcon}
              </IconButton>
            </Grid>
          </Grid>
        )
      }
    }
  ]

  // let rows = [];

  if (isLoading) return <LoadingPleaseWait />

  const jsx = (
    <Box sx={{ height: 710 }}>
      <Typography variant='body2' sx={{ mb: 3 }}>
        Connecting to a user, will allow you to pull data from the CRM to populate into the Master List, Assign, CRM Fields and any editor that relies on CRM
        information.
      </Typography>
      <DataGrid
        rows={rows}
        columns={columns}
        showToolbarDensity={false}
        showToolbarExport={false}
        // defaultPageSize={10}
        autoPageSize
        disableRowSelectionOnClick
      />
    </Box>
  )

  return (
    <DialogGeneric
      title={props.title}
      titleDone='Connect'
      titleClose='Disconnect'
      content={jsx}
      fullWidth
      onChange={handleSave}
      onClose={(event) => props.onClose(event)}
    />
  )
}

const styles = {
  button: {
    backgroundColor: '#6D717E'
  },
  buttonText: {
    color: 'white'
  },
  textField: {
    fontSize: 14,
    paddingRight: 4
  },
  footer: {
    position: 'fixed',
    width: '100%',
    paddingTop: 16,
    paddingBottom: 16,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255,255,255,.96)',
    border: '1px solid rgba(0,0,0,.16)',
    zIndex: 999
  }
}
