import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, IconButton, Typography, ImageList, ImageListItem, ImageListItemBar } from '@mui/material'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { DataGridAssets } from 'components/Reusable/DataGridAssets'
import { DialogAssetSelector } from 'components/Reusable/DialogAssetSelector'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import { getAssetInfo } from '../CompanyConfigPresentations'
import Helper from 'library/helper'
import { pdfjs, Document, Page } from 'react-pdf/dist/esm/entry.webpack5'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

export const RightPanelDetailsSlideshow = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [variant, setVariant] = useState('slideshow')
  const [assets, setAssets] = useState([])
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [showAddPDFPageDialog, setShowAddPDFPageDialog] = useState(false)
  const [assetPDF, setAssetPDF] = useState(null)

  useEffect(() => {
    if (isLoading) {
      const p = JSON.parse(JSON.stringify(props.presentation))
      if (!props.presentation.assets) {
        p.assets = []
        props.setPresentation(p)
      }
      setVariant('slideshow')
      setAssets(p.assets)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (l) => {
    const p = JSON.parse(JSON.stringify(props.presentation))
    p.assets = l
    setAssets(l)
    props.setPresentation(p)
  }

  const handleAdd = (l) => {
    // Loop thru existing, delete ones not in l that are not PDF
    const newl = []
    for (let x = 0; x < assets.length; x++) {
      if (assets[x].type === 'PDF') {
        newl.push(assets[x])
      }
      const found = l.find(o => o.asset === assets[x].asset)
      if (found) {
        newl.push(assets[x])
      }
    }
    // Loop thru new, add if new
    for (let x = 0; x < l.length; x++) {
      const found = newl.find(o => o.asset === l[x].asset)
      if (!found) {
        newl.push(l[x])
      }
    }
    const p = JSON.parse(JSON.stringify(props.presentation))
    p.assets = newl
    setAssets(newl)
    props.setPresentation(p)
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Box sx={{ p: 2, borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Typography style={styles.title}>{props.title || 'Slideshow Presentation Details'}</Typography>
      </Box>
      <Box style={styles.container} sx={{ pt: 2, pl: 2 }}>
        <Typography variant='body2'>
          Add and order assets that you would like in your slideshow. If you want to use specific pages in a PDF, press Add PDF Pages.
        </Typography>
      </Box>
      <Box>
        {variant === 'slideshow'
          ? (
            <Box style={styles.container} sx={{ pt: 2, pl: 2, pr: 2 }}>
              <DataGridAssets
                {...props}
                list={assets}
                onChange={handleChange}
                onAdd={handleAdd}
                options={
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      setShowAddDialog(!showAddDialog)
                    }}
                    sx={{ ml: 1 }}
                  >
                    Add PDF Pages
                  </Button>
              }
              />
            </Box>
            )
          : null}

        {showAddDialog
          ? (
            <DialogAssetSelector
              title='Step 1/2 - Select PDF'
              singleUseOnly
              list={[]}
              assets={props.config.assets2}
              filterOverride={['PDF']}
              onSave={(l) => {
                if (l.length) {
                  setAssetPDF(l[0])
                  setShowAddPDFPageDialog(!showAddPDFPageDialog)
                }
                setShowAddDialog(!showAddDialog)
              }}
              onClose={() => setShowAddDialog(!showAddDialog)}
            />
            )
          : null}

        {showAddPDFPageDialog && assetPDF
          ? (
            <DialogPDFPageSelector
              {...props}
              title='Step 2/2 - Select PDF Pages'
              list={assets}
              asset={assetPDF}
              onSave={(list) => {
                handleChange(list)
                setShowAddPDFPageDialog(!showAddPDFPageDialog)
              }}
              onClose={() => setShowAddPDFPageDialog(!showAddPDFPageDialog)}
            />
            )
          : null}
      </Box>
    </>
  )
}

const DialogPDFPageSelector = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [base64PDF, setBase64PDF] = useState()
  const [numPages, setNumPages] = useState(0)
  const [pages, setPages] = useState([])

  useEffect(() => {
    if (isLoading) {
      const a = getAssetInfo(props.asset.asset, props.assetsConfig)
      Helper.toDataURL(a.path, function (base64) {
        setBase64PDF(base64)
        setIsLoading(false)
      })
    }
  }, []) // eslint-disable-line

  const handleSave = () => {
    const l = JSON.parse(JSON.stringify(props.list))
    if (pages.length) {
      for (let x = 0; x < pages.length; x++) {
        l.push({
          type: 'PDF',
          asset: props.asset.asset,
          title: `Page ${pages[x]} of ${props.asset.title}`,
          page: pages[x]
        })
      }
    }
    props.onSave(l)
  }

  const handleChange = (pageNum) => {
    const p = JSON.parse(JSON.stringify(pages))
    // If page is IN pages array, remove it. Otherwise add it.
    const idx = p.indexOf(pageNum)
    if (idx > -1) {
      p.splice(idx, 1)
    } else {
      p.push(pageNum)
    }
    setPages(p)
  }

  const handleDocumentLoad = (data) => {
    setNumPages(data.numPages)
  }

  if (isLoading) return <LoadingPleaseWait message='Loading PDF' />

  const jsxArray = []
  for (let x = 0; x < numPages; x++) {
    let checked = false
    if (pages.includes(x + 1)) {
      checked = true
    }

    jsxArray.push(
      <ImageListItem key={`item-${x + 1}`}>
        <Grid container sx={{ border: '1px solid rgba(0,0,0,.08)', backgroundColor: 'rgba(0,0,0,.04)' }}>
          <Grid xs={12} item sx={{ p: 1, display: 'flex', justifyContent: 'center' }}>
            <Page scale={0.18} pageNumber={x + 1} loading='' />
          </Grid>
          <Grid xs={12} item sx={{ pl: 2 }}>
            <ImageListItemBar
              position='below'
              title={`Page ${x + 1}`}
              actionIcon={
                <IconButton
                  variant='contained'
                  aria-label={`page-${x + 1}`}
                  onClick={() => {
                    handleChange(x + 1)
                  }}
                >
                  {checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                </IconButton>
              }
            />
          </Grid>
        </Grid>
      </ImageListItem>
    )
  }

  const jsx = (
    <Box>
      <Document file={base64PDF} onLoadSuccess={handleDocumentLoad} loading=''>
        <ImageList cols={3}>{jsxArray}</ImageList>
      </Document>
    </Box>
  )
  return <DialogGeneric title={props.title} content={jsx} fullWidth onChange={handleSave} onClose={props.onClose} />
}

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'white'
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  textField: {
    fontSize: 14
  }
}
