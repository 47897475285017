import React, { useState, useEffect } from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import Field from './LeftPanel/Field'
import { getFlattenedFields } from './PDFDocumentEditor'

const LeftPanel = ({
  forms,
  sforms,
  document,
  selectedFormId,
  pagenum,
  field,
  fieldValue,
  fieldSubIndex,
  onFieldSelect,
  onDragStart,
  onDragEnd,
  onSelectionAdd,
  onChange,
  onPageSelect
}) => {
  const [formType, setFormType] = useState(null)
  const [formId, setFormId] = useState(null)
  const [section, setSection] = useState(null)

  // Used to set the correct section on the LeftPanel when a field is clicked on in the CenterCanvas
  // eslint-disable-next-line
  useEffect(() => {
    if (selectedFormId) {
      if (selectedFormId === 'standard') {
        setFormType('standard')
        setFormId('standard')
      } else {
        setFormType('form')
        setFormId(selectedFormId)
      }
    } else {
      if (!formType) {
        setFormType('standard')
        setFormId('standard')
      }
    }
    // eslint-disable-next-line
  }, [selectedFormId])

  useEffect(() => {
    let section
    if (formType) {
      if (formType === 'standard') {
        section = sforms.find((o) => o.id === 'standard')
      } else {
        section = forms.find((o) => o.id === selectedFormId)
      }
      setSection(section)
    }
    // eslint-disable-next-line
  }, [formType])

  /** Force a refresh of the LeftPanel when adding a selection in standard forms */
  useEffect(() => {
    if (formType === 'standard') {
      const s = sforms.find((o) => o.id === formId)
      setSection(s)
    }
    // eslint-disable-next-line
  }, [onSelectionAdd, onChange])

  const handleChange = (event) => {
    if (event.target.name === 'select-form-type') {
      setFormType(event.target.value)
      if (event.target.value === 'standard') {
        setFormId('standard')
        setFormType('standard')
        const section = sforms.find((o) => o.id === event.target.value)
        setSection(section)
      } else {
        setFormId(null)
      }
    } else if (event.target.name === 'select-form') {
      setFormId(event.target.value)
      const section = forms.find((o) => o.id === event.target.value)
      setSection(section)
    }
  }

  let jsx
  try {
    jsx = (
      <div style={{ width: '100%' }}>
        <div style={{ paddingTop: '4px' }}>
          <FormTypeSelector forms={forms} formType={formType} onChange={handleChange} />
        </div>
        {formType === 'form'
          ? (
            <div style={{ paddingTop: '4px' }}>
              <FormSelector forms={forms} formId={formId} formType={formType} onChange={handleChange} />
            </div>
            )
          : null}
        {formId && section
          ? (
            <div>
              <FieldSelector
                forms={forms}
                document={document}
                section={section}
                formId={formId}
                pagenum={pagenum}
                field={field}
                fieldSubIndex={fieldSubIndex}
                fieldValue={fieldValue}
                onFieldSelect={onFieldSelect}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onSelectionAdd={onSelectionAdd}
                onChange={onChange}
                onPageSelect={onPageSelect}
              />
            </div>
            )
          : null}
      </div>
    )
  } catch (err) {
    console.log(`LeftPanel error: ${err.stack}`)
    jsx = <div>Data is not available.</div>
  }
  return <div id='LeftPanel'>{jsx}</div>
}
export default LeftPanel

const FormTypeSelector = (props) => {
  return (
    <FormControl fullWidth>
      <InputLabel shrink={false} htmlFor='select-form-type'>
        {!props.formType && 'Form Type'}
      </InputLabel>
      <Select
        labelId='select-form-type'
        label=''
        name='select-form-type'
        id='select-form-type'
        value={props.formType || ''}
        onChange={props.onChange}
        sx={{
          borderRadius: 0,
          '& label.Mui-focused': {
            display: 'none'
          },
          '& legend': {
            display: 'none'
          },
          marginBottom: 0
        }}
      >
        <MenuItem key='select-form-type-standard' value='standard' style={styles.menuItem}>
          Standard Fields
        </MenuItem>
        <MenuItem key='select-form-type-fields' value='form' style={styles.menuItem}>
          Form Fields
        </MenuItem>
      </Select>
    </FormControl>
  )
}

const FormSelector = (props) => {
  return (
    <FormControl fullWidth>
      <InputLabel shrink={false} htmlFor='select-form'>
        {!props.formId && 'Form'}
      </InputLabel>
      <Select
        id='select-form'
        labelId='select-form'
        label=''
        name='select-form'
        value={props.formId || ''}
        onChange={props.onChange}
        sx={{
          borderRadius: 0,
          '& label.Mui-focused': {
            display: 'none'
          },
          '& legend': {
            display: 'none'
          }
        }}
      >
        {props.forms.map((form) => {
          return (
            <MenuItem key={`select-form-${form.id}`} value={form.id} style={styles.menuItem}>
              {form.formTitle}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

const FieldSelector = ({
  section,
  formId,
  forms,
  document,
  pagenum,
  field,
  fieldSubIndex = -1,
  fieldValue,
  onFieldSelect,
  onDragStart,
  onDragEnd,
  onChange,
  onPageSelect
}) => {
  const handleDragStart = ({ formId, field, fieldValue }) => {
    // console.log(`onDragStart FORM:${formId} FIELD:${field.id} VALUE:${fieldValue}`);
    onDragStart({ formId, field, fieldValue })
  }

  const handleDragEnd = () => {
    // console.log(`LeftPanel onDragEnd FORM:${formId} FIELD:${field.id} VALUE: ${fieldValue}`);
    onDragEnd()
  }

  const handleDragExit = (event) => {
    event.preventDefault()
  }

  return (
    <>
      {section.formSection.map((fs, index1) => {
        const flattenedFields = getFlattenedFields(fs)
        return (
          <div key={`fs-section-${index1}`}>
            <FormSection
              sectionTitle={fs.sectionTitle}
              fields={flattenedFields.map((f, index2) => {
                let variant = 'nav'
                if (f.type.match(/^(checkbox|radio|boolean|choice)/)) {
                  variant = 'navmulti'
                } else if (f.type.match(/^(selection|signature|initial|text-input)/)) {
                  variant = f.type
                }
                return (
                  <Field
                    id={`select-${f.id}-${index2}`}
                    key={`select-${f.id}-${index2}`}
                    variant={variant}
                    document={document}
                    forms={forms}
                    formId={formId}
                    pagenum={pagenum}
                    field={f}
                    selectedField={field}
                    fieldSubIndex={fieldSubIndex} // for selections type when clicked on canvas
                    fieldValue={fieldValue} // for multivalues type clicked on canvas
                    onFieldSelect={onFieldSelect}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    onDragExit={handleDragExit}
                    onChange={onChange}
                    onPageSelect={onPageSelect}
                  />
                )
              })}
            />
          </div>
        )
      })}
    </>
  )
}

const FormSection = (props) => {
  const [showSection, setShowSection] = useState(true)
  return (
    <>
      <div style={styles.sectionTitle} onClick={() => setShowSection(!showSection)}>
        {Array.isArray(props.sectionTitle) ? <>{props.sectionTitle[0].title}</> : <>{props.sectionTitle}</>}
      </div>
      {showSection ? <>{props.fields}</> : null}
    </>
  )
}

const styles = {
  sectionTitle: {
    marginTop: 12,
    marginLeft: 24,
    fontSize: 14,
    fontWeight: 'bold'
  },
  menuItem: {
    height: 54,
    borderBottom: '1px solid rgba(0,0,0,.16)'
  }
}
