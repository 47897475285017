import React, { useEffect, useRef, useState } from 'react'
import FieldTypeAddLogicPanel from '../FieldTypeAddLogicPanel'
import FieldTypeExpandMarker from '../Reusables/FieldTypeExpandMarker'
// import AddLogic from '../AddLogic'
import { Button, FormControl, IconButton, InputLabel, MenuItem, Paper, Select } from '@mui/material'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import TextInputBasic from '../Reusables/TextInputBasic'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
// import RelatedFieldTypeEditor from '../Reusables/RelatedFieldTypeEditor'

function Area (props) {
  const [options, setOptions] = useState(props.choices)
  // const [optionValue, setOptionValue] = useState('')
  // const [field, setField] = useState(props.field)
  const [optIndex, setOptIndex] = useState(null)
  const inputRefs = useRef([])
  const [areaType, setAreaType] = useState('areaType' in props ? props.field.areaType : '')

  const changeHandlerVisible = (visible) => {
    props.onChangeVisible(visible)
  }

  const addOption = () => {
    setOptions(options => [...options, 'newOption'])
  }

  const changeHandler = (value, idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    const newOptionsArr = [...options]

    newOptionsArr[idx] = value
    setOptions(newOptionsArr)

    newField.choices = newOptionsArr
    // setField(newField)

    props.onChange(newField)
  }

  const keyPressHandler = (event, idx) => {
    if (event.key === 'Enter') {
      if (inputRefs.current[idx + 1]) {
        inputRefs.current[idx + 1].focus()
      } else {
        setOptions(options => [...options, 'newOption'])
      }
    }
  }

  const deleteHandler = (idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    const newOptionsArr = [...options]
    const optionDeleted = newOptionsArr[idx]

    newOptionsArr.splice(idx, 1)
    setOptions(newOptionsArr)
    setOptIndex(idx)

    newField.choices = newOptionsArr
    if ('related' in newField) {
      const indexRelated = newField.related.findIndex((rel) => {
        return rel.value === optionDeleted
      })
      if (indexRelated !== -1) {
        newField.related.splice(indexRelated, 1)
        if (!newField.related.length) {
          delete newField.related
        }
      }
    }

    // setField(newField)
    props.onChange(newField)
  }

  const handleMoveUp = (idx) => {
    const noptions = JSON.parse(JSON.stringify(options))
    if (idx > 0) {
      [noptions[idx], noptions[idx - 1]] = [noptions[idx - 1], noptions[idx]]
    }
    setOptions(noptions)
  }

  const handleMoveDown = (idx) => {
    const noptions = JSON.parse(JSON.stringify(options))
    if (idx < options.length) {
      [noptions[idx], noptions[idx + 1]] = [noptions[idx + 1], noptions[idx]]
    }
    setOptions(noptions)
  }

  const changeHandlerAreaType = (e) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    newField.areaType = e.target.value
    setAreaType(e.target.value)
    props.onChange(newField)
  }

  useEffect(() => {
    // setField(props.field)
  }, [props.field])

  useEffect(() => {
    if (optIndex) {
      inputRefs.current.splice(optIndex, 1)
      inputRefs.current[optIndex - 1].focus()
    } else {
      if (inputRefs.current[inputRefs.current.length - 1]) {
        inputRefs.current[inputRefs.current.length - 1].focus()
      }
    }
    // eslint-disable-next-line
  }, [options.length])

  useEffect(() => {
    setAreaType(props.field.areaType)
  }, [props.field.areaType])

  return (
    <>
      {
        props.expandMarker &&
          <FieldTypeExpandMarker />
      }

      <div style={styles.logicPanel}>
        <FieldTypeAddLogicPanel
          field={props.field}
          onChangeVisible={(visible) => changeHandlerVisible(visible)}
        />
        <div style={{ display: 'flex' }}>
          <FieldTypeExpandMarker />
          <Paper elevation={0} square style={styles.addOptionPaper}>
            {/* <div style={styles.areaTypeLabel}>Area Type</div>
                        <div style={styles.optionsMainDiv}>
                            <TextInputBasic
                                placeholder={'enter sub-title'}
                                inputValue={areaType || ''}
                                onChange={(value) => changeHandlerAreaType(value)}
                            />
                        </div> */}
            <FormControl sx={{ marginLeft: 3, width: '350px' }}>
              <InputLabel sx={{ backgroundColor: '#F4F4F4', lineHeight: '15px' }}>Area Type</InputLabel>
              <Select
                labelId='areaType'
                id='areaType'
                name='areaType'
                size='small'
                label='Area Type'
                value={areaType}
                onChange={changeHandlerAreaType}
              >
                {
                  'formsConfig' in props &&
                  props.formsConfig.map((f, idx) => {
                    return (
                      <MenuItem
                        key={idx}
                        value={f.id}
                      >
                        {f.formTitle}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
          </Paper>
        </div>

        {
                    options.map((item, idx) => {
                      return (
                        <div key={`radio-main-${idx}`}>
                          <div style={{ display: 'flex' }} key={`radio-opt-${idx}`}>
                            <FieldTypeExpandMarker />
                            <Paper elevation={0} square style={styles.singleSelectionPaper}>
                              <div style={styles.optionsMainDivIcon}>
                                <IconButton style={styles.addOptionIconBtn}>
                                  <RadioButtonUncheckedIcon style={styles.addOptionIcon} />
                                </IconButton>
                              </div>
                              <div style={styles.optionsMainDiv}>
                                <TextInputBasic
                                  className={`single-selection-text-${idx}`}
                                  id={`single-selection-text-${idx}`}
                                  placeholder='area name'
                                  inputValue={item !== 'newOption' ? item : ''}
                                  onChange={(value) => changeHandler(value, idx)}
                                  onKeyPress={(event) => keyPressHandler(event, idx)}
                                  ref={ref => inputRefs.current[idx] = (ref)} // eslint-disable-line
                                />
                              </div>
                              <div style={styles.optionsMainDivIcon}>
                                <IconButton onClick={() => deleteHandler(idx)}>
                                  <DeleteOutlineIcon />
                                </IconButton>
                                <IconButton
                                  disabled={!(idx < options.length - 1)}
                                  onClick={() => {
                                    handleMoveDown(idx)
                                  }}
                                >
                                  <ArrowDownwardIcon />
                                </IconButton>
                                <IconButton
                                  disabled={!(idx > 0)} onClick={() => {
                                    handleMoveUp(idx)
                                  }}
                                >
                                  <ArrowUpwardIcon />
                                </IconButton>
                              </div>
                            </Paper>
                          </div>
                        </div>
                      )
                    })
                }

        <div style={{ display: 'flex' }}>
          <FieldTypeExpandMarker />
          <Paper elevation={0} square style={styles.addOptionPaper}>
            <Button
              className='add-option-single-selection-btn'
              style={styles.addOptionBtn}
              color='primary'
              variant='text'
              startIcon={<RadioButtonUncheckedIcon />}
              onClick={addOption}
            >
              Add default area name
            </Button>
          </Paper>
        </div>

      </div>
    </>
  )
}

const styles = {
  relatedMain: {
    display: 'flex'
  },
  logicPanel: {
    // width: "100%"
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%'
  },
  singleSelectionPaper: {
    borderTop: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    borderLeft: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    // marginRight: "96.19px",
    // width: "100%",
    height: '50px',
    flexBasis: '100%'
  },
  addOptionPaper: {
    borderTop: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    borderLeft: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    // marginRight: "96.19px",
    // width: "100%",
    height: '70px',
    flexBasis: '100%',
    paddingRight: '5px'
  },
  addOptionBtn: {
    textTransform: 'none',
    marginLeft: '16px',
    fontSize: '13px'
  },
  addOptionIconBtn: {
    padding: '8px'
  },
  addOptionIcon: {
    fontSize: '20px'
  },
  areaTypeLabel: {
    fontSize: '13px',
    color: '#000000a3',
    marginLeft: '10px',
    // marginRight: "5px"
    width: '10%'
  },
  optionsMainDiv: {
    display: 'flex',
    flexBasis: '100%'
  },
  optionsMainDivIcon: {
    marginLeft: '12px',
    whiteSpace: 'nowrap'
  }
}
export default Area
