import React, { useState, useEffect } from 'react'
import { Box, Button, FormControl, FormLabel, FormControlLabel, Grid, IconButton, Link, Typography, RadioGroup, Radio } from '@mui/material'
import { LoadingPleaseWait } from '@supportworks/react-components'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { DataGrid } from '@mui/x-data-grid'

const RightPanelTieredPricing = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [variant, setVariant] = useState(null)
  const [product, setProduct] = useState({}) // For object manipulation
  const [data, setData] = useState([])

  const mirror = {
    inc_tier: 'unit_tier',
    unit_tier: 'inc_tier'
  }

  useEffect(() => {
    if (isLoading) {
      const p = props.product
      if (p.pricingOptions) {
        if ('inc_tier' in p.pricingOptions) {
          setVariant('inc_tier')
          setData(p.pricingOptions.inc_tier)
        } else if ('unit_tier' in p.pricingOptions) {
          setVariant('unit_tier')
          setData(p.pricingOptions.unit_tier)
        }
      } else {
        p.pricingOptions = {}
      }
      setProduct(p)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    setProduct(props.product)
    const p = props.product
    if ('inc_tier' in p.pricingOptions) {
      setVariant('inc_tier')
      setData(p.pricingOptions.inc_tier)
    } else if ('unit_tier' in p.pricingOptions) {
      setVariant('unit_tier')
      setData(p.pricingOptions.unit_tier)
    }
  }, [props.product])

  const handleChangeTier = (e) => {
    const value = e.target.value
    const p = JSON.parse(JSON.stringify(props.product))
    let copy = JSON.parse(JSON.stringify(data))

    // Only change my tier if it's not already changed.
    if (!p.pricingOptions[value]) {
      if (value === 'inc_tier') {
        // From unit_tier to inc_tier
        for (let x = 0; x < copy.length; x++) {
          if (x < copy.length - 1) {
            copy[x].break = data[x + 1].break
          } else {
            delete copy[x].break
          }
        }
        p.pricingOptions[value] = copy
        delete p.pricingOptions[mirror[value]]
      } else if (value === 'unit_tier') {
        // From inc_tier to unit_tier
        for (let x = 0; x < copy.length; x++) {
          if (x === 0) {
            delete copy[x].break
          } else {
            copy[x].break = data[x - 1].break
          }
        }
        p.pricingOptions[value] = copy
        delete p.pricingOptions[mirror[value]]
      }
    }
    if (value === 'noneselected') {
      // From inc_tier or unit_tier to none
      copy = []
      if (p.pricingOptions.inc_tier) {
        delete p.pricingOptions.inc_tier
      }
      if (p.pricingOptions.unit_tier) {
        delete p.pricingOptions.unit_tier
      }
    }
    setVariant(value)
    setData(copy)
    setProduct(p)
    props.setProduct(p)
  }

  const handleChange = (idx, row) => {
    const d = JSON.parse(JSON.stringify(data))
    d[idx] = row

    if (variant === 'unit_tier') {
      // Auto-fix stuff if I'm in a row entering stuff < previous rows
      if (d[idx + 1] && d[idx + 1].break <= d[idx].break) {
        let num = Number(d[idx].break + 1)
        for (let x = idx + 1; x < d.length; x++) {
          if (x <= d.length - 1) {
            if (d[x].break < num) {
              d[x].break = num
              num++
            }
          }
        }
      }
      // Auto-fix stuff if I'm in a row entering stuff > next rows
      if (d[idx - 1] && d[idx - 1].break >= d[idx].break) {
        let num = Number(d[idx].break - 1)
        for (let x = idx - 1; x > 0; x--) {
          if (x < d.length) {
            d[x].break = num
          }
          num = num - 1
          if (num < 0) num = 0
        }
      }
    }
    const p = JSON.parse(JSON.stringify(product))
    p.pricingOptions[variant] = d
    setData(d)
    setProduct(p)
    props.setProduct(p)
  }

  const handleAdd = () => {
    const p = JSON.parse(JSON.stringify(product))
    const d = JSON.parse(JSON.stringify(data))
    let b = 0
    let price = 0
    if (data.length > 0) {
      if (variant === 'unit_tier') {
        if (data[data.length - 1].break) {
          b = data[data.length - 1].break + 1
        } else {
          b = 1
        }
        price = data[data.length - 1].price
      } else if (variant === 'inc_tier') {
        if (data.length > 1 && data[data.length - 2].break) {
          b = data[data.length - 2].break + 1
        } else {
          b = 1
        }
      }
    }

    // If price is 0, make it the display price.
    if (price === 0) {
      price = product.price
    }

    // Do not allow duplicates of break.
    // Based on the variant, add the row in the right spot in the json array.
    const f = data.find((o) => o.break === b)
    if (!f) {
      const row = {
        price: Number(price)
      }
      if (b > 0) {
        row.break = b
      }
      if (variant === 'unit_tier') {
        d.push(row)
      } else if (variant === 'inc_tier') {
        if (d.length === 0) {
          d.push(row)
        } else if (d.length === 1) {
          d.unshift(row)
        } else {
          // Insert right before last row
          d.splice(d.length - 1, 0, row)
        }
      }
    }
    p.pricingOptions[variant] = d
    setData(d)
    setProduct(p)
    props.setProduct(p)
  }

  const handleDelete = (idx) => {
    const p = JSON.parse(JSON.stringify(product))
    const d = JSON.parse(JSON.stringify(data))
    if (variant === 'unit_tier') {
      d.splice(idx, 1)
    } else {
      d.splice(idx, 1)
    }
    p.pricingOptions[variant] = d
    setData(d)
    setProduct(p)
    props.setProduct(p)
  }

  if (isLoading) return <LoadingPleaseWait />

  let disabled = false
  if (props.variant === 'pricing' && !product.tier_ovrd) {
    disabled = true
  }

  return (
    <Box>
      <Grid container style={styles.gridContainer}>
        <Grid item xs={12} sx={{ p: 2, borderBottom: '1px solid rgba(0,0,0,.16)' }}>
          <Typography style={styles.title}>Tiered Pricing</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={{ pl: 2 }}>
          <FormControl>
            <FormLabel id='tiered-label' />
            <RadioGroup aria-labelledby='tiered-label' value={variant} name='tier-group' onChange={handleChangeTier}>
              {props.displaySettings && props.displaySettings.showTieredUnits
                ? (
                  <FormControlLabel
                    disabled={disabled}
                    value='unit_tier'
                    control={<Radio />}
                    label={<Typography style={styles.textField}>Units Based Pricing</Typography>}
                  />
                  )
                : null}
              {props.displaySettings && props.displaySettings.showTieredIncremental
                ? (
                  <FormControlLabel
                    disabled={disabled}
                    value='inc_tier'
                    control={<Radio />}
                    label={<Typography style={styles.textField}>Incremental Pricing</Typography>}
                  />
                  )
                : null}
              <FormControlLabel disabled={disabled} value='noneselected' control={<Radio />} label={<Typography style={styles.textField}>None</Typography>} />
            </RadioGroup>
          </FormControl>
        </Grid>
        {variant && !disabled && variant !== 'none'
          ? (
            <Grid item xs={12} sx={{ pl: 1.5, pb: 2 }}>
              <Button variant='outlined' startIcon={<AddCircleOutlineIcon />} onClick={handleAdd}>
                Add Break
              </Button>
            </Grid>
            )
          : null}
        {data && data.length && variant === 'unit_tier'
          ? (
            <TieredPricingItemsUnits
              disabled={disabled}
              variant={variant}
              product={product}
              data={data}
              handleChange={handleChange}
              handleAdd={handleAdd}
              handleDelete={handleDelete}
            />
            )
          : null}
        {data && data.length && variant === 'inc_tier'
          ? (
            <TieredPricingItemsIncremental
              disabled={disabled}
              variant={variant}
              product={product}
              data={data}
              handleChange={handleChange}
              handleAdd={handleAdd}
              handleDelete={handleDelete}
            />
            )
          : null}
      </Grid>
    </Box>
  )
}
export default RightPanelTieredPricing

const TieredPricingItemsUnits = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [rows, setRows] = useState([])
  const [pageSize, setPageSize] = useState(10)

  useEffect(() => {
    if (isLoading) {
      if (props.data && props.data.length) {
        _updateRowsFromData(props.data)
        setData(props.data)
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    _updateRowsFromData(props.data)
    setData(props.data)
  }, [props.data])

  const handleProcessRowUpdate = (row) => {
    if (row) {
      const d = JSON.parse(JSON.stringify(data))
      d[row.id].break = Number(row.break)
      d[row.id].price = Number(row.price)
      setData(d)
      props.handleChange(row.id, d[row.id])
      return row
    }
  }

  const handleDelete = (params) => {
    if (window.confirm('Are you sure you want to delete?')) {
      props.handleDelete(params.row.id)
    }
  }

  const _updateRowsFromData = (data) => {
    const rowsTmp = []
    for (let x = 0; x < data.length; x++) {
      const r = {}
      r.id = x
      r.price = Number(data[x].price)
      if (!data[x].break) {
        r.break = 0
      } else {
        r.break = data[x].break
      }
      if (x === 0) {
        if (data.length > 1) {
          r.to = data[x + 1].break - 1
        } else {
          r.to = 'and up'
        }
      } else {
        if (x < data.length - 1) {
          r.to = data[x + 1].break - 1
        } else {
          r.to = 'and up'
        }
      }
      rowsTmp.push(r)
    }
    setRows(rowsTmp)
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    {
      flex: 1,
      type: 'number',
      field: 'break',
      headerName: 'From',
      editable: true,
      sortable: true,
      preProcessEditCellProps: (params) => {
        const isValid = !isNaN(params.props.value)
        return { ...params.props, error: !isValid }
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'to',
      headerName: 'To',
      editable: false,
      sortable: false,
      preProcessEditCellProps: (params) => {
        const isValid = params.props.value > 0
        return { ...params.props, error: !isValid }
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'price',
      headerName: 'Price',
      editable: true,
      sortable: false,
      preProcessEditCellProps: (params) => {
        const isValid = !isNaN(params.props.value) || params.props.value === 0
        return { ...params.props, error: !isValid }
      },
      valueFormatter: (value, params) => {
        value = params.price
        if (value === null || value === undefined) return ''
        const valueFormatted = value.toFixed(2)
        return `$${valueFormatted} ${props.product.units || 'each'}`
      }
    },
    {
      field: 'action',
      headerName: 'Actions',
      headerAlign: 'right',
      align: 'right',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const jsxArray = []
        if (params.id > 0) {
          jsxArray.push(
            <IconButton tabIndex={1} disabled={props.disabled} key={`delete-${params.id}`} onClick={() => handleDelete(params)}>
              <DeleteIcon fontSize='small' />
            </IconButton>
          )
        }
        if (params.id === rows.length - 1) {
          jsxArray.push(
            <IconButton
              tabIndex={1}
              disabled={props.disabled}
              key={`add-${params.id}`}
              onClick={() => {
                props.handleAdd()
              }}
            >
              <AddCircleOutlineIcon fontSize='small' />
            </IconButton>
          )
        }
        return jsxArray
      }
    }
  ]

  return (
    <Box
      sx={{
        p: 2,
        height: '100vh',
        width: 600,
        '& .action-cell': {
          outline: 'none !important'
        },
        '& .edit-cell': {
          border: '1px solid rgba(0,0,255,.24) !important',
          borderRadius: 0
        },
        '& .edit-cell:focus': {
          border: '2px solid rgba(0,0,255,.24) !important',
          borderRadius: 0
        },
        '& .non-edit-cell': {
          border: '1px solid rgba(0,0,0,.04) !important',
          backgroundColor: 'rgba(0,0,0,.08)',
          color: 'rgba(0,0,0,.64)'
        },
        '& .non-edit-cell:focus': {
          outline: 'none !important',
          color: 'rgba(0,0,0,1)'
        }
      }}
    >
      <DataGrid
        autoHeight
        pagination
        rows={rows}
        columns={columns}
        slotProps={{
          cell: {
            tabIndex: 0
          }
        }}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10]}
        isCellEditable={(params) => (!!(!props.disabled && (params.field !== 'break' || (params.field === 'break' && params.row.break > 0))))}
        getCellClassName={(params) => {
          if (params.field === 'action') {
            return 'action-cell'
          }
          if (!props.disabled && ((params.field.match(/price|break/) && params.id > 0) || (!props.disabled && params.field === 'price' && params.id === 0))) {
            return 'edit-cell'
          } else {
            return 'non-edit-cell'
          }
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        processRowUpdate={(updatedRow) => {
          const row = handleProcessRowUpdate(updatedRow)
          return row
        }}
        onProcessRowUpdateError={(error, updatedRow, originalRow) => {
          console.log('onProcessRowUpdateError', error, updatedRow, originalRow)
        }}
      />
    </Box>
  )
}

const TieredPricingItemsIncremental = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [rows, setRows] = useState([])
  const [pageSize, setPageSize] = useState(10)

  useEffect(() => {
    if (isLoading) {
      if (props.data && props.data.length) {
        _updateRowsFromData(props.data)
        setData(props.data)
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    _updateRowsFromData(props.data)
    setData(props.data)
  }, [props.data])

  const handleDelete = (params) => {
    if (window.confirm('Are you sure you want to delete?')) {
      props.handleDelete(params.row.id)
    }
  }

  const handleProcessRowUpdate = (row) => {
    if (row) {
      const d = JSON.parse(JSON.stringify(data))
      if (!row.break) row.break = 1
      if (!row.price) row.price = 0
      d[row.id].break = Number(row.break)
      d[row.id].price = Number(row.price)
      setData(d)
      props.handleChange(row.id, d[row.id])
      return row
    }
  }

  const _updateRowsFromData = (data) => {
    const rowsTmp = []
    for (let x = 0; x < data.length; x++) {
      const r = {}
      // Display inc_tier format
      r.id = x
      r.price = Number(data[x].price)
      if (x === data.length) {
        r.break = 0
      } else {
        r.break = data[x].break
      }
      rowsTmp.push(r)
    }
    setRows(rowsTmp)
  }

  const isCellEditable = (props, params) => {
    if (props.disabled) return false
    if (params.field === 'price') return true
    if (params.field === 'break' && params.row.break > 0) return true
    return false
  }

  const columns = [
    {
      flex: 1,
      field: 'break-text',
      headerName: 'Range',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        if (params.row.break === undefined) {
          return <span style={{ color: 'rgba(0,0,0,.32)' }}>Remaining Units</span>
        } else if (params.row.id === 0) {
          return <span style={{ color: 'rgba(0,0,0,.32)' }}>Up to</span>
        } else {
          return <span style={{ color: 'rgba(0,0,0,.32)' }}>Break</span>
        }
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'break',
      headerName: '',
      editable: true,
      sortable: false,
      preProcessEditCellProps: (params) => {
        const isValid = !isNaN(params.props.value)
        return { ...params.props, error: !isValid }
      }
    },
    {
      flex: 2,
      type: 'number',
      field: 'price',
      headerName: 'Unit Price within Range',
      editable: true,
      sortable: false,
      preProcessEditCellProps: (params) => {
        const isValid = !isNaN(params.props.value) || params.props.value === 0
        return { ...params.props, error: !isValid }
      },
      valueFormatter: (value, params) => {
        value = params.price
        if (value === null || value === undefined) return ''
        const valueFormatted = value.toFixed(2)
        return `$${valueFormatted} ${props.product.units || 'each'}`
      }
    },
    {
      field: 'action',
      headerName: 'Actions',
      headerAlign: 'right',
      align: 'right',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const jsxArray = []
        if (params.id > 0 && params.id < rows.length - 1) {
          jsxArray.push(
            <IconButton tabIndex={1} disabled={props.disabled} key={`delete-${params.id}`} onClick={() => handleDelete(params)}>
              <DeleteIcon fontSize='small' />
            </IconButton>
          )
        }
        if (params.id === data.length - 1) {
          jsxArray.push(
            <IconButton
              tabIndex={1}
              disabled={props.disabled}
              key={`add-${params.id}`}
              onClick={() => {
                props.handleAdd()
              }}
            >
              <AddCircleOutlineIcon fontSize='small' />
            </IconButton>
          )
        }
        return jsxArray
      }
    }
  ]

  if (isLoading) return <LoadingPleaseWait />

  return (
    <Box
      sx={{
        p: 2,
        height: '100vh',
        width: 700,
        '& .break-text-edit-cell': {
          borderTop: '1px solid rgba(0,0,255,.24) !important',
          borderBottom: '1px solid rgba(0,0,255,.24) !important',
          borderLeft: '1px solid rgba(0,0,255,.24) !important',
          borderRight: 0,
          borderRadius: 0
        },
        '& .break-text-non-edit-cell': {
          border: 0
        },
        '& .break-edit-cell': {
          borderTop: '1px solid rgba(0,0,255,.24) !important',
          borderBottom: '1px solid rgba(0,0,255,.24) !important',
          borderRight: '0px solid rgba(0,0,255,.24) !important',
          borderLeft: 0,
          borderRadius: 0
        },
        '& .break-non-edit-cell': {
          border: 0
        },
        '& .action-cell': {
          outline: 'none !important'
        },
        '& .edit-cell': {
          border: '1px solid rgba(0,0,255,.24) !important',
          borderRadius: 0
        },
        '& .edit-cell:focus': {
          border: '2px solid rgba(0,0,255,.24) !important',
          borderRadius: 0
        },
        '& .non-edit-cell': {
          border: '1px solid rgba(0,0,0,.04) !important',
          backgroundColor: 'rgba(0,0,0,.08)',
          color: 'rgba(0,0,0,.64)'
        },
        '& .non-edit-cell:focus': {
          outline: 'none !important',
          color: 'rgba(0,0,0,1)'
        }
      }}
    >
      <DataGrid
        autoHeight
        pagination
        rows={rows}
        columns={columns}
        componentsProps={{
          cell: {
            tabIndex: 0
          }
        }}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10]}
        isCellEditable={(params) => isCellEditable(props, params)}
        getCellClassName={(params) => {
          if (params.field === 'action') {
            return 'action-cell'
          }
          if (params.field === 'break-text') {
            if (params.id < rows.length - 1) {
              return 'break-text-edit-cell'
            } else {
              return 'break-text-non-edit-cell'
            }
          }
          if (params.field === 'break') {
            if (!props.disabled && params.id < rows.length - 1) {
              return 'break-edit-cell'
            } else {
              return 'break-non-edit-cell'
            }
          }
          if (!props.disabled && params.field.match(/price/)) {
            return 'edit-cell'
          } else {
            return 'non-edit-cell'
          }
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        processRowUpdate={(updatedRow) => {
          const row = handleProcessRowUpdate(updatedRow)
          return row
        }}
        onProcessRowUpdateError={(error, updatedRow, originalRow) => {
          console.log('onProcessRowUpdateError', error, updatedRow, originalRow)
        }}
      />

      <Box sx={{ pt: 2 }}>
        <Link href='https://help.solutionview.app/c/products/products-tiered-pricing' target='_blank' rel='noreferrer' underline='hover'>
          Need help understanding tiered pricing? Here's a link to tutorial page.
        </Link>
      </Box>
    </Box>
  )
}

const styles = {
  gridContainer: {
    backgroundColor: 'white'
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  textField: {
    fontSize: 14
  }
}
