import React, { useEffect, useState } from 'react'
import FieldTypeAddLogicPanel from '../FieldTypeAddLogicPanel'
import FieldTypeExpandMarker from '../Reusables/FieldTypeExpandMarker'
// import AddLogic from '../AddLogic'
import {
  Button,
  Paper,
  TextField,
  Link,
  Drawer,
  FormControl,
  RadioGroup,
  FormControlLabel, Radio, MenuItem,
  Typography
} from '@mui/material'
// import Typography from '@mui/material/Typography'
import DrawerHeader from '../SolutionAutomation/DrawerHeader'
import LogicBanner from '../Reusables/LogicBanner'
import Search from '../../../../components/Reusable/Search'
import DrawerRow from '../Reusables/DrawerRow'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import Api from '../../../../library/Api'
import Chip from '@mui/material/Chip'
import SaveIcon from '@mui/icons-material/Save'

function ProductRec (props) {
  // console.log(props)
  // const [field, setField] = useState(props.field)
  const [product, setProduct] = useState('product' in props.field ? props.field.product : '')
  const [categories, setCategories] = useState([])
  const [catSelected, setCatSelected] = useState('productCategories' in props.field ? props.field.productCategories : [])

  const changeHandlerVisible = (visible) => {
    props.onChangeVisible(visible)
  }

  const changeHandlerProduct = (event) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    newField.product = event.target.value
    setProduct(event.target.value)
    props.onChange(newField)
  }

  const saveHandlerSolution = (solution) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    newField.solutionid = solution.solutionid
    props.onChange(newField)
  }

  const deleteHandlerSolution = () => {
    const newField = JSON.parse(JSON.stringify(props.field))
    newField.solutionid = ''
    props.onChange(newField)
  }

  const saveHandlerQuantity = (name, value) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    newField.quantityType = name
    if (name === 'assignedValue') {
      newField.quantity = value
    }
    if (name === 'field') {
      newField.quantity = { field: value }
    }
    props.onChange(newField)
  }

  const deleteHandlerQuantity = () => {
    const newField = JSON.parse(JSON.stringify(props.field))
    newField.quantityType = ''
    newField.quantity = ''
    props.onChange(newField)
  }

  const clickHandlerTags = (cat, idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    const newCatSelected = [...catSelected]
    const i = newCatSelected.indexOf(cat)
    if (i === -1) {
      newCatSelected.push(cat)
    } else {
      newCatSelected.splice(i, 1)
    }
    newField.productCategories = newCatSelected
    setCatSelected(newCatSelected)
    props.onChange(newField)
  }

  /* useEffect(() => {
    setField(props.field)
  }, [props.field]) */

  useEffect(() => {
    setProduct(props.field.product)
  }, [props.field]) // eslint-disable-line

  useEffect(() => {
    Api.callRegisteredMethod('getConfigListByTag', { tag: 'products', startsWith: false }).then(async (configs) => {
      const res = Math.max.apply(
        Math,
        configs.map(function (o) {
          return o.configId
        })
      ) // finds greatest configId value
      const mostRecent = configs.filter((obj) => {
        // gets most recent Product config //Might not be needed, only being used now for orgId
        return obj.configId === res
      })
      const org = mostRecent[0].organizationId

      const cats = []
      Api.callRegisteredMethod('getConfigById', { configId: res, organization: org }).then((prodProps) => {
        const list = JSON.parse(prodProps.data[0].contentJson)
        list.forEach((product) => {
          // DEV-3036 Put all of the unique categories on the categories array, for chip picks
          if (product.categories && product.categories.length) {
            product.categories.forEach((cat) => {
              if (cats.indexOf(cat) === -1) {
                cats.push(cat)
              }
            })
          }
        })
        setCategories(cats.sort())
      })
    })
  }, [])

  return (
    <>
      {
        props.expandMarker &&
          <FieldTypeExpandMarker />
      }

      <div style={styles.logicPanel}>
        <FieldTypeAddLogicPanel
          field={props.field}
          onChangeVisible={(visible) => changeHandlerVisible(visible)}
        />
        <div style={{ display: 'flex' }}>
          <FieldTypeExpandMarker />
          <Paper elevation={0} square style={styles.addOptionPaper}>
            <div>
              <TextField
                id='outlined-basic'
                label='product'
                variant='outlined'
                size='small'
                onChange={changeHandlerProduct}
                value={product || ''}
                sx={{
                  '& label': {
                    backgroundColor: '#F2F2F2'
                  }
                }}
              />
            </div>
            <div style={styles.solutionDiv}>
              <SolutionTrigger
                field={props.field}
                form={props.form}
                onSaveSolution={(solution) => saveHandlerSolution(solution)}
                onDeleteSolution={deleteHandlerSolution}
                solutionsConfig={props.solutionsConfig}
              />
            </div>
            <div style={styles.quantityDiv}>
              <QuantityTrigger
                field={props.field}
                form={props.form}
                allFieldsSolutions={props.allFieldsSolutions}
                onSaveQuantity={(name, value) => saveHandlerQuantity(name, value)}
                onDeleteQuantity={deleteHandlerQuantity}
              />
            </div>
            <div>
              <Typography variant='subtitle2' style={styles.tagsTitle}>Select Tags</Typography>
              <div style={styles.tagsDiv}>
                {
                  categories.map((cat, idx) => {
                    const selected = catSelected.indexOf(cat)
                    return (
                      <Chip
                        key={idx}
                        idx={`Chip-${idx}`}
                        size='small'
                        label={cat}
                        name='discounts'
                        onClick={() => {
                          clickHandlerTags(cat, idx)
                        }}
                        sx={{ m: 0.5 }}
                        color={selected !== -1 ? 'primary' : 'default'}
                      />
                    )
                  })
              }
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </>
  )
}

const styles = {
  relatedMain: {
    display: 'flex'
  },
  logicPanel: {
    // width: "100%"
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%'
  },
  singleSelectionPaper: {
    // borderTop: '1px solid #0000003b',
    // borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    // borderLeft: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    // marginRight: "96.19px",
    // width: "100%"
    flexBasis: '100%'
  },
  addOptionPaper: {
    borderTop: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    borderLeft: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F2F2F2',
    display: 'inlineBlock',
    alignItems: 'center',
    // marginRight: "96.19px",
    // width: "100%",
    // height: "56px"
    flexBasis: '100%',
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingBottom: '20px'
  },
  solutionDiv: {
    marginTop: '10px'
  },
  quantityDiv: {
    marginTop: '10px',
    marginBottom: '10px'
  },
  tagsDiv: {
    display: 'inline'
  },
  tagsTitle: {
    display: 'inline',
    fontWeight: '600',
    marginRight: '10px'
  },
  relatedQuestionMain: {
    background: '#fff',
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: '5px',
    marginRight: '5px'
  }
}
export default ProductRec

function SolutionTrigger (props) {
  const [solution, setSolution] = useState({})
  const [showDrawerSolutionSelect, setShowDrawerSolutionSelect] = useState(false)

  const toggleDrawerSolutionSelect = () => {
    setShowDrawerSolutionSelect(!showDrawerSolutionSelect)
  }

  const saveSolution = (solution) => {
    setSolution(solution)
    props.onSaveSolution(solution)
    setShowDrawerSolutionSelect(false)
  }

  const handleClose = () => {
    // setAnchorEl(null);
    setShowDrawerSolutionSelect(false)
  }

  const handleDelete = () => {
    setSolution({})
    props.onDeleteSolution()
  }

  const handleClickSolution = (solution) => {
    setSolution(solution)
    setShowDrawerSolutionSelect(true)
  }

  useEffect(() => {
    if ('solutionid' in props.field && props.field.solutionid !== '') {
      const solution = props.solutionsConfig.filter((s) => {
        return props.field.solutionid === s.value
      })

      setSolution({ solutionid: solution[0].value, name: solution[0].name })
    }
  }, []) // eslint-disable-line

  return (
    <>
      <Link
        onClick={toggleDrawerSolutionSelect}
        underline='hover'
      >
        Which solution will product be added?
      </Link>
      {
        solution && 'solutionid' in solution &&
          <Chip
            style={styles.relatedQuestionMain}
            label={solution.name}
            onDelete={handleDelete}
            variant='outlined'
            onClick={() => handleClickSolution(solution)}
          />
      }

      {
        showDrawerSolutionSelect &&
          <DrawerSolution
            open={showDrawerSolutionSelect}
            onClose={handleClose}
            onSaveSolution={(solution) => saveSolution(solution)}
            solution={solution}
          />
      }
    </>
  )
}

function DrawerSolution (props) {
  // const [showDrawerSolutionEditorDrawer, setShowDrawerSolutionEditorDrawer] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [assets, setAssets] = useState([])
  const [solutions, setSolutions] = useState([])
  // const [formSection, setFormSection] = useState(props.form)
  const [filteredList, setFilteredList] = useState([])
  // let currFieldId
  let logicBannerText

  useEffect(() => {
    if (isLoading) {
      Api.callRegisteredMethod('getAssetList', {}).then((assetList) => {
        setAssets(assetList)
        Api.callRegisteredMethod('getCurrentConfig', {
          tag: 'all',
          startsWith: false
        }).then((config) => {
          let solutions = []
          if (config && 'solutions' in config) {
            solutions = config.solutions.map((row) => {
              return {
                id: row.id,
                title: row.title,
                subtitle: row.subtitle,
                inspectType: row.inspectType,
                asset: row.asset
              }
            })
          }
          solutions.sort((a, b) => (a.title > b.title) ? 1 : -1)
          setSolutions(solutions)
          setFilteredList(solutions)

          setIsLoading(false)
        })
      })
    }
  }, []) // eslint-disable-line

  const handleClose = () => {
    props.onClose()
  }

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const initializeSolution = (name, id) => {
    props.onSaveSolution({ solutionid: id, name })
  }

  const getAssetInfo = (assets, id) => {
    const assetConfig = assets.filter((asset) => {
      return (asset.id === id)
    })
    if (assetConfig.length !== 0) {
      return assetConfig[0]
    } else {
      return id
    }
  }

  const searchItems = (searchValue) => {
    const newArr = solutions.filter((item) => {
      return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase())
    })
    setFilteredList(newArr)
  }

  return (
    <Drawer
      anchor='right'
      variant='temporary'
      open={props.open}
      onKeyDown={stopPropagationForTab}
      onClose={handleClose}
    >
      <DrawerHeader variant='solution' title='Add Solutions' onClose={handleClose} />
      <div className='sa-container'>
        <div className='sa-section'>
          <LogicBanner>{logicBannerText}</LogicBanner>
          <Search
            onChange={(searchValue) => {
              searchItems(searchValue)
            }}
          />
          {filteredList && filteredList.map((row, idx) => {
            let previewURL = null
            if (row.asset) {
              const asset = getAssetInfo(assets, row.asset)
              if (asset && asset.previewURL) {
                previewURL = asset.previewURL
              }
            }
            return (
              <DrawerRow
                key={'solution' + idx}
                title={row.title}
                subtitle={row.subtitle ? row.subtitle : null}
                previewURL={previewURL}
                icon={<KeyboardArrowRightIcon />}
                onClick={() => {
                  initializeSolution(row.title, row.id)
                }}
              />
            )
          })}
        </div>
      </div>
    </Drawer>
  )
}

function QuantityTrigger (props) {
  const [quantity, setQuantity] = useState()
  const [quantityType, setQuantityType] = useState('')
  const [showDrawerQuantitySelect, setShowDrawerQuantitySelect] = useState(false)

  const toggleDrawerQuantitySelect = () => {
    setShowDrawerQuantitySelect(!showDrawerQuantitySelect)
  }

  const handleClose = () => {
    setShowDrawerQuantitySelect(false)
  }

  const saveQuantity = (name, value) => {
    setQuantity(value)
    setQuantityType(name)
    props.onSaveQuantity(name, value)
    setShowDrawerQuantitySelect(false)
  }

  const handleDelete = () => {
    setQuantity('')
    setQuantityType('')
    props.onDeleteQuantity()
  }

  const getQuantityField = (quantity) => {
    const field = props.allFieldsSolutions.filter((s) => {
      return s.id === quantity
    })
    // console.log(field);
    return field[0].title
  }

  const handleClickQuantity = (quantity, quantityType) => {
    // console.log(quantity, quantityType);
    setQuantityType(quantityType)
    setQuantity(quantity)
    setShowDrawerQuantitySelect(true)
  }

  useEffect(() => {
    if ('quantityType' in props.field) {
      if (props.field.quantityType === 'assignedValue') {
        setQuantity(props.field.quantity)
        setQuantityType(props.field.quantityType)
      }
      if (props.field.quantityType === 'field') {
        setQuantity(props.field.quantity.field)
        setQuantityType(props.field.quantityType)
      }
    }
  }, []) // eslint-disable-line

  return (
    <>
      <Link
        onClick={toggleDrawerQuantitySelect}
        underline='hover'
      >
        How will quantity be determined?
      </Link>

      {
        quantity &&
          <Chip
            style={styles.relatedQuestionMain}
            label={quantityType === 'assignedValue' ? 'Custom Quantity, ' + quantity : getQuantityField(quantity)}
            onDelete={handleDelete}
            variant='outlined'
            onClick={() => handleClickQuantity(quantity, quantityType)}
          />
      }

      {
        showDrawerQuantitySelect &&
          <DrawerQuantity
            open={showDrawerQuantitySelect}
            onClose={handleClose}
            onSaveQuantity={(name, value) => saveQuantity(name, value)}
            allFieldsSolutions={props.allFieldsSolutions}
            quantity={quantity}
            quantityType={quantityType}
          />
      }
    </>
  )
}

function DrawerQuantity (props) {
  const [quantityType, setQuantityType] = useState('quantityType' in props ? props.quantityType : '')
  const [quantity, setQuantity] = useState('quantity' in props ? props.quantity : '')
  const [showQuantity, setShowQuantity] = useState(false)
  const [showMapFormFields, setShowMapFormFields] = useState(false)
  const [mappedFieldId, setMappedFieldId] = useState('')
  const [formFields, setFormFields] = useState(props.allFieldsSolutions) // eslint-disable-line no-unused-vars

  const handleClose = () => {
    props.onClose()
  }

  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === 'radioQuantity') {
      if (value === 'field') {
        if (quantityType === 'assignedValue') {
          // delete product["quantity"];
          setMappedFieldId('')
        }
        setQuantityType('field')
        setShowMapFormFields(true)
        setShowQuantity(false)
        // setProduct({...product, quantityType: "field"});
      } else {
        if (quantityType === 'field') {
          // delete product["quantity"];
        }
        setQuantityType('assignedValue')
        setShowQuantity(true)
        setShowMapFormFields(false)
        // setProduct({...product, quantityType: "assignedValue"});
      }
    }
    if (name === 'formField') {
      // delete product["quantity"];
      setMappedFieldId(value)
      // setProduct({...product, "quantity": { "field": value}});
    }
    if (name === 'quantity') {
      // delete product["quantity"];
      setQuantity(value)
      // setProduct({...product, "quantity": value});
    }
  }

  const saveHandler = () => {
    if (quantityType === 'field') {
      props.onSaveQuantity(quantityType, mappedFieldId)
    }
    if (quantityType === 'assignedValue') {
      props.onSaveQuantity(quantityType, quantity)
    }
  }

  useEffect(() => {
    if (props.quantityType === 'field') {
      setShowMapFormFields(true)
      setShowQuantity(false)
      setMappedFieldId(props.quantity)
    }
    if (props.quantityType === 'assignedValue') {
      setShowQuantity(true)
      setShowMapFormFields(false)
      setQuantity(props.quantity)
    }
  }, []) // eslint-disable-line

  return (
    <Drawer
      anchor='right'
      variant='temporary'
      open={props.open}
            // onKeyDown={stopPropagationForTab}
      onClose={handleClose}
    >
      <DrawerHeader variant='solution' title='Specify Quantity Automation' onClose={handleClose} />
      <div className='sa-container'>
        <div className='sa-section'>
          <div className='sa-heading'>
            <strong>How will quantity be determined?</strong>
          </div>
          <div className='sa-indent'>
            <FormControl component='fieldset' fullWidth>
              <RadioGroup name='radioQuantityGroup' value={quantityType || ''}>
                <FormControlLabel
                  name='radioQuantity'
                  value='field'
                  control={
                    <Radio size='small' color='primary' />
                                    }
                  label='Map to Form Field'
                  onClick={handleChange}
                />
                {showMapFormFields
                  ? (
                    <div className='sa-indent'>
                      <FormControl key='FormControl' fullWidth>
                        <TextField
                          key='mapformfields'
                          select
                          name='formField'
                          label='Choose Field to use for Quantity'
                          variant='outlined'
                          defaultValue={mappedFieldId || ''}
                          onChange={handleChange}
                        >
                          {
                            typeof formFields !== 'undefined' &&
                            formFields.length !== 0 &&
                            formFields.map((row, idx) => {
                              if (row.type.match(/numeric|calc/)) {
                                return (
                                  <MenuItem key={`-${idx}`} value={row.id}>{row.sectionTitle ? row.sectionTitle + ' - ' : null}{row.title}</MenuItem>
                                )
                              }
                              return null
                            })
                          }
                        </TextField>
                      </FormControl>
                    </div>
                    )
                  : null}
                <FormControlLabel
                  name='radioQuantity'
                  value='assignedValue'
                  control={
                    <Radio size='small' color='primary' />
                                    }
                  label='Custom Quantity'
                  onClick={handleChange}
                />
                {showQuantity
                  ? (
                    <div className='sa-indent'>
                      <div style={{ paddingBottom: '16px' }}>
                        <TextField
                          name='quantity'
                          id='quantity'
                          value={quantity || ''}
                          label='Enter Numeric Value'
                          variant='outlined'
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    )
                  : null}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>

      <div className='sa-footer-container'>
        <div className='sa-button-right'>
          <Button style={{ marginRight: '8px' }} color='primary' variant='contained' onClick={saveHandler}>
            <span className='sa-button'><SaveIcon />Save</span>
          </Button>
        </div>
      </div>
    </Drawer>
  )
}
