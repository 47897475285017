import React from 'react'
import { withSubscription } from './Subscriber'
import { Topics } from 'config/topics'

/**
 * Fills the screen with a loading overlay that lasts for a short time.
 */
export class LoadingOverlay extends React.Component {
  /**
     * TimerID -- active if the loading screen is visible.
     *
     * @type {int|null}
     */
  timerId

  static topic

  /**
     * @type {LoadingOverlay}
     */
  static instance

  /**
     *
     * @param {Object} props
     * @param {string} [props.message] Message to display
     * @param {boolean} [props.visible=false] Whether or not the loading overlay is visible
     * @param {number} [props.maxDuration=30] Maximum duration to show the loading screen -- useful if the
     *        background process doesn't return.
     */
  constructor (props) {
    super(props)

    this._isMounted = false

    this.state = {
      visible: !!this.props.visible,
      maxDuration: this.props.maxDuration ? this.props.maxDuration : 30,
      imageUrl: this.props.imageUrl
    }

    LoadingOverlay.topic = this.props.topic

    LoadingOverlay.instance = this
  }

  get isMounted () {
    return this._isMounted
  }

  /**
     * Hides the overlay after a number of seconds has passed.
     */
  timerCallback () {
    this.resetTimer()
    this.setState({ visible: false, message: '' })
  }

  resetTimer () {
    if (this.timerId) {
      window.clearTimeout(this.timerId)
      this.timerId = null
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    let changed = false
    const changes = {}
    if (nextProps.message !== prevState.message) {
      changed = true
      changes.message = nextProps.message
      if (changes.message) {
        changes.visible = true
      }
    }
    if (nextProps.visible !== prevState.visible) {
      changed = true
      changes.visible = nextProps.visible
    }
    if (nextProps.imageUrl !== prevState.imageUrl) {
      changed = true
      changes.imageUrl = nextProps.imageUrl
    }

    if (changed) {
      return changes
    }
    return null
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    let changes = LoadingOverlay.getDerivedStateFromProps(this.props, prevProps)
    // let changes = LoadingOverlay.getDerivedStateFromProps(prevProps, this.props);
    let performChanges = true
    if (!changes) {
      changes = {}
      performChanges = false
    }

    // Changes in visibility or changes in message will trigger a new timeout
    if ((Object.prototype.hasOwnProperty.call(changes, 'visible') && changes.visible) || changes.message) {
      let timeout = changes.timeout
      if (!timeout) {
        timeout = (this.state.timeout ? this.state.timeout : this.state.maxDuration)
      }
      timeout *= 1000
      if (this.timerId) {
        window.clearTimeout(this.timerId)
        this.timerId = null
      }
      this.timerId = window.setTimeout(() => {
        LoadingOverlay.hide()
      }, timeout)
    }
    if (this.isMounted === true && performChanges) {
      for (const key in changes) {
        this.setState({ [key]: changes.key })
      }
    }
  }

  render () {
    if (!this.state.visible) {
      return null
    }
    return (
      <div className='LoadingOverlay'>
        <div className='LoadingOverlayContentContainer'>
          <img
            src={this.state.imageUrl}
            alt='Loading... Please wait.'
          />
          {this.props.message
            ? <div className='LoadingOverlayMessage'>{this.props.message}</div>
            : null}
        </div>
      </div>
    )
  }

  static hide () {
    Topics.publish(this.topic, { visible: false })
  }

  /**
     * Displays a loading message, optionally disappearing after specified seconds.
     *
     * @param {string} message
     * @param {int|null} [timeout=null]
     */
  static message (message, timeout = null) {
    const data = { message }
    Topics.publish(this.topic, data)
    if (timeout) {
      window.setTimeout(() => this.hide(), timeout * 1000)
    }
  }
}

export default withSubscription(LoadingOverlay, {})
