import React, { useState } from 'react'
import FieldTypeExpandMarker from '../Reusables/FieldTypeExpandMarker'
import { Paper } from '@mui/material'
import FieldTypeEditor from '../Reusables/FieldTypeEditor'

function TableColumn2 (props) {
  const [field, setField] = useState(props.field.rows[0])

  const changeHandler = (row, idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    newField.rows[0].columns[idx] = row
    setField(newField.rows[0])
    props.onChange(newField)
  }

  const saveSolutions = (solutions) => {
    props.onSaveSolutions(solutions)
  }

  return (
    <>
      {
        props.expandMarker &&
          <FieldTypeExpandMarker />
      }

      {
        field.columns.length > 0 &&
        field.columns.map((r, idx) => {
          return (
            <div key={idx} style={styles.mainDiv}>
              <div style={styles.fieldTypeDiv}>
                <FieldTypeExpandMarker />
                <Paper elevation={0} square style={styles.paper}>
                  <div style={styles.optionsMainDivIcon}>
                    <span style={styles.colSpan}>{`Column ${idx + 1}`}</span>
                  </div>
                </Paper>
              </div>
              <div style={styles.fieldTypeDiv}>
                <FieldTypeEditor
                  {...props}
                  parentType={props.field.type}
                  rowField={field.columns[idx]}
                  onChange={(row) => changeHandler(row, idx)}
                  onSaveSolutions={(solutions) => saveSolutions(solutions)}
                />
              </div>
            </div>
          )
        })
      }
    </>
  )
}

const styles = {
  mainDiv: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%'
  },
  fieldTypeDiv: {
    display: 'flex'
  },
  paper: {
    borderLeft: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    borderTop: '1px solid #0000003b',
    // fontSize: "13px",
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    // marginRight: "96.19px",
    // width: "100%"
    height: '50px',
    flexBasis: '100%'
  },
  colSpan: {
    fontSize: '14px',
    paddingLeft: '16px',
    color: '#b3b7bc'
  }
}

export default TableColumn2
