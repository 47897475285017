import React, { useEffect, useState } from 'react'
import { IconButton } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown'
import TextInputBasic from './TextInputBasic'
import FieldTypeSelect from '../FieldTypeSelect'
import FieldTypeExpandMarker from './FieldTypeExpandMarker'
import Calculator from '../FieldTypes/Calculator'
import Date from '../FieldTypes/Date'
import DropdownSelection from '../FieldTypes/DropdownSelection'
import MultiSelection from '../FieldTypes/MultiSelection'
import Numeric from '../FieldTypes/Numeric'
import LongText from '../FieldTypes/LongText'
import SingleSelection from '../FieldTypes/SingleSelection'
import ShortText from '../FieldTypes/ShortText'
import YesNo from '../FieldTypes/YesNo'
import '../../../../css/forms.css'

function FieldTypeEditor (props) {
  const [field, setField] = useState(props.rowField)
  const [showArrowRight, setShowArrowRight] = useState(true)
  const [showArrowDown, setShowArrowDown] = useState(false)
  const [elemArr, setElemArr] = useState([])
  const [operatorArr, setOperatorArr] = useState([])
  const [questionArr, setQuestionArr] = useState([])
  const [minMax, setMinMax] = useState({})
  const [currentQuestionCalc, setCurrentQuestionCalc] = useState({})

  const setShowArrow = (val) => {
    if (val === 'showArrowRight') {
      setShowArrowRight(false)
      setShowArrowDown(true)
    }
    if (val === 'showArrowDown') {
      setShowArrowRight(true)
      setShowArrowDown(false)
    }
  }

  const changeHandlerTitle = (title) => {
    const newField = JSON.parse(JSON.stringify(field))
    newField.title = title
    setField(newField)
    props.onChange(newField)
  }

  const changeHandlerType = (type) => {
    const newField = JSON.parse(JSON.stringify(field))
    newField.type = type
    setField(newField)
    props.onChange(newField)
  }

  const changeHandlerVisible = (val) => {
    const newField = JSON.parse(JSON.stringify(field))
    newField.includeSummary = val
    setField(newField)
    props.onChange(newField)
  }

  const changeHandlerDefault = (val) => {
    const newField = JSON.parse(JSON.stringify(field))
    newField.default = val
    setField(newField)
    props.onChange(newField)
  }

  const changeHandlerCRM = (val) => {
    const newField = JSON.parse(JSON.stringify(field))
    newField.includeCRMFields = false
    if (val !== '') {
      newField.includeCRMFields = true
    }

    newField.crm = val
    setField(newField)
    props.onChange(newField)
  }

  const changeHandlerUnits = (val) => {
    const newField = JSON.parse(JSON.stringify(field))
    newField.units = val
    setField(newField)
    props.onChange(newField)
  }

  const changeHandlerAddRelated = (newField) => {
    setField(newField)
    props.onChange(newField)
  }

  const saveSolutions = (val) => {
    props.onSaveSolutions(val)
  }

  const changeHandlerSingleSelection = (field) => {
    setField(field)
    props.onChange(field)
  }

  const changeHandlerMultiSelection = (field) => {
    setField(field)
    props.onChange(field)
  }

  const changeHandlerDropdownSelection = (field) => {
    setField(field)
    props.onChange(field)
  }

  const changeHandlerNumeric = (field) => {
    setField(field)
    props.onChange(field)
  }

  const changeHandlerCalc = (field) => {
    setField(field)
    props.onChange(field)
  }

  const changeHandlerText = (field) => {
    setField(field)
    props.onChange(field)
  }

  const changeHandlerElemArr = (elemArr) => {
    setElemArr(elemArr)
  }

  const changeHandlerOperator = (operator, elemIdx) => {
    const newOperatorArr = [...operatorArr]

    if (newOperatorArr.length === 0) {
      newOperatorArr.push({ idx: elemIdx, operator })
    } else {
      const checkIdx = (obj) => obj.idx === elemIdx
      const found = newOperatorArr.some(checkIdx)

      if (found) {
        const currIdx = newOperatorArr.findIndex((obj) => obj.idx === elemIdx)
        newOperatorArr.splice(currIdx, 1, { idx: elemIdx, operator })
      } else {
        newOperatorArr.push({ idx: elemIdx, operator })
      }
    }

    setOperatorArr(newOperatorArr)
  }

  const changeHandlerQuestion = (f, elemIdx, elemArr) => {
    const newQuestionArr = [...questionArr]
    if (newQuestionArr.length === 0) {
      if (f.type === 'numerical') {
        newQuestionArr.push({ idx: elemIdx, numerical: f })
      } else {
        newQuestionArr.push({ idx: elemIdx, question: f })
      }
    } else {
      const checkIdx = (obj) => obj.idx === elemIdx
      const found = newQuestionArr.some(checkIdx)

      if (found) {
        const currIdx = newQuestionArr.findIndex((obj) => obj.idx === elemIdx)
        if (f.type === 'numerical') {
          newQuestionArr.splice(currIdx, 1, { idx: elemIdx, numerical: f })
        } else {
          newQuestionArr.splice(currIdx, 1, { idx: elemIdx, question: f })
        }
      } else {
        if (f.type === 'numerical') {
          newQuestionArr.push({ idx: elemIdx, numerical: f })
        } else {
          newQuestionArr.push({ idx: elemIdx, question: f })
        }
      }
    }
    setQuestionArr(newQuestionArr)
  }

  const deleteHandlerQuestion = (elem) => {
    const newQuestionArr = [...questionArr]
    let currIdx

    if ('id' in elem) {
      currIdx = newQuestionArr.findIndex((obj) => 'question' in obj && obj.question.id === elem.id)
    }
    if ('val' in elem) {
      currIdx = newQuestionArr.findIndex((obj) => 'numerical' in obj && obj.numerical.val === elem.val)
    }

    newQuestionArr.splice(currIdx, 1)

    setQuestionArr(newQuestionArr)
  }

  const deleteHandlerOperator = (idx) => {
    const newOperatorArr = [...operatorArr]
    const currIdx = newOperatorArr.findIndex((obj) => obj.idx === idx)
    newOperatorArr.splice(currIdx, 1)
    setOperatorArr(newOperatorArr)
  }

  const changeHandlerMinMax = (minMax) => {
    setMinMax(minMax)
  }

  const changeHandlerVisibleInApp = (display) => {
    const newField = JSON.parse(JSON.stringify(field))
    newField.display = display
    setField(newField)
    props.onChange(newField)
  }

  const setCurrentQuestion = (elem) => {
    setCurrentQuestionCalc(elem)
  }

  const currentType = (field) => {
    if (field.id === 'areaName') {
      return 'areaName'
    } else {
      if (field.type === '') {
        return null
      } else {
        return field.type
      }
    }
  }

  useEffect(() => {
    if (field.type === 'calc' && 'calc' in field) {
      const arr = field.calc.split(' ')
      arr.reverse()

      const newMinMax = JSON.parse(JSON.stringify(minMax))

      for (let i = 0; i < 3; i++) {
        if (arr[0].match(/min|max|round|floor|ceil/)) {
          if (arr[0] === 'min' || arr[0] === 'max' || arr[0] === 'round') {
            newMinMax[arr[0]] = arr[1]
            arr.splice(0, 2)
          } else if (arr[0] === 'ceil' || arr[0] === 'floor') {
            newMinMax[arr[0]] = true
            arr.splice(0, 1)
          }
        }
      }
      arr.reverse()
      setMinMax(newMinMax)
      // console.log(arr);

      const s = []
      arr.forEach((e) => {
        if (e !== '+' && e !== '-' && e !== '*' && e !== '/') {
          s.push(e)
        } else {
          // console.log(s);
          const op1 = s.pop()
          const op2 = s.pop()
          // s.pop();
          s.push(op2 + ' ' + e + ' ' + op1)
        }
      })

      const infix = s[s.length - 1]
      const arrInfix = infix.split(' ')
      const operandArr = []
      const operatorArr = []
      let i = 0

      if (typeof props.allFields !== 'undefined') {
        arrInfix.forEach((e, idx) => {
          if (e !== '+' && e !== '-' && e !== '*' && e !== '/') {
            if (e !== '' || undefined) {
              const elemObj = props.allFields.filter((a) => {
                return a.id === e
              })
              // console.log(elemObj);
              if (Object.keys(elemObj).length === 1) {
                operandArr.push({ idx: i, question: elemObj[0] })
              } else {
                operandArr.push({ idx: i, numerical: { val: e, type: 'numerical' } })
              }
              i = i + 2
            }
          } else {
            operatorArr.push({ idx, operator: e })
          }
        })
      }

      setQuestionArr(operandArr)
      setOperatorArr(operatorArr)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const newQuestionArr = [...questionArr]
    newQuestionArr.sort((a, b) => parseFloat(a.idx) - parseFloat(b.idx))

    const newOperatorArr = [...operatorArr]
    const infixArr = []
    // eslint-disable-next-line no-unused-vars
    let infix = '' // Never used, but not sure if it's for future so just suppressed the warning
    if (newQuestionArr.length > 0) {
      newQuestionArr.forEach((q, idx) => {
        if (q.question !== undefined || q.numerical !== undefined) {
          if ('question' in q) {
            infixArr.push(q.question.id)
          }
          if ('numerical' in q) {
            infixArr.push(q.numerical.val)
          }

          if (newOperatorArr[idx] !== undefined) {
            infixArr.push(newOperatorArr[idx].operator)
          }

          if ('question' in q) {
            infix += q.question.id + (newOperatorArr[idx] !== undefined ? ' ' + newOperatorArr[idx].operator + ' ' : '')
          }
          if ('numerical' in q) {
            infix += q.numerical.val + (newOperatorArr[idx] !== undefined ? ' ' + newOperatorArr[idx].operator + ' ' : '')
          }
        }
      })
    }

    let postfix = ''
    const stackArr = []
    const precedence = (operator) => {
      switch (operator) {
        case '*':
        case '/':
          return 2
        case '+':
        case '-':
          return 1
        default:
          return 0
      }
    }

    infixArr.forEach((e) => {
      if (e !== '+' && e !== '-' && e !== '*' && e !== '/') {
        postfix += e + ' '
      } else {
        while (!(stackArr.length === 0) && precedence(e) <= precedence(stackArr[0])) {
          postfix += stackArr.pop() + ' '
        }
        stackArr.push(e)
      }
    })
    while (!(stackArr.length === 0)) {
      if (stackArr.length !== 1) {
        postfix += stackArr.pop() + ' '
      } else {
        postfix += stackArr.pop()
      }
    }

    if (field.type === 'calc') {
      if (Object.keys(minMax).length !== 0) {
        let funcExp = ''
        let i = 0
        for (const [key, value] of Object.entries(minMax)) {
          if (i === 0) {
            if (key === 'min' || key === 'max' || key === 'round') {
              funcExp = value + ' ' + key
            } else if (key === 'floor' || key === 'ceil') {
              funcExp = key
            }
          } else {
            if (key === 'min' || key === 'max' || key === 'round') {
              funcExp += ' ' + value + ' ' + key
            } else if (key === 'floor' || key === 'ceil') {
              funcExp += ' ' + key
            }
          }
          i++
        }
        postfix = postfix + ' ' + funcExp
      }
      field.calc = postfix

      props.onChange(field)
    }
    // eslint-disable-next-line
  }, [questionArr, operatorArr, minMax])

  const renderFieldTypes = (type) => {
    switch (type) {
      case 'boolean':
        return (
          <div style={styles.fieldTypesRel}>
            <YesNo
              {...props}
              field={field}
              expandMarker
              onChangeVisible={(visible) => changeHandlerVisible(visible)}
              onChangeDefault={(val) => changeHandlerDefault(val)}
              onChangeCRM={(crm) => changeHandlerCRM(crm)}
              onChangeUnits={(units) => changeHandlerUnits(units)}
              guid={props.guid}
              askQuestion={(related) => changeHandlerAddRelated(related)}
              form={props.form}
              onSaveSolutions={(solutions) => saveSolutions(solutions)}
              allFieldsSolutions={props.allFieldsSolutions}
              crmFieldsConfig={props.crmFieldsConfig}
              numericUnitsConfig={props.numericUnitsConfig}
              allFields={props.allFields}
            />
          </div>
        )
      case 'radio':
        return (
          <div style={styles.fieldTypesRel}>
            <SingleSelection
              {...props}
              field={field}
              list={'list' in field ? field.list : []}
              onChange={(options) => changeHandlerSingleSelection(options)}
              onChangeVisible={(visible) => changeHandlerVisible(visible)}
              onChangeCRM={(crm) => changeHandlerCRM(crm)}
              onChangeUnits={(units) => changeHandlerUnits(units)}
              guid={props.guid}
              askQuestion={(related) => changeHandlerAddRelated(related)}
              expandMarker
              form={props.form}
              onSaveSolutions={(solutions) => saveSolutions(solutions)}
              allFieldsSolutions={props.allFieldsSolutions}
              crmFieldsConfig={props.crmFieldsConfig}
              numericUnitsConfig={props.numericUnitsConfig}
            />
          </div>
        )
      case 'checkbox':
        return (
          <div style={styles.fieldTypesRel}>
            <MultiSelection
              {...props}
              field={field}
              list={'list' in field ? field.list : []}
              onChange={(options) => changeHandlerMultiSelection(options)}
              onChangeVisible={(visible) => changeHandlerVisible(visible)}
              onChangeDefault={(val) => changeHandlerDefault(val)}
              onChangeCRM={(crm) => changeHandlerCRM(crm)}
              onChangeUnits={(units) => changeHandlerUnits(units)}
              guid={props.guid}
              askQuestion={(related) => changeHandlerAddRelated(related)}
              expandMarker
              form={props.form}
              onSaveSolutions={(solutions) => saveSolutions(solutions)}
              allFieldsSolutions={props.allFieldsSolutions}
              crmFieldsConfig={props.crmFieldsConfig}
              numericUnitsConfig={props.numericUnitsConfig}
            />
          </div>
        )
      case 'choice':
        return (
          <div style={styles.fieldTypesRel}>
            <DropdownSelection
              {...props}
              field={field}
              choices={'choices' in field ? field.choices : []}
              onChange={(options) => changeHandlerDropdownSelection(options)}
              onChangeVisible={(visible) => changeHandlerVisible(visible)}
              onChangeCRM={(crm) => changeHandlerCRM(crm)}
              onChangeDefault={(val) => changeHandlerDefault(val)}
              onChangeUnits={(units) => changeHandlerUnits(units)}
              guid={props.guid}
              askQuestion={(related) => changeHandlerAddRelated(related)}
              expandMarker
              form={props.form}
              onSaveSolutions={(solutions) => saveSolutions(solutions)}
              allFieldsSolutions={props.allFieldsSolutions}
              crmFieldsConfig={props.crmFieldsConfig}
              numericUnitsConfig={props.numericUnitsConfig}
            />
          </div>
        )
      case 'numeric':
        return (
          <div style={styles.fieldTypesRel}>
            <Numeric
              {...props}
              field={field}
              onChange={(options) => changeHandlerNumeric(options)}
              onChangeVisible={(visible) => changeHandlerVisible(visible)}
              onChangeVisibleInApp={(display) => changeHandlerVisibleInApp(display)}
              onChangeCRM={(crm) => changeHandlerCRM(crm)}
              onChangeDefault={(val) => changeHandlerDefault(val)}
              onChangeUnits={(units) => changeHandlerUnits(units)}
              guid={props.guid}
              askQuestion={(related) => changeHandlerAddRelated(related)}
              expandMarker
              form={props.form}
              onSaveSolutions={(solutions) => saveSolutions(solutions)}
              allFieldsSolutions={props.allFieldsSolutions}
              crmFieldsConfig={props.crmFieldsConfig}
              numericUnitsConfig={props.numericUnitsConfig}
            />
          </div>
        )
      case 'calc':
        return (
          <div style={styles.fieldTypesRel}>
            <Calculator
              {...props}
              field={field}
              onChange={(field) => changeHandlerCalc(field)}
              onChangeElemArr={(elemArr) => changeHandlerElemArr(elemArr)}
              onChangeOperatorArr={(operator, elemIdx) => changeHandlerOperator(operator, elemIdx)}
              onChangeQuestionArr={(f, elemIdx, elemArr) => changeHandlerQuestion(f, elemIdx, elemArr)}
              onDeleteQuestionArr={(elem) => deleteHandlerQuestion(elem)}
              onDeleteOperatorArr={(idx) => deleteHandlerOperator(idx)}
              onChangeMinMax={(minMax) => changeHandlerMinMax(minMax)}
              onChangeVisible={(visible) => changeHandlerVisible(visible)}
              onChangeVisibleInApp={(display) => changeHandlerVisibleInApp(display)}
              onChangeCRM={(crm) => changeHandlerCRM(crm)}
              onChangeUnits={(units) => changeHandlerUnits(units)}
              onSetCurrentQuestion={(elem) => setCurrentQuestion(elem)}
              allFields={props.allFields}
              allFieldsSolutions={props.allFieldsSolutions}
              askQuestion={(related) => changeHandlerAddRelated(related)}
              form={props.form}
              expandMarker
              elemArr={elemArr}
              questionArr={questionArr}
              operatorArr={operatorArr}
              minMax={minMax}
              currentQuestion={currentQuestionCalc}
              crmFieldsConfig={props.crmFieldsConfig}
              numericUnitsConfig={props.numericUnitsConfig}
              guid={props.guid}
              onSaveSolutions={(solutions) => saveSolutions(solutions)}
            />
          </div>
        )
      case 'text':
        return (
          <div style={styles.fieldTypesRel}>
            <ShortText
              field={field}
              onChange={(options) => changeHandlerText}
              onChangeVisible={(visible) => changeHandlerVisible(visible)}
              onChangeCRM={(crm) => changeHandlerCRM(crm)}
              onChangeDefault={(val) => changeHandlerDefault(val)}
              guid={props.guid}
              expandMarker
            />
          </div>
        )
      case 'type':
        return (
          <div style={styles.fieldTypesRel}>
            <LongText
              field={field}
              onChange={(options) => changeHandlerText}
              onChangeVisible={(visible) => changeHandlerVisible(visible)}
              onChangeCRM={(crm) => changeHandlerCRM(crm)}
              onChangeDefault={(val) => changeHandlerDefault(val)}
              guid={props.guid}
              expandMarker
            />
          </div>
        )
      case 'date':
        return (
          <div style={styles.fieldTypesRel}>
            <Date
              field={field}
              onChange={(options) => changeHandlerText}
              onChangeVisible={(visible) => changeHandlerVisible(visible)}
              onChangeCRM={(crm) => changeHandlerCRM(crm)}
              onChangeDefault={(val) => changeHandlerDefault(val)}
              guid={props.guid}
              expandMarker
            />
          </div>
        )
      case 'time':
        return (
          <div style={styles.fieldTypesRel}>
            <Date
              field={field}
              onChange={(options) => changeHandlerText}
              onChangeVisible={(visible) => changeHandlerVisible(visible)}
              onChangeCRM={(crm) => changeHandlerCRM(crm)}
              onChangeDefault={(val) => changeHandlerDefault(val)}
              guid={props.guid}
              expandMarker
            />
          </div>
        )
      default:
        return <div />
    }
  }

  useEffect(() => {
    // console.log(props.parentType);
  })

  return (
    <div style={styles.mainDiv}>
      <div style={styles.fieldTypeDiv}>
        <FieldTypeExpandMarker />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            height: '100%',
            backgroundColor: 'white',
            border: '1px solid #0000003b'
          }}
        >
          <div>
            <IconButton className={'field-arrow-icon-' + (showArrowRight ? 'show' : 'hidden')} onClick={() => setShowArrow('showArrowRight')}>
              <ChevronRightIcon />
            </IconButton>
            <IconButton className={'field-arrow-icon-' + (showArrowDown ? 'show' : 'hidden')} onClick={() => setShowArrow('showArrowDown')} color='primary'>
              <ExpandCircleDownIcon color='primary' />
            </IconButton>
          </div>

          <TextInputBasic name='field-type-title' placeholder='Untitled Field Type' inputValue={'title' in field ? field.title : ''} onChange={(fieldTitle) => changeHandlerTitle(fieldTitle)} />

          <div style={styles.flex}>
            <FieldTypeSelect onChange={(fieldType) => changeHandlerType(fieldType)} currentType={currentType(field)} type='fieldLevel' parentType={props.parentType} />
          </div>
        </div>
      </div>

      {renderFieldTypes(field.type)}
    </div>
  )
}

const styles = {
  mainDiv: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%'
  },
  fieldTypeDiv: {
    display: 'flex'
  },
  fieldTypesRel: {
    display: 'flex'
  },
  paper: {
    display: 'flex',
    flexBasis: '100%',
    borderLeft: '1px solid #0000003b'
  },
  titleIcon: {
    display: 'table-cell',
    background: '#fff',
    height: '56px'
  },
  optionsDialog: {
    background: '#f2f2f2f2'
  },
  textInputBasic: {
    display: 'flex'
  },
  fieldTypeSelect: {
    display: 'flex'
  },
  logicPanel: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%'
  },
  flex: {
    display: 'flex',
    whiteSpace: 'nowrap'
  }
}

export default FieldTypeEditor
