import React from 'react'
import CompanyConfigEdit from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'

const config = {
  title: 'Display Settings',
  variant: 'displaySettings',
  tag: 'displaySettings',
  name: 'Display Settings',
  lockTag: true,
  lockName: true
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}
