import React, { useEffect, useState } from 'react'
import '../../../../css/forms.css'
import FieldTypeAddLogicPanel from 'screens/Config/Forms/FieldTypeAddLogicPanel'
import AddLogic from 'screens/Config/Forms/AddLogic'
import FieldTypeExpandMarker from 'screens/Config/Forms/Reusables/FieldTypeExpandMarker'
import RelatedFieldTypeEditor from 'screens/Config/Forms/Reusables/RelatedFieldTypeEditor'
import { IconButton, Paper } from '@mui/material'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'

function YesNo (props) {
  const options = ['Yes', 'No']
  const [hasLogic, setHasLogic] = useState(false)
  const [optionValue, setOptionValue] = useState('')
  const [field, setField] = useState(props.field)
  const [tabItem, setTabItem] = useState('')
  const [relatedLength, setRelatedLength] = useState(0)

  const changeHandlerVisible = (visible) => {
    props.onChangeVisible(visible)
  }

  const changeHandlerDefault = (val) => {
    props.onChangeDefault(val)
  }

  const changeHandlerCRM = (crm) => {
    props.onChangeCRM(crm)
  }

  const changeHandlerUnits = (units) => {
    props.onChangeUnits(units)
  }

  const addLogic = (e) => {
    if (e) {
      setHasLogic(true)
    }
  }

  const changeHandlerOption = (val) => {
    setOptionValue(val)
  }

  const askQuestionYesNo = () => {
    const newField = JSON.parse(JSON.stringify(field))

    if (!('related' in newField)) {
      newField.related = []
    }

    /* const existDupOption = newField.related.some(el => el.value === optionValue);
        if(!existDupOption) {
            newField['related'].push({
                id: props.guid(),
                value: optionValue,
                type: null
            });
        } */
    newField.related.push({
      id: props.guid(),
      value: optionValue,
      type: null
    })

    setField(newField)
    props.askQuestion(newField)
  }

  const changeHandlerRelated = (related, idx) => {
    const newField = JSON.parse(JSON.stringify(field))
    newField.related[idx] = related
    setField(newField)
    props.askQuestion(newField)
  }

  const deleteRelated = (idx) => {
    const newField = JSON.parse(JSON.stringify(field))

    newField.related.splice(idx, 1)
    if (!newField.related.length) {
      delete newField.related
    }

    setField(newField)
    props.askQuestion(newField)
  }

  const saveIssues = (withIssuesArr) => {
    const newField = JSON.parse(JSON.stringify(withIssuesArr))
    setField(newField)
    props.askQuestion(newField)
  }

  const saveSolutions = (solutions) => {
    props.onSaveSolutions(solutions)
  }

  const clickHandlerTab = (tab) => {
    // console.log(tab);
    setTabItem(tab)
  }

  const deleteHandlerQuestion = (field) => {
    props.askQuestion(field)
  }

  useEffect(() => {
    if ('solutions' in props.form) {
      // props.form.solutions.map((s, idx) => {
      for (let i = 0; i < props.form.solutions.length; i++) {
        if (props.form.solutions[i].fieldTypeId === props.field.id) {
          setHasLogic(true)
        }
      }
    // )
    }

    if ('related' in props.field || 'issues' in props.field) {
      setHasLogic(true)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    setField(props.field)
  }, [props.field])

  useEffect(() => {
    if (props.field.related && props.field.related.length > 0) {
      setRelatedLength(props.field.related.length)

      const index = props.field.related.findIndex(i => i.id === tabItem)
      if (index !== -1) {
        if (props.field.related[index].value === 'Yes') {
          setTabItem('Yes')
        } else if (props.field.related[index].value === 'No') {
          setTabItem('No')
        } else {
          setTabItem(props.field.related[props.field.related.length - 1].id)
        }
      } else {
        if (props.field.related[props.field.related.length - 1].value === 'No') {
          setTabItem('No')
        }
        if (props.field.related[props.field.related.length - 1].value === 'Yes') {
          setTabItem('Yes')
        }
      }
    }
  }, [props.field.related]) // eslint-disable-line

  useEffect(() => {
    if (props.field.related && props.field.related.length > 0) {
      if (props.field.related.length !== relatedLength) {
        const index = props.field.related.findIndex(i => i.id === tabItem)
        // console.log(index);
        if (index !== -1) {
          if (props.field.related[index].value === 'Yes') {
            setTabItem('Yes')
          } else if (props.field.related[index].value === 'No') {
            setTabItem('No')
          } else {
            setTabItem(props.field.related[props.field.related.length - 1].id)
          }
        } else {
          if (props.field.related[props.field.related.length - 1].value === 'No') {
            setTabItem('No')
          }
          if (props.field.related[props.field.related.length - 1].value === 'Yes') {
            setTabItem('Yes')
          }
        }
      }
    }
  }, [props.field.related]) // eslint-disable-line

  return (
    <>
      {
                props.expandMarker &&
                  <FieldTypeExpandMarker />
            }
      <div style={styles.logicPanel} className='logic-panel-main'>
        <FieldTypeAddLogicPanel
          {...props}
          field={props.field}
          onChangeVisible={(visible) => changeHandlerVisible(visible)}
          onChangeDefault={(val) => changeHandlerDefault(val)}
          onChangeCRM={(crm) => changeHandlerCRM(crm)}
          onChangeUnits={(units) => changeHandlerUnits(units)}
          onAddLogic={(e) => addLogic(e)}
          crmFieldsConfig={props.crmFieldsConfig}
        />
        {
                    hasLogic &&
                      <AddLogic
                        {...props}
                        type='boolean'
                        field={props.field}
                        onSelectChange={(val) => changeHandlerOption(val)}
                        askQuestionFieldType={() => askQuestionYesNo()}
                        onSaveIssues={(field) => saveIssues(field)}
                        onSaveSolutions={(solutions) => saveSolutions(solutions)}
                        form={props.form}
                        allFieldsSolutions={props.allFieldsSolutions}
                        onClickTab={(tab) => clickHandlerTab(tab)}
                        onDeleteQuestion={(field) => deleteHandlerQuestion(field)}
                        guid={props.guid}
                        tabItem={tabItem}
                      />
                }
        {
                    hasLogic &&
                    options.map((item, idx) => {
                      // console.log(item);
                      return (
                        <div key={`yesno-main-${idx}`}>
                          <div style={{ display: 'flex' }}>
                            <FieldTypeExpandMarker />
                            <Paper elevation={0} square style={styles.paper}>
                              <div style={styles.optionsMainDivIcon}>
                                <IconButton style={styles.addOptionIconBtn}>
                                  <RadioButtonUncheckedIcon style={styles.addOptionIcon} />
                                </IconButton>
                              </div>
                              <div style={styles.optionsMainDiv}>
                                {item}
                              </div>
                            </Paper>
                          </div>
                          {
                                    'related' in field &&
                                    field.related.map((rel, idx) => {
                                      // console.log(tabItem, item, rel);
                                      return (
                                        <div key={`related-${idx}`}>
                                          {
                                                    rel.value === item &&
                                                      <div style={tabItem === rel.value ? styles.relatedMain : styles.relatedMainHide}>
                                                        {/* <div style={styles.relatedMain}> */}
                                                        <FieldTypeExpandMarker />
                                                        <RelatedFieldTypeEditor
                                                          {...props}
                                                          field={field.related[idx]}
                                                          guid={props.guid}
                                                          onChange={(related) => changeHandlerRelated(related, idx)}
                                                          onDelete={() => deleteRelated(idx)}
                                                          form={props.form}
                                                          onSaveSolutions={(solutions) => saveSolutions(solutions)}
                                                          allFieldsSolutions={props.allFieldsSolutions}
                                                          crmFieldsConfig={props.crmFieldsConfig}
                                                          numericUnitsConfig={props.numericUnitsConfig}
                                                          allFields={props.allFields}
                                                        />
                                                      </div>
                                                }
                                        </div>
                                      )
                                    })
                                }
                        </div>
                      )
                    })
                }
      </div>
    </>
  )
}

const styles = {
  relatedMain: {
    display: 'flex'
  },
  relatedMainHide: {
    display: 'none'
  },
  logicPanel: {
    // width: "100%"
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%'
  },
  paper: {
    borderLeft: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    borderTop: '1px solid #0000003b',
    // fontSize: "13px",
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    // marginRight: "96.19px",
    // width: "100%"
    height: '50px',
    flexBasis: '100%'
  },
  optionsMainDiv: {
    // display: "inline-block"
    display: 'flex',
    flexBasis: '100%'
  },
  optionsMainDivIcon: {
    // display: "inline-block",
    marginLeft: '12px'
  },
  addOptionIconBtn: {
    padding: '8px'
  },
  addOptionIcon: {
    fontSize: '20px'
  }
}

export default YesNo
