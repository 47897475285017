import React, { useState, useEffect } from 'react'
import { Box, Button, Drawer, FormControlLabel, Grid, IconButton, Switch, TextField, Typography } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import TuneIcon from '@mui/icons-material/Tune'
import { DialogSolutionSelector } from 'components/Reusable/DialogSolutionSelector'
import DrawerHeader from '../Reusables/DrawerHeader'
import DrawerFooter from '../Reusables/DrawerFooter'
import { LoadingPleaseWait } from '@supportworks/react-components'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { DataGrid } from '@mui/x-data-grid'

const DrawerFormSettings = (props) => {
  const [isLoading, setIsLoading] = useState(true)

  const [formDescription, setFormDescription] = useState('')
  const [addPhoto, setAddPhoto] = useState(true)
  const [projectDisplay, setProjectDisplay] = useState(false)

  const [includeSubAreas, setIncludeSubAreas] = useState(true)
  const [formLabel, setFormLabel] = useState('')
  const [productRemainderLabel, setProductRemainderLabel] = useState('')
  const [areaOverrideLabel, setAreaOverrideLabel] = useState('')
  const [areaLabel, setAreaLabel] = useState('')
  const [newAreaLabel, setNewAreaLabel] = useState('')

  const [showAddDialog, setShowAddDialog] = useState(false)
  const [availableSolutions, setAvailableSolutions] = useState([])
  const [hidden, setHidden] = useState(false)

  useEffect(() => {
    if (isLoading) {
      const f = props.form
      // Self-healing stuff
      if (f.formDescription === undefined) {
        f.formDescription = ''
      }
      setFormDescription(f.formDescription)
      if (f.addPhoto === undefined) {
        f.addPhoto = true
      }
      setAddPhoto(f.addPhoto)
      if (f.projectDisplay === undefined) {
        f.projectDisplay = true
      }
      setProjectDisplay(f.projectDisplay)
      if (f.hidden === undefined) {
        f.hidden = false
      }
      setHidden(f.hidden)
      if (typeof f.formSubAreas !== 'object') {
        f.formSubAreas = {}
      }
      if (f.formSubAreas.include === undefined) {
        f.formSubAreas.include = false
      }
      setIncludeSubAreas(f.formSubAreas.include)
      if (f.formSubAreas.formLabel === undefined) {
        f.formSubAreas.formLabel = 'Sub-Area'
      }
      setFormLabel(f.formSubAreas.formLabel)
      if (f.formSubAreas.productRemainderLabel === undefined) {
        f.formSubAreas.productRemainderLabel = 'Remainder of Area'
      }
      setProductRemainderLabel(f.formSubAreas.productRemainderLabel)
      if (f.formSubAreas.areaOverrideLabel === undefined) {
        f.formSubAreas.areaOverrideLabel = 'Sub-Area'
      }
      setAreaOverrideLabel(f.formSubAreas.areaOverrideLabel)
      if (f.formSubAreas.areaLabel === undefined) {
        f.formSubAreas.areaLabel = 'Sub-Area'
      }
      setAreaLabel(f.formSubAreas.areaLabel)
      if (f.formSubAreas.newAreaLabel === undefined) {
        f.formSubAreas.newAreaLabel = 'New Sub-Area'
      }
      if (f.solutions === undefined) {
        f.solutions = []
      }
      if (f.availableSolutions === undefined) {
        f.availableSolutions = []
      }
      setAvailableSolutions(f.availableSolutions)
      setNewAreaLabel(f.formSubAreas.newAreaLabel)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const stopPropagationForTab = (e) => {
    if (e.key === 'Tab') {
      e.stopPropagation()
    }
  }

  const handleClose = (e) => {
    props.onClose()
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'formDescription') {
      setFormDescription(value)
    } else if (name === 'addPhoto') {
      setAddPhoto(!addPhoto)
    } else if (name === 'projectDisplay') {
      setProjectDisplay(!projectDisplay)
    } else if (name === 'includeSubAreas') {
      setIncludeSubAreas(!includeSubAreas)
    } else if (name === 'formLabel') {
      setFormLabel(value)
    } else if (name === 'productRemainderLabel') {
      setProductRemainderLabel(value)
    } else if (name === 'areaOverrideLabel') {
      setAreaOverrideLabel(value)
    } else if (name === 'areaLabel') {
      setAreaLabel(value)
    } else if (name === 'newAreaLabel') {
      setNewAreaLabel(value)
    } else if (name === 'hidden') {
      setHidden(!hidden)
    }
  }

  const handleDone = () => {
    const f = JSON.parse(JSON.stringify(props.form))
    f.formDescription = formDescription
    f.addPhoto = addPhoto
    f.projectDisplay = projectDisplay
    if (typeof f.formSubAreas !== 'object') {
      f.formSubAreas = {}
    }
    f.formSubAreas.include = includeSubAreas
    f.formSubAreas.formLabel = formLabel
    f.formSubAreas.productRemainderLabel = productRemainderLabel
    f.formSubAreas.areaOverrideLabel = areaOverrideLabel
    f.formSubAreas.areaLabel = areaLabel
    f.formSubAreas.newAreaLabel = newAreaLabel
    f.availableSolutions = availableSolutions
    f.hidden = hidden
    props.onDone(f)
  }

  const handleEditSolutions = () => {
    setShowAddDialog(!showAddDialog)
  }

  const handleSolutionsChange = (l) => {
    setAvailableSolutions(l)
  }

  const getSolution = (s) => {
    const solution = props.solutionsConfig.filter((c, idx) => {
      if (c.value === s) {
        return c
      } else {
        return false
      }
    })
    if (0 in solution) {
      return solution[0].name
    }
  }

  const handleMoveUp = (idx) => {
    const l = JSON.parse(JSON.stringify(availableSolutions))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx - 1, 0, ele)
    handleSolutionsChange(l)
  }

  const handleMoveDown = (idx) => {
    const l = JSON.parse(JSON.stringify(availableSolutions))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx + 1, 0, ele)
    handleSolutionsChange(l)
  }

  const handleDelete = (idx) => {
    const r = window.confirm('Are you sure you want to delete?')
    if (r) {
      const l = JSON.parse(JSON.stringify(availableSolutions))
      l.splice(idx, 1)
      handleSolutionsChange(l)
    }
  }

  const columns = [
    { field: 'id', headerName: 'Id', hide: true },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: (params) => {
        return <>{params.row.title}</>
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id)}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton size='small' disabled={!(params.row.id < availableSolutions.length - 1)} onClick={() => handleMoveDown(params.row.id)}>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < availableSolutions.length; x++) {
    rows.push({
      id: x,
      title: getSolution(availableSolutions[x]),
      actions: ''
    })
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Drawer
        key='forms-drawerformsettings'
        open={props.open}
        PaperProps={{
          sx: { width: '560px !important' }
        }}
        anchor='right'
        variant='temporary'
        onKeyDown={stopPropagationForTab}
        onClose={handleClose}
      >
        <DrawerHeader icon={<TuneIcon style={{ color: 'rgba(0,0,0,.66)' }} />} title='Form Settings' onClose={handleClose} />
        <Box sx={{ p: 2 }} style={styles.content}>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                id='formDescription'
                name='formDescription'
                label='Form Description'
                fullWidth
                multiline
                rows={3}
                variant='outlined'
                value={formDescription}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Typography variant='body2'>Allow Photos to be Added</Typography>
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <FormControlLabel
                name='addPhoto'
                sx={{ pr: 0, mr: -1 }}
                control={
                  <Switch
                    checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                    id='addPhoto'
                    checked={addPhoto}
                    color='primary'
                    onClick={handleChange}
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  />
                }
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Typography variant='body2'>Show on Project Screen</Typography>
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <FormControlLabel
                name='projectDisplay'
                sx={{ pr: 0, mr: -1 }}
                control={
                  <Switch
                    checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                    id='projectDisplay'
                    checked={projectDisplay === true}
                    color='primary'
                    onClick={handleChange}
                  />
                }
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Typography variant='body2'>Allow Sub-Areas</Typography>
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <FormControlLabel
                name='includeSubAreas'
                sx={{ pr: 0, mr: -1 }}
                control={
                  <Switch
                    checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                    id='includeSubAreas'
                    checked={includeSubAreas}
                    color='primary'
                    onClick={handleChange}
                  />
                }
                onChange={handleChange}
              />
            </Grid>

            {includeSubAreas
              ? (
                <>
                  <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>
                    <TextField
                      id='formLabel'
                      name='formLabel'
                      label='Form Label'
                      fullWidth
                      variant='outlined'
                      value={formLabel}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ pb: 2 }}>
                    <TextField
                      id='areaLabel'
                      name='areaLabel'
                      label='Area Label'
                      fullWidth
                      variant='outlined'
                      value={areaLabel}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ pb: 2 }}>
                    <TextField
                      id='productRemainderLabel'
                      name='productRemainderLabel'
                      label='Product Remainder Label'
                      fullWidth
                      variant='outlined'
                      value={productRemainderLabel}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ pb: 2 }}>
                    <TextField
                      id='areaOverrideLabel'
                      name='areaOverrideLabel'
                      label='Area Override Label'
                      fullWidth
                      variant='outlined'
                      value={areaOverrideLabel}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ pb: 1 }}>
                    <TextField
                      id='newAreaLabel'
                      name='newAreaLabel'
                      label='New Area Label'
                      fullWidth
                      variant='outlined'
                      value={newAreaLabel}
                      onChange={handleChange}
                    />
                  </Grid>
                </>
                )
              : null}
          </Grid>

          <Grid container sx={{ pt: 2 }}>
            <Grid item xs={12}>
              <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                Available Solutions
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ pt: 2 }}>
              <Typography variant='body2'>
                Select what solutions will be available during an appointment within this form. Additionally, the order
                of solutions here drives the order in which the solutions will be ordered in the presentation and project screens.
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ pt: 2 }}>
              <DataGrid
                autoHeight
                rows={rows}
                columns={columns}
                pagination
                pageSize={10}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      id: false
                    }
                  }
                }}
              />
              <Button size='small' variant='contained' onClick={handleEditSolutions} sx={{ mt: 2, mb: 2 }}>
                Add Solutions
              </Button>
            </Grid>
          </Grid>

          <Grid container>
            <Grid xs={9} item style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Typography variant='body2'>Hide from Assigned Library Organizations</Typography>
            </Grid>
            <Grid xs={3} item style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <FormControlLabel
                name='hidden'
                sx={{ pr: 0, mr: -1 }}
                control={
                  <Switch
                    checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                    id='hidden'
                    checked={hidden === true}
                    color='primary'
                    onClick={handleChange}
                  />
                }
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Box>
        <DrawerFooter onClose={handleClose} onDone={handleDone} />
      </Drawer>

      {showAddDialog
        ? (
          <DialogSolutionSelector
            title='Select Solutions'
            list={availableSolutions}
            solutions={props.currentConfig.solutions}
            assets={props.currentConfig.assets2}
            onSave={(l) => {
              handleSolutionsChange(l)
              setShowAddDialog(!showAddDialog)
            }}
            onClose={() => setShowAddDialog(!showAddDialog)}
          />
          )
        : null}
    </>
  )
}
export default DrawerFormSettings

const styles = {
  content: {
    overflowX: 'hidden'
  },
  icon: {
    color: 'rgba(0,0,0,.66)'
  }
}
