import React from 'react'
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded'

function LogicBanner (props) {
  if (!props.children) {
    return <></>
  }
  return (
    <div style={styles.container}>
      <div style={styles.left}>
        <AccountTreeRoundedIcon />
      </div>
      <div style={styles.content}>
        {props.children}
      </div>
    </div>
  )
}

const styles = {
  container: {
    width: '528px',
    height: '48px',
    borderRadius: '8px',
    backgroundColor: '#E7F0FC',
    marginBottom: '10px'
  },
  left: {
    width: '46px',
    height: '48px',
    lineHeight: '48px',
    paddingLeft: '16px',
    float: 'left',
    color: '#1871E7'
  },
  content: {
    height: '48px',
    color: '#181F27',
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '48px',
    paddingLeft: '8px'
  }
}

export default LogicBanner
