import React, { useState, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import NavBulletNumber from '../../components/NavBulletNumber'
import { FieldAction } from '../../components/FieldAction'
import { findFieldNumber } from '../PDFDocumentEditor'
import { fieldTypeConfig } from '../../../index'

const FieldNavMulti = (props) => {
  const [selected, setSelected] = useState(false)
  const [showSelections, setShowSelections] = useState(false)

  useEffect(() => {
    if (props.field.id === props.selectedField.id || props.field.id === props.selectedField.editorId) {
      setSelected(true)
      const documentIndex = props.document.fields.findIndex((o) => o.editorId && o.editorId === props.selectedField.editorId)
      if (documentIndex > -1) setShowSelections(true)
    } else {
      setSelected(false)
    }
    // eslint-disable-next-line
  }, [props.field, props.selectedField])

  const field = props.field
  let icon = <></>
  const f = fieldTypeConfig[field.type]
  if (f && f.icon) {
    icon = f.icon
  }

  let list = []
  if (field.type.match(/^(checkbox|radio)/)) {
    list = field.list
  } else if (field.type === 'choice') {
    list = field.choices.map((f) => f.title)
  } else if (field.type === 'boolean') {
    list = ['Yes', 'No']
  }

  const documentIndex = props.document.fields.findIndex((o) => o.editorId === props.field.id)
  return (
    <div id={`fieldnavmulti-${field.id}`} key={`fieldnavmulti-${field.id}`}>
      <Box key={field.id}>
        <Grid container spacing={0} sx={{ p: 1 }} style={styles.navContainer}>
          <Grid item sm={1} sx={{ pl: 1 }}>
            {icon}
          </Grid>
          <Grid item sm={9} sx={{ pl: 1 }}>
            <Typography sx={{ pl: 1 }} style={styles.navText}>
              {field.title}
            </Typography>
          </Grid>
          <Grid item sm={1}>
            {documentIndex > -1 ? <NavBulletNumber number='1+' selected={selected} /> : null}
          </Grid>
          <Grid item sm={1}>
            <IconButton onClick={() => setShowSelections(!showSelections)}>{showSelections ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
          </Grid>
        </Grid>
      </Box>
      {showSelections
        ? (
          <>
            {list.map((item, idx) => {
              let selected = false
              if (props.fieldValue === item) {
                selected = true
              }
              const number = findFieldNumber(props.document, props.field, item)
              return (
                <div
                  key={`field-nav-selections-${item}-${idx}`}
                  style={styles.pointer}
                  draggable
                  onDragStart={() => {
                    props.onDragStart({ formId: props.formId, field, fieldValue: item })
                  }}
                  onDragEnd={props.onDragEnd}
                  onDragExit={props.onDragExit}
                >
                  <Box key={`${field.id}-${item}`}>
                    <Grid container spacing={0} sx={{ p: 1 }} style={{ ...styles.navContainer, ...styles.navSubContainer }}>
                      <Grid item sm={1}>
                        <RadioButtonCheckedIcon fontSize='small' />
                      </Grid>
                      <Grid item sm={9} sx={{ pl: 1 }}>
                        <Typography
                          sx={{ pl: 1 }}
                          style={styles.navText}
                          onClick={() => {
                            props.onFieldSelect(field, number, item)
                          }}
                        >
                          {item}
                        </Typography>
                      </Grid>
                      <Grid item sm={1}>
                        {number > -1 && <NavBulletNumber number={number} selected={selected} />}
                      </Grid>
                      <Grid item sm={1}>
                        <FieldAction {...props} selectionIndex={idx} />
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              )
            })}
          </>
          )
        : null}
    </div>
  )
}
export default FieldNavMulti

const styles = {
  navContainer: {
    alignItems: 'center',
    userSelect: 'none',
    WebkitUserSelect: 'none'
  },
  navSubContainer: {
    backgroundColor: 'rgba(0,0,0,.04)'
  },
  navText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 14,
    paddingTop: '0px',
    userSelect: 'none'
  },
  pointer: {
    cursor: 'pointer'
  }
}
