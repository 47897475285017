/**
 * Creates a local storage area for small documents. These do not persist between reloads.
 */
class LocalStorage {
  static storage = {}

  /**
   * Clears
   *
   */
  static clear () {
    this.storage = {}
  }

  /**
   * Writes a document to storage.
   *
   * @param {string} key
   * @param value
   */
  static store (key, value) {
    this.storage[key] = value
  }

  /**
   * Whether or not the storage contains the named key.
   *
   * @param {string} key
   * @return {boolean}
   */
  static hasKey (key) {
    return !!Object.prototype.hasOwnProperty.call(this.storage, key)
  }

  /**
   * Removes an element from storage.
   *
   * @param {string|string[]} key
   */
  static remove (key) {
    if (!key) {
      return
    }
    let keys = key
    if (!Array.isArray(keys)) {
      keys = [key]
    }
    keys.forEach((key) => {
      if (this.hasKey(key)) {
        delete this.storage[key]
      }
    })
  }

  /**
   * Pulls value from storage.
   *
   * @param {string} key
   * @param {*} [defaultValue=null] Value to return if key isn't found.
   * @return {null|*}
   */
  static retrieve (key, defaultValue = null) {
    if (this.hasKey(key)) {
      return this.storage[key]
    }
    return defaultValue
  }
}
export default LocalStorage
