import Api, { ApiPlugin } from 'library/Api'
import Company from 'screens/Company'

export class ApiPluginRippleCloud extends ApiPlugin {
  constructor (apiBase, props) {
    super(apiBase, props)

    if (props && props.baseUrl) {
      this.baseUrl = props.baseUrl
    }
    apiBase.registerMethod('checkPortalSubDomain', this.checkPortalSubDomain.bind(this))
    apiBase.registerMethod('provisionCNAME', this.provisionCNAME.bind(this))
    apiBase.registerMethod('provisionCloudFront', this.provisionCloudFront.bind(this))
    apiBase.registerMethod('deprovision', this.deprovision.bind(this))
  }

  async checkPortalSubDomain (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/portal/:subdomain', {
      subdomain: props.subdomain,
      organization: Company.getCurrentOrgId()
    })
    return this.getApiBase()
      .get(route, props)
      .then((rsp) => {
        if (rsp && rsp.status === 'success') {
          return rsp.data[0] // false === is NOT taken
        }
        return true
      })
  }

  async provisionCNAME (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/portal/:subdomain/cname', {
      organization: Company.getCurrentOrgId(),
      subdomain: props.subdomain
    })
    const payload = {
      subdomain: props.subdomain
    }
    return this.getApiBase()
      .post(route, payload)
      .then((rsp) => {
        if (rsp && rsp.status === 'success') {
          return rsp.data[0]
        }
        return false
      })
  }

  async provisionCloudFront (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/portal/:subdomain/cloudfront', {
      organization: Company.getCurrentOrgId(),
      subdomain: props.subdomain
    })
    const payload = {
      subdomain: props.subdomain
    }
    return this.getApiBase()
      .post(route, payload)
      .then((rsp) => {
        if (rsp && rsp.status === 'success') {
          return rsp.data[0]
        }
        return false
      })
  }

  async deprovision (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/portal/:subdomain', {
      organization: Company.getCurrentOrgId(),
      subdomain: props.subdomain
    })
    const payload = {
      subdomain: props.subdomain
    }
    return this.getApiBase()
      .delete(route, payload)
      .then((rsp) => {
        if (rsp && rsp.status === 'success') {
          return rsp.data[0]
        }
        return false
      })
  }
}
