import React, { useState } from 'react'
import { Box } from '@mui/material'
import FieldNav from './FieldNav'
import FieldNavMulti from './FieldNavMulti'
import FieldNavSelections from './FieldNavSelections'
import FieldNavSignatures from './FieldNavSignatures'
import FieldNavInitials from './FieldNavInitials'
import FieldNavNotAvailable from './FieldNavNotAvailable'
import FieldNavText from './FieldNavText'

const Field = (props) => {
  const [isHover, setIsHover] = useState(false)

  let jsx
  if (props.field.type && props.field.type.match(/media|contentBlock|noresponse|title|areaName|area|addAreaCheckbox|relatedInspectAreas|productRecommendation/)) {
    jsx = <FieldNavNotAvailable {...props} />
  }
  if (props.variant === 'navmulti') {
    jsx = <FieldNavMulti {...props} />
  } else if (props.variant === 'selection') {
    jsx = <FieldNavSelections {...props} />
  } else if (props.variant === 'signature') {
    jsx = <FieldNavSignatures {...props} />
  } else if (props.variant === 'initials') {
    jsx = <FieldNavInitials {...props} />
  } else if (props.variant === 'text-input') {
    jsx = <FieldNavText {...props} />
  } else {
    jsx = <FieldNav {...props} />
  }
  return (
    <Box
      style={{ ...styles.container, backgroundColor: isHover ? '#F2F2F2' : 'white' }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {jsx}
    </Box>
  )
}
export default Field

const styles = {
  container: {
    borderBottom: '1px solid rgba(0,0,0,.16)',
    userSelect: 'none',
    WebkitUserSelect: 'none'
  }
}
