import React, { useState, useEffect } from 'react'
import { Box, Button, Chip, Grid, Stack, Typography, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DataGrid from 'components/Reusable/DataGrid'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { DialogSolutionSelector } from 'components/Reusable/DialogSolutionSelector'

const RightPanelAccomplishList = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [pageSize, setPageSize] = useState(10)
  const [selectedIdx, setSelectedIdx] = useState(-1)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [showSolutionSelector, setShowSolutionSelector] = useState(false)
  const [list, setList] = useState([]) // array of objects on meeting.accomplishList array

  useEffect(() => {
    if (isLoading) {
      if (props.meeting.accomplishList) {
        setList(props.meeting.accomplishList)
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (l) => {
    const m = JSON.parse(JSON.stringify(props.meeting))
    m.accomplishList = l
    props.setMeeting(m)
    setList(l)
  }

  const handleSolutionsChange = (l) => {
    const m = JSON.parse(JSON.stringify(props.meeting))
    if (selectedIdx > -1) {
      m.accomplishList[selectedIdx].solutions = l
      setList(m.accomplishList)
      setSelectedIdx(-1)
    }
    props.setMeeting(m)
  }

  const handleProcessRowUpdate = (row) => {
    if (row) {
      const l = JSON.parse(JSON.stringify(list))
      l[row.id].title = row.title
      setList(l)
      handleChange(l)
      return row
    }
  }

  const handleAdd = () => {
    const m = JSON.parse(JSON.stringify(props.meeting))
    const r = {
      title: 'New Title',
      solutions: []
    }
    m.accomplishList.push(r)
    setList(m.accomplishList)
    props.setMeeting(m)
  }

  const handleDelete = (idx) => {
    const r = window.confirm('Are you sure you want to delete?')
    if (r) {
      const l = JSON.parse(JSON.stringify(list))
      l.splice(idx, 1)
      handleChange(l)
    }
  }

  const handleMoveUp = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx - 1, 0, ele)
    handleChange(l)
  }

  const handleMoveDown = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx + 1, 0, ele)
    handleChange(l)
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    { field: 'id', headerName: 'Id' },
    { field: 'title', headerName: 'Title', flex: 1, editable: true, sortable: false },
    {
      field: 'solutions',
      headerName: 'Solutions',
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          let jsx = <Chip size='small' label='No Solutions' />
          if (params.row.solutions) {
            let label = `${params.row.solutions.length}`
            if (params.row.solutions.lenght === 1) {
              label = `${label} Solution`
            } else {
              label = `${label} Solutions`
            }
            jsx = <Chip size='small' label={label} />
          }
          return (
            <Grid container wrap='nowrap' alignItems='center'>
              <Grid item style={{ width: 96 }}>
                {jsx}
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => {
                    setSelectedIdx(params.row.id)
                    setShowSolutionSelector(true)
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
        return null
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id)}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton size='small' disabled={!(params.row.id < list.length - 1)} onClick={() => handleMoveDown(params.row.id)}>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < list.length; x++) {
    rows.push({
      id: x,
      title: list[x].title,
      solutions: list[x].solutions,
      actions: ''
    })
  }

  if (selectedIdx > -1) {
    for (let y = 0; y < list[selectedIdx].solutions.length; y++) {
      const val = list[selectedIdx].solutions[y]
      const f = props.config.solutions.find((o) => o.id === val)
      if (!f) {
        console.log(`'${val}' is not in the master solutions config`)
      }
    }
  }

  return (
    <>
      <Box style={styles.container} sx={{ p: 2 }}>
        <Stack sx={{ pb: 2 }}>
          <Typography variant='h6'>Accomplish List Setup</Typography>
          <Typography variant='body2'>
            The accomplish list consists of solutions that can be selected. Those solutions appear in the app and are grouped by categories. Use this editor to
            build your ideal accomplish list.
          </Typography>
        </Stack>

        <Box>
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            pagination
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 25, 50]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            sx={{
              '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
                color: '#A6B0CF',
                backgroundColor: 'transparent'
              }
            }}
            onProcessRowUpdate={(row) => {
              handleProcessRowUpdate(row)
            }}
          />
        </Box>
        <Box sx={{ pt: 2 }}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              handleAdd()
            }}
          >
            Add Group
          </Button>
        </Box>
      </Box>

      {showSolutionSelector && selectedIdx > -1
        ? (
          <DialogSolutionSelector
            title='Select Solutions'
            list={list[selectedIdx].solutions}
            solutions={props.config.solutions}
            assets={props.config.assets2}
            onSave={(l) => {
              handleSolutionsChange(l)
              setShowAddDialog(!showAddDialog)
            }}
            onClose={() => setShowSolutionSelector(!showSolutionSelector)}
          />
          )
        : null}
    </>
  )
}
export default RightPanelAccomplishList

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'white'
  }
}
