import React, { useState, useEffect } from 'react'
import { Drawer, IconButton } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import DrawerSolutionEditor from './SolutionAutomation/DrawerSolutionEditor'
import DrawerHeader from './SolutionAutomation/DrawerHeader'
import DrawerRow from './Reusables/DrawerRow'
import { LoadingPleaseWait } from '@supportworks/react-components'
import LogicBanner from './Reusables/LogicBanner'
import Search from '../../../components/Reusable/Search'
import '../../../css/solution-automation.css'

const DrawerSolutionSelect = ({
  open,
  onSave,
  onClose,
  form,
  currSolution,
  currFieldId,
  allFieldsSolutions,
  logicBannerText,
  assetsConfig,
  currentConfig,
  productList,
  field,
  guid,
  hideBackdrop = false
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [solution, setSolution] = useState(currSolution)
  const [assets, setAssets] = useState([])
  const [solutions, setSolutions] = useState([])
  const [productConfig, setProductConfig] = useState([])
  // const [productList, setProductList] = useState([])
  // const [formSection, setFormSection] = useState(form)
  const [filteredList, setFilteredList] = useState()

  const [showDrawerSolutionEditor, setShowDrawerSolutionEditor] = useState(false)

  // A giant "scratch pad" json object we need to display various things that aren't on the giant json object we're building.
  const [scratch, setScratch] = useState({})
  console.log('drawerSolutionSelect')
  useEffect(() => {
    if (isLoading) {
      setAssets(assetsConfig)

      let solutions = []
      if (currentConfig && 'solutions' in currentConfig) {
        solutions = currentConfig.solutions.map((row) => {
          return {
            id: row.id,
            title: row.title,
            subtitle: row.subtitle,
            inspectType: row.inspectType,
            asset: row.asset
          }
        })
      }
      solutions.sort((a, b) => (a.name > b.name ? 1 : -1))
      setSolutions(solutions)
      setFilteredList(solutions)

      let pc = []
      if (currentConfig && 'product_config' in currentConfig) {
        pc = currentConfig.product_config.filter((row) => {
          if ('configuration' in row) {
            return {
              id: row.id,
              title: row.title,
              configuration: row.configuration
            }
          }
          return null
        })
        pc.sort((a, b) => (a.title > b.title ? 1 : -1))
        setProductConfig(pc)
      }
      // console.log(productList)
      /* let pl = []
      if (currentConfig && 'product_list' in currentConfig) {
        pl = currentConfig.product_list.map((row) => {
          return {
            id: row.id,
            title: row.title,
            description: row.description,
            units: row.units
          }
        })
        pl.sort((a, b) => (a.title > b.title ? 1 : -1))
        setProductList(pl)
      } */

      // If I'm passing in a solution, go straight to the DrawerSolutionEditor.
      if (solution && solution.solutionid) {
        const s = solutions.filter((o) => o.id === solution.solutionid)
        if (s.length) {
          setScratch({ solutionTitle: s[0].title || 'Unknown Solution' })
          setShowDrawerSolutionEditor(!showDrawerSolutionEditor)
        } else {
          console.log(`Could not find solution in main config! ${JSON.stringify(solution)}`)
        }
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const initializeSolution = (name, id) => {
    setSolution({ solutionid: id, fieldTypeId: currFieldId })
    setScratch({ solutionTitle: name })
  }

  const searchItems = (searchValue) => {
    const newArr = solutions.filter((item) => {
      return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase())
    })
    setFilteredList(newArr)
  }

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const handleClose = () => {
    setScratch({})
    onClose()
  }

  const handleSave = () => {
    setScratch({})
    onSave(solution)
    onClose()
  }

  const toggleDrawerSolutionEditor = () => {
    setShowDrawerSolutionEditor(!showDrawerSolutionEditor)
  }

  return (
    <Drawer
      anchor='right'
      variant='temporary'
      open={open}
      onKeyDown={stopPropagationForTab}
      onClose={handleClose}
      PaperProps={{
        sx: { width: '560px !important' }
      }}
      hideBackdrop={hideBackdrop}
    >
      <DrawerHeader variant='solution' title={solution && solution.solutionTitle ? solution.solutionTitle : 'Add Solutions'} onClose={handleClose} />
      {isLoading
        ? (
          <LoadingPleaseWait />
          )
        : (
          <div className='sa-container'>
            <div className='sa-section'>
              <LogicBanner>{logicBannerText}</LogicBanner>
              <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                <Search
                  onChange={(searchValue) => {
                    searchItems(searchValue)
                  }}
                />
              </div>
              {filteredList &&
              filteredList.map((row, idx) => {
                let previewURL = null
                if (row.asset) {
                  const asset = getAssetInfo(assets, row.asset)
                  if (asset && asset.previewURL) {
                    previewURL = asset.previewURL
                  }
                }
                return (
                  <DrawerRow
                    key={'solution' + idx}
                    title={row.title}
                    subtitle={row.subtitle ? row.subtitle : null}
                    previewURL={previewURL}
                    icon={
                      <IconButton>
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    }
                    onClick={() => {
                      initializeSolution(row.title, row.id, row.asset)
                      toggleDrawerSolutionEditor()
                    }}
                  />
                )
              })}
            </div>

            {showDrawerSolutionEditor
              ? (
                <DrawerSolutionEditor
                  open={showDrawerSolutionEditor}
                  onSave={handleSave}
                  onClose={handleClose}
                  setSolution={setSolution}
                  solution={solution}
                  scratch={scratch}
                  // formSection={formSection}
                  allFieldsSolutions={allFieldsSolutions}
                  productList={productList}
                  productConfig={productConfig}
                  field={field}
                  guid={guid}
                />
                )
              : null}
          </div>
          )}
    </Drawer>
  )
}
export default DrawerSolutionSelect

const getAssetInfo = (assets, id) => {
  const assetConfig = assets.filter((asset) => {
    return asset.id === id
  })
  if (assetConfig.length !== 0) {
    return assetConfig[0]
  } else {
    return id
  }
}
