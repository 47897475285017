import React from 'react'
import Api from 'library/Api'
import CompanySelectFromList from 'screens/Company/CompanySelect'
import LocalStorage from 'library/LocalStorage'
import Config from 'config/index'
import CompanyHeader from 'screens/Company/CompanyHeader'
import { Route, useParams } from 'react-router'
import { Redirect } from 'react-router-dom'
import { StatusOverlay } from 'components/Reusable'
import UsersIndex from 'screens/Users/UsersIndex'
import SelfUserEdit from 'screens/Users/SelfUserEdit'
import AppointmentsIndex from 'screens/Appointments/AppointmentsIndex'
import CompanyDashboard from './CompanyDashboard'
import CompanyNoAccess from './CompanyNoAccess'
import UserImpersonateIndex from 'screens/Users/UserImpersonateIndex'
import UserImpersonateStop from 'screens/Users/UserImpersonateStop'
import ProposalsIndex from 'screens/Proposals/ProposalsIndex'
import ProposalView from 'screens/Proposals/ProposalView'
import ProposalsSigned from 'screens/Proposals/ProposalsSigned'
import Reports from 'screens/Reports/Reports'
import ReportsWIP from 'screens/Reports/ReportsWIP'
import AppointmentsReport from '../Reports/AppointmentsReport'
import PresentationsReport from '../Reports/PresentationsReport'
import SolutionsReport from '../Reports/SolutionsReport'
import SalesRepAveragesReport from 'screens/Reports/SalesRepAveragesReport'
import CompanyIntegrations from 'screens/Company/CompanyIntegrations'
import CompanyConfigPublish from 'screens/Config/CompanyConfigPublish'
import CompanyConfigSettings from 'screens/Config/CompanyConfigSettings'

/**
 * If a direct CompanyRoute URL is used by an authenticated user, this will be used to store the route and will
 * handle the redirect.
 *
 * @type {location}
 */
let StoredCompanyRoute = null

/**
 * Returns organization object for currently selected organization
 * @return {Organization}
 */
function getCurrentCompany () {
  const currentOrgId = LocalStorage.retrieve(Config.currentOrgId)
  if (!currentOrgId) {
    // redirect to company select, or display that here.
    return null
  }

  const companies = LocalStorage.retrieve(Config.myOrgsKey)
  for (const company of companies) {
    if (company.id === currentOrgId) {
      return company
    }
  }
  return null
}

/**
 * Returns organization object for currently selected organization
 * @return {int|null}
 */
function getCurrentOrgId () {
  const currentOrgId = LocalStorage.retrieve(Config.currentOrgId)
  // console.log(`here: ${Config.currentOrgId}`)
  if (!currentOrgId) {
    // redirect to company select, or display that here.
    return null
  }
  return currentOrgId
}

/**
 * Returns the number of companies to which the user has direct affiliation.
 * @return {int}
 */
function countCompanies () {
  const companies = LocalStorage.retrieve(Config.myOrgsKey)
  if (Array.isArray(companies)) {
    return companies.length
  }
  return 0
}

/**
 * Selects an individual company, if the user has access to it.
 *
 * Also clears cached data for any currently selected company -- IF a switch is made.
 *
 * @return {Redirect}
 * @constructor
 */
export function CompanySelectId () {
  // let history = useHistory();
  // let location = useLocation();

  LocalStorage.remove(LocalStorage.retrieve(Config.companyCacheKeys))
  Api.callRegisteredMethod('unsetOrgId')

  let { id } = useParams()
  id = parseInt(id, 10)
  // Check to see if the user has access to the requested org
  const myOrgs = LocalStorage.retrieve(Config.myOrgsKey)
  if (myOrgs && myOrgs.length) {
    for (const org of myOrgs) {
      if (org.id === id) {
        // Success!
        LocalStorage.store(Config.currentOrgId, org.id)
        Api.callRegisteredMethod('setOrgId', { orgId: org.id })
        if (StoredCompanyRoute) {
          const from = StoredCompanyRoute
          StoredCompanyRoute = null
          return <Redirect to={from} />
        } else {
          return <Redirect to={{ pathname: '/Company' }} />
        }
      }
    }
  }
  StatusOverlay.message('You do not have access to that company.', 7)
  return <Redirect to={{ pathname: '/Company/Select' }} />
}

/**
 * Selects a new organization, provided the user has access to it.
 *
 * @param {string|int} organizationId
 * @return {boolean} Whether or not the switch was successful.
 * @constructor
 */
export function CompanySelectIdNonCom (organizationId) {
  organizationId = parseInt(organizationId, 10)
  if (organizationId !== Company.getCurrentOrgId()) {
    LocalStorage.remove(LocalStorage.retrieve(Config.companyCacheKeys))
    Api.callRegisteredMethod('unsetOrgId')

    // Check to see if the user has access to the requested org
    const myOrgs = LocalStorage.retrieve(Config.myOrgsKey)
    if (myOrgs && myOrgs.length) {
      for (const org of myOrgs) {
        if (org.id === organizationId) {
          // Success!
          LocalStorage.store(Config.currentOrgId, org.id)
          Api.callRegisteredMethod('setOrgId', { orgId: org.id })
          return true
        }
      }
    }
  } else {
    // new ID is the same as the previous selection.
    Api.callRegisteredMethod('setOrgId', { orgId: organizationId })
    return true
  }
  return false
}

/**
 * Returns whether or not a company is selected.
 *
 * @return {boolean}
 */
function companyIsSelected () {
  const id = LocalStorage.retrieve(Config.currentOrgId)
  return !!id
}

/**
 * Higher-order component that wil redirect to company selection if a company isn't currently selected.
 *
 * @param {React.Component|function} WrappedComponent
 */
function requireCompanyHOC (WrappedComponent) {
  return class CompanyRequired extends React.Component {
    render () {
      const company = Company.getCurrent()
      if (!company) {
        return <Redirect to={{ pathname: '/Company/Select' }} />
      }
      return <WrappedComponent {...this.props} />
    }
  }
}

/**
 * Route wrapper that requires company selection to use specified route.
 *
 * @param {React.Component|function} WrappedComponent
 */
function CompanyRoute ({ render, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (getCurrentCompany()) {
          return render(rest)
        }
        StoredCompanyRoute = location
        return (
          <Redirect
            to={{
              pathname: '/Company/Select',
              state: { from: location }
            }}
          />
        )
      }}
    />
  )
}

/**
 * Provides a single place to access Company-specific UI components,
 * appropriately set up to expect a selected company with requireCompanyHOC.
 *
 * @type {Object}
 */
const Company = {
  List: CompanySelectFromList,
  SelectId: CompanySelectId,
  SelectIdNC: CompanySelectIdNonCom,
  getCurrent: getCurrentCompany,
  getCurrentOrgId,
  count: countCompanies,
  isSelected: companyIsSelected,
  Header: CompanyHeader,
  UsersIndex,
  SelfUserEdit,
  UserImpersonate: UserImpersonateIndex,
  UserImpersonateStop,
  AppointmentsIndex,
  ProposalsIndex,
  ProposalView,
  ProposalsSigned,
  Reports,
  ReportsWIP,
  AppointmentsReport,
  PresentationsReport,
  SolutionsReport,
  SalesRepAveragesReport,
  CompanyConfigPublish,
  CompanyIntegrations,
  Dashboard: CompanyDashboard,
  NoAccess: CompanyNoAccess,
  CompanyConfigSettings
}

export default Company

export { requireCompanyHOC, CompanyRoute }
