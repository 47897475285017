import React, { useState, useEffect } from 'react'
import { DialogGeneric } from './DialogGeneric'
import { Box, Chip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { LoadingPleaseWait } from '@supportworks/react-components'
import H from '../../library/helper'
import Company from '../../screens/Company'

export const DialogGlobalWarrantySelector = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [pageSize, setPageSize] = useState(10)
  const [filterChips, setFilterChips] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [rows, setRows] = useState([])
  const [filteredRows, setFilteredRows] = useState([])
  // console.log(props);
  useEffect(() => {
    if (isLoading) {
      const sr = []
      const r = []
      let filter = []
      const prefilter = [
        { id: 'All', selected: true },
        { id: 'Selected', selected: false }
      ]

      for (let x = 0; x < props.warrantyConfig.length; x++) {
        const fc = props.warrantyConfig[x]
        // console.log(fc);
        /* let l = props.list.find((o) => {
                    if('id' in o) {
                        return o.id === fc.id;
                    }
                })

                if (l && l.id) {
                    sr.push(x);
                } */
        if (props.list.includes(fc.id)) {
          sr.push(x)
        }

        let chipName
        if ('library' in fc) {
          chipName = fc.library.libraryName
        } else {
          const currentCompany = Company.getCurrent()
          chipName = currentCompany.name
        }

        const exists = filter.find((o) => o.id === chipName)
        if (!exists) {
          filter.push({
            id: chipName,
            selected: false
          })
        }

        r.push({
          id: x,
          // title: l && l.heading ? l.heading : fc.heading,
          title: fc.heading,
          library: chipName
          // created: fc.created ? fc.created : '-',
          // updated: fc.lastupdated ? fc.lastupdated : '-',
        })
      }
      filter.sort((a, b) => (a.id > b.id ? 1 : -1))
      filter = [...prefilter, ...filter]
      setRows(r)
      setFilteredRows(r)
      setSelectedRows(sr)
      setFilterChips(filter)
      setIsLoading(false)
    }
  }, [])

  const handleChange = (r) => {
    setSelectedRows(r)
  }

  const handleSave = () => {
    const list = []

    /* for(let x=0; x<props.list.length; x++) {
            let idx = props.warrantyConfig.findIndex(o => o.id === props.list[x].id);
            if(idx === -1) {
                list.push(props.list[x]);
            }
        } */
    for (let x = 0; x < selectedRows.length; x++) {
      const row = props.warrantyConfig[selectedRows[x]]
      list.push(row.id)
      /* list.push({
                id: row.id,
                heading: row.heading
            }) */
    }

    props.onSave(list)
  }

  const handleChipSelect = (value) => {
    const [fr, fc] = H.filterDataGridUsingChipFilter({ value, rows, selectedRows, filterChips })
    setFilterChips(fc)
    setFilteredRows(fr)
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    { field: 'id', headerName: 'Id', hide: true },
    { field: 'title', headerName: 'Title', flex: 1 },
    { field: 'library', headerName: 'Library', flex: 1 }
    /* { field: 'created', headerName: 'Created', flex: 1 },
        { field: 'updated', headerName: 'Updated ', flex: 1 }, */
  ]

  const jsx = (
    <Box>
      <Box sx={{ pb: 1 }}>
        {filterChips.map((f) => {
          return (
            <Chip
              key={`Chip-${f.id}`}
              sx={{ p: 1, m: 0.5 }}
              color={f.selected === true ? 'primary' : 'default'}
              size='small'
              label={f.id}
              onClick={() => handleChipSelect(f.id)}
            />
          )
        })}
      </Box>
      <Box sx={{ height: 500 }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50]}
          checkboxSelection
          rowSelectionModel={selectedRows}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onRowSelectionModelChange={(rows) => {
            handleChange(rows)
          }}
          keepNonExistentRowsSelected
          sx={{
            '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
              color: '#A6B0CF',
              backgroundColor: 'transparent',
              display: props.singleUseOnly ? 'none' : ''
            }
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false
              }
            }
          }}
        />
      </Box>
    </Box>
  )

  return <DialogGeneric title={props.title} content={jsx} fullWidth onChange={handleSave} onClose={props.onClose} />
}
