import React, { useState, useEffect, useRef } from 'react'
import Button from '@mui/material/Button'
import { AppRegistrationOutlined } from '@mui/icons-material'
import {
  Box,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem, Popover,
  Select,
  Switch,
  TextField
} from '@mui/material'
import DrawerHeader from '../../Reusables/DrawerHeader'
import DialpadIcon from '@mui/icons-material/Dialpad'
import DrawerFooter from '../../Reusables/DrawerFooter'
import Typography from '@mui/material/Typography'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import Divider from '@mui/material/Divider'

export const AddValueDrawer = (props) => {
  const [open, setOpen] = useState(false)
  const [dataValues, setDataValues] = useState(props.dataValues)
  const [anchorEl, setAnchorEl] = useState(null)
  const [stoopValues, setStoopValues] = useState('')
  const [status, setStatus] = useState('')
  const [displayArr, setDisplayArr] = useState([])
  const [dataValuesIndex, setDataValuesIndex] = useState([])
  const answerRef = useRef()

  const handleClick = () => {
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(!open)
  }

  const handleDone = () => {
    // console.log(props);
    const newDataValues = JSON.parse(JSON.stringify(dataValues))
    const newDataValuesIndex = JSON.parse(JSON.stringify(dataValuesIndex))
    // let dataArr = []

    const newDataDesc = JSON.parse(JSON.stringify(props.dataDescription))

    if (displayArr !== undefined || displayArr.length !== 0) {
      // displayArr.map((item, idx) => {
      for (let i = 0; i < displayArr.length; i++) {
        if (displayArr[i] === false) {
          newDataValuesIndex[i] = Boolean(false)
        } else {
          // console.log(newDataDesc[idx].type)
          if (newDataValuesIndex[i] === false && newDataDesc[i].type !== 'boolean') {
            newDataDesc[i].display[props.index] = Boolean(false)
          }
        }
      }
      // )
    }

    // newDataValues[props.index] = dataArr;
    newDataValues[props.index] = newDataValuesIndex
    props.onSave(newDataValues, newDataDesc)
    setOpen(!open)
  }

  const handleChangeType = (e, idx) => {
    // console.log(e.target.value, idx);
    const newDataValuesIndex = JSON.parse(JSON.stringify(dataValuesIndex))
    if (e.target.name === 'typeNumber') {
      newDataValuesIndex[idx] = Number(e.target.value)
      // setValNumber(e.target.value);
    }
    if (e.target.name === 'typeString') {
      newDataValuesIndex[idx] = e.target.value
      // setValString(e.target.value);
    }
    if (e.target.name === 'typeBoolean') {
      if (e.target.value === 'Yes') {
        newDataValuesIndex[idx] = Boolean(true)
      }
      if (e.target.value === 'No') {
        newDataValuesIndex[idx] = Boolean(false)
      }
    }
    // console.log(newDataValuesIndex);
    setDataValuesIndex(newDataValuesIndex)
  }

  const changeHandlerIncludeValue = (index) => {
    // console.log(displayArr);
    const newDisplayArr = JSON.parse(JSON.stringify(displayArr))
    if (newDisplayArr.length > 0) {
      if (typeof newDisplayArr[index] === 'undefined') {
        newDisplayArr.splice(index, 0, true)
      } else {
        newDisplayArr.splice(index, 1, !displayArr[index])
      }
    } else {
      newDisplayArr.splice(index, 0, true)
    }

    props.dataDescription[index].display[props.index] = !displayArr[index]
    setDisplayArr(newDisplayArr)
  }

  const handlePopoverOpen = () => {
    setAnchorEl(answerRef.current)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const getData = (idx) => {
    if (!(props.dataValues[props.index] === undefined)) {
      const val = props.dataValues[props.index][idx]
      if (val === true) {
        return 'Yes'
      } else if (val === false) {
        return 'No'
      } else {
        return val
      }
    }
  }

  useEffect(() => {
    setDataValues(props.dataValues)

    const displayArrNew = []
    for (let i = 0; i < props.dataDescription.length; i++) {
      displayArrNew.push(props.dataDescription[i].display[props.index])
    }
    setDisplayArr(displayArrNew)

    if (typeof props.dataValues[props.index] !== 'undefined') {
      setDataValuesIndex(props.dataValues[props.index])
    }
  }, [props.dataValues]) // eslint-disable-line

  useEffect(() => {
    let i = 0
    let j = 0
    let include
    let val
    const displayArrNew = []
    // props.dataDescription.map((desc, idx) => {
    for (let i = 0; i < props.dataDescription.length; i++) {
      displayArrNew.push(props.dataDescription[i].display[props.index])
    }
    // )
    const jsx = (
      <Box sx={{ marginTop: '16px' }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='subtitle2' style={{ marginLeft: '16px', marginBottom: '4px' }}>Stoop
              Values
            </Typography>
          </Grid>
          <Divider style={{ width: '100%' }} />
          {
            props.dataDescription.map((item, idx) => {
              if (!(props.dataValues[props.index] === undefined) && !(props.dataValues[props.index] === null)) {
                // console.log(props.dataValues[props.index]);
                val = props.dataValues[props.index][idx]
                if (val === true) {
                  val = 'Yes'
                }
                if (val === false) {
                  val = 'No'
                }
                include = displayArrNew[idx]

                if (item.type === 'string') {
                  if (include && val === '') {
                    j++
                  }
                }
                if (item.type === 'number') {
                  if (include && val === 0) {
                    j++
                  }
                }
                if (item.type === 'boolean') {
                  if (include && val == null) {
                    j++
                  }
                }
              }
              i++
              return (
                <Grid item xs={12} key={idx}>
                  <div
                    style={{
                      fontWeight: '400',
                      fontSize: '14px',
                      marginLeft: '16px',
                      marginBottom: '8px',
                      marginTop: '8px',
                      color: 'rgba(0, 0, 0, 0.65)'
                    }}
                  >
                    <Grid container direction='row' justifyContent='center' alignItems='center'>
                      <Grid item xs={8}>{item.title === '' ? `value ${idx + 1}` : item.title}</Grid>
                      <Grid item xs={4}>{include ? getData(idx) : 'Not Included'}</Grid>
                    </Grid>
                  </div>
                  <Divider style={{ width: '100%' }} />
                </Grid>
              )
            })
          }
        </Grid>
      </Box>
    )
    setStatus(`${i - j} / ${i} Values`)
    setStoopValues(jsx)
  }, [props.dataValues]) // eslint-disable-line

  return (
    <>
      <Button
        ref={answerRef}
        onClick={handleClick}
        variant='text'
        startIcon={<AppRegistrationOutlined />}
        onMouseEnter={() => handlePopoverOpen()}
        onMouseLeave={() => handlePopoverClose()}
      >
        {status}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        sx={{
          pointerEvents: 'none'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={() => handlePopoverClose()}
        disableRestoreFocus
        PaperProps={{
          sx: { width: '350px !important' }
        }}
      >
        {stoopValues}
      </Popover>
      <Drawer
        anchor='right'
        open={open}
        variant='temporary'
        PaperProps={{
          sx: { width: '560px !important' }
        }}
        onClose={() => handleClose()}
      >
        <DrawerHeader
          icon={<DialpadIcon style={{ color: 'rgba(0,0,0,.66)' }} />}
          title='Edit Values'
          onClose={() => handleClose()}
        />
        <Box sx={{ p: 2, overflowX: 'hidden' }}>
          <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid item xs={12}>
              <Typography variant='subtitle2' style={{ fontSize: '1.1rem', marginBottom: '8px' }}>{props.answer}</Typography>
            </Grid>
            {
              props.dataDescription.length > 0 &&
              props.dataDescription.map((item, idx) => {
                return (
                  <div key={idx}>
                    <Grid item xs={12}>
                      <Typography
                        variant='subtitle1'
                        style={{ fontWeight: 500 }}
                      >{item.title}
                      </Typography>
                      <Typography variant='body2'>{item.description}</Typography>
                    </Grid>

                    {
                      item.type === 'number' &&
                        <div>
                          <Grid item xs={12}>
                            <FormControlLabel
                              id={item.type}
                              control={
                                <Switch
                                  checked={typeof displayArr[idx] !== 'undefined' ? displayArr[idx] : false}
                                  color='primary'
                                  checkedIcon={<CheckCircleRoundedIcon
                                    fontSize='small'
                                               />}
                                  onChange={() => changeHandlerIncludeValue(idx)}
                                />
                                  }
                              label={<Typography variant='body2' sx={{ paddingRight: '20px' }}>Include Value</Typography>}
                              labelPlacement='start'
                              sx={{ marginLeft: '0px' }}
                            />
                          </Grid>
                          {
                            typeof displayArr[idx] !== 'undefined' &&
                            displayArr[idx] &&
                              <Grid item xs={12} style={{ marginBottom: '24px' }}>
                                <TextField
                                  key={`number-${idx}-${props.index}`}
                                  id='typeNumber'
                                  name='typeNumber'
                                  fullWidth
                                  variant='outlined'
                                  onChange={(event) => handleChangeType(event, idx)}
                                  type='number'
                                  size='small'
                                  defaultValue={dataValuesIndex[idx] || 0}
                                />
                              </Grid>
                          }
                        </div>
                    }

                    {
                      item.type === 'string' &&
                        <div>
                          <Grid item xs={12}>
                            <FormControlLabel
                              id={item.type}
                              control={
                                <Switch
                                  checked={typeof displayArr[idx] !== 'undefined' ? displayArr[idx] : false}
                                  color='primary'
                                  checkedIcon={<CheckCircleRoundedIcon
                                    fontSize='small'
                                               />}
                                  onChange={() => changeHandlerIncludeValue(idx)}
                                />
                                  }
                              label={<Typography variant='body2' sx={{ paddingRight: '20px' }}>Include Value</Typography>}
                              labelPlacement='start'
                              sx={{ marginLeft: '0px' }}
                            />
                          </Grid>
                          {
                            typeof displayArr[idx] !== 'undefined' &&
                            displayArr[idx] &&
                              <Grid item xs={12} style={{ marginBottom: '24px' }}>
                                <TextField
                                  key={`string-${idx}-${props.index}`}
                                  id='typeString'
                                  name='typeString'
                                  fullWidth
                                  variant='outlined'
                                  onChange={(event) => handleChangeType(event, idx)}
                                  size='small'
                                  defaultValue={dataValuesIndex[idx] || ''}
                                />
                              </Grid>
                          }
                        </div>
                    }

                    {
                      item.type === 'boolean' &&
                        <div>
                          <Grid item xs={12}>
                            <FormControlLabel
                              id={item.type}
                              control={
                                <Switch
                                  checked={typeof displayArr[idx] !== 'undefined' ? displayArr[idx] : false}
                                  color='primary'
                                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                                  onChange={() => changeHandlerIncludeValue(idx)}
                                />
                                  }
                              label={<Typography variant='body2' sx={{ paddingRight: '20px' }}>Include Value</Typography>}
                              labelPlacement='start'
                              sx={{ marginLeft: '0px' }}
                            />
                          </Grid>
                          {
                            typeof displayArr[idx] !== 'undefined' &&
                            displayArr[idx] &&
                              <Grid item xs={12} style={{ marginBottom: '24px' }}>
                                <FormControl size='small' fullWidth>
                                  <Select
                                    key={`string-${idx}-${props.index}`}
                                    name='typeBoolean'
                                    id='typeBoolean'
                                    defaultValue={dataValuesIndex[idx] === true ? 'Yes' : 'No'}
                                    size='small'
                                        // label="Type"
                                    onChange={(event) => handleChangeType(event, idx)}
                                  >
                                    <MenuItem
                                      key='Yes' value='Yes'
                                      selected={dataValuesIndex[idx] === true}
                                    >
                                      Yes
                                    </MenuItem>
                                    <MenuItem
                                      key='No' value='No'
                                      selected={dataValuesIndex[idx] === false}
                                    >
                                      No
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                          }
                        </div>
                    }
                  </div>
                )
              })
            }
          </Grid>
        </Box>

        <DrawerFooter onClose={() => handleClose()} onDone={handleDone} />
      </Drawer>
    </>
  )
}
