import React, { useEffect, useState } from 'react'
import '../../../../css/forms.css'
import Chip from '@mui/material/Chip'
import HelpIcon from '@mui/icons-material/Help'
import ReportIcon from '@mui/icons-material/Report'
import GppGoodIcon from '@mui/icons-material/GppGood'

function AddLogicTriggerPill (props) {
  const [field, setField] = useState(props.field)

  const handleDelete = (id) => {
    props.onDeleteQuestion(id)
  }

  const handleDeleteIssue = (idx) => {
    props.onDeleteIssue(idx)
  }

  const handleSelectIssue = (issue, idx) => {
    props.onSelectIssue(issue, idx)
  }

  const handleDeleteSolution = (idx) => {
    props.onDeleteSolution(idx)
  }

  const handleSelectSolution = (solution, idx) => {
    props.onSelectSolution(solution, idx)
  }

  useEffect(() => {
    setField(props.field)
  }, [props.field])

  // console.log(props);

  return (
    <>
      {
                'related' in field &&
                field.related.map((rel, idx) => {
                  return (
                    <Chip
                      key={`rel-question-${rel.id}`}
                      style={styles.relatedQuestionMain}
                      icon={<HelpIcon />}
                      label={rel.title}
                      onDelete={() => handleDelete(rel.id)}
                      variant='outlined'
                    />
                  )
                })
            }

      {
                'issues' in field &&
                field.issues.map((issue, idx) => {
                  return (
                    <Chip
                      key={`issue-${idx}`}
                      style={styles.relatedQuestionMain}
                      icon={<ReportIcon style={styles.issueIcon} />}
                      label={issue.issueid}
                      onDelete={() => handleDeleteIssue(idx)}
                      onClick={() => handleSelectIssue(issue, idx)}
                      variant='outlined'
                    />
                  )
                })
            }

      {
                'solutions' in props && props.solutions.length !== 0 &&
                props.solutions.map((solution, idx) => {
                  return (
                    field.id === solution.fieldTypeId &&
                      <Chip
                        key={`solution-${idx}`}
                        style={styles.relatedQuestionMain}
                        icon={<GppGoodIcon style={styles.solutionIcon} />}
                            // label={solution.id}
                        label={solution.customTitle || solution.solutionTitle}
                        onDelete={() => handleDeleteSolution(idx)}
                        variant='outlined'
                        onClick={() => handleSelectSolution(solution, idx)}
                      />
                  )
                })
            }
    </>
  )
}

const styles = {
  relatedQuestionMain: {
    background: '#fff',
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: '5px',
    marginRight: '5px'
  },
  issueIcon: {
    color: 'red'
  },
  solutionIcon: {
    color: '#2196f3'
  }
}

export default AddLogicTriggerPill
