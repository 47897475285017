import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { DataGridPresentations } from '../../../components/Reusable/DataGridPresentations'

const RightPanelFollowUpPresentations = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState([])

  useEffect(() => {
    if (isLoading) {
      setList(props.presentation.followUp.presentations)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = ({ list }) => {
    const p = JSON.parse(JSON.stringify(props.presentation))
    p.followUp.presentations = list
    props.setPresentation(p)
    setList(list)
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Box sx={{ p: 2, borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Typography style={styles.title}>{props.title || 'Presentations'}</Typography>
      </Box>
      <Box style={styles.container} sx={{ pt: 2, pl: 2 }}>
        <Typography variant='body2'>
          Add presentations that will appear at the end of this presentation.
        </Typography>
      </Box>
      <Box style={styles.container} sx={{ pt: 2, pl: 2, pr: 2 }}>
        <DataGridPresentations {...props} list={list} onChange={handleChange} />
      </Box>
    </>
  )
}
export default RightPanelFollowUpPresentations

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'white'
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  }
}
