import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, Popover, IconButton, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DocumentIcon from '@mui/icons-material/InsertDriveFileOutlined'
import { LoadingPleaseWait } from '@supportworks/react-components'
import DataGrid from './DataGrid'
import { DialogAssetSelector } from 'components/Reusable/DialogAssetSelector'
import Helper from 'library/helper'

import { pdfjs, Document, Page } from 'react-pdf/dist/esm/entry.webpack5'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

export const DataGridAssets = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [pageSize, setPageSize] = useState(10)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [list, setList] = useState([])

  useEffect(() => {
    setList(props.list)
    setIsLoading(false)
  }, [props.list])

  const handleAdd = (l) => {
    setList(l)
    props.onAdd(l)
  }

  const handleChange = (l) => {
    props.onChange(l)
  }

  const handleDelete = (idx) => {
    const r = window.confirm('Are you sure you want to delete?')
    if (r) {
      const l = JSON.parse(JSON.stringify(list))
      l.splice(idx, 1)
      handleChange(l)
    }
  }

  const handleMoveUp = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx - 1, 0, ele)
    handleChange(l)
  }

  const handleMoveDown = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx + 1, 0, ele)
    handleChange(l)
  }

  const handleProcessRowUpdate = (row) => {
    if (row) {
      const l = JSON.parse(JSON.stringify(list))
      l[row.id].title = row.title
      handleChange(l)
      return row
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    { field: 'id', headerName: 'Id', hide: true },
    {
      field: 'type',
      headerName: 'Type',
      flex: 0,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        if (params.row.page) {
          return (
            <>
              {params.row.type}
              <div style={{ position: 'relative', display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                <DocumentIcon color='primary' style={{ fontSize: 28, color: '#A6B0CF' }} />
                <Typography component='span' style={{ position: 'absolute', top: 8, color: '#A6B0CF', fontSize: 12 }}>
                  {params.row.page}
                </Typography>
              </div>
            </>
          )
        } else {
          return params.row.type
        }
      }
    },
    {
      field: 'previewURL',
      headerName: 'Preview',
      sortable: false,
      renderCell: (params) => {
        if (params.row.previewURL) {
          if (params.row.type === 'PDF' && params.row.page && params.row.path) {
            return <PreviewPDFPage path={params.row.path} page={params.row.page} />
          } else {
            return (
              <a href={params.row.path} target='_blank' rel='noreferrer'>
                <img src={params.row.previewURL} alt={params.row.title} width='100%' />
              </a>
            )
          }
        }
        return null
      }
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 2,
      editable: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box>
            <strong>{params.row.title}</strong>
          </Box>
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id)}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton size='small' disabled={!(params.row.id < list.length - 1)} onClick={() => handleMoveDown(params.row.id)}>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < list.length; x++) {
    const a = props.config.assets2.find((o) => o.id === list[x].asset)
    if (a && a.type) {
      rows.push({
        id: x,
        type: a.type,
        previewURL: a.previewURL ? a.previewURL : null,
        path: a.path ? a.path : null,
        title: list[x].title,
        actions: '',
        page: list[x].page ? list[x].page : null
      })
    } else {
      // console.log(`Could not lookup ${list[x].asset}`);
    }
  }

  return (
    <>
      <Box>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onProcessRowUpdate={(row) => {
            handleProcessRowUpdate(row)
          }}
        />
      </Box>
      <Box sx={{ pt: 2 }}>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            setShowAddDialog(!showAddDialog)
          }}
        >
          Add Asset
        </Button>
        {props.options ? <>{props.options}</> : null}
      </Box>
      {showAddDialog
        ? (
          <DialogAssetSelector
            title='Select Assets'
            list={list.filter((o) => !o.type)}
            assets={props.config.assets2}
            onSave={(l) => {
              handleAdd(l)
              setShowAddDialog(!showAddDialog)
            }}
            onClose={() => setShowAddDialog(!showAddDialog)}
          />
          )
        : null}
    </>
  )
}

const PreviewPDFPage = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [base64PDF, setBase64PDF] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    if (isLoading) {
      Helper.toDataURL(props.path, function (base64) {
        setBase64PDF(base64)
        setIsLoading(false)
      })
    }
  }, []) // eslint-disable-line

  const handlePopoverOpen = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  if (isLoading) return <LoadingPleaseWait />

  const open = Boolean(anchorEl)

  return (
    <Box onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
      <Box sx={{ backgroundColor: 'rgba(0,0,0,.16)' }}>
        <Document file={base64PDF} loading=''>
          <Page scale={0.18} pageNumber={props.page} loading='' />
        </Document>
      </Box>
      <Popover
        id='mouse-over-popover'
        sx={{
          pointerEvents: 'none'
        }}
        open={open}
        anchorEl={anchorEl}
      >
        <Box sx={{ p: 2, border: '1px solid rgba(0,0,0,.16)' }}>
          <Document file={base64PDF}>
            <Page scale={0.5} pageNumber={props.page} />
          </Document>
        </Box>
      </Popover>
    </Box>
  )
}
