import React, { useState, useEffect } from 'react'
import { Button, Grid, Box, Paper, Dialog, AppBar, Toolbar, IconButton, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import LeftPanel from './LeftPanel'
import Canvas from './Canvas'
import Permissions from 'library/Permissions'
import { LoadingPleaseWait } from '@supportworks/react-components'
import DrawerDebug from 'components/Reusable/DrawerDebug'

export const OverlayDocumentEditor = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [field, setField] = useState(null)
  const [presentation, setPresentation] = useState({})

  useEffect(() => {
    setPresentation(props.presentation)
    if (isLoading) {
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleSave = async () => {
    setIsSaving(true)
    props.onSave(presentation)
  }

  const handleClose = () => {
    if (window.confirm('You will lose all changes.  Are you sure you want to close?')) {
      props.onClose()
    }
  }

  const handleDragStart = (field) => {
    console.log('handleDragStart')
    setField(field)
  }

  const handleDragEnd = (e) => {
    console.log('handleDragEnd')
    setField(null)
  }

  const handleFieldSelect = (field) => {
    console.log('handleFieldSelect')
    if (!field || (field && !field.id)) {
      setField({})
    } else {
      setField(field)
    }
  }

  const handleResetAll = () => {
    const r = window.confirm('Are you sure you want to clear all icons and images from the canvas?')
    if (r) {
      const p = JSON.parse(JSON.stringify(presentation))
      for (let x = 0; x < p.overlay.length; x++) {
        p.overlay[x].image.x = null
        p.overlay[x].image.y = null
        p.overlay[x].icon.x = null
        p.overlay[x].icon.y = null
      }
      setPresentation(p)
      props.setPresentation(p)
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  let jsx
  try {
    jsx = (
      <Box>
        <Paper elevation={0} sx={{ p: 2, display: { xs: 'block', sm: 'none' } }}>
          <Grid container spacing={0}>
            For the best user experience, please use a larger resolution.
          </Grid>
          <Box sx={{ display: 'flex', ...styles.bottomNavigation }}>
            <Button id='close-editor' sx={{ mr: 2 }} onClick={props.onClose}>
              Close
            </Button>
          </Box>
        </Paper>
        <Paper elevation={0} sx={{ pt: 0, pb: 0, pl: 0, pr: 0, display: { xs: 'none', sm: 'block' } }}>
          <AppBar position='relative'>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
              <Grid container spacing={0} wrap='nowrap' alignItems='center'>
                <Grid item>
                  <IconButton title='Previous' aria-label='Previous' sx={{ pl: 1, mr: 2 }} onClick={props.onClose}>
                    <ArrowBackIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={8}>
                  <Typography noWrap variant='h6'>
                    Presentation Overlay Editor
                  </Typography>
                </Grid>
                <Grid container justifyContent='flex-end'>
                  {/**
                  <IconButton title="Full Screen" aria-label="Full Screen" onClick={handleFullScreen}>
                    <FullscreenIcon size="large" />
                  </IconButton>
                  **/}
                  <IconButton title='Zoom In' aria-label='Zoom In' onClick={handleResetAll}>
                    <RestartAltIcon size='large' />
                  </IconButton>
                  <IconButton title='Close without Saving' aria-label='Close without Saving' onClick={props.onClose}>
                    <CloseIcon size='large' />
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>

          {!props.config
            ? (
              <Grid container spacing={0} direction='row' alignItems='center' justifyContent='center' style={{ minHeight: '50vh' }}>
                <Stack alignItems='center'>
                  <Typography sx={{ pb: 1 }}>We apologize there was an error of some kind.</Typography>
                  <Typography sx={{ pb: 1 }}>The presentation is missing configuration data.</Typography>
                  <Typography>Please notify your representative.</Typography>
                </Stack>
              </Grid>
              )
            : (
              <Grid container spacing={0} style={{ height: 'calc(100vh - 65px)' }} wrap='nowrap'>
                <Grid item style={styles.leftPanel}>
                  <LeftPanel
                    field={field}
                    assets={props.assetsConfig}
                    onFieldSelect={handleFieldSelect}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    presentation={props.presentation}
                    setPresentation={(p) => {
                      setPresentation(p)
                      props.setPresentation(p)
                    }}
                  />
                </Grid>
                <Grid item sm={11} style={styles.rightPanel}>
                  <Canvas
                    assets={props.assetsConfig}
                    presentation={presentation}
                    field={field}
                    onFieldSelect={handleFieldSelect}
                    onDelete={handleDragEnd} // Forces a refresh of the LeftPanel
                    setPresentation={(p) => {
                      setPresentation(p)
                      props.setPresentation(p)
                    }}
                  />
                </Grid>
                {Permissions.hasRole('super_user') ? <DrawerDebug json={[{ name: 'presentation', json: presentation }]} /> : null}
              </Grid>
              )}

          <Box sx={{ display: 'flex', ...styles.bottomNavigation }}>
            <Button id='close-editor' sx={{ mr: 2 }} variant='outlined' onClick={handleClose}>
              Close
            </Button>
            <Button id='save-editor' disabled={!!isSaving} variant='contained' color='primary' sx={{ marginLeft: 'auto' }} onClick={handleSave}>
              {isSaving ? <LoadingPleaseWait className='loading-please-wait-button' /> : <>Done</>}
            </Button>
          </Box>
        </Paper>
      </Box>
    )
  } catch (err) {
    console.log(`OverlayDocumentEditor error: ${err.stack}`)
    jsx = <div>Data is not available.</div>
  }
  return (
    <Dialog open onClose={props.onClose} fullScreen PaperProps={{ sx: { width: '100%', height: '100%' } }} scroll='paper'>
      {jsx}
    </Dialog>
  )
}
export default OverlayDocumentEditor

export const calculateXPos = (e, zoom) => {
  const x = e.pageX / zoom
  return x
}

export const calculateYPos = (e, zoom, imageHeight) => {
  const y = imageHeight - e.pageY / zoom
  return y
}

const styles = {
  bottomNavigation: {
    position: 'fixed',
    width: '100%',
    left: 0,
    bottom: 0,
    maxHeight: '69px',
    padding: '16px',
    borderTop: 1,
    borderColor: 'rgba(0,0,0,.16)',
    backgroundColor: 'white'
  },
  leftPanel: {
    width: '336px',
    maxHeight: '100vh',
    overflow: 'auto',
    WebkitUserSelect: 'none',
    resize: 'horizontal',
    marginBottom: 68
  },
  rightPanel: {
    overflow: 'hidden',
    backgroundColor: '#c2c2c2',
    outline: '1px solid rgba(0,0,0,.16)',
    WebkitUserSelect: 'none'
  }
}
