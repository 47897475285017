/* eslint-disable array-callback-return */
import React from 'react'
import withScreenWrapper from '../withScreenWrapper'

class CompanyNoAccess extends React.Component {
  render () {
    return (
      <div className='CompanyDashboard'>
        <div className='CompanyDashboard__header'>
          <h1>No Access</h1>
        </div>
        <div className='CompanyDashboard__content'>
          <p>You do not have access to this company.</p>
        </div>
      </div>
    )
  }
}

export default withScreenWrapper(CompanyNoAccess)
