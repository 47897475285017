/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { Autocomplete, Checkbox, FormControlLabel, Grid, Paper, TextField, Typography } from '@mui/material'
import CompanyConfigEdit, { CompanyConfigBaseEditor } from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import Api from '../../library/Api'
import { LoadingPleaseWait } from '@supportworks/react-components'

const config = {
  title: 'Build Configuration',
  variant: '__complete',
  tag: '__complete',
  name: 'Build Configuration',
  lockTag: true,
  lockName: false,
  lockTitle: true
}

class BuildEditor extends CompanyConfigBaseEditor {
  constructor (props) {
    super(props)
    let config = {}
    if (typeof props.contentJson === 'string') {
      // try catch
      try {
        config = JSON.parse(props.contentJson)
      } catch (err) {
        config = {}
      }
    } else if (typeof props.contentJson === 'object') {
      // no need to parse
      config = JSON.parse(JSON.stringify(props.contentJson))
    } else if (props.contentJson !== undefined) {
      try {
        config = JSON.parse(props.contentJson)
      } catch (err) {
        config = {}
      }
    } else {
      config = {}
    }
    this.state = {
      config,
      filter: '',
      configList: null
    }
  }

  componentDidMount () {
    super.componentDidMount()
    Api.callRegisteredMethod('getConfigList', {}).then((configs) => {
      const configList = {}
      configs.map((c) => {
        if (c.status === 'Published') {
          if (!(c.tag in configList)) {
            configList[c.tag] = []
          }
          configList[c.tag].push(c)
        }
        return null
      })
      this.setState({ configList })
    })
  }

  handleChange = (name, value) => {
    const c = JSON.parse(JSON.stringify(this.state.config))
    if (value !== undefined) {
      c[name] = value
      // For pricing, remove from additional_pricing
      if (name === 'pricing') {
        if (c.additional_pricing !== undefined) {
          const idx = c.additional_pricing.findIndex((e) => e === value)
          if (idx > -1) {
            c.additional_pricing.splice(idx, 1)
          }
        }
      }
    } else {
      c[name] = -1
    }
    this.setState({ config: c })
  }

  handleChangeMulti = (selection, id) => {
    // We only support a pricing multi select, but I built this so we can add to it
    if (selection === 'pricing') {
      const c = JSON.parse(JSON.stringify(this.state.config))
      if (c.additional_pricing === undefined) {
        c.additional_pricing = []
      }
      const idx = c.additional_pricing.findIndex((e) => e === id)
      if (idx < 0) {
        c.additional_pricing.push(id)
      } else {
        c.additional_pricing.splice(idx, 1)
      }
      this.setState({ config: c })
    }
  }

  finalize () {
    return JSON.stringify(this.state.config)
  }

  hasFinalize () {
    return true
  }

  render () {
    if (this.state.configList == null) {
      return <LoadingPleaseWait />
    } else {
      const configs = [
        { category: 'appointment', label: 'Meetings', id: 'AppointmentType', tag: 'appointment_type' },
        { category: 'products', label: 'Default Pricing List', id: 'pricing', tag: 'pricing' },
        { category: 'products', label: 'Accounting Details', id: 'contract_terms', tag: 'contract.terms' },
        { category: 'company', label: 'Company Info', id: 'contract_dealer', tag: 'contract.dealer' },
        // { category: 'company', label: 'About Us', id: 'aboutus', tag: 'aboutus' },
        { category: 'company', label: 'Contract Details', id: 'contract', tag: 'contract' },
        { category: 'company', label: 'Document Packet', id: 'pdfPlacement', tag: 'pdfPlacement' },
        { category: 'communication', label: 'Distribution List', id: 'distributionList', tag: 'distributionList' },
        { category: 'communication', label: 'Email & Text Messaging', id: 'messaging', tag: 'messaging' }
      ]

      // Dev-4489 Show stuff by categories
      //
      const jsxArray = []
      let selections = configs.filter((o) => o.category === 'appointment')
      let jsx = (
        <BuildSingleSelect
          key='bss-appt-process'
          title='Appointment Process'
          selections={selections}
          config={this.state.config}
          configList={this.state.configList}
          onChange={this.handleChange}
        />
      )
      jsxArray.push(jsx)

      selections = configs.filter((o) => o.category === 'products')
      jsx = (
        <BuildSingleSelect
          key='bss-p-and-p'
          title='Products and Pricing'
          selections={selections}
          config={this.state.config}
          configList={this.state.configList}
          onChange={this.handleChange}
        />
      )
      jsxArray.push(jsx)

      // Additional stuff under products, checkboxes for multi-select
      const selection = configs.find((o) => o.id === 'pricing')
      jsx = (
        <BuildMultiSelect
          key='bms-apl'
          title='Available Price Lists'
          keyConfig='pricing'
          keyConfigMulti='additional_pricing'
          selection={selection}
          config={this.state.config}
          configList={this.state.configList}
          onChange={this.handleChangeMulti}
        />
      )
      jsxArray.push(jsx)

      selections = configs.filter((o) => o.category === 'company')
      jsx = (
        <BuildSingleSelect
          key='bss-company'
          title='Company'
          selections={selections}
          config={this.state.config}
          configList={this.state.configList}
          onChange={this.handleChange}
        />
      )
      jsxArray.push(jsx)

      selections = configs.filter((o) => o.category === 'communication')
      jsx = (
        <BuildSingleSelect
          key='bss-comm'
          title='Communication'
          selections={selections}
          config={this.state.config}
          configList={this.state.configList}
          onChange={this.handleChange}
        />
      )
      jsxArray.push(jsx)

      return (
        <Paper elevation={0} sx={{ p: 2 }}>
          {jsxArray}
        </Paper>
      )
    }
  }
}

const BuildSingleSelect = (props) => {
  const config = props.config
  const configList = props.configList
  return (
    <Grid container sx={{ pb: 2 }} spacing={2}>
      <Grid item xs={12} sx={{ pb: 1 }}>
        <Typography variant='h6'>{props.title}</Typography>
      </Grid>
      {props.selections.map((c, idx) => {
        const [options, value] = getAutocompleteData({ id: c.id, tag: c.tag, config, configList })
        return (
          <Grid key={`bss-${idx}`} item xs={6}>
            <Autocomplete
              key={`autocomplete-${c.label}-${idx}`}
              disablePortal
              id={`autocomplete-${c.label}-${idx}`}
              options={options}
              value={value}
              placeholder={c.label}
              onChange={(e, obj) => {
                if (obj !== null) {
                  props.onChange(c.id, obj.value)
                } else {
                  props.onChange(c.id)
                }
              }}
              renderInput={(params) => <TextField fullWidth {...params} label={value !== null ? c.label : `Select ${c.label}`} />}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

const BuildMultiSelect = (props) => {
  const s = props.selection
  // eslint-disable-next-line no-unused-vars
  const [options, value] = getAutocompleteData({ id: s.id, tag: s.tag, config: props.config, configList: props.configList })
  return (
    <Grid container sx={{ pb: 2 }}>
      <Grid item xs={12} sx={{ pb: 2 }}>
        <Typography variant='body2' style={{ fontWeight: 'bold' }}>
          {props.title}
        </Typography>
      </Grid>
      <Grid container>
        {options.map((o, idx) => {
          // Using the key, get the values from the matching array so we can set checked to true or false
          let checked = false
          const json = props.config[props.keyConfigMulti]
          if (json !== undefined && json.find((e) => e === o.value) > 1) checked = true
          let disabled = false
          if (o.value === props.config[props.keyConfig]) {
            disabled = true
            checked = true
          }
          return (
            <Grid key={`multiselect-${idx}`} item xs={3} sx={{ m: 0, p: 0, border: '1px solid rgba(0,0,0,.0)' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={disabled}
                    checked={checked}
                    id={`multiselect-${idx}`}
                    name={`multiselect-${idx}`}
                    onClick={() => props.onChange(s.id, o.value)}
                  />
                }
                label={<Typography variant='body2'>{o.label}</Typography>}
              />
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

const getAutocompleteData = ({ id, tag, config, configList }) => {
  const options = []
  let value = null
  if (tag in configList) {
    const cl = configList[tag]
    for (let y = 0; y < cl.length; y++) {
      options.push({ label: cl[y].name, value: cl[y].configId })
      if (config[id] === cl[y].configId) {
        value = cl[y].name
      }
    }
  }
  return [options, value]
}

export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} editorComponent={BuildEditor} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />,
  config
}
