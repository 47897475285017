import React, { useState, useEffect } from 'react'
import { Box, Button, Chip, Grid, Typography, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { DataGrid } from '@mui/x-data-grid'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { DialogSolutionSelector } from 'components/Reusable/DialogSolutionSelector'

const RightPanelSolutions = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [pageSize, setPageSize] = useState(10)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [list, setList] = useState([])

  useEffect(() => {
    if (isLoading) {
      setList(props.presentation.followUp.solutions)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (l) => {
    const p = JSON.parse(JSON.stringify(props.presentation))
    p.followUp.solutions = l
    props.setPresentation(p)
    setList(l)
  }

  const handleAdd = (l) => {
    handleChange(l)
    setShowAddDialog(!showAddDialog)
  }

  const handleDelete = (idx) => {
    const r = window.confirm('Are you sure you want to delete?')
    if (r) {
      const l = JSON.parse(JSON.stringify(list))
      l.splice(idx, 1)
      handleChange(l)
    }
  }

  const handleMoveUp = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx - 1, 0, ele)
    handleChange(l)
  }

  const handleMoveDown = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx + 1, 0, ele)
    handleChange(l)
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    { field: 'id', headerName: 'Id', hide: true },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return <strong>{params.row.title}</strong>
      }
    },
    {
      field: 'defaultProducts',
      headerName: 'Default Products',
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return <Chip size='small' label={params.row > 1 ? <>{params.row} Default Products</> : <>0 Default Products</>} />
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id)}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton size='small' disabled={!(params.row.id < list.length - 1)} onClick={() => handleMoveDown(params.row.id)}>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < list.length; x++) {
    const s = props.config.solutions.find((o) => o.id === list[x])
    let df = 0
    if (props.config.solutions.product) {
      df = props.config.solutions.product.length
    }
    let title = s.title
    if (s.subtitle) {
      title = title + ' - ' + s.subtitle
    }
    rows.push({
      id: x,
      title,
      defaultProducts: df,
      actions: ''
    })
  }

  return (
    <>

      <Box sx={{ p: 2, borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Typography style={styles.title}>{props.title || 'Solutions'}</Typography>
      </Box>
      <Box style={styles.container} sx={{ pt: 2, pl: 2 }}>
        <Typography variant='body2'>
          Add Solutions that will appear at the end of this presentation.
        </Typography>
      </Box>

      <Box style={styles.container} sx={{ pt: 2, pl: 2, pr: 2 }}>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          sx={{
            '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
              color: '#A6B0CF',
              backgroundColor: 'transparent'
            }
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false
              }
            }
          }}
        />
      </Box>
      <Box style={styles.container} sx={{ pt: 2, pl: 2, pr: 2 }}>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            setShowAddDialog(!showAddDialog)
          }}
        >
          Add Solutions
        </Button>
      </Box>

      {showAddDialog
        ? (
          <DialogSolutionSelector
            title='Select Solutions'
            list={list}
            solutions={props.config.solutions}
            assets={props.config.assets2}
            onSave={(l) => {
              handleAdd(l)
              setShowAddDialog(!showAddDialog)
            }}
            onClose={() => setShowAddDialog(!showAddDialog)}
          />
          )
        : null}
    </>
  )
}
export default RightPanelSolutions

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'white'
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  }
}
