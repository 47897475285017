import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, LinearProgress, Paper, Stack, Switch, TextField, Typography } from '@mui/material'
import SquareIcon from '@mui/icons-material/Square'
import AdminIcon from '@mui/icons-material/AdminPanelSettings'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import Api from 'library/Api'
import ColorPicker from 'components/Reusable/ColorPicker'
import Company from 'screens/Company'
import CompanyConfigEdit from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import H from 'library/helper'
import { LoadingPleaseWait } from '@supportworks/react-components'
import Permissions from '../../library/Permissions'

const config = {
  title: 'Customer Portal',
  variant: 'settingsCustomerPortal',
  tag: 'settingsCustomerPortal',
  name: 'Customer Portal',
  lockTag: true,
  lockName: true,
  lockTitle: true,
  ref: React.createRef(null)
}

const SettingsCustomerPortal = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [usePortalSigning, setUsePortalSigning] = useState(false)
  const [showDialogProvisioning, setShowDialogProvisioning] = useState(false)

  const [contentJson, setContentJson] = useState({})
  const [originalJson, setOriginalJson] = useState({})

  const [subDomain, setSubDomain] = useState('')
  const [state, setState] = useState('Inactive') // Active Inactive Failed - state of the subDomain.  Core updates this!
  const [linkExpires, setLinkExpires] = useState(7)

  const [showColorPicker, setShowColorPicker] = useState(false)
  const [showColorPickerText, setShowColorPickerText] = useState(false)
  const [primaryColor, setPrimaryColor] = useState('#0000FF')
  const [buttonTextColor, setButtonTextColor] = useState('#FFFFFF')
  const [emailSignedProposal, setEmailSignedProposal] = useState('')
  const [emailError, setEmailError] = useState('')
  const [paymentURL, setPaymentURL] = useState('')
  const [paymentURLError, setPaymentURLError] = useState('')

  const [showContactUsSalesRepInfo, setShowContactUsSalesRepInfo] = useState(true)
  const [showContactUsCompanyInfo, setShowContactUsCompanyInfo] = useState(true)
  const [showJobInformation, setShowJobInformation] = useState(true)
  const [showMyProject, setShowMyProject] = useState(true)
  const [showFinancingPlans, setShowFinancingPlans] = useState(true)
  const [showDocuments, setShowDocuments] = useState(true)
  const [showQuestionnaire, setShowQuestionnaire] = useState(true)
  const [showPhotos, setShowPhotos] = useState(true)
  const [showInspectionDetails, setShowInspectionDetails] = useState(true)
  const [showPresentations, setShowPresentations] = useState(true)
  const [showDrawing, setShowDrawing] = useState(true)

  const [showProductQuantities, setShowProductQuantities] = useState(true)
  const [showItemizedPricing, setShowItemizedPricing] = useState(true)
  const [showSolutions, setShowSolutions] = useState(true)
  // for settings.projectDisplay: { }
  const [showRecommendations, setShowRecommendations] = useState(true)
  const [showAllOptions, setShowAllOptions] = useState(true)
  const [showRecommendationsWhenSold, setShowRecommendationsWhenSold] = useState(true)
  const [error, setError] = useState('') // Used to trigger a field focus on error

  useEffect(() => {
    if (props.config && props.config.contentJson) {
      const c = JSON.parse(props.config.contentJson)
      if (!c.state) c.state = 'Inactive'
      setOriginalJson(JSON.parse(props.config.contentJson))
      if (c.primaryColor) setPrimaryColor(c.primaryColor)
      if (c.buttonTextColor) setButtonTextColor(c.buttonTextColor)
      if (c.subDomain) setSubDomain(c.subDomain)
      if (c.state) setState(c.state)
      if (c.linkExpires) setLinkExpires(c.linkExpires)
      if (c.emailSignedProposal) setEmailSignedProposal(c.emailSignedProposal)
      if (c.paymentURL) setPaymentURL(c.paymentURL)

      // Auto-fix empty stuff...
      for (let x = 0; x < variableDefaults.length; x++) {
        if (c[variableDefaults[x].name] === undefined) {
          if (variableDefaults[x].key === undefined) {
            c[variableDefaults[x].name] = variableDefaults[x].value
          } else {
            if (c[variableDefaults[x].key] === undefined) {
              c[variableDefaults[x].key] = {}
            }
            if (
              c[variableDefaults[x].key][variableDefaults[x].name] === undefined
            ) {
              c[variableDefaults[x].key][variableDefaults[x].name] =
                variableDefaults[x].value
            }
          }
        }
      }
      if (c.usePortalSigning !== undefined) {
        setUsePortalSigning(c.usePortalSigning)
      } else {
        c.usePortalSigning = false
        setUsePortalSigning(false)
      }
      if (c.showContactUsSalesRepInfo !== undefined) { setShowContactUsSalesRepInfo(c.showContactUsSalesRepInfo) }
      if (c.showContactUsCompanyInfo !== undefined) { setShowContactUsCompanyInfo(c.showContactUsCompanyInfo) }
      if (c.showJobInformation !== undefined) { setShowJobInformation(c.showJobInformation) }
      if (c.showMyProject !== undefined) setShowMyProject(c.showMyProject)
      if (c.showFinancingPlans !== undefined) { setShowFinancingPlans(c.showFinancingPlans) }
      if (c.showDocuments !== undefined) setShowDocuments(c.showDocuments)
      if (c.showQuestionnaire !== undefined) { setShowQuestionnaire(c.showQuestionnaire) }
      if (c.showPhotos !== undefined) setShowPhotos(c.showPhotos)
      if (c.showInspectionDetails !== undefined) { setShowInspectionDetails(c.showInspectionDetails) }
      if (c.showPresentations !== undefined) { setShowPresentations(c.showPresentations) }
      if (c.showDrawing !== undefined) setShowDrawing(c.showDrawing)
      if (c.productDisplay !== undefined) {
        const pd = c.productDisplay
        setShowProductQuantities(pd.productQuantities)
        setShowItemizedPricing(pd.itemizedPricing)
        setShowSolutions(pd.showSolutions)
      }
      if (c.projectDisplay !== undefined) {
        const pd = c.projectDisplay
        if (pd.showRecommendations !== undefined) { setShowRecommendations(pd.showRecommendations) }
        if (pd.showAllOptions !== undefined) { setShowAllOptions(pd.showAllOptions) }
        if (pd.showRecommendationsWhenSold !== undefined) { setShowRecommendationsWhenSold(pd.showRecommendationsWhenSold) }
      }
      if (props.onChange) {
        props.onChange(JSON.stringify(c))
      }
      setContentJson(c)
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (contentJson) {
      props.onRef({
        hasFinalizeHook: () => {
          return true
        },
        finalizeHook: c => {
          return finalizeHook(c)
        }
      })
    }
    // eslint-disable-next-line
  }, [contentJson])

  const handleChange = e => {
    const name = e.target.name
    const value = e.target.value

    const c = JSON.parse(JSON.stringify(contentJson))
    if (name === 'subDomain') {
      setSubDomain(value)
      c.subDomain = value
      if (!state) {
        setState('Inactive')
        c.state = 'Inactive'
      }
    } else if (name === 'linkExpires') {
      setLinkExpires(value)
      c.linkExpires = value
    } else if (name === 'showContactUsSalesRepInfo') {
      setShowContactUsSalesRepInfo(!showContactUsSalesRepInfo)
      c.showContactUsSalesRepInfo = !c.showContactUsSalesRepInfo
    } else if (name === 'showContactUsCompanyInfo') {
      setShowContactUsCompanyInfo(!showContactUsCompanyInfo)
      c.showContactUsCompanyInfo = !c.showContactUsCompanyInfo
    } else if (name === 'showJobInformation') {
      setShowJobInformation(!showJobInformation)
      c.showJobInformation = !c.showJobInformation
    } else if (name === 'showQuestionnaire') {
      setShowQuestionnaire(!showQuestionnaire)
      c.showQuestionnaire = !c.showQuestionnaire
    } else if (name === 'showMyProject') {
      setShowMyProject(!showMyProject)
      c.showMyProject = !c.showMyProject
    } else if (name === 'showFinancingPlans') {
      setShowFinancingPlans(!showFinancingPlans)
      c.showFinancingPlans = !c.showFinancingPlans
    } else if (name === 'showPresentations') {
      setShowPresentations(!showPresentations)
      c.showPresentations = !c.showPresentations
    } else if (name === 'showDocuments') {
      setShowDocuments(!showDocuments)
      c.showDocuments = !c.showDocuments
    } else if (name === 'showQuestionnaire') {
      setShowQuestionnaire(!showQuestionnaire)
      c.showQuestionnaire = !c.showQuestionnaire
    } else if (name === 'showInspectionDetails') {
      setShowInspectionDetails(!showInspectionDetails)
      c.showInspectionDetails = !c.showInspectionDetails
    } else if (name === 'showDrawing') {
      setShowDrawing(!showDrawing)
      c.showDrawing = !c.showDrawing
    } else if (name === 'showPhotos') {
      setShowPhotos(!showPhotos)
      c.showPhotos = !c.showPhotos
    } else if (name === 'showProductQuantities') {
      setShowProductQuantities(!showProductQuantities)
      c.productDisplay.productQuantities = !c.productDisplay.productQuantities
    } else if (name === 'showItemizedPricing') {
      setShowItemizedPricing(!showItemizedPricing)
      c.productDisplay.itemizedPricing = !c.productDisplay.itemizedPricing
    } else if (name === 'showSolutions') {
      setShowSolutions(!showSolutions)
      c.productDisplay.showSolutions = !c.productDisplay.showSolutions
    } else if (name === 'showRecommendations') {
      setShowRecommendations(!showRecommendations)
      c.projectDisplay.showRecommendations =
        !c.projectDisplay.showRecommendations
    } else if (name === 'showAllOptions') {
      setShowAllOptions(!showAllOptions)
      c.projectDisplay.showAllOptions = !c.projectDisplay.showAllOptions
    } else if (name === 'showRecommendationsWhenSold') {
      setShowRecommendationsWhenSold(!showRecommendationsWhenSold)
      c.projectDisplay.showRecommendationsWhenSold = !c.projectDisplay.showRecommendationsWhenSold
    } else if (name === 'emailSignedProposal') {
      const emails = value.split(',').map(email => email.trim())
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const isValid = emails.every(email => emailRegex.test(email))
      if (!isValid) {
        setEmailError('Please enter valid email addresses, separated by commas.')
      } else {
        setEmailError('')
        c.emailSignedProposal = value
      }
      setEmailSignedProposal(value)
    } else if (name === 'paymentURL') {
      // Regex to validate value against valid URL
      const urlRegex = /^(http|https):\/\/[^ "]+$/
      const isValid = urlRegex.test(value)
      if (!isValid) {
        setPaymentURLError('Please enter a valid URL.')
      } else {
        setPaymentURLError('')
        c.paymentURL = value
        setPaymentURL(value)
      }
    } else if (name === 'usePortalSigning') {
      setUsePortalSigning(!usePortalSigning)
      c.usePortalSigning = !usePortalSigning
    }
    if (props.onChange) {
      props.onChange(JSON.stringify(c))
    }
    setContentJson(c)
  }

  const handleChangeColor = (variant, color) => {
    if (variant === 'primaryColor') {
      setPrimaryColor(color)
    } else {
      setButtonTextColor(color)
    }
    const c = JSON.parse(JSON.stringify(contentJson))
    c[variant] = color
    setContentJson(c)
    props.onChange(JSON.stringify(c))
  }

  const handleAdminState = state => {
    const r = window.confirm(
      'WARNING! This could lead to corrupt data settings.  Are you sure?'
    )
    if (r) {
      const c = JSON.parse(JSON.stringify(contentJson))
      setState(state)
      c.state = state
      if (state === 'Inactive') {
        Api.callRegisteredMethod('deprovision', {
          subdomain: c.subDomain
        }).then(status => {
          setState('Inactive')
          c.state = 'Inactive'
          setSubDomain('')
          c.subDomain = ''
          setOriginalJson(c)
          props.onChange(JSON.stringify(c))
        })
      } else {
        if (props.onChange) {
          setOriginalJson(c)
          props.onChange(JSON.stringify(c))
        }
      }
    }
  }

  const finalizeHook = async contentJson => {
    const c = JSON.parse(contentJson)
    console.log(`subDomain: ${subDomain} state: ${state}`)
    if (!c.subDomain) {
      c.state = 'Inactive'
      setOriginalJson(c)
    } else {
      if (originalJson.subDomain !== c.subDomain) {
        if (c.state.match(/Failed|Inactive/i)) {
          const isTaken = await Api.callRegisteredMethod('checkPortalSubDomain', {
            subdomain: c.subDomain
          })
          if (!isTaken) {
            c.state = 'Active' // Temporary, can be overriden in background provisioning logic.
            c.subDomain = subDomain
            setShowDialogProvisioning(true)
          } else {
            c.state = 'Inactive'
            c.subDomain = ''
            setState(c.state)
            setSubDomain(c.subDomain)
            setError('subdomain-taken')
            return JSON.stringify(c)
          }
        }
      }
    }
    return JSON.stringify(c)
  }

  if (isLoading) return <LoadingPleaseWait />

  const currentCompany = Company.getCurrent()
  const domains = domainize(currentCompany.name)

  return (
    <Paper elevation={0} sx={{ mb: 2, p: 2 }}>
      <Box sx={{ pb: 2 }}>
        <Stack>
          <Typography variant='h6'>Settings</Typography>
          <Typography variant='body2'>
            Customize the customer portal to your preferences.
          </Typography>
        </Stack>
      </Box>

      {Permissions.hasRole('super_user')
        ? (
          <Box
            sx={{
              border: '1px solid rgba(0,0,0,.16)',
              backgroundColor: 'rgba(255,0,0,.04)',
              p: 2,
              mb: 2
            }}
          >
            <Typography sx={{ pb: 1, fontWeight: 'bold' }}>
              <AdminIcon style={{ color: 'red' }} /> Administrator Testing Tools
              for domain provisioning state
            </Typography>
            <Grid container>
              <Grid item sx={{ pr: 1 }}>
                <Button
                  color='error'
                  variant='contained'
                  disabled={state === 'Inactive'}
                  onClick={() => handleAdminState('Inactive')}
                  startIcon={<AdminIcon />}
                >
                  <Typography>Inactive</Typography>
                </Button>
              </Grid>
              <Grid item sx={{ pr: 1 }}>
                <Button
                  color='error'
                  variant='contained'
                  disabled={state === 'Active'}
                  onClick={() => handleAdminState('Active')}
                  startIcon={<AdminIcon />}
                >
                  <Typography>Active</Typography>
                </Button>
              </Grid>
              <Grid item sx={{ pr: 1 }}>
                <Button
                  color='error'
                  variant='contained'
                  disabled={state === 'Failed'}
                  onClick={() => handleAdminState('Failed')}
                  startIcon={<AdminIcon />}
                >
                  <Typography>Failed</Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
          )
        : null}

      <Box
        sx={{
          p: 2,
          mb: 2,
          border: '1px solid rgba(0,0,0,.08)',
          backgroundColor: 'rgba(0,0,0,.01)'
        }}
      >
        <Box sx={{ pb: 2 }}>
          <Stack>
            {!state.match(/Active|Failed/)
              ? (
                <>
                  <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                    Subdomain Provisioning
                  </Typography>
                  <Typography variant='body2'>
                    Please choose wisely a subdomain that will be used in your
                    customer portal. The subdomain must be lowercase a-z or 0-9
                    characters. Changes must be made through your account
                    representative. Examples: {domains.join(', ')}.
                  </Typography>
                </>
                )
              : (
                <>
                  <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                    Subdomain
                  </Typography>
                  <Typography variant='body2'>
                    This is the domain that will be used for your customer
                    portal. If you need to change this, please contact your
                    account representative.
                  </Typography>
                </>
                )}
          </Stack>
        </Box>

        {state === 'Failed'
          ? (
            <Alert severity='error' sx={{ p: 2, mb: 2 }}>
              We apologize, there was a an error provisioning your subdomain.
              Please contact your account representative and we will resolve
              quickly.
            </Alert>
            )
          : null}

        <Grid container sx={{ pb: 2 }}>
          <Grid item xs={12} sm={8} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              disabled={!!state.match(/Active|Failed/)}
              id='subDomain'
              name='subDomain'
              variant='outlined'
              helperText={
              error === 'subdomain-taken'
                ? 'That subdomain is being used.  Please choose another.'
                : state === 'Failed'
                  ? 'There was an error provisioning the subdomain.'
                  : state === 'Active'
                    ? 'Your subdomain is active.'
                    : 'Please enter the subdomain you would like to use. '
            }
              value={subDomain}
              error={error === 'subdomain-taken'}
              inputProps={{
                maxLength: 63
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Typography>.mysolutionview.com</Typography>
                  </InputAdornment>
                ),
                startAdornment: (
                  <>
                    {state === 'Active'
                      ? (
                        <InputAdornment position='start'>
                          <CheckCircleIcon style={{ color: 'green' }} />
                        </InputAdornment>
                        )
                      : null}
                    {state === 'Failed'
                      ? (
                        <InputAdornment position='start'>
                          <ErrorOutlineIcon style={{ color: 'red' }} />
                        </InputAdornment>
                        )
                      : null}
                  </>
                )
              }}
              onKeyDown={event => {
                if (event.key !== 'Backspace') {
                  if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                    event.preventDefault()
                  }
                }
              }}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              id='linkExpires'
              name='linkExpires'
              variant='outlined'
              helperText='Link Expiration'
              defaultValue={linkExpires}
              type='number'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Typography>Days</Typography>
                  </InputAdornment>
                )
              }}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ pb: 3 }}>
        <Stack>
          <Typography variant='body1' style={{ fontWeight: 'bold' }}>
            Custom White Labeling
          </Typography>
          <Typography variant='body2'>
            You may set the colors for icons and buttons used in your portal to
            match your brand.
          </Typography>
        </Stack>
      </Box>

      <Grid container>
        <Grid item xs={12} sm={6} sx={{ pr: 2, pb: 2 }}>
          {showColorPicker
            ? (
              <ColorPicker
                color={primaryColor}
                onChange={color => {
                  setShowColorPicker(!showColorPicker)
                  handleChangeColor('primaryColor', color)
                }}
                onClose={e => setShowColorPicker(!showColorPicker)}
              />
              )
            : (
              <TextField
                fullWidth
                id='primaryColor'
                name='primaryColor'
                variant='outlined'
                label='Icon and Button Color'
                helperText='Pick the color used for icons and buttons.'
                defaultValue={primaryColor}
                onClick={() => setShowColorPicker(!showColorPicker)}
                sx={{
                  'input:focus': {
                    border: 'none'
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <IconButton
                        sx={{
                          width: 24,
                          height: 24,
                          border: 1,
                          borderColor: 'black',
                          borderRadius: 0,
                          backgroundColor: primaryColor
                        }}
                      >
                        <SquareIcon sx={{ color: primaryColor }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {showColorPickerText
            ? (
              <ColorPicker
                color={buttonTextColor}
                onChange={color => {
                  setShowColorPickerText(!showColorPickerText)
                  handleChangeColor('buttonTextColor', color)
                }}
                onClose={e => setShowColorPickerText(!showColorPickerText)}
              />
              )
            : (
              <TextField
                fullWidth
                id='buttonTextColor'
                name='buttonTextColor'
                variant='outlined'
                label='Button Text Color'
                helperText='Pick the color used for text on buttons.'
                defaultValue={buttonTextColor}
                onClick={() => setShowColorPickerText(!showColorPickerText)}
                sx={{
                  'input:focus': {
                    border: 'none'
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <IconButton
                        sx={{
                          width: 24,
                          height: 24,
                          border: 1,
                          borderColor: 'black',
                          borderRadius: 0,
                          backgroundColor: buttonTextColor
                        }}
                      >
                        <SquareIcon sx={{ color: buttonTextColor }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              )}
        </Grid>
      </Grid>

      <Divider sx={{ mt: 0, mb: 2 }} />

      <Box sx={{ pb: 2 }}>
        <Stack>
          <Typography variant='body1' style={{ fontWeight: 'bold' }}>
            Finance
          </Typography>
          <Typography variant='body2'>
            Enter finance config if applicable.  If unset, nothing will be displayed.
          </Typography>
        </Stack>
      </Box>
      {/** Add a TextField where the user can enter a signed proposal distribution list email, in a MUI Grid like above **/}
      <Grid container>
        <Grid item xs={12} sm={6} sx={{ pr: 2 }}>
          <TextField
            fullWidth
            id='paymentURL'
            name='paymentURL'
            variant='outlined'
            label='Payment URL for Financing'
            helperText={paymentURLError || 'Enter the payment link that the customer will be sent to for financing information.'}
            defaultValue={paymentURL}
            onChange={handleChange}
            error={!!paymentURLError}
          />
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2, mb: 2 }} />

      <Grid container sx={{ mb: 2 }}>
        <Grid item>
          <Typography variant='body1' style={{ fontWeight: 'bold' }}>Document E-Sign</Typography>
          <FormControl>
            <FormControlLabel
              sx={{ m: 0 }}
              control={
                <Switch
                  name='usePortalSigning'
                  sx={{ display: 'flex' }}
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  onChange={handleChange}
                  checked={usePortalSigning}
                />
              }
              label={
                <Typography sx={{ mr: 4 }}>Enable Customer Portal E-Sign</Typography>
              }
              labelPlacement='start'
            />
          </FormControl>
        </Grid>
      </Grid>

      <Box sx={{ pb: 2 }}>
        <Stack>
          <Typography variant='body1' style={{ fontWeight: 'bold' }}>
            E-Sign Distribution List
          </Typography>
          <Typography variant='body2'>
            Enter the email addresses you want notified when a customer signs for a project.
          </Typography>
        </Stack>
      </Box>
      {/** Add a TextField where the user can enter a signed proposal distribution list email, in a MUI Grid like above **/}
      <Grid container>
        <Grid item xs={12} sm={6} sx={{ pr: 2, pb: 2 }}>
          <TextField
            fullWidth
            id='emailSignedProposal'
            name='emailSignedProposal'
            variant='outlined'
            label='Signed Proposal Event Email'
            helperText={emailError || 'Enter the emails for signed proposal events, separated by commas.'}
            defaultValue={emailSignedProposal}
            onChange={handleChange}
            error={!!emailError}
          />
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2, mb: 2 }} />

      <Grid container>
        <Grid item xs={12} sm={4} sx={{ pr: 2 }}>
          <Stack>
            <Typography variant='body1' style={{ fontWeight: 'bold' }}>
              Include Sections
            </Typography>
            <Typography variant='body2'>
              Check the sections you would like to appear on your portal site.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='showJobInformation'
                    onChange={handleChange}
                    checked={showJobInformation}
                  />
                }
                label='Job Information'
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='showMyProject'
                    onChange={handleChange}
                    checked={showMyProject}
                  />
                }
                label='My Project'
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='showFinancingPlans'
                    onChange={handleChange}
                    checked={showFinancingPlans}
                  />
                }
                label='Financing Plans'
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='showQuestionnaire'
                    onChange={handleChange}
                    checked={showQuestionnaire}
                  />
                }
                label='Questionnaire'
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='showDocuments'
                    onChange={handleChange}
                    checked={showDocuments}
                  />
                }
                label='Documents'
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='showInspectionDetails'
                    onChange={handleChange}
                    checked={showInspectionDetails}
                  />
                }
                label='Inspection Details'
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='showDrawing'
                    onChange={handleChange}
                    checked={showDrawing}
                  />
                }
                label='Drawing'
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='showPresentations'
                    onChange={handleChange}
                    checked={showPresentations}
                  />
                }
                label='Presentations'
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='showPhotos'
                    onChange={handleChange}
                    checked={showPhotos}
                  />
                }
                label='Photos'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2, mb: 2 }} />

      <Grid container>
        <Grid item xs={12} sm={4} sx={{ pr: 2 }}>
          <Stack>
            <Typography variant='body1' style={{ fontWeight: 'bold' }}>
              Contact Us Settings
            </Typography>
            <Typography variant='body2'>
              Customize the information that will appear when a customer presses
              the "Contact Us" on your portal site.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='showContactUsSalesRepInfo'
                    onChange={handleChange}
                    checked={showContactUsSalesRepInfo}
                  />
                }
                label='Include Sales Rep Information'
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='showContactUsCompanyInfo'
                    onChange={handleChange}
                    checked={showContactUsCompanyInfo}
                  />
                }
                label='Include Company Information'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2, mb: 2 }} />

      <Grid container>
        <Grid item xs={12} sm={4} sx={{ pr: 2 }}>
          <Stack>
            <Typography variant='body1' style={{ fontWeight: 'bold' }}>
              Product Display Settings
            </Typography>
            <Typography variant='body2'>
              Customize the information that will appear when a customer views
              the product line items on the My Project section.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='showProductQuantities'
                    onChange={handleChange}
                    checked={showProductQuantities}
                  />
                }
                label='Show Product Quantities'
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='showItemizedPricing'
                    onChange={handleChange}
                    checked={showItemizedPricing}
                  />
                }
                label='Show Itemized Pricing'
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='showSolutions'
                    onChange={handleChange}
                    checked={showSolutions}
                  />
                }
                label='Show Solutions'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2, mb: 2 }} />

      <Grid container>
        <Grid item xs={12} sm={4} sx={{ pr: 2 }}>
          <Stack>
            <Typography variant='body1' style={{ fontWeight: 'bold' }}>
              Project Display Settings
            </Typography>
            <Typography variant='body2'>
              Customize the information that will appear when a customer views
              the My Project drop down display.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='showRecommendations'
                    onChange={handleChange}
                    checked={showRecommendations}
                  />
                }
                label='Show Recommendations'
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='showAllOptions'
                    onChange={handleChange}
                    checked={showAllOptions}
                  />
                }
                label='Show All Project Options'
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='showRecommendationsWhenSold'
                    onChange={handleChange}
                    checked={showRecommendationsWhenSold}
                  />
                }
                label='Show Recommended Projects on Sold Appointments'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {showDialogProvisioning
        ? (
          <DialogProvisioning
            {...props}
            contentJson={contentJson}
            subDomain={subDomain}
            setState={setState}
            setSubDomain={setSubDomain}
            onSave={props.onSave}
            onClose={() => setShowDialogProvisioning(!showDialogProvisioning)}
          />
          )
        : null}
    </Paper>
  )
}

const DialogProvisioning = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isCheckingCNAME, setIsCheckingCNAME] = useState(false) // Done second with 5 second sleep, until AWS has it registered
  const [attemptsCheckCNAME, setAttemptsCheckCNAME] = useState(1)
  const [isDoingCloudFront, setIsDoingCloudFront] = useState(false) // Done third.  Will fail if the CNAME isn't completely done.
  const [attemptsCloudFront, setAttemptsCloudFront] = useState(1)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (isLoading) {
      // Register with Route 53
      Api.callRegisteredMethod('provisionCNAME', {
        subdomain: props.subDomain
      }).then(status => {
        // Trigger the Route 53 CNAME validation.
        if (status) {
          setIsCheckingCNAME(true)
          setProgress(33)
        } else {
          handleDone({ status: 'error' })
        }
      })
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  // Check the subdomain in Route 53.
  //
  // The subdomain has to be done provisioned inside of AWS or the Cloudfront alternate domain stuff won't work.
  //
  useEffect(() => {
    if (isCheckingCNAME) {
      const intervalId = setInterval(() => {
        Api.callRegisteredMethod('checkPortalSubDomain', {
          subdomain: props.subDomain
        }).then(status => {
          if (status) {
            setIsDoingCloudFront(true)
            setIsCheckingCNAME(false)
            setProgress(66)
          } else {
            if (attemptsCheckCNAME >= 5) {
              setIsCheckingCNAME(false)
              props.onClose()
            }
            setAttemptsCheckCNAME(attemptsCheckCNAME + 1)
          }
        })
      }, 5000)
      return () => clearInterval(intervalId)
    }
    // eslint-disable-next-line
  }, [isCheckingCNAME])

  // Provision the subdomain in Cloudfront
  //
  useEffect(() => {
    if (isDoingCloudFront) {
      const intervalId = setInterval(() => {
        Api.callRegisteredMethod('provisionCloudFront', {
          subdomain: props.subDomain
        }).then(status => {
          if (status) {
            setProgress(100)
            handleDone(true)
          } else {
            if (attemptsCloudFront >= 5) {
              setIsDoingCloudFront(false)
              handleDone(false)
            }
            setAttemptsCloudFront(attemptsCloudFront + 1)
          }
        })
      }, 5000)
      return () => clearInterval(intervalId)
    }
    // eslint-disable-next-line
  }, [isDoingCloudFront])

  const handleDone = success => {
    const c = JSON.parse(JSON.stringify(props.contentJson))
    if (success) {
      c.state = 'Active'
      c.subDomain = props.subDomain
    } else {
      c.state = 'Failed'
      c.subDomain = ''
    }
    // Force a save outside of the normal save path, to avoid all the hasFinalize stuff...
    H.saveConfig(props.organizationId, config, c)
    props.setState(c.state)
    props.setSubDomain(c.subDomain)
    props.onClose()
  }

  if (isLoading) return <LoadingPleaseWait />

  const jsx = (
    <Box>
      <Box sx={{ pt: 2, pb: 2 }}>
        <Stack>
          <Typography sx={{ pb: 2 }}>
            When complete, your domain will be available at: &nbsp;
          </Typography>
          <Typography
            sx={{
              pb: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <code>https://{props.subDomain}.mysolutionview.com</code>
          </Typography>
          <Typography>
            If the URL above is not immediately available, please wait 5 minutes
            and check again.
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ mt: 2, width: '100%' }}>
        <LinearProgress
          variant='determinate'
          sx={{ height: '24px' }}
          value={progress}
        />
      </Box>
    </Box>
  )

  return (
    <Dialog open fullScreen={false} fullWidth maxWidth='sm'>
      <DialogTitle className='dialog-title' sx={{ pl: 0 }}>
        <Grid container alignItems='center'>
          <Grid item sx={{ pl: 2 }}>
            <Typography variant='h6'>Subdomain Provisioning</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ pl: 2, pr: 2, ml: 0, mt: 1 }}>{jsx}</DialogContent>
    </Dialog>
  )
}

// Get a couple domain examples...
const domainize = value => {
  const domains = []
  const d = value.replace(/\W/g, '')
  domains.push(d.toLowerCase() + 'portal')

  // Get first letter of each word
  const first = value
    .split(' ')
    .map(value => value[0])
    .join('')
  domains.push(first.toLowerCase() + 'portal')
  return domains
}

const ALPHA_NUMERIC_DASH_REGEX = /^[a-z0-9-]+$/

const variableDefaults = [
  { name: 'showContactUsSalesRepInfo', value: true },
  { name: 'showContactUsCompanyInfo', value: true },
  { name: 'showJobInformation', value: true },
  { name: 'showMyProject', value: true },
  { name: 'showFinancingPlans', value: true },
  { name: 'showQuestionnaire', value: true },
  { name: 'showPresentations', value: true },
  { name: 'showDocuments', value: true },
  { name: 'showPhotos', value: true },
  { name: 'showInspectionDetails', value: true },
  { name: 'showDrawing', value: true },
  { name: 'buttonTextColor', value: '#FFFFFF' },
  { name: 'primaryColor', value: '#0000FF' },
  { key: 'productDisplay', name: 'productQuantities', value: true },
  { key: 'productDisplay', name: 'itemizedPricing', value: true },
  { key: 'productDisplay', name: 'showSolutions', value: true },
  { key: 'projectDisplay', name: 'showRecommendations', value: true },
  { key: 'projectDisplay', name: 'showAllOptions', value: true }
]

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Edit: props => (
    <CompanyConfigEdit
      {...config}
      {...props}
      editorComponent={SettingsCustomerPortal}
    />
  ),
  Index: props => <CompanyConfigIndex {...config} {...props} />
}
