import React, { useEffect, useState } from 'react'
import { Box, Button, Chip, Grid, IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import Api from 'library/Api'
import CompanyConfigEdit from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import DataGrid from 'components/Reusable/DataGrid'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import { getAssetInfo } from 'screens/Config/CompanyConfigPresentations'
import Helper from '@supportworks/helper'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { ResourceEditor } from 'screens/Config/Resources/ResourceEditor'
import Categories from 'screens/Config/Resources/Categories'

const config = {
  title: 'Resources',
  variant: 'resources',
  tag: 'resources',
  name: 'Resources',
  lockTag: true,
  lockName: true
}

const CompanyConfigResources = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [config, setConfig] = useState({})
  const [assetsConfig, setAssetsConfig] = useState({})
  const [resources, setResources] = useState([])
  const [originalResources, setOriginalResources] = useState([])
  const [categories, setCategories] = useState([])
  const [originalCategories, setOriginalCategories] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [showEditComponent, setShowEditComponent] = useState(false)
  const [showEditCategories, setShowEditCategories] = useState(false)
  const [isAdding, setIsAdding] = useState(false)

  useEffect(() => {
    const initializeResourcesAndCategories = () => {
      if (!props.config || !props.config.contentJson) return
      let content = JSON.parse(props.config.contentJson)
      // If content is NOT an object, like it's an array, flip it to an object
      if (Array.isArray(content)) {
        content = { }
      }
      content.resources = content.resources || []
      content.categories = content.categories || []

      setResources(content.resources)
      setOriginalResources(content.resources)
      setCategories(content.categories)
      setOriginalCategories(content.categories)
      setConfig(content)
    }

    if (isLoading) {
      initializeResourcesAndCategories()

      Promise.all([
        Api.callRegisteredMethod('getAssetList', {}).then((assets) => {
          setAssetsConfig(assets)
        })
      ]).then(() => {
        setIsLoading(false)
      })
    }
  }, []) // eslint-disable-line)

  useEffect(() => {
    if (config) {
      props.onRef({
        hasFinalizeHook: () => {
          return true
        },
        finalizeHook: config => {
          return finalizeHook(config)
        }
      })
    }
    // eslint-disable-next-line
  }, [config])

  // handleAdd for config.resources
  const handleAdd = () => {
    const tmpResources = JSON.parse(JSON.stringify(resources))
    tmpResources.unshift({
      id: Helper.guid(),
      title: 'New Resource',
      type: 'pdf'
    })
    setIsAdding(true)
    setSelectedIndex(0)
    setShowEditComponent(true)
    handleChange('resources', tmpResources)
  }

  // handleDelete for config.resources
  const handleDelete = indexes => {
    const count = indexes.length
    const confirmMessage = count === 1 ? 'Are you sure you want to delete this item?' : `Are you sure you want to delete these ${count} items?`
    const r = window.confirm(confirmMessage)
    if (r) {
      const l = JSON.parse(JSON.stringify(resources))
      // delete the indexes in reverse order so we don't mess up the indexes
      indexes.sort((a, b) => b - a).forEach(idx => {
        l.splice(idx, 1)
      })
      setResources(l)
      handleChange('resources', l)
    }
  }

  // handleSave for config.resources
  const handleSave = (obj) => {
    try {
      const l = JSON.parse(JSON.stringify(resources))
      l[selectedIndex] = obj
      handleChange('resources', l)
      setSelectedIndex(-1)
      setShowEditComponent(false)
    } catch (e) {
      console.error(e)
    }
  }

  // handleClose for config.resources
  const handleClose = () => {
    setSelectedIndex(-1)
    setShowEditComponent(false)
  }

  // handleEdit for config.resources
  const handleEdit = idx => {
    setSelectedIndex(idx)
    setShowEditComponent(!showEditComponent)
  }

  // handleProcessRowUpdate for config.resources
  const handleProcessRowUpdate = row => {
    if (row) {
      const l = JSON.parse(JSON.stringify(resources))
      l[row.id].title = row.title
      setResources(l)
      handleChange('resources', l)
      return row
    }
  }

  // handleChange for config.resources and config.categories
  const handleChange = (variant, obj) => {
    const c = JSON.parse(JSON.stringify(config))
    if (variant === 'resources') {
      c.resources = obj
      setResources(obj)
    } else if (variant === 'categories') {
      c.categories = obj
      setCategories(obj)
    } else if (variant === 'config') {
      // Replace the entire config object
      c.resources = resources
      c.categories = categories
      setOriginalCategories(categories)
      setOriginalResources(resources)
    }
    setConfig(c)
    if (props.onChange) {
      props.onChange(JSON.stringify(c))
    }
  }

  // handleShowEditCategories for config.categories
  const handleShowEditCategories = () => {
    setShowEditCategories(!showEditCategories)
  }

  const finalizeHook = async contentJson => {
    // Nothing to see here...
    return contentJson
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    {
      flex: 1,
      field: 'asset',
      headerName: 'Preview',
      editable: false,
      sortable: false,
      renderCell: params => {
        if (params.row.type.match(/video/i)) {
          return (
            <Box key={`media-${params.row.id}`} sx={{ maxWidth: 72 }}>
              <a target='_blank' href={params.row.path} download rel='noreferrer'>
                <img key={`img-${params.row.id}`} src={params.row.previewURL} style={{ maxHeight: 50 }} alt={params.row.previewURL} />
              </a>
            </Box>
          )
        } else if (params.row.type.match(/image|pdf/i)) {
          return (
            <Box key={`media-${params.row.id}`}>
              <a target='_blank' href={params.row.path} download rel='noreferrer'>
                <img key={`img-${params.row.id}`} src={params.row.previewURL} style={{ maxHeight: 50 }} alt={params.row.previewURL} />
              </a>
            </Box>
          )
        } else if (params.row.filename.match(/\.mp3/)) {
          return (
            <Box key={`media-${params.row.id}`}>
              <a target='_blank' href={params.row.path} download rel='noreferrer'>
                <audio id={`audio-${params.row.id}`} style={{ display: 'none' }}>
                  <source src={params.row.path} type='audio/mpeg' />
                  Your browser does not support the audio element.
                </audio>
                <PlayArrowIcon size='small' />
              </a>
            </Box>
          )
        }
        return (
          <>Unknown</>
        )
      }
    },
    {
      flex: 1,
      field: 'type',
      headerName: 'Type',
      editable: false,
      sortable: false,
      renderCell: params => {
        const formatType = (type) => {
          if (type.toLowerCase() === 'pdf') {
            return type.toUpperCase()
          }
          return type.charAt(0).toUpperCase() + type.slice(1)
        }
        return (
          <Tooltip title={formatType(params.row.type)}>
            <>{formatType(params.row.type)}</>
          </Tooltip>
        )
      }
    },
    {
      flex: 2,
      field: 'title',
      headerName: 'Title',
      editable: true,
      sortable: true,
      renderCell: params => {
        return (
          <Tooltip title={params.row.title}>
            <>{params.row.title}</>
          </Tooltip>
        )
      }
    },
    {
      flex: 1,
      field: 'levels',
      headerName: 'Levels',
      editable: false,
      sortable: false,
      renderCell: params => {
        return (
          <Box>
            {!params.row?.levels || params.row?.levels?.length === 0
              ? (
                <Chip key='chip-fup' sx={{ mb: 0.5 }} label='No Levels' size='small' />
                )
              : (
                  params.row.levels.map((level, idx) => (
                    <Chip key={`chip-${idx}`} sx={{ mb: 0.5 }} label={level} size='small' />
                  ))
                )}
          </Box>
        )
      }
    },
    {
      flex: 1,
      field: 'library',
      headerName: 'Library',
      editable: false,
      sortable: false,
      renderCell: params => {
        return (
          <Tooltip title={params.row.library}>
            <>{params.row.library}</>
          </Tooltip>
        )
      }
    },
    {
      width: 164,
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'right',
      sortable: false,
      renderCell: params => {
        if (params.row) {
          return (
            <Grid container justifyContent='flex-end' alignItems='center' spacing={1}>
              <Grid item>
                <IconButton disabled={params.row.isLibrary} size='small' onClick={() => handleEdit(params.row.id)}>
                  <EditIcon fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton disabled={params.row.isLibrary} size='small' onClick={() => handleDelete([params.row.id])}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < resources.length; x++) {
    const r = resources[x]
    if (r.library) {
      if (r.library?.status?.match(/notadded|deleted/)) {
        continue
      }
    }

    let previewURL = ''
    let filename = ''
    let path = ''
    if (resources[x].asset) {
      const a = getAssetInfo(resources[x].asset, assetsConfig)
      if (a) {
        if (a.previewURL) previewURL = a.previewURL
        if (a.filename) filename = a.filename
        if (a.path) path = a.path
      }
    }
    const row = {
      id: x,
      type: resources[x].type,
      title: resources[x].title,
      levels: resources[x].levels,
      library: r.library?.libraryName || '',
      isLibrary: !!r.library?.libraryName, // For disabling p/Josh 240819psb
      filename,
      previewURL,
      path,
      actions: ''
    }
    rows.push(row)
  }

  return (
    <Box>
      <DataGrid
        rows={rows}
        columns={columns}
        showToolbarDensity={false} // wrap the chips in datagrid
        customToolbarButtons={(
          <Grid item sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', height: 40 }}>
            <Button
              variant='outlined'
              size='small'
              color='primary'
              onClick={handleShowEditCategories}
              sx={{ whiteSpace: 'nowrap' }}
            >
              Resource Categories
            </Button>
          </Grid>
      )}
        onAdd={handleAdd}
        onProcessRowUpdate={row => {
          handleProcessRowUpdate(row)
        }}
        onDelete={(indexes) => {
          handleDelete(indexes)
        }}
      />
      {showEditComponent && (
        <ResourceEditor
          assetsConfig={assetsConfig}
          categoriesConfig={categories}
          resource={resources[selectedIndex]}
          isAdding={isAdding}
          onClose={handleClose}
          onSave={handleSave}
          onEditCategories={handleShowEditCategories}
        />
      )}
      {showEditCategories && (
        <DialogGeneric
          title='Edit Categories'
          content={<Categories isAdding={isAdding} assetsConfig={assetsConfig} categories={categories} setCategories={setCategories} resources={resources} setResources={setResources} />}
          fullScreen
          showBackArrow
          onChange={() => {
            handleChange('config')
            setShowEditCategories(false)
          }}
          onClose={() => {
            setCategories(originalCategories)
            setResources(originalResources)
            setShowEditCategories(false)
          }}
        />
      )}
    </Box>
  )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Edit: props => <CompanyConfigEdit {...config} {...props} editorComponent={CompanyConfigResources} />,
  Index: props => <CompanyConfigIndex {...config} {...props} />
}
