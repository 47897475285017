import React from 'react'
import { Box, Typography } from '@mui/material'
import Permissions from 'library/Permissions'
import Api from 'library/Api'

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      hasError: false,
      error: null
    }
  }

  static getDerivedStateFromError () {
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    try {
      const componentStack = errorInfo.componentStack.substr(0, 1000).replace(/^\s+/g, '')
      const errorMessage = error.message
      const errorStack = error.stack
      const apiErr = `Fatal error: ${JSON.stringify(errorMessage)}, Component Stack: ${JSON.stringify(componentStack)}, JS Stack: ${JSON.stringify(errorStack)}`
      const err = `Fatal error: ${JSON.stringify(error.message)} - ${JSON.stringify(errorInfo.componentStack.substr(0, 200).replace(/^\s+/g, ''))}`
      if (window?.location?.hostname === 'localhost') {
        this.setState({ error: apiErr })
      } else {
        this.setState({ error: err })
        Api.callRegisteredMethod('recordError', { error: apiErr })
      }
    } catch (err) {
      this.setState({ error: 'An error has occurred.  Please contact your account representative.' })
    }
  }

  render () {
    if (this.state.hasError) {
      return (
        <Box sx={{ p: 2 }}>
          <Typography variant='h6' sx={{ mb: 2 }}>
            We're sorry, something went wrong. Please email <a href='mailto:support@solutionview.com'>support@solutionview.com</a> with details about the issue.
            Our team will get back to you with a solution or an estimated time for resolution. Thank you.
          </Typography>
          {Permissions.hasRole('super_user')
            ? (
              <Typography variant='body2'>
                <code>{this.state.error}</code>
              </Typography>
              )
            : null}
        </Box>
      )
    }

    return this.props.children
  }
}
export default ErrorBoundary
