import React from 'react'
import { IconButton, Typography, AppBar, Toolbar } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'

const EditorHeader = ({ title, fullScreen, onBack, onFullscreen, showFullScreenOption = true }) => {
  return (
    <AppBar position='relative'>
      <Toolbar>
        {onBack
          ? (
            <IconButton title='Previous' aria-label='Previous' sx={{ mr: 1 }} onClick={onBack}>
              <ArrowBackIcon />
            </IconButton>
            )
          : null}
        <Typography variant='h6' sx={{ pl: onBack ? 0 : 2, flexGrow: 2 }}>
          {title}
        </Typography>
        {showFullScreenOption && (
          <IconButton title='Full Screen' aria-label='Full Screen' onClick={onFullscreen} sx={{ mr: 1 }}>
            {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  )
}
export default EditorHeader
