import React, { useState, useEffect } from 'react'
import { FormControl, TextField, MenuItem } from '@mui/material'
import MapFormFieldNumeric from './MapFormFieldNumeric'

const MapFormFieldNoAutomate = ({ setProduct, product, formFields }) => {
  const [mappedFieldObject, setMappedFieldObject] = useState({})
  const [formFieldId, setFormFieldId] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [formFieldValue, setFormFieldValue] = useState('')
  const [showMapFormField, setShowMapFormField] = useState(false)
  const [list, setList] = useState([])

  useEffect(() => {
    console.log('useEffect start [] triggered')
    // Initialize all things on the component with existing product data.
    if (!formFieldId) {
      if (product.config && product.config.length > 0) {
        const formFieldId = product.config[0].field
        setFormFieldId(formFieldId)
        const m = formFields.find(o => o.id === formFieldId)
        setMappedFieldObject(m)
      }
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    console.log('useEffect formFieldId triggered')
    if (formFieldId) {
      const m = formFields.find(o => o.id === formFieldId)
      setMappedFieldObject(m)
      const config = []
      config.push({ field: formFieldId })
      setProduct({ ...product, config })
      setShowMapFormField(true)
      if (m.type === 'choice') {
        const c = m.choices.map((o) => o.title)
        setList(c)
      } else if (m.type === 'boolean') {
        setList(['Yes', 'No'])
      } else if (m.type === 'imageCheckbox') {
        const c = m.list.map((o) => o.id)
        setList(c)
      } else {
        setList(m.list)
      }
    }
  }, [formFieldId]) // eslint-disable-line

  const handleChange = (event) => {
    const { name, value } = event.target
    console.log(JSON.stringify(product))
    if (name === 'formField') {
      setFormFieldId(value)
      // setProduct({...product})
    } else if (name === 'formFieldValue') {
      // The spec only has this as a single select...so we'll just override it.
      const config = product.config.filter((o) => o.field === formFieldId)
      config[0].values = [{ value }]
      setProduct({ ...product, config })
    }
  }

  return (
    <div>
      <div className='sa-section'>
        <div className='sa-heading'>
          What field will trigger the solution?
        </div>
      </div>
      <div className='sa-indent'>
        <FormControl key='FormControl' name='formField' value={formFieldId || ''} variant='outlined' fullWidth>
          <TextField
            select
            name='formField'
            label='Choose Field'
            variant='outlined'
            defaultValue={formFieldId || ''}
            onChange={handleChange}
          >
            {formFields.map((row, idx) => {
              return (
                <MenuItem key={`MFFMI-${idx}`} value={row.id}>{row.sectionTitle} - {row.title}</MenuItem>
              )
            })}
          </TextField>
        </FormControl>
      </div>
      <div className='sa-indent' style={{ paddingTop: '16px' }}>
        {showMapFormField && mappedFieldObject.type === 'numeric'
          ? <MapFormFieldNumeric
              config={product.config[0]}
              setProduct={setProduct}
              product={product}
              mappedFieldObject={mappedFieldObject}
              showSpecParagraph={false}
            />
          : null}
        {showMapFormField && mappedFieldObject.type.match(/radio|checkbox|choice|boolean|imageCheckbox/)
          ? (
            <>
              <div className='sa-indent'>
                <div className='sa-heading'>
                  <strong>Choose value that will trigger product:</strong>
                </div>
              </div>
              <div className='sa-indent'>
                <FormControl name='formFieldValue' value={formFieldValue || ''} variant='outlined' fullWidth>
                  <TextField
                    select
                    name='formFieldValue'
                    label='Choose Value'
                    variant='outlined'
                    defaultValue={formFieldValue || ''}
                    onChange={handleChange}
                  >
                    {list.map((row, idx) => {
                      return (
                        <MenuItem key={`MFFMI-${idx}`} value={row}>{row}</MenuItem>
                      )
                    })}
                  </TextField>
                </FormControl>
              </div>
            </>
            )
          : null}
      </div>
    </div>
  )
}
export default MapFormFieldNoAutomate
