import React, { useState, useEffect } from 'react'
import { Box, Button, Chip, Grid, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { DataGrid } from '@mui/x-data-grid'
import { DialogPresentationSelector } from 'components/Reusable/DialogPresentationSelector'
import brokenImageSvg from '../../images/broken-image-128.png'

export const DataGridPresentations = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [pageSize, setPageSize] = useState(10)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [list, setList] = useState([])

  useEffect(() => {
    setList(props.list)
    setIsLoading(false)
  }, [props.list])

  const handleAdd = (l) => {
    handleChange(l)
  }

  const handleChange = (l) => {
    setList(l)
    props.onChange({ list: l, variant: props.variant ? props.variant : null, category: props.category ? props.category : null }) // props.category is for meeting SolutionCategories that use this component
  }

  const handleDelete = (idx) => {
    const r = window.confirm('Are you sure you want to delete?')
    if (r) {
      const l = JSON.parse(JSON.stringify(list))
      l.splice(idx, 1)
      handleChange(l)
    }
  }

  const handleMoveUp = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx - 1, 0, ele)
    handleChange(l)
  }

  const handleMoveDown = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx + 1, 0, ele)
    handleChange(l)
  }

  const handleDefaultThumbnail = (e) => {
    e.target.src = brokenImageSvg
  }

  const columns = [
    { field: 'id', headerName: 'Id', hide: true },
    { field: 'type', headerName: 'Type', flex: 0, editable: false, sortable: true },
    {
      field: 'previewURL',
      headerName: 'Preview',
      renderCell: (params) => {
        if (params.row.previewURL) {
          return (
            <a href={params.row.path} target='_blank' rel='noreferrer'>
              <img onError={handleDefaultThumbnail} src={params.row.previewURL} alt={params.row.title} width='100%' style={{ maxHeight: '-webkit-fill-available' }} />
            </a>
          )
        }
        return null
      }
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 2,
      editable: false,
      sortable: true,
      renderCell: (params) => {
        return <Box sx={{ pl: 1 }}><strong>{params.row.title}</strong></Box>
      }
    },
    {
      field: 'followUp',
      headerName: 'Follow-Ups',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return <Chip label={params.row.followUp > 1 ? <>{params.row.followUp} Follow-Ups</> : <>0 Follow-Ups</>} />
      }
    },
    {
      width: 128,
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id)}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton size='small' disabled={!(params.row.id < list.length - 1)} onClick={() => handleMoveDown(params.row.id)}>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < list.length; x++) {
    const pres = props.config.presentations.find((o) => o.id === list[x])
    if (pres) {
      let fu = 0
      if (pres.followUp) {
        if (pres.followUp.presentations) {
          fu = pres.followUp.presentations.length
        }
        if (pres.followUp.solutions) {
          fu = fu + pres.followUp.solutions.length
        }
      }
      let previewURL
      let path
      if (pres.asset && props.config.assets2) {
        const a = props.config.assets2.find(o => o.id === pres.asset)
        if (a && a.previewURL) {
          previewURL = a.previewURL
          path = a.path
        }
      }
      rows.push({
        id: x,
        type: pres.type ? pres.type[0].toUpperCase() + pres.type.substring(1) : 'None',
        previewURL: previewURL || null,
        path,
        title: pres.title,
        followUp: fu,
        actions: ''
      })
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Box>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50]}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false
              }
            }
          }}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          autosizeOptions={{
            columns: ['action'],
            includeOutliers: true,
            includeHeaders: false
          }}
        />
      </Box>
      <Box sx={{ pt: 2 }}>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            setShowAddDialog(!showAddDialog)
          }}
        >
          Add Presentation
        </Button>
      </Box>

      {showAddDialog
        ? (
          <DialogPresentationSelector
            title='Select Presentations'
            list={list}
            presentations={props.currentPresentations ? props.currentPresentations : props.config.presentations}
            assets={props.config.assets2}
            onSave={(l) => {
              handleAdd(l)
              setShowAddDialog(!showAddDialog)
            }}
            onClose={() => setShowAddDialog(!showAddDialog)}
          />
          )
        : null}
    </>
  )
}
