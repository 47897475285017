import React, { useState, useEffect } from 'react'
import { Box, Grid, IconButton, TextField, Typography } from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'

export const LeftPanelSelection = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isHover, setIsHover] = useState(false)
  const [editable, setEditable] = useState(true)
  const [isEditing, setIsEditing] = useState(false)
  const [title, setTitle] = useState('')

  useEffect(() => {
    const key = `${props.subVariant}Title`
    if (props.meeting[key]) {
      setTitle(props.meeting[key])
    } else {
      setTitle(props.title)
    }
    if (typeof props.editable === 'boolean') {
      setEditable(props.editable)
    }
    setIsLoading(false)
  }, []) // eslint-disable-line

  const handleChange = (e) => {
    const m = JSON.parse(JSON.stringify(props.meeting))
    const value = e.target.value
    const key = `${props.subVariant}Title`
    m[key] = value
    props.setMeeting(m)
    setTitle(value)
  }

  const handleClickAway = () => {
    setIsEditing(false)
  }

  if (isLoading) return <></>

  let selected = false
  if (props.rightPanelVariant === props.subVariant) selected = true

  return (
    <Box
      key={props.title}
      onClick={props.onClick}
      style={{ ...styles.selectionContainer, backgroundColor: isHover ? '#F2F2F2' : 'white' }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Grid container sx={{ pl: 2, pt: props.onClick ? 1 : 1, pb: props.onClick ? 1 : 1 }} style={selected ? styles.selected : null} alignItems='center'>
        <Grid item sm={10}>
          <Grid container wrap='nowrap'>
            <Grid item onBlur={() => setIsEditing(false)}>
              {isEditing
                ? (
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <TextField
                      InputProps={{ style: { fontSize: 14, width: isEditing ? 112 : null } }}
                      size={isEditing ? 'small' : 'small'}
                      id='title'
                      name='title'
                      variant={isEditing ? 'outlined' : 'standard'}
                      value={title}
                      onChange={handleChange}
                      onFocus={() => setIsEditing(true)}
                      onBlur={() => setIsEditing(false)}
                    />
                  </ClickAwayListener>
                  )
                : (
                  <Typography sx={{ fontSize: 14 }} onClick={editable === true ? () => setIsEditing(true) : null}>
                    {title}
                  </Typography>
                  )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={1}>
          <IconButton>
            {props.rightPanelVariant === props.subVariant
              ? (
                <PlayCircleFilledIcon style={props.onClick ? styles.icon : styles.iconHidden} />
                )
              : (
                <ArrowRightIcon style={props.onClick ? styles.icon : styles.iconHidden} />
                )}
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  )
}

const styles = {
  selectionContainer: {
    display: 'flex',
    borderTop: '1px solid rgba(0,0,0,.16)',
    borderBottom: '1px solid rgba(0,0,0,.16)',
    marginTop: -1,
    marginBottom: -1,
    userSelect: 'none',
    WebkitUserSelect: 'none',
    cursor: 'pointer'
  },
  textField: {
    fontSize: 14,
    paddingRight: 4
  },
  chipField: {
    fontSize: 12
  },
  icon: {
    color: 'rgba(0,0,0,.66)'
  },
  iconHidden: {
    color: 'rgba(0,0,0,0)'
  },
  hideOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  selected: {
    backgroundColor: '#e7f0fc'
  }
}
