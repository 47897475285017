import React, { useState } from 'react'
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TextField,
  Typography, FormControl, Grid
} from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import DownIcon from '@mui/icons-material/KeyboardArrowDown'
import EditIcon from '@mui/icons-material/Edit'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import UpIcon from '@mui/icons-material/KeyboardArrowUp'
import { DialogGeneric } from '../../../components/Reusable/DialogGeneric'
import DataGrid from '../../../components/Reusable/DataGrid'

export const AppointmentNotes = (props) => {
  const [open, setOpen] = useState(false)
  const [editDialog, setEditDialog] = useState(false)
  const [editIndex, setEditIndex] = useState(0)
  const [appNotes, setAppNotes] = useState(props.appNotes)

  const addAdditionalNote = () => {
    const newAppNotes = JSON.parse(JSON.stringify(appNotes))
    newAppNotes.push({
      id: props.guid(),
      title: '',
      body: '',
      customer: false
    })
    setAppNotes(newAppNotes)
    props.onChange(newAppNotes)
  }

  const changeHandler = (e, idx) => {
    const newAppNotes = JSON.parse(JSON.stringify(appNotes))

    if (e.target.type === 'checkbox') {
      newAppNotes[idx].customer = !newAppNotes[idx].customer
    } else {
      newAppNotes[idx][e.target.name] = e.target.value
    }
    setAppNotes(newAppNotes)
    props.onChange(newAppNotes)
  }

  const editHandler = (entry, idx) => {
    setEditDialog(!editDialog)
    setEditIndex(idx)
  }

  const deleteHandler = (idx) => {
    const newAddNotes = JSON.parse(JSON.stringify(appNotes))
    newAddNotes.splice(idx, 1)
    setAppNotes(newAddNotes)
    props.onChange(newAddNotes)
  }

  const onMove = (idx, direction) => {
    const p = JSON.parse(JSON.stringify(appNotes))
    if (direction === 'up') {
      if (p[idx - 1]) {
        const toSwitch = JSON.parse(JSON.stringify(p[idx]))
        const switchWith = JSON.parse(JSON.stringify(p[idx - 1]))
        p[idx - 1] = toSwitch
        p[idx] = switchWith
        setAppNotes(p)
        props.onChange(p)
      }
    } else if (direction === 'down') {
      if (p[idx + 1]) {
        const switchWith = JSON.parse(JSON.stringify(p[idx + 1]))
        p[idx + 1] = JSON.parse(JSON.stringify(p[idx]))
        p[idx] = switchWith
        setAppNotes(p)
        props.onChange(p)
      }
    }
  }

  const handleCloseDialog = () => {
    setEditDialog(!editDialog)
  }

  const handleSaveDialog = () => {
    props.onChange(appNotes)
    setEditDialog(!editDialog)
  }

  const jsx = (
    <>
      <FormControlLabel
        sx={{ m: 0, marginRight: '24px' }}
        control={
          <Switch
            name='customer'
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
            checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
            onChange={(event) => changeHandler(event, editIndex)}
            checked={appNotes[editIndex] !== undefined ? appNotes[editIndex].customer : true}
          />
        }
        label={<Typography>Add by Default</Typography>}
        labelPlacement='start'
      />

      <TextField
        id='title'
        name='title'
        label='Title'
        variant='outlined'
        value={appNotes[editIndex] !== undefined ? appNotes[editIndex].title : ''}
        onChange={(event) => changeHandler(event, editIndex)}
        sx={{ mt: 3 }}
        fullWidth
      />

      <TextField
        id='body'
        name='body'
        label='Body'
        multiline
        rows={10}
        variant='outlined'
        value={appNotes[editIndex] !== undefined ? appNotes[editIndex].body : ''}
        onChange={(event) => changeHandler(event, editIndex)}
        sx={{ mt: 3 }}
        inputProps={{
          style: {
            border: 'none',
            padding: '0px',
            resize: 'vertical'
          }
        }}
        fullWidth
      />
    </>
  )

  const rows = []
  if (appNotes.length > 0) {
    for (let x = 0; x < appNotes.length; x++) {
      const row = {
        id: x,
        title: appNotes[x].title,
        body: appNotes[x].body,
        customer: appNotes[x].customer
      }
      rows.push(row)
    }
  }

  const columns = [
    {
      flex: 1,
      type: 'string',
      field: 'title',
      headerName: 'Title',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  id={`additional_notes_${params.row.id}`}
                  key={params.row.id}
                  name='title'
                  label='Title'
                  multiline
                  rows={2}
                  variant='outlined'
                  value={params.row.title}
                  onChange={(event) => changeHandler(event, params.row.id)}
                  onKeyDown={(event) => {
                    if (event.keyCode === 32) {
                      changeHandler(event, params.row.id)
                      event.stopPropagation()
                    }
                  }}
                  fullWidth
                  sx={{ verticalAlign: 'middle' }}
                />
              </Grid>
            </Grid>
          )
        }
      }
    },
    {
      flex: 1,
      type: 'string',
      field: 'body',
      headerName: 'Body',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  id={`additional_notes_${params.row.id}`}
                  key={params.row.id}
                  name='body'
                  label='body'
                  multiline
                  rows={2}
                  variant='outlined'
                  value={params.row.body}
                  onChange={(event) => changeHandler(event, params.row.id)}
                  onKeyDown={(event) => {
                    if (event.keyCode === 32) {
                      changeHandler(event, params.row.id)
                      event.stopPropagation()
                    }
                  }}
                  fullWidth
                  sx={{ verticalAlign: 'middle' }}
                />
              </Grid>
            </Grid>
          )
        }
      }
    },
    {
      flex: 1,
      type: 'string',
      field: 'customer',
      headerName: 'Add By Default',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Box>
              <FormControl sx={{ mt: 1, verticalAlign: 'middle' }}>
                <FormControlLabel
                  sx={{ m: 0, marginRight: '24px' }}
                  control={
                    <Switch
                      name='customer'
                      color='primary'
                      sx={{ display: 'flex', justifyContent: 'flex-end' }}
                            // checked={true}
                      onChange={(event) => changeHandler(event, params.row.id)}
                      checked={params.row.customer}
                    />
                      }
                />
              </FormControl>
            </Box>
          )
        }
      }
    },
    {
      width: 168,
      field: 'action',
      headerName: 'Actions',
      headerAlign: 'right',
      align: 'right',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        // console.log(params);
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton title='Edit' aria-label='edit' onClick={() => editHandler(params.row, params.row.id)}>
                  <EditIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton title='Move Up' aria-label='up' onClick={() => onMove(params.row.id, 'up')} disabled={params.row.id < 1}>
                  <UpIcon variant='icon' fontSize='small' />
                </IconButton>
                <IconButton title='Move Down' aria-label='down' onClick={() => onMove(params.row.id, 'down')} disabled={params.row.id === appNotes.length - 1}>
                  <DownIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton title='Delete' aria-label='delete' onClick={() => deleteHandler(params.row.id)}>
                  <DeleteIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <TableContainer component={Paper} sx={{ marginTop: '16px', marginBottom: '8px' }}>
          <Table aria-label='customized table' sx={{ borderBottom: '2px solid #2a3042' }}>
            <TableHead>
              <TableRow onClick={() => setOpen(!open)}>
                <TableCell>Appointment Notes ({appNotes.length})</TableCell>
                <TableCell align='right'>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</TableCell>
              </TableRow>
            </TableHead>
          </Table>

          {open && (
            <DataGrid
              sx={{ border: 0, '& .MuiDataGrid-columnHeaders': { borderRadius: 0 } }}
              rows={rows}
              columns={columns}
              showToolbar={false}
              rowHeight={104}
              autosizeOptions={{
                columns: ['action'],
                includeOutliers: true,
                includeHeaders: false
              }}
            />
          )}

          {open && (
            <Button
              id='additionalNotes-action'
              style={{ textTransform: 'uppercase', margin: '16px 0px 16px 16px' }}
              className='mui-btn'
              color='primary'
              variant='contained'
              onClick={addAdditionalNote}
            >
              Add Appointment Note
            </Button>
          )}

          {editDialog && (
            <DialogGeneric title='Edit Additional Note' onClose={() => handleCloseDialog()} onChange={() => handleSaveDialog()} content={jsx} fullWidth />
          )}
        </TableContainer>
      </Box>
    </>
  )
}
