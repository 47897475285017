import React, { useState } from 'react'
import { Box, IconButton, Modal } from '@mui/material'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { TreeView } from '@mui/x-tree-view/TreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'

const PopoverAutomation = (props) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const getSolutionTitle = (solutionId) => {
    const solution = props.solutionsConfig.find((s) => s.value === solutionId)
    return solution ? solution.name : 'Solution Not Defined (' + solutionId + ')'
  }
  const getProductTitle = (productId) => {
    const product = props.productConfig.find((s) => s.value === productId)
    return product ? product.name : 'Product Not Defined (' + productId + ')'
  }

  const getFieldChoices = (id, field) => {
    if ('choices' in field) {
      for (let i = 0; i < field.choices.length; i++) {
        const fieldDefn1 = getFieldRelated(id, field.choices[i])
        if (fieldDefn1) return fieldDefn1
      }
    }
    return false
  }
  const getFieldRelated = (id, field) => {
    if (!(typeof field === 'object')) return false
    if ('related' in field) {
      for (let i = 0; i < field.related.length; i++) {
        const fieldDefn = field.related[i]
        if (fieldDefn.id === id) return fieldDefn
        const fieldDefn1 = getFieldChoices(id, fieldDefn)
        if (fieldDefn1) return fieldDefn1
        const fieldDefn2 = getFieldRelated(id, fieldDefn)
        if (fieldDefn2) return fieldDefn2
      }
    }
    return false
  }
  const getFieldColumns = (id, field) => {
    if (!(typeof field === 'object')) return false
    if ('rows' in field) {
      for (let i = 0; i < field.rows.length; i++) {
        if ('columns' in field.rows[i]) {
          for (let j = 0; j < field.rows[i].columns.length; j++) {
            const fieldDefn = field.rows[i].columns[j]
            if (fieldDefn.id === id) return fieldDefn
            const fieldDefn1 = getFieldChoices(id, fieldDefn)
            if (fieldDefn1) return fieldDefn1
            const fieldDefn2 = getFieldRelated(id, fieldDefn)
            if (fieldDefn2) return fieldDefn2
          }
        }
      }
    }
    return false
  }
  const getField = (id, formObj) => {
    if (formObj) {
      if ('formSection' in formObj && Array.isArray(formObj.formSection)) {
        for (let i = 0; i < formObj.formSection.length; i++) {
          if ('fields' in formObj.formSection[i]) {
            for (let j = 0; j < formObj.formSection[i].fields.length; j++) {
              const fieldDefn = formObj.formSection[i].fields[j]
              if (fieldDefn.id === id) return fieldDefn
              const fieldDefn1 = getFieldChoices(id, fieldDefn)
              if (fieldDefn1) return fieldDefn1
              const fieldDefn2 = getFieldRelated(id, fieldDefn)
              if (fieldDefn2) return fieldDefn2
              const fieldDefn3 = getFieldColumns(id, fieldDefn)
              if (fieldDefn3) return fieldDefn3
            }
          }
        }
      }
    }
    return false
  }
  const getFieldName = (id, formObj) => {
    const r = getField(id, formObj)
    if (r) {
      return r.title
    } else {
      return 'Field not found (' + id + ')'
    }
  }

  const forms = []
  if (open) {
    props.formsList.forEach((form) => {
      if (form && 'solutions' in form) {
        const f = {
          title: form.formTitle,
          solutions: []
        }
        form.solutions.forEach((solution) => {
          const r = {
            title: getSolutionTitle(solution.solutionid),
            field: '',
            products: []
          }
          solution.products.forEach((product) => {
            let config = ''
            let quantity = ''
            if (Array.isArray(product.config) && product.config.length > 0) {
              config += 'Configuration: '
              let prefix = ''
              product.config.forEach((c) => {
                if (typeof c === 'string') {
                  config += prefix + getFieldName(c, form)
                } else {
                  config += prefix + getFieldName(c.field, form)
                }
                prefix = ', '
              })
            }
            if (product.quantity?.field) {
              quantity += '\nQuantity: ' + getFieldName(product.quantity.field, form)
            }
            const description = []
            if (config !== '') {
              description.push(config)
            }
            if (quantity !== '') {
              description.push(quantity)
            }
            r.products.push({
              title: getProductTitle(product.id),
              description
            })
          })
          f.solutions.push(r)
        })
        forms.push(f)
      }
    })
  }

  let tid = 1
  return (
    <>
      <IconButton onClick={handleOpen}>
        <AdminPanelSettingsIcon style={{ color: 'red' }} />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={{ p: 2 }} style={styles.container}>
          <TreeView
            aria-label='file system navigator'
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {forms.map((form, idx1) => {
              return (
                <TreeItem key={`level1-${idx1}`} nodeId={`${tid++}`} label={form.title}>
                  {
                  form.solutions.map((solution, idx2) => {
                    return (
                      <TreeItem key={`level2-${idx2}`} nodeId={`${tid++}`} label={solution.title}>
                        {solution.products.map((product, idx3) => {
                          return (
                            <TreeItem key={`level3-${idx3}`} nodeId={`${tid++}`} label={product.title}>
                              {product.description.map((description, idx4) => {
                                return (<Box key={idx4} style={styles.description}>{description}</Box>)
                              })}
                            </TreeItem>
                          )
                        })}
                      </TreeItem>
                    )
                  }
                  )
                }
                </TreeItem>
              )
            })}
          </TreeView>
        </Box>
      </Modal>
    </>
  )
}
export default PopoverAutomation

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '80%',
    backgroundColor: 'white',
    boxShadow: 24,
    overflowY: 'auto',
    borderRadius: '8px'
  },
  title: {
    fontSize: '16px',
    letterSpacing: '.15px',
    lineHeight: '22px',
    color: 'rgba(255,0,0,.66)'
  },
  description: {
    paddingLeft: '15px',
    color: 'rgba(0,0,0,.50)'
  },
  icon: {
    color: 'rgba(255,0,0,.66)'
  }
}
