import React, { useState, useEffect } from 'react'
import { Button, Grid, Box, Paper, Dialog, AppBar, Toolbar, IconButton, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Permissions from '../../../library/Permissions'
import { LoadingPleaseWait } from '@supportworks/react-components'
import Api from '../../../library/Api'
import DrawerDebug from 'components/Reusable/DrawerDebug'
import H from 'library/helper'
import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel'

export const ProductEditor = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [rightPanelVariant, setRightPanelVariant] = useState(null)
  const [displaySettings, setDisplaySettings] = useState({})
  const [numericUnits, setNumericUnits] = useState([])
  const [product, setProduct] = useState({})
  const [flattenedProductCategories, setFlattenedProductCategories] = useState([])

  useEffect(() => {
    if (isLoading) {
      let flat = []
      if (props.productFeatures && props.productFeatures.product_categories) {
        flat = flattenProductCategories(props.productFeatures.product_categories, [])
        setFlattenedProductCategories(flat)
      }

      // Miscellenaous bad data fixes.
      // Fix min_price
      if (!props.product.pricingOptions || (props.product.pricingOptions && Array.isArray(props.product.pricingOptions))) {
        props.product.pricingOptions = {}
      }
      const po = props.product.pricingOptions
      if (!po.min_price) {
        po.min_price = 0
      }
      props.product.pricingOptions = po

      // Fix orphan categories
      if (props.product.categories) {
        let x = props.product.categories.length
        while (x--) {
          const id = props.product.categories[x]
          if (!flat.find((o) => o.id === id)) {
            if (props.product.categories_ovrd === true) {
              props.product.categories.splice(x, 1)
            } else {
              if (!props.productFeatures.product_categories) {
                props.productFeatures.product_categories = []
              } else {
                props.productFeatures.product_categories.push({ id, title: id, custom: true })
                handleProductFeaturesChange('productCategories', props.productFeatures.product_categories)
              }
            }
          }
        }
      }

      // Autofix units
      const p = JSON.parse(JSON.stringify(props.product))
      if (p.units === undefined) {
        p.units = 'each'
      }
      setProduct(p)

      Promise.all([
        Api.callRegisteredMethod('getLatestConfigByTag', { tag: 'displaySettings', startsWith: false }).then((c) => {
          if (c && c.contentJson) {
            const cj = JSON.parse(c.contentJson)
            setDisplaySettings(cj.productEditor)
          }
        }),
        Api.callRegisteredMethod('getLatestConfigByTag', { tag: 'numericUnits', startsWith: false }).then((c) => {
          if (c && c.contentJson) {
            setNumericUnits(JSON.parse(c.contentJson))
          } else {
            setNumericUnits([{ id: 'EACH', title: 'each' }])
          }
        })
      ]).then((results) => {
        setIsLoading(false)
      })
    }
  }, []) // eslint-disable-line
  useEffect(() => {
    setProduct(props.product)
  }, [props.product])

  const handleSave = async () => {
    if (!isSaving) {
      // Since they are dictionary type data, I chose to save productFeatures immediatelly
      setIsSaving(true)
      await H.saveConfig(props.organizationId, { tag: 'productFeatures', title: 'Features' }, props.productFeatures)
      props.onSave(product, flattenedProductCategories)
    }
  }

  /**
   * Forces an update of the dictionary stuff used on the left nav, when something is added on the right panel.
   */
  const handleProductFeaturesChange = (variant, json) => {
    if (variant === 'productCategories') {
      const flat = flattenProductCategories(json, [])
      setFlattenedProductCategories(flat)
    }
  }

  const handleClose = () => {
    props.onClose()
  }

  const getAncestors = (id) => {
    const pc = props.productFeatures.product_categories
    let res = null
    for (let x = 0; x < pc.length; x++) {
      res = findAncestors(pc[x], id)
    }
    return res
  }

  function findAncestors (obj, id) {
    if (obj.id === id) return [id]
    let result = []
    if (obj.subCategories) {
      for (const element of obj.subCategories) {
        result = result.concat(findAncestors(element, id))
      }
    }
    if (result.length > 0) result.push(obj.id)
    return result
  }

  if (isLoading) return <LoadingPleaseWait />

  let jsx
  try {
    jsx = (
      <>
        <Paper elevation={0} sx={{ p: 2, display: { xs: 'block', sm: 'none' } }}>
          <Grid container spacing={0}>
            For the best user experience, please use a larger resolution.
          </Grid>
          <Box sx={{ display: 'flex', ...styles.bottomNavigation }}>
            <Button id='close-editor' sx={{ mr: 2 }} onClick={props.onClose}>
              Close
            </Button>
          </Box>
        </Paper>
        <Paper elevation={0} sx={{ pt: 0, pb: 0, pl: 0, pr: 0, display: { xs: 'none', sm: 'block' } }}>
          <AppBar position='relative'>
            <Toolbar>
              <IconButton title='Previous' aria-label='Previous' sx={{ pl: 1, mr: 2 }} onClick={handleClose}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant='h6' component='div' sx={{ flexGrow: 2 }}>
                {props.variant === 'master' ? 'Master Product Editor' : 'Product Editor'}
              </Typography>
            </Toolbar>
          </AppBar>
          <Grid container spacing={0} style={{ height: 'calc(100vh - 65px)' }} wrap='nowrap'>
            <Grid item xs={4} style={styles.leftPanel}>
              <LeftPanel
                variant={props.variant}
                isAdding={props.isAdding}
                productFeatures={props.productFeatures}
                numericUnits={numericUnits}
                flattenedProductCategories={flattenedProductCategories}
                displaySettings={displaySettings}
                masterProduct={props.masterProduct}
                crmProducts={props.crmProducts || []}
                products={props.products}
                product={product}
                isCRM={props.isCRM}
                setProduct={setProduct}
                rightPanelVariant={rightPanelVariant}
                setRightPanelVariant={setRightPanelVariant}
                assetsConfig={props.assetsConfig}
              />
            </Grid>
            <Grid item xs={8} sx={{ mb: 8 }} style={styles.rightPanel}>
              <RightPanel
                variant={props.variant}
                productFeatures={props.productFeatures}
                assetsConfig={props.assetsConfig}
                numericUnits={numericUnits}
                flattenedProductCategories={flattenedProductCategories}
                displaySettings={displaySettings}
                crmProducts={props.crmProducts}
                products={props.products} // used for product inclusion in advanced features
                product={product}
                setProduct={setProduct}
                rightPanelVariant={rightPanelVariant}
                getAncestors={getAncestors}
                onProductFeaturesChange={handleProductFeaturesChange}
              />
            </Grid>
            {Permissions.hasRole('super_user')
              ? (
                <DrawerDebug
                  json={[
                    { name: 'DISPLAY SETTINGS', json: displaySettings },
                    { name: 'PRODUCT', json: product },
                    { name: 'MASTER', json: props.masterProduct }
                  ]}
                />
                )
              : null}
          </Grid>

          <Box sx={{ display: 'flex', ...styles.bottomNavigation }}>
            <Button id='close-editor' sx={{ mr: 2 }} variant='outlined' onClick={props.onClose}>
              Cancel
            </Button>
            <Button id='save-editor' disabled={!!isSaving} variant='contained' color='primary' sx={{ marginLeft: 'auto' }} onClick={handleSave}>
              {isSaving ? <LoadingPleaseWait className='loading-please-wait-button' /> : <>Done</>}
            </Button>
          </Box>
        </Paper>
      </>
    )
  } catch (err) {
    console.log(`ProductEditor error: ${err.stack}`)
    jsx = <div>Data is not available.</div>
  }
  return (
    <Dialog open onClose={props.onClose} fullScreen PaperProps={{ sx: { width: '100%', height: '100%' } }} scroll='paper'>
      {jsx}
    </Dialog>
  )
}

export const flattenProductCategories = (source, dest, parent) => {
  if (!source) return []
  for (let x = 0; x < source.length; x++) {
    if (source[x].subCategories) {
      dest.push({ id: source[x].id, title: source[x].title, parent })
      flattenProductCategories(source[x].subCategories, dest, source[x])
    } else {
      dest.push({ id: source[x].id, title: source[x].title })
    }
  }
  return dest
}

const styles = {
  bottomNavigation: {
    position: 'fixed',
    width: '100%',
    left: 0,
    bottom: 0,
    maxHeight: '69px',
    padding: '16px',
    borderTop: 1,
    borderColor: 'rgba(0,0,0,.16)',
    backgroundColor: 'white'
  },
  leftPanel: {
    width: '672px',
    maxHeight: '100vh',
    overflow: 'auto',
    WebkitUserSelect: 'none',
    resize: 'horizontal',
    marginBottom: 68
  },
  rightPanel: {
    maxHeight: '100vh',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,.04)',
    outline: '1px solid rgba(0,0,0,.08)',
    WebkitUserSelect: 'none'
  }
}
