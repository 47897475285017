import React, { useState, useEffect, useRef } from 'react'
import '../../../../css/forms.css'
import { Button, IconButton, Paper } from '@mui/material'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import TextInputBasic from 'screens/Config/Forms/Reusables/TextInputBasic'
import FieldTypeExpandMarker from 'screens/Config/Forms/Reusables/FieldTypeExpandMarker'
import FieldTypeAddLogicPanel from 'screens/Config/Forms/FieldTypeAddLogicPanel'
import AddLogic from '../AddLogic'
import RelatedFieldTypeEditor from '../Reusables/RelatedFieldTypeEditor'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

function SingleSelection (props) {
  const [options, setOptions] = useState(props.list)
  const [hasLogic, setHasLogic] = useState(false)
  const [optionValue, setOptionValue] = useState('')
  const [field, setField] = useState(props.field)
  const [optIndex, setOptIndex] = useState(null)
  const inputRefs = useRef([])
  const [tabItem, setTabItem] = useState(props.tabItem)
  // const [relatedLength, setRelatedLength] = useState(0)

  const changeHandlerVisible = (visible) => {
    props.onChangeVisible(visible)
  }

  const changeHandlerCRM = (crm) => {
    props.onChangeCRM(crm)
  }

  const changeHandlerUnits = (units) => {
    props.onChangeUnits(units)
  }

  const addOption = () => {
    setOptions(options => [...options, 'newOption'])
  }

  const changeHandler = (value, idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    const newOptionsArr = [...options]

    newOptionsArr[idx] = value
    setOptions(newOptionsArr)

    newField.list = newOptionsArr
    setField(newField)

    props.onChange(newField)
  }

  const keyPressHandler = (event, idx) => {
    if (event.key === 'Enter') {
      if (inputRefs.current[idx + 1]) {
        inputRefs.current[idx + 1].focus()
      } else {
        setOptions(options => [...options, 'newOption'])
      }
    }
  }

  const deleteHandler = (idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    const newOptionsArr = [...options]
    const optionDeleted = newOptionsArr[idx]

    newOptionsArr.splice(idx, 1)
    setOptions(newOptionsArr)
    setOptIndex(idx)

    newField.list = newOptionsArr
    if ('related' in newField) {
      const indexRelated = newField.related.findIndex((rel) => {
        return rel.value === optionDeleted
      })
      if (indexRelated !== -1) {
        newField.related.splice(indexRelated, 1)
        if (!newField.related.length) {
          delete newField.related
        }
      }
    }

    setField(newField)
    props.onChange(newField)
  }

  const handleMoveUp = (idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    const noptions = JSON.parse(JSON.stringify(options))
    if (idx > 0) {
      [noptions[idx], noptions[idx - 1]] = [noptions[idx - 1], noptions[idx]]
    }
    setOptions(noptions)
    newField.list = noptions
    setField(newField)

    props.onChange(newField)
  }

  const handleMoveDown = (idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    const noptions = JSON.parse(JSON.stringify(options))
    if (idx < options.length) {
      [noptions[idx], noptions[idx + 1]] = [noptions[idx + 1], noptions[idx]]
    }
    setOptions(noptions)
    newField.list = noptions
    setField(newField)

    props.onChange(newField)
  }

  const addLogic = (e) => {
    if ('list' in field && field.list.length > 0) {
      setHasLogic(true)
    }
  }

  const changeHandlerOption = (val) => {
    setOptionValue(val)
  }

  const askQuestionSingleSelection = () => {
    const newField = JSON.parse(JSON.stringify(props.field))

    if (!('related' in newField)) {
      newField.related = []
    }

    /* const existDupOption = newField.related.some(el => el.value === optionValue);
        if(!existDupOption) {
            newField['related'].push({
                id: props.guid(),
                value: optionValue,
                type: null
            });
        } */
    newField.related.push({
      id: props.guid(),
      value: optionValue,
      type: null
    })

    setField(newField)
    setTabItem(optionValue)
    props.askQuestion(newField)
  }

  const changeHandlerRelated = (related, idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    newField.related[idx] = related
    setField(newField)
    props.askQuestion(newField)
  }

  const deleteRelated = (idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))

    newField.related.splice(idx, 1)
    if (!newField.related.length) {
      delete newField.related
    }

    setField(newField)
    props.askQuestion(newField)
  }

  const changeRelatedOptionVal = (oldVal, newVal) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    if ('related' in newField) {
      // newField.related.map((rel, idx) => {
      for (let idx = 0; idx < newField.related.length; idx++) {
        if (newField.related[idx].value === oldVal) {
          newField.related[idx].value = newVal
        }
      }
      // )
      setField(newField)
      props.askQuestion(newField)
    }
  }

  const saveIssues = (withIssuesArr) => {
    const newField = JSON.parse(JSON.stringify(withIssuesArr))
    setField(newField)
    props.askQuestion(newField)
  }

  const saveSolutions = (solutions) => {
    props.onSaveSolutions(solutions)
  }

  const clickHandlerTab = (tab) => {
    setTabItem(tab)
  }

  const deleteHandlerQuestion = (field) => {
    props.askQuestion(field)
  }

  useEffect(() => {
    setField(props.field)

    if ('list' in props.field && props.field.list.length < 1) {
      setHasLogic(false)
    }
  }, [props.field])

  useEffect(() => {
    if ('solutions' in props.form) {
      // props.form.solutions.map((s, idx) => {
      for (let i = 0; i < props.form.solutions.length; i++) {
        if (props.form.solutions[i].fieldTypeId === props.field.id) {
          setHasLogic(true)
        }
      }
      // )
    }

    if ('related' in props.field || 'issues' in props.field) {
      setHasLogic(true)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (optIndex) {
      inputRefs.current.splice(optIndex, 1)
      inputRefs.current[optIndex - 1].focus()
    } else {
      if (inputRefs.current[inputRefs.current.length - 1]) {
        inputRefs.current[inputRefs.current.length - 1].focus()
      }
    }
    // eslint-disable-next-line
  }, [options.length])

  /* useEffect(() => {
    if (props.field.related && props.field.related.length > 0) {
      setRelatedLength(props.field.related.length)
      // setTabItem(props.field.related[props.field.related.length-1].value);
    }
  }, [props.field.related]) */

  /* useEffect( () => {
        if(props.field.related && props.field.related.length > 0) {
            if(props.field.related.length !== relatedLength) {
                //setTabItem(props.field.related[props.field.related.length-1].value);
            }
        }
    }, [props.field.related])
*/
  return (
    <>
      {
                props.expandMarker &&
                  <FieldTypeExpandMarker />
            }
      <div style={styles.logicPanel}>
        <FieldTypeAddLogicPanel
          {...props}
          field={props.field}
          onChangeVisible={(visible) => changeHandlerVisible(visible)}
          onChangeCRM={(crm) => changeHandlerCRM(crm)}
          onChangeUnits={(units) => changeHandlerUnits(units)}
          onAddLogic={(e) => addLogic(e)}
          crmFieldsConfig={props.crmFieldsConfig}
        />

        {
                    hasLogic &&
                      <AddLogic
                        {...props}
                        type='radio'
                        field={props.field}
                        onSelectChange={(val) => changeHandlerOption(val)}
                        askQuestionFieldType={() => askQuestionSingleSelection()}
                        onRelatedOptionValChange={(oldVal, newVal) => changeRelatedOptionVal(oldVal, newVal)}
                        onSaveIssues={(field) => saveIssues(field)}
                        onSaveSolutions={(solutions) => saveSolutions(solutions)}
                        form={props.form}
                        allFieldsSolutions={props.allFieldsSolutions}
                        onClickTab={(tab) => clickHandlerTab(tab)}
                        onDeleteQuestion={(field) => deleteHandlerQuestion(field)}
                        guid={props.guid}
                      />
                }

        {
                    options.map((item, idx) => {
                      return (
                        <div key={`radio-main-${idx}`}>
                          <div style={{ display: 'flex' }} key={`radio-opt-${idx}`}>
                            <FieldTypeExpandMarker />
                            <Paper elevation={0} square style={styles.singleSelectionPaper}>
                              <div style={styles.optionsMainDivIcon}>
                                <IconButton style={styles.addOptionIconBtn}>
                                  <RadioButtonUncheckedIcon style={styles.addOptionIcon} />
                                </IconButton>
                              </div>
                              <div style={styles.optionsMainDiv}>
                                <TextInputBasic
                                  className={`single-selection-text-${idx}`}
                                  id={`single-selection-text-${idx}`}
                                  placeholder='Untitled Option'
                                  inputValue={item !== 'newOption' ? item : ''}
                                  onChange={(value) => changeHandler(value, idx)}
                                  onKeyPress={(event) => keyPressHandler(event, idx)}
                                  ref={ref => inputRefs.current[idx] = (ref)} // eslint-disable-line
                                />
                              </div>
                              <div style={styles.optionsMainDivIcon}>
                                <IconButton onClick={() => deleteHandler(idx)}>
                                  <DeleteOutlineIcon />
                                </IconButton>
                                <IconButton
                                  disabled={!(idx < options.length - 1)}
                                  onClick={() => {
                                    handleMoveDown(idx)
                                  }}
                                >
                                  <ArrowDownwardIcon />
                                </IconButton>
                                <IconButton
                                  disabled={!(idx > 0)} onClick={() => {
                                    handleMoveUp(idx)
                                  }}
                                >
                                  <ArrowUpwardIcon />
                                </IconButton>
                              </div>
                            </Paper>
                          </div>
                          {
                                    'related' in field &&
                                    field.related.map((rel, idx) => {
                                      return (
                                        <div key={`related-${idx}`}>
                                          {
                                                    rel.value === item &&
                                                      <div
                                                        style={tabItem === item ? styles.relatedMain : styles.relatedMainHide}
                                                      >
                                                        <FieldTypeExpandMarker />
                                                        <RelatedFieldTypeEditor
                                                          {...props}
                                                          field={field.related[idx]}
                                                          onChange={(related) => changeHandlerRelated(related, idx)}
                                                          onDelete={() => deleteRelated(idx)}
                                                          guid={props.guid}
                                                          form={props.form}
                                                          onSaveSolutions={(solutions) => saveSolutions(solutions)}
                                                          allFieldsSolutions={props.allFieldsSolutions}
                                                          crmFieldsConfig={props.crmFieldsConfig}
                                                          numericUnitsConfig={props.numericUnitsConfig}
                                                          allFields={props.allFields}
                                                        />
                                                      </div>
                                                }
                                        </div>
                                      )
                                    })
                                }
                        </div>
                      )
                    })
                }

        <div style={{ display: 'flex' }}>
          <FieldTypeExpandMarker />
          <Paper elevation={0} square style={styles.addOptionPaper}>
            <Button
              className='add-option-single-selection-btn'
              style={styles.addOptionBtn}
              color='primary'
              variant='text'
              startIcon={<RadioButtonUncheckedIcon />}
              onClick={addOption}
            >
              Add Option
            </Button>
          </Paper>
        </div>
      </div>
    </>
  )
}

const styles = {
  relatedMain: {
    display: 'flex'
  },
  relatedMainHide: {
    display: 'none'
  },
  logicPanel: {
    // width: "100%"
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%'
  },
  optionsMainDiv: {
    display: 'flex',
    flexBasis: '100%'
  },
  optionsMainDivIcon: {
    marginLeft: '12px',
    whiteSpace: 'nowrap'
  },
  singleSelectionPaper: {
    borderTop: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    borderLeft: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    // marginRight: "96.19px",
    // width: "100%"
    height: '50px',
    flexBasis: '100%'
  },
  addOptionPaper: {
    borderTop: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    borderLeft: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    // marginRight: "96.19px",
    // width: "100%",
    height: '50px',
    flexBasis: '100%'
  },
  addOptionBtn: {
    textTransform: 'none',
    marginLeft: '16px',
    fontSize: '13px'
  },
  addOptionIconBtn: {
    padding: '8px'
  },
  addOptionIcon: {
    fontSize: '20px'
  }
}

export default SingleSelection
