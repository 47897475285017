import React from 'react'
import { IconButton } from '@mui/material'
import { styled } from '@mui/system'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

const LightTooltip = styled(Tooltip)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: 'linear-gradient(90deg, #708090 50%, white 50%)',
    boxShadow: theme.shadows[1],
    minWidth: 540,
    borderRadius: '4px'
  }
}))

export const HoverDialog = (props) => {
  return (
    <LightTooltip title={props.jsx} placement='right-start'>
      <IconButton>
        {props.icon}
      </IconButton>
    </LightTooltip>
  )
}
