import React, { useState, useEffect } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { Box, List as MuiList, ListItemButton, ListItemText, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useLocalStorage } from '@supportworks/react-hooks'
import { ApiBase } from '../library/Api'
import Company from 'screens/Company'
import { LoadingPleaseWait } from '@supportworks/react-components'
import Permissions from '../library/Permissions'

// prettier-ignore
const HeaderMenus = ({ organizationId }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [location, setLocation] = useState()
  const [toggles, setToggles] = useLocalStorage('toggles', {})
  const [currentScreen, setCurrentScreen] = useLocalStorage('currentScreen', '')

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLocation(window.location)
        if (window.location.pathname.match(/Dashboard/)) {
          setCurrentScreen('Dashboard')
        }
        setIsLoading(false)
      } catch (error) {
        console.error('Error:', error)
      }
    }
    fetchData()
  }, []) // eslint-disable-line

  const toggleSelect = (label) => {
    setCurrentScreen(label)
    // console.log("Selection set to " + label);
  }

  const handleListItemClick = (index) => {
    let value = toggles[index]
    value = !value
    // p/josh set all of them to false so we only have 1 open
    Object.keys(toggles).forEach(v => {
      toggles[v] = false
    })
    setToggles({ ...toggles, [index]: value })
  }

  let match = useRouteMatch('/Company/:organizationId')
  if (!match) {
    match = { params: {} }
  }

  const renderMainNav = (navLinks) => {
    return (
      <Box sx={{ mb: 3, width: '100%' }}>
        {navLinks
          .filter((c) => c != null)
          .map((l, index) => {
            if (!l.criteria || (l.criteria && l.criteria(l))) {
              if (l.children && l.children.length && l.children.filter((c) => c != null).length) {
                const navL1 = `nav-${index}`
                return (
                  <List
                    key={`List-${navL1}`}
                    sx={{ p: 0 }}
                    style={styles.sectionDivider}
                    component='nav'
                    aria-label='List'
                  >
                    <ListItemButton
                      id={navL1}
                      sx={{
                        pl: 2,
                        '& .MuiListItemButton-root:hover': {
                          backgroundColor: 'transparent'
                        }
                      }}
                      style={toggles[navL1] ? styles.sectionSelected : styles.section}
                      selected={toggles[navL1]}
                      onClick={() => handleListItemClick(navL1)}
                    >
                      <ListItemText style={styles.sectionText}>{l.label}</ListItemText>
                      {toggles[navL1] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </ListItemButton>

                    {toggles[navL1]
                      ? (
                        <>
                          {l.children
                            .filter((c) => c != null)
                            .map((c, index2) => {
                              const navL2 = `${navL1}-${index2}`
                              if (c.divider) {
                                return (
                                  <ListItemText
                                    key={`divider-${index2}`}
                                    sx={{ m: 0, pl: 2, pt: index2 === 0 ? 4 : 2, pb: 2 }}
                                    style={styles.subSection}
                                  >
                                    <Typography style={styles.subSectionText}>{c.label}</Typography>
                                  </ListItemText>
                                )
                              } else {
                                const resolved = ApiBase.resolvePath(c.pathname, { location, ...match.params })
                                return (
                                  <ListItemButton
                                    key={navL2}
                                    sx={{
                                      pl: 2,
                                      backgroundColor: currentScreen === c.label ? '#F2F2F2 !important' : 'white'
                                    }}
                                    selected={currentScreen === c.label}
                                    component={Link}
                                    to={resolved}
                                    onClick={() => toggleSelect(c.label)}
                                  >
                                    <ListItemText>
                                      <Typography style={styles.selectionText}>
                                        {currentScreen === c.label
                                          ? <strong>{c.label}</strong>
                                          : <>{c.label}</>}
                                      </Typography>
                                    </ListItemText>
                                  </ListItemButton>
                                )
                              }
                            })}
                        </>
                        )
                      : null}
                  </List>
                )
              } else {
                const resolved = ApiBase.resolvePath(l.pathname, { location, ...match.params })
                return (
                  <List key={`List-${l.label}`} component='nav' aria-label='child' sx={{ p: 0 }} style={styles.sectionDivider}>
                    <ListItemButton
                      key={`LIB-${l.label}`}
                      sx={{ pl: 2 }}
                      style={currentScreen === l.label ? styles.sectionSelected : styles.section}
                      selected={currentScreen === l.label}
                      component={Link}
                      to={resolved}
                      onClick={() => handleListItemClick(l.label)}
                    >
                      <ListItemText>
                        {l.label}
                      </ListItemText>
                    </ListItemButton>
                  </List>
                )
              }
            }
            return null
          })}
      </Box>
    )
  }

  if (isLoading) return <LoadingPleaseWait />

  const headerLinks = []

  // Everyone gets this.
  //
  headerLinks.push({ pathname: '/Company/:organizationId/Dashboard', label: 'Dashboard' })

  // Sales user gets these.
  //
  if (Permissions.companyIsSelected() && (Permissions.hasRole('client_user') || Permissions.hasRole('inside_sales')) && Permissions.hasPermission('sign_proposal') && !Permissions.hasRole('admin')) {
    const admin = [
      {
        pathname: '/Company/:organizationId/Config',
        label: 'Appointments',
        criteria: () => Company.isSelected(),
        children: [
          { pathname: '/Company/:organizationId/Proposals', label: 'Proposals', criteria: () => Company.isSelected() },
          { pathname: '/Company/:organizationId/ProposalsSigned', label: 'Signed Proposals', criteria: () => Company.isSelected() }
        ]
      }
    ]
    headerLinks.push(...admin)
  }

  if (Permissions.companyIsSelected() && (Permissions.hasRole('client_user') || Permissions.hasRole('inside_sales')) && !Permissions.hasPermission('sign_proposal') && !Permissions.hasRole('admin')) {
    const admin = [
      {
        pathname: '/Company/:organizationId/Config',
        label: 'Appointments',
        criteria: () => Company.isSelected(),
        children: [
          { pathname: '/Company/:organizationId/Proposals', label: 'Proposals', criteria: () => Company.isSelected() }
        ]
      }
    ]
    headerLinks.push(...admin)
  }

  // Admin gets these.
  //
  if (Permissions.companyIsSelected() && Permissions.hasRole('admin')) {
    const admin = [
      {
        pathname: '/Company/:organizationId/Config',
        label: 'Appointments',
        criteria: () => Company.isSelected(),
        children: [
          { pathname: '/Company/:organizationId/Proposals', label: 'Proposals', criteria: () => Company.isSelected() },
          { pathname: '/Company/:organizationId/ProposalsSigned', label: 'Signed Proposals', criteria: () => Company.isSelected() },
          { pathname: '/Company/:organizationId/Reports', label: 'Reporting', criteria: () => Company.isSelected() }
        ]
      },
      {
        pathname: '/Company/:organizationId/Config',
        label: 'Products',
        criteria: () => Company.isSelected(),
        children: [
          { pathname: '/Company/:organizationId/Configs?variant=products', label: 'Master List' },
          { pathname: '/Company/:organizationId/Configs?variant=pricing', label: 'Price Lists' },
          { pathname: '/Company/:organizationId/Configs?variant=productConfig', label: 'Product Groups' },
          { pathname: '/Company/:organizationId/Configs?variant=libraryProductMapping', label: 'Product Mapping', criteria: () => Company.isSelected() },
          { pathname: '/Company/:organizationId/Configs?variant=productFeatures', label: 'Features' }
        ]
      },
      {
        pathname: '/Company/:organizationId/Config',
        label: 'App Features',
        criteria: () => Company.isSelected(),
        children: [
          { pathname: '/Company/:organizationId/Configs/CompanyConfigSettings', label: 'General Settings' },
          { pathname: '/Company/:organizationId/Configs?variant=aboutus', label: 'About Us' },
          { pathname: '/Company/:organizationId/Configs?variant=contract.terms', label: 'Accounting Details' },
          { pathname: '/Company/:organizationId/Configs?variant=contract.dealer', label: 'Company Info' },
          { pathname: '/Company/:organizationId/Configs?variant=contract', label: 'Contract Details' },
          { pathname: '/Company/:organizationId/Configs?variant=distributionList', label: 'Distribution List' },
          { pathname: '/Company/:organizationId/Configs?variant=documentEditor', label: 'Document Packets' },
          { pathname: '/Company/:organizationId/Configs?variant=forms2', label: 'Forms' },
          { pathname: '/Company/:organizationId/Configs?variant=appointment_type', label: 'Meetings' },
          { pathname: '/Company/:organizationId/Configs?variant=presentations', label: 'Presentations' },
          { pathname: '/Company/:organizationId/Configs?variant=solutions', label: 'Solutions' }
        ]
      },
      {
        pathname: '/Company/:organizationId/Config',
        label: 'App Configuration',
        criteria: () => Company.isSelected(),
        children: [
          { pathname: '/Company/:organizationId/Configs?variant=__complete', label: 'Build' },
          { pathname: '/Company/:organizationId/Configs?variant=__differentiate', label: 'Assign' },
          { pathname: '/Company/:organizationId/Configs/Publish', label: 'Publish' }
        ]
      },
      /*
        { pathname: '/Company/:organizationId/Config', label: 'Assets', criteria: () => Company.isSelected(), children: [] },
      */
      {
        pathname: '/Company/:organizationId/Config',
        label: 'Company',
        criteria: () => Company.isSelected(),
        children: [
          { pathname: '/Company/:organizationId/Configs?variant=assets2', label: 'Assets' },
          { pathname: '/Company/:organizationId/Configs?variant=billingDetails', label: 'Billing', criteria: () => Company.isSelected() },
          { pathname: '/Company/:organizationId/Configs?variant=crmFields', label: 'CRM Fields' },
          { pathname: '/Company/:organizationId/Configs?variant=settingsCustomerPortal', label: 'Customer Portal Setup', criteria: () => Company.isSelected() },
          { pathname: '/Company/:organizationId/Configs?variant=pptLibrary', label: 'Drawing Tool Setup' },
          { pathname: '/Company/:organizationId/Integrations', label: 'Integrations', criteria: () => Company.isSelected() },
          { pathname: '/Company/:organizationId/Configs?variant=referrals', label: 'Referral Setup' },
          Permissions.hasPermission('add_users') ? { pathname: '/Company/:organizationId/Users', label: 'Users', criteria: () => Company.isSelected() } : []
        ]
      }
    ]
    if (Permissions.hasPermission('edit_config')) {
      headerLinks.push(...admin)
    } else {
      // If I don't have edit_config I can't do anything except look at these.
      headerLinks.push(
        {
          pathname: '/Company/:organizationId/Config',
          label: 'Company',
          criteria: () => Company.isSelected(),
          children: [
            { pathname: '/Company/:organizationId/Proposals', label: 'Proposals', criteria: () => Company.isSelected() }
          ]
        }
      )
    }
  }

  // Only super_user gets these.
  //
  if (Permissions.companyIsSelected() && Permissions.hasRole('super_user')) {
    const superAdmin = [
      {
        pathname: '/Company/:organizationId/Config',
        label: 'Super Admin',
        criteria: () => Company.isSelected(),
        children: [
          { pathname: '/Company/:organizationId/Impersonate', label: 'Impersonate Users', criteria: () => Company.isSelected() },
          { divider: true, label: 'Configurators' },
          { pathname: '/Company/:organizationId/Configs?variant=__root', label: 'Default Configuration' },
          { pathname: '/Company/:organizationId/Configs?variant=__current', label: 'Current Configuration' },
          { pathname: '/Company/:organizationId/Configs?variant=crm_config', label: 'CRM Configuration' },
          { divider: true, label: 'Current Projects' },
          { divider: true, label: 'Future Projects' },
          { pathname: '/Company/:organizationId/Appointments', label: 'Appointments', criteria: () => Company.isSelected() },
          { pathname: '/Company/:organizationId/Configs?variant=__new_root', label: 'Branches' },
          { pathname: '/Company/:organizationId/ReportsWIP', label: 'Reporting', criteria: () => Company.isSelected() },
          { pathname: '/Company/:organizationId/Configs?variant=resources', label: 'Resources' }
        ]
      }
    ]
    headerLinks.push(...superAdmin)
  }
  return renderMainNav(headerLinks)
}
export default HeaderMenus

const List = styled(MuiList)({
  // selected and (selected + hover) states
  '&& .Mui-selected, && .Mui-selected:hover': {
    '&, & .MuiListItemIcon-root': {
      color: '#F2F2F2'
    }
  },
  // hover states
  '& .MuiListItemButton-root:hover': {
    backgroundColor: '#FAFAFA',
    color: 'black',
    '&, & .MuiListItemIcon-root': {
      color: '#F2F2F2'
    }
  }
})

const styles = {
  section: {
    backgroundColor: 'transparent'
  },
  sectionSelected: {
    backgroundColor: 'rgba(255,255,255,.16) !important'
  },
  sectionDivider: {
    borderBottom: '1px solid rgba(255,255,255,.16)'
  },
  sectionText: {
    fontSize: 16
  },
  subSection: {
    backgroundColor: 'white',
    fontWeight: 'bold'
  },
  subSectionText: {
    color: 'black',
    fontWeight: 'bolder',
    fontSize: 12
  },
  selectionText: {
    color: 'black',
    fontSize: 14
  }
}
