import React from 'react'
import '../../../../css/forms.css'
import { Menu, MenuItem, Tooltip } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import IconButton from '@mui/material/IconButton'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import HelpIcon from '@mui/icons-material/Help'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

function OptionsDialogSection (props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const changeHandler = (action) => {
    setAnchorEl(null)
    props.onChange()
  }

  const addQuestion = () => {
    setAnchorEl(null)
    props.onAddQuestion()
  }

  return (
    <>
      <Tooltip title='Options' placement='top-start'>
        <IconButton
          className='section-icon'
          onClick={handleClick}
        >
          <MoreHorizIcon />
        </IconButton>
      </Tooltip>
      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem
          style={styles.sectionOptionsMenu}
          onClick={() => addQuestion()}
        >
          <span><AddCircleOutlineIcon /></span>
          Add Question
        </MenuItem>
        <MenuItem
          style={styles.sectionOptionsMenu}
          disabled
        >
          <span><HelpIcon /></span>
          Duplicate Section
        </MenuItem>
        <MenuItem
          style={styles.sectionOptionsMenu}
          onClick={() => changeHandler('delete')}
        >
          <span><DeleteOutlineIcon /></span>
          Delete Section
        </MenuItem>
      </Menu>
    </>
  )
}

const styles = {
  sectionOptionsMenu: {
    color: '#000000a3'
  }
}

export default OptionsDialogSection
