import { InputAdornment, Paper, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import React from 'react'

class Search extends React.Component {
  /*
   * Description of UserInput Class.
   *
   * @param {Object} props The props object... not sure this needs to be described
   * @param {string} props.inputLabel Label for the field
   * @param {string} props.labelClass CSS classes for Label
   * @param {string} [props.value] Current value for the input
   * @param {function} [props.onChange] Optional onChange callback
   * @param {string} [props.className=userInput] Base class name, if necessary
   * @param {string} [props.type=text] Input type.
   */
  constructor (props) {
    super(props)

    this.state = {
      value: this.props.value ? this.props.value : ''
    }
  }

  onChange (value) {
    if (this.props.onChange) {
      this.props.onChange(value)
    }
    this.setState({ value })
  }

  render () {
    return (
      <Paper elevation={0}>
        <TextField
          // className={this.props.className ? this.props.className : 'userSearch'}
          type='text'
          placeholder={this.props.placeholder ? this.props.placeholder : 'Search...'}
          name={this.props.name}
          value={this.state.value}
          onChange={(e) => this.onChange(e.target.value)}
          size='small'
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </Paper>
    )
  }
}

export default Search
