import React from 'react'
import { Alert, AlertTitle, Box, Paper, Typography } from '@mui/material'

const ErrorMessage = (props) => {
  return (
    <Paper elevation={0}>
      <Alert variant='filled' severity='error'>
        <AlertTitle>Error</AlertTitle>
        We apologize, an error has been encountered accessing this information.
      </Alert>
      <Box sx={{ p: 2 }}>
        <Typography variant='body1'>
          <strong>Message: </strong><br /><br />{props.description}
        </Typography>
      </Box>
    </Paper>
  )
}
export default ErrorMessage
