import React, { useEffect, useState } from 'react'
import FieldTypeExpandMarker from '../Reusables/FieldTypeExpandMarker'
import FieldTypeAddLogicPanel from '../FieldTypeAddLogicPanel'

function AddSolutions (props) {
  const [field, setField] = useState(props.field)
  const changeHandlerVisible = (visible) => {
    props.onChangeVisible(visible)
  }

  const changeHandlerSortByCategory = (val) => {
    const newField = JSON.parse(JSON.stringify(field))
    newField.sortByCategory = val
    setField(newField)
    props.onChange(newField)
  }

  const changeHandlerDisplayAll = (val) => {
    const newField = JSON.parse(JSON.stringify(field))
    newField.displayAllProjectSolutions = val
    setField(newField)
    props.onChange(newField)
  }

  useEffect(() => {
    const newField = JSON.parse(JSON.stringify(props.field))
    if (!('sortByCategory' in newField)) {
      newField.sortByCategory = true
    }
    if (!('displayAllProjectSolutions' in newField)) {
      newField.displayAllProjectSolutions = true
    }

    setField(newField)
    props.onChange(newField)
  }, [])

  return (
    <>
      {
                props.expandMarker &&
                  <FieldTypeExpandMarker />
            }
      <div style={styles.logicPanel}>
        <FieldTypeAddLogicPanel
          {...props}
          field={props.field}
          onChangeVisible={(visible) => changeHandlerVisible(visible)}
          onChangeSortByCategory={(val) => changeHandlerSortByCategory(val)}
          onChangeDisplayAll={(val) => changeHandlerDisplayAll(val)}
        />
      </div>
    </>
  )
}

const styles = {
  relatedMain: {
    display: 'flex'
  },
  logicPanel: {
    // width: "100%"
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%'
  }
}

export default AddSolutions
