import React, { useEffect, useState } from 'react'
import { Link, Menu, TextField, Button, MenuItem } from '@mui/material'
import Divider from '@mui/material/Divider'
import Dialog from '@mui/material/Dialog'

function CalculatorFuncDialog (props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [min, setMin] = useState('minMax' in props && 'min' in props.minMax ? props.minMax.min : '')
  const [max, setMax] = useState('minMax' in props && 'max' in props.minMax ? props.minMax.max : '')
  const [total, setTotal] = useState(!('minMax' in props && ('floor' in props.minMax || 'ceil' in props.minMax || 'round' in props.minMax)))
  const [floor, setFloor] = useState('minMax' in props && 'floor' in props.minMax ? props.minMax.floor : false)
  const [ceil, setCeil] = useState('minMax' in props && 'ceil' in props.minMax ? props.minMax.ceil : false)
  const [decimal, setDecimal] = useState(!!('minMax' in props && 'round' in props.minMax))
  const [itemTotal, setItemTotal] = useState(!('minMax' in props && ('floor' in props.minMax || 'ceil' in props.minMax || 'round' in props.minMax)))
  const [itemFloor, setItemFloor] = useState(!!('minMax' in props && 'floor' in props.minMax))
  const [itemCeil, setItemCeil] = useState(!!('minMax' in props && 'ceil' in props.minMax))
  // eslint-disable-next-line no-unused-vars
  const [itemDecimal, setItemDecimal] = useState(false)
  const open = Boolean(anchorEl)

  useEffect(() => {
    if ('minMax' in props && 'min' in props.minMax) {
      setMin(props.minMax.min)
    }

    if ('minMax' in props && 'max' in props.minMax) {
      setMax(props.minMax.max)
    }

    if ('floor' in props.minMax || 'ceil' in props.minMax || 'round' in props.minMax) {
      setTotal(false)
    } else {
      setTotal(true)
    }

    if ('minMax' in props && 'floor' in props.minMax) {
      setFloor(props.minMax.floor)
    } else {
      setFloor(false)
    }

    if ('minMax' in props && 'ceil' in props.minMax) {
      setCeil(props.minMax.ceil)
    } else {
      setCeil(false)
    }

    if ('minMax' in props && 'round' in props.minMax) {
      setDecimal(true)
    } else {
      setDecimal(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    if ('minMax' in props && 'max' in props.minMax) {
      setMax(props.minMax.max)
    } else {
      setMax('')
    }
    if ('minMax' in props && 'min' in props.minMax) {
      setMin(props.minMax.min)
    } else {
      setMin('')
    }
    setAnchorEl(null)
  }

  const cancelHandler = () => {
    if ('minMax' in props && 'max' in props.minMax) {
      setMax(props.minMax.max)
    } else {
      setMax('')
    }
    if ('minMax' in props && 'min' in props.minMax) {
      setMin(props.minMax.min)
    } else {
      setMin('')
    }
    setAnchorEl(null)
  }

  const saveHandlerMin = () => {
    props.onSave(min)
    setAnchorEl(null)
  }

  const changeHandlerMin = (event) => {
    setMin(event.target.value)
  }

  const saveHandlerMax = () => {
    props.onSave(max)
    setAnchorEl(null)
  }

  const changeHandlerMax = (event) => {
    setMax(event.target.value)
  }

  const saveHandlerDecimal = (val) => {
    props.onSave(val)
    setDecimal(true)
    setCeil(false)
    setFloor(false)
    setTotal(false)
    setItemCeil(false)
    setItemFloor(false)
    setItemTotal(false)
    setItemDecimal(true)
    setAnchorEl(null)
  }

  const changeHandlerTotal = () => {
    props.onChangeRound(true, 'total')
    setTotal(true)
    setFloor(false)
    setCeil(false)
    setDecimal(false)
    setItemTotal(true)
    setItemFloor(false)
    setItemCeil(false)
    setItemDecimal(false)
    setAnchorEl(null)
  }

  const changeHandlerFloor = () => {
    props.onChangeRound(true, 'floor')
    setFloor(true)
    setCeil(false)
    setTotal(false)
    setDecimal(false)
    setItemFloor(true)
    setItemCeil(false)
    setItemTotal(false)
    setItemDecimal(false)
    setAnchorEl(null)
  }

  const changeHandlerCeiling = () => {
    props.onChangeRound(true, 'ceil')
    setCeil(true)
    setFloor(false)
    setTotal(false)
    setDecimal(false)
    setItemCeil(true)
    setItemFloor(false)
    setItemTotal(false)
    setItemDecimal(false)
    setAnchorEl(null)
  }

  return (
    <>
      {props.type === 'min' && (
        <>
          <Link onClick={handleClick} underline='hover'>
            {min !== '' ? min : 'total'}
          </Link>

          <div>
            <Menu
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'center'
              }}
              PaperProps={{
                sx: {
                  width: '350px',
                  height: '190px',
                  padding: '20px'
                }
              }}
            >
              <span style={styles.header}>{props.header}</span>
              <span style={styles.subHeader}>{props.subHeader}</span>
              <TextField style={styles.textInput} id='filled-basic' size='small' variant='filled' onChange={changeHandlerMin} value={min} fullWidth />
              <div style={styles.buttons}>
                <Button style={styles.saveBtn} size='small' onClick={saveHandlerMin}>
                  Save
                </Button>
                <Button style={styles.cancelBtn} size='small' onClick={cancelHandler}>
                  Cancel
                </Button>
              </div>
            </Menu>
          </div>
        </>
      )}

      {props.type === 'max' && (
        <>
          <Link onClick={handleClick} underline='hover'>
            {max !== '' ? max : 'total'}
          </Link>

          <div>
            <Menu
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'center'
              }}
              PaperProps={{
                sx: {
                  width: '350px',
                  height: '190px',
                  padding: '20px'
                }
              }}
            >
              <span style={styles.header}>{props.header}</span>
              <span style={styles.subHeader}>{props.subHeader}</span>
              <TextField style={styles.textInput} id='filled-basic' size='small' variant='filled' onChange={changeHandlerMax} value={max} fullWidth />
              <div style={styles.buttons}>
                <Button style={styles.saveBtn} size='small' onClick={saveHandlerMax}>
                  Save
                </Button>
                <Button style={styles.cancelBtn} size='small' onClick={cancelHandler}>
                  Cancel
                </Button>
              </div>
            </Menu>
          </div>
        </>
      )}

      {props.type === 'round' && (
        <>
          <Link onClick={handleClick} underline='hover'>
            {total && 'total'}
            {decimal && props.minMax.round + ' decimal point'}
            {floor && 'floor (rounds down to the next whole number)'}
            {ceil && 'ceiling (rounds up to the next whole number)'}
          </Link>

          <div>
            <Menu
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'center'
              }}
              PaperProps={{
                sx: {
                  width: '350px',
                  height: '190px',
                  padding: '20px'
                }
              }}
            >
              <MenuItem selected={itemTotal} onClick={changeHandlerTotal}>
                total
              </MenuItem>
              <Divider />
              <MenuItemDialog minMax={props.minMax} onSave={(val) => saveHandlerDecimal(val)} />
              <MenuItem selected={itemFloor} onClick={changeHandlerFloor}>
                floor (rounds down to the next whole number)
              </MenuItem>
              <MenuItem selected={itemCeil} onClick={changeHandlerCeiling}>
                ceiling (rounds up to the next whole number)
              </MenuItem>
            </Menu>
          </div>
        </>
      )}
    </>
  )
}

const styles = {
  headerDiv: {
    marginBottom: '10px',
    fontWeight: '600',
    fontSize: '15px'
  },
  header: {
    fontSize: '15px',
    fontWeight: '800'
    // color: "#000000a3"
  },
  subHeader: {
    fontSize: '13px',
    color: '#000000a3',
    display: 'block',
    marginBottom: '6px'
  },
  textInput: {
    marginTop: '10px'
  },
  buttons: {
    marginTop: '15px'
  },
  cancelBtn: {
    float: 'right'
  },
  saveBtn: {
    float: 'right'
  }
}

export default CalculatorFuncDialog

function MenuItemDialog (props) {
  const [open, setOpen] = useState(false)
  const [decimal, setDecimal] = useState('minMax' in props && 'round' in props.minMax ? props.minMax.round : '2')
  const [itemDecimal, setItemDecimal] = useState(!!('minMax' in props && 'round' in props.minMax))

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const changeHandler = (event) => {
    setDecimal(event.target.value)
  }

  const saveHandler = () => {
    setOpen(false)
    setItemDecimal(true)
    props.onSave(decimal)
  }

  const cancelHandler = () => {
    setOpen(false)
  }

  return (
    <div>
      <MenuItem onClick={handleClickOpen} selected={itemDecimal}>
        decimal points
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: '350px',
            height: '215px',
            padding: '20px'
          }
        }}
      >
        <span style={styles.header}>Set Decimal Points</span>
        <span style={styles.subHeader}>The calculator will display number of decimal points as the total</span>
        <TextField style={styles.textInput} id='filled-basic' size='small' variant='filled' onChange={changeHandler} value={decimal} fullWidth />
        <div style={styles.buttons}>
          <Button style={styles.saveBtn} size='small' onClick={saveHandler}>
            Save
          </Button>
          <Button style={styles.cancelBtn} size='small' onClick={cancelHandler}>
            Cancel
          </Button>
        </div>
      </Dialog>
    </div>
  )
}
