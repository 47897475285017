import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, Box, Grid, Typography, Stack } from '@mui/material'
import { LoadingPleaseWait } from '@supportworks/react-components'
import AdminIcon from '@mui/icons-material/AdminPanelSettings'

export const DialogUpdatingProducts = props => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  if (isLoading) return <LoadingPleaseWait />

  const jsx = (
    <Box>
      <Box sx={{ pt: 2, pb: 2 }}>
        <Stack>
          <Typography sx={{ pb: 2 }}>We are syncing any assemblies on products.</Typography>
          <Typography>Please do NOT close your browser window. This should only take a few seconds.</Typography>
        </Stack>
      </Box>
      <Box sx={{ mt: 2, width: '100%' }}>
        <LoadingPleaseWait />
      </Box>
    </Box>
  )

  return (
    <Dialog open fullScreen={false} fullWidth maxWidth='sm'>
      <DialogTitle className='dialog-title' sx={{ pl: 0 }}>
        <Grid container alignItems='center' spacing={1} sx={{ display: 'flex', ml: 1 }}>
          <Grid item>
            <AdminIcon size='small' style={{ color: 'red' }} />
          </Grid>
          <Grid item>
            <Typography variant='h6'>Master List Sync</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ pl: 2, pr: 2, ml: 0, mt: 1 }}>{jsx}</DialogContent>
    </Dialog>
  )
}
