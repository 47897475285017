import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { LoadingPleaseWait } from '@supportworks/react-components'
import Api from 'library/Api'
import { DialogFormSelector } from 'components/Reusable/DialogFormSelector'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import { FormCardOverview } from './components/FormCardOverview'

const RightPanelOverview = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [showAddSystemTemplateDialog, setShowAddSystemTemplateDialog] = useState(false)
  const [list, setList] = useState([])
  const [formsConfig, setFormsConfig] = useState([])
  const [aboutUsConfig, setAboutUsConfig] = useState([])

  useEffect(() => {
    if (isLoading) {
      if (props.meeting.intro) {
        setList(props.meeting.intro)
      }
      if (props.formsConfig) {
        const l = props.formsConfig.filter((f) => f.formType.match(/overview/i))
        setFormsConfig(l)
      }
      // Load aboutUs from tag
      Api.callRegisteredMethod('getConfigListByTag', { tag: 'aboutus', startsWith: false }).then(async (clist) => {
        const aboutUsConfig = []
        if (clist && clist.length > 0) {
          for (let x = 0; x < clist.length; x++) {
            if (clist[x].status === 'Published') {
              await Api.callRegisteredMethod('getConfigById', { configId: clist[x].configId, organization: clist[x].organizationId }).then((c) => {
                // console.log(`async loop ${x}`);
                if (c && c.data && c.data[0] && c.data[0].contentJson) {
                  const config = JSON.parse(c.data[0].contentJson)
                  if (Array.isArray(config)) {
                    aboutUsConfig.push({ id: config[0].id, title: config[0].title })
                  } else if (aboutUsConfig.id !== undefined) {
                    aboutUsConfig.push({ id: aboutUsConfig.id, title: config.title })
                  }
                }
                // eslint-disable-next-line no-loop-func
              })
            }
          }
        }
        setIsLoading(false)
        setAboutUsConfig(aboutUsConfig)
      })
    }
  }, []) // eslint-disable-line

  const handleChange = (l) => {
    const m = JSON.parse(JSON.stringify(props.meeting))
    // DEV-3827 set type to formId because it breaks stuff.  Just goin with it.
    for (let x = 0; x < l.length; x++) {
      if (l[x].formId !== undefined) {
        l[x].type = l[x].formId
      } else if (l[x].type) {
        l[x].formId = l[x].type
      }
    }
    m.intro = l
    setList(l)
    props.setMeeting(m)
  }

  const handleDelete = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    l.splice(idx, 1)
    handleChange(l)
  }

  const handleMoveUp = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx - 1, 0, ele)
    handleChange(l)
  }

  const handleMoveDown = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx + 1, 0, ele)
    handleChange(l)
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Box style={styles.container} sx={{ p: 2 }}>
        <Stack sx={{ pb: 2 }}>
          <Typography variant='h6'>Overview Forms</Typography>
          <Typography variant='body2'>
            Add the default forms you want available at the beginning of appointments. Once you add forms, sort the forms to your preferred order.
          </Typography>
        </Stack>

        <>
          {list.map((form, idx) => {
            // console.log(form)
            if (form.type.match(/accomplishList|contact/)) {
              return (
                <FormCardSystem
                  key={`fau-${form.formId}-${idx}`}
                  form={form}
                  subTitle={form.title}
                  formsConfig={{ id: form.type, formTitle: 'System Template ' }}
                  onMoveUp={idx > 0 ? () => handleMoveUp(idx) : null}
                  onDelete={() => {
                    handleDelete(idx)
                  }}
                  onMoveDown={idx < list.length - 1 ? () => handleMoveDown(idx) : null}
                />
              )
            } else if (form.type === 'aboutUs') {
              return (
                <FormCardAboutUs
                  {...props}
                  key={`fau-${form.formId}-${idx}`}
                  form={form}
                  subTitle={form.title}
                  formsConfig={{ id: form.type, formTitle: 'System Template ' }}
                  aboutUsConfig={aboutUsConfig}
                  onMoveUp={idx > 0 ? () => handleMoveUp(idx) : null}
                  onDelete={() => {
                    handleDelete(idx)
                  }}
                  onMoveDown={idx < list.length - 1 ? () => handleMoveDown(idx) : null}
                />
              )
            } else {
              const fc = formsConfig.find((o) => o.id === form.formId)
              if (fc) {
                return (
                  <FormCardGeneric
                    key={`fcg-${fc.id}-${idx}`}
                    form={form}
                    formsConfig={fc}
                    onMoveUp={idx > 0 ? () => handleMoveUp(idx) : null}
                    onDelete={() => {
                      handleDelete(idx)
                    }}
                    onMoveDown={idx < list.length - 1 ? () => handleMoveDown(idx) : null}
                  />
                )
              }
              if (fc === undefined) {
                return (
                  <FormCardGeneric
                    key={`fcg-deleted-${form.formId}-${idx}`}
                    form={form}
                    formsConfig={form}
                    onMoveUp={idx > 0 ? () => handleMoveUp(idx) : null}
                    onDelete={() => {
                      handleDelete(idx)
                    }}
                    onMoveDown={idx < list.length - 1 ? () => handleMoveDown(idx) : null}
                    formStatus='undefined'
                  />
                )
              }
            }
            return null
          })}
        </>
        <Box>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setShowAddDialog(!showAddDialog)
            }}
          >
            Add Form
          </Button>
          <Button
            sx={{ ml: 1 }}
            variant='contained'
            color='primary'
            onClick={() => {
              setShowAddSystemTemplateDialog(!showAddSystemTemplateDialog)
            }}
          >
            Add System Template
          </Button>
        </Box>
      </Box>

      {showAddDialog
        ? (
          <DialogFormSelector
            title='Select Forms'
            list={list}
            formsConfig={formsConfig}
            onSave={(l) => {
              handleChange(l)
              setShowAddDialog(!showAddDialog)
            }}
            onClose={() => setShowAddDialog(!showAddDialog)}
            type='overview'
          />
          )
        : null}

      {showAddSystemTemplateDialog
        ? (
          <DialogSystemTemplate
            title='Select System Template'
            list={list}
            formsConfig={formsConfig}
            onSave={(l) => {
              handleChange(l)
              setShowAddSystemTemplateDialog(!showAddSystemTemplateDialog)
            }}
            onClose={() => setShowAddSystemTemplateDialog(!showAddSystemTemplateDialog)}
          />
          )
        : null}
    </>
  )
}
export default RightPanelOverview

export const DialogSystemTemplate = (props) => {
  const [isLoading, setIsLoading] = useState(true)

  const [aboutUs, setAboutUs] = useState(false)
  const [accomplishList, setAccomplishList] = useState(false)
  const [contact, setContact] = useState(false)

  useEffect(() => {
    if (isLoading) {
      let idx = props.list.findIndex((o) => o.type === 'aboutUs')
      if (idx > -1) setAboutUs(true)
      idx = props.list.findIndex((o) => o.type === 'accomplishList')
      if (idx > -1) setAccomplishList(true)
      idx = props.list.findIndex((o) => o.type === 'contact')
      if (idx > -1) setContact(true)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (e) => {
    const name = e.target.name
    if (name === 'aboutUs') {
      setAboutUs(!aboutUs)
    } else if (name === 'accomplishList') {
      setAccomplishList(!accomplishList)
    } else if (name === 'contact') {
      setContact(!contact)
    }
  }

  const handleSave = () => {
    const list = JSON.parse(JSON.stringify(props.list))
    let idx = list.findIndex((o) => o.type === 'aboutUs')
    if (idx === -1 && aboutUs) {
      list.push({
        title: 'About Us',
        type: 'aboutUs'
      })
    } else if (idx > -1 && !aboutUs) {
      list.splice(idx, 1)
    }
    idx = list.findIndex((o) => o.type === 'accomplishList')
    if (idx === -1 && accomplishList) {
      list.push({
        title: 'Accomplish List',
        type: 'accomplishList'
      })
    } else if (idx > -1 && !accomplishList) {
      list.splice(idx, 1)
    }
    idx = list.findIndex((o) => o.type === 'contact')
    if (idx === -1 && contact) {
      list.push({
        title: 'Contact Info',
        type: 'contact'
      })
    } else if (idx > -1 && !contact) {
      list.splice(idx, 1)
    }
    props.onSave(list)
  }

  if (isLoading) return <LoadingPleaseWait />

  const jsx = (
    <>
      <Grid container sx={{ pb: 2, borderBottom: '1px solid rgba(0,0,0,.08)' }}>
        <Grid item xs={12} sx={{ pt: 2 }}>
          <FormGroup>
            <FormControlLabel
              sx={{ m: 0, p: 0 }}
              style={styles.alignTop}
              control={<Checkbox sx={{ mr: 2, p: 0 }} id='aboutUs' name='aboutUs' checked={aboutUs} onClick={handleChange} />}
              label={
                <Stack style={styles.alignTop}>
                  <Typography sx={{ pl: 1 }} style={styles.title}>
                    About Us
                  </Typography>
                  <Typography sx={{ pl: 1, pt: 1 }}>Add this template and any About Us assigned in your configuration will appear in this place.</Typography>
                </Stack>
              }
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container sx={{ pb: 2, borderBottom: '1px solid rgba(0,0,0,.08)' }}>
        <Grid item xs={12} sx={{ pt: 2 }}>
          <FormGroup>
            <FormControlLabel
              sx={{ m: 0, p: 0 }}
              style={styles.alignTop}
              control={<Checkbox sx={{ mr: 2, p: 0 }} id='accomplishList' name='accomplishList' checked={accomplishList} onClick={handleChange} />}
              label={
                <Stack style={styles.alignTop}>
                  <Typography sx={{ pl: 1 }} style={styles.title}>
                    Accomplish List
                  </Typography>
                  <Typography sx={{ pl: 1, pt: 1 }}>Add this template and the accomplish list you have created will appear in this place.</Typography>
                </Stack>
              }
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container sx={{ pb: 2 }}>
        <Grid item xs={12} sx={{ pt: 2 }}>
          <FormGroup>
            <FormControlLabel
              sx={{ m: 0, p: 0 }}
              style={styles.alignTop}
              control={<Checkbox sx={{ mr: 2, p: 0 }} id='contact' name='contact' checked={contact} onClick={handleChange} />}
              label={
                <Stack style={styles.alignTop}>
                  <Typography sx={{ pl: 1 }} style={styles.title}>
                    Customer Information
                  </Typography>
                  <Typography sx={{ pl: 1, pt: 1 }}>Add this template and any customer information you have added will appear in this place.</Typography>
                </Stack>
              }
            />
          </FormGroup>
        </Grid>
      </Grid>
    </>
  )
  return <DialogGeneric title={props.title} content={jsx} fullWidth onChange={handleSave} onClose={props.onClose} />
}

export const FormCardGeneric = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [title, setTitle] = useState('')

  useEffect(() => {
    setTitle(props.form && props.form.title ? props.form.title : 'No title')
    setIsLoading(false)
  }, []) // eslint-disable-line

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'title') {
      props.form.title = value
      setTitle(value)
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  const jsx = (
    <Box sx={{ pt: 2, pl: 2, flex: 2 }}>
      <TextField fullWidth id='title' name='title' variant='outlined' label='Title' value={title} onChange={handleChange} />
    </Box>
  )

  return <FormCardOverview {...props} jsx={jsx} />
}

export const FormCardSystem = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [title, setTitle] = useState('')

  useEffect(() => {
    setTitle(props.form && props.form.title ? props.form.title : 'No title')
    setIsLoading(false)
  }, []) // eslint-disable-line

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'title') {
      props.form.title = value
      setTitle(value)
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  const jsx = (
    <Box sx={{ pt: 2, pl: 2, flex: 2 }}>
      <TextField fullWidth id='title' name='title' variant='outlined' label='Title' value={title} onChange={handleChange} />
    </Box>
  )

  return <FormCardOverview {...props} jsx={jsx} />
}

export const FormCardAboutUs = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [title, setTitle] = useState('')
  const [aboutUs, setAboutUs] = useState('')

  useEffect(() => {
    if (props.form) {
      setTitle(props.form.title ? props.form.title : 'No title')
      setAboutUs(props.form.aboutUsId ? props.form.aboutUsId : null)
    }
    setIsLoading(false)
  }, []) // eslint-disable-line

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'title') {
      props.form.title = value
      setTitle(value)
    } else if (name === 'aboutUs') {
      setAboutUs(value)
      props.form.aboutUsId = value
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  const jsx = (
    <Box sx={{ pt: 2, pl: 2, flex: 2 }}>
      <Grid container>
        <Grid item xs={8}>
          <TextField fullWidth id='title' name='title' variant='outlined' label='Title' value={title} onChange={handleChange} />
        </Grid>
        <Grid item xs={4} sx={{ pl: 2 }}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id='aboutUs'>{props.form.aboutUsId === undefined ? <>Select About Us</> : <>About Us</>}</InputLabel>
            <Select labelId='aboutUs' id='aboutUs' name='aboutUs' value={aboutUs || ''} label='About Us' onChange={handleChange}>
              {props.aboutUsConfig.map((o, idx) => {
                let title
                if (o.title) {
                  title = o.title
                }
                return (
                  <MenuItem key={`MenuItem-aboutUs-${idx}`} value={o.id}>
                    {title}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )

  return <FormCardOverview {...props} jsx={jsx} />
}

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'white'
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  alignTop: {
    display: 'flex',
    alignItems: 'flex-start'
  }
}
