import React, { useState, useEffect } from 'react'
import { Box, Grid, Typography, Dialog, DialogTitle, DialogContent, Button, TextField, InputAdornment, Menu, MenuItem } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import InitialIcon from '@mui/icons-material/HistoryEdu'
import CircleIcon from '@mui/icons-material/Circle'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SquareIcon from '@mui/icons-material/Square'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { findFieldNumber } from '../PDFDocumentEditor'
import { guid } from '@supportworks/helper'
import ColorPicker from 'components/Reusable/ColorPicker'

const FieldNavInitials = (props) => {
  const [showSelections, setShowSelections] = useState(false)

  const handleAdd = () => {
    const s = {
      id: `initial-group-${guid()}`,
      type: 'initials',
      title: 'New Initial',
      esignTag: '',
      esignColor: '#000000',
      page: props.pagenum || 1,
      additional: []
    }
    setShowSelections(true)
    props.document.fields.push(s)
    props.onChange('generic', props.document)
  }

  const selections = props.field.selections
  return (
    <>
      <Box>
        <Grid container spacing={0} sx={{ p: 1 }} style={styles.navContainer}>
          <Grid item sm={1} sx={{ pl: 1 }}>
            <InitialIcon />
          </Grid>
          <Grid item sm={selections.length > 0 ? 9 : 10} sx={{ pl: 1 }}>
            <Typography sx={{ pl: 1 }} style={styles.navText}>
              Initials
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={handleAdd}>
              <AddCircleOutlineIcon fontSize='small' />
            </IconButton>
          </Grid>
          {selections.length > 0
            ? (
              <Grid item xs={1}>
                <IconButton onClick={() => setShowSelections(!showSelections)}>{showSelections ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
              </Grid>
              )
            : null}
        </Grid>
      </Box>
      {showSelections
        ? (
          <>
            {selections.map((selection, idx) => {
              return (
                <div key={`FNG-${idx}`}>
                  <FieldNavInitialGroup idx={idx} selection={selection} {...props} />
                </div>
              )
            })}
          </>
          )
        : null}
    </>
  )
}
export default FieldNavInitials

const FieldNavInitialGroup = (props) => {
  const [showConfig, setShowConfig] = useState(false)
  const [showDialogEdit, setShowDialogEdit] = useState(false)

  const handleAdd = () => {
    const idx = props.document.fields.findIndex((o) => o.id === props.selection.id)
    if (idx > -1) {
      const selection = props.document.fields[idx]
      const a = {
        id: `initial-${guid()}`,
        type: 'initials',
        title: `Page ${props.pagenum}`,
        esignTag: '',
        esignColor: '#000000',
        page: props.pagenum || 1
      }
      selection.additional.push(a)
      props.document.fields[idx] = selection
      props.onChange('generic', props.document)
    }
  }

  const handleDelete = () => {
    if (window.confirm('There is no undo for deleting a initial.  Are you sure you want to delete?')) {
      const idx = props.document.fields.findIndex((o) => o.id === props.selection.id)
      props.document.fields.splice(idx, 1)
      props.onChange('generic', props.document)
    }
  }

  // The initial JSON has the first initial data at the beginning and each occurrence inside the additional array.
  // We want to list them all on the left nav, with the first/top being the main initial data that you can not delete.
  //
  const inits = []
  const firstInit = JSON.parse(JSON.stringify(props.selection))
  delete firstInit.additional
  inits.push(firstInit)
  for (let x = 0; x < props.selection.additional.length; x++) {
    inits.push(props.selection.additional[x])
  }
  return (
    <>
      <Box>
        <Grid container spacing={0} sx={{ p: 1, pl: 1 }} style={{ ...styles.navContainer, ...styles.navSelectionContainer }} wrap='nowrap'>
          <Grid item sm={1}>
            <InitialIcon fontSize='small' />
          </Grid>
          <Grid item sm={8}>
            <Typography sx={{ pl: 1, pr: 1 }} style={styles.navText}>
              {props.selection.title}
            </Typography>
          </Grid>
          <Grid item sm={1}>
            <IconButton
              onClick={() => {
                setShowDialogEdit(!showDialogEdit)
              }}
            >
              <EditIcon fontSize='small' />
            </IconButton>
          </Grid>
          <Grid item sm={1}>
            <IconButton onClick={handleDelete}>
              <RemoveCircleOutlineIcon fontSize='small' />
            </IconButton>
          </Grid>
          <Grid item sm={1}>
            <IconButton onClick={() => setShowConfig(!showConfig)}>{showConfig ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
          </Grid>
        </Grid>
      </Box>
      {showConfig
        ? (
          <>
            <Box style={styles.navSelectionContainer}>
              <Grid container spacing={0} sx={{ p: 1 }} wrap='nowrap'>
                <Grid item sm={11} sx={{ p: 1 }}>
                  <Typography style={styles.navText}>Initials</Typography>
                </Grid>
                <Grid item sm={1}>
                  <IconButton onClick={handleAdd}>
                    <AddCircleOutlineIcon fontSize='small' />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ pb: 1 }} style={styles.navSelectionContainer}>
              {inits.map((init, idx2) => {
                return (
                  <div key={`FNI-${idx2}`}>
                    <FieldNavInitial idx={props.idx} idx2={idx2} selection={init} {...props} />
                  </div>
                )
              })}
            </Box>
          </>
          )
        : null}
      {showDialogEdit ? <DialogInitialGroupEdit onClose={() => setShowDialogEdit(!showDialogEdit)} selection={props.selection} {...props} /> : null}
    </>
  )
}

const DialogInitialGroupEdit = (props) => {
  const [title, setTitle] = useState(null)
  const [field, setField] = useState(null)

  useEffect(() => {
    setTitle(props.selection.title)
    setField(props.selection)
    // eslint-disable-next-line
  }, [])

  const handleChange = (e) => {
    setTitle(e.target.value)
    const f = props.selection
    f.title = e.target.value
    setField(f)
  }

  const handleSave = () => {
    props.onChange('field', field)
    props.onClose()
  }

  return (
    <Dialog onClose={props.onClose} open fullWidth maxWidth='md'>
      <DialogTitle className='dialog-title'>
        Edit Title
        <IconButton
          className='dialog-close'
          aria-label='close'
          onClick={() => {
            props.onClose()
          }}
          sx={{ position: 'absolute', right: 12, top: 12 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className='dialog-content' style={{ paddingTop: 8 }}>
        <Box>
          <TextField
            fullWidth
            required
            id='selection-title'
            variant='outlined'
            label='Title'
            helperText='Please enter the title.'
            defaultValue={title}
            onChange={handleChange}
            sx={{
              'input:focus': {
                border: 'none'
              }
            }}
          />
        </Box>
      </DialogContent>
      <Box style={styles.bottomNavigation}>
        <Button
          id='cancel'
          variant='outlined'
          color='primary'
          sx={{ mr: 2 }}
          onClick={() => {
            props.onClose()
          }}
        >
          Close
        </Button>
        <Button id='save' variant='contained' color='primary' sx={{ marginLeft: 'auto' }} onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Dialog>
  )
}

const FieldNavInitial = (props) => {
  const [isDragging, setIsDragging] = useState(false)
  const [showDialogEdit, setShowDialogEdit] = useState(false)

  const handleDelete = (idx) => {
    if (window.confirm('There is no undo for deleting a initial.  Are you sure you want to delete?')) {
      const mainIdx = props.document.fields.findIndex((o) => o.id === props.selection.id)
      const selection = props.document.fields[mainIdx]
      if (idx === 0) {
        delete selection.x
        delete selection.y
      } else {
        selection.additional.splice(idx - 1, 1)
      }
      props.onChange('generic', props.document)
    }
  }

  const handleEnableDate = (idx) => {
    const mainIdx = props.document.fields.findIndex((o) => o.id === props.selection.id)
    let f = props.document.fields[mainIdx]
    if (idx > 0) {
      f = props.selection.additional[idx - 1]
    }
    f.date = {
      id: guid(),
      esignTag: '',
      esignColor: '#000000',
      x: f.x + 16,
      y: f.y - f.height - 8,
      width: 96,
      height: 24
    }

    if (idx === 0) {
      props.onChange('field', f)
    } else {
      props.selection.additional[idx - 1] = f
      props.onChange('field', props.selection)
    }

    // props.onChange("generic", props.document);
  }

  const handleDisableDate = (idx) => {
    // if (window.confirm(`There is no undo for this, you will be deleting date config.  Are you sure you want to delete?`)) {
    const mainIdx = props.document.fields.findIndex((o) => o.id === props.selection.id)
    const f = props.document.fields[mainIdx]
    if (idx === 0) {
      delete f.date
    } else {
      delete f.additional[idx - 1].date
    }
    props.onChange('generic', props.document)
    // }
  }

  const idx = props.idx
  const idx2 = props.idx2
  const sel = props.selection

  let selected = false
  let number = -1
  let subtitle = `Page ${sel.page}`
  let hasDate = false
  if (idx2 === 0) {
    number = findFieldNumber(props.document, props.field.selections[idx], -99) // -99 signifies the first init
    if (props.selection.id === props.selectedField.id) {
      if (props.fieldSubIndex === -99) {
        selected = true
      }
    }
    if (sel.date) {
      hasDate = true
    }
  } else {
    number = findFieldNumber(props.document, props.field.selections[idx], idx2 - 1) // -1 to get the correct additional:[] index
    subtitle = `Page ${sel.additional[idx2 - 1].page}`
    if (props.fieldSubIndex === idx2 - 1) selected = true
    if (sel.additional[idx2 - 1].date) {
      hasDate = true
    }
  }
  return (
    <>
      <Grid
        container
        spacing={0}
        sx={{ pl: 2 }}
        onClick={() => {
          let weirdIdx // #notproud
          if (idx2 === 0) {
            weirdIdx = -99
          } else {
            weirdIdx = idx2 - 1
          }
          props.onFieldSelect(props.field.selections[idx], weirdIdx)
        }}
      >
        <Grid item sm={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderLeft: '4px solid black' }}>
          <Typography style={styles.navText}>{idx2 + 1}</Typography>
        </Grid>
        <Grid
          item
          sm={7}
          sx={{ p: 1 }}
          style={isDragging ? styles.fieldDragging : styles.field}
          draggable
          onDragStart={(e) => {
            setIsDragging(true)
            if (idx2 === 0) {
              sel.page = props.pagenum
            } else {
              sel.additional[idx2 - 1].page = props.pagenum
              sel.additional[idx2 - 1].title = sel.title
            }
            props.onDragStart({ formId: props.formId, type: 'initials', field: sel, fieldValue: idx2 })
          }}
          onDragEnd={() => {
            setIsDragging(false)
            props.onDragEnd()
          }}
          onDragExit={props.onDragExit}
        >
          <div style={styles.navText}>&nbsp;{subtitle}&nbsp;</div>
        </Grid>
        <Grid item sm={1} sx={{ ml: 2 }}>
          {number > -1
            ? (
              <>
                {hasDate
                  ? (
                    <IconButton onClick={() => { handleDisableDate(idx2) }}>
                      <CalendarMonthIcon fontSize='small' style={{ color: 'black' }} />
                    </IconButton>
                    )
                  : (
                    <IconButton onClick={() => { handleEnableDate(idx2) }}>
                      <CalendarMonthIcon fontSize='small' style={{ color: 'grey' }} />
                    </IconButton>
                    )}
              </>
              )
            : null}
        </Grid>
        <Grid item sm={1}>
          {number > -1
            ? (
              <IconButton>
                <CircleIcon fontSize='small' style={selected ? { color: 'blue' } : { color: 'black' }} />
                <Typography style={styles.iconText}>{number}</Typography>
              </IconButton>
              )
            : null}
        </Grid>
        <Grid item sm={1}>
          <FieldActionInitial
            {...props}
            hasDate={hasDate}
            onEdit={() => setShowDialogEdit(!showDialogEdit)}
            onDelete={() => {
              handleDelete(idx2)
            }}
            onEnableDate={() => {
              handleEnableDate(idx2)
            }}
            onDisableDate={() => {
              handleDisableDate(idx2)
            }}
          />
        </Grid>
      </Grid>
      {showDialogEdit ? <DialogEditInitial onClose={() => setShowDialogEdit(!showDialogEdit)} {...props} /> : null}
    </>
  )
}

const DialogEditInitial = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [showInitColorPicker, setShowInitColorPicker] = useState(false)
  const [showDateColorPicker, setShowDateColorPicker] = useState(false)

  const [field, setField] = useState()
  const [initTag, setInitTag] = useState(null)
  const [initColor, setInitColor] = useState('#000000')
  const [dateTag, setDateTag] = useState(null)
  const [dateColor, setDateColor] = useState('#000000')

  // Used to set the correct section on the LeftPanel when a field is clicked on in the CenterCanvas
  useEffect(() => {
    if (isLoading) {
      let f = props.selection
      if (props.idx2 > 0) {
        f = props.selection.additional[props.idx2 - 1]
      }
      setField(f)
      setInitTag(f.esignTag)
      setInitColor(f.esignColor)
      if (f.date) {
        setDateTag(f.date.esignTag)
        setDateColor(f.date.esignColor)
      }
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (field && field.id) {
      let f = field
      if (props.idx2 > 0) {
        f = props.selection.additional[props.idx2 - 1]
      }
      f.esignTag = initTag
      f.esignColor = initColor
      if (f.date) {
        f.date.esignTag = dateTag
        f.date.esignColor = dateColor
      } else {
        if (dateColor || dateTag) {
          f.date = {
            guid: guid(),
            esignTag: dateTag,
            esignColor: dateColor
          }
        }
      }
      if (f.x && (!f.date.x || !f.date.y)) {
        f.date.x = f.x + 16
        f.date.y = f.y - f.height - 8
        f.date.width = 96
        f.date.height = 24
      }
      setField(f)
    }
    // eslint-disable-next-line
  }, [initColor, dateColor, initTag, dateTag])

  const handleSave = () => {
    if (props.idx2 === 0) {
      props.onChange('field', field)
    } else {
      props.selection.additional[props.idx2 - 1] = field
      props.onChange('field', props.selection)
    }
    props.onClose()
  }

  if (isLoading) return <></>

  return (
    <Dialog onClose={props.onClose} open fullWidth maxWidth='md'>
      <DialogTitle className='dialog-title'>
        Modify Initial Tags
        <IconButton
          className='dialog-close'
          aria-label='close'
          onClick={() => {
            props.onClose()
          }}
          sx={{ position: 'absolute', right: 12, top: 12 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className='dialog-content' style={{ paddingTop: 8 }}>
        <p>Initial</p>
        <Grid container>
          <Grid item sm={6} sx={{ pb: 2 }}>
            <TextField
              required
              id='init-tag'
              name='esignTag'
              variant='outlined'
              label='Initial eTag'
              helperText='Please enter the tag, example: $CSig.'
              defaultValue={initTag}
              onChange={(e) => {
                setInitTag(e.target.value)
              }}
              sx={{
                'input:focus': {
                  border: 'none'
                },
                pr: 1
              }}
            />
          </Grid>
          <Grid item sm={6}>
            {showInitColorPicker
              ? (
                <ColorPicker
                  color={initColor}
                  onChange={(color) => {
                    setShowInitColorPicker(!showInitColorPicker)
                    if (color) setInitColor(color)
                  }}
                  onClose={() => setShowInitColorPicker(!showInitColorPicker)}
                />
                )
              : (
                <TextField
                  required
                  id='init-color'
                  name='esignColor'
                  variant='outlined'
                  label='Initial color'
                  helperText='Pick the color of the initial.'
                  defaultValue={initColor}
                  onClick={() => setShowInitColorPicker(!showInitColorPicker)}
                  sx={{
                    'input:focus': {
                      border: 'none'
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <IconButton sx={{ width: 24, height: 24, border: 1, borderColor: 'black', borderRadius: 0, backgroundColor: initColor }}>
                          <SquareIcon sx={{ color: initColor }} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                )}
          </Grid>
        </Grid>
        <p>Date</p>
        <Grid container>
          <Grid item sm={6} sx={{ pb: 2 }}>
            <TextField
              required
              id='date-tag'
              name='esignTag'
              variant='outlined'
              label='Date eTag'
              helperText='Please enter the tag, example: $CDate.'
              defaultValue={dateTag}
              onChange={(e) => {
                setDateTag(e.target.value)
              }}
              sx={{
                'input:focus': {
                  border: 'none'
                },
                pr: 1
              }}
            />
          </Grid>
          <Grid item sm={6}>
            {showDateColorPicker
              ? (
                <ColorPicker
                  color={initColor}
                  onChange={(color) => {
                    setShowDateColorPicker(!showDateColorPicker)
                    if (color) setDateColor(color)
                  }}
                  onClose={() => setShowDateColorPicker(!showDateColorPicker)}
                />
                )
              : (
                <TextField
                  required
                  id='date-color'
                  name='esignColor'
                  variant='outlined'
                  label='Date color'
                  helperText='Pick the color of the date.'
                  defaultValue={dateColor}
                  onClick={() => setShowDateColorPicker(!showDateColorPicker)}
                  sx={{
                    'input:focus': {
                      border: 'none'
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <IconButton sx={{ width: 24, height: 24, border: 1, borderColor: 'black', borderRadius: 0, backgroundColor: dateColor }}>
                          <SquareIcon sx={{ color: dateColor }} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                )}
          </Grid>
        </Grid>
      </DialogContent>
      <Box style={styles.bottomNavigation}>
        <Button
          id='cancel'
          variant='outlined'
          color='primary'
          sx={{ mr: 2 }}
          onClick={() => {
            props.onClose()
          }}
        >
          Close
        </Button>
        <Button id='save' variant='contained' color='primary' sx={{ marginLeft: 'auto' }} onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Dialog>
  )
}

const FieldActionInitial = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu id='action-menu' open={open} onClose={handleClose} anchorEl={anchorEl}>
        <MenuItem
          onClick={() => {
            props.onEdit()
            handleClose()
          }}
        >
          Modify eSign Tag
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onDelete()
            handleClose()
          }}
        >
          Remove Initial
        </MenuItem>
        {!props.hasDate
          ? (
            <MenuItem
              onClick={() => {
                props.onEnableDate()
                handleClose()
              }}
            >
              Enable Date
            </MenuItem>
            )
          : (
            <MenuItem
              onClick={() => {
                props.onDisableDate()
                handleClose()
              }}
            >
              Disable Date
            </MenuItem>
            )}
      </Menu>
    </>
  )
}

const styles = {
  navContainer: {
    alignItems: 'center',
    userSelect: 'none',
    WebkitUserSelect: 'none'
  },
  navSelectionContainer: {
    backgroundColor: 'rgba(0,0,0,.08)'
  },
  navText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 14,
    userSelect: 'none'
  },
  bottomNavigation: {
    display: 'flex',
    width: '100%',
    padding: '16px',
    borderTop: 1,
    borderColor: 'rgba(0,0,0,.16)',
    backgroundColor: 'white'
  },
  borderBottom: {
    borderBottom: 1,
    borderColor: 'rgba(0,0,0,.16)'
  },
  fieldNumber: {
    fontSize: 8
  },
  iconText: {
    position: 'absolute',
    fontSize: 8,
    fontWidth: 'bold',
    fontFamily: 'sans-serif',
    color: 'white'
  },
  field: {
    cursor: 'pointer',
    border: '1px dotted rgba(0,0,0,.16)'
  },
  fieldDragging: {
    border: '1px dashed rgba(0,0,0,.24)'
  }
}
