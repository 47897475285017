import React, { useState, useEffect } from 'react'
import { Box, Button, Menu, MenuItem } from '@mui/material'
import SolutionIcon from '@mui/icons-material/VerifiedUser'
import HelpIcon from '@mui/icons-material/Help'
import DrawerIssueSelect from '../DrawerIssueSelect'
import DrawerSolutionSelect from '../DrawerSolutionSelect'

const TriggerMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const [showDrawerIssueSelect, setShowDrawerIssueSelect] = useState(false)
  const [showDrawerSolutionSelect, setShowDrawerSolutionSelect] = useState(false)

  /* const toggleDrawerIssueSelect = () => {
    setShowDrawerIssueSelect(!showDrawerIssueSelect)
  } */

  const toggleDrawerSolutionSelect = () => {
    setShowDrawerSolutionSelect(!showDrawerSolutionSelect)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setShowDrawerSolutionSelect(false)
    setShowDrawerIssueSelect(false)
    props.onExitSolutionAutomation()
    props.onExitIssuesAutomation()
  }

  const handleSaveIssues = (dataArr) => {
    // alert(`TODO - handleSave() - Connect forms to TriggerMenu for saving solutions.`);
    props.onSaveIssues(dataArr)
    setAnchorEl(null)
    setShowDrawerIssueSelect(false)
  }

  const handleSaveSolutions = (solution) => {
    props.onSaveSolution(solution)
    // console.log(dataArr);
    setAnchorEl(null)
    setShowDrawerSolutionSelect(false)
  }

  useEffect(() => {
    if (Object.keys(props.currSolution).length !== 0) {
      setShowDrawerSolutionSelect(!showDrawerSolutionSelect)
    }
  }, [props.currSolution]) // eslint-disable-line

  useEffect(() => {
    if (Object.keys(props.currIssues).length !== 0) {
      setShowDrawerIssueSelect(!showDrawerIssueSelect)
    }
  }, [props.currIssues]) // eslint-disable-line

  if (showDrawerSolutionSelect) {
    return (
      <DrawerSolutionSelect
        {...props}
        open={showDrawerSolutionSelect}
        onSave={(solution) => handleSaveSolutions(solution)}
        onClose={handleClose}
        form={props.form}
        currSolution={props.currSolution}
        currFieldId={props.field.id}
        allFieldsSolutions={props.allFieldsSolutions}
        field={props.field}
        guid={props.guid}
      />
    )
  }

  if (showDrawerIssueSelect) {
    return (
      <DrawerIssueSelect
        {...props}
        field={props.field}
        open={showDrawerIssueSelect}
        onSave={(issues) => handleSaveIssues(issues)}
        onClose={handleClose}
        currOption={props.currOption}
        currIssues={props.currIssues}
        allFieldsSolutions={props.allFieldsSolutions}
      />
    )
  }

  const askQuestion = () => {
    setAnchorEl(null)
    props.askQuestionAddLogic()
  }

  return (
    <>
      <Box sx={{ pl: 1 }}>
        <Button
          style={styles.linkDiv}
          color='primary'
          variant='text'
          onClick={handleClick}
        >
          + trigger
        </Button>
      </Box>
      <Menu
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          handleClose()
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box sx={{ p: 0, m: 0 }}>
          <MenuItem sx={{ p: 1, m: 0 }} onClick={askQuestion}>
            <HelpIcon sx={{ mr: 1 }} style={styles.question} />
            Ask Question
          </MenuItem>
          <MenuItem sx={{ p: 1, m: 0 }} onClick={toggleDrawerSolutionSelect}>
            <SolutionIcon sx={{ mr: 1 }} style={styles.solution} />
            Add Solution
          </MenuItem>
        </Box>
      </Menu>
    </>
  )
}

const styles = {
  question: {
    color: 'rgba(0,0,0,0.6)'
  },
  issue: {
    color: '#FB393E'
  },
  solution: {
    color: '#1871E7'
  },
  triggerContainer: {
    paddingLeft: '0px',
    border: '1px solid rgba(0,0,0,0.12)'
  }
}

export default TriggerMenu
