import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { DataGridPresentations } from 'components/Reusable/DataGridPresentations'

export const RightPanelPresentations = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [presentation, setPresentation] = useState([])

  useEffect(() => {
    if (isLoading) {
      if (props.solution.presentation) {
        setPresentation(props.solution.presentation || [])
      }
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, []) 

  const handleChange = ({ list }) => {
    const s = JSON.parse(JSON.stringify(props.solution))
    s.presentation = list
    props.setSolution(s)
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Box sx={{ p: 2, borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Typography style={styles.title}>{props.title || 'Presentation Setup'}</Typography>
      </Box>
      <Box style={styles.container} sx={{ pt: 2, pl: 2 }}>
        <Typography variant='body2'>
          Presentations added here will be available on the solution. Once you add presentations, sort the presentations to your preferred order.
        </Typography>
      </Box>
      <Box style={styles.container} sx={{ pt: 2, pl: 2, pr: 2 }}>
        <DataGridPresentations {...props} variant='top' list={presentation} onChange={handleChange} />
      </Box>
    </>
  )
}

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'white'
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  }
}
