import React, { useEffect, useState } from 'react'
import { Button, Divider, MenuItem, Menu } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

function DefaultDropdown (props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [options, setOptions] = useState([])
  const [defaultVal, setDefaultVal] = useState('default' in props.field ? props.field.default : 'n/a')

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const changeHandler = (value) => {
    setDefaultVal(value)
    props.onChange(value)
    setAnchorEl(null)
  }

  useEffect(() => {
    switch (props.field.type) {
      case 'boolean':
        setOptions(['Yes', 'No'])
        break
      case 'radio':
        if ('list' in props.field) {
          setOptions(props.field.list)
        }
        break
      case 'checkbox':
        if ('list' in props.field) {
          setOptions(props.field.list)
        }
        break
      case 'choice':
        if ('choices' in props.field) {
          const arr = []
          props.field?.choices?.forEach((item, idx) => {
            arr.push(item.title)
          })
          setOptions(arr)
        }
        break
      default:
        break
    }
  }, [props.field])

  return (
    <>
      <Button onClick={handleClick} variant='text' endIcon={<ArrowDropDownIcon />}>
        {defaultVal}
      </Button>
      <div>
        <Menu
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}
        >
          <MenuItem value='n/a' onClick={() => changeHandler('n/a')} selected={defaultVal === 'n/a'}>
            Not Applicable (n/a)
          </MenuItem>
          <Divider />
          {options.length > 0 &&
                        options.map((opt, idx) => {
                          return (
                            <div key={`opt-${idx}`}>
                              <MenuItem value={opt} onClick={() => changeHandler(opt)} selected={opt === defaultVal}>
                                {opt}
                              </MenuItem>
                            </div>
                          )
                        })}
        </Menu>
      </div>
    </>
  )
}

export default DefaultDropdown
