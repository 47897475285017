import React, { useState, useEffect } from 'react'
import { Box, Button, CardMedia, Grid, Stack, Typography } from '@mui/material'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import SelectorWithPreview from 'components/Reusable/SelectorWithPreview'
import { getAssetInfo } from 'screens/Config/CompanyConfigPresentations'

export const RightPanelDetailsPresentation = (props) => {
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [asset, setAsset] = useState({})
  const [type, setType] = useState('')
  const [assetId, setAssetId] = useState('') // tmp until save.

  useEffect(() => {
    if (props.presentation.asset) {
      const a = getAssetInfo(props.presentation.asset, props.assetsConfig)
      if (a && a.previewURL) {
        setAsset(a)
      }
    }
  }, []) // eslint-disable-line

  const handleUpdate = (asset) => {
    // SelectorWithPreview does not have a cancel, so I'm using the DialogGeneric exit strategy.
    if (asset) {
      setAssetId(asset.id)
    } else {
      setAssetId(null)
    }
  }

  const handleSave = () => {
    if (assetId) {
      const p = JSON.parse(JSON.stringify(props.presentation))
      p[type] = assetId
      const a = getAssetInfo(assetId, props.assetsConfig)
      if (type === 'asset') {
        setAsset(a)
      }
      props.setPresentation(p)
    }
  }

  // Figure out assetType
  let assetType = ['Image']
  if (props.variant === 'video') {
    assetType = ['Video']
  } else if (props.variant === 'document') {
    assetType = ['PDF']
  }
  return (
    <>
      <Box sx={{ p: 2, borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Typography style={styles.title}>{props.title || 'Presentation Details'}</Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <Grid container wrap='nowrap'>
          <Grid item xs={12} sm={6}>
            <Stack>
              <Box>
                <Typography sx={{ pb: 1, display: { sm: 'none', md: 'block' } }}>Select Presentation Asset</Typography>
                <Typography sx={{ display: { sm: 'block', md: 'none' } }}>Select Asset</Typography>
              </Box>
              {asset.previewURL
                ? (
                  <Box style={styles.imageContainer} sx={{ mr: 2 }}>
                    {props.variant === 'video'
                      ? (
                        <video
                          style={{ width: 'auto', minWidth: '100%' }}
                          controls
                          height='160'
                          poster={asset.previewURL}
                          onClick={(event) => event.target.play()}
                          onMouseOut={(event) => event.target.pause()}
                          src={`${asset.path}#t=1`}
                        />
                        )
                      : asset.previewURL
                        ? (
                          <a href={asset.path} target='_blank' rel='noreferrer'>
                            <CardMedia component='img' height='160' sx={{ objectFit: 'contain' }} image={asset.previewURL} alt={asset.previewURL} />
                          </a>
                          )
                        : (
                            ''
                          )}
                  </Box>
                  )
                : (
                  <Typography variant='body2'>There is no presentation asset. Please add one.</Typography>
                  )}
              {asset.filename
                ? (
                  <Box sx={{ pt: 2 }}>
                    <code style={{ color: 'black' }}>{asset.filename}</code>
                  </Box>
                  )
                : null}
              <Box sx={{ pt: 2 }}>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    setType('asset')
                    setShowAddDialog(!showAddDialog)
                  }}
                >
                  {props.presentation.asset ? 'Replace' : 'Add'} {props.variant === 'video' ? ' Video' : ' Document'}
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      {showAddDialog
        ? (
          <DialogGeneric
            title='Select Presentation Asset'
            content={
              <SelectorWithPreview
                selectedConfig={props.presentation}
                fieldName={type}
                config={props.config.assets2}
                assetType={assetType}
                onUpdate={(asset) => {
                  handleUpdate(asset)
                }}
              />
          }
            fullWidth
            onChange={() => {
              handleSave()
              setShowAddDialog(false)
            }}
            onClose={() => setShowAddDialog(false)}
          />
          )
        : null}
    </>
  )
}

const styles = {
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  textField: {
    fontSize: 14
  },
  imageContainer: {
    display: 'flex',
    padding: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,.04)',
    border: '1px dashed rgba(0,0,0,.16)'
  }
}
