const NavBulletNumber = ({ number, selected }) => {
  return (
    <div style={!selected ? { ...styles.navCircle } : { ...styles.navCircle, ...styles.selectedBackground }}>
      <span style={styles.fieldCircleNumber}>{number || 1}</span>
    </div>
  )
}
export default NavBulletNumber

const styles = {
  selectedBackground: {
    backgroundColor: 'blue'
  },
  navCircle: {
    borderRadius: '100%',
    height: '16px',
    width: '16px',
    backgroundColor: 'black',
    alignItems: 'center',
    verticalAlign: 'center',
    textAlign: 'center'
  },
  fieldCircleNumber: {
    position: 'relative',
    top: '-8px',
    fontSize: 8,
    fontWidth: 'bold',
    fontFamily: 'sans-serif',
    color: 'white'
  }
}
