import React, { useState, useEffect } from 'react'
import { Box, Button, CardMedia, Grid, Stack, Typography } from '@mui/material'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import SelectorWithPreview from 'components/Reusable/SelectorWithPreview'
import { getAssetInfo } from 'screens/Config/CompanyConfigPresentations'
import OverlayDocumentEditor from './ODE/OverlayDocumentEditor'

export const RightPanelDetailsOverlay = (props) => {
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [showOverlayDialog, setShowOverlayDialog] = useState(false)
  const [asset, setAsset] = useState('')
  const [assetId, setAssetId] = useState('') // tmp until save.

  useEffect(() => {
    if (props.presentation.asset) {
      const a = getAssetInfo(props.presentation.asset, props.assetsConfig)
      if (a && a.previewURL) {
        setAsset(a)
      }
    }
  }, []) // eslint-disable-line

  const handleUpdate = (asset) => {
    // SelectorWithPreview does not have a cancel, so I'm using the DialogGeneric exit strategy.
    setAssetId(asset.id)
  }

  const handleSave = () => {
    const p = JSON.parse(JSON.stringify(props.presentation))
    if (assetId) {
      p.asset = assetId
      p.poster = assetId
      const a = getAssetInfo(assetId, props.assetsConfig)
      setAsset(a)
    }
    props.setPresentation(p)
  }

  return (
    <>
      <Box sx={{ p: 2, borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Typography style={styles.title}>Overlay Presentation Details</Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <Grid container wrap='nowrap'>
          <Grid item xs={6} sx={{ mr: 2 }}>
            <Stack>
              <Box>
                <Typography sx={{ pb: 1 }}>Select Overlay Asset</Typography>
              </Box>

              {asset && asset.previewURL
                ? (
                  <Box style={styles.imageContainer}>
                    <a href={asset.path} target='_blank' rel='noreferrer'>
                      <CardMedia component='img' height='160' sx={{ objectFit: 'contain' }} image={asset.previewURL} alt={asset.previewURL} />
                    </a>
                  </Box>
                  )
                : (
                  <Typography variant='body2'>There is no overlay asset. Please add one.</Typography>
                  )}
              <Box sx={{ pt: 2 }}>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    setShowAddDialog(!showAddDialog)
                  }}
                >
                  {props.presentation.asset ? 'Replace' : 'Add'} Image
                </Button>
              </Box>
              {asset && asset.previewURL
                ? (
                  <Box sx={{ pt: 2 }}>
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={() => {
                        setShowOverlayDialog(!showOverlayDialog)
                      }}
                    >
                      Launch Placement Editor
                    </Button>
                  </Box>
                  )
                : null}
            </Stack>
          </Grid>
        </Grid>
      </Box>

      {showAddDialog
        ? (
          <DialogGeneric
            title='Select Overlay Asset'
            content={
              <SelectorWithPreview
                selectedConfig={props.presentation}
                fieldName='asset'
                config={props.config.assets2}
                assetType={['Image']}
                onUpdate={(asset) => {
                  handleUpdate(asset)
                }}
              />
          }
            fullWidth
            onChange={() => {
              handleSave()
              setShowAddDialog(false)
            }}
            onClose={() => setShowAddDialog(false)}
          />
          )
        : null}

      {showOverlayDialog
        ? (
          <OverlayDocumentEditor
            {...props}
            setPresentation={props.setPresentation}
            onClose={() => setShowOverlayDialog(!showOverlayDialog)}
            onSave={() => {
              handleSave()
              setShowOverlayDialog(!showOverlayDialog)
            }}
          />
          )
        : null}
    </>
  )
}

const styles = {
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  textField: {
    fontSize: 14
  },
  imageContainer: {
    display: 'flex',
    padding: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,.04)',
    border: '1px dashed rgba(0,0,0,.16)'
  }
}
