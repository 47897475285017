import React, { useState, useEffect } from 'react'
import { Button, Typography, TextField, Checkbox, Container, Box, Paper, Grid, Dialog, FormGroup, FormControlLabel } from '@mui/material'
import { LoadingPleaseWait } from '@supportworks/react-components'
import SelectorWithPreview from '../../../components/Reusable/SelectorWithPreview'
import EditorHeader from 'components/Reusable/EditorHeader'
import PDFDocumentEditor from './CDE/PDFDocumentEditor'
import CompanyConfigModal from '../CompanyConfigModal'
import Api from '../../../library/Api'

const CompanyDocumentEditor = ({ document, forms, assets, onChange, onClose }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [showPickAsset, setShowPickAsset] = useState(false)
  const [showPDFDocumentEditor, setShowPDFDocumentEditor] = useState(false)

  // Used for page data
  const [pageCount, setPageCount] = useState(0)
  const [pages, setPages] = useState([])

  // Misc other document settings
  const [state, setState] = useState({
    id: null,
    title: '',
    include: false,
    required: false,
    sendCustomer: false,
    sendDistributionList: false,
    merge: true,
    pageCount: null,
    pages: []
  })

  useEffect(() => {
    if (isLoading) {
      if (document.asset) {
        // console.log(`first load ${document.asset}`)
        Api.callRegisteredMethod('getPDFMeta', { guid: document.asset }).then((response) => {
          setPageCount(response.pageCount)
          setPages(response.pages)
          setIsLoading(false)
        })
      }

      setState({
        id: document.id,
        title: document.title,
        include: document.include,
        required: document.required,
        sendCustomer: document.sendCustomer,
        sendDistributionList: document.sendDistributionList,
        merge: document.merge
      })
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleChange = (event) => {
    const name = event.target.name
    if (event.target.type === 'text') {
      document[name] = event.target.value
      setState({ ...state, [event.target.name]: event.target.value })
    } else if (event.target.type === 'checkbox') {
      document[name] = !document[name]
      setState({ ...state, [event.target.name]: event.target.checked })
    }
    onChange()
  }

  const handleChangePDF = () => {
    setIsLoading(true)
    onChange()
    // console.log(`re load ${document.asset}`);
    Api.callRegisteredMethod('getPDFMeta', { guid: document.asset }).then((response) => {
      setPageCount(response.pageCount)
      setPages(response.pages)
      setState({
        id: document.id,
        title: document.title,
        include: document.include,
        required: document.required,
        sendCustomer: document.sendCustomer,
        sendDistributionList: document.sendDistributionList,
        merge: document.merge
      })
      setIsLoading(false)
    })
  }

  const getAssetInfo = (id) => {
    const assetConfig = assets.filter((asset) => {
      return asset.id === id
    })
    if (assetConfig.length !== 0) {
      return assetConfig[0]
    } else {
      return id
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  const { title, include, required, sendCustomer, sendDistributionList, merge } = state
  let selectedAsset
  if (document.asset) {
    selectedAsset = getAssetInfo(document.asset)
  }

  let jsx
  try {
    jsx = (
      <>
        <EditorHeader
          title='Edit Company Document'
          fullScreen
          showFullScreenOption={false}
          onBack={() => {
            onChange()
            onClose()
          }}
        />
        <Paper elevation={0} sx={{ pt: 2, pb: 2 }}>
          <Container maxWidth={false}>
            <Box>
              <Typography variant='h5'>Setup</Typography>
            </Box>
            <Box sx={{ pb: 3 }}>
              <Typography paragraph>
                A custom document is a user uploaded PDF that can be combined with the standard document generated in the SolutionView App at the end of the
                appointment. After assigning a PDF and defining settings, you can use the placement tool to automate data that will be rendered on the document.
              </Typography>
            </Box>
            <Box sx={{ mb: 3, pb: 3, ...styles.borderBottom }}>
              <TextField
                required
                id='outlined-required'
                label='Title Document'
                defaultValue={title}
                sx={{ width: '50%' }}
                name='title'
                onChange={handleChange}
              />
            </Box>
            <Grid container spacing={0} sx={{ mb: 3, ...styles.borderBottom }}>
              <Grid item xs={12} sm={3}>
                Document Settings
              </Grid>
              <Grid item xs={12} sm={9}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name='include' onChange={handleChange} checked={include} />}
                    label='Include Document Every Appointment'
                  />
                  <FormControlLabel
                    control={<Checkbox name='sendCustomer' onChange={handleChange} checked={sendCustomer} />}
                    label='Email Customer when User Sends to Cloud'
                  />
                  <FormControlLabel
                    control={<Checkbox name='required' onChange={handleChange} checked={required} />}
                    label='Require Document at Appointment Completion'
                  />
                  <FormControlLabel
                    control={<Checkbox name='sendDistributionList' onChange={handleChange} checked={sendDistributionList} />}
                    label='Send to Distribution List'
                  />
                  <FormControlLabel
                    control={<Checkbox name='merge' onChange={handleChange} checked={merge} />}
                    label='Include Document in Merged PDF'
                  />
                </FormGroup>
              </Grid>
            </Grid>

            <Grid container spacing={0} sx={{ mb: 3, pb: 5 }}>
              <Grid item xs={12} sm={3} sx={{ p: 1, mr: 1, borderColor: 'rgba(0,0,0,.24)', backgroundColor: 'rgba(0,0,0,.08)' }}>
                {document.asset
                  ? (
                    <img src={selectedAsset.previewURL} className='selected-Asset' alt={selectedAsset.title} width='95%' />
                    )
                  : (
                    <span>No PDF Selected</span>
                    )}
              </Grid>
              <Grid item xs={12} sm={8}>
                <Button
                  id='add-cover-photo'
                  color='primary'
                  variant='contained'
                  sx={{ mr: 2 }}
                  onClick={() => {
                    setShowPickAsset(!showPickAsset)
                  }}
                >
                  Select PDF
                </Button>
              </Grid>
            </Grid>

            <Box sx={{ display: 'flex', ...styles.bottomNavigation }}>
              {document.asset && (
                <Button
                  id='launch-pdf-editor'
                  color='primary'
                  variant='contained'
                  sx={{ mr: 2 }}
                  onClick={() => {
                    setShowPDFDocumentEditor(!showPDFDocumentEditor)
                  }}
                >
                  Launch PDF Editor
                </Button>
              )}
              <Button
                id='done'
                variant='contained'
                color='primary'
                sx={{ marginLeft: 'auto' }}
                onClick={() => {
                  onChange()
                  onClose()
                }}
              >
                Back
              </Button>
            </Box>
          </Container>
        </Paper>

        {showPickAsset && (
          <CompanyConfigModal
            level={999}
            open
            body={
              <SelectorWithPreview
                selectedConfig={{ asset: document.asset }}
                fieldName='asset'
                config={assets}
                assetType={['PDF']}
                onUpdate={(asset) => {
                  if (asset && asset.id) {
                    document.asset = asset.id
                  }
                }}
              />
            }
            header='Select Asset'
            update={() => {
              setShowPickAsset(!showPickAsset)
              handleChangePDF()
            }}
            cancel={() => {
              setShowPickAsset(!showPickAsset)
            }}
          />
        )}

        {showPDFDocumentEditor && (
          <PDFDocumentEditor
            document={document}
            forms={forms}
            asset={selectedAsset}
            pageCount={pageCount}
            pages={pages}
            onChange={() => {
              setShowPDFDocumentEditor(!showPDFDocumentEditor)
              onChange()
            }}
            onClose={() => {
              setShowPDFDocumentEditor(!showPDFDocumentEditor)
              onChange()
            }}
          />
        )}
      </>
    )
  } catch (err) {
    console.log(`StandardDocumentEditor error: ${err.stack}`)
    jsx = <div>Data is not available.</div>
  }
  return (
    <>
      <Dialog open onClose={onClose} fullScreen PaperProps={{ sx: { width: '100%', height: '100%' } }} scroll='paper'>
        {jsx}
      </Dialog>
    </>
  )
}
export default CompanyDocumentEditor

const styles = {
  bottomNavigation: {
    position: 'fixed',
    width: '100%',
    left: 0,
    bottom: 0,
    padding: '16px',
    borderTop: 1,
    borderColor: 'rgba(0,0,0,.16)',
    backgroundColor: 'white'
  },
  borderBottom: {
    borderBottom: 1,
    borderColor: 'rgba(0,0,0,.16)'
  }
}
