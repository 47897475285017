import React, { createRef } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import IconButton from '@mui/material/IconButton'
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined'
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay'
import ViewDayOutlinedIcon from '@mui/icons-material/ViewDayOutlined'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import { AppBar, Typography, Toolbar, Grid, TextField, Container, Dialog, Button, Box } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import Paper from '@mui/material/Paper'
import SingleSectionEditor from './SingleSectionEditor'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import DrawerDebug from '../../../components/Reusable/DrawerDebug'
import Permissions from '../../../library/Permissions'
import { LoadingPleaseWait } from '@supportworks/react-components'
import H from 'library/helper'
import FormActionBar from './ActionBar/FormActionBar'
import FieldTypeSelect from './FieldTypeSelect'
import '../../../css/forms.css'

class SingleFormEditor extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      form: this.props.form,
      handleGoBack: false,
      toolbar: 'default',
      formTitleState: 'view',
      allFields: null,
      allFieldsSolutions: null,
      fullScreen: true,
      isSaving: false,
      unsavedChanges: false
    }
    this.sectionRef = createRef(null)

    this.changeToolbar = this.changeToolbar.bind(this)
    this.addSection = this.addSection.bind(this)
    this.handleChangeFormTitle = this.handleChangeFormTitle.bind(this)
    this.setInputFocus = this.setInputFocus.bind(this)
    this.changeHandlerFormSection = this.changeHandlerFormSection.bind(this)
    this.addField = this.addField.bind(this)
    this.addFieldFromSection = this.addFieldFromSection.bind(this)
    this.populateFieldsArray = this.populateFieldsArray.bind(this)
    this.saveSolutions = this.saveSolutions.bind(this)
    this.onFullScreen = this.onFullScreen.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  componentDidMount () {
    let main = {}
    if (this.props.form.formSection.length !== 0) {
      main = this.populateFieldsArray(this.props.form.formSection)
    }
    this.setState({ allFields: main.allFields, allFieldsSolutions: main.allFieldsSolutions })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevState.form !== this.state.form) {
      if (this.state.form.formSection === undefined || this.state.form.formSection === 0) {
        this.setState({ toolbar: 'default' })
      }
      this.setState({ unsavedChanges: true })
    }

    const pfs = prevState.form.formSection
    const fs = this.state.form.formSection
    if ((fs !== undefined && fs.length !== 0) && (pfs !== undefined && pfs.length !== 0)) {
      if (pfs !== fs) {
        if (pfs[pfs.length - 1].fields.length < fs[fs.length - 1].fields.length) {
          this.sectionRef.current.scrollIntoView()
        }
      }
    }

    if (this.state.isSaving) {
      setTimeout(() => {
        this.setState({ isSaving: false, unsavedChanges: !this.state.unsavedChanges })
      }, 500)
    }
  }

  handleGoBack = e => {
    if (this.state.unsavedChanges) {
      if (window.confirm('Are you sure you want to close?')) {
        e.preventDefault()
        const form = this.state.form
        this.props.backToTable(form)
      }
    } else {
      const form = this.state.form
      this.props.backToTable(form)
    }
  }

  changeToolbar (name) {
    this.setState({ toolbar: name })
  }

  addSection () {
    const form = JSON.parse(JSON.stringify(this.state.form))
    form.formSection.push({ id: this.props.guid(), sectionTitle: '', fields: [] })
    this.sectionRef.current.scrollIntoView()
    this.setState({ form })
  }

  handleChangeFormTitle (event) {
    const form = JSON.parse(JSON.stringify(this.state.form))
    form.formTitle = event.target.value
    this.setState({ form })
  }

  setInputFocus () {
    // console.log(this.myInputRef);
    this.myInputRef.focus()
  }

  changeHandlerFormSection (fs, idx) {
    // console.log(fs, idx);
    const form = JSON.parse(JSON.stringify(this.state.form))
    form.formSection[idx] = fs
    this.setState({ form })
    this.handleChange(form)
  }

  deleteFormSection (idx) {
    const form = JSON.parse(JSON.stringify(this.state.form))
    form.formSection.splice(idx, 1)
    this.setState({ form })
  }

  addField (type) {
    const form = JSON.parse(JSON.stringify(this.state.form))

    switch (type) {
      case 'table_2':
        form.formSection[form.formSection.length - 1].fields.push({
          id: this.props.guid(),
          type: 'table',
          tableType: '2-Column',
          subArea: true,
          rows: [
            {
              columns: [
                {
                  id: this.props.guid(),
                  subArea: true,
                  areaValueDefault: true,
                  type: '',
                  title: ''
                },
                {
                  id: this.props.guid(),
                  subArea: true,
                  areaValueDefault: true,
                  type: '',
                  title: ''
                }
              ]
            }
          ]
        })
        break
      case 'table_3':
        form.formSection[form.formSection.length - 1].fields.push({
          id: this.props.guid(),
          type: 'table',
          tableType: '3-Column',
          subArea: true,
          subAreaOnly: true,
          rows: [
            {
              columns: [
                {
                  id: this.props.guid(),
                  subArea: true,
                  areaValueDefault: true,
                  type: '',
                  title: ''
                },
                {
                  id: this.props.guid(),
                  subArea: true,
                  areaValueDefault: true,
                  type: '',
                  title: ''
                },
                {
                  id: this.props.guid(),
                  subArea: true,
                  areaValueDefault: true,
                  type: '',
                  title: ''
                }
              ]
            }
          ]
        })
        break
      default:
        form.formSection[form.formSection.length - 1].fields.push({
          id: this.props.guid(),
          type
        })
    }

    this.setState({ form })
  }

  addFieldFromSection (sectionId, idx) {
    const form = JSON.parse(JSON.stringify(this.state.form))
    form.formSection[idx].fields.push({ id: this.props.guid(), type: null })
    this.setState({ form })
  }

  saveSolutions (solutions) {
    const form = JSON.parse(JSON.stringify(this.state.form))

    if (solutions.length !== 0) {
      form.solutions = solutions
    } else {
      delete form.solutions
    }

    this.setState({ form })
  }

  populateFieldsArray (formSection) {
    const main = {}
    const allFields = []
    const allFieldsSolutions = []
    Object.keys(formSection).forEach((fs) => {
      if (!formSection[fs].fields) formSection[fs].fields = []
      allFields.push(formSection[fs])
      const sectionTitle = formSection[fs].sectionTitle
      if ('fields' in formSection[fs]) {
        formSection[fs].fields.forEach((f) => {
          // console.log(f);
          allFieldsSolutions.push({ sectionTitle, ...f })
          allFields.push(f)
          if ('choices' in f) {
            function iterateChoices (choices) {
              Object.keys(choices).forEach((c) => {
                // console.log(choices[c]);
                if (typeof choices[c] === 'object' && 'related' in choices[c]) {
                  choices[c].related.forEach((f) => {
                    allFieldsSolutions.push({ sectionTitle, ...f })
                    allFields.push(f)
                    // console.log(f);
                    if ('choices' in f) {
                      iterateChoices(f.choices)
                    }
                  })
                }
              })
            }
            iterateChoices(f.choices)
          }
          if ('related' in f) {
            function iterateRelated (related) {
              Object.keys(related).forEach((r) => {
                allFieldsSolutions.push({ sectionTitle, ...related[r] })
                allFields.push(related[r])
                if ('related' in related[r]) {
                  iterateRelated(related[r].related)
                }
                // console.log(related[r]);
              })
            }
            iterateRelated(f.related)
          }
        })
      }
    })
    main.allFields = allFields
    main.allFieldsSolutions = allFieldsSolutions
    // console.log(allFieldsSolutions);
    return main
  }

  handleMoveUp = (idx) => {
    const form = JSON.parse(JSON.stringify(this.state.form))
    if (idx > 0) {
      [form.formSection[idx], form.formSection[idx - 1]] = [form.formSection[idx - 1], form.formSection[idx]]
    }
    this.setState({ form })
  }

  handleMoveDown = (idx) => {
    const form = JSON.parse(JSON.stringify(this.state.form))
    if (idx < form.formSection.length) {
      [form.formSection[idx], form.formSection[idx + 1]] = [form.formSection[idx + 1], form.formSection[idx]]
    }
    this.setState({ form })
  }

  onFullScreen () {
    const newFullScreen = !this.state.fullScreen
    this.setState({ fullScreen: newFullScreen })
  }

  handleSave = (e) => {
    const config = {
      title: 'Forms',
      variant: 'forms2',
      tag: 'forms2',
      name: 'Forms',
      lockTag: true,
      lockName: true
    }

    const index = this.props.formsList.findIndex(item => item.id === this.state.form.id)
    // console.log(index);
    const formsList = JSON.parse(JSON.stringify(this.props.formsList))
    // console.log(this.props.formsList);

    if ('newForm' in this.state.form) {
      delete this.state.form.newForm
    }
    if ('editForm' in this.state.form) {
      delete this.state.form.editForm
    }

    formsList[index] = this.state.form

    this.setState({ isSaving: true })
    H.saveConfig(this.props.orgId, config, formsList)
  }

  /** handleChange()
     *
     *  Generic handleChange so we can pass the entire form places, modify it, and have changes stick.
     *
    **/
  handleChange = (form) => {
    const f = JSON.parse(JSON.stringify(form))
    this.setState({ form: f })
  }

  render () {
    const form = JSON.parse(JSON.stringify(this.state.form))
    const jsx = (
      <>
        <div className='form__main'>
          <AppBar position='relative'>
            <Toolbar>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <IconButton
                    aria-label='back to list' onClick={this.handleGoBack}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={4}>
                  <div className='main-appbar-center'>
                    <Typography variant='subtitle2'>{form.formTitle ? form.formTitle : 'Untitled'} Form</Typography>
                    {/* <Typography variant={'caption'}>All changes saved</Typography> */}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className='main-appbar-right'>
                    {/* <IconButton><HelpOutlineIcon /></IconButton>
                                        <IconButton><VisibilityOutlinedIcon /></IconButton> */}
                    <IconButton title='Full Screen' aria-label='Full Screen' onClick={() => this.onFullScreen()}>
                      {this.state.fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>

          <Container className='single-form-main'>
            <Grid container spacing={2}>
              {
                                this.state.toolbar === 'default' &&
                                  <Grid item sm={1}>
                                    <Paper className='paper-left-toolbar'>
                                      {/* <Tooltip title={'+ Question'} placement={'right'}>
                                            <span>
                                                <IconButton
                                                    disabled={form.formSection && form.formSection.length === 0 ? true : false}
                                                    className={'icon-left-toolbar'}
                                                    onClick={()=>this.changeToolbar('fieldTypes')}
                                                >
                                                <AddCircleOutlineIcon />
                                                </IconButton>
                                            </span>
                                        </Tooltip> */}
                                      <FieldTypeSelect
                                        type='formLevel'
                                        disabled={!!(form.formSection && form.formSection.length === 0)}
                                        onChange={(fieldType) => this.addField(fieldType)}
                                      />
                                      <Tooltip title='+ Photo' placement='right'>
                                        <span>
                                          <IconButton className='icon-left-toolbar' disabled>
                                            <AddPhotoAlternateOutlinedIcon />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                      <Tooltip title='+ Content Block' placement='right'>
                                        <span>
                                          <IconButton className='icon-left-toolbar' disabled>
                                            <PlaylistPlayIcon />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                      <Tooltip title='+ Solutions' placement='right'>
                                        <span>
                                          <IconButton className='icon-left-toolbar' disabled>
                                            <AssignmentOutlinedIcon />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                      <Tooltip title='+ Section' placement='right'>
                                        <span>
                                          <IconButton
                                            className='icon-left-toolbar'
                                            onClick={() => this.addSection()}
                                          >
                                            <ViewDayOutlinedIcon />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                    </Paper>
                                  </Grid>
                            }

              {
                                // this.state.toolbar === 'fieldTypes' &&

                                /* <Grid item sm={1}>
                                    <Paper className={'paper-left-toolbar fieldTypes'}>
                                        <IconButton
                                            className={'icon-left-toolbar cancel'}
                                            onClick={()=>this.changeToolbar('default')}>
                                            <CancelRoundedIcon fontSize={'large'} className={"addField-toolbar-cancel"} />
                                        </IconButton>

                                        <Tooltip title={"Yes/No"} placement={"right"}>
                                            <IconButton
                                                className={'icon-left-toolbar fields'}
                                                onClick={()=>this.addField('boolean')}
                                            >
                                                <ThumbsUpDownIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={"Single Selection"} placement={"right"}>
                                            <IconButton
                                                className={'icon-left-toolbar fields'}
                                                onClick={()=>this.addField('radio')}
                                            >
                                                <RadioButtonCheckedOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={"Multi Selection"} placement={"right"}>
                                            <IconButton
                                                className={'icon-left-toolbar fields'}
                                                onClick={()=>this.addField('checkbox')}
                                            >
                                                <CheckBoxIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={"Dropdown"} placement={"right"}>
                                            <IconButton
                                                className={'icon-left-toolbar fields'}
                                                onClick={()=>this.addField('choice')}
                                            >
                                                <ArrowDropDownCircleIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={"Numeric"} placement={"right"}>
                                            <IconButton
                                                className={'icon-left-toolbar fields'}
                                                onClick={()=>this.addField('numeric')}
                                            >
                                                <PinOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={"Calculator"} placement={"right"}>
                                            <IconButton
                                                className={'icon-left-toolbar fields'}
                                                onClick={()=>this.addField('calc')}
                                            >
                                                <CalculateRoundedIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={'Photo'} placement={'right'}>
                                            <IconButton
                                                className={'icon-left-toolbar fields'}
                                                onClick={()=>this.addField('media')}
                                            >
                                                <AddPhotoAlternateOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={"Short Text"} placement={"right"}>
                                            <IconButton
                                                className={'icon-left-toolbar fields'}
                                                onClick={()=>this.addField('text')}
                                            >
                                                <ShortTextOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={"Long Text"} placement={"right"}>
                                            <IconButton
                                                className={'icon-left-toolbar fields'}
                                                onClick={()=>this.addField('type')}
                                            >
                                                <SubjectIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={"Date"} placement={"right"}>
                                            <IconButton
                                                className={'icon-left-toolbar fields'}
                                                onClick={()=>this.addField('date')}
                                            >
                                                <EventOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={"Time"} placement={"right"}>
                                            <IconButton
                                                className={'icon-left-toolbar fields'}
                                                onClick={()=>this.addField('time')}
                                            >
                                                <WatchLaterIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Paper>
                                </Grid> */
                            }

              <div style={{
                width: '100%',
                paddingBottom: '100px'
              }}
              >
                <div style={{
                  width: '100%',
                  maxWidth: '1170px',
                  margin: '40px auto',
                  padding: '0px 24px',
                  paddingLeft: '90px'
                }}
                >
                  <TextField
                    className='form-title'
                    id='form-title'
                    value={form.formTitle}
                    fullWidth
                    variant='outlined'
                    onFocus={(event) => event.target.select()}
                    onChange={this.handleChangeFormTitle}
                    inputRef={c => (this.myInputRef = c)}
                    InputProps={{
                      // disableUnderline: true, // disableUnderline doesn't work for variant outlined, so this errors
                    }}
                    sx={{
                      '& label.Mui-focused': {
                        display: 'none'
                      },
                      '& legend': {
                        display: 'none'
                      },
                      '& .MuiInputBase-root': {
                        height: 60,
                        fontSize: 34
                      },
                      '& .MuiOutlinedInput-root.Mui-focused': {
                        '& > fieldset': {
                          border: '1px solid dodgerblue'
                        }
                      },
                      '&:hover': {
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: '1px solid dodgerblue'
                        }
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #f2f2f2'
                      }
                    }}
                  />

                  <FormActionBar
                    {...this.props}
                    form={form}
                    allFieldsSolutions={this.state.allFieldsSolutions}
                    onChange={this.handleChange}
                  />

                  {
                      form.formSection.length !== 0 &&
                      this.state.allFields !== null &&
                      form.formSection.map((section, idx) => {
                        return (
                          <SingleSectionEditor
                            key={`section-${idx}-${section.id}`}
                            formSection={section}
                            form={form}
                            onChange={(fs) => this.changeHandlerFormSection(fs, idx)}
                            onDelete={() => this.deleteFormSection(idx)}
                            onAddQuestion={() => this.addFieldFromSection(section.id, idx)}
                            guid={this.props.guid}
                            formsConfig={this.props.formsConfig}
                            currentConfig={this.props.currentConfig}
                            assetsConfig={this.props.assetsConfig}
                            solutionsConfig={this.props.solutionsConfig}
                            crmFieldsConfig={this.props.crmFieldsConfig}
                            numericUnitsConfig={this.props.numericUnitsConfig}
                            productList={this.props.productList}
                            allFields={this.state.allFields}
                            allFieldsSolutions={this.state.allFieldsSolutions}
                            onSaveSolutions={(solutions) => this.saveSolutions(solutions)}
                            onMoveUp={idx > 0 ? () => { this.handleMoveUp(idx) } : null}
                            onMoveDown={idx === form.formSection.length - 1 ? null : () => this.handleMoveDown(idx)}
                          />
                        )
                      })
                    }

                  <div
                    style={{ float: 'left', clear: 'both' }}
                    ref={this.sectionRef}
                  />
                  {Permissions.hasRole('super_user') ? <DrawerDebug json={[{ name: 'Forms', json: form }]} /> : null}
                </div>
              </div>
            </Grid>
            <Box sx={{ display: 'flex', ...styles.bottomNavigation }}>
              <Button id='close-editor' sx={{ mr: 2 }} variant='outlined' onClick={this.handleGoBack}>
                Close
              </Button>
              <Button id='save-editor' disabled={!this.state.unsavedChanges} variant='contained' color='primary' sx={{ marginLeft: 'auto' }} onClick={this.handleSave}>
                {this.state.isSaving ? <LoadingPleaseWait className='loading-please-wait-button' /> : <>Save</>}
              </Button>
            </Box>
          </Container>
        </div>
      </>
    )

    return (
      <>
        {this.state.fullScreen
          ? (
            <Dialog
              open
              onClose={() => this.onClose()}
              fullScreen={this.state.fullScreen}
              PaperProps={{ sx: { width: '100%', height: '100%' } }}
              scroll='paper'
              sx={{
                zIndex: 1001
              }}
            >
              {jsx}
            </Dialog>
            )
          : (
            <>{jsx}</>
            )}
      </>
    )
  }
}

const styles = {
  desc: {
    marginTop: '10px'
  },
  bottomNavigation: {
    position: 'fixed',
    width: '100%',
    left: 0,
    bottom: 0,
    maxHeight: '69px',
    padding: '16px',
    borderTop: 1,
    borderColor: 'rgba(0,0,0,.16)',
    backgroundColor: 'white'
  }
}
export default SingleFormEditor
