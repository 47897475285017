import React, { useState, useEffect } from 'react'
import { Box, Chip, Grid, IconButton, Typography, Drawer, Stack } from '@mui/material'
import AdminIcon from '@mui/icons-material/AdminPanelSettings'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import DrawerHeader from '../Reusables/DrawerHeader'
import DrawerFooter from '../Reusables/DrawerFooter'
import DrawerSolutionSelect from '../DrawerSolutionSelect'
import { JSONEditor } from 'components/Reusable'
import { LoadingPleaseWait } from '@supportworks/react-components'
import Helper from 'library/helper'
import Permissions from 'library/Permissions'

const DrawerSolutionList = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isAdding, setIsAdding] = useState(false)
  const [showDrawerSolutionSelect, setShowDrawerSolutionSelect] = useState(false)
  const [showJSONEditor, setShowJSONEditor] = useState(false)
  const [solution, setSolution] = useState(null) // The solution I clicked on, passed into the DrawerSolutionEditor.
  const [solutionIndex, setSolutionIndex] = useState(-1)

  useEffect(() => {
    if (isLoading) {
      // Autofix
      if (props.form.solutions === undefined) {
        const f = JSON.parse(JSON.stringify(props.form))
        f.solutions = []
        props.onChange(f)
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const handleAdd = () => {
    setIsAdding(true)
    setShowDrawerSolutionSelect(!showDrawerSolutionSelect)
  }

  const handleDelete = (e, idx) => {
    e.stopPropagation()
    if (window.confirm('Are you sure you want to remove this solution?')) {
      const f = JSON.parse(JSON.stringify(props.form))
      f.solutions.splice(idx, 1)
      props.onChange(f)
    }
  }

  const handleSave = (solution) => {
    const f = JSON.parse(JSON.stringify(props.form))
    if (isAdding) {
      f.solutions.push(solution)
    } else {
      f.solutions[solutionIndex] = solution
    }
    setSolutionIndex(-1)
    setSolution(null)
    setIsAdding(false)
    props.onChange(f)
  }

  const handleClose = (e) => {
    toggleDrawerSolutionSelect(false)
    props.onClose()
  }

  const handleShowJSONEditor = (idx) => {
    if (showJSONEditor) {
      setSolution(null)
      setSolutionIndex(-1)
    } else {
      setSolution(props.form.solutions[idx])
      setSolutionIndex(idx)
    }
    setShowJSONEditor(!showJSONEditor)
  }

  const toggleDrawerSolutionSelect = (value) => {
    setShowDrawerSolutionSelect(value)
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Drawer
        key='forms-edit-values'
        open={props.open}
        PaperProps={{
          sx: { width: '560px !important' }
        }}
        anchor='right'
        variant='temporary'
        onKeyDown={stopPropagationForTab}
        onClose={handleClose}
      >
        <DrawerHeader icon={<VerifiedUserIcon style={{ color: 'rgba(0,0,0,.66)' }} />} title='Solutions' onClose={handleClose} />

        {props.form.solutions && props.form.solutions.length === 0
          ? (
            <Box sx={{ p: 2 }}>There are no solutions. Add one.</Box>
            )
          : (
            <Box sx={{ pl: 2, pt: 2 }} style={styles.content}>
              {props.form.solutions.map((solution, idx) => {
                let productGroupCount = 0
                let productCount = 0
                if (solution.products !== undefined && solution.products.length > 0) {
                  productGroupCount = solution.products.filter((o) => o.type === 'productGroup').length
                  productCount = solution.products.filter((o) => o.type === 'product').length
                }

                let previewURL
                let solConfig
                if (props.currentConfig.solutions) {
                  solConfig = props.currentConfig.solutions.find((o) => o.id === solution.solutionid)
                  if (solConfig && solConfig.asset) {
                    const asset = Helper.getAssetInfo(solConfig.asset, props.assetsConfig)
                    if (asset && asset.previewURL) {
                      previewURL = asset.previewURL
                    }
                  }
                }

                return (
                  <Grid container key={`solution-${idx}-${solution.id}`} sx={{ pt: idx > 0 ? 2 : 0, pb: 2, borderBottom: '1px solid rgba(0,0,0,.08)' }}>
                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'top' }}>
                      {previewURL
                        ? (
                          <Box>
                            <img src={previewURL} alt='Solution preview' height={56} width={96} />
                          </Box>
                          )
                        : !solConfig
                            ? (
                              <Box style={styles.nosolution}>
                                <Typography variant='body2' align='center'>
                                  Missing Solution
                                </Typography>
                              </Box>
                              )
                            : (
                              <Box style={styles.nosolution}>
                                <Typography variant='body2' align='center'>
                                  No image
                                </Typography>
                              </Box>
                              )}
                    </Grid>
                    <Grid item xs={8} sx={{ pl: 3 }} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'top' }}>
                      <Stack>
                        <Typography variant='body2' sx={{ pb: 1 }} style={{ display: 'flex', alignItems: 'center' }}>
                          {solution.customTitle ? solution.customTitle : solution.solutionTitle}
                          {solConfig && solConfig.id ? <DeleteIcon sx={{ ml: 1 }} style={styles.icon} onClick={(e) => handleDelete(e, idx)} /> : null}
                        </Typography>

                        <Box>
                          <Chip size='small' label={`${productCount} Products`} />
                          <Chip sx={{ ml: 1 }} size='small' label={`${productGroupCount} Product Groups`} />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs sx={{ mr: 1 }} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                      <>
                        {Permissions.hasRole('super_user')
                          ? (
                            <Box sx={{ ml: 0, pl: 0, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                              <IconButton onClick={() => handleShowJSONEditor(idx)}>
                                <AdminIcon size='small' style={{ color: 'red' }} />
                              </IconButton>
                            </Box>
                            )
                          : null}

                        <IconButton
                          disabled={!solConfig}
                          onClick={() => {
                            setSolutionIndex(idx)
                            setSolution(props.form.solutions[idx])
                            toggleDrawerSolutionSelect(!showDrawerSolutionSelect)
                          }}
                        >
                          <KeyboardArrowRightIcon size='large' />
                        </IconButton>
                      </>
                    </Grid>
                  </Grid>
                )
              })}
            </Box>
            )}

        <DrawerFooter
          onClose={handleClose}
          titleClose='Close'
          onAdd={() => {
            handleAdd()
          }}
          titleAdd='Add Solution'
        />
      </Drawer>

      {showJSONEditor
        ? <DialogGeneric
            title='JSON Field Editor'
            fullWidth
            maxWidth='md'
            content={
              <JSONEditor
                value={JSON.stringify(solution)}
                onChange={(solution) => {
                  handleSave(JSON.parse(solution))
                }}
                saveOnlyOnDone
              />
                    }
            onClose={handleShowJSONEditor}
            onSave={handleShowJSONEditor}
          />
        : null}

      {showDrawerSolutionSelect === true
        ? (
          <DrawerSolutionSelect
            {...props}
            open={showDrawerSolutionSelect}
            form={props.form}
            allFieldsSolutions={props.allFieldsSolutions}
            setSolution={setSolution}
            currSolution={solution}
            onClose={() => {
              setSolutionIndex(-1)
              setSolution(null)
              toggleDrawerSolutionSelect(false)
            }}
            onSave={(solution) => {
              handleSave(solution)
            }}
            hideBackdrop
          />
          )
        : null}
    </>
  )
}
export default DrawerSolutionList

const styles = {
  content: {
    overflowX: 'hidden'
  },
  icon: {
    color: 'rgba(0,0,0,.66)',
    fontSize: '16px',
    cursor: 'pointer'
  },
  icon2: {
    color: 'rgba(0,0,0,.66)'
  },
  nosolution: {
    display: 'flex',
    alignItems: 'center',
    height: '56px',
    backgroundColor: 'rgba(0,0,0,.12)',
    border: '1px solid rgba(0,0,0,.08)'
  }
}
