import React, { useEffect, useState } from 'react'
import FieldTypeExpandMarker from '../Reusables/FieldTypeExpandMarker'
import FieldTypeAddLogicPanel from '../FieldTypeAddLogicPanel'

function CustomerWhy (props) {
  const [field, setField] = useState(props.field)

  const changeHandlerVisible = (val) => {
    props.onChangeVisible(val)
  }

  const changeHandlerEditable = (val) => {
    const newField = JSON.parse(JSON.stringify(field))
    newField.editable = val
    setField(newField)
    props.onChange(newField)
  }

  useEffect(() => {
    const newField = JSON.parse(JSON.stringify(props.field))
    if (!('editable' in newField)) {
      newField.editable = true
    }
    setField(newField)
    props.onChange(newField)
  }, [])

  return (
    <>
      {
                props.expandMarker &&
                  <FieldTypeExpandMarker />
            }
      <div style={styles.logicPanel}>
        <FieldTypeAddLogicPanel
          {...props}
          field={props.field}
          onChangeVisible={(visible) => changeHandlerVisible(visible)}
          onChangeEditable={(val) => changeHandlerEditable(val)}
        />
      </div>
    </>
  )
}

const styles = {
  relatedMain: {
    display: 'flex'
  },
  logicPanel: {
    // width: "100%"
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%'
  }
}

export default CustomerWhy
