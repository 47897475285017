import React, { useState, useEffect } from 'react'
import {
  Box,
  Chip,
  Container,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import AdminIcon from '@mui/icons-material/AdminPanelSettings'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import TuneIcon from '@mui/icons-material/Tune'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import DrawerHeader from './Reusables/DrawerHeader'
import DrawerFooter from './Reusables/DrawerFooter'
import Permissions from 'library/Permissions'
import { LoadingPleaseWait } from '@supportworks/react-components'
import SingleFieldEditor from 'screens/Config/Forms/SingleFieldEditor'
import TextInputBasic from 'screens/Config/Forms/Reusables/TextInputBasic'
import OptionsDialogSection from 'screens/Config/Forms/Reusables/OptionsDialogSection'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import { JSONEditor } from 'components/Reusable'

import '../../../css/forms.css'

class SingleSectionEditor extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      formSection: props.formSection,
      expanded: true,
      showSectionSettings: false,
      showJSONEditor: false
    }

    this.changeHandler = this.changeHandler.bind(this)
    this.deleteSection = this.deleteSection.bind(this)
    this.changeHandlerField = this.changeHandlerField.bind(this)
    this.deleteHandlerField = this.deleteHandlerField.bind(this)
    this.addQuestion = this.addQuestion.bind(this)
    this.saveSolutions = this.saveSolutions.bind(this)
    this.handleMoveUp = this.handleMoveUp.bind(this)
    this.handleMoveDown = this.handleMoveDown.bind(this)
    this.handleChangeFreeForm = this.handleChangeFreeForm.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (this.props.formSection.fields.length !== prevProps.formSection.fields.length) {
      this.setState({ formSection: this.props.formSection })
    }
  }

  changeHandler (title) {
    const formSection = JSON.parse(JSON.stringify(this.state.formSection))
    formSection.sectionTitle = title
    this.props.onChange(formSection)
    this.setState({ formSection })
  }

  handleChangeFreeForm (newFormSection) {
    const formSection = JSON.parse(JSON.stringify(newFormSection))
    this.props.onChange(formSection)
    this.setState({ formSection })
  }

  deleteSection () {
    // let formSection = JSON.parse(JSON.stringify(this.state.formSection));
    // this.props.onDelete(formSection.id);
    this.props.onDelete()
  }

  addQuestion () {
    this.props.onAddQuestion()
  }

  changeHandlerField (field, idx) {
    const formSection = JSON.parse(JSON.stringify(this.state.formSection))
    formSection.fields[idx] = field
    this.props.onChange(formSection)
    this.setState({ formSection })
  }

  deleteHandlerField (idx) {
    const formSection = JSON.parse(JSON.stringify(this.state.formSection))
    formSection.fields.splice(idx, 1)
    this.props.onChange(formSection)
    this.setState({ formSection })
  }

  handleMoveUp = (idx) => {
    const formSection = JSON.parse(JSON.stringify(this.state.formSection))
    if (idx > 0) {
      [formSection.fields[idx], formSection.fields[idx - 1]] = [formSection.fields[idx - 1], formSection.fields[idx]]
    }
    this.props.onChange(formSection)
    this.setState({ formSection })
  }

  handleMoveDown = (idx) => {
    const formSection = JSON.parse(JSON.stringify(this.state.formSection))
    if (idx < formSection.fields.length) {
      [formSection.fields[idx], formSection.fields[idx + 1]] = [formSection.fields[idx + 1], formSection.fields[idx]]
    }
    this.props.onChange(formSection)
    this.setState({ formSection })
  }

  handleDrawerSectionSettings = () => {
    this.setState({ showSectionSettings: !this.state.showSectionSettings })
  }

  // A beefier handleChange that allows us to pass in a form key and value.
  //
  handleChange = (key, value) => {
    const formSection = JSON.parse(JSON.stringify(this.state.formSection))
    formSection[key] = value
    this.props.onChange(formSection)
    this.setState({ formSection })
  }

  // Force a change on the form section.
  //
  handleChangeForm = (fs) => {
    this.setState({ formSection: fs })
    this.props.onChange(fs)
  }

  saveSolutions (solutions) {
    this.props.onSaveSolutions(solutions)
  }

  handleShowJSONEditor = () => {
    this.setState({ showJSONEditor: !this.state.showJSONEditor })
  }

  render () {
    const formSection = JSON.parse(JSON.stringify(this.state.formSection))
    // console.log(this.props);
    return (
      <>
        <Container style={styles.sectionContainer}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            {formSection && formSection.sectionType === 'projectPlan'
              ? (
                <Grid container>
                  <Grid item sx={{ paddingTop: '4px', display: 'flex', alignItems: 'center' }}>
                    <Chip size='small' color='primary' label={formSection.sectionTitle} />
                  </Grid>
                  <Grid item xs sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextInputBasic
                      id='sectionBody'
                      name='sectionBody'
                      inputValue={formSection.sectionBody}
                      placeholder='Untitled Section'
                      onChange={(inputValue) => this.handleChange('sectionBody', inputValue)}
                    />
                  </Grid>
                </Grid>
                )
              : (
                <div style={styles.sectionTitleGrid}>
                  <TextInputBasic
                    id='sectionTitle'
                    name='sectionTitle'
                    inputValue={formSection.sectionTitle}
                    placeholder='Untitled Section'
                    onChange={(inputValue) => this.handleChange('sectionTitle', inputValue)}
                  />
                </div>
                )}
            <div style={styles.gridSectionIcon}>
              <span style={styles.sectionGridSpan}>
                {Permissions.hasRole('super_user')
                  ? (
                    <span>
                      {/** Pop open a modal with the JSON from this field */}
                      <IconButton onClick={this.handleShowJSONEditor}>
                        <AdminIcon size='small' style={{ color: 'red' }} />
                      </IconButton>
                    </span>
                    )
                  : null}

                <Tooltip title='Section Settings' placement='top-start'>
                  <span>
                    <IconButton style={styles.sectionIcon} onClick={this.handleDrawerSectionSettings}>
                      <TuneIcon />
                    </IconButton>
                  </span>
                </Tooltip>

                <OptionsDialogSection onChange={() => this.deleteSection()} onAddQuestion={() => this.addQuestion()} />
                {/* <Tooltip title={"Delete"} placement={"top-start"}>
                                    <IconButton className={"section-icon"}
                                                type={"button"}
                                                onClick={this.deleteSection}
                                    >
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Tooltip> */}
                <Tooltip title='Move section down' placement='top-start'>
                  <span>
                    <IconButton style={styles.sectionIcon} disabled={!this.props.onMoveDown} onClick={this.props.onMoveDown}>
                      <ArrowDownwardIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title='Move section up' placement='top-start'>
                  <span>
                    <IconButton style={styles.sectionIcon} disabled={!this.props.onMoveUp} onClick={this.props.onMoveUp}>
                      <ArrowUpwardIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                {this.state.expanded
                  ? (
                    <Tooltip title='Collapse' placement='top-start'>
                      <span>
                        <IconButton style={styles.sectionIcon} disabled={false} onClick={() => this.setState({ expanded: false })}>
                          <UnfoldLessIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    )
                  : (
                    <Tooltip title='Expand' placement='top-start'>
                      <span>
                        <IconButton style={styles.sectionIcon} disabled={false} onClick={() => this.setState({ expanded: true })}>
                          <UnfoldMoreIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    )}
              </span>
            </div>
          </div>
        </Container>

        <div style={styles.sectionFieldsWrapper}>
          {this.state.expanded && formSection.fields && formSection.fields.length !== 0 && (
            <Container style={styles.sectionFieldsContainer}>
              {formSection.fields.map((ftype, idx) => {
                // TODO SEAN
                return (
                  <SingleFieldEditor
                    {...this.props}
                    key={`field_${idx}-${ftype.id}`}
                    field={ftype}
                    guid={this.props.guid}
                    assetsConfig={this.props.assetsConfig}
                    solutionsConfig={this.props.solutionsConfig}
                    crmFieldsConfig={this.props.crmFieldsConfig}
                    numericUnitsConfig={this.props.numericUnitsConfig}
                    allFields={this.props.allFields}
                    allFieldsSolutions={this.props.allFieldsSolutions}
                    form={this.props.form}
                    onSaveSolutions={(solutions) => this.saveSolutions(solutions)}
                    onChange={(field) => this.changeHandlerField(field, idx)}
                    onDelete={() => this.deleteHandlerField(idx)}
                    onMoveUp={
                      idx > 0
                        ? () => {
                            this.handleMoveUp(idx)
                          }
                        : null
                    }
                    onMoveDown={idx === formSection.fields.length - 1 ? null : () => this.handleMoveDown(idx)}
                  />
                )
              })}
            </Container>
          )}
        </div>

        <DrawerSectionSettings
          {...this.props}
          open={this.state.showSectionSettings}
          onClose={() => {
            this.setState({ showSectionSettings: !this.state.showSectionSettings })
          }}
          onDone={(fs) => {
            this.handleChangeForm(fs)
            this.setState({ showSectionSettings: !this.state.showSectionSettings })
          }}
        />

        {this.state.showJSONEditor
          ? <DialogGeneric
              title='JSON Field Editor'
              fullWidth
              maxWidth='md'
              content={
                <JSONEditor
                  value={JSON.stringify(formSection)}
                  onChange={(value) => {
                    this.handleChangeFreeForm(JSON.parse(value))
                  }}
                  saveOnlyOnDone
                />
                    }
              onClose={this.handleShowJSONEditor}
              onSave={this.handleShowJSONEditor}
            />
          : null}

      </>
    )
  }
}
export default SingleSectionEditor

const DrawerSectionSettings = (props) => {
  const [isLoading, setIsLoading] = useState(true)

  const [sectionType, setSectionType] = useState('standard')
  const [sectionTitle, setSectionTitle] = useState('')
  const [sectionBody, setSectionBody] = useState('')
  const [sectionHelpText, setSectionHelpText] = useState('') // standard only
  const [sectionStep, setSectionStep] = useState(1) // projectPlan only
  const [subArea, setSubArea] = useState(false)
  const [subAreaOnly, setSubAreaOnly] = useState(false)
  const [sectionCollapse, setSectionCollapse] = useState(false) // standard only
  const [multiple, setMultiple] = useState(false) // standard only
  const [multipleSubArea, setMultipleSubArea] = useState(false) // standard only
  const [module, setModule] = useState(false) // standard only DEV-5270

  useEffect(() => {
    if (isLoading) {
      // Autofix stuff.
      const fs = props.formSection
      if (fs.sectionType === undefined) {
        fs.sectionType = 'standard'
      }
      setSectionType(fs.sectionType)
      if (fs.sectionTitle === undefined) {
        fs.sectionTitle = ''
      }
      setSectionTitle(fs.sectionTitle)
      if (fs.sectionBody === undefined) {
        fs.sectionBody = ''
      }
      setSectionBody(fs.sectionBody)
      if (fs.sectionHelpText === undefined) {
        fs.sectionHelpText = ''
      }
      setSectionHelpText(fs.sectionHelpText)
      if (fs.subArea === undefined) {
        fs.subArea = false
      }
      setSubArea(fs.subArea)
      if (fs.subAreaOnly === undefined) {
        fs.subAreaOnly = false
      }
      setSubAreaOnly(fs.subAreaOnly)
      if (fs.sectionCollapse === undefined) {
        fs.sectionCollapse = false
      }
      if (fs.module === undefined) {
        fs.module = false
      }
      setModule(fs.module)
      setSectionCollapse(fs.sectionCollapse)
      if (fs.multiple === undefined) {
        fs.multiple = false
      }
      setMultiple(fs.multiple)
      if (fs.multipleSubArea === undefined) {
        fs.multipleSubArea = false
      }
      setMultipleSubArea(fs.multipleSubArea)
      if (fs.sectionType === 'projectPlan') {
        setSectionStep(fs.sectionTitle)
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const handleClose = (e) => {
    props.onClose()
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'sectionType') {
      if (sectionType === 'standard') {
        // coming FROM standard
        // sectionBody = sectionTitle; sectionTitle = sectionStep;
        setSectionBody(sectionTitle)
        setSectionTitle(sectionStep)
      } else if (sectionType === 'projectPlan') {
        // coming FROM projectPlan
        // sectionTitle = sectionBody; sectionBody = ''
        setSectionTitle(sectionBody)
        setSectionBody('')
        setSectionStep(1)
      }
      setSectionType(value)
    } else if (name === 'sectionType') {
      setSectionType(value)
    } else if (name === 'sectionHelpText') {
      setSectionHelpText(value)
    } else if (name === 'subArea') {
      setSubArea(!subArea)
      if (subArea) {
        // If subArea was true set all its dependencies to false
        setSubAreaOnly(false)
      }
    } else if (name === 'subAreaOnly') {
      setSubAreaOnly(!subAreaOnly)
    } else if (name === 'sectionCollapse') {
      setSectionCollapse(!sectionCollapse)
    } else if (name === 'multiple') {
      setMultiple(!multiple)
      if (multiple) {
        // If multiple was true set all its dependencies to false
        setMultipleSubArea(false)
      }
    } else if (name === 'multipleSubArea') {
      setMultipleSubArea(!multipleSubArea)
    } else if (name === 'sectionStep') {
      setSectionStep(value)
      setSectionTitle(value)
    } else if (name === 'module') {
      setModule(!module)
      if (module) {
        setModule(false)
      }
    }
  }

  const handleDone = () => {
    const fs = JSON.parse(JSON.stringify(props.formSection)) // Passed from SingleSectionEditor.js
    fs.sectionTitle = sectionTitle
    fs.sectionBody = sectionBody
    fs.sectionType = sectionType
    fs.sectionHelpText = sectionHelpText
    fs.subArea = subArea
    fs.subAreaOnly = subAreaOnly
    fs.sectionCollapse = sectionCollapse
    fs.multiple = multiple
    fs.multipleSubArea = multipleSubArea
    fs.module = module
    props.onDone(fs)
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <Drawer
      key='form-section-settings'
      open={props.open}
      PaperProps={{
        sx: { width: '560px !important' }
      }}
      anchor='right'
      variant='temporary'
      onKeyDown={stopPropagationForTab}
      onClose={handleClose}
    >
      <DrawerHeader
        icon={
          <IconButton>
            <TuneIcon />
          </IconButton>
        }
        title='Section Settings'
        onClose={handleClose}
      />

      {props.formSection.sectionTitle
        ? (
          <Box xs={12} sx={{ pl: 2, pr: 2, pb: 2, pt: 2, borderBottom: '1px solid rgba(0,0,0,.08)' }}>
            <Typography style={{ fontWeight: 'bold' }}>{props.formSection.sectionTitle}</Typography>
          </Box>
          )
        : null}

      <Box sx={{ p: 2 }}>
        <Grid container>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <FormControl fullWidth>
              <InputLabel id='sectionTypeLabel'>Section Type</InputLabel>
              <Select labelId='sectionTypeLabel' id='sectionType' name='sectionType' value={sectionType} label='Section Type' onChange={handleChange}>
                <MenuItem value='standard'>Standard</MenuItem>
                <MenuItem value='projectPlan'>Project Step</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {sectionType === 'standard'
            ? (
              <Grid item xs={12}>
                <TextField
                  id='sectionHelpText'
                  name='sectionHelpText'
                  label='Help Text'
                  fullWidth
                  multiline
                  rows={3}
                  variant='outlined'
                  value={sectionHelpText}
                  onChange={handleChange}
                />
              </Grid>
              )
            : (
              <Grid item xs={12}>
                <TextField
                  id='sectionStep'
                  name='sectionStep'
                  label='Step'
                  fullWidth
                  variant='outlined'
                  value={sectionStep}
                  inputProps={{ maxLength: 3 }}
                  onChange={handleChange}
                />
              </Grid>
              )}

          {sectionType === 'standard'
            ? (
              <>
                <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Typography variant='body2'>Allow Section to be Collapsed</Typography>
                </Grid>
                <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <FormControlLabel
                    name='sectionCollapse'
                    sx={{ pr: 0, mr: -1 }}
                    control={
                      <Switch
                        checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                        id='sectionCollapse'
                        checked={sectionCollapse}
                        color='primary'
                        onClick={handleChange}
                      />
                  }
                    onChange={handleChange}
                  />
                </Grid>
              </>
              )
            : null}

          <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Typography variant='body2'>Visible in Sub-Areas</Typography>
          </Grid>
          <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <FormControlLabel
              name='subArea'
              sx={{ pr: 0, mr: -1 }}
              control={
                <Switch checkedIcon={<CheckCircleRoundedIcon fontSize='small' />} id='subArea' checked={subArea} color='primary' onClick={handleChange} />
              }
              onChange={handleChange}
            />
          </Grid>

          {subArea === true
            ? (
              <>
                <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Typography variant='body2'>Visible in Sub-Areas Only</Typography>
                </Grid>
                <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <FormControlLabel
                    name='subAreaOnly'
                    sx={{ pr: 0, mr: -1 }}
                    control={
                      <Switch
                        checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                        id='subAreaOnly'
                        checked={subAreaOnly}
                        color='primary'
                        onClick={handleChange}
                      />
                  }
                    onChange={handleChange}
                  />
                </Grid>
              </>
              )
            : null}

          {sectionType === 'standard'
            ? (
              <>
                <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Typography variant='body2'>Allow to be Duplicated</Typography>
                </Grid>
                <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <FormControlLabel
                    name='multiple'
                    sx={{ pr: 0, mr: -1 }}
                    control={
                      <Switch
                        checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                        id='multiple'
                        checked={multiple}
                        color='primary'
                        onClick={handleChange}
                      />
                  }
                    onChange={handleChange}
                  />
                </Grid>
              </>
              )
            : null}

          {sectionType === 'standard' && subArea === true && multiple === true
            ? (
              <>
                <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Typography variant='body2'>Allow to be Duplicated in Sub-Area</Typography>
                </Grid>
                <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <FormControlLabel
                    name='multipleSubArea'
                    sx={{ pr: 0, mr: -1 }}
                    control={
                      <Switch
                        checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                        id='multipleSubArea'
                        checked={multipleSubArea}
                        color='primary'
                        onClick={handleChange}
                      />
                  }
                    onChange={handleChange}
                  />
                </Grid>
              </>
              )
            : null}

          {sectionType === 'standard'
            ? (
              <>
                <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Typography variant='body2'>Edit Section in Separate Screen</Typography>
                </Grid>
                <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <FormControlLabel
                    name='module'
                    sx={{ pr: 0, mr: -1 }}
                    control={
                      <Switch
                        checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                        id='multiple'
                        checked={module}
                        color='primary'
                        onClick={handleChange}
                      />
                  }
                    onChange={handleChange}
                  />
                </Grid>
              </>
              )
            : null}
        </Grid>
      </Box>

      <DrawerFooter onClose={handleClose} onDone={handleDone} />
    </Drawer>
  )
}

const styles = {
  sectionContainer: {
    marginTop: '24px',
    paddingLeft: '0',
    paddingRight: '0',
    marginBottom: '10px'
  },
  gridSectionIcon: {
    paddingLeft: '10px',
    width: '42%'
  },
  sectionIcon: {
    marginTop: '4px',
    padding: '8px'
  },
  sectionGridSpan: {
    float: 'right'
  },
  sectionTitleGrid: {
    display: 'table',
    width: '100%'
  },
  sectionFieldsContainer: {
    paddingLeft: '0',
    paddingRight: '0',
    display: 'flex',
    flexDirection: 'column'
  }
}
