import React, { useEffect, useState } from 'react'
import DataGrid from 'components/Reusable/DataGrid'
import withScreenWrapper from '../../screens/withScreenWrapper'
import Company from '../Company'
import Api from '../../library/Api'
import {
  Box, Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select, Typography
} from '@mui/material'
import WhatshotIcon from '@mui/icons-material/Whatshot'
import Helper from '@supportworks/helper'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { BarChart, LineChart } from '@mui/x-charts'

const SolutionsReport = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')
  const [response, setResponse] = useState({})
  const [errorStartDate, setErrorStartDate] = useState(false)
  const [errorEndDate, setErrorEndDate] = useState(false)
  const [disposition, setDisposition] = useState('all')
  const [author, setAuthor] = useState('all')
  // const [rspAuthor, setRspAuthor] = useState({})
  const [users, setUsers] = useState([])
  const [xRow, setXRow] = useState([])
  const [yCol1, setYCol1] = useState([])
  const [yCol2, setYCol2] = useState([])
  const [yCol3, setYCol3] = useState([])
  const [yCol4, setYCol4] = useState([])
  const [yCol5, setYCol5] = useState([])
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 })
  const [showCharts, setShowCharts] = useState(false)

  useEffect(() => {
    if (isLoading) {
      const currDate = new Date()
      const lastThirtyDays = new Date(new Date().setDate(currDate.getDate() - 30))

      setStart(dayjs(lastThirtyDays))
      setEnd(dayjs(currDate))

      getUsers()
      handleExecuteReport(lastThirtyDays, currDate, author, disposition)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const getUsers = () => {
    Api.callRegisteredMethod('getUsers').then((users) => {
      const authors = []
      for (let i = 0; i < users.persons.length; i++) {
        authors.push(users.persons[i].person)
      }

      setUsers(authors)
    })
  }

  const handleExecuteReport = (start, end, author, disposition) => {
    if (!errorStartDate && !errorEndDate) {
      start = Helper.formatDate(start, 'yyyy-mm-dd')
      end = Helper.formatDate(end, 'yyyy-mm-dd')

      const params = { start, end }

      if (author !== 'all') {
        params.authorID = author
      }
      if (disposition !== 'all') {
        params.dispositionResult = disposition
      }
      // console.log(params)
      Api.callRegisteredMethod('getSummarizedSolutions', params).then((rsp) => {
        setResponse(rsp)
        generateChartDataSet(rsp, paginationModel)
      })
    }
  }

  const handleChangeDateStart = (e) => {
    setStart(dayjs(e.$d))
  }

  const handleChangeDateEnd = (e) => {
    setEnd(dayjs(e.$d))
  }

  const handleChangeDisposition = (event) => {
    setDisposition(event.target.value)
    // handleExecuteReport(start, end, author, event.target.value)
  }

  const handleChangeAuthor = (event) => {
    setAuthor(event.target.value)
    // handleExecuteReport(start, end, event.target.value, disposition)
  }

  const handleOnPaginationModelChange = (model, details) => {
    setPaginationModel(model)
    generateChartDataSet(response, model)
  }

  const generateChartDataSet = (response, model) => {
    if (response.length > 0) {
      const newXRow = []
      const newYCol1 = []
      const newYCol2 = []
      const newYCol3 = []
      const newYCol4 = []
      const newYCol5 = []

      /* eslint no-unreachable-loop: ["error", { "ignore": ["ForStatement"] }] */
      for (let i = model.page * model.pageSize; i < response.length; i += model.pageSize) {
        const batch = response.slice(i, i + model.pageSize)

        for (let j = 0; j < batch.length; j++) {
          newXRow.push(batch[j].title)
          newYCol1.push(batch[j].appointments)
          newYCol2.push(batch[j].averageTotal)
          newYCol3.push(batch[j].averageAddedTotal)

          if ('accepted' in batch[j].statuses) {
            newYCol4.push(batch[j].statuses.accepted.count)
          }

          if ('declined' in batch[j].statuses) {
            newYCol5.push(batch[j].statuses.declined.count)
          }
        }
        break
      }
      setXRow(newXRow)
      setYCol1(newYCol1)
      setYCol2(newYCol2)
      setYCol3(newYCol3)
      setYCol4(newYCol4)
      setYCol5(newYCol5)
      setShowCharts(true)
    } else {
      setShowCharts(false)
    }
  }

  const columns = [
    {
      flex: 1,
      type: 'string',
      field: 'solution',
      headerName: 'Solutions',
      editable: false,
      sortable: true
    },
    {
      flex: 1,
      type: 'number',
      field: 'appts',
      headerName: 'Appointments',
      editable: false,
      sortable: true,
      align: 'right',
      headerAlign: 'right',
      renderCell: params => {
        const formattedValue = (params.value && params.value !== null) ? `${Number(params.value)}` : Number(0)
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>{formattedValue}</Box>
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'avgTotal',
      headerName: 'Avg Total',
      editable: false,
      sortable: true,
      align: 'right',
      headerAlign: 'right',
      renderCell: params => {
        const formattedValue = params.value ? Number(params.value).toFixed(2) : '0.00'
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>${formattedValue}</Box>
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'avgAccepted',
      headerName: 'Avg Accepted',
      editable: false,
      sortable: true,
      align: 'right',
      headerAlign: 'right',
      renderCell: params => {
        const formattedValue = params.value ? Number(params.value).toFixed(2) : '0.00'
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>${formattedValue}</Box>
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'accepted',
      headerName: 'Accepted',
      editable: false,
      sortable: true,
      align: 'right',
      headerAlign: 'right',
      renderCell: params => {
        const formattedValue = (params.value && params.value !== null) ? `${Number(params.value)}` : Number(0)
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>{formattedValue}</Box>
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'declined',
      headerName: 'Declined',
      editable: false,
      sortable: true,
      align: 'right',
      headerAlign: 'right',
      renderCell: params => {
        const formattedValue = (params.value && params.value !== null) ? `${Number(params.value)}` : Number(0)
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>{formattedValue}</Box>
      }
    }
  ]

  const rows = []
  for (let i = 0; i < response.length; i++) {
    // console.log(response[i])
    const solution = response[i].title
    const appts = response[i].appointments
    const avgTotal = Number(response[i].averageTotal)
    // `$${new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(parseFloat(response[i].averageTotal))}`
    const avgAccepted = Number(response[i].averageAddedTotal)
    // `$${new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(parseFloat(response[i].averageAddedTotal))}`
    let accepted, declined

    if ('accepted' in response[i].statuses) {
      accepted = response[i].statuses.accepted.count
    }

    if ('declined' in response[i].statuses) {
      declined = response[i].statuses.declined.count
    }

    rows.push({
      id: i,
      solution,
      appts,
      avgTotal,
      avgAccepted,
      accepted,
      declined
    })
  }

  if (isLoading) {
    return <LoadingPleaseWait />
  }

  return (
    <Box>
      <Company.Header title='Appointment Solutions Summary' />
      <Box sx={{ mb: 3, mt: 3 }}>
        <Grid container spacing={1} alignItems='flex-end'>
          <Grid item sx={{ width: '220px' }}>
            <FormControl size='large' fullWidth>
              <InputLabel>Sales Rep</InputLabel>
              <Select
                label='Sales Rep'
                onChange={handleChangeAuthor}
                value={author}
              >
                <MenuItem key='all' value='all'>All</MenuItem>
                {users.map((user, idx) => {
                  return (
                    <MenuItem key={idx} value={user.id}>{user.nameFirst + ' ' + user.nameLast}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sx={{ width: '220px' }}>
            <FormControl size='large' fullWidth>
              <InputLabel>Result Detail</InputLabel>
              <Select
                label='Result Detail'
                onChange={handleChangeDisposition}
                value={disposition}
              >
                <MenuItem key='all' value='all'>All</MenuItem>
                <MenuItem key='pending' value='pending'>Pending</MenuItem>
                <MenuItem key='sold' value='sold'>Sold</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='Start Date'
                name='startDate'
                value={start}
                onChange={handleChangeDateStart}
                onError={(reason, value) => {
                  if (reason) {
                    setErrorStartDate(true)
                  } else {
                    setErrorStartDate(false)
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='End Date'
                name='endDate'
                value={end}
                onChange={handleChangeDateEnd}
                onError={(reason, value) => {
                  if (reason) {
                    setErrorEndDate(true)
                  } else {
                    setErrorEndDate(false)
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sx={{ marginLeft: 'auto' }}>
            <Button size='medium' variant='contained' color='primary' onClick={() => handleExecuteReport(start, end, author, disposition)} endIcon={<WhatshotIcon />}>
              <Typography variant='button' style={{ textTransform: 'uppercase' }}>Generate</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        onPaginationModelChange={(model, details) => {
          handleOnPaginationModelChange(model, details)
        }}
      />

      {
        showCharts &&
          <>
            <Grid container>
              <Grid item>
                <BarChart
                  width={700}
                  height={600}
                  series={[
                    { data: yCol1, label: 'appointments', id: 'appts' }
                  ]}
                  xAxis={[{ data: xRow, scaleType: 'band' }]}
                  // layout="horizontal"
                  margin={{ bottom: 300 }}
                  slotProps={{
                    legend: {
                      direction: 'row',
                      position: { horizontal: 'middle', vertical: 'top' },
                      padding: 0
                    }
                  }}
                  sx={{ mt: 4 }}
                  bottomAxis={{
                    tickLabelStyle: {
                      angle: 45,
                      textAnchor: 'start',
                      fontSize: 12
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <LineChart
                  width={700}
                  height={600}
                  series={[
                    { data: yCol2, label: 'Average Total', id: 'Avg Total' },
                    { data: yCol3, label: 'Average Accepted', id: 'Avg Accepted' }
                  ]}
                  xAxis={[{ data: xRow, scaleType: 'point' }]}
                  // layout="horizontal"
                  margin={{ bottom: 300 }}
                  slotProps={{
                    legend: {
                      direction: 'row',
                      position: { horizontal: 'middle', vertical: 'top' },
                      padding: 0
                    }
                  }}
                  sx={{ mt: 4 }}
                  bottomAxis={{
                    tickLabelStyle: {
                      angle: 45,
                      textAnchor: 'start',
                      fontSize: 12
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <LineChart
                  width={700}
                  height={600}
                  series={[
                    { data: yCol4, label: 'Accepted', id: 'accepted' }
                  ]}
                  xAxis={[{ data: xRow, scaleType: 'point' }]}
                  // layout="horizontal"
                  margin={{ bottom: 300 }}
                  slotProps={{
                    legend: {
                      direction: 'row',
                      position: { horizontal: 'middle', vertical: 'top' },
                      padding: 0
                    }
                  }}
                  sx={{ mt: 4 }}
                  bottomAxis={{
                    tickLabelStyle: {
                      angle: 45,
                      textAnchor: 'start',
                      fontSize: 12
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <LineChart
                  width={700}
                  height={600}
                  series={[
                    { data: yCol5, label: 'Declined', id: 'declined' }
                  ]}
                  xAxis={[{ data: xRow, scaleType: 'point' }]}
                  // layout="horizontal"
                  margin={{ bottom: 300 }}
                  slotProps={{
                    legend: {
                      direction: 'row',
                      position: { horizontal: 'middle', vertical: 'top' },
                      padding: 0
                    }
                  }}
                  sx={{ mt: 4 }}
                  bottomAxis={{
                    tickLabelStyle: {
                      angle: 45,
                      textAnchor: 'start',
                      fontSize: 12
                    }
                  }}
                />
              </Grid>
            </Grid>

            <LineChart
              width={1500}
              height={600}
              series={[
                { data: yCol1, label: 'appointments', id: 'appts' },
                { data: yCol2, label: 'Average Total', id: 'Avg Total' },
                { data: yCol3, label: 'Average Accepted', id: 'Avg Accepted' },
                { data: yCol4, label: 'Accepted', id: 'accepted' },
                { data: yCol5, label: 'Declined', id: 'declined' }
              ]}
              xAxis={[{ data: xRow, scaleType: 'point' }]}
              margin={{ bottom: 300 }}
              slotProps={{
                legend: {
                  direction: 'row',
                  position: { horizontal: 'middle', vertical: 'top' },
                  padding: 0
                }
              }}
              sx={{ mt: 4 }}
              bottomAxis={{
                tickLabelStyle: {
                  angle: 45,
                  textAnchor: 'start',
                  fontSize: 12
                }
              }}
            />
          </>
      }
    </Box>
  )
}

export default withScreenWrapper(SolutionsReport)
