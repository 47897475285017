import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import RightPanelAccomplishList from './RightPanelAccomplishList'
import RightPanelOverview from './RightPanelOverview'
import RightPanelInspect from './RightPanelInspect'
import RightPanelPresent from './RightPanelPresent'
import RightPanelComplete from './RightPanelComplete'
import RightPanelAllAppointments from './RightPanelAllAppointments'
import RightPanelProjectDisplayPrefs from './RighPanelProjectDisplayPrefs'
import RightPanelPresentVersion3 from './RightPanelPresentVersion3'
import RightPanelProject from './RightPanelProject'
import RightPanelProjectGoals from './RightPanelProjectGoals'
import RightPanelInitialProjects from './RightPanelInitialProjects'

const RightPanel = (props) => {
  const [styleId, setStyleId] = useState('')

  useEffect(() => {
    if (props.rightPanelVariant === 'present') {
      if ('style' in props.meeting && 'id' in props.meeting.style) {
        setStyleId(props.meeting.style.id.toString())
      }
    } else {
      setStyleId('')
    }
  }, [props.rightPanelVariant]) // eslint-disable-line

  return (
    <Grid container style={{ height: '100%' }}>
      {!props.rightPanelVariant
        ? (
          <Grid container spacing={0} direction='row' alignItems='center' justifyContent='center' style={{ minHeight: '50vh' }}>
            <Typography variant='h7'>Please select an editor grouping on the left.</Typography>
          </Grid>
          )
        : null}
      {props.rightPanelVariant === 'accomplishlist' ? <RightPanelAccomplishList {...props} /> : null}
      {props.rightPanelVariant === 'projectGoals' ? <RightPanelProjectGoals {...props} /> : null}
      {props.rightPanelVariant === 'initialProjects' ? <RightPanelInitialProjects {...props} /> : null}
      {props.rightPanelVariant === 'intro' ? <RightPanelOverview {...props} /> : null}
      {props.rightPanelVariant === 'inspect' ? <RightPanelInspect {...props} /> : null}
      {
        (props.rightPanelVariant === 'present' &&
         styleId === '2' &&
         props.meeting?.style?.title === 'Version 2') &&
           <RightPanelPresent {...props} />
      }
      {
        (props.rightPanelVariant === 'present' &&
         styleId === '3' &&
         props.meeting.style?.title === 'Version 3') &&
           <RightPanelPresentVersion3 {...props} />
      }
      {props.rightPanelVariant === 'project' ? <RightPanelProject {...props} /> : null}
      {props.rightPanelVariant === 'complete' ? <RightPanelComplete {...props} /> : null}
      {props.rightPanelVariant === 'allappointments' ? <RightPanelAllAppointments {...props} /> : null}
      {props.rightPanelVariant === 'projectDisplayPrefs' ? <RightPanelProjectDisplayPrefs {...props} /> : null}
    </Grid>
  )
}
export default RightPanel
