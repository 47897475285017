import React, { useEffect, useState } from 'react'
import { Box, Chip, Grid, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import CalculateIcon from '@mui/icons-material/Calculate'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Api from 'library/Api'
import CompanyConfigEdit from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import DialogCalculator, { clearCalc, recurseCalc } from 'components/Reusable/DialogCalculator'
import DataGrid from 'components/Reusable/DataGrid'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import { DialogUpdatingProducts } from 'components/Reusable/DialogUpdatingProducts'
import DrawerDebug from 'components/Reusable/DrawerDebug'
import ExpressionHelper from 'library/ExpressionHelper'
import H from 'library/helper'
import Helper from '@supportworks/helper'
import { LoadingPleaseWait } from '@supportworks/react-components'
import Permissions from 'library/Permissions'

const config = {
  title: 'Materials',
  variant: 'materials',
  tag: 'materials',
  name: 'Materials',
  lockTag: true,
  lockName: true
}

const CompanyConfigMaterials = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [isWorking, setIsWorking] = useState(false)
  const [list, setList] = useState({})
  const [numericUnits, setNumericUnits] = useState([])
  const [showEditComponent, setShowEditComponent] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(-1)

  useEffect(() => {
    if (isLoading) {
      if (props.config && props.config.contentJson) {
        // Fix the contentJson.
        const cj = JSON.parse(props.config.contentJson)
        if (!Array.isArray(cj)) {
          props.config.contentJson = '[]'
        }
        setList(JSON.parse(props.config.contentJson))
        Promise.all([
          Api.callRegisteredMethod('getLatestConfigByTag', { tag: 'numericUnits', startsWith: false }).then(c => {
            if (c && c.contentJson) {
              setNumericUnits(JSON.parse(c.contentJson))
            } else {
              setNumericUnits([{ id: 'EACH', title: 'each' }])
            }
          })
        ]).then(() => {
          setIsLoading(false)
        })
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (list) {
      props.onRef({
        hasFinalizeHook: () => {
          return true
        },
        finalizeHook: list => {
          return finalizeHook(list)
        }
      })
    }
    // eslint-disable-next-line
  }, [list])

  const handleAdd = () => {
    const l = JSON.parse(JSON.stringify(list))
    l.unshift({
      id: Helper.guid(),
      title: 'New Material',
      price: 0,
      units: 'each'
    })
    handleChange(l)
  }

  const handleEdit = idx => {
    setSelectedIndex(idx)
    setShowEditComponent(!showEditComponent)
  }

  const handleChange = l => {
    const c = JSON.parse(JSON.stringify(l))
    setList(c)
    if (props.onChange) {
      props.onChange(JSON.stringify(c))
    }
  }

  const handleDelete = idx => {
    const r = window.confirm('Are you sure you want to delete?')
    if (r) {
      const l = JSON.parse(JSON.stringify(list))
      l.splice(idx, 1)
      handleChange(l)
    }
  }

  const handleMoveUp = idx => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx - 1, 0, ele)
    handleChange(l)
  }

  const handleMoveDown = idx => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx + 1, 0, ele)
    handleChange(l)
  }

  const handleProcessRowUpdate = row => {
    if (row) {
      const l = JSON.parse(JSON.stringify(list))
      l[row.id].title = row.title
      l[row.id].price = row.price
      l[row.id].units = row.units
      setList(l)
      handleChange(l)
      return row
    }
  }

  const handleUnitChange = (e, idx) => {
    const l = JSON.parse(JSON.stringify(list))
    l[idx].units = e.target.value
    setList(l)
    handleChange(l)
  }

  const finalizeHook = async contentJson => {
    if (Permissions.hasRole('super_user')) {
      // ONLY super user thru test
      const r = window.confirm('Perform an auto-sync for matching materials in the master product list?')
      if (r) {
        setIsWorking(true)
        await H.updateProductsFromAssembly({ configMaterials: JSON.parse(contentJson), saveConfigProducts: true })
        setIsWorking(false)
      }
    }
    return contentJson
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    {
      flex: 2,
      field: 'title',
      headerName: 'Title',
      editable: true,
      sortable: false,
      renderCell: params => {
        return <>{params.row.title}</>
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'price',
      headerName: 'Amount',
      editable: true,
      sortable: true,
      preProcessEditCellProps: params => {
        const isValid = !isNaN(params.props.value)
        return { ...params.props, error: !isValid }
      },
      renderCell: params => {
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>${Number(params.value).toFixed(2)}</Box>
      }
    },
    {
      flex: 1,
      type: 'string',
      field: 'units',
      headerName: 'Unit',
      editable: true,
      sortable: true,
      renderCell: params => (
        <Box style={{ display: 'flex', flex: 1 }}>
          <Select
            value={params.value || ''} // Provide an empty string if value is undefined
            onChange={event => {
              handleUnitChange(event, params.row.id)
            }}
            fullWidth
            size='small'
          >
            {numericUnits.map((u, idx) => {
              return (
                <MenuItem key={`unit - ${idx}`} value={u.title}>
                  {u.title}
                </MenuItem>
              )
            })}
          </Select>
        </Box>
      )
    },
    {
      flex: 1,
      type: 'string',
      field: 'components',
      headerName: 'Components',
      editable: false,
      sortable: false,
      renderCell: params => {
        if (!params.value) return ''
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Chip size='small' label={params.value} />
          </Box>
        )
      }
    },
    {
      width: 164,
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: params => {
        if (params.row) {
          return (
            <Grid container justifyContent='flex-end' alignItems='center' spacing={1}>
              <Grid item>
                <IconButton size='small' onClick={() => handleEdit(params.row.id)}>
                  <EditIcon fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id)}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton size='small' disabled={!(params.row.id < list.length - 1)} onClick={() => handleMoveDown(params.row.id)}>
                  <KeyboardArrowDownIcon fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < list.length; x++) {
    const row = {
      id: x,
      title: list[x].title,
      price: list[x].price,
      units: list[x].units,
      actions: '',
      editAmount: true
    }
    if (list[x].components && list[x].components.length > 0) {
      row.components = list[x].components.length + ' component' + (list[x].components.length > 1 ? 's' : '')
      row.editAmount = false
      // TODO Need to disable the Amount so I can't change it, if it has components...
    }
    rows.push(row)
  }

  return (
    <Box>
      <DataGrid
        rows={rows}
        columns={columns}
        onAdd={handleAdd}
        onProcessRowUpdate={row => {
          handleProcessRowUpdate(row)
        }}
        isCellEditable={(params) => params.row.editAmount === true}
      />
      {showEditComponent && (
        <DialogComponentEdit
          title={`Edit Components ${list[selectedIndex]?.title ? '- ' + list[selectedIndex].title : ''}`}
          selectedIndex={selectedIndex}
          list={list}
          onSave={l => {
            setSelectedIndex(-1)
            setShowEditComponent(false)
            handleChange(l)
          }}
          onClose={() => {
            setSelectedIndex(-1)
            setShowEditComponent(false)
          }}
        />
      )}
      {isWorking ? <DialogUpdatingProducts /> : null}
    </Box>
  )
}

const DialogComponentEdit = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [material, setMaterial] = useState({})
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [selectedField, setSelectedField] = useState('')
  const [showDialogCalculator, setShowDialogCalculator] = useState(false)
  const [showDialogTotalCalculator, setShowDialogTotalCalculator] = useState(false)
  const [calcType, setCalcType] = useState('')
  const [total, setTotal] = useState(0)

  const localFunctions = function (key) {
    const component = props.list[props.selectedIndex].components.find(comp => comp.id === key)
    if (component) {
      if (component.type === 'calc') {
        return component.calcTotal
      } else {
        return component.price
      }
    } else {
      return 0
    }
  }

  useEffect(() => {
    if (isLoading) {
      const m = JSON.parse(JSON.stringify(props.list[props.selectedIndex]))
      if (!m.components) m.components = []
      if (!m.calc) m.calc = ''
      if (!m.calcType?.match(/sum|field|calc/)) {
        m.calcType = 'sum'
      }
      if (m.calcType === 'field') {
        setSelectedField(m.calc)
      }
      const [price, calc] = H.getMaterialPriceAndCalc(m)
      m.calc = calc
      m.price = price
      setTotal(price)
      setCalcType(m.calcType)
      clearCalc(m.components)
      recurseCalc(m.components)
      setMaterial(m)
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleSave = () => {
    if (props.onSave) {
      clearCalc(material.components)
      recurseCalc(material.components)
      const l = JSON.parse(JSON.stringify(props.list))
      l[props.selectedIndex] = material
      const [price, calc] = H.getMaterialPriceAndCalc(material)
      l[props.selectedIndex].calc = calc
      l[props.selectedIndex].price = price
      setTotal(price)
      props.onSave(l)
    }
  }

  const handleAdd = () => {
    const m = JSON.parse(JSON.stringify(material))
    m.components.unshift({
      id: Helper.guid(),
      title: 'New Component',
      price: 0,
      type: 'amt'
    })
    setMaterial(m)
  }

  const handleDelete = idx => {
    // We cannot delete this if the id is used in a calc.  They need to clean out all of the other calcs first.
    const m = JSON.parse(JSON.stringify(material))
    for (let x = 0; x < m.components.length; x++) {
      if (m.components[x].calc) {
        const calc = m.components[x].calc
        const calcArray = calc.split(/\s+/)
        for (let y = 0; y < calcArray.length; y++) {
          if (calcArray[y] === m.components[idx].id) {
            // eslint-disable-next-line no-undef
            alert('You cannot delete this component because it is used in a calculation.  Please remove all references to this, and try again.')
            return
          }
        }
      }
    }
    const r = window.confirm('Are you sure you want to delete?')
    if (r) {
      m.components.splice(idx, 1)
      clearCalc(m.components)
      recurseCalc(m.components)
      setMaterial(m)
    }
  }

  const handleMoveUp = idx => {
    const m = JSON.parse(JSON.stringify(material))
    const ele = m.components.splice(idx, 1)[0]
    m.components.splice(idx - 1, 0, ele)
    setMaterial(m)
  }

  const handleMoveDown = idx => {
    const m = JSON.parse(JSON.stringify(material))
    const ele = m.components.splice(idx, 1)[0]
    m.components.splice(idx + 1, 0, ele)
    setMaterial(m)
  }

  const handleProcessRowUpdate = row => {
    if (row) {
      const m = JSON.parse(JSON.stringify(material))
      m.components[row.id].title = row.title
      m.components[row.id].price = row.price
      m.components[row.id].calc = row.calc
      clearCalc(m.components)
      recurseCalc(m.components)
      const [price, calc] = H.getMaterialPriceAndCalc(m)
      m.calc = calc
      m.price = price
      setTotal(m.price)
      setMaterial(m)
      return row
    }
  }

  const handleTypeChange = (e, idx) => {
    const m = JSON.parse(JSON.stringify(material))
    if (idx > -1) {
      m.components[idx].type = e.target.value
      if (e.target.value === 'amt') {
        if (m.components[idx].calc) m.components[idx].calc = undefined
        m.components[idx].price = 0
      } else {
        if (m.components[idx].price) m.components[idx].price = undefined
      }
      const [price, calc] = H.getMaterialPriceAndCalc(m)
      m.calc = calc
      m.price = price
      setTotal(m.price)
      setMaterial(m)
    }
  }

  const handleShowCalculator = idx => {
    setSelectedIndex(idx)
    setShowDialogCalculator(!showDialogCalculator)
  }

  const handleSaveCalculator = calc => {
    const m = JSON.parse(JSON.stringify(material))
    if (selectedIndex > -1) {
      m.components[selectedIndex].calc = calc
      m.components[selectedIndex].price = ''
      clearCalc(m.components)
      recurseCalc(m.components)
      const [price, calc2] = H.getMaterialPriceAndCalc(m)
      m.calc = calc2
      m.price = price
      setTotal(m.price)
      setMaterial(m)
      setSelectedIndex(-1)
      setShowDialogCalculator(!showDialogCalculator)
    }
  }

  // Used by the Material Calculation at the bottom.  If I change my material calculation type.
  const handleCalcTypeChange = event => {
    const m = JSON.parse(JSON.stringify(material))
    if (event.target.value === 'sum') {
      m.calcType = 'sum'
      const [price, calc] = H.getMaterialPriceAndCalc(m)
      m.calc = calc
      m.price = price
      setTotal(m.price)
    } else if (event.target.value === 'field') {
      m.calcType = 'field'
      m.calc = ''
      setTotal(0)
    } else if (event.target.value === 'calc') {
      m.calcType = 'calc'
      m.calc = ''
      setTotal(0)
    }
    setSelectedField('')
    setMaterial(m)
    setCalcType(event.target.value)
  }

  // Used by the Material Calculation at the bottom.  If I have a field calcType and a field change.
  const handleCalcFieldChange = event => {
    const m = JSON.parse(JSON.stringify(material))
    m.calc = event.target.value
    const [price, calc] = H.getMaterialPriceAndCalc(m)
    m.calc = calc
    m.price = price
    setTotal(price)
    setMaterial(m)
    setSelectedField(event.target.value)
  }

  const handleShowTotalCalculator = () => {
    setShowDialogTotalCalculator(!showDialogTotalCalculator)
  }

  const handleSaveTotalCalculator = calc => {
    const m = JSON.parse(JSON.stringify(material))
    m.calc = calc
    const [price, calc2] = H.getMaterialPriceAndCalc(m)
    m.calc = calc2
    m.price = price
    setMaterial(m)
    setTotal(price)
    setShowDialogTotalCalculator(!showDialogTotalCalculator)
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    {
      flex: 2,
      field: 'title',
      headerName: 'Title',
      editable: true,
      sortable: false,
      renderCell: params => {
        return <>{params.row.title}</>
      }
    },
    {
      flex: 1,
      type: 'string',
      field: 'type',
      headerName: 'Type',
      editable: true,
      sortable: true,
      renderCell: params => (
        <Box style={{ display: 'flex', flex: 1 }}>
          <Select
            value={params.value || ''} // Provide an empty string if value is undefined
            onChange={event => {
              handleTypeChange(event, params.row.id)
            }}
            fullWidth
            size='small'
          >
            <MenuItem key='unit - amt' value='amt'>
              amount
            </MenuItem>
            <MenuItem key='unit - calc' value='calc'>
              calc
            </MenuItem>
          </Select>
        </Box>
      )
    },
    {
      flex: 1,
      type: 'number',
      field: 'price',
      headerName: 'Amount',
      editable: true,
      sortable: true,
      preProcessEditCellProps: params => {
        const isValid = !isNaN(params.props.value)
        return { ...params.props, error: !isValid }
      },
      renderCell: params => {
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>{params.value}</Box>
      }
    },
    {
      flex: 1,
      type: 'string',
      field: 'calc',
      headerName: 'Calc',
      editable: false,
      sortable: true,
      renderCell: params => {
        if (params.row.type === 'calc') {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={() => handleShowCalculator(params.row.id)}>
                <CalculateIcon fontSize='small' />
              </IconButton>
              <Typography variant='body2'>{params.value}</Typography>
            </Box>
          )
        }
      }
    },
    {
      flex: 1,
      type: 'string',
      field: 'mixed',
      headerAlign: 'right',
      headerName: 'Total',
      editable: true,
      sortable: true,
      align: 'right',
      preProcessEditCellProps: params => {
        if (params.row.type === 'amt') {
          const isValid = !isNaN(params.props.value)
          if (isValid) {
            params.row.price = Number(params.props.value)
            params.row.calc = undefined
          }
          return { ...params.props, error: !isValid }
        } else {
          const isValid = ExpressionHelper.validPostfix(params.props.value, localFunctions)
          if (isValid) {
            params.row.calc = params.props.value
            params.row.price = undefined
          }
          return { ...params.props, error: !isValid }
        }
      },
      renderCell: params => {
        if (params.row.type === 'calc') {
          let v = 'Press the Calculator'
          if (params.row.calcTotal !== undefined) v = `${Number(params.row.calcTotal).toFixed(2)}`
          return (
            <Grid container alignItems='center'>
              <Grid item xs={6}>
                <IconButton onClick={() => handleShowCalculator(params.row.id)}>
                  <CalculateIcon fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body2' align='right'>
                  {v}
                </Typography>
              </Grid>
            </Grid>
          )
        } else {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Typography variant='body2'>{Number(params.value).toFixed(2)}</Typography>
            </Box>
          )
        }
      }
    },
    {
      width: 164,
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: params => {
        if (params.row) {
          return (
            <Grid container justifyContent='flex-end' alignItems='center' spacing={1}>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id)}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton size='small' disabled={!(params.row.id < material.components.length - 1)} onClick={() => handleMoveDown(params.row.id)}>
                  <KeyboardArrowDownIcon fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < material.components.length; x++) {
    const row = {
      id: x,
      rowId: material.components[x].id,
      title: material.components[x].title,
      type: material.components[x].type,
      price: material.components[x].price,
      calc: material.components[x].calc,
      calcTotal: material.components[x].calcTotal,
      mixed: material.components[x].type === 'amt' ? material.components[x].price : material.components[x].calc,
      actions: ''
    }
    rows.push(row)
  }

  const jsx = (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        onAdd={handleAdd}
        onProcessRowUpdate={row => {
          handleProcessRowUpdate(row)
        }}
        columnVisibility={{
          rowId: false,
          calc: false,
          price: false
        }}
      />
      {showDialogCalculator ? <DialogCalculator title='Calculator' defaultComponents={material.components} selectedIndex={selectedIndex} onSave={handleSaveCalculator} onClose={handleShowCalculator} /> : null}

      {showDialogTotalCalculator ? <DialogCalculator title='Material Calculator' variant='show-all' parent={material} defaultComponents={material.components} selectedIndex={-1} onSave={handleSaveTotalCalculator} onClose={handleShowTotalCalculator} /> : null}

      {/* Dropdown for Calculation Options */}
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} sm={4} sx={{ mr: 1 }}>
          <TextField select label='Material Calculation' value={calcType} onChange={handleCalcTypeChange} fullWidth>
            <MenuItem key='sum' value='sum'>
              Sum of all Components
            </MenuItem>
            <MenuItem key='field' value='field'>
              Component Field
            </MenuItem>
            <MenuItem key='calc' value='calc'>
              Custom Calculation
            </MenuItem>
          </TextField>
        </Grid>
        {calcType === 'field' && (
          <Grid item xs={12} sm={2} sx={{ pr: 2 }}>
            <TextField select label='Select Field' value={selectedField} onChange={handleCalcFieldChange} fullWidth>
              <MenuItem value=''>Select Component</MenuItem>
              {material.components.map(field => (
                <MenuItem key={field.id} value={field.id}>
                  {field.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
        {calcType === 'calc' && (
          <Grid item sx={{ pt: 1, pr: 1 }}>
            <IconButton onClick={handleShowTotalCalculator}>
              <CalculateIcon />
            </IconButton>
          </Grid>
        )}
        <Grid item xs={12} sm={2}>
          <TextField label='Total' value={`$${Number(total).toFixed(2)} `} disabled fullWidth />
        </Grid>
      </Grid>
      {Permissions.hasRole('super_user') ? <DrawerDebug json={[{ name: 'Material', json: material }]} /> : null}
    </>
  )

  return <DialogGeneric fullScreen showBackArrow title={props.title} content={jsx} fullWidth onChange={handleSave} onClose={props.onClose} />
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Edit: props => <CompanyConfigEdit {...config} {...props} editorComponent={CompanyConfigMaterials} />,
  Index: props => <CompanyConfigIndex {...config} {...props} />
}
