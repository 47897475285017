import React, { useState, useEffect } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { FormCardInspect } from './components/FormCardInspect'
import { DialogFormSelector } from 'components/Reusable/DialogFormSelector'

const RightPanelInspect = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [list, setList] = useState([])
  const [formsConfig, setFormsConfig] = useState([])

  useEffect(() => {
    if (isLoading) {
      if (props.meeting.inspect) {
        setList(props.meeting.inspect)
      }
      if (props.formsConfig) {
        const l = props.formsConfig.filter((f) => f.formType.match(/inspect/i))
        setFormsConfig(l)
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  // Update the list when the meeting changes
  useEffect(() => {
    if (props.meeting.inspect) {
      setList(props.meeting.inspect)
    }
  }, [props.meeting]) // eslint-disable-line

  const handleChange = (l) => {
    const m = JSON.parse(JSON.stringify(props.meeting))
    // Auto cleanup p/DEV-3823
    for (let x = 0; x < l.length; x++) {
      if (!l[x].solutions) {
        l[x].solutions = { default: [], title: 'Solutions' }
      }
      if (!l[x].issues) {
        l[x].issues = { default: [], title: 'Issues' }
      }
      if (!('visible' in l[x])) {
        l[x].visible = true
      }
      const current = list.find(o => o.formId === l[x].formId)
      if (current) {
        l[x] = current
      }
      // DEV-3823 set type to formId because it breaks stuff.  Just goin with it.
      l[x].type = l[x].formId
    }
    m.inspect = l
    props.setMeeting(m)
    setList(l)
  }

  const handleDelete = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    l.splice(idx, 1)
    handleChange(l)
  }

  const handleMoveUp = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx - 1, 0, ele)
    handleChange(l)
  }

  const handleMoveDown = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx + 1, 0, ele)
    handleChange(l)
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Box style={styles.container} sx={{ p: 2 }}>
        <Stack sx={{ pb: 2 }}>
          <Typography variant='h6'>Inspect Forms</Typography>
          <Typography variant='body2'>
            Add the default forms you want available for inspections.  Once you add presentations, sort the presentations to your preferred order.
          </Typography>
        </Stack>

        <>
          {list.map((form, idx) => {
            const fc = formsConfig.find(o => o.id === form.formId)
            if (fc) {
              return (
                <FormCardInspect
                  key={`form-${form.formId}-${idx}`}
                  {...props}
                  idx={idx}
                  form={form}
                  formsConfig={fc}
                  onMoveUp={idx > 0 ? () => handleMoveUp(idx) : null}
                  onDelete={() => { handleDelete(idx) }}
                  onMoveDown={idx < list.length - 1 ? () => handleMoveDown(idx) : null}
                />
              )
            }
            if (fc === undefined) {
              return (
                <FormCardInspect
                  key={`form-deleted-${form.formId}-${idx}`}
                  {...props}
                  idx={idx}
                  form={form}
                  formsConfig={form}
                  onMoveUp={idx > 0 ? () => handleMoveUp(idx) : null}
                  onDelete={() => { handleDelete(idx) }}
                  onMoveDown={idx < list.length - 1 ? () => handleMoveDown(idx) : null}
                  formStatus='undefined'
                />
              )
            }
            return null
          })}
        </>

        <Box>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setShowAddDialog(!showAddDialog)
            }}
          >
            Add Form
          </Button>
        </Box>
      </Box>

      {showAddDialog
        ? (
          <DialogFormSelector
            title='Select Forms'
            list={list}
            formsConfig={formsConfig}
            onSave={(l) => {
              handleChange(l)
              setShowAddDialog(!showAddDialog)
            }}
            onClose={() => setShowAddDialog(!showAddDialog)}
            type='inspect'
          />
          )
        : null}
    </>
  )
}
export default RightPanelInspect

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'white'
  }
}
