import React, { useEffect, useState } from 'react'
import { FormControl, MenuItem, Select } from '@mui/material'

function CalcOperator (props) {
  // const [open, setOpen] = useState(false);
  // const [currCustomExp, setCurrentCustomExp] = useState(null);
  const [operatorLocal, setOperatorLocal] = useState(props.opr)
  // const [isCustomExp, setIsCustomExp] = useState(false);

  const changeHandlerOperator = (event) => {
    setOperatorLocal(event.target.value)
    props.onChangeOperator(event.target.value, props.elemIdx)
  }
  // console.log(props);

  const operators = [
    { value: '', name: 'none' },
    { value: '*', name: 'multiplied (x) by' },
    { value: '+', name: 'added (+) to' },
    { value: '-', name: 'subtracted (-) from' },
    { value: '/', name: 'divided (/) by' }
  ]

  useEffect(() => {
    setOperatorLocal(props.opr)
  }, [props.opr])

  /* useEffect( () => {
        let ceFound = operators.some((el)=>{
            return props.opr === el.value;
        });
        //console.log(props);
        //console.log(ceFound);
        if(!ceFound) {
            //console.log('here');
            setCurrentCustomExp(props.opr);
            setIsCustomExp(!isCustomExp);
        }
    }, [props])

    useEffect( () => {
        setCurrentCustomExp(currCustomExp);
        setOperatorLocal(currCustomExp);
    }, [currCustomExp])

    useEffect( () => {
        setOperatorLocal(operatorLocal);
    }, [operatorLocal])

    const handleCloseCustomExp = () => {
        setOpen(false);
    };

    /!*const handleOpenCustomExp = () => {
        setOpen(true);
    };*!/

    const handleOpenCustomExp = () => {
        setOpen(true);
    };

    const changeHandlerCustomExp = (event) => {
        setCurrentCustomExp(event.target.value);
        setOperatorLocal(event.target.value);
    }

    const saveHandlerCustomExp = () => {
        setOpen(false);
        props.onChangeOperator(currCustomExp, props.elemIdx);
    }

    const cancelHandlerCustomExp = () => {
        setOpen(false);
    } */

  return (
    <div style={styles.triggerPanelElSelect}>
      <FormControl variant='standard' className='options-in-addlogic' size='small' style={styles.formControlOperator}>
        <Select style={styles.selectOperator} value={operatorLocal} onChange={changeHandlerOperator} labelId='calc-operator-label' label='Select operator' autoWidth>
          {operators.map((option, idx) => {
            return (
              <MenuItem key={option.name} value={option.value}>
                {option.name}
              </MenuItem>
            )
          })}

          {/* <MenuItem
                        key={'customExp'}
                        value={currCustomExp}
                        onClick={handleOpenCustomExp}
                        //selected={isCustomExp}
                    >
                        {currCustomExp || 'custom expression'}
                    </MenuItem> */}
        </Select>
      </FormControl>

      {/* <Dialog
                open={open}
                onClose={handleCloseCustomExp}
                PaperProps={{
                    sx: {
                        padding: "20px"
                    }
                }}
            >
                <span style={styles.header}>{"Custom Expression"}</span>
                <span style={styles.subHeader}>{"The calculator will not compute below this number"}</span>
                <TextField
                    key={"customExpCalc"}
                    style={styles.textInput}
                    id="filled-basic"
                    size={"small"}
                    variant="filled"
                    onChange={changeHandlerCustomExp}
                    value={currCustomExp}
                    fullWidth
                />
                <div style={styles.buttons}>
                    <Button
                        style={styles.saveBtn}
                        size={"small"}
                        onClick={saveHandlerCustomExp}
                    >
                        Save
                    </Button>
                    <Button
                        style={styles.cancelBtn}
                        size={"small"}
                        onClick={cancelHandlerCustomExp}
                    >
                        Cancel
                    </Button>
                </div>
            </Dialog> */}
    </div>
  )
}

const styles = {
  triggerPanelElSelect: {
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '5px'
  },
  selectOperator: {
    fontSize: '13px',
    color: '#1976d2',
    paddingTop: 0,
    paddingBottom: 0,
    minWidth: '150px'
  },
  formControlOperator: {
    marginTop: '10px'
  }
  /* subHeader: {
        fontSize: "13px",
        color: "#000000a3",
        display: "block",
        marginBottom: "6px"
    },
    buttons: {
        marginTop: "15px",

    },
    textInput: {
        marginTop: "10px"
    },
    saveBtn: {
        float: "right"
    },
    cancelBtn: {
        float: "right"
    } */
}

export default CalcOperator

/*
function CustomExpressionDialog(props) {
    //console.log(props);
    const [open, setOpen] = useState(false);
    const [customExpression, setCustomExpression] = useState(props.currVal || '');
    const [itemCustomExp, setItemCustomExp] = useState(props.currVal !== undefined && props.currVal !== "");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const changeHandler = (event) => {
        setCustomExpression(event.target.value);
    }

    const saveHandler = () => {
        setOpen(false);
        setItemCustomExp(true);
        props.onSave(customExpression);
    }

    const cancelHandler = () => {
        setOpen(false);
    }

    //console.log(customExpression);

    return(
        <div>
            <MenuItem
                key={'customExpression'}
                onClick={handleClickOpen}
                selected={itemCustomExp}
                value={customExpression}
            >
                custom expression
            </MenuItem>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        padding: "20px"
                    }
                }}
            >
                <span style={styles.header}>{"Custom Expression"}</span>
                <span style={styles.subHeader}>{"The calculator will not compute below this number"}</span>
                <TextField
                    key={"customExpCalc"}
                    style={styles.textInput}
                    id="filled-basic"
                    size={"small"}
                    variant="filled"
                    onChange={changeHandler}
                    value={customExpression}
                    fullWidth
                />
                <div style={styles.buttons}>
                    <Button
                        style={styles.saveBtn}
                        size={"small"}
                        onClick={saveHandler}
                    >
                        Save
                    </Button>
                    <Button
                        style={styles.cancelBtn}
                        size={"small"}
                        onClick={cancelHandler}
                    >
                        Cancel
                    </Button>
                </div>
            </Dialog>
        </div>
    );
} */
