import { useEffect, useState } from 'react'
import { Box, Grid, Chip, Tooltip, Typography, IconButton } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'

export const LeftPanelSelection = (props) => {
  const [isHover, setIsHover] = useState(false)

  let selected = false
  if (props.rightPanelVariant === props.subVariant) selected = true
  return (
    <Box
      key={props.title}
      onClick={props.onClick}
      style={{ ...styles.selectionContainer, backgroundColor: isHover ? '#F2F2F2' : 'white' }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Grid container sx={{ pl: 2, pt: 1, pb: 1, pr: 2 }} style={selected ? styles.selected : null}>
        <Grid item sm={11}>
          <Grid container wrap='nowrap' alignItems='center'>
            {props.onLock
              ? (
                <Grid item>
                  <span style={styles.lockSwitch}>
                    <IconButton
                      onClick={props.onLock}
                    >
                      {props.lock === true ? <LockOpenIcon fontSize='small' /> : <LockIcon fontSize='small' />}
                    </IconButton>
                  </span>
                </Grid>
                )
              : null}
            <Grid item sx={{ display: 'flex', alignItems: 'center', pt: 1 }}>
              {props.icon ? props.icon : null}
              <Typography sx={{ ml: 1 }} style={styles.textField}>
                {props.title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={1} sx={{ pr: 1 }}>
          <IconButton>
            {props.rightPanelVariant === props.subVariant ? <PlayCircleFilledIcon style={styles.icon} /> : <ArrowRightIcon style={styles.icon} />}
          </IconButton>
        </Grid>
        {props.displaySettings && props.subVariant && props.displaySettings.showSelectionSummary
          ? (
            <Grid item sm={12}>
              {props.subVariant === 'categories' ? <CategoriesSummary {...props} /> : null}
              {props.subVariant === 'properties' ? <PropertiesSummary {...props} /> : null}
              {props.subVariant === 'warranties' ? <WarrantiesSummary {...props} /> : null}
              {props.subVariant === 'tiered-pricing' ? <TieredPricingSummary {...props} /> : null}
              {props.subVariant === 'margin' ? <MarginSummary {...props} /> : null}
              {props.subVariant === 'estimate-project' ? <EstimateProjectSummary {...props} /> : null}
              {props.subVariant === 'sales-commission' ? <SalesCommissionSummary {...props} /> : null}
            </Grid>
            )
          : null}
      </Grid>
    </Box>
  )
}

const CategoriesSummary = (props) => {
  const [categories, setCategories] = useState([])

  useEffect(() => {
    if (props.product.categories) {
      setCategories(props.product.categories)
    }
  }, [props.product])

  const handleDelete = (idx) => {
    const p = JSON.parse(JSON.stringify(props.product))
    p.categories.splice(idx, 1)
    setCategories(p.categories)
    props.setProduct(p)
  }

  if (categories.length === 0) return <></>

  let disabled = false
  if (props.variant === 'pricing' && !props.product.categories_ovrd) disabled = true

  return (
    <Grid container sx={{ pb: 1 }}>
      {categories.map((c, idx) => {
        const fpc = props.flattenedProductCategories.find((o) => o.id === c)
        let title = c
        if (fpc) {
          if (fpc.title) title = fpc.title
          if (fpc.parent) title = `${fpc.parent.title}: ${title}`
        }
        return (
          <Grid key={`cs-${idx}`} item style={styles.hideOverflow} sx={{ pr: 1 }}>
            <Tooltip title={title}>
              <Chip size='small' style={styles.chipField} label={title} onDelete={!disabled ? () => handleDelete(idx) : null} />
            </Tooltip>
          </Grid>
        )
      })}
    </Grid>
  )
}

const PropertiesSummary = (props) => {
  const [properties, setProperties] = useState([])

  useEffect(() => {
    if (props.product.properties) {
      setProperties(props.product.properties)
    }
  }, [props.product])

  const handleDelete = (idx) => {
    const p = JSON.parse(JSON.stringify(props.product))
    p.properties.splice(idx, 1)
    setProperties(p.properties)
    props.setProduct(p)
  }

  if (properties.length === 0) return <></>

  let disabled = false
  if (props.variant === 'pricing' && !props.product.properties_ovrd) disabled = true

  return (
    <Grid container sx={{ pb: 1 }}>
      {properties.map((p, idx) => {
        let lstring = p.title
        let lvalue = p.value
        if (p.type === 'boolean') {
          p.value ? lvalue = 'Yes' : lvalue = 'No'
        }
        if (Array.isArray(p.value)) {
          lstring += `: ${lvalue.join(', ')}`
        } else {
          if (p.value) lstring += ': ' + lvalue
        }
        return (
          <Grid key={`ps-${idx}`} item style={styles.hideOverflow} sx={{ pr: 1 }}>
            <Tooltip title={lstring}>
              <Chip size='small' style={styles.chipField} label={lstring} onDelete={!disabled ? () => handleDelete(idx) : null} />
            </Tooltip>
          </Grid>
        )
      })}
    </Grid>
  )
}

const WarrantiesSummary = (props) => {
  const [warranty, setWarranty] = useState([])

  useEffect(() => {
    if (props.product.warranty) {
      setWarranty(props.product.warranty)
    }
  }, [props.product])

  const handleDelete = (idx) => {
    const p = JSON.parse(JSON.stringify(props.product))
    p.warranty.splice(idx, 1)
    setWarranty(p.warranty)
    props.setProduct(p)
  }

  if (warranty.length === 0) return <></>

  return (
    <Grid container sx={{ pb: 1 }}>
      {warranty.map((w, idx) => {
        const ccw = props.productFeatures.warranties.find((o) => o.id === w)
        let title = w
        if (ccw && ccw.heading) title = ccw.heading
        return (
          <Grid key={`ws-${idx}`} item style={styles.hideOverflow} sx={{ pr: 1 }}>
            <Tooltip title={title}>
              <Chip size='small' style={styles.chipField} label={title} onDelete={() => handleDelete(idx)} />
            </Tooltip>
          </Grid>
        )
      })}
    </Grid>
  )
}

const TieredPricingSummary = (props) => {
  const [tier, setTier] = useState(null)
  const [title, setTitle] = useState(null)

  useEffect(() => {
    if (props.product.pricingOptions) {
      if (props.product.pricingOptions.unit_tier) {
        setTier(props.product.pricingOptions.unit_tier)
        setTitle('Units Based Pricing')
      } else if (props.product.pricingOptions.inc_tier) {
        setTier(props.product.pricingOptions.inc_tier)
        setTitle('Incremental Pricing')
      }
    }
  }, [props.product])

  if (!tier) return <></>

  return (
    <Grid container sx={{ pb: 1 }}>
      <Grid key='tps-pc' item style={styles.hideOverflow} sx={{ pr: 1 }}>
        <Tooltip title={title}>
          <Chip size='small' label={title} />
        </Tooltip>
      </Grid>
    </Grid>
  )
}

const MarginSummary = (props) => {
  const [margin, setMargin] = useState(null)

  useEffect(() => {
    if (props.product.pricingOptions && props.product.pricingOptions.margin) {
      setMargin(props.product.pricingOptions.margin)
    } else {
      setMargin(null)
    }
  }, [props.product])

  if (!margin) return <></>

  const titleProductCost = `Product Cost: $${Number(margin.productCost).toFixed(2) || 0}`
  const titleMarkupPercentage = `* Margin: ${margin.markupPercentage || 0}%`
  return (
    <Grid container sx={{ pb: 1 }}>
      <Grid key='ms-pc' item style={styles.hideOverflow} sx={{ pr: 1 }}>
        <Tooltip title={titleProductCost}>
          <Chip size='small' label={titleProductCost} />
        </Tooltip>
      </Grid>
      <Grid key='ms-mp' item style={styles.hideOverflow} sx={{ pr: 1 }}>
        <Tooltip title={titleMarkupPercentage}>
          <Chip size='small' label={titleMarkupPercentage} />
        </Tooltip>
      </Grid>
    </Grid>
  )
}

const EstimateProjectSummary = (props) => {
  const [eplh, setEPLH] = useState({})

  useEffect(() => {
    if (props.product && props.product.pricingOptions && props.product.pricingOptions.eplh) {
      setEPLH(props.product.pricingOptions.eplh)
    } else {
      setEPLH(null)
    }
  }, [props.product])

  if (!eplh) return <></>

  const titleHPJ = `Fixed Hours per Job : ${eplh.hoursPerJob || 0} hour(s)`
  const titleHPU = `Install Hours per Unit : ${eplh.hoursPerUnit || 0} hour(s)`
  return (
    <Grid container sx={{ pb: 1 }}>
      <Grid key='eps-hpj' item style={styles.hideOverflow} sx={{ pr: 1 }}>
        <Tooltip title={titleHPJ}>
          <Chip size='small' label={titleHPJ} />
        </Tooltip>
      </Grid>
      <Grid key='eps-hpu' item style={styles.hideOverflow} sx={{ pr: 1 }}>
        <Tooltip title={titleHPU}>
          <Chip size='small' label={titleHPU} />
        </Tooltip>
      </Grid>
    </Grid>
  )
}

const SalesCommissionSummary = (props) => {
  const [salesCommission, setSalesCommission] = useState(null)

  useEffect(() => {
    if (props.product && props.product.pricingOptions && props.product.pricingOptions.salesCommission) {
      setSalesCommission(props.product.pricingOptions.salesCommission)
    } else {
      setSalesCommission(null)
    }
  }, [props.product])

  if (!salesCommission) return <></>

  const spiff = `SPIFF: $${salesCommission.spiff || 0}`
  return (
    <Grid container sx={{ pb: 1 }}>
      <Grid key='scs-spiff' item style={styles.hideOverflow} sx={{ pr: 1 }}>
        <Tooltip title={spiff}>
          <Chip size='small' label={spiff} />
        </Tooltip>
      </Grid>
    </Grid>
  )
}

const styles = {
  selectionContainer: {
    display: 'flex',
    borderTop: '1px solid rgba(0,0,0,.16)',
    userSelect: 'none',
    WebkitUserSelect: 'none'
  },
  textField: {
    fontSize: 14,
    paddingRight: 4
  },
  chipField: {
    fontSize: 12
  },
  icon: {
    color: 'rgba(0,0,0,.66)'
  },
  hideOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  selected: {
    backgroundColor: '#e7f0fc'
  },
  lockSwitch: {
    border: '0px solid red',
    verticalAlign: '-9px'
  }
}
