import Api from './Api'
import Company from 'screens/Company'
import ExpressionHelper from './ExpressionHelper'

/**
 * helper.js
 *
 * These are helper functions specific to ripple admin.
 */

/**
 * getProductsFromConfig()
 *
 * This function recursively walks a master solution automation config file using source array values to return
 * products that are at the end of the branch.
 */
const getProductsFromConfig = (productConfig, config) => {
  const pc = productConfig.find(o => o.id === config.id)
  if (!pc) return []
  let branch = pc.configuration[0]
  for (let x = 0; x < config.configuration.length; x++) {
    branch = _recurseConfigTree(branch, config.configuration[x])
    // If I found a branch with a product, get out and return.
    if (branch && branch.product) {
      return branch
    }
  }
  return branch
}

const _recurseConfigTree = (branch, id) => {
  try {
    if (branch.choices) {
      const found = branch.choices.find(o => o.id === id)
      if (!found) return false
      return found
    } else if (branch.related) {
      return _recurseConfigTree(branch.related[0], id)
    }
  } catch (err) {
    console.log(`_recurseConfigTree ERROR: ${err.stack}`)
    return false
  }
  return false
}

const filterDataGridUsingChipFilter = ({ value, rows, selectedRows, filterChips }) => {
  const fc = JSON.parse(JSON.stringify(filterChips))
  const f = fc.find(o => o.id === value)
  f.selected = !f.selected
  if (value.match(/All|Selected/) && f.selected === true) {
    fc.map(o => (o.id !== value ? (o.selected = false) : null))
  } else {
    fc.map(o => (o.id === 'All' ? (o.selected = false) : null))
  }

  const fAll = fc.find(o => o.id === 'All')
  const fSelected = fc.find(o => o.id === 'Selected')
  const selections = fc.filter(o => o.selected === true)
  if (fAll && fAll.selected) {
    return [rows, fc]
  } else {
    const fr = []
    for (let x = 0; x < rows.length; x++) {
      let filtered = true
      if (!fSelected.selected) {
        if (fc.find(o => o.selected === true && o.id === rows[x].library)) {
          filtered = false
        }
        if (fc.find(o => o.selected === true && o.id === rows[x].type)) {
          filtered = false
        }
        if (fc.find(o => o.selected === true && o.id === 'Document' && rows[x].type.match(/PDF|document/i))) {
          filtered = false
        }
        if (fc.find(o => o.selected === true && o.id === 'Video' && rows[x].type.match(/mp|video/i))) {
          filtered = false
        }
        if (fc.find(o => o.selected === true && o.id === 'Slideshow' && rows[x].type.match(/slideshow/i))) {
          filtered = false
        }
      } else {
        if (selectedRows.indexOf(x) > -1) {
          filtered = false
        }
        if (selections.length > 1) {
          // More than just selected is selected
          const f = fc.find(o => o.selected === true && o.id === rows[x].library)
          if (!f) {
            filtered = true
          }
        }
      }
      if (!filtered) {
        fr.push(rows[x])
      }
    }
    return [fr, fc]
  }
}

const toDataURL = (url, callback) => {
  // eslint-disable-next-line no-undef
  const xhr = new XMLHttpRequest()
  xhr.onload = function () {
    // eslint-disable-next-line no-undef
    const reader = new FileReader()
    reader.onloadend = function () {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response)
  }
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.send()
}

const NUMERIC_REGEX = /^[0-9]+$/
const DECIMAL_REGEX = /^[0-9.]+$/
const ALPHA_NUMERIC_DASH_REGEX = /^[a-z0-9-]+$/

const isNumeric = value => {
  return NUMERIC_REGEX.test(value)
}

const isDecimal = value => {
  return DECIMAL_REGEX.test(value)
}

const isAlphaNumeric = value => {
  return ALPHA_NUMERIC_DASH_REGEX.test(value)
}

/**
 * getAssetInfo()
 *
 * Importable function that will return asset information from an ID and full assetsConfig structure.
 *
 * @param {*} id
 * @param {*} config
 * @return {*}
 */
const getAssetInfo = (id, config) => {
  const x = config.find(o => o.id === id)
  if (x) {
    return x
  }
  return null
}

/** getSvgWidthHeight()
 *
 * Uses XML Parser to get the width and height out of the SVG Viewbox.
 *
 */
const getSvgWidthHeight = data => {
  let w = 0
  let h = 0
  // eslint-disable-next-line no-undef
  const parser = new DOMParser()
  const xml = parser.parseFromString(data, 'image/svg+xml')
  const svg = xml.querySelector('svg')
  // If I have width & height in svg, use that
  if (svg.width.baseVal.value && svg.width.baseVal.value > 0) {
    w = svg.width.baseVal.value
    h = svg.height.baseVal.value
    return [w, h]
  }
  if (svg.viewBox.baseVal.width > 0) {
    w = svg.viewBox.baseVal.width
    h = svg.viewBox.baseVal.height
    return [w, h]
  }
  return [w, h]
}

const getDateString = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = `${date.getMonth() + 1}`.padStart(2, '0')
  const day = `${date.getDate()}`.padStart(2, '0')
  return `${year}${month}${day}`
}

/**
 * isCRM()
 *
 * This method looks at the current org, current session, and returns the isCRM value from the org.
 *
 */
const isCRM = async () => {
  try {
    const ses = await Api.callRegisteredMethod('getSessionOrganizations')

    if (ses && ses.length > 0) {
      const orgId = Company.getCurrentOrgId()
      if (orgId !== undefined) {
        const org = ses.find(o => o.id === orgId)
        if (org && org.isCRM) {
          return org.isCRM
        }
      }
      return false
    }
  } catch (err) {
    console.log(`isCRM ERROR: ${err.stack}`)
  }
  return undefined
}

/**
 * updateProductsFromAssembly()
 *
 * This method will update the products config with the latest material and labor values from those master configs.
 *
 * @param {*} { configMaterials = null, configProductFeatures = null, saveConfigProducts = false, setProgress = null, setTotal = null }
 */
const updateProductsFromAssembly = async ({ configMaterials = null, configProductFeatures = null, saveConfigProducts = false }) => {
  let organizationId
  let configId
  let version

  // Get the current config for materials
  if (!configMaterials) {
    await Api.callRegisteredMethod('getLatestConfigByTag', { tag: 'materials', startsWith: false }).then(c => {
      if (c && c.contentJson) {
        configMaterials = JSON.parse(c.contentJson)
      }
    })
  }

  // Get the current config for Labor & Markup
  if (!configProductFeatures) {
    await Api.callRegisteredMethod('getLatestConfigByTag', { tag: 'productFeatures', startsWith: false }).then(c => {
      if (c && c.contentJson) {
        configProductFeatures = JSON.parse(c.contentJson)
      }
    })
  }

  // Get the products config, which we will end up updating with data from the other configs
  let configProducts
  await Api.callRegisteredMethod('getLatestConfigByTag', { tag: 'products', startsWith: false }).then(c => {
    if (c && c.contentJson) {
      configProducts = JSON.parse(c.contentJson)
      organizationId = c.organizationId
      configId = c.configId
      version = c.version
      console.log(`\n\nPreparing to update org Id: ${organizationId}, configId: ${configId}, version: ${version}`)
    }
  })

  // Update the product config
  if (configProducts.length > 0 && organizationId && configId && version) {
    const products = JSON.parse(JSON.stringify(configProducts)) // clone

    for (let x = 0; x < products.length; x++) {
      // I only care if it has assembly materials
      if (products[x].pricingOptions?.assembly?.materials?.length) {
        const assembly = products[x].pricingOptions.assembly
        const materials = assembly.materials
        let materialsTotal = 0

        // Step 1, figure out "materialsTotal" for the product, and update each materials array item with correct data.
        //
        for (let y = 0; y < materials.length; y++) {
          // WTF do we have nulls??? DEV-5629 work around
          if (materials[y] === null) continue

          // Look in my master material list to get totals
          const masterMaterial = configMaterials.find(m => m.id === materials[y].id)
          if (masterMaterial) {
            let [price, calc] = getMaterialPriceAndCalc(masterMaterial)

            console.log(`material: ${masterMaterial.title} price: ${price} calc: ${calc}`)

            // if price is not a number
            const qty = materials[y].qty || 0
            if (isNaN(price)) {
              console.log(`Can't figure out price for ${materials[y].id}, so setting to 0`)
              price = 0
            }
            if (price !== materials[y].price) {
              console.log(`Updating ${materials[y].id} from ${materials[y].price} to ${price} and ${calc}`)
              materials[y].price = price
              materials[y].calc = calc
              materials[y].total = price * qty // total for this material
            } else {
              // console.log(`No change for ${materials[y].id} with ${price} and ${calc}`);
            }
            materialsTotal = materialsTotal + price * qty
          } else {
            // Should I DELETE this material from the product?
            console.log(`Master material not found for ${materials[y].id}, HOUSTON, we have an orphan.`)
            delete materials[y]
            // materials[y].price = 0;
            // materials[y].calc = '';
          }
        }
        console.log(`${products[x].title} materials total is: ${materialsTotal}`)

        // Step 2, loop all my assembly.materialMarkup.
        // If ovrd === true, skip
        // If ovrd === false, use the master materialMarkup value
        // Update markupPercentTotal for each using markup
        let markupPercentTotal = 0
        for (let y = 0; y < assembly.materialMarkup.length; y++) {
          const markup = assembly.materialMarkup[y]
          if (!markup.ovrd) {
            const markupMaster = configProductFeatures?.materialMarkup.find(m => m.id === markup.id)
            if (markupMaster) {
              markup.markup = markupMaster.markup
            }
            markupPercentTotal = markupPercentTotal + markup.markup
          }
        }

        const markupTotal = markupPercentTotal * 0.01 * materialsTotal
        console.log(`Markup total is: ${markupTotal}`)

        // Step 3 - Labor is LAST and is not used with the materialMarkup
        //
        let laborTotal = 0
        const labor = products[x].pricingOptions?.assembly?.labor || []
        for (let y = 0; y < labor.length; y++) {
          const laborMaster = configProductFeatures?.labor.find(m => m.id === labor[y].id)
          if (laborMaster && !isNaN(laborMaster.price)) {
            const laborPrice = laborMaster.price || 0
            labor[y].price = laborPrice // update the product with the latest labor value
            laborTotal = laborTotal + labor[y].value * laborPrice
          }
        }
        console.log(`Labor total is: ${laborTotal}`)

        let assemblyTotal = materialsTotal + markupTotal + laborTotal
        products[x].pricingOptions.assembly.total = assemblyTotal
        console.log(`Assembly total is: ${assemblyTotal}`)

        // Third, if we have an "final markup" on the assembly, use that to re-calculate the assemblyTotal
        //
        if (products[x].pricingOptions?.assembly?.markup) {
          const markup = products[x].pricingOptions.assembly.markup
          if (!isNaN(markup)) {
            assemblyTotal = assemblyTotal + (markup * 0.01 + assemblyTotal)
          }
        }

        // Last, If we have a custom calucation do stuff...
        //
        let price = assemblyTotal
        if (products[x].pricingOptions.assembly.calc) {
          const calc = products[x].pricingOptions.assembly.calc
          if (calc && calc !== undefined) {
            let calcFixed = calc
            // Right now we only accept "total" 2023/01/02
            if (!calc.startsWith('total')) {
              calcFixed = 'total ' + calc // Prepend "total" if not already starting with it
            }
            const result = ExpressionHelper.calc(calcFixed, function (key) {
              if (key === 'total') {
                return assemblyTotal
              } else {
                return 0
              }
            })
            if (result && result.valid && result.value) {
              price = parseFloat(result.value)
            }
          }
        }
        console.log(`${products[x].title} ${price}\n\n`)
        products[x].pricingOptions.assembly.total = price // set price on assembly total
        products[x].price = price // set price on product
      }
    }

    if (products.length > 0 && saveConfigProducts) {
      saveConfig(
        organizationId,
        {
          title: 'Master List',
          tag: 'products'
        },
        products
      )
    }
  } else {
    console.log('Did not find any product config to update')
  }
}

/**
 * getMaterialPriceAndCalc
 *
 * Utility method used for calculating the price and calc for a material using all of its components and the calcType.
 *
 * @param {*} material
 * @return {*}
 */
const getMaterialPriceAndCalc = mat => {
  let postfix = ''
  let price = 0
  let calc = ''
  const calcStack = [] // Stack to store postfix calculation elements

  const localFunctions = function (key) {
    const component = mat.components.find(comp => comp.id === key)
    if (component) {
      if (component.type === 'calc') {
        return component.calcTotal
      } else {
        return component.price
      }
    } else {
      return 0
    }
  }

  if (mat.calcType === 'sum') {
    // For sum, the calc is all the components in postfix annotation and the price is the total of all
    for (let x = 0; x < mat.components.length; x++) {
      if (mat.components[x].type === 'amt') {
        price = price + Number(mat.components[x].price)
      } else {
        if (mat.components[x].calcTotal !== undefined) {
          price = price + Number(mat.components[x].calcTotal)
        }
      }
      // Check if it's not the last iteration before adding '+'
      if (x !== mat.components.length - 1) {
        calcStack.push(mat.components[x].id + ' + ')
      } else {
        calcStack.push(mat.components[x].id)
      }
    }
    calc = calcStack.join(' ')
    postfix = ExpressionHelper.infixToPostfix(calc)
  } else if (mat.calcType === 'field') {
    // Validate the field is in the materials.components.  If it's not, clear out calc.
    const field = mat.components.find(comp => comp.id === mat.calc)
    if (field && field.id) {
      calc = field.id
      postfix = ExpressionHelper.infixToPostfix(calc)
    } else {
      return [0, '']
    }
  } else if (mat.calcType === 'calc') {
    calc = mat.calc
    postfix = ExpressionHelper.infixToPostfix(calc)
  } else {
    // return the mat price and calc
    return [mat.price, mat.calc]
  }
  if (!postfix) return [0, '']

  const isValid = ExpressionHelper.validPostfix(postfix, localFunctions)
  if (!isValid) return [0, '']

  const result = ExpressionHelper.calc(postfix, localFunctions)
  if (!result.valid) return [0, '']
  if (result.valid === true) {
    price = result.value
    calc = postfix
  }

  return [price, calc]
}

/**
 * saveConfig()
 *
 * Function that allows saving arbitrary configs outside of the class component 'Save' button.
 *
 */
const saveConfig = async (organizationId, config, json) => {
  if (organizationId && config.tag) {
    const configs = await Api.callRegisteredMethod('getConfigListByTag', { tag: config.tag, startsWith: false })
    const c = {
      name: config.title,
      tag: config.tag,
      status: 'Published',
      contentJson: JSON.stringify(json),
      organizationId
    }
    if (configs.length) {
      const configId = Math.max.apply(
        Math,
        configs.map(function (o) {
          return o.configId
        })
      )
      c.configId = configId
    }
    await Api.callRegisteredMethod('saveConfig', { ...c })
  }
}

const Helper = {
  getProductsFromConfig,
  filterDataGridUsingChipFilter,
  toDataURL,
  isNumeric,
  isAlphaNumeric,
  isDecimal,
  getAssetInfo,
  getSvgWidthHeight,
  getDateString,
  isCRM,
  updateProductsFromAssembly,
  getMaterialPriceAndCalc,
  saveConfig
}

export default Helper
