import React, { useState, useEffect } from 'react'
import { Box, Stack, TextField, Typography } from '@mui/material'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { DataGridPresentations } from 'components/Reusable/DataGridPresentations'

const RightPanelAllAppointments = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [top, setTop] = useState([])
  const [bottom, setBottom] = useState([])
  const [titleTop, setTitleTop] = useState('')
  const [titleBottom, setTitleBottom] = useState('')

  useEffect(() => {
    if (isLoading) {
      if (props.meeting.always_present) {
        setTop(props.meeting.always_present.top || [])
        setBottom(props.meeting.always_present.bottom || [])
        if (props.meeting.always_present?.top_name) {
          setTitleTop(props.meeting.always_present.top_name)
        }
        if (props.meeting.always_present?.bottom_name) {
          setTitleBottom(props.meeting.always_present.bottom_name)
        }
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    const m = JSON.parse(JSON.stringify(props.meeting))
    if (!m.always_present) {
      m.always_present = {}
    }
    if (name === 'titleTop') {
      setTitleTop(value)
      m.always_present.top_name = value
    } else if (name === 'titleBottom') {
      setTitleBottom(value)
      m.always_present.bottom_name = value
    }
    props.setMeeting(m)
  }

  const handleChangeList = ({ list, variant }) => {
    const m = JSON.parse(JSON.stringify(props.meeting))
    m.always_present[variant] = list
    props.setMeeting(m)
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Box style={styles.container} sx={{ p: 2 }}>
        <Stack sx={{ pb: 2 }}>
          <Typography variant='h6'>Starting Presentations</Typography>
          <Typography variant='body2'>
            Presentations added here will always begin with presentation. Once you add presentations, sort the presentations to your preferred order.
          </Typography>
          <Box sx={{ pt: 2 }}>
            <TextField
              fullWidth
              id='titleTop'
              name='titleTop'
              variant='outlined'
              label={titleTop ? 'Section Title' : null}
              placeholder='Please enter the top section title'
              value={titleTop}
              onChange={handleChange}
            />
          </Box>
        </Stack>
        <DataGridPresentations {...props} variant='top' list={top} onChange={handleChangeList} />
        <Stack sx={{ pt: 4, pb: 2 }}>
          <Typography variant='h6'>Final Presentations</Typography>
          <Typography variant='body2'>
            Presentations added here will always end with presentation. Once you add presentations, sort the presentations to your preferred order.
          </Typography>
          <Box sx={{ pt: 2 }}>
            <TextField
              fullWidth
              id='titleBottom'
              name='titleBottom'
              variant='outlined'
              label={titleBottom ? 'Section Title' : null}
              placeholder='Please enter the end section title'
              value={titleBottom}
              onChange={handleChange}
            />
          </Box>
        </Stack>
        <DataGridPresentations {...props} variant='bottom' list={bottom} onChange={handleChangeList} />
      </Box>
    </>
  )
}
export default RightPanelAllAppointments

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'white'
  }
}
