import React, { useState, useEffect } from 'react'
import { Box, Button, Chip, FormControl, FormHelperText, Grid, IconButton, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useRouteMatch } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Company from 'screens/Company'
import { DialogGeneric } from './DialogGeneric'
import { LoadingPleaseWait } from '@supportworks/react-components'
import DataGrid from './DataGrid'
import { getTriggerSpecifications } from 'screens/Config/Forms/SolutionAutomation/DrawerSolutionEditor'

export const DataGridProducts = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [pageSize, setPageSize] = useState(10)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [list, setList] = useState([])
  const [products, setProducts] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(-1)

  let match = useRouteMatch('/Company/:organizationId')
  if (!match) match = { params: {} }

  useEffect(() => {
    if (isLoading) {
      const p = []
      const productList = props.productList
      for (let x = 0; x < productList.length; x++) {
        const pList = productList[x].configuration
        if (pList && pList.length > 0) {
          const arr = getTriggerSpecifications(pList[0])
          productList[x].specifications = arr
        }
        p.push(productList[x])
      }
      setProducts(p)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    setList(props.list)
    setIsLoading(false)
  }, [props.list])

  const handleChange = (l) => {
    setList(l)
    setSelectedIndex(-1)
    props.onChange(l)
  }

  const handleDelete = (idx) => {
    const r = window.confirm('Are you sure you want to delete?')
    if (r) {
      const l = JSON.parse(JSON.stringify(list))
      l.splice(idx, 1)
      handleChange(l)
    }
  }

  const handleMoveUp = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    if (ele.library) {
      ele.changed = true
    }
    l.splice(idx - 1, 0, ele)
    handleChange(l)
  }

  const handleMoveDown = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    // If I'm a library, set changed to true so we do not delete on autoload from SolutionEditor
    if (ele.library) {
      ele.changed = true
    }
    l.splice(idx + 1, 0, ele)
    handleChange(l)
  }

  const handleProcessRowUpdate = (row) => {
    if (row) {
      const l = JSON.parse(JSON.stringify(list))
      l[row.id].quantity = row.quantity
      if (l[row.id].library) {
        l[row.id].changed = true
      }
      setList(l)
      handleChange(l)
      return row
    }
  }

  // Look up the choice title from the flattened recursive product list
  //
  const getChoiceTitle = (id, idx, choice) => {
    // let pc = props.productConfig.find((o) => o.value === id);
    const pc = props.productList.find((o) => o.value === id)
    let title
    if (pc.specifications && pc.specifications[idx]) {
      title = pc.specifications[idx].title
      if (pc.specifications[idx].choices && pc.specifications[idx].choices.length > 0) {
        const c = pc.specifications[idx].choices.find((o) => o.id === choice)
        if (c && c.title) title = c.title
      }
    }
    return title
  }

  const columns = [
    { field: 'id', headerName: 'Id', hide: true },
    {
      flex: 2,
      field: 'title',
      headerName: 'Title',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {params.row.title}
          </Typography>
        )
      }
    },
    {
      flex: 1,
      field: 'specification',
      headerName: 'Specification',
      editable: false,
      sortable: false,
      hide: true,
      renderCell: (params) => {
        let jsx = ''
        const spec = params.row.specification
        if (spec && spec.length) {
          jsx = spec.map((s, idx) => {
            const title = getChoiceTitle(params.row.productId, idx, s)
            return <Chip key={`spec-${params.row.id}-${idx}`} label={title} size='small' sx={spec.length > idx ? { mr: 0.5 } : null} />
          })
        }
        return <Box>{jsx}</Box>
      }
    },
    {
      flex: 1,
      field: 'quantity',
      headerName: 'Quantity',
      editable: true,
      sortable: false,
      renderCell: (params) => {
        return <Typography>{params.row.quantity}</Typography>
      }
    },
    {
      flex: 1,
      field: 'libraryName',
      headerName: 'Library',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {params.row.libraryName}
          </Typography>
        )
      }
    },
    {
      flex: 1,
      field: 'libraryTitle',
      headerName: 'Library Title',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {params.row.libraryProductTitle}
          </Typography>
        )
      }
    },
    {
      width: 128,
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'right',
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end' style={{ lineHeight: '52px', whiteSpace: 'nowrap' }}>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id)}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
                <IconButton size='small' disabled={!(params.row.id < list.length - 1)} onClick={() => handleMoveDown(params.row.id)}>
                  <KeyboardArrowDownIcon fontSize='small' />
                </IconButton>
                <IconButton disabled={!!params.row.libraryId} size='small' onClick={() => handleDelete(params.row.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const company = Company.getCurrent()
  const companyname = company.name

  const rows = []
  for (let x = 0; x < list.length; x++) {
    // let pc = props.productConfig.find((o) => o.value === list[x].id);
    const pc = props.productList.find((o) => o.value === list[x].id)
    if (pc) {
      rows.push({
        id: x,
        productId: list[x].id,
        title: pc.name,
        specification: list[x].config ? list[x].config : null,
        libraryName: companyname,
        libraryProductTitle: '',
        quantity: list[x].quantity || 0,
        actions: ''
      })
    } else {
      if ('library' in list[x]) {
        // lookup the library product from library
        let libraryProductTitle = ''
        let orgProduct = ''
        const l = props.library.find((o) => o.libraryProduct === list[x].id)
        if (l) {
          libraryProductTitle = l.libraryProductTitle
          if (l.orgProduct === 'notoffered') {
            orgProduct = <span style={{ color: '#9e9e9e' }}>Product Not Offered</span>
          } else {
            const p = props.productList.find((o) => o.value === l.orgProduct)
            if (p) {
              orgProduct = p.name
            } else {
              const resolved = '/Company/' + match.params.organizationId + '/Configs?variant=libraryProductMapping'
              orgProduct = (
                <Link href={resolved} target='_blank' rel='noreferrer' style={{ textDecoration: 'none' }}>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ color: 'red', paddingRight: '8px' }}>{list[x].id}</span>
                    <OpenInNewIcon sx={{ color: 'red' }} fontSize='small' />
                  </span>
                </Link>
              )
            }
          }
        }
        rows.push({
          id: x,
          productId: list[x].id,
          title: orgProduct || list[x].id,
          libraryName: list[x].library.libraryName,
          libraryProductTitle,
          specification: list[x].config ? list[x].config : null,
          quantity: list[x].quantity || 0,
          actions: ''
        })
      }
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Box>
        <DataGrid
          autoHeight
          getRowHeight={() => 'auto'}
          rows={rows}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onProcessRowUpdate={(row) => {
            handleProcessRowUpdate(row)
          }}
          autosizeOptions={{
            columns: ['action'],
            includeOutliers: true,
            includeHeaders: false
          }}
        />
      </Box>
      <Box sx={{ pt: 2 }}>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            setShowAddDialog(!showAddDialog)
          }}
        >
          Add Product
        </Button>
      </Box>

      {showAddDialog
        ? (
          <DialogProductSelector
            {...props}
            title='Select a Product'
            list={list}
            selectedIndex={selectedIndex}
            products={products}
            onSave={(l) => {
              handleChange(l)
              setShowAddDialog(!showAddDialog)
            }}
            onClose={() => setShowAddDialog(!showAddDialog)}
          />
          )
        : null}
    </>
  )
}

const DialogProductSelector = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  // const [productConfig, setProductConfig] = useState({}); // The product spec from props.products
  const [productList, setProductList] = useState({}) // for DEV-4927, switched to using productList instead of productConfig
  const [productId, setProductId] = useState()
  const [quantity, setQuantity] = useState(0)
  const [config, setConfig] = useState([])

  useEffect(() => {
    if (isLoading) {
      if (props.selectedIndex > -1) {
        // Get the product spec and put it on this component for easier access.
        const p = props.list[props.selectedIndex]
        const pc = props.products.find((o) => o.value === p.id)
        // setProductConfig(pc);
        setProductList(pc)

        // Default stuff based on the product in the list
        setProductId(p.id)
        setQuantity(p.quantity)
        setConfig(p.config)
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleSave = () => {
    const list = JSON.parse(JSON.stringify(props.list))
    if (props.selectedIndex > -1) {
      // const product = props.products.find((o) => o.value === productId)
      const p = list[props.selectedIndex]
      p.quantity = quantity
      p.config = config
    } else {
      const p = {
        id: productId,
        quantity,
        config
      }
      list.push(p)
    }
    props.onSave(list)
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'productId') {
      const pc = props.products.find((o) => o.value === value)
      // setProductConfig(pc);
      setProductList(pc)
      setProductId(value)
      // Do I have a spec, and does my first level have no choices?
      const c = []
      if (pc.specifications && pc.specifications[0] && !pc.specifications[0].choices) {
        c.push(pc.specifications[0].id)
      }
      setConfig(c)
    } else if (name === 'quantity') {
      const regex = /^[0-9\b]+$/
      if (value === '' || regex.test(value)) {
        setQuantity(value)
      }
    }
  }

  const handleChangeChoice = (e, idx) => {
    const value = e.target.value
    const c = JSON.parse(JSON.stringify(config))
    if (c && c[idx]) {
      c[idx] = value
    } else {
      c.push(value)
    }
    setConfig(c)
  }

  if (isLoading) return <LoadingPleaseWait />

  const jsx = (
    <Grid container wrap='nowrap'>
      <Grid item sm={12} sx={{ pt: 2 }}>
        <FormControl fullWidth sx={{ pb: { sm: 1 }, 'input:focus': { border: 'none' } }}>
          <InputLabel id='product'>Product</InputLabel>
          <Select
            fullWidth
            disabled={props.selectedIndex > -1}
            id='productId'
            name='productId'
            label='Product'
            labelId='productId'
            variant='outlined'
            value={productId || ''}
            onChange={handleChange}
            sx={{ height: 50 }}
          >
            {props.products.map((p, idx) => {
              return (
                <MenuItem key={`product-${idx}`} value={p.value}>
                  <span style={styles.textField}>{p.name}</span>
                </MenuItem>
              )
            })}
          </Select>
          <FormHelperText id='my-helper-text'>Please select a product.</FormHelperText>
        </FormControl>

        {
          productList && productList.specifications
            ? ( // productConfig && productConfig.specifications ?
              <Box sx={{ pt: 1 }}>
                {productList.specifications.map((spec, idx) => {
                  // productConfig.specifications.map((spec, idx) => {
                  return (
                    <Box key={`choice-${idx}`}>
                      <FormControl fullWidth sx={{ pb: { sm: 1 }, 'input:focus': { border: 'none' } }}>
                        <InputLabel id={`choice-${idx}`}>{spec.title}</InputLabel>
                        <Select
                          fullWidth
                          disabled={!spec.choices}
                          id='choice'
                          name='choice'
                          label={spec.title}
                          labelId={`choice-${idx}`}
                          variant='outlined'
                          value={config && config[idx] ? config[idx] : ''}
                          onChange={(e) => handleChangeChoice(e, idx)}
                          sx={{ height: 50 }}
                        >
                          {spec.choices &&
                            spec.choices.length > 0 &&
                            spec.choices.map((choice, idx2) => {
                              return (
                                <MenuItem key={`menuitem-choice-${idx}-${idx2}`} value={choice.id}>
                                  <span style={styles.textField}>{choice.title}</span>
                                </MenuItem>
                              )
                            })}
                          {!spec.choices
                            ? (
                              <MenuItem key={`menuitem-choice-single-${idx}`} value={spec.id}>
                                <span style={styles.textField}>{spec.title}</span>
                              </MenuItem>
                              )
                            : null}
                        </Select>
                      </FormControl>
                    </Box>
                  )
                })}
              </Box>
              )
            : null
        }

        <Grid item sm={12} sx={{ pt: 1 }}>
          <TextField
            fullWidth
            id='quantity'
            name='quantity'
            variant='outlined'
            label='Quantity'
            helperText='Please enter a numeric quantity.'
            value={quantity || ''}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Grid>
  )

  return <DialogGeneric title={props.title} content={jsx} fullWidth onChange={handleSave} onClose={props.onClose} />
}

const styles = {
  textField: {
    fontSize: 14,
    paddingRight: 4
  }
}
