import React from 'react'

const RightPanelProjectDisplayPrefs = (props) => {
  return (
    <>
    </>
  )
}

export default RightPanelProjectDisplayPrefs
