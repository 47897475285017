import Api, { ApiPlugin, ApiPluginAuth } from 'library/Api'
import Company from 'screens/Company'
import { Topics } from 'config/topics'

export class ApiPluginRipple extends ApiPlugin {
  /**
   *
   * @param {ApiBase} apiBase
   * @param {Object} props
   */
  constructor (apiBase, props) {
    super(apiBase, props)

    if (props && props.baseUrl) {
      this.baseUrl = props.baseUrl
    }

    apiBase.registerMethod('validateSession', this.validateSession.bind(this))
    apiBase.registerMethod('stripePortal', this.stripePortal.bind(this))
  }

  /**
   * Validates a session token and publishes the validation response.
   *
   * This expects a valid token already exists and is being used as a header in ApiBase.
   */
  validateSession (props) {
    const route = this.getBaseUrl() + '/validateSession'
    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */

        if (typeof rsp === 'undefined') {
          if (props && props.callback) {
            props.callback({ status: 'error', message: 'Unkown Error' })
          }
          if (props && props.topic) {
            Topics.publish(props.topic, { status: 'error', message: 'Unkown Error' })
          }
          return { status: 'error', message: 'Unkown Error' }
        }
        if (rsp && rsp.status === 'success') {
          for (const prop in rsp.data[0]) {
            const datum = rsp.data[0][prop]
            if (prop === 'person') {
              let p = datum
              if (rsp.data[0].session?.extra) {
                p = { ...datum, extra: rsp.data[0].session.extra }
              }
              Api.callRegisteredMethod('setSessionUser', p)
            }
            if (prop === 'organizations') {
              // Updating the organization data stored in the Auth plugin
              Api.callRegisteredMethod('setSessionOrganizations', datum)
            }
            if (datum[ApiPluginAuth.CORE_TYPE_FIELD] === ApiPluginAuth.CORE_SESSION_OBJECT_TYPE) {
              // Updating the session data stored in the Auth plugin
              Api.callRegisteredMethod('setSession', datum)
            }
          }
        }
        if (props && props.callback) {
          props.callback(rsp)
        }
        if (props && props.topic) {
          Topics.publish(props.topic, rsp)
        }
        return rsp
      }).catch((err) => {
        if (props && props.callback) {
          props.callback({ status: 'error', message: err.message })
        }
        if (props && props.topic) {
          Topics.publish(props.topic, { status: 'error', message: err.message })
        }
        return { status: 'error', message: err.message }
      })
  }

  /**
   *
   * @param {object} props
   * @param {object} props.redirectUrl
  */
  stripePortal = (props) => {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/stripePortal', {
      organization: Company.getCurrentOrgId()
    })
    const payload = {
      redirectUrl: props.redirectUrl
    }
    return this.getApiBase()
      .get(route, payload)
      .then((rsp) => {
        if (rsp && rsp.status === 'success' && rsp.data && rsp.data.length) {
          return rsp.data[0]
        }
        return rsp
      })
  }
}
