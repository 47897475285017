import React, { useState, useEffect } from 'react'
import { Chip } from '@mui/material'
import MapFormFieldNumeric from './MapFormFieldNumeric'
import EditIcon from '@mui/icons-material/Edit'
import DrawerFooter from './DrawerFooter'

const numFieldCond = [
  { value: 'eq', name: '=' },
  { value: 'gt', name: '>' },
  { value: 'gte', name: '>=' },
  { value: 'lt', name: '<' },
  { value: 'lte', name: '<=' }
]

const MapFormFieldNumericSpec = ({ config: defaultConfig, mappedFieldObject, setProduct, product, specification, onBack }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [config, setConfig] = useState(defaultConfig)
  const [choices, setChoices] = useState([])
  const [choiceIdx, setChoiceIdx] = useState(0)
  const [showMapFormField, setShowMapFormField] = useState(false)
  const [showSaveButton, setShowSaveButton] = useState(false) // eslint-disable-line 

  useEffect(() => {
    console.log('useEffect start [] triggered')
    // Initialize all things on the component with existing product data.
    if (isLoading) {
      try {
        initialize()
      } catch (err) {
        console.log(err)
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line 

  const initialize = () => {
    if (!config.values) {
      config.field = mappedFieldObject.id
      config.values = []
    }
    const choices = specification.choices
    for (let x = 0; x < choices.length; x++) {
      if (!config.values[x]) {
        config.values.push({ config: choices[x].id })
      }
    }
    setChoices(choices)
    setConfig(config)
    setProduct({ ...product })
  }

  const handleChange = (idx) => {
    setProduct({ ...product })
    setChoiceIdx(idx)
    setShowMapFormField(true)
    setShowSaveButton(true)
  }

  if (isLoading) return <></>

  if (choices.length === 0) {
    return (
      <div className='sa-section'>
        <div className='sa-heading'>
          Not able to find field mapping specification.  Please map to a different field.
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='sa-section'>
        <div className='sa-heading'>
          {mappedFieldObject.sectionTitle}: {mappedFieldObject.title}
        </div>
        <div className='sa-spec-paragraph'>
          <span>
            Connect the dots.  Take the information your teams are inputting and automate the products so they don't need to do any more work.
          </span>
        </div>

        {showMapFormField
          ? (
            <>
              <MapFormFieldNumeric
                config={config.values[choiceIdx]}
                mappedFieldObject={mappedFieldObject}
                setProduct={setProduct}
                product={product}
                setShowSaveButton={setShowSaveButton}
                showSpecParagraph={false}
                variant='specification'
              />
              <DrawerFooter onBack={() => { setShowMapFormField(false) }} zIndex={2} />
            </>
            )
          : (
            <>
              {config.values.map((c, idx) => {
                const spec = specification.choices.filter((o) => o.id === c.config)
                return (
                  <ChoiceRow key={'CR' + idx} choiceIdx={idx} config={c} onChange={handleChange} onDelete={initialize} specification={spec ? spec[0] : null} />
                )
              })}
              <DrawerFooter onBack={onBack} showSaveButton={false} />
            </>
            )}
      </div>
    </>
  )
}
export default MapFormFieldNumericSpec

const ChoiceRow = ({ config, choiceIdx, onChange, onDelete, specification }) => {
  const handleDelete = (k) => {
    // console.log(`Deleting this chip: ${k}`);
    delete config[k]
    onDelete()
  }

  return (
    <div className='sa-spec-container'>
      <div className='sa-spec-left'>
        <span className='sa-product-text-title'>
          <strong>{specification.title}</strong>
        </span>
        <span className='sa-product-text-id'>
          <span className='sa-text12'>Define numeric range to trigger {specification.title}</span>
        </span>
      </div>
      <div className='sa-spec-right'>
        <span name={config.config} className='sa-icon'>
          <EditIcon onClick={() => { onChange(choiceIdx) }} />
        </span>
      </div>
      <div className='sa-spec-chip-container'>
        {Object.keys(config).map((k) => {
          if (k !== 'config') {
            const nfc = numFieldCond.find((o) => o.value === k)
            const label = `${nfc && nfc.name ? nfc.name : ''} ${config[k]}`
            return (
              <div key={'CHIP-' + k} className='sa-spec-chip'>
                <Chip label={label} variant='outlined' onDelete={() => { handleDelete(k) }} />
              </div>
            )
          }
          return null
        })}
      </div>
    </div>
  )
}
