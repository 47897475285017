import React, { useState, useEffect } from 'react'
import { Box, Grid, IconButton, MenuItem, Select } from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Api from 'library/Api'
import DataGrid from 'components/Reusable/DataGrid'
import Helper from '@supportworks/helper'
import { LoadingPleaseWait } from '@supportworks/react-components'

const RightPanelMaterialLabor = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState([]) // For screen manipulation
  const [numericUnits, setNumericUnits] = useState([])

  useEffect(() => {
    if (isLoading) {
      if (!props.productFeatures.labor) {
        props.productFeatures.labor = []
      }
      setList(props.productFeatures.labor)

      Promise.all([
        Api.callRegisteredMethod('getLatestConfigByTag', { tag: 'numericUnits', startsWith: false }).then(c => {
          if (c && c.contentJson) {
            setNumericUnits(JSON.parse(c.contentJson))
          } else {
            setNumericUnits([{ id: 'EACH', title: 'each' }])
          }
        })
      ]).then(() => {
        setIsLoading(false)
      })
    }
  }, []) // eslint-disable-line

  const handleAdd = () => {
    const l = JSON.parse(JSON.stringify(list))
    l.unshift({ id: Helper.guid(), title: 'New Material Labor', price: 0, units: '' })
    props.productFeatures.labor = l
    setList(l)
  }

  const handleDelete = idx => {
    const l = JSON.parse(JSON.stringify(list))
    l.splice(idx, 1)
    props.productFeatures.labor = l
    setList(l)
  }

  const handleMoveUp = idx => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx - 1, 0, ele)
    props.productFeatures.labor = l
    setList(l)
  }

  const handleMoveDown = idx => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx + 1, 0, ele)
    props.productFeatures.labor = l
    setList(l)
  }

  const handleUnitChange = (e, index) => {
    const l = JSON.parse(JSON.stringify(list))
    if (index > -1) {
      l[index].units = e.target.value
      props.productFeatures.labor = l
      setList(l)
    }
  }

  const handleProcessRowUpdate = row => {
    if (row) {
      const l = JSON.parse(JSON.stringify(list))
      l[row.id].title = row.title
      l[row.id].price = row.price
      l[row.id].units = row.units
      props.productFeatures.labor = l
      setList(l)
      return row
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    {
      flex: 3,
      type: 'string',
      field: 'title',
      headerName: 'Title',
      editable: true,
      sortable: true
    },
    {
      flex: 1,
      type: 'number',
      field: 'price',
      headerName: 'Price',
      editable: true,
      sortable: true,
      preProcessEditCellProps: params => {
        const isValid = !isNaN(params.props.value)
        return { ...params.props, error: !isValid }
      },
      renderCell: params => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AttachMoneyIcon fontSize='small' sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            {params.value}
          </Box>
        )
      }
    },
    {
      flex: 1,
      type: 'string',
      field: 'units',
      headerName: 'Unit',
      editable: true,
      sortable: true,
      renderCell: params => (
        <Box sx={{ ml: 2 }} style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
          <Select
            value={params.value || ''} // Provide an empty string if value is undefined
            onChange={event => handleUnitChange(event, params.row.id)}
            style={{ flexGrow: 1 }}
            size='small'
          >
            {numericUnits.map((u, idx) => {
              return (
                <MenuItem key={`unit-${idx}`} value={u.title}>
                  {u.title}
                </MenuItem>
              )
            })}
          </Select>
        </Box>
      )
    },
    {
      flex: 1,
      field: 'action',
      headerName: 'Actions',
      headerAlign: 'right',
      align: 'right',
      editable: false,
      sortable: false,
      renderCell: params => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id)}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton size='small' disabled={!(params.row.id < list.length - 1)} onClick={() => handleMoveDown(params.row.id)}>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < list.length; x++) {
    const row = {
      id: x,
      rowId: list[x].id,
      title: list[x].title,
      price: list[x].price,
      units: list[x].units
    }
    rows.push(row)
  }

  return (
    <Box>
      <DataGrid
        rows={rows}
        columns={columns}
        onAdd={handleAdd}
        onProcessRowUpdate={row => {
          handleProcessRowUpdate(row)
        }}
      />
    </Box>
  )
}
export default RightPanelMaterialLabor
