import React, { useEffect, useState } from 'react'
import { FormControl, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField, Select, InputLabel, Typography, Box, Paper, CardMedia } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { LeftPanelSelection } from './components/LeftPanelSelection'
import { DialogGeneric } from '../../../components/Reusable/DialogGeneric'
import { DialogCRMProductSelector } from './components/DialogCRMProductSelector'
import SelectorWithPreview from '../../../components/Reusable/SelectorWithPreview'

const LeftPanel = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [product, setProduct] = useState({})
  const [masterProduct, setMasterProduct] = useState()
  const [price, setPrice] = useState(null)
  const [numericUnits, setNumericUnits] = useState([])
  const [showAddDialogAsset, setShowAddDialogAsset] = useState(false)
  const [showAddDialogContract, setShowAddDialogContract] = useState(false)
  const [asset, setAsset] = useState({})
  const [proposalImage, setProposalImage] = useState({})
  const [proposalImageId, setProposalImageId] = useState('')
  const [assetId, setAssetId] = useState('')
  const [filteredAssetsConfig, setFilteredAssetsConfig] = useState([])
  const [showCRMFieldSelector, setShowCRMFieldSelector] = useState(false)

  useEffect(() => {
    if (isLoading) {
      setProduct(props.product)
      setPrice(props.product.price)
      setMasterProduct(props.masterProduct)
      setIsLoading(false)
      if (props.numericUnits && Array.isArray(props.numericUnits) && props.numericUnits.length > 0) {
        setNumericUnits(props.numericUnits)
      }
      if (props.masterProduct?.asset !== undefined) {
        const asset = props.assetsConfig.filter((o) => {
          return o.id === props.masterProduct.asset
        })
        setAsset(asset.length ? asset[0] : [])
        setAssetId(props.masterProduct.asset)
      }

      if (props.masterProduct?.proposalImage !== undefined) {
        if (props.masterProduct.proposalImage?.asset !== undefined) {
          const asset = props.assetsConfig.filter((o) => {
            return o.id === props.masterProduct.proposalImage.asset
          })
          setProposalImage(asset.length ? asset[0] : [])
          setAssetId(props.masterProduct.proposalImage.asset)
        }
      }

      if ('assetsConfig' in props) {
        const filtered = []
        props.assetsConfig.map((a, idx) => {
          const name = a.filename?.match(/.png|.jpg|.JPG/)
          if (name !== null) {
            filtered.push(a)
          }
          return null
        })
        setFilteredAssetsConfig(filtered)
      }
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    setPrice(props.product.price)
    setProduct(props.product)
  }, [props.product])

  const handleChange = (e) => {
    let p = JSON.parse(JSON.stringify(product))
    const name = e.target.name
    const value = e.target.value
    if (name === 'min_price') {
      p.pricingOptions[name] = Number(value)
    } else if (name === 'price') {
      p = initializeMargin(p, value, 0)
    } else if (name === 'tax' || name === 'discount' || name === 'isCustom') {
      p[name] = !p[name]
    } else if (name === 'productClause') {
      if ('productClause' in p) {
        p.productClause.active = !p.productClause.active
      } else {
        p.productClause = {
          active: true,
          title: '',
          body: ''
        }
      }
    } else if (name === 'contractGroup') {
      p.contractGroup = value
    } else {
      if (e.target.type === 'number') {
        p[name] = Number(value)
      } else {
        p[name] = value
      }
    }
    setProduct(p)
    props.setProduct(p)
  }

  const handleChangeCRMId = (field) => {
    const p = JSON.parse(JSON.stringify(product))
    if (!field) {
      p.crmId = null
      p.mappedToCRM = false
    } else {
      p.crmId = field.id
      p.mappedToCRM = true
    }
    setProduct(p)
    props.setProduct(p)
  }

  const handleChangeAdvancedFeature = (e) => {
    const p = JSON.parse(JSON.stringify(product))

    if ('productClause' in p) {
      p.productClause.active = !p.productClause.active
    } else {
      p.productClause = {
        active: true,
        title: '',
        body: ''
      }
    }

    setProduct(p)
    props.setRightPanelVariant('advanced-features')
    props.setProduct(p)
  }

  const handleLock = (field, override) => {
    let p = JSON.parse(JSON.stringify(product))

    if (
      field.match(/title|description|price|units|tax|discount|categories|properties|warranty|tier|margin|eplh|salesCommission|min_price|isCustom/) &&
      p[override] === true
    ) {
      if (window.confirm('This will reset your data to what the parent record has.  Are you sure you want to lock?')) {
        // Do nothing
      } else {
        return
      }
    }

    // If I lock the thing, I'm in override false.  It needs to have the master value.
    p[override] = !p[override]
    if (p[override] === false) {
      if (field === 'price') {
        if (typeof masterProduct.price !== 'undefined') {
          p = initializeMargin(p, masterProduct.price)
        }
      } else if (field === 'margin') {
        if (typeof masterProduct.price !== 'undefined' && masterProduct.pricingOptions && masterProduct.pricingOptions.margin) {
          p = initializeMargin(p, masterProduct.price, masterProduct.pricingOptions.margin.markupPercentage)
        }
      } else if (field === 'tier') {
        // Get unit_tier or inc_tier from MPL and assign it.
        if (masterProduct.pricingOptions.unit_tier) {
          p.pricingOptions.unit_tier = masterProduct.pricingOptions.unit_tier
          delete p.pricingOptions.inc_tier
        } else if (masterProduct.pricingOptions.inc_tier) {
          p.pricingOptions.inc_tier = masterProduct.pricingOptions.inc_tier
          delete p.pricingOptions.unit_tier
        }
      } else if (field.match(/eplh|salesCommission|min_price/)) {
        // This data exists in pricingOptions[field]
        if (masterProduct.pricingOptions && masterProduct.pricingOptions[field]) {
          p.pricingOptions[field] = masterProduct.pricingOptions[field]
        } else {
          delete p.pricingOptions[field]
        }
      } else {
        // This data sits in the root of product
        p[field] = masterProduct[field]
      }
    }
    setProduct(p)
    props.setProduct(p)
  }

  const handleChangeAsset = (asset) => {
    if (asset) {
      setAssetId(asset.id)
      setAsset(asset)
    } else {
      setAssetId(null)
    }
  }

  const handleSaveAsset = () => {
    if (assetId) {
      const p = JSON.parse(JSON.stringify(product))
      if ('asset' in p) {
        p.asset = assetId
      } else {
        p.asset = assetId
      }
      setProduct(p)
      props.setProduct(p)
    }
  }

  const handleChangeContract = (asset) => {
    if (asset) {
      setProposalImageId(asset.id)
      setProposalImage(asset)
    } else {
      setProposalImageId(null)
    }
  }

  const handleSaveContract = () => {
    if (proposalImageId) {
      const p = JSON.parse(JSON.stringify(product))
      if ('proposalImage' in p) {
        p.proposalImage.asset = proposalImageId
      } else {
        const proposalImage = { asset: proposalImageId }
        p.proposalImage = proposalImage
      }
      setProduct(p)
      props.setProduct(p)
    }
  }

  const deleteAsset = (type) => {
    const p = JSON.parse(JSON.stringify(product))
    if (type === 'asset') {
      setAsset({})
      setAssetId(null)
      p.asset = ''
    }
    if (type === 'proposalImage') {
      setProposalImage({})
      setProposalImageId(null)
      p.proposalImage.asset = ''
    }
    setProduct(p)
    props.setProduct(p)
  }

  // Utility method to initialize and set the pricingOptions.margin values
  const initializeMargin = (p, price, margin) => {
    p.price = Number(price)
    if (!p.pricingOptions) p.pricingOptions = {}
    if (!p.pricingOptions.margin) p.pricingOptions.margin = {}
    p.pricingOptions.margin = {
      productCost: Number(price),
      markupPercentage: Number(margin)
    }
    return p
  }

  const CrmIdText = ({ crmId }) => {
    let jsx = ''
    const crmProduct = props.crmProducts.find((o) => o.id === crmId)
    if (crmProduct) {
      jsx = (
        <Box sx={{ mt: -3 }}>
          <Typography variant='caption' style={styles.textCrmLabel}>
            CRM ID
          </Typography>
          <Box sx={{ pt: 0 }} style={styles.textCrmIdBox}>
            <Typography variant='body2' style={styles.textCrmTitle}>
              {crmProduct.title}
            </Typography>
            <Typography variant='caption' style={{ color: 'inherit' }}>
              {crmProduct.id}
            </Typography>
          </Box>
        </Box>
      )
    } else {
      jsx = 'Product is not mapped'
    }
    return <Box>{jsx}</Box>
  }

  if (isLoading) return <LoadingPleaseWait />
  return (
    <>
      <Grid container sx={{ pt: 2, pl: 2, pr: 1 }}>
        {props.displaySettings && props.displaySettings?.showImageryProductImages && (
          <Grid container spacing={4}>
            <Grid item xs={6} sx={{ pb: 2 }}>
              <Box>
                <Typography sx={{ fontWeight: 'bold', pb: 1 }}>App Image</Typography>
              </Box>

              {asset && asset.previewURL
                ? (
                  <Box>
                    <Paper elevation={0} variant='outlined'>
                      <a href={asset.path} target='_blank' rel='noreferrer noopener' sx={{ mr: 2 }}>
                        <CardMedia component='img' height='160' sx={{ objectFit: 'contain' }} image={asset.previewURL} alt={asset.previewURL} />
                      </a>
                    </Paper>
                  </Box>
                  )
                : (
                  <Box>
                    <Grid container>
                      <Grid
                        item sx={{
                          border: '1px solid rgba(0, 0, 0, 0.12)',
                          borderRadius: '4px',
                          height: '162px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          pl: 1
                        }}
                      >
                        <Typography variant='body2' sx={{ textAlign: 'center' }}>
                          There is no App Image. Please add one.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  )}

              {props.variant !== 'pricing' && (
                <Box sx={{ display: 'flex' }}>
                  <IconButton aria-label='edit' onClick={() => setShowAddDialogAsset(!showAddDialogAsset)}>
                    <AddIcon />
                  </IconButton>
                  <Box sx={{ flexGrow: 1 }} /> {/* This empty Box acts as a spacer */}
                  <IconButton sx={{ marginLeft: 'auto' }} aria-label='edit' onClick={() => deleteAsset('asset')}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Grid>
            <Grid item xs={6} sx={{ pb: 2 }}>
              <Box>
                <Typography sx={{ fontWeight: 'bold', pb: 1 }}>Contract Image</Typography>
              </Box>

              {proposalImage && proposalImage.previewURL
                ? (
                  <Box>
                    <Paper elevation={0} variant='outlined'>
                      <a href={proposalImage.path} target='_blank' rel='noreferrer noopener' sx={{ mr: 2 }}>
                        <CardMedia component='img' height='160' sx={{ objectFit: 'contain' }} image={proposalImage.previewURL} alt={proposalImage.previewURL} />
                      </a>
                    </Paper>
                  </Box>
                  )
                : (
                  <Box>
                    <Grid container>
                      <Grid
                        item sx={{
                          border: '1px solid rgba(0, 0, 0, 0.12)',
                          borderRadius: '4px',
                          height: '162px',
                          display: 'flex',
                          alignItems: 'center',
                          pl: 1
                        }}
                      >
                        <Typography variant='body2' sx={{ textAlign: 'center' }}>
                          There is no Contract Image. Please add one.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  )}

              {props.variant !== 'pricing' && (
                <Box sx={{ display: 'flex' }}>
                  <IconButton aria-label='edit' onClick={() => setShowAddDialogContract(!showAddDialogContract)}>
                    <AddIcon />
                  </IconButton>
                  <Box sx={{ flexGrow: 1 }} /> {/* This empty Box acts as a spacer */}
                  <IconButton sx={{ marginLeft: 'auto' }} aria-label='edit' onClick={() => deleteAsset('proposalImage')}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} sx={{ pb: 2 }}>
          <TextField
            disabled
            fullWidth
            id='productId'
            name='productId'
            variant='outlined'
            label='ID'
            value={product.id}
            InputProps={{
              style: styles.textField
            }}
            sx={{ 'input:focus': { border: 'none' } }}
          />
        </Grid>

        {props.isCRM === true
          ? (
            <Grid container sx={{ pb: 2 }}>
              <Grid item xs={props.variant === 'master-crm' && product.id !== product.crmId ? 11 : 12}>
                {product.crmId
                  ? (
                    <CrmIdText crmId={product.crmId} />
                    )
                  : (
                    <TextField
                      disabled
                      fullWidth
                      id='crmId'
                      name='crmId'
                      variant='outlined'
                      label={props.variant === 'master-crm' ? 'Map to CRM Product' : 'CRM Product ID'}
                      value='Product is not mapped'
                      InputProps={{
                        style: styles.textField
                      }}
                      sx={{ 'input:focus': { border: 'none' } }}
                    />
                    )}
              </Grid>
              {props.variant === 'master-crm' && product.id !== product.crmId
                ? (
                  <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton aria-label='edit' onClick={() => setShowCRMFieldSelector(!showCRMFieldSelector)}>
                      <EditIcon />
                    </IconButton>
                  </Grid>
                  )
                : null}
            </Grid>
            )
          : null}

        <Grid item xs={12} sx={{ pb: 2 }}>
          <TextField
            fullWidth
            disabled={product.title_ovrd !== true}
            id='title'
            name='title'
            variant='outlined'
            label='Title'
            value={product.title}
            InputProps={{
              style: styles.textField,
              startAdornment: (
                <EndAdornment
                  product={product}
                  field='title_ovrd'
                  onLock={() => {
                    handleLock('title', 'title_ovrd')
                  }}
                />
              )
            }}
            sx={{ 'input:focus': { border: 'none' } }}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sx={{ pb: 2 }}>
          <Grid container wrap='nowrap'>
            <Grid item>
              <span style={styles.lockSwitch}>
                <IconButton
                  style={styles.iconButton}
                  onClick={() => {
                    handleLock('isCustom', 'isCustom_ovrd')
                  }}
                >
                  {product.isCustom_ovrd === true ? <LockOpenIcon fontSize='small' /> : <LockIcon fontSize='small' />}
                </IconButton>
              </span>
            </Grid>
            <Grid item style={{ pl: 1 }}>
              <FormControlLabel
                name='isCustom'
                disabled={!(props.variant === 'master' || product.isCustom_ovrd === true)}
                  // disabled={props.variant === 'master' || product.isCustom_ovrd}
                control={
                  <Switch
                    checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                    id='isCustom'
                    checked={!!product.isCustom}
                    color='primary'
                    onClick={handleChange}
                  />
                  }
                label={<Typography style={styles.textField}>Editable In App</Typography>}
                labelPlacement='start'
                onChange={handleChange}
                sx={{ ml: '8px', p: 0 }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ pb: 2 }}>
          <TextField
            fullWidth
            disabled={product.description_ovrd !== true}
            id='description'
            name='description'
            variant='outlined'
            label={<Typography style={styles.textField}>Please Enter the Descriptions</Typography>}
            value={product.description || ''}
            multiline
            rows={3}
            InputProps={{
              style: {
                fontSize: 14
              },
              startAdornment: (
                <EndAdornment
                  product={product}
                  field='description_ovrd'
                  onLock={() => {
                    handleLock('description', 'description_ovrd')
                  }}
                />
              )
            }}
            inputProps={{
              style: {
                border: 'none',
                padding: '0px',
                resize: 'vertical'
              }
            }}
            sx={{ 'input:focus': { border: 'none' } }}
            onChange={handleChange}
          />
        </Grid>
        <Grid container sx={{ pb: 2 }}>
          <Grid item sm={12} md={6} sx={{ pr: { md: 1 }, pb: 1 }}>
            <TextField
              key='price'
              fullWidth
              disabled={product.price_ovrd !== true}
              id='price'
              name='price'
              variant='outlined'
              label='Display Price'
              value={price}
              type='number'
              InputProps={{
                inputProps: { min: 0, max: 999999.99, step: '0.01' },
                style: styles.textField,
                startAdornment: (
                  <EndAdornment
                    product={product}
                    field='price_ovrd'
                    onLock={() => {
                      handleLock('price', 'price_ovrd')
                    }}
                  />
                )
              }}
              sx={{ pb: { sm: 1 }, 'input:focus': { border: 'none' } }}
              onChange={handleChange}
            />
          </Grid>
          <Grid item sm={12} md={6} sx={{ pb: 1 }}>
            <Grid container wrap='nowrap'>
              <Grid item sm={12}>
                <FormControl fullWidth sx={{ pb: { sm: 1 }, 'input:focus': { border: 'none' } }}>
                  <InputLabel id='unit-type'>Unit Type</InputLabel>
                  <Select
                    fullWidth
                    disabled={product.units_ovrd !== true}
                    id='units'
                    name='units'
                    label='Unit Type'
                    labelId='unit-type'
                    variant='outlined'
                    value={product.units ?? ''}
                    onChange={handleChange}
                    sx={{ height: 50 }}
                    startAdornment={
                      <InputAdornment position='start'>
                        <span
                          style={{
                            border: '0px solid red',
                            verticalAlign: '3px'
                          }}
                        >
                          <IconButton
                            style={styles.iconButton}
                            onClick={() => {
                              handleLock('units', 'units_ovrd')
                            }}
                          >
                            {product.units_ovrd === true ? <LockOpenIcon fontSize='small' /> : <LockIcon fontSize='small' />}
                          </IconButton>
                        </span>
                      </InputAdornment>
                    }
                  >
                    {numericUnits.map((u, idx) => {
                      return (
                        <MenuItem key={`unit-${idx}`} value={u.title}>
                          <span style={styles.textField}>{u.title}</span>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item sx={{ pt: 1 }}>
                <span style={styles.lockSwitch}>
                  <IconButton
                    onClick={() => {
                      handleLock('units', 'units_ovrd');
                    }}
                  >
                    {product.units_ovrd === true ? <LockOpenIcon fontSize="small" /> : <LockIcon fontSize="small" />}
                  </IconButton>
                </span>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item sm={12} md={6} sx={{ pr: { md: 1 } }}>
            <TextField
              key='min_price'
              fullWidth
              id='min_price'
              name='min_price'
              variant='outlined'
              label='Price Minimum'
              value={product.pricingOptions.min_price || 0}
              type='number'
              InputProps={{
                inputProps: { min: 0, max: 999999.99, step: '0.01' },
                style: styles.textField,
                startAdornment: (
                  <EndAdornment
                    product={product}
                    field='min_price_ovrd'
                    onLock={() => {
                      handleLock('min_price', 'min_price_ovrd')
                    }}
                  />
                )
              }}
              sx={{ 'input:focus': { border: 'none' } }}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item sm={6}>
            <Grid container wrap='nowrap'>
              <Grid item>
                <span style={styles.lockSwitch}>
                  <IconButton
                    style={styles.iconButton}
                    onClick={() => {
                      handleLock('tax', 'tax_ovrd')
                    }}
                  >
                    {product.tax_ovrd === true ? <LockOpenIcon fontSize='small' /> : <LockIcon fontSize='small' />}
                  </IconButton>
                </span>
              </Grid>
              <Grid item>
                <FormControlLabel
                  name='tax'
                  disabled={!(props.variant === 'master' || product.tax_ovrd === true)}
                  control={
                    <Switch
                      checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                      id='tax'
                      checked={!!product.tax}
                      color='primary'
                      onClick={handleChange}
                    />
                  }
                  label={<Typography style={styles.textField}>Taxable</Typography>}
                  labelPlacement='start'
                  onChange={handleChange}
                  sx={{ ml: '6px', p: 0 }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container wrap='nowrap'>
              <Grid item>
                <span style={styles.lockSwitch}>
                  <IconButton
                    style={styles.iconButton}
                    onClick={() => {
                      handleLock('discount', 'discount_ovrd')
                    }}
                  >
                    {product.discount_ovrd === true ? <LockOpenIcon fontSize='small' /> : <LockIcon fontSize='small' />}
                  </IconButton>
                </span>
              </Grid>
              <Grid item>
                <FormControlLabel
                  name='discount'
                  disabled={!(props.variant === 'master' || product.discount_ovrd === true)}
                  control={
                    <Switch
                      checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                      id='discount'
                      checked={!!product.discount}
                      color='primary'
                      onClick={handleChange}
                    />
                  }
                  label={<Typography style={styles.textField}>Discount</Typography>}
                  labelPlacement='start'
                  onChange={handleChange}
                  sx={{ ml: '8px', p: 0 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/** Contract Groups */}
      {props.productFeatures?.contract_groups?.length > 0
        ? (
          <Grid item xs={12} sx={{ p: 2 }}>
            <FormControl fullWidth>
              <InputLabel id='contract-group-label'>{product.contract_groups ? 'Contract Group' : 'Contract Group - Not Set'}</InputLabel>
              <Select
                disabled={!props.variant.match(/^master/)}
                name='contractGroup'
                labelId='contract-group-label'
                id='contract-group-select'
                value={product.contractGroup ?? ''}
                onChange={handleChange}
              >
                {props.productFeatures?.contract_groups?.map((group, index) => (
                  <MenuItem key={index} value={group.id}>
                    {group.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          )
        : (
          <Box sx={{ p: 2 }}>
            <Typography variant='body2' sx={{ fontWeight: 'bold', color: 'red' }}>
              There are no Contract Groups. Please configure.
            </Typography>
          </Box>
          )}

      {/** Assembly */}
      <Grid container>
        <Grid item sm={12} sx={{ borderBottom: '1px solid rgba(0,0,0,.16)' }}>
          {props.displaySettings && props.displaySettings.showAssemblies && (
            <LeftPanelSelection
              {...props}
              subVariant='assembly'
              title='Assembly'
              onClick={() => {
                props.setRightPanelVariant('assembly')
              }}
              onLock={
                props.variant === 'assembly'
                  ? () => {
                      handleLock('assembly', 'assembly_ovrd')
                    }
                  : null
              }
              lock={!!product.assembly_ovrd}
            />
          )}
          <LeftPanelSelection
            {...props}
            subVariant='categories'
            title='Categories'
            onClick={() => {
              props.setRightPanelVariant('categories')
            }}
            onLock={
              () => {
                handleLock('categories', 'categories_ovrd')
              }
            }
            lock={!!product.categories_ovrd}
          />
          <LeftPanelSelection
            {...props}
            subVariant='properties'
            title='Properties'
            onClick={() => {
              props.setRightPanelVariant('properties')
            }}
            onLock={
              props.variant === 'pricing'
                ? () => {
                    handleLock('properties', 'properties_ovrd')
                  }
                : null
            }
            lock={!!product.properties_ovrd}
          />
          <LeftPanelSelection
            {...props}
            subVariant='warranties'
            title='Warranties'
            onClick={() => {
              props.setRightPanelVariant('warranties')
            }}
            onLock={
              props.variant === 'pricing'
                ? () => {
                    handleLock('warranty', 'warranty_ovrd')
                  }
                : null
            }
            lock={!!product.warranty_ovrd}
          />

          {props.displaySettings && (props.displaySettings.showTieredUnits || props.displaySettings.showTieredIncremental)
            ? (
              <LeftPanelSelection
                {...props}
                subVariant='tiered-pricing'
                title='Tiered Pricing'
                onClick={() => {
                  props.setRightPanelVariant('tiered-pricing')
                }}
                onLock={
                props.variant === 'pricing'
                  ? () => {
                      handleLock('tier', 'tier_ovrd')
                    }
                  : null
              }
                lock={!!product.tier_ovrd}
              />
              )
            : null}
          {props.displaySettings && (props.displaySettings.showMarginProductCost || props.displaySettings.showMarginMarkupPercentage)
            ? (
              <LeftPanelSelection
                {...props}
                subVariant='margin'
                title='Margin'
                onClick={() => {
                  props.setRightPanelVariant('margin')
                }}
                onLock={
                props.variant === 'pricing'
                  ? () => {
                      handleLock('margin', 'margin_ovrd')
                    }
                  : null
              }
                lock={!!product.margin_ovrd}
              />
              )
            : null}
          {props.displaySettings && (props.displaySettings.showEplhHoursPerUnit || props.displaySettings.showEplhHoursPerJob)
            ? (
              <LeftPanelSelection
                {...props}
                subVariant='estimate-project'
                title='Estimate Project Labor Hours (EPLH)'
                onClick={() => {
                  props.setRightPanelVariant('estimate-project')
                }}
                onLock={
                props.variant === 'pricing'
                  ? () => {
                      handleLock('eplh', 'eplh_ovrd')
                    }
                  : null
              }
                lock={!!product.eplh_ovrd}
              />
              )
            : null}
          {props.displaySettings && props.displaySettings.showSalesSPIFF
            ? (
              <LeftPanelSelection
                {...props}
                subVariant='sales-commission'
                title='Sales Commission'
                onClick={() => {
                  props.setRightPanelVariant('sales-commission')
                }}
                onLock={
                props.variant === 'pricing'
                  ? () => {
                      handleLock('salesCommission', 'salesCommission_ovrd')
                    }
                  : null
              }
                lock={!!product.salesCommission_ovrd}
              />
              )
            : null}
          <LeftPanelSelection
            {...props}
            subVariant='drawing'
            title='Drawing Configuration'
            onClick={() => {
              props.setRightPanelVariant('drawing')
            }}
            onLock={
                  () => {
                    handleLock('drawing', 'drawingConfig_ovrd')
                  }
            }
            lock={product.drawingConfig_ovrd === true}
          />
          {
            // 'includes' in product || 'customQuantityLabel' in product &&
            <LeftPanelSelection
              {...props}
              subVariant='advanced-features'
              title='Advanced Features'
                /* onClick={() => {
                  props.setRightPanelVariant('advanced-features');

                }} */
              onClick={handleChangeAdvancedFeature}
            />
          }
        </Grid>
      </Grid>

      {showAddDialogAsset && (
        <DialogGeneric
          title='Select App Image'
          content={
            <SelectorWithPreview
              selectedConfig={props.product}
              fieldName='asset'
              config={filteredAssetsConfig}
              assetType='Image'
              onUpdate={(asset) => {
                handleChangeAsset(asset)
              }}
            />
          }
          fullWidth
          onChange={() => {
            handleSaveAsset()
            setShowAddDialogAsset(false)
          }}
          onClose={() => setShowAddDialogAsset(false)}
        />
      )}

      {showAddDialogContract && (
        <DialogGeneric
          title='Select Contract Image'
          content={
            <SelectorWithPreview
              selectedConfig={props.product}
              fieldName='asset'
              config={filteredAssetsConfig}
              assetType='Image'
              onUpdate={(asset) => {
                handleChangeContract(asset)
              }}
            />
              }
          fullWidth
          onChange={() => {
            handleSaveContract()
            setShowAddDialogContract(false)
          }}
          onClose={() => setShowAddDialogContract(false)}
        />
      )}

      {showCRMFieldSelector && (
        <DialogCRMProductSelector
          title='Select CRM Product'
          selectedCrmId={product.crmId}
          products={props.crmProducts}
          onClose={() => setShowCRMFieldSelector(!showCRMFieldSelector)}
          onSave={(field) => {
            handleChangeCRMId(field)
            setShowCRMFieldSelector(!showCRMFieldSelector)
          }}
        />
      )}
    </>
  )
}
export default LeftPanel

const EndAdornment = ({ product, field, onLock }) => {
  return (
    <InputAdornment position='start'>
      {product[field] === true
        ? (
          <IconButton
            style={styles.iconButton}
            onClick={() => {
              onLock(field)
            }}
          >
            <LockOpenIcon fontSize='small' style={styles.icon} />
          </IconButton>
          )
        : (
          <IconButton
            style={styles.iconButton}
            onClick={() => {
              onLock(field)
            }}
          >
            <LockIcon fontSize='small' style={styles.icon} />
          </IconButton>
          )}
    </InputAdornment>
  )
}

const styles = {
  textField: {
    fontSize: 14,
    paddingRight: 4
  },
  icon: {
    color: 'rgba(0,0,0,.66)'
  },
  iconButton: {
    // paddingLeft: '0px',
  },
  lockSwitch: {
    border: '0px solid red',
    paddingLeft: '8px',
    verticalAlign: '3px'
  },
  textCrmLabel: {
    position: 'relative',
    top: 12,
    left: 8,
    paddingLeft: 4,
    color: 'rgba(0, 0, 0, .64)',
    background: 'white',
    zIndex: 2,
    width: 56
  },
  textCrmIdBox: {
    position: 'relative',
    border: '1px solid #ccc',
    borderRadius: 4,
    padding: 10,
    borderWidth: 1,
    maxWidth: 676,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'rgba(0, 0, 0, 0.38)'
  },
  textCrmTitle: {
    color: 'inherit',
    paddingRight: 8,
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
}
