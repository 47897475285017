/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { Box, Button, Chip, Grid, IconButton, Typography, Stack } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import AdminIcon from '@mui/icons-material/AdminPanelSettings'
import CheckIcon from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import WarningIcon from '@mui/icons-material/Warning'
import DataGrid from 'components/Reusable/DataGrid'
import Api from 'library/Api'
import CompanyConfigEdit, { CompanyConfigBaseEditor } from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import Company from '../Company'
import { DialogFormsSelector } from './Forms/Reusables/DialogFormsSelector'
import { DialogGeneric } from '../../components/Reusable/DialogGeneric'
import { JSONEditor } from 'components/Reusable'
import { LoadingPleaseWait } from '@supportworks/react-components'
import Permissions from '../../library/Permissions'
import PopoverAutomation from './Forms/ActionBar/PopoverAutomation'
import SingleFormEditor from 'screens/Config/Forms/SingleFormEditor'
import '../../css/select-search.css'
import UpIcon from '@mui/icons-material/KeyboardArrowUp'
import DownIcon from '@mui/icons-material/KeyboardArrowDown'

const config = {
  title: 'Forms',
  variant: 'forms2',
  tag: 'forms2',
  name: 'Forms',
  lockTag: true,
  lockName: true
}

class CompanyConfigForms2 extends CompanyConfigBaseEditor {
  constructor (props) {
    super(props)
    // console.log(this.props);
    let formsInfo
    if (typeof props.contentJson === 'string') {
      try {
        formsInfo = JSON.parse(props.contentJson)
        if (!Array.isArray(formsInfo)) {
          formsInfo = []
        }
      } catch (e) {
        formsInfo = []
      }
    } else {
      formsInfo = []
    }

    this.state = {
      isLoading: true,
      isAdding: false,
      formsList: formsInfo,
      formsConfig: null,
      currentConfig: null, // Used in solution editor, so I don't have to hit the API over and over
      issuesConfig: null,
      assetsConfig: null,
      solutionsConfig: null,
      productList: null,
      productConfig: null,
      crmFieldsConfig: null,
      numericUnitsConfig: null,
      mediaArray: null,
      filter: '',
      tabValue: 0,
      view: 'table',
      showImportLibraryDialog: false,
      showLibraryUpdateDialog: false,
      libraryUpdateIndex: -1,
      filterChips: [],
      showJSONEditor: false,
      selectedIndex: -1
    }

    this.handleChangeTab = this.handleChangeTab.bind(this)
    this.addHandler = this.addHandler.bind(this)
    this.editHandler = this.editHandler.bind(this)
    this.deleteHandler = this.deleteHandler.bind(this)
    this.handleLibraryAdd = this.handleLibraryAdd.bind(this)
    this.handleShowJSONEditor = this.handleShowJSONEditor.bind(this)
  }

  componentDidMount () {
    super.componentDidMount()
    const formsList = this.state.formsList

    const mediaArray = []
    for (let i = 0; i < formsList.length; i++) {
      this.addExistingGuid(formsList[i].id)

      if ('formSection' in formsList[i]) {
        const formSection = formsList[i].formSection
        for (let j = 0; j < formSection.length; j++) {
          if ('fields' in formSection[j]) {
            const fields = formSection[j].fields
            for (let k = 0; k < fields.length; k++) {
              if (fields[k].type === 'media') {
                mediaArray.push(fields[k])
              }
            }
          }
        }
      }
    }

    Api.callRegisteredMethod('getConfigListByTag', { tag: 'products', startsWith: false }).then((configs) => {
      const res = Math.max.apply(
        Math,
        configs.map(function (o) {
          return o.configId
        })
      ) // finds greatest configId value
      const mostRecent = configs.filter((obj) => {
        // gets most recent Product config //Might not be needed, only being used now for orgId
        return obj.configId === res
      })
      const org = mostRecent[0].organizationId
      // console.log(res, org)

      Api.callRegisteredMethod('getConfigById', { configId: res, organization: org }).then((prodProps) => {
        const list = JSON.parse(prodProps.data[0].contentJson)
        const productList = []
        list.map((product, ind) => {
          if (product.id) {
            productList.push({
              id: product.id,
              title: product.title,
              configuration: product.configuration,
              crmId: product.crmId,
              description: product.description,
              units: product.units
            })
          }
          return null
        })
        this.setState({ productList: productList.sort((a, b) => (a.name > b.name ? 1 : -1)) })
      })
    })

    Api.callRegisteredMethod('getAssetList', {}).then((assetList) => {
      const assets = assetList
      Api.callRegisteredMethod('getCurrentConfig', { tag: 'all', startsWith: false }).then((currentConfig) => {
        const issues = []
        if (currentConfig && 'issues' in currentConfig) {
          for (let x = 0; x < currentConfig.issues.length; x++) {
            issues.push({ value: currentConfig.issues[x].id, name: currentConfig.issues[x].title })
          }
        }

        const solutions = []
        if (currentConfig && 'solutions' in currentConfig) {
          for (let x = 0; x < currentConfig.solutions.length; x++) {
            solutions.push({ value: currentConfig.solutions[x].id, name: currentConfig.solutions[x].title })
          }
        }

        const productConfig = []
        if (currentConfig && 'product_config' in currentConfig) {
          for (let y = 0; y < currentConfig.product_config.length; y++) {
            if ('configuration' in currentConfig.product_config[y]) {
              productConfig.push({
                value: currentConfig.product_config[y].id,
                name: currentConfig.product_config[y].title,
                configuration: currentConfig.product_config[y].configuration
              })
            } else {
              productConfig.push({ value: currentConfig.product_config[y].id, name: currentConfig.product_config[y].title, configuration: [] })
            }
          }
        }

        const crmFieldsConfig = []
        if (currentConfig && 'crmFields' in currentConfig) {
          for (let z = 0; z < currentConfig.crmFields.length; z++) {
            crmFieldsConfig.push({ value: currentConfig.crmFields[z].id, name: currentConfig.crmFields[z].object + ': ' + currentConfig.crmFields[z].fieldName })
          }
        }

        /* let numericUnitsConfig = [];
        if (currentConfig && 'numericUnits' in currentConfig) {
          currentConfig.numericUnits.map((listItem) => {
            numericUnitsConfig.push({ value: listItem.id, name: listItem.title });
          });
        } */

        const formsConfig = []
        if (currentConfig && 'forms' in currentConfig) {
          for (let m = 0; m < currentConfig.forms.length; m++) {
            formsConfig.push(currentConfig.forms[m])
          }
        }

        const formsList = this.state.formsList
        const prefilter = [
          { id: 'All', selected: true },
          { id: 'Overview', selected: false },
          { id: 'Inspect', selected: false },
          { id: 'Complete', selected: false }
          /* { id: 'Archived', selected: false } */
        ]

        this.setState({
          isLoading: false,
          formsList,
          formsConfig,
          currentConfig,
          assetsConfig: assets.sort((a, b) => (a.name > b.name ? 1 : -1)),
          issuesConfig: issues.sort((a, b) => (a.name > b.name ? 1 : -1)),
          solutionsConfig: solutions.sort((a, b) => (a.name > b.name ? 1 : -1)),
          productConfig: productConfig.sort((a, b) => (a.name > b.name ? 1 : -1)),
          crmFieldsConfig: crmFieldsConfig.sort((a, b) => (a.name > b.name ? 1 : -1)),
          // numericUnitsConfig: numericUnitsConfig.sort((a, b) => (a.name > b.name ? 1 : -1)),
          mediaArray,
          filterChips: [...prefilter]
        })
      })
      Api.callRegisteredMethod('getLatestConfigByTag', { tag: 'numericUnits', startsWith: false }).then((c) => {
        if (c.contentJson?.length > 0) {
          this.setState({ numericUnitsConfig: JSON.parse(c.contentJson) })
        } else {
          // console.log(`There are no units setup in General Settings/Dictionary Lists/Numeric Units.  The units drop downs may be empty.`);
          this.setState({ numericUnitsConfig: [] }) // DEV-5012 prevent death on new orgs
        }
      })
    })
  }

  addHandler (name) {
    const formsList = JSON.parse(JSON.stringify(this.state.formsList))
    formsList.push({
      id: this.guid(),
      formType: name,
      formTitle: 'Default Title',
      area: '',
      formSection: [],
      newForm: true,
      status: 'custom',
      formDescription: '',
      addPhoto: true,
      projectDisplay: true,
      formSubAreas: {
        include: false,
        formLabel: 'Sub-Area',
        areaLabel: 'Sub-Area',
        productRemainderLabel: 'Remainder of Area',
        areaOverrideLabel: 'Sub-Area',
        newAreaLabel: 'New Sub-Area'
      }
    })
    this.setState({ formsList, view: 'formNew' })
  }

  editHandler (idx, formId) {
    const formsList = JSON.parse(JSON.stringify(this.state.formsList))
    const index = formsList.findIndex((f) => {
      return f.id === formId
    })

    formsList[index].editForm = true
    // DEV-4382 Autofix for formSubAreas
    if (formsList[index].formSubAreas === undefined) {
      formsList[index].formSubAreas = {
        include: false,
        formLabel: 'Sub-Area',
        areaLabel: 'Sub-Area',
        productRemainderLabel: 'Remainder of Area',
        areaOverrideLabel: 'Sub-Area',
        newAreaLabel: 'New Sub-Area'
      }
    }
    this.setState({ formsList, view: 'formEdit' })
  }

  handleMove = (idx, formId, direction) => {
    const formsList = JSON.parse(JSON.stringify(this.state.formsList))
    if (direction === 'up') {
      if (formsList[idx - 1]) {
        const toSwitch = JSON.parse(JSON.stringify(formsList[idx]))
        const switchWith = JSON.parse(JSON.stringify(formsList[idx - 1]))
        formsList[idx - 1] = toSwitch
        formsList[idx] = switchWith
        this.setState({ formsList })
      }
    } else if (direction === 'down') {
      if (formsList[idx + 1]) {
        const switchWith = JSON.parse(JSON.stringify(formsList[idx + 1]))
        formsList[idx + 1] = JSON.parse(JSON.stringify(formsList[idx]))
        formsList[idx] = switchWith
        this.setState({ formsList })
      }
    }
  }

  handleChangeTab (event, newValue) {
    let tabValue = this.state.tabValue
    tabValue = newValue
    this.setState({ tabValue })
  }

  deleteHandler (formId) {
    const formsList = JSON.parse(JSON.stringify(this.state.formsList))
    const index = formsList.findIndex((form) => {
      return form.id === formId
    })
    formsList.splice(index, 1)
    this.setState({ formsList })
  }

  backToTable = (form) => {
    const formsList = JSON.parse(JSON.stringify(this.state.formsList))
    const index = formsList.findIndex((f) => {
      return f.id === form.id
    })

    if ('newForm' in form) {
      delete form.newForm
    }
    if ('editForm' in form) {
      delete form.editForm
    }

    if (this.state.isAdding) {
      formsList[index] = form
    } else {
      if (form.status === 'added') {
        form.status = 'edited'
        form.changedByUser = true
      } else if (form.status === 'added1') {
        form.changedByUser = true
      }

      // let idx = list.map((o) => o.id).indexOf(form.id);
      if (index > -1) {
        formsList[index] = form
      }
    }

    this.setState({ formsList, view: 'table' })
  }

  handleShowJSONEditor = (idx) => {
    const index = idx !== undefined ? idx : -1
    this.setState({ selectedIndex: index, showJSONEditor: !this.state.showJSONEditor })
  }

  handleChangeJSONEditor = (value) => {
    const formsList = JSON.parse(JSON.stringify(this.state.formsList))
    formsList[this.state.selectedIndex] = value
    this.setState({ formsList })
  }

  hasFinalize () {
    return true
  }

  finalize () {
    return JSON.stringify(this.state.formsList)
  }

  handleLibraryAdd = () => {
    this.setState({ showImportLibraryDialog: !this.state.showImportLibraryDialog })
  }

  handleLibrarySave = (l) => {
    const list = JSON.parse(JSON.stringify(this.state.formsList))
    for (let x = 0; x < list.length; x++) {
      if (l.includes(list[x].id)) {
        if (list[x].status && list[x].status !== 'custom') {
          list[x].status = 'added1'
        }
      } else {
        if (list[x].status && list[x].status.match(/^(added|edited)/) && !(list[x].hidden === true)) {
          list[x].status = 'deleted'
        }
      }
    }
    // console.log(fl);
    this.setState({ formsList: list })
  }

  triggerLibraryUpdateDialog = (id) => {
    const idx = this.state.formsList.findIndex((o) => o.id === id)
    if (idx > -1) {
      this.setState({ libraryUpdateIndex: idx })
    } else {
      console.log(`Could not find ${id} in formsList to update!`)
    }
    this.setState({ showLibraryUpdateDialog: !this.state.showLibraryUpdateDialog })
  }

  handleLibraryUpdate = () => {
    const idx = this.state.libraryUpdateIndex

    if (idx > -1) {
      const list = JSON.parse(JSON.stringify(this.state.formsList))

      if (list[idx].library && list[idx].library.currentVersion) {
        const libcopy = JSON.parse(JSON.stringify(list[idx].library))
        libcopy.changed = false
        list[idx] = list[idx].library.currentVersion
        list[idx].library = libcopy
        list[idx].status = 'added1'
      }
      this.setState({ formsList: list, showLibraryUpdateDialog: false, libraryUpdateIndex: -1 })
    }
  }

  handleChipSelect = (value) => {
    let filterChips = JSON.parse(JSON.stringify(this.state.filterChips))

    if (value.toLowerCase() === 'all') {
      filterChips = [
        { id: 'All', selected: true },
        { id: 'Overview', selected: false },
        { id: 'Inspect', selected: false },
        { id: 'Complete', selected: false }
        /* { id: 'Archived', selected: false } */
      ]
    }

    if (value.toLowerCase() === 'overview') {
      filterChips = [
        { id: 'All', selected: false },
        { id: 'Overview', selected: true },
        { id: 'Inspect', selected: false },
        { id: 'Complete', selected: false }
        /* { id: 'Archived', selected: false } */
      ]
    }

    if (value.toLowerCase() === 'inspect') {
      filterChips = [
        { id: 'All', selected: false },
        { id: 'Overview', selected: false },
        { id: 'Inspect', selected: true },
        { id: 'Complete', selected: false }
        /* { id: 'Archived', selected: false } */
      ]
    }

    if (value.toLowerCase() === 'complete') {
      filterChips = [
        { id: 'All', selected: false },
        { id: 'Overview', selected: false },
        { id: 'Inspect', selected: false },
        { id: 'Complete', selected: true }
        /* { id: 'Archived', selected: false } */
      ]
    }

    /* if(value.toLowerCase() === 'archived') {
      filterChips = [
        { id: 'All', selected: false },
        { id: 'Overview', selected: false },
        { id: 'Inspect', selected: false },
        { id: 'Complete', selected: false },
        /!*{ id: 'Archived', selected: true }*!/
      ]
    } */

    this.setState({ filterChips, filter: value })
  }

  render () {
    if (this.state.isLoading) return <LoadingPleaseWait />

    let formsList = JSON.parse(JSON.stringify(this.state.formsList))
    if (this.state.filter !== '' && this.state.filter !== 'All') {
      formsList = formsList.filter((o) => {
        if ('formType' in o) {
          return o.formType.toLowerCase() === this.state.filter.toLowerCase() || (o.formType.toLowerCase() === 'conclusion' && this.state.filter.toLowerCase() === 'complete')
        } else {
          return null
        }
      })
    }

    const view = this.state.view

    let editIndex = formsList.findIndex((f) => {
      return 'editForm' in f
    })

    const columns = [
      { field: 'id', headerName: 'Id', hide: true },
      {
        field: 'type',
        headerName: 'Type',
        flex: 1,
        renderCell: (val) => {
          return (
            <Stack>
              <Typography style={{ fontSize: '12px' }}>{val.row.type}</Typography>
            </Stack>
          )
        }
      },
      {
        field: 'title',
        headerName: 'Title',
        flex: 2,
        renderCell: (val) => {
          return (
            <Stack>
              <Typography style={{ fontSize: '12px' }}>{val.row.title}</Typography>
            </Stack>
          )
        }
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (val) => {
          return (
            <Stack>
              <Typography style={{ fontSize: '12px' }}>{val.value}</Typography>
            </Stack>
          )
        }
      },
      {
        flex: 2,
        field: 'library',
        headerName: 'Library',
        renderCell: (val) => {
          return (
            <Stack>
              <Typography style={{ fontSize: '12px' }}>{val.row.library}</Typography>
            </Stack>
          )
        }
      },
      {
        width: 196, // Do not allow truncation of the 3 icons when resizing
        field: 'actions',
        headerName: 'Actions',
        headerAlign: 'right',
        align: 'right',
        sortable: false,
        editable: false,
        renderCell: (val) => {
          return val.value
        }
      }
    ]

    const countNotAdded = formsList.filter(i => i.status === 'notadded').length
    const rowsData = []
    let actionsCell

    if (this.state.filter !== '') {
      formsList.filter((o) => {
        if ('formType' in o) {
          return o.formType.toLowerCase() === this.state.filter.toLowerCase()
        } else {
          return null
        }
      })
    }

    if (this.state.issuesConfig !== null && this.state.assetsConfig !== null && this.state.solutionsConfig !== null && this.state.productConfig !== null) {
      // eslint-disable-next-line array-callback-return
      formsList.map((form, idx) => {
        if ('editForm' in form) {
          editIndex = idx
        }

        let statusCell
        let libraryCell
        const currentCompany = Company.getCurrent()

        if (!form.status || form.status === 'custom') {
          libraryCell = currentCompany.name
        } else {
          if (form.library) {
            if (form.status.match(/notadded|deleted/)) {
              return null
            }
            if (form.library.changed === true) {
              // console.log('it is true!');
            }
            statusCell = (
              <Box component='span'>
                {form.library.changed
                  ? (
                    <IconButton
                      title='Update Library'
                      aria-label='Update Library'
                      onClick={() => {
                        this.triggerLibraryUpdateDialog(form.id)
                      }}
                      size='small'
                    >
                      <WarningIcon size='small' style={{ color: 'red' }} />
                    </IconButton>
                    )
                  : form.status.match(/^(added|edited)/)
                    ? (
                      <CheckIcon size='small' style={{ color: 'green' }} sx={{ pr: 1 }} />
                      )
                    : null}
                {form.changedByUser ? <Chip key='chip-modified' component='span' label='Modified' size='small' sx={{ fontSize: '12px' }} /> : null}
              </Box>
            )
            libraryCell = form.library.libraryName
          }
        }

        actionsCell = (
          <Grid container wrap='nowrap' justifyContent='flex-end' style={{ overflow: 'visible' }}>
            {Permissions.hasRole('super_user')
              ? (
                <Grid item>
                  <IconButton onClick={() => {
                    this.handleShowJSONEditor(idx)
                  }}
                  >
                    <AdminIcon size='small' style={{ color: 'red' }} />
                  </IconButton>
                </Grid>
                )
              : null}

            <Grid item>
              <IconButton
                title='Edit'
                aria-label='edit'
                onClick={() => {
                  // this.editHandler(name, idx, form.id);
                  this.editHandler(idx, form.id)
                }}
              >
                <EditIcon variant='icon' fontSize='small' />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                title='Up'
                aria-label='up'
                onClick={() => {
                  this.handleMove(idx, form.id, 'up')
                }}
                disabled={idx < 1}
              >
                <UpIcon variant='icon' fontSize='small' />
              </IconButton>
              <IconButton
                title='Down'
                aria-label='down'
                onClick={() => {
                  this.handleMove(idx, form.id, 'down')
                }}
                disabled={idx === formsList.length - (1 + countNotAdded)}
              >
                <DownIcon variant='icon' fontSize='small' />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                title='Delete'
                aria-label='delete'
                onClick={() => {
                  this.deleteHandler(form.id)
                }}
              >
                <DeleteIcon variant='icon' fontSize='small' />
              </IconButton>
            </Grid>
          </Grid>
        )

        rowsData.push({ id: form.id, type: form.formType, title: form.formTitle, status: statusCell, library: libraryCell, actions: actionsCell })
      })
    }

    if (this.state.showImportLibraryDialog) {
      return (
        <DialogFormsSelector
          title='Add Library Forms'
          variant='library'
          list={this.state.formsList.filter((o) => o.status && o.status.match(/^(notadded|added|edited)/))}
          formsList={this.state.formsList.filter((o) => o.status && o.status !== 'custom' && !(o.hidden === true))}
          onSave={(l) => {
            this.handleLibrarySave(l)
            this.setState({ showImportLibraryDialog: !this.state.showImportLibraryDialog })
          }}
          onClose={() => this.setState({ showImportLibraryDialog: !this.state.showImportLibraryDialog })}
        />
      )
    }

    if (this.state.showLibraryUpdateDialog) {
      return (
        <DialogGeneric
          title='Update Form'
          content={
            <Box>
              <Stack>
                <Typography variant='body1' sx={{ pb: 2 }}>
                  By updating the form, you will replace the form in <br /> it's current state.
                </Typography>
                <Typography variant='body1' sx={{ pb: 2 }}>
                  If you have modified the form, it is encouraged that <br />
                  you document any custom changes so that you can <br /> update the form.
                </Typography>
              </Stack>
            </Box>
          }
          onChange={this.handleLibraryUpdate}
          onClose={() => this.setState({ showLibraryUpdateDialog: !this.state.showLibraryUpdateDialog })}
          titleDone='Update'
          titleClose='Cancel'
        />
      )
    }

    switch (view) {
      case 'table':
        return (
          <>
            <Box sx={{ marginTop: '32px' }}>
              <Box sx={{ marginBottom: '16px' }}>
                {this.state.filterChips.map((f) => {
                  return (
                    <Chip
                      key={`Chip-${f.id}`}
                      sx={{ p: 0.5, marginRight: 1, fontSize: '0.875rem' }}
                      color={f.selected === true ? 'primary' : 'default'}
                      label={`${f.id} Forms`}
                      size='medium'
                      onClick={() => this.handleChipSelect(f.id)}
                    />
                  )
                })}
                {
                  /* this.state.filter !== '' && this.state.filter !== 'All' && this.state.filter !== 'Archived' && */
                  this.state.filter !== '' && this.state.filter !== 'All' && (
                    <Button
                      variant='contained'
                      color='success'
                      onClick={() => this.addHandler(this.state.filter)}
                      startIcon={<AddIcon />}
                      sx={{ float: 'right' }}
                      size='small'
                    >
                      Add Form to {this.state.filter}
                    </Button>
                  )
                }
                {Permissions.hasRole('super_user')
                  ? (
                    <PopoverAutomation formsList={formsList} solutionsConfig={this.state.solutionsConfig} productConfig={this.state.productConfig} />
                    )
                  : null}
              </Box>
              <Box>
                <DataGrid
                  rows={rowsData}
                  columns={columns}
                  defaultPageSize={50}
                  showToolbarDensity
                  useStandardRowHeight
                  rowHeight={52}
                  autosizeOptions={{
                    columns: ['action'],
                    includeOutliers: true,
                    includeHeaders: false
                  }}
                />
              </Box>
            </Box>
            {this.state.showJSONEditor && this.state.selectedIndex > -1
              ? <DialogGeneric
                  title='JSON Field Editor'
                  fullWidth
                  maxWidth='md'
                  content={
                    <JSONEditor
                      value={JSON.stringify(formsList[this.state.selectedIndex])}
                      onChange={(value) => {
                        this.handleChangeJSONEditor(JSON.parse(value))
                      }}
                      saveOnlyOnDone
                    />
                    }
                  onClose={this.handleShowJSONEditor}
                  onSave={this.handleShowJSONEditor}
                />
              : null}
          </>
        )

      case 'formNew':
        return (
          <SingleFormEditor
            form={formsList[formsList.length - 1]}
            backToTable={this.backToTable}
            guid={this.guid}
            currentConfig={this.state.currentConfig} // Used in solution editor, it's massaged differently!
            assetsConfig={this.state.assetsConfig}
            solutionsConfig={this.state.solutionsConfig}
            productConfig={this.state.productConfig}
            productList={this.state.productList}
            crmFieldsConfig={this.state.crmFieldsConfig}
            numericUnitsConfig={this.state.numericUnitsConfig}
            formsList={this.state.formsList}
            orgId={this.props.organizationId}
            formsConfig={this.state.formsConfig}
          />
        )

      case 'formEdit':
        return (
          <SingleFormEditor
            form={formsList[editIndex]}
            backToTable={this.backToTable}
            guid={this.guid}
            currentConfig={this.state.currentConfig} // Used in solution editor, it's massaged differently!
            assetsConfig={this.state.assetsConfig}
            solutionsConfig={this.state.solutionsConfig}
            productList={this.state.productList}
            productConfig={this.state.productConfig}
            crmFieldsConfig={this.state.crmFieldsConfig}
            numericUnitsConfig={this.state.numericUnitsConfig}
            formsList={this.state.formsList}
            orgId={this.props.organizationId}
            formsConfig={this.state.formsConfig}
          />
        )

      default:
        return null
    }
  }
}

export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} editorComponent={CompanyConfigForms2} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}
