import React from 'react'
import { Box, Tabs, Tab, Chip, ImageList, ImageListItem, ImageListItemBar, IconButton, Grid } from '@mui/material'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import MovieCreationTwoToneIcon from '@mui/icons-material/MovieCreationTwoTone'
import PictureAsPdfTwoToneIcon from '@mui/icons-material/PictureAsPdfTwoTone'
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined'
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import Search from '../../components/Reusable/Search'
import { LoadingPleaseWait } from '@supportworks/react-components'
import '../../css/selector-material-ui.css'

class SelectorWithPreview extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: true,
      selectedConfig: props.selectedConfig,
      fieldName: props.fieldName,
      config: props.config,
      asset: null,
      assetType: props.assetType,
      valueTab1: 1,
      valueTab2: 0,
      filter: '',
      visibleCounter: props.config ? props.config.length : 0,
      mode: 'all'
    }
    this.handleChangeTab1 = this.handleChangeTab1.bind(this)
    this.handleChangeTab2 = this.handleChangeTab2.bind(this)
  }

  componentDidMount () {
    let asset = this.state.asset
    if (this.state.selectedConfig[this.state.fieldName] !== '' && this.state.selectedConfig[this.state.fieldName] !== null) {
      if (this.props.config) {
        asset = this.props.config.filter((a) => {
          return a.id === this.props.selectedConfig[this.props.fieldName]
        })
        if (asset.length > 0) {
          asset = asset[0]
        }
      }
    }
    this.setState({ isLoading: false, asset })
  }

  iconClick (event, item) {
    let asset = this.state.asset
    if (asset !== null) {
      if (asset.id === item.id) {
        asset = null
      } else {
        asset = item
      }
    } else {
      asset = item
    }
    // console.log(asset);
    this.props.onUpdate(asset)

    this.setState({ asset })
  }

  handleChangeTab1 (value, newValue) {
    let valueTab1 = this.state.valueTab2
    valueTab1 = newValue
    this.setState({ valueTab1 })
  }

  handleChangeTab2 (value, newValue) {
    let valueTab2 = this.state.valueTab2
    valueTab2 = newValue
    this.setState({ valueTab2 })
  }

  render () {
    if (this.state.isLoading) {
      return <LoadingPleaseWait />
    }

    let visibleCounter = this.state.visibleCounter

    const checked = {
      color: '#1976d2'
    }

    let image
    let video
    let pdf
    let audio
    if (this.state.config) {
      image = this.state.config.filter((c, idx) => {
        return c.type === 'Image'
      })
      video = this.state.config.filter((c, idx) => {
        return c.type === 'Video'
      })
      pdf = this.state.config.filter((c, idx) => {
        return c.type === 'PDF'
      })
      audio = this.state.config.filter((c, idx) => {
        return c.type === 'Audio'
      })
    }

    const TabPanel = ({ value, index, data }) => {
      // console.log(value, index);
      return (
        <div role='tabpanel' hidden={value !== index} id={`tab2-tabpanel-${index}`} aria-labelledby={`tab2-tab-${index}`}>
          {value === index
            ? (
              <Grid container spacing={0}>
                <ImageList sx={{ width: '100%' }} cols={3}>
                  {data && data
                    .filter((item) => this.state.assetType.includes(item.type))
                    .map((item, idx) => {
                      let visible = true
                      if (this.state.filter.length > 0) {
                        visible = item.title.toUpperCase().includes(this.state.filter)
                      } else {
                        visible = true
                      }
                      const display = visible ? 'block' : 'none'

                      if (display === 'none') {
                        visibleCounter--
                      }

                      if (visibleCounter === 0) {
                        return (
                          <div className='search-noresult' key={idx}>
                            <span className='search-noresult-span'>No matching results found.</span>
                          </div>
                        )
                      } else {
                        return (
                          <ImageListItem key={item.id} style={{ border: '1px solid rgba(0,0,0,.16)', marginTop: 8, marginRight: 8, display }}>
                            {item.type === 'Image' && (
                              <div>
                                <div className='selector-tile'>
                                  <img
                                    className='responsive'
                                    src={item.previewURL}
                                    alt={item.title}
                                    loading='lazy'
                                    onClick={() => {
                                      window.open(item.path, '_blank')
                                    }}
                                  />
                                </div>
                                <div className='selector-title'>{item.title}</div>
                              </div>
                            )}
                            {item.type === 'Video' && (
                              <div>
                                <div className='selector-tile'>
                                  {item.previewURL
                                    ? (
                                      <img
                                        className='responsive'
                                        src={item.previewURL}
                                        alt={item.title}
                                        loading='lazy'
                                        onClick={() => {
                                          window.open(item.path, '_blank')
                                        }}
                                      />
                                      )
                                    : (
                                      <MovieCreationTwoToneIcon
                                        fontSize='large'
                                        onClick={() => {
                                          window.open(item.path, '_blank')
                                        }}
                                      />
                                      )}
                                </div>
                                <div className='selector-title'>{item.title}</div>
                              </div>
                            )}
                            {item.type === 'PDF' && (
                              <div>
                                <div className='selector-tile'>
                                  {item.previewURL
                                    ? (
                                      <img
                                        className='responsive'
                                        src={item.previewURL}
                                        alt={item.title}
                                        loading='lazy'
                                        onClick={() => {
                                          window.open(item.path, '_blank')
                                        }}
                                      />
                                      )
                                    : (
                                      <PictureAsPdfTwoToneIcon
                                        fontSize='large'
                                        onClick={() => {
                                          window.open(item.path, '_blank')
                                        }}
                                      />
                                      )}
                                </div>
                                <div className='selector-title'>{item.title}</div>
                              </div>
                            )}
                            {/** show an audio type image with a play button */}
                            {item.type === 'Audio' && (
                              <div>
                                <div className='selector-tile'>
                                  {item.previewURL
                                    ? (
                                      <Box sx={{ p: 1, width: '100%' }}>
                                        <audio controls style={{ width: '100%' }}>
                                          <source src={item.path} type='audio/mpeg' />
                                          Your browser does not support the audio element.
                                        </audio>
                                      </Box>
                                      )
                                    : (
                                      <PlayCircleOutlineOutlinedIcon
                                        fontSize='large'
                                        onClick={() => {
                                          window.open(item.path, '_blank')
                                        }}
                                      />
                                      )}
                                </div>
                                <div className='selector-title'>{item.title}</div>
                              </div>
                            )}

                            <ImageListItemBar
                              position='top'
                              subtitle={<span style={{ color: 'rgba(0,0,0,.60)' }}>{item.type}</span>}
                              style={{ backgroundColor: 'rgba(52,52,52,0.01)' }}
                              actionIcon={
                                <IconButton variant='contained' aria-label={item.title} onClick={(event) => this.iconClick(event, item)}>
                                  {this.state.asset && this.state.asset.id === item.id ? <CheckBoxIcon style={checked} /> : <CheckBoxOutlineBlankIcon />}
                                </IconButton>
                            }
                            />
                          </ImageListItem>
                        )
                      }
                    })}
                </ImageList>
              </Grid>
              )
            : null}
        </div>
      )
    }

    return (
      <Box>
        {this.state.assetType.length > 1 && (
          <Tabs
            value={this.state.valueTab2}
            onChange={this.handleChangeTab2}
            TabIndicatorProps={{
              style: {
                display: 'none'
              }
            }}
            textColor='primary'
          >
            <Tab
              id='tab2-index-0'
              label={
                <Chip style={{ width: '96px' }} className='mui-btn' label='All' variant={this.state.mode === 'all' ? 'filled' : 'outlined'} color='primary' />
              }
              className={this.props.assetType.length > 1 ? 'tab-panel-show' : 'tab-panel-hide'}
              onClick={() => this.setState({ mode: 'all' })}
              style={styles.tabsRoot}
              disableRipple
            />

            <Tab
              id='tab2-index-1'
              label={
                <Chip
                  style={{ width: '96px' }}
                  className='mui-btn'
                  label='Images'
                  variant={this.state.mode === 'images' ? 'filled' : 'outlined'}
                  color='primary'
                  icon={<ImageOutlinedIcon />}
                />
              }
              className={this.props.assetType.length > 1 && this.props.assetType.includes('Image') ? 'tab-panel-show' : 'tab-panel-hide'}
              onClick={() => this.setState({ mode: 'images' })}
              style={styles.tabsRoot}
              disableRipple
            />

            <Tab
              id='tab2-index-2'
              label={
                <Chip
                  style={{ width: '96px' }}
                  className='mui-btn'
                  label='Videos'
                  variant={this.state.mode === 'videos' ? 'filled' : 'outlined'}
                  color='primary'
                  icon={<PlayCircleOutlineOutlinedIcon />}
                />
              }
              className={this.props.assetType.length > 1 && this.props.assetType.includes('PDF') ? 'tab-panel-show' : 'tab-panel-hide'}
              onClick={() => this.setState({ mode: 'videos' })}
              style={styles.tabsRoot}
              disableRipple
            />

            <Tab
              id='tab2-index-3'
              label={
                <Chip
                  style={{ width: '96px' }}
                  className='mui-btn'
                  label='PDFs'
                  variant={this.state.mode === 'pdfs' ? 'filled' : 'outlined'}
                  color='primary'
                  icon={<PictureAsPdfOutlinedIcon />}
                />
              }
              className={this.props.assetType.length > 1 && this.props.assetType.includes('PDF') ? 'tab-panel-show' : 'tab-panel-hide'}
              onClick={() => this.setState({ mode: 'pdfs' })}
              style={styles.tabsRoot}
              disableRipple
            />

            <Tab
              id='tab2-index-4'
              label={
                <Chip
                  style={{ width: '96px' }}
                  className='mui-btn'
                  label='Audio'
                  variant={this.state.mode === 'audios' ? 'filled' : 'outlined'}
                  color='primary'
                  icon={<PlayCircleOutlineOutlinedIcon />}
                />
              }
              className={this.props.assetType.length > 1 && this.props.assetType.includes('Audio') ? 'tab-panel-show' : 'tab-panel-hide'}
              onClick={() => this.setState({ mode: 'audios' })}
              style={styles.tabsRoot}
              disableRipple
            />

          </Tabs>
        )}

        <Search
          onChange={(k) => {
            this.setState({ filter: k.trim().toUpperCase() })
          }}
          wrapperClass='userSearch__wrapper asset-search'
        />

        <Box>
          <TabPanel value={this.state.valueTab2} index={0} data={this.state.config} />
          <TabPanel value={this.state.valueTab2} index={1} data={image} />
          <TabPanel value={this.state.valueTab2} index={2} data={video} />
          <TabPanel value={this.state.valueTab2} index={3} data={pdf} />
          <TabPanel value={this.state.valueTab2} index={4} data={audio} />
        </Box>
      </Box>
    )
  }
}

const styles = {
  tabRoot: {
    border: '0px solid red',
    margin: 0,
    padding: 0,
    height: '36px',
    minHeight: '36px'
  },
  tabsRoot: {
    margin: 0,
    padding: 0,
    paddingBottom: 8
  }
}

export default SelectorWithPreview
