import React, { useEffect, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

function LogicOptionsTab (props) {
  const [value, setValue] = useState(0)
  const [relatedLength, setRelatedLength] = useState(0)
  const boolOptions = ['Yes', 'No']

  const changeHandlerValue = (event, newVal) => {
    setValue(newVal)
  }

  const onClickTab = (item) => {
    props.onClickTab(item)
  }

  const getOperator = (opr) => {
    switch (opr) {
      case 'lt':
        return <i className='fas fa-less-than' />
      case 'lte':
        return <i className='fas fa-less-than-equal' />
      case 'gt':
        return <i className='fa-regular fa-greater-than' />
        /* return <i className="fas fa-greater-than"></i>; */
      case 'gte':
        return <i className='fas fa-greater-than-equal' />
      case 'eq':
        return <i className='fas fa-equals' />
      default:
        return ''
    }
  }

  useEffect(() => {
    if (props.field.related) {
      setRelatedLength(props.field.related.length)
    }
  }, [props.field.related])

  useEffect(() => {
    if (props.field.related && props.field.related.length > 0) {
      if (props.field.related.length !== relatedLength) {
        if ('list' in props.field) {
          const index = props.field.list.findIndex((i) => i === props.field.related[props.field.related.length - 1].value)
          setValue(index)
        }
        if ('choices' in props.field) {
          const index = props.field.choices.findIndex((i) => i.id === props.field.related[props.field.related.length - 1].value)
          setValue(index)
        }
        if (props.field.type === 'numeric' && 'related' in props.field) {
          setValue(props.field.related.length - 1)
        }
        if (props.field.type === 'boolean' && 'related' in props.field) {
          if (props.field.related[props.field.related.length - 1].value === 'No') {
            setValue(1)
          }
          if (props.field.related[props.field.related.length - 1].value === 'Yes') {
            setValue(0)
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [props.field.related])

  return (
    <>
      <Tabs value={value} onChange={changeHandlerValue} variant='scrollable'>
        {'list' in props.field &&
                    props.field.list.map((item, idx) => {
                      // console.log(item);
                      return <Tab key={item} label={<span style={styles.tabHeader}>= {item}</span>} onClick={() => onClickTab(item)} />
                    })}

        {'choices' in props.field &&
                    props.field.choices.map((item, idx) => {
                      return <Tab key={item.title} label={<span style={styles.tabHeader}>= {item.title}</span>} onClick={() => onClickTab(item.title)} />
                    })}

        {props.field.type === 'numeric' &&
                    'related' in props.field &&
                    props.field.related.map((rel, idx) => {
                      const range = Object.keys(rel.ranges[0])[0]
                      const rangeVal = rel.ranges[0][Object.keys(rel.ranges[0])[0]]
                      return (
                        <Tab
                          key={props.guid()}
                          label={
                            <span style={styles.tabHeader}>
                              {getOperator(range)} {rangeVal}
                            </span>
                                }
                          onClick={() => onClickTab(rel.id)}
                        />
                      )
                    })}

        {props.field.type === 'calc' &&
                    'related' in props.field &&
                    props.field.related.map((rel, idx) => {
                      const range = Object.keys(rel.ranges[0])[0]
                      const rangeVal = rel.ranges[0][Object.keys(rel.ranges[0])[0]]
                      return (
                        <Tab
                          key={props.guid()}
                          label={
                            <span style={styles.tabHeader}>
                              {getOperator(range)} {rangeVal}
                            </span>
                                }
                          onClick={() => onClickTab(rel.id)}
                        />
                      )
                    })}

        {
                    /* props.field.type === 'boolean' &&
                    'related' in props.field &&
                    props.field.related.map((rel, idx) => {
                        console.log(rel);
                        return(
                            <Tab
                                key={rel.id}
                                label={<span style={styles.tabHeader}>= {rel.value}</span>}
                                onClick={() => onClickTab(rel.id)}
                            />
                        )
                    }) */

                    props.field.type === 'boolean' &&
                        boolOptions.map((rel, idx) => {
                          return <Tab value={rel === 'Yes' ? 0 : 1} key={rel} label={<span style={styles.tabHeader}>= {rel}</span>} onClick={() => onClickTab(rel)} />
                        })
                }
      </Tabs>
    </>
  )
}

const styles = {
  tabHeader: {
    fontSize: '13px'
  }
}

export default LogicOptionsTab
