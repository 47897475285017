/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import CompanyConfigEdit from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'

const config = {
  title: 'Current Configuration',
  variant: '__current',
  tag: '__current',
  name: 'Current Configuration',
  lockTag: true,
  lockName: true,
  readonly: true,
  current: true
}

export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}
