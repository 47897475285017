import React, { useState } from 'react'
import { FormControl, MenuItem, TextField, Button } from '@mui/material'

const MapFormFieldChooseField = ({ formField: defaultValue, formFields, onChange, toggleMapping, disabled }) => {
  const [formField, setFormField] = useState(defaultValue)

  const handleChange = (event) => {
    const { name, value } = event.target
    // console.log(name, value);
    if (name === 'formField') {
      if (!formField) {
        setFormField(value)
        onChange(event)
      } else {
        if (formField !== value) {
          if (window.confirm('Changing the form field will reset all configured values.  Are you sure?')) {
            setFormField(value)
            onChange(event)
          } else {
            console.log('Skipping update.')
          }
        }
      }
    }
  }

  return (
    <>
      <div className='sa-section'>
        <FormControl key='FormControl' name='formField' variant='outlined' fullWidth>
          <TextField
            select
            name='formField'
            label='Choose Field'
            variant='outlined'
            value={formField || ''}
            onChange={handleChange}
          >
            {
                            !(formFields == null) &&
                            formFields.map((row, idx) => {
                              return (
                                <MenuItem key={`MFFMI-${idx}`} value={row.id}>{row.sectionTitle ? row.sectionTitle + ' - ' : null}{row.title}</MenuItem>
                              )
                            })
                        }
          </TextField>
        </FormControl>
      </div>
      <div className='sa-section'>
        <Button color='primary' variant='contained' onClick={toggleMapping} fullWidth disabled={disabled || false}>
          <span className='sa-button'>Map Field Values to Automate Products</span>
        </Button>
      </div>
    </>
  )
}
export default MapFormFieldChooseField
