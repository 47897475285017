import React, { useState } from 'react'
import { Box, IconButton, Drawer, Fab } from '@mui/material'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import '../../css/dev-debug.css'

/**
 * DrawerDebug
 *
 * This component was invented during solution automation to more easily tell real-time changes to the solution json object
 * without having to drop lots of console.log's in or break points in a debugger.
 *
 * Usage:
 *
 * Add something like this inside of your component.
 *    { Permissions.hasRole("super_user") ? <DrawerDebug json={[{name:"solution",json:solution},{name:"scratch",json:scratch}]}/> : null }
 *
 * A debug icon will show up on the bottom left.
 * If you click to the left of the icon, a mui Drawer will open to the left.
 * If you click to the right of the icon, a mui Drawer will open to the right.
 *
 * @param {*} param0
 * @returns
 */
const DrawerDebug = ({ json = [] }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [anchor, setAnchor] = useState('left')

  const handleClick = (event) => {
    const x = event.nativeEvent.offsetX
    if (x < 20) setAnchor('left')
    if (x >= 20) setAnchor('right')
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Box className='development-fab-container'>
        <IconButton
          size='small'
          onClick={() => {
            setIsOpen(!isOpen)
            setAnchor('left')
          }}
          sx={{ color: 'red' }}
        >
          <KeyboardArrowLeftIcon size='small' />
        </IconButton>
        <Fab
          size='small'
          className='development-fab'
          onClick={(e) => {
            handleClick(e)
          }}
        >
          <AdminPanelSettingsIcon fontSize='large' style={{ color: 'red' }} />
        </Fab>
        <IconButton
          size='small'
          onClick={() => {
            setIsOpen(!isOpen)
            setAnchor('right')
          }}
          sx={{ color: 'red' }}
        >
          <KeyboardArrowRightIcon size='small' />
        </IconButton>
      </Box>
      {isOpen
        ? (
          <Drawer
            PaperProps={{
              sx: { pt: 2, pl: 2, pr: 2, pb: 8, border: '3px dotted red', backgroundColor: 'black', width: '33%', borderBottomLeftRadius: 16, zIndex: 1 }
            }}
            anchor={anchor}
            variant='permanent'
            open
          >
            <Box>
              {json.map((j) => {
                return (
                  <div key={j.name}>
                    <pre className='development-title-text'>{j.name}</pre>
                    <pre className='development-content-text'>
                      {/* <JSONEditor value={JSON.stringify(j.json)} maxLines={50}/> */}
                      {JSON.stringify(j.json, null, 2)}
                    </pre>
                  </div>
                )
              })}
            </Box>
          </Drawer>
          )
        : null}
    </>
  )
}
export default DrawerDebug
