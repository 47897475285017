import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, IconButton, Card, CardMedia, CardContent, Switch, Typography, TextField } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import arcSiteFavicon from '../../images/arcsite-favicon.png'
import magicplanfavicon from '../../images/magicplan-favicon.png'
import zapierFavicon from '../../images/zapier-favicon.svg'
import Api from 'library/Api'
import Company from './index'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import DrawerDebug from 'components/Reusable/DrawerDebug'
import { LoadingPleaseWait } from '@supportworks/react-components'
import Permissions from 'library/Permissions'
import H from 'library/helper'
import { styled } from '@mui/material/styles'
import { Topics } from 'config/topics'
import withScreenWrapper from '../withScreenWrapper'

const config = {
  title: 'Integrations',
  variant: 'Integrations',
  tag: 'integrations',
  name: 'Integrations',
  lockTag: true,
  lockName: true
}

class CompanyIntegrations extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      integrations: [],
      isLoading: true,
      zapToken: null,
      showDialogZapier: false,
      showDialogArcSite: false,
      showDialogMagicPlan: false
    }
    this.handleSaveArcSite = this.handleSaveArcSite.bind(this)
    this.handleSaveMagicPlan = this.handleSaveMagicPlan.bind(this)
  }

  componentDidMount () {
    Api.callRegisteredMethod('validateSession', { topic: Topics.ValidateSession }).then((rsp) => {
      if (rsp && rsp.status === 'success') {
        const orgs = rsp.data[0].organizations
        const compObj = orgs.find((x) => x.id === parseInt(this.props.organizationId))
        if (compObj && compObj.integrations.find((y) => y.app === 'zapier')) {
          const zapObj = compObj.integrations.find((y) => y.app === 'zapier')
          if (zapObj.token) {
            this.setState({ zapToken: zapObj.token })
          }
        }
      }
      Api.callRegisteredMethod('getLatestConfigByTag', { tag: 'integrations', startsWith: false }).then((c) => {
        if (c.contentJson === undefined) c.contentJson = '[]'
        if (c && c.contentJson) {
          const cj = JSON.parse(c.contentJson)
          // Auto-fix stuff.
          let app = cj.find((o) => o.app === 'zapier')
          if (app === undefined) {
            cj.push({ app: 'zapier', enabled: false })
          } else {
            if (app.enabled === undefined) app.enabled = false
          }

          app = cj.find((o) => o.app === 'ArcSite')
          if (app === undefined) {
            cj.push({ app: 'ArcSite', APIKey: '', enabled: false })
          } else {
            if (app.enabled === undefined) {
              app.APIKey ? (app.enabled = true) : (app.enabled = false)
            }
          }

          app = cj.find((o) => o.app === 'MagicPlan')
          if (app === undefined) {
            cj.push({ app: 'MagicPlan', customerId: '', APIKey: '', enabled: false })
          } else {
            if (app.enabled === undefined) {
              app.APIKey ? (app.enabled = true) : (app.enabled = false)
            }
          }
          this.setState({ isLoading: false, integrations: cj })
        } else {
          this.setState({ isLoading: false })
        }
      })
    })
  }

  toggleConnect = (app) => {
    const integrations = JSON.parse(JSON.stringify(this.state.integrations))
    const integration = integrations.find((o) => o.app === app)
    integration.enabled = !integration.enabled
    this.handleSave(integrations)
  }

  toggleDialog = (app) => {
    if (app === 'zapier') this.setState({ showDialogZapier: !this.state.showDialogZapier })
    if (app === 'ArcSite') this.setState({ showDialogArcSite: !this.state.showDialogArcSite })
    if (app === 'MagicPlan') this.setState({ showDialogMagicPlan: !this.state.showDialogMagicPlan })
  }

  handleSaveZapier = () => {
    const integrations = JSON.parse(JSON.stringify(this.state.integrations))
    // Nothing else to do, Zapier doesn't set anything...
    this.setState({ showDialogZapier: !this.state.showDialogZapier })
    this.handleSave(integrations)
  }

  handleSaveArcSite = (integration) => {
    const integrations = JSON.parse(JSON.stringify(this.state.integrations))
    const index = integrations.findIndex((e) => e.app === 'ArcSite')
    if (index > -1) {
      integrations[index] = integration
      this.setState({ showDialogArcSite: !this.state.showDialogArcSite })
      this.handleSave(integrations)
    }
  }

  handleSaveMagicPlan = (integration) => {
    const integrations = JSON.parse(JSON.stringify(this.state.integrations))
    const index = integrations.findIndex((e) => e.app === 'MagicPlan')
    if (index > -1) {
      integrations[index] = integration
      this.setState({ showDialogMagicPlan: !this.state.showDialogMagicPlan })
      this.handleSave(integrations)
    }
  }

  handleSave = async (integrations) => {
    await H.saveConfig(this.props.organizationId, config, integrations)
    this.setState({ integrations })
  }

  isEnabled = (app) => {
    const idx = this.state.integrations.findIndex((o) => o.app === app && o.enabled === true)
    if (idx > -1) {
      return true
    }
    return false
  }

  render () {
    if (this.state.isLoading) return <LoadingPleaseWait />

    // console.log(JSON.stringify(this.state.integrations, null, 2));

    const showConnectZapier = this.state.integrations.findIndex((o) => o.app === 'zapier' && o.enabled === true) > -1
    const showConnectArcSite = this.state.integrations.findIndex((o) => o.app === 'ArcSite' && o.enabled === true) > -1
    const showConnectMagicPlan = this.state.integrations.findIndex((o) => o.app === 'MagicPlan' && o.enabled === true) > -1

    return (
      <div className='CompanyIntegrations'>
        <Company.Header title='Integrations' />

        <Card sx={{ display: 'flex', width: 650, height: 170, mt: 1 }}>
          <CardMedia component='img' sx={{ width: 170, p: 2 }} image={zapierFavicon} alt='zapier' />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography component='div' variant='h6' sx={{ fontWeight: '700', color: '#212529d1' }}>
                Zapier
              </Typography>
              <Typography variant='subtitle1' color='text.secondary' component='div'>
                Use Zapier to connect SolutionView with over 2,000 websites and apps.
              </Typography>
              <MuiSwitchLarge
                checkedIcon={<CheckCircleRoundedIcon fontSize='large' />}
                onChange={() => this.toggleConnect('zapier')}
                checked={this.isEnabled('zapier')}
                disabled={!this.state.zapToken}
              />

              {showConnectZapier
                ? (
                  <Button
                    className='mui-btn'
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      this.toggleDialog('zapier')
                    }}
                    sx={{ mt: 1, ml: 3 }}
                    disabled={!this.state.zapToken}
                  >
                    {this.state.zapToken ? <>Connect</> : <Typography>Missing Zapier Token</Typography>}
                  </Button>
                  )
                : null}
            </CardContent>
          </Box>
        </Card>
        {this.state.showDialogZapier ? <DialogZapier zapToken={this.state.zapToken} onClose={() => this.handleSaveZapier()} /> : null}

        <Card sx={{ display: 'flex', width: 650, height: 170, mt: 2 }}>
          <CardMedia component='img' sx={{ width: 170, p: 2 }} image={arcSiteFavicon} alt='solutionView' />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography component='div' variant='h6' sx={{ fontWeight: '700', color: '#212529d1' }}>
                ArcSite
              </Typography>
              <Typography variant='subtitle1' color='text.secondary' component='div'>
                Easy-to-use CAD App for Sales & Inspection Professionals.
              </Typography>
              <MuiSwitchLarge
                checkedIcon={<CheckCircleRoundedIcon fontSize='large' />}
                onChange={() => this.toggleConnect('ArcSite')}
                checked={this.isEnabled('ArcSite')}
              />

              {showConnectArcSite && (
                <Button variant='contained' size='medium' sx={{ mt: 1, ml: 2 }} onClick={() => this.toggleDialog('ArcSite')}>
                  Connect
                </Button>
              )}
            </CardContent>
          </Box>
        </Card>
        {this.state.showDialogArcSite && (
          <DialogArcSite
            integration={this.state.integrations.find((o) => o.app === 'ArcSite')}
            onClose={() => this.toggleDialog('ArcSite')}
            onSave={(integration) => this.handleSaveArcSite(integration)}
          />
        )}

        <Card sx={{ display: 'flex', width: 650, height: 170, mt: 2 }}>
          <CardMedia component='img' sx={{ width: 170, p: 2 }} image={magicplanfavicon} alt='solutionView' />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography component='div' variant='h6' sx={{ fontWeight: '700', color: '#212529d1' }}>
                magicplan
              </Typography>
              <Typography variant='subtitle1' color='text.secondary' component='div'>
                Sketch & measure rooms fast - Fast, precise & easy to use.
              </Typography>
              <MuiSwitchLarge
                checkedIcon={<CheckCircleRoundedIcon fontSize='large' />}
                onChange={() => this.toggleConnect('MagicPlan')}
                checked={this.isEnabled('MagicPlan')}
              />

              {showConnectMagicPlan && (
                <Button variant='contained' size='medium' sx={{ mt: 1, ml: 2 }} onClick={() => this.toggleDialog('MagicPlan')}>
                  Connect
                </Button>
              )}
            </CardContent>
          </Box>
        </Card>
        {this.state.showDialogMagicPlan && (
          <DialogMagicPlan
            integration={this.state.integrations.find((o) => o.app === 'MagicPlan')}
            onClose={() => this.toggleDialog('MagicPlan')}
            onSave={(integration) => this.handleSaveMagicPlan(integration)}
          />
        )}

        {Permissions.hasRole('super_user') ? <DrawerDebug json={[{ name: 'Integrations', json: this.state.integrations }]} /> : null}
      </div>
    )
  }
}
export default withScreenWrapper(CompanyIntegrations)

const DialogZapier = ({ zapToken, onClose }) => {
  const urlZapier = 'https://zapier.com/developer/public-invite/154735/4ea639334374f0929708e465f25d2608/'

  const jsx = (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ pb: 2 }}>
        <Typography>To connect, you will need to copy the token below and paste it into your Zapier account.</Typography>
      </Box>
      <Box sx={{ pb: 2 }}>
        <Typography>
          <a href={urlZapier} target='_blank' rel='noreferrer noopener'>
            Access SolutionView in Zapier
          </a>
        </Typography>
      </Box>

      <Box>
        <Typography style={{ fontWeight: 'bold' }}>Token</Typography>
      </Box>

      {zapToken !== null
        ? (
          <Box sx={{ pl: 2 }}>
            {zapToken}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(zapToken)
              }}
            >
              <ContentCopyIcon />
            </IconButton>
            <br />
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                navigator.clipboard.writeText(zapToken)
              }}
            >
              Copy to Clipboard
            </Button>
          </Box>
          )
        : null}
    </Box>
  )

  return (
    <DialogGeneric
      title={
        <Grid container>
          <Grid item>
            <Card sx={{ boxShadow: 'none' }}>
              <CardMedia component='img' image={zapierFavicon} alt='zapier logo' sx={{ width: '32px' }} />
            </Card>
          </Grid>
          <Grid item sx={{ pl: 2 }}>
            Zapier Integration
          </Grid>
        </Grid>
      }
      content={jsx}
      fullWidth
      maxWidth='md'
      onClose={onClose}
    />
  )
}

const DialogArcSite = (props) => {
  const [APIKey, setAPIKey] = useState('')

  useEffect(() => {
    setAPIKey(props.integration.APIKey)
    // eslint-disable-next-line
  }, [])

  const handleChange = (e) => {
    setAPIKey(e.target.value)
  }

  const handleSave = () => {
    props.integration.APIKey = APIKey
    props.onSave(props.integration)
  }

  const jsx = (
    <CardContent sx={{ mt: 2, p: 0 }}>
      <Typography variant='body1' color='text.secondary' sx={{ fontWeight: 'bold' }}>
        To connect, you will need to paste the API key from ArcSite into the field below.
        <br />
        <br />
        To complete the integration, you will need to assign the ArcSite user email within each user in User Settings.
      </Typography>
      <TextField name='APIKey' fullWidth sx={{ mt: 3 }} size='small' label='API Key' placeholder='API Key' onChange={handleChange} value={APIKey} />
    </CardContent>
  )

  return (
    <DialogGeneric
      title={
        <Grid container>
          <Grid item>
            <Card sx={{ boxShadow: 'none' }}>
              <CardMedia component='img' image={arcSiteFavicon} alt='zapier logo' sx={{ width: '32px' }} />
            </Card>
          </Grid>
          <Grid item sx={{ pl: 2 }}>
            ArcSite Integration
          </Grid>
        </Grid>
      }
      content={jsx}
      fullWidth
      maxWidth='md'
      onClose={props.onClose}
      titleDone='Save'
      titleClose='Cancel'
      onChange={handleSave}
    />
  )
}

const DialogMagicPlan = (props) => {
  const [customerID, setCustomerID] = useState('')
  const [APIKey, setAPIKey] = useState('')

  useEffect(() => {
    setCustomerID(props.integration.customerID)
    setAPIKey(props.integration.APIKey)
    // eslint-disable-next-line
  }, [])

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'customerID') {
      setCustomerID(value)
    } else if (name === 'APIKey') {
      setAPIKey(value)
    }
  }

  const handleSave = () => {
    props.integration.customerID = customerID
    props.integration.APIKey = APIKey
    props.onSave(props.integration)
  }

  const jsx = (
    <Box sx={{ pt: 2, pb: 2, mt: 1 }}>
      <Box>
        <Typography variant='body1' color='text.secondary' sx={{ fontWeight: '600' }}>
          To connect, you will need to paste the Customer ID and API Key from magicplan into the field below.
          <br />
          <br />
          To complete the integration, you will need to assign the magicplan user email within each user in User Settings.
        </Typography>
        <TextField
          name='customerID'
          fullWidth
          size='small'
          sx={{ mt: 4 }}
          label='Customer ID'
          placeholder='Customer ID'
          onChange={handleChange}
          value={customerID}
        />
        <TextField name='APIKey' fullWidth size='small' sx={{ mt: 2 }} label='API Key' placeholder='API Key' onChange={handleChange} value={APIKey} />
      </Box>
    </Box>
  )

  return (
    <DialogGeneric
      title={
        <Grid container>
          <Grid item>
            <Card sx={{ boxShadow: 'none' }}>
              <CardMedia component='img' image={magicplanfavicon} alt='magicplan logo' sx={{ width: '32px' }} />
            </Card>
          </Grid>
          <Grid item sx={{ pl: 2 }}>
            magicplan Integration
          </Grid>
        </Grid>
      }
      content={jsx}
      fullWidth
      maxWidth='md'
      onClose={props.onClose}
      titleClose='Cancel'
      titleDone='Save'
      onChange={handleSave}
    />
  )
}

const MuiSwitchLarge = styled(Switch)(({ theme }) => ({
  marginLeft: '-10px',
  marginTop: '10px',
  width: 68,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    // margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      transform: 'translateX(30px)'
    }
  },
  '& .MuiSwitch-thumb': {
    width: 32,
    height: 32
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2
  }
}))
