import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Company from 'screens/Company'
import Api, { ApiBase } from 'library/Api'
import { LoadingOverlay } from 'components/Reusable/LoadingOverlay'
import withScreenWrapper from '../withScreenWrapper'

function UserImpersonateStop (props) {
  useEffect(() => {
    LoadingOverlay.message('Cancelling impersonation...', 15)
    Api.callRegisteredMethod('stopImpersonation').then(() => {
      LoadingOverlay.hide()
      props.history.push(
        ApiBase.resolvePath('/Company/:organization/Dashboard', {
          organization: Company.getCurrentOrgId()
        })
      )
    })
  }, []) // eslint-disable-line

  return (
    <div className='UsersIndex'>
      <Company.Header title='Cancelling Impersonation' />
      <p>Please wait. You will be redirected to the dashboard.</p>
    </div>
  )
}

export default withRouter(withScreenWrapper(UserImpersonateStop))
