import React, { useEffect, useState } from 'react'
import { Checkbox, Chip, FormGroup, FormControlLabel, Grid, TextField, Typography, Switch } from '@mui/material'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { LeftPanelSelection } from './components/LeftPanelSelection'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { guid } from '@supportworks/helper'

const LeftPanel = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [meeting, setMeeting] = useState({})
  const [minVersion, setMinVersion] = useState('minVersion' in props.meeting && props.meeting.minVersion !== '' ? props.meeting.minVersion : '')
  const [minVersionToggle, setMinVersionToggle] = useState(false)

  useEffect(() => {
    if (isLoading) {
      // Autovivify stuff
      if (!props.meeting.findings || !props.meeting.findings.length) {
        props.meeting.findings = [
          {
            type: 'overview',
            visible: true,
            header: {},
            review: {},
            asset: '',
            poster: ''
          }
        ]
      }

      if (!props.meeting.presentation) {
        props.meeting.presentation = {
          title: 'Presentation',
          visible: true
        }
      }

      if ('minVersion' in props.meeting) {
        setMinVersion(props.meeting.minVersion)
        if (props.meeting.minVersion === '') {
          setMinVersionToggle(false)
        } else {
          setMinVersionToggle(true)
        }
      } else {
        props.meeting.minVersion = ''
      }

      if (!props.meeting.goals) {
        props.meeting.goals = {
          id: guid(),
          formId: '',
          title: '',
          type: 'project-goals',
          solutions: {
            default: [],
            title: 'Solutions'
          },
          issues: {
            default: [],
            title: 'Issues'
          },
          visible: false,
          mainNav: true,
          bottomNav: true
        }
      }

      if (!props.meeting.initialProjects) {
        props.meeting.initialProjects = []
        props.meeting.showInitialProjects = false
      }

      setMeeting(props.meeting)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (e) => {
    const m = JSON.parse(JSON.stringify(meeting))
    const name = e.target.name
    const value = e.target.value
    if (name === 'title') {
      m[name] = value
    } else if (name === 'checkedFindings') {
      m.findings[0].visible = !m.findings[0].visible
    } else if (name === 'checkedPresent') {
      m.presentation.visible = !m.presentation.visible
    } else if (name === 'checkedAccomplish') {
      // p/Josh blow away this section if it exists and we uncheck.  Create if we check.
      if (m.accomplishList) {
        delete m.accomplishList
      } else {
        m.accomplishList = []
      }
    } else if (name === 'minVersionToggle') {
      if (minVersionToggle === true) {
        setMinVersion('')
        m.minVersion = ''
      }
      setMinVersionToggle(!minVersionToggle)
    } else if (name === 'minVersion') {
      m[name] = value
      setMinVersion(value)
    } else if (name === 'checkedProjectGoals') {
      m.goals.visible = !m.goals.visible
    } else if (name === 'checkedDefaultProjects') {
      m.showInitialProjects = !m.showInitialProjects
    } else if (name === 'retired') {
      if (typeof m.retired === 'undefined') {
        m.retired = false
      }
      m.retired = !m.retired
    }
    setMeeting(m)
    props.setMeeting(m)
  }

  if (isLoading) return <LoadingPleaseWait />

  const includedAreas = []
  if (props.meeting.inspect) {
    for (let x = 0; x < props.meeting.inspect.length; x++) {
      const i = props.meeting.inspect[x]
      if (i.area) {
        const a = props.config.areas.find((o) => o.id === i.area)
        const title = a && a.title ? a.title : i.area
        if (includedAreas.indexOf(title) === -1) {
          includedAreas.push(title)
        }
      }
    }
  }

  return (
    <>
      <Grid container sx={{ pt: 2, pl: 2, pr: 2, borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <TextField
            fullWidth
            id='title'
            name='title'
            variant='outlined'
            label='Meeting Title'
            value={props.meeting.title}
            sx={{ 'input:focus': { border: 'none' } }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <Typography variant='caption'>
            <strong>Include in Appointment</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <FormGroup>
            <FormControlLabel
              sx={{ m: 0, p: 0 }}
              control={
                <Checkbox
                  sx={{ m: 0, p: 0 }}
                  id='checkedAccomplish'
                  name='checkedAccomplish'
                  checked={!!meeting.accomplishList}
                  onClick={handleChange}
                />
              }
              label={
                <Typography sx={{ pl: 1 }} style={styles.textField}>
                  Accomplish List
                </Typography>
              }
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <FormGroup>
            <FormControlLabel
              sx={{ m: 0, p: 0 }}
              control={
                <Checkbox
                  sx={{ m: 0, p: 0 }}
                  id='checkedProjectGoals'
                  name='checkedProjectGoals'
                  checked={!!meeting.goals.visible}
                  onClick={handleChange}
                />
                }
              label={
                <Typography sx={{ pl: 1 }} style={styles.textField}>
                  Project Goals
                </Typography>
                }
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <FormGroup>
            <FormControlLabel
              sx={{ m: 0, p: 0 }}
              control={
                <Checkbox
                  sx={{ m: 0, p: 0 }}
                  id='checkedDefaultProjects'
                  name='checkedDefaultProjects'
                  checked={!!meeting.showInitialProjects}
                  onClick={handleChange}
                />
                }
              label={
                <Typography sx={{ pl: 1 }} style={styles.textField}>
                  Default Projects
                </Typography>
                }
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <FormGroup>
            <FormControlLabel
              sx={{ m: 0, p: 0 }}
              control={
                <Checkbox
                  sx={{ m: 0, p: 0 }}
                  id='checkedFindings'
                  name='checkedFindings'
                  checked={!!(meeting.findings[0] && meeting.findings[0].visible)}
                  onClick={handleChange}
                />
              }
              label={
                <Typography sx={{ pl: 1 }} style={styles.textField}>
                  Findings Section
                </Typography>
              }
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <FormGroup>
            <FormControlLabel
              sx={{ m: 0, p: 0 }}
              control={
                <Checkbox
                  sx={{ m: 0, p: 0 }}
                  id='checkedPresent'
                  name='checkedPresent'
                  checked={!!(meeting.presentation && meeting.presentation.visible)}
                  onClick={handleChange}
                />
              }
              label={
                <Typography sx={{ pl: 1 }} style={styles.textField}>
                  Present Section
                </Typography>
              }
            />
          </FormGroup>
        </Grid>
        {includedAreas.length
          ? (
            <>
              <Grid item xs={12} sx={{ pb: 1 }}>
                <Typography variant='caption'>
                  <strong>Included Areas</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ pb: 2 }}>
                {includedAreas.map((a, idx) => {
                  return <Chip key={`ir-chip-${idx}`} sx={{ borderRadius: 1, border: '2px solid white' }} label={a} />
                })}
              </Grid>
            </>
            )
          : null}
      </Grid>

      <Grid container sx={{ borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Grid item xs={12} sx={{ p: 2 }}>
          <Typography variant='caption'>
            <strong>Features</strong>
          </Typography>
        </Grid>
        {meeting.accomplishList
          ? (
            <Grid item xs={12}>
              <LeftPanelSelection
                {...props}
                subVariant='accomplishlist'
                title='Accomplish List'
                editable={false}
                onClick={() => {
                  props.setRightPanelVariant('accomplishlist')
                }}
              />
            </Grid>
            )
          : null}

        {
          meeting.goals.visible &&
            <Grid item xs={12}>
              <LeftPanelSelection
                {...props}
                subVariant='projectGoals'
                title='Project Goals'
                editable={false}
                onClick={() => {
                  props.setRightPanelVariant('projectGoals')
                }}
              />
            </Grid>
        }

        {
          meeting.showInitialProjects &&
            <Grid item xs={12}>
              <LeftPanelSelection
                {...props}
                subVariant='initialProjects'
                title='Default Projects'
                editable={false}
                onClick={() => {
                  props.setRightPanelVariant('initialProjects')
                }}
              />
            </Grid>
        }
      </Grid>

      <Grid container sx={{ borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Grid item xs={12} sx={{ p: 2 }}>
          <Typography variant='caption'>
            <strong>App Section Settings</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <LeftPanelSelection
            {...props}
            subVariant='intro'
            title='Overview'
            onClick={() => {
              props.setRightPanelVariant('intro')
            }}
          />
          <LeftPanelSelection
            {...props}
            subVariant='inspect'
            title='Inspect'
            onClick={() => {
              props.setRightPanelVariant('inspect')
            }}
          />
          {meeting.findings[0] && meeting.findings[0].visible && (
            <LeftPanelSelection
              {...props}
              subVariant='findings'
              title='Findings'
              onChangeTitle={() => {
                window.alert('changing title')
              }}
            />
          )}
          {meeting.presentation && meeting.presentation.visible && (
            <LeftPanelSelection
              {...props}
              subVariant='present'
              title='Present'
              onClick={() => {
                props.setRightPanelVariant('present')
              }}
            />
          )}
          <LeftPanelSelection
            {...props}
            subVariant='project'
            title='Project'
            editable={false}
            onClick={() => {
              props.setRightPanelVariant('project')
            }}
          />
          <LeftPanelSelection
            {...props}
            subVariant='complete'
            title='Complete'
            onClick={() => {
              props.setRightPanelVariant('complete')
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '8px' }}>
          <FormControlLabel
            name='minVersionToggle'
            control={
              <Switch
                checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                id='minVersionToggle'
                checked={!!minVersionToggle}
                color='primary'
                onClick={handleChange}
                sx={{ mr: 2 }}
              />
              }
            label={<Typography style={styles.textField} sx={{ pb: 1 }}>Set Minimum Version Requirement</Typography>}
            labelPlacement='start'
          />

          {
            minVersionToggle &&
              <TextField
                style={{ marginLeft: '16px', marginBottom: '16px' }}
                id='minVersion'
                name='minVersion'
                variant='outlined'
                label='App Minimum Version'
                value={minVersion}
                size='small'
                sx={{ 'input:focus': { border: 'none' } }}
                onChange={handleChange}
              />
          }
        </Grid>
      </Grid>
      <Grid container sx={{ borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Grid item xs={12} sx={{ p: 2 }}>
          <Typography variant='caption'>
            <strong>Presentation Settings</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <LeftPanelSelection
            {...props}
            subVariant='allappointments'
            title='All Appointments'
            editable={false}
            onClick={() => {
              props.setRightPanelVariant('allappointments')
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ pt: 2, pl: 2, pr: 2, borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <FormGroup>
            <FormControlLabel
              sx={{ m: 0, p: 0 }}
              control={
                <Checkbox
                  sx={{ m: 0, p: 0 }}
                  id='retired'
                  name='retired'
                  checked={!!meeting.retired}
                  onClick={handleChange}
                />
              }
              label={
                <Typography sx={{ pl: 1 }} style={styles.textField}>
                  Retired?
                </Typography>
              }
            />
          </FormGroup>
        </Grid>
      </Grid>
    </>
  )
}
export default LeftPanel

const styles = {
  textField: {
    fontSize: 14
  }
}
