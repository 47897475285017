import React, { useEffect, useState } from 'react'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { Box, Card, FormControlLabel, Grid, IconButton, Switch, Typography } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

export const FormCardOverview = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [formsConfig, setFormsConfig] = useState(null)
  const [visible, setVisible] = useState(true)

  const handleChange = (e) => {
    if (e.target.name === 'visible') {
      props.form.visible = !visible
      setVisible(!visible)
    }
  }

  useEffect(() => {
    if (props.formsConfig) {
      setFormsConfig(props.formsConfig)
    }
    if (props.form) {
      if (!('visible' in props.form)) {
        props.form.visible = true
        setVisible(true)
      } else {
        setVisible(props.form.visible)
      }
    }

    setIsLoading(false)
  }, []) // eslint-disable-line

  if (isLoading) return <LoadingPleaseWait />

  if (!formsConfig) {
    return <>Missing forms config.</>
  }

  return (
    <Card sx={{ mb: 2 }}>
      <Grid container style={styles.title} sx={{ pl: 2, pt: 1, pb: 1, width: '100%' }}>
        <Grid item sx={{ display: 'flex', alignItems: 'center', pr: 1 }}>
          <Typography variant='body2'>{formsConfig ? formsConfig.libraryName : 'Miscelleneous'}</Typography>
        </Grid>
        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
          {
            'formStatus' in props && props.formStatus === 'undefined'
              ? (
                <Typography variant='subtitle2' color='error'>
                  {'formStatus' in props && props.formStatus === 'undefined' ? 'Form does not exist' : null}
                </Typography>
                )
              : (
                <Typography variant='body2' sx={{ color: 'rgba(0,0,0,.66)' }}>
                  {formsConfig.formTitle ? formsConfig.formTitle : ''}
                  {formsConfig && formsConfig.lastupdated ? <>(Updated {formsConfig.lastupdated})</> : null}
                </Typography>
                )
          }
          {props.subTitle ? <Typography variant='body2' sx={{ pl: 3 }} style={{ color: 'rgba(0,0,0,.40)' }}>{props.subTitle}</Typography> : null}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Box sx={{ display: 'flex' }}>
            {
              'formStatus' in props && props.formStatus === 'undefined'
                ? (
                  <Box sx={{ pt: 2, pl: 2, flex: 2 }}>
                    <Typography variant='body2'>This form titled "{props.form.title}" no longer exists in your organizations forms.
                      To resolve this issue, add the library back into forms or delete this form<br /> from your meeting setup to resolve
                      issues within the app.
                    </Typography>
                  </Box>
                  )
                : props.jsx
            }
            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', pl: 2 }}>
              <Grid>
                {!('formStatus' in props && props.formStatus === 'undefined')
                  ? (
                    <Grid item>
                      <FormControlLabel
                        name='visible'
                        control={
                          <Switch
                            checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                            id='visible'
                            checked={!!visible}
                            color='primary'
                            onClick={handleChange}
                          />
                        }
                        label={<Typography style={styles.textField}>Visible</Typography>}
                        labelPlacement='start'
                      />
                    </Grid>
                    )
                  : null}
              </Grid>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', pl: 2 }}>
              <Grid>
                {!props.singleUseOnly
                  ? (
                    <Grid item>
                      <IconButton size='small' disabled={!props.onMoveUp} onClick={props.onMoveUp ? props.onMoveUp : null}>
                        <KeyboardArrowUpIcon fontSize='small' />
                      </IconButton>
                    </Grid>
                    )
                  : null}
                <Grid item>
                  <IconButton size='small' disabled={!props.onDelete} onClick={props.onDelete ? props.onDelete : null}>
                    <DeleteIcon fontSize='small' />
                  </IconButton>
                </Grid>
                {!props.singleUseOnly
                  ? (
                    <Grid item>
                      <IconButton size='small' disabled={!props.onMoveDown} onClick={props.onMoveDown ? props.onMoveDown : null}>
                        <KeyboardArrowDownIcon fontSize='small' />
                      </IconButton>
                    </Grid>
                    )
                  : null}
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Card>
  )
}

const styles = {
  title: {
    backgroundColor: 'rgba(0,0,0,.16)'
  },
  textField: {
    fontSize: 14
  },
  icon: {
    color: 'rgba(0,0,0,.66)'
  }
}
