import React, { useState, useEffect } from 'react'
import { Box, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, TextField, Typography, Select, Stack, Switch } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { LoadingPleaseWait } from '@supportworks/react-components'

const RightPanelPresent = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [title, setTitle] = useState('')
  const [subtitle, setSubtitle] = useState('')
  const [asset, setAsset] = useState('')
  const [viewAboutUsSwitch, setViewAboutUsSwitch] = useState(false)
  const [viewAboutUs, setViewAboutUs] = useState('')
  const [viewAboutUsId, setViewAboutUsId] = useState('')
  const [presentationExisting, setPresentationExisting] = useState('')
  const [presentationAdd, setPresentationAdd] = useState('')

  useEffect(() => {
    if (isLoading) {
      const m = JSON.parse(JSON.stringify(props.meeting))
      const f = m.findings[0]
      if (f.asset) setAsset(f.asset)
      if (f.header && f.header.title) setTitle(f.header.title)
      if (f.header && f.header.subtitle) setSubtitle(f.header.subtitle)

      const pres = props.meeting.presentation
      if (pres.viewAboutUs && pres.viewAboutUsId) {
        setViewAboutUsSwitch(true)
        setViewAboutUs(pres.viewAboutUs)
        setViewAboutUsId(pres.viewAboutUsId)
      }
      if (pres.existing) setPresentationExisting(pres.existing)
      if (pres.add) setPresentationAdd(pres.add)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    const m = JSON.parse(JSON.stringify(props.meeting))
    const findings = m.findings[0]
    // Autofix stuff
    if (findings.header === undefined || Array.isArray(findings.header)) findings.header = {}
    if (name === 'title') {
      findings.header.title = value
      setTitle(value)
    } else if (name === 'subtitle') {
      findings.header.subtitle = value
      setSubtitle(value)
    } else if (name === 'asset') {
      findings.asset = value
      setAsset(value)
    } else if (name === 'viewAboutUsSwitch') {
      const v = !viewAboutUsSwitch
      setViewAboutUsSwitch(v)
      if (!v) {
        m.presentation.viewAboutUs = ''
        m.presentation.viewAboutUsId = ''
        setViewAboutUs('')
        setViewAboutUsId('')
      }
    } else if (name === 'viewAboutUs') {
      m.presentation.viewAboutUs = value
      setViewAboutUs(value)
    } else if (name === 'viewAboutUsId') {
      m.presentation.viewAboutUsId = value
      setViewAboutUsId(value)
    } else if (name === 'presentation-existing') {
      m.presentation.existing = value
      setPresentationExisting(value)
    } else if (name === 'presentation-add') {
      m.presentation.add = value
      setPresentationAdd(value)
    }
    m.findings = [findings]
    props.setMeeting(m)
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Box style={styles.container} sx={{ p: 2 }}>
        <Stack sx={{ pb: 3 }}>
          <Typography variant='h6'>Introduction Setup</Typography>
          <Typography variant='body2'>Specify the details of the introduction that starts the presentation.</Typography>
        </Stack>
        <Box sx={{ pb: 2 }}>
          <FormControl sx={{ width: '50%' }} size='small'>
            <InputLabel id='area'>Video</InputLabel>
            <Select sx={{ p: 1 }} labelId='asset' id='asset' name='asset' value={asset} label='Select Video' onChange={handleChange}>
              {props.config && props.config.assets2 && props.config.assets2
                .filter((o) => o.type === 'Video')
                .map((a, idx) => {
                  return (
                    <MenuItem key={`asset-${idx}`} value={a.id}>
                      {a.title}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ pb: 2 }}>
          <Grid item xs={12} sm={12} md={9}>
            <TextField
              fullWidth
              id='title'
              name='title'
              variant='outlined'
              label={title ? 'Title' : null}
              placeholder='Please enter the title.'
              value={title}
              onChange={handleChange}
            />
          </Grid>
        </Box>
        <Box sx={{ pb: 3 }}>
          <Grid item xs={12} sm={12} md={9}>
            <TextField
              fullWidth
              id='subtitle'
              name='subtitle'
              variant='outlined'
              label={subtitle ? 'Sub-Title' : null}
              placeholder='Please enter the sub-title.'
              value={subtitle}
              onChange={handleChange}
            />
          </Grid>
        </Box>
        <Box sx={{ pb: 2 }}>
          <Typography variant='h6'>About Us</Typography>
          <FormControlLabel
            sx={{ m: 0 }}
            name='viewAboutUsSwitch'
            control={
              <Switch
                checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                id='viewAboutUsSwitch'
                checked={!!viewAboutUsSwitch}
                color='primary'
                onClick={handleChange}
              />
            }
            label={<Typography style={styles.textField}>Display About Us</Typography>}
            labelPlacement='start'
            onChange={handleChange}
          />
        </Box>
        {viewAboutUsSwitch && (
          <>
            <Box sx={{ pb: 2 }}>
              <Grid item xs={12} sm={12} md={9}>
                <TextField
                  fullWidth
                  id='viewAboutUs'
                  name='viewAboutUs'
                  variant='outlined'
                  label={viewAboutUs ? 'About Us Display Title' : null}
                  placeholder='Please enter the display title.'
                  value={viewAboutUs}
                  onChange={handleChange}
                />
              </Grid>
            </Box>
            <Box sx={{ pb: 2 }}>
              <FormControl sx={{ width: '50%' }} size='small'>
                <InputLabel id='viewAboutUsId'>Select About Us</InputLabel>
                <Select
                  sx={{ p: 1 }}
                  labelId='viewAboutUsId'
                  id='viewAboutUsId'
                  name='viewAboutUsId'
                  value={viewAboutUsId}
                  label='Select About Us'
                  onChange={handleChange}
                >
                  {props.config.aboutus.map((a, idx) => {
                    return (
                      <MenuItem key={`viewAboutUs-${idx}`} value={a.id}>
                        {a.title ? a.title : a.id}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          </>
        )}

        <Box sx={{ pt: 1, pb: 2 }}>
          <Stack sx={{ pb: 1 }}>
            <Typography variant='h6'>Add Presentation Display Settings</Typography>
            <Typography variant='body2'>Define the text that displays in the app to prompt for manually added presentation.</Typography>
          </Stack>
        </Box>
        <Box sx={{ pb: 2 }}>
          <Grid item xs={12} sm={12} md={9}>
            <TextField
              fullWidth
              id='presentation-existing'
              name='presentation-existing'
              variant='outlined'
              label={presentationExisting ? 'Presentations Prompt' : null}
              value={presentationExisting || 'Add the project options you\'d like to learn more about.'}
              onChange={handleChange}
            />
          </Grid>
        </Box>
        <Box sx={{ pb: 2 }}>
          <Grid item xs={12} sm={12} md={9}>
            <TextField
              fullWidth
              id='presentation-add'
              name='presentation-add'
              variant='outlined'
              label={presentationAdd ? 'Additional Presentations Prompt' : null}
              placeholder='Add additional presentations.'
              value={presentationAdd || 'Add additional presentations.'}
              onChange={handleChange}
            />
          </Grid>
        </Box>
      </Box>
    </>
  )
}
export default RightPanelPresent

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'white'
  },
  textField: {
    fontSize: 14
  }
}
