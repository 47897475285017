import React from 'react'
import Api from '../../library/Api' // defined in CompanyConfigEdit
import CompanyConfigEdit, { CompanyConfigBaseEditor } from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import CompanyDocumentEditor from './DocumentEditor/CompanyDocumentEditor'
import DocumentsDataTable from './DocumentEditor/components/DocumentsDataTable'
import { LoadingPleaseWait } from '@supportworks/react-components'
import StandardDocumentEditor from './DocumentEditor/StandardDocumentEditor'
import '../../css/selector-material-ui.css'
import { Box, FormControl, FormControlLabel, Typography } from '@mui/material'
import Switch from '@mui/material/Switch'

const documentEditorConfig = {
  title: 'Document Packets',
  exactTitle: true,
  variant: 'documentEditor',
  tag: 'pdfPlacement',
  name: 'Document Packets',
  lockTag: true,
  lockName: false,
  showProductSettings: false
}

class DocumentEditor extends CompanyConfigBaseEditor {
  constructor (props) {
    super(props)

    let content
    if (typeof props.contentJson === 'string') {
      try {
        content = JSON.parse(props.contentJson)
      } catch (err) {
        console.log(err.message)
      }
    } else if (typeof props.contentJson === 'object') {
      content = props.contentJson
    }

    if (content !== undefined && content.constructor === Array) {
      const contentArray = content
      content = { pdf_placement: [], pdf_standard: [] }

      for (const i of contentArray) {
        if ('id' in i) {
          content.pdf_placement.push(i)
        } else {
          content.pdf_standard.push(i)
        }
      }

      if (content.pdf_standard.length === 0) {
        delete content.pdf_standard
      }
    }

    this.state = {
      isLoading: true,
      isEditing: false,
      currentIndex: null,
      currentType: '',
      content: {
        pdf_placement: content && content.pdf_placement ? content.pdf_placement : [],
        pdf_standard: content && content.pdf_standard ? content.pdf_standard : [this.initializePdfStandard()],
        pdf_options: content && content.pdf_options ? content.pdf_options : {}
      },
      assets: [],
      forms: [],
      displaySettings: {},
      productFeatures: {}
    }
  }

  async componentDidMount () {
    // Push all my guid's onto the this.guids (see CompanyConfigEdit) array to prevent dupes when we add.
    this.state.content.pdf_placement.forEach((m) => {
      this.addExistingGuid(m.id)
    })
    this.state.content.pdf_standard.forEach((m) => {
      if (m.id) {
        this.addExistingGuid(m.id)
      }
    })
    await Api.callRegisteredMethod('getAssetList', {}).then((assetList) => {
      const assets = assetList.sort((a, b) => (a.name > b.name ? 1 : -1))
      this.setState({ assets })
    })

    const configDS = await Api.callRegisteredMethod('getLatestConfigByTag', { tag: 'displaySettings', startsWith: false })
    if (configDS && configDS.contentJson) {
      const displaySettings = JSON.parse(configDS.contentJson)
      this.setState({ displaySettings })
    }

    const newContent = this.state.content
    if (!('generateAllProjects' in newContent.pdf_options)) {
      newContent.pdf_options = { generateAllProjects: true }
      this.setState({ content: newContent })
      this.props.onChange(JSON.stringify(newContent, null, 2))
    } else {
      newContent.pdf_options = this.state.content.pdf_options
      this.setState({ content: newContent })
    }

    const config = await Api.callRegisteredMethod('getCurrentConfig', { tag: 'all', startsWith: false })
    this.setState({
      isLoading: false,
      forms: config && 'forms' in config ? config.forms : [],
      productFeatures: config && 'productFeatures' in config ? config.productFeatures : {}
    })
  }

  handleAdd = (type) => {
    const content = this.state.content
    let documents
    let document
    let title
    if (type === 'standard') {
      documents = content.pdf_standard
      document = this.initializePdfStandard()
      title = 'New Standard Document'
    } else {
      documents = content.pdf_placement
      document = this.initializePdfCompany()
      title = 'New Company Document'
    }
    document.title = title
    documents.push(document)
    this.setState({ content })
    this.props.onChange(JSON.stringify(content, null, 2))
  }

  handleDelete = (type, idx) => {
    const content = this.state.content
    let documents
    if (type === 'standard') {
      documents = content.pdf_standard
    } else {
      documents = content.pdf_placement
    }
    documents.splice(idx, 1)
    this.setState({ content })
    this.props.onChange(JSON.stringify(content, null, 2))
  }

  handleChange = () => {
    this.props.onChange(JSON.stringify(this.state.content, null, 2))
    // console.log(this.state.content);
    // this.handleClose();
  }

  handleEdit = (type, idx) => {
    this.setState({ currentIndex: idx, currentType: type, isEditing: true })
  }

  handleClose = () => {
    this.setState({ currentIndex: null, currentType: '', isEditing: false })
  }

  handleMove = (type, documents) => {
    const content = this.state.content
    if (type === 'standard') {
      content.pdf_standard = documents
    } else {
      content.pdf_placement = documents
    }
    this.props.onChange(JSON.stringify(this.state.content, null, 2))
  }

  handleEditRow = (type, documents) => {
    const content = this.state.content
    if (type === 'standard') {
      content.pdf_standard = documents
    } else {
      content.pdf_placement = documents
    }
    this.props.onChange(JSON.stringify(this.state.content, null, 2))
  }

  onChangeSwitch = () => {
    const content = this.state.content
    content.pdf_options.generateAllProjects = !content.pdf_options.generateAllProjects
    this.props.onChange(JSON.stringify(this.state.content, null, 2))
  }

  hasFinalize = () => true

  finalize () {
    return JSON.stringify(this.state.content)
  }

  initializePdfStandard () {
    return {
      id: this.guid(),
      title: 'New Standard Document',
      include: false,
      merge: true,
      sendCustomer: false,
      sendDistributionList: false,
      heroImageDefault: {
        asset: ''
      },
      showProductSpecifications: false,
      showRecommendations: false,
      showSolutionRecommendations: false,
      solutionRecommendationsPage: {
        title: '',
        disclaimer: ''
      },
      showAllOptions: false,
      productDisplay: {
        productDescriptions: false,
        itemizedPricing: false,
        productImages: false
      },
      esignTags: {
        company_sign: '',
        company_date: '',
        company_initial: '',
        customer_sign: '',
        customer_date: '',
        customer_initial: ''
      },
      sections: []
    }
  }

  initializePdfCompany () {
    return {
      id: this.guid(),
      asset: '',
      title: 'New Company Document',
      include: false,
      required: false,
      sendCustomer: false,
      sendDistributionList: false,
      merge: true,
      fields: []
    }
  }

  render () {
    if (this.state.isLoading) {
      return <LoadingPleaseWait />
    }

    if (!this.state.forms) {
      return <>Form data missing.</>
    }

    const sharedProps = {
      onAdd: this.handleAdd,
      onDelete: this.handleDelete,
      onEdit: this.handleEdit,
      onMove: this.handleMove,
      onEditRow: this.handleEditRow
    }

    return (
      <>
        {!this.state.isEditing
          ? (
            <>
              <Box>
                <FormControl sx={{ mt: 1, mb: 1 }}>
                  <FormControlLabel
                    sx={{ ml: 0 }}
                    control={
                      <Switch
                        color='primary'
                        sx={{ m: 0 }}
                        onChange={() => this.onChangeSwitch()}
                        checked={this.state.content.pdf_options.generateAllProjects}
                      />
                    }
                    label={<Typography>Generate Contract PDF for Each Project</Typography>}
                    labelPlacement='start'
                  />
                </FormControl>
              </Box>
              <DocumentsDataTable title='Standard Document' type='standard' documents={this.state.content.pdf_standard} {...sharedProps} showRequired={false} />
              <DocumentsDataTable title='Company Document' type='company' documents={this.state.content.pdf_placement} {...sharedProps} />
            </>
            )
          : null}

        {this.state.isEditing && this.state.currentType === 'standard'
          ? (
            <StandardDocumentEditor
              document={this.state.content.pdf_standard[this.state.currentIndex]}
              assets={this.state.assets}
              productFeatures={this.state.productFeatures}
              displaySettings={this.state.displaySettings}
              onChange={this.handleChange}
              onClose={this.handleClose}
              onCancel={this.handleClose}
            />
            )
          : null}

        {this.state.isEditing && this.state.currentType === 'company'
          ? (
            <CompanyDocumentEditor
              document={this.state.content.pdf_placement[this.state.currentIndex]}
              forms={this.state.forms}
              assets={this.state.assets}
              onChange={this.handleChange}
              onClose={this.handleClose}
              onCancel={this.handleClose}
            />
            )
          : null}
      </>
    )
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Edit: (props) => <CompanyConfigEdit {...documentEditorConfig} {...props} editorComponent={DocumentEditor} />,
  Index: (props) => <CompanyConfigIndex {...documentEditorConfig} {...props} />
}
