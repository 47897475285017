import React, { useState, useEffect } from 'react'
import FieldTypeExpandMarker from 'screens/Config/Forms/Reusables/FieldTypeExpandMarker'
import { Divider, FormControl, InputAdornment, MenuItem, Paper, Select, TextField } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
import TriggerMenu from 'screens/Config/Forms/Reusables/TriggerMenu'
import AddLogicTriggerPill from './Reusables/AddLogicTriggerPill'
import LogicOptionsTab from './Reusables/LogicOptionsTab'
import '../../../css/forms.css'

function AddLogic (props) {
  const [field, setField] = useState(props.field)
  const [optionsArr, setOptionsArr] = useState([])
  const [numericCond, setNumericCond] = useState('')
  const [currOption, setOption] = useState('')
  const [solutions, setSolutions] = useState([])
  const [issues, setIssues] = useState([])
  const [currSolution, setCurrSolution] = useState({})
  const [currSolutionIndex, setCurrSolutionIndex] = useState(null)

  useEffect(() => {
    if ('form' in props && 'solutions' in props.form) {
      setSolutions(props.form.solutions)
    }
    // eslint-disable-next-line
  }, [solutions])

  useEffect(() => {
    if (!('related' in props.field)) {
      setOption('')
    }
    setField(props.field)
  }, [props.field])

  useEffect(() => {
    let newOptionsArr = []
    // console.log(props);
    switch (props.field.type) {
      case 'boolean':
        newOptionsArr = [
          { value: 'Yes', name: 'Yes' },
          { value: 'No', name: 'No' }
        ]
        break
      case 'radio':
        props.field?.list?.forEach((item, idx) => {
          newOptionsArr.push({ value: item, name: item })
        })
        break
      case 'checkbox':
        props.field?.list?.forEach((item, idx) => {
          newOptionsArr.push({ value: item, name: item })
        })
        break
      case 'choice':
        props.field?.choices?.forEach((item, idx) => {
          newOptionsArr.push({ value: item.id, name: item.title })
        })
        break
      case 'numeric':
        newOptionsArr = [
          { value: 'lt', name: 'less than' },
          { value: 'lte', name: 'less than or equal to' },
          { value: 'eq', name: 'equal to' },
          { value: 'gt', name: 'greater than' },
          { value: 'gte', name: 'greater than or equal to' }
        ]
        break
      case 'calc':
        newOptionsArr = [
          { value: 'lt', name: 'less than' },
          { value: 'lte', name: 'less than or equal to' },
          { value: 'eq', name: 'equal to' },
          { value: 'gt', name: 'greater than' },
          { value: 'gte', name: 'greater than or equal to' }
        ]
        break
      case 'media':
        break
      default:
        break
    }

    setOptionsArr(newOptionsArr)
  }, [props.field])

  useEffect(() => {
    if ('list' in field && (field.type === 'radio' || field.type === 'checkbox')) {
      const oldVal = field.list.filter((x) => !props.field.list.includes(x))
      const newVal = props.field.list.filter((x) => !field.list.includes(x))

      if (oldVal[0] !== newVal[0]) {
        setField(props.field)
        props.onRelatedOptionValChange(oldVal[0], newVal[0])
      }
    }
    // eslint-disable-next-line
  }, [props.field])

  const changeHandlerOption = (event) => {
    setOption(event.target.value)
    props.onSelectChange(event.target.value)
  }

  const changeHandlerNumericCond = (event) => {
    setNumericCond(event.target.value)
    props.onNumericChange(event.target.value)
  }

  const askQuestion = () => {
    props.askQuestionFieldType()
  }

  const saveIssues = (issues) => {
    const newField = JSON.parse(JSON.stringify(field))
    newField.issues = issues
    setField(newField)
    setIssues([])
    props.onSaveIssues(newField)
  }

  const saveSolution = (solution) => {
    const solutionsNew = [...solutions]

    if (currSolutionIndex !== null) {
      solutionsNew[currSolutionIndex] = solution
      setCurrSolution({})
      setCurrSolutionIndex(null)
    } else {
      solutionsNew.push(solution)
    }

    setSolutions(solutionsNew)
    props.onSaveSolutions(solutionsNew)
  }

  const deleteSolution = (idx) => {
    const solutionsNew = [...solutions]
    solutionsNew.splice(idx, 1)
    setSolutions(solutionsNew)
    props.onSaveSolutions(solutionsNew)
  }

  const selectSolution = (solution, idx) => {
    setCurrSolution(solution)
    setCurrSolutionIndex(idx)
  }

  const resetCurrSolution = () => {
    setCurrSolution({})
    setCurrSolutionIndex(null)
  }

  const deleteIssue = (idx) => {
    const newField = JSON.parse(JSON.stringify(field))

    newField.issues.splice(idx, 1)
    setField(newField)

    if (newField.issues.length === 0) {
      delete newField.issues
    }
    props.onSaveIssues(newField)
  }

  const selectIssue = () => {
    if ('issues' in props.field) {
      setIssues(props.field.issues)
    }
  }

  const resetIssues = () => {
    setIssues([])
  }

  const clickHandlerTab = (item) => {
    props.onClickTab(item)
  }

  const deleteQuestion = (id) => {
    const newField = JSON.parse(JSON.stringify(field))
    const fieldIndex = newField.related.findIndex((item) => item.id === id)
    newField.related.splice(fieldIndex, 1)
    setField(newField)
    props.onDeleteQuestion(newField)
  }

  return (
    <>
      <div style={styles.mainDiv}>
        <FieldTypeExpandMarker />
        <Paper elevation={0} square style={styles.paper}>
          <div style={styles.paperItems}>
            {'related' in props.field && props.field.related.length > 0 && (
              <>
                <div style={styles.tabPanel}>
                  <LogicOptionsTab field={props.field} onClickTab={(item) => clickHandlerTab(item)} guid={props.guid} tabItem={props.tabItem} />
                </div>

                <Divider variant='middle' style={styles.divider} />
              </>
            )}

            <div style={styles.triggerPanel}>
              <span>if answer is</span>
              <div style={styles.triggerPanelElSelect}>
                <FormControl variant='standard' sx={{ m: 1, minWidth: 80 }} size='small'>
                  <Select style={styles.select} id='options-in-addlogic' value={currOption} onChange={changeHandlerOption} autoWidth displayEmpty>
                    {optionsArr.map((option, idx) => {
                      return (
                        <MenuItem value={option.value} key={option.value}>
                          {option.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>

              {field.type === 'numeric' && (
                <div>
                  <TextField
                    style={styles.numInput}
                    className='numeric-cond-field'
                    id='numeric-cond-field'
                    placeholder=''
                    variant='standard'
                    size='small'
                    value={numericCond}
                    onChange={changeHandlerNumericCond}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <CreateIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              )}

              {field.type === 'calc' && (
                <div>
                  <TextField
                    style={styles.numInput}
                    className='numeric-cond-field'
                    id='numeric-cond-field'
                    placeholder=''
                    variant='standard'
                    size='small'
                    value={numericCond}
                    onChange={changeHandlerNumericCond}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <CreateIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              )}

              <span>then</span>
              <AddLogicTriggerPill
                {...props}
                field={field}
                onDeleteQuestion={(id) => deleteQuestion(id)}
                solutions={solutions}
                onDeleteSolution={(idx) => deleteSolution(idx)}
                onSelectSolution={(solution, idx) => selectSolution(solution, idx)}
                onDeleteIssue={(idx) => deleteIssue(idx)}
                onSelectIssue={(issue, idx) => selectIssue(issue, idx)}
              />
              <TriggerMenu
                {...props}
                field={field}
                currOption={currOption}
                askQuestionAddLogic={() => askQuestion()}
                onSaveIssues={(issues) => saveIssues(issues)}
                onSaveSolution={(solution) => saveSolution(solution)}
                form={props.form}
                currSolution={currSolution}
                currIssues={issues}
                onExitSolutionAutomation={resetCurrSolution}
                onExitIssuesAutomation={resetIssues}
                allFieldsSolutions={props.allFieldsSolutions}
                guid={props.guid}
              />
            </div>
          </div>
        </Paper>
      </div>
    </>
  )
}

const styles = {
  mainDiv: {
    display: 'flex'
  },
  paper: {
    // height: "89px",
    borderLeft: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    // marginRight: "96.19px",
    // width: "100%"
    flexBasis: '100%',
    overflowX: 'auto'
  },
  paperItems: {
    width: '100%',
    color: '#000000a3'
  },
  divider: {
    borderLeft: '1px solid #0000003b',
    marginLeft: '25px',
    marginRight: '25px'
  },
  tabPanel: {
    minHeight: '40px',
    // marginLeft: '25px',
    marginRight: '25px'
  },
  triggerPanel: {
    // height: "48px",
    lineHeight: '48px',
    marginLeft: '25px',
    marginRight: '25px',
    display: 'flex',
    flexWrap: 'wrap'
  },
  /* triggerPanelEl: {
        display: "flex",
        verticalAlign: "middle",
        lineHeight: "normal",
    }, */
  triggerPanelElSelect: {
    display: 'flex',
    verticalAlign: 'middle'
    // lineHeight: "normal",
    // marginLeft: "10px",
    // marginRight: "10px",
    // marginTop: "5px"
  },
  /* triggerPanelElPill: {
        display: "flex",
        verticalAlign: "middle",
        lineHeight: "normal",
        marginLeft: "10px",
        marginRight: "10px"
    }, */
  /* stackPill: {
        display: "flex",
        flexWrap: "wrap",
        //marginTop: "8px"
    }, */
  select: {
    fontSize: '13px',
    color: '#3f51b5',
    paddingTop: 0,
    paddingBottom: 0
  },
  numInput: {
    width: '80px',
    marginRight: '8px',
    verticalAlign: 'middle'
  }
}

export default AddLogic
