import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { LoadingPleaseWait } from '@supportworks/react-components'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import AddCircleIcon from '@mui/icons-material/AddCircleOutline'
import { DialogGeneric } from '../../../components/Reusable/DialogGeneric'
import { SelectionHeader } from './components/SelectionHeader'
import Helper from '@supportworks/helper'

const RightPanelProperties = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [product, setProduct] = useState({}) // For object manipulation
  const [properties, setProperties] = useState([])
  const [productProperties, setProductProperties] = useState([])
  const [selectedIdx, setSelectedIdx] = useState(-1)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [variant, setVariant] = useState('solo-editor')

  useEffect(() => {
    if (isLoading) {
      if (!props.productFeatures.product_properties) {
        props.productFeatures.product_properties = []
      }
      setProductProperties(props.productFeatures.product_properties)
      if (props.product) {
        const p = props.product
        setProperties(p.properties ? p.properties : [])
        setProduct(p)
        setVariant('product-editor')
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (variant === 'product-editor') {
      setProperties(props.product.properties ? props.product.properties : [])
      setProduct(props.product)
    }
  }, [props.product]) // eslint-disable-line

  const handleChange = (e) => {
    const p = JSON.parse(JSON.stringify(properties))
    const idx = p.findIndex((o) => o.id === e.target.id)
    if (idx > -1) {
      p.splice(idx, 1)
    } else {
      const pp = productProperties.find((o) => o.id === e.target.id)
      if (pp) {
        p.push({ id: e.target.id, title: pp.title, type: pp.type, value: pp.type === 'list' ? [] : '' })
      }
    }
    setProperties(p)
    const pCopy = JSON.parse(JSON.stringify(product))
    pCopy.properties = p
    setProduct(pCopy)
    props.setProduct(pCopy) // Force a refresh.
  }

  const handleDelete = (idx) => {
    const pp = JSON.parse(JSON.stringify(productProperties))
    if (window.confirm(`Are you sure you want to delete '${pp[idx].title}'?`)) {
      pp.splice(idx, 1)
      props.productFeatures.product_properties = pp
      setProductProperties(pp)
    }
    handleCloseDialog()
  }

  const handleSort = () => {
    const pp = JSON.parse(JSON.stringify(productProperties))
    pp.sort((a, b) => a.title.localeCompare(b.title))
    props.productFeatures.product_properties = pp
    setProductProperties(pp)
  }

  const handleCloseDialog = () => {
    setSelectedIdx(-1)
    setShowEditDialog(false)
    setShowAddDialog(false)
  }

  if (isLoading) return <LoadingPleaseWait />

  let disabled = false
  if (props.variant === 'pricing' && !product.properties_ovrd) {
    disabled = true
  }

  return (
    <>
      <Grid container wrap='nowrap'>
        {productProperties.length
          ? (
            <Box component='div' style={styles.gridContainer}>
              <SelectionHeader
                title='Properties'
                onAdd={
                !disabled
                  ? () => {
                      setShowAddDialog(!showAddDialog)
                    }
                  : null
              }
                onSort={!disabled ? handleSort : null}
              />

              <Grid item xs={12}>
                {productProperties.map((property, idx) => {
                  let checked = false
                  const i = properties.findIndex((o) => o.id === property.id)
                  if (i > -1) checked = true
                  return (
                    <Grid
                      key={`rpp-${idx}`}
                      container
                      wrap='nowrap'
                      sx={{ pl: 2, pt: 1, pr: 1, pb: variant === 'solo-editor' ? 1 : 0 }}
                      style={Object.assign({}, styles.selectionContainer, selectedIdx === idx ? styles.selected : null)}
                    >
                      <Grid item xs={12}>
                        <Grid container justifyContent='flex-start' wrap='nowrap'>
                          {variant === 'product-editor'
                            ? (
                              <Grid item sx={{ width: 24 }}>
                                <FormGroup>
                                  <FormControlLabel
                                    disabled={disabled}
                                    control={<Checkbox id={property.id} name={property.id} checked={checked} onClick={handleChange} />}
                                    label=''
                                  />
                                </FormGroup>
                              </Grid>
                              )
                            : null}
                          <Grid item sx={{ pl: 1, pt: 1.25 }} style={styles.hideOverflow}>
                            <Typography style={{ ...styles.hideOverflow, ...styles.textField }}>{property.title}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        {!disabled
                          ? (
                            <IconButton
                              onClick={() => {
                                setSelectedIdx(idx)
                                setShowEditDialog(!showEditDialog)
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                            )
                          : null}
                      </Grid>
                      {variant === 'product-editor'
                        ? (
                          <Grid item>
                            <IconButton
                              onClick={() => {
                                if (selectedIdx === idx) {
                                  setSelectedIdx(-1)
                                } else {
                                  setSelectedIdx(idx)
                                }
                              }}
                            >
                              {selectedIdx === idx ? <PlayCircleFilledIcon /> : <ArrowRightIcon />}
                            </IconButton>
                          </Grid>
                          )
                        : null}
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
            )
          : null}

        {showAddDialog || showEditDialog
          ? (
            <DialogEditProperty
              {...props}
              variant={showAddDialog ? 'new' : 'edit'}
              selectedIdx={selectedIdx}
              productProperties={productProperties}
              onDelete={() => {
                handleDelete(selectedIdx)
              }}
              onSave={handleCloseDialog}
              onClose={handleCloseDialog}
            />
            )
          : null}

        {variant === 'product-editor' && selectedIdx > -1
          ? (
            <Box key={`box-${selectedIdx}`} component='div' style={styles.gridContainer}>
              <PropertyValues
                {...props}
                disabled={disabled}
                selectedIdx={selectedIdx}
                properties={properties}
                productProperty={productProperties[selectedIdx]}
                onCheck={variant === 'product-editor' ? handleChange : undefined}
              />
            </Box>
            )
          : null}
      </Grid>

      {!productProperties.length
        ? (
          <Grid container spacing={0} direction='row' alignItems='center' justifyContent='center' style={{ minHeight: '50vh' }}>
            <Stack spacing={2}>
              <Typography style={styles.stackItem}>There are no product properties.</Typography>
              <span style={styles.stackItem}>
                <Button variant='contained' onClick={() => setShowAddDialog(!showAddDialog)}>
                  Add One
                </Button>
              </span>
            </Stack>
          </Grid>
          )
        : null}
    </>
  )
}
export default RightPanelProperties

const PropertyValues = (props) => {
  const [productProperty, setProductProperty] = useState({})

  useEffect(() => {
    setProductProperty(props.productProperty)
  }, []) // eslint-disable-line

  const handleChange = (e) => {
    const p = JSON.parse(JSON.stringify(props.product))
    let idx = props.properties.findIndex((o) => o.id === props.productProperty.id)
    if (idx === -1) {
      props.properties.push({
        id: props.productProperty.id,
        title: productProperty.title,
        type: productProperty.type,
        value: productProperty.type === 'list' ? [] : ''
      })
    }
    idx = props.properties.findIndex((o) => o.id === props.productProperty.id)
    if (e.target.id === 'list') {
      const idx = props.properties.findIndex((o) => o.id === props.productProperty.id)
      const idxValue = props.properties[idx].value.indexOf(e.target.name)
      if (idxValue > -1) {
        props.properties[idx].value.splice(idxValue, 1)
      } else {
        props.properties[idx].value.push(e.target.name)
      }
    } else if (e.target.name === 'boolean') {
      props.properties[idx].value = e.target.value === 'yes'
    } else if (e.target.name === 'numeric') {
      props.properties[idx].value = e.target.value
    }
    p.properties = props.properties
    props.setProduct(p) // Force a refresh.
  }

  const property = props.properties.find((o) => o.id === productProperty.id)

  return (
    <React.Fragment key={`selection-${props.selectedIdx}`}>
      <Box component='div' style={styles.gridContainer}>
        <SelectionHeader title={productProperty.title} />
        {productProperty.list &&
          productProperty.list.map((value, idx) => {
            let checked = false
            if (property && property.value && property.value.includes(value)) {
              checked = true
            }
            return (
              <Grid key={`pv-list-${idx}`} container wrap='nowrap' sx={{ pl: 2, pt: 1 }} style={styles.selectionContainer}>
                <Grid item xs={9}>
                  <Grid container justifyContent='flex-start' wrap='nowrap'>
                    <Grid item sx={{ width: 24 }}>
                      <FormGroup disabled={props.disabled}>
                        <FormControlLabel control={<Checkbox id='list' name={value} checked={checked} onClick={handleChange} />} label='' />
                      </FormGroup>
                    </Grid>
                    <Grid item sx={{ pl: 1, pt: 1.25 }} style={styles.hideOverflow}>
                      <Typography style={{ ...styles.hideOverflow, ...styles.textField }}>{value}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
          })}

        {productProperty.type === 'boolean'
          ? (
            <Grid container wrap='nowrap' sx={{ p: 2 }} style={styles.selectionContainer}>
              <Grid item xs={12}>
                <FormControl disabled={props.disabled}>
                  <RadioGroup aria-labelledby='boolean' name='boolean' value={property.value ? 'yes' : 'no'} onChange={handleChange}>
                    <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                    <FormControlLabel value='no' control={<Radio />} label='No' />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            )
          : null}

        {productProperty.type === 'numeric'
          ? (
            <Grid container wrap='nowrap' sx={{ p: 2 }} style={styles.selectionContainer}>
              <Grid item xs={12}>
                <TextField
                  disabled={props.disabled}
                  fullWidth
                  required
                  id='numeric'
                  name='numeric'
                  variant='outlined'
                  type='number'
                  label=''
                  helperText='Please enter the numeric value'
                  value={property && property.value ? property.value : ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Typography>{productProperty.units}</Typography>
                      </InputAdornment>
                    )
                  }}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            )
          : null}
      </Box>
    </React.Fragment>
  )
}

const DialogEditProperty = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [numericUnits, setNumericUnits] = useState([])
  const [title, setTitle] = useState('')
  const [units, setUnits] = useState('')
  const [type, setType] = useState('list')
  const [list, setList] = useState([])
  const [value, setValue] = useState('')

  useEffect(() => {
    if (isLoading) {
      if (props.numericUnits && Array.isArray(props.numericUnits) && props.numericUnits.length > 0) {
        setNumericUnits(props.numericUnits)
      }
      let p
      if (props.variant === 'edit') {
        p = props.productProperties[props.selectedIdx]
        setTitle(p.title)
        setType(p.type)
        if (p.type === 'list') {
          setList(p.list ? p.list : [])
        } else if (p.type === 'numeric') {
          setUnits(p.units ? p.units : 'each')
        }
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleSave = (e) => {
    let p
    if (props.variant === 'new') {
      const guid = Helper.guid()
      p = {
        id: guid,
        title: title || 'No title',
        type,
        custom: true // To track in case we want to prevent edits on non-custom values
      }
      props.productProperties.unshift(p)
    } else {
      p = props.productProperties[props.selectedIdx]
    }
    p.title = title
    if (p.type === 'list') {
      p.list = list || []
    } else if (p.type === 'numeric') {
      p.units = units || 'each'
    }
    props.onSave()
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'title') {
      setTitle(value)
    } else if (name === 'type') {
      setType(value)
    } else if (name === 'units') {
      setUnits(value)
    }
  }

  const handleSortList = () => {
    const l = JSON.parse(JSON.stringify(list))
    l.sort()
    setList(l)
  }

  const handleAddList = (value) => {
    const l = JSON.parse(JSON.stringify(list))
    l.push(value)
    setList(l)
    setValue('')
  }

  const handleDeleteList = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    if (window.confirm(`Are you sure you want to delete '${l[idx]}'?`)) {
      l.splice(idx, 1)
      setList(l)
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  const property = props.productProperties[props.selectedIdx]

  const jsx = (
    <>
      <Grid container sx={{ pt: 1 }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id='title'
            name='title'
            variant='outlined'
            label='Title'
            helperText='Please enter the title.'
            value={title || ''}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} sx={{ pt: 2 }}>
          <FormControl>
            <FormLabel id='type'>Type</FormLabel>
            <RadioGroup aria-labelledby='type' name='type' defaultValue={type || 'list'} onChange={handleChange}>
              <FormControlLabel disabled={props.variant === 'edit' && type !== 'list'} value='list' control={<Radio />} label='List' />
              <FormControlLabel disabled={props.variant === 'edit' && type !== 'numeric'} value='numeric' control={<Radio />} label='Numeric' />
              <FormControlLabel disabled={props.variant === 'edit' && type !== 'boolean'} value='boolean' control={<Radio />} label='Yes / No' />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ p: 0 }}>
          {type === 'list'
            ? (
              <>
                <Grid container sx={{ pb: 1 }}>
                  <Grid item xs={11}>
                    <TextField
                      fullWidth
                      id='add-list-value'
                      name='add-list-value'
                      variant='outlined'
                      label='Add a new value'
                      value={value || ''}
                      onChange={(e) => {
                        setValue(e.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} sx={{ pt: 1 }}>
                    <IconButton
                      onClick={() => {
                        handleAddList(value)
                      }}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container>
                  {list && list.length ? <SelectionHeader title='List' onSort={handleSortList} /> : null}
                  {list.map((l, idx) => {
                    return (
                      <Grid container key={`dep-list-${idx}`} sx={{ pl: 2 }} style={styles.selectionContainer}>
                        <Grid item xs={11} sx={{ pt: 2, pb: 2 }}>
                          {l}
                        </Grid>
                        <Grid item xs={1} sx={{ pt: 1.5 }}>
                          <IconButton
                            onClick={() => {
                              handleDeleteList(idx)
                            }}
                          >
                            <DeleteIcon fontSize='small' />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </>
              )
            : null}
          {type === 'numeric'
            ? (
              <Grid container>
                <FormControl fullWidth sx={{ pb: { sm: 1 }, 'input:focus': { border: 'none' } }}>
                  <InputLabel id='unit-type'>Unit Type</InputLabel>
                  <Select
                    required
                    fullWidth
                    id='units'
                    name='units'
                    label='Unit Type'
                    labelId='unit-type'
                    variant='outlined'
                    value={units}
                    onChange={handleChange}
                    sx={{ height: 50 }}
                  >
                    {numericUnits.map((u, idx) => {
                      return (
                        <MenuItem key={`unit-${idx}`} value={u.title}>
                          <span style={styles.textField}>{u.title}</span>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              )
            : null}
        </Grid>
      </Grid>
    </>
  )

  return (
    <DialogGeneric
      title={props.variant === 'edit' ? 'Edit Property' : 'Add New Property'}
      content={jsx}
      fullWidth
      onChange={handleSave}
      onClose={props.onClose}
      onDelete={props.onDelete && property && property.custom ? props.onDelete : null}
    />
  )
}

const styles = {
  gridContainer: {
    width: 450,
    minWidth: 450
  },
  boxContainer: {
    flex: 1,
    display: 'flex',
    alignContent: 'flex-start',
    overflow: 'auto'
  },
  boxScroll: {
    overflow: 'auto'
  },
  selectionContainer: {
    border: '1px solid rgba(0,0,0,.16)',
    marginTop: -1,
    backgroundColor: 'white',
    userSelect: 'none',
    WebkitUserSelect: 'none'
  },
  hideOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  textField: {
    fontSize: 14
  },
  selected: {
    backgroundColor: '#e7f0fc'
  },
  stackItem: {
    textAlign: 'center'
  }
}
