import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

class DateRangePicker extends React.Component {
  constructor (props) {
    super(props)
    const dateFromString = this.props.dateFrom ? `${this.props.dateFrom}T00:00:00` : null
    const dateFrom = new Date(dateFromString)
    let dateTo
    if (this.props.dateTo) {
      const dateToString = `${this.props.dateTo}T00:00:00`
      dateTo = new Date(dateToString)
    } else {
      // If dateTo is not provided, set it to one day after dateFrom
      dateTo = new Date(dateFrom)
      dateTo.setDate(dateFrom.getDate() + 1)
    }
    this.state = {
      dateFrom,
      dateTo
    }
    this.handleChangeDate = this.handleChangeDate.bind(this)
    this.setDateFrom = this.setDateFrom.bind(this)
    this.setDateTo = this.setDateTo.bind(this)
  }

  handleChangeDate () {
    if (this.props.onChangeDate) {
      this.props.onChangeDate(this.state.dateFrom, this.state.dateTo)
    }
  }

  setDateFrom (date) {
    this.setState({ dateFrom: date }, () => { this.handleChangeDate() })
  }

  setDateTo (date) {
    this.setState({ dateTo: date }, () => { this.handleChangeDate() })
  }

  render () {
    /**
     * Return columns side by side.  If you need another format, refactor.
     */
    return (
      <div className='date-range-picker-wrapper'>
        <div className='date-container' style={{ marginRight: '8px' }}>
          <span>Start Date</span>
          <div className='date-picker'>
            <PickDateFrom date={this.state.dateFrom} setDateFrom={this.setDateFrom} dateTo={this.state.dateTo} />
          </div>
        </div>
        <div className='date-container'>
          <span>End Date</span>
          <div className='date-picker'>
            <PickDateTo date={this.state.dateTo} setDateTo={this.setDateTo} dateFrom={this.state.dateFrom} />
          </div>
        </div>
      </div>
    )
  }
}

const PickDateFrom = (props) => {
  const [dateFrom, setDateFrom] = useState(new Date(props.date))

  return (
    <DatePicker
      className='form-control' maxDate={props.dateTo} selected={dateFrom} onChange={(date) => {
        setDateFrom(date)
        props.setDateFrom(date)
      }}
    />
  )
}

const PickDateTo = (props) => {
  const [dateTo, setDateTo] = useState(new Date(props.date))
  return (
    <DatePicker
      style={{ zIndex: 9999 }} className='form-control' minDate={props.dateFrom} selected={dateTo} onChange={(date) => {
        setDateTo(date)
        props.setDateTo(date)
      }}
    />
  )
}

export default DateRangePicker
