import React, { useState } from 'react'
import { Box, Button, Grid, IconButton, Paper, Table, TableCell, TableHead, TableRow, TableContainer, FormControlLabel, FormControl, Switch } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import UpIcon from '@mui/icons-material/KeyboardArrowUp'
import DownIcon from '@mui/icons-material/KeyboardArrowDown'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import DataGrid from 'components/Reusable/DataGrid'

const DocumentsDataTable = ({ title, type, documents, onAdd, onDelete, onEdit, onEditRow, onMove, showRequired = true }) => {
  const [open, setOpen] = useState(true)

  const handleMove = (idx, direction) => {
    const d = JSON.parse(JSON.stringify(documents))
    if (direction === 'up') {
      if (d[idx - 1]) {
        const toSwitch = JSON.parse(JSON.stringify(d[idx]))
        const switchWith = JSON.parse(JSON.stringify(d[idx - 1]))
        d[idx - 1] = toSwitch
        d[idx] = switchWith
      }
    } else if (direction === 'down') {
      if (d[idx + 1]) {
        const switchWith = JSON.parse(JSON.stringify(d[idx + 1]))
        d[idx + 1] = JSON.parse(JSON.stringify(d[idx]))
        d[idx] = switchWith
      }
    }
    documents = d
    onMove(type, documents)
  }

  const handleProcessRowUpdate = (row, type) => {
    const d = JSON.parse(JSON.stringify(documents))

    d[row.id].title = row.title
    documents = d

    onEditRow(type, documents)
  }

  const onChangeSwitch = (row, type, cellValue, headerName) => {
    const d = JSON.parse(JSON.stringify(documents))

    if (headerName === 'include') {
      d[row.id].include = !(d[row.id].include)
    } else if (headerName === 'required') {
      d[row.id].required = !(d[row.id].required)
    } else if (headerName === 'sendCustomer') {
      d[row.id].sendCustomer = !(d[row.id].sendCustomer)
    } else if (headerName === 'sendDistributionList') {
      d[row.id].sendDistributionList = !(d[row.id].sendDistributionList)
    }

    documents = d
    onEditRow(type, documents)
  }

  const columns = [
    {
      flex: 1,
      type: 'string',
      field: 'title',
      headerName: 'Title',
      editable: true,
      sortable: true
    },
    {
      flex: 1,
      field: 'include',
      headerName: 'Include',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const isTrueSet = (String(params.row.include).toLowerCase() === 'yes')
        if (params.row) {
          return (
            <Box>
              <FormControl sx={{ mt: 1 }}>
                <FormControlLabel
                  sx={{ mt: 1 }}
                  control={
                    <Switch
                      color='primary'
                      sx={{ m: 0 }}
                      onChange={() => onChangeSwitch(params.row, type, params.row.include, 'include')}
                      checked={isTrueSet}
                    />
                  }
                />
              </FormControl>
            </Box>
          )
        }
      }
    },
    {
      flex: 1,
      field: 'required',
      headerName: 'Required',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const isTrueSet = (String(params.row.required).toLowerCase() === 'yes')
        if (params.row) {
          return (
            <Box>
              <FormControl sx={{ mt: 1 }}>
                <FormControlLabel
                  sx={{ mt: 1 }}
                  control={
                    <Switch
                      color='primary'
                      sx={{ m: 0 }}
                      onChange={() => onChangeSwitch(params.row, type, params.row.required, 'required')}
                      checked={isTrueSet}
                    />
                  }
                />
              </FormControl>
            </Box>
          )
        }
      }
    },
    {
      flex: 1,
      field: 'sendCustomer',
      headerName: 'Send to Customer',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const isTrueSet = (String(params.row.sendCustomer).toLowerCase() === 'yes')
        if (params.row) {
          return (
            <Box>
              <FormControl sx={{ mt: 1 }}>
                <FormControlLabel
                  sx={{ mt: 1 }}
                  control={
                    <Switch
                      color='primary'
                      sx={{ m: 0 }}
                      onChange={() => onChangeSwitch(params.row, type, params.row.sendCustomer, 'sendCustomer')}
                      checked={isTrueSet}
                    />
                  }
                />
              </FormControl>
            </Box>
          )
        }
      }
    },
    {
      flex: 1,
      field: 'sendDistributionList',
      headerName: 'Send to Distribution List',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const isTrueSet = (String(params.row.sendDistributionList).toLowerCase() === 'yes')
        if (params.row) {
          return (
            <Box>
              <FormControl sx={{ mt: 1 }}>
                <FormControlLabel
                  sx={{ mt: 1 }}
                  control={
                    <Switch
                      color='primary'
                      sx={{ m: 0 }}
                      onChange={() => onChangeSwitch(params.row, type, params.row.sendDistributionList, 'sendDistributionList')}
                      checked={isTrueSet}
                    />
                  }
                />
              </FormControl>
            </Box>
          )
        }
      }
    },
    {
      width: 168,
      field: 'action',
      headerName: 'Actions',
      headerAlign: 'right',
      align: 'right',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton
                  title='Edit'
                  aria-label='edit'
                  onClick={() => {
                    onEdit(type, params.row.id)
                  }}
                >
                  <EditIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton title='Up' aria-label='up' onClick={() => handleMove(params.row.id, 'up')} disabled={params.row.id < 1}>
                  <UpIcon variant='icon' fontSize='small' />
                </IconButton>
                <IconButton title='Down' aria-label='down' onClick={() => handleMove(params.row.id, 'down')} disabled={params.row.id === documents.length - 1}>
                  <DownIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  title='Delete'
                  aria-label='delete'
                  onClick={() => {
                    onDelete(type, params.row.id)
                  }}
                >
                  <DeleteIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < documents.length; x++) {
    rows.push({
      id: x,
      title: documents[x].title,
      include: documents[x].include ? 'Yes' : 'No',
      required: documents[x].required ? 'Yes' : 'No',
      sendCustomer: documents[x].sendCustomer ? 'Yes' : 'No',
      sendDistributionList: documents[x].sendDistributionList ? 'Yes' : 'No'
    })
  }

  return (
    <Box sx={{ width: '100%', marginBottom: '16px' }}>
      <TableContainer component={Paper} sx={{ marginBottom: '16px', borderBottom: 0 }}>
        <Table aria-label='customized table' sx={{ borderBottom: '2px solid #2a3042' }}>
          <TableHead>
            <TableRow onClick={() => setOpen(!open)}>
              <TableCell>
                {title}s ({documents.length})
              </TableCell>
              <TableCell align='right'>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {open
          ? (
            <DataGrid
              sx={{ border: 0, '& .MuiDataGrid-columnHeaders': { borderRadius: 0 } }}
              rows={rows}
              columns={columns}
              showToolbar={false}
              showRequired
              onAdd={() => {
                onAdd(type)
              }}
              onProcessRowUpdate={(row) => {
                handleProcessRowUpdate(row, type)
              }}
              autosizeOptions={{
                columns: ['action'],
                includeOutliers: true,
                includeHeaders: false
              }}
            />
            )
          : null}
      </TableContainer>
      {open
        ? (
          <Button
            id='asset-action'
            className='mui-btn'
            color='success'
            variant='contained'
            onClick={() => {
              onAdd(type)
            }}
          >
            Add {title}
          </Button>
          )
        : null}
    </Box>
  )
}
export default DocumentsDataTable
