import React, { useEffect, useState } from 'react'
import { Box, Button, Chip, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import ExpressionHelper from 'library/ExpressionHelper'
import { LoadingPleaseWait } from '@supportworks/react-components'
import Permissions from 'library/Permissions'

const DialogCalculator = ({ variant = 'filtered-list', title = 'Material Price Calculator', defaultComponents, selectedIndex, parent, onSave, onClose }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [components, setComponents] = useState(defaultComponents) // List of components
  const [calcInfix, setCalcInfix] = useState('') // Infix calculation
  const [calcPostfix, setCalcPostfix] = useState('') // Postfix calculation
  const [isValid, setIsValid] = useState(true)
  const [selectedFieldId, setSelectedFieldId] = useState('')
  const [calculation, setCalculation] = useState(0)

  const localFunctions = function (key) {
    const component = defaultComponents.find((comp) => comp.id === key)
    if (component) {
      if (component.type === 'calc') {
        return component.calcTotal
      } else {
        return component.price
      }
    } else {
      return 0
    }
  }

  useEffect(() => {
    if (isLoading) {
      const c = JSON.parse(JSON.stringify(components))
      let component
      if (selectedIndex > -1) {
        component = c[selectedIndex]
      } else {
        component = parent
      }
      if (component && (component.type === 'calc' || component.calcType === 'calc') && component.calc !== undefined) {
        handleExpressionChange(component.calc)
        if (ExpressionHelper.validPostfix(component.calc, localFunctions)) {
          const infix = ExpressionHelper.postfixToInfix(component.calc, localFunctions)
          if (ExpressionHelper.validInfix(infix, localFunctions)) {
            setCalcInfix(infix !== undefined ? infix : '')
            setCalcPostfix(component.calc)
            setIsValid(true)
            if (component.calc !== undefined && component.calc !== '' && component.calcTotal) {
              // components of materials use calc and calcTotal
              setCalculation(Number(component.calcTotal))
            } else if (component.calcType === 'calc') {
              // corner case, materials top level parent uses calcType and price
              setCalculation(Number(component.price))
            } else {
              setCalculation(0)
            }
          }
        }
      }
      // Use filtered-list if you want to remove the selected component from the list.  See CompanyConfigMaterials.js.
      if (variant === 'filtered-list') {
        c.splice(selectedIndex, 1)
      }
      setComponents(c)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (selectedFieldId) {
      setSelectedFieldId('')
      let c
      if (calcInfix === undefined) {
        c = `${selectedFieldId}`.trim() // for when you AC and it's undefined
      } else {
        c = `${calcInfix} ${selectedFieldId}`.trim()
      }
      handleExpressionChange(c)
      setCalcInfix(c)
    }
  }, [selectedFieldId]) // eslint-disable-line

  const handleSave = () => {
    if (onSave) {
      onSave(calcPostfix)
    }
    onClose()
  }

  const handleAddMathFunction = (mathFunction) => {
    const c = `${calcInfix} ${mathFunction}`.trim()
    handleExpressionChange(c)
    setCalcInfix(c)
  }

  const handleAddNumber = (number) => {
    if (calcInfix === undefined) {
      handleExpressionChange(number)
      setCalcInfix(number)
      return
    }
    const calcArray = calcInfix.split(/\s+/)
    const lastElement = calcArray[calcArray.length - 1]
    const c = findComponentById(lastElement)
    if (c === null && (lastElement === '' || !isNaN(parseFloat(lastElement)))) {
      // Last element is a number or empty or period, concatenate the number without a space
      calcArray[calcArray.length - 1] += number
    } else {
      if (lastElement === '.') {
        calcArray[calcArray.length - 1] += number
      } else {
        // Last element is not a number, add a space before concatenating the number
        calcArray.push(number)
      }
    }
    const updatedCalcInfix = calcArray.join(' ')
    handleExpressionChange(updatedCalcInfix)
    setCalcInfix(updatedCalcInfix)
  }

  const handleExpressionChange = (calcInfix) => {
    if (calcInfix !== undefined && calcInfix !== '') {
      const c = calcInfix.trim()
      // console.log(`expression change here: ${c}`);
      if (ExpressionHelper.validInfix(c, localFunctions)) {
        const postfix = ExpressionHelper.infixToPostfix(c, localFunctions)
        if (postfix !== undefined && postfix !== '') {
          const result = ExpressionHelper.calc(postfix, localFunctions)
          setCalculation(Number(result.value))
        } else {
          setCalculation(0)
        }
        setCalcPostfix(postfix)
        setIsValid(true)
      } else {
        setCalculation(0)
        setIsValid(false)
      }
    }
  }

  const handleFieldSelectionChange = (e) => {
    setSelectedFieldId(e.target.value)
  }

  const handleClear = () => {
    setCalcInfix(undefined)
    setCalcPostfix(undefined)
    setCalculation(0)
    setIsValid(true)
  }

  const renderCalcChips = () => {
    if (calcInfix !== undefined && calcInfix.length === 0) return
    if (calcInfix === undefined) return
    if (calcInfix === '') return
    const calcArray = calcInfix.split(/\s+/)
    return calcArray.map((item, index) => {
      const c = findComponentById(item)
      if (c && c.title !== item) {
        if (c.type === 'calc') {
          return (
            <Chip
              key={index}
              label={
                <Box style={{ whiteSpace: 'nowrap' }}>
                  <Typography variant='body2'>
                    {c.title} <i>${c.calcTotal}</i>
                  </Typography>
                </Box>
              }
              style={{ margin: '2px' }}
              size='small'
            />
          )
        }
        return <Chip key={index} label={`${c.title} $${c.price}`} style={{ margin: '2px' }} size='small' />
      } else {
        return ` ${item}`
      }
    })
  }

  const findComponentById = (id) => {
    const component = components.find((comp) => comp.id === id)
    return component || null
  }

  if (isLoading) return <LoadingPleaseWait />

  const jsx = (
    <Box>
      <Box bgcolor='black' color='white' p={1}>
        <Typography variant='h6' align='right' style={{ fontFamily: 'monospace' }}>
          {isValid ? calculation : '...'}
        </Typography>
      </Box>
      <Grid container sx={{ p: 1, bgcolor: '#F0F0F0' }}>
        <Grid item xs={12}>
          {renderCalcChips()}
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ pt: 2 }}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Select a component</InputLabel>
            <Select value={selectedFieldId} onChange={handleFieldSelectionChange}>
              {components.map((component) => {
                if (component.type === 'calc' && component.calcTotal === undefined) return null
                return (
                  <MenuItem key={component.id} value={component.id}>
                    {component.title} {component.type === 'calc' ? `($${component.calcTotal})` : `($${component.price})`}
                  </MenuItem>
                )
              }
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
        <Grid container spacing={1} sx={{ pt: 1 }}>
          <Grid item xs={3}>
            <Button onClick={handleClear} variant='outlined' fullWidth>
              AC
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => handleAddMathFunction('(')} variant='outlined' fullWidth>
              (
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => handleAddMathFunction(')')} variant='outlined' fullWidth>
              )
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => handleAddMathFunction('/')} variant='outlined' fullWidth>
              ÷
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ pt: 1 }}>
          <Grid item xs={3}>
            <Button onClick={() => handleAddNumber('7')} variant='contained' color='primary' fullWidth>
              7
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => handleAddNumber('8')} variant='contained' color='primary' fullWidth>
              8
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => handleAddNumber('9')} variant='contained' color='primary' fullWidth>
              9
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => handleAddMathFunction('*')} variant='outlined' fullWidth>
              x
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ pt: 1 }}>
          <Grid item xs={3}>
            <Button onClick={() => handleAddNumber('4')} variant='contained' color='primary' fullWidth>
              4
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => handleAddNumber('5')} variant='contained' color='primary' fullWidth>
              5
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => handleAddNumber('6')} variant='contained' color='primary' fullWidth>
              6
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => handleAddMathFunction('-')} variant='outlined' fullWidth>
              -
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ pt: 1 }}>
          <Grid item xs={3}>
            <Button onClick={() => handleAddNumber('1')} variant='contained' color='primary' fullWidth>
              1
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => handleAddNumber('2')} variant='contained' color='primary' fullWidth>
              2
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => handleAddNumber('3')} variant='contained' color='primary' fullWidth>
              3
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => handleAddMathFunction('+')} variant='outlined' fullWidth>
              +
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ pt: 1 }}>
          <Grid item xs={3}>
            <Button onClick={() => handleAddNumber('0')} variant='contained' color='primary' fullWidth>
              0
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => handleAddNumber('.')} variant='contained' color='primary' fullWidth>
              .
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button disabled />
          </Grid>
          <Grid item xs={3}>
            <Button disabled />
          </Grid>
        </Grid>
      </Box>

      {Permissions.hasRole('super_userSeanWasHere')
        ? (
          <Grid container sx={{ mt: 1 }} style={{ border: '3px dotted red', backgroundColor: 'black' }}>
            <Grid item xs={12} sx={{ p: 1 }}>
              <Stack>
                <Typography variant='body2' sx={{ pl: 1 }} style={{ color: 'yellow' }}>
                  Infix: {calcInfix}
                </Typography>
                <Typography variant='body2' sx={{ pl: 1 }} style={{ color: 'yellow' }}>
                  Postfix: {calcPostfix}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          )
        : null}
    </Box>
  )

  let subTitle = ''
  if (selectedIndex > -1) {
    subTitle = defaultComponents[selectedIndex].title
  }
  return (
    <DialogGeneric
      title={title}
      subTitle={subTitle}
      fullWidth
      maxWidth='sm'
      content={jsx}
      onChange={handleSave}
      onClose={onClose}
    />
  )
}
export default DialogCalculator

/**
 * This is a recursive function that will loop through all of the components and clear the calcTotal.
 *
 * @param {*} c
 */
const clearCalc = (c) => {
  for (let x = 0; x < c.length; x++) {
    c[x].calcTotal = undefined
  }
}

/**
 * This is a recursive function that will loop through all of the components and calculate the calcTotal.
 *
 * @param {*} c
 * @return {*}
 */
const recurseCalc = (c) => {
  // count all of my c array records that have a calcTotal set
  const done = c.filter((o) => o.calcTotal !== undefined).length
  // console.log(`I am done with ${done} out of ${c.length}`);
  if (done === c.length) {
    return
  }
  for (let x = 0; x < c.length; x++) {
    if (c[x].calcTotal) {
      // console.log(`Already done ${c[x].id} calcTotal: ${c[x].calcTotal}`);
      continue
    }
    // console.log(`Trying to figure out ${c[x].id} __ calc: ${c[x].calc} __ price: ${c[x].price}`);
    const localFunctions = function (key) {
      const component = c.find((comp) => comp.id === key)
      if (component) {
        if (component.type === 'calc') {
          return component.calcTotal
        } else {
          return component.price
        }
      } else {
        return 0
      }
    }
    // console.log(`Trying to calculate __ ${c[x].id} __ calc: ${c[x].calc} __ price: ${c[x].price} `);
    if (c[x].type === 'amt') {
      c[x].calcTotal = c[x].price
      // console.log(`skipping, amt`);
      continue
    }
    const calc = c[x].calc
    if (calc !== undefined) {
      const result = ExpressionHelper.calc(calc, localFunctions)
      if (result.valid) {
        // console.log(`setting calcTotal to ${result.value}`);
        c[x].calcTotal = result.value
      } else {
        // console.log(`skipping, invalid`);
      }
    } else {
      c[x].calcTotal = 0
    }
  }
  recurseCalc(c)
}

export {
  clearCalc,
  recurseCalc
}
