import React, { useState } from 'react'
import { Box, Dialog, DialogTitle, DialogContent, Button } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { SketchPicker } from 'react-color'
import rgbHex from 'rgb-hex'

const ColorPicker = ({ color: defaultColor, onChange, onClose, disableAlpha = false }) => {
  const [color, setColor] = useState(defaultColor || '#FFFFFFFF')

  return (
    <Dialog onClose={onClose} open maxWidth='md'>
      <DialogTitle className='dialog-title'>
        Color Picker
        <IconButton className='dialog-close' aria-label='close' onClick={onClose} sx={{ position: 'absolute', right: 0, top: 0 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className='dialog-content'>
        <SketchPicker
          disableAlpha={disableAlpha}
          width={250}
          color={color}
          presetColors={[
            'transparent',
            '#FFFFFF',
            '#C0C0C0',
            '#808080',
            '#000000',
            '#FF0000',
            '#800000',
            '#FFFF00',
            '#808000',
            '#00FF00',
            '#008000',
            '#00FFFF',
            '#008080',
            '#0000FF',
            '#000080',
            '#FF00FF',
            '#800080'
          ]}
          onChangeComplete={(color) => {
            if (disableAlpha) {
              const hex = rgbHex(color.rgb.r, color.rgb.g, color.rgb.b)
              setColor(`#${hex}`)
            } else {
              const hex = rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a)
              setColor(`#${hex}`)
            }
          }}
        />
      </DialogContent>
      <Box style={styles.bottomNavigation}>
        <Button id='cancel' variant='outlined' color='primary' sx={{ mr: 2 }} onClick={onClose}>
          Close
        </Button>
        <Button
          color='primary'
          variant='contained'
          sx={{ marginLeft: 'auto' }}
          onClick={() => {
            onChange(color)
            onClose()
          }}
        >
          Save
        </Button>
      </Box>
    </Dialog>
  )
}
export default ColorPicker

const styles = {
  bottomNavigation: {
    display: 'flex',
    width: '100%',
    padding: '16px',
    borderTop: 1,
    borderColor: 'rgba(0,0,0,.16)',
    backgroundColor: 'white'
  }
}
