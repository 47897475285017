import React, { useState, useEffect, useRef } from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'
import Field from './Canvas/Field'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { calculateXPos, calculateYPos } from './PDFDocumentEditor'
import { guid } from '@supportworks/helper'
import { pdfjs, Document, Page } from 'react-pdf/dist/esm/entry.webpack5'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

const MINIMUM_FIELD_SIZE = 8

const CenterCanvas = ({
  document,
  asset,
  forms,
  sforms,
  formId,
  field,
  fieldSubIndex,
  fieldValue,
  page,
  pagenum = 1,
  isDragging,
  onFieldSelect,
  onDelete,
  onChange,
  onBack
}) => {
  const [isLoading, setIsLoading] = useState(false)

  // Used to turn off select when clicking out of the main ref
  const parentRef = useRef(null)

  const [base64PDF, setBase64PDF] = useState()

  // Used for zooming and field placement for the purpose of matching the PDF plotter.
  const [zoom, setZoom] = useState(1)
  const minZoom = 1
  const maxZoom = 6.0

  const [pdfWidth, setPdfWidth] = useState(-1)
  const [pdfHeight, setPdfHeight] = useState(-1)

  // Used for tracking mouse movement to drop, when placing LeftPanel elements on the CenterCanvas.
  const [dropX, setDropX] = useState(-1)
  const [dropY, setDropY] = useState(-1)

  // Used for figuring out width and height of this component when the window is resized.
  const ref = useRef(null)
  const [width, setWidth] = useState(-1)
  const [height, setHeight] = useState(-1)

  // Used for tracking moving and editing fields.
  const [selectedField, setSelectedField] = useState({}) // Used for visual plotting in this component.  Selected fields from leftnav come over as "field".
  const [selectionIndex, setSelectionIndex] = useState(-1) // Used for visual plotting in this component for fields where we need to plot fieldSubIndexes.
  const [selectedChildKey, setSelectedChildKey] = useState(null) // Used for moving field child keys that have x/y, like signature: {date:{}}.
  const [isMovingField, setIsMovingField] = useState(false)
  const [isMovingVertex, setIsMovingVertex] = useState(false)
  const [isMovingVertexVariant, setIsMovingVertexVariant] = useState(null)

  const [lastX, setLastX] = useState(-1)
  const [lastY, setLastY] = useState(-1)

  // Helper drawing tools that fire off during certain scenarios
  const [helperHLine, setHelperHLine] = useState(-1)
  const [helperVLine, setHelperVLine] = useState(-1)

  // Main useEffect that fires on page load
  useEffect(() => {
    function handleResize () {
      if (ref && ref.current) {
        if (ref.current.offsetWidth !== null) {
          setWidth(ref.current.offsetWidth)
          setHeight(ref.current.offsetHeight)
        }
      }
    }
    if (ref && ref.current) {
      setWidth(ref.current.offsetWidth)
      setHeight(ref.current.offsetHeight)
    }
    window.addEventListener('resize', handleResize)

    toDataURL(asset.path, function (dataUrl) {
      setBase64PDF(dataUrl)
      setIsLoading(false)
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (ref && ref.current) {
      if (ref.current.offsetWidth !== null) {
        setWidth(ref.current.offsetWidth)
        setHeight(ref.current.offsetHeight)
      }
    }
    // eslint-disable-next-line
  }, [zoom])

  // Used for deselecting everything if a click is done outside the main ref
  useEffect(() => {
    function handleClickOutside (event) {
      if (parentRef.current && !parentRef.current.contains(event.target)) {
        handleReset()
      }
    }
    window.addEventListener('mousedown', handleClickOutside)
    // eslint-disable-next-line
  }, [parentRef])

  const handleDragOver = (event) => {
    event.stopPropagation()
    event.preventDefault()
    const offsetX = event.nativeEvent.offsetX
    const offsetY = event.nativeEvent.offsetY
    setDropX(offsetX)
    setDropY(offsetY)
  }

  const handleDragLeave = (event) => {
    setDropX(null)
    setDropY(null)
  }

  const handleDragDrop = () => {
    const fields = JSON.parse(JSON.stringify(document.fields))

    const x = dropX / zoom
    const y = pdfHeight / zoom - dropY / zoom

    if (field.type.match(/selection/)) {
      const idx = document.fields.findIndex((o) => o.id === field.id)
      field.selections[fieldValue].id = field.selections[fieldValue].choice
      field.selections[fieldValue].x = x
      field.selections[fieldValue].y = y
      fields[idx] = field
      onChange('field', field)
      return
    }

    if (field.type.match(/signature|initial/)) {
      const idx = document.fields.findIndex((o) => o.id === field.id)
      // account for 1st signature in the main json area, which is coming in here as
      if (fieldValue === 0) {
        field.x = x
        field.y = y
        fields[idx] = field
      } else {
        fieldValue = fieldValue - 1
        field.additional[fieldValue].x = x
        field.additional[fieldValue].y = y
        fields[idx] = field
      }
      onChange('field', field)
      return
    }

    if (field.type === 'text') {
      const idx = document.fields.findIndex((o) => o.id === field.id)
      field.editorId = field.id
      field.x = x
      field.y = y
      fields[idx] = field
      return
    }

    if (document && field && field.id) {
      const f = {
        id: guid(),
        editorId: field.id,
        // type: field.type, // We only set this as "text" for the "text" type.
        title: field.title,
        x,
        y,
        page: pagenum
      }

      // For text-input type, we want to set "type": "text", otherwise skip it.  We can't remove that on the
      // field config tho because we use it to identify what to do based on field type.
      if (field.type === 'text') {
        f.type = 'text'
      }

      if (formId === 'standard') {
        f.field = field.field
      } else {
        f.type = 'source'
        f.source = {
          form: formId,
          field: field.id,
          display: 'values'
        }
        if (fieldValue) {
          f.title = fieldValue
          f.source.value = fieldValue
          f.source.display = 'values'
        }
      }
      document.fields = fields
      fields.push(f)
    }
  }

  // All handleMouse functions are used for moving fields INSIDE of the CenterCanvas.
  const handleMouseDown = (idx, selectionIndex = -1, selectedChildKey = null) => {
    const f = document.fields[idx]
    // console.log(`Mouse down ${JSON.stringify(f.id)} child key: ${selectedChildKey}`);
    setSelectedField(f)
    setIsMovingField(!isMovingField)
    setSelectionIndex(selectionIndex)
    setSelectedChildKey(selectedChildKey)
  }

  // All handleMouse functions are used for moving fields INSIDE of the CenterCanvas.
  const handleMouseMove = (e) => {
    const xPos = calculateXPos(e, zoom)
    const yPos = calculateYPos(e, zoom, pdfHeight)
    const xo = lastX - xPos
    const yo = lastY - yPos

    if (isMovingVertex) {
      if (lastX > -1) {
        let f = selectedField
        // We're resizing a sig/init type
        if (selectedField.type && selectedField.type.match(/signature|initial/) && selectionIndex !== -99) {
          f = selectedField.additional[selectionIndex]
        }
        if (f.width - xo > MINIMUM_FIELD_SIZE) {
          f.width = f.width - xo
        }
        if (isMovingVertexVariant === 'TR') {
          if (f.height - yo > MINIMUM_FIELD_SIZE) {
            f.height = f.height - yo
            if (selectedField.type && selectedField.type.match(/signature|initial/)) {
              f.width = f.height * 4.25
            }
          }
        } else {
          if (f.height + yo > MINIMUM_FIELD_SIZE) {
            f.y = f.y - yo
            f.height = f.height + yo
            if (selectedField.type && selectedField.type.match(/signature|initial/)) {
              f.width = f.height * 4.25
            }
          }
        }
      }
      setLastX(xPos)
      setLastY(yPos)
      return
    }

    if (isMovingField) {
      if (lastX > -1) {
        if (selectionIndex > -1) {
          // We're moving a field inside of a field
          if (selectedField.type && selectedField.type.match(/signature|initial/)) {
            const f = selectedField.additional[selectionIndex]
            if (selectedChildKey) {
              // We're moving a child key inside of a field that is inside of a field
              f[selectedChildKey].x = f[selectedChildKey].x - xo
              f[selectedChildKey].y = f[selectedChildKey].y - yo
            } else {
              f.x = f.x - xo
              f.y = f.y - yo
            }
          } else {
            // We're moving a selection type
            const f = selectedField.selections[selectionIndex]
            if (selectedChildKey) {
              // We're moving a child key inside of a field that is inside of a field
              f[selectedChildKey].x = f[selectedChildKey].x - xo
              f[selectedChildKey].y = f[selectedChildKey].y - yo
            } else {
              f.x = f.x - xo
              f.y = f.y - yo
            }
          }
        } else {
          // We're moving a field where the x/y is at the object level
          if (selectedChildKey) {
            // We're moving a child key inside of a regular field
            selectedField[selectedChildKey].x = selectedField[selectedChildKey].x - xo
            selectedField[selectedChildKey].y = selectedField[selectedChildKey].y - yo
          } else {
            selectedField.x = selectedField.x - xo
            selectedField.y = selectedField.y - yo
          }
        }
        // console.log(`MOVING FIELD ${selectedField.id} ${selectionIndex} ${selectedChildKey}`);
      }
      setLastX(xPos)
      setLastY(yPos)

      // HORIZONTAL LINE drawing helper
      for (let x = 0; x < document.fields.length; x++) {
        const f = document.fields[x]
        if (f.title !== selectedField.title) {
          if (selectedField.page === f.page) {
            let fudgeFactorA = 0
            let fudgeFactorB = 0
            // Fudge needed because the height of checkmark was changed so it !== values/highlights
            if (selectedField.source && selectedField.source.display === 'checkmark') {
              fudgeFactorA = 12
            }
            if (f.source && f.source.display === 'checkmark') {
              fudgeFactorB = 12
            }
            if (Math.round(selectedField.y + selectedField.height - fudgeFactorA) === Math.round(f.y + f.height - fudgeFactorB)) {
              // console.log(`match ${f.id} x: ${x} sf: ${selectedField.y}`);
              setHelperHLine(x)
              break
            }
          }
        } else {
          setHelperHLine(-1)
        }
      }
      // VERTICAL LINE drawing helper
      for (let x = 0; x < document.fields.length; x++) {
        const f = document.fields[x]
        if (f.title !== selectedField.title) {
          if (Math.round(selectedField.x) === Math.round(f.x) && selectedField.page === f.page) {
            setHelperVLine(x)
            break
          }
        } else {
          setHelperVLine(-1)
        }
      }
    } else {
      setHelperHLine(-1)
      setHelperVLine(-1)
    }
  }

  // All handleMouse functions are used for moving fields INSIDE of the CenterCanvas.
  const handleMouseUp = (e) => {
    setIsMovingField(false)
    setIsMovingVertex(false)
    setIsMovingVertexVariant(null)
    setLastX(-1)
    setLastY(-1)
  }

  const handleMoveVertex = (variant) => {
    setIsMovingVertex(true)
    setIsMovingVertexVariant(variant)
  }

  const handleZoomIn = () => {
    if (zoom < maxZoom) {
      const z = zoom + 0.25
      setZoom(z)
    }
  }

  const handleZoomOut = () => {
    if (zoom > minZoom) {
      const z = zoom - 0.25
      setZoom(z)
    }
  }

  const handleZoomFit = () => {
    if (zoom < maxZoom) {
      setZoom(maxZoom)
    }
  }

  const handleZoomReset = () => {
    setZoom(1)
  }

  const handleDelete = (field, idx) => {
    if (window.confirm('Are you sure you want to remove this field?')) {
      const mainIdx = document.fields.findIndex((o) => o.id === field.id)
      if (field.type === 'selection') {
        // For selection, idx === the selections array index.
        const f = document.fields[mainIdx]
        delete f.selections[idx].x
        delete f.selections[idx].y
        document.fields[mainIdx] = f
        onChange('field', f)
      } else if (field.type && field.type.match(/signature|initial/)) {
        // For signature/initial, idx -99 is the json on the main field.
        const f = document.fields[mainIdx]
        if (idx === -99) {
          delete f.x
          delete f.y
        } else {
          delete f.additional[idx].x
          delete f.additional[idx].y
        }
        document.fields[mainIdx] = f
        onChange('field', f)
      } else if (field.type && field.type.match(/text/)) {
        const f = document.fields[mainIdx]
        delete f.x
        delete f.y
      } else {
        document.fields.splice(idx, 1)
      }
      handleReset()
      onDelete()
    }
  }

  const handleReset = () => {
    setSelectedField({})
    setSelectionIndex(-1)
    setSelectedChildKey(null)
    setIsMovingField(false)
    onFieldSelect({})
  }

  const handlePageLoadSuccess = (page) => {
    const { width, height } = page
    setPdfWidth(width)
    setPdfHeight(height)
  }

  let jsx
  let sf = {}
  if (selectedField && selectedField.id) {
    sf = selectedField
  } else if (field && field.id) {
    sf = field
  }

  if (isLoading) return <LoadingPleaseWait />

  try {
    jsx = (
      <div ref={parentRef}>
        <div
          ref={ref}
          onDrop={handleDragDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          {page && (
            <div style={styles.container}>
              <div style={styles.pageContainer}>
                <Document key={`${pagenum}-${pdfHeight}-${pdfWidth}`} file={base64PDF} loading={<LoadingPleaseWait />} noData={<></>}>
                  <Page
                    scale={zoom}
                    pageNumber={pagenum}
                    onRenderSuccess={handlePageLoadSuccess}
                    style={{ userSelect: 'none' }}
                    loading={<LoadingPleaseWait />}
                  >
                    {pdfWidth > 0 && pdfHeight > 0
                      ? (
                        <Plotter
                          document={document}
                          forms={forms}
                          sforms={sforms}
                          pagenum={pagenum}
                          pdfWidth={pdfWidth}
                          pdfHeight={pdfHeight}
                          width={width}
                          height={height}
                          zoom={zoom}
                          field={sf}
                          fieldSubIndex={fieldSubIndex} // For selection, signature, initials
                          fieldValue={fieldValue} // for multi select types
                          selectionIndex={selectionIndex}
                          helperHLine={helperHLine}
                          helperVLine={helperVLine}
                          onFieldSelect={onFieldSelect}
                          onMouseDown={handleMouseDown}
                          onDelete={handleDelete}
                          onChange={onChange}
                          onMoveVertex={handleMoveVertex}
                        />
                        )
                      : null}
                  </Page>
                </Document>
                {isDragging
                  ? (
                    <div style={{ ...styles.hiddenContainer, height: pdfHeight, width: pdfWidth }}>
                      <span>INVISIBLE OVERLAY</span>
                    </div>
                    )
                  : null}
              </div>
              <ControlHeader
                zoom={zoom}
                onZoomIn={handleZoomIn}
                onZoomOut={handleZoomOut}
                onZoomFit={handleZoomFit}
                onZoomReset={handleZoomReset}
                onBack={onBack}
              />
            </div>
          )}
          {/**
                    <DebugInfo x={dropX} y={dropY} formId={formId} field={field} pdfWidth={pdfWidth} pdfHeight={pdfHeight} width={width} height={height} zoom={zoom} />
                    **/}
        </div>
      </div>
    )
  } catch (err) {
    console.log(`CenterCanvas error: ${err.stack}`)
    jsx = <div>The PDF data is not available. You may have to re-upload into assets, and then re-select in Document Packets.</div>
  }
  return jsx
}
export default CenterCanvas

const Plotter = ({
  document,
  forms,
  sforms,
  pagenum,
  pdfWidth,
  pdfHeight,
  zoom,
  field,
  fieldSubIndex,
  fieldValue,
  selectionIndex,
  helperHLine,
  helperVLine,
  onFieldSelect,
  onMouseDown,
  onDelete,
  onChange,
  onMoveVertex
}) => {
  const fields = document.fields
  let jsx
  let number = 0
  try {
    jsx = (
      <>
        {fields.map((f, idx) => {
          const sharedProps = {
            forms,
            sforms,
            variant: f.source ? (f.source.display ? f.source.display : 'values') : 'values',
            fieldIndex: idx,
            fieldValue,
            pdfWidth,
            pdfHeight,
            zoom
          }

          const jsxArray = []
          // For "selection" type, we have to loop the inside array elements.  Yay.
          if (f.type === 'selection') {
            for (let x = 0; x < f.selections.length; x++) {
              const fs = f.selections[x]
              if (fs.x) {
                number = number + 1
              }
              if (f.page && f.page === pagenum) {
                if (!fs.x || !fs.y) continue
                const jsx = (
                  <div
                    key={`plotter-selection-${idx}-${x}`}
                    onMouseDown={() => {
                      onFieldSelect(f, x)
                      onMouseDown(idx, x)
                    }}
                    style={{ cursor: 'pointer', zIndex: 2 }}
                  >
                    <Field
                      {...sharedProps}
                      field={fs}
                      selected={!!(f.id && field.id === f.id && fieldSubIndex === x)}
                      number={number}
                      onDelete={() => {
                        onDelete(f, x)
                      }}
                      onChange={onChange}
                      onMoveVertex={onMoveVertex}
                    />
                  </div>
                )
                jsxArray.push(jsx)
              }
            }
          } else if (f.type && f.type.match(/signature|initial/)) {
            if (f.x) {
              number = number + 1
            }
            // Display the primary sig/init
            if (f.page && f.page === pagenum && f.x) {
              let selected = false
              if (f.id === field.id) {
                if (fieldSubIndex === -99) {
                  selected = true
                }
              }
              const jsx = (
                <div key={`plotter-sig-main-${idx}`} style={{ cursor: 'pointer', zIndex: 2 }}>
                  <Field
                    {...sharedProps}
                    field={f}
                    fieldSubIndex={-99}
                    selected={selected}
                    number={number}
                    helperHLine={helperHLine === idx}
                    helperVLine={helperVLine === idx}
                    onDelete={() => {
                      onDelete(f, -99)
                    }}
                    onMouseDown={onMouseDown}
                    onFieldSelect={onFieldSelect}
                    onChange={onChange}
                    onMoveVertex={onMoveVertex}
                  />
                </div>
              )
              jsxArray.push(jsx)
            }

            // Display all the additional: [] stuff...
            for (let x = 0; x < f.additional.length; x++) {
              const fs = f.additional[x]
              if (fs.x) {
                number = number + 1
              }
              if (fs.page && fs.page === pagenum) {
                if (!fs.x || !fs.y) continue
                const jsx = (
                  <div key={`plotter-sig-additional-${idx}-${x}`} style={{ cursor: 'pointer', zIndex: 2 }}>
                    <Field
                      {...sharedProps}
                      field={fs}
                      fieldSubIndex={x}
                      selected={!!(f.id && field.id === f.id && selectionIndex !== -99 && fieldSubIndex === x)}
                      number={number}
                      onDelete={() => {
                        onDelete(f, x)
                      }}
                      onMouseDown={onMouseDown}
                      onFieldSelect={onFieldSelect}
                      onChange={onChange}
                      onMoveVertex={onMoveVertex}
                    />
                  </div>
                )
                jsxArray.push(jsx)
              }
            }
          } else {
            // All the normal stuff...
            //
            number = number + 1
            if (f.page && f.page === pagenum && f.x && f.y) {
              let selected = false
              if ((field.id && field.id === f.id) || (field.id && field.id === f.editorId)) {
                if (f.source && f.source.value) {
                  if (f.source.value === fieldValue) {
                    selected = true
                  } else {
                    selected = false
                  }
                } else {
                  selected = true
                }
              }

              const jsx = (
                <div
                  key={`plotter-${idx}`}
                  onMouseDown={() => {
                    onFieldSelect(f, -1, f.source && f.source.value ? f.source.value : null)
                    onMouseDown(idx, -1)
                  }}
                  style={{ cursor: 'pointer', zIndex: 2 }}
                >
                  <Field
                    {...sharedProps}
                    field={f}
                    selected={selected}
                    number={number}
                    helperHLine={helperHLine === idx}
                    helperVLine={helperVLine === idx}
                    onDelete={() => {
                      onDelete(f, idx)
                    }}
                    onChange={onChange}
                    onMoveVertex={onMoveVertex}
                  />
                </div>
              )
              jsxArray.push(jsx)
            }
          }
          return jsxArray
        })}
      </>
    )
  } catch (err) {
    console.log(`Plotter error: ${err.stack}`)
    jsx = <div>Plotting data is not available.</div>
  }
  return <div>{jsx}</div>
}

const ControlHeader = ({ zoom, onBack, onZoomIn, onZoomOut, onZoomFit, onZoomReset }) => {
  return (
    <div style={styles.containerControlBar}>
      <Grid container spacing={0} wrap='nowrap' alignItems='center'>
        <Grid item>
          <IconButton title='Previous' aria-label='Previous' onClick={onBack} sx={{ mr: 2 }}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={4} alignItems='center'>
          <Typography variant='h6' sx={{ display: { xs: 'none', sm: 'none', md: 'inline' } }}>
            Placement Editor
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton title='Zoom In' aria-label='Zoom In' onClick={onZoomIn}>
            <ZoomInIcon size='large' />
          </IconButton>
          <span style={{ fontSize: 8 }}>{zoom}</span>
        </Grid>
        <Grid item xs={1}>
          <IconButton title='Zoom Out' aria-label='Zoom Out' onClick={onZoomOut}>
            <ZoomOutIcon size='large' />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton title='Fill' aria-label='Fill' onClick={onZoomFit}>
            <ZoomOutMapIcon size='large' />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton title='Fill' aria-label='Fill' onClick={onZoomReset}>
            <ZoomInMapIcon size='large' />
          </IconButton>
        </Grid>
        <Grid item xs={3}>
          &nbsp;
        </Grid>
      </Grid>
    </div>
  )
}

// eslint-disable-next-line no-unused-vars
const DebugInfo = (props) => {
  return (
    <Box sx={{ position: 'fixed', left: 0, bottom: 0, zIndex: 1, backgroundColor: 'rgba(255,255,0,.50)' }}>
      <span>
        PDF: {props.pdfWidth}x{props.pdfHeight} | CanvasNav: {props.width}x{props.height} | ZOOM: {props.zoom} | Drop: {props.x},{props.y} |
        {props.formId
          ? (
            <>
              Form: {props.formId}, {props.field && props.field.id}
            </>
            )
          : null}
      </span>
    </Box>
  )
}

const toDataURL = (url, callback) => {
  // eslint-disable-next-line no-undef
  const xhr = new XMLHttpRequest()
  xhr.onload = function () {
    // eslint-disable-next-line no-undef
    const reader = new FileReader()
    reader.onloadend = function () {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response)
  }
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.send()
}

const styles = {
  container: {
    position: 'relative',
    backgroundColor: '#c2c2c2'
  },
  pageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  hiddenContainer: {
    position: 'absolute',
    backgroundColor: 'rgba(255,0,0,0.01)',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(255,0,0,0)'
  },
  containerControlBar: {
    position: 'fixed',
    zIndex: 2,
    top: 0,
    left: 0,
    paddingTop: 12,
    height: 66,
    width: '100%',
    borderBottom: '1px solid rgba(0,0,0,.16)',
    backgroundColor: 'white'
  }
}
