import { useEffect, useState } from 'react'
import { Box, Button, FormControl, Grid, InputAdornment, IconButton, InputLabel, Menu, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import SquareIcon from '@mui/icons-material/Square'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ImageIcon from '@mui/icons-material/Image'
import CheckIcon from '@mui/icons-material/WhereToVote'
import TargetIcon from '@mui/icons-material/Adjust'
import { LoadingPleaseWait } from '@supportworks/react-components'
import ColorPicker from 'components/Reusable/ColorPicker'
import { DialogAssetSelector } from 'components/Reusable/DialogAssetSelector'
import Helper from '@supportworks/helper'
import { NestedMenuItem } from 'mui-nested-menu'

const LeftPanel = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [presentation, setPresentation] = useState({})
  const [title, setTitle] = useState('')
  const [iconDefault, setIconDefault] = useState('')
  const [iconColor, setIconColor] = useState('')
  const [showIconColorPicker, setShowIconColorPicker] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState('')
  const [showBackgroundColorPicker, setShowBackgroundColorPicker] = useState(false)

  useEffect(() => {
    if (isLoading) {
      const p = JSON.parse(JSON.stringify(props.presentation))
      // Auto fix stuff...
      if (!props.presentation.overlay) {
        p.overlay = []
      }
      if (!p.iconColor) {
        p.iconColor = '#0000FF'
      }
      if (!p.backgroundColor) {
        p.backgroundColor = '#FFFFFF'
      }
      setTitle(p.title || 'No Title')
      setIconDefault(p.iconDefault || 'photo')
      setIconColor(p.iconColor)
      setBackgroundColor(p.backgroundColor)
      setPresentation(p)
      props.setPresentation(p)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    setPresentation(props.presentation)
  }, [props.presentation])

  const handleChange = (event) => {
    const p = JSON.parse(JSON.stringify(presentation))
    const name = event.target.name
    const value = event.target.value
    if (name === 'title') {
      setTitle(value)
    } else if (name === 'iconDefault') {
      setIconDefault(value)
    }
    p[name] = value
    setPresentation(p)
    props.setPresentation(p)
  }

  const handleColorChange = (name, value) => {
    const p = JSON.parse(JSON.stringify(presentation))
    if (name === 'iconColor') {
      setIconColor(value)
    } else if (name === 'backgroundColor') {
      setBackgroundColor(value)
    }
    p[name] = value
    setPresentation(p)
    props.setPresentation(p)
  }

  if (isLoading) return <LoadingPleaseWait />

  let jsx
  try {
    jsx = (
      <div id='ODE-LeftPanel-top-div' key='ODE-LeftPanel-top-div'>
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={12}>
            <TextField fullWidth id='title' name='title' variant='outlined' label='Presentation Title' value={title} sx={{ 'input:focus': { border: 'none' } }} onChange={handleChange} />
          </Grid>
        </Grid>

        <Grid container sx={{ pl: 2, pr: 2, pb: 2 }}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id='area'>Default Icon</InputLabel>
              <Select labelId='iconDefault' id='iconDefault' name='iconDefault' value={iconDefault} label='Default Icon' onChange={handleChange}>
                {iconTypes.map((a, idx) => {
                  return (
                    <MenuItem key={`area-${idx}`} value={a.id}>
                      {a.title}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container sx={{ pl: 2, pr: 2, pb: 2 }}>
          <Grid item xs={6}>
            {showIconColorPicker
              ? (
                <ColorPicker
                  color={iconColor}
                  onChange={(color) => {
                    if (color) {
                      setIconColor(color)
                      handleColorChange('iconColor', color)
                    }
                    setShowIconColorPicker(!showIconColorPicker)
                  }}
                  onClose={() => setShowIconColorPicker(!showIconColorPicker)}
                />
                )
              : null}
            <TextField
              id='iconColor'
              name='iconColor'
              variant='outlined'
              label='Icon Color'
              value={iconColor}
              onClick={() => setShowIconColorPicker(!showIconColorPicker)}
              sx={{
                'input:focus': {
                  border: 'none'
                },
                paddingRight: '4px'
              }}
              InputProps={{
                style: { fontSize: 14 },
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton sx={{ width: 24, height: 24, border: 1, borderColor: 'black', borderRadius: 0, backgroundColor: iconColor }}>
                      <SquareIcon sx={{ color: iconColor }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            {showBackgroundColorPicker
              ? (
                <ColorPicker
                  color={backgroundColor}
                  onChange={(color) => {
                    if (color) {
                      setBackgroundColor(color)
                      handleColorChange('backgroundColor', color)
                    }
                    setShowBackgroundColorPicker(!showBackgroundColorPicker)
                  }}
                  onClose={() => setShowBackgroundColorPicker(!showBackgroundColorPicker)}
                />
                )
              : null}

            <TextField
              id='backgroundColor'
              name='backgroundColor'
              variant='outlined'
              label='Background Color'
              value={backgroundColor}
              onClick={() => setShowBackgroundColorPicker(!showBackgroundColorPicker)}
              sx={{
                'input:focus': {
                  border: 'none'
                }
              }}
              InputProps={{
                style: { fontSize: 14 },
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton sx={{ width: 24, height: 24, border: 1, borderColor: 'black', borderRadius: 0, backgroundColor }}>
                      <SquareIcon sx={{ color: backgroundColor }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>

        <div>
          <OverlayAssets
            id='overlayassets'
            key='overlayassets'
            assets={props.assets}
            presentation={presentation}
            setPresentation={(p) => {
              setPresentation(p)
              props.setPresentation(p)
            }}
            selectedField={props.selectedField}
            iconDefault={iconDefault}
            onDragStart={props.onDragStart}
            onDragEnd={props.onDragEnd}
            onFieldSelect={props.onFieldSelect}
          />
        </div>
      </div>
    )
  } catch (err) {
    console.log(`LeftPanel error: ${err.stack}`)
    jsx = <div>Data is not available.</div>
  }
  return <div id='LeftPanel'>{jsx}</div>
}
export default LeftPanel

export const OverlayAssets = ({ presentation, setPresentation, selectedField, iconDefault, onDragStart, onDragEnd, onFieldSelect, assets }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [showAddDialog, setShowAddDialog] = useState(false)

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleAdd = (l) => {
    const p = JSON.parse(JSON.stringify(presentation))
    // Add to overlay by finding things in list that are not in overlay.
    for (let x = 0; x < l.length; x++) {
      const idx = p.overlay.findIndex((o) => o.image.id === l[x].asset)
      if (idx < 0) {
        p.overlay.push({
          image: {
            id: l[x].asset,
            title: l[x].title,
            height: '',
            width: '',
            x: '',
            y: ''
          },
          icon: {
            id: Helper.guid(),
            size: 'standard',
            type: iconDefault,
            x: '',
            y: ''
          }
        })
      }
    }
    // Delete from overlay by finding things not in list that are in overlay.
    for (let x = p.overlay.length - 1; x > -1; x--) {
      const idx = l.findIndex((o) => o.asset === p.overlay[x].image.id)
      if (idx < 0) {
        p.overlay.splice(x, 1)
      }
    }
    setPresentation(p)
  }

  if (isLoading) return <LoadingPleaseWait />

  const list = presentation.overlay.map((o) => {
    return { asset: o.image.id }
  })

  return (
    <Box id='top-div-overlayassets' key='top-div-key-overlayassets'>
      <Stack sx={{ ml: 2, mr: 2, pb: 1 }}>
        <Typography variant='h6'>Overlay Images</Typography>
        <Typography variant='body2'>Add and drag assets that you want to overlay on the base image.</Typography>
      </Stack>

      <Box id='div-overlayassets' key='div-key-overlayassets'>
        {presentation.overlay.map((f, idx) => {
          return (
            <Field
              id={`oa-field-${idx}`}
              key={`oa-field-${idx}`}
              idx={idx}
              presentation={presentation}
              setPresentation={setPresentation}
              field={f}
              selectedField={selectedField}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              onFieldSelect={onFieldSelect}
            />
          )
        })}
      </Box>

      <Grid item xs={12} sx={{ pl: 2, pt: 2 }}>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            setShowAddDialog(!showAddDialog)
          }}
        >
          Add Asset
        </Button>
      </Grid>

      {showAddDialog
        ? (
          <DialogAssetSelector
            title='Select Assets'
            list={list}
            assets={assets}
            filterOverride={['Image']}
            onSave={(l) => {
              handleAdd(l)
              setShowAddDialog(!showAddDialog)
            }}
            onClose={() => setShowAddDialog(!showAddDialog)}
          />
          )
        : null}
    </Box>
  )
}

const Field = ({ idx, presentation, setPresentation, field, onDragStart, onDragEnd, onFieldSelect }) => {
  const [isHover, setIsHover] = useState(false)

  const handleItemClick = (elem) => {
    console.info('clicked ' + field.image.title)
  }

  const handleDragExit = (event) => {
    event.preventDefault()
  }

  const handleDelete = () => {
    const p = JSON.parse(JSON.stringify(presentation))
    p.overlay.splice(idx, 1)
    setPresentation(p)
  }

  const handleIconChange = (type, size) => {
    const p = JSON.parse(JSON.stringify(presentation))
    p.overlay[idx].icon.type = type
    if (size) {
      p.overlay[idx].icon.size = size
    }
    setPresentation(p)
  }

  return (
    <Box style={{ cursor: 'pointer', backgroundColor: isHover ? '#F2F2F2' : 'white' }} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} onClick={handleItemClick} sx={{ ml: 2, mr: 2 }}>
      <Box
        id={`Field-inner-div-${field.image.id}`}
        key={`Field-inner-div-${field.image.id}`}
        draggable
        onDragStart={(event) => {
          event.stopPropagation() // IMPORTANT! Will not work without this.
          onDragStart(field)
        }}
        onDragEnd={onDragEnd}
        onDragExit={handleDragExit}
      >
        <Box
          key={`field-${field.image.id}`}
          onClick={() => {
            onFieldSelect(field)
          }}
        >
          <Grid container spacing={0} sx={{ pl: 0, pt: 1, pb: 1, pr: 0 }} style={styles.navContainer}>
            <Grid item>
              {field.icon.type === 'photo' ? <ImageIcon size='small' style={{ color: field.icon.x ? presentation.iconColor : 'black' }} /> : null}
              {field.icon.type === 'checkmark' ? <CheckIcon size='small' style={{ color: field.icon.x ? presentation.iconColor : 'black' }} /> : null}
              {field.icon.type === 'target' ? <TargetIcon size='small' style={{ color: field.icon.x ? presentation.iconColor : 'black' }} /> : null}
            </Grid>
            <Grid item sm={8} sx={{ pl: 1 }}>
              <Typography style={styles.navText}>{field.image.title}</Typography>
            </Grid>
            <Grid item sm={2}>
              <Grid container justifyContent='flex-end'>
                <FieldActionSelection field={field} onDelete={handleDelete} onIconChange={handleIconChange} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

const FieldActionSelection = ({ onDelete, onIconChange }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu id='action-menu' open={open} onClose={handleClose} anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <NestedMenuItem
          sx={{ pl: 1 }}
          onClick={() => {
            onIconChange('photo', null)
            handleClose()
          }}
          leftIcon={null}
          rightIcon={null}
          parentMenuOpen={open}
          label={<Typography component='span' variant='body2'>Photo</Typography>}
        >
          <MenuItem
            sx={{ pl: 2 }}
            onClick={() => {
              onIconChange('photo', 'standard')
              handleClose()
            }}
          >
            Standard
          </MenuItem>
          <MenuItem
            sx={{ pl: 2 }}
            onClick={() => {
              onIconChange('photo', 'large')
              handleClose()
            }}
          >
            Large
          </MenuItem>
          <MenuItem
            sx={{ pl: 2 }}
            onClick={() => {
              onIconChange('photo', 'xlarge')
              handleClose()
            }}
          >
            Extra Large
          </MenuItem>
        </NestedMenuItem>
        <NestedMenuItem
          sx={{ pl: 1 }}
          onClick={() => {
            onIconChange('checkmark', null)
            handleClose()
          }}
          leftIcon={null}
          rightIcon={null}
          parentMenuOpen={open}
          label={<Typography component='span' variant='body2'>Checkmark</Typography>}
        >
          <MenuItem
            sx={{ pl: 2 }}
            onClick={() => {
              onIconChange('checkmark', 'standard')
              handleClose()
            }}
          >
            Standard
          </MenuItem>
          <MenuItem
            sx={{ pl: 2 }}
            onClick={() => {
              onIconChange('checkmark', 'large')
              handleClose()
            }}
          >
            Large
          </MenuItem>
          <MenuItem
            sx={{ pl: 2 }}
            onClick={() => {
              onIconChange('checkmark', 'xlarge')
              handleClose()
            }}
          >
            Extra Large
          </MenuItem>
        </NestedMenuItem>
        <NestedMenuItem
          sx={{ pl: 1 }}
          onClick={() => {
            onIconChange('target')
            handleClose()
          }}
          leftIcon={null}
          rightIcon={null}
          parentMenuOpen={open}
          label={<Typography component='span' variant='body2'>Target</Typography>}
        >
          <MenuItem
            sx={{ pl: 2 }}
            onClick={() => {
              onIconChange('target', 'standard')
              handleClose()
            }}
          >
            Standard
          </MenuItem>
          <MenuItem
            sx={{ pl: 2 }}
            onClick={() => {
              onIconChange('target', 'large')
              handleClose()
            }}
          >
            Large
          </MenuItem>
          <MenuItem
            sx={{ pl: 2 }}
            onClick={() => {
              onIconChange('target', 'xlarge')
              handleClose()
            }}
          >
            Extra Large
          </MenuItem>
        </NestedMenuItem>
        <MenuItem
          sx={{ ml: 0, pl: 2 }}
          onClick={() => {
            onDelete()
            handleClose()
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  )
}

const iconTypes = [
  { id: 'photo', title: 'Photo' },
  { id: 'checkmark', title: 'Checkmark' },
  { id: 'target', title: 'Target' }
]

const styles = {
  navContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    outline: '1px solid rgba(0,0,0,.08)'
  },
  navSubContainer: {
    backgroundColor: 'rgba(0,0,0,.04)'
  },
  navText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 14,
    paddingTop: '0px',
    userSelect: 'none'
  },
  iconText: {
    position: 'absolute',
    fontSize: 8,
    fontWidth: 'bold',
    fontFamily: 'sans-serif',
    color: 'white'
  },
  pointer: {
    cursor: 'pointer',
    border: '5px solid red'
  }
}
