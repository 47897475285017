/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import {
  Accordion,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  Typography,
  AccordionDetails,
  Box,
  Grid,
  Switch,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Tooltip
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccountingDetailsDataTable from './AccountingDetails/AccountingDetailsDataTable'
import Api from '../../library/Api'
import CompanyConfigEdit, { CompanyConfigBaseEditor } from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import { Discounts } from './AccountingDetails/Discounts'
import { Finance } from './AccountingDetails/Finance'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { Surcharges } from './AccountingDetails/Surcharges'
import { Taxes } from './AccountingDetails/Taxes'
import { Commission } from './AccountingDetails/Commission'

const config = {
  title: 'Accounting Details',
  variant: 'contract.terms',
  tag: 'contract.terms',
  name: 'Accounting Details',
  lockTag: true,
  lockName: false
}

class AccountingDetailsEditor extends CompanyConfigBaseEditor {
  constructor (props) {
    super(props)

    let accountingInfo
    if (typeof props.contentJson === 'string') {
      try {
        accountingInfo = JSON.parse(props.contentJson)
      } catch (err) {
        console.log(err.message)
      }
    } else if (typeof props.contentJson === 'object') {
      // no need to parse
      accountingInfo = props.contentJson
    } else if (props.contentJson !== undefined) {
      // not string, object, or undefined. Necessary case?
      try {
        accountingInfo = JSON.parse(props.contentJson)
      } catch (err) {
        // console.log(err.message)
      }
    } else {
      // set new dealer
      accountingInfo = {
        commission: [],
        taxes: [],
        // taxSettings: {},
        finance: [],
        discounts: [],
        deposit: {},
        surcharges: []
      }
    }

    if (accountingInfo.surcharges !== undefined) {
      accountingInfo.surcharges.forEach((m) => {
        this.addExistingGuid(m.id)
      })
    }

    this.state = {
      isLoading: true,
      accountingInfo,
      commission: typeof accountingInfo.commission === 'undefined' ? [] : accountingInfo.commission,
      taxes: typeof accountingInfo.taxes === 'undefined' ? [] : accountingInfo.taxes,
      // taxSettings: typeof accountingInfo.taxSettings === 'undefined' ? {} : accountingInfo.taxSettings,
      finance: typeof accountingInfo.finance === 'undefined' ? [] : accountingInfo.finance,
      discounts:
        typeof accountingInfo.discounts === 'undefined'
          ? []
          : accountingInfo.discounts.map(function (d) {
            if ('type' in d) return d
            if (d.overrideTotal === true) {
              d.type = 'overrideTotal'
              d.amount = '0'
              d.pct = '0'
              return d
            }
            d.overrideTotal = false
            if (d.amount > 0) {
              d.type = 'amount'
              d.pct = '0'
              return d
            }
            d.amount = '0'
            d.type = 'pct'
            return d
          }),
      surcharges: typeof accountingInfo.surcharges === 'undefined' ? [] : accountingInfo.surcharges,
      deposit: typeof accountingInfo.deposit === 'undefined' || Array.isArray(accountingInfo.deposit) ? {} : accountingInfo.deposit,
      // paymentTypes: typeof accountingInfo.paymentTypes === 'undefined' ? {} : accountingInfo.paymentTypes,

      taxesList: null,
      productsList: null,
      masterList: null,
      productConfig: null,
      assetsConfig: null,
      categories: [],
      crmFieldsConfig: null,
      formsConfig: null,
      currentIndex: null,
      currentType: '',
      isEditing: false,
      expanded: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleMove = this.handleMove.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleSaveTaxes = this.handleSaveTaxes.bind(this)
    // this.handleChangeTaxExempt = this.handleChangeTaxExempt.bind(this)
    this.handleSaveFinance = this.handleSaveFinance.bind(this)
    this.handleChangeAccordion = this.handleChangeAccordion.bind(this)
    // this.handleChangePaymentTypes = this.handleChangePaymentTypes.bind(this);
    this.handleChangeDepositType = this.handleChangeDepositType.bind(this)
    this.handleChangeDefault = this.handleChangeDefault.bind(this)
    this.handleSaveRowEdit = this.handleSaveRowEdit.bind(this)
  }

  async componentDidMount () {
    super.componentDidMount()
    this.state.taxes.forEach((m) => {
      this.addExistingGuid(m.id)
    })
    this.state.finance.forEach((m) => {
      this.addExistingGuid(m.id)
    })
    this.state.discounts.forEach((m) => {
      this.addExistingGuid(m.id)
    })

    await Api.callRegisteredMethod('getCRMTaxes').then(async (taxesList) => {
      // console.log(taxesList);
      if (taxesList == null || taxesList.length === 0 || (taxesList.length === 1 && taxesList[0] == null)) {
        this.setState({ taxesList: [] })
      } else {
        this.setState({
          taxesList: taxesList.map((o) => {
            return { value: o.id, name: o.name + ' (' + o.rate + '%)', crmName: o.name }
          })
        })
      }
    })
    await Api.callRegisteredMethod('getConfigListByTag', { tag: 'products', startsWith: false }).then((configs) => {
      const res = Math.max.apply(
        Math,
        configs.map(function (o) {
          return o.configId
        })
      ) // finds greatest configId value
      const mostRecent = configs.filter((obj) => {
        // gets most recent Product config //Might not be needed, only being used now for orgId
        return obj.configId === res
      })
      const org = mostRecent[0].organizationId
      // console.log(res, org)

      Api.callRegisteredMethod('getConfigById', { configId: res, organization: org }).then((prodProps) => {
        const list = JSON.parse(prodProps.data[0].contentJson)
        const masterConfig = []
        list.map((product, ind) => {
          if (product.id) {
            masterConfig.push({
              value: product.id,
              name: product.title,
              configuration: product.configuration,
              crmId: product.crmId,
              description: product.description
            })
          }
          return null
        })
        this.setState({ masterList: masterConfig.sort((a, b) => (a.name > b.name ? 1 : -1)) })
      })
    })
    await Api.callRegisteredMethod('getCurrentConfig', { tag: 'all', startsWith: false }).then((config) => {
      let cats = []
      if (config && 'productFeatures' in config) {
        if ('product_categories' in config.productFeatures) {
          cats = config.productFeatures.product_categories
        }
      }

      const productConfig = []
      if (config && 'product_config' in config) {
        config.product_config.forEach((listItem) => {
          if ('configuration' in listItem) {
            productConfig.push({
              value: listItem.id,
              name: listItem.title,
              configuration: listItem.configuration
            })
          } else {
            productConfig.push({ value: listItem.id, name: listItem.title, configuration: [] })
          }
        })
      }

      const crmFieldsConfig = []
      if (config && 'crmFields' in config) {
        config.crmFields.forEach((o) => {
          crmFieldsConfig.push({ value: o.id, name: o.fieldName })
        })
      }

      let formsConfig = []
      if (config && 'forms' in config) {
        formsConfig = config.forms
      }

      this.setState({
        productConfig: productConfig.sort((a, b) => (a.name > b.name ? 1 : -1)),
        crmFieldsConfig: crmFieldsConfig.sort((a, b) => (a.name > b.name ? 1 : -1)),
        assetsConfig: config.assets2,
        categories: cats.sort(),
        formsConfig: formsConfig.sort((a, b) => (a.name > b.name ? 1 : -1))
      })
    })
    this.setState({ isLoading: false })
  }

  handleSave = (idx, variant, json) => {
    const c = JSON.parse(JSON.stringify(this.state.accountingInfo))
    c[variant][idx] = json
    this.handleClose()
    this.setState({ accountingInfo: c, [variant]: c[variant] })
  }

  handleChange = (event) => {
    const clonedArr = Array.from(this.state[event.target.dataset.parent])
    if (event.target.dataset.parent === 'deposit') {
      // 'deposit' is an obj. The other states are arrays of objs
      const clonedObj = JSON.parse(JSON.stringify(this.state[event.target.dataset.parent]))
      clonedObj[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value
      this.setState({
        [event.target.dataset.parent]: clonedObj
      })
    } else {
      clonedArr[event.target.id][event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value
      this.setState({
        [event.target.dataset.parent]: clonedArr
      })
    }
  }

  handleDelete = (idx, stateName) => {
    const clonedArr = Array.from(this.state[stateName])
    const index = clonedArr.findIndex((o) => o.id === idx)
    clonedArr.splice(index, 1)
    this.setState({
      [stateName]: clonedArr
    })
  }

  handleAdd = (stateName) => {
    const clonedArr = Array.from(this.state[stateName])
    if (stateName === 'discounts') {
      clonedArr.push({ id: this.guid(), label: '', amount: '', pct: '', type: 'pct', editable: false, default: false, crmID: '', categories: [] })
    }
    if (stateName === 'finance') {
      clonedArr.push({ id: this.guid(), label: '', months: 0, apr: 0, aprMax: '', url: '', minJobTotal: '', maxJobTotal: '', round_payment_factor: false, default: false, depositRequired: false, asset: '' })
    }
    if (stateName === 'taxes') {
      clonedArr.push({ id: this.guid(), label: '', amount: '', editable: false, crmID: '' })
    }
    if (stateName === 'surcharges') {
      clonedArr.push({ id: this.guid(), label: '', amount: '', pct: '', type: 'pct', taxable: false, separateLineItem: false })
    }
    if (stateName === 'commission') {
      clonedArr.push({ id: this.guid(), label: '', amount: '', pct: '', type: 'pct', default: false, editable: false, maxPct: '', crmID: '', taxable: false, separateLineItem: false })
    }
    this.setState({
      [stateName]: clonedArr
    })
  }

  handleMove = (idx, direction, stateName) => {
    const p = JSON.parse(JSON.stringify(this.state[stateName])) // p = copy of state
    const index = p.findIndex((o) => o.id === idx)
    if (direction === 'up') {
      if (p[index - 1]) {
        const toSwitch = JSON.parse(JSON.stringify(p[index]))
        const switchWith = JSON.parse(JSON.stringify(p[index - 1]))
        p[index - 1] = toSwitch
        p[index] = switchWith
        this.setState({ [stateName]: p })
      }
    } else if (direction === 'down') {
      if (p[index + 1]) {
        const switchWith = JSON.parse(JSON.stringify(p[index + 1]))
        p[index + 1] = JSON.parse(JSON.stringify(p[index]))
        p[index] = switchWith
        this.setState({ [stateName]: p })
      }
    }
  }

  handleSaveRowEdit = (config, type) => {
    if (type === 'commission') {
      const newCommission = JSON.parse(JSON.stringify(config))
      this.setState({ commission: newCommission })
    }
    if (type === 'taxes') {
      const newTaxes = JSON.parse(JSON.stringify(config))
      this.setState({ taxes: newTaxes })
    }
    if (type === 'finance') {
      const newFinance = JSON.parse(JSON.stringify(config))
      this.setState({ finance: newFinance })
    }
    if (type === 'discounts') {
      const newDiscounts = JSON.parse(JSON.stringify(config))
      this.setState({ discounts: newDiscounts })
    }
    if (type === 'surcharges') {
      const newSurcharges = JSON.parse(JSON.stringify(config))
      this.setState({ surcharges: newSurcharges })
    }
  }

  handleEdit = (idx, type) => {
    const newTaxes = this.state.taxes
    const p = JSON.parse(JSON.stringify(this.state[type]))
    const index = p.findIndex((o) => o.id === idx)
    this.setState({ taxes: newTaxes, currentIndex: index, currentType: type, isEditing: true })
  }

  handleClose = () => {
    this.setState({ currentIndex: null, currentType: '', isEditing: false })
  }

  handleSaveTaxes = (taxConfig) => {
    const newTaxes = this.state.taxes
    newTaxes[this.state.currentIndex] = taxConfig
    this.setState({ taxes: newTaxes, currentType: '', isEditing: false })
  }

  /* handleChangeTaxExempt = () => {
    const newTaxSettings = this.state.taxSettings

    if ('allowTaxExemptProposals' in newTaxSettings) {
      newTaxSettings.allowTaxExemptProposals = !newTaxSettings.allowTaxExemptProposals
    } else {
      newTaxSettings.allowTaxExemptProposals = true
    }
    this.setState({ taxSettings: newTaxSettings })
  } */

  handleChangeDefault = (id, type) => {
    if (type === 'taxes') {
      const newTaxes = JSON.parse(JSON.stringify(this.state.taxes))
      // eslint-disable-next-line array-callback-return
      newTaxes.map((item, idx) => {
        if (item.id === id) {
          if ('default' in item) {
            item.default = !item.default
          } else {
            item.default = true
          }
        } else {
          item.default = false
        }
      })
      this.setState({ taxes: newTaxes })
    }

    if (type === 'finance') {
      const newFinance = JSON.parse(JSON.stringify(this.state.finance))
      // eslint-disable-next-line array-callback-return
      newFinance.map((item, idx) => {
        if (item.id === id) {
          if ('default' in item) {
            item.default = !item.default
          } else {
            item.default = true
          }
        } else {
          item.default = false
        }
      })
      this.setState({ finance: newFinance })
    }

    if (type === 'commission') {
      const newCommission = JSON.parse(JSON.stringify(this.state.commission))
      // eslint-disable-next-line array-callback-return
      newCommission.map((item, idx) => {
        if (item.id === id) {
          if ('default' in item) {
            item.default = !item.default
          } else {
            item.default = true
          }
        } else {
          item.default = false
        }
      })
      this.setState({ commission: newCommission })
    }
  }

  handleSaveFinance = (financeConfig) => {
    this.setState({ currentType: '', isEditing: false })
  }

  handleSaveDiscount = (discountConfig) => {
    this.setState({ currentType: '', isEditing: false })
  }

  handleSaveCommission = (commissionConfig) => {
    this.setState({ currentType: '', isEditing: false })
  }

  handleChangeAccordion = (val) => {
    if (this.state.expanded === 'panel1') {
      this.setState({ expanded: false })
    } else {
      this.setState({ expanded: 'panel1' })
    }
  }

  handleChangeDepositType = (e) => {
    const newDeposit = JSON.parse(JSON.stringify(this.state.deposit))
    newDeposit[e.target.name] = e.target.value
    this.setState({ deposit: newDeposit })
  }

  finalize () {
    // This was saving the entire this.state including the isEditing, all the master list, product list, assets, etc making it huge.
    //
    // The way this component is written with object changes (ie. this.state.taxes instead of this.state.accountingInfo.taxes) forces
    // some code here to make sure things remain clean in the config, until all that is refactored.
    //
    const c = JSON.parse(JSON.stringify(this.state.accountingInfo))
    c.commission = this.state.commission
    c.taxes = this.state.taxes
    c.finance = this.state.finance
    c.discounts = this.state.discounts
    c.surcharges = this.state.surcharges
    c.finance = this.state.finance
    c.paymentTypes = this.state.paymentTypes
    c.deposit = this.state.deposit
    // c.taxSettings = this.state.taxSettings

    return JSON.stringify(c)
  }

  hasFinalize () {
    // remove categoryType from state before save
    if ('discounts' in this.state && this.state.discounts.length !== 0) {
      this.state.discounts.forEach((d) => {
        if ('categoryType' in d) {
          delete d.categoryType
        }
      })
    }

    return true
  }

  render () {
    if (this.state.isLoading) return <LoadingPleaseWait />
    // console.log(this.state)
    const sharedProps = {
      crmFieldsConfig: this.state.crmFieldsConfig,
      masterList: this.state.masterList,
      onAdd: this.handleAdd,
      onDelete: this.handleDelete,
      onEdit: this.handleEdit,
      onMove: this.handleMove,
      // onChangeTaxExempt: this.handleChangeTaxExempt,
      onChangeDefault: this.handleChangeDefault,
      onSaveRowEdit: this.handleSaveRowEdit
    }

    return (
      <>
        <div style={{ marginBottom: '24px' }} />
        <AccountingDetailsDataTable title='Commissions' type='commission' items={this.state.commission} {...sharedProps} />

        {this.state.isEditing && this.state.currentType === 'commission' && (
          <Commission
            open={this.state.isEditing}
            onClose={() => this.handleClose()}
            commissionConfig={this.state.commission[this.state.currentIndex]}
            crmFieldsConfig={this.state.crmFieldsConfig}
            masterList={this.state.masterList}
            onSave={(commissionConfig) => this.handleSaveCommission(commissionConfig)}
          />
        )}

        <AccountingDetailsDataTable
          title='Tax Rate'
          type='taxes'
          items={this.state.taxes}
          headers={getTaxesHeaders({ taxesList: this.state.taxesList })}
          // allowTaxExemptProposals={this.state.taxSettings.allowTaxExemptProposals}
          {...sharedProps}
        />

        {this.state.isEditing && this.state.currentType === 'taxes' && (
          <Taxes
            open={this.state.isEditing}
            onClose={() => this.handleClose()}
            taxConfig={this.state.taxes[this.state.currentIndex]}
            taxesList={this.state.taxesList}
            onSave={(taxConfig) => this.handleSaveTaxes(taxConfig)}
          />
        )}

        <AccountingDetailsDataTable title='Financing Plans' type='finance' items={this.state.finance} headers={getFinancePlansHeaders()} {...sharedProps} />

        {this.state.isEditing && this.state.currentType === 'finance' && (
          <Finance
            open={this.state.isEditing}
            financeConfig={this.state.finance[this.state.currentIndex]}
            assetsConfig={this.state.assetsConfig}
            onClose={() => this.handleClose()}
            onSave={(financeConfig) => this.handleSaveFinance(financeConfig)}
          />
        )}

        <AccountingDetailsDataTable title='Discounts' type='discounts' items={this.state.discounts} headers={getDiscountHeaders()} {...sharedProps} />

        {this.state.isEditing && this.state.currentType === 'discounts' && (
          <Discounts
            open={this.state.isEditing}
            onClose={() => this.handleClose()}
            discountsConfig={this.state.discounts[this.state.currentIndex]}
            productsList={this.state.productsList}
            masterList={this.state.masterList}
            productConfig={this.state.productConfig}
            categories={this.state.categories}
            crmFieldsConfig={this.state.crmFieldsConfig}
            formsConfig={this.state.formsConfig}
            onSave={(discountsConfig) => this.handleSaveDiscount(discountsConfig)}
          />
        )}

        <AccountingDetailsDataTable title='Surcharges' type='surcharges' items={this.state.surcharges} headers={getSurchargeHeaders()} {...sharedProps} />

        {this.state.isEditing && this.state.currentType === 'surcharges' && (
          <Surcharges
            open={this.state.isEditing}
            onClose={this.handleClose}
            surchargesConfig={this.state.surcharges[this.state.currentIndex]}
            productsList={this.state.productsList}
            masterList={this.state.masterList}
            productConfig={this.state.productConfig}
            onSave={(c) => {
              this.handleSave(this.state.currentIndex, 'surcharges', c)
            }}
          />
        )}

        <Accordion expanded={this.state.expanded === 'panel1'} onChange={() => this.handleChangeAccordion('panel1')}>
          <AccordionSummary
            sx={{ backgroundColor: '#2a3042', borderRadius: '4px', paddingTop: '4.4px', paddingBottom: '4.4px' }}
            expandIcon={<ExpandMoreIcon style={{ color: '#A6B0CF' }} />}
            aria-controls='panel1d-content'
            id='panel1d-header'
          >
            <Typography style={{ fontSize: '0.875rem', color: '#A6B0CF', fontWeight: 500 }}>Payment Requirements</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box style={{ marginTop: '16px' }}>
              <Grid container direction='row' spacing={2}>
                <Grid item>
                  <FormControlLabel
                    name='allowed'
                    control={
                      <Switch
                        color='primary'
                        checked={this.state.deposit.allowed ? this.state.deposit.allowed : false}
                        onChange={this.handleChange}
                        inputProps={{
                          'data-parent': 'deposit'
                        }}
                      />
                    }
                    label='Deposit Requirement'
                    labelPlacement='start'
                    sx={{ marginLeft: '0px' }}
                  />
                </Grid>
                <Grid item style={{ marginLeft: '32px' }}>
                  <FormControl size='small' sx={{ minWidth: '122px' }}>
                    <InputLabel id='demo-simple-select-label'>Deposit Type</InputLabel>
                    <Select
                      labelId='type'
                      name='type'
                      id='type'
                      size='small'
                      label='Deposit Type'
                      onChange={this.handleChangeDepositType}
                      value={this.state.deposit.type ? this.state.deposit.type : ''}
                    >
                      <MenuItem key='amt' value='amt'>
                        Dollar Amount
                      </MenuItem>
                      <MenuItem key='pct' value='pct'>
                        Percentage
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    id='amount'
                    name='amount'
                    variant='outlined'
                    label='Amount'
                    onChange={this.handleChangeDepositType}
                    value={this.state.deposit.amount ? this.state.deposit.amount : ''}
                    size='small'
                    sx={{ width: '100px' }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id='max_dollar'
                    name='max_dollar'
                    variant='outlined'
                    label='Max Amount'
                    onChange={this.handleChangeDepositType}
                    value={this.state.deposit.max_dollar ? this.state.deposit.max_dollar : ''}
                    size='small'
                    sx={{ width: '122px' }}
                  />
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </>
    )
  }
}

const getDiscountHeaders = () => {
  return [
    {
      name: 'Title',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => (
          <div className='actionsTable__title'>
            <span>{val}</span>
          </div>
        )
      }
    },
    {
      name: 'Product',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => (
          <Box style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Tooltip title={val}>
              <Typography variant='body2' noWrap>
                {val}
              </Typography>
            </Tooltip>
          </Box>
        )
      }
    },
    {
      name: 'Discount',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => (
          <div className='actionsTable__title'>
            <span>{val}</span>
          </div>
        )
      }
    },
    {
      name: 'Discount Categories',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => (
          <div className='actionsTable__title'>
            <span>{val}</span>
          </div>
        )
      }
    },
    {
      name: 'Action',
      options: {
        filter: false,
        sort: false
      }
    }
  ]
}

const getFinancePlansHeaders = () => {
  return [
    {
      name: 'Detail',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Title',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => (
          <div className='actionsTable__title'>
            <span>{val}</span>
          </div>
        )
      }
    },
    {
      name: 'Months',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => (
          <div className='actionsTable__title'>
            <span>{val}</span>
          </div>
        )
      }
    },
    {
      name: 'APR',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (val) => (
          <div className='actionsTable__title'>
            <span>{val}</span>
          </div>
        )
      }
    },
    {
      name: 'Round to Payment Factor',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (val) => (
          <div className='actionsTable__title'>
            <span>{val === true ? 'Yes' : 'No'}</span>
          </div>
        )
      }
    },
    {
      name: 'Action',
      options: {
        filter: false,
        sort: false
      }
    }
  ]
}

const getTaxesHeaders = ({ taxesList }) => {
  return [
    {
      name: 'Title',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => <div className='actionsTable__title'>{val}</div>
      }
    },
    {
      name: 'Amount',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => <div className='actionsTable__title'>{val}</div>
      }
    },
    {
      name: 'Crm Taxes',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => <div className='actionsTable__title'>{getCrmTaxName(taxesList, val)}</div>
      }
    },
    {
      name: 'Action',
      options: {
        filter: false,
        sort: false
      }
    }
  ]
}

const getSurchargeHeaders = () => {
  return [
    {
      name: 'Title',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (val) => (
          <Box>
            <span>{val}</span>
          </Box>
        )
      }
    },
    {
      name: 'Product',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (val) => (
          <Box style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Tooltip title={val}>
              <Typography variant='body2' noWrap>
                {val}
              </Typography>
            </Tooltip>
          </Box>
        )
      }
    },
    {
      name: 'Surcharge',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (val) => (
          <Box>
            <span>{val}</span>
          </Box>
        )
      }
    },
    {
      name: 'Taxable',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (val) => (
          <Box>
            <span>{val}</span>
          </Box>
        )
      }
    },
    {
      name: 'Itemized',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (val) => (
          <Box>
            <span>{val}</span>
          </Box>
        )
      }
    },
    {
      name: 'Action',
      options: {
        filter: false,
        sort: false
      }
    }
  ]
}

const getCrmTaxName = (taxesList, id) => {
  const taxConfig = taxesList.filter((item) => {
    return item.value === id
  })
  if (taxConfig.length !== 0) {
    return taxConfig[0].crmName
  } else {
    return 'Not Set'
  }
}

export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} editorComponent={AccountingDetailsEditor} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}
