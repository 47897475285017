import React from 'react'

const examples = {
  aboutus: {
    segment: {
      companyName: 'Example Company',
      address: '123 Main St.',
      city: 'Aurora',
      state: 'EX'
    }
  },
  'aboutus.companyName': {
    segment: 'Modified Company Name'
  }
}

examples.aboutus.final = { aboutus: examples.aboutus.segment }
examples['aboutus.companyName'].final = {
  aboutus: {
    companyName: examples.aboutus.segment
  }
}
examples['aboutus.companyName'].final.aboutus.companyName = examples['aboutus.companyName'].segment

/**
 * Help text for the importsJson field.
 * @return {JSX.Element}
 * @constructor
 */
function HelpTag () {
  return (
    <>
      <p>
        Tag represents the property within a whole configuration that this particular configuration is
        concerned with.
      </p>
      <p>
        For instance, if the tag is "aboutus", then any content provided will be used in the "aboutus" property
        of the complete configuration (assuming this configuration will be imported into another). The tag can
        be segmented with a period. The tag "aboutus.companyName", for example, will affect the "companyName"
        property of the "aboutus" object in the final configuration.
      </p>

      <h2>Tag: "aboutus"</h2>
      <p>With the following configuration segment:</p>
      <pre>{JSON.stringify(examples.aboutus.segment, null, 2)}</pre>
      <p>The final configuration:</p>
      <pre>{JSON.stringify(examples.aboutus.final, null, 2)}</pre>

      <h2>Tag: "aboutus.companyName"</h2>
      <p>With the following configuration segment:</p>
      <pre>{JSON.stringify(examples['aboutus.companyName'].segment, null, 2)}</pre>
      <p>The final configuration:</p>
      <pre>{JSON.stringify(examples['aboutus.companyName'].final, null, 2)}</pre>
    </>
  )
}

export default HelpTag
