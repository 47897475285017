import React, { useState, useEffect } from 'react'
import { FormControl, TextField, MenuItem } from '@mui/material'
import ProductInfo from './ProductInfo'

const MapFormFieldNonNumeric = ({ mappedFieldObject, setProduct, product, productMaster, setShowSaveButton }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [formFieldValue, setFormFieldValue] = useState('')
  const [list, setList] = useState([])

  useEffect(() => {
    console.log('useEffect start [] triggered')
    // Initialize all things on the component with existing product data.
    if (isLoading) {
      if (mappedFieldObject.type === 'choice') {
        const c = mappedFieldObject.choices.map((o) => o.title)
        setList(c)
      } else if (mappedFieldObject.type === 'boolean') {
        setList(['Yes', 'No'])
      } else if (mappedFieldObject.type === 'imageCheckbox') {
        const c = mappedFieldObject.list.map((o) => o.id)
        setList(c)
      } else {
        setList(mappedFieldObject.list)
      }
      const config = product.config.filter((o) => o.field === mappedFieldObject.id)
      if (config[0].values.length > 0) {
        setFormFieldValue(config[0].values[0].value)
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (event, idx) => {
    const { value } = event.target
    const config = product.config.filter((o) => o.field === mappedFieldObject.id)
    config[0].values = [{ value }]
    setProduct({ ...product, config })
    setShowSaveButton(true)
  }

  if (isLoading) return <></>

  return (
    <div className='sa-section sa-top-padding'>
      <ProductInfo productMaster={productMaster} />
      <div className='sa-heading'>
        <strong>Choose value that will trigger product:</strong>
      </div>

      <div className='sa-indent'>
        <FormControl name='formFieldValue' value={formFieldValue || ''} variant='outlined' fullWidth>
          <TextField
            select
            name='formFieldValue'
            label='Choose Value'
            variant='outlined'
            defaultValue={formFieldValue || ''}
            onChange={handleChange}
          >
            {list.map((row, idx) => {
              return (
                <MenuItem key={`PMFMI-${idx}`} value={row}>{row}</MenuItem>
              )
            })}
          </TextField>
        </FormControl>
      </div>
    </div>
  )
}
export default MapFormFieldNonNumeric
