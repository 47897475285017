/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { Box, Checkbox, Grid, Paper, Typography, Stack, FormGroup, FormControlLabel } from '@mui/material'
import Api from 'library/Api'
import CompanyConfigEdit, { CompanyConfigBaseEditor } from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import CompanyConfigBuild from './CompanyConfigBuild'
import { LoadingPleaseWait } from '@supportworks/react-components'

const config = {
  title: 'Assign Configuration',
  variant: '__differentiate',
  tag: '__differentiate',
  name: 'Assign Configuration',
  lockTag: true,
  lockName: true,
  lockTitle: true
}

class CompanyConfigEditDifferentiate extends CompanyConfigBaseEditor {
  constructor (props) {
    super(props)
    let config = {}
    if (typeof props.contentJson === 'string') {
      config = JSON.parse(props.contentJson)
    } else {
      config = []
    }

    this.state = {
      config,
      completeConfigs: [],
      differentiators: [],
      isLoading: true,
      content: null
    }
  }

  componentDidMount () {
    const cRef = this
    super.componentDidMount()

    Promise.all([
      Api.callRegisteredMethod('getConfigDifferentiators').then((diffs) => {
        console.log('getConfigDifferentiators...')
        if (diffs == null) {
          diffs = [diffs]
        }
        cRef.setState({ differentiators: diffs })
      }),
      Api.callRegisteredMethod('getConfigListByTag', { tag: CompanyConfigBuild.config.tag }).then((configs) => {
        console.log('getConfigListByTag...')
        configs = configs
          .filter((c) => c.status === 'Published')
          .map((c) => {
            return { value: c.configId, name: c.name }
          })
        cRef.setState({ completeConfigs: configs })
      }),
      Api.callRegisteredMethod('getConfigList', { tag: 'all' }).then((all) => {
        const config = this.state.config

        if (config.length !== 0) {
          config[0].packages.forEach((p, idx) => {
            if (
              all.some((a) => {
                return a.configId === p && (a.status === 'Unpublished' || a.status === 'Deleted')
              })
            ) {
              config[0].packages.splice(idx, 1)
              cRef.setState({ config })
            }
          })
        }
      })
    ])
      .then((results) => {
        let list = []
        let title = 'No Selector'
        if (cRef.state.differentiators[0] == null) {
          list.push({ id: '', name: 'Default', package: '', packages: [] })
        } else {
          cRef.state.differentiators.forEach((o) => {
            title = o[0].label
            o[0].options.forEach((option) => {
              if (!option.deleted) list.push({ id: option.id, name: option.name, package: '', packages: [] })
            })
          })
        }
        list = list.map((l) => {
          cRef.state.config.forEach((c) => {
            if (l.id === c.id) l.packages = c.packages
          })
          return l
        })
        cRef.setState({ title, config: list, isLoading: false })
      })
      .catch((err) => console.log(err))
  }

  finalize () {
    return JSON.stringify(this.state.config)
  }

  hasFinalize () {
    return true // true to enable saving
  }

  updateHandler = (idx, val) => {
    const config = JSON.parse(JSON.stringify(this.state.config))
    config[idx].packages = val
    this.setState({
      config
    })
  }

  handleChange = (idx, value) => {
    const config = JSON.parse(JSON.stringify(this.state.config))
    const c = config[idx]
    const idx2 = c.packages.indexOf(value)
    if (idx2 > -1) {
      c.packages.splice(idx2, 1)
    } else {
      c.packages.push(value)
    }
    this.setState({ config })
  }

  render () {
    if (this.state.isLoading) return <LoadingPleaseWait />

    return (
      <Paper elevation={0} sx={{ p: 2 }}>
        <Box sx={{ pb: 2 }}>
          <Typography variant='h6'>Assign</Typography>
          <Typography variant='body2'>
            Configurations that are assigned will be available to users. If multiple configurations are assigned to a location, the user will select which
            configuration before starting the meeting.
          </Typography>
        </Box>

        {this.state.config.map((c, idx) => {
          return (
            <Grid container key={`grid-container-${idx}`} sx={{ p: 2, borderTop: '1px solid rgba(0,0,0,.16)' }}>
              <Grid item xs={6}>
                <Stack>
                  <Typography sx={{ fontWeight: 'bold' }}>Location</Typography>
                  <Typography>{c.name}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Grid container>
                    <FormGroup>
                      {this.state.completeConfigs.map((cc, idx2) => {
                        let checked = false
                        if (c.packages.includes(cc.value)) {
                          checked = true
                        }
                        return (
                          <Grid item key={`grid-config-${idx2}`}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={cc.value}
                                  onChange={() => {
                                    this.handleChange(idx, cc.value)
                                  }}
                                  checked={checked}
                                />
                              }
                              label={cc.name}
                            />
                          </Grid>
                        )
                      })}
                    </FormGroup>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )
        })}
      </Paper>
    )
  }
}

export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} editorComponent={CompanyConfigEditDifferentiate} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />,
  config
}
