import React, { useState, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { LoadingPleaseWait } from '@supportworks/react-components'
import BoltIcon from '@mui/icons-material/Bolt'
import { pdfjs, Document, Page } from 'react-pdf/dist/esm/entry.webpack5'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

const RightPanel = ({ document, asset, pageCount, pages, pagenum = 1, onPageSelect }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [base64PDF, setBase64PDF] = useState()

  useEffect(() => {
    if (isLoading) {
      toDataURL(asset.path, function (dataUrl) {
        setBase64PDF(dataUrl)
        setIsLoading(false)
      })
      onPageSelect(0)
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  if (isLoading) return <LoadingPleaseWait />

  let jsx
  try {
    jsx = (
      <Box>
        <Grid sx={{ pb: 2 }}>
          <Typography noWrap variant='caption'>
            ({pageCount}){asset.filename}
          </Typography>
        </Grid>
        {pages.map((page, idx) => {
          const fields = []
          document.fields.forEach((f) => {
            if (f.type === 'selection') {
              for (let y = 0; y < f.selections.length; y++) {
                if (f.selections[y].page === idx + 1 && f.selections[y].x) {
                  fields.push(f.selections[y])
                }
              }
            } else if (f.type && f.type.match(/signature|initial/)) {
              if (f.page === idx + 1 && f.x) {
                fields.push(f)
              }
              for (let y = 0; y < f.additional.length; y++) {
                if (f.additional[y].page === idx + 1 && f.additional[y].x) {
                  fields.push(f.additional[y])
                }
              }
            } else {
              if (f.page === idx + 1) {
                fields.push(f)
              }
            }
          })
          return (
            <div key={`page-${idx}`}>
              <Grid
                container
                align='center'
                cols={1}
                onClick={() => {
                  onPageSelect(idx)
                }}
              >
                <Grid item xs={12} align='center' style={idx + 1 === pagenum ? { border: '5px solid yellow' } : null}>
                  {fields.length >= 0
                    ? (
                      <div style={styles.container}>
                        <div style={styles.centeredPage}>
                          <Document file={base64PDF}>
                            <Page scale={0.18} pageNumber={idx + 1} />
                          </Document>
                        </div>
                        <div style={styles.centeredPlacements}>
                          <div style={idx + 1 === pagenum ? { ...styles.placement, ...styles.selected } : { ...styles.placement }}>
                            <BoltIcon />
                            <br />
                            <span style={styles.text}>{fields.length} Placements</span>
                          </div>
                        </div>
                      </div>
                      )
                    : null}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='caption'>Page {idx + 1}</Typography>
                </Grid>
              </Grid>
            </div>
          )
        })}
      </Box>
    )
  } catch (err) {
    console.log(`RightPanel error: ${err.stack}`)
    jsx = <Box sx={{ p: 1 }}>The PDF data is not available. Try to re-upload in assets, and re-attach in Document Packets.</Box>
  }
  return <div id='RightPanel'>{jsx}</div>
}
export default RightPanel

const toDataURL = (url, callback) => {
  // eslint-disable-next-line no-undef
  const xhr = new XMLHttpRequest()
  xhr.onload = function () {
    // eslint-disable-next-line no-undef
    const reader = new FileReader()
    reader.onloadend = function () {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response)
  }
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.send()
}

const styles = {
  container: {
    position: 'relative',
    textAlign: 'center'
  },
  centeredPage: {
    display: 'flex',
    justifyContent: 'center'
  },
  centeredPlacements: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  placement: {
    position: 'relative',
    padding: '8px',
    backgroundColor: 'rgba(0,0,0,.40)',
    borderRadius: '4px',
    color: 'white'
  },
  selected: {
    backgroundColor: '#1871E7'
  },
  text: {
    fontSize: '12px',
    whiteSpace: 'nowrap'
  }
}
