import React, { useState, useEffect } from 'react'
import { Box, Grid, IconButton, TextField, Stack, Typography } from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CalculateIcon from '@mui/icons-material/Calculate'
import PercentIcon from '@mui/icons-material/Percent'
import Api from 'library/Api'
import AssembleMaterials from './components/AssembleMaterials'
import AssembleLabor from './components/AssembleLabor'
import AssembleMarkup from './components/AssembleMarkup'
import ExpressionHelper from 'library/ExpressionHelper'
import DialogCalculator, { clearCalc, recurseCalc } from 'components/Reusable/DialogCalculator'
import { LoadingPleaseWait } from '@supportworks/react-components'

const RightPanelAssembly = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [product, setProduct] = useState({}) // For object manipulation
  const [config, setConfig] = useState([])
  const [productFeatures, setProductFeatures] = useState({})
  const [hasMaterials, setHasMaterials] = useState(false)
  const [materialsTotal, setMaterialsTotal] = useState(0)
  const [markupTotal, setMarkupTotal] = useState(0)
  const [laborTotal, setLaborTotal] = useState(0)
  const [markup, setMarkup] = useState(0)
  const [calc, setCalc] = useState('')
  const [assemblyTotal, setAssemblyTotal] = useState(0) // materials + markup + labor
  const [total, setTotal] = useState(0) // final product total

  // For the postfix calculator;
  const [components, setComponents] = useState([])
  const [showDialogCalculator, setShowDialogCalculator] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(1)

  useEffect(() => {
    if (isLoading) {
      const p = _initialize(props.product)
      setMarkup(Number(p.pricingOptions.assembly.markup))
      setCalc(p.pricingOptions.assembly.calc)
      setTotal(Number(p.pricingOptions.assembly.total))
      setProduct(p)
      Promise.all([
        Api.callRegisteredMethod('getLatestConfigByTag', { tag: 'materials', startsWith: false }).then((c) => {
          if (c && c.contentJson) {
            setConfig(JSON.parse(c.contentJson))
          }
        }),
        Api.callRegisteredMethod('getLatestConfigByTag', { tag: 'productFeatures', startsWith: false }).then((c) => {
          if (c && c.contentJson) {
            setProductFeatures(JSON.parse(c.contentJson))
          }
        })
      ]).then(() => {
        setIsLoading(false)
      })
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    const p = _initialize(props.product)
    if (p.pricingOptions.assembly.materials && p.pricingOptions.assembly.materials.length > 0) {
      setHasMaterials(true)
    } else {
      setHasMaterials(false)
    }
    setProduct(p)
  }, [props.product])

  useEffect(() => {
    if (product && product.id) {
      const p = JSON.parse(JSON.stringify(product))
      if (materialsTotal !== undefined) {
        const at = materialsTotal + markupTotal + laborTotal
        setAssemblyTotal(at)
        const t = at + at * (markup / 100)
        try {
          let price = at + at * (markup / 100)
          if (calc && calc !== undefined) {
            let calcFixed = calc
            if (!calc.startsWith('total')) {
              calcFixed = 'total ' + calc // Prepend "total" if not already starting with it
            }
            const result = ExpressionHelper.calc(calcFixed, function (key) {
              if (key === 'total') {
                return t
              } else {
                return 0
              }
            })
            if (result && result.valid && result.value) {
              price = parseFloat(result.value)
            }
          }
          if (price !== undefined) {
            price = Number(price)
            if (p && p.price_ovrd === true) {
              setTotal(price)
              p.price = price
            }
            p.pricingOptions.assembly.total = price
            p.pricingOptions.assembly.calc = calc
            p.pricingOptions.assembly.markup = markup
            props.setProduct(p)
          } else {
            setTotal(0)
          }
        } catch (err) {
          console.log(`There was an error trying to calculate the total: ${JSON.stringify(err)}`)
        }
        const c = [
          {
            id: 'total',
            title: 'Total',
            subTitle: 'Total',
            type: 'amt',
            price: at || 0
          },
          {
            id: 'calcTotal',
            title: 'Total',
            subTitle: 'Total',
            type: 'calc',
            calc: p.pricingOptions.assembly.calc || '',
            calcTotal: Number(p.pricingOptions.assembly.total) || 0
          }
        ]
        setSelectedIndex(1) // This should always be 1 in this scenario
        setComponents(c)
      }
    }
  }, [materialsTotal, markupTotal, laborTotal, markup, calc]) // eslint-disable-line

  const handleShowCalculator = () => {
    setShowDialogCalculator(!showDialogCalculator)
  }

  const handleSaveCalculator = (calc) => {
    const c = JSON.parse(JSON.stringify(components))
    c[selectedIndex].calc = calc
    c[selectedIndex].price = ''
    clearCalc(c)
    recurseCalc(c)
    setComponents(c)
    setCalc(calc)
    const p = JSON.parse(JSON.stringify(product))
    p.pricingOptions.assembly.calc = calc
    setProduct(p)
    props.setProduct(p)
    setShowDialogCalculator(!showDialogCalculator)
  }

  const _initialize = (product) => {
    const p = JSON.parse(JSON.stringify(product))
    if (!p.pricingOptions) p.pricingOptions = {}
    if (!p.pricingOptions.assembly) p.pricingOptions.assembly = {}
    if (!p.pricingOptions.assembly.materials) p.pricingOptions.assembly.materials = []
    if (!p.pricingOptions.assembly.materialMarkup) p.pricingOptions.assembly.materialMarkup = []
    if (!p.pricingOptions.assembly.labor) p.pricingOptions.assembly.labor = []
    if (!p.pricingOptions.assembly.markup) p.pricingOptions.assembly.markup = 0
    if (!p.pricingOptions.assembly.total) p.pricingOptions.assembly.total = 0
    if (!p.pricingOptions.assembly.calc) p.pricingOptions.assembly.calc = ''
    return p
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <Box style={styles.container} wrap='nowrap'>
      <Box sx={{ p: 2 }} style={styles.header}>
        <Typography style={styles.title}>{props.title || 'Assembly'}</Typography>
      </Box>

      <Box sx={{ p: 2 }}>
        <AssembleMaterials
          {...props}
          config={config}
          product={product}
          setProduct={(p) => {
            setProduct(p)
            props.setProduct(p)
          }}
          setMaterialsTotal={setMaterialsTotal}
        />
      </Box>

      {materialsTotal !== undefined
        ? (
          <Box sx={{ p: 2 }}>
            <AssembleMarkup
              {...props}
              config={productFeatures.materialMarkup ? productFeatures.materialMarkup : []}
              product={product}
              materialsTotal={materialsTotal}
              setProduct={(p) => {
                setProduct(p)
                props.setProduct(p)
              }}
              setMarkupTotal={setMarkupTotal}
            />
          </Box>
          )
        : null}

      <Box sx={{ p: 2 }}>
        <AssembleLabor
          {...props}
          config={productFeatures.labor ? productFeatures.labor : []}
          product={product}
          setProduct={(p) => {
            setProduct(p)
            props.setProduct(p)
          }}
          setLaborTotal={setLaborTotal}
        />
      </Box>

      <Grid container sx={{ p: 2 }} spacing={2}>
        <Grid item xs={12} sm={3}>
          <Stack>
            <Typography>Component Total</Typography>
            <TextField disabled value={assemblyTotal > 0 ? assemblyTotal.toFixed(2) : 0} InputProps={{ startAdornment: <AttachMoneyIcon /> }} />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Stack>
            <Typography>Final Markup</Typography>
            <TextField
              disabled={!hasMaterials}
              value={markup}
              onChange={(e) => setMarkup(e.target.value)}
              InputProps={{ endAdornment: <PercentIcon /> }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Stack>
            <Typography>Custom Calculation</Typography>
            <TextField
              disabled={!hasMaterials}
              value={calc}
              onChange={(e) => {
                setCalc(e.target.value)
              }}
              InputProps={{
                style: { paddingLeft: 8 },
                startAdornment: (
                  <IconButton onClick={handleShowCalculator} disabled={!hasMaterials}>
                    <CalculateIcon />
                  </IconButton>
                )
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Stack>
            <Typography>Product Price</Typography>
            <TextField disabled value={total ? total.toFixed(2) : 0} InputProps={{ startAdornment: <AttachMoneyIcon /> }} />
          </Stack>
        </Grid>
      </Grid>
      {showDialogCalculator
        ? (
          <DialogCalculator
            title='Product Assembly Calculator'
            defaultComponents={components}
            selectedIndex={selectedIndex}
            onSave={handleSaveCalculator}
            onClose={handleShowCalculator}
          />
          )
        : null}
    </Box>
  )
}
export default RightPanelAssembly

const styles = {
  container: {
    backgroundColor: 'white'
  },
  header: {
    backgroundColor: 'white',
    borderBottom: '1px solid rgba(0,0,0,.16)'
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  textField: {
    fontSize: 14
  }
}
