/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { Box, Button, Chip, Grid, IconButton, Stack, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import WarningIcon from '@mui/icons-material/Warning'
import Api from 'library/Api'
import CompanyConfigEdit, { CompanyConfigBaseEditor } from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import DataGrid from 'components/Reusable/DataGrid'
import { DialogSolutionSelector } from 'components/Reusable/DialogSolutionSelector'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { SolutionEditor } from './Solution/SolutionEditor'
import '../../css/select-search.css'
import '../../css/selector-material-ui.css'

const config = {
  title: 'Solutions',
  variant: 'Solutions',
  tag: 'solutions',
  name: 'Solutions',
  lockTag: true,
  lockName: true
}

class CompanyConfigSolutions extends CompanyConfigBaseEditor {
  constructor (props) {
    super(props)

    let solutionsInfo = []
    if (typeof props.contentJson === 'string') {
      solutionsInfo = JSON.parse(props.contentJson)
    } else {
      solutionsInfo = []
    }

    this.state = {
      isLoading: true,
      isAdding: false,
      solutionsList: solutionsInfo,
      assetsConfig: null,
      presentationsConfig: null,
      productConfig: null,
      productList: null, // this is the one to use, for DEV-4927
      solutionsConfig: null,
      filter: '',
      solution: null,
      showSolutionEditor: false,
      showImportLibraryDialog: false,
      showLibraryUpdateDialog: false,
      libraryUpdateIndex: -1
    }
    this.handleLibraryAdd = this.handleLibraryAdd.bind(this)
  }

  componentDidMount () {
    super.componentDidMount()
    this.state.solutionsList.forEach((m) => {
      this.addExistingGuid(m.id)
    })

    Api.callRegisteredMethod('getConfigListByTag', { tag: 'products', startsWith: false }).then((configs) => {
      const res = Math.max.apply(
        Math,
        configs.map(function (o) {
          return o.configId
        })
      ) // finds greatest configId value
      const mostRecent = configs.filter((obj) => {
        // gets most recent Product config //Might not be needed, only being used now for orgId
        return obj.configId === res
      })
      const org = mostRecent[0].organizationId

      Api.callRegisteredMethod('getConfigById', { configId: res, organization: org }).then((prodProps) => {
        const list = JSON.parse(prodProps.data[0].contentJson)
        const productList = []
        list.map((listItem, ind) => {
          if (listItem.id) {
            productList.push({ value: listItem.id, name: listItem.title, configuration: listItem.configuration })
          }
          return null
        })
        // console.log(productList)
        this.setState({
          productList: productList.sort((a, b) => (a.name > b.name ? 1 : -1))
        })
      })
    })
    Api.callRegisteredMethod('getAssetList', {}).then((assetList) => {
      Api.callRegisteredMethod('getCurrentConfig', { tag: 'all', startsWith: false }).then((config) => {
        const presentations = []
        if (config && 'presentations' in config) {
          config.presentations.forEach((listItem) => {
            presentations.push({ value: listItem.id, name: listItem.title })
          })
        }

        const solutionsC = []
        if (config && 'solutions' in config) {
          config.solutions.forEach((listItem) => {
            solutionsC.push({ value: listItem.id, name: listItem.title })
          })
        }

        const productConfig = []
        if (config && 'product_config' in config) {
          config.product_config.forEach((listItem) => {
            if ('configuration' in listItem) {
              productConfig.push({ value: listItem.id, name: listItem.title, configuration: listItem.configuration })
            } else {
              productConfig.push({ value: listItem.id, name: listItem.title, configuration: [] })
            }
          })
        }

        const solutionsList = this.state.solutionsList.map((solution) => {
          if ('product' in solution && Array.isArray(solution.product)) {
            solution.productFull = JSON.parse(JSON.stringify(solution.product))
            solution.product = solution.product.filter((product) => {
              if ('library' in product) return true // DEV-5173
              // eslint-disable-next-line eqeqeq
              if (productConfig.filter((p) => p.value == product.id).length > 0) {
                return true
              } else {
                return false
              }
            })
          }
          return solution
        })
        this.setState({
          isLoading: false,
          config, // Just pass the whole thing and let the child component pick what it wants
          solutionsList: solutionsList.sort((a, b) => (a.title > b.title ? 1 : -1)),
          presentationsConfig: presentations.sort((a, b) => (a.name > b.name ? 1 : -1)),
          assetsConfig: assetList.sort((a, b) => (a.name > b.name ? 1 : -1)),
          solutionsConfig: solutionsC.sort((a, b) => (a.name > b.name ? 1 : -1)),
          productConfig: productConfig.sort((a, b) => (a.name > b.name ? 1 : -1))
        })
      })
    })
  }

  handleAdd = () => {
    const solution = {
      id: this.guid(),
      status: 'custom',
      title: '',
      subtitle: '',
      inspectType: '',
      asset: '',
      presentation: [],
      product: []
    }
    this.setState({
      isAdding: true,
      solution,
      showSolutionEditor: !this.state.showSolutionEditor
    })
  }

  handleDelete (indexes) {
    const count = indexes.length
    const confirmMessage = count === 1 ? 'Are you sure you want to delete this item?' : `Are you sure you want to delete these ${count} items?`
    const r = window.confirm(confirmMessage)
    if (r) {
      const updatedSolutionsList = this.state.solutionsList.map((item, index) => {
        // Set status to 'notadded' for items that are being 'deleted'
        if (indexes.includes(index)) {
          return { ...item, status: 'notadded' }
        }
        return item
      })
      this.setState({ solutionsList: updatedSolutionsList }, () => {
        this.props.onChange(JSON.stringify(updatedSolutionsList))
      })
    }
  }

  handleSave = (solution) => {
    const list = JSON.parse(JSON.stringify(this.state.solutionsList))
    if (this.state.isAdding) {
      list.push(solution)
    } else {
      if (solution.status && solution.status === 'added') {
        solution.status = 'edited'
        solution.changedByUser = true
      } else {
        solution.changedByUser = true
      }
      const idx = list.map((o) => o.id).indexOf(solution.id)
      if (idx > -1) {
        list[idx] = solution
      }
    }
    this.setState({
      solutionsList: list,
      solution: null,
      showSolutionEditor: false,
      isAdding: false
    })
    this.props.onChange(JSON.stringify(list)) // Trigger unsaved changes.
  }

  handleSolutionSelect = (s) => {
    this.setState({ solution: s, showSolutionEditor: !this.state.showSolutionEditor })
  }

  handleClose = () => {
    this.setState({ solution: null, showSolutionEditor: !this.state.showSolutionEditor })
  }

  handleLibraryAdd = () => {
    this.setState({ showImportLibraryDialog: !this.state.showImportLibraryDialog })
  }

  handleLibrarySave = (s) => {
    const list = JSON.parse(JSON.stringify(this.state.solutionsList))
    for (let x = 0; x < list.length; x++) {
      if (s.includes(list[x].id)) {
        if (list[x].status && list[x].status !== 'custom') {
          list[x].status = 'added1'
          if (x === 30) {
            console.log(`X IS ${x} ${list[x].title}`)
          }
          // Add all my library products to the solution.product
          if (list[x].library?.currentVersion?.product) {
            const libraryProduct = list[x].library.currentVersion.product
            for (let y = 0; y < libraryProduct.length; y++) {
              // Only change the solution.product that is a library if it hasn't changed.
              const spIdx = list[x].product.findIndex((p) => p.id === libraryProduct[y].id)
              if (spIdx > -1) {
                if (list[x].product[spIdx].changed === false) {
                  list[x].product[spIdx] = {
                    ...libraryProduct[y],
                    library: { libraryId: libraryProduct[y].id, libraryName: list[x].library.libraryName },
                    changed: false
                  }
                }
              } else {
                // Library product not on solution.product.  Add it.
                list[x].product.push({
                  ...libraryProduct[y],
                  library: { libraryId: libraryProduct[y].id, libraryName: list[x].library.libraryName },
                  changed: false
                })
                console.log(`${list[x].id} ${libraryProduct.id} ADDED NEW`)
              }
            }
          }
        }
      } else {
        if (list[x].status && list[x].status.match(/^(added|edited)/)) {
          list[x].status = 'deleted'
        }
      }
    }
    this.setState({ solutionsList: list }, () => {
      this.props.onChange(JSON.stringify(list))
    })
  }

  triggerLibraryUpdateDialog = (idx) => {
    this.setState({ showLibraryUpdateDialog: !this.state.showLibraryUpdateDialog, libraryUpdateIndex: idx })
  }

  handleLibraryUpdate = () => {
    const idx = this.state.libraryUpdateIndex
    if (idx > -1) {
      const list = JSON.parse(JSON.stringify(this.state.solutionsList))
      if (list[idx].library && list[idx].library.currentVersion) {
        const libcopy = JSON.parse(JSON.stringify(list[idx].library))
        libcopy.changed = false
        list[idx] = list[idx].library.currentVersion
        list[idx].library = libcopy
        list[idx].status = 'added1'
      }
      this.setState({ solutionsList: list, showLibraryUpdateDialog: false, libraryUpdateIndex: -1 }, () => {
        this.props.onChange(JSON.stringify(list))
      })
    }
  }

  getAsset (id) {
    const x = this.state.assetsConfig.filter((arr) => {
      return arr.guid === id
    })
    if (x.length !== 0) {
      return x[0]
    } else {
      return undefined
    }
  }

  hasFinalize () {
    return true
  }

  finalize () {
    return JSON.stringify(this.state.solutionsList)
  }

  getProductName (id) {
    const configProduct = this.state.productConfig.filter((arr) => {
      return arr.value === id
    })
    if (configProduct.length !== 0) return configProduct[0].name
    return id
  }

  getPresentationName (id) {
    const x = this.state.presentationsConfig.filter((arr) => {
      return arr.value === id
    })
    if (x.length !== 0) return x[0].name
    return 'Missing ' + id
  }

  render () {
    if (this.state.isLoading) {
      return <LoadingPleaseWait />
    }

    const columns = [
      {
        type: 'string',
        field: 'poster',
        headerName: 'Poster',
        editable: false,
        sortable: false,
        renderCell: (params) => {
          if (params.row) {
            const asset = params.row.asset
            return (
              <Box>
                {asset && asset.previewURL
                  ? (
                    <a target='_blank' href={asset.path ? asset.path : asset.previewURL} rel='noreferrer'>
                      <img src={asset.previewURL} alt={asset.previewURL} width='96' />
                    </a>
                    )
                  : null}
              </Box>
            )
          }
        }
      },
      {
        flex: 2,
        type: 'string',
        field: 'title',
        headerName: 'Title',
        editable: false,
        sortable: true,
        renderCell: (params) => (
          <Typography noWrap variant='body2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {params.value}
          </Typography>
        )
      },
      {
        flex: 1,
        type: 'string',
        field: 'subtitle',
        headerName: 'Subtitle',
        editable: false,
        sortable: true,
        renderCell: (params) => (
          <Typography noWrap variant='body2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {params.value}
          </Typography>
        )
      },
      {
        width: 128,
        type: 'string',
        field: 'automations',
        headerName: 'Automations',
        editable: false,
        sortable: false,
        renderCell: (params) => {
          if (params.row) {
            return (
              <Box>
                {params.row.presentationCount > 0
                  ? (
                    <Chip key='chip-fus' label={`${params.row.presentationCount} Presentations`} size='small' />
                    )
                  : (
                    <Chip key='chip-fus' label='No Presentations' size='small' />
                    )}
                <br />
                {params.row.productCount > 0
                  ? (
                    <Chip key='chip-as' sx={{ mb: 0.5 }} label={`${params.row.productCount} Products`} size='small' />
                    )
                  : (
                    <Chip key='chip-as' sx={{ mb: 0.5 }} label='No Solutions' size='small' />
                    )}
              </Box>
            )
          }
        }
      },
      {
        type: 'string',
        field: 'status',
        headerName: 'Status',
        editable: false,
        sortable: true,
        renderCell: (params) => {
          if (params.row) {
            return (
              <Box display='flex' alignItems='center' sx={{ mr: 2 }}>
                {params.row.changed
                  ? (
                    <IconButton
                      title='Update Library'
                      aria-label='Update Library'
                      onClick={() => {
                        this.triggerLibraryUpdateDialog(params.row.id)
                      }}
                      size='small'
                    >
                      <WarningIcon size='small' style={{ color: 'red' }} />
                    </IconButton>
                    )
                  : params.row.status === 'added'
                    ? (
                      <CheckIcon size='small' style={{ color: 'green' }} sx={{ pr: 1 }} />
                      )
                    : params.row.status === 'edited'
                      ? (
                        <CheckIcon size='small' style={{ color: 'green' }} sx={{ pr: 1 }} />
                        )
                      : params.row.status === 'added1'
                        ? (
                          <CheckIcon size='small' style={{ color: 'green' }} sx={{ pr: 1 }} />
                          )
                        : params.row.status === 'custom'
                          ? (
                            <Chip key='chip-custom' label='Custom' size='small' />
                            )
                          : params.row.status === 'deleted'
                            ? (
                              <Chip key='chip-deleted' label='Deleted' size='small' />
                              )
                            : params.row.status === 'notadded'
                              ? (
                                <Chip key='chip-notadded' label='Not Added' size='small' />
                                )
                              : null}
                {params.row.changedByUser ? <Chip key='chip-modified' label='Modified' size='small' /> : null}
              </Box>
            )
          }
        }
      },
      {
        flex: 2,
        type: 'string',
        field: 'library',
        headerName: 'Library',
        editable: false,
        sortable: true,
        renderCell: (params) => (
          <Typography noWrap variant='body2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {params.value}
          </Typography>
        )
      },
      {
        width: 128,
        field: 'action',
        headerName: 'Actions',
        headerAlign: 'right',
        align: 'right',
        editable: false,
        sortable: false,
        disableExport: true,
        renderCell: (params) => {
          if (params.row) {
            const solution = this.state.solutionsList.find((o) => o.id === params.row.configId)
            return (
              <Grid container wrap='nowrap' justifyContent='flex-end'>
                <Grid item>
                  <IconButton
                    title='Edit'
                    disabled={solution?.id === undefined}
                    aria-label='edit'
                    onClick={() => {
                      this.handleSolutionSelect(solution)
                    }}
                  >
                    <EditIcon fontSize='small' />
                  </IconButton>
                </Grid>
                {this.state.readonly
                  ? null
                  : (
                    <Grid item>
                      <IconButton
                        title='Delete'
                        aria-label='delete'
                        onClick={() => {
                          this.handleDelete([params.row.id])
                        }}
                      >
                        <DeleteIcon variant='icon' fontSize='small' />
                      </IconButton>
                    </Grid>
                    )}
              </Grid>
            )
          }
        }
      }
    ]

    const rows = []
    for (let x = 0; x < this.state.solutionsList.length; x++) {
      const solution = this.state.solutionsList[x]
      if (solution.library) {
        if (solution.status.match(/notadded|deleted/)) {
          continue
        }
      }

      let asset = null
      if (solution.asset) {
        asset = this.getAsset(solution.asset)
      } else if (solution.poster) {
        asset = this.getAsset(solution.poster)
      }
      const row = {
        id: x,
        configId: solution.id,
        asset,
        title: solution.title,
        subtitle: solution.subtitle,
        poster: solution.asset,
        productCount: solution?.product?.length > 0 ? solution.product.length : 0,
        presentationCount: solution?.presentation?.length > 0 ? solution.presentation.length : 0,
        status: solution.status,
        changed: solution.library?.changed ? solution.library.changed : false,
        changedByUser: solution.changedByUser ? solution.changedByUser : false,
        library: solution.library ? solution.library.libraryName : ''
      }
      rows.push(row)
    }

    return (
      <>
        <Box sx={{ p: 0 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            useStandardRowHeight={false}
            showToolbarDensity={false}
            defaultPageSize={50}
            onAdd={this.handleAdd}
            onDelete={(indexes) => {
              this.handleDelete(indexes)
            }}
            autosizeOptions={{
              columns: ['action'],
              includeOutliers: true,
              includeHeaders: false
            }}
          />
        </Box>

        {this.state.showSolutionEditor
          ? (
            <SolutionEditor
              config={this.state.config}
              currentSolutions={this.state.solutionsList.filter((o) => !o.status || o.status.match(/^(added|edited|custom)/))}
              assetsConfig={this.state.assetsConfig}
              productConfig={this.state.productConfig}
              productList={this.state.productList}
              solution={this.state.solution}
              onClose={this.handleClose}
              onSave={this.handleSave}
              isAdding={this.state.isAdding}
            />
            )
          : null}

        {this.state.showImportLibraryDialog
          ? (
            <DialogSolutionSelector
              title='Select Solutions'
              variant='library'
              list={this.state.solutionsList.filter((o) => o.status && o.status.match(/notadded|added|edited/))}
              solutions={this.state.solutionsList.filter((o) => o.status && o.status !== 'custom')}
              assets={this.state.assetsConfig}
              onSave={(l) => {
                this.handleLibrarySave(l)
                this.setState({ showImportLibraryDialog: !this.state.showImportLibraryDialog })
              }}
              onClose={() => this.setState({ showImportLibraryDialog: !this.state.showImportLibraryDialog })}
            />
            )
          : null}

        {this.state.showLibraryUpdateDialog
          ? (
            <DialogGeneric
              title='Update Presentation'
              content={
                <Box>
                  <Stack>
                    <Typography variant='body1' sx={{ pb: 2 }}>
                      You are about to update this solution: <strong>{this.state.solutionsList[this.state.libraryUpdateIndex].title}</strong>.
                    </Typography>
                    <Typography variant='body1' sx={{ pb: 2 }}>
                      By updating the solution, you will replace the solution in its current state.
                    </Typography>
                    <Typography variant='body1'>
                      If you have modified the solution, it is encouraged that you document any custom changes so that you can update the solution.
                    </Typography>
                  </Stack>
                </Box>
            }
              fullWidth
              onChange={this.handleLibraryUpdate}
              onClose={() => this.setState({ showLibraryUpdateDialog: !this.state.showLibraryUpdateDialog })}
              titleDone='Update'
              titleClose='Cancel'
            />
            )
          : null}

        <Box sx={{ pt: 1 }}>
          <Button variant='contained' color='success' onClick={() => this.handleAdd()} startIcon={<AddIcon />}>
            Add Solution
          </Button>
        </Box>
      </>
    )
  }
}

export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} editorComponent={CompanyConfigSolutions} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}
