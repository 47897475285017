import Api, { ApiPlugin } from 'library/Api'
import { Topics } from 'config/topics'
import Company from '../../screens/Company'

export class ApiPluginAppointments extends ApiPlugin {
  /**
     *
     * @param {ApiBase} apiBase
     * @param {Object} props
     */
  constructor (apiBase, props) {
    super(apiBase, props)

    if (props && props.baseUrl) {
      this.baseUrl = props.baseUrl
    }

    apiBase.registerMethod('getAppointments', this.getAppointments.bind(this))
  }

  /**
     *
     * @param {object} props
     * @param {string} [props.organizationId] Optional organization ID, with fallback to currently selected org.
     * @param {string} [props.topic] Topic on which to publish response from server.
     * @return {Promise<ResponseEnvelope>}
     */
  getAppointments (props = {}) {
    const route = Api.resolvePath(
      this.getBaseUrl() + '/org/:organizationId/appointments',
      { organizationId: props.organizationId ? props.organizationId : Company.getCurrentOrgId() }
    )

    return this.getApiBase().get(route).then(
      (rsp) => {
        // no local storage, callback and publishing options
        if (props && props.callback) {
          props.callback(rsp.data[0].list)
        }
        if (props && props.topic) {
          Topics.publish(props.topic, rsp.data[0].list)
        }
        return rsp.data[0].list
      }
    )
  }
}
