import React, { useEffect, useState } from 'react'
import { Box, Button, Link, Paper, Stack, Typography } from '@mui/material'
import Api from 'library/Api'
import CompanyConfigEdit from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import { LoadingPleaseWait } from '@supportworks/react-components'

const config = {
  title: 'Billing Details',
  variant: 'billingDetails',
  tag: 'billingDetails',
  name: 'Billing Details',
  lockTag: true,
  lockName: true,
  lockTitle: true
}

const CompanyConfigBillingDetails = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isStripeCustomer, setIsStripeCustomer] = useState(false)
  const [stripeUrl, setStripeUrl] = useState('')
  /**
  const [config, setConfig] = useState({});
  const [contentJson, setContentJson] = useState({});
  const [originalSettings, setOriginalSettings] = useState({});
  const [organizationId, setOrganizationId] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [plan, setPlan] = useState('');
  **/

  useEffect(() => {
    Api.callRegisteredMethod('stripePortal', { redirectUrl: window.location.href }).then((data) => {
      if (data && data.url) {
        setStripeUrl(data.url)
        setIsStripeCustomer(true)
      }
      if (props.config && props.config.contentJson) {
        /**
        let c = JSON.parse(props.config.contentJson);
        setConfig(props.config);
        setOrganizationId(props.config.organizationId);
        if (!c.plan) {
          c.plan = 'standard';
          props.onChange(JSON.stringify(c));
        }
        setPlan(c.plan);
        setContentJson(c);
        setOriginalSettings(c);
        **/
        setIsLoading(false)
      }
    })
    // eslint-disable-next-line
  }, [])

  /** Temporary commented out until we have billing plans
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let c = JSON.parse(JSON.stringify(contentJson));
    if (name === 'plan') {
      setPlan(value);
      c.plan = value;
    }
    if (props.onChange) {
      props.onChange(JSON.stringify(c));
    }
  };
  **/

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Paper elevation={0} sx={{ mb: 2, pt: 2 }}>
        {isStripeCustomer
          ? (
            <Box sx={{ pb: 2 }}>
              <Typography variant='body2' sx={{ pb: 2 }}>
                Billing processing is handled with a 3rd party. Click the link below to update billing preferences.
              </Typography>
              <Link href={stripeUrl}>
                <Button variant='contained'>Update Billing Details</Button>
              </Link>
            </Box>
            )
          : null}

        <Box>
          <Stack>
            <Typography variant='body1' sx={{ pb: 2, fontWeight: 'bold' }}>
              How does billing work?
            </Typography>
            <Typography variant='body2'>
              SolutionView is billed on a per user basis. Each month invoices will be sent and the card on file will be charged for each licensed sales user. To
              modify licenses, visit the Company{' '}
              <Link href={'/Company/' + props.organizationId + '/Users'} underline='none'>
                {' '}
                Users{' '}
              </Link>{' '}
              configuration page.
            </Typography>
          </Stack>
        </Box>
      </Paper>

      {/*
      <Paper elevation={0} sx={{p: 2}}>
        <Box sx={{pb: 2}}>
          <Stack>
            <Typography variant="h6">Plan Details</Typography>
            <Typography variant="body2">There are two plans available today. Standard and Premium. Select between the two.</Typography>
          </Stack>
        </Box>
        <Box sx={{pb: 2}}>
          <Box sx={{ mb: 2, p: 2, border: '1px solid rgba(0,0,0,.16)' }}>
            <FormControl>
              <RadioGroup aria-labelledby="boolean" name="boolean" onChange={handleChange}>
                <FormControlLabel value="standard" control={<Radio name='plan' checked={plan === 'standard' ? true : false} />}
                  label={
                    <Box>
                      <span style={styles.planTitle}>Standard Plan</span> <span style={styles.planDetails}>$50 per user / mo.</span><br/>
                      <Typography variant='body2'>
                        Custom Sales Workflow, and ability to integrate to 3rd party applications, including CRMs.
                      </Typography>
                    </Box>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box sx={{ p: 2, border: '1px solid rgba(0,0,0,.16)' }}>
            <FormControl>
              <RadioGroup aria-labelledby="boolean" name="boolean" onChange={handleChange}>
                <FormControlLabel value="premium" control={<Radio name='plan' checked={plan === 'premium' ? true : false} />}
                  label={
                    <Box>
                      <span style={styles.planTitle}>Premium Plan</span> <span style={styles.planDetails}>$75 per user / mo.</span><br/>
                      <Typography variant='body2'>
                        Everything from Standard Plan, plus Customer Portal.
                      </Typography>
                    </Box>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </Paper>
      */}
    </>
  )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} editorComponent={CompanyConfigBillingDetails} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}
