import React, { useEffect, useState } from 'react'
import FieldTypeExpandMarker from '../Reusables/FieldTypeExpandMarker'
import FieldTypeAddLogicPanel from '../FieldTypeAddLogicPanel'
// import AddLogic from '../AddLogic'
import {
  Card,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography,
  InputLabel,
  Select,
  Button, Grid, Box, InputAdornment
} from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import SelectorWithPreview from '../../../../components/Reusable/SelectorWithPreview'
import CompanyConfigModal from '../../CompanyConfigModal'

function FreeFormCapture (props) {
  const [field, setField] = useState(props.field)
  // const [width, setWidth] = useState('blankCanvas' in props.field ? props.field.blankCanvas.width : 0)
  // const [height, setHeight] = useState('blankCanvas' in props.field ? props.field.blankCanvas.height : 0)
  const [grid, setGrid] = useState('blankCanvas' in props.field ? props.field.blankCanvas.grid : false)
  const [printGrid, setPrintGrid] = useState('blankCanvas' in props.field ? props.field.blankCanvas.printGrid : false)
  const [type, setType] = useState('')
  const [openAssetSelector, setOpenAssetSelector] = useState(false)
  const [asset, setAsset] = useState({})

  const changeHandlerVisible = (visible) => {
    props.onChangeVisible(visible)
  }

  const changeHandlerJobInfo = (jobInfo) => {
    props.onChangeJobInfo(jobInfo)
  }

  const changeHandlerWidth = (event) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    newField.blankCanvas.width = Number(event.target.value)
    // setWidth(event.target.value)
    setField(newField)
    props.onChange(newField)
  }

  const changeHandlerHeight = (event) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    newField.blankCanvas.height = Number(event.target.value)
    // setHeight(event.target.value)
    setField(newField)
    props.onChange(newField)
  }

  const changeHandlerGrid = () => {
    const newField = JSON.parse(JSON.stringify(props.field))
    newField.blankCanvas.grid = Boolean(!grid)
    setGrid(!grid)
    setField(newField)
    props.onChange(newField)
  }

  const changeHandlerPrintGrid = () => {
    const newField = JSON.parse(JSON.stringify(props.field))
    newField.blankCanvas.printGrid = Boolean(!printGrid)
    setPrintGrid(!printGrid)
    setField(newField)
    props.onChange(newField)
  }

  const handleChangeType = (event) => {
    const newField = JSON.parse(JSON.stringify(props.field))

    if (event.target.value === 'blankCanvas') {
      newField.blankCanvas.use = true
      newField.blankCanvas.height = 1000
      newField.blankCanvas.width = 1000
      newField.imageCanvas.use = false
      newField.imageCanvas.asset = ''
    }
    if (event.target.value === 'imageCanvas') {
      newField.imageCanvas.use = true
      newField.blankCanvas.use = false
      newField.blankCanvas.width = 0
      newField.blankCanvas.height = 0
      newField.blankCanvas.grid = false
      newField.blankCanvas.printGrid = false
    }
    setType(event.target.value)
    setField(newField)
    props.onChange(newField)
  }

  const changeHandlerAsset = (asset) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    newField.imageCanvas.asset = asset.id
    setField(newField)
    setAsset(asset)
    props.onChange(newField)
  }

  const getAssetInfo = (id) => {
    const assetConfig = props.assetsConfig.filter((asset) => {
      return asset.id === id
    })
    if (assetConfig.length !== 0) {
      return assetConfig[0]
    } else {
      return id
    }
  }

  const deleteHandlerAsset = () => {
    const newField = JSON.parse(JSON.stringify(props.field))
    newField.imageCanvas.asset = ''
    setField(newField)
    props.onChange(newField)
  }

  useEffect(() => {
    const newField = JSON.parse(JSON.stringify(props.field))
    if (!('blankCanvas' in newField)) {
      newField.blankCanvas = {
        use: false,
        width: 1000,
        height: 1000,
        grid: false,
        printGrid: false
      }
    }

    if (!('imageCanvas' in newField)) {
      newField.imageCanvas = {
        use: false,
        asset: ''
      }
    }

    if ('imageCanvas' in newField && newField.imageCanvas.asset !== '') {
      const assetInfo = getAssetInfo(newField.imageCanvas.asset)
      setAsset(assetInfo)
      setType('imageCanvas')
    }

    if ('blankCanvas' in newField && newField.blankCanvas.use) {
      setType('blankCanvas')
    }

    setField(newField)
    props.onChange(newField)
  }, []) // eslint-disable-line

  /* useEffect(() => {
    // console.log(openAssetSelector);
  }) */

  return (
    <>
      {
                props.expandMarker &&
                  <FieldTypeExpandMarker />
            }

      <div style={styles.logicPanel}>
        <FieldTypeAddLogicPanel
          {...props}
          field={props.field}
          onChangeVisible={(visible) => changeHandlerVisible(visible)}
          onChangeJobInfo={(jobInfo) => changeHandlerJobInfo(jobInfo)}
        />

        <div style={{ display: 'flex' }}>
          <FieldTypeExpandMarker />
          <Paper elevation={0} square style={styles.addOptionPaper}>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id='select-type' style={{ backgroundColor: '#F2F2F2' }}>Select Type</InputLabel>
              <Select
                labelId='select-type'
                id='demo-simple-select'
                value={type}
                label='Select Type'
                onChange={handleChangeType}
                size='small'
                sx={{
                  width: '298px'
                }}
              >
                <MenuItem value='blankCanvas'>Blank Canvas</MenuItem>
                <MenuItem value='imageCanvas'>Image Canvas</MenuItem>
              </Select>
            </FormControl>

            {
                            type === 'blankCanvas' &&
                              <Card variant='outlined' style={styles.cardMain}>
                                <Typography variant='subtitle2' style={{ marginBottom: '16px' }}>Blank Canvas Setup</Typography>
                                <div style={{ marginBottom: '16px', display: 'flex' }}>
                                  <TextField
                                    id='outlined-basic'
                                    label='Width'
                                    variant='outlined'
                                    size='small'
                                    onChange={changeHandlerWidth}
                                    value={field.blankCanvas.width}
                                    type='tel'
                                    sx={{
                                      marginRight: '16px',
                                      width: '120px'
                                    }}
                                    InputLabelProps={{
                                      style: { backgroundColor: '#FFF' }
                                    }}
                                    InputProps={{
                                      endAdornment: <InputAdornment position='end'>px</InputAdornment>
                                    }}
                                  />

                                  <TextField
                                    id='outlined-basic'
                                    label='Height'
                                    variant='outlined'
                                    size='small'
                                    onChange={changeHandlerHeight}
                                    value={field.blankCanvas.height}
                                    type='tel'
                                    sx={{
                                      marginRight: '16px',
                                      width: '120px'
                                    }}
                                    InputLabelProps={{
                                      style: { backgroundColor: '#FFF' }
                                    }}
                                    InputProps={{
                                      endAdornment: <InputAdornment position='end'>px</InputAdornment>
                                    }}
                                  />
                                </div>
                                <div>
                                  <FormControlLabel
                                    name='Grid'
                                    control={
                                      <Switch
                                        checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                                        id='tax'
                                                // size={"small"}
                                        checked={field.blankCanvas.grid}
                                        color='primary'
                                        onClick={() => changeHandlerGrid()}
                                      />
                                        }
                                    label={<Typography style={{ fontSize: '14px', marginRight: '8px' }}>Display Grid</Typography>}
                                    labelPlacement='start'
                                    sx={{ marginLeft: '0px' }}
                                  />
                                </div>
                                <div>
                                  <FormControlLabel
                                    name='Grid'
                                    control={
                                      <Switch
                                        checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                                        id='tax'
                                                // size={"small"}
                                        checked={field.blankCanvas.printGrid}
                                        color='primary'
                                        onClick={() => changeHandlerPrintGrid()}
                                      />
                                        }
                                    label={<Typography style={{ fontSize: '14px', marginRight: '8px' }}>Print Grid</Typography>}
                                    labelPlacement='start'
                                    sx={{ marginLeft: '0px' }}
                                  />
                                </div>
                              </Card>
                        }

            {
                            type === 'imageCanvas' && field.imageCanvas.asset === '' &&
                              <Card variant='outlined' style={styles.cardMain}>
                                <Button
                                  style={styles.btnVideo}
                                  onClick={() => {
                                    setOpenAssetSelector(!openAssetSelector)
                                  }}
                                >
                                  <AddPhotoAlternateIcon
                                    style={styles.playIcon}
                                    fontSize='large'
                                    color='primary'
                                  />
                                  Choose Image from Assets
                                </Button>
                              </Card>
                        }

            {
                            type === 'imageCanvas' && field.imageCanvas.asset !== '' &&
                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item sx={{ pr: 2 }}>
                                    <Box style={styles.logo}>
                                      {asset.type === 'Image' && <img src={asset.previewURL} alt='asset' className='selected-Asset' style={{ maxHeight: '135px' }} />}
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box style={{ margin: '12px' }}>
                                      <code>{asset.title}</code>
                                    </Box>
                                    <div style={{ paddingTop: '50px' }}>
                                      <span>
                                        <Button id='asset-action' className='mui-btn' variant='contained' color='primary' onClick={() => { setOpenAssetSelector(!openAssetSelector) }}>
                                          Edit Logo
                                        </Button>
                                      </span>
                                      <span style={{ marginLeft: '40px', marginBottom: '10px' }}>
                                        <Button id='asset-action' className='mui-btn' variant='contained' color='error' onClick={() => deleteHandlerAsset()}>
                                          Remove
                                        </Button>
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            /* <Card variant={"outlined"} style={styles.cardMain}>
                            </Card> */
                        }

            {
                            openAssetSelector &&
                              <CompanyConfigModal
                                open={openAssetSelector}
                                cancel={() => {
                                  setOpenAssetSelector(!openAssetSelector)
                                }}
                                header='Select Image'
                                update={() => {
                                  setOpenAssetSelector(!openAssetSelector)
                                }}
                                body={
                                  <SelectorWithPreview
                                    selectedConfig={field}
                                    fieldName='asset'
                                    config={props.assetsConfig}
                                    assetType={['Image']}
                                    onUpdate={(asset) => changeHandlerAsset(asset)}
                                  />
                                }
                              />
                        }
          </Paper>
        </div>
      </div>

    </>
  )
}

const styles = {
  addOptionPaper: {
    borderTop: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    borderLeft: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F2F2F2',
    display: 'inlineBlock',
    alignItems: 'center',
    // marginRight: "96.19px",
    // width: "100%",
    // height: "56px"
    flexBasis: '100%',
    padding: '20px 20px 20px 20px'
  },
  cardMain: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '20px',
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingRight: '20px',
    maxWidth: '298px'
  },
  logicPanel: {
    // width: "100%"
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%'
  },
  btnVideo: {
    // display: "flex",
    flexWrap: 'wrap',
    flexDirection: 'column'
  },
  playIcon: {
    marginBottom: '15px'
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '140px',
    width: '298px',
    border: '1px solid #ced4da',
    borderRadius: '.25rem',
    backgroundColor: 'rgba(0,0,0,.02)'
  }
}

export default FreeFormCapture
