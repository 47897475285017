import React, { useState, useEffect, useRef } from 'react'
import { Box, Typography } from '@mui/material' // eslint-disable-line
import ImageIcon from '@mui/icons-material/Image'
import CheckIcon from '@mui/icons-material/WhereToVote'
import TargetIcon from '@mui/icons-material/Adjust'
import { LoadingPleaseWait } from '@supportworks/react-components'
import Permissions from 'library/Permissions' // eslint-disable-line
import { getAssetInfo } from 'screens/Config/CompanyConfigPresentations'

const MINIMUM_IMAGE_ASSET_SIZE = 200

const Canvas = ({ assets, presentation, field, onFieldSelect, onDelete, setPresentation }) => {
  const [isLoading, setIsLoading] = useState(true)

  // Asset config information for the current presentation poster
  const [asset, setAsset] = useState(null)

  // Used for tracking mouse movement to drop, when placing LeftPanel elements on the CenterCanvas.
  const [dropX, setDropX] = useState(-1)
  const [dropY, setDropY] = useState(-1)

  // Full width x height of page
  const [pageOffsetLeft, setPageOffsetLeft] = useState(-1)
  const [pageOffsetTop, setPageOffsetTop] = useState(-1)

  // Full width x height of right panel canvas
  const ref = useRef(null)
  const [canvasWidth, setCanvasWidth] = useState(-1) // eslint-disable-line
  const [canvasHeight, setCanvasHeight] = useState(-1) // eslint-disable-line

  // Full width x height of image
  const imgRef = useRef(null)
  const [imageWidth, setImageWidth] = useState(-1) // eslint-disable-line
  const [imageHeight, setImageHeight] = useState(-1) // eslint-disable-line
  const [ratio, setRatio] = useState(1) // x & y multiplier

  // Used for tracking moving and editing fields.
  const [selectionIndex, setSelectionIndex] = useState(-1) // field.overlay index # used for dragging/selecting.
  const [selectionVariant, setSelectionVariant] = useState(null) // icon or image

  const [isMovingField, setIsMovingField] = useState(false)
  const [isMovingVertex, setIsMovingVertex] = useState(false)
  const [isMovingVertexVariant, setIsMovingVertexVariant] = useState(null)
  const [lastX, setLastX] = useState(-1)
  const [lastY, setLastY] = useState(-1)

  // Main useEffect that fires on page load
  useEffect(() => {
    if (isLoading) {
      function handleResize () {
        if (ref && ref.current) {
          setCanvasWidth(ref.current.offsetWidth)
          setCanvasHeight(ref.current.offsetHeight)
          setPageOffsetLeft(ref.current.offsetLeft)
          setPageOffsetTop(ref.current.offsetTop)
          if (imgRef && imgRef.current) {
            setImageWidth(imgRef.current.clientWidth)
            setImageHeight(imgRef.current.clientHeight)
            const r = imgRef.current.clientWidth / ref.current.offsetWidth
            setRatio(r)
            // console.log( `Window Resize Setting BG image ratio ${imgRef.current.clientWidth} x ${imgRef.current.clientHeight} / ${ref.current.offsetWidth} x ${ref.current.offsetHeight} = ${r}`, );
          }
        }
      }
      window.addEventListener('resize', handleResize)

      const a = getAssetInfo(presentation.poster, assets)
      if (a && a.previewURL) {
        setAsset(a)
        setIsLoading(false)
      }
    }
  }, []) // eslint-disable-line

  const handleBGImageLoad = (e) => {
    if (ref && ref.current) {
      setCanvasWidth(ref.current.offsetWidth)
      setCanvasHeight(ref.current.offsetHeight)
      setPageOffsetLeft(ref.current.offsetLeft)
      setPageOffsetTop(ref.current.offsetTop)
      if (imgRef && imgRef.current) {
        setImageWidth(imgRef.current.clientWidth)
        setImageHeight(imgRef.current.clientHeight)
        const r = imgRef.current.clientWidth / ref.current.offsetWidth
        setRatio(r)
        // console.log(`Setting ratio ${imgRef.current.clientWidth}w / ${ref.current.offsetWidth}w = ${r}`);
      }
    }
  }

  // handleDrag events are for dragging left panel to canvas.
  //
  const handleDragOver = (event) => {
    event.stopPropagation()
    event.preventDefault()
    const offsetX = event.nativeEvent.offsetX
    const offsetY = event.nativeEvent.offsetY
    setDropX(offsetX)
    setDropY(offsetY)
  }

  const handleDragLeave = (event) => {
    setDropX(-1)
    setDropY(-1)
  }

  const handleDragDrop = (event) => {
    // console.log(`handleDragDrop ${dropX} ${dropY}`);
    event.stopPropagation()
    const p = JSON.parse(JSON.stringify(presentation))
    const index = p.overlay.findIndex((o) => o.image.id === field.image.id)
    p.overlay[index].icon.x = dropX * ratio
    p.overlay[index].icon.y = dropY * ratio
    p.overlay[index].image.x = dropX * ratio + 56 * ratio
    p.overlay[index].image.y = dropY * ratio + 56 * ratio
    if (!p.overlay[index].image.width) {
      p.overlay[index].image.width = MINIMUM_IMAGE_ASSET_SIZE // Need to set this to something before loading the image because we do not have it.
    }
    setPresentation(p) // Force update of parent
  }

  // handleMouse events are for moving objects inside the canvas.
  //
  const handleMouseDown = (idx, variant) => {
    // console.log(`START move ${idx} ${variant}`);
    setIsMovingField(true)
    setSelectionIndex(idx)
    setSelectionVariant(variant)
  }

  const handleMouseUp = (e) => {
    // console.log(`END move ${selectionIndex} ${selectionVariant}`);
    setIsMovingField(false)
    setIsMovingVertex(false)
    setLastX(-1)
    setLastY(-1)
  }

  const handleMouseMove = (e) => {
    const xPos = e.pageX + pageOffsetLeft
    const yPos = e.pageY + pageOffsetTop

    if (isMovingVertex) {
      if (lastX > -1) {
        const xo = (lastX - xPos) * ratio
        const yo = (lastY - yPos) * ratio
        console.log(`moving vertex ${selectionIndex} `)
        if (selectionIndex > -1) {
          const p = JSON.parse(JSON.stringify(presentation))
          const f = p.overlay[selectionIndex].image
          if (isMovingVertexVariant === 'TR') {
            // x doesn't move, y moves, bottom doesn't move
            f.y = f.y - yo
            f.width = Math.round(f.height * f.aspectRatio)
            f.height = f.height + yo
          }
          if (isMovingVertexVariant === 'BR') {
            // x & y do not move on resize
            f.width = f.width - xo
            f.height = f.width / f.aspectRatio
          }
          setPresentation(p) // Force update of parent
        }
      }
      setLastX(xPos)
      setLastY(yPos)
    }

    if (isMovingField && !isMovingVertex) {
      const xo = (lastX - xPos) * ratio
      const yo = (lastY - yPos) * ratio
      if (lastX > -1) {
        if (selectionIndex > -1) {
          const p = JSON.parse(JSON.stringify(presentation))
          const f = p.overlay[selectionIndex][selectionVariant]
          let x = f.x - xo
          let y = f.y - yo
          // Do not allow dragging off the left
          if (x < 24) x = 1
          if (y < 24) y = 1
          // console.log(`MOVING to ${x} ${y}  ${selectionIndex} ${selectionVariant} IMAGE: ${imageWidth} PAGE X,Y: ${e.pageX} x ${e.pageY}  xo: ${xo} yo: ${yo} lastX: ${lastX} lastY: ${lastY}`);
          f.x = x
          f.y = y
          setPresentation(p) // Force update of parent
        }
      }
      setLastX(xPos)
      setLastY(yPos)
    }
  }

  const handleMoveVertex = (idx, variant) => {
    // console.log(`START move vertex ${idx} ${variant}`);
    // setSelectionIndex(idx);
    setIsMovingVertex(true)
    setIsMovingVertexVariant(variant)
  }

  const handleReset = (e) => {
    if (e && e.target.id === 'parent') {
      setSelectionIndex(-1)
      setSelectionVariant(null)
      setIsMovingField(false)
      onFieldSelect({})
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  let jsx
  try {
    jsx = (
      <div>
        <div
          ref={ref}
          id='parent'
          onDrop={handleDragDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseDown={handleReset}
          style={{
            margin: 'auto',
            minHeight: '100em',
            backgroundImage: isMovingField
              ? `linear-gradient(to bottom, rgba(255,255,255,.2), rgba(255,255,255,.2)), url(${asset.path})`
              : `url(${asset.path})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'top'
          }}
        >
          {presentation.overlay.map((f, idx) => {
            return (
              <div key={`plotted-item-${idx}`}>
                <Plotter
                  idx={idx}
                  presentation={presentation}
                  setPresentation={setPresentation}
                  selectionIndex={selectionIndex}
                  selectionVariant={selectionVariant}
                  field={f}
                  ratio={ratio}
                  color={presentation.iconColor ? presentation.iconColor : 'black'}
                  bgColor={presentation.backgroundColor ? presentation.backgroundColor : 'black'}
                  assets={assets}
                  pageOffsetLeft={pageOffsetLeft}
                  pageOffsetTop={pageOffsetTop}
                  isMovingField={isMovingField}
                  isMovingVertexVariant={isMovingVertexVariant}
                  onMouseDown={handleMouseDown}
                  onMoveVertex={handleMoveVertex}
                />
              </div>
            )
          })}
        </div>
        <Box style={styles.hiddenContainer}>
          <img ref={imgRef} src={asset.path} onLoad={handleBGImageLoad} alt='hidden' />
        </Box>
        {/**
        {Permissions.hasRole('super_user') ? (
          <DebugInfo
            x={dropX}
            y={dropY}
            field={field}
            canvasWidth={canvasWidth}
            canvasHeight={canvasHeight}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            pageOffsetLeft={pageOffsetLeft}
            pageOffsetTop={pageOffsetTop}
          />
        ) : null}
        **/}
      </div>
    )
  } catch (err) {
    console.log(`Canvas error: ${err.stack}`)
    jsx = <div>Data is not available.</div>
  }
  return <div id='Canvas'>{jsx}</div>
}
export default Canvas

const Plotter = (props) => {
  if (!props.field.icon.x || !props.field.icon.y) return <></>

  // Figure out icon, size, and color, and icon.
  let icon
  let size = 50
  const tsize = props.field.icon.size || 'standard'
  if (tsize === 'large') size = 80
  if (tsize === 'xlarge') size = 110
  size = size / props.ratio

  if (props.field.icon.type === 'photo') {
    icon = <ImageIcon sx={{ fontSize: size, color: props.color, backgroundColor: props.bgColor }} />
  } else if (props.field.icon.type === 'checkmark') {
    icon = <CheckIcon sx={{ fontSize: size, color: props.color, borderRadius: '50%', backgroundColor: props.bgColor }} />
  } else if (props.field.icon.type === 'target') {
    icon = <TargetIcon sx={{ fontSize: size, color: props.color, borderRadius: '50%', backgroundColor: props.bgColor }} />
  }

  // Other shortcut variables to help code readability.
  const asset = getAssetInfo(props.field.image.id, props.assets)
  let selectedImage = false
  if (props.selectionIndex === props.idx && props.selectionVariant === 'image') {
    selectedImage = true
  }
  let selectedIcon = false
  if (props.selectionIndex === props.idx && props.selectionVariant === 'icon') {
    selectedIcon = true
  }
  let opacity = 1
  if (props.isMovingField && props.selectionIndex !== props.idx) {
    opacity = 0.1
  }

  let cursor = 'pointer'
  if (props.isMovingVertex) {
    cursor = 'se-resize'
  } else if (selectedImage || selectedIcon) {
    cursor = 'move'
  }

  return (
    <div>
      <div
        key={`plotter-icon-${props.idx}`}
        onMouseDown={() => {
          props.onMouseDown(props.idx, 'icon')
        }}
        style={{
          cursor: selectedIcon ? 'move' : 'pointer',
          opacity
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: props.field.icon.y / props.ratio + props.pageOffsetTop,
            left: props.field.icon.x / props.ratio + props.pageOffsetLeft,
            border: selectedIcon ? '3px solid blue' : 'none',
            borderRadius: props.field.icon.type.match(/target|checkmark/) ? '50%' : 0
          }}
        >
          {icon}
        </div>
      </div>
      {asset && asset.path
        ? (
          <div
            key={`plotter-image-${props.idx}`}
            onMouseDown={() => {
              props.onMouseDown(props.idx, 'image')
            }}
            style={{
              cursor,
              opacity
            }}
          >
            <div
              key={`plotted-image-${props.idx}`}
              style={{
                position: 'absolute',
                top: props.field.image.y / props.ratio + props.pageOffsetTop,
                left: props.field.image.x / props.ratio + props.pageOffsetLeft,
                border: selectedImage ? '3px solid blue' : '1px solid rgba(0,0,0,.16)',
                width: props.field.image.width / props.ratio + (selectedImage ? 7 : 2)
              }}
            >
              <ImageAsset
                idx={props.idx}
                selectionIndex={props.selectionIndex}
                selectionVariant={props.selectionVariant}
                key={`ImageAsset-${props.idx}-${props.selectionIndex}`}
                presentation={props.presentation}
                setPresentation={props.setPresentation}
                asset={asset}
                isMovingField={props.isMovingField}
                ratio={props.ratio}
                onMoveVertex={props.onMoveVertex}
              />
            </div>
          </div>
          )
        : null}
    </div>
  )
}

const ImageAsset = ({ idx, selectionIndex, selectionVariant, presentation, setPresentation, asset, ratio, onMoveVertex }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [width, setWidth] = useState(-1)
  const [height, setHeight] = useState(-1)

  useEffect(() => {
    if (isLoading) {
      const i = presentation.overlay[idx].image
      if (!i.height || !i.width) {
        const img = new Image() // eslint-disable-line
        img.src = asset.path
        img.onload = () => {
          // Maximum 100 width until they expand...
          setWidth(MINIMUM_IMAGE_ASSET_SIZE)
          const h = img.height * (MINIMUM_IMAGE_ASSET_SIZE / img.width)
          setHeight(h)
          const p = JSON.parse(JSON.stringify(presentation))
          p.overlay[idx].image.width = MINIMUM_IMAGE_ASSET_SIZE
          p.overlay[idx].image.height = h
          p.overlay[idx].image.aspectRatio = img.width / img.height
          setPresentation(p) // Force update of parent
          setIsLoading(false)
        }
      } else {
        setWidth(i.width)
        setHeight(i.height)
        setIsLoading(false)
      }
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (idx > -1) {
      const i = presentation.overlay[idx].image
      setWidth(i.width)
      setHeight(i.height)
    }
  }, [presentation]) // eslint-disable-line

  const handleMoveVertex = (variant) => {
    onMoveVertex(idx, variant)
  }

  if (isLoading) return <LoadingPleaseWait />

  const w = width / ratio
  const h = height / ratio
  return (
    <Box key={`ImageAssetBox-${idx}`}>
      {idx === selectionIndex && selectionVariant === 'image'
        ? (
          <Vertice
            top={h - 9}
            left={w}
            fill='blue'
            onMouseDown={() => {
              handleMoveVertex('BR')
            }}
            variant='BR'
          />

          )
        : null}
      <img src={asset.path} width={w} height={h} style={{ pointerEvents: 'none', cursor: 'default' }} alt='draggable asset' />
    </Box>
  )
}

export const Vertice = (props) => {
  const cursor = 'se-resize'
  // <rect width={24} height={24} fill={props.fill} stroke={props.fill} />
  return (
    <div
      style={{
        position: 'absolute',
        top: props.top,
        left: props.left,
        cursor
      }}
      onMouseDown={props.onMouseDown}
    >
      <svg width={12} height={12}>
        <circle cx={6} cy={6} r={3} fill={props.fill} stroke={props.fill} />
      </svg>
    </div>
  )
}

/*
const DebugInfo = (props) => {
  return (
    <Box sx={{ p: 1, position: 'fixed', left: 0, bottom: 0, zIndex: 1, backgroundColor: 'rgba(0,0,0,.75)' }}>
      <Typography variant='body' sx={{ color: 'yellow' }}>
        DROP: {props.x},{props.y} | CANVAS IMAGE: {props.canvasWidth} x {props.canvasHeight} FULL IMAGE: {props.imageWidth} x {props.imageHeight} OFFSETS: left:
        {props.pageOffsetLeft} top:{props.pageOffsetTop}
      </Typography>
    </Box>
  )
}
*/

const styles = {
  hiddenContainer: {
    margin: 'auto',
    opacity: '0'
  }
}
