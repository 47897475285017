import React, { useEffect, useState } from 'react'
import DataGrid from 'components/Reusable/DataGrid'
import withScreenWrapper from '../../screens/withScreenWrapper'
import Company from '../Company'
import Api from '../../library/Api'
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select, Typography
} from '@mui/material'
import WhatshotIcon from '@mui/icons-material/Whatshot'
import { LoadingPleaseWait } from '@supportworks/react-components'
import Helper from '@supportworks/helper'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { BarChart, LineChart } from '@mui/x-charts'

const AppointmentsReport = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [start, setStart] = useState(null)
  const [end, setEnd] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [response, setResponse] = useState({})
  const [errorStartDate, setErrorStartDate] = useState(false)
  const [errorEndDate, setErrorEndDate] = useState(false)
  const [disposition, setDisposition] = useState('all')
  const [author, setAuthor] = useState('all')
  const [users, setUsers] = useState([])
  const [xRow, setXRow] = useState([])
  const [yCol1, setYCol1] = useState([])
  const [yCol2, setYCol2] = useState([])
  const [yCol3, setYCol3] = useState([])
  const [yCol4, setYCol4] = useState([])
  const [yCol5, setYCol5] = useState([])
  const [yCol6, setYCol6] = useState([])
  const [yCol7, setYCol7] = useState([])
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 })
  const [showCharts, setShowCharts] = useState(false)

  useEffect(() => {
    if (isLoading) {
      const currDate = new Date()
      const lastThirtyDays = new Date(new Date().setDate(currDate.getDate() - 30))

      setStart(dayjs(lastThirtyDays))
      setEnd(dayjs(currDate))

      getUsers()
      handleExecuteReport(lastThirtyDays, currDate)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const getUsers = () => {
    Api.callRegisteredMethod('getUsers').then((users) => {
      const authors = []
      for (let i = 0; i < users.persons.length; i++) {
        authors.push(users.persons[i].person)
      }

      setUsers(authors)
    })
  }

  const handleExecuteReport = (start, end) => {
    if (!errorStartDate && !errorEndDate) {
      start = Helper.formatDate(start, 'yyyy-mm-dd')
      end = Helper.formatDate(end, 'yyyy-mm-dd')

      Api.callRegisteredMethod('getSummarizedAppointments', { start, end }).then((rsp) => {
        // console.log(rsp, author, disposition)
        if (author === 'all' && disposition === 'all') {
          const r = {}
          for (const key of Object.keys(rsp)) {
            const averageProjects = rsp[key].numberOfProjects / rsp[key].numberOfAppointments
            r[key] = {
              ADL: rsp[key].ADL,
              ADS: rsp[key].ADS,
              firstNameAuthor: rsp[key].firstNameAuthor,
              lastNameAuthor: rsp[key].lastNameAuthor,
              percentClosing: rsp[key].percentClosing,
              numberOfAppointments: rsp[key].numberOfAppointments,
              averageProjects,
              percentFinancing: rsp[key].percentFinancing,
              percentDiscounts: rsp[key].percentDiscounts
            }
          }
          setResponse(r)
          generateChartDataSet(r, paginationModel)
        }
        if (author === 'all' && disposition !== 'all') {
          const r = {}
          if (disposition === 'pending') {
            for (const key of Object.keys(rsp)) {
              let appts, avgNumProjects, apptsDiscounts, apptsFinancing
              if ('Pending' in rsp[key].dispositions) {
                appts = rsp[key].dispositions.Pending.numberOfAppointments

                if ('averageProjects' in rsp[key].dispositions.Pending) {
                  avgNumProjects = rsp[key].dispositions.Pending.averageProjects
                }
                if ('percentFinancing' in rsp[key].dispositions.Pending) {
                  apptsFinancing = rsp[key].dispositions.Pending.percentFinancing
                }
                if ('percentDiscounts' in rsp[key].dispositions.Pending) {
                  apptsDiscounts = rsp[key].dispositions.Pending.percentDiscounts
                }
              }
              r[key] = {
                ADL: rsp[key].ADL,
                ADS: rsp[key].ADS,
                firstNameAuthor: rsp[key].firstNameAuthor,
                lastNameAuthor: rsp[key].lastNameAuthor,
                percentClosing: rsp[key].percentClosing,
                numberOfAppointments: appts,
                averageProjects: avgNumProjects,
                percentFinancing: apptsFinancing,
                percentDiscounts: apptsDiscounts
              }
            }
          }
          if (disposition === 'sold') {
            for (const key of Object.keys(rsp)) {
              let appts, avgNumProjects, apptsDiscounts, apptsFinancing
              if ('Sold' in rsp[key].dispositions) {
                appts = rsp[key].dispositions.Sold.numberOfAppointments
                if ('averageProjects' in rsp[key].dispositions.Sold) {
                  avgNumProjects = rsp[key].dispositions.Sold.averageProjects
                }
                if ('percentFinancing' in rsp[key].dispositions.Sold) {
                  apptsFinancing = rsp[key].dispositions.Sold.percentFinancing
                }
                if ('percentDiscounts' in rsp[key].dispositions.Sold) {
                  apptsDiscounts = rsp[key].dispositions.Sold.percentDiscounts
                }
              }
              r[key] = {
                ADL: rsp[key].ADL,
                ADS: rsp[key].ADS,
                firstNameAuthor: rsp[key].firstNameAuthor,
                lastNameAuthor: rsp[key].lastNameAuthor,
                percentClosing: rsp[key].percentClosing,
                numberOfAppointments: appts,
                averageProjects: avgNumProjects,
                percentFinancing: apptsFinancing,
                percentDiscounts: apptsDiscounts
              }
            }
          }
          setResponse(r)
          generateChartDataSet(r, paginationModel)
        }
        if (author !== 'all' && disposition === 'all') {
          const r = {}
          for (const key of Object.keys(rsp)) {
            if (key === author.toString()) {
              const averageProjects = rsp[key].numberOfProjects / rsp[key].numberOfAppointments
              r[key] = {
                ADL: rsp[key].ADL,
                ADS: rsp[key].ADS,
                firstNameAuthor: rsp[key].firstNameAuthor,
                lastNameAuthor: rsp[key].lastNameAuthor,
                percentClosing: rsp[key].percentClosing,
                numberOfAppointments: rsp[key].numberOfAppointments,
                averageProjects,
                percentFinancing: rsp[key].percentFinancing,
                percentDiscounts: rsp[key].percentDiscounts
              }
            } else {
              setResponse({})
              continue
            }
          }
          setResponse(r)
          generateChartDataSet(r, paginationModel)
        }
        if (author !== 'all' && disposition !== 'all') {
          // console.log(rsp, author, disposition)
          if (disposition === 'pending') {
            const r = {}
            for (const key of Object.keys(rsp)) {
              if (key === author.toString()) {
                let appts, avgNumProjects, apptsDiscounts, apptsFinancing
                if ('Pending' in rsp[key].dispositions) {
                  appts = rsp[key].dispositions.Pending.numberOfAppointments

                  if ('averageProjects' in rsp[key].dispositions.Pending) {
                    avgNumProjects = rsp[key].dispositions.Pending.averageProjects
                  }
                  if ('percentFinancing' in rsp[key].dispositions.Pending) {
                    apptsFinancing = rsp[key].dispositions.Pending.percentFinancing
                  }
                  if ('percentDiscounts' in rsp[key].dispositions.Pending) {
                    apptsDiscounts = rsp[key].dispositions.Pending.percentDiscounts
                  }
                }
                r[key] = {
                  ADL: rsp[key].ADL,
                  ADS: rsp[key].ADS,
                  firstNameAuthor: rsp[key].firstNameAuthor,
                  lastNameAuthor: rsp[key].lastNameAuthor,
                  percentClosing: rsp[key].percentClosing,
                  numberOfAppointments: appts,
                  averageProjects: avgNumProjects,
                  percentFinancing: apptsFinancing,
                  percentDiscounts: apptsDiscounts
                }
              } else {
                setResponse({})
                continue
              }
            }
            setResponse(r)
            generateChartDataSet(r, paginationModel)
          }
          if (disposition === 'sold') {
            const r = {}
            for (const key of Object.keys(rsp)) {
              if (key === author.toString()) {
                let appts, avgNumProjects, apptsDiscounts, apptsFinancing
                if ('Sold' in rsp[key].dispositions) {
                  appts = rsp[key].dispositions.Sold.numberOfAppointments
                  if ('averageProjects' in rsp[key].dispositions.Sold) {
                    avgNumProjects = rsp[key].dispositions.Sold.averageProjects
                  }
                  if ('percentFinancing' in rsp[key].dispositions.Sold) {
                    apptsFinancing = rsp[key].dispositions.Sold.percentFinancing
                  }
                  if ('percentDiscounts' in rsp[key].dispositions.Sold) {
                    apptsDiscounts = rsp[key].dispositions.Sold.percentDiscounts
                  }
                }
                r[key] = {
                  ADL: rsp[key].ADL,
                  ADS: rsp[key].ADS,
                  firstNameAuthor: rsp[key].firstNameAuthor,
                  lastNameAuthor: rsp[key].lastNameAuthor,
                  percentClosing: rsp[key].percentClosing,
                  numberOfAppointments: appts,
                  averageProjects: avgNumProjects,
                  percentFinancing: apptsFinancing,
                  percentDiscounts: apptsDiscounts
                }
              } else {
                setResponse({})
                continue
              }
            }
            setResponse(r)
            generateChartDataSet(r, paginationModel)
          }
        }
      })
    }
  }

  const handleChangeDateStart = (e) => {
    setStart(dayjs(e.$d))
  }

  const handleChangeDateEnd = (e) => {
    setEnd(dayjs(e.$d))
  }

  const handleChangeDisposition = (event) => {
    setDisposition(event.target.value)
  }

  const handleChangeAuthor = (event) => {
    setAuthor(event.target.value)
  }

  const handleOnPaginationModelChange = (model, details) => {
    setPaginationModel(model)
    generateChartDataSet(response, model)
  }

  const generateChartDataSet = (response, model) => {
    const responseArr = []
    for (const key of Object.keys(response)) {
      responseArr.push(response[key])
    }

    if (responseArr.length > 0) {
      const newXRow = []
      const newYCol1 = []
      const newYCol2 = []
      const newYCol3 = []
      const newYCol4 = []
      const newYCol5 = []
      const newYCol6 = []
      const newYCol7 = []

      /* eslint no-unreachable-loop: ["error", { "ignore": ["ForStatement"] }] */
      for (let i = model.page * model.pageSize; i < responseArr.length; i += model.pageSize) {
        const batch = responseArr.slice(i, i + model.pageSize)

        for (let j = 0; j < batch.length; j++) {
          newXRow.push(batch[j].lastNameAuthor + ', ' + batch[j].firstNameAuthor)
          newYCol1.push(batch[j].numberOfAppointments)
          newYCol2.push(batch[j].ADL)
          newYCol3.push(batch[j].ADS)
          newYCol4.push(batch[j].percentClosing)
          newYCol5.push(batch[j].averageProjects)
          newYCol6.push(batch[j].percentFinancing)
          newYCol7.push(batch[j].percentDiscounts)
        }
        break
      }
      setXRow(newXRow)
      setYCol1(newYCol1)
      setYCol2(newYCol2)
      setYCol3(newYCol3)
      setYCol4(newYCol4)
      setYCol5(newYCol5)
      setYCol6(newYCol6)
      setYCol7(newYCol7)
      setShowCharts(true)
    } else {
      setShowCharts(false)
    }
  }

  const columns = [
    {
      flex: 1,
      type: 'string',
      field: 'salesRep',
      headerName: 'Sales Rep',
      editable: false,
      sortable: true
    },
    {
      flex: 1,
      type: 'number',
      field: 'appts',
      headerName: 'Appts',
      editable: false,
      sortable: true,
      align: 'right',
      headerAlign: 'right',
      renderCell: params => {
        const formattedValue = (params.value && params.value !== null) ? `${Number(params.value)}` : Number(0)
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>{formattedValue}</Box>
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'adl',
      headerName: 'ADL',
      editable: false,
      sortable: true,
      align: 'right',
      headerAlign: 'right',
      renderCell: params => {
        const formattedValue = params.value !== null ? `$${Number(params.value).toFixed(2)}` : '$0.00'
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>{formattedValue}</Box>
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'ads',
      headerName: 'ADS',
      editable: false,
      sortable: true,
      align: 'right',
      headerAlign: 'right',
      renderCell: params => {
        const formattedValue = params.value !== null ? `$${Number(params.value).toFixed(2)}` : '$0.00'
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>{formattedValue}</Box>
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'closing',
      headerName: 'Closing %',
      editable: false,
      sortable: true,
      align: 'right',
      headerAlign: 'right',
      renderCell: params => {
        const formattedValue = params.value != null ? `${Number(params.value).toFixed(2)}%` : '0%'
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>{formattedValue}</Box>
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'avgNumProjects',
      headerName: 'Avg # of Projects',
      editable: false,
      sortable: true,
      align: 'right',
      headerAlign: 'right',
      renderCell: params => {
        const formattedValue = (params.value && params.value !== null) ? `${Number(params.value).toFixed(2)}` : '0.00'
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>{formattedValue}</Box>
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'apptsFinancing',
      headerName: 'Appts w/Financing',
      editable: false,
      sortable: true,
      align: 'right',
      headerAlign: 'right',
      renderCell: params => {
        const formattedValue = params.value != null ? `${Number(params.value).toFixed(2)}%` : '0%'
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>{formattedValue}</Box>
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'apptsDiscounts',
      headerName: 'Appts w/Discounts',
      editable: false,
      sortable: true,
      align: 'right',
      headerAlign: 'right',
      renderCell: params => {
        const formattedValue = params.value != null ? `${Number(params.value).toFixed(2)}%` : '0%'
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>{formattedValue}</Box>
      }
    }
  ]

  const rows = []
  // console.log(response, disposition)
  for (const key of Object.keys(response)) {
    const salesRep = response[key].lastNameAuthor + ', ' + response[key].firstNameAuthor
    const appts = response[key].numberOfAppointments
    const adl = response[key].ADL
    const ads = response[key].ADS
    const closing = response[key].percentClosing
    const avgNumProjects = response[key].averageProjects
    const apptsFinancing = response[key].percentFinancing
    const apptsDiscounts = response[key].percentDiscounts

    rows.push({
      id: key,
      salesRep,
      appts,
      adl,
      ads,
      closing,
      avgNumProjects,
      apptsFinancing,
      apptsDiscounts
    })
  }

  if (isLoading) {
    return <LoadingPleaseWait />
  }

  return (
    <Box>
      <Company.Header title='Sales Representative ADL / ADS Summary' />
      <Box sx={{ mb: 3, mt: 3 }}>
        <Grid container spacing={1} alignItems='flex-end'>
          <Grid item sx={{ width: '220px' }}>
            <FormControl size='large' fullWidth>
              <InputLabel>Sales Rep</InputLabel>
              <Select
                label='Sales Rep'
                onChange={handleChangeAuthor}
                value={author}
              >
                <MenuItem key='all' value='all'>All</MenuItem>
                {users.map((user, idx) => {
                  return (
                    <MenuItem key={idx} value={user.id}>{user.nameFirst + ' ' + user.nameLast}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sx={{ width: '220px' }}>
            <FormControl size='large' fullWidth>
              <InputLabel>Result Detail</InputLabel>
              <Select
                label='Result Detail'
                onChange={handleChangeDisposition}
                value={disposition}
              >
                <MenuItem key='all' value='all'>All</MenuItem>
                <MenuItem key='pending' value='pending'>Pending</MenuItem>
                <MenuItem key='sold' value='sold'>Sold</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='Start Date'
                name='startDate'
                value={start}
                onChange={handleChangeDateStart}
                onError={(reason, value) => {
                  if (reason) {
                    setErrorStartDate(true)
                  } else {
                    setErrorStartDate(false)
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='End Date'
                name='endDate'
                value={end}
                onChange={handleChangeDateEnd}
                onError={(reason, value) => {
                  if (reason) {
                    setErrorEndDate(true)
                  } else {
                    setErrorEndDate(false)
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sx={{ marginLeft: 'auto' }}>
            <Button size='medium' variant='contained' color='primary' onClick={() => handleExecuteReport(start, end)} endIcon={<WhatshotIcon />}>
              <Typography variant='button' style={{ textTransform: 'uppercase' }}>Generate</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        onPaginationModelChange={(model, details) => {
          handleOnPaginationModelChange(model, details)
        }}
      />

      {
        showCharts &&
          <Box>
            <Grid container>
              <Grid item>
                <BarChart
                  width={700}
                  height={600}
                  series={[
                    { data: yCol1, label: 'Appointments', id: 'appts' },
                    { data: yCol5, label: 'Avg Projects', id: 'avg projects' }
                  ]}
                  xAxis={[{ data: xRow, scaleType: 'band' }]}
                  // layout="horizontal"
                  margin={{ bottom: 300 }}
                  slotProps={{
                    legend: {
                      direction: 'row',
                      position: { horizontal: 'middle', vertical: 'top' },
                      padding: 0
                    }
                  }}
                  sx={{ mt: 4 }}
                  bottomAxis={{
                    tickLabelStyle: {
                      angle: 45,
                      textAnchor: 'start',
                      fontSize: 12
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <LineChart
                  width={700}
                  height={600}
                  series={[
                    { data: yCol2, label: 'ADL', id: 'adl' },
                    { data: yCol3, label: 'ADS', id: 'ads' }
                  ]}
                  xAxis={[{ data: xRow, scaleType: 'point' }]}
                  // layout="horizontal"
                  margin={{ bottom: 300 }}
                  slotProps={{
                    legend: {
                      direction: 'row',
                      position: { horizontal: 'middle', vertical: 'top' },
                      padding: 0
                    }
                  }}
                  sx={{ mt: 4 }}
                  bottomAxis={{
                    tickLabelStyle: {
                      angle: 45,
                      textAnchor: 'start',
                      fontSize: 12
                    }
                  }}
                />
              </Grid>

              <Grid item>
                <LineChart
                  width={700}
                  height={600}
                  series={[
                    { data: yCol4, label: 'Closing %', area: true, id: 'closing' }
                  ]}
                  xAxis={[{ data: xRow, scaleType: 'point' }]}
                  // layout="horizontal"
                  margin={{ bottom: 300 }}
                  slotProps={{
                    legend: {
                      direction: 'row',
                      position: { horizontal: 'middle', vertical: 'top' },
                      padding: 0
                    }
                  }}
                  sx={{ mt: 4 }}
                  bottomAxis={{
                    tickLabelStyle: {
                      angle: 45,
                      textAnchor: 'start',
                      fontSize: 12
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <LineChart
                  width={700}
                  height={600}
                  series={[
                    { data: yCol6, label: '% with Financing', area: true, id: 'financing' },
                    { data: yCol7, label: '% with Discounts', area: true, id: 'discounts' }
                  ]}
                  xAxis={[{ data: xRow, scaleType: 'point' }]}
                  // layout="horizontal"
                  margin={{ bottom: 300 }}
                  slotProps={{
                    legend: {
                      direction: 'row',
                      position: { horizontal: 'middle', vertical: 'top' },
                      padding: 0
                    }
                  }}
                  sx={{ mt: 4 }}
                  bottomAxis={{
                    tickLabelStyle: {
                      angle: 45,
                      textAnchor: 'start',
                      fontSize: 12
                    }
                  }}
                />
              </Grid>
            </Grid>

            {/* <BarChart
              width={700}
              height={600}
              series={[
                { data: yCol1, label: 'Appointments', id: 'appts' },
                { data: yCol2, label: 'ADL', id: 'adl' },
                { data: yCol3, label: 'ADS', id: 'ads' },
                { data: yCol4, label: 'Closing', id: 'closing' },
                { data: yCol5, label: 'Avg Projects', id: 'avg projects' },
                { data: yCol6, label: 'Financing', id: 'financing' },
                { data: yCol7, label: 'Discounts', id: 'discounts' }
              ]}
              xAxis={[{ data: xRow, scaleType: 'band' }]}
              // layout="horizontal"
              margin={{ bottom: 300 }}
              slotProps={{
                legend: {
                  direction: 'row',
                  position: { horizontal: 'middle', vertical: 'top' },
                  padding: 0
                }
              }}
              sx={{ mt: 4 }}
              bottomAxis={{
                tickLabelStyle: {
                  angle: 45,
                  textAnchor: 'start',
                  fontSize: 12
                }
              }}
            /> */}

            <LineChart
              width={700}
              height={600}
              series={[
                { data: yCol1, label: 'Appointments', id: 'appts' },
                { data: yCol2, label: 'ADL', id: 'adl' },
                { data: yCol3, label: 'ADS', id: 'ads' },
                { data: yCol4, label: 'Closing', id: 'closing' },
                { data: yCol5, label: 'Avg Projects', id: 'avg projects' },
                { data: yCol6, label: 'Financing', id: 'financing' },
                { data: yCol7, label: 'Discounts', id: 'discounts' }
              ]}
              xAxis={[{ data: xRow, scaleType: 'point' }]}
              margin={{ bottom: 300 }}
              slotProps={{
                legend: {
                  direction: 'row',
                  position: { horizontal: 'middle', vertical: 'top' },
                  padding: 0
                }
              }}
              sx={{ mt: 4 }}
              bottomAxis={{
                tickLabelStyle: {
                  angle: 45,
                  textAnchor: 'start',
                  fontSize: 12
                }
              }}
            />
          </Box>
      }
    </Box>
  )
}

export default withScreenWrapper(AppointmentsReport)
