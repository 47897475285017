import React from 'react'

/**
 * ProductInfo()
 *
 * This component displays product or config information.  It accepts as arguments:
 *
 * - a single productMaster OR
 * - a product and either a productList or productConfig
 *
 * @param {*} param0
 * @returns
 */
const ProductInfo = ({ productMaster, product, productList = [], productConfig = [] }) => {
  let pm = productMaster
  if (!pm && product) {
    if (productList.length > 0) {
      pm = productList.find((p) => (p.id === product.id))
    }
    if (!pm && productConfig.length > 0) {
      pm = productConfig.find((p) => (p.id === product.id))
    }
  }

  if (!pm) return <></>
  return (
    <div className='sa-section'>
      <div className='sa-section'>
        <span className='sa-heading'><strong>{pm.title}</strong></span><br />
        <span className='sa-text12'>
          {product && product.type === 'productGroup'
            ? 'Product Group'
            : <>(SKU: {pm.id})</>}
        </span>
      </div>
      {pm.description
        ? (
          <div className='sa-indent'>
            {pm.description}
          </div>
          )
        : null}

    </div>
  )
}
export default ProductInfo
