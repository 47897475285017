import React, { useState } from 'react'
import { Divider, FormControlLabel, Menu, MenuItem, Switch, Tooltip, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import Permissions from 'library/Permissions'
import '../../../../css/forms.css'

function OptionsDialogSection (props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [locked, setLocked] = useState(props.locked)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (action) => {
    setAnchorEl(null)
    props.onDelete()
  }

  const handleChangeLocked = () => {
    setAnchorEl(null)
    setLocked(!locked)
    props.onChangeLocked(!locked)
  }

  return (
    <div style={styles.container}>
      <Tooltip title='Options' placement='top-start'>
        <IconButton className='section-icon' onClick={handleClick}>
          <MoreHorizIcon />
        </IconButton>
      </Tooltip>
      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem style={styles.optionsMenu} disabled={!props.onMoveUp} onClick={props.onMoveUp}>
          <span>
            <ArrowUpwardIcon />
          </span>
          Move Up
        </MenuItem>
        <MenuItem style={styles.optionsMenu} disabled={!props.onMoveDown} onClick={props.onMoveDown}>
          <span>
            <ArrowDownwardIcon />
          </span>
          Move Down
        </MenuItem>
        <MenuItem style={styles.optionsMenu} onClick={() => handleChange('delete')}>
          <span>
            <DeleteOutlineIcon />
          </span>
          Delete Question
        </MenuItem>
        {Permissions.hasRole('super_user') && props.fieldType === 'calc' && (
          <div>
            <Divider />
            <FormControlLabel
              sx={{ m: 0, width: '100%' }}
              value='start'
              control={
                <Switch
                  color='primary'
                  fontSize='small'
                  sx={{ alignItems: 'flex-end' }}
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' sx={{ alignItems: 'flex-end' }} />}
                  onChange={handleChangeLocked}
                  checked={locked}
                />
                            }
              label={
                <Typography style={{ color: '#000000a3', paddingLeft: '16px' }} variant='body2' sx={{ flex: 1 }}>
                  Locked
                </Typography>
                            }
              labelPlacement='start'
            />
          </div>
        )}
      </Menu>
    </div>
  )
}

const styles = {
  container: {
    margin: 'auto'
  },
  optionsMenu: {
    color: '#000000a3'
  }
}

export default OptionsDialogSection
