import React, { useState } from 'react'
import Chip from '@mui/material/Chip'
import HelpIcon from '@mui/icons-material/Help'
import NumbersRoundedIcon from '@mui/icons-material/NumbersRounded'

function QuestionChip (props) {
  // eslint-disable-next-line no-unused-vars
  const [elem, setElem] = useState(props.elem)

  const handleDelete = () => {
    props.onDelete(elem)
  }

  const handleSelectQuestion = () => {
    props.onSelectQuestion(elem)
  }
  // console.log(props);

  return (
    <Chip
      icon={'title' in elem ? <HelpIcon /> : <NumbersRoundedIcon />}
      label={'title' in elem ? elem.title : elem.val}
      variant='outlined'
      style={styles.chipMain}
      onClick={handleSelectQuestion}
      onDelete={handleDelete}
    />
  )
}

const styles = {
  chipMain: {
    background: '#fff',
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: '5px',
    marginRight: '5px'
  }
}

export default QuestionChip
