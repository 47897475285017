import React, { useState, useEffect } from 'react'
import { Menu, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'

const FieldAction = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [display, setDisplay] = useState(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    if (props.field && props.field.source) {
      setDisplay(props.source.display)
    }
  }, []) // eslint-disable-line

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDisplay = (d) => {
    let f = props.document.fields.find(o => o.editorId === props.field.id) // for nav
    if (typeof props.selectionIndex !== 'undefined' && props.selectionIndex > -1) { // for navmulti
      const v = props.field.list[props.selectionIndex]
      f = props.document.fields.find(o => o.editorId === props.field.id && o.title === v)
    }

    if (f !== undefined) {
      if (f.source) {
        f.source.display = d
      } else {
        f.source = { display: d }
      }
      props.onChange('field', f)
      setDisplay(d)
    }
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu id='action-menu' open={open} onClose={handleClose} anchorEl={anchorEl}>
        <MenuItem
          style={display === 'checkmark' ? { backgroundColor: 'rgba(0,0,0,.08)' } : null}
          onClick={() => {
            handleDisplay('checkmark')
            handleClose()
          }}
        >
          Checkmark
        </MenuItem>
        <MenuItem
          style={display === 'highlight' ? { backgroundColor: 'rgba(0,0,0,.08)' } : null}
          onClick={() => {
            handleDisplay('highlight')
            handleClose()
          }}
        >
          Highlight
        </MenuItem>
        <MenuItem
          style={display === 'values' ? { backgroundColor: 'rgba(0,0,0,.08)' } : null}
          onClick={() => {
            handleDisplay('values')
            handleClose()
          }}
        >
          Values
        </MenuItem>
      </Menu>
    </>
  )
}

export {
  FieldAction
}
