import React, { useEffect, useRef, useState } from 'react'
import FieldTypeExpandMarker from '../Reusables/FieldTypeExpandMarker'
import FieldTypeAddLogicPanel from '../FieldTypeAddLogicPanel'
import { Button, IconButton, Paper } from '@mui/material'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone'
import TextInputBasic from '../Reusables/TextInputBasic'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

function CheckList (props) {
  const [options, setOptions] = useState(props.list)
  // const [field, setField] = useState(props.field)
  const [optIndex, setOptIndex] = useState(null)
  const inputRefs = useRef([])

  const changeHandlerVisible = (visible) => {
    props.onChangeVisible(visible)
  }

  const addOption = () => {
    setOptions(options => [...options, 'newOption'])
  }

  const changeHandlerCRM = (crm) => {
    props.onChangeCRM(crm)
  }

  const changeHandler = (value, idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    const newOptionsArr = [...options]

    newOptionsArr[idx] = value
    setOptions(newOptionsArr)

    newField.list = newOptionsArr
    // setField(newField)

    props.onChange(newField)
  }

  const keyPressHandler = (event, idx) => {
    if (event.key === 'Enter') {
      if (inputRefs.current[idx + 1]) {
        inputRefs.current[idx + 1].focus()
      } else {
        setOptions(options => [...options, 'newOption'])
      }
    }
  }

  const deleteHandler = (idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    const newOptionsArr = [...options]

    newOptionsArr.splice(idx, 1)
    setOptions(newOptionsArr)
    setOptIndex(idx)

    newField.list = newOptionsArr

    // setField(newField)
    props.onChange(newField)
  }

  const handleMoveUp = (idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    const noptions = JSON.parse(JSON.stringify(options))
    if (idx > 0) {
      [noptions[idx], noptions[idx - 1]] = [noptions[idx - 1], noptions[idx]]
    }
    setOptions(noptions)
    newField.list = noptions
    // setField(newField)

    props.onChange(newField)
  }

  const handleMoveDown = (idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    const noptions = JSON.parse(JSON.stringify(options))
    if (idx < options.length) {
      [noptions[idx], noptions[idx + 1]] = [noptions[idx + 1], noptions[idx]]
    }
    setOptions(noptions)
    newField.list = noptions
    // setField(newField)

    props.onChange(newField)
  }

  useEffect(() => {
    if (optIndex) {
      inputRefs.current.splice(optIndex, 1)
      inputRefs.current[optIndex - 1].focus()
    } else {
      if (inputRefs.current[inputRefs.current.length - 1]) {
        inputRefs.current[inputRefs.current.length - 1].focus()
      }
    }
    // eslint-disable-next-line
  }, [options.length])

  return (
    <>
      {
                props.expandMarker &&
                  <FieldTypeExpandMarker />
            }
      <div style={styles.logicPanel}>
        <FieldTypeAddLogicPanel
          {...props}
          field={props.field}
          onChangeVisible={(visible) => changeHandlerVisible(visible)}
          onChangeCRM={(crm) => changeHandlerCRM(crm)}
        />

        {
                    options.map((item, idx) => {
                      return (
                        <div key={`checklist-main-${idx}`}>
                          <div style={{ display: 'flex' }} key={`checklist-opt-${idx}`}>
                            <FieldTypeExpandMarker />
                            <Paper elevation={0} square style={styles.singleSelectionPaper}>
                              <div style={styles.optionsMainDivIcon}>
                                <IconButton style={styles.addOptionIconBtn}>
                                  <CheckCircleTwoToneIcon style={styles.addOptionIcon} color='primary' />
                                </IconButton>
                              </div>
                              <div style={styles.optionsMainDiv}>
                                <TextInputBasic
                                  className={`checklist-text-${idx}`}
                                  id={`checklist-text-${idx}`}
                                  placeholder='Untitled Option'
                                  inputValue={item !== 'newOption' ? item : ''}
                                  onChange={(value) => changeHandler(value, idx)}
                                  onKeyPress={(event) => keyPressHandler(event, idx)}
                                  ref={ref => inputRefs.current[idx] = (ref)} // eslint-disable-line
                                />
                              </div>
                              <div style={styles.optionsMainDivIcon}>
                                <IconButton onClick={() => deleteHandler(idx)}>
                                  <DeleteOutlineIcon />
                                </IconButton>
                                <IconButton
                                  disabled={!(idx < options.length - 1)}
                                  onClick={() => {
                                    handleMoveDown(idx)
                                  }}
                                >
                                  <ArrowDownwardIcon />
                                </IconButton>
                                <IconButton
                                  disabled={!(idx > 0)} onClick={() => {
                                    handleMoveUp(idx)
                                  }}
                                >
                                  <ArrowUpwardIcon />
                                </IconButton>
                              </div>
                            </Paper>
                          </div>
                        </div>
                      )
                    })
                }

        <div style={{ display: 'flex' }}>
          <FieldTypeExpandMarker />
          <Paper elevation={0} square style={styles.addOptionPaper}>
            <Button
              className='add-option-single-selection-btn'
              style={styles.addOptionBtn}
              color='primary'
              variant='text'
              startIcon={<RadioButtonUncheckedIcon />}
              onClick={addOption}
            >
              Add Checklist item
            </Button>
          </Paper>
        </div>
      </div>
    </>
  )
}

const styles = {
  relatedMain: {
    display: 'flex'
  },
  relatedMainHide: {
    display: 'none'
  },
  logicPanel: {
    // width: "100%"
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%'
  },
  optionsMainDiv: {
    display: 'flex',
    flexBasis: '100%'
  },
  optionsMainDivIcon: {
    marginLeft: '12px',
    whiteSpace: 'nowrap'
  },
  singleSelectionPaper: {
    borderTop: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    borderLeft: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    // marginRight: "96.19px",
    // width: "100%"
    height: '50px',
    flexBasis: '100%'
  },
  addOptionPaper: {
    borderTop: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    borderLeft: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    // marginRight: "96.19px",
    // width: "100%",
    height: '50px',
    flexBasis: '100%'
  },
  addOptionBtn: {
    textTransform: 'none',
    marginLeft: '16px',
    fontSize: '13px'
  },
  addOptionIconBtn: {
    padding: '8px'
  },
  addOptionIcon: {
    fontSize: '20px'
  }
}

export default CheckList
