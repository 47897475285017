import React, { useState, useEffect } from 'react'
import { Autocomplete, Box, Grid, IconButton, TextField, Typography } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import PercentIcon from '@mui/icons-material/Percent'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DataGrid from 'components/Reusable/DataGrid'
import { LoadingPleaseWait } from '@supportworks/react-components'

const AssembleMarkup = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState([]) // For screen manipulation
  const [rows, setRows] = useState([]) // For DataGrid
  const [markupTotal, setMarkupTotal] = useState(0)
  const [materialsTotal, setMaterialsTotal] = useState(0)

  useEffect(() => {
    if (isLoading) {
      updateList('load', props.product.pricingOptions.assembly.materialMarkup)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    updateList('load', props.product.pricingOptions.assembly.materialMarkup)
  }, [props.materialsTotal]) // eslint-disable-line

  const handleAdd = () => {
    const l = [...list, { id: '', ovrd: false }]
    updateList('add', l)
  }

  const handleDelete = idx => {
    const l = [...list]
    l.splice(idx, 1)
    updateList('delete', l)
  }

  const handleMoveUp = idx => {
    if (idx > 0) {
      const l = [...list]
      ;[l[idx - 1], l[idx]] = [l[idx], l[idx - 1]]
      updateList('move', l)
    }
  }

  const handleMoveDown = idx => {
    if (idx < list.length - 1) {
      const l = [...list]
      ;[l[idx], l[idx + 1]] = [l[idx + 1], l[idx]]
      updateList('move', l)
    }
  }

  const handleProcessRowUpdate = row => {
    if (row) {
      const l = JSON.parse(JSON.stringify(list))
      l[row.id].id = row.recordId
      l[row.id].markup = row.markup
      delete l[row.id].total // We do not want this, based on original spec, and recalculation woes
      l[row.id].ovrd = true
      updateList('change', l)
      return row
    }
  }

  const handleChange = (index, id) => {
    const l = JSON.parse(JSON.stringify(list))
    l[index].id = id
    updateList('change', l)
  }

  const updateList = (variant, updatedList) => {
    const rows = []
    let markupTotal = 0
    const materialsTotal = props.materialsTotal
    for (let x = 0; x < updatedList.length; x++) {
      let markup = updatedList[x].markup
      let total = 0
      let title = updatedList[x].title
      const master = props.config.find(o => o.id === updatedList[x].id)
      if (master !== undefined) {
        if (!updatedList[x].markup) {
          markup = master.markup
        }
        total = markup * 0.01 * materialsTotal
        markupTotal = markupTotal + total
        title = master.title
      }
      const row = {
        id: x,
        recordId: updatedList[x].id,
        title,
        markup,
        total
      }
      rows.push(row)
    }
    setRows(rows)
    setList(updatedList)
    const p = JSON.parse(JSON.stringify(props.product))
    p.pricingOptions.assembly.materialMarkup = updatedList
    if (variant !== 'load') {
      props.setProduct(p)
    }
    setMarkupTotal(markupTotal)
    setMaterialsTotal(materialsTotal)
    props.setMarkupTotal(markupTotal) // Must be last to force update
  }

  if (isLoading) return <LoadingPleaseWait />

  // Build the columns for the DataGrid
  const columns = [
    {
      flex: 3,
      type: 'string',
      field: 'recordId',
      headerName: 'Material Markup Factors',
      // editable: true, // This kills Autocomplete!!!! Do not use -pbaker 08/10/23
      sortable: true,
      renderCell: params => {
        // Build the list of options
        let options = props.config
          .filter(p => !list.some(item => item.id === p.id)) // Filter out existing IDs
          .map(p => {
            return { id: p.id, title: p.title, label: p.title }
          })
        options.unshift({ id: params.row.id, title: params.row.title, label: params.row.title }) // Add the current row to the list
        options = options.sort((a, b) => a.title.localeCompare(b.title))

        return (
          <Box
            sx={{ width: 1 }}
            onKeyDown={event => {
              if (event.key === ' ' || event.key.match(/ArrowDown|ArrowUp/)) {
                event.stopPropagation()
              }
            }}
          >
            <Autocomplete
              id='markup-autocomplete'
              size='small'
              value={params.row.title}
              options={options}
              onChange={(e, newValue) => {
                handleChange(params.row.id, newValue?.id || -1)
              }}
              renderInput={params => <TextField {...params} />}
              renderOption={(props, option) => {
                return (
                  <Box
                    {...props}
                    key={`label-key-${option.id}`}
                    component='li'
                    sx={{
                      borderBottom: '1px solid rgba(0, 0, 0, 0.08)' // Add bottom border
                    }}
                  >
                    <Typography variant='body2'>{option.title}</Typography>
                  </Box>
                )
              }}
              isOptionEqualToValue={(option, value) => {
                if (value === '') return true
                return option.title === value
              }}
            />
          </Box>
        )
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'markup',
      headerName: '% Markup',
      editable: true,
      sortable: true,
      preProcessEditCellProps: params => {
        const isValid = !isNaN(params.props.value)
        return { ...params.props, error: !isValid }
      },
      renderCell: params => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {params.value ? params.value : 0}
            <PercentIcon fontSize='small' sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
          </Box>
        )
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'total',
      headerName: 'Total',
      editable: false,
      sortable: true,
      renderCell: params => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AttachMoneyIcon fontSize='small' sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            {params.row.markup ? Number(params.row.markup * 0.01 * materialsTotal).toFixed(2) : 0}
          </Box>
        )
      }
    },
    {
      flex: 1,
      field: 'action',
      headerName: 'Actions',
      headerAlign: 'right',
      align: 'right',
      editable: false,
      sortable: false,
      renderCell: params => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id)}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton size='small' disabled={!(params.row.id < list.length - 1)} onClick={() => handleMoveDown(params.row.id)}>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  return (
    <Box>
      <Box>
        <Typography sx={{ fontWeight: 'bold', pb: 1 }}>Markup</Typography>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        defaultPageSize={100}
        showId
        onAdd={handleAdd}
        onProcessRowUpdate={row => {
          handleProcessRowUpdate(row)
        }}
      />
      <Grid container style={styles.markupTotalContainer}>
        <Grid item style={styles.markupTotalDescription}>
          <Typography variant='body2' style={{ fontWeight: 'bolder' }}>
            Totals
          </Typography>
        </Grid>
        <Grid item style={styles.markupTotalFiller}>
          &nbsp;
        </Grid>
        <Grid item style={styles.markupTotalAmount}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AttachMoneyIcon fontSize='small' sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <Typography variant='body2' style={{ fontWeight: 'bolder' }}>
              {markupTotal.toFixed(2)}
            </Typography>
          </Box>
        </Grid>
        <Grid item style={styles.markupTotalAction}>
          <IconButton size='small' onClick={handleAdd} sx={{ mr: 1 }}>
            <AddCircleOutlineIcon fontSize='small' />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  )
}
export default AssembleMarkup

const styles = {
  markupTotalContainer: {
    borderLeft: '1px solid rgba(0,0,0,.16)',
    borderBottom: '1px solid rgba(0,0,0,.16)',
    borderRight: '1px solid rgba(0,0,0,.16)',
    backgroundColor: '#f5f5f5'
  },
  markupTotalDescription: {
    display: 'flex',
    flex: 3,
    paddingLeft: '12px',
    paddingTop: '12px',
    paddingBottom: '12px',
    alignItems: 'center'
  },
  markupTotalFiller: {
    display: 'flex',
    flex: 1,
    borderRight: '1px solid rgba(0,0,0,.16)',
    borderLeft: '1px solid rgba(0,0,0,.16)'
  },
  markupTotalAmount: {
    display: 'flex',
    flex: 1,
    paddingRight: '12px',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  markupTotalAction: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderLeft: '1px solid rgba(0,0,0,.16)'
  }
}
