import React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'

const DrawerFooter = ({ onClose, onDone, onAdd, titleClose = 'Cancel', titleDone = 'Done', titleAdd = 'Add' }) => {
  return (
    <Box sx={{ mt: 7 }}>
      <Grid container sx={{ pt: 2, pb: 2, pl: 1, pr: 2 }} style={styles.container}>
        <Grid item xs sx={{ pl: 1 }} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Button variant='outlined' onClick={onClose}>
            <Typography variant='body'>{titleClose}</Typography>
          </Button>
        </Grid>

        <Grid item xs style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          {onAdd
            ? (
              <Button sx={{ mr: 1 }} variant='contained' color='primary' onClick={onAdd}>
                <Typography variant='body'>{titleAdd}</Typography>
              </Button>
              )
            : null}
          {onDone
            ? (
              <Button variant='contained' color='primary' onClick={onDone}>
                <Typography variant='body'>{titleDone}</Typography>
              </Button>
              )
            : null}
        </Grid>
      </Grid>
    </Box>
  )
}
export default DrawerFooter

const styles = {
  container: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '560px',
    borderTop: '1px solid rgba(0,0,0,.08)',
    backgroundColor: 'white'
  }
}
