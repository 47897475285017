import { useEffect, useState } from 'react'
import {
  Badge,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'
import { LoadingPleaseWait } from '@supportworks/react-components'

const LeftPanel = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [presentation, setPresentation] = useState({})
  const [title, setTitle] = useState('')
  const [type, setType] = useState('')

  useEffect(() => {
    if (isLoading) {
      setPresentation(props.presentation)
      setTitle(props.presentation.title || 'No Title')
      setType(props.presentation.type || 'document')

      if (!props.presentation.followUp || (Array.isArray(props.presentation.followUp) && props.presentation.followUp.length === 0)) {
        const p = JSON.parse(JSON.stringify(props.presentation))
        p.followUp = {}
        setPresentation(p)
        props.setPresentation(p)
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    setPresentation(props.presentation)
    setTitle(props.presentation.title)
  }, [props.presentation])

  const handleChange = (e) => {
    const p = JSON.parse(JSON.stringify(presentation))
    const name = e.target.name
    const value = e.target.value
    if (name === 'title') {
      setTitle(value)
      p[name] = value
    } else if (name === 'type') {
      setType(value)
      p.poster = ''
      p.asset = ''
      if (value !== 'slideshow') {
        delete p.assets
      }
      if (value !== 'overlay') {
        delete p.overlay
      }
      p[name] = value
    } else if (name.match(/presentations|solutions/)) {
      if (presentation.followUp[name]) {
        const r = window.confirm(`Unchecking this will delete current ${name}.  Are you sure you want to uncheck?`)
        if (r) {
          delete p.followUp[name]
        }
      } else {
        p.followUp[name] = []
      }
    }
    setPresentation(p)
    props.setPresentation(p)
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Grid container sx={{ pt: 2, pl: 2, pr: 2 }}>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <TextField
            fullWidth
            id='title'
            name='title'
            variant='outlined'
            label='Presentation Title'
            value={title}
            sx={{ 'input:focus': { border: 'none' } }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ pl: 2, pr: 2, borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Grid item xs={12} sx={{ pb: 1 }}>
          <FormControl fullWidth sx={{ pb: { sm: 1 }, 'input:focus': { border: 'none' } }}>
            <InputLabel id='unit-type'>Presentation Type</InputLabel>
            <Select id='type' name='type' label='Presentation Type' labelId='type' variant='outlined' value={type} onChange={handleChange}>
              {presentationTypes.map((pt, idx) => {
                return (
                  <MenuItem key={`unit-${idx}`} value={pt.id}>
                    <Typography variant='subtitle'>{pt.title}</Typography>
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container sx={{ borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Grid item xs={12}>
          <Selection
            {...props}
            subVariant='presentation'
            title='Presentation Setup'
            step={1}
            onClick={() => {
              props.setRightPanelVariant('presentation')
            }}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Grid item xs={12}>
          <Selection
            {...props}
            subVariant='poster'
            title='Poster Setup'
            step={2}
            onClick={() => {
              props.setRightPanelVariant('poster')
            }}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ pl: 2, pr: 2, pt: 2, borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <Typography variant='caption'>
            <strong>Include Follow-Ups</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <FormGroup>
            <FormControlLabel
              sx={{ m: 0, p: 0 }}
              control={
                <Checkbox
                  sx={{ m: 0, p: 0 }}
                  id='presentations'
                  name='presentations'
                  checked={!!(presentation.followUp && presentation.followUp.presentations)}
                  onClick={handleChange}
                />
              }
              label={
                <Typography sx={{ pl: 1 }} style={styles.textField}>
                  Presentations
                </Typography>
              }
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <FormGroup>
            <FormControlLabel
              sx={{ m: 0, p: 0 }}
              control={
                <Checkbox
                  sx={{ m: 0, p: 0 }}
                  id='solutions'
                  name='solutions'
                  checked={!!(presentation.followUp && presentation.followUp.solutions)}
                  onClick={handleChange}
                />
              }
              label={
                <Typography sx={{ pl: 1 }} style={styles.textField}>
                  Solutions
                </Typography>
              }
            />
          </FormGroup>
        </Grid>
      </Grid>

      {presentation.followUp && (presentation.followUp.solutions || presentation.followUp.presentations)
        ? (
          <Grid container sx={{ borderBottom: '1px solid rgba(0,0,0,.16)' }}>
            {presentation.followUp.presentations
              ? (
                <Grid item xs={12} sx={{ borderBottom: '1px solid rgba(0,0,0,.16)' }}>
                  <Selection
                    {...props}
                    subVariant='fu-presentations'
                    title='Presentations'
                    editable={false}
                    onClick={() => {
                      props.setRightPanelVariant('fu-presentations')
                    }}
                    step={3}
                  />
                </Grid>
                )
              : null}
            {presentation.followUp.solutions
              ? (
                <Grid item xs={12}>
                  <Selection
                    {...props}
                    subVariant='fu-solutions'
                    title='Solutions'
                    editable={false}
                    onClick={() => {
                      props.setRightPanelVariant('fu-solutions')
                    }}
                    step={presentation.followUp.presentations ? 4 : 3}
                  />
                </Grid>
                )
              : null}
          </Grid>
          )
        : null}
    </>
  )
}
export default LeftPanel

const Selection = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isHover, setIsHover] = useState(false)
  const [title, setTitle] = useState('')

  useEffect(() => {
    const key = `${props.subVariant}Title`
    if (props.presentation[key]) {
      setTitle(props.presentation[key])
    } else {
      setTitle(props.title)
    }
    setIsLoading(false)
    // eslint-disable-next-line
  }, [])

  if (isLoading) return <></>

  let selected = false
  if (props.rightPanelVariant === props.subVariant) selected = true

  return (
    <Box
      key={props.title}
      onClick={props.onClick}
      style={{ ...styles.selectionContainer, backgroundColor: isHover ? '#F2F2F2' : 'white' }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Grid container sx={{ pl: 2, pt: props.onClick ? 1 : 1, pb: props.onClick ? 1 : 1 }} style={selected ? styles.selected : null} alignItems='center'>
        <Grid item sm={10}>
          <Grid container wrap='nowrap'>
            <Grid item sx={{ pl: 1 }}>
              <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
                {props.step ? <Badge badgeContent={props.step} color='primary' sx={{ mr: 3 }} /> : null}
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={1}>
          <IconButton>
            {props.rightPanelVariant === props.subVariant
              ? (
                <PlayCircleFilledIcon style={props.onClick ? styles.icon : styles.iconHidden} />
                )
              : (
                <ArrowRightIcon style={props.onClick ? styles.icon : styles.iconHidden} />
                )}
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  )
}

const presentationTypes = [
  { id: 'document', title: 'Document' },
  { id: 'overlay', title: 'Overlay' },
  { id: 'slideshow', title: 'Slideshow' },
  { id: 'video', title: 'Video' }
]

const styles = {
  textField: {
    fontSize: 14
  },
  icon: {
    color: 'rgba(0,0,0,.66)'
  },
  iconHidden: {
    color: 'rgba(0,0,0,0)'
  }
}
