import React, { useEffect, useState } from 'react'
import { DialogGeneric } from '../../../components/Reusable/DialogGeneric'
import {
  Autocomplete,
  Box, FormControl, FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select, Switch,
  TextField, Typography
} from '@mui/material'

export const Commission = (props) => {
  const [showDialog, setShowDialog] = useState(props.open)
  const [label, setLabel] = useState('')
  const [amount, setAmount] = useState('')
  const [type, setType] = useState('')
  const [pct, setPct] = useState('')
  const [maxPct, setMaxPct] = useState('')
  const [crmID, setCrmID] = useState('')
  const [taxable, setTaxable] = useState(false)
  const [editable, setEditable] = useState(false)
  const [separateLineItem, setSeparateLineItem] = useState(false)
  const [options, setOptions] = useState([])

  useEffect(() => {
    let options = []
    if (props.masterList) {
      options = props.masterList.map((p) => {
        return {
          id: p.crmId ? p.crmId : p.value,
          label: p.name,
          title: p.name
        }
      })
      options = options.sort((a, b) => a.label.localeCompare(b.label))
      setOptions(options)
    }
  }, [])

  useEffect(() => {
    setLabel(props.commissionConfig.label)
    setAmount(props.commissionConfig.amount)
    setType(props.commissionConfig.type)
    setPct(props.commissionConfig.pct)
    setMaxPct(props.commissionConfig.maxPct)

    if ('crmID' in props.commissionConfig) {
      setCrmID(props.commissionConfig.crmID)
    }

    if ('taxable' in props.commissionConfig) {
      setTaxable(props.commissionConfig.taxable)
    }

    if ('separateLineItem' in props.commissionConfig) {
      setSeparateLineItem(props.commissionConfig.separateLineItem)
    }

    if ('editable' in props.commissionConfig) {
      setEditable(props.commissionConfig.editable)
    }
  }, [props.commissionConfig])

  const closeHandler = () => {
    setShowDialog(!showDialog)
    props.onClose()
  }

  const handleChange = (e) => {
    if (e.target.name === 'label') {
      setLabel(e.target.value)
    }
    if (e.target.name === 'amount') {
      setAmount(e.target.value)
      setPct('')
    }
    if (e.target.name === 'pct') {
      setPct(e.target.value)
      setAmount('')
    }
    if (e.target.name === 'maxPct') {
      setMaxPct(e.target.value)
    }
  }

  const handleSave = () => {
    const newCommissionConfig = props.commissionConfig

    newCommissionConfig.label = label
    newCommissionConfig.amount = amount
    newCommissionConfig.pct = pct
    newCommissionConfig.maxPct = maxPct
    newCommissionConfig.type = type
    newCommissionConfig.taxable = Boolean(taxable)
    newCommissionConfig.separateLineItem = Boolean(separateLineItem)
    newCommissionConfig.editable = Boolean(editable)
    newCommissionConfig.crmID = crmID

    props.onSave(newCommissionConfig)
  }

  const handleChangeSelect = (e) => {
    if (e.target.name === 'type') {
      setType(e.target.value)
    }
    if (e.target.name === 'mapToCRM') {
      setCrmID(e.target.value)
    }
  }

  const onChangeSwitch = (e) => {
    if (e.target.name === 'taxable') {
      setTaxable(!taxable)
    }
    if (e.target.name === 'separateLineItem') {
      setSeparateLineItem(!separateLineItem)
    }
    if (e.target.name === 'editable') {
      setEditable(!editable)
    }
  }

  const getSelectedProduct = (pid) => {
    let title = ''
    if (pid !== -1 && pid !== '') {
      const p = options.find((o) => o.id === pid)
      title = p.title
    }
    return title
  }

  const handleChangeProduct = (e, newProd) => {
    setCrmID(newProd)
  }

  const jsx = (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id='label'
            name='label'
            variant='outlined'
            label='Title'
            onChange={handleChange}
            value={label}
            size='small'
            sx={{
              marginBottom: '25px',
              marginTop: '25px'
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl sx={{ marginTop: '25px', marginBottom: '5px' }} size='small' fullWidth>
            <InputLabel id='demo-simple-select-label'>Deposit Type</InputLabel>
            <Select labelId='type' name='type' id='type' value={type} size='small' label='Discount Type' onChange={handleChangeSelect}>
              <MenuItem key='amount' value='amount'>
                Amount
              </MenuItem>
              <MenuItem key='pct' value='pct'>
                Percentage
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {type === 'amount' && (
          <Grid item xs={2}>
            <TextField
              fullWidth
              id='amount'
              name='amount'
              variant='outlined'
              label='Amount'
              onChange={handleChange}
              value={amount}
              size='small'
              sx={{
                marginBottom: '25px',
                marginTop: '25px'
              }}
            />
          </Grid>
        )}
        {type === 'pct' && (
          <Grid item xs={2}>
            <TextField
              fullWidth
              id='pct'
              name='pct'
              variant='outlined'
              label='Percentage'
              onChange={handleChange}
              value={pct}
              size='small'
              sx={{
                marginBottom: '25px',
                marginTop: '25px'
              }}
            />
          </Grid>
        )}
        <Grid item xs={2}>
          <TextField
            fullWidth
            id='maxPct'
            name='maxPct'
            variant='outlined'
            label='Max Percentage'
            onChange={handleChange}
            value={maxPct}
            size='small'
            sx={{
              marginBottom: '25px',
              marginTop: '25px'
            }}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={2}>
          <FormControl sx={{ mb: 2 }}>
            <FormControlLabel
              sx={{ ml: 0 }}
              label={
                <Typography variant='body2' sx={{ flex: 1, fontSize: '1rem' }}>
                  Taxable
                </Typography>
              }
              labelPlacement='start'
              value='start'
              control={
                <Switch
                  name='taxable'
                  color='primary'
                  sx={{ m: 0 }}
                  checked={taxable}
                  onChange={onChangeSwitch}
                />
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl sx={{ mb: 2 }}>
            <FormControlLabel
              sx={{ ml: 0 }}
              label={
                <Typography variant='body2' sx={{ flex: 1, fontSize: '1rem' }}>
                  Separate Line Item
                </Typography>
              }
              labelPlacement='start'
              value='start'
              control={
                <Switch
                  name='separateLineItem'
                  color='primary'
                  sx={{ m: 0 }}
                  checked={separateLineItem}
                  onChange={onChangeSwitch}
                />
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl sx={{ mb: 2 }}>
            <FormControlLabel
              sx={{ ml: 0 }}
              label={
                <Typography variant='body2' sx={{ flex: 1, fontSize: '1rem' }}>
                  Editable by Sales Rep
                </Typography>
              }
              labelPlacement='start'
              value='start'
              control={
                <Switch
                  name='editable'
                  color='primary'
                  sx={{ m: 0 }}
                  checked={editable}
                  onChange={onChangeSwitch}
                />
              }
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Autocomplete
            id='autocomplete-maptocrm-commission'
            name='mapToCRM'
            isOptionEqualToValue={(option, value) => {
              if (value === '') return true
              return option.title === value
            }}
            options={options}
            size='small'
            value={getSelectedProduct(crmID)}
            onChange={(e, newValue) => {
              handleChangeProduct(e, newValue && newValue.id ? newValue.id : -1)
            }}
            renderInput={(params) =>
              <TextField
                {...params}
                label='Map to CRM'
              />}
            renderOption={(props, option) => {
              return (
                <Box component='li' {...props} key={option.id}>
                  <Typography variant='body'>{option.title}</Typography>
                </Box>
              )
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )

  return (
    <>
      showDialog &&
      <DialogGeneric title='Commission' onClose={() => closeHandler()} content={jsx} fullWidth onChange={handleSave} />
    </>
  )
}
