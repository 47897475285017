import Api, { ApiPlugin } from 'library/Api'
import { Topics } from 'config/topics'
import Company from 'screens/Company'

export class ApiPluginRippleMedia extends ApiPlugin {
  /**
   * @param {ApiBase} apiBase
   * @param {Object} props
   */
  constructor (apiBase, props) {
    super(apiBase, props)

    if (props && props.baseUrl) {
      this.baseUrl = props.baseUrl
    }
    apiBase.registerMethod('getPresignedRequestPutObject', this.getPresignedRequestPutObject.bind(this))
    apiBase.registerMethod('birthMediaLibraryUsingGUID', this.birthMediaLibraryUsingGUID.bind(this))
    apiBase.registerMethod('invokeCloudMediaProcessor', this.invokeCloudMediaProcessor.bind(this))
    apiBase.registerMethod('getPDFMeta', this.getPDFMeta.bind(this))
  }

  /**
   * Gets a presigned URL for uploading objects to AWS S3
   */
  async getPresignedRequestPutObject (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/media-presigned', {
      organization: Company.getCurrentOrgId()
    })
    return this.getApiBase()
      .get(route, props)
      .then((rsp) => {
        if (rsp && rsp.status === 'success') {
          if (props && props.callback) {
            props.callback(rsp.data[0])
          }
          if (props && props.topic) {
            Topics.publish(props.topic, rsp.data[0])
          }
          return rsp.data[0]
        }
        return rsp
      })
  }

  /**
   * Triggers core to update the ml_media creation after upload.
   * See the sequence diagram documented here: https://wiki.test.supportworks.com/wiki/index.php/DEV-1875_Ripple_Admin_media_upload_to_AWS_S3#Specifications
   */
  async birthMediaLibraryUsingGUID (props) {
    const route = Api.resolvePath(this.getApiBaseUrl() + '/media-library-direct/:guid', { guid: props.guid })
    return this.getApiBase()
      .get(route, props)
      .then((rsp) => {
        if (rsp && rsp.status === 'success') {
          if (props && props.callback) {
            props.callback(rsp.data[0])
          }
          if (props && props.topic) {
            Topics.publish(props.topic, rsp.data[0])
          }
          return rsp.data[0]
        }
        return rsp
      })
  }

  /**
   * Triggers core cloud-media-processor.
   *
   * See the sequence diagram documented here: https://wiki.test.supportworks.com/wiki/index.php/DEV-1875_Ripple_Admin_media_upload_to_AWS_S3#Specifications
   */
  async invokeCloudMediaProcessor (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/cloud-media-processor', { key: props.key, size: props.size, seconds: props.seconds })
    return this.getApiBase()
      .get(route, props)
      .then((rsp) => {
        if (rsp && rsp.status === 'success') {
          if (props && props.callback) {
            props.callback(rsp.data[0])
          }
          if (props && props.topic) {
            Topics.publish(props.topic, rsp.data[0])
          }
          return rsp.data[0]
        }
        return rsp
      })
  }

  /**
   * Gets PDF meta data including pageCount, pages, and info for each page that contains thumbnail, preview links.
   */
  async getPDFMeta (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/media/:guid/pages', {
      organization: Company.getCurrentOrgId(),
      guid: props.guid
    })
    return this.getApiBase()
      .get(route, props)
      .then((rsp) => {
        if (rsp && rsp.status === 'success') {
          if (props && props.callback) {
            props.callback(rsp.data[0])
          }
          if (props && props.topic) {
            Topics.publish(props.topic, rsp.data[0])
          }
          return rsp.data[0]
        }
        return rsp
      })
  }
}
