import React from 'react'
import Select from 'react-select'

export const SelectSearch = ({ options, name, value, placeholder, getOptionLabel = defaultGetOptionLabel, getOptionValue = defaultGetOptionValue, onChange, isMulti = false, menuPlacement = 'bottom' }) => {
  // massage values
  let modValue
  if (value) {
    if (value.constructor === String || value.constructor === Number) {
      modValue = findObjectByValue({ value, options, getOptionValue })
    } else if (value.constructor === Array && value.length) {
      modValue = []
      for (let i = 0, l = value.length; i < l; i++) {
        if (value[i].constructor === String || value[i].constructor === Number) {
          const foundValue = findObjectByValue({ value: value[i], options, getOptionValue })
          if (foundValue) {
            modValue[modValue.length] = foundValue
          }
        }
      }
    }
  }

  return (
    <Select
      name={name || 'select-search'}
      styles={customStyles}
      value={modValue}
      isMulti={isMulti}
      getOptionLabel={getOptionLabel || (option => option.label)}
      getOptionValue={getOptionValue || (option => option.value)}
      options={options}
      isSearchable
      onChange={onChange}
      placeholder={placeholder || 'Please select an option'}
      menuPlacement={menuPlacement || 'bottom'}
    />
  )
}

/**
 * Extracts Display value from option object.
 *
 * @param {Option|Object} option
 * @param {?string} option.label Description of option presented to the user. Use this or name.
 * @param {?string} option.name Description of option presented to the user. Use this or label.
 * @param {string} option.value Value of the selection. If neither name nor label is passed, this will be used.
 * @return {string} Display string.
 */
const defaultGetOptionLabel = option => {
  if ('label' in option) {
    return option.label
  }
  if ('name' in option) {
    return option.name
  }
  return option.value
}

/**
 * Extracts selection value from options object.
 *
 * @param {Option|Object} option
 * @param {string} option.value Value of the selection. If neither name nor label is passed, this will be used.
 * @return {string} Value string.
 */
const defaultGetOptionValue = option => option.value

/**
 *
 * @param value
 * @param options
 * @param getOptionLabel
 * @param getOptionValue
 * @return {null|*}
 */
const findObjectByValue = ({ value, options, getOptionValue = defaultGetOptionValue }) => {
  for (let i = 0, l = options.length; i < l; i++) {
    if (getOptionValue(options[i]) === value) {
      return options[i]
    }
  }
  return null
}

/**
 *
 * @param {Option[]} selections
 * @param {Boolean} isMulti
 * @param {Function} getOptionValue
 * @return {string}
 */
export const getValuesOnly = ({ selections, isMulti, getOptionValue = defaultGetOptionValue }) => {
  if (isMulti) {
    return selections.map((opt) => getOptionValue(opt))
  }
  return getOptionValue(selections)
}

const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: 48
  }),
  option: (provided) => ({
    ...provided,
    borderBottom: '1px solid rgba(0,0,0,.16)',
    padding: 8
  })
}
