import React, { useState, useEffect } from 'react'
import { FormControl, TextField, MenuItem } from '@mui/material'

const MapFormFieldNonNumericSpec = ({ config: defaultConfig, mappedFieldObject, setProduct, product, formFields, specification, setShowSaveButton }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [config, setConfig] = useState(defaultConfig)
  const [list, setList] = useState([])
  const [choices, setChoices] = useState([])
  useEffect(() => {
    console.log('useEffect start [] triggered')
    // Initialize all things on the component with existing product data.
    if (isLoading) {
      try {
        initialize()
      } catch (err) {
        console.log(err)
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (event, idx) => {
    const { value } = event.target
    initialize()
    config.values[idx].config = value
    setProduct({ ...product })
    if (setShowSaveButton) setShowSaveButton(true)
  }

  const initialize = () => {
    const formField = formFields.find((o) => o.id === mappedFieldObject.id)
    let l
    if (formField.type === 'choice') {
      l = formField.choices.map((o) => o.title)
    } else if (formField.type === 'boolean') {
      l = ['Yes', 'No']
    } else if (formField.type === 'imageCheckbox') {
      l = formField.list.map((o) => o.id)
    } else {
      l = formField.list
    }
    if (!config.values) {
      config.field = mappedFieldObject.id
      config.values = []
    }
    for (let x = 0; x < l.length; x++) {
      if (!config.values[x]) {
        config.values.push({ config: '', value: l[x] })
      }
    }
    setList(l)
    setChoices(specification.choices)
    setConfig(config)
    setProduct({ ...product })
  }

  if (isLoading) return <></>

  if (list.length === 0) {
    return (
      <div className='sa-section'>
        <div className='sa-heading'>
          Not able to find field mapping configuration.  Please map to a different field.
        </div>
      </div>
    )
  }

  return (
    <div className='sa-section'>
      <div className='sa-heading'>
        {mappedFieldObject.sectionTitle}: {mappedFieldObject.title}
      </div>
      <div className='sa-spec-paragraph'>
        <span>
          Connect the dots.  Take the information your teams are inputting and automate the products so they don't need to do any more work.
        </span>
      </div>
      <div className='sa-indent'>
        {
                    list.map((value, idx) => {
                      let formField
                      if (config && config.values) {
                        const c = config.values.filter((o) => o.value === value)
                        if (c.length) {
                          formField = c[0].config
                        }
                      }
                      return (
                        <AnswerRow key={'AR' + idx} index={idx} title={value} formField={formField} choices={choices} onChange={(e) => { handleChange(e, idx) }} />
                      )
                    })
                }
      </div>
    </div>
  )
}
export default MapFormFieldNonNumericSpec

const AnswerRow = ({ formField: defaultValue, title, choices, onChange }) => {
  const [formField, setFormField] = useState(defaultValue)

  const handleChange = (event) => {
    const { name, value } = event.target
    if (name === 'formField') {
      setFormField(value)
    }
    onChange(event)
  }

  return (
    <div>
      <div className='sa-heading' style={{ paddingBottom: '16px' }}>
        Answer: {title}
      </div>
      <div className='sa-indent'>
        <FormControl key='FormControl' variant='outlined' value={formField || ''} fullWidth>
          <TextField
            select
            name='answer'
            label='Select Product Group Value'
            variant='outlined'
            defaultValue={formField || ''}
            onChange={handleChange}
          >
            {choices.map((row, idx) => {
              return (
                <MenuItem key={`PLMI-${idx}`} value={row.id}>
                  <span className='sa-product-text-title'>{row.title}</span>
                </MenuItem>
              )
            })}
          </TextField>
        </FormControl>
      </div>
    </div>
  )
}
