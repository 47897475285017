import React, { useState, useEffect } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, TextField, Box, Grid, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DataObjectIcon from '@mui/icons-material/DataObject'
import Api from '../../library/Api'
import Company from '../../screens/Company'
import Helper from '@supportworks/helper'
import Permissions from 'library/Permissions'
import withScreenWrapper from '../../screens/withScreenWrapper'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import { JSONEditor } from 'components/Reusable'
import { LoadingPleaseWait } from '@supportworks/react-components'
import DataGrid from '../../components/Reusable/DataGrid'

const CompanyConfigPublish = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [showConfigDialog, setShowConfigDialog] = useState(false)
  const [configs, setConfigs] = useState([])
  const [configId, setConfigId] = useState(null)

  useEffect(() => {
    // console.log('useEffect start [] triggered');
    if (isLoading) {
      if (!props.proposal) {
        Api.callRegisteredMethod('getPublishedConfigs', {}).then((configs) => {
          setConfigs(configs)
          setIsLoading(false)
        })
      }
    }
  }, []) // eslint-disable-line

  const handleNew = (name) => {
    setIsLoading(true)
    setShowAddDialog(false)
    Api.callRegisteredMethod('newPublishedConfig', { name }).then((response) => {
      console.log(`newPublishedConfig: ${response}`)
      Api.callRegisteredMethod('getPublishedConfigs', {}).then((configs) => {
        setConfigs(configs)
        setIsLoading(false)
      })
    })
  }

  const handleRollback = (id) => {
    setIsLoading(true)
    Api.callRegisteredMethod('rollbackPublishedConfig', { configId: id }).then((response) => {
      console.log(`rollbackPublishedConfig: ${response}`)
      Api.callRegisteredMethod('getPublishedConfigs', {}).then((configs) => {
        setConfigs(configs)
        setIsLoading(false)
      })
    })
  }

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'publishedDt',
      sort: 'desc'
    }
  ])

  if (isLoading) {
    return <LoadingPleaseWait />
  }

  const columns = [
    {
      flex: 1,
      type: 'string',
      field: 'title',
      headerName: 'Title',
      editable: false,
      sortable: true
    },
    {
      flex: 1,
      type: 'string',
      field: 'version',
      headerName: 'Version',
      editable: false,
      sortable: true
    },
    {
      flex: 1,
      type: 'string',
      field: 'created',
      headerName: 'Created',
      editable: false,
      sortable: true,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container>
              <Grid item>
                <Typography variant='body2' sx={{ pl: 1, color: '#3c3c3c !important' }}>
                  {Helper.formatDate(params.row.created, 'yyyy-mm-dd hh:MM TT')}
                </Typography>
              </Grid>
            </Grid>
          )
        }
      }
    },
    {
      flex: 1,
      type: 'string',
      field: 'appUser',
      headerName: 'Created By',
      editable: false,
      sortable: true
    },
    {
      flex: 1,
      type: 'string',
      field: 'publishedDt',
      headerName: 'Last Published',
      editable: false,
      sortable: true,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container>
              <Grid item>
                <Typography variant='body2' sx={{ pl: 1, color: '#3c3c3c !important' }}>
                  {Helper.formatDate(params.row.publishedDt, 'yyyy-mm-dd hh:MM TT')}
                </Typography>
              </Grid>
            </Grid>
          )
        }
      }
    },
    {
      width: 168,
      type: 'string',
      field: 'action',
      headerName: 'Action',
      editable: false,
      sortable: false,
      sort: 'desc',
      renderCell: (params) => {
        if (params.row) {
          let configJSX
          if (Permissions.hasRole('super_user')) {
            configJSX = (
              <IconButton
                onClick={() => {
                  setConfigId(params.row.configId)
                  setShowConfigDialog(!showConfigDialog)
                }}
              >
                <DataObjectIcon style={{ color: 'red' }} />
              </IconButton>
            )
          }
          if (params.row.published === 0) {
            return (
              <Box key={`sort-${params.row.id}`} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    handleRollback(params.row.configId)
                  }}
                >
                  Publish
                </Button>
                {configJSX}
              </Box>
            )
          } else {
            return (
              <Box key={`sort-${params.row.id}`} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button color='primary' variant='outlined'>
                  Published
                </Button>
                {configJSX}
              </Box>
            )
          }
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < configs.length; x++) {
    // console.log(configs[x]);
    const row = {
      id: x,
      configId: configs[x].configId,
      title: configs[x].name,
      version: configs[x].version,
      created: configs[x].created,
      appUser: configs[x].appUser ? configs[x].appUser : '',
      publishedDt: configs[x].publishedDt,
      published: configs[x].published
    }
    rows.push(row)
  }

  return (
    <div className='UsersIndex'>
      <Company.Header title='Publish Configurations' />
      <div className='actionsTable__wrapper'>
        <DataGrid
          rows={rows}
          columns={columns}
          showToolbarDensity={false}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          onAdd={() => {
            setShowAddDialog(!showAddDialog)
          }}
          autosizeOptions={{
            columns: ['action'],
            includeOutliers: true,
            includeHeaders: false
          }}
        />
        {showAddDialog
          ? (
            <DialogAdd
              onNew={handleNew}
              onClose={() => {
                setShowAddDialog(!showAddDialog)
              }}
            />
            )
          : null}
      </div>
      <Box sx={{ pt: 2, pl: 1 }}>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setShowAddDialog(!showAddDialog)
          }}
        >
          Create a New Version
        </Button>
      </Box>

      {showConfigDialog
        ? (
          <DialogConfig
            configId={configId}
            onClose={() => {
              setConfigId(null)
              setShowConfigDialog(!showConfigDialog)
            }}
          />
          )
        : null}
    </div>
  )
}
export default withScreenWrapper(CompanyConfigPublish)

const DialogAdd = ({ onNew, onClose }) => {
  const [title, setTitle] = useState('')

  const handleAdd = () => {
    onNew(title)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth='md'>
      <DialogTitle className='dialog-title'>
        New Version
        <IconButton className='dialog-close' aria-label='close' onClick={onClose} sx={{ position: 'absolute', right: 12, top: 12 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className='dialog-content'>
        <Box component='form' sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }} noValidate autoComplete='off'>
          <TextField
            required
            id='name'
            label='Enter Version Title'
            placeholder='Enter Version Title'
            variant='outlined'
            onChange={(e) => {
              setTitle(e.target.value)
            }}
            onKeyDown={handleKeyDown} // Add this line to handle keydown event
            sx={{
              'input:focus': {
                border: 'none'
              }
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant='contained' onClick={onClose}>
          Cancel
        </Button>
        <Button color='primary' variant='contained' onClick={handleAdd}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const DialogConfig = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [config, setConfig] = useState('{}')

  useEffect(() => {
    if (isLoading) {
      Api.callRegisteredMethod('getPublishedConfig', { configId: props.configId }).then((rsp) => {
        if (rsp.length > 0) {
          setConfig(rsp[0].contentJson)
        }
        setIsLoading(false)
      })
    }
    // eslint-disable-next-line
  }, [])

  if (isLoading) return <LoadingPleaseWait />

  const jsx = (
    <Box sx={{ mt: 2 }}>
      <JSONEditor value={config} />
    </Box>
  )
  return <DialogGeneric fullWidth title={`Config: ${props.configId}`} content={jsx} {...props} onClose={props.onClose} />
}
