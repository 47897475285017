import React, { useState, useEffect } from 'react'
import { DataGrid, GridToolbarQuickFilter, GridToolbarContainer } from '@mui/x-data-grid'
import { Box, Chip, Grid } from '@mui/material'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import { LoadingPleaseWait } from '@supportworks/react-components'

export const DialogCRMProductSelector = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [pageSize, setPageSize] = useState(10)
  const [selectedRows, setSelectedRows] = useState([]) // Array of row idx's of things that have been checked
  const [rows, setRows] = useState([]) // Array of row objects that can be selected
  const [products, setProducts] = useState([])

  useEffect(() => {
    if (isLoading) {
      const sr = []
      const r = []
      // these products should ONLY be crmProducts, see CompanyConfigProducts <ProductEditor> usage
      for (let x = 0; x < props.products?.length; x++) {
        r.push({
          id: x,
          title: props.products[x].title,
          price: props.products[x].price,
          crmId: props.products[x].id,
          categories: props.products[x].categories ? props.products[x].categories : []
        })
        if (props.products[x].id === props.selectedCrmId) {
          sr.push(x)
        }
      }
      setProducts(props.products)
      setRows(r)
      setSelectedRows(sr)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleSave = () => {
    const idx = selectedRows[0]
    const field = products[idx]
    props.onSave(field)
  }

  const handleChange = (r) => {
    const f = r.filter(x => !selectedRows.includes(x))
    setSelectedRows(f)
  }

  const CustomToolbar = (filterChips) => {
    return (
      <GridToolbarContainer>
        <Grid container sx={{ p: 1 }}>
          <Grid item>
            <GridToolbarQuickFilter size='small' variant='outlined' />
          </Grid>
        </Grid>
      </GridToolbarContainer>
    )
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    { field: 'id', headerName: 'Id', hide: true },
    { field: 'title', headerName: 'Title', flex: 1 },
    {
      flex: 1,
      field: 'price',
      headerName: 'Price',
      editable: false,
      sortable: true,
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            ${params.value}
          </Box>
        )
      }
    },
    {
      flex: 1,
      field: 'categories',
      headerName: 'Categories',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {params.value.map((cat, idx) => {
              return <Chip key={`Chip-${idx}`} idx={`Chip-${idx}`} size='small' label={cat} name='category' sx={{ m: 0.25 }} color='default' />
            })}
          </Box>
        )
      }
    },
    { field: 'crmId', headerName: 'ID', flex: 1 }
  ]

  const jsx = (
    <Box>
      <Box sx={{ height: 500 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50]}
          checkboxSelection
          rowSelectionModel={selectedRows}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onRowSelectionModelChange={(rows) => {
            handleChange(rows)
          }}
          keepNonExistentRowsSelected
          sx={{
            '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
              color: '#A6B0CF',
              backgroundColor: 'transparent',
              display: 'none'
            }
          }}
          slots={{ toolbar: CustomToolbar }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false
              }
            }
          }}
        />
      </Box>
    </Box>
  )

  return <DialogGeneric title={props.title} content={jsx} fullWidth onChange={(handleSave)} onClose={props.onClose} />
}
