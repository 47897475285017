import React from 'react'
import '../../../../css/forms.css'
import FieldTypeAddLogicPanel from 'screens/Config/Forms/FieldTypeAddLogicPanel'
import FieldTypeExpandMarker from '../Reusables/FieldTypeExpandMarker'
// import AddLogic from '../AddLogic'

function LongText (props) {
  // const [hasLogic, setHasLogic] = useState('related' in props.field ? true : false);
  // const [field, setField] = useState(props.field)

  const changeHandlerVisible = (visible) => {
    props.onChangeVisible(visible)
  }

  const changeHandlerDefault = (val) => {
    props.onChangeDefault(val)
  }

  const changeHandlerCRM = (crm) => {
    props.onChangeCRM(crm)
  }

  /* const changeHandlerUnits = (units) => {
        props.onChangeUnits(units);
    } */

  /* const addLogic = (e) => {
        if (e) {
            setHasLogic(true);
        }
    } */

  /* useEffect(() => {
    setField(props.field)
  }, [props.field]) */

  return (
    <>
      {
                props.expandMarker &&
                  <FieldTypeExpandMarker />
            }

      <div style={styles.logicPanel}>
        <FieldTypeAddLogicPanel
          {...props}
          field={props.field}
          onChangeVisible={(visible) => changeHandlerVisible(visible)}
          onChangeDefault={(val) => changeHandlerDefault(val)}
          onChangeCRM={(crm) => changeHandlerCRM(crm)}
        />

        {/* {
                    hasLogic &&
                    <AddLogic
                        {...props}
                        type={'type'}
                        field={field}
                    />
                } */}
      </div>
    </>
  )
}

const styles = {
  relatedMain: {
    display: 'flex'
  },
  logicPanel: {
    // width: "100%"
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%'
  }
}

export default LongText
