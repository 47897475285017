import React from 'react'
import Api from 'library/Api'
import { LoadingOverlay } from '../../components/Reusable'
import { LoadingPleaseWait } from '@supportworks/react-components'

/**
 * Provides a list of existing configurations for the specified company, links to maintain them and create new configs.
 */
export class CompanyConfigSelect extends React.Component {
  /**
     *
     * @param {Object} props
     * @param {Object[]} [props.configList] List of configs to show
     * @param {string} [props.tag]
     * @param {string} [props.name]
     * @param {boolean} [props.startsWith=false]
     * @param {string} [props.variant]
     * @param {string} [props.title]
     */
  constructor (props) {
    super(props)
    this.state = {
      isLoading: true,
      configList: props.configList ? props.configList : null
    }
    this.mounted = false

    console.log(this.state)
  }

  componentDidMount () {
    this.mounted = true
    Api.callRegisteredMethod(
      'getConfigList'
    ).then((rspConfigList) => {
      this.setState({
        configList: rspConfigList.data,
        isLoading: false
      })
      LoadingOverlay.hide()
    })
  }

  select (index) {
    if (this.props.onSelect) {
      this.props.onSelect(this.state.configList[index])
    }
  }

  render () {
    if (this.state.isLoading) {
      return (<LoadingPleaseWait />)
    }
    if (!this.state.configList || !this.state.configList.length) {
      return (<p>No configurations to select.</p>)
    }
    return (
      <table striped bordered hover size='sm'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Tag</th>
          </tr>
        </thead>
        <tbody>
          {this.state.configList.map(
            (c, index) => {
              return (
                <tr
                  key={index} onClick={() => {
                    this.select(index)
                  }}
                >
                  <td>{c.name}</td>
                  <td>{c.tag}</td>
                </tr>
              )
            })}
        </tbody>
      </table>
    )
  }
}
