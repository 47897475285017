import React, { useEffect, useState } from 'react'
import { Box, Button, CardMedia, Grid, InputAdornment, Switch, TextField, Typography, FormControlLabel, Stack } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import PercentIcon from '@mui/icons-material/Percent'
import { DialogGeneric } from '../../../components/Reusable/DialogGeneric'
import SelectorWithPreview from 'components/Reusable/SelectorWithPreview'
import { getAssetInfo } from '../CompanyConfigPresentations'
import { LoadingPleaseWait } from '@supportworks/react-components'
import Helper from 'library/helper'

export const Finance = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [showDialog, setShowDialog] = useState(props.open)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [label, setLabel] = useState('')
  const [months, setMonths] = useState(0)
  const [apr, setApr] = useState('')
  const [aprMax, setAprMax] = useState('')
  const [depositPct, setDepositTotal] = useState('')
  const [url, setUrl] = useState('')
  const [minJobTotal, setMinJobTotal] = useState('')
  const [maxJobTotal, setMaxJobTotal] = useState('')
  const [roundPaymentFactor, setRoundPaymentFactor] = useState(false)
  const [depositRequired, setDepositRequired] = useState(false)
  const [defaultPlan, setDefaultPlan] = useState(false)

  const [asset, setAsset] = useState(null) // full selected asset object

  useEffect(() => {
    if (isLoading) {
      setLabel(props.financeConfig.label)
      setMonths(props.financeConfig.months)
      setApr(props.financeConfig.apr)
      setAprMax(props.financeConfig.aprMax)
      setDepositTotal(props.financeConfig.depositPct)
      setMinJobTotal(props.financeConfig.minJobTotal)
      setMaxJobTotal(props.financeConfig.maxJobTotal)
      setUrl(props.financeConfig.url)
      if (props.financeConfig.asset) {
        const a = getAssetInfo(props.financeConfig.asset, props.assetsConfig)
        if (a && a.id) {
          setAsset(a)
        }
      }

      if ('round_payment_factor' in props.financeConfig) {
        setRoundPaymentFactor(props.financeConfig.round_payment_factor)
      }
      if ('default' in props.financeConfig) {
        setDefaultPlan(props.financeConfig.default)
      }
      if ('depositRequired' in props.financeConfig) {
        setDepositRequired(props.financeConfig.depositRequired)
      }
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleSave = () => {
    const newFinanceConfig = props.financeConfig

    newFinanceConfig.label = label
    newFinanceConfig.months = months
    newFinanceConfig.apr = apr
    newFinanceConfig.aprMax = aprMax
    newFinanceConfig.url = url
    newFinanceConfig.minJobTotal = minJobTotal
    newFinanceConfig.maxJobTotal = maxJobTotal
    newFinanceConfig.round_payment_factor = roundPaymentFactor
    newFinanceConfig.default = defaultPlan
    newFinanceConfig.depositRequired = depositRequired
    newFinanceConfig.depositPct = depositPct
    if (depositRequired && !depositPct) {
      newFinanceConfig.depositPct = 0
    }
    if (asset && asset.id) {
      newFinanceConfig.asset = asset.id
    } else {
      newFinanceConfig.asset = ''
    }

    props.onSave(newFinanceConfig)
  }

  const handleChange = (e) => {
    if (e.target.name === 'label') {
      setLabel(e.target.value)
    }
    if (e.target.name === 'months') {
      setMonths(e.target.value)
    }
    if (e.target.name === 'apr') {
      setApr(e.target.value)
    }
    if (e.target.name === 'depositPct') {
      setDepositTotal(e.target.value)
    }
    if (e.target.name === 'url') {
      setUrl(e.target.value)
    }
    if (e.target.name === 'aprMax') {
      setAprMax(e.target.value)
    }
    if (e.target.name === 'minJobTotal') {
      setMinJobTotal(e.target.value)
    }
    if (e.target.name === 'maxJobTotal') {
      setMaxJobTotal(e.target.value)
    }
  }

  const handleUpdateAsset = (asset) => {
    if (asset && asset.id) {
      setAsset(asset)
    } else {
      setAsset(null)
    }
  }

  const handleClose = () => {
    setShowDialog(!showDialog)
    props.onClose()
  }

  const onChangePaymentFactor = () => {
    setRoundPaymentFactor(!roundPaymentFactor)
  }

  const onChangeDepositRequired = () => {
    setDepositRequired(!depositRequired)
  }

  if (isLoading) return <LoadingPleaseWait />

  const jsx = (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              id='label'
              name='label'
              variant='outlined'
              label='Title'
              onChange={handleChange}
              value={label}
              size='small'
              sx={{ pb: 2 }}
            />

            <Grid container spacing={1} sx={{ pb: 2 }}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id='months'
                  name='months'
                  variant='outlined'
                  label='Months'
                  onChange={handleChange}
                  value={months}
                  size='small'
                  onKeyDown={(event) => {
                    if (event.key !== 'Backspace') {
                      if (!Helper.isNumeric(event.key)) {
                        event.preventDefault()
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id='apr'
                  name='apr'
                  variant='outlined'
                  label='Min APR %'
                  onChange={handleChange}
                  value={apr}
                  size='small'
                  onKeyDown={(event) => {
                    if (event.key !== 'Backspace') {
                      if (!Helper.isDecimal(event.key)) {
                        event.preventDefault()
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id='aprMax'
                  name='aprMax'
                  variant='outlined'
                  label='Max APR %'
                  onChange={handleChange}
                  value={aprMax}
                  size='small'
                  onKeyDown={(event) => {
                    if (event.key !== 'Backspace') {
                      if (!Helper.isDecimal(event.key)) {
                        event.preventDefault()
                      }
                    }
                  }}
                />
              </Grid>
            </Grid>

            <TextField
              fullWidth
              id='url'
              name='url'
              variant='outlined'
              label='Website URL'
              onChange={handleChange}
              value={url}
              size='small'
              sx={{ pb: 1 }}
            />

            <Grid container sx={{ pb: 2 }}>
              <Grid item xs={12} sm={12}>
                <Grid container>
                  <Grid item xs={4} sx={{ display: { xs: 'none' } }}>
                    &nbsp;
                  </Grid>
                  <Grid item xs={8}>
                    <FormControlLabel
                      sx={{ m: 0, width: '100%' }}
                      control={
                        <Switch
                          sx={{ display: 'flex', justifyContent: 'flex-end' }}
                          checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                          onChange={onChangePaymentFactor}
                          checked={roundPaymentFactor}
                        />
                      }
                      label={
                        <Typography variant='body2' sx={{ flex: 1 }}>
                          Round To Payment Factor
                        </Typography>
                      }
                      labelPlacement='start'
                    />
                  </Grid>
                  <Grid item xs={4} sx={{ display: { xs: 'none' } }}>
                    &nbsp;
                  </Grid>
                  <Grid item xs={8}>
                    <FormControlLabel
                      sx={{ m: 0, width: '100%' }}
                      control={
                        <Switch
                          sx={{ display: 'flex', justifyContent: 'flex-end' }}
                          checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                          onChange={onChangeDepositRequired}
                          checked={depositRequired}
                        />
                      }
                      label={
                        <Typography variant='body2' sx={{ flex: 1 }}>
                          Deposit Required
                        </Typography>
                      }
                      labelPlacement='start'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    {depositRequired
                      ? (
                        <TextField
                          id='depositPct'
                          name='depositPct'
                          variant='outlined'
                          label='Deposit %'
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <PercentIcon fontSize='small' />
                              </InputAdornment>
                            )
                          }}
                          onChange={handleChange}
                          value={depositPct}
                          size='small'
                          onKeyDown={(event) => {
                            if (event.key !== 'Backspace') {
                              if (!Helper.isDecimal(event.key)) {
                                event.preventDefault()
                              }
                            }
                          }}
                        />
                        )
                      : (
                        <>&nbsp;</>
                        )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item>
                <Typography style={{ fontSize: '1rem', fontWeight: 'bold' }}>Project Total Ranges</Typography>
                <Typography variant='body2' style={{ color: 'rgb(0 0 0 / 60%)' }} sx={{ pb: 2 }}>
                  Specify if this plan has eligibility limitations
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id='minJobTotal'
                      name='minJobTotal'
                      variant='outlined'
                      label='Minimum'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <AttachMoneyIcon fontSize='small' />
                          </InputAdornment>
                        )
                      }}
                      onChange={handleChange}
                      value={minJobTotal}
                      size='small'
                      onKeyDown={(event) => {
                        if (event.key !== 'Backspace') {
                          if (!Helper.isDecimal(event.key)) {
                            event.preventDefault()
                          }
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id='maxJobTotal'
                      name='maxJobTotal'
                      variant='outlined'
                      label='Maximum'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <AttachMoneyIcon fontSize='small' />
                          </InputAdornment>
                        )
                      }}
                      onChange={handleChange}
                      value={maxJobTotal}
                      size='small'
                      onKeyDown={(event) => {
                        if (event.key !== 'Backspace') {
                          if (!Helper.isDecimal(event.key)) {
                            event.preventDefault()
                          }
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack>
              <Box sx={{ pt: 1 }}>
                <Typography sx={{ pb: 1 }} variant='body2'>
                  Select Poster Image
                </Typography>
              </Box>
              {asset && asset.path
                ? (
                  <Box style={styles.imageContainer}>
                    {asset.path
                      ? (
                        <a href={asset.path} target='_blank' rel='noreferrer'>
                          <CardMedia component='img' height='160' sx={{ objectFit: 'contain' }} image={asset.path} alt={asset.path} />
                        </a>
                        )
                      : (
                          ''
                        )}
                  </Box>
                  )
                : (
                  <Typography variant='body2'>There is no poster image. Please add one.</Typography>
                  )}
              <Box sx={{ pt: 2 }}>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    setShowAddDialog(!showAddDialog)
                  }}
                >
                  {asset && asset.id ? 'Replace Image' : 'Add Image'}
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      {showAddDialog
        ? (
          <DialogGeneric
            title='Select Poster Asset'
            content={
              <SelectorWithPreview
                selectedConfig={{ asset: asset && asset.id ? asset.id : null }}
                fieldName='asset'
                config={props.assetsConfig}
                assetType={['Image']}
                onUpdate={(asset) => {
                  handleUpdateAsset(asset)
                }}
              />
          }
            fullWidth
            onChange={() => {
              setShowAddDialog(false)
            }}
            onClose={() => setShowAddDialog(false)}
          />
          )
        : null}
    </>
  )

  return <>{showDialog && <DialogGeneric title='Financing Plans' onClose={() => handleClose()} content={jsx} fullWidth onChange={handleSave} />}</>
}

const styles = {
  imageContainer: {
    display: 'flex',
    padding: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,.04)',
    border: '1px dashed rgba(0,0,0,.16)'
  }
}
