import React, { useState, useEffect } from 'react'
import { Box, FormControlLabel, Grid, Stack, Switch, TextField, Typography } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { LoadingPleaseWait } from '@supportworks/react-components'

const RightPanelProject = (props) => {
  const [isLoading, setIsLoading] = useState(true)

  const [newSolutionTotalsVisible, setNewSolutionTotalsVisible] = useState(false)
  const [newItemizedTotalsVisible, setNewItemizedTotalsVisible] = useState(false)
  const [newQuantitiesVisible, setNewQuantitiesVisible] = useState(false)

  const [declinedSolutionTotalsVisible, setDeclinedSolutionTotalsVisible] = useState(false)
  const [declinedItemizedTotalsVisible, setDeclinedItemizedTotalsVisible] = useState(false)
  const [declinedQuantitiesVisible, setDeclinedQuantitiesVisible] = useState(false)

  const [acceptedSolutionTotalsVisible, setAcceptedSolutionTotalsVisible] = useState(false)
  const [acceptedItemizedTotalsVisible, setAcceptedItemizedTotalsVisible] = useState(false)
  const [acceptedQuantitiesVisible, setAcceptedQuantitiesVisible] = useState(false)

  const [userEditableSolutionTotals, setUserEditableSolutionTotals] = useState(false)
  const [userEditableItemizedTotals, setUserEditableItemizedTotals] = useState(false)
  const [userEditableQuantities, setUserEditableQuantities] = useState(false)

  const [title, setTitle] = useState('')
  const [displayQuestions, setDisplayQuestions] = useState(true)
  const [projectScreenDisplay, setProjectScreenDisplay] = useState(true)
  const [projectScreenEditable, setProjectScreenEditable] = useState(true)
  const [accomplishListDisplay, setAccomplishListDisplay] = useState(true)
  const [addProductsAsIncluded, setAddProductsAsIncluded] = useState(true)

  const [displayAreas, setDisplayAreas] = useState(false)

  const defaultStructure = {
    customerWhy: {
      title: 'Why do you want the problem(s) fixed now?',
      displayQuestions: true,
      projectScreen: {
        display: true,
        editable: true
      },
      accomplishList: {
        display: true
      }
    },
    new: {
      solutionTotalsVisible: false,
      itemizedTotalsVisible: false,
      quantitiesVisible: false
    },
    declined: {
      solutionTotalsVisible: false,
      itemizedTotalsVisible: false,
      quantitiesVisible: false
    },
    accepted: {
      solutionTotalsVisible: false,
      itemizedTotalsVisible: false,
      quantitiesVisible: false
    },
    userEditable: {
      solutionTotals: false,
      itemizedTotals: false,
      quantities: false
    },
    projectComparison: {
      displayAreas: false
    }
  }

  useEffect(() => {
    if (isLoading) {
      const m = JSON.parse(JSON.stringify(props.meeting))
      if (props.meeting.project === undefined) {
        m.project = [defaultStructure] // Yeah, it's always 1 array element.
        props.setMeeting(m)
      } else {
        if (!('customerWhy' in props.meeting.project[0])) {
          m.project[0].customerWhy = {
            title: 'Why do you want the problem(s) fixed now?',
            displayQuestions: true,
            projectScreen: {
              display: true,
              editable: true
            },
            accomplishList: {
              display: true
            }
          }
        }

        if ('addProductsAsIncluded' in m) {
          setAddProductsAsIncluded(m.addProductsAsIncluded)
        } else {
          m.addProductsAsIncluded = true
        }

        if (!('projectComparison' in props.meeting.project[0])) {
          m.project[0].projectComparison = {
            displayAreas: false
          }
        }

        props.setMeeting(m)
      }

      const project = m.project[0] // Yeah, it's always 1 array element.
      setNewSolutionTotalsVisible(project.new.solutionTotalsVisible)
      setNewItemizedTotalsVisible(project.new.itemizedTotalsVisible)
      setNewQuantitiesVisible(project.new.quantitiesVisible)

      setDeclinedSolutionTotalsVisible(project.declined.solutionTotalsVisible)
      setDeclinedItemizedTotalsVisible(project.declined.itemizedTotalsVisible)
      setDeclinedQuantitiesVisible(project.declined.quantitiesVisible)

      setAcceptedSolutionTotalsVisible(project.accepted.solutionTotalsVisible)
      setAcceptedItemizedTotalsVisible(project.accepted.itemizedTotalsVisible)
      setAcceptedQuantitiesVisible(project.accepted.quantitiesVisible)

      setUserEditableSolutionTotals(project.userEditable.solutionTotals)
      setUserEditableItemizedTotals(project.userEditable.itemizedTotals)
      setUserEditableQuantities(project.userEditable.quantities)

      setTitle(project.customerWhy.title)
      setDisplayQuestions(project.customerWhy.displayQuestions)
      setProjectScreenDisplay(project.customerWhy.projectScreen.display)
      setProjectScreenEditable(project.customerWhy.projectScreen.editable)
      setAccomplishListDisplay(project.customerWhy.accomplishList.display)

      setDisplayAreas(project.projectComparison.displayAreas)

      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (e) => {
    const name = e.target.name
    const m = JSON.parse(JSON.stringify(props.meeting))
    const project = m.project[0]
    if (name === 'newSolutionTotalsVisible') {
      setNewSolutionTotalsVisible(!newSolutionTotalsVisible)
      project.new.solutionTotalsVisible = !newSolutionTotalsVisible
    } else if (name === 'newItemizedTotalsVisible') {
      setNewItemizedTotalsVisible(!newItemizedTotalsVisible)
      project.new.itemizedTotalsVisible = !newItemizedTotalsVisible
    } else if (name === 'newQuantitiesVisible') {
      setNewQuantitiesVisible(!newQuantitiesVisible)
      project.new.quantitiesVisible = !newQuantitiesVisible
    } else if (name === 'declinedSolutionTotalsVisible') {
      setDeclinedSolutionTotalsVisible(!declinedSolutionTotalsVisible)
      project.declined.solutionTotalsVisible = !declinedSolutionTotalsVisible
    } else if (name === 'declinedItemizedTotalsVisible') {
      setDeclinedItemizedTotalsVisible(!declinedItemizedTotalsVisible)
      project.declined.itemizedTotalsVisible = !declinedItemizedTotalsVisible
    } else if (name === 'declinedQuantitiesVisible') {
      setDeclinedQuantitiesVisible(!declinedQuantitiesVisible)
      project.declined.quantitiesVisible = !declinedQuantitiesVisible
    } else if (name === 'acceptedSolutionTotalsVisible') {
      setAcceptedSolutionTotalsVisible(!acceptedSolutionTotalsVisible)
      project.accepted.solutionTotalsVisible = !acceptedSolutionTotalsVisible
    } else if (name === 'acceptedItemizedTotalsVisible') {
      setAcceptedItemizedTotalsVisible(!acceptedItemizedTotalsVisible)
      project.accepted.itemizedTotalsVisible = !acceptedItemizedTotalsVisible
    } else if (name === 'acceptedQuantitiesVisible') {
      setAcceptedQuantitiesVisible(!acceptedQuantitiesVisible)
      project.accepted.quantitiesVisible = !acceptedQuantitiesVisible
    } else if (name === 'userEditableSolutionTotals') {
      setUserEditableSolutionTotals(!userEditableSolutionTotals)
      project.userEditable.solutionTotals = !userEditableSolutionTotals
    } else if (name === 'userEditableItemizedTotals') {
      setUserEditableItemizedTotals(!userEditableItemizedTotals)
      project.userEditable.itemizedTotals = !userEditableItemizedTotals
    } else if (name === 'userEditableQuantities') {
      setUserEditableQuantities(!userEditableQuantities)
      project.userEditable.quantities = !userEditableQuantities
    } else if (name === 'title') {
      setTitle(e.target.value)
      project.customerWhy.title = e.target.value
    } else if (name === 'displayQuestions') {
      setDisplayQuestions(!displayQuestions)
      project.customerWhy.displayQuestions = !displayQuestions
    } else if (name === 'projectScreenDisplay') {
      setProjectScreenDisplay(!projectScreenDisplay)
      project.customerWhy.projectScreen.display = !projectScreenDisplay
    } else if (name === 'projectScreenEditable') {
      setProjectScreenEditable(!projectScreenEditable)
      project.customerWhy.projectScreen.editable = !projectScreenEditable
    } else if (name === 'accomplishListDisplay') {
      setAccomplishListDisplay(!accomplishListDisplay)
      project.customerWhy.accomplishList.display = !accomplishListDisplay
    } else if (name === 'addProductsAsIncluded') {
      setAddProductsAsIncluded(!addProductsAsIncluded)
      m.addProductsAsIncluded = !addProductsAsIncluded
    } else if (name === 'displayAreas') {
      setDisplayAreas(!displayAreas)
      project.projectComparison.displayAreas = !displayAreas
    }
    props.setMeeting(m)
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <Box style={styles.container} sx={{ p: 2 }}>
      <Stack sx={{ pb: 3 }}>
        <Typography variant='h6'>Products Included</Typography>
        <Typography variant='body2'>
          When this feature is enabled, products that are added to a solution will be included when they are
          <br />added through automation. When disabled, products will not be included.
        </Typography>
      </Stack>

      <Grid container style={{ marginBottom: '32px' }}>
        <Grid container>
          <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>Products Included when Added to Solution</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControlLabel
              sx={{ m: 0 }}
              name='addProductsAsIncluded'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='addProductsAsIncluded'
                  checked={!!addProductsAsIncluded}
                  color='primary'
                  onClick={handleChange}
                />
                            }
              labelPlacement='start'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>

      <Stack sx={{ pb: 3 }}>
        <Typography variant='h6'>Customer Why</Typography>
        <Typography variant='body2'>
          This feature is a card that can appear in the accomplish list and project review screens.
          Customer Why <br />is displayed as a card and was designed to help reps capture and review
          customers desired outcomes <br />during the appointment.

          <br /><br />Additionally, fields from within forms can be mapped to this feature. Many companies will
          take <br />questions from their customer interviews and map them to the customer why card.
        </Typography>
      </Stack>

      <Grid container style={{ marginBottom: '32px' }}>
        <Grid item xs={6} sx={{ pb: 2 }}>
          <TextField
            id='title'
            name='title'
            size='small'
            variant='outlined'
            label='Customer Why Prompt'
            value={title}
            sx={{ 'input:focus': { border: 'none' }, width: '90%' }}
            onChange={handleChange}
          />
        </Grid>

        <Grid container>
          <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>Display in Accomplish List</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControlLabel
              sx={{ m: 0 }}
              name='accomplishListDisplay'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='accomplishListDisplay'
                  checked={!!accomplishListDisplay}
                  color='primary'
                  onClick={handleChange}
                />
                            }
              labelPlacement='start'
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>Display in Project Review</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControlLabel
              sx={{ m: 0 }}
              name='projectScreenDisplay'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='projectScreenDisplay'
                  checked={!!projectScreenDisplay}
                  color='primary'
                  onClick={handleChange}
                />
                            }
              labelPlacement='start'
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>Editable in Project Review</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControlLabel
              sx={{ m: 0 }}
              name='projectScreenEditable'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='projectScreenEditable'
                  checked={!!projectScreenEditable}
                  color='primary'
                  onClick={handleChange}
                />
                            }
              labelPlacement='start'
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>Include Questions with Mapped Form Fields</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControlLabel
              sx={{ m: 0 }}
              name='displayQuestions'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='displayQuestions'
                  checked={!!displayQuestions}
                  color='primary'
                  onClick={handleChange}
                />
                            }
              labelPlacement='start'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>

      <Stack sx={{ pb: 3 }}>
        <Typography variant='h6'>Project Display Preferences</Typography>
        <Typography variant='body2'>
          Customize how solutions and products are presented in the project page within the application.
        </Typography>
      </Stack>

      <Grid container>
        <Grid item xs={12} sx={{ pb: 1 }} style={{ fontWeight: 'bold' }}>
          <Typography variant='body'>New Solutions</Typography>
        </Grid>
        <Grid container>
          <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>Display Solution Totals</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControlLabel
              sx={{ m: 0 }}
              name='newSolutionTotalsVisible'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='newSolutionTotalsVisible'
                  checked={!!newSolutionTotalsVisible}
                  color='primary'
                  onClick={handleChange}
                />
                            }
              labelPlacement='start'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>Display Itemized Pricing</Typography>
          </Grid>

          <Grid item xs={9}>
            <FormControlLabel
              sx={{ m: 0 }}
              name='newItemizedTotalsVisible'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='newItemizedTotalsVisible'
                  checked={!!newItemizedTotalsVisible}
                  color='primary'
                  onClick={handleChange}
                />
                            }
              labelPlacement='start'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>Display Product Quantities</Typography>
          </Grid>

          <Grid item xs={9}>
            <FormControlLabel
              sx={{ m: 0 }}
              name='newQuantitiesVisible'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='newQuantitiesVisible'
                  checked={!!newQuantitiesVisible}
                  color='primary'
                  onClick={handleChange}
                />
                            }
              labelPlacement='start'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ pb: 1, pt: 3 }} style={{ fontWeight: 'bold' }}>
          <Typography variant='body'>Accepted Solutions</Typography>
        </Grid>
        <Grid container>
          <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>Display Solution Totals</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControlLabel
              sx={{ m: 0 }}
              name='acceptedSolutionTotalsVisible'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='acceptedSolutionTotalsVisible'
                  checked={!!acceptedSolutionTotalsVisible}
                  color='primary'
                  onClick={handleChange}
                />
                            }
              labelPlacement='start'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>Display Itemized Pricing</Typography>
          </Grid>

          <Grid item xs={9}>
            <FormControlLabel
              sx={{ m: 0 }}
              name='acceptedItemizedTotalsVisible'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='acceptedItemizedTotalsVisible'
                  checked={!!acceptedItemizedTotalsVisible}
                  color='primary'
                  onClick={handleChange}
                />
                            }
              labelPlacement='start'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>Display Product Quantities</Typography>
          </Grid>

          <Grid item xs={9}>
            <FormControlLabel
              sx={{ m: 0 }}
              name='acceptedQuantitiesVisible'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='acceptedQuantitiesVisible'
                  checked={!!acceptedQuantitiesVisible}
                  color='primary'
                  onClick={handleChange}
                />
                            }
              labelPlacement='start'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ pb: 1, pt: 3 }} style={{ fontWeight: 'bold' }}>
          <Typography variant='body'>Declined Solutions</Typography>
        </Grid>
        <Grid container>
          <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>Display Solution Totals</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControlLabel
              sx={{ m: 0 }}
              name='declinedSolutionTotalsVisible'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='declinedSolutionTotalsVisible'
                  checked={!!declinedSolutionTotalsVisible}
                  color='primary'
                  onClick={handleChange}
                />
                            }
              labelPlacement='start'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>Display Itemized Pricing</Typography>
          </Grid>

          <Grid item xs={9}>
            <FormControlLabel
              sx={{ m: 0 }}
              name='declinedItemizedTotalsVisible'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='declinedItemizedTotalsVisible'
                  checked={!!declinedItemizedTotalsVisible}
                  color='primary'
                  onClick={handleChange}
                />
                            }
              labelPlacement='start'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>Display Product Quantities</Typography>
          </Grid>

          <Grid item xs={9}>
            <FormControlLabel
              sx={{ m: 0 }}
              name='declinedQuantitiesVisible'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='declinedQuantitiesVisible'
                  checked={!!declinedQuantitiesVisible}
                  color='primary'
                  onClick={handleChange}
                />
                            }
              labelPlacement='start'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ pb: 1, pt: 3 }} style={{ fontWeight: 'bold' }}>
          <Typography variant='body'>User Overrides</Typography>
        </Grid>
        <Grid container>
          <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>Modify Solution Totals Display</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControlLabel
              sx={{ m: 0 }}
              name='userEditableSolutionTotals'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='userEditableSolutionTotals'
                  checked={!!userEditableSolutionTotals}
                  color='primary'
                  onClick={handleChange}
                />
                            }
              labelPlacement='start'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>Modify Itemized Pricing Display</Typography>
          </Grid>

          <Grid item xs={9}>
            <FormControlLabel
              sx={{ m: 0 }}
              name='userEditableItemizedTotals'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='userEditableItemizedTotals'
                  checked={!!userEditableItemizedTotals}
                  color='primary'
                  onClick={handleChange}
                />
                            }
              labelPlacement='start'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>Modify Product Quantities Display</Typography>
          </Grid>

          <Grid item xs={9}>
            <FormControlLabel
              sx={{ m: 0 }}
              name='userEditableQuantities'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='userEditableQuantities'
                  checked={!!userEditableQuantities}
                  color='primary'
                  onClick={handleChange}
                />
                            }
              labelPlacement='start'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>

      <Stack sx={{ pb: 1, pt: 3 }}>
        <Typography variant='h6'>Project Comparison</Typography>
      </Stack>

      <Grid container style={{ marginBottom: '32px' }}>
        <Grid container>
          <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>Display Areas</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControlLabel
              sx={{ m: 0 }}
              name='displayAreas'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='displayAreas'
                  checked={!!displayAreas}
                  color='primary'
                  onClick={handleChange}
                />
              }
              labelPlacement='start'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>

    </Box>
  )
}
export default RightPanelProject

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'white'
  }
}
