import React from 'react'
import { Alert, Box, Button, TextField } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import Api from '../../library/Api'
import Company from '../Company'
import { DialogUpdatingProducts } from 'components/Reusable/DialogUpdatingProducts'
import Helper from 'library/helper'
import HelpTag from './Field/HelpTag'
import HelpSystem from '../../components/Reusable/HelpSystem'
import HelpImportJson from './Field/HelpImportJson'
import Permissions from '../../library/Permissions'
import withScreenWrapper from '../withScreenWrapper'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { CompanyConfigSelect } from 'screens/Config/CompanyConfigSelect'
import { FooterRightPanel } from 'components/Reusable/Footer'
import { JSONEditor, ModalOverlay } from '../../components/Reusable'

/**
 * Accepts mixed input parameter and converts it to a json-encoded string.
 *
 * @param {*} contentJson
 * @return {string}
 */
function handleJsonContent (contentJson) {
  if (contentJson) {
    if (contentJson.constructor === Array) {
      if (!contentJson.length) {
        contentJson = '{}'
      } else {
        contentJson = JSON.stringify(contentJson, null, 2)
      }
    } else if (typeof contentJson === 'object') {
      contentJson = JSON.stringify(contentJson, null, 2)
    } else {
      // string
      try {
        contentJson = JSON.stringify(JSON.parse(contentJson), null, 2)
      } catch (ex) {
        // likely a non-json string
        console.error(ex)
      }
    }
  }
  return contentJson
}

/**
 * Fields with which the config object is concerned along with any transformation.
 * This is used to get the data values stored in the state object for form use.
 *
 * @type {(string|{inputFilter: inputFilter, name: string})[]}
 */
const configFieldConcern = [
  'configId',
  'name',
  'tag',
  'status',
  {
    name: 'contentJson',
    inputFilter: handleJsonContent
  },
  {
    name: 'importJson',
    inputFilter: handleJsonContent
  }
]

/**
 * Plain JSON editor, which is the default editor if nothing else is defined.
 *
 * Provides methods for maintaining list of guids.
 *
 * @property {Object} props
 * @property {string} props.contentJson JSON-encoded content.
 */
export class CompanyConfigBaseEditor extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      organizationId: props.organizationId ? props.organizationId : null,
      contentJson: props.contentJson ? props.contentJson : null
    }
    this.guids = []
    this.addExistingGuid = this.addExistingGuid.bind(this)
    this.guid = this.guid.bind(this)
  }

  /**
   * Adds guid to manifest.
   *
   * @param {string} id
   */
  addExistingGuid (id) {
    this.guids.push(id)
  }

  /**
   * Generates new type-4 (random) guid.
   *
   * @return {string|*}
   */
  guid () {
    function s4 () {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    }
    let newId = s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
    if (this.props.organizationId) {
      newId = this.props.organizationId + '-' + newId
    }
    if (!this.guids.includes(newId)) {
      this.guids.push(newId)
      return newId
    } else {
      return this.guid()
    }
  }

  pushData (newval) {
    if (this.props && this.props.onChange) {
      this.props.onChange(newval)
    }
  }

  hasFinalize () {
    return false
  }

  finalize () {
    return this.state.contentJson
  }

  /**
   * Registers the component with the parent
   */
  componentDidMount () {
    if (this._componentDidMount) {
      this._componentDidMount()
    }
    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount () {
    if (this.props.onRef) {
      this.props.onRef(null)
    }
  }

  render () {
    return (
      <>
        <label>Configuration Content</label>
        <JSONEditor
          onChange={newval => {
            return this.pushData(newval)
          }}
          value={this.state.contentJson}
        />
      </>
    )
  }
}

/**
 * Complex editor for importing other configurations.
 */
export class CompanyConfigImportEditor extends CompanyConfigBaseEditor {
  constructor (props) {
    super(props)

    this.state.showImports = false
  }

  render () {
    return (
      <>
        <div className='row'>
          <div className='col'>
            <label>
              <span onClick={() => this.setState({ showImports: !this.state.showImports })}>Optional Imported Configurations</span>
            </label>
            <div className='btn-group btn-group-sm'>
              <HelpSystem content={() => <HelpImportJson />} title='Importing other configurations.' className='btn btn-sm btn-info' />
              <button
                type='button'
                className='btn btn-sm btn-info'
                onClick={() => {
                  ModalOverlay.setContent(
                    <CompanyConfigSelect
                      onSelect={s => {
                        console.log(s)
                        ModalOverlay.hide()
                      }}
                    />,
                    { title: 'Import an existing configuration' }
                  )
                }}
              >
                <i className='fas fa-plus' />
              </button>
              <button type='button' className='btn btn-sm btn-info' onClick={() => this.setState({ showImports: !this.state.showImports })}>
                <i className={'fas fa-caret-' + (this.state.showImports ? 'up' : 'down')} />
              </button>
            </div>
            {this.state.showImports
              ? (
                <table striped bordered hover size='sm'>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Tag</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody />
                </table>
                )
              : null}
          </div>
        </div>
      </>
    )
  }
}

/**
 * Generic form used for editing existing and creating new configs.
 * This serves as the base for all config-edit components.
 */
class CompanyConfigEdit extends React.Component {
  editorInstance

  /**
   *
   * @param {Object} props
   * @param {integer} [props.configId] ID of configuration to edit.
   * @param {Object} [props.config] Configuration Object
   * @param {string} [props.title='Configuration'] Page title fragment -- will be preceded by 'Edit' or 'Create'
   * @param {string} [props.name] Name of configuration
   * @param {boolean} [props.lockTag=false] Whether or not to lock the tag field
   * @param {boolean} [props.lockName=false] Whether or not to lock the name field
   * @param {string} [props.variant] This maps to one of the exports defined in CompanyConfig
   * @param {string} [props.tag] Tag used in configuration
   * @param {React.Component} [props.editorComponent] Custom editor to use instead of JSON editor.
   */
  constructor (props) {
    super(props)
    // Attempt backwards compatibility, of a bug or hidden feature where you can pass props.configId.  During development this value was undefined, and I needed it so the document editor would get config.
    let configId = props.configId
    if (!configId && props.config) {
      configId = props.config.configId
    }
    this.state = {
      organizationId: props.organizationId,
      configId,
      variant: props.variant ? props.variant : '',
      variantProductFeatures: null,
      config: props.config
        ? props.config
        : {
            tag: props.tag ? props.tag : '',
            title: props.title ? props.title : '',
            name: props.name ? props.name : '',
            // status: props.status ? props.status : '',
            importJson: props.importJson ? props.importJson : '',
            contentJson: props.contentJson ? props.contentJson : '{}'
          },
      originalContentJson: '{}', // Used to track 'contentJson' changes
      editorComponent: props.editorComponent ? props.editorComponent : CompanyConfigBaseEditor,
      useBaseEditor: false,
      showImports: Object.prototype.hasOwnProperty.call(props, 'showImports') ? props.showImports : !!((props.config && props.config.importJson) || props.importJson),
      isLoading: true,
      title: props.title ? props.title : 'Configuration',
      name: props.name ? props.name : '',
      status: props.status ? props.status : 'Published',
      tag: props.tag ? props.tag : '',
      lockTag: !!props.lockTag,
      lockName: !!props.lockName,
      readonly: props.readonly === true,
      current: props.current === true,
      isCRM: false,
      isSaving: false,
      isDoneSaving: false,
      isDeleting: false,
      isDataLoading: false,
      unSavedChanges: false,
      hasLibraryAdd: false,
      version: 1,
      productFeatures: props.productFeatures ? props.productFeatures : '',
      ...this.processConfigConcerns(props.config)
    }

    if (!Permissions.canAccessScreen(props.tag, 'write')) {
      this.state.readonly = true
    }

    this.handleChangeConfig = this.handleChangeConfig.bind(this)
    this.handleLibraryAdd = this.handleLibraryAdd.bind(this)
    this.editorInstance = null
  }

  async componentDidMount () {
    let productFeatures = {}
    await Api.callRegisteredMethod('getLatestConfigByTag', { tag: 'productFeatures', startsWith: false }).then(c => {
      if (c && c.contentJson) {
        productFeatures = JSON.parse(c.contentJson)
        this.setState({ productFeatures })
      }
    })

    let isCRM = false
    await Helper.isCRM()
      .then(result => {
        if (result !== undefined) {
          isCRM = result
        }
      })
      .catch(error => {
        console.log(`LeftPanel error: ${error}`)
      })

    if (this.state.current) {
      Api.callRegisteredMethod('getCurrentFinalConfig', { tag: 'all', startsWith: false }).then(rsp => {
        const config = {
          $type: 'RippleConfig',
          configId: -1,
          contentJson: JSON.stringify(rsp),
          name: 'Current',
          organizationId: -1,
          retired: null,
          status: 'Published',
          tag: '__current',
          version: 2
        }
        const changes = {
          isCRM,
          isNew: false,
          isLoading: false,
          config,
          originalContentJson: JSON.stringify(rsp),
          version: this.state.version + 1,
          ...this.processConfigConcerns(config)
        }
        this.setState(changes)
      })
    } else {
      if (this.state.configId) {
        await Api.callRegisteredMethod('getConfigById', { configId: this.state.configId }).then(rsp => {
          if (rsp.status === 'success') {
            const changes = {
              isCRM,
              isNew: false,
              isLoading: false,
              config: rsp.data[0],
              originalContentJson: rsp.data[0].contentJson,
              ...this.processConfigConcerns(rsp.data[0])
            }
            this.setState(changes)
          }
        })
      } else {
        Api.callRegisteredMethod('getDefaultConfig', { tag: this.state.tag }).then(config => {
          if (config == null || (Array.isArray(config) && config.length === 0)) {
            this.setState({ contentJson: undefined, isCRM, isLoading: false, isNew: true })
          } else {
            this.setState({ contentJson: JSON.stringify(config), isCRM, isLoading: false, isNew: true })
          }
        })
      }
    }
  }

  /**
   * Send json back through the API
   */
  async handleSave () {
    const cRef = this
    const config = JSON.parse(JSON.stringify(this.state.config))
    if (cRef.editorInstance) {
      if (typeof cRef.editorInstance.hasFinalize === 'function' && cRef.editorInstance.hasFinalize()) {
        config.contentJson = cRef.editorInstance.finalize()
      }
      // Weird logic to execute a hook function inside this class component.
      if (typeof cRef.editorInstance.hasFinalizeHook === 'function') {
        if (Object.keys(config.contentJson).length > 0) {
          config.contentJson = await cRef.editorInstance.finalizeHook(config.contentJson)
        }
      }
    }

    if (this.state.variantProductFeatures?.match(/labor|markup/)) {
      if (Permissions.hasRole('super_user')) {
        // ONLY super user thru test
        const r = window.confirm('Perform an auto-sync for matching materials in the master product list?')
        if (r) {
          this.setState({ isWorking: true })
          await Helper.updateProductsFromAssembly({ configProductFeatures: JSON.parse(config.contentJson), saveConfigProducts: true })
        }
      }
      this.setState({ isWorking: false })
      cRef.setState({ variantProductFeatures: null })
    }

    if (config.contentJson !== null) {
      cRef.setState({ isSaving: true, config }, function () {
        // Get editor content, since it's an unmanaged component.
        Api.callRegisteredMethod('saveConfig', {
          organizationId: cRef.state.organizationId,
          configId: cRef.state.configId,
          ...config
        }).then(async rsp => {
          // DEV-4247 reload the config, because Core does modifications to it and we want a fresh copy.
          let configId = cRef.state.configId
          if (configId === undefined && rsp.status === 'success') {
            // DEV-4362 Must be a new save
            if (rsp.data[0] && rsp.data[0].configId) {
              configId = rsp.data[0].configId
            }
          }
          await Api.callRegisteredMethod('getConfigById', { configId }).then(rsp => {
            if (rsp.status === 'success') {
              const config = rsp.data[0]
              cRef.setState({
                isSaving: false,
                isDoneSaving: true,
                unSavedChanges: false,
                config,
                contentJson: config.contentJson,
                originalContentJson: config.contentJson
              })
            }
          })
        })
      })
    }
  }

  handleChangeConfig (field, value) {
    const c = this.state.config || {}
    let unSavedChanges = this.state.unSavedChanges
    if (typeof c[field] === 'string') {
      const f1 = c[field].replace(/\s/g, '')
      const f2 = value.replace(/\s/g, '')
      if (f1 !== f2) {
        unSavedChanges = true
      }
    }
    c[field] = value
    this.setState({ config: c, [field]: value, unSavedChanges })
  }

  setLibraryAdd () {
    const cRef = this
    let hasLibraryAdd = false
    if (cRef.editorInstance) {
      if (typeof cRef.editorInstance.handleLibraryAdd === 'function') {
        hasLibraryAdd = true
      }
    }
    this.setState({ hasLibraryAdd })
  }

  handleLibraryAdd () {
    if (this.state.hasLibraryAdd) {
      this.editorInstance.handleLibraryAdd()
    }
  }

  handleDelete () {
    const r = window.confirm('Are you sure you want to delete this configuration and rely on the default going forward?')
    if (r) {
      this.setState(
        {
          isDeleting: true
        },
        function () {
          Api.callRegisteredMethod('deleteConfig', {
            configId: this.state.configId
          }).then(rsp => {
            this.setState({ isDeleting: false, isDoneSaving: true })
          })
        }
      )
    }
  }

  handleResetToDefault () {
    const r = window.confirm('Are you sure you want to clear your changes and set this to the default configuration?')
    if (r) {
      this.setState({ isLoading: true }, function () {
        Api.callRegisteredMethod('getDefaultConfig', { tag: this.state.tag }).then(config => {
          if (config == null) {
            this.setState({ contentJson: undefined, isLoading: false, version: this.state.version + 1 })
          } else {
            this.setState({ contentJson: JSON.stringify(config), isLoading: false, version: this.state.version + 1 })
          }
        })
      })
    }
  }

  handleCancel () {
    const r = window.confirm('Are you sure you want to restore all changes since last save?')
    if (r) {
      const c = JSON.parse(JSON.stringify(this.state.config))
      c.contentJson = this.state.originalContentJson
      this.setState({ config: c, contentJson: c.contentJson })
    }
  }

  handleDataLoading = value => {
    this.setState({ isDataLoading: value })
  }

  /** A method used for setting the status from the child
   *  Usage from the child:
   *    this.handleSetStatus('unSavedChanges', true)
   **/
  handleSetStatus = (status, value) => {
    this.setState({ [status]: value })
  }

  beautifyJson (prop) {
    try {
      const newState = {}
      newState[prop] = JSON.stringify(JSON.parse(this.state[prop]), null, 2)
      this.setState(newState)
    } catch (ex) {
      console.log(ex)
    }
  }

  /**
   * Processes incoming configuration changes.
   *
   * @param newConfig
   * @return object Change object consumable by setState.
   */
  processConfigConcerns (newConfig) {
    if (!newConfig) {
      return {}
    }
    const config = {}
    for (const concern of configFieldConcern) {
      if (typeof concern === 'object') {
        if (Object.prototype.hasOwnProperty.call(newConfig, concern.name)) {
          if (concern.inputFilter) {
            config[concern.name] = concern.inputFilter(newConfig[concern.name])
          }
        }
      } else {
        // Assuming string
        if (Object.prototype.hasOwnProperty.call(newConfig, concern)) {
          config[concern] = newConfig[concern]
        }
      }
    }
    return config
  }

  loadingOrForm () {
    if (this.state.isLoading) {
      return <LoadingPleaseWait />
    }

    if (this.state.isDeleting) {
      return <p>Saving configuration, please wait.</p>
    }

    return (
      <form autoComplete='off'>
        <Box>
          {this.state.readonly
            ? null
            : (
              <Box>
                {this.state.unSavedChanges
                  ? (
                    <Alert
                      severity='warning'
                      sx={{ mb: 2, p: 2 }}
                      action={
                        <Button color='primary' variant='contained' sx={{ mr: 2 }} onClick={() => this.handleSave()} startIcon={<SaveIcon />}>
                          {this.state.isSaving ? 'Saving...' : 'Save'}
                        </Button>
                  }
                    >
                      There are unsaved changes.
                    </Alert>
                    )
                  : null}
              </Box>
              )}
          {this.state.lockName
            ? null
            : (
              <Box sx={{ pt: 2, pb: 1 }}>
                <TextField
                  fullWidth
                  id='company-config-name'
                  name='company-config-name'
                  variant='outlined'
                  label='Name'
                  value={this.state.name}
                  sx={{ 'input:focus': { border: 'none' } }}
                  onChange={el => {
                    return this.handleChangeConfig('name', el.target.value)
                  }}
                />
              </Box>
              )}
          {this.state.lockTag
            ? null
            : (
              <div className='col form-group'>
                <label htmlFor='company-config-tag'>Tag</label>
                <HelpSystem content={() => <HelpTag />} title='How configuration tags work.' />
                <input
                  type='text'
                  id='company-config-tag'
                  className='form-control'
                  value={this.state.tag}
                  onChange={el => {
                    return this.handleChangeConfig('tag', el.target.value)
                  }}
                />
              </div>
              )}
        </Box>

        {
          // eslint-disable-next-line
          true ? null : <CompanyConfigImportEditor />
        }

        <div className='row' style={styles.content}>
          <div className='col'>
            {(this.props.editorComponent && this.state.useBaseEditor) || !this.props.editorComponent
              ? (
                <CompanyConfigBaseEditor
                  key={this.state.version}
                  isCRM={this.state.isCRM}
                  contentJson={this.state.contentJson}
                  config={this.state.config}
                  productFeatures={this.state.productFeatures}
                  organizationId={this.state.organizationId}
                  onRef={ref => {
                    this.editorInstance = ref
                  }}
                  onChange={(newValue, variant) => {
                    this.handleChangeConfig('contentJson', newValue)
                  }}
                />
                )
              : (
                <this.props.editorComponent
                  isCRM={this.state.isCRM}
                  contentJson={this.state.contentJson}
                  config={this.state.config}
                  organizationId={this.state.organizationId}
                  productFeatures={this.state.productFeatures}
                  onRef={ref => {
                    this.editorInstance = ref
                    this.setLibraryAdd()
                  }}
                  onChange={(newValue, variant) => {
                    this.handleChangeConfig('contentJson', newValue)
                    if (variant?.match(/labor|markup/)) {
                      this.setState({ variantProductFeatures: variant })
                    }
                  }}
                  onDataLoading={this.handleDataLoading}
                  onSetStatus={this.handleSetStatus}
                />
                )}
            {this.state.readonly
              ? null
              : (
                <FooterRightPanel
                  onDelete={() => this.handleDelete()}
                  onSave={() => this.handleSave()}
                  onResetToDefault={() => this.handleResetToDefault()}
                  onPublish={this.handleChangeConfig}
                  onLibraryAdd={this.state.hasLibraryAdd ? this.handleLibraryAdd : null}
                  onChangeEditor={
                  this.props.editorComponent && Permissions.companyIsSelected() && Permissions.hasRole('super_user')
                    ? () => {
                        this.setState({ useBaseEditor: !this.state.useBaseEditor })
                      }
                    : null
                  }
                  isNew={this.state.isNew}
                  isSaving={this.state.isSaving}
                  isDataLoading={this.state.isDataLoading}
                  lockName={this.props.lockName}
                  status={this.state.status}
                  useBaseEditor={this.state.useBaseEditor}
                />
                )}
            {this.state.isWorking ? <DialogUpdatingProducts /> : null}
          </div>
        </div>
      </form>
    )
  }

  render () {
    let title = this.state.title
    if (!this.props.lockTitle) {
      if (this.state.readonly) {
        title = `View ${title}`
      } else {
        if (this.state.configId) {
          title = `Edit ${title}`
        } else {
          title = `Create ${title}`
        }
      }
    }
    return (
      <div className='CompanyConfigEdit'>
        <Company.Header title={title} />
        {this.loadingOrForm()}
      </div>
    )
  }
}
export default withScreenWrapper(CompanyConfigEdit)

const styles = {
  content: {
    marginBottom: 72
  },
  footer: {
    position: 'fixed',
    width: '100%',
    paddingTop: 16,
    paddingBottom: 16,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255,255,255,.96)',
    border: '1px solid rgba(0,0,0,.16)',
    zIndex: 6
  }
}
