import React, { useState, useEffect } from 'react'
import { Box, FormControlLabel, Grid, Paper, Switch, TextField, Typography } from '@mui/material'
import { LoadingPleaseWait } from '@supportworks/react-components'
import CompanyConfigEdit from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'

const config = {
  title: 'Calendar Settings',
  variant: 'calendar',
  tag: '__root',
  name: 'Default Configuration',
  lockTag: true,
  lockName: true
}

const SettingsCalendar = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [contentJson, setContentJson] = useState()
  const [daysBack, setDaysBack] = useState(90)
  const [daysAhead, setDaysAhead] = useState(7)
  const [wonEstimate, setWonEstimate] = useState()
  const [lostEstimate, setLostEstimate] = useState()
  const [openEstimate, setOpenEstimate] = useState()
  const [assigned, setAssigned] = useState()
  const [finished, setFinished] = useState()
  const [inProgress, setInProgress] = useState()
  const [suspended, setSuspended] = useState()
  const [getShowCalendarDayView, setGetShowCalendarDayView] = useState(false)

  useEffect(() => {
    if (isLoading) {
      let cj = JSON.parse(props.config.contentJson)
      let c
      if (0 in cj) {
        c = cj[0].config
      } else {
        cj = [{ id: '', config: cj }]
        c = cj[0].config
      }

      if (c.calendarRange) {
        setDaysBack(c.calendarRange.daysBack)
        setDaysAhead(c.calendarRange.daysAhead)
      } else {
        c.calendarRange = {
          daysBack,
          daysAhead
        }
      }
      if (!c.status) {
        c.status = defaultStatus()
        props.onChange(JSON.stringify(cj))
      }
      if (c.getShowCalendarDayView) {
        setGetShowCalendarDayView(c.getShowCalendarDayView)
      }
      setWonEstimate(c.status.find(o => o.value === 'WonEstimate').label)
      setLostEstimate(c.status.find(o => o.value === 'LostEstimate').label)
      setOpenEstimate(c.status.find(o => o.value === 'OpenEstimate').label)
      setAssigned(c.status.find(o => o.value === 'Assigned').label)
      setFinished(c.status.find(o => o.value === 'Finished').label)
      setInProgress(c.status.find(o => o.value === 'InProgress').label)
      setSuspended(c.status.find(o => o.value === 'Suspended').label)
      setContentJson(cj)
      setIsLoading(false)
    }
  }, [daysAhead, daysBack, isLoading, props])

  const handleChange = (e) => {
    const cj = JSON.parse(JSON.stringify(contentJson))
    const name = e.target.name
    const value = e.target.value

    const c = cj[0].config
    if (name.match(/daysBack|daysAhead/)) {
      c.calendarRange[name] = value
      if (name === 'daysBack') {
        setDaysBack(value)
      } else {
        setDaysAhead(value)
      }
    } else if (name.match(/getShowCalendarDayView/)) {
      setGetShowCalendarDayView(!getShowCalendarDayView)
      c.getShowCalendarDayView = !getShowCalendarDayView
    } else {
      const idx = c.status.findIndex(o => o.value === name)
      if (idx > -1) {
        c.status[idx].label = value
      }
      if (name === 'WonEstimate') setWonEstimate(value)
      if (name === 'LostEstimate') setLostEstimate(value)
      if (name === 'OpenEstimate') setOpenEstimate(value)
      if (name === 'Assigned') setAssigned(value)
      if (name === 'Finished') setFinished(value)
      if (name === 'InProgress') setInProgress(value)
      if (name === 'Suspended') setSuspended(value)
    }
    setContentJson(cj)
    props.onChange(JSON.stringify(cj))
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <Box>
      <Paper sx={{ p: 2 }}>
        <Box>
          <Typography paragraph>Below are settings for the calendar within the app.</Typography>
        </Box>
        <Box sx={{ pb: 1 }}>
          <Typography variant='h6'>Enable Single Day View</Typography>
        </Box>
        <Box sx={{ pb: 2 }}>
          <Typography variant='body2'>
            When enabled, the app will only require the appointments from the current day to be loaded before the app will load. For companies using
            SolutionView integrated with a CRM, this will allow the app open before loading all of the appointments from the calendar range.
          </Typography>
          <FormControlLabel
            name='getShowCalendarDayView'
            control={
              <Switch
                color='primary'
                checked={getShowCalendarDayView}
                onChange={handleChange}
              />
            }
            label='Single Day View'
            labelPlacement='start'
            sx={{ marginLeft: '0px', marginTop: '8px' }}
          />
        </Box>
        <Box sx={{ pb: 1 }}>
          <Typography variant='h6'>Calendar Range</Typography>
        </Box>
        <Box sx={{ pb: 2 }}>
          <Typography variant='body2'>
            The calendar is populated with appointments assigned to a user from the company's CRM. Appointments that are completed and have fallen off of the
            calendar are saved in the cloud and can be accessed later.
          </Typography>
        </Box>
        <Box sx={{ pb: 2 }}>
          <Typography variant='body2'>By extending the range on the calendar, load times will take longer.</Typography>
        </Box>
        <Box sx={{ pb: 2 }}>
          <Grid container justifyContent='flex-start'>
            <Grid item sx={{ pr: 2 }}>
              <TextField id='daysBack' name='daysBack' value={daysBack} label='Days in the past' onChange={handleChange} />
            </Grid>
            <Grid item>
              <TextField id='daysAhead' name='daysAhead' value={daysAhead} label='Days in the future' onChange={handleChange} />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ pb: 1 }}>
          <Typography variant='h6'>Appointment Statuses</Typography>
        </Box>
        <Box sx={{ pb: 2 }}>
          <Typography variant='body2'>
            The calendar displays statuses on each appointment. Based on company preferences or CRM terminology, modify the statuses to reflect company
            preferences.
          </Typography>
        </Box>
        <Box sx={{ pb: 2 }}>
          <Grid container justifyContent='flex-start' sx={{ pb: 2 }}>
            <Grid item sx={{ pr: 2 }}>
              <TextField id='WonEstimate' name='WonEstimate' value={wonEstimate} label='Won Estimate Label' onChange={handleChange} />
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-start' sx={{ pb: 2 }}>
            <Grid item sx={{ pr: 2 }}>
              <TextField id='LostEstimate' name='LostEstimate' value={lostEstimate} label='Lost Estimate Label' onChange={handleChange} />
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-start' sx={{ pb: 2 }}>
            <Grid item sx={{ pr: 2 }}>
              <TextField id='OpenEstimate' name='OpenEstimate' value={openEstimate} label='Open Estimate Label' onChange={handleChange} />
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-start' sx={{ pb: 2 }}>
            <Grid item sx={{ pr: 2 }}>
              <TextField id='Assigned' name='Assigned' value={assigned} label='Assigned Label' onChange={handleChange} />
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-start' sx={{ pb: 2 }}>
            <Grid item sx={{ pr: 2 }}>
              <TextField id='Finished' name='Finished' value={finished} label='Finished Label' onChange={handleChange} />
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-start' sx={{ pb: 2 }}>
            <Grid item sx={{ pr: 2 }}>
              <TextField id='InProgress' name='InProgress' value={inProgress} label='In Progress Label' onChange={handleChange} />
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-start' sx={{ pb: 2 }}>
            <Grid item sx={{ pr: 2 }}>
              <TextField id='Suspended' name='Suspended' value={suspended} label='Suspended Label' onChange={handleChange} />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  )
}

const defaultStatus = () => {
  return [
    {
      value: 'WonEstimate',
      label: 'Sold',
      confirmed: true,
      canceled: false
    },
    {
      value: 'LostEstimate',
      label: 'Lost',
      confirmed: false,
      canceled: false
    },
    {
      value: 'OpenEstimate',
      label: '',
      confirmed: true,
      canceled: false
    },
    {
      value: 'Assigned',
      label: '',
      confirmed: true,
      canceled: false
    },
    {
      value: 'Finished',
      label: 'Completed',
      confirmed: true,
      canceled: false
    },
    {
      value: 'InProgress',
      label: '',
      confirmed: true,
      canceled: false
    },
    {
      value: 'Suspended',
      label: 'Canceled',
      confirmed: false,
      canceled: true
    }
  ]
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} editorComponent={SettingsCalendar} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}
