import React, { useState, useEffect } from 'react'
import { Drawer } from '@mui/material'
import UndoIcon from '@mui/icons-material/Undo'
import AddCircleIcon from '@mui/icons-material/AddCircleOutline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Api from '../../../library/Api'
import Search from '../../../components/Reusable/Search'
import LogicBanner from './Reusables/LogicBanner'
import DrawerHeader from './SolutionAutomation/DrawerHeader'
import DrawerRow from './Reusables/DrawerRow'
import '../../../css/solution-automation.css'

function IssueSelect ({ field, open, onSave, onClose, currOption, currIssues, logicBannerText }) {
  const [list, setList] = useState([])
  // const [issuesArr, setIssuesArr] = useState('issues' in field ? field.issues : [])
  const [filteredList, setFilteredList] = useState([])

  useEffect(() => {
    let mounted = true
    Api.callRegisteredMethod('getCurrentConfig', {
      tag: 'all',
      startsWith: false
    }).then((config) => {
      if (mounted) {
        const issues = []
        if (config && 'issues' in config) {
          for (let i = 0; i < config.issues.length; i++) {
            issues.push({ value: config.issues[i].id, name: config.issues[i].title, selected: false })
          }
        }

        /* issues.map((l, idx) => {
          currIssues.map((i, idx) => {
            if (Object.values(i).includes(l.value)) {
              l.selected = true
            }
          })
        }) */

        for (let x = 0; x < issues.length; x++) {
          for (let y = 0; y < currIssues.length; y++) {
            if (Object.values(currIssues[y].includes(issues[x].value))) {
              issues[x].selected = true
            }
          }
        }

        setList(issues)
        setFilteredList(issues)
      }
    })
    return () => (mounted = false)
  }, []) // eslint-disable-line

  const toggleDrawer = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    onClose()
  }

  const handleClick = (idx) => {
    const newArr = [...list]
    newArr[idx].selected = !newArr[idx].selected

    setList(newArr)
  }

  const handleSave = () => {
    const issuesSelected = []
    /* list.map((l) => {
      if (l.selected === true) {
        issuesSelected.push({ issueid: l.value, value: currOption })
      }
    }) */
    for (let i = 0; i < list.length; i++) {
      if (list[i].selected === true) {
        issuesSelected.push({ issueid: list[i].value, value: currOption })
      }
    }

    if (issuesSelected.length > 0) {
      onSave(issuesSelected)
    }
  }

  const handleClear = () => {
    const newArr = [...list]
    newArr.forEach((o) => (o.selected = false))

    // setIssuesArr([])
    setList(newArr)
  }

  const searchItems = (searchValue) => {
    const newArr = list.filter((item) => {
      return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase())
    })
    setFilteredList(newArr)
  }

  const selected = list.filter((o) => {
    return o.selected === true
  })

  return (
    <Drawer anchor='right' open={open} onClose={toggleDrawer(false)}>
      <DrawerHeader variant='issue' title={selected.length ? selected.length + ' issues selected' : 'Record Issues'} onClose={toggleDrawer(false)} />
      <div className='sa-container'>
        <div className='sa-section'>
          <LogicBanner>{logicBannerText}</LogicBanner>

          <Search
            onChange={(searchValue) => {
              searchItems(searchValue)
            }}
          />

          {filteredList.map((row, idx) => {
            return (
              <DrawerRow
                key={'issue' + idx}
                title={row.name}
                description={row.description ? row.description : null}
                image={row.image ? row.image : null}
                icon={
                                    row.selected ? <CheckCircleIcon /> : <AddCircleIcon />
                                }
                onClick={() => { handleClick(idx) }}
              />
            )
          })}

        </div>
      </div>
      <div className='issue-footer-container'>
        <span className='issue-footer-button' onClick={handleClear}>
          <UndoIcon />Clear
        </span>
        <span className='issue-footer-button' onClick={handleSave}>Add Issues</span>
      </div>
    </Drawer>
  )
}

export default IssueSelect
