import React, { useState, useEffect } from 'react'
import { Drawer, Button, FormControl, TextField, MenuItem } from '@mui/material'
import DrawerHeader from './DrawerHeader'
import MapFormFieldChooseField from './MapFormFieldChooseField'
import MapFormFieldNumericSpec from './MapFormFieldNumericSpec'
import MapFormFieldNonNumericSpec from './MapFormFieldNonNumericSpec'
import DrawerFooter from './DrawerFooter'
import '../../../../css/solution-automation.css'

function DrawerSpecAutomation ({ open: defaultOpen, onSave, onClose, scratch, branch, setProduct, product, formFields }) {
  const [isLoading, setIsLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const [configIdx, setConfigIdx] = useState('')
  const [mappedFieldId, setMappedFieldId] = useState()
  const [mappedFieldObject, setMappedFieldObject] = useState()
  const [specification, setSpecification] = useState() // single specification of the map field that was last selected
  const [specifications, setSpecifications] = useState()
  const [showMapFormField, setShowMapFormField] = useState(false)
  const [showSaveButton, setShowSaveButton] = useState(false) // eslint-disable-line 

  useEffect(() => {
    console.log('useEffect start [] triggered')
    if (isLoading) {
      // Set the array elements to strings for single level specs, like wallsAttic
      const config = product.config
      const specs = branch.specifications
      for (let x = 0; x < specs.length; x++) {
        if (!config[x] && specs[x].count === 1) {
          config[x] = specs[x].id
        }
        if (config[x] && typeof config[x] === 'string') {
          specs[x].manual = true
        } else {
          specs[x].manual = false
        }
      }
      setSpecifications(specs)
      setProduct({ ...product })
      setIsLoading(false)
    }
  }, []) // eslint-disable-line 

  useEffect(() => {
    console.log('useEffect mappedFieldId triggered')
    if (mappedFieldId) {
      const m = formFields.find(o => o.id === mappedFieldId)
      setMappedFieldObject(m)
    }
  }, [mappedFieldId]) // eslint-disable-line

  const handleSave = () => {
    onSave()
  }

  const handleChange = (event, idx) => {
    const { name, value } = event.target
    // console.log(JSON.stringify(product));
    if (name === 'formField') {
      setMappedFieldId(value)
      const c = {}
      const config = product.config
      if (config[idx]) {
        // Flip the thing back to the automated format
        if (typeof config[idx] === 'string') {
          config[idx] = { field: value, values: [] }
        }
        if (config[idx].field !== value) {
          config[idx].values = []
        }
        config[idx].field = value
      } else {
        c.field = value
        c.values = []
        config.push(c)
      }
      setProduct({ ...product, config })
    }
  }

  const toggleManualSet = (idx) => {
    const spec = specifications
    spec[idx].manual = !spec[idx].manual || false
    setSpecifications(spec)
    setProduct({ ...product }) // Force screen refresh (cheating!)
  }

  const toggleMapping = (idx) => {
    const config = product.config
    if (config[idx]) {
      setMappedFieldId(config[idx].field)

      const m = formFields.find(o => o.id === config[idx].field)
      setMappedFieldObject(m)

      setSpecification(branch.specifications[idx])
      setConfigIdx(idx)
      setShowMapFormField(true)
    }
  }

  const toggleDrawer = (event) => {
    event.preventDefault()
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    if (!showMapFormField) {
      // I really want to go back
      setIsOpen(false)
      onClose()
    } else {
      // Set the default display
      setShowMapFormField(false)
    }
  }

  const stopPropagationForTab = (event) => {
    // Tab in the Search and other input fields closes the whole drawer!!! Material UI bug or feature?
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  let mapFormField
  if (showMapFormField) {
    if (mappedFieldObject) {
      if (mappedFieldObject.type.match(/numeric/)) {
        mapFormField = (
          <MapFormFieldNumericSpec
            config={product.config[configIdx]}
            mappedFieldObject={mappedFieldObject}
            setProduct={setProduct}
            product={product}
            specification={specification}
            onBack={toggleDrawer}
          />
        )
      }
      if (mappedFieldObject.type.match(/radio|checkbox|choice|boolean|imageCheckbox/)) {
        mapFormField = (
          <MapFormFieldNonNumericSpec
            config={product.config[configIdx]}
            mappedFieldObject={mappedFieldObject}
            setProduct={setProduct}
            product={product}
            formFields={formFields}
            specification={specification}
            setShowSaveButton={setShowSaveButton}
          />
        )
      }
    }
  }

  if (isLoading) return <></>

  return (
    <Drawer
      key='DTA'
      anchor='right'
      variant='persistent'
      open={isOpen}
      onKeyDown={stopPropagationForTab}
      onClose={(event) => {
        toggleDrawer(event)
      }}
    >

      <div className='sa-container'>
        <DrawerHeader variant='solution' title={scratch.solutionTitle} onClose={toggleDrawer} />

        <div className='sa-breadcrumb-container'>
          <Button sx={{ pt: 1 }} color='primary' variant='text' onClick={toggleDrawer}><span className='sa-button'>&lt; Back</span></Button>
        </div>

        {showMapFormField
          ? (
            <>
              {mapFormField}
            </>
            )
          : (
            <div className='sa-section'>
              <div className='sa-spec-paragraph'>
                <span>
                  Below are the specifications for {branch.title}. Specify which fields will drive the specification.
                  Then add automation so that products populate as your team completes the inspection.
                </span>
              </div>

              {specifications.map((spec, idx) => {
                let completed = 0
                let formField = ''
                const config = product.config
                if (config && config[idx] && config[idx].field) {
                  formField = config[idx].field
                  const c = config[idx].values.filter((o) => o.config && o.value)
                  completed = c.length
                }

                // Has the previous index item not been selected?  If so, disable this one.
                let disabled = true
                if (idx === 0) disabled = false
                if (idx > 0) {
                  if (formField) {
                    if (typeof config[idx - 1] === 'string' || typeof config[idx - 1] === 'object') {
                      disabled = false
                    } else if (config[idx - 1].field) {
                      disabled = false
                    }
                  }
                }

                if (spec.count === 1) {
                  return (
                    <div key={'spec' + idx} className='sa-section'>
                      <div className='sa-heading'>
                        <span>{spec.title}</span>
                      </div>
                      <span className='sa-text12'>
                        This specification has only one option.  Due to this setup, there is no need to map to a form field.
                        Automation will be driven by the other specifications in the product group.
                      </span>
                    </div>
                  )
                }

                if (spec.manual === true) {
                  return (
                    <div key={'spec' + idx} className='sa-section'>
                      <div className='sa-heading'>
                        <span>{spec.title}</span>
                      </div>
                      <>
                        <MapFormFieldManual
                          configIdx={idx}
                          formField={config[idx]}
                          setProduct={setProduct}
                          product={product}
                          specification={spec}
                          setShowSaveButton={setShowSaveButton}
                        />
                        <div className='sa-button-right sa-text12'>
                          <span className='sa-link' onClick={() => toggleManualSet(idx)}>Reset Specification</span>
                        </div>
                        <div style={{ clear: 'both' }} />
                      </>
                    </div>
                  )
                } else {
                  const remaining = spec.count - completed
                  return (
                    <div key={'spec' + idx} className='sa-section'>
                      <div className='sa-heading'>
                        <span>{spec.title}</span>
                      </div>
                      <>
                        <MapFormFieldChooseField
                          formField={formField}
                          formFields={formFields}
                          setProduct={setProduct}
                          product={product}
                          toggleMapping={() => { toggleMapping(idx) }}
                          onChange={(e) => { handleChange(e, idx) }}
                          disabled={disabled}
                        />
                        <div className='sa-button-left sa-text12'>
                          {remaining > 0
                            ? <>{spec.count - completed} Specifications Remaining</>
                            : <>No Specifications Remaining</>}
                        </div>
                        <div className='sa-button-right sa-text12'>
                          <span className='sa-link' onClick={() => toggleManualSet(idx)}>Manually Set Specification</span>
                        </div>
                        <div style={{ clear: 'both' }} />
                      </>
                    </div>
                  )
                }
              })}
            </div>
            )}
      </div>
      <DrawerFooter onBack={toggleDrawer} onSave={handleSave} showSaveButton />
    </Drawer>
  )
}
export default DrawerSpecAutomation

const MapFormFieldManual = ({ configIdx = 0, formField: defaultValue, setProduct, product, specification, setShowSaveButton }) => {
  const [formField, setFormField] = useState(defaultValue)

  const handleChange = (event) => {
    const { value } = event.target
    setFormField(value)
    product.config[configIdx] = value
    setShowSaveButton(true)
    setProduct({ ...product })
  }

  return (
    <div className='sa-section'>
      <FormControl key='FormControl' name='formField' variant='outlined' fullWidth>
        <TextField
          select
          name='formField'
          label='Select Value'
          variant='outlined'
          value={formField || ''}
          onChange={handleChange}
        >
          {specification.choices.map((row, idx) => {
            return (
              <MenuItem key={`MFFMI-${idx}`} value={row.id}>{row.title} ({row.id})</MenuItem>
            )
          })}
        </TextField>
      </FormControl>
    </div>
  )
}
