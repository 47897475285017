/* eslint-disable array-callback-return */
import React from 'react'
import Company from './index'
import withScreenWrapper from '../withScreenWrapper'
import { Link } from 'react-router-dom'
import Api, { ApiBase } from 'library/Api'
import Permissions from 'library/Permissions'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'

class CompanyDashboard extends React.Component {
  constructor (props) {
    super(props)
    this.configTags = {
      Products: {
        products: { title: 'Master List', usesPackage: false, edit: true },
        pricing: { title: 'Price Lists', usesPackage: true, packageName: 'product_list', edit: true },
        productConfig: { title: 'Product Groups', usesPackage: false, edit: true },
        libraryProductMapping: { title: 'Product Mapping', usesPackage: false, edit: false }
      },
      'App Features': {
        aboutus: { title: 'About Us', usesPackage: true, packageName: 'about_us', edit: false },
        'contract.terms': { title: 'Accounting Details', usesPackage: true, packageName: 'contract_terms', edit: true },
        'contract.dealer': { title: 'Company Info', usesPackage: true, packageName: 'contract_dealer', edit: true },
        contract: { title: 'Contract Details', usesPackage: true, packageName: 'contract', edit: true },
        distributionList: { title: 'Distribution List', usesPackage: true, packageName: 'distributionList', edit: true },
        documentEditor: { title: 'Document Packets', usesPackage: true, packageName: 'pdfPlacement', edit: false },
        forms: { title: 'Forms', usesPackage: false, edit: false },
        appointment_type: { title: 'Meetings', usesPackage: true, packageName: 'appointment_type', edit: false },
        presentations: { title: 'Presentations', usesPackage: false, edit: false },
        solutions: { title: 'Solutions', usesPackage: false, edit: true }
      },
      'App Configuration': {
        __complete: { title: 'Build', usesPackage: false, edit: true },
        __differentiate: { title: 'Assign', usesPackage: false, edit: true }
      },
      Company: {
        assets2: { title: 'Assets', usesPackage: false, edit: false },
        billingDetails: { title: 'Billing', usesPackage: false, edit: false },
        crmFields: { title: 'CRM Fields', usesPackage: false, edit: true },
        settingsCustomerPortal: { title: 'Customer Portal', usesPackage: false, edit: true }
      }
    }
    this.state = {
      isLoading: true
    }
  }

  componentDidMount () {
    const cRef = this
    Promise.all([
      Api.callRegisteredMethod('getCurrentConfig', { tag: 'all', startsWith: false }).then((config) => {
        this.setState({
          completeConfig: config
        })
      }),
      Api.callRegisteredMethod('getConfigList', {}).then((configs) => {
        if (configs) {
          const publishedConfigCounts = {}
          const unPublishedConfigCounts = {}
          configs
            .filter((c) => c.status === 'Published')
            .map((c) => {
              if (!(c.tag in publishedConfigCounts)) publishedConfigCounts[c.tag] = 0
              publishedConfigCounts[c.tag] += 1
              return { tag: c.tag, value: c.configId, name: c.name }
            })
          configs
            .filter((c) => c.status === 'Unpublished')
            .map((c) => {
              if (!(c.tag in unPublishedConfigCounts)) unPublishedConfigCounts[c.tag] = 0
              unPublishedConfigCounts[c.tag] += 1
              return { tag: c.tag, value: c.configId, name: c.name }
            })
          cRef.setState({ unPublishedConfigCounts, publishedConfigCounts })
        } else {
          cRef.setState({ unPublishedConfigCounts: [], publishedConfigCounts: [] })
        }
      })
    ])
      .then((results) => {
        cRef.setState({ isLoading: false })
      })
      .catch((err) => console.log(err))
  }

  getDescription (tag, t) {
    if (t.usesPackage) {
      let used = false
      if (Array.isArray(this.state.completeConfig)) {
        this.state.completeConfig.map((c) => {
          if ('configSummary' in c.config) {
            if (t.packageName in c.config.configSummary) {
              used = true
            }
          }
        })
      } else {
        if (this.state.completeConfig && 'configSummary' in this.state.completeConfig) {
          if (t.packageName in this.state.completeConfig.configSummary) {
            used = true
          }
        }
      }
      if (used) {
        return 'Published'
      } else {
        return 'Uses Default'
      }
    } else {
      if (tag in this.state.publishedConfigCounts) {
        return 'Published'
      } else {
        if (tag in this.state.unPublishedConfigCounts) {
          return this.state.unPublishedConfigCounts[tag] + ' Not Published, Uses Default'
        } else {
          return 'Uses Default'
        }
      }
    }
  }

  render () {
    return (
      <div className='CompanyDashboard'>
        <Company.Header title='Dashboard' />
        {this.state.isLoading
          ? (
            <LoadingPleaseWait />
            )
          : (
            <div className='columns__two'>
              {Object.keys(this.configTags).map((main, ind) => {
                return (
                  <div className='actionsTable__wrapper' key={'tbl_' + ind}>
                    <h2>{main}</h2>
                    <table className='actionsTable'>
                      <thead>
                        <tr className='actionsTable__header'>
                          <th className='actionsTable__label'>Configuration</th>
                          <th className='actionsTable__label'>Status</th>
                          <th className='actionsTable__label'>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(this.configTags[main]).map((tag, idx) => {
                          return (
                            <tr className='actionsTable__row' key={'configsTag_' + idx}>
                              <td className='actionsTable__title'>{this.configTags[main][tag].title}</td>
                              <td className='actionsTable__title'>{this.getDescription(tag, this.configTags[main][tag])}</td>
                              <td className='actionsTable__actions'>
                                {Permissions.companyIsSelected() &&
                              (Permissions.hasRole('super_user') ||
                                (this.configTags[main][tag].edit && Permissions.hasRole('admin') && Permissions.hasPermission('edit_config')))
                                  ? (
                                    <Link to={ApiBase.resolvePath('/Company/:organizationId/Configs?variant=' + tag, this.props)}>
                                      <IconButton title='Edit' aria-label='edit'>
                                        <EditIcon variant='icon' fontSize='small' />
                                      </IconButton>
                                    </Link>
                                    )
                                  : null}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                )
              })}
            </div>
            )}
      </div>
    )
  }
}

export default withScreenWrapper(CompanyDashboard)
