import React from 'react'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography
  , Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import ExportIcon from '@mui/icons-material/FileUpload'
import ImportIcon from '@mui/icons-material/FileDownload'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import Api from '../../library/Api' // defined in CompanyConfigEdit
import CompanyConfigEdit, { CompanyConfigBaseEditor } from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import DataGrid from 'components/Reusable/DataGrid'
import Permissions from '../../library/Permissions'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { DialogSettingsProduct } from './Products/DialogSettingsProduct'
import { ProductEditor, flattenProductCategories } from './Products/ProductEditor'
import PriceListMinimum from './PriceList/PriceListMinimum'

const config = {
  title: 'Price Lists',
  exactTitle: true,
  variant: 'pricing',
  tag: 'pricing',
  name: 'Price Lists',
  lockTag: true,
  lockName: false,
  canClone: true
}

class ProductPricingEditor extends CompanyConfigBaseEditor {
  constructor (props) {
    super(props)

    const { contentJson, onDataLoading } = props
    onDataLoading(true)

    const productsPricing = []
    let adjustment = 100
    let minimums = {
      project: {
        amount: '',
        productId: ''
      },
      areas: []
    }

    if (typeof contentJson === 'string') {
      try {
        let jsonObj = JSON.parse(contentJson)
        if ('minimums' in jsonObj) minimums = jsonObj.minimums
        if ('adjustment' in jsonObj) {
          adjustment = jsonObj.adjustment
          jsonObj = jsonObj.product_list
        }
        for (const k in jsonObj) productsPricing.push(jsonObj[k])
      } catch (err) {
        console.log(err.message)
      }
    } else if (typeof contentJson === 'object') {
      let jsonObj = contentJson
      if ('minimums' in jsonObj) minimums = jsonObj.minimums
      if ('adjustment' in jsonObj) {
        adjustment = jsonObj.adjustment
        jsonObj = jsonObj.product_list
      }
      for (const k in jsonObj) productsPricing.push(jsonObj[k])
    } else if (contentJson !== undefined) {
      try {
        let jsonObj = JSON.parse(contentJson)
        if ('minimums' in jsonObj) minimums = jsonObj.minimums
        if ('adjustment' in jsonObj) {
          adjustment = jsonObj.adjustment
          jsonObj = jsonObj.product_list
        }
        for (const j in jsonObj) productsPricing.push(jsonObj[j])
      } catch (err) {
        console.log(err.message)
      }
    }

    this.state = {
      isLoading: true,
      showSettings: false,
      showProductEditor: false,
      showPriceListMinimum: false,
      productsPricing: productsPricing.sort((a, b) => (a.id > b.id ? 1 : -1)),
      filter: '',
      productsList: null,
      version: 1,
      hidden: false,
      importedProducts: [],
      importSummary: [],
      showImportConfirm: false,
      updatedProducts: 0,
      globalPriceChange: {
        validAmount: false,
        amount: adjustment,
        showDialog: false
      },
      productFeatures: props.productFeatures || {},
      assetsConfig: null,
      formsConfig: null,
      displaySettings: {},
      flatCat: [],
      selectedProduct: null,
      masterProduct: null,
      minimums
    }
  }

  async componentDidMount () {
    super.componentDidMount()

    let displaySettings
    await Api.callRegisteredMethod('getLatestConfigByTag', { tag: 'displaySettings', startsWith: false }).then((c) => {
      if (c && c.contentJson) {
        displaySettings = JSON.parse(c.contentJson)
      }
    })

    const pricingList = {}
    await Api.callRegisteredMethod('getConfigPricingList', { name: this.props.config.name }).then((c) => {
      if (c.length === 1 && c[0] !== null && c[0].length === 1 && c[0][0].list && c[0][0].blocks && c[0][0].list.length > 0) {
        const raw = c[0][0].list
        // eslint-disable-next-line array-callback-return
        raw.map((p) => {
          pricingList[p.crmId] = p
          pricingList[p.crmId].blocks = []
        })
        // eslint-disable-next-line array-callback-return
        c[0][0].blocks.map((b) => {
          if (b.crmId in pricingList) {
            pricingList[b.crmId].blocks.push(b)
          }
        })
      }
    })

    const adjustment = this.state.globalPriceChange.amount
    if (this.state.productFeatures && this.state.productFeatures.product_categories) {
      const flat = flattenProductCategories(this.state.productFeatures.product_categories, [])
      this.setState({ flatCat: flat })
    }

    await Api.callRegisteredMethod('getAssetList', {}).then((assetsConfig) => {
      this.setState({ assetsConfig: assetsConfig.sort((a, b) => (a.name > b.name ? 1 : -1)) })
    })

    const Config = await Api.callRegisteredMethod('getCurrentConfig', { tag: 'all', startsWith: false })
    this.setState({ isLoading: false, formsConfig: Config.forms })

    Api.callRegisteredMethod('getConfigListByTag', {
      tag: 'products',
      startsWith: false
    }).then((configs) => {
      const res = Math.max.apply(
        Math,
        configs.map(function (o) {
          return o.configId
        })
      ) // finds greatest configId value
      const mostRecent = configs.filter((obj) => {
        // gets most recent Product config //Might not be needed, only being used now for orgId
        return obj.configId === res
      })
      const org = mostRecent[0].organizationId

      Api.callRegisteredMethod('getConfigById', {
        configId: res,
        organization: org
      }).then((prodProps) => {
        const productsList = []
        if (prodProps.data[0].contentJson) {
          const list = JSON.parse(prodProps.data[0].contentJson)
          list.forEach((product) => {
            if (product.id) {
              productsList.push(product)
            }
          })
        }
        let productsPricing = []

        /**
         * The whole point of this following code is to set override ([field]_ovrd) keys on things that we want to be able
         * to override in the Price Lists screen.
         */
        const fields = ['title', 'price', 'units', 'tax', 'discount', 'description', 'categories', 'properties', 'warranty']
        productsList.forEach((productsMaster) => {
          let found = false
          this.state.productsPricing.forEach((product) => {
            if (product.id === productsMaster.id) {
              const p = JSON.parse(JSON.stringify(product))

              // Can't modify these in the pricing list, but we want to display
              //
              p.drawingConfig = productsMaster.drawingConfig

              // Keep the override values, if the field is an override type field and it's set to true.
              //
              // If the _ovrd is FALSE, we want to set it to master!
              //
              fields.forEach((f) => {
                if (!(f + '_ovrd' in p)) p[f + '_ovrd'] = true
                if (p[f + '_ovrd'] === false) {
                  if (f === 'price') {
                    p[f] = parseFloat((productsMaster[f] * (adjustment / 100)).toFixed(2))
                  } else {
                    p[f] = productsMaster[f]
                  }
                }
              })

              if (!('pricingOptions' in p)) p.pricingOptions = {}
              if (!('min_price_ovrd' in p)) p.min_price_ovrd = true
              if (p.min_price_ovrd === false) {
                if (!('min_price' in p.pricingOptions)) {
                  p.pricingOptions.min_price = 0
                }
                if (productsMaster.pricingOptions && 'min_price' in productsMaster.pricingOptions) {
                  p.pricingOptions.min_price = parseFloat((productsMaster.pricingOptions.min_price * (adjustment / 100)).toFixed(2))
                }
              }

              ['eplh', 'margin', 'salesCommission'].forEach((f) => {
                if (!(f + '_ovrd' in p)) p[f + '_ovrd'] = true
                if (p[f + '_ovrd'] === false) {
                  if ('pricingOptions' in productsMaster) {
                    if (productsMaster.pricingOptions && productsMaster.pricingOptions[f]) {
                      p.pricingOptions[f] = productsMaster.pricingOptions[f]
                    }
                  }
                }
              })

              if (!('tier_ovrd' in p)) p.tier_ovrd = true
              if (p.tier_ovrd === false) {
                if ('pricingOptions' in productsMaster) {
                  if ('inc_tier' in productsMaster.pricingOptions) {
                    p.pricingOptions.inc_tier = JSON.parse(JSON.stringify(productsMaster.pricingOptions.inc_tier)).map((t) => {
                      t.price = parseFloat((t.price * (adjustment / 100)).toFixed(2))
                      return t
                    })
                  }
                  if ('unit_tier' in productsMaster.pricingOptions) {
                    p.pricingOptions.unit_tier = JSON.parse(JSON.stringify(productsMaster.pricingOptions.unit_tier)).map((t) => {
                      t.price = parseFloat((t.price * (adjustment / 100)).toFixed(2))
                      return t
                    })
                  }
                }
              }
              if (!('available' in p)) p.available = true
              if (!found) productsPricing.push(p)
              found = true
            }
          })
          if (!found) {
            const pm = JSON.parse(JSON.stringify(productsMaster))
            pm.available = true
            fields.forEach((f) => {
              pm[f + '_ovrd'] = false
            })
            pm.tier_ovrd = false;
            ['eplh', 'margin'].forEach((f) => {
              pm[f + '_ovrd'] = false
            })
            if (!('pricingOptions' in pm)) {
              pm.pricingOptions = {
                min_price: 0
              }
            }
            if (!('min_price' in pm.pricingOptions)) {
              pm.pricingOptions.min_price = 0
            }
            if (!('available' in pm)) pm.available = true
            productsPricing.push(pm)
          }
        })
        const setUnavailable = Object.keys(pricingList).length > 0
        productsPricing = productsPricing.map((p) => {
          if (p.crmId in pricingList) {
            p.price = pricingList[p.crmId].price
            p.price_ovrd = true
            try {
              const blocks = pricingList[p.crmId].blocks.sort((a, b) => (parseFloat(a.floor) > parseFloat(b.floor) ? 1 : -1))
              if (blocks.length > 0) {
                if (!('pricingOptions' in p) || Array.isArray(p.pricingOptions)) {
                  p.pricingOptions = {}
                  p.pricingOptions.unit_tier = []
                  p.tier_ovrd = true
                }
                if ('unit_tier' in p.pricingOptions) {
                  p.tier_ovrd = true
                  p.pricingOptions.unit_tier = []
                  // eslint-disable-next-line array-callback-return
                  blocks.map((b, idx) => {
                    if (idx === 0) {
                      p.pricingOptions.unit_tier.push({ price: b.price })
                    } else {
                      p.pricingOptions.unit_tier.push({ price: b.price, break: b.floor })
                    }
                  })
                }
                if ('inc_tier' in p.pricingOptions) {
                  p.tier_ovrd = true
                  p.pricingOptions.inc_tier = []
                  // eslint-disable-next-line array-callback-return
                  blocks.map((b, idx) => {
                    if (idx === blocks.length - 1) {
                      p.pricingOptions.inc_tier.push({ price: b.price })
                    } else {
                      p.pricingOptions.inc_tier.push({ price: b.price, break: blocks[idx + 1].floor - (parseFloat(blocks[idx].floor) === 0 ? 1 : blocks[idx].floor) })
                    }
                  })
                }
              }
            } catch (e) {}
          } else {
            if (setUnavailable) {
              p.available = false
            }
          }
          return p
        })
        this.setState({
          isLoading: false,
          displaySettings,
          masterList: productsList,
          productsList,
          productsPricing: productsPricing.sort((a, b) => (a.crmId > b.crmId ? 1 : -1))
        })
        this.props.onDataLoading(false)
      })
    })
  }

  handleSave = (product, flatCat) => {
    const productsPricing = JSON.parse(JSON.stringify(this.state.productsPricing))
    const idx = productsPricing.map((o) => o.id).indexOf(product.id)
    if (idx > -1) {
      if ('available' in this.state.productsPricing[idx]) {
        product.available = this.state.productsPricing[idx].available
        product.available_ovrd = this.state.productsPricing[idx].available_ovrd
      } else {
        product.available = true
        product.available_ovrd = true
      }
      productsPricing[idx] = product
      // Update flatCat dictionary on changes so we can display the title on the index page correctly.
      if (JSON.stringify(flatCat) !== JSON.stringify(this.state.flatCat)) {
        this.setState({ flatCat })
      }
      this.setState({
        productsPricing,
        version: this.state.version + 1,
        selectedProduct: null,
        masterProduct: null,
        showProductEditor: !this.state.showProductEditor
      })
      this.props.onChange(JSON.stringify(productsPricing)) // Trigger unsaved changes.
    }
  }

  handleClose = () => {
    this.setState({ selectedProduct: null, masterProduct: null, showProductEditor: !this.state.showProductEditor })
  }

  handleSaveMinimums = (minimums) => {
    const newMinimums = JSON.parse(JSON.stringify(minimums))
    this.setState({ minimums: newMinimums, showPriceListMinimum: !this.state.showPriceListMinimum })
  }

  handleChangeAvailable = (idx) => {
    const p = JSON.parse(JSON.stringify(this.state.productsPricing))
    p[idx].available = !p[idx].available
    p[idx].available_ovrd = true
    this.setState({ productsPricing: p, version: this.state.version + 1 })
    this.props.onSetStatus('unSavedChanges', true)
  }

  handleExport = () => {
    const productsPricing = this.state.productsPricing
    let exportJSON = productsPricing.map((elm) => ({
      Available: elm.available ? 'Yes' : 'No',
      SKU: elm.crmId,
      Title: elm.title,
      Description: elm.description,
      Price: elm.price,
      Units: elm.units,
      Tax: elm.tax ? 'Yes' : 'No',
      Discount: elm.discount ? 'Yes' : 'No'
    }))
    // Check if there are any products with duplicate SKUs
    const duplicates = []
    const unique = []
    exportJSON.forEach((elm, i) => {
      if (unique.indexOf(elm.SKU) === -1) {
        unique.push(elm.SKU)
      } else {
        duplicates.push(elm.SKU)
      }
    })
    if (duplicates.length > 0) {
      exportJSON = productsPricing.map((elm) => ({
        Available: elm.available ? 'Yes' : 'No',
        SKU: elm.crmId,
        Title: elm.title,
        Description: elm.description,
        Price: elm.price,
        Units: elm.units,
        Tax: elm.tax ? 'Yes' : 'No',
        Discount: elm.discount ? 'Yes' : 'No',
        UID: elm.id
      }))
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(exportJSON)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    // Get pricelist name

    FileSaver.saveAs(data, `Pricelist_${this.getDateString()}` + fileExtension)
  }

  // User has clicked the "Import" button. Open file dialog and file handler
  handleImport = async () => {
    const fileUpload = document.getElementById('fileButton')
    if (fileUpload.files) {
      fileUpload.value = null
    }

    fileUpload.click()
    fileUpload.onchange = (e) => {
      // let fileName = fileUpload.files[0].name;
      if (typeof FileReader !== 'undefined') {
        // eslint-disable-next-line no-undef
        const reader = new FileReader()
        if (reader.readAsBinaryString) {
          reader.onload = (e) => {
            this.processExcel(reader.result)
          }
          reader.readAsBinaryString(fileUpload.files[0])
        }
      }
    }
  }

  // Use XLXS to parse the file and build a JSON object for the import
  processExcel (data) {
    const workbook = XLSX.read(data, { type: 'binary' })
    const firstSheet = workbook.SheetNames[0]
    const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet], { raw: false })
    const clonedArr = Array.from(this.state.productsPricing)
    const newProducts = excelRows.map((elm, idx) => {
      const obj = {}
      if ('Available' in elm) {
        obj.available = elm.Available === 'Yes'
      }
      if ('SKU' in elm) {
        obj.crmId = String(elm.SKU)
      }
      if ('Title' in elm) {
        obj.title = elm.Title
      }
      if ('Price' in elm) {
        obj.price = this.getFloat(elm.Price)
      }
      if ('Description' in elm) {
        obj.description = elm.Description
      }
      if ('Tax' in elm) {
        obj.tax = elm.Tax === 'Yes'
      }
      if ('Discount' in elm) {
        obj.discount = elm.Discount === 'Yes'
      }
      if ('Units' in elm) {
        obj.units = elm.Units
      }
      if ('UID' in elm) {
        obj.UID = elm.UID
      }
      return obj
    })
    // Assign state and trigger the handlePreviewImport() function
    this.setState({ importedProducts: newProducts })
    this.handlePreviewImport(clonedArr, newProducts)
    this.props.onSetStatus('unSavedChanges', true)
  }

  getFloat (value) {
    const num = parseFloat(value, 10)
    return isNaN(num) ? 0 : `${num}`
  }

  handlePreviewImport (oldArrayOfObjects, newArrayOfObjects) {
    // Build array of objects with old/new values for the user to see
    const previewArray = []

    const comparableFields = ['available', 'title', 'price', 'units', 'description', 'tax', 'discount']
    let matchByUID = false
    if (newArrayOfObjects.some((elm) => elm.UID)) {
      matchByUID = true
    }
    newArrayOfObjects.forEach((newProduct) => {
      const matchedProduct = oldArrayOfObjects.find((oldProduct) => (matchByUID ? oldProduct.id === newProduct.UID : oldProduct.crmId === newProduct.crmId))
      if (matchedProduct) {
        let changed = false
        comparableFields.forEach((field) => {
          if (field in newProduct && newProduct[field] !== matchedProduct[field]) {
            changed = true
          }
        })
        if (changed) {
          previewArray.push({
            old: matchedProduct,
            new: newProduct
          })
        } else {
          // previewArray.push({
          //   old: matchedProduct,
          //   new: null,
          // });
        }
      }
    })
    this.setState({
      importSummary: previewArray,
      showImportConfirm: true
    })
  }

  handleImportConfirm () {
    // User has clicked the confirm button and we will now import the products into the main products price state
    const clonedArr = Array.from(this.state.masterList)
    const newArray = []
    this.state.productsPricing.forEach((_element) => {
      const obj = JSON.parse(JSON.stringify(_element))
      if (this.state.importedProducts.filter((p) => {
        if (p.UID && p.UID.length > 0) {
          return _element.id === p.UID
        } else {
          return _element.crmId === p.crmId
        }
      }).length === 0) {
        newArray.push(obj)
      }
    })
    this.state.importedProducts.forEach((p) => {
      let i = 0
      if (p.UID && p.UID.length > 0) {
        // Unique ID found in the imported json
        i = clonedArr.findIndex((_element) => _element.id === p.UID)
        if (i > -1) {
          const obj = JSON.parse(JSON.stringify(clonedArr[i]))
          if ('available' in p) obj.available = p.available
          if ('categories' in p) obj.categories = p.categories
          if ('description' in p) {
            obj.description = p.description
            obj.description_ovrd = true
          }
          if ('discount' in p) {
            obj.discount = p.discount
            obj.discount_ovrd = true
          }
          if ('price' in p) {
            obj.price = p.price
            obj.price_ovrd = true
          }
          if ('tax' in p) {
            obj.tax = p.tax
            obj.tax_ovrd = true
          }
          if ('title' in p) {
            obj.title = p.title
            obj.title_ovrd = true
          }
          if ('units' in p) {
            obj.units = p.units
            obj.units_ovrd = true
          }
          newArray.push(obj)
        }
      } else {
        // Unique ID not found in the imported json
        i = clonedArr.findIndex((_element) => _element.crmId === p.crmId)
        if (i > -1) {
          const obj = JSON.parse(JSON.stringify(clonedArr[i]))
          if ('available' in p) obj.available = p.available
          if ('categories' in p) obj.categories = p.categories
          if ('description' in p) {
            obj.description = p.description
            obj.description_ovrd = true
          }
          if ('discount' in p) {
            obj.discount = p.discount
            obj.discount_ovrd = true
          }
          if ('price' in p) {
            obj.price = p.price
            obj.price_ovrd = true
          }
          if ('tax' in p) {
            obj.tax = p.tax
            obj.tax_ovrd = true
          }
          if ('title' in p) {
            obj.title = p.title
            obj.title_ovrd = true
          }
          if ('units' in p) {
            obj.units = p.units
            obj.units_ovrd = true
          }
          newArray.push(obj)
        }
      }
    })
    // Update the main products price state, up the version number and close the confirm modal
    this.setState({
      productsPricing: newArray,
      version: this.state.version + 1,
      showImportConfirm: false
    })
  }

  /** New product editor handlers 07/2022.  Need to deprecate above on approval. */
  handleProductSelect = (p) => {
    const mp = this.state.productsList?.find((o) => o.id === p.id)
    this.setState({ selectedProduct: p, masterProduct: mp, showProductEditor: !this.state.showProductEditor })
  }

  restoreDefaults = (ind) => {
    const clonedArr = JSON.parse(JSON.stringify(this.state.productsPricing))
    const fields = ['title', 'price', 'units', 'tax', 'discount', 'description']
    this.state.productsList.forEach((p) => {
      if (clonedArr[ind].id === p.id) {
        fields.forEach((f) => {
          clonedArr[ind][f + '_ovrd'] = false
          clonedArr[ind][f] = p[f]
        })
      }
    })
    this.setState({
      productsPricing: clonedArr,
      version: this.state.version + 1
    })
  }

  // used by "available" filter to capitalize booleans, works with strings as well
  capitalize = (s) => {
    if (typeof s === 'string') {
      return s.charAt(0).toUpperCase() + s.slice(1)
    } else if (typeof s === 'boolean') {
      const toStr = s.toString()
      return toStr.charAt(0).toUpperCase() + toStr.slice(1)
    } else {
      return s
    }
  }

  // changes wording of chips created by "available" filter to be more helpful than 'true' and 'false'
  customChips = (s) => {
    if (s === false) {
      return 'Unavailable'
    } else if (s === true) {
      return 'Available'
    } else {
      return s
    }
  }

  // Used for file name timestamp
  getDateString () {
    const date = new Date()
    const year = date.getFullYear()
    const month = `${date.getMonth() + 1}`.padStart(2, '0')
    const day = `${date.getDate()}`.padStart(2, '0')
    return `${year}${month}${day}`
  }

  // Price change function globl
  priceChange = (confirm, amount) => {
    const cRef = this
    if (amount > -100) {
      if (confirm) {
        const clonedArr = JSON.parse(JSON.stringify(this.state.productsPricing))
        clonedArr.forEach((p) => {
          let masterProduct = cRef.state.masterList.filter((mp) => mp.id === p.id)
          if (masterProduct.length === 1) {
            masterProduct = masterProduct[0]
            if (p.price_ovrd === false) {
              p.price = parseFloat((masterProduct.price * (amount / 100)).toFixed(2))
            }
            if (p.tier_ovrd === false) {
              if ('pricingOptions' in masterProduct) {
                if ('min_price' in masterProduct.pricingOptions) {
                  p.min_price = parseFloat((masterProduct.min_price * (amount / 100)).toFixed(2))
                }
                if ('inc_tier' in masterProduct.pricingOptions) {
                  p.pricingOptions.inc_tier = JSON.parse(JSON.stringify(masterProduct.pricingOptions.inc_tier)).map((t) => {
                    t.price = parseFloat((t.price * (amount / 100)).toFixed(2))
                    return t
                  })
                } else {
                  delete p.pricingOptions.inc_tier
                }
                if ('unit_tier' in masterProduct.pricingOptions) {
                  p.pricingOptions.unit_tier = JSON.parse(JSON.stringify(masterProduct.pricingOptions.unit_tier)).map((t) => {
                    t.price = parseFloat((t.price * (amount / 100)).toFixed(2))
                    return t
                  })
                } else {
                  delete p.pricingOptions.unit_tier
                }
              }
            }
          }
        })
        this.setState({
          productsPricing: clonedArr,
          version: this.state.version + 1,
          globalPriceChange: {
            amount: this.state.globalPriceChange.amount,
            showDialog: false
          }
        })
      } else {
        this.setState({
          globalPriceChange: {
            ...this.state.globalPriceChange,
            showDialog: true
          }
        })
      }
    } else {
      // console.log('Invalid price change amount')
    }
  }

  finalize () {
    return JSON.stringify({ adjustment: this.state.globalPriceChange.amount, minimums: this.state.minimums, product_list: this.state.productsPricing })
  }

  hasFinalize () {
    return true // true to enable saving
  }

  render () {
    if (this.state.isLoading) return <LoadingPleaseWait />

    const columns = [
      {
        flex: 1,
        type: 'boolean',
        field: 'available',
        headerName: 'Available',
        headerAlign: 'left',
        align: 'left',
        editable: true,
        renderCell: (params) => {
          return (
            <Checkbox
              checked={params.value}
              onChange={() => {
                this.handleChangeAvailable(params.row.id)
              }}
            />
          )
        }
      },
      {
        flex: 2,
        type: 'string',
        field: 'title',
        headerName: 'Title',
        editable: false,
        sortable: true
      },
      {
        flex: 2,
        type: 'string',
        field: 'categories',
        headerName: 'Categories',
        editable: false,
        sortable: false,
        renderCell: (params) => {
          const categories = params.row.categories || []
          return (
            <Box sx={{ maxWidth: '100%', overflow: 'hidden' }}>
              {categories.map((cat, idx) => (
                <Chip key={`chip-${idx}`} label={cat} size='small' sx={{ pr: 0.25, mb: 0.25 }} />
              ))}
            </Box>
          )
        }
      },
      {
        flex: 1,
        type: 'string',
        field: 'tax',
        headerName: 'Tax',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'discount',
        headerName: 'Discount',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'number',
        field: 'price',
        headerName: 'Display Price',
        editable: false,
        sortable: true,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => {
          return <Box>${Number(params.value).toFixed(2)}</Box>
        }
      },
      {
        flex: 1,
        type: 'string',
        field: 'unit_type',
        headerName: 'Unit Type',
        editable: false,
        sortable: true
      },
      {
        width: 124, // Do not allow truncation of the 3 icons when resizing
        field: 'action',
        headerName: 'Actions',
        headerAlign: 'right',
        align: 'right',
        editable: false,
        sortable: false,
        disableExport: true,
        renderCell: (params) => {
          if (params.row) {
            const p = params.row.product
            return (
              <Grid container wrap='nowrap' justifyContent='flex-end' style={{ overflow: 'visible' }}>
                <Grid item>
                  <IconButton
                    title='Edit'
                    aria-label='edit'
                    onClick={() => {
                      this.handleProductSelect(p)
                    }}
                  >
                    <EditIcon fontSize='small' />
                  </IconButton>
                </Grid>
              </Grid>
            )
          }
        }
      }
    ]

    const rows = []
    for (let x = 0; x < this.state.productsPricing.length; x++) {
      const p = this.state.productsPricing[x]
      const categories = []
      for (let j = 0; j < p.categories.length; j++) {
        const cat = p.categories[j]
        const pc = this.state.flatCat.find((o) => o.id === cat)
        categories.push(pc && pc.title ? pc.title : cat)
      }
      rows.push({
        id: x,
        product: p,
        available: p.available,
        title: p.title,
        categories,
        tax: p.tax ? 'Yes' : 'No',
        discount: p.discount ? 'Yes' : 'No',
        price: p.price,
        unit_type: p.units
      })
    }

    return (
      <Box>
        <DataGrid
          rows={rows}
          columns={columns}
          useStandardRowHeight={false}
          showToolbarDensity={false}
          onShowSettings={() => {
            this.setState({ showSettings: !this.state.showSettings })
          }}
          showToolbarExport={false}
        />

        {this.state.showProductEditor
          ? (
            <ProductEditor
              variant='pricing'
              organizationId={this.props.config.organizationId}
              productFeatures={this.state.productFeatures}
              assetsConfig={this.state.assetsConfig}
              displaySettings={this.state.displaySettings}
              products={this.state.productsList}
              crmProducts={this.state.productsList}
              product={this.state.selectedProduct}
              masterProduct={this.state.masterProduct}
              isCRM={this.props.isCRM}
              onClose={this.handleClose}
              onSave={this.handleSave}
            />
            )
          : null}

        {this.state.showSettings
          ? (
            <DialogSettingsProduct
              organizationId={this.props.config.organizationId}
              onClose={(displaySettings) => {
                this.setState({ displaySettings, showSettings: !this.state.showSettings })
              }}
            />
            )
          : null}

        {this.state.showPriceListMinimum && (
          <PriceListMinimum
            productsList={this.state.productsList}
            formsConfig={this.state.formsConfig}
            minimums={this.state.minimums}
            onSave={(minimums) => this.handleSaveMinimums(minimums)}
            onClose={() => {
              this.setState({ showPriceListMinimum: !this.state.showPriceListMinimum })
            }}
          />
        )}

        {/* Count importSummary items that have .new values */}
        <Dialog open={this.state.showImportConfirm} onClose={() => this.setState({ showImportConfirm: false })} maxWidth='lg'>
          {this.state.importSummary.filter((item) => item.new).length > 0
            ? (
              <DialogTitle>The following {this.state.importSummary.filter((item) => item.new).length} products will be updated.</DialogTitle>
              )
            : (
              <DialogTitle>We could not find any updates in the provided file.</DialogTitle>
              )}

          <DialogContent>
            {this.state.importSummary.filter((item) => item.new).length > 0 && (
              <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label='import preview table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>SKU</TableCell>
                      <TableCell>Available</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Units</TableCell>
                      <TableCell>Taxable</TableCell>
                      <TableCell>Discount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.importSummary.map((product, index) => {
                      const rowsWeCareAbout = ['crmId', 'available', 'title', 'description', 'price', 'units', 'tax', 'discount']

                      if (product.new !== null) {
                        return (
                          <TableRow key={index}>
                            {rowsWeCareAbout.map((row, index) => {
                              if (product.new[row] === undefined) {
                                return (<TableCell key={index} />)
                              }
                              return (
                                <TableCell key={index}>
                                  {/* If product.old[row] !== product.new[row] then display both values in the cell */}
                                  {/* eslint-disable-next-line multiline-ternary */}
                                  {product.old[row] !== product.new[row] ? (
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        justifyContent: 'center'
                                      }}
                                    >
                                      <span
                                        style={{
                                          backgroundColor: '#FFE0DD',
                                          borderRadius: '5px',
                                          paddingTop: '2px',
                                          paddingBottom: '2px',
                                          paddingLeft: '10px',
                                          paddingRight: '10px',
                                          marginBottom: '5px',
                                          textDecoration: 'line-through'
                                        }}
                                      >
                                        {/* If typeof product.old[row] is boolean, convert to Yes/No */}
                                        {typeof product.old[row] === 'boolean' ? (product.old[row] ? 'Yes' : 'No') : product.old[row]}
                                      </span>

                                      <span
                                        style={{
                                          backgroundColor: '#DBFADB',
                                          borderRadius: '5px',
                                          paddingTop: '2px',
                                          paddingBottom: '2px',
                                          paddingLeft: '10px',
                                          paddingRight: '10px'
                                        }}
                                      >
                                        {typeof product.new[row] === 'boolean' ? (product.new[row] ? 'Yes' : 'No') : product.new[row]}
                                      </span>
                                    </div>
                                  ) : (
                                    <span>{typeof product.old[row] === 'boolean' ? (product.old[row] ? 'Yes' : 'No') : product.old[row]}</span>
                                  )}
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        )
                      } else {
                        // eslint-disable-next-line
                        return <></>
                      }
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </DialogContent>
          <DialogContent>Are you sure you want to continue?</DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ showImportConfirm: false })} color='secondary'>
              Cancel
            </Button>
            <Button color='primary' onClick={() => this.handleImportConfirm()}>
              Import
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.globalPriceChange.showDialog}>
          <DialogTitle>Confirm Price Change</DialogTitle>
          <DialogContent>
            By clicking ok, you will be setting all prices that have not been unlocked to {('' + this.state.globalPriceChange.amount).replace('-', '')}% of
            their price on the master list.
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState({
                  globalPriceChange: {
                    ...this.state.globalPriceChange,
                    showDialog: false
                  }
                })}
              color='secondary'
            >
              Cancel
            </Button>
            <Button color='primary' onClick={() => this.priceChange(true, this.state.globalPriceChange.amount)}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item container>
            <Grid item>
              <Button fullWidth id='export-action' variant='contained' onClick={this.handleExport} startIcon={<ExportIcon />}>
                Export
              </Button>
            </Grid>

            {Permissions.hasRole('super_user')
              ? (
                <Grid item sx={{ pl: 1 }}>
                  <input id='fileButton' type='file' accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' hidden />
                  <Button fullWidth sx={{ mr: 1 }} id='import-action' variant='contained' onClick={this.handleImport} startIcon={<ImportIcon />}>
                    Import
                  </Button>
                </Grid>
                )
              : null}

            <Grid item sx={{ pl: 3 }}>
              <Typography
                style={{
                  fontWeight: 500,
                  top: '50%',
                  position: 'relative',
                  margin: '0',
                  transform: 'translateY(-50%)',
                  color: 'black'
                }}
                variant='body1'
              >
                Master Product List Price
              </Typography>
            </Grid>

            <Grid item sx={{ pl: 1 }}>
              <TextField
                id='globalPriceChange'
                name='globalPriceChange'
                variant='outlined'
                size='small'
                value={this.state.globalPriceChange.amount}
                onChange={(e) => {
                  if (e.target.value === '' || /^-?\d*(\.\d*)?$/.test(e.target.value)) {
                    this.setState({
                      globalPriceChange: {
                        ...this.state.globalPriceChange,
                        amount: e.target.value,
                        validAmount: !!(e.target.value > -100 && e.target.value !== 0)
                      }
                    })
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Typography>%</Typography>
                    </InputAdornment>
                  )
                }}
                placeholder='100'
                style={{ width: '100px' }}
              />
            </Grid>
            <Grid item sx={{ pl: 1 }}>
              <Button
                style={{ width: '120px' }}
                id='gpc-action'
                color='primary'
                variant='contained'
                onClick={(e) => {
                  e.preventDefault()
                  if (this.state.globalPriceChange.validAmount) {
                    this.priceChange(false, this.state.globalPriceChange.amount)
                  }
                }}
              >
                Apply
              </Button>
            </Grid>

            <Grid item sx={{ pl: 3 }}>
              <Button
                id='priceListMinimum'
                color='primary'
                variant='contained'
                onClick={() => {
                  this.setState({ showPriceListMinimum: !this.state.showPriceListMinimum })
                }}
                // sx={{float: "right"}}
              >
                Price List Minimums
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} editorComponent={ProductPricingEditor} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}
