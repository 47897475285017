import React from 'react'
import withScreenWrapper from '../withScreenWrapper'

/**
 * Shows a "Resource Not Found" error
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
function ResourceNotFound (props) {
  return (
    <div className='ResourceNotFound'>
      {props.message
        ? props.message
        : <p className='lead'>We couldn't locate the requested resource. If you think this is an error, please contact Tech Support and send the URL of this page to them.</p>}
    </div>
  )
}

export default withScreenWrapper(ResourceNotFound)
