import React, { useState, useEffect } from 'react'
import { Box, Grid, Typography, Dialog, DialogTitle, DialogContent, Button, TextField, Menu, MenuItem } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import IconButton from '@mui/material/IconButton'
import CircleIcon from '@mui/icons-material/Circle'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ShortTextOutlinedIcon from '@mui/icons-material/ShortTextOutlined'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import { findFieldNumber } from '../PDFDocumentEditor'
import { guid } from '@supportworks/helper'

const FieldNavText = (props) => {
  const [showSelections, setShowSelections] = useState(false)

  const handleSelectionAdd = () => {
    const s = {
      id: guid(),
      type: 'text',
      title: 'Custom text',
      page: props.pagenum || 1
    }
    setShowSelections(true)
    props.document.fields.push(s)
    props.onChange('generic', props.document)
  }

  const selections = props.document.fields.filter((o) => o.type === 'text')
  return (
    <>
      <Box>
        <Grid container spacing={0} sx={{ p: 1 }} style={styles.navContainer}>
          <Grid item sm={1} sx={{ pl: 1 }}>
            <ShortTextOutlinedIcon />
          </Grid>
          <Grid item sm={selections.length > 0 ? 9 : 10} sx={{ pl: 1 }}>
            <Typography sx={{ pl: 1 }} style={styles.navText}>
              Text Input
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={handleSelectionAdd}>
              <AddCircleOutlineIcon fontSize='small' />
            </IconButton>
          </Grid>
          {selections.length > 0
            ? (
              <Grid item xs={1}>
                <IconButton onClick={() => setShowSelections(!showSelections)}>{showSelections ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
              </Grid>
              )
            : null}
        </Grid>
      </Box>
      {showSelections
        ? (
          <Box sx={{ pb: 0 }} style={styles.navSelectionContainer}>
            {selections.map((field, idx) => {
              return <FieldNavTextItem key={`fntd-${idx}`} idx={idx} selections={selections} selection={field} {...props} />
            })}
          </Box>
          )
        : null}
    </>
  )
}
export default FieldNavText

const FieldNavTextItem = (props) => {
  const [selected, setSelected] = useState(false)
  const [showDialogEdit, setShowDialogEdit] = useState(false)

  useEffect(() => {
    if (props.selectedField && props.selection && (props.selection.id === props.selectedField.id || props.selection.id === props.selectedField.editorId)) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }, [props.selection, props.selectedField])

  const handleDeleteSelection = (field) => {
    if (window.confirm(`There is no undo for deleting a text field.  Are you sure you want to delete "${field.title}"?`)) {
      const idx = props.document.fields.findIndex((o) => o.id === field.id)
      if (idx > -1) {
        props.document.fields.splice(idx, 1)
      }
      props.onChange('generic', props.document)
    }
  }

  const field = props.selection
  const idx = props.idx
  const number = findFieldNumber(props.document, field)
  return (
    <div
      key={`fntd-${idx}`}
      style={styles.pointer}
      draggable
      onDragStart={(e) => {
        props.onDragStart({ formId: props.formId, type: 'text', field })
      }}
      onDragEnd={() => {
        props.onDragEnd()
      }}
      onDragExit={props.onDragExit}
    >
      <Box
        key={field.id}
        onClick={() => {
          props.onFieldSelect(field, -1)
        }}
      >
        <Grid container spacing={0} sx={{ p: 1, pl: 1 }} style={{ ...styles.navContainer, ...styles.navSelectionContainer }} wrap='nowrap'>
          <Grid item sm={1}>
            <ShortTextOutlinedIcon fontSize='small' />
          </Grid>
          <Grid item sm={8} sx={{ pl: 1 }}>
            <Typography sx={{ pl: 1, pr: 1 }} style={styles.navText}>
              {field.title}
            </Typography>
          </Grid>
          <Grid item sm={1}>
            <IconButton
              onClick={() => {
                setShowDialogEdit(!showDialogEdit)
              }}
            >
              <EditIcon fontSize='small' />
            </IconButton>
          </Grid>
          <Grid item sm={1}>
            {number > -1
              ? (
                <IconButton>
                  <CircleIcon fontSize='small' style={selected ? { color: 'blue' } : { color: 'black' }} />
                  <Typography style={styles.iconText}>{number}</Typography>
                </IconButton>
                )
              : null}
          </Grid>
          {/**
                    <Grid item sm={1}>
                        <IconButton onClick={() => handleDeleteSelection(field)}>
                            <RemoveCircleOutlineIcon fontSize="small" />
                        </IconButton>
                    </Grid>
                    **/}
          <Grid item sm={1}>
            <FieldActionSelection {...props} onDelete={() => handleDeleteSelection(field)} />
          </Grid>
        </Grid>
      </Box>
      {showDialogEdit ? <DialogEditSelection onClose={() => setShowDialogEdit(!showDialogEdit)} {...props} /> : null}
    </div>
  )
}

const DialogEditSelection = (props) => {
  const [title, setTitle] = useState()
  const [selection, setSelection] = useState()

  // Used to set the correct section on the LeftPanel when a field is clicked on in the CenterCanvas
  useEffect(() => {
    setTitle(props.selection.title)
    setSelection(props.selection)
    // eslint-disable-next-line
  }, [])

  const handleChange = (e) => {
    setTitle(e.target.value)
    const s = props.selection
    s.title = e.target.value
    setSelection(s)
  }

  const handleSave = () => {
    props.onChange('field', selection)
    props.onClose()
  }

  return (
    <Dialog onClose={props.onClose} open fullWidth maxWidth='md'>
      <DialogTitle className='dialog-title'>
        Edit Text
        <IconButton
          className='dialog-close'
          aria-label='close'
          onClick={() => {
            props.onClose()
          }}
          sx={{ position: 'absolute', right: 12, top: 12 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className='dialog-content' style={{ paddingTop: 8 }}>
        <Box>
          <TextField
            fullWidth
            required
            multiline
            rows={8}
            id='selection-title'
            variant='outlined'
            label='Text'
            helperText='Please enter the text to display.'
            defaultValue={title}
            onChange={handleChange}
            sx={{
              'input:focus': {
                border: 'none'
              }
            }}
          />
        </Box>
      </DialogContent>
      <Box style={styles.bottomNavigation}>
        <Button
          id='cancel'
          variant='outlined'
          color='primary'
          sx={{ mr: 2 }}
          onClick={() => {
            props.onClose()
          }}
        >
          Close
        </Button>
        <Button id='save' variant='contained' color='primary' sx={{ marginLeft: 'auto' }} onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Dialog>
  )
}

const FieldActionSelection = ({ field, selectionIndex = null, onFieldSelect, onDelete, onAddAbove, onAddBelow }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu id='action-menu' open={open} onClose={handleClose} anchorEl={anchorEl}>
        <MenuItem
          onClick={() => {
            onDelete()
            onFieldSelect(field, selectionIndex) // Force refresh
            handleClose()
          }}
        >
          Remove Selection
        </MenuItem>
      </Menu>
    </>
  )
}

const styles = {
  navContainer: {
    alignItems: 'center',
    userSelect: 'none',
    WebkitUserSelect: 'none'
  },
  navSelectionContainer: {
    backgroundColor: 'rgba(0,0,0,.04)'
  },
  navText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 14,
    userSelect: 'none'
  },
  bottomNavigation: {
    display: 'flex',
    width: '100%',
    padding: '16px',
    borderTop: 1,
    borderColor: 'rgba(0,0,0,.16)',
    backgroundColor: 'white'
  },
  borderBottom: {
    borderBottom: 1,
    borderColor: 'rgba(0,0,0,.16)'
  },
  fieldNumber: {
    fontSize: 8
  },
  iconText: {
    position: 'absolute',
    fontSize: 8,
    fontWidth: 'bold',
    fontFamily: 'sans-serif',
    color: 'white'
  },
  pointer: {
    cursor: 'pointer'
  },
  field: {
    cursor: 'pointer',
    border: '1px dashed rgba(0,0,0,.04)'
  },
  fieldDragging: {
    border: '1px dashed rgba(0,0,0,.24)'
  }
}
