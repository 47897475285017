import React from 'react'
import CompanyConfigPublish from './CompanyConfigPublish'

/** Configs
 *
 * We have a couple differnet variants of config.
 *
 * 1. Single configs
 *
 * - There is 1 config in ripple_config that is Published.
 * - There is a "JSON Editor" on the first page.
 * - When you make a change, a banner will show at the top that says "There are unsaved changes"
 * - When changes are made, the "Published" record updates
 *
 * 2. Multi configs
 *
 * - Can have multiple "Published" configs in ripple_config
 * - The screen has an index datatable with rows
 * - You can add or delete configs from the index screen
 * - There is no "JSON Editor" on the index page
 *
 * 3. There might be other Company specific configs inside of ../Company/index.js
 *
*/

/** Single configs
 *
 * NOTE: If you add a single config, ADD IT HERE.
 *
**/
import CompanyConfigAssets from './CompanyConfigAssets'
import CompanyConfigBillingDetails from './CompanyConfigBillingDetails'
import CompanyConfigCRM from './CompanyConfigCRM'
import CompanyConfigCrmFields from './CompanyConfigCrmFields'
import CompanyConfigCurrent from './CompanyConfigCurrent'
import CompanyConfigDifferentiate from './CompanyConfigDifferentiate'
import CompanyConfigForms2 from './CompanyConfigForms2'
import CompanyConfigMaterials from './CompanyConfigMaterials'
import CompanyConfigDrawingToolEditor from './CompanyConfigDrawingToolEditor'
import CompanyConfigReferrals from './CompanyConfigReferrals'
import CompanyConfigPresentations from './CompanyConfigPresentations'
import CompanyConfigProductFeatures from './CompanyConfigProductFeatures'
import CompanyConfigProductMapping from './CompanyConfigProductMapping'
import CompanyConfigProducts from './CompanyConfigProducts'
import CompanyConfigResources from './CompanyConfigResources'
import CompanyConfigRoot from './CompanyConfigRoot'
import CompanyConfigSolutions from './CompanyConfigSolutions'
import SettingsCalendar from './SettingsCalendar'
import SettingsCustomerPortal from './SettingsCustomerPortal'
import SettingsDisplay from './SettingsDisplay'
import SettingsNumericUnits from './SettingsNumericUnits'
import SettingsSolutionCategories from './SettingsSolutionCategories'

/** Multiple configs
 *
 * NOTE: If you add a multi config, ADD IT HERE.
*/
import CompanyConfigAboutUs from './CompanyConfigAboutUs'
import CompanyConfigAccountingDetails from './CompanyConfigAccountingDetails'
import CompanyConfigBuild from './CompanyConfigBuild'
import CompanyConfigContract from './CompanyConfigContract'
import CompanyConfigDealerInfo from 'screens/Config/CompanyConfigDealerInfo'
import CompanyConfigDistributionList from './CompanyConfigDistributionList'
import CompanyConfigDocumentEditor from './CompanyConfigDocumentEditor'
import CompanyConfigMeetings from './CompanyConfigMeetings'
import CompanyConfigMessaging from './CompanyConfigMessaging'
import CompanyConfigPricing from './CompanyConfigPricing'
import CompanyConfigProductConfig from './CompanyConfigProductConfig'

/** Unknown Configs */
import CompanyConfigTmpRoot from './CompanyConfigTmpRoot'

// Misc icons for form & document editor usage...
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import ArticleIcon from '@mui/icons-material/Article'
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle'
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded'
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import DrawIcon from '@mui/icons-material/Draw'
import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined'
import ListIcon from '@mui/icons-material/List'
import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined'
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined'
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined'
import PinOutlinedIcon from '@mui/icons-material/PinOutlined'
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay'
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined'
import ShortTextOutlinedIcon from '@mui/icons-material/ShortTextOutlined'
import SubjectIcon from '@mui/icons-material/Subject'
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'

/**
 * This provides a convenient container to access Company Config UI elements. This maps both the "variant" (camelCase)
 * and tag to the corresponding component, so a generic config loader can identify the edit/index component based on the
 * value of the tag field.
 *
 * @type {Object}
 */
const CompanyConfig = {
  DEFAULT_VARIANT: '_default',
  ':tag': CompanyConfigRoot,
  __current: CompanyConfigCurrent,
  __differentiate: CompanyConfigDifferentiate,
  __complete: CompanyConfigBuild,
  __new_root: CompanyConfigTmpRoot,
  __root: CompanyConfigRoot,
  AboutUs: CompanyConfigAboutUs,
  aboutus: CompanyConfigAboutUs,
  AppointmentType: CompanyConfigMeetings,
  appointment_type: CompanyConfigMeetings,
  Assets: CompanyConfigAssets,
  Assets2: CompanyConfigAssets,
  assets: CompanyConfigAssets,
  assets2: CompanyConfigAssets,
  billingDetails: CompanyConfigBillingDetails,
  calendar: SettingsCalendar,
  Contract: CompanyConfigContract,
  contract: CompanyConfigContract,
  'contract.dealer': CompanyConfigDealerInfo,
  'contract.terms': CompanyConfigAccountingDetails,
  CrmFields: CompanyConfigCrmFields,
  crmFields: CompanyConfigCrmFields,
  crm_config: CompanyConfigCRM,
  // DEFAULT_VARIANT: '_default',  *duplicate
  displaySettings: SettingsDisplay,
  DistributionList: CompanyConfigDistributionList,
  distributionList: CompanyConfigDistributionList,
  DocumentEditor: CompanyConfigDocumentEditor,
  documentEditor: CompanyConfigDocumentEditor,
  Forms2: CompanyConfigForms2,
  forms2: CompanyConfigForms2,
  libraryProductMapping: CompanyConfigProductMapping,
  Materials: CompanyConfigMaterials,
  materials: CompanyConfigMaterials,
  Messaging: CompanyConfigMessaging,
  messaging: CompanyConfigMessaging,
  numericUnits: SettingsNumericUnits,
  pptLibrary: CompanyConfigDrawingToolEditor,
  referrals: CompanyConfigReferrals,
  resources: CompanyConfigResources,
  Presentations: CompanyConfigPresentations,
  presentations: CompanyConfigPresentations,
  Pricing: CompanyConfigPricing,
  pricing: CompanyConfigPricing,
  ProductConfig: CompanyConfigProductConfig,
  productConfig: CompanyConfigProductConfig,
  productFeatures: CompanyConfigProductFeatures,
  Products: CompanyConfigProducts,
  products: CompanyConfigProducts,
  Publish: CompanyConfigPublish,
  settingsCustomerPortal: SettingsCustomerPortal,
  solutionCategories: SettingsSolutionCategories,
  Solutions: CompanyConfigSolutions,
  solutions: CompanyConfigSolutions,
  subject: CompanyConfigAccountingDetails, // Assuming 'subject' is an alias for 'contract.terms'
  WatchLater: CompanyConfigBuild // Assuming 'WatchLater' is an alias for '__complete'
}
export default CompanyConfig

export const fieldTypeConfig = {
  null: {
    label: 'Select Field Type',
    icon: null
  },
  boolean: {
    label: 'Yes/No',
    icon: <ThumbsUpDownIcon />,
    completedFieldInProposal: true
  },
  radio: {
    label: 'Single Selection',
    icon: <RadioButtonCheckedOutlinedIcon />,
    completedFieldInProposal: true
  },
  checkbox: {
    label: 'Multi Selection',
    icon: <CheckBoxIcon />,
    completedFieldInProposal: true
  },
  choice: {
    label: 'Dropdown',
    icon: <ArrowDropDownCircleIcon />,
    completedFieldInProposal: true
  },
  media: {
    label: 'Take Photo',
    icon: <AddPhotoAlternateIcon />,
    completedFieldInProposal: true
  },
  contentBlock: {
    label: 'Content Block',
    icon: <PlaylistPlayIcon />
  },
  noresponse: {
    label: 'Instruction',
    icon: <ListIcon />
  },
  title: {
    label: 'Section Formatting',
    icon: <CalendarViewDayIcon />
  },
  numeric: {
    label: 'Numeric',
    icon: <PinOutlinedIcon />,
    completedFieldInProposal: true
  },
  calc: {
    label: 'Calculator',
    icon: <CalculateRoundedIcon />,
    completedFieldInProposal: true
  },
  text: {
    label: 'Short Text',
    icon: <ShortTextOutlinedIcon />,
    completedFieldInProposal: true
  },
  string: {
    // Used by Doc Editor
    label: 'Short Text',
    icon: <ShortTextOutlinedIcon />,
    completedFieldInProposal: true
  },
  type: {
    label: 'Long Text',
    icon: <SubjectIcon />,
    completedFieldInProposal: true
  },
  date: {
    label: 'Date Selector',
    icon: <EventOutlinedIcon />,
    completedFieldInProposal: true
  },
  time: {
    label: 'Time Selector',
    icon: <WatchLaterIcon />,
    completedFieldInProposal: true
  },
  areaName: {
    label: 'Form Name',
    icon: <ArticleIcon />
  },
  area: {
    label: 'Add Form Dropdown',
    icon: <ArrowDropDownCircleIcon />
  },
  addAreaCheckbox: {
    label: 'Add Form Checkbox',
    icon: <CheckBoxIcon />
  },
  relatedInspectAreas: {
    label: 'Related Forms',
    icon: <CheckBoxIcon />
  },
  productRecommendation: {
    label: 'Product Recommendation',
    icon: <FeaturedPlayListOutlinedIcon />
  },
  productRecommendationProductList: {
    label: 'Product Dropdown',
    icon: <ArrowDropDownCircleIcon />
  },
  checklist: {
    label: 'Checklist',
    icon: <FactCheckOutlinedIcon />
  },
  pdf: {
    label: 'Display Document',
    icon: <PictureAsPdfOutlinedIcon />
  },
  image: {
    label: 'Display Image',
    icon: <PhotoSizeSelectActualOutlinedIcon />
  },
  video: {
    label: 'Display Video',
    icon: <MovieOutlinedIcon />
  },
  freeFormCapture: {
    label: 'Sketch',
    icon: <DrawIcon />
  },
  addAreaListAdvanced: {
    label: 'Form Automation',
    icon: <CheckBoxOutlineBlankIcon />
  },
  table_2: {
    label: '2-Column Row',
    icon: <ViewWeekOutlinedIcon />
  },
  table_3: {
    label: '3-column Row',
    icon: <ViewWeekOutlinedIcon />
  },
  addSolutions: {
    label: 'Select Solutions',
    icon: <VerifiedUserIcon />
  },
  customerWhy: {
    label: 'Customer Why',
    icon: <PersonSearchIcon />
  }
}
