import React from 'react'
import { Grid, Stack, Typography } from '@mui/material'
import Company from 'screens/Company'

const CompanyHeader = (props) => {
  const currentCompany = Company.getCurrent()
  return (
    <Grid container sx={{ pb: 1 }}>
      {/** Company name */}
      <Grid item xs={8}>
        {props.title ? <Typography variant='h5'>{props.title}</Typography> : null}
        {currentCompany
          ? (
            <Stack>
              <Typography variant='body2'> {currentCompany.name} ({currentCompany.id})</Typography>
              <Typography variant='body2'> {currentCompany.workspace}</Typography>
            </Stack>
            )
          : null}
      </Grid>
      {/** Buttons on the top right */}
      <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {props.children}
      </Grid>
    </Grid>
  )
}
export default CompanyHeader
