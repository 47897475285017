import React, { useEffect, useState } from 'react'
import '../../../../css/forms.css'
import FieldTypeAddLogicPanel from 'screens/Config/Forms/FieldTypeAddLogicPanel'
import AddLogic from '../AddLogic'
import FieldTypeExpandMarker from '../Reusables/FieldTypeExpandMarker'
import RelatedFieldTypeEditor from '../Reusables/RelatedFieldTypeEditor'

function Numeric (props) {
  const [hasLogic, setHasLogic] = useState(false)
  const [optionValue, setOptionValue] = useState('')
  const [numCondValue, setNumCondValue] = useState('')
  const [field, setField] = useState(props.field)
  /* const unitArrLocal = [
        {value: 'sq. ft.', name: 'Square Feet'},
        {value: 'in.', name: 'Inches'},
        {value: 'years', name: 'Years'},
        {value: 'ft.', name: 'Feet'},
        {value: 'linear ft.', name: 'Linear Feet'}
    ]; */
  const [unitArrayHelper, setUnitArrayHelper] = useState([])
  const [tabItem, setTabItem] = useState('')
  const [relatedLength, setRelatedLength] = useState(0)

  const changeHandlerVisible = (visible) => {
    props.onChangeVisible(visible)
  }

  const changeHandlerVisibleInApp = (display) => {
    props.onChangeVisibleInApp(display)
  }

  const changeHandlerDefault = (val) => {
    props.onChangeDefault(val)
  }

  const changeHandlerCRM = (crm) => {
    props.onChangeCRM(crm)
  }

  const changeHandlerUnits = (units) => {
    props.onChangeUnits(units)
  }

  const changeHandlerOption = (val) => {
    setOptionValue(val)
  }

  const askQuestionNumeric = () => {
    const newField = JSON.parse(JSON.stringify(field))

    if (!('related' in newField)) {
      newField.related = []
    }

    const existDupOption = newField.related.some((el) => {
      // return el.ranges[0].hasOwnProperty(optionValue) && el.ranges[0][optionValue] === numCondValue
      return Object.prototype.hasOwnProperty.call(el.ranges[0], optionValue) && (el.ranges[0][optionValue] === numCondValue)
    })

    if (!existDupOption) {
      newField.related.push({
        id: props.guid(),
        ranges: [
          {
            [optionValue]: numCondValue
          }
        ],
        type: null
      })
    }
    /* newField['related'].push({
            id: props.guid(),
            ranges:[
                {
                    [optionValue]: numCondValue
                }
            ],
            type: null
        }); */

    setField(newField)
    props.askQuestion(newField)
  }

  const changeNumericCondition = (val) => {
    setNumCondValue(val)
  }

  const addLogic = (e) => {
    if (e) {
      setHasLogic(true)
    }
  }

  const changeRelatedOptionVal = (oldVal, newVal) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    if ('related' in newField) {
      // newField.related.map((rel, idx) => {
      for (let idx = 0; idx < newField.related.length; idx++) {
        if (newField.related[idx].value === oldVal) {
          newField.related[idx].value = newVal
        }
      }
      // )
    }
    setField(newField)
    props.askQuestion(newField)
  }

  const changeHandlerRelated = (related, idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    newField.related[idx] = related
    setField(newField)
    props.askQuestion(newField)
  }

  const deleteRelated = (idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))

    newField.related.splice(idx, 1)
    if (!newField.related.length) {
      delete newField.related
    }

    setField(newField)
    props.askQuestion(newField)
  }

  const saveIssues = (withIssuesArr) => {
    const newField = JSON.parse(JSON.stringify(withIssuesArr))
    setField(newField)
    props.askQuestion(newField)
  }

  const saveSolutions = (solutions) => {
    props.onSaveSolutions(solutions)
  }

  const createHandlerUnit = (unitArr) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    if ('unitArrayHelper' in newField) {
      newField.unitArrayHelper = unitArr
    } else {
      newField.unitArrayHelper = unitArr
    }
    // newField['unitArray'] = unitArr;
    // setField(newField);
    setUnitArrayHelper(unitArr)
    props.onChangeUnitArr(newField)
    // props.onChange(newField);
  }

  /* const deleteHandlerUnit = (unitArr) => {
        let newField = JSON.parse(JSON.stringify(props.field));
        newField['unitArray'] = unitArr;
        setField(newField);
        setUnitArr(unitArr);
        props.onChange(newField);
    } */

  const clickHandlerTab = (tab) => {
    setTabItem(tab)
  }

  const deleteHandlerQuestion = (field) => {
    props.askQuestion(field)
  }

  useEffect(() => {
    setField(props.field)

    if ('unitArrayHelper' in props.field) {
      setUnitArrayHelper(props.field.unitArrayHelper)
    }
  }, [props.field])

  useEffect(() => {
    if ('solutions' in props.form) {
      // props.form.solutions.map((s, idx) => {
      for (let idx = 0; idx < props.form.solutions.length; idx++) {
        if (props.form.solutions[idx].fieldTypeId === props.field.id) {
          setHasLogic(true)
        }
      }
      // )
    }

    if ('related' in props.field || 'issues' in props.field) {
      setHasLogic(true)
    }
  }, []) // eslint-disable-line

  /* useEffect(() => {
    if ('unitArrayHelper' in props.field) {
      setUnitArrayHelper(props.field.unitArrayHelper)
    }
  }, [props.field]) */

  useEffect(() => {
    if (props.field.related && props.field.related.length > 0) {
      setRelatedLength(props.field.related.length)
      setTabItem(props.field.related[props.field.related.length - 1].id)
    }
  }, [props.field.related])

  useEffect(() => {
    if (props.field.related && props.field.related.length > 0) {
      if (props.field.related.length !== relatedLength) {
        setTabItem(props.field.related[props.field.related.length - 1].id)
      }
    }
  }, [props.field.related]) // eslint-disable-line

  // console.log(props.field);

  return (
    <>
      {
                props.expandMarker &&
                  <FieldTypeExpandMarker />
            }
      <div style={styles.logicPanel}>
        <FieldTypeAddLogicPanel
          {...props}
          field={props.field}
          onChangeVisible={(visible) => changeHandlerVisible(visible)}
          onChangeVisibleInApp={(display) => changeHandlerVisibleInApp(display)}
          onChangeCRM={(crm) => changeHandlerCRM(crm)}
          onChangeUnits={(units) => changeHandlerUnits(units)}
          onAddLogic={(e) => addLogic(e)}
          crmFieldsConfig={props.crmFieldsConfig}
          numericUnitsConfig={props.numericUnitsConfig}
          unitArr={unitArrayHelper}
          onCreateUnit={(unitArr) => createHandlerUnit(unitArr)}
                    // onDeleteUnit={(unitArr)=>deleteHandlerUnit(unitArr)}
          onChangeDefault={(val) => changeHandlerDefault(val)}
          guid={props.guid}
        />

        {
                    hasLogic &&
                      <AddLogic
                        {...props}
                        type='numeric'
                        field={field}
                        onSelectChange={(val) => changeHandlerOption(val)}
                        askQuestionFieldType={() => askQuestionNumeric()}
                        onNumericChange={(val) => changeNumericCondition(val)}
                        onRelatedOptionValChange={(oldVal, newVal) => changeRelatedOptionVal(oldVal, newVal)}
                        onSaveIssues={(field) => saveIssues(field)}
                        onSaveSolutions={(solutions) => saveSolutions(solutions)}
                        form={props.form}
                        allFieldsSolutions={props.allFieldsSolutions}
                        onClickTab={(tab) => clickHandlerTab(tab)}
                        onDeleteQuestion={(field) => deleteHandlerQuestion(field)}
                        guid={props.guid}
                      />
                }

        {
                    'related' in field &&
                    field.related.map((rel, idx) => {
                      return (
                        <div key={`related-${idx}`}>
                          <div style={tabItem === rel.id ? styles.relatedMain : styles.relatedMainHide}>
                            <FieldTypeExpandMarker />
                            <RelatedFieldTypeEditor
                              {...props}
                              field={field.related[idx]}
                              onChange={(related) => changeHandlerRelated(related, idx)}
                              onDelete={() => deleteRelated(idx)}
                              guid={props.guid}
                              form={props.form}
                              onSaveSolutions={(solutions) => saveSolutions(solutions)}
                              allFieldsSolutions={props.allFieldsSolutions}
                              crmFieldsConfig={props.crmFieldsConfig}
                              numericUnitsConfig={props.numericUnitsConfig}
                              allFields={props.allFields}
                            />
                          </div>
                        </div>
                      )
                    })
                }
      </div>
    </>
  )
}

const styles = {
  relatedMain: {
    display: 'flex'
  },
  relatedMainHide: {
    display: 'none'
  },
  logicPanel: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%'
  }
}

export default Numeric
