import CompanyConfigEdit, { CompanyConfigBaseEditor } from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import Search from '../../components/Reusable/Search'
import CompanyConfigModal from './CompanyConfigModal'
import Api from 'library/Api'
import React from 'react'
import { SelectSearch, getValuesOnly } from '../../components/Reusable/SelectSearch'
import { IconButton, Button } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import AddIcon from '@mui/icons-material/Add'
import '../../css/select-search.css'

const config = {
  title: 'Email & Text Messaging',
  variant: 'Messaging',
  tag: 'messaging',
  name: 'Email & Text Messaging',
  lockTag: true,
  lockName: false
}

class AssetEditor extends React.Component {
  constructor (props) {
    super(props)
    this.state = { asset: this.props.asset }
    // console.log(this.props);

    this.selectChangeHandler = this.selectChangeHandler.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  selectChangeHandler (event, name) {
    const asset = this.state.asset
    asset[name] = event
    this.setState({ asset })
  }

  handleChange (event) {
    const asset = this.state.asset
    asset[event.target.name] = event.target.value
    this.setState({ asset })
  }

  render () {
    const asset = this.state.asset
    const body = (
      <div>
        <div className='col-sm-12 form-group row'>
          <label className='col-sm-2'>Id</label>
          <div className='col-sm-10'>
            <SelectSearch
              name='id'
              options={this.props.assetsConfig}
              placeholder='Select Id'
              value={'id' in asset ? asset.id : ''}
              onChange={(event) => this.selectChangeHandler(getValuesOnly({ selections: event }), 'id')}
            />
          </div>
        </div>
        <div className='col-sm-12 form-group row'>
          <label className='col-sm-2'>Type:</label>
          <div className='col-sm-10'>
            <input
              type='text'
              className='form-control'
              name='type'
              defaultValue={'type' in asset ? asset.type : ''}
              placeholder='Enter Type'
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className='col-sm-12 form-group row'>
          <label className='col-sm-2'>Title:</label>
          <div className='col-sm-10'>
            <input
              type='text'
              className='form-control'
              name='title'
              defaultValue={'title' in asset ? asset.title : ''}
              placeholder='Enter Title'
              onChange={this.handleChange}
            />
          </div>
        </div>
      </div>
    )
    return (
      <CompanyConfigModal
        open={this.props.open}
        toggle={this.props.children}
        header='Asset Editor'
        body={body}
        cancel={() => {
          const asset = this.state.asset
          this.props.onCancel(asset)
        }}
        update={() => {
          const asset = this.state.asset
          delete asset.newAsset
          this.props.onUpdate(asset)
        }}
      />
    )
  }
}

class MessagingEditor extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      message: this.props.message
    }

    this.cancelHandler = this.cancelHandler.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.assetAddHandler = this.assetAddHandler.bind(this)
    this.assetDeleteHandler = this.assetDeleteHandler.bind(this)
  }

  cancelHandler (a, idx) {
    const message = JSON.parse(JSON.stringify(this.state.message))
    if (a.newAsset === true) {
      message.assets.splice(idx, 1)
    }
    this.setState({ message })
  }

  handleChange (event) {
    const message = JSON.parse(JSON.stringify(this.state.message))
    message[event.target.name] = event.target.value
    this.setState({ message })
  }

  assetAddHandler () {
    const message = JSON.parse(JSON.stringify(this.state.message))
    message.assets.push({ id: '', type: '', title: '', newAsset: true })
    this.setState({ message })
  }

  assetDeleteHandler (idx) {
    const message = JSON.parse(JSON.stringify(this.state.message))
    message.assets.splice(idx, 1)
    this.setState({ message })
  }

  updateHandler (a, idx) {
    const message = JSON.parse(JSON.stringify(this.state.message))
    message.assets[idx] = a
    this.setState({ message })
  }

  render () {
    const message = this.state.message
    const body = (
      <div>
        <div className='col-sm-12 form-group row' style={{ display: 'none' }}>
          <label className='col-sm-2'>Id:</label>
          <div className='col-sm-10'>
            <input
              type='text'
              className='form-control'
              name='id'
              readOnly
              defaultValue={'id' in message ? message.id : ''}
              placeholder='Enter Id'
              // onChange={this.changeHandler}
            />
          </div>
        </div>
        <div className='col-sm-12 form-group row'>
          <label className='col-sm-2'>Title:</label>
          <div className='col-sm-10'>
            <input
              type='text'
              className='form-control'
              name='title'
              defaultValue={'title' in message ? message.title : ''}
              placeholder='Enter Title'
              onChange={this.handleChange}
            />
          </div>
        </div>
        {message.type === 'email' && (
          <div className='col-sm-12 form-group row'>
            <label className='col-sm-2'>Subject:</label>
            <div className='col-sm-10'>
              <input
                type='text'
                className='form-control'
                name='subject'
                defaultValue={'subject' in message ? message.subject : ''}
                placeholder='Enter Subject'
                onChange={this.handleChange}
              />
            </div>
          </div>
        )}
        <div className='col-sm-12 form-group row'>
          <label className='col-sm-2'>Body:</label>
          <div className='col-sm-10'>
            <textarea
              style={{ minHeight: 200 }}
              className='form-control'
              name='body'
              defaultValue={'body' in message ? message.body : ''}
              placeholder='Enter Message Body'
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className='actionsTable__wrapper'>
          <div className='actionsTable__options'>
            <Button id='add-action' color='success' variant='contained' onClick={() => this.assetAddHandler()} startIcon={<AddIcon />}>
              Add Assets
            </Button>
          </div>
          {'assets' in message && message.assets.length !== 0 && (
            <>
              <table className='actionsTable'>
                <thead>
                  <tr className='actionsTable__header'>
                    <th className='actionsTable__label'>Id</th>
                    <th className='actionsTable__label'>Type</th>
                    <th className='actionsTable__label'>Title</th>
                    <th className='actionsTable__actions'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {message.assets.map((asset, idx) => {
                    return (
                      <tr className='actionsTable__row' key={idx}>
                        <td className='actionsTable__title'>
                          <span>{asset.id}</span>
                        </td>
                        <td className='actionsTable__inspect'>
                          <span>{asset.type}</span>
                        </td>
                        <td className='actionsTable__title'>
                          <span>{asset.title}</span>
                        </td>
                        <td className='actionsTable__actions'>
                          <AssetEditor
                            key={'asset_' + this.props.guid()}
                            open={'newAsset' in asset}
                            onCancel={(a) => this.cancelHandler(a, idx)}
                            onUpdate={(a) => this.updateHandler(a, idx)}
                            asset={asset}
                            assetsConfig={this.props.assetsConfig}
                          >
                            <IconButton title='Edit' aria-label='edit'>
                              <EditIcon variant='icon' fontSize='small' />
                            </IconButton>
                          </AssetEditor>
                          <IconButton title='Delete' aria-label='delete' onClick={() => this.assetDeleteHandler(idx)}>
                            <DeleteIcon variant='icon' fontSize='small' />
                          </IconButton>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    )

    return (
      <CompanyConfigModal
        open={this.props.open}
        toggle={this.props.children}
        header={this.props.message.type.toUpperCase() + ' - Messaging Editor'}
        body={body}
        cancel={() => {
          const message = JSON.parse(JSON.stringify(this.state.message))
          this.props.onCancel(message)
        }}
        update={() => {
          const message = JSON.parse(JSON.stringify(this.state.message))
          delete message.newMessaging
          this.props.onUpdate(message)
        }}
      />
    )
  }
}

class RenderMessagingFields extends React.Component {
  render () {
    const message = this.props.singleMessage
    const idx = this.props.currentIdx
    const display = this.props.display

    return (
      <tr className='actionsTable__row' style={{ display: `${display}` }}>
        <td className='actionsTable__checkboxTD'>
          <span>C</span>
        </td>
        <td className='actionsTable__title'>
          <span>{message.title}</span>
        </td>
        {'subject' in message && (
          <td className='actionsTable__title'>
            <span>{message.subject}</span>
          </td>
        )}
        <td className='actionsTable__prodText'>
          <span>{message.body}</span>
        </td>
        <td className='actionsTable__categories'>
          {message.assets.map((asset, i) => {
            return (
              <span key={'assets-' + i}>
                {asset.title} {asset.type}
              </span>
            )
          })}
        </td>
        <td className='actionsTable__actions'>
          <MessagingEditor
            key={'renderMsg_' + this.props.guid()}
            open={'newMessaging' in message}
            message={message}
            assetsConfig={this.props.assetsConfig}
            messagingConfig={this.props.messagingConfig}
            onCancel={(m) => {
              this.props.cancelParent(m)
            }}
            onUpdate={(m) => {
              this.props.updateParent(m)
            }}
            guid={this.props.guid}
          >
            <IconButton title='Edit' aria-label='edit'>
              <EditIcon variant='icon' fontSize='small' />
            </IconButton>
          </MessagingEditor>
          <IconButton title='Delete' aria-label='delete' onClick={() => this.props.deleteParent(idx)}>
            <DeleteIcon variant='icon' fontSize='small' />
          </IconButton>
        </td>
      </tr>
    )
  }
}

class CompanyConfigMessaging extends CompanyConfigBaseEditor {
  constructor (props) {
    super(props)
    let messagingInfo

    if (typeof props.contentJson === 'string') {
      messagingInfo = JSON.parse(props.contentJson)
    } else {
      /* messagingInfo = [{
                id: "",
                type: "",
                title: "",
                subject: "",
                body: "",
                assets: []
            }]; */
      messagingInfo = []
    }

    this.state = {
      messagingList: messagingInfo,
      assetsConfig: null,
      messagingConfig: null,
      filter: ''
    }
    // console.log(this.state);

    this.updateHandler = this.updateHandler.bind(this)
    this.addHandler = this.addHandler.bind(this)
    this.deleteHandler = this.deleteHandler.bind(this)
    this.cancelHandler = this.cancelHandler.bind(this)
  }

  addHandler (type) {
    const messagingList = JSON.parse(JSON.stringify(this.state.messagingList))
    if (type === 'text') {
      messagingList.push({
        id: this.guid(),
        type,
        title: '',
        body: '',
        assets: [],
        newMessaging: true
      })
    }
    if (type === 'email') {
      messagingList.push({
        id: this.guid(),
        type,
        title: '',
        subject: '',
        body: '',
        assets: [],
        newMessaging: true
      })
    }
    this.setState({ messagingList })
  }

  deleteHandler (idx) {
    const messagingList = JSON.parse(JSON.stringify(this.state.messagingList))
    messagingList.splice(idx, 1)
    this.setState({ messagingList })
  }

  cancelHandler (m, idx) {
    const messagingList = JSON.parse(JSON.stringify(this.state.messagingList))
    if (m.newMessaging === true) {
      messagingList.splice(idx, 1)
    }
    this.setState({ messagingList })
  }

  updateHandler (m, idx) {
    const messagingList = JSON.parse(JSON.stringify(this.state.messagingList))
    messagingList[idx] = m
    this.setState({ messagingList })
  }

  componentDidMount () {
    super.componentDidMount()
    this.apiCalls('assets')
    this.apiCalls('messaging')

    this.state.messagingList.map((m) => {
      this.addExistingGuid(m.id)
      return null
    })
  }

  async apiCalls (tagName) {
    await Api.callRegisteredMethod('getConfigListByTag', { tag: tagName, startsWith: false }).then((configs) => {
      const minConfigId = Math.min.apply(
        null,
        configs.map(function (o) {
          return o.configId
        })
      )
      const minConfigArr = configs.filter((arr) => {
        return arr.configId === minConfigId
      })
      const minOrgId = minConfigArr[0].organizationId

      Api.callRegisteredMethod('getConfigById', {
        configId: minConfigId,
        organizationId: minOrgId
      }).then((listProps) => {
        const tagList = JSON.parse(listProps.data[0].contentJson)

        if (tagName === 'messaging') {
          const messaging = []
          tagList.map((listItem) => {
            messaging.push({ value: listItem.id, name: listItem.title })
            return null
          })
          this.setState({ messagingConfig: messaging })
        }
        if (tagName === 'assets') {
          const assets = []
          tagList.forEach((listItem) => {
            assets.push({ value: listItem.id, name: listItem.title })
          })
          this.setState({ assetsConfig: assets })
        }
      })
    })
  }

  finalize () {
    return JSON.stringify(this.state.messagingList)
  }

  hasFinalize () {
    return true
  }

  render () {
    const messagingList = this.state.messagingList
    // console.log(this.state);
    return (
      <>
        <div className='actionsTable__wrapper' key='textMessage'>
          <h2>Text Messages</h2>
          <div className='actionsTable__options'>
            <Search
              onChange={(k) => {
                this.setState({ filter: k.trim().toUpperCase() })
              }}
            />
            <Button id='add-action' color='success' variant='contained' onClick={() => this.addHandler('text')} startIcon={<AddIcon />}>
              Add New Text Message
            </Button>
          </div>
          <table className='actionsTable'>
            <thead>
              <tr className='actionsTable__header'>
                <th className='actionsTable__label'>C</th>
                <th className='actionsTable__label'>Title</th>
                <th className='actionsTable__label'>Content</th>
                <th className='actionsTable__label'>Follow-Up Materials</th>
                <th className='actionsTable__label'>Modify</th>
              </tr>
            </thead>
            <tbody>
              {messagingList.forEach((val, idx) => {
                let visible = true
                if (this.state.filter.length > 0) {
                  visible = (val.title + val.content).toUpperCase().includes(this.state.filter)
                }
                const display = visible ? 'table-row' : 'none'

                if (val.type === 'text') {
                  return (
                    <>
                      <RenderMessagingFields
                        key={'text_' + val.id}
                        assetsConfig={this.state.assetsConfig}
                        messagingConfig={this.state.messagingConfig}
                        singleMessage={val}
                        currentIdx={idx}
                        display={display}
                        cancelParent={(m) => {
                          this.cancelHandler(m, idx)
                        }}
                        updateParent={(m) => {
                          this.updateHandler(m, idx)
                        }}
                        deleteParent={(idx) => {
                          this.deleteHandler(idx)
                        }}
                        guid={this.guid}
                      />
                    </>
                  )
                }
              })}
            </tbody>
          </table>
        </div>

        <div className='actionsTable__wrapper' key='emailMessage'>
          <h2>Email Messages</h2>
          <div className='actionsTable__options'>
            <Search
              onChange={(k) => {
                this.setState({ filter: k.trim().toUpperCase() })
              }}
            />
            <Button id='add-action' color='success' variant='contained' onClick={() => this.addHandler('email')} startIcon={<AddIcon />}>
              Add New Email Message
            </Button>
          </div>
          <table className='actionsTable'>
            <thead>
              <tr className='actionsTable__header'>
                <th className='actionsTable__label'>C</th>
                <th className='actionsTable__label'>Title</th>
                <th className='actionsTable__label'>Subject</th>
                <th className='actionsTable__label'>Content</th>
                <th className='actionsTable__label'>Follow-Up Materials</th>
                <th className='actionsTable__label'>Modify</th>
              </tr>
            </thead>
            <tbody>
              {messagingList.forEach((val, idx) => {
                let visible = true
                if (this.state.filter.length > 0) {
                  visible = (val.title + val.content).toUpperCase().includes(this.state.filter)
                }
                const display = visible ? 'table-row' : 'none'

                if (val.type === 'email') {
                  return (
                    <RenderMessagingFields
                      key={'email_' + val.id}
                      assetsConfig={this.state.assetsConfig}
                      messagingConfig={this.state.messagingConfig}
                      singleMessage={val}
                      currentIdx={idx}
                      display={display}
                      cancelParent={(m) => {
                        this.cancelHandler(m, idx)
                      }}
                      updateParent={(m) => {
                        this.updateHandler(m, idx)
                      }}
                      deleteParent={(idx) => {
                        this.deleteHandler(idx)
                      }}
                      guid={this.guid}
                    />
                  )
                }
              })}
            </tbody>
          </table>
        </div>
      </>
    )
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} editorComponent={CompanyConfigMessaging} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}
