import React, { useState, useEffect } from 'react'
import { Box, Grid, TextField, Typography } from '@mui/material'

export const RightPanelProductClause = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [product, setProduct] = useState({})
  const [clause, setClause] = useState(props.product.productClause)

  useEffect(() => {
    if (isLoading) {
      const p = JSON.parse(JSON.stringify(props.product))
      setProduct(p)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (event) => {
    const p = JSON.parse(JSON.stringify(product))
    const c = JSON.parse(JSON.stringify(clause))

    c[event.target.name] = event.target.value
    p.productClause = c
    setProduct(p)
    setClause(c)
    props.setProduct(p)
  }

  return (
    <Box>
      <Box sx={{ p: 2, borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Typography style={styles.title}>Product Clause</Typography>
      </Box>
      <Grid container sx={{ pl: 2, mt: 4, mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            size='small'
            label='Title'
            name='title'
            variant='outlined'
            fullWidth
            value={clause.title}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ pl: 2 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            size='small'
            label='Description'
            name='body'
            variant='outlined'
            fullWidth
            multiline
            rows={8}
            value={clause.body}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Box>
        <Typography variant='h6' sx={{ p: 2, fontSize: '16px' }}>Codes</Typography>
        <Typography variant='h6' sx={{ pl: 2, fontSize: '15px', color: '#495057db' }}>$qty$</Typography>
        <Typography sx={{ pl: 2, fontSize: '15px', color: '#808080cc' }}>
          Add the text above into the description to have the quantity from the proposal print<br />
          into the description.
        </Typography>

        <Typography variant='h6' sx={{ pl: 2, pt: 2, fontSize: '15px', color: '#495057db' }}>$price$</Typography>
        <Typography sx={{ pl: 2, fontSize: '15px', color: '#808080cc' }}>
          Add the text above into the description to have the itemized price from the proposal<br /> print into the description.
        </Typography>
      </Box>

      <Box>
        <Typography variant='h6' sx={{ p: 2, fontSize: '16px' }}>Example</Typography>
        <Typography variant='h6' sx={{ pl: 2, fontSize: '15px', color: '#495057db' }}>Admin</Typography>
        <Typography sx={{ pl: 2, fontSize: '15px', color: '#808080cc' }}>
          If amount installed exceeds $qty$ lbs, there will be an additional charge of $$price$/lb.
        </Typography>

        <Typography variant='h6' sx={{ pl: 2, pt: 2, fontSize: '15px', color: '#495057db' }}>Proposal</Typography>
        <Typography sx={{ pl: 2, fontSize: '15px', color: '#808080cc' }}>
          If amount installed exceeds 68.2 lbs, there will be an additional charge of $2.00/lb.
        </Typography>
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'white'
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  }
}
