import React, { useState, useEffect, useRef } from 'react'
import Permissions from '../../../../../library/Permissions'
import { findInForm } from '../PDFDocumentEditor'
import { HelperHLine, HelperVLine, Vertice, calculateOffset, calculateAnchor } from './Field'
import MoveableChild from './MoveableChild'

const FieldSignature = (props) => {
  const [dimensions, setDimensions] = useState()
  const [showJSON, setShowJSON] = useState(false)
  const fieldRef = useRef()

  useEffect(() => {
    if (fieldRef.current) {
      setDimensions(fieldRef.current.getBoundingClientRect())
    }
  }, [fieldRef])

  useEffect(() => {
    if (dimensions && dimensions.height && dimensions.width) {
      if (!props.field.width) {
        props.field.width = 96
        props.field.height = 24
        props.onChange('field', props.field)
      }
    }
    // eslint-disable-next-line
  }, [dimensions])

  const handleMoveVertex = (variant) => {
    props.onMoveVertex(variant)
  }

  const field = props.field
  let [x, y] = [0, 0];
  [x, y] = calculateOffset(field.x, field.y, props.zoom, 'signature')

  let variant = props.variant
  if (!findInForm(props.sforms, field)) {
    variant = 'zombie'
  }

  let anchor = {}
  if (field.date) {
    anchor = calculateAnchor(x, y, field.width, field.height, props.pdfWidth, props.pdfHeight, props.zoom)
  }

  // console.log(`${field.id} ${props.number} selected: ${props.selected}`)

  return (
    <div>
      {props.helperHLine ? <HelperHLine y={y} field={field} {...props} /> : null}
      {props.helperVLine ? <HelperVLine x={x} dimensions={dimensions} {...props} /> : null}
      {field.date ? <MoveableChild title='mm/dd/yyyy' child={field.date} {...props} number={props.number} anchor={anchor} /> : null}

      <div
        style={{ ...styles.fieldContainer, bottom: y, left: x, translate: `scale:${props.zoom}` }}
        onMouseDown={() => {
          props.onFieldSelect(field, props.fieldSubIndex)
          props.onMouseDown(props.fieldIndex, props.fieldSubIndex)
        }}
      >
        <div style={!props.selected ? { ...styles.fieldCircle } : { ...styles.fieldCircle, ...styles.selectedBackground }} onClick={() => setShowJSON(!showJSON)}>
          <span style={styles.fieldCircleNumber}>{props.number || 1}</span>
        </div>

        <div ref={fieldRef} key={field.id} style={!props.selected ? { ...styles[variant] } : { ...styles[variant], ...styles.selectedBorder }}>
          {props.selected && field.width
            ? (
              <>
                <Vertice
                  top={-8}
                  left={field.width * props.zoom}
                  fill='blue'
                  onMouseDown={() => {
                    handleMoveVertex('TR')
                  }}
                />
                <Vertice
                  top={field.height * props.zoom - 4}
                  left={field.width * props.zoom}
                  fill='blue'
                  onMouseDown={() => {
                    handleMoveVertex('BR')
                  }}
                />
              </>
              )
            : null}

          {field.width
            ? (
              <div style={{ ...styles.field, width: field.width * props.zoom, height: field.height * props.zoom }}>
                <span style={{ ...styles.fieldSpan, fontSize: field.width / 8 * props.zoom }}>{field.title}</span>
              </div>
              )
            : null}

          <div style={!props.selected ? { ...styles.delete } : { ...styles.delete, ...styles.selectedColor }}>
            <span onClick={props.onDelete}>x</span>
          </div>
        </div>
      </div>
      {Permissions.hasRole('super_user') && showJSON
        ? (
          <div style={{ ...styles.jsonContainer, top: props.pdfHeight - y, left: x + 16 + field.width }}>
            <pre style={styles.jsonText}>{JSON.stringify(field, null, 2)}</pre>
          </div>
          )
        : null}
    </div>
  )
}
export default FieldSignature

const styles = {
  fieldContainer: {
    position: 'absolute',
    border: '1px dotted rgba(0,0,0,.16)',
    backgroundColor: 'rgba(255,100,100,0)',
    zIndex: 2
  },
  field: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    paddingTop: 0,
    paddingLeft: 8,
    paddingRight: 8
  },
  fieldSpan: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontFamily: 'Snell Roundhand',
    paddingLeft: 4
  },
  values: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 10,
    fontWeight: 'bold',
    border: '2px solid rgba(0,0,0,.16)',
    borderRadius: '4px',
    backgroundColor: 'rgba(0,0,0,.08)',
    userSelect: 'none',
    WebkitUserSelect: 'none'
  },
  zombie: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 10,
    fontWeight: 'bold',
    border: '2px solid rgba(255,0,0,1)',
    borderRadius: '4px',
    backgroundColor: 'rgba(0,0,0,.08)',
    userSelect: 'none'
  },
  selectedBorder: {
    border: '2px solid blue'
  },
  selectedBackground: {
    backgroundColor: 'blue'
  },
  selectedColor: {
    color: 'blue'
  },
  delete: {
    position: 'absolute',
    fontSize: 14,
    bottom: -10,
    left: -4,
    userSelect: 'none'
  },
  navCircle: {
    borderRadius: '100%',
    height: '16px',
    width: '16px',
    backgroundColor: 'black',
    alignItems: 'center',
    verticalAlign: 'center',
    textAlign: 'center'
  },
  fieldCircle: {
    position: 'absolute',
    borderRadius: '100%',
    top: '-8px',
    left: '-8px',
    height: '16px',
    width: '16px',
    backgroundColor: 'black',
    alignItems: 'center',
    verticalAlign: 'center',
    textAlign: 'center'
  },
  fieldCircleNumber: {
    position: 'relative',
    top: '-8px',
    fontSize: 8,
    fontWidth: 'bold',
    fontFamily: 'sans-serif',
    color: 'white'
  },
  jsonContainer: {
    position: 'absolute',
    padding: 12,
    borderRadius: 4,
    border: '1px dotted rgba(255,255,0,1)',
    backgroundColor: 'rgba(0,0,0,1)'
  },
  jsonText: {
    fontSize: 12,
    color: 'yellow'
  }
}
