import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, IconButton, Paper, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DataGrid from 'components/Reusable/DataGrid'
import { DialogGlobalWarrantySelector } from '../../../components/Reusable/DialogGlobalWarrantySelector'
import UpIcon from '@mui/icons-material/KeyboardArrowUp'
import DownIcon from '@mui/icons-material/KeyboardArrowDown'
import DeleteIcon from '@mui/icons-material/DeleteOutline'

export const GlobalWarranty = (props) => {
  const [open, setOpen] = useState(false)
  const [showWarrDialog, setShowWarrDialog] = useState(false)
  const [warrantyConfig, setWarrantyConfig] = useState([])
  const [warranty, setWarranty] = useState([])

  useEffect(() => {
    if ('warrantyConfig' in props) {
      setWarrantyConfig(props.warrantyConfig)
    }
    if ('warranty' in props) {
      setWarranty(props.warranty)
    }
    // eslint-disable-next-line
  }, [])

  const showWarranty = () => {
    setShowWarrDialog(!showWarrDialog)
  }

  const handleAddWarranty = (warrantyList) => {
    setShowWarrDialog(!showWarrDialog)
    setWarranty(warrantyList)
    props.onChange(warrantyList)
  }

  const handleDelete = (idx) => {
    const newWarranty = JSON.parse(JSON.stringify(warranty))
    newWarranty.splice(idx, 1)
    setWarranty(newWarranty)
    props.onChange(newWarranty)
  }

  const handleMove = (idx, direction) => {
    const p = JSON.parse(JSON.stringify(warranty))
    if (direction === 'up') {
      if (p[idx - 1]) {
        const toSwitch = JSON.parse(JSON.stringify(p[idx]))
        const switchWith = JSON.parse(JSON.stringify(p[idx - 1]))
        p[idx - 1] = toSwitch
        p[idx] = switchWith
        setWarranty(p)
        props.onChange(p)
      }
    } else if (direction === 'down') {
      if (p[idx + 1]) {
        const switchWith = JSON.parse(JSON.stringify(p[idx + 1]))
        p[idx + 1] = JSON.parse(JSON.stringify(p[idx]))
        p[idx] = switchWith
        setWarranty(p)
        props.onChange(p)
      }
    }
  }

  const getWarrantyTitle = (wid) => {
    const entry = warrantyConfig.filter((o) => {
      return wid === o.id
    })
    if (0 in entry) {
      return entry[0].heading
    } else {
      return '- Warranty Missing -'
    }
  }

  const columns = [
    {
      flex: 1,
      type: 'string',
      field: 'title',
      headerName: 'Title',
      editable: false,
      sortable: true
    },
    {
      flex: 1,
      field: 'action',
      headerName: 'Actions',
      headerAlign: 'right',
      align: 'right',
      editable: false,
      sortable: false,
      disableExport: true,
      renderCell: (params) => {
        if (params.row) {
          const idx = params.row.warrantyId
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton title='Move Up' aria-label='up' onClick={() => handleMove(idx, 'up')} disabled={idx < 1}>
                  <UpIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton title='Move Down' aria-label='down' onClick={() => handleMove(idx, 'down')} disabled={idx === warranty.length - 1}>
                  <DownIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton title='Delete' aria-label='delete' onClick={() => handleDelete(idx)}>
                  <DeleteIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < warranty.length; x++) {
    rows.push({ id: x, warrantyId: x, title: getWarrantyTitle(warranty[x]) })
  }

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer component={Paper} sx={{ mb: 1, borderBottom: 0 }}>
        <Table aria-label='customized table' sx={{ borderBottom: '2px solid #2a3042' }}>
          <TableHead>
            <TableRow onClick={() => setOpen(!open)}>
              <TableCell>Global Warranties ({warranty.length})</TableCell>
              <TableCell align='right'>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {open && (
          <DataGrid
            rows={rows}
            columns={columns}
            sx={{ border: 0, '& .MuiDataGrid-columnHeaders': { borderRadius: 0 } }}
            showToolbar={false}
            showSearch={false}
            onAdd={showWarranty}
          />
        )}
      </TableContainer>
      {open && (
        <Box sx={{ mt: 1, mb: 1 }}>
          <Button id='additionalNotes-action' color='primary' variant='contained' onClick={showWarranty}>
            Add Warranty
          </Button>
        </Box>
      )}
      {showWarrDialog && (
        <DialogGlobalWarrantySelector
          title='Select Global Warranty'
          list={warranty}
          warrantyConfig={warrantyConfig}
          onSave={(w) => handleAddWarranty(w)}
          onClose={() => setShowWarrDialog(!showWarrDialog)}
        />
      )}
    </Box>
  )
}
