import React from 'react'
import { Grid, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const DrawerHeader = ({ icon, title, onClose }) => {
  return (
    <Grid container sx={{ pt: 1, pb: 1, pl: 2, pr: 1 }} style={styles.container}>
      {icon
        ? (
          <Grid item sx={{ pr: 1 }} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            {icon}
          </Grid>
          )
        : null}
      <Grid item sx={{ pl: 0 }} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='h6'>{title}</Typography>
      </Grid>
      <Grid item xs sx={{ pr: 0 }} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
export default DrawerHeader

const styles = {
  container: {
    borderBottom: '1px solid rgba(0,0,0,.08)'
  }
}
