import React, { useState, useEffect } from 'react'
import FieldTypeAddLogicPanel from 'screens/Config/Forms/FieldTypeAddLogicPanel'
import FieldTypeExpandMarker from '../Reusables/FieldTypeExpandMarker'
import FieldSelectDialog from '../Reusables/FieldSelectDialog'
import CalculatorFuncDialog from '../Reusables/CalculatorFuncDialog'
import { Paper, Link, Divider } from '@mui/material'
import QuestionChip from '../Reusables/QuestionChip'
import CalcOperator from '../Reusables/CalcOperator'
// import { elementAt } from 'rxjs/operators'
import AddLogic from '../AddLogic'
import RelatedFieldTypeEditor from '../Reusables/RelatedFieldTypeEditor'

function Calculator (props) {
  // const [options, setOptions] = useState(props.list);
  const [field, setField] = useState(props.field)
  const [hasLogic, setHasLogic] = useState(false)
  // const [questionArr, setQuestionArr] = useState(props.questionArr)
  // const [operatorArr, setOperatorArr] = useState(props.operatorArr)
  const [minMax, setMinMax] = useState(props.minMax)
  // const [currQuestion, setCurrentQuestion] = useState(props.currentQuestion)
  const [optionValue, setOptionValue] = useState('')
  const [tabItem, setTabItem] = useState('')
  const [numCondValue, setNumCondValue] = useState('')
  const [relatedLength, setRelatedLength] = useState(0)
  const [unitArrayHelper, setUnitArrayHelper] = useState([])
  // const [visibleInApp, setVisibleInApp] = useState(false)

  // console.log(props);
  const expElemArrMain = [
    <FieldSelectDialog
      key={0}
      allFields={props.allFields}
      onChange={(f, elemIdx, elemArr) => changeHandlerQuestion(f, elemIdx, elemArr)}
      elemIdx={0}
    />,
    <CalcOperator
      key={1}
      onChangeOperator={(op, elemIdx) => changeHandlerOperator(op, elemIdx)}
      elemIdx={1}
      opr={props.operatorArr.length !== 0 ? props.operatorArr[0].operator : ''}
    />,
    <FieldSelectDialog
      key={2}
      allFields={props.allFields}
      onChange={(f, elemIdx, elemArr) => changeHandlerQuestion(f, elemIdx, elemArr)}
      elemIdx={2}
    />
  ]

  const [expElemArr, setExpElemArr] = useState(props.elemArr.length === 0 ? expElemArrMain : props.elemArr)

  useEffect(() => {
    const newElemArr = [...expElemArr]
    let idx

    if ('val' in props.currentQuestion) {
      idx = newElemArr.findIndex(x => x.val === props.currentQuestion.val)
    }
    if ('id' in props.currentQuestion) {
      idx = newElemArr.findIndex(x => x.id === props.currentQuestion.id)
    }

    newElemArr[idx] = (
      <FieldSelectDialog
        key={idx}
        allFields={props.allFields}
        onChange={(f, elemIdx, elemArr) => changeHandlerQuestion(f, elemIdx, elemArr)}
        elemIdx={idx}
        currQuestion={newElemArr[idx]}
        onClose={onCloseHandler}
      />
    )

    setExpElemArr(newElemArr)
    // setCurrentQuestion(props.currentQuestion)
    props.onChangeElemArr(newElemArr)
    // eslint-disable-next-line
  }, [props.currentQuestion])

  useEffect(() => {
    setMinMax(props.minMax)
    // eslint-disable-next-line
  }, [props.minMax])

  /* useEffect(() => {
    setOperatorArr(props.operatorArr)
  }) */

  useEffect(() => {
    let newElemArr = []
    let arrLength
    let l = 0

    // console.log(props.questionArr);
    // console.log(props.elemArr);
    if (props.questionArr.length !== 0 && props.elemArr.length === 0) {
      arrLength = props.questionArr[props.questionArr.length - 1].idx + 1

      /* if(props.questionArr.length === 1) {
                arrLength = 2;
            } */
      /* } else if(props.questionArr.length !== 0 && props.elemArr.length !== 0) {
            arrLength = props.elemArr.length;
        } *//* } else if(props.questionArr.length === 1) {
            arrLength = 2;
        } */ } else if (props.questionArr.length > 2) {
      arrLength = props.questionArr[props.questionArr.length - 1].idx + 1
    } else {
      arrLength = expElemArr.length
    }

    // console.log(props.questionArr);
    // console.log(props.operatorArr);
    // console.log(props.elemArr);
    // console.log(arrLength);
    // console.log(expElemArr);

    if (props.questionArr.length > 0) {
      for (let i = 0; i < arrLength; i++) {
        if (i % 2 === 0) {
          // console.log(props.questionArr[i]);
          newElemArr.push(
            <FieldSelectDialog
              key={i}
              allFields={props.allFields}
              onChange={(f, elemIdx, elemArr) => changeHandlerQuestion(f, elemIdx, elemArr)}
              elemIdx={i}
            />
          )
        } else {
          newElemArr.push(
            <CalcOperator
              key={i}
              onChangeOperator={(op, elemIdx) => changeHandlerOperator(op, elemIdx)}
              elemIdx={i}
              opr={props.operatorArr[l] !== undefined ? props.operatorArr[l].operator : ''}
            />
          )
          l++
        }
      }
    } else {
      newElemArr = [...expElemArr]
    }
    // console.log(newElemArr);
    for (let i = 0; i < props.questionArr.length; i++) {
    // props.questionArr.map((arr, idx) => {
      if ('question' in props.questionArr[i]) {
        newElemArr.splice(props.questionArr[i].idx, 1, props.questionArr[i].question)
      }
      if ('numerical' in props.questionArr[i]) {
        newElemArr.splice(props.questionArr[i].idx, 1, props.questionArr[i].numerical)
      }
    }
    // )

    setExpElemArr(newElemArr)
    // console.log(newElemArr);
    // setQuestionArr(props.questionArr)
    props.onChangeElemArr(newElemArr)
    // eslint-disable-next-line
  }, [props.questionArr])

  /* useEffect( () => {
        let newElemArr = [];
        let arrLength;
        let l=0;

        //console.log(props.questionArr);
        //console.log(props.elemArr);
        if(props.questionArr.length !== 0 && props.elemArr.length === 0) {
            arrLength = props.questionArr[props.questionArr.length-1].idx + 1;

            if(props.questionArr.length === 1) {
                arrLength = 2;
            }
            /!*} else if(props.questionArr.length !== 0 && props.elemArr.length !== 0) {
                arrLength = props.elemArr.length;
            }*!/} /!*else if(props.questionArr.length === 1) {
            arrLength = 2;
        }*!/ else if(props.questionArr.length > 2) {
            arrLength = props.questionArr[props.questionArr.length-1].idx + 1;
        }
        else {
            arrLength = expElemArr.length;
        }

        if(props.questionArr.length > 0) {
            for (let i=0; i<arrLength; i++) {
                if(i%2 === 0) {
                    //console.log(props.questionArr[i]);
                    newElemArr.push(
                        <FieldSelectDialog
                            key={i}
                            allFields={props.allFields}
                            onChange={(f, elemIdx, elemArr)=>changeHandlerQuestion(f, elemIdx, elemArr)}
                            elemIdx={i}
                        />
                    )
                } else {
                    newElemArr.push(
                        <CalcOperator
                            key={i}
                            onChangeOperator={(op, elemIdx) => changeHandlerOperator(op, elemIdx)}
                            elemIdx={i}
                            opr={props.operatorArr[l] !== undefined ? props.operatorArr[l].operator : ''}
                        />
                    )
                    l++;
                }
            }
        } else {
            newElemArr = [...expElemArr];
        }
        //console.log(newElemArr);
        props.questionArr.map((arr, idx) => {
            if('question' in arr) {
                newElemArr.splice(arr.idx, 1, arr.question);
            }
            if('numerical' in arr) {
                newElemArr.splice(arr.idx, 1, arr.numerical);
            }
        })
        setExpElemArr(newElemArr);
    }, [props.operatorArr]) */

  const onCloseHandler = () => {
    setExpElemArr(props.elemArr)
    props.onSetCurrentQuestion({})
  }

  const changeHandlerVisible = (visible) => {
    props.onChangeVisible(visible)
  }

  const changeHandlerVisibleInApp = (display) => {
    props.onChangeVisibleInApp(display)
  }

  const changeHandlerCRM = (crm) => {
    props.onChangeCRM(crm)
  }

  const changeHandlerUnits = (units) => {
    props.onChangeUnits(units)
  }

  const changeHandlerQuestion = (f, elemIdx, elemArr) => {
    // console.log('changeHandlerQuestion - calc');
    props.onChangeQuestionArr(f, elemIdx, elemArr)
  }

  const changeHandlerOperator = (operator, elemIdx) => {
    props.onChangeOperatorArr(operator, elemIdx)
  }

  const addQuestionHandler = () => {
    const newExpElemArr = [...expElemArr]
    newExpElemArr.push(
      <CalcOperator
        key={props.guid()}
        onChangeOperator={(op, elemIdx) => changeHandlerOperator(op, elemIdx)}
        elemIdx={newExpElemArr.length}
        opr=''
      />
    )

    newExpElemArr.push(
      <FieldSelectDialog
        key={props.guid()}
        allFields={props.allFields}
        onChange={(f, elemIdx, elemArr) => changeHandlerQuestion(f, elemIdx, elemArr)}
        elemIdx={newExpElemArr.length}
      />
    )

    setExpElemArr(newExpElemArr)
    props.onChangeElemArr(newExpElemArr)
  }

  const deleteHandlerQuestion = (elem) => {
    console.log('delete handler')
    const newElemArr = [...expElemArr]
    let elemIdx
    if ('id' in elem) {
      elemIdx = newElemArr.findIndex(obj => obj.id === elem.id)
    }
    if ('val' in elem) {
      elemIdx = newElemArr.findIndex(obj => obj.val === elem.val)
    }

    newElemArr.splice(elemIdx, 1, <FieldSelectDialog
      allFields={props.allFields}
      onChange={(f, elemIdx, elemArr) => changeHandlerQuestion(f, elemIdx, elemArr)}
      elemIdx={elemIdx}
                                  />)

    setExpElemArr(newElemArr)
    props.onDeleteQuestionArr(elem)
    if (!('id' in newElemArr[newElemArr.length - 1])) {
      props.onDeleteOperatorArr(elemIdx - 1)
    }

    props.onChangeElemArr(newElemArr)
  }

  const selectHandlerQuestion = (elem) => {
    // console.log('calculator');
    props.onSetCurrentQuestion(elem)
  }

  const addLogic = (e) => {
    if (e) {
      setHasLogic(true)
      // alert('under construction ..');
    }
  }

  const saveHandlerMinMax = (val, type) => {
    const newMinMax = { ...minMax }

    if (type === 'min' || type === 'max') {
      if (val === '') {
        delete newMinMax[type]
      } else {
        newMinMax[type] = val
      }
    }
    if (type === 'floor') {
      newMinMax[type] = val
      if ('ceil' in newMinMax) {
        delete newMinMax.ceil
      }
      if ('round' in newMinMax) {
        delete newMinMax.round
      }
    }
    if (type === 'ceil') {
      newMinMax[type] = val
      if ('floor' in newMinMax) {
        delete newMinMax.floor
      }
      if ('round' in newMinMax) {
        delete newMinMax.round
      }
    }
    if (type === 'total') {
      if ('ceil' in newMinMax) {
        delete newMinMax.ceil
      }
      if ('floor' in newMinMax) {
        delete newMinMax.floor
      }
      if ('round' in newMinMax) {
        delete newMinMax.round
      }
    }
    if (type === 'round') {
      newMinMax[type] = val
      if ('ceil' in newMinMax) {
        delete newMinMax.ceil
      }
      if ('floor' in newMinMax) {
        delete newMinMax.floor
      }
    }

    setMinMax(newMinMax)
    props.onChangeMinMax(newMinMax)
  }

  const changeHandlerOption = (val) => {
    setOptionValue(val)
  }

  const askQuestionCalc = () => {
    const newField = JSON.parse(JSON.stringify(field))

    if (!('related' in newField)) {
      newField.related = []
    }

    const existDupOption = newField.related.some((el) => {
      // return el.ranges[0].hasOwnProperty(optionValue) && el.ranges[0][optionValue] === numCondValue
      return Object.prototype.hasOwnProperty.call(el.ranges[0], optionValue) && (el.ranges[0][optionValue] === numCondValue)
    })

    if (!existDupOption) {
      newField.related.push({
        id: props.guid(),
        ranges: [
          {
            [optionValue]: numCondValue
          }
        ],
        type: null
      })
    }
    /* newField['related'].push({
           id: props.guid(),
           ranges: [{
               [optionValue]: numCondValue
           }],
           type: null
        }); */

    setField(newField)
    props.askQuestion(newField)
  }

  const changeNumericCondition = (val) => {
    setNumCondValue(val)
  }

  const changeRelatedOptionVal = (oldVal, newVal) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    if ('related' in newField) {
      // newField.related.map((rel, idx) => {
      for (let i = 0; i < newField.related.length; i++) {
        if (newField.related[i].value === oldVal) {
          newField.related[i].value = newVal
        }
      }
      // )
    }
    setField(newField)
    props.askQuestion(newField)
  }

  const saveIssues = (withIssuesArr) => {
    const newField = JSON.parse(JSON.stringify(withIssuesArr))
    setField(newField)
    props.askQuestion(newField)
  }

  const saveSolutions = (solutions) => {
    props.onSaveSolutions(solutions)
  }

  const clickHandlerTab = (tab) => {
    setTabItem(tab)
  }

  const changeHandlerRelated = (related, idx) => {
    // console.log('changeHandlerRelated');
    // console.log(related, idx);
    const newField = JSON.parse(JSON.stringify(props.field))
    newField.related[idx] = related
    setField(newField)
    props.askQuestion(newField)
  }

  const deleteRelated = (idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))

    newField.related.splice(idx, 1)
    if (!newField.related.length) {
      delete newField.related
    }

    setField(newField)
    props.askQuestion(newField)
  }

  const deleteHandlerQuestionCalc = (field) => {
    props.askQuestion(field)
  }

  /* const changeHandlerCalcExpGen = (elem) => {
        console.log(elem);
    } */

  const createHandlerUnit = (unitArr) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    if ('unitArrayHelper' in newField) {
      newField.unitArrayHelper = unitArr
    } else {
      newField.unitArrayHelper = unitArr
    }
    // newField['unitArray'] = unitArr;
    // setField(newField);
    setUnitArrayHelper(unitArr)
    props.onChangeUnitArr(newField)
    // props.onChange(newField);
  }

  /* const deleteHandlerUnit = (unitArr) => {
        let newField = JSON.parse(JSON.stringify(props.field));
        newField['unitArray'] = unitArr;
        setField(newField);
        setUnitArr(unitArr);
        props.onChange(newField);
    } */

  /* const changeHandlerRelatedQuestionArr = (questionArr) => {
        console.log(questionArr);
    } */

  useEffect(() => {
    setField(props.field)
  }, [props.field])

  useEffect(() => {
    if ('solutions' in props.form) {
      for (let i = 0; i < props.form.solutions.length; i++) {
      // props.form.solutions.map((s, idx) => {
        if (props.form.solutions[i].fieldTypeId === props.field.id) {
          setHasLogic(true)
        }
      }
      // )
    }

    if ('related' in props.field || 'issues' in props.field) {
      setHasLogic(true)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if ('unitArrayHelper' in props.field) {
      setUnitArrayHelper(props.field.unitArrayHelper)
    }
  }, [props.field])

  useEffect(() => {
    if (props.field.related && props.field.related.length > 0) {
      setRelatedLength(props.field.related.length)
      setTabItem(props.field.related[props.field.related.length - 1].id)
    }
  }, [props.field.related])

  useEffect(() => {
    if (props.field.related && props.field.related.length > 0) {
      if (props.field.related.length !== relatedLength) {
        setTabItem(props.field.related[props.field.related.length - 1].id)
      }
    }
    // eslint-disable-next-line
  }, [props.field.related])
  // console.log(unitArrayHelper);
  return (
    <>
      {
        props.expandMarker &&
          <FieldTypeExpandMarker />
      }
      <div style={styles.logicPanel}>
        <FieldTypeAddLogicPanel
          {...props}
          field={props.field}
          onChangeVisible={(visible) => changeHandlerVisible(visible)}
          onChangeVisibleInApp={(display) => changeHandlerVisibleInApp(display)}
          onChangeCRM={(crm) => changeHandlerCRM(crm)}
          onChangeUnits={(units) => changeHandlerUnits(units)}
          onAddLogic={(e) => addLogic(e)}
          crmFieldsConfig={props.crmFieldsConfig}
          numericUnitsConfig={props.numericUnitsConfig}
          unitArr={unitArrayHelper}
          onCreateUnit={(unitArr) => createHandlerUnit(unitArr)}
                    // onDeleteUnit={(unitArr)=>deleteHandlerUnit(unitArr)}
                    // onChangeDefault={(val) => changeHandlerDefault(val)}
          guid={props.guid}
        />

        {
          hasLogic &&
            <AddLogic
              {...props}
              type='calc'
              field={field}
              onSelectChange={(val) => changeHandlerOption(val)}
              askQuestionFieldType={() => askQuestionCalc()}
              onNumericChange={(val) => changeNumericCondition(val)}
              onRelatedOptionValChange={(oldVal, newVal) => changeRelatedOptionVal(oldVal, newVal)}
              onSaveIssues={(field) => saveIssues(field)}
              onSaveSolutions={(solutions) => saveSolutions(solutions)}
              form={props.form}
              allFieldsSolutions={props.allFieldsSolutions}
              onClickTab={(tab) => clickHandlerTab(tab)}
              onDeleteQuestion={(field) => deleteHandlerQuestionCalc(field)}
              guid={props.guid}
            />
        }

        {
                    'related' in field &&
                    field.related.map((rel, idx) => {
                      return (
                        <div key={`related-${idx}`}>
                          <div style={tabItem === rel.id ? styles.relatedMain : styles.relatedMainHide}>
                            <FieldTypeExpandMarker />
                            <RelatedFieldTypeEditor
                              {...props}
                              field={field.related[idx]}
                              onChange={(related) => changeHandlerRelated(related, idx)}
                              onDelete={() => deleteRelated(idx)}
                              guid={props.guid}
                              form={props.form}
                              onSaveSolutions={(solutions) => saveSolutions(solutions)}
                              allFieldsSolutions={props.allFieldsSolutions}
                              crmFieldsConfig={props.crmFieldsConfig}
                              numericUnitsConfig={props.numericUnitsConfig}
                              allFields={props.allFields}
                            />
                          </div>
                        </div>
                      )
                    })
                }

        <Paper elevation={0} square className='addlogic-main-paper' style={styles.paper}>
          <div style={styles.paperItems}>
            <div style={styles.triggerPanel}>
              <span>calculation is</span>

              {
                                expElemArr.map((e, idx) => {
                                  if (typeof e === 'object' && e !== null && 'id' in e) {
                                    return (
                                      <QuestionChip
                                        key={idx}
                                        onDelete={(elem) => deleteHandlerQuestion(elem)}
                                        elem={e}
                                        onSelectQuestion={(elem) => selectHandlerQuestion(elem)}
                                      />
                                    )
                                  } else if (typeof e === 'object' && e !== null && 'val' in e) {
                                    return (
                                      <QuestionChip
                                        key={idx}
                                        onDelete={(elem) => deleteHandlerQuestion(elem)}
                                        elem={e}
                                        onSelectQuestion={(elem) => selectHandlerQuestion(elem)}
                                      />
                                    )
                                  } else {
                                    return (e)
                                  }
                                })
                            }

              <Link
                                // component={"button"}
                style={styles.addQuestionLink}
                onClick={addQuestionHandler}
                underline='hover'
              >
                + addt'l. calculation
              </Link>

            </div>
          </div>
        </Paper>

        <Paper elevation={0} square className='addLogic-min-max' style={styles.paper}>
          <div style={styles.paperItems}>
            <div style={styles.triggerPanel}>
              <span>Minimum set to&nbsp;</span>
              <CalculatorFuncDialog
                type='min'
                header='Set Minimum'
                subHeader='The calculator will not compute below this number.'
                onSave={(val) => saveHandlerMinMax(val, 'min')}
                minMax={minMax}
              />

              <Divider style={styles.leftGridDivider} orientation='vertical' variant='middle' flexItem />
              <span>Maximum set to&nbsp;</span>
              <CalculatorFuncDialog
                type='max'
                header='Set Maximum'
                subHeader='The calculator will not exceed this number.'
                onSave={(val) => saveHandlerMinMax(val, 'max')}
                minMax={minMax}
              />

              <Divider style={styles.leftGridDivider} orientation='vertical' variant='middle' flexItem />
              <span>Rounding set to&nbsp;</span>
              <CalculatorFuncDialog
                type='round'
                header='Set Rounding'
                onChangeRound={(val, type) => saveHandlerMinMax(val, type)}
                minMax={minMax}
                onSave={(val) => saveHandlerMinMax(val, 'round')}
              />
            </div>
          </div>
        </Paper>
      </div>

      {/* <div style={styles.mainDiv}>
                <FieldTypeExpandMarker />
                <Paper elevation={0} square={true} className={"addlogic-main-paper"} style={styles.paper}>
                    <div style={styles.paperItems}>
                        <div style={styles.triggerPanel}>
                            <span>calculation is</span>

                            {
                                expElemArr.map((e, idx) => {
                                    if(typeof e === 'object' && e !== null && 'id' in e) {
                                        return(
                                            <QuestionChip
                                                key={idx}
                                                onDelete={(elem)=>deleteHandlerQuestion(elem)}
                                                elem={e}
                                                onSelectQuestion={(elem)=>selectHandlerQuestion(elem)}
                                            />
                                        );
                                    } else if (typeof e === 'object' && e !== null && 'val' in e) {
                                      return(
                                          <QuestionChip
                                              key={idx}
                                              onDelete={(elem)=>deleteHandlerQuestion(elem)}
                                              elem={e}
                                              onSelectQuestion={(elem)=>selectHandlerQuestion(elem)}
                                          />
                                      );
                                    } else {
                                        return(e);
                                    }
                                })
                            }

                            <Link
                                //component={"button"}
                                style={styles.addQuestionLink}
                                onClick={addQuestionHandler}
                                underline={"hover"}
                            >
                                + addt'l. calculation
                            </Link>

                        </div>
                    </div>
                </Paper>
            </div> */}

      {/* <div style={styles.mainDiv}>
                <FieldTypeExpandMarker />
                <Paper elevation={0} square={true} className={"addLogic-min-max"} style={styles.paper}>
                    <div style={styles.paperItems}>
                        <div style={styles.triggerPanel}>
                            <span>Minimum set to&nbsp;</span>
                            <CalculatorFuncDialog
                                type={'min'}
                                header={"Set Minimum"}
                                subHeader={"The calculator will not compute below this number."}
                                onSave={(val)=>saveHandlerMinMax(val, 'min')}
                                minMax={minMax}
                            />

                            <Divider style={ styles.leftGridDivider } orientation="vertical" variant="middle" flexItem />
                            <span>Maximum set to&nbsp;</span>
                            <CalculatorFuncDialog
                                type={'max'}
                                header={"Set Maximum"}
                                subHeader={"The calculator will not exceed this number."}
                                onSave={(val)=>saveHandlerMinMax(val, 'max')}
                                minMax={minMax}
                            />

                            <Divider style={ styles.leftGridDivider } orientation="vertical" variant="middle" flexItem />
                            <span>Rounding set to&nbsp;</span>
                            <CalculatorFuncDialog
                                type={'round'}
                                header={"Set Rounding"}
                                onChangeRound={(val, type)=>saveHandlerMinMax(val, type)}
                                minMax={minMax}
                                onSave={(val)=>saveHandlerMinMax(val, 'round')}
                            />
                        </div>
                    </div>
                </Paper>
            </div> */}
    </>
  )
}

const styles = {
  mainDiv: {
    display: 'flex'
  },
  paper: {
    borderLeft: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    // marginRight: "96.19px",
    flexBasis: '100%'
  },
  paperItems: {
    width: '100%',
    color: '#000000a3'
  },
  triggerPanel: {
    lineHeight: '56px',
    marginLeft: '25px',
    marginRight: '25px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  triggerPanelEl: {
    textDecoration: 'none'
  },
  triggerPanelElSelect: {
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '5px'
  },
  select: {
    fontSize: '13px',
    color: '#1976d2',
    paddingTop: 0,
    paddingBottom: 0,
    minWidth: '150px',
    background: '#007bff26'
  },
  selectOperator: {
    fontSize: '13px',
    color: '#1976d2',
    paddingTop: 0,
    paddingBottom: 0,
    minWidth: '150px'
  },
  formControlOperator: {
    marginTop: '10px'
  },
  formControlQuestion: {
    marginTop: '5px'
  },
  addQuestionLink: {
    marginLeft: '10px'
  },
  totalLink: {
    marginLeft: '5px'
  },
  leftGridDivider: {
    display: 'flex',
    lineHeight: '56px',
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '15px',
    marginRight: '15px'
  },
  logicPanel: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%'
  },
  relatedMain: {
    display: 'flex'
  },
  relatedMainHide: {
    display: 'none'
  }
}

export default Calculator
