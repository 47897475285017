import React from 'react'
import { withSubscription } from './Subscriber'
import { Topics } from 'config/topics'

/**
 * Fills the screen with a loading overlay that lasts for a short time.
 */
export class ModalOverlay extends React.Component {
  static topic

  /**
     * @type {ModalOverlay}
     */
  static instance

  /**
     *
     * @param {Object} props
     * @param {string} [props.message] Message to display
     * @param {boolean} [props.visible=false] Whether or not the loading overlay is visible
     * @param {number} [props.maxDuration=30] Maximum duration to show the loading screen -- useful if the
     *        background process doesn't return.
     */
  constructor (props) {
    super(props)

    this._isMounted = false

    this.state = {
      visible: !!this.props.visible,
      title: this.props.title ? this.props.title : null,
      maxDuration: this.props.maxDuration ? this.props.maxDuration : 30,
      imageUrl: this.props.imageUrl
    }

    ModalOverlay.topic = this.props.topic

    ModalOverlay.instance = this
  }

  get isMounted () {
    return this._isMounted
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    let changed = false
    const changes = {}
    if (nextProps.content !== prevState.content) {
      changed = true
      changes.content = nextProps.content
      if (changes.content) {
        changes.visible = true
      }
    }
    if (nextProps.title !== prevState.title) {
      changed = true
      changes.title = nextProps.title ? nextProps.title : null
    }
    if (nextProps.visible !== prevState.visible) {
      changed = true
      changes.visible = nextProps.visible
    }

    if (changed) {
      return changes
    }
    return null
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    let changes = ModalOverlay.getDerivedStateFromProps(this.props, prevProps)
    let performChanges = true
    if (!changes) {
      changes = {}
      performChanges = false
    }

    if (this.isMounted === true && performChanges) {
      for (const key in changes) {
        this.setState({ [key]: changes.key })
      }
    }
  }

  render () {
    if (!this.state.visible) {
      return null
    }
    return (
      <div className='ModalOverlay'>
        <div className='ModalOverlayContentContainer'>
          {/* <div className={"ModalOverlayTopBar"} onClick={this.props.onClose}>  -- where would this get set? */}
          <div className='ModalOverlayTopBar' onClick={() => { this.setState({ visible: false }) }}>
            {this.state.title
              ? <div className='ModalOverlayTitle'>{this.state.title}</div>
              : null}
            <div className='ModalOverlayCloseButton'>
              <i className='fas fa-window-close' />
            </div>
          </div>
          <div className='ModalOverlayContent'>
            {this.state.content}
          </div>
        </div>
      </div>
    )
  }

  static hide () {
    Topics.publish(this.topic, { visible: false })
  }

  /**
     * Displays a loading message, optionally disappearing after specified seconds.
     *
     * @param {*|JSX} content
     * @param {int|null} [timeout=null]
     */
  static setContent (content, props = {}) {
    const data = { content, ...props }
    Topics.publish(this.topic, data)
  }
}

export default withSubscription(ModalOverlay, {})
