import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, InputBase, Popper, Paper } from '@mui/material'
import {
  DataGrid as MuiDataGridX,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  useGridApiContext,
  GridCellEditStopReasons,
  gridClasses
} from '@mui/x-data-grid'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import SettingsIcon from '@mui/icons-material/Settings'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

const DataGrid = ({
  autosizeOptions,
  customToolbarButtons,
  selectionModel,
  rows,
  columns: initialColumns,
  rowHeight = 52,
  useStandardRowHeight = true, // Used to display wrapped components in a cell, like category chips.  See CompanyConfigProducts for an example.
  defaultPageSize = 10,
  density = 'standard',
  sx,
  showId = false,
  showRequired = false,
  showSearch = true,
  showToolbar = true, // Show or suppress the entire toolbar
  showToolbarDensity = true, // Show/suppress specific toolbar
  showToolbarExport = true, // Show/suppress specific toolbar
  showCellVerticalBorder = false,
  columnVisibility = {}, // Hash of columns to add to columnVisibilityModel
  disableRowSelectionOnClick = true,
  sortModel,
  onSortModelChange,
  onAddText = 'Add',
  onAdd,
  onCloudUploadText = 'Cloud Upload',
  onCloudUpload,
  onDelete,
  onShowSettings,
  onCellClick,
  onProcessRowUpdate,
  onCellKeyDown,
  isCellEditable,
  onPaginationModelChange
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [columns, setColumns] = useState([])
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [rowSelectionModel, setRowSelectionModel] = useState([])

  useEffect(() => {
    setIsLoading(false)
  }, [])

  useEffect(() => {
    // Check if selectionModel prop is not undefined
    if (typeof selectionModel !== 'undefined') {
      setRowSelectionModel(selectionModel)
    }
    setIsLoading(false)
  }, [selectionModel]) // Add selectionModel as a dependency

  /**
   * If we delete/edit a row the columns needs updated so it can fix the index on the edit and delete button.
  */
  useEffect(() => {
    const updatedColumns = []

    for (const column of initialColumns) {
      if (column.multiline) {
        updatedColumns.push({
          ...column,
          ...multilineColumn
        })
      } else {
        updatedColumns.push(column)
      }
    }
    setColumns(updatedColumns)
  }, [rows]) // eslint-disable-line

  const handleRowChange = (rows) => {
    setRowSelectionModel(rows)
  }

  const handleDelete = () => {
    handleRowChange([])
    if (onDelete) {
      onDelete(rowSelectionModel)
    }
  }

  const CustomToolbar = (filterChips) => {
    return (
      <GridToolbarContainer>
        <Grid container sx={{ p: 1 }}>
          {showSearch
            ? (
              <Grid item sx={{ pr: 1 }}>
                <GridToolbarQuickFilter size='small' variant='outlined' />
              </Grid>
              )
            : null}
          {showToolbar
            ? (
              <Grid item sx={{ justifyContent: 'flex-end', display: 'flex', flex: 1 }}>
                {customToolbarButtons}

                {onDelete && rowSelectionModel.length
                  ? (
                    <Button variant='text' size='small' color='primary' onClick={handleDelete} startIcon={<DeleteIcon />}>
                      Delete ({rowSelectionModel.length} row{rowSelectionModel.length > 1 ? 's' : ''})
                    </Button>
                    )
                  : null}
                {onAdd
                  ? (
                    <Button variant='text' size='small' color='primary' onClick={onAdd} startIcon={<AddIcon />}>
                      {onAddText}
                    </Button>
                    )
                  : null}
                {onCloudUpload
                  ? (
                    <Button variant='text' size='small' color='primary' onClick={onCloudUpload} startIcon={<CloudUploadIcon />}>
                      {onCloudUploadText}
                    </Button>
                    )
                  : null}
                {onShowSettings
                  ? (
                    <Button variant='text' size='small' color='primary' onClick={onShowSettings} startIcon={<SettingsIcon />}>
                      Settings
                    </Button>
                    )
                  : null}
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                {showToolbarDensity ? <GridToolbarDensitySelector /> : null}
                {showToolbarExport ? <GridToolbarExport /> : null}
              </Grid>
              )
            : null}
        </Grid>
      </GridToolbarContainer>
    )
  }

  const initStateOverride = {
    columns: {
      columnVisibilityModel: {
        id: !!showId,
        required: !!showRequired,
        ...columnVisibility
      }
    },
    pagination: {
      paginationModel: {
        pageSize
      }
    }
  }

  const sxOverride = {
    ...sx,
    '& .MuiDataGrid-columnHeader .MuiCheckbox-root': {
      color: '#A6B0CF !important'
    },
    '& .MuiDataGrid-columnHeader': {
      color: '#A6B0CF !important'
    },
    [`& .${gridClasses.cell}`]: {
      py: 1,
      display: 'flex',
      alignItems: 'center'
    }
  }

  if (isLoading) return null

  return (
    <Box sx={{ m: 0 }}>
      <MuiDataGridX
        autoHeight
        autosizeOptions={autosizeOptions || undefined}
        rowHeight={useStandardRowHeight ? rowHeight : undefined}
        getRowHeight={showToolbarDensity ? undefined : () => 'auto'}
        density={density}
        rows={rows}
        columns={columns}
        sx={sxOverride}
        pagination
        rowSelectionModel={rowSelectionModel}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        checkboxSelection={!!onDelete}
        isRowSelectable={(params) => params.row.isRowSelectable !== false}
        showCellVerticalBorder={showCellVerticalBorder}
        slotProps={{
          header: {
            border: '1px solid red'
          }
        }}
        slots={{
          toolbar: showToolbar ? CustomToolbar : null // Conditionally render the toolbar slot
        }}
        initialState={initStateOverride}
        localeText={{
          toolbarColumns: 'Columns' // Example if we need to change the toolbar text
        }}
        processRowUpdate={(updatedRow, originalRow) => {
          if (onProcessRowUpdate) {
            onProcessRowUpdate(updatedRow)
          }
          return updatedRow
        }}
        sortModel={sortModel || undefined}
        onSortModelChange={onSortModelChange || undefined}
        onProcessRowUpdateError={(error, updatedRow, originalRow) => {
          console.log('onProcessRowUpdateError', error, updatedRow, originalRow)
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onCellClick={onCellClick || null}
        onRowSelectionModelChange={(rows) => {
          handleRowChange(rows)
        }}
        onCellKeyDown={onCellKeyDown || null}
        onCellEditStop={(params, event) => {
          if (params.reason !== GridCellEditStopReasons.enterKeyDown) {
            return
          }
          if (isKeyboardEvent(event) && !event.ctrlKey && !event.metaKey) {
            event.defaultMuiPrevented = true
          }
        }}
        isCellEditable={isCellEditable || null}
        onPaginationModelChange={(paginationModel, paginationDetails) => {
          if (onPaginationModelChange) {
            onPaginationModelChange(paginationModel, paginationDetails)
          }
        }}
      />
    </Box>
  )
}
export default DataGrid

/**
 * multilineColumn
 *
 * This is a custom column type that allows for multiline text fields in the grid.
 *
 * @type {Object}
 */
const multilineColumn = {
  type: 'string',
  renderEditCell: (params) => <EditTextarea {...params} />
}

/**
 * isKeyboardEvent()
 *
 * This function is used in multiline TextField's in the grid, so enter works and doesn't tab out.
 *
 * @param {*} event
 * @return {*}
 */
function isKeyboardEvent (event) {
  return !!event.key
}

/**
 * EditTextarea
 *
 * Component used for editing data that is in a multiline Textfield
 *
 * @param {*} props
 * @return {*}
 */
function EditTextarea (props) {
  const { id, field, value, colDef, hasFocus } = props
  const [valueState, setValueState] = React.useState(value)
  const [anchorEl, setAnchorEl] = React.useState()
  const [inputRef, setInputRef] = React.useState(null)
  const apiRef = useGridApiContext()

  React.useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus()
    }
  }, [hasFocus, inputRef])

  const handleRef = React.useCallback((el) => {
    setAnchorEl(el)
  }, [])

  const handleChange = React.useCallback(
    (event) => {
      const newValue = event.target.value
      setValueState(newValue)
      apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 }, event)
    },
    [apiRef, field, id]
  )

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.stopPropagation() // Prevent the Enter key from propagating further
    }
  }

  return (
    <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: 'block',
          position: 'absolute',
          top: 0
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement='bottom-start'>
          <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
            <InputBase
              multiline
              rows={4}
              value={valueState}
              sx={{ textarea: { resize: 'both' }, width: '100%' }}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              inputRef={(ref) => setInputRef(ref)}
            />
          </Paper>
        </Popper>
      )}
    </div>
  )
}
