import React, { useState, useEffect } from 'react'
import { Box, Grid, Switch } from '@mui/material'
import { DialogGeneric } from './DialogGeneric'
import { LoadingPleaseWait } from '@supportworks/react-components'

export const DialogSettings = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [displaySettings, setDisplaySettings] = useState({})

  useEffect(() => {
    if (isLoading) {
      setDisplaySettings(props.displaySettings)
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleSave = () => {
    props.onSave(displaySettings)
  }

  const handleChange = (e, field) => {
    const s = JSON.parse(JSON.stringify(displaySettings))
    if (field.type === 'boolean') {
      s[props.config.key][e.target.id] = !s[props.config.key][e.target.id]
    }
    setDisplaySettings(s)
  }

  if (isLoading) return <LoadingPleaseWait />

  const config = props.config
  let lastCategory = null
  const jsx = (
    <Box style={{ overflow: 'auto' }}>
      {config.list.map((field, idx) => {
        let showCategory = false
        if (!showCategory && field.category !== lastCategory) {
          showCategory = true
          lastCategory = field.category
        }
        if (field.type === 'boolean') {
          let checked = false
          if (displaySettings[props.config.key][field.id]) {
            checked = true
          }
          return (
            <Grid container key={`DS-${idx}`} sx={showCategory && idx > 0 ? { borderTop: '1px solid rgba(0,0,0,.08)' } : null}>
              {showCategory
                ? (
                  <Grid item xs={6} sx={idx > 1 ? { pt: 1 } : null}>
                    {field.category}
                  </Grid>
                  )
                : lastCategory !== null
                  ? (
                    <Grid item xs={6}>
                  &nbsp;
                    </Grid>
                    )
                  : null}
              <Grid item xs={4} sx={idx > 1 ? { pt: 1 } : null}>
                {field.title}
                {field.descr
                  ? (
                    <>
                      <br />
                      <span style={{ fontSize: 12 }}>{field.descr}</span>
                    </>
                    )
                  : null}
              </Grid>
              <Grid item xs={2} justifyContent='flex-end'>
                <Box display='flex' justifyContent='flex-end'>
                  <Switch id={field.id} checked={checked} color='primary' onClick={(e) => handleChange(e, field)} />
                </Box>
              </Grid>
            </Grid>
          )
        } else {
          console.log('Type is not supported. Add it!')
        }
        return null
      })}
    </Box>
  )
  return (
    <DialogGeneric
      title={config.title}
      content={jsx}
      {...props}
      onChange={() => {
        handleSave(displaySettings)
      }}
    />
  )
}
