import React, { useEffect, useRef, useState } from 'react'
import { DialogGeneric } from '../../../components/Reusable/DialogGeneric'
import {
  Box,
  Chip,
  FormControl, FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Switch, Button,
  Autocomplete
} from '@mui/material'
import {
  DialogFormSelector
} from '../../../components/Reusable/DialogFormSelector'
import {
  DialogCategorySelector
} from '../../../components/Reusable/DialogCategorySelector'

export const Discounts = (props) => {
  const [showDialog, setShowDialog] = useState(props.open)
  const [label, setLabel] = useState('')
  const [amount, setAmount] = useState('')
  const [type, setType] = useState('')
  const [pct, setPct] = useState('')
  const [crmId, setCrmId] = useState('')
  const [cats, setCats] = useState([])
  const [userEdit, setUserEdit] = useState(false)
  const [defaultDiscount, setDefaultDiscount] = useState(false)
  const titleRef = useRef(null)
  // const mapToProductRef = useRef(null)
  const [errors, setErrors] = useState({
    label: '',
    crmID: ''
  })
  const [showAssignCategory, setShowAssignCategory] = useState(false)
  const [showAssignForm, setShowAssignForm] = useState(false)
  const [list, setList] = useState([])
  const [formsConfig, setFormsConfig] = useState([])
  const [forms, setForms] = useState([])
  const [categoryConfig, setCategoryConfig] = useState([])
  const [catsList, setCatsList] = useState([])
  const [options, setOptions] = useState([])

  const handleClose = () => {
    setShowDialog(!showDialog)
    props.onClose()
  }

  const handleSave = () => {
    // console.log('here');
    const newErrors = JSON.parse(JSON.stringify(errors))
    let requiredTitle = false
    let requiredCrmID = false

    if (label === '') {
      newErrors.label = 'Required'
      requiredTitle = true
    }
    if (crmId === '' || crmId.toString() === '-1') {
      newErrors.crmID = 'Required'
      requiredCrmID = true
    }

    const newDiscountsConfig = props.discountsConfig

    newDiscountsConfig.label = label
    newDiscountsConfig.type = type
    newDiscountsConfig.amount = amount
    newDiscountsConfig.editable = Boolean(userEdit)
    newDiscountsConfig.default = Boolean(defaultDiscount)
    newDiscountsConfig.crmID = crmId // YES, this is crmID for the CRM.  DEV-4855
    newDiscountsConfig.pct = pct
    if (cats && cats.length) {
      newDiscountsConfig.categories = cats
    }
    if (type === 'overrideTotal') {
      newDiscountsConfig.amount = ''
      newDiscountsConfig.pct = ''
    }
    if (forms && forms.length) {
      newDiscountsConfig.forms = forms
    }

    setErrors(newErrors)
    if (!requiredTitle && !requiredCrmID) {
      props.onSave(newDiscountsConfig)
    }
  }

  const handleChange = (e) => {
    const newErrors = JSON.parse(JSON.stringify(errors))
    if (e.target.name === 'label') {
      newErrors.label = ''
      setLabel(e.target.value)
    }
    if (e.target.name === 'amount') {
      setAmount(e.target.value)
      setPct('')
    }
    if (e.target.name === 'pct') {
      setPct(e.target.value)
      setAmount('')
    }
    setErrors(newErrors)
  }

  const handleChangeSelect = (e) => {
    if (e.target.name === 'type') {
      setType(e.target.value)
    }
  }

  const handleChangeProduct = (e, newProd) => {
    const newErrors = JSON.parse(JSON.stringify(errors))
    newErrors.crmID = ''
    setCrmId(newProd)
    setErrors(newErrors)
  }

  const onChangeAllowUserEdit = (e) => {
    setUserEdit(!userEdit)
  }

  const onChangeDefaultDiscount = (e) => {
    setDefaultDiscount(!defaultDiscount)
  }

  useEffect(() => {
    // console.log(props)
    let options = []
    if (props.masterList) {
      options = props.masterList.map((p) => {
        return {
          id: p.crmId ? p.crmId : p.value,
          label: p.name,
          title: p.name
        }
      })
      options = options.sort((a, b) => a.label.localeCompare(b.label))
      setOptions(options)
    }

    setLabel(props.discountsConfig.label)
    setAmount(props.discountsConfig.amount)
    setType(props.discountsConfig.type)
    setPct(props.discountsConfig.pct)

    if ('crmID' in props.discountsConfig) {
      setCrmId(props.discountsConfig.crmID) // YES, this is crmID for the CRM.  DEV-4855
    }

    if ('categories' in props.discountsConfig) {
      setCats(props.discountsConfig.categories)
    }

    if ('editable' in props.discountsConfig) {
      setUserEdit(props.discountsConfig.editable)
    }

    if ('default' in props.discountsConfig) {
      setDefaultDiscount(props.discountsConfig.default)
    }

    if (props.formsConfig) {
      setFormsConfig(props.formsConfig)
    }

    if ('forms' in props.discountsConfig) {
      setForms(props.discountsConfig.forms)
    }

    if (props.categories) {
      setCategoryConfig(props.categories)
    }
    // eslint-disable-next-line
  }, [])

  const handleAssignCategory = () => {
    if (props.categories) {
      if (cats.length > 0) {
        const newCatsList = []
        cats.forEach((i, idx) => {
          const cat = props.categories.filter((c) => c.id === i)
          newCatsList.push(cat[0])
        })
        setCatsList(newCatsList)
      }
    }
    setShowAssignCategory(!showAssignCategory)
  }

  const handleAssignForm = () => {
    if (props.formsConfig) {
      if (forms.length > 0) {
        const newList = []
        forms.forEach((i, idx) => {
          const form = props.formsConfig.filter((f) => f.id === i)
          newList.push(form[0])
        })
        setList(newList)
      }
    }
    setShowAssignForm(!showAssignForm)
  }

  const handleAssignFormSave = (formsList) => {
    const newForms = []
    formsList.forEach((f, idx) => {
      if ('formId' in f) {
        newForms.push(f.formId)
      }
    })
    setForms(newForms)
    setShowAssignForm(!showAssignForm)
  }

  const getFormName = (fid) => {
    const form = props.formsConfig.filter((o) => o.id == fid) // eslint-disable-line
    if (form) {
      return form[0].formTitle
    }
  }

  const handleDeleteForm = (idx) => {
    const newForms = JSON.parse(JSON.stringify(forms))
    newForms.splice(idx, 1)
    setForms(newForms)
  }

  const handleAssignCategorySave = (categoryList) => {
    const newCats = [...categoryList]
    setCats(newCats)
    setShowAssignCategory(!showAssignCategory)
  }

  const handleDeleteCategory = (idx) => {
    const newCats = JSON.parse(JSON.stringify(cats))
    newCats.splice(idx, 1)
    setCats(newCats)
  }

  const getSelectedProduct = (pid) => {
    let title = ''
    if (pid !== -1 && pid !== '') {
      const p = options.find((o) => o.id === pid)
      title = p.title
    }
    return title
  }

  const jsx = (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id='label'
            name='label'
            variant='outlined'
            label='Title'
            onChange={handleChange}
            value={label}
            size='small'
            sx={{
              marginBottom: '25px',
              marginTop: '25px'
            }}
            ref={titleRef}
            error={!!errors.label}
            helperText={errors.label}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl sx={{ marginTop: '25px', marginBottom: '5px' }} size='small' fullWidth>
            <InputLabel id='demo-simple-select-label'>Discount Type</InputLabel>
            <Select labelId='type' name='type' id='type' value={type} size='small' label='Discount Type' onChange={handleChangeSelect}>
              <MenuItem key='amount' value='amount'>
                Amount
              </MenuItem>
              <MenuItem key='pct' value='pct'>
                Percentage
              </MenuItem>
              <MenuItem key='overrideTotal' value='overrideTotal'>
                Override Total
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {type === 'amount' && (
          <Grid item xs={3}>
            <TextField
              fullWidth
              id='amount'
              name='amount'
              variant='outlined'
              label='Amount'
              onChange={handleChange}
              value={amount}
              size='small'
              sx={{
                marginBottom: '25px',
                marginTop: '25px'
              }}
            />
          </Grid>
        )}

        {type === 'pct' && (
          <Grid item xs={3}>
            <TextField
              fullWidth
              id='pct'
              name='pct'
              variant='outlined'
              label='Percentage'
              onChange={handleChange}
              value={pct}
              size='small'
              sx={{
                marginBottom: '25px',
                marginTop: '25px'
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <FormControl sx={{ mb: 2 }}>
            <FormControlLabel
              sx={{ ml: 0 }}
              label={
                <Typography variant='body2' sx={{ flex: 1, fontSize: '1rem' }}>
                  Allow User to Edit
                </Typography>
              }
              labelPlacement='start'
              value='start'
              control={
                <Switch
                  color='primary'
                  sx={{ m: 0 }}
                  checked={userEdit}
                  onChange={onChangeAllowUserEdit}
                />
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ mb: 2 }}>
            <FormControlLabel
              label={
                <Typography variant='body2' sx={{ flex: 1, fontSize: '1rem' }}>
                  Default Discount in Project
                </Typography>
              }
              labelPlacement='start'
              value='start'
              control={
                <Switch
                  color='primary'
                  sx={{ m: 0 }}
                  checked={defaultDiscount}
                  onChange={onChangeDefaultDiscount}
                />
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Autocomplete
            id='autocomplete-maptocrm-discounts'
            name='mapToProduct'
            isOptionEqualToValue={(option, value) => {
              if (value === '') return true
              return option.title === value
            }}
            options={options}
            size='small'
            value={getSelectedProduct(crmId)}
            onChange={(e, newValue) => {
              handleChangeProduct(e, newValue && newValue.id ? newValue.id : -1)
            }}
            renderInput={(params) =>
              <TextField
                {...params}
                label='Map to Product'
                error={!!errors.crmID}
                helperText={<span style={{ color: '#dc3545' }}>{errors.crmID}</span>}
              />}
            renderOption={(props, option) => {
              return (
                <Box component='li' {...props} key={option.id}>
                  <Typography variant='body'>{option.title}</Typography>
                </Box>
              )
            }}
          />
          {/* <FormControl sx={{ marginBottom: '5px' }} size='small' fullWidth>
            <InputLabel id='demo-simple-select-label'>Map To Product</InputLabel>
            <Select
              labelId='mapToProduct'
              name='mapToProduct'
              id='mapToProduct'
              value={crmId}
              size='small'
              label='Map to Product'
              onChange={handleChangeSelect}
              ref={mapToProductRef}
              error={!!errors.crmID}
            >
              {props.masterList.map((product, idx) => {
                return (
                  <MenuItem key={`${idx}-${product.crmId ? product.crmId : product.value}`} value={product.crmId ? product.crmId : product.value}>
                    {product.name}, {product.crmId ? product.crmId : product.value}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText style={{ color: '#dc3545' }}>{errors.crmID}</FormHelperText>
          </FormControl> */}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom style={{ fontSize: '1rem' }} sx={{ marginTop: '16px', marginBottom: '0px' }}>
            Assign To Product Categories
          </Typography>
          <Typography variant='body2' gutterBottom style={{ marginBottom: '16px', color: 'rgb(0 0 0 / 60%)' }}>
            By not assigning to a product category, the discount will apply to all products. If assigned to a product category, only products with associated
            categories will be discounted.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={2.4}>
          <Button
            size='medium'
            variant='contained'
            onClick={() => handleAssignCategory()}
          >
            Assign to Category
          </Button>
        </Grid>
        <Grid item xs={9}>
          {
            cats.length > 0 &&
            cats.map((cat, idx) => {
              return (
                <Chip key={idx} label={cat} onDelete={() => handleDeleteCategory(idx)} sx={{ mr: 1, mb: 1 }} />
              )
            })
          }
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom style={{ fontSize: '1rem' }} sx={{ marginTop: '16px', marginBottom: '0px' }}>
            Assign To Forms
          </Typography>
          <Typography variant='body2' gutterBottom style={{ marginBottom: '16px', color: 'rgb(0 0 0 / 60%)' }}>
            By assigning to a discount to a form, it will only apply on the products included in that form.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={2}>
          <Button
            size='medium'
            variant='contained'
            onClick={() => handleAssignForm()}
          >
            Assign to Form
          </Button>
        </Grid>
        <Grid item xs={10}>
          {
            forms.length > 0 &&
            forms.map((f, idx) => {
              return (
                <Chip key={idx} label={getFormName(f)} onDelete={() => handleDeleteForm(idx)} sx={{ mr: 1, mb: 1 }} />
              )
            })
          }
        </Grid>
      </Grid>

      {
        showAssignCategory &&
          <DialogCategorySelector
            title='Assign to Category'
            list={catsList}
            categoryConfig={categoryConfig}
            onSave={(l) => handleAssignCategorySave(l)}
            onClose={() => setShowAssignCategory(!showAssignCategory)}
          />
      }

      {
        showAssignForm &&
          <DialogFormSelector
            title='Assign to Form'
            list={list}
            formsConfig={formsConfig}
            onSave={(l) => handleAssignFormSave(l)}
            onClose={() => setShowAssignForm(!showAssignForm)}
          />
      }
    </Box>
  )

  return (
    <>
      showDialog &&
      <DialogGeneric title='Discounts' onClose={() => handleClose()} content={jsx} fullWidth onChange={handleSave} />
    </>
  )
}
