/* eslint-disable array-callback-return */
import React from 'react'
import { Box, Button, Chip, Grid, IconButton, Paper, Stack, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import WarningIcon from '@mui/icons-material/Warning'
import CheckIcon from '@mui/icons-material/Check'
import UpIcon from '@mui/icons-material/KeyboardArrowUp'
import DownIcon from '@mui/icons-material/KeyboardArrowDown'
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import Api from 'library/Api'
import CompanyConfigEdit, { CompanyConfigBaseEditor } from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import Company from '../Company'
import DataGrid from '../../components/Reusable/DataGrid'
import { DialogMeetingSelector } from '../../components/Reusable/DialogMeetingSelector'
import { DialogGeneric } from '../../components/Reusable/DialogGeneric'
import { HoverDialog } from '../../components/Reusable/HoverDialog'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { MeetingEditor } from './Meetings/MeetingEditor'
import '../../css/select-search.css'
import '../../css/selector-material-ui.css'

const config = {
  title: 'Meetings',
  variant: 'AppointmentType',
  tag: 'appointment_type',
  name: 'Meetings',
  lockTag: true,
  lockName: false,
  canClone: true
}

class CompanyConfigMeetings extends CompanyConfigBaseEditor {
  constructor (props) {
    super(props)
    let appointmentTypeInfo

    if (props.contentJson !== '' && typeof props.contentJson === 'string') {
      appointmentTypeInfo = JSON.parse(props.contentJson)
    } else {
      appointmentTypeInfo = {
        title: '',
        types: []
      }
    }

    this.state = {
      isLoading: true,
      appointmentTypeList: appointmentTypeInfo,
      issuesList: null,
      solutionsList: null,
      assetsList: null,
      presentationsList: null,
      formsList: null,
      filter: '',

      isAdding: false,
      meeting: null,
      formsConfig: [], // identical representation of config.forms, to replace formsList
      showMeetingEditor: false,
      anchorEl: null,
      iRef: [],
      hoverBodyIndex: 0,
      showImportLibraryDialog: false,
      showLibraryUpdateDialog: false,
      libraryUpdateIndex: -1
    }
    // console.log(typeof this.props.contentJson);
    this.handleDelete = this.handleDelete.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.getItemTitle = this.getItemTitle.bind(this)
  }

  componentDidMount () {
    super.componentDidMount()
    this.state.appointmentTypeList.types.map((m) => {
      this.addExistingGuid(m.id)
    })

    Api.callRegisteredMethod('getAssetList', {}).then((assetList) => {
      const assets = assetList

      Api.callRegisteredMethod('getCurrentConfig', { tag: 'all', startsWith: false }).then((config) => {
        const formsC = []
        const formsConfig = []
        if (config && 'forms' in config) {
          config.forms.map((listItem) => {
            formsC.push({ value: listItem.id, name: listItem.formTitle, type: listItem.formType })
            formsConfig.push(listItem)
          })
        }

        const presentationsC = []
        if (config && 'presentations' in config) {
          config.presentations.map((listItem) => {
            presentationsC.push({ value: listItem.id, name: listItem.title })
          })
        }

        const solutionsC = []
        if (config && 'solutions' in config) {
          config.solutions.map((listItem) => {
            solutionsC.push({ value: listItem.id, name: listItem.title })
          })
        }

        const issuesC = []
        if (config && 'issues' in config) {
          config.issues.map((listItem) => {
            issuesC.push({ value: listItem.id, name: listItem.title })
          })
        }

        const appointmentTypeList = this.state.appointmentTypeList
        appointmentTypeList.types = appointmentTypeList.types.map((appointmentType) => {
          if ('present' in appointmentType && Array.isArray(appointmentType.present)) {
            appointmentType.present = appointmentType.present.filter((pres) => {
              if (presentationsC.filter((pc) => pc.value === pres).length > 0) {
                return true
              } else {
                return false
              }
            })
          }
          return appointmentType
        })

        this.setState({
          isLoading: false,
          config,
          appointmentTypeList,
          presentationsList: presentationsC.sort((a, b) => (a.name > b.name ? 1 : -1)),
          assetsList: assets.sort((a, b) => (a.name > b.name ? 1 : -1)),
          solutionsList: solutionsC.sort((a, b) => (a.name > b.name ? 1 : -1)),
          issuesList: issuesC.sort((a, b) => (a.name > b.name ? 1 : -1)),
          formsList: formsC.sort((a, b) => (a.name > b.name ? 1 : -1)),
          formsConfig
        })
      })
    })
    this.updateJsonConfig()
  }

  handleAdd () {
    const meeting = {
      id: this.guid(),
      title: '',
      intro: [],
      inspect: [],
      findings: [],
      always_present: { top: [], bottom: [] },
      present: [],
      conclusion: {},
      style: {
        id: 3,
        title: 'Version 3'
      }
    }
    this.setState({
      isAdding: true,
      meeting,
      showMeetingEditor: !this.state.showMeetingEditor
    })
  }

  handleSave = (meeting) => {
    const list = JSON.parse(JSON.stringify(this.state.appointmentTypeList))
    if (this.state.isAdding) {
      list.types.push(meeting)
    } else {
      if (meeting.status === 'added') {
        meeting.status = 'edited'
        meeting.changedByUser = true
      } else if (meeting.status === 'added1') {
        meeting.changedByUser = true
      }

      const idx = list.types.map((o) => o.id).indexOf(meeting.id)
      if (idx > -1) {
        list.types[idx] = meeting
      }
    }
    this.setState({
      appointmentTypeList: list,
      meeting: null,
      showMeetingEditor: false,
      isAdding: false
    })
    this.props.onChange(JSON.stringify(list)) // Trigger unsaved changes.
  }

  handleCancel (idx, aType) {
    const appointmentType = JSON.parse(JSON.stringify(this.state.appointmentTypeList))
    if (aType.newAppType === true) {
      appointmentType.types.splice(idx, 1)
    }
    this.setState({
      appointmentTypeList: appointmentType,
      isAdding: false,
      meeting: null,
      showMeetingEditor: false
    })
  }

  handleDelete (idx) {
    if (window.confirm('Are you sure you want to delete this meeting?')) {
      const appointmentType = JSON.parse(JSON.stringify(this.state.appointmentTypeList))
      appointmentType.types.splice(idx, 1)
      this.setState({ appointmentTypeList: appointmentType })
    }
  }

  handleMeetingSelect = (m) => {
    this.setState({ meeting: m, showMeetingEditor: !this.state.showMeetingEditor })
  }

  handleClose = () => {
    this.setState({ meeting: null, showMeetingEditor: !this.state.showMeetingEditor })
  }

  getItemTitle (id, config) {
    let a
    config.map((c) => {
      if (c.value === id) {
        a = c
      }
    })
    if (a && 'name' in a) {
      return a.name
    }
  }

  handleMove = (idx, direction) => {
    const p = JSON.parse(JSON.stringify(this.state.appointmentTypeList)) // p = copy of state
    if (direction === 'up') {
      if (p.types[idx - 1]) {
        const toSwitch = JSON.parse(JSON.stringify(p.types[idx]))
        const switchWith = JSON.parse(JSON.stringify(p.types[idx - 1]))
        p.types[idx - 1] = toSwitch
        p.types[idx] = switchWith
        this.setState({ appointmentTypeList: p })
      }
    } else if (direction === 'down') {
      if (p.types[idx + 1]) {
        const switchWith = JSON.parse(JSON.stringify(p.types[idx + 1]))
        p.types[idx + 1] = JSON.parse(JSON.stringify(p.types[idx]))
        p.types[idx] = switchWith
        this.setState({ appointmentTypeList: p })
      }
    }
  }

  handlePopoverOpen = (idx) => {
    this.setState({ anchorEl: this.state.iRef[idx] })
    this.setState({ hoverBodyIndex: idx })
  }

  handlePopoverClose = () => {
    // console.log('2');
    this.setState({ anchorEl: null })
  }

  handleLibraryAdd = () => {
    this.setState({ showImportLibraryDialog: !this.state.showImportLibraryDialog })
  }

  handleLibrarySave = (l) => {
    const list = JSON.parse(JSON.stringify(this.state.appointmentTypeList))
    for (let x = 0; x < list.types.length; x++) {
      if (l.includes(list.types[x].id)) {
        if (list.types[x].status && list.types[x].status !== 'custom') {
          list.types[x].status = 'added1'
        }
      } else {
        if (list.types[x].status && list.types[x].status.match(/^(added|edited)/) && !(list.types[x].hidden === true)) {
          list.types[x].status = 'deleted'
        }
      }
    }
    // console.log(fl);
    this.setState({ appointmentTypeList: list })
  }

  triggerLibraryUpdateDialog = (id) => {
    const idx = this.state.appointmentTypeList.types.findIndex((o) => o.id === id)
    console.log(idx)
    if (idx > -1) {
      this.setState({ libraryUpdateIndex: idx })
    } else {
      // console.log(`Could not find ${id} in formsList to update!`);
    }
    this.setState({ showLibraryUpdateDialog: !this.state.showLibraryUpdateDialog })
  }

  handleLibraryUpdate = () => {
    const idx = this.state.libraryUpdateIndex

    if (idx > -1) {
      const list = JSON.parse(JSON.stringify(this.state.appointmentTypeList))
      console.log(list)
      if (list.types[idx].library && list.types[idx].library.currentVersion) {
        const libcopy = JSON.parse(JSON.stringify(list.types[idx].library))
        libcopy.changed = false
        list.types[idx] = list.types[idx].library.currentVersion
        list.types[idx].library = libcopy
        list.types[idx].status = 'added1'
      }
      this.setState({ appointmentTypeList: list, showLibraryUpdateDialog: false, libraryUpdateIndex: -1 })
    }
  }

  /*
   * add default[] to config
   */
  updateJsonConfig () {
    const appointmentTypeList = this.state.appointmentTypeList
    appointmentTypeList.types = appointmentTypeList.types.map((appointmentType) => {
      if ('intro' in appointmentType) {
        appointmentType.intro.map((i) => {
          this.updateJsonConfigHelper(i)
        })
      }
      if ('inspect' in appointmentType) {
        appointmentType.inspect.map((i) => {
          this.updateJsonConfigHelper(i)
        })
      }
      return appointmentType
    })
    this.setState({ appointmentTypeList })
  }

  updateJsonConfigHelper (i) {
    if ('issues' in i) {
      if (!('title' in i.issues)) {
        i.issues.title = ''
      }
      if (!('default' in i.issues)) {
        i.issues.default = []
      }
    } else {
      i.issues = {
        title: '',
        default: []
      }
    }
    if ('solutions' in i) {
      if (!('title' in i.solutions)) {
        i.solutions.title = ''
      }
      if (!('default' in i.solutions)) {
        i.issues.default = []
      }
    } else {
      i.solutions = {
        title: '',
        default: []
      }
    }
  }

  finalize () {
    return JSON.stringify(this.state.appointmentTypeList)
  }

  hasFinalize () {
    return true
  }

  render () {
    // console.log(this.state);
    if (this.state.isLoading) return <LoadingPleaseWait />

    const appointmentTypeList = this.state.appointmentTypeList

    const columns = [
      {
        flex: 1,
        type: 'string',
        field: 'details',
        headerName: 'Details',
        editable: false,
        sortable: false,
        renderCell: (params) => {
          if (params.row) {
            // console.log(params.row);
            const item = params.row
            const detailsJsx = (
              <Paper elevation={3} style={{ background: '#fff', margin: '-8px' }}>
                <Box sx={{ p: 2 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant='subtitle2' style={{ marginBottom: '4px' }}>
                        Overview Section
                      </Typography>
                    </Grid>
                    {
                      item.overview.length > 0 &&
                      item.overview.map((title, idx) => {
                        return (
                          <Grid item xs={12} sx={{ mb: 1 }} key={`overview-${idx}`}>
                            <Typography variant='body2' sx={{ pl: 1, color: '#666666' }}>
                              {title}
                            </Typography>
                          </Grid>
                        )
                      })
                    }
                  </Grid>

                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant='subtitle2' style={{ marginBottom: '4px', marginTop: '4px' }}>
                        Inspect Section
                      </Typography>
                    </Grid>
                    {
                      item.inspect.length > 0 &&
                      item.inspect.map((title, idx) => {
                        return (
                          <Grid item xs={12} sx={{ mb: 1 }} key={`inspect-${idx}`}>
                            <Typography variant='body2' sx={{ pl: 1, color: '#666666' }}>
                              {title}
                            </Typography>
                          </Grid>
                        )
                      })
                    }
                  </Grid>

                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant='subtitle2' style={{ marginBottom: '4px', marginTop: '4px' }}>
                        Conclusion Section
                      </Typography>
                    </Grid>
                    {
                      item.conclusion.length > 0 &&
                      item.conclusion.map((title, idx) => {
                        return (
                          <Grid item xs={12} sx={{ mb: 1 }} key={`findings-${idx}`}>
                            <Typography variant='body2' sx={{ pl: 1, color: '#666666' }}>
                              {title}
                            </Typography>
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                </Box>
              </Paper>
            )
            return (
              <HoverDialog jsx={detailsJsx} icon={<TextSnippetOutlinedIcon />} />
            )
          }
        }
      },
      {
        flex: 1,
        type: 'string',
        field: 'title',
        headerName: 'Title',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'statusCell',
        headerName: 'Status',
        editable: false,
        sortable: false,
        renderCell: (params) => {
          if (params.row) {
            const appointment = params.row.appointment
            // console.log(appointment);
            if ('library' in appointment && appointment.library.changed) {
              return (
                <Box component='span'>
                  <IconButton
                    key='update'
                    title='Update Library'
                    aria-label='Update Library'
                    onClick={() => {
                      this.triggerLibraryUpdateDialog(params.row.appointment.id)
                    }}
                    size='small'
                  >
                    <WarningIcon size='small' style={{ color: 'red' }} />
                  </IconButton>
                  {
                    appointment.changedByUser &&
                      <Chip key='chip-modified-update' component='span' label='Modified' size='small' sx={{ fontSize: '12px' }} />
                  }
                </Box>
              )
            } else {
              if ('status' in appointment && appointment.status.match(/^(added|edited)/)) {
                return (
                  <Box component='span'>
                    <CheckIcon key='add-edit' size='small' style={{ color: 'green' }} sx={{ pr: 1 }} />
                    {
                       appointment.changedByUser &&
                         <Chip key='chip-modified-add-edit' component='span' label='Modified' size='small' sx={{ fontSize: '12px' }} />
                     }
                  </Box>
                )
              }
            }

            if (appointment.retired === true) {
              return (
                <div>Retired</div>
              )
            }
          }
        }
      },
      {
        flex: 1,
        type: 'string',
        field: 'library',
        headerName: 'Library',
        editable: false,
        sortable: false
      },
      {
        width: 168,
        field: 'action',
        headerName: 'Actions',
        editable: false,
        sortable: false,
        renderCell: (params) => {
          if (params.row) {
            return (
              <Grid container wrap='nowrap' justifyContent='flex-end'>
                <Grid item>
                  <IconButton
                    title='Edit'
                    aria-label='edit'
                    onClick={() => {
                      this.handleMeetingSelect(params.row.appointment)
                    }}
                  >
                    <EditIcon variant='icon' fontSize='small' />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    title='Up'
                    aria-label='up'
                    onClick={() => {
                      this.handleMove(params.row.id, 'up')
                    }}
                    disabled={params.row.id < 1}
                  >
                    <UpIcon variant='icon' fontSize='small' />
                  </IconButton>
                  <IconButton
                    title='Down'
                    aria-label='down'
                    onClick={() => {
                      this.handleMove(params.row.id, 'down')
                    }}
                    disabled={params.row.id === params.row.appointment.length - 1}
                  >
                    <DownIcon variant='icon' fontSize='small' />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    title='Delete'
                    aria-label='delete'
                    onClick={() => {
                      this.handleDelete(params.row.id)
                    }}
                  >
                    <DeleteIcon variant='icon' fontSize='small' />
                  </IconButton>
                </Grid>
              </Grid>
            )
          }
        }
      }
    ]

    const rows = []
    if (this.state.assetsList !== null && this.state.presentationsList !== null && this.state.solutionsList !== null && this.state.issuesList !== null) {
      for (let x = 0; x < appointmentTypeList.types.length; x++) {
        const appointment = appointmentTypeList.types[x]
        let statusCell
        let libraryCell
        let changedByUser
        const currentCompany = Company.getCurrent()

        const overview = []
        if ('intro' in appointment) {
          for (let y = 0; y < appointment.intro.length; y++) {
            overview.push(appointment.intro[y].title)
          }
        }

        const inspect = []
        if ('inspect' in appointment) {
          for (let z = 0; z < appointment.inspect.length; z++) {
            inspect.push(appointment.inspect[z].title)
          }
        }

        const conclusion = []
        if ('findings' in appointment) {
          for (let i = 0; i < appointment.findings.length; i++) {
            conclusion.push(appointment.findings[i].title)
          }
        }

        if (!appointment.status || appointment.status === 'custom') {
          libraryCell = currentCompany.name
        } else {
          if (appointment.library) {
            if (appointment.status.match(/notadded|deleted/)) {
              continue
            }
            if (appointment.library.changed === true) {
              statusCell = <WarningIcon size='small' style={{ color: 'red' }} />
            } else {
              if (appointment.status.match(/^(addes|edited)/)) {
                statusCell = <CheckIcon size='small' style={{ color: 'green' }} sx={{ pr: 1 }} />
              }
            }
            if (appointment.changedByUser === true) {
              changedByUser = <Chip key='chip-modified' component='span' label='Modified' size='small' sx={{ fontSize: '12px' }} />
            }
            if (appointment.retired === true) {
              statusCell = ('Retired')
            }
            libraryCell = appointment.library.libraryName
          }
        }

        const row = {
          id: x,
          appointment,
          title: appointment.title,
          status: statusCell,
          library: libraryCell,
          changedByUser,
          overview,
          inspect,
          conclusion
        }
        rows.push(row)
      }
    }

    return (
      <>
        <div className='actionsTable__wrapper'>
          <Box sx={{ display: 'flex' }}>
            <Box>
              <Typography variant='h6'>Meetings</Typography>
            </Box>
          </Box>

          <DataGrid
            columns={columns}
            rows={rows}
            showToolbarDensity={false}
            defaultPageSize={50}
            onAdd={this.handleAdd}
            autosizeOptions={{
              columns: ['action'],
              includeOutliers: true,
              includeHeaders: false
            }}
          />

          {this.state.showImportLibraryDialog && (
            <DialogMeetingSelector
              title='Select Meetings'
              variant='library'
              list={this.state.appointmentTypeList.types.filter((o) => o.status && o.status.match(/^(notadded|added|edited)/))}
              appointments={this.state.appointmentTypeList.types.filter((o) => o.status && o.status !== 'custom' && !(o.hidden === true))}
              onSave={(l) => {
                this.handleLibrarySave(l)
                this.setState({ showImportLibraryDialog: !this.state.showImportLibraryDialog })
              }}
              onClose={() => this.setState({ showImportLibraryDialog: !this.state.showImportLibraryDialog })}
            />
          )}

          {this.state.showLibraryUpdateDialog && (
            <DialogGeneric
              title='Update Form'
              content={
                <Box>
                  <Stack>
                    <Typography variant='body1' sx={{ pb: 2 }}>
                      By updating the form, you will replace the form in <br /> it's current state.
                    </Typography>
                    <Typography variant='body1' sx={{ pb: 2 }}>
                      If you have modified the form, it is encouraged that <br />
                      you document any custom changes so that you can <br /> update the form.
                    </Typography>
                  </Stack>
                </Box>
              }
              onChange={this.handleLibraryUpdate}
              onClose={() => this.setState({ showLibraryUpdateDialog: !this.state.showLibraryUpdateDialog })}
              titleDone='Update'
              titleClose='Cancel'
            />
          )}

          {this.state.showMeetingEditor
            ? (
              <MeetingEditor
                config={this.state.config}
                meeting={this.state.meeting}
                formsConfig={this.state.formsConfig}
                onClose={this.handleClose}
                onSave={this.handleSave}
                isAdding={this.state.isAdding}
              />
              )
            : null}

          <Box sx={{ marginLeft: 'auto', marginTop: '8px' }}>
            <Button
              id='add-action'
              color='success'
              variant='contained'
              onClick={() => {
                this.handleAdd()
              }}
              startIcon={<AddIcon />}
            >
              Add Meeting Type
            </Button>
          </Box>
        </div>
      </>
    )
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} editorComponent={CompanyConfigMeetings} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}
