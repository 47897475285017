import React from 'react'
import Modal from 'react-modal'
import { IconButton, Button, Box, Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

/**
 * Displays a component in a modal. If the body parameter provides a "finalize" method, it will be called
 * when the "Save" button is clicked, and the result will be passed as an argument to the method provided
 * to the "update" property.
 */
class CompanyConfigModal extends React.Component {
  /**
   *
   * @param {Object} props
   * @param {boolean} [props.open] Whether or not the modal is open
   * @param {React.Component} [props.toggle] Component that, when clicked, will toggle the display of the modal.
   * @param {string|React.Component} [props.header] Title
   * @param {function} [props.cancel] Function to call when cancel target is activated
   * @param {function} [props.update] Function called when the Save button is activated.
   * @param {function} [props.delete] Function to call when item should be deleted
   * @param {*} [props.body] Modal body
   *
   */
  constructor (props) {
    super(props)
    this.state = {
      open: this.props.open ? this.props.open : false
    }
  }

  render () {
    const zIndex = 9999 + ('level' in this.props ? this.props.level : 0)
    return (
      <div>
        {this.props.toggle
          ? (
            <div
              onClick={() => {
                this.setState({ open: true })
              }}
            >
              {this.props.toggle}
            </div>
            )
          : null}
        {this.state.open
          ? (
            <Modal
              isOpen={this.state.open}
              ariaHideApp={false}
              style={{
                overlay: {
                  zIndex: `${zIndex}`,
                  inset: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                },
                content: {
                  inset: 0,
                  position: 'relative'
                }
              }}
            >
              <Box sx={{ mt: 0, pt: 0, ...styles.header }}>
                <Grid container spacing={0} alignItems='center'>
                  <Grid item sx={{ flex: 1, ml: 2, mt: 1 }}>
                    {this.props.header}
                  </Grid>
                  <Grid item sx={{ mr: 1, mt: 2 }}>
                    <IconButton
                      title='Close'
                      aria-label='close'
                      className='modal__close'
                      onClick={() => {
                        this.setState({ open: false }, () => {
                          if (this.props.cancel) {
                            this.props.cancel()
                          }
                        })
                      }}
                    >
                      <CloseIcon variant='icon' />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ mt: 8 }} className='modal__body'>
                {this.props.body}
              </Box>
              <Box sx={{ mt: -6, p: 2, ...styles.footer }}>
                <Grid container spacing={1} justify='space-between'>
                  <Grid item sx={{ flex: 1 }}>
                    {this.props.delete
                      ? (
                        <Button
                          className='mui-btn'
                          variant='contained'
                          color='error'
                          onClick={() => {
                            this.props.delete()
                            this.setState({ open: false })
                          }}
                        >
                          Delete
                        </Button>
                        )
                      : null}
                  </Grid>
                  <Grid item>
                    <Button
                      className='mui-btn'
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        if ('validation' in this.props) {
                          if (this.props.update) {
                            this.props.update()
                            if (this.props.validation) {
                              this.setState({ open: false })
                            }
                          }
                        } else {
                          this.setState({ open: false })
                          if (this.props.update) {
                            this.props.update()
                          }
                        }
                      }}
                    >
                      Done
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            )
          : null}
      </div>
    )
  }
}
export default CompanyConfigModal

const styles = {
  header: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '66px',
    width: '100%',
    borderBottom: '1px solid rgba(0,0,0,.12)',
    backgroundColor: '#FFF',
    zIndex: 1
  },
  footer: {
    position: 'absolute',
    left: 0,
    width: '100%',
    borderTop: '1px solid rgba(0,0,0,.12)',
    backgroundColor: '#FFF'
  }
}
