/**
 * Creates a local storage area for small documents. These do not persist between reloads.
 */
class ExpressionHelper {
  static mathFunctions = Object.getOwnPropertyNames(Math)
    .filter((prop) => typeof Math[prop] === 'function')
    .map((a) => {
      return 'Math.' + a
    })

  static rippleFunctions = {
    isEmpty: 1,
    'array.max': 1,
    'array.min': 1,
    'array.sum': 1,
    'call.checkboxLookup': 3,
    'call.dereference': 1,
    'call.index': 2,
    'call.lookup': 3,
    'call.lookup2': 4,
    'call.lookup4': 6,
    'call.lookup3': 5,
    'call.replaceDoubleQuote': 2,
    'call.substitute': 2,
    'call.translate': 3,
    ceil: 1,
    floor: 1,
    max: 2,
    min: 2,
    round: 2
  }

  static operators = ['+', '-', '*', '/', '%', '&', '|', '=', '<>', '!=', '>', '<', '>=', '<=']

  static isOperator (char) {
    return ExpressionHelper.operators.includes(char)
  }

  static isFunction (token, formModules) {
    return token in ExpressionHelper.rippleFunctions || ExpressionHelper.mathFunctions.includes(token) || token === 'call.module'
  }

  static getFunctionArity (funcName, formModules) {
    if (funcName in ExpressionHelper.rippleFunctions) {
      return ExpressionHelper.rippleFunctions[funcName]
    }
    if (ExpressionHelper.mathFunctions.includes(funcName)) {
      return 1
    }
    for (let i = 0; i < formModules.length; i++) {
      if (formModules[i].id === funcName) {
        return formModules[i].parms.length
      }
    }
    return 1
  }

  static precedence (operator) {
    if (operator === '+' || operator === '-') {
      return 1
    } else if (operator === '*' || operator === '/') {
      return 2
    } else {
      return 3 // Functions have higher precedence than other operators
    }
  }

  static tokenizer (input) {
    const regex = /\s*([A-Za-z."]+|[0-9.]+|\S)\s*/g
    const result = []
    let match

    while ((match = regex.exec(input))) {
      result.push(match[1])
    }

    return result
  }

  static postfixToInfix (expression, formModules) {
    const stack = []
    const tokens = expression ? expression.match(/\S+/g).filter((p) => p !== ' ' && p !== ',') : []
    // const tokens = expression.match(/[\w\"\/.]+|[^\w\"\/.]/g).filter((p) => p != ' ' && p != ',');
    // const tokens = this.tokenizer(expression);

    for (const token of tokens) {
      if (!isNaN(token)) {
        // If the token is a number, push it onto the stack.
        stack.push(token)
      } else if (this.isFunction(token, formModules)) {
        // If the token is a function, pop one operand from the stack and combine it with the function.
        const funcName = token
        let arity = this.getFunctionArity(funcName, formModules)
        if (funcName === 'call.module') {
          const callName = stack.pop()
          arity = this.getFunctionArity(callName, formModules)
          const operands = []
          for (let i = 0; i < arity; i++) {
            operands.unshift(stack.pop())
          }
          stack.push(`${funcName}(${operands.join(', ')}, ${callName})`)
        } else {
          const operands = []
          for (let i = 0; i < arity; i++) {
            operands.unshift(stack.pop())
          }
          stack.push(`${funcName}(${operands.join(', ')})`)
        }
      } else if (this.isOperator(token)) {
        // If the token is an operator, pop the last two operands from the stack and combine them with the operator.
        const operand2 = stack.pop()
        const operand1 = stack.pop()
        const combinedExpression = `( ${operand1} ${token} ${operand2} )`
        stack.push(combinedExpression)
      } else {
        stack.push(token)
      }
    }

    // The final result should be the infix expression.
    return stack[0]
  }

  static parseFunctionCall (expression, index) {
    const funcStart = index
    let parenCount = 1

    while (parenCount > 0 && index < expression.length - 1) {
      index++
      if (expression[index] === '(') parenCount++
      else if (expression[index] === ')') parenCount--
    }

    return expression.substring(funcStart, index + 1)
  }

  static infixToPostfix (expression, formModules) {
    const output = []
    const operatorStack = []

    // const tokens = expression.match(/[\w\"\/.]+|[^\w\"\/.]/g).filter((p) => p != ' ' && p != ',');
    const tokens = expression ? expression.match(/\S+/g).filter((p) => p !== ' ' && p !== ',') : []

    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i]
      if (!isNaN(token)) {
        // If the token is a number, add it to the output queue.
        output.push(token)
      } else if (this.isFunction(token, formModules)) {
        // If the token is a function, parse the function call and push it onto the stack.
        operatorStack.push(token)
      } else if (this.isOperator(token)) {
        // If the token is an operator, pop operators from the stack to the output
        // until the stack is empty, or the top operator has lower precedence than the token.
        while (
          operatorStack.length > 0 &&
          this.isOperator(operatorStack[operatorStack.length - 1]) &&
          this.precedence(operatorStack[operatorStack.length - 1]) >= this.precedence(token)
        ) {
          output.push(operatorStack.pop())
        }

        // Push the current operator onto the stack.
        operatorStack.push(token)
      } else if (token === '(') {
        // If the token is an open parenthesis, push it onto the stack.
        operatorStack.push(token)
      } else if (token === ')') {
        // If the token is a close parenthesis, pop operators from the stack to the output
        // until an open parenthesis is encountered.
        while (operatorStack.length > 0 && operatorStack[operatorStack.length - 1] !== '(') {
          output.push(operatorStack.pop())
        }

        // Pop the open parenthesis from the stack (but don't add it to the output).
        operatorStack.pop()

        // If the top of the stack is a function, pop it to the output.
        if (this.isFunction(operatorStack[operatorStack.length - 1], formModules)) {
          output.push(operatorStack.pop())
        }
      } else {
        output.push(token)
      }
    }

    // Pop any remaining operators from the stack to the output.
    while (operatorStack.length > 0) {
      output.push(operatorStack.pop())
    }

    // Return the postfix expression as a string.
    return output.join(' ')
  }

  static validInfix (expression, localFunctions) {
    const postfix = this.infixToPostfix(expression, localFunctions)
    const infix = this.postfixToInfix(postfix, localFunctions)
    const postfix2 = this.infixToPostfix(infix, localFunctions)
    return postfix === postfix2
  }

  static validPostfix (expression, localFunctions) {
    const infix = this.postfixToInfix(expression, localFunctions)
    const postfix = this.infixToPostfix(infix, localFunctions)
    const infix2 = this.postfixToInfix(postfix, localFunctions)
    return infix === infix2
  }

  static calc = (expression, getValue) => {
    const tokens = expression.match(/\S+/g)
    const stack = []
    let valid = true

    const push = (a) => {
      if (Array.isArray(a)) {
        stack.push(a)
      } else {
        if (isNaN(a) || isNaN(parseFloat(a))) {
          stack.push(a)
        } else {
          stack.push(a)
        }
      }
    }

    const popNumber = () => {
      const a = stack.pop()
      if (a === null) return null
      if (typeof a === 'undefined') return a
      if (a === true) return 1
      if (a === false) return 0
      if (isNaN(a) || isNaN(parseFloat(a))) {
        return null
      }
      return parseFloat(a)
    }

    for (let j = 0; j < tokens.length; j++) {
      if (valid) {
        const token = tokens[j]
        // console.log(`checking ${token}`);
        if (!isNaN(token)) {
          push(parseFloat(token))
        } else if (token === 'eq') {
          const b = stack.pop()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = stack.pop()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(a === b)
        } else if (token === '!') {
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(a > 0 ? 0 : 1)
        } else if (token === '&') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
        } else if (token === '%') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(a % b)
        } else if (token === '|') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(a > 0 || b > 0 ? 1 : 0)
        } else if (token === '>') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(a > b ? 1 : 0)
        } else if (token === '<') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(a < b ? 1 : 0)
        } else if (token === '=') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(a === b ? 1 : 0)
        } else if (token === '>=') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(a >= b ? 1 : 0)
        } else if (token === '<>') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(a !== b ? 1 : 0)
        } else if (token === '!=') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(a !== b ? 1 : 0)
        } else if (token === '<=') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(a <= b)
        } else if (token === '+') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(a + b)
        } else if (token === '-') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(a - b)
        } else if (token === '*') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(a * b)
        } else if (token === '/') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          if (b === 0) {
            valid = false
          } else {
            push(a / b)
          }
        } else if (token === 'min') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          if (valid) {
            push(a < b ? a : b)
          }
        } else if (token === 'max') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(a > b ? a : b)
        } else if (token === 'round') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          if (valid) {
            push(a.toFixed(b))
          }
        } else if (token === 'floor') {
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(Math.floor(a))
        } else if (token === 'ceil') {
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(Math.ceil(a))
        } else if (token === 'Math.min') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(a < b ? a : b)
        } else if (token === 'Math.max') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(a > b ? a : b)
        } else if (token === 'Math.round') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(a.toFixed(b))
        } else if (token === 'Math.floor') {
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(Math.floor(a))
        } else if (token === 'Math.ceil') {
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(Math.ceil(a))
        } else if (token === 'Math.pow') {
          const b = popNumber()
          if (typeof b === 'undefined' || b === null) {
            valid = false
          }
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(Math.pow(a, b))
        } else if (token === 'Math.sqrt') {
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(Math.sqrt(a))
        } else if (token === 'Math.abs') {
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(Math.abs(a))
        } else if (token === 'Math.log') {
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(Math.log(a))
        } else if (token === 'Math.log2') {
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(Math.log2(a))
        } else if (token === 'Math.log10') {
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(Math.log10(a))
        } else if (token === 'Math.exp') {
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(Math.exp(a))
        } else if (token === 'Math.sign') {
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(Math.sign(a))
        } else if (token === 'Math.sin') {
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(Math.sin(a))
        } else if (token === 'Math.cos') {
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(Math.cos(a))
        } else if (token === 'Math.tan') {
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(Math.tan(a))
        } else if (token === 'Math.asin') {
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(Math.asin(a))
        } else if (token === 'Math.acos') {
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(Math.acos(a))
        } else if (token === 'Math.atan') {
          const a = popNumber()
          if (typeof a === 'undefined' || a === null) {
            valid = false
          }
          push(Math.atan(a))
        } else {
          const a = getValue(token)
          push(a)
        }
      }
    }

    if (valid && stack.length === 1) {
      return { valid: true, value: stack.pop() }
    } else {
      return { valid: false }
    }
  }
}
export default ExpressionHelper
