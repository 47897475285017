import React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'

const DrawerFooter = ({ showSaveButton = false, onBack, onSave, text }) => {
  return (
    <Box sx={{ mt: 4 }}>
      <Grid container sx={{ pt: 2, pb: 2, pl: 1, pr: 2 }} style={styles.container}>
        <Grid item xs={6} sx={{ pl: 1 }} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Button color='primary' variant='text' onClick={onBack}>
            <Typography variant='body1'>&lt; {text || 'Back'}</Typography>
          </Button>
        </Grid>
        {showSaveButton && onSave
          ? (
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Button color='primary' variant='contained' onClick={onSave}>
                <Typography variant='body'>Done</Typography>
              </Button>
            </Grid>
            )
          : null}
      </Grid>
    </Box>
  )
}
export default DrawerFooter

const styles = {
  container: {
    position: 'fixed',
    bottom: 0,
    borderTop: '1px solid rgba(0,0,0,.08)',
    backgroundColor: 'white'
  }
}
