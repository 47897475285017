import Api, { ApiPlugin } from 'library/Api'
import { Topics } from 'config/topics'
import Company from 'screens/Company'
import LocalStorage from 'library/LocalStorage'
import Config from 'config/index'

export class ApiPluginRippleConfig extends ApiPlugin {
  /**
   *
   * @param {ApiBase} apiBase
   * @param {Object} props
   */
  constructor (apiBase, props) {
    super(apiBase, props)

    if (props && props.baseUrl) {
      this.baseUrl = props.baseUrl
    }
    apiBase.registerMethod('getDefaultConfig', this.getDefaultConfig.bind(this))
    apiBase.registerMethod('getCurrentConfig', this.getCurrentConfig.bind(this))
    apiBase.registerMethod('getCurrentFinalConfig', this.getCurrentFinalConfig.bind(this))
    apiBase.registerMethod('getConfigList', this.getConfigList.bind(this))
    apiBase.registerMethod('getConfigById', this.getConfigById.bind(this))
    apiBase.registerMethod('getConfigListByTag', this.getConfigListByTag.bind(this))
    apiBase.registerMethod('getLatestConfigByTag', this.getLatestConfigByTag.bind(this))
    apiBase.registerMethod('getImportableConfigs', this.getImportableConfigs.bind(this))
    apiBase.registerMethod('getLibraryMaster', this.getLibraryMaster.bind(this))
    apiBase.registerMethod('saveConfig', this.saveConfig.bind(this))
    apiBase.registerMethod('getConfigDifferentiators', this.getDiscriminators.bind(this))
    apiBase.registerMethod('getConfigProducts', this.getConfigProducts.bind(this))
    apiBase.registerMethod('getConfigPricingList', this.getConfigPricingList.bind(this))
    apiBase.registerMethod('getCRMFields', this.getCRMFields.bind(this))
    apiBase.registerMethod('getCRMTaxes', this.getCRMTaxes.bind(this))
    apiBase.registerMethod('getAssetList', this.getAssetList.bind(this))
    apiBase.registerMethod('deleteConfig', this.deleteConfig.bind(this))
    apiBase.registerMethod('getApiBaseUrl', this.getApiBaseUrl.bind(this))
    apiBase.registerMethod('getPublishedConfigs', this.getPublishedConfigs.bind(this))
    apiBase.registerMethod('getPublishedConfig', this.getPublishedConfig.bind(this))
    apiBase.registerMethod('newPublishedConfig', this.newPublishedConfig.bind(this))
    apiBase.registerMethod('rollbackPublishedConfig', this.rollbackPublishedConfig.bind(this))
    apiBase.registerMethod('recordError', this.recordError.bind(this))
  }

  getApiBaseUrl () {
    return this.apiBase.baseUrl
  }

  /**
   *
   * @param {Object} props
   * @param {string} props.tag particular tag if applicable
   * @param {string} [props.topic] Topic on which to publish the returned response.
   *
   * @return {Promise<Response>}
   */
  getDefaultConfig (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/defaultConfig/:tag', {
      tag: props.tag ? props.tag : 'all',
      organization: Company.getCurrentOrgId()
    })

    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */
        if (props && props.callback) {
          props.callback(rsp)
        }
        if (props && props.topic) {
          Topics.publish(props.topic, rsp)
        }
        if ('data' in rsp && Array.isArray(rsp.data) && rsp.data.length > 0) {
          return rsp.data[0]
        } else {
          return false
        }
      })
  }

  /**
   * Retrieves a list of users associated with the current organization.
   *
   * @param {Object} props
   * @param {string} props.tag particular tag if applicable
   * @param {string} [props.topic] Topic on which to publish the returned response.
   *
   * @return {Promise<Response>}
   */
  async getCurrentConfig (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/currentConfig/:tag', {
      tag: props.tag ? 'all' : props.tag,
      organization: Company.getCurrentOrgId()
    })

    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */
        if (props && props.callback) {
          props.callback(rsp)
        }
        if (props && props.topic) {
          Topics.publish(props.topic, rsp)
        }
        if ('data' in rsp && Array.isArray(rsp.data) && rsp.data.length > 0) {
          if (Array.isArray(rsp.data[0]) && rsp.data.length > 0) {
            return rsp.data[0][0].config
          } else {
            return rsp.data[0]
          }
        } else {
          return false
        }
      })
  }

  /**
   * Retrieves a list of users associated with the current organization.
   *
   * @param {Object} props
   * @param {string} props.tag particular tag if applicable
   * @param {string} [props.topic] Topic on which to publish the returned response.
   *
   * @return {Promise<Response>}
   */
  getCurrentFinalConfig (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/currentConfig/:tag', {
      tag: props.tag ? 'all' : props.tag,
      organization: Company.getCurrentOrgId()
    })

    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */
        if (props && props.callback) {
          props.callback(rsp)
        }
        if (props && props.topic) {
          Topics.publish(props.topic, rsp)
        }
        if ('data' in rsp && Array.isArray(rsp.data) && rsp.data.length > 0) {
          return rsp.data[0]
        } else {
          return false
        }
      })
  }

  /**
   * Retrieves a list of users associated with the current organization.
   *
   * @param {Object} props
   * @param {string} props.configId Configuration ID
   * @param {string} [props.topic] Topic on which to publish the returned response.
   *
   * @return {Promise<Response>}
   */
  getConfigById (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/configs/:configId', {
      configId: props.configId,
      organization: Company.getCurrentOrgId()
    })

    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */
        if (props && props.callback) {
          props.callback(rsp)
        }
        if (props && props.topic) {
          Topics.publish(props.topic, rsp)
        }
        return rsp
      })
  }

  /**
   * Retrieves a list of configs associated with the current organization.
   *
   * @param {Object} props
   * @param {string} [props.topic] Topic on which to publish the returned response.
   *
   * @return {Promise<Response>}
   */
  getConfigList (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/configs', { organization: Company.getCurrentOrgId() })

    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */
        if (rsp && rsp.status === 'success') {
          // This should be an array of person objects
          if (this.getApiBase().cacheResponses()) {
            LocalStorage.store(Config.configList, rsp.data)
          }
        }
        if (props && props.callback) {
          props.callback(rsp)
        }
        if (props && props.topic) {
          Topics.publish(props.topic, rsp)
        }
        return rsp.data
      })
  }

  /**
   * Retrieves a list of configs that can be imported.
   *
   * @param {Object} props
   * @param {string} [props.topic] Topic on which to publish the returned response.
   *
   * @return {Promise<Response>}
   */
  getImportableConfigs (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/configs', { organization: Company.getCurrentOrgId() })

    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */
        if (rsp && rsp.status === 'success') {
          // This should be an array of person objects
          if (this.getApiBase().cacheResponses()) {
            LocalStorage.store(Config.configList, rsp.data)
          }
        }
        if (props && props.callback) {
          props.callback(rsp)
        }
        if (props && props.topic) {
          Topics.publish(props.topic, rsp)
        }
        return rsp
      })
  }

  /**
   * Retrieves product libraryMaster data for a single org.
   *
   * @param {Object} props
   *
   * @return {Promise<Response>}
   */
  getLibraryMaster (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/libraryMaster', { organization: Company.getCurrentOrgId() })
    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */
        if (rsp && rsp.data[0] && rsp.status === 'success') {
          return rsp.data[0]
        }
        return []
      })
  }

  /**
   * Retrieves a list config based on tag.  This will hit the cached list if available. If no tag is passed, this is
   * a wrapper for getConfigList.
   *
   * @param {Object} props
   * @param {string} [props.tag] Tag on which to filter the config list.
   * @param {string} [props.startsWith=false] Whether to match tag exactly (default) or all configs whose tags start
   *        with the passed tag.
   *
   * @return {Promise<[]>}
   */
  async getConfigListByTag (props) {
    let configList
    let configs = []
    if (!this.getApiBase().cacheResponses() || LocalStorage.hasKey(Config.configList)) {
      // load all configs
      configList = await this.getConfigList({})
    }
    if (props.tag) {
      if (configList) {
        for (const config of configList) {
          if (config.tag === props.tag || (props && props.startsWith)) {
            configs[configs.length] = config
          }
        }
      }
    } else {
      configs = configList
    }
    return Promise.resolve(configs)
  }

  /**
   * Retrieves newest config based on tag. This will hit the cached list if available. If no tag is passed, this is
   * a wrapper for getConfigList.
   *
   * @param {Object} props
   * @param {string} [props.tag] Tag on which to filter the config list.
   * @param {string} [props.startsWith=false] Whether to match tag exactly (default) or all configs whose tags start
   *        with the passed tag.
   *
   * @return {Promise<Object>}
   */
  async getLatestConfigByTag (props) {
    const configs = await this.getConfigListByTag(props)
    const maxConfigId = Math.max.apply(
      Math,
      configs
        .filter((o) => ('status' in props ? o.status === props.status : o.status === 'Published'))
        .map(function (o) {
          return o.configId
        })
    ) // use min/max main/latest config
    const maxConfigArr = configs.filter((arr) => {
      return arr.configId === maxConfigId
    })
    if (maxConfigArr.length) {
      const maxOrgId = maxConfigArr[0].organizationId
      const config = await this.getConfigById({ configId: maxConfigId, organizationId: maxOrgId })
      if (config && config.data[0]) return config.data[0]
      return {}
    } else {
      return {}
    }
  }

  /**
   *
   * @param {object} props
   * @return {Promise<RippleConfigDiscriminator[]>}
   */
  async getDiscriminators (props) {
    if (!this.getApiBase().cacheResponses() || !LocalStorage.hasKey(Config.configDiscriminator)) {
      const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/configDiscriminators', { organization: Company.getCurrentOrgId() })

      const rsp = await this.getApiBase().get(route)
      if (rsp && rsp.status === 'success') {
        // This should be an array of person objects
        if (this.getApiBase().cacheResponses()) {
          LocalStorage.store(Config.configDiscriminator, rsp.data)
        }
        return Promise.resolve(rsp.data)
      }
    }
    if (this.getApiBase().cacheResponses()) {
      return Promise.resolve(LocalStorage.retrieve(Config.configDiscriminator))
    }
    return null
  }

  /**
   *
   * @param {object} props
   * @return {Promise<RippleConfigProducts[]>}
   */
  async getConfigProducts (props) {
    if (!this.getApiBase().cacheResponses() || !LocalStorage.hasKey(Config.configProducts)) {
      const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/configProducts', { organization: Company.getCurrentOrgId() })

      const rsp = await this.getApiBase().get(route)
      if (rsp && rsp.status === 'success') {
        // This should be an array of person objects
        if (this.getApiBase().cacheResponses()) {
          LocalStorage.store(Config.configProducts, rsp.data)
        }
        return rsp.data
      }
    }
    if (this.getApiBase().cacheResponses()) {
      return Promise.resolve(LocalStorage.retrieve(Config.configProducts))
    }
    return null
  }

  /**
   *
   * @param {object} props
   * @return {Promise<RippleConfigProducts[]>}
   */
  async getConfigPricingList (props) {
    if (!this.getApiBase().cacheResponses() || !LocalStorage.hasKey(Config.configPricingList)) {
      const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/configPricingList/:name', { organization: Company.getCurrentOrgId(), name: props.name })
      try {
        const rsp = await this.getApiBase().get(route)
        if (rsp && rsp.status === 'success') {
          // This should be an array of person objects
          if (this.getApiBase().cacheResponses()) {
            LocalStorage.store(Config.configPricingList, rsp.data)
          }
          return rsp.data
        }
        if (this.getApiBase().cacheResponses()) {
          return Promise.resolve(LocalStorage.retrieve(Config.configPricingList))
        }
      } catch (e) {
      }
    }
    return []
  }

  /**
   *
   * @param {object} props
   * @return {Promise<RippleConfigCRMFields[]>}
   */
  async getCRMFields (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/crmFields', { organization: Company.getCurrentOrgId() })

    const rsp = await this.getApiBase().get(route)
    if (rsp && rsp.status === 'success') {
      return Promise.resolve(rsp.data[0])
    }

    return Promise.resolve([])
  }

  /**
   *
   * @param {object} props
   * @return {Promise<RippleConfigCRMTaxes[]>}
   */
  async getCRMTaxes (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/crmTaxes', { organization: Company.getCurrentOrgId() })

    const rsp = await this.getApiBase().get(route)
    if (rsp && rsp.status === 'success' && rsp.data !== null) {
      return Promise.resolve(rsp.data[0])
    }

    return Promise.resolve([])
  }

  /**
   *
   * @param {object} props
   * @return {Promise<RippleConfigAssetList[]>}
   */
  async getAssetList (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/assets', { organization: Company.getCurrentOrgId() })

    const rsp = await this.getApiBase().get(route)
    if (rsp && rsp.status === 'success' && rsp.data !== null) {
      return Promise.resolve(rsp.data[0])
    }

    return Promise.resolve([])
  }

  saveConfig (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organizationId/configs' + (props.configId ? '/:configId' : ''), {
      configId: props.configId,
      organizationId: props.organizationId
    })
    let status = 'Published' // DEV-4650
    if (props.status) status = props.status
    const payload = {
      name: props.name,
      tag: props.tag,
      status,
      contentJson: props.contentJson
    }
    return this.getApiBase()
      .post(route, payload)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */

        // Update config list
        if (rsp && rsp.status === 'success') {
          let added = false
          let list = null
          if (this.getApiBase().cacheResponses()) {
            list = LocalStorage.retrieve(Config.configList)
          }
          if (!list) {
            list = []
          }
          if (props.configId) {
            // existing config, replace
            for (let i = 0, l = list.length; i < l; i++) {
              if (list[i].configId === rsp.data[0].configId) {
                added = true
                list[i] = rsp.data[0]
              }
            }
          }
          if (!added) {
            list[list.length] = rsp.data[0]
          }
          if (this.getApiBase().cacheResponses()) {
            LocalStorage.store(Config.configList, list)
          }
        }

        if (props && props.callback) {
          props.callback(rsp)
        }
        if (props && props.topic) {
          Topics.publish(props.topic, rsp)
        }
        return rsp
      })
  }

  /**
   * Retrieves a list of users associated with the current organization.
   *
   * @param {Object} props
   * @param {string} props.id Configuration ID
   *
   * @return {Promise<Response>}
   */
  deleteConfig (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organizationId/configs/:configId/delete', {
      configId: props.configId,
      organizationId: Company.getCurrentOrgId()
    })
    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */

        // Update config list
        if (rsp && rsp.status === 'success') {
          if (this.getApiBase().cacheResponses()) {
            let list = null
            if (this.getApiBase().cacheResponses()) {
              list = LocalStorage.retrieve(Config.configList)
            }
            if (!list) {
              list = []
            }
            if (props.configId) {
              // existing config, replace
              for (let i = 0, l = list.length; i < l; i++) {
                if (list[i].configId === rsp.data[0].configId) {
                  list.splice(i, 1)
                  break
                }
              }
            }
            if (this.getApiBase().cacheResponses()) {
              LocalStorage.store(Config.configList, list)
            }
          }
        }

        if (props && props.callback) {
          props.callback(rsp)
        }
        if (props && props.topic) {
          Topics.publish(props.topic, rsp)
        }
        return rsp
      })
  }

  /**
   * Retrieves a list of published configs.
   *
   * @return {Promise<Response>}
   */
  async getPublishedConfigs (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/published/configs', {
      tag: props.tag ? 'all' : props.tag,
      organization: Company.getCurrentOrgId()
    })
    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        if (props && props.callback) {
          props.callback(rsp)
        }
        if ('data' in rsp && Array.isArray(rsp.data) && rsp.data.length > 0) {
          return rsp.data
        } else {
          return []
        }
      })
  }

  /**
   * Retrieves a single config from ripple_config_pub, including the json.
   *
   * @return {Promise<Response>}
   */
  async getPublishedConfig (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/published/configs/:configId', {
      organization: Company.getCurrentOrgId(),
      configId: props.configId
    })
    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        if (props && props.callback) {
          props.callback(rsp)
        }
        if ('data' in rsp && Array.isArray(rsp.data) && rsp.data.length > 0) {
          return rsp.data
        } else {
          return []
        }
      })
  }

  /**
   * Posts a new config with a name.
   *
   * If it exists, it updates the version.
   * If it does not exist, it creates one.  And sets it as published.
   */
  async newPublishedConfig (props) {
    if (!props.name) {
      return false
    }
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/published/new', { organization: Company.getCurrentOrgId() })
    const payload = {
      name: props.name
    }
    return this.getApiBase()
      .post(route, payload)
      .then((rsp) => {
        if (rsp.status) {
          return rsp.status
        } else {
          return false
        }
      })
  }

  /**
   * Rollbacks to a config Id.
   */
  async rollbackPublishedConfig (props) {
    if (!props.configId) {
      return false
    }
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/published/:configId/publish', {
      organization: Company.getCurrentOrgId(),
      configId: props.configId
    })
    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        if (props && props.callback) {
          props.callback(rsp)
        }
        if (rsp.status) {
          return rsp.status
        } else {
          return false
        }
      })
  }

  /**
   * Record Error
   */
  async recordError (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/recordError', {
      organization: Company.getCurrentOrgId()
    })
    const payload = {
      error: props.error
    }
    return this.getApiBase()
      .post(route, payload)
      .then((rsp) => {
        return true
      })
  }
}
