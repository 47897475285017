/* eslint-disable eqeqeq */
import React from 'react'
import { Box, Button, Chip, Grid, IconButton, Tooltip, Stack, Typography } from '@mui/material'
import AdminIcon from '@mui/icons-material/AdminPanelSettings'
import EditIcon from '@mui/icons-material/Edit'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import ImportIcon from '@mui/icons-material/FileUpload'
import ExportIcon from '@mui/icons-material/FileDownload'
import Api from 'library/Api'
import CompanyConfigEdit, { CompanyConfigBaseEditor } from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import DataGrid from 'components/Reusable/DataGrid'
import { DialogSettingsProduct } from './Products/DialogSettingsProduct'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import { flattenProductCategories, ProductEditor } from './Products/ProductEditor'
import Helper from 'library/helper'
import { JSONEditor } from 'components/Reusable'
import Permissions from 'library/Permissions'
import { LoadingPleaseWait } from '@supportworks/react-components'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

const config = {
  title: 'Master List',
  variant: 'Products',
  tag: 'products',
  name: 'Master List',
  lockTag: true,
  lockName: true,
  ovrd_fields: ['description', 'title', 'description', 'price', 'units', 'tax', 'discount', 'isCustom']
}

class ProductsEditor extends CompanyConfigBaseEditor {
  constructor (props) {
    super(props)
    props.onDataLoading(true)

    this.state = {
      productsList: [],
      crmProductsList: [], // All original data from the CRM in a single array
      isLoading: true,
      isWorking: false, // Used for updating materials on the products
      filter: '',
      editable: false,
      version: 1,
      showImportConfirm: false,
      importedProductsModifications: {
        added: 0,
        updated: 0,
        unchanged: 0
      },
      importedProducts: [],

      productFeatures: this.props.productFeatures ? this.props.productFeatures : {},
      assetsConfig: null,
      displaySettings: {},
      flatCat: [],
      selectedProduct: null,
      masterProduct: null,
      isAdding: false,
      isCRM: this.props.isCRM,
      showSettings: false,
      showProductEditor: false,
      showJSONEditor: false,
      error: null
    }
  }

  async componentDidMount () {
    const cRef = this
    super.componentDidMount()

    let productsList = []
    if (typeof this.props.contentJson === 'string') {
      try {
        const jsonObj = JSON.parse(this.props.contentJson)
        for (const i in jsonObj) {
          productsList.push(jsonObj[i])
        }
      } catch (err) {
        console.log(err.message)
      }
    } else if (typeof this.props.contentJson === 'object') {
      // no need to parse
      const jsonObj = this.props.contentJson
      jsonObj.forEach(obj => {
        productsList.push(obj)
      })
    } else if (this.props.contentJson !== undefined) {
      try {
        const jsonObj = JSON.parse(this.props.contentJson)
        jsonObj.forEach(obj => {
          productsList.push(obj)
        })
      } catch (err) {
        console.log(err.message)
      }
    }

    productsList = productsList.map(p => {
      if (!('categories' in p && Array.isArray(p.categories))) {
        p.categories = []
      }
      return p
    })

    await Api.callRegisteredMethod('getAssetList', {}).then(assetsConfig => {
      this.setState({ assetsConfig: assetsConfig.sort((a, b) => (a.name > b.name ? 1 : -1)) })
    })

    let displaySettings
    await Api.callRegisteredMethod('getLatestConfigByTag', { tag: 'displaySettings', startsWith: false }).then(c => {
      if (c && c.contentJson) {
        displaySettings = JSON.parse(c.contentJson)
      }
    })
    this.setState({ displaySettings })

    if (this.state.productFeatures && this.state.productFeatures.product_categories) {
      const flat = flattenProductCategories(this.state.productFeatures.product_categories, [])
      this.setState({ flatCat: flat })
    }

    Promise.all([
      Api.callRegisteredMethod('getConfigProducts').then(async products => {
        // ensure products and editable are set before the .then
        return new Promise((resolve) => {
          if (products && (products.length === 0 || (products.length === 1 && products[0] == null))) {
            cRef.setState({ products: null, editable: true }, resolve)
          } else {
            cRef.setState({ products, editable: false }, resolve)
          }
        })
      })
    ])
      .then(results => {
        /**
         * NO CRM products
         *
         * I do not have any products, which means we're not coming from a CRM.
         * We want to set editable to true so we can manually add/edit.
         */
        const list = []
        if (!cRef.state?.products || cRef.state.products[0] === null) {
          productsList.forEach(p => {
            cRef.addExistingGuid(p.id)
          })
          cRef.setState({
            isLoading: false,
            editable: true,
            isCRM: this.props.isCRM,
            productsList
          })
          this.props.onDataLoading(false)
        } else {
          /**
           * CRM products
           *
           * I have CRM products, and I might have custom added stuff (productsList) that I want to merge in.
           */
          const crmProductsList = []
          // The first loop are products in the CRM from the getConfigProducts call above
          cRef.state.products.forEach(l => {
            l[0].list.forEach(crmProduct => {
              let exists = false
              crmProductsList.push(JSON.parse(JSON.stringify(crmProduct)))

              // Loop all my products in my actual config and bounce against the CRM products list to see if it's mapped
              productsList.forEach(ourProduct => {
                // If my id is not in the CRM products list but my crmId is, it's mapped, so we'll set it mapped.
                ourProduct.mappedToCRM = null
                let crmIdx = l[0].list.findIndex(x => x.id === ourProduct.id)
                if (crmIdx === -1) {
                  ourProduct.mappedToCRM = false
                  crmIdx = l[0].list.findIndex(x => x.id === ourProduct.crmId)
                  if (crmIdx > -1) {
                    ourProduct.mappedToCRM = true
                  }
                }

                if (ourProduct.id === crmProduct.id) {
                  /**
                   * These fields ARE in the CRM, but can be overriden.
                   * If the ([field]_ovrd) key is true, we want to use what is in our config and not the CRM.
                   */
                  config.ovrd_fields.forEach(f => {
                    if (ourProduct[f + '_ovrd'] === true) {
                      crmProduct[f + '_ovrd'] = true
                      crmProduct[f] = ourProduct[f]
                    } else {
                      crmProduct[f + '_ovrd'] = false
                    }
                  })
                  // All of these ARE NOT in the CRM but only in our config, so we always want them.
                  if (ourProduct.categories_ovrd === true) {
                    crmProduct.categories = JSON.parse(JSON.stringify(ourProduct.categories))
                  } else {
                    crmProduct.categories_ovrd = false
                  }
                  if (ourProduct.warranty) crmProduct.warranty = JSON.parse(JSON.stringify(ourProduct.warranty))
                  if (ourProduct.properties) crmProduct.properties = JSON.parse(JSON.stringify(ourProduct.properties))
                  if (ourProduct.pricingOptions) crmProduct.pricingOptions = JSON.parse(JSON.stringify(ourProduct.pricingOptions))
                  if (ourProduct.drawingConfig) crmProduct.drawingConfig = JSON.parse(JSON.stringify(ourProduct.drawingConfig))

                  if ('eplh_hoursPerJob' in crmProduct) {
                    if (crmProduct.eplh_hoursPerJob != null) {
                      if ('pricingOptions' in crmProduct) {
                        if ('eplh' in crmProduct.pricingOptions) {
                          crmProduct.pricingOptions.eplh.hoursPerJob = crmProduct.eplh_hoursPerJob
                        } else {
                          crmProduct.pricingOptions.eplh = { hoursPerJob: crmProduct.eplh_hoursPerJob }
                        }
                      } else {
                        crmProduct.pricingOptions = { eplh: { hoursPerJob: crmProduct.eplh_hoursPerJob } }
                      }
                    }
                    delete crmProduct.eplh_hoursPerJob
                  }
                  if ('eplh_hoursPerUnit' in crmProduct) {
                    if (crmProduct.eplh_hoursPerUnit != null) {
                      if ('pricingOptions' in crmProduct) {
                        if ('eplh' in crmProduct.pricingOptions) {
                          crmProduct.pricingOptions.eplh.hoursPerUnit = crmProduct.eplh_hoursPerUnit
                        } else {
                          crmProduct.pricingOptions.eplh = { hoursPerUnit: crmProduct.eplh_hoursPerUnit }
                        }
                      } else {
                        crmProduct.pricingOptions = { eplh: { hoursPerUnit: crmProduct.eplh_hoursPerUnit } }
                      }
                    }
                    delete crmProduct.eplh_hoursPerUnit
                  }
                  if (!crmProduct.deleted) {
                    crmProduct.in_master = true
                    if (!exists) list.push(crmProduct)
                  }
                  exists = true
                }
              })
              if (!exists) {
                if (!crmProduct.deleted) {
                  crmProduct.in_master = true
                  config.ovrd_fields.forEach(f => {
                    crmProduct[f + '_ovrd'] = false
                  })
                  if (!('categories' in crmProduct)) crmProduct.categories = []
                  if (!('warranty' in crmProduct)) crmProduct.warranty = []
                  if (!('properties' in crmProduct)) crmProduct.properties = []
                  if (!('pricingOptions' in crmProduct)) crmProduct.pricingOptions = {}
                  if (!('drawingConfig' in crmProduct)) crmProduct.drawingConfig = {}
                  if ('eplh_hoursPerJob' in crmProduct) {
                    if (crmProduct.eplh_hoursPerJob != null) {
                      if ('pricingOptions' in crmProduct) {
                        if ('eplh' in crmProduct.pricingOptions) {
                          crmProduct.pricingOptions.eplh.hoursPerJob = crmProduct.eplh_hoursPerJob
                        } else {
                          crmProduct.pricingOptions.eplh = { hoursPerJob: crmProduct.eplh_hoursPerJob }
                        }
                      } else {
                        crmProduct.pricingOptions = { eplh: { hoursPerJob: crmProduct.eplh_hoursPerJob } }
                      }
                    }
                    delete crmProduct.eplh_hoursPerJob
                  }
                  if ('eplh_hoursPerUnit' in crmProduct) {
                    if (crmProduct.eplh_hoursPerUnit != null) {
                      if ('pricingOptions' in crmProduct) {
                        if ('eplh' in crmProduct.pricingOptions) {
                          crmProduct.pricingOptions.eplh.hoursPerUnit = crmProduct.eplh_hoursPerUnit
                        } else {
                          crmProduct.pricingOptions.eplh = { hoursPerUnit: crmProduct.eplh_hoursPerUnit }
                        }
                      } else {
                        crmProduct.pricingOptions = { eplh: { hoursPerUnit: crmProduct.eplh_hoursPerUnit } }
                      }
                    }
                    delete crmProduct.eplh_hoursPerUnit
                  }
                  list.push(crmProduct)
                }
              }
            })
          })
          productsList.forEach(p2 => {
            let existsInMaster = false
            cRef.state.products.forEach(l => {
              l[0].list.forEach(p => {
                if (p2.id == p.id) existsInMaster = true
              })
            })
            if (!existsInMaster) {
              if (p2.manual === true || !(p2.in_master === true)) {
                p2.in_master = false
                list.push(p2)
              } else {
                console.log(`SKIPPING ${p2.title}`)
              }
            }
          })

          list.forEach(p => {
            cRef.addExistingGuid(p.id)
          })

          cRef.setState({
            isCRM: this.props.isCRM,
            productsList: list.sort((a, b) => (a.crmId > b.crmId ? 1 : -1)),
            crmProductsList,
            isLoading: false
          })
          this.props.onDataLoading(false)
        }
      })
      .catch(err => {
        console.log(err)
      })

    if (Permissions.hasRole('super_user')) {
      // Loop through all my productsList.  If I have any with pricingOptions.assembly, I want to prompt for the auto-sync.  If I do not have any, I do not want to prompt.
      let hasAssembly = false
      for (const p of productsList) {
        if (p.pricingOptions && p.pricingOptions.assembly) {
          hasAssembly = true
          break // Break out of the loop
        }
      }

      if (hasAssembly) {
        // ONLY super user thru test
        const r = window.confirm('ADMIN ONLY feature - This organization has assemblies.  Would you like to perform an auto-sync for matching materials in the master product list?')
        if (r) {
          this.setState({ isWorking: true })
          try {
            await Helper.updateProductsFromAssembly({ saveConfigProducts: true })
          } catch (error) {
            window.alert(error.message)
            this.setState({ isWorking: false, error: error.message })
          }
        }
        this.setState({ isWorking: false })
      }
    }
  }

  handleAdd = () => {
    const product = {
      id: this.guid(),
      crmId: '',
      mappedToCRM: false,
      title: 'New product',
      isCustom: false,
      tax: false,
      discount: true,
      description: 'Description',
      price: 0,
      units: 'each', // default to each p/janstey
      model: '',
      manufacturer: '',
      deleted: false,
      in_master: false,
      categories: [],
      warranty: [],
      properties: [],
      pricingOptions: {
        min_price: 0
      },
      isCRM: this.props.isCRM
    }
    config.ovrd_fields.forEach(f => {
      product[f + '_ovrd'] = true
    })
    this.setState({ isAdding: true, selectedProduct: product, masterProduct: product, showProductEditor: !this.state.showProductEditor })
  }

  handleProductSelect = p => {
    let mp
    if (this.props.isCRM) {
      mp = this.state.crmProductsList.find(o => o.id === p.id)
    }
    // For custom products that were added prior to it being a CRM or hacked into the JSON, I have to get it from productsList
    if (!mp) {
      mp = this.state.productsList.find(o => o.id === p.id)
    }
    this.setState({ selectedProduct: p, masterProduct: mp, showProductEditor: !this.state.showProductEditor })
  }

  handleSave = (product, flatCat) => {
    const pl = JSON.parse(JSON.stringify(this.state.productsList))
    if (this.state.isAdding) {
      pl.push(product)
    } else {
      const idx = pl.map(o => o.id).indexOf(product.id)
      if (idx > -1) {
        pl[idx] = product
      }
    }
    // Update flatCat dictionary on changes so we can display the title on the index page correctly.
    if (JSON.stringify(flatCat) !== JSON.stringify(this.state.flatCat)) {
      this.setState({ flatCat })
    }
    this.setState({
      productsList: pl,
      version: this.state.version + 1,
      selectedProduct: null,
      masterProduct: null,
      showProductEditor: !this.state.showProductEditor,
      isAdding: false
    })
    this.props.onChange(JSON.stringify(pl)) // Trigger unsaved changes.
  }

  handleDelete = idx => {
    const r = window.confirm('Are you sure you want to delete?')
    if (r) {
      const clonedArr = Array.from(this.state.productsList)
      clonedArr.splice(idx, 1)
      this.setState({
        productsList: clonedArr,
        version: this.state.version + 1
      })
    }
  }

  handleDuplicate = idx => {
    // console.log("IDX: "+idx);
    const clonedArr = Array.from(this.state.productsList)
    const newProduct = JSON.parse(JSON.stringify(clonedArr[idx]))
    newProduct.id = this.guid()
    newProduct.manual = true
    clonedArr.splice(idx + 1, 0, newProduct)
    this.setState({
      productsList: clonedArr,
      version: this.state.version + 1
    })
  }

  handleClose = () => {
    this.setState({ selectedProduct: null, masterProduct: null, showProductEditor: !this.state.showProductEditor })
  }

  handleExport = full => {
    // Build JSON for export
    const productList = this.state.productsList
    const productFeatures = this.state.productFeatures

    const exportJSON = productList.map(elm => {
      const obj = {}
      let doFull = full
      obj.SKU = elm.crmId
      obj.Source = 'CRM'
      if (elm.crmId != elm.id) {
        obj.Source = 'Custom'
        obj.UID = elm.id
        doFull = true
      }
      if ('productCode' in elm) {
        obj.ProductCode = elm.productCode
      } else {
        obj.ProductCode = ''
      }
      if (doFull) {
        obj.Title = elm.title
        obj.Categories = elm.categories
          .map(w => {
            let title = w
            if (productFeatures && productFeatures.product_categories && Array.isArray(productFeatures.product_categories)) {
              productFeatures.product_categories.forEach(f => {
                if (f.id == w) {
                  title = f.title
                }
              })
            }
            return title
          })
          .join(', ')
        obj.Description = elm.description
        obj.Price = elm.price
        obj.Units = elm.units
        obj.Tax = elm.tax ? 'Yes' : 'No'
        obj.Discount = elm.discount ? 'Yes' : 'No'
        obj.IsCustom = elm.isCustom ? 'Yes' : 'No'
      } else {
        if (elm.title_ovrd) obj.Title = elm.title
        if (elm.categories_ovrd) {
          obj.Categories = elm.categories
            .map(w => {
              let title = w
              if (productFeatures && productFeatures.product_categories && Array.isArray(productFeatures.product_categories)) {
                productFeatures.product_categories.forEach(f => {
                  if (f.id == w) {
                    title = f.title
                  }
                })
              }
              return title
            })
            .join(', ')
        }
        if (elm.description_ovrd) obj.Description = elm.description
        if (elm.price_ovrd) obj.Price = elm.price
        if (elm.units_ovrd) obj.Units = elm.units
        if (elm.tax_ovrd) obj.Tax = elm.tax ? 'Yes' : 'No'
        if (elm.discount_ovrd) obj.Discount = elm.discount ? 'Yes' : 'No'
        if (elm.isCustom_ovrd) obj.IsCustom = elm.isCustom ? 'Yes' : 'No'
      }
      if ('warranty' in elm) {
        obj.Warranty = elm.warranty
          .map(w => {
            let title = w
            productFeatures.warranties.forEach(f => {
              if (f.id === w) {
                title = f.heading
              }
            })
            return title
          })
          .join(', ')
      }
      if ('pricingOptions' in elm) {
        if ('eplh' in elm.pricingOptions) {
          obj.HoursPerJob = elm.pricingOptions.eplh.hoursPerJob
          obj.HoursPerUnit = elm.pricingOptions.eplh.hoursPerUnit
        }
        if ('margin' in elm.pricingOptions) {
          obj.ProductCost = elm.pricingOptions.margin.productCost
          obj.MarkupPct = elm.pricingOptions.margin.markupPercentage
        }
        if ('min_price' in elm.pricingOptions) {
          obj.MinPrice = elm.pricingOptions.min_price
        }
        if ('salesCommission' in elm.pricingOptions) {
          obj.SPIFF = elm.pricingOptions.salesCommission.spiff
        }
        if ('unit_tier' in elm.pricingOptions) {
          obj.UnitTierPrice = elm.pricingOptions.unit_tier
            .map((x, idx) => {
              if (idx > 0) {
                return x.break + ', ' + x.price
              } else {
                return x.price
              }
            })
            .join(', ')
        }
        if ('inc_tier' in elm.pricingOptions) {
          obj.IncTierPrice = elm.pricingOptions.inc_tier
            .map((x, idx) => {
              if (idx < elm.pricingOptions.inc_tier.length - 1) {
                return x.price + ', ' + x.break
              } else {
                return x.price
              }
            })
            .join(', ')
        }
      }
      return obj
    })

    // Export to xlsx via xlsx
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(exportJSON)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, 'Products_' + (full ? 'full' : 'override') + '_' + Helper.getDateString() + fileExtension)
  }

  handleImport = async () => {
    const fileUpload = document.getElementById('fileButton')
    if (fileUpload.files) {
      fileUpload.value = null
    }

    fileUpload.click()
    fileUpload.onchange = e => {
      // let fileName = fileUpload.files[0].name;
      if (typeof FileReader !== 'undefined') {
        const reader = new FileReader() // eslint-disable-line
        if (reader.readAsBinaryString) {
          reader.onload = e => {
            this.handleProcessExcel(reader.result)
          }
          reader.readAsBinaryString(fileUpload.files[0])
        }
      }
    }
  }

  handleProcessExcel (data) {
    const workbook = XLSX.read(data, { type: 'binary' })
    const firstSheet = workbook.SheetNames[0]
    const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet], { raw: false })

    const newProducts = excelRows.map(elm => {
      const obj = {}
      if ('SKU' in elm && String(elm.SKU).length > 0) obj.SKU = elm.SKU
      if ('UID' in elm && String(elm.UID).length > 0) obj.UID = elm.UID
      if ('Source' in elm && String(elm.Source).length > 0) obj.Source = elm.Source
      if ('ProductCode' in elm && String(elm.ProductCode).length > 0) obj.ProductCode = elm.ProductCode
      if ('Title' in elm && String(elm.Title).length > 0) obj.Title = elm.Title
      if ('Categories' in elm && String(elm.Categories).length > 0) obj.Categories = elm.Categories
      if ('Description' in elm && String(elm.Description).length > 0) obj.Description = elm.Description
      if ('Price' in elm && String(elm.Price).length > 0) obj.Price = elm.Price
      if ('Units' in elm && String(elm.Units).length > 0) obj.Units = elm.Units
      if ('Tax' in elm && String(elm.Tax).length > 0) obj.Tax = elm.Tax
      if ('Discount' in elm && String(elm.Discount).length > 0) obj.Discount = elm.Discount
      if ('Warranty' in elm && String(elm.Warranty).length > 0) obj.Warranty = elm.Warranty
      if ('HoursPerJob' in elm && String(elm.HoursPerJob).length > 0) obj.HoursPerJob = elm.HoursPerJob
      if ('HoursPerUnit' in elm && String(elm.HoursPerUnit).length > 0) obj.HoursPerUnit = elm.HoursPerUnit
      if ('ProductCost' in elm && String(elm.ProductCost).length > 0) obj.ProductCost = elm.ProductCost
      if ('MarkupPct' in elm && String(elm.MarkupPct).length > 0) obj.MarkupPct = elm.MarkupPct
      if ('MinPrice' in elm && String(elm.MinPrice).length > 0) obj.MinPrice = elm.MinPrice
      if ('SPIFF' in elm && String(elm.SPIFF).length > 0) obj.SPIFF = elm.SPIFF
      if ('UnitTierPrice' in elm && String(elm.UnitTierPrice).length > 0) obj.UnitTierPrice = elm.UnitTierPrice
      if ('IncTierPrice' in elm && String(elm.IncTierPrice).length > 0) obj.IncTierPrice = elm.IncTierPrice
      return obj
    })
    this.setState({ importedProducts: newProducts })
    // Update existing products and add new products while keeping the unincluded ones
    const clonedArr = Array.from(this.state.productsList)

    this.handlePreviewImport(clonedArr)
  }

  handlePreviewImport (oldObject) {
    const stagedStats = {
      updated: 0,
      added: 0,
      unchanged: 0
    }
    this.state.importedProducts.forEach(p => {
      if (p.Source == 'Custom' || (p.UID && p.UID.length > 0)) {
        const oldProduct = oldObject.find(elm => elm.id === p.UID)
        if (oldProduct) {
          if (('SKU' in p && p.SKU !== oldProduct.crmId) || ('Title' in p && p.Title !== oldProduct.title) || ('Price' in p && p.Price !== oldProduct.price) || ('Units' in p && p.Units !== oldProduct.units) || ('Description' in p && p.Description !== oldProduct.description) || ('Tax' in p && p.Tax !== oldProduct.tax) || ('Discount' in p && p.Discount !== oldProduct.discount) || ('IsCustom' in p && p.IsCustom !== oldProduct.isCustom) || 'Warranty' in p || 'HoursPerJob' in p || 'HoursPerUnit' in p || 'ProductCost' in p || 'MarkupPct' in p || 'MinPrice' in p || 'MinPrice' in p || 'SPIFF' in p || 'UnitTierPrice' in p || 'IncTierPrice' in p) {
            stagedStats.updated++
          } else {
            stagedStats.unchanged++
          }
        } else {
          stagedStats.added++
        }
      } else {
        const oldProduct = oldObject.find(elm => elm.crmId === p.SKU)
        if (oldProduct) {
          if (('SKU' in p && p.SKU !== oldProduct.crmId) || ('Title' in p && p.Title !== oldProduct.title) || ('Price' in p && p.Price !== oldProduct.price) || ('Units' in p && p.Units !== oldProduct.units) || ('Description' in p && p.Description !== oldProduct.description) || ('Tax' in p && p.Tax !== oldProduct.tax) || ('Discount' in p && p.Discount !== oldProduct.discount) || ('IsCustom' in p && p.IsCustom !== oldProduct.isCustom) || 'Warranty' in p || 'HoursPerJob' in p || 'HoursPerUnit' in p || 'ProductCost' in p || 'MarkupPct' in p || 'MinPrice' in p || 'MinPrice' in p || 'SPIFF' in p || 'UnitTierPrice' in p || 'IncTierPrice' in p) {
            stagedStats.updated++
          } else {
            stagedStats.unchanged++
          }
        } else {
          stagedStats.added++
        }
      }
    })

    this.setState({ importedProductsModifications: stagedStats })
    this.setState({ showImportConfirm: true })
  }

  handleConfirmImport () {
    const productFeatures = this.state.productFeatures
    const newArray = Array.from(this.state.productsList)
    this.state.importedProducts.forEach(p => {
      let i = -1
      if (p.UID && p.UID.length > 0) {
        if (p.Source == 'Custom') {
          i = newArray.findIndex(_element => _element.id === p.UID)
        }
      } else {
        if (p.Source == 'CRM') {
          i = newArray.findIndex(_element => _element.crmId === p.SKU && _element.crmId === _element.id)
        }
      }

      let obj = null
      let ok = true
      if (i > -1) {
        obj = newArray[i]
      } else {
        if (p.Source == 'CRM') {
          ok = false
        }
        obj = {}
        if ('UID' in p) {
          obj.id = p.UID
        } else {
          obj.id = this.guid()
        }
      }
      if ('ProductCode' in p) obj.productCode = p.ProductCode
      if ('Title' in p) obj.title = p.Title
      if ('Description' in p) obj.description = p.Description
      if ('Price' in p) {
        obj.price = parseFloat(p.Price)
        obj.price_ovrd = true
      }
      if ('Units' in p) obj.units = p.Units
      if ('Tax' in p) obj.tax = p.Tax == 'Yes'
      if ('Discount' in p) obj.discount = p.Discount == 'Yes'
      if ('IsCustom' in p) obj.isCustom = p.IsCustom == 'Yes'
      if ('Categories' in p) {
        obj.categories = p.Categories.split(',').map(d => {
          let id = d
          productFeatures.product_categories?.forEach(f => {
            if (f.title == d) {
              id = f.id
            }
          })
          return id
        })
      }
      if (!('pricingOptions' in obj)) {
        obj.pricingOptions = { min_price: 0 }
      }
      if ('Warranty' in p) {
        obj.warranty = p.Warranty.split(',').map(d => {
          let id = d
          productFeatures.warranties.forEach(f => {
            if (f.heading == d) {
              id = f.id
            }
          })
          return id
        })
      }
      if ('HoursPerJob' in p || 'HoursPerUnit' in p) {
        if (!('eplh' in obj.pricingOptions)) {
          obj.pricingOptions.eplh = { hoursPerJob: 0, hoursPerUnit: 0 }
        }
        if ('HoursPerJob' in p) obj.pricingOptions.eplh.hoursPerJob = parseFloat(p.HoursPerJob)
        if ('HoursPerUnit' in p) obj.pricingOptions.eplh.hoursPerUnit = parseFloat(p.HoursPerUnit)
      }
      if ('ProductCost' in p || 'MarkupPct' in p) {
        if (!('margin' in obj.pricingOptions)) {
          obj.pricingOptions.margin = { productCost: 0, markupPercentage: 0 }
        }
        if ('ProductCost' in p) obj.pricingOptions.margin.productCost = parseFloat(p.ProductCost)
        if ('MarkupPct' in p) obj.pricingOptions.margin.markupPercentage = parseFloat(p.MarkupPct)
      }
      if ('MinPrice' in obj) obj.pricingOptions.min_price = parseFloat(obj.MinPrice)
      if ('SPIFF' in p) {
        if (!('salesCommission' in obj.pricingOptions)) {
          obj.pricingOptions.salesCommission = { spiff: 0 }
        }
        if ('SPIFF' in p) obj.pricingOptions.margin.spiff = parseFloat(p.SPIFF)
      }
      if ('UnitTierPrice' in p) {
        delete obj.pricingOptions.unit_tier
        delete obj.pricingOptions.inc_tier
        obj.pricingOptions.unit_tier = []
        let lastBreak = 0
        const n = p.UnitTierPrice.split(',')
        n.forEach((x, idx) => {
          if (idx % 2 == 1) {
            lastBreak = parseFloat(x)
          }
          if (idx == 0) {
            obj.pricingOptions.unit_tier.push({ price: parseFloat(x) })
          } else {
            if (idx % 2 == 0) {
              obj.pricingOptions.unit_tier.push({ break: lastBreak, price: parseFloat(x) })
            }
          }
        })
      }
      if ('IncTierPrice' in p) {
        delete obj.pricingOptions.unit_tier
        delete obj.pricingOptions.inc_tier
        obj.pricingOptions.inc_tier = []
        let lastPrice = 0
        const n = p.IncTierPrice.split(',')
        n.forEach((x, idx) => {
          if (idx % 2 == 0) {
            lastPrice = parseFloat(x)
            if (idx == n.length - 1) {
              obj.pricingOptions.inc_tier.push({ price: lastPrice })
            }
          }
          if (idx % 2 == 1) {
            obj.pricingOptions.inc_tier.push({ price: lastPrice, break: parseFloat(x) })
          }
        })
      }
      if (ok) {
        if (i > -1) {
          newArray[i] = obj
        } else {
          newArray.push(obj)
        }
      }
    })
    this.setState({
      productsList: newArray,
      version: this.state.version + 1,
      showImportConfirm: false
    })
    this.forceUpdate()
  }

  handleConfirmImportClose = () => {
    this.setState({ showImportConfirm: false })
  }

  handleShowJSONEditor = (p) => {
    const show = !this.state.showJSONEditor
    if (show) {
      this.setState({ selectedProduct: p })
    } else {
      this.setState({ selectedProduct: null })
    }
    this.setState({ showJSONEditor: show })
  }

  handleChangeJSONEditor = (p) => {
    const product = JSON.parse(p)
    const clonedArr = Array.from(this.state.productsList)
    if (product && product.id) {
      const idx = clonedArr.map(o => o.id).indexOf(product.id)
      if (idx > -1) {
        clonedArr[idx] = product
      }
    }
    this.setState({
      productsList: clonedArr,
      selectedProduct: null,
      showJSONEditor: !this.state.showJSONEditor
    })
    // this.props.onChange(JSON.stringify(clonedArr)) // Trigger unsaved changes.
  }

  getFloat (value) {
    const num = parseFloat(value, 10)
    return isNaN(num) ? 0 : `${num}`
  }

  finalize () {
    // console.log("Finalize called")
    return JSON.stringify(this.state.productsList)
  }

  hasFinalize () {
    return true // true to enable saving
  }

  render () {
    if (this.state.isLoading) {
      return <LoadingPleaseWait />
    }

    if (this.state.error) {
      throw new Error(this.state.error)
    }

    const columns = [
      {
        flex: 3,
        type: 'string',
        field: 'title',
        headerName: 'Product Title',
        editable: false,
        sortable: true,
        renderCell: params => {
          return (
            <Box sx={{ maxWidth: '100%', overflow: 'hidden' }}>
              <Tooltip title={params.value}>
                <Typography fontSize={14}>{params.value}</Typography>
              </Tooltip>
            </Box>
          )
        }
      },
      {
        flex: 1,
        type: 'string',
        field: 'source',
        headerName: 'Source',
        editable: false,
        sortable: true
      },
      {
        flex: 2,
        type: 'string',
        field: 'categories',
        headerName: 'Categories',
        editable: false,
        sortable: false,
        renderCell: params => {
          const categories = params.row.categories || []
          return (
            <Box sx={{ maxWidth: '100%', overflow: 'hidden' }}>
              {categories.map((cat, idx) => (
                <Tooltip title={cat} key={idx}>
                  <Chip label={cat} size='small' sx={{ pr: 0.25, mb: 0.25 }} />
                </Tooltip>
              ))}
            </Box>
          )
        }
      },
      {
        flex: 1,
        type: 'string',
        field: 'tax',
        headerName: 'Tax',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'discount',
        headerName: 'Discount',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'number',
        field: 'price',
        headerName: 'Display Price',
        editable: false,
        sortable: true,
        align: 'right',
        headerAlign: 'right',
        renderCell: params => {
          return <Box>${Number(params.value).toFixed(2)}</Box>
        }
      },
      {
        flex: 1,
        type: 'string',
        field: 'unit_type',
        headerName: 'Unit Type',
        editable: false,
        sortable: true
      },
      {
        width: Permissions.hasRole('super_user') ? 168 : 128, // DEV-6580
        field: 'action',
        headerName: 'Actions',
        headerAlign: 'right',
        align: 'right',
        editable: false,
        sortable: false,
        disableExport: true,
        renderCell: params => {
          if (params.row) {
            const p = params.row.product
            const idx = params.row.id
            return (
              <Grid container wrap='nowrap' justifyContent='flex-end' style={{ overflow: 'visible' }}>
                {Permissions.hasRole('super_user')
                  ? (
                    <Grid item>
                      <IconButton
                        title='Edit Json'
                        aria-label='edit json'
                        onClick={() => {
                          this.handleShowJSONEditor(p)
                        }}
                      >
                        <AdminIcon fontSize='small' style={{ color: 'red' }} />
                      </IconButton>
                    </Grid>
                    )
                  : null}
                <Grid item>
                  <IconButton
                    title='Edit'
                    aria-label='edit'
                    onClick={() => {
                      this.handleProductSelect(p)
                    }}
                  >
                    <EditIcon fontSize='small' />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton title='Copy' aria-label='copy' onClick={() => this.handleDuplicate(idx)}>
                    <ContentCopyIcon fontSize='small' />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    title='Delete'
                    aria-label='delete'
                    onClick={() => {
                      this.handleDelete(idx)
                    }}
                  >
                    <DeleteIcon fontSize='small' />
                  </IconButton>
                </Grid>
              </Grid>
            )
          }
        }
      }
    ]

    const rows = []
    for (let i = 0; i < this.state.productsList.length; i++) {
      const categories = []
      const p = this.state.productsList[i]

      if ('categories' in p) {
        for (let j = 0; j < p.categories.length; j++) {
          const cat = p.categories[j]
          const pc = this.state.flatCat.find(o => o.id === cat)
          categories.push(pc && pc.title ? pc.title : cat)
        }
      }

      const row = {
        id: i,
        product: p,
        title: p.title,
        source: p.crmId == p.id ? 'CRM' : 'Custom',
        categories,
        tax: p.tax ? 'Yes' : 'No',
        discount: p.discount ? 'Yes' : 'No',
        price: Number(p.price).toFixed(2),
        unit_type: p.units
      }
      rows.push(row)
    }

    // const DialogImportConfirm = (props) => {}

    const jsxImportConfirm = (
      <Box>
        <Stack>
          <Typography>
            You are about to add {this.state.importedProductsModifications.added} products and modify {this.state.importedProductsModifications.updated}{' '}
            products.
          </Typography>
          <Typography sx={{ pt: 2 }}>
            {this.state.importedProductsModifications.unchanged} products will remain unchanged.
          </Typography>
          <Typography sx={{ pt: 2, fontWeight: 'bold' }}>Are you sure you want to continue?</Typography>
        </Stack>
      </Box>
    )

    return (
      <Box>
        <Box sx={{ pb: 1 }} style={{ width: '100%', overflow: 'hidden' }}>
          <Box style={{ width: '60%', float: 'right' }}>
            {!this.state.editable
              ? (
                <Button
                  id='export-overrides-action'
                  variant='contained'
                  onClick={() => {
                    this.handleExport(false)
                  }}
                  startIcon={<ExportIcon />}
                  sx={{ float: 'right', marginLeft: '8px' }}
                >
                  Overrides
                </Button>
                )
              : null}

            <input id='fileButton' type='file' accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' hidden />
            <Button id='import-action' variant='contained' onClick={this.handleImport} startIcon={<ImportIcon />} sx={{ float: 'right' }}>
              Import
            </Button>

            <Button
              id='export-action'
              variant='contained'
              onClick={() => {
                this.handleExport(true)
              }}
              startIcon={<ExportIcon />}
              sx={{ float: 'right', marginRight: '8px' }}
            >
              Export
            </Button>

            {!this.props.isCRM || Permissions.hasRole('super_user')
              ? (
                <Button id='add-action' color='primary' variant='contained' onClick={this.handleAdd} sx={{ float: 'right', marginRight: '8px' }}>
                  Add Custom Product
                </Button>
                )
              : null}
          </Box>
        </Box>
        <DataGrid
          rows={rows}
          columns={columns}
          useStandardRowHeight={false}
          showToolbarDensity={false}
          showToolbarExport={false}
          onShowSettings={() => {
            this.setState({ showSettings: !this.state.showSettings })
          }}
          autosizeOptions={{
            columns: ['action'],
            includeOutliers: true,
            includeHeaders: false
          }}
        />

        {this.state.showSettings
          ? (
            <DialogSettingsProduct
              organizationId={this.props.config.organizationId}
              onClose={displaySettings => {
                this.setState({ displaySettings, showSettings: !this.state.showSettings })
              }}
            />
            )
          : null}

        {this.state.showProductEditor ? <ProductEditor variant={this.props.isCRM ? 'master-crm' : 'master-nocrm'} organizationId={this.props.config.organizationId} productFeatures={this.state.productFeatures} assetsConfig={this.state.assetsConfig} displaySettings={this.state.displaySettings} crmProducts={this.state.crmProductsList} products={this.state.productsList} product={this.state.selectedProduct} masterProduct={this.state.masterProduct} onClose={this.handleClose} onSave={this.handleSave} isAdding={this.state.isAdding} isCRM={this.props.isCRM} /> : null}

        {this.state.showJSONEditor
          ? <DialogGeneric
              title='JSON Field Editor'
              fullWidth
              maxWidth='md'
              content={
                <JSONEditor
                  value={JSON.stringify(this.state.selectedProduct)}
                  onChange={(product) => {
                    this.handleChangeJSONEditor(product)
                  }}
                  saveOnlyOnDone
                />
                    }
              onClose={this.handleShowJSONEditor}
              onSave={this.handleShowJSONEditor}
            />
          : null}

        {this.state.showImportConfirm
          ? <DialogGeneric
              title='Import Products'
              content={jsxImportConfirm}
              fullWidth
              maxWidth='sm'
              titleDone='Done'
              onClose={() => this.handleConfirmImportClose()}
              onChange={() => this.handleConfirmImport()}
            />
          : null}
      </Box>
    )
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Edit: props => <CompanyConfigEdit {...config} {...props} editorComponent={ProductsEditor} />,
  Index: props => <CompanyConfigIndex {...config} {...props} />
}
