import { useEffect, useState } from 'react'
import { Box, TextField } from '@mui/material'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { FormCard } from './FormCard'

export const FormCardComplete = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [title, setTitle] = useState('')

  useEffect(() => {
    setTitle(props.form && props.form.title ? props.form.title : 'No title')
    setIsLoading(false)
  }, []) // eslint-disable-line

  useEffect(() => {
    setTitle(props.form.title ? props.form.title : 'No title')
  }, [props.form])

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'title') {
      props.form.title = value
      setTitle(value)
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  const jsx = (
    <Box sx={{ p: 2, flex: 1 }}>
      <TextField
        fullWidth
        id='title'
        name='title'
        variant='outlined'
        label='Title'
        value={title}
        onChange={handleChange}
      />
    </Box>
  )

  return (
    <FormCard {...props} jsx={jsx} />
  )
}
