import Api, { ApiPlugin, ApiBase } from 'library/Api'
import { Topics } from 'config/topics'
import Company from 'screens/Company'
import LocalStorage from 'library/LocalStorage'
import Config from 'config/index'

export class ApiPluginUsers extends ApiPlugin {
  /**
   *
   * @param {ApiBase} apiBase
   * @param {Object} props
   */
  constructor (apiBase, props) {
    super(apiBase, props)

    if (props && props.baseUrl) {
      this.baseUrl = props.baseUrl
    }
    apiBase.registerMethod('getUsers', this.getUsers.bind(this))
    apiBase.registerMethod('getUser', this.getUser.bind(this))
    apiBase.registerMethod('getAuthorizationManifest', this.getAuthorizationManifest.bind(this))
    apiBase.registerMethod('saveUser', this.saveUser.bind(this))
    apiBase.registerMethod('newUser', this.newUser.bind(this))
    apiBase.registerMethod('impersonateUser', this.impersonateUser.bind(this))
    apiBase.registerMethod('stopImpersonation', this.stopImpersonation.bind(this))
  }

  /**
   *
   * @param props
   */
  getAuthorizationManifest (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/authorization-config', { organization: Company.getCurrentOrgId() })

    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */
        if (rsp && rsp.status === 'success') {
          if (props && props.callback) {
            props.callback(rsp.data[0])
          }
          if (props && props.topic) {
            Topics.publish(props.topic, rsp.data[0])
          }
          return rsp.data[0]
        }
        return null
      })
  }

  /**
   *
   * @param {object} props
   * @param {int} props.person Person identifier
   *
   * @return Promise
   */
  getUser (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/users/:person', { organization: Company.getCurrentOrgId(), person: props.person })

    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */
        if (rsp && rsp.status === 'success') {
          if (props && props.callback) {
            props.callback(rsp.data[0])
          }
          if (props && props.topic) {
            Topics.publish(props.topic, rsp.data[0])
          }
          return rsp.data[0]
        }
        return null
      })
  }

  /**
   * Retrieves a list of users associated with the current organization.
   *
   * @return {Promise<Response>}
   */
  getUsers (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/users', { organization: Company.getCurrentOrgId() })

    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        /**
         * @type {ResponseEnvelope} rsp
         */
        if (rsp && rsp.status === 'success') {
          // This should be an array of person objects
          if (this.getApiBase().cacheResponses()) {
            LocalStorage.store(Config.users, rsp.data[0]) // I can't tell if this is even used??? psb 03/22/23
          }
          return rsp.data[0]
        }
        return null
      })
  }

  /**
   *
   * @param {object} props
   * @param {object} props.user
   * @param {object} props.id
   */
  saveUser (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/users/:person', {
      organization: Company.getCurrentOrgId(),
      person: props.user.person.id
    })
    const payload = {
      person: props.user.person,
      authorizations: props.user.authorizations,
      details: props.user.details
    }

    return this.getApiBase()
      .post(route, payload)
      .then((rsp) => {
        return rsp
      })
  }

  /**
   *
   * @param {object} props
   * @param {object} props.user
   * @param {object} props.id
   */
  newUser (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/users', { organization: Company.getCurrentOrgId() })
    const payload = {
      person: props.user.person,
      authorizations: props.user.authorizations,
      details: props.user.details
    }

    return this.getApiBase()
      .post(route, payload)
      .then((rsp) => {
        return rsp
      })
  }

  /**
   *
   * @param props
   * @return {Promise<Response>}
   */
  impersonateUser (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/org/:organization/users/:person/impersonate', {
      organization: Company.getCurrentOrgId(),
      person: props.personId
    })

    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        if (rsp.status === 'success') {
          // iterate over data and update the session variable
          for (let i = 0, l = rsp.data.length; i < l; i++) {
            const o = rsp.data[i]
            if (o.$type === 'CoreBundle\\Entity\\AppSession') {
              ApiBase.callRegisteredMethod('setSession', o)
            }
          }
        }
        return rsp
      })
  }

  /**
   *
   * @param props
   * @return {Promise<Response>}
   */
  stopImpersonation (props) {
    const route = Api.resolvePath(this.getBaseUrl() + '/stopImpersonation', {})

    return this.getApiBase()
      .get(route)
      .then((rsp) => {
        if (rsp.status === 'success') {
          // iterate over data and update the session variable
          for (let i = 0, l = rsp.data.length; i < l; i++) {
            const o = rsp.data[i]
            if (o.$type === 'CoreBundle\\Entity\\AppSession') {
              ApiBase.callRegisteredMethod('setSession', o)
            }
          }
        }
        return rsp
      })
  }
}
