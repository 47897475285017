import React, { useState, useEffect } from 'react'
import { Button, Grid, IconButton, Typography } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import Api, { ApiBase } from '../../library/Api'
import { Link } from 'react-router-dom'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { FooterLeftPanel } from './Footer'

const Header = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [session, setSession] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    Api.callRegisteredMethod('getSession').then((ses) => {
      if (ses) {
        setSession(ses)
      }
      setIsLoading(false)
    })
  }, [])

  let headerAllClasses = 'HeaderContainer'
  headerAllClasses = props.headerClass ? headerAllClasses + ' ' + props.headerClass : headerAllClasses

  if (isLoading) return <LoadingPleaseWait />

  return (
    <div className={headerAllClasses}>
      <div className='Logo'>
        {/** <img src={require('../../images/sv_logo_rev.png')} width={419} alt={'SolutionView by Supportworks, Inc.'} className={'logo__desktop'} /> **/}
        <img src={require('../../images/sv_logo_light.png')} width={419} alt='SolutionView by Supportworks, Inc.' className='logo__desktop' />
        <img src={require('../../images/sv_logo_light.png')} width={225} alt='SolutionView by Supportworks, Inc.' className='logo__mobile' />
      </div>

      <div
        className={menuOpen ? 'menu__overlay menu__open' : 'menu__overlay'}
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
      />

      <div className='menu__desktop'>
        <div className='Middle'>{props.children ? props.children : null}</div>
        <FooterLeftPanel session={session} />
      </div>

      <div className='menu__mobile'>
        <IconButton
          onClick={() => {
            setMenuOpen(!menuOpen)
          }}
        >
          <MenuIcon style={{ color: 'white' }} />
        </IconButton>
        <div className={menuOpen ? 'menu__wrapper menu__open' : 'menu__wrapper'}>
          {props.children ? props.children : null}
          <Grid container justifyContent='center'>
            {Api.callRegisteredMethod('isAuthenticated')
              ? (
                <Grid item xs={12} sx={{ pl: 2, pr: 2 }}>
                  <Button fullWidth variant='contained' component={Link} to={ApiBase.resolvePath('/Logout')} startIcon={<LogoutIcon sx={{ color: 'white' }} />}>
                    <Typography sx={{ color: 'white' }}>Logout</Typography>
                  </Button>
                </Grid>
                )
              : null}
          </Grid>
        </div>
      </div>
    </div>
  )
}
export default Header
