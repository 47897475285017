import React, { useState, forwardRef } from 'react'
import '../../../../css/forms.css'
import { TextField } from '@mui/material'

function TextInputBasic (props, ref) {
  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState('')

  const changeHandler = (event) => {
    setInputValue(event.target.value)
    if (props.onChange) {
      props.onChange(event.target.value)
    }
  }

  const keyPressHandler = (event) => {
    if ('onKeyPress' in props) {
      props.onKeyPress(event)
    }
  }

  return (
    <TextField
      id={'id' in props ? props.id : 'text-input-basic'}
      className={'name' in props ? `textInputBasic-${props.name}` : 'textInputBasic'}
      placeholder={'placeholder' in props ? props.placeholder : 'Untitled Field'}
      fullWidth
      variant='outlined'
      value={'inputValue' in props ? props.inputValue : ''}
      onChange={changeHandler}
      onKeyPress={keyPressHandler}
      InputProps={{
        // disableUnderline: true
      }}
            // autoFocus={'onKeyPress' in props ? true : false}
      inputRef={ref}
      sx={{
        '& label.Mui-focused': {
          display: 'none'
        },
        '& legend': {
          display: 'none'
        },
        '& .MuiInputBase-root': {
          height: 36,
          marginTop: 0.7
        },
        '& .MuiOutlinedInput-root.Mui-focused': {
          '& > fieldset': {
            border: '1px solid dodgerblue'
          }
        },
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid dodgerblue'
          }
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none'
        }
      }}
    />
  )
}

export default forwardRef(TextInputBasic)
