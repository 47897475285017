import React, { useState, useEffect } from 'react'
import { Box, Grid, InputAdornment, Typography, Drawer, TextField } from '@mui/material'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import DrawerHeader from '../Reusables/DrawerHeader'
import DrawerFooter from '../Reusables/DrawerFooter'
import { LoadingPleaseWait } from '@supportworks/react-components'
import Helper from 'library/helper'

const DrawerEditValues = (props) => {
  const [isLoading, setIsLoading] = useState(true)

  const [context, setContext] = useState([])

  useEffect(() => {
    if (isLoading) {
      const f = JSON.parse(JSON.stringify(props.form))
      setContext(f.context)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const handleClose = (e) => {
    props.onClose()
  }

  const handleChange = (idx, obj) => {
    const c = JSON.parse(JSON.stringify(context))
    c[idx] = obj
    setContext(c)
  }

  const handleDone = (e) => {
    const f = JSON.parse(JSON.stringify(props.form))
    f.context = context
    props.onDone(f)
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <Drawer
      key='forms-edit-values'
      open={props.open}
      PaperProps={{
        sx: { width: '560px !important' }
      }}
      anchor='right'
      variant='temporary'
      onKeyDown={stopPropagationForTab}
      onClose={handleClose}
    >
      <DrawerHeader icon={<AppRegistrationIcon style={{ color: 'rgba(0,0,0,.66)' }} />} title='Edit Values' onClose={handleClose} />

      <Box sx={{ p: 2 }} style={styles.content}>
        {context.map((c, idx) => {
          return <Context key={`context-${idx}-${c.id}`} index={idx} context={c} onChange={handleChange} />
        })}
      </Box>

      <DrawerFooter onClose={handleClose} onDone={handleDone} />
    </Drawer>
  )
}
export default DrawerEditValues

const Context = (props) => {
  const [value, setValue] = useState(0)

  useEffect(() => {
    setValue(props.context.value)
  }, []) // eslint-disable-line

  const handleChange = (e) => {
    const value = e.target.value
    const c = JSON.parse(JSON.stringify(props.context))
    c.value = parseFloat(value)
    setValue(value)
    props.onChange(props.index, c)
  }

  return (
    <Grid container sx={{ pb: 2 }}>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 'bold' }}>{props.context.title || `No title for ${props.context.id}`}</Typography>
      </Grid>
      <Grid item xs={12} sx={{ pb: 1 }}>
        <Typography>{props.context.description || 'No description'}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='value'
          name='value'
          variant='outlined'
          fullWidth
          value={value}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Typography>{props.context.units || 'No units'}</Typography>
              </InputAdornment>
            )
          }}
          onKeyDown={(event) => {
            if (event.key !== 'Backspace') {
              if (!Helper.isDecimal(event.key)) {
                event.preventDefault()
              }
            }
          }}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  )
}

const styles = {
  content: {
    overflowX: 'hidden'
  }
}
