import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, TextField, IconButton } from '@mui/material'
import DataGrid from 'components/Reusable/DataGrid'
import EditIcon from '@mui/icons-material/Edit'
import UpIcon from '@mui/icons-material/KeyboardArrowUp'
import DownIcon from '@mui/icons-material/KeyboardArrowDown'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { DialogGeneric } from '../../../components/Reusable/DialogGeneric'

export const CustomerInitials = (props) => {
  const [initials, setInitials] = useState([])
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [editIndex, setEditIndex] = useState(0)

  useEffect(() => {
    if ('initials' in props.signoffs) {
      setInitials(props.signoffs.initials)
    }
    // eslint-disable-next-line
  }, [])

  const handleProcessRowUpdate = (row) => {
    if (row) {
      const l = JSON.parse(JSON.stringify(initials))
      l[row.id].title = row.title
      l[row.id].body = row.body
      setInitials(l)
      props.onChange(l)
      return row
    }
  }

  const handleAddCustInitials = () => {
    const l = JSON.parse(JSON.stringify(initials))
    l.push({
      id: props.guid(),
      title: '',
      body: ''
    })
    setInitials(l)
    props.onChange(l)
  }

  const handleDelete = (idx) => {
    const initialsNew = JSON.parse(JSON.stringify(initials))
    initialsNew.splice(idx, 1)
    props.onChange(initialsNew)
    setInitials(initialsNew)
  }

  const handleMove = (idx, direction) => {
    const list = JSON.parse(JSON.stringify(initials))
    if (direction === 'up') {
      if (list[idx - 1]) {
        const toSwitch = JSON.parse(JSON.stringify(list[idx]))
        const switchWith = JSON.parse(JSON.stringify(list[idx - 1]))
        list[idx - 1] = toSwitch
        list[idx] = switchWith
        setInitials(list)
        props.onChange(list)
      }
    } else if (direction === 'down') {
      if (list[idx + 1]) {
        const switchWith = JSON.parse(JSON.stringify(list[idx + 1]))
        list[idx + 1] = JSON.parse(JSON.stringify(list[idx]))
        list[idx] = switchWith
        setInitials(list)
        props.onChange(list)
      }
    }
  }

  const handleShowEditDialog = (idx) => {
    setEditIndex(idx)
    setShowEditDialog(!showEditDialog)
  }

  const handleCloseDialog = () => {
    setEditIndex(-1)
    setShowEditDialog(!showEditDialog)
  }

  const handleSaveDialog = (initials) => {
    setEditIndex(-1)
    setShowEditDialog(!showEditDialog)
    setInitials(initials)
    props.onChange(initials)
  }

  const columns = [
    {
      flex: 1,
      type: 'string',
      field: 'title',
      headerName: 'Title',
      editable: true,
      sortable: false,
      multiline: true,
      renderCell: (params) => {
        return <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{params.row.title}</Box>
      }
    },
    {
      flex: 1,
      type: 'string',
      field: 'body',
      headerName: 'Initial Signoff Language',
      editable: true,
      sortable: false,
      multiline: true,
      renderCell: (params) => {
        return <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{params.row.body}</Box>
      }
    },
    {
      flex: 1,
      field: 'action',
      headerName: 'Actions',
      headerAlign: 'right',
      align: 'right',
      editable: false,
      sortable: false,
      disableExport: true,
      renderCell: (params) => {
        if (params.row) {
          const idx = params.row.id
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton title='Edit' aria-label='edit' onClick={() => handleShowEditDialog(idx)}>
                  <EditIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton title='Move Up' aria-label='up' onClick={() => handleMove(idx, 'up')} disabled={idx < 1}>
                  <UpIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton title='Move Down' aria-label='down' onClick={() => handleMove(idx, 'down')} disabled={idx === initials.length - 1}>
                  <DownIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton title='Delete' aria-label='delete' onClick={() => handleDelete(idx)}>
                  <DeleteIcon variant='icon' fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < initials.length; x++) {
    rows.push({
      id: x,
      title: initials[x].title,
      body: initials[x].body
    })
  }

  return (
    <Box>
      <DataGrid
        rows={rows}
        rowHeight={56}
        columns={columns}
        showToolbar
        showSearch
        onAdd={() => handleAddCustInitials()}
        onProcessRowUpdate={(row) => {
          handleProcessRowUpdate(row)
        }}
        onCellKeyDown={(params, event) => {
          if (event.key === 'Enter') {
            event.preventDefault() // Prevent default Enter key behavior
          }
        }}
      />
      <Button sx={{ mt: 1 }} variant='contained' color='primary' onClick={handleAddCustInitials}>
        Add Customer Initial
      </Button>
      {showEditDialog && <DialogEdit index={editIndex} initials={initials} multiline onClose={handleCloseDialog} onChange={handleSaveDialog} />}
    </Box>
  )
}

const DialogEdit = (props) => {
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')

  useEffect(() => {
    setTitle(props.initials[props.index].title)
    setBody(props.initials[props.index].body)
    // eslint-disable-next-line
  }, [])

  const handleChange = (e) => {
    if (e.target.name === 'title') {
      setTitle(e.target.value)
    } else if (e.target.name === 'body') {
      setBody(e.target.value)
    }
  }

  const handleSave = () => {
    const l = JSON.parse(JSON.stringify(props.initials))
    l[props.index].title = title
    l[props.index].body = body
    props.onChange(l)
  }

  const jsx = (
    <Box>
      <TextField
        id='title'
        name='title'
        label='Title'
        multiline
        rows={4}
        variant='outlined'
        value={title}
        onChange={handleChange}
        inputProps={{
          style: {
            border: 'none',
            padding: '0px',
            resize: 'vertical'
          }
        }}
        sx={{ mt: 2 }}
        fullWidth
      />

      <TextField
        id='body'
        name='body'
        label='Initial Signoff Language'
        multiline
        rows={4}
        variant='outlined'
        value={body}
        onChange={handleChange}
        inputProps={{
          style: {
            border: 'none',
            padding: '0px',
            resize: 'vertical'
          }
        }}
        sx={{ mt: 3 }}
        fullWidth
      />
    </Box>
  )
  return <DialogGeneric title='Edit Customer Initial' onClose={props.onClose} onChange={handleSave} content={jsx} fullWidth />
}
