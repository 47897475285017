import React, { useEffect, useState } from 'react'
import DataGrid from 'components/Reusable/DataGrid'
import withScreenWrapper from '../../screens/withScreenWrapper'
import Company from '../Company'
import Api from '../../library/Api'
import {
  Box, Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select, Typography
} from '@mui/material'
import WhatshotIcon from '@mui/icons-material/Whatshot'
import Helper from '@supportworks/helper'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { BarChart, LineChart } from '@mui/x-charts'

const SalesRepAveragesReport = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')
  const [response, setResponse] = useState({})
  const [errorStartDate, setErrorStartDate] = useState(false)
  const [errorEndDate, setErrorEndDate] = useState(false)
  const [disposition, setDisposition] = useState('all')
  const [author, setAuthor] = useState('all')
  const [users, setUsers] = useState([])
  const [xRow, setXRow] = useState([])
  const [yCol1, setYCol1] = useState([])
  const [yCol2, setYCol2] = useState([])
  const [yCol3, setYCol3] = useState([])
  const [yCol4, setYCol4] = useState([])
  const [yCol5, setYCol5] = useState([])
  const [yCol6, setYCol6] = useState([])
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 })
  const [showCharts, setShowCharts] = useState(false)

  const currDate = new Date()
  const lastThirtyDays = new Date(new Date().setDate(currDate.getDate() - 30))

  useEffect(() => {
    if (isLoading) {
      setStart(dayjs(lastThirtyDays))
      setEnd(dayjs(currDate))
      Api.callRegisteredMethod('getUsers').then((users) => {
        const authors = users.persons.map(user => ({
          id: user.person.id,
          name: `${user.person.nameFirst} ${user.person.nameLast}`,
          email: user.person.email
        }))
        setUsers(authors)
        handleExecuteReport() // note that this set isLoading to false on first load
        setIsLoading(false)
      })
    }
  }, []) // eslint-disable-line

  const handleExecuteReport = () => {
    if (!errorStartDate && !errorEndDate) {
      let tstart = start
      let tend = end
      if (!tstart) tstart = dayjs(lastThirtyDays)
      if (!tend) tend = dayjs(currDate)
      const params = { start: Helper.formatDate(tstart, 'yyyy-mm-dd'), end: Helper.formatDate(tend, 'yyyy-mm-dd') }
      if (author !== 'all') {
        params.authorID = author
      }
      if (disposition !== 'all') {
        params.dispositionResult = disposition
      }
      Api.callRegisteredMethod('getSummarizedAppointmentsPresentations', params).then((rsp) => {
        setResponse(rsp)
        generateChartDataSet(rsp, paginationModel)
        setIsLoading(false)
      })
    }
  }

  const handleChangeDateStart = (e) => {
    // console.log(`setting start to ${e.$d}`)
    setStart(dayjs(e.$d))
  }

  const handleChangeDateEnd = (e) => {
    setEnd(dayjs(e.$d))
  }

  const handleChangeDisposition = (event) => {
    setDisposition(event.target.value)
  }

  const handleChangeAuthor = (event) => {
    setAuthor(event.target.value)
  }

  const handleOnPaginationModelChange = (model, details) => {
    setPaginationModel(model)
    generateChartDataSet(response, model)
  }

  const generateChartDataSet = (response, model) => {
    const responseArr = []
    for (const key of Object.keys(response)) {
      responseArr.push(response[key])
    }

    if (responseArr.length > 0) {
      const newXRow = []
      const newYCol1 = []
      const newYCol2 = []
      const newYCol3 = []
      const newYCol4 = []
      const newYCol5 = []
      const newYCol6 = []

      /* eslint no-unreachable-loop: ["error", { "ignore": ["ForStatement"] }] */
      for (let i = model.page * model.pageSize; i < responseArr.length; i += model.pageSize) {
        const batch = responseArr.slice(i, i + model.pageSize)

        for (let j = 0; j < batch.length; j++) {
          newXRow.push(batch[j].lastNameAuthor + ', ' + batch[j].firstNameAuthor)
          newYCol1.push(batch[j].numberOfAppointments)
          newYCol2.push(batch[j].projectAverageBase)
          newYCol3.push(batch[j].percentCustomerWhy)
          newYCol4.push(batch[j].averageImages)
          newYCol5.push(batch[j].averageSolutions)
          newYCol6.push(batch[j].averagePresentations)
        }
        break
      }
      setXRow(newXRow)
      setYCol1(newYCol1)
      setYCol2(newYCol2)
      setYCol3(newYCol3)
      setYCol4(newYCol4)
      setYCol5(newYCol5)
      setYCol6(newYCol6)
      setShowCharts(true)
    } else {
      setShowCharts(false)
    }
  }

  const columns = [
    {
      flex: 1,
      type: 'string',
      field: 'fullNameAuthor',
      headerName: 'Sales Rep',
      editable: false,
      sortable: true
    },
    {
      flex: 1,
      type: 'number',
      field: 'numberOfAppointments',
      headerName: 'Appointments',
      editable: false,
      sortable: true,
      align: 'right',
      headerAlign: 'right',
      renderCell: params => {
        const formattedValue = (params.value && params.value !== null) ? `${Number(params.value)}` : Number(0)
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>{formattedValue}</Box>
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'projectAverageBase',
      headerName: 'Selected Project Avg',
      editable: false,
      sortable: true,
      align: 'right',
      headerAlign: 'right',
      renderCell: params => {
        const formattedValue = (params.value && params.value !== null)
          ? `$${Number(params.value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
          : '$0.00'
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>{formattedValue}</Box>
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'percentCustomerWhy',
      headerName: 'Customer Why',
      editable: false,
      sortable: true,
      align: 'right',
      headerAlign: 'right',
      renderCell: params => {
        const formattedValue = params.value != null ? `${Number(params.value).toFixed(2)}%` : '0%'
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>{formattedValue}</Box>
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'averageImages',
      headerName: 'Avg/Photos',
      editable: false,
      sortable: true,
      align: 'right',
      headerAlign: 'right',
      renderCell: params => {
        const formattedValue = (params.value && params.value !== null) ? `${Number(params.value).toFixed(2)}` : '0.00'
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>{formattedValue}</Box>
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'averageSolutions',
      headerName: 'Avg/Solutions',
      editable: false,
      sortable: true,
      align: 'right',
      headerAlign: 'right',
      renderCell: params => {
        const formattedValue = (params.value && params.value !== null) ? `${Number(params.value).toFixed(2)}` : '0.00'
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>{formattedValue}</Box>
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'averagePresentations',
      headerName: 'Avg/Presentations',
      editable: false,
      sortable: true,
      align: 'right',
      headerAlign: 'right',
      renderCell: params => {
        const formattedValue = (params.value && params.value !== null) ? `${Number(params.value).toFixed(2)}` : '0.00'
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>{formattedValue}</Box>
      }
    }
  ]

  // response has a bunch of keys.  i want to loop through my keys.
  const rows = []
  for (const key of Object.keys(response)) {
    const author = users.find(user => user.id.toString() === key.toString())
    if (author) {
      rows.push({
        id: author.id,
        email: author.email,
        fullNameAuthor: response[key].fullNameAuthor,
        numberOfAppointments: response[key].numberOfAppointments,
        // projectAverage: `$${new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(parseFloat(response[key].projectAverage))}`,
        // percentCustomerWhy: `${new Intl.NumberFormat('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(parseFloat(response[key].percentCustomerWhy))}%`,
        projectAverageBase: response[key].projectAverageBase,
        percentCustomerWhy: response[key].percentCustomerWhy,
        averageImages: `${new Intl.NumberFormat('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(parseFloat(response[key].averageImages))}`,
        averageSolutions: `${new Intl.NumberFormat('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(parseFloat(response[key].averageSolutions))}`,
        averagePresentations: `${new Intl.NumberFormat('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(parseFloat(response[key].averagePresentations))}`
      })
    }
  }

  if (isLoading) {
    return <LoadingPleaseWait />
  }

  return (
    <Box>
      <Company.Header title='Sales Representative Averages' />
      <Box sx={{ mb: 3, mt: 3 }}>
        <Grid container spacing={1} alignItems='center' justifyContent='flex-start'>
          <Grid item sx={{ width: '220px' }}>
            <FormControl size='large' fullWidth>
              <InputLabel>Sales Rep</InputLabel>
              <Select
                label='Sales Rep'
                onChange={handleChangeAuthor}
                value={author}
              >
                <MenuItem key='all' value='all'>All</MenuItem>
                {users.map((user, idx) => {
                  return (
                    <MenuItem key={idx} value={user.id}>{user.name} ({user.id} {user.email})</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sx={{ width: '220px' }}>
            <FormControl size='large' fullWidth>
              <InputLabel>Result Detail</InputLabel>
              <Select
                label='Result Detail'
                onChange={handleChangeDisposition}
                value={disposition}
              >
                <MenuItem key='all' value='all'>All</MenuItem>
                <MenuItem key='pending' value='pending'>Pending</MenuItem>
                <MenuItem key='sold' value='sold'>Sold</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='Start Date'
                name='startDate'
                value={start}
                onChange={handleChangeDateStart}
                onError={(reason, value) => {
                  if (reason) {
                    setErrorStartDate(true)
                  } else {
                    setErrorStartDate(false)
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='End Date'
                name='endDate'
                value={end}
                onChange={handleChangeDateEnd}
                onError={(reason, value) => {
                  if (reason) {
                    setErrorEndDate(true)
                  } else {
                    setErrorEndDate(false)
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sx={{ ml: 2 }}>
            <Button size='medium' variant='contained' color='primary' onClick={() => handleExecuteReport()} endIcon={<WhatshotIcon />}>
              <Typography variant='button' style={{ textTransform: 'uppercase' }}>Generate</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        onPaginationModelChange={(model, details) => {
          handleOnPaginationModelChange(model, details)
        }}
      />

      {
        showCharts &&
          <Box>
            <Grid container>
              <Grid item>
                <BarChart
                  width={700}
                  height={600}
                  series={[
                    { data: yCol1, label: 'Appointments', id: 'appts' }
                  ]}
                  xAxis={[{ data: xRow, scaleType: 'band' }]}
                  // layout="horizontal"
                  margin={{ bottom: 300 }}
                  slotProps={{
                    legend: {
                      direction: 'row',
                      position: { horizontal: 'middle', vertical: 'top' },
                      padding: 0
                    }
                  }}
                  sx={{ mt: 4 }}
                  bottomAxis={{
                    tickLabelStyle: {
                      angle: 45,
                      textAnchor: 'start',
                      fontSize: 12
                    }
                  }}
                />
              </Grid>

              <Grid item>
                <LineChart
                  width={700}
                  height={600}
                  series={[
                    { data: yCol2, label: 'Selected Project Average', id: 'project average' }
                  ]}
                  xAxis={[{ data: xRow, scaleType: 'point' }]}
                  // layout="horizontal"
                  margin={{ bottom: 300 }}
                  slotProps={{
                    legend: {
                      direction: 'row',
                      position: { horizontal: 'middle', vertical: 'top' },
                      padding: 0
                    }
                  }}
                  sx={{ mt: 4 }}
                  bottomAxis={{
                    tickLabelStyle: {
                      angle: 45,
                      textAnchor: 'start',
                      fontSize: 12
                    }
                  }}
                />
              </Grid>

              <Grid item>
                <LineChart
                  width={700}
                  height={600}
                  series={[
                    { data: yCol3, label: 'Customer Why', id: 'customer why' }
                  ]}
                  xAxis={[{ data: xRow, scaleType: 'point' }]}
                  // layout="horizontal"
                  margin={{ bottom: 300 }}
                  slotProps={{
                    legend: {
                      direction: 'row',
                      position: { horizontal: 'middle', vertical: 'top' },
                      padding: 0
                    }
                  }}
                  sx={{ mt: 4 }}
                  bottomAxis={{
                    tickLabelStyle: {
                      angle: 45,
                      textAnchor: 'start',
                      fontSize: 12
                    }
                  }}
                />
              </Grid>

              <Grid item>
                <LineChart
                  width={700}
                  height={600}
                  series={[
                    { data: yCol4, label: 'Avg Photos', id: 'avg photos', area: true },
                    { data: yCol5, label: 'Avg Solutions', id: 'avg solutions', area: true },
                    { data: yCol6, label: 'Avg Presentations', id: 'avg presentations', area: true }
                  ]}
                  xAxis={[{ data: xRow, scaleType: 'point' }]}
                  // layout="horizontal"
                  margin={{ bottom: 300 }}
                  slotProps={{
                    legend: {
                      direction: 'row',
                      position: { horizontal: 'middle', vertical: 'top' },
                      padding: 0
                    }
                  }}
                  sx={{ mt: 4 }}
                  bottomAxis={{
                    tickLabelStyle: {
                      angle: 45,
                      textAnchor: 'start',
                      fontSize: 12
                    }
                  }}
                />
              </Grid>
            </Grid>

            <LineChart
              width={1500}
              height={600}
              series={[
                { data: yCol1, label: 'Appointments', id: 'appts' },
                { data: yCol2, label: 'Selected Project Average', id: 'project average' },
                { data: yCol3, label: 'Customer Why', id: 'customer why' },
                { data: yCol4, label: 'Avg Photos', id: 'avg photos' },
                { data: yCol5, label: 'Avg Solutions', id: 'avg solutions' },
                { data: yCol6, label: 'Avg Presentations', id: 'avg presentations' }
              ]}
              xAxis={[{ data: xRow, scaleType: 'point' }]}
              margin={{ bottom: 300 }}
              slotProps={{
                legend: {
                  direction: 'row',
                  position: { horizontal: 'middle', vertical: 'top' },
                  padding: 0
                }
              }}
              sx={{ mt: 4 }}
              bottomAxis={{
                tickLabelStyle: {
                  angle: 45,
                  textAnchor: 'start',
                  fontSize: 12
                }
              }}
            />
          </Box>
      }
    </Box>
  )
}

export default withScreenWrapper(SalesRepAveragesReport)
