import React, { useEffect, useState } from 'react'
import { Box, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Grid, Stack, Switch, TextField, Typography } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

const RightPanelProjectGoals = (props) => {
  const [formId, setFormId] = useState('')
  const [title, setTitle] = useState('')
  const [mainNav, setMainNav] = useState(true)
  const [bottomNav, setBottomNav] = useState(true)

  useEffect(() => {
    const m = JSON.parse(JSON.stringify(props.meeting))
    const goals = m.goals

    setFormId(goals.formId)
    setTitle(goals.title)
    setMainNav(goals.mainNav)
    setBottomNav(goals.bottomNav)
  }, []) // eslint-disable-line

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    const m = JSON.parse(JSON.stringify(props.meeting))
    const goals = m.goals

    if (name === 'formId') {
      const form = props.formsConfig.filter((f) => {
        return f.id === value
      })
      if (0 in form) {
        setTitle(form[0].formTitle)
        goals.title = form[0].formTitle
      }
      setFormId(value)
      goals.formId = value
    }

    if (name === 'title') {
      setTitle(value)
      goals.title = value
    }

    if (name === 'mainNav') {
      setMainNav(!mainNav)
      goals.mainNav = !goals.mainNav
    }

    if (name === 'bottomNav') {
      setBottomNav(!bottomNav)
      goals.bottomNav = !goals.bottomNav
    }

    props.setMeeting(m)
  }

  return (
    <Box style={styles.container} sx={{ p: 2 }}>
      <Stack sx={{ pb: 3 }}>
        <Typography variant='h6'>Project Goals Forms</Typography>
        <Typography variant='body2'>
          Add the default forms you want available at the beginning of appointments. Once you
          add forms, sort<br /> the forms to your preferred order.
        </Typography>
      </Stack>

      <Grid container style={{ marginBottom: '24px' }}>
        <Grid container spacing={2}>
          <Grid item xs={3} sx={{ pb: 2 }}>
            <FormControl size='small' fullWidth>
              <InputLabel id='area'>Select Form</InputLabel>
              <Select
                labelId='formId'
                id='formId'
                name='formId'
                value={formId}
                label='Form'
                onChange={handleChange}
              >
                {
                  'formsConfig' in props && props.formsConfig.length > 0 &&
                  // eslint-disable-next-line
                  props.formsConfig.map((f, idx) => {
                    if (f.formType === 'Inspect') {
                      return (
                        <MenuItem
                          key={idx}
                          value={f.id}
                          onChange={handleChange}
                        >
                          {f.formTitle}
                        </MenuItem>
                      )
                    }
                  })
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3} sx={{ pb: 2 }}>
            <TextField
              id='title'
              name='title'
              size='small'
              variant='outlined'
              label='Display Title'
              value={title}
              sx={{ 'input:focus': { border: 'none' }, width: '90%' }}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>

      <Stack sx={{ pb: 1 }}>
        <Typography variant='h6'>Project Display Preferences</Typography>
      </Stack>

      <Grid container>
        <Grid container>
          <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>Display in Left Navigation</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControlLabel
              sx={{ m: 0 }}
              name='mainNav'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='mainNav'
                  checked={!!mainNav}
                  color='primary'
                  onClick={handleChange}
                />
                            }
              labelPlacement='start'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>Display in Bottom Navigation</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControlLabel
              sx={{ m: 0 }}
              name='bottomNav'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='bottomNav'
                  checked={!!bottomNav}
                  color='primary'
                  onClick={handleChange}
                />
                            }
              labelPlacement='start'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default RightPanelProjectGoals

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'white'
  }
}
