import React, { useEffect, useState, useRef } from 'react'
import { Box, Grid, TextField, Typography, Divider, Button, InputAdornment, IconButton, Chip, Alert, Autocomplete } from '@mui/material'
import { DialogMinimum } from './DialogMinimum'
import DeleteIcon from '@mui/icons-material/Delete'
import { DialogFormSelector } from '../../../components/Reusable/DialogFormSelector'

const PriceListMinimum = (props) => {
  const [minimums, setMinimums] = useState(props.minimums)
  const [showAddForm, setShowAddForm] = useState(false)
  const [list, setList] = useState([])
  const [formsConfig, setFormsConfig] = useState([])
  const [index, setIndex] = useState('')
  const amountProjectRef = useRef(null)
  // const productProjectRef = useRef(null)
  const [errors, setErrors] = useState({
    project: {
      amount: '',
      productId: ''
    },
    areas: []
  })
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (props.formsConfig) {
      setFormsConfig(props.formsConfig)
    }

    if (!('project' in minimums)) {
      const newMinimums = JSON.parse(JSON.stringify(minimums))
      newMinimums.project = { amount: '', productId: '' }
      setMinimums(newMinimums)
    }

    if (minimums.areas.length > 0) {
      if (minimums.areas.length !== errors.areas.length) {
        const newAreas = []
        minimums.areas.forEach((item, idx) => {
          newAreas.push({ forms: [], amount: '', productId: '' })
        })
        const newErrors = JSON.parse(JSON.stringify(errors))
        newErrors.areas = newAreas
        setErrors(newErrors)
      }
    }

    let options = []
    if (props.productsList) {
      options = props.productsList.map((p) => {
        // console.log(p)
        return {
          id: p.id,
          label: p.title,
          name: p.title,
          title: p.title
        }
      })
      options = options.sort((a, b) => a.label.localeCompare(b.label))
      setOptions(options)
    }
  }, []) // eslint-disable-line

  const handleChangeProject = (e) => {
    const newMinimums = JSON.parse(JSON.stringify(minimums))
    newMinimums.project[e.target.name] = e.target.value

    const newErrors = JSON.parse(JSON.stringify(errors))
    if (newMinimums.project.amount !== '') {
      if (newMinimums.project.productId === '') {
        newErrors.project.productId = 'Required'
      } else {
        newErrors.project.productId = ''
      }
    }
    setMinimums(newMinimums)
    setErrors(newErrors)
  }

  const handleChangeProjectProduct = (e, newProd) => {
    // console.log(newProd)
    const newMinimums = JSON.parse(JSON.stringify(minimums))
    newMinimums.project.productId = newProd

    const newErrors = JSON.parse(JSON.stringify(errors))
    if (newMinimums.project.amount !== '') {
      if (newMinimums.project.productId === '' || newMinimums.project.productId.toString() === '-1') {
        newErrors.project.productId = 'Required'
      } else {
        newErrors.project.productId = ''
      }
    }

    setMinimums(newMinimums)
    setErrors(newErrors)
  }

  const addAreaMinimum = () => {
    const newMinimums = JSON.parse(JSON.stringify(minimums))
    newMinimums.areas.push({
      forms: [],
      amount: '',
      productId: ''
    })

    const newErrors = JSON.parse(JSON.stringify(errors))
    newErrors.areas.push({
      forms: [],
      amount: '',
      productId: ''
    })

    setMinimums(newMinimums)
    setErrors(newErrors)
  }

  const handleChangeAreas = (e, index) => {
    const areas = JSON.parse(JSON.stringify(minimums.areas))
    areas[index][e.target.name] = e.target.value

    const newMinimums = JSON.parse(JSON.stringify(minimums))
    newMinimums.areas = areas

    const newErrors = JSON.parse(JSON.stringify(errors))
    if (newMinimums.areas.length > 0) {
      newMinimums.areas.forEach((item, idx) => {
        if (item.amount !== '') {
          if (item.productId === '' || newMinimums.project.productId.toString() === '-1') {
            newErrors.areas[idx].productId = 'Required'
          } else {
            newErrors.areas[idx].productId = ''
          }
        }
      })
    }

    setMinimums(newMinimums)
    setErrors(newErrors)
  }

  const handleChangeAreaProduct = (e, newProd, index) => {
    // console.log(index, newProd)
    const areas = JSON.parse(JSON.stringify(minimums.areas))
    if (index !== null) {
      areas[index].productId = newProd
    }

    const newMinimums = JSON.parse(JSON.stringify(minimums))
    newMinimums.areas = areas
    // console.log(newMinimums)

    const newErrors = JSON.parse(JSON.stringify(errors))
    if (newMinimums.areas.length > 0) {
      newMinimums.areas.forEach((item, idx) => {
        if (item.amount !== '') {
          if (item.productId === '') {
            newErrors.areas[idx].productId = 'Required'
          } else {
            newErrors.areas[idx].productId = ''
          }
        }
      })
    }
    // console.log(errors)
    setMinimums(newMinimums)
    setErrors(newErrors)
  }

  const handleSave = () => {
    let requiredProject = false
    let requiredAreasProject = false
    let requiredAreasForms = false
    const newErrors = JSON.parse(JSON.stringify(errors))
    if (minimums.project.amount !== '') {
      if (minimums.project.productId === '' || minimums.project.productId.toString() === '-1') {
        newErrors.project.productId = 'Required'
        requiredProject = true
      } else {
        newErrors.project.productId = ''
        requiredProject = false
      }
    }

    if (minimums.areas.length > 0) {
      minimums.areas.forEach((item, idx) => {
        if (item.amount !== '') {
          if (item.productId === '') {
            newErrors.areas[idx].productId = 'Required'
            requiredAreasProject = true
          } else {
            newErrors.areas[idx].productId = ''
            requiredAreasProject = false
          }
        }

        // console.log(item);
        if (item.forms.length === 0) {
          newErrors.areas[idx].forms.pop()
          newErrors.areas[idx].forms.push(0)
          requiredAreasForms = true
        } else {
          newErrors.areas[idx].forms.pop()
          newErrors.areas[idx].forms.push(item.forms.length)
          requiredAreasForms = false
        }
      })
    }

    setErrors(newErrors)
    if (!requiredProject && !requiredAreasProject && !requiredAreasForms) {
      props.onSave(minimums)
    }
  }

  const handleDelete = (idx) => {
    const newMinimums = JSON.parse(JSON.stringify(minimums))
    newMinimums.areas.splice(idx, 1)
    setMinimums(newMinimums)
  }

  const handleAddForm = (idx) => {
    if (props.formsConfig) {
      if (minimums.areas[idx].forms.length > 0) {
        const newList = []
        minimums.areas[idx].forms.forEach((i, idx) => {
          const form = props.formsConfig.filter((f) => f.id === i)
          newList.push(form[0])
        })
        setList(newList)
      }
    }

    setIndex(idx)
    setShowAddForm(!showAddForm)
  }

  const handleAddFormSave = (formsList) => {
    const areas = JSON.parse(JSON.stringify(minimums.areas))
    const list = []
    formsList.forEach((f, idx) => {
      if ('formId' in f) {
        list.push(f.formId)
      }
    })
    areas[index].forms = list

    const newMinimums = JSON.parse(JSON.stringify(minimums))
    newMinimums.areas = areas

    const newErrors = JSON.parse(JSON.stringify(errors))
    if (newMinimums.areas.length > 0) {
      newMinimums.areas.forEach((item, idx) => {
        // console.log(item);
        if (item.forms.length === 0) {
          newErrors.areas[idx].forms.pop()
          newErrors.areas[idx].forms.push(0)
        } else {
          newErrors.areas[idx].forms.pop()
          newErrors.areas[idx].forms.push(item.forms.length)
        }
      })
    }

    setMinimums(newMinimums)
    setErrors(newErrors)
    setShowAddForm(!showAddForm)
  }

  const handleDeleteForm = (idxArea, idxForm) => {
    const areas = JSON.parse(JSON.stringify(minimums.areas))
    areas[idxArea].forms.splice(idxForm, 1)

    const newMinimums = JSON.parse(JSON.stringify(minimums))
    newMinimums.areas = areas
    setMinimums(newMinimums)
  }

  const getFormName = (fid) => {
    const form = props.formsConfig.filter((o) => o.id == fid) // eslint-disable-line
    if (form) {
      return form[0].formTitle
    }
  }

  const getSelectedProduct = (pid) => {
    let title = ''
    if (pid !== -1 && pid !== '') {
      const p = props.productsList.find((o) => o.id === pid)
      title = p.title
    }
    return title
  }

  const jsx = (
    <Box sx={{ pt: 3 }}>
      <Grid container>
        <Grid item xs={12} sx={{ mb: 3, ml: 2 }}>
          <Typography variant='subtitle2' style={{ fontSize: '16px' }}>Project Minimum</Typography>
          <Typography variant='body2'>
            Specify minimum and the master list product it will be associated to.
          </Typography>
        </Grid>
        <Grid item xs={12} container direction='row' spacing={2}>
          <Grid item xs={2} sx={{ ml: 2 }}>
            <TextField
              variant='outlined'
              label='Project Minimum'
              name='amount'
              size='small'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>$</InputAdornment>
                )
              }}
              onChange={handleChangeProject}
              value={'project' in minimums ? minimums.project.amount : ''}
              ref={amountProjectRef}
            />
          </Grid>
          <Grid item xs={9}>
            <Autocomplete
              id='autocomplete-project-minimum'
              name='productId'
              isOptionEqualToValue={(option, value) => {
                if (value === '') return true
                return option.title === value
              }}
              value={getSelectedProduct(minimums.project.productId)}
              size='small'
              options={options}
              onChange={(e, newValue) => {
                handleChangeProjectProduct(e, newValue && newValue.id ? newValue.id : -1)
              }}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label='Map to Product'
                  error={!!errors.project.productId}
                  helperText={<span style={{ color: '#dc3545' }}>{errors.project.productId && errors.project.productId}</span>}
                />}
              renderOption={(props, option) => {
                return (
                  <Box component='li' {...props} key={option.id}>
                    <Typography variant='body2'>{option.name}</Typography>
                  </Box>
                )
              }}
              // ref={productProjectRef}
            />
          </Grid>
        </Grid>

        <Divider style={{ width: '100%', marginBottom: '16px', marginTop: '24px' }} />

        <Grid container item xs={12} sx={{ ml: 2, mb: 3 }}>
          <Grid item xs={8}>
            <Typography variant='subtitle2' style={{ fontSize: '16px' }}>Area/Form Minimum</Typography>
            <Typography variant='body2'>
              Area minimums only apply to the forms that are selected as a part of the minimum.
              If you include more than one form, minimum applies when the included forms do not
              meet the minimum as total.
            </Typography>
          </Grid>
          <Grid
            item xs={4}
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Button
              size='large'
              variant='contained'
              onClick={addAreaMinimum}
              style={{ marginLeft: '56px' }}
            >
              Add Area Minimum
            </Button>
          </Grid>
        </Grid>

        {minimums.areas.length > 0 && minimums.areas.map((area, index) => {
          return (
            <Grid item container key={`areas-${index}`}>
              <Grid
                item xs={11} direction='row' container
                sx={{ pt: 3, pb: 3, ml: 2, mb: 2, borderRadius: '4px', border: 1, borderColor: 'rgba(0, 0, 0, 0.12)' }}
              >
                <Grid item xs={2} sx={{ ml: 2 }}>
                  <TextField
                    key={`areas-amount-${index}`}
                    variant='outlined'
                    label='Area Minimum'
                    name='amount'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                      style: {
                        height: '51px'
                      }
                    }}
                    onChange={(event) => handleChangeAreas(event, index)}
                    value={minimums.areas[index].amount}
                  />
                </Grid>
                <Grid item xs={9} sx={{ ml: 2 }}>
                  <Autocomplete
                    id='autocomplete-area-minimum'
                    name='productId'
                    isOptionEqualToValue={(option, value) => {
                      if (value === '') return true
                      return option.title === value
                    }}
                    value={getSelectedProduct(minimums.areas[index].productId)}
                    size='small'
                    options={options}
                    onChange={(e, newValue) => {
                      handleChangeAreaProduct(e, newValue && newValue.id ? newValue.id : -1, index)
                    }}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        label='Map to Product'
                        error={errors.areas[index] && !!errors.areas[index].productId}
                        helperText={<span style={{ color: '#dc3545' }}>{errors.areas[index] && errors.areas[index].productId}</span>}
                      />}
                    renderOption={(props, option) => {
                      return (
                        <Box component='li' {...props} key={option.id}>
                          <Typography variant='body2'>{option.name}</Typography>
                        </Box>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} container sx={{ ml: 2, mt: 2, mb: 2 }}>
                  <Grid item xs={2}>
                    <Button
                      size='large'
                      variant='contained'
                      onClick={() => handleAddForm(index)}
                    >
                      Add Form
                    </Button>
                  </Grid>
                  <Grid item xs={10}>
                    {minimums.areas[index].forms.length > 0 &&
                      minimums.areas[index].forms.map((f, idx) => {
                        return (
                          <Chip key={`areas-form-${idx}`} label={getFormName(f)} onDelete={() => handleDeleteForm(index, idx)} sx={{ mr: 1, mb: 1 }} />
                        )
                      })}
                    {
                      index in errors.areas &&
                      errors.areas[index].forms[0] === 0 &&
                        <Alert severity='error' sx={{ width: 'fit-content' }}>You must assign a form to area/form minimums before saving!</Alert>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sx={{
                  mb: 2,
                  ml: 1,
                  display: 'flex',
                  alignItems: 'center',
                  verticalAlign: 'middle'
                }}
              >
                <IconButton onClick={() => handleDelete(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          )
        })}

        {showAddForm &&
          <DialogFormSelector
            title='Add Forms'
              // variant={'library'}
            list={list}
            formsConfig={formsConfig}
            onSave={(l) => handleAddFormSave(l)}
            onClose={() => setShowAddForm(!showAddForm)}
          />}
      </Grid>
    </Box>
  )

  return (
    <DialogMinimum
      title='Edit Price List Minimums'
      content={jsx}
      onChange={handleSave}
      onClose={props.onClose}
    />
  )
}

export default PriceListMinimum
