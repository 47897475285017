import React from 'react'
import { Grid, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SolutionIcon from '@mui/icons-material/VerifiedUser'
import IssueIcon from '@mui/icons-material/Report'

const DrawerHeader = ({ variant, title, onClose }) => {
  let icon = <SolutionIcon style={styles.icon} />
  if (variant === 'issue') {
    icon = <IssueIcon style={styles.icon} />
  }
  return (
    <Grid container sx={{ pt: 1, pb: 1, pl: 2, pr: 1 }} style={styles.container}>
      <Grid item style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        {icon}
      </Grid>
      <Grid item sx={{ pl: 1 }} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='h6'>{title}</Typography>
      </Grid>
      <Grid item xs sx={{ pr: 0 }} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
export default DrawerHeader

const styles = {
  container: {
    borderBottom: '1px solid rgba(0,0,0,.08)'
  },
  icon: {
    color: 'rgba(0,0,0,.66)'
  }
}
