import React, { useState, useEffect } from 'react'
import { Grid, TextField, Typography, IconButton, InputAdornment } from '@mui/material'
import { LoadingPleaseWait } from '@supportworks/react-components'
import DeleteIcon from '@mui/icons-material/DeleteOutline'

const RightPanelMargin = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [product, setProduct] = useState({}) // For object manipulation
  const [productCost, setProductCost] = useState(null)
  const [markupPercentage, setMarkupPercentage] = useState(null)

  useEffect(() => {
    if (isLoading) {
      const p = JSON.parse(JSON.stringify(props.product))
      if (!p.pricingOptions) p.pricingOptions = {}
      if (!p.pricingOptions.margin) p.pricingOptions.margin = {}
      if (!p.pricingOptions.margin.productCost) {
        p.pricingOptions.margin.productCost = p.price
        setProductCost(p.price)
      }
      if (!p.pricingOptions.margin.markupPercentage) {
        p.pricingOptions.margin.markupPercentage = 0
        setMarkupPercentage(0)
      }
      setProduct(p)
      props.setProduct(p)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    setProduct(props.product)
    try {
      if (props.product.pricingOptions.margin.productCost) {
        setProductCost(props.product.pricingOptions.margin.productCost)
      }
      if (props.product.pricingOptions.margin.markupPercentage > -1) {
        setMarkupPercentage(props.product.pricingOptions.margin.markupPercentage)
      }
    } catch (err) {
      // Avoiding a crappy multi-line if conditional for the nested JSON
    }
  }, [props.product])

  const handleChange = (e) => {
    const p = JSON.parse(JSON.stringify(product))
    const name = e.target.name
    const value = e.target.value
    if (!p.pricingOptions.margin) p.pricingOptions.margin = {}
    if (value) {
      p.pricingOptions.margin[name] = Number(value)
    } else {
      if (name === 'markupPercentage') {
        p.pricingOptions.margin.markupPercentage = 0
      }
    }
    if (name === 'markupPercentage') {
      setMarkupPercentage(value)
      const pc = productCost + productCost * (value * 0.01)
      p.price = pc
    }
    setProduct(p)
    props.setProduct(p)
  }

  const handleDeleteMarkup = () => {
    const p = JSON.parse(JSON.stringify(product))
    if (p.pricingOptions.margin.markupPercentage) {
      p.pricingOptions.margin.markupPercentage = 0
    }
    p.price = productCost
    setMarkupPercentage(0)
    setProduct(p)
    props.setProduct(p)
  }

  if (isLoading) return <LoadingPleaseWait />

  let disabled = false
  if (props.variant === 'pricing' && (!product.margin_ovrd || !product.price_ovrd)) {
    disabled = true
  }

  return (
    <Grid container style={styles.gridContainer}>
      <Grid item xs={12} sx={{ p: 2, borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Typography style={styles.title}>Margin</Typography>
      </Grid>
      {props.displaySettings && (props.displaySettings.showMarginProductCost)
        ? (
          <Grid item sm={12} sx={{ p: 2 }}>
            <TextField
              id='productCost'
              disabled
              name='productCost'
              type='number'
              variant='outlined'
              label='Product Cost'
              value={productCost}
              InputProps={{
                style: styles.textField,
                startAdornment: (
                  <InputAdornment position='start'>
                    <Typography>$</Typography>
                  </InputAdornment>
                )
              }}
              onChange={handleChange}
              sx={{ 'input:focus': { border: 'none' } }}
            />
          </Grid>
          )
        : null}
      {props.displaySettings && (props.displaySettings.showMarginMarkupPercentage)
        ? (
          <Grid item sm={12} sx={{ p: 2 }}>
            <TextField
              id='markupPercentage'
              name='markupPercentage'
              disabled={disabled}
              type='number'
              variant='outlined'
              label='Markup %'
              value={markupPercentage}
              style={{ width: 155 }}
              InputProps={{
                style: styles.textField,
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton disabled={disabled} onClick={handleDeleteMarkup}>
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              onChange={handleChange}
              sx={{ 'input:focus': { border: 'none' } }}
            />
          </Grid>
          )
        : null}
    </Grid>
  )
}
export default RightPanelMargin

const styles = {
  gridContainer: {
    backgroundColor: 'white'
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  textField: {
    fontSize: 14
  }
}
