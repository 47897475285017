import React from 'react'
import { Redirect, Switch, Route, useParams, useHistory } from 'react-router-dom'
import { CompatRoute } from 'react-router-dom-v5-compat'
import Api from 'library/Api'
import CompanyScreens from 'screens/Company'
import CompanyConfig from './screens/Config'
import Company from './screens/Company'
import CompanyConfigTagDiscriminator from './screens/Config/CompanyConfigTagDiscriminator'
import { Topics } from 'config/topics'
import Permissions from 'library/Permissions'

/**
 * AppRunning()
 *
 * Used when we do not have Company/:organizationId in the URL.  This is the default route.
 *
 * @return {*}
 */
const AppRunning = () => {
  const history = useHistory()

  const handleLogout = () => {
    history.push('/')
    Topics.publish(Topics.Logout)
  }

  const renderCompanyRoute = (props) => {
    let orgId = props.match.params.organizationId
    if (orgId === ':organizationId') {
      // No organization ID was passed, see if we can infer one
      orgId = Company.getCurrentOrgId()
    }
    if (!Company.SelectIdNC(orgId)) {
      return <Redirect to='/' />
    }
    if (props.match.isExact) {
      return <Redirect to={props.match.url + '/Dashboard'} />
    }
    return <OrganizationRouter {...props} organizationId={orgId} />
  }

  return (
    <Switch>
      <CompatRoute exact path='/' component={CompanyScreens.List} />
      <CompatRoute exact path='/Logout' render={handleLogout} />
      <CompatRoute exact path='/Company' component={CompanyScreens.List} />
      <CompatRoute path='/Company/:organizationId' render={renderCompanyRoute} />
      <CompatRoute>
        {
          (...rest) => {
            console.log('140', rest)
            return null
          }
        }
      </CompatRoute>
    </Switch>
  )
}

/**
 * OrganizationRouter()
 *
 * This component is used when we have Company/:organizationId in the URL.
 *
 * @return {*}
 */
const OrganizationRouter = () => {
  const { organizationId } = useParams()

  if (!organizationId) {
    // No organization ID was passed, see if we can infer one
    const orgId = Company.getCurrentOrgId()
    if (!orgId) {
      return <Redirect to='/' />
    }
  }

  if (Permissions.hasRole('super_user') || Permissions.hasRole('admin')) {
    return (
      <Switch>
        <Route exact path='/Company/:organizationId/Configs/Publish' component={CompanyConfig.Publish} />
        <Route exact path='/Company/:organizationId/Dashboard' component={CompanyScreens.Dashboard} />
        <Route exact path='/Company/:organizationId/Configs/CompanyConfigSettings' component={CompanyScreens.CompanyConfigSettings} />
        <Route
          exact
          path='/Company/:organizationId/Configs'
          render={(props) => {
            const q = qry(props.location.search)
            const variant = q.variant ? q.variant : CompanyConfig.DEFAULT_VARIANT
            const flavor = 'Index'
            if (CompanyConfig[variant] && CompanyConfig[variant][flavor]) {
              const CfgComponent = CompanyConfig[variant][flavor]
              return <CfgComponent organizationId={Company.getCurrentOrgId()} />
            }
            return <Redirect to={{ pathname: '/' }} />
          }}
        />
        <Route
          exact
          path='/Company/:organizationId/Configs/new'
          render={(props) => {
            const q = qry(props.location.search)
            const variant = q.variant ? q.variant : CompanyConfig.DEFAULT_VARIANT
            const flavor = 'Edit'
            if (CompanyConfig[variant] && CompanyConfig[variant][flavor]) {
              const CfgComponent = CompanyConfig[variant][flavor]
              return <CfgComponent organizationId={Company.getCurrentOrgId()} />
            }
            return <Redirect to={{ pathname: '/' }} />
          }}
        />
        <Route
          exact
          path='/Company/:organizationId/Configs/:configId'
          render={(props) => {
            const q = qry(props.location.search)
            const flavor = 'Edit'
            if (q.variant) {
              const variant = q.variant ? q.variant : CompanyConfig.DEFAULT_VARIANT
              if (CompanyConfig[variant] && CompanyConfig[variant][flavor]) {
                const CfgComponent = CompanyConfig[variant][flavor]
                return <CfgComponent {...props.match.params} />
              }
            }
            return <CompanyConfigTagDiscriminator {...props.match.params} />
          }}
        />

        <Route exact path='/Company/:organizationId/Appointments' render={(props) => <Company.AppointmentsIndex {...props.match.params} />} />
        <Route exact path='/Company/:organizationId/Integrations' render={(props) => <CompanyScreens.CompanyIntegrations {...props.match.params} />} />

        <Route exact path='/Company/:organizationId/Impersonate' render={(props) => <CompanyScreens.UserImpersonate {...props.match.params} />} />
        <Route exact path='/Company/:organizationId/Impersonate/stop' render={(props) => <CompanyScreens.UserImpersonateStop {...props.match.params} />} />

        <Route exact path='/Company/:organizationId/Proposals' render={(props) => <CompanyScreens.ProposalsIndex {...props.match.params} />} />
        <Route exact path='/Company/:organizationId/Proposals/:proposalId' render={(props) => <CompanyScreens.ProposalView {...props.match.params} />} />
        <Route exact path='/Company/:organizationId/ProposalsSigned' render={(props) => <CompanyScreens.ProposalsSigned {...props.match.params} />} />

        <Route exact path='/Company/:organizationId/Reports' render={(props) => <CompanyScreens.Reports {...props.match.params} />} />
        <Route exact path='/Company/:organizationId/ReportsWIP' render={(props) => <CompanyScreens.ReportsWIP {...props.match.params} />} />
        <Route
          exact
          path='/Company/:organizationId/Reports/:reportId'
          render={(props) => {
            switch (props.match.params.reportId) {
              case 'sales-rep-adl-ads':
                return (<CompanyScreens.AppointmentsReport {...props.match.params} />)
              case 'presentations-usage':
                return (<CompanyScreens.PresentationsReport {...props.match.params} />)
              case 'solutions':
                return (<CompanyScreens.SolutionsReport {...props.match.params} />)
              case 'sales-rep-averages':
                return (<CompanyScreens.SalesRepAveragesReport {...props.match.params} />)
              default:
                break
            }
          }}
        />

        <Route exact path='/Company/:organizationId/Users' render={(props) => <CompanyScreens.UsersIndex {...props.match.params} />} />
        <Route exact path='/Company/:organizationId/Users/edit' render={(props) => <CompanyScreens.SelfUserEdit {...props.match.params} />} />
        <Route
          exact
          path='/Company/:organizationId/Users/:personId/impersonate'
          render={(props) => {
            Api.callRegisteredMethod('impersonateUser', { personId: props.match.params }).then(() => {})
            return null
          }}
        />
        {/* Catch-all route that redirects to Dashboard */}
        <Route>
          {({ location }) => {
            if (!location.pathname.endsWith('/Dashboard')) {
              return <Redirect to='/Company/:organizationId/Dashboard' />
            }
            return null
          }}
        </Route>
      </Switch>
    )
  }
  if (Permissions.hasRole('client_user')) {
    return (
      <Switch>
        <Route exact path='/Company/:organizationId/Dashboard' component={CompanyScreens.Dashboard} />
        <Route exact path='/Company/:organizationId/Appointments' render={(props) => <Company.AppointmentsIndex {...props.match.params} />} />
        <Route exact path='/Company/:organizationId/Proposals' render={(props) => <CompanyScreens.ProposalsIndex {...props.match.params} />} />
        <Route exact path='/Company/:organizationId/Proposals/:proposalId' render={(props) => <CompanyScreens.ProposalView {...props.match.params} />} />
        <Route exact path='/Company/:organizationId/ProposalsSigned' render={(props) => <CompanyScreens.ProposalsSigned {...props.match.params} />} />
        <Route exact path='/Company/:organizationId/Users/edit' render={(props) => <CompanyScreens.SelfUserEdit {...props.match.params} />} />
        {/* Catch-all route that redirects to Dashboard */}
        <Route>
          {({ location }) => {
            if (!location.pathname.endsWith('/Dashboard')) {
              return <Redirect to='/Company/:organizationId/Dashboard' />
            }
            return null
          }}
        </Route>
      </Switch>
    )
  }
  return (
    <Switch>
      <Route exact path='/Company/:organizationId/NoAccess' component={CompanyScreens.NoAccess} />
      <Route>
        {({ location }) => {
          return <Redirect to='/Company/:organizationId/NoAccess' />
        }}
      </Route>
    </Switch>
  )
}

/**
 * Processes search/query string into an object.
 *
 * @param {string} qs
 * @return {Object}
 */
function qry (qs) {
  qs = qs.replace(/^\?/, '')
  const o = {}
  for (const pairing of qs.split('&')) {
    const pair = pairing.split('=')
    if (pair.length) {
      if (pair.length === 1) {
        o[pair[0]] = true
      } else {
        o[pair[0]] = pair[1]
      }
    }
  }
  return o
}

export default AppRunning
