import React from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material'
import '../../../../css/solution-automation.css'

const DrawerRow = ({ title, subtitle, previewURL, icon, onClick }) => {
  return (
    <Grid container sx={{ borderBottom: '1px solid rgba(0,0,0,.08)' }} onClick={onClick}>
      <Grid item sx={{ pb: 1 }} style={{ display: 'flex', alignItems: 'center' }}>
        {previewURL
          ? (
            <Box style={styles.drawerRowLeft}>
              <img src={previewURL} alt='Solution preview' height={56} width={96} />
            </Box>
            )
          : (
            <Box style={styles.drawerRowLeft}>{previewURL}</Box>
            )}
      </Grid>
      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        <Stack sx={{ pl: 2 }}>
          <Typography style={styles.title}>{title}</Typography>
          <Typography style={styles.subtitle}>{subtitle}</Typography>
        </Stack>
      </Grid>
      <Grid item xs style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {icon}
      </Grid>
    </Grid>
  )
}
export default DrawerRow

const styles = {
  title: {
    fontSize: '16px',
    letterSpacing: '.15px',
    lineHeight: '22px'
  },
  subtitle: {
    color: 'rgba(0,0,0,.60)',
    fontSize: '14px',
    letterSpacing: '.25px',
    lineHeight: '20px'
  },
  drawerRowLeft: {
    float: 'left',
    width: '96px',
    height: '56px',
    marginTop: '8px',
    backgroundColor: 'rgba(0,0,0,.08)'
  }
}
