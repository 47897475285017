import React from 'react'

export class LogoMark extends React.PureComponent {
  render () {
    return (
      <div>
        <img src={require('../images/sv_logo.png')} title='SolutionView' alt='SolutionView' />
      </div>
    )
  }
}
