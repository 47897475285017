import React, { useState, useEffect, useRef } from 'react'
import Button from '@mui/material/Button'
import HelpIcon from '@mui/icons-material/Help'
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined'
import { ListItemIcon, Menu, MenuItem, TextField } from '@mui/material'
import Dialog from '@mui/material/Dialog'

function FieldSelectDialog (props) {
  const [anchorEl, setAnchorEl] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [currQuestion, setCurrentQuestion] = useState(props.currQuestion)
  const open = Boolean(anchorEl)
  const [allFields, setAllFields] = useState(props.allFields)
  const selectRef = useRef()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    if ('currQuestion' in props) {
      props.onClose()
    }

    setAnchorEl(null)
  }

  const changeHandler = (f) => {
    setAnchorEl(null)
    props.onChange(f, props.elemIdx, props.elemArr)
  }

  const saveHandlerNumerical = (numVal) => {
    setAnchorEl(null)
    props.onChange({ val: numVal, type: 'numerical' }, props.elemIdx, props.elemArr)
  }

  useEffect(() => {
    setAllFields(props.allFields)
    // eslint-disable-next-line
  }, [open])

  useEffect(() => {
    setCurrentQuestion(props.currQuestion)
    if (props.currQuestion !== undefined && ('val' in props.currQuestion || 'id' in props.currQuestion)) {
      setAnchorEl(selectRef.current)
    }
  }, [props.currQuestion])

  return (
    <>
      <Button ref={selectRef} className='field-select-dialog' onClick={handleClick} variant='outlined' startIcon={<HelpIcon />} style={styles.selectBtn}>
        Select Value
      </Button>
      <div>
        <Menu
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}
          PaperProps={{
            sx: {
              width: '350px',
              padding: '20px'
            }
          }}
        >
          <div style={styles.headerDivNum}>Select value for calculation</div>
          <NumericalValueDialog onSave={(numVal) => saveHandlerNumerical(numVal)} currVal={props.currQuestion} />
          <div style={styles.headerDiv}>Select question for calculation</div>
          <div>
            {allFields !== null &&
                            allFields !== undefined &&
                            allFields.map((f, idx) => {
                              if ('sectionTitle' in f) {
                                if ('fields' in f && f.fields.length > 0) {
                                  const found = f.fields.some((el) => el.type.match(/numeric|calc/))
                                  if (found) {
                                    return (
                                      <span style={styles.sectionHeader} key={`span-${idx}`}>
                                        {f.sectionTitle}
                                      </span>
                                    )
                                  }
                                }
                              } else {
                                if (f.type.match(/numeric|calc/)) {
                                  return (
                                    <MenuItem key={`item-${idx}`} onClick={() => changeHandler(f)} selected={props.currQuestion !== undefined && props.currQuestion.id === f.id}>
                                      <ListItemIcon>
                                        <RadioButtonUncheckedOutlinedIcon fontSize='small' />
                                      </ListItemIcon>
                                      {f.title}
                                    </MenuItem>
                                  )
                                }
                              }
                              return null
                            })}
          </div>
        </Menu>
      </div>
    </>
  )
}

const styles = {
  selectBtn: {
    fontSize: '13px',
    textTransform: 'none',
    marginLeft: '5px',
    marginRight: '5px'
    // height: "35px"
  },
  headerDivNum: {
    marginBottom: '10px',
    fontWeight: '600',
    fontSize: '15px'
  },
  headerDiv: {
    marginTop: '15px',
    marginBottom: '10px',
    fontWeight: '600',
    fontSize: '15px'
  },
  sectionHeader: {
    fontSize: '14px',
    fontWeight: '400',
    color: '#9e9e9e'
  },
  subHeader: {
    fontSize: '13px',
    color: '#000000a3',
    display: 'block',
    marginBottom: '6px'
  },
  textInput: {
    marginTop: '10px'
  },
  buttons: {
    marginTop: '15px'
  },
  cancelBtn: {
    float: 'right'
  },
  saveBtn: {
    float: 'right'
  }
}

export default FieldSelectDialog

function NumericalValueDialog (props) {
  const [open, setOpen] = useState(props.currVal !== undefined && 'val' in props.currVal)
  const [numVal, setNumVal] = useState(props.currVal !== undefined ? props.currVal.val : '')
  const [itemNumVal, setItemNumVal] = useState(props.currVal !== undefined && 'val' in props.currVal)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const changeHandler = (event) => {
    setNumVal(event.target.value)
  }

  const saveHandler = () => {
    setOpen(false)
    setItemNumVal(true)
    props.onSave(numVal)
  }

  const cancelHandler = () => {
    setOpen(false)
  }

  // console.log(numVal, open, itemNumVal);
  return (
    <div>
      <MenuItem onClick={handleClickOpen} selected={itemNumVal}>
        <ListItemIcon>
          <RadioButtonUncheckedOutlinedIcon fontSize='small' />
        </ListItemIcon>
        numerical value
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: '20px'
          }
        }}
      >
        <span style={styles.header}>Set Numerical Value</span>
        <span style={styles.subHeader}>This value will be set against the value before or after it</span>
        <TextField
                    // key={"numValCalc"}
          style={styles.textInput}
          id='filled-basic'
          size='small'
          variant='filled'
          onChange={changeHandler}
          value={numVal}
          fullWidth
        />
        <div style={styles.buttons}>
          <Button style={styles.saveBtn} size='small' onClick={saveHandler}>
            Save
          </Button>
          <Button style={styles.cancelBtn} size='small' onClick={cancelHandler}>
            Cancel
          </Button>
        </div>
      </Dialog>
    </div>
  )
}
