import FieldCanvas from './FieldCanvas'
import FieldSignature from './FieldSignature'
import FieldInitial from './FieldInitial'
import FieldText from './FieldText'

const Field = (props) => {
  if (props.field.type === 'signature') {
    return <FieldSignature {...props} />
  } else if (props.field.type === 'initials') {
    return <FieldInitial {...props} />
  } else if (props.field.type === 'text') {
    return <FieldText {...props} />
  } else {
    return <FieldCanvas {...props} />
  }
}
export default Field

export const HelperHLine = (props) => {
  // let y1 = props.pdfHeight - props.dimensions.height - 5; // 5 = offset for field bullet number
  let y1 = props.pdfHeight - props.field.height * props.zoom
  // Fudge needed to match checkmark
  if (props.field.source && props.field.source.display === 'checkmark') {
    y1 = y1 + 12 * props.zoom
  }
  return (
    <div style={{ position: 'absolute', bottom: props.y + 4, left: 0 }}>
      <svg width={props.pdfWidth} height={props.pdfHeight}>
        <line x1={0} y1={y1} x2={props.pdfWidth} y2={y1} stroke='rgba(0,0,0,.36)' strokeDasharray='2, 2' strokeWidth='1' shapeRendering='crispEdges' />
      </svg>
    </div>
  )
}

export const HelperVLine = (props) => {
  const x1 = 0
  return (
    <div style={{ position: 'absolute', top: 0, left: props.x }}>
      <svg width={props.pdfWidth} height={props.pdfHeight}>
        <line x1={x1} y1={0} x2={x1} y2={props.pdfHeight} stroke='rgba(0,0,0,.66)' strokeDasharray='2, 2' strokeWidth='2' shapeRendering='crispEdges' />
      </svg>
    </div>
  )
}

export const Connector = (props) => {
  if (props.x1 && props.y1 && props.x2 && props.y2 && props.pdfWidth && props.pdfHeight) {
    return (
      <div style={{ position: 'absolute', bottom: 0, left: 0, translate: `scale:${props.zoom || 1}` }}>
        <svg width={props.pdfWidth} height={props.pdfHeight}>
          <line
            x1={props.x1}
            y1={props.y1}
            x2={props.x2}
            y2={props.y2}
            stroke='rgba(0,0,0,.66)'
            strokeDasharray='1, 1'
            strokeWidth='1'
            shapeRendering='crispEdges'
          />
        </svg>
      </div>
    )
  } else {
    return <></>
  }
}

export const Vertice = (props) => {
  return (
    <div style={{ position: 'absolute', top: props.top, left: props.left }} onMouseDown={props.onMouseDown}>
      <svg width={12} height={12}>
        <circle cx={6} cy={6} r={3} fill={props.fill} stroke={props.fill} />
      </svg>
    </div>
  )
}

export const calculateOffset = (x, y, zoom, variant) => {
  let fx = -12
  let fy = -12
  if (variant && variant.match(/selection/)) {
    fx = -6
    fy = -6
  } else if (variant === 'signature') { // p/RJ remove offset for sig
    fx = 0
    fy = 0
  } else if (variant === 'child') { // p/RT shift up and right for dates on sigs
    fx = -12
    fy = -6
  }
  const offsetX = (x + fx) * zoom // left right
  const offsetY = (y + fy) * zoom // up down
  return [offsetX, offsetY]
}

export const calculateAnchor = (x, y, width, height, pdfWidth, pdfHeight, zoom) => {
  if (x && y && width && height) {
    const xPos = x + (width / 2) * zoom
    const yPos = pdfHeight - y
    return { x: xPos, y: yPos }
  }
}
