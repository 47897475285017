/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import CompanyConfigEdit from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'

const config = {
  title: 'Default Configuration',
  variant: '__root',
  tag: '__root',
  name: 'Default Configuration',
  lockTag: true,
  lockName: true
}

export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}
