import React, { useEffect, useState } from 'react'
import { Box, FormControlLabel, Switch, Typography } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

const RightPanelPresentVersion3 = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [promptToViewProducts, setPromptToViewProducts] = useState(false)
  const [enforcePresentations, setEnforcePresentations] = useState(false)

  useEffect(() => {
    if (isLoading) {
      const m = JSON.parse(JSON.stringify(props.meeting))

      if ('promptToViewProducts' in m) {
        setPromptToViewProducts(m.promptToViewProducts)
      } else {
        m.promptToViewProducts = false
        props.setMeeting(m)
      }

      if ('enforcePresentations' in m.findings[0]) {
        setEnforcePresentations(m.findings[0].enforcePresentations)
      } else {
        m.findings[0].enforcePresentations = false
        props.setMeeting(m)
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (e) => {
    const name = e.target.name
    const m = JSON.parse(JSON.stringify(props.meeting))

    if (name === 'promptToViewProducts') {
      m.promptToViewProducts = !promptToViewProducts
      setPromptToViewProducts(!promptToViewProducts)
    }

    if (name === 'enforcePresentations') {
      m.findings[0].enforcePresentations = !enforcePresentations
      setEnforcePresentations(!enforcePresentations)
    }

    props.setMeeting(m)
  }

  return (
    <>
      <Box style={styles.container} sx={{ p: 2 }}>
        <Typography variant='h6'>View Products Alert</Typography>
        <Typography variant='body2'>
          When this feature is enabled, at the end of each presentation, there will be an alert that pops up that <br />prompts the rep to review products.
        </Typography>

        <Box sx={{ pb: 2, pt: 2 }}>
          <FormControlLabel
            sx={{ m: 0 }}
            name='promptToViewProducts'
            control={
              <Switch
                checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                id='promptToViewProducts'
                checked={!!promptToViewProducts}
                color='primary'
                onClick={handleChange}
              />
                        }
            label={<Typography style={styles.textField}>Prompt User to View Products</Typography>}
            labelPlacement='start'
            onChange={handleChange}
          />
        </Box>

        <Typography variant='h6'>Require Solutions to be Selected in Findings</Typography>
        <Typography variant='body2'>
          When this feature is enabled, solutions will be required to be selected in the Findings Details to be <br />included in the presentation.
        </Typography>

        <Box sx={{ pb: 2, pt: 2 }}>
          <FormControlLabel
            sx={{ m: 0 }}
            name='enforcePresentations'
            control={
              <Switch
                checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                id='enforcePresentations'
                checked={!!enforcePresentations}
                color='primary'
                onClick={handleChange}
              />
                        }
            label={<Typography style={styles.textField}>Require Solutions be Selected in Findings</Typography>}
            labelPlacement='start'
            onChange={handleChange}
          />
        </Box>
      </Box>
    </>
  )
}

export default RightPanelPresentVersion3

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'white'
  },
  textField: {
    fontSize: 14
  }
}
