import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material'
import DataGrid from 'components/Reusable/DataGrid'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DrawerInitialProjects from './components/DrawerInitialProjects'

const RightPanelInitialProjects = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState([])
  const [showAddSolutionsDrawer, setShowAddSolutionsDrawer] = useState(false)
  const [projectConfig, setProjectConfig] = useState({})
  const [editIndex, setEditIndex] = useState(null)

  useEffect(() => {
    if (isLoading) {
      if (props.meeting.initialProjects) {
        setList(props.meeting.initialProjects)
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (l) => {
    const m = JSON.parse(JSON.stringify(props.meeting))
    m.initialProjects = l
    props.setMeeting(m)
    setList(l)
  }

  const handleAddSolutions = () => {
    setShowAddSolutionsDrawer(!showAddSolutionsDrawer)
  }

  const handleEdit = (idx) => {
    setEditIndex(idx)
    setProjectConfig(list[idx])
    setShowAddSolutionsDrawer(!showAddSolutionsDrawer)
  }

  const handleDelete = (idx) => {
    const r = window.confirm('Are you sure you want to delete?')
    if (r) {
      const l = JSON.parse(JSON.stringify(list))
      l.splice(idx, 1)
      handleChange(l)
    }
  }

  const handleMoveUp = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx - 1, 0, ele)
    handleChange(l)
  }

  const handleMoveDown = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx + 1, 0, ele)
    handleChange(l)
  }

  const handleDone = (project) => {
    console.log(project)
    const m = JSON.parse(JSON.stringify(props.meeting))
    const newList = JSON.parse(JSON.stringify(list))

    if (editIndex === null) {
      newList.push(project)
    } else {
      newList[editIndex] = project
    }

    m.initialProjects = newList
    setList(newList)
    setProjectConfig({})
    setEditIndex(null)
    setShowAddSolutionsDrawer(!showAddSolutionsDrawer)
    props.setMeeting(m)
  }

  const handleClose = () => {
    setEditIndex(null)
    setProjectConfig({})
    setShowAddSolutionsDrawer(!showAddSolutionsDrawer)
  }

  const columns = [
    { field: 'id', headerName: 'Id' },
    { field: 'title', headerName: 'Title', flex: 2, editable: true, sortable: false },
    { field: 'solutions', headerName: 'Solutions', flex: 1, sortable: false },
    {
      field: 'defaultStatus',
      headerName: 'Default Status',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Box sx={{ textTransform: 'capitalize' }}>{params.row.defaultStatus}</Box>
          )
        }
      }
    },
    {
      width: 128,
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton size='small' onClick={() => handleEdit(params.row.id)}>
                  <EditIcon fontSize='small ' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id)}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton size='small' disabled={!(params.row.id < list.length - 1)} onClick={() => handleMoveDown(params.row.id)}>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < list.length; x++) {
    rows.push({
      id: x,
      title: list[x].name,
      solutions: list[x].solutions.accepted.length + list[x].solutions.declined.length + list[x].solutions.hidden.length,
      defaultStatus: list[x].solutions.recommended,
      actions: ''
    })
  }

  return (
    <>
      <Box style={styles.container} sx={{ p: 2 }}>
        <Stack sx={{ mb: 2 }}>
          <Typography variant='h6'>Default Projects</Typography>
          <Typography variant='body2'>
            <p style={styles.p}>
              Each appointment can be defined with preset projects, tailored to your workflow needs. This feature
              allows you to establish a default project count for every new appointment.
            </p>
            <p style={styles.p}>
              Customize each project with a name and how solutions will be included, as either accepted, declined, or hidden.
            </p>
          </Typography>
        </Stack>

        <Box>
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            autosizeOptions={{
              columns: ['action'],
              includeOutliers: true,
              includeHeaders: false
            }}
          />
        </Box>
        <Box sx={{ pt: 2 }}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              handleAddSolutions()
            }}
          >
            Add Project
          </Button>
        </Box>
      </Box>
      {
        showAddSolutionsDrawer &&
          <DrawerInitialProjects
            {...props}
            projectConfig={projectConfig}
            open={showAddSolutionsDrawer}
            onClose={() => handleClose()}
            onDone={(project) => handleDone(project)}
          />
      }
    </>
  )
}

export default RightPanelInitialProjects

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'white'
  },
  p: {
    marginBottom: '8px'
  }
}
