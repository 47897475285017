import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, CardMedia, Checkbox, Divider, FormControlLabel, FormGroup, Grid, Paper, Stack, TextField, Typography } from '@mui/material'
import AdminIcon from '@mui/icons-material/AdminPanelSettings'
import { FormContainer, TextFieldElement } from 'react-hook-form-mui'
import { Redirect } from 'react-router-dom'
import Api from 'library/Api'
import { FooterRightPanel } from 'components/Reusable/Footer'
import Company from 'screens/Company'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import { getAssetInfo } from 'screens/Config/CompanyConfigPresentations'
import { LoadingPleaseWait } from '@supportworks/react-components'
import Permissions from 'library/Permissions'
import SelectorWithPreview from 'components/Reusable/SelectorWithPreview'
import { DialogSignature, convertTextToBase64 } from '@supportworks/react-components-input'
// import { DialogSignature } from 'local_npm/react-components-input/src'
// import { convertTextToBase64 } from 'local_npm/react-components-input/src/components/Signature/TypingBox'

const UserEdit = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [hasSaved, setHasSaved] = useState(false)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [manifest, setManifest] = useState({})
  const [user, setUser] = useState()
  const [id, setId] = useState(-1)
  const [assetsConfig, setAssetConfig] = useState([])
  const [error, setError] = useState('')
  const [showDialogSignatures, setShowDialogSignatures] = useState(false)
  const [showDialogInitials, setShowDialogInitials] = useState(false)

  // Misc user variables
  const [nameFirst, setNameFirst] = useState('')
  const [nameLast, setNameLast] = useState('')
  const [username, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [emailProposals, setEmailProposals] = useState('')
  const [phoneWork, setPhoneWork] = useState('')
  const [phoneMobile, setPhoneMobile] = useState('')
  const [title, setTitle] = useState('')
  const [bio, setBio] = useState('')
  const [asset, setAsset] = useState('')
  const variant = props.variant ? props.variant : 'admin'

  useEffect(() => {
    if (isLoading) {
      setManifest(props.authManifest)
      let id = -1
      if (props.selectedIndex > -1 && props.users && props.users.length > 0) {
        id = props.users[props.selectedIndex].person.id
      }
      setId(id)
      if (id === -1) {
        const u = {
          person: {
            nameFirst: '',
            nameLast: '',
            email: '',
            username: ''
          },
          details: {
            integrations: {}
          },
          authorizations: {
            rippleAdmin: {
              roles: {},
              permissions: {},
              libraries: JSON.parse(JSON.stringify(props.authManifest.libraries)).map((lib) => { lib.access = true; return lib })
            }
          }
        }
        if (props.permissionDefaults !== undefined) {
          u.authorizations.rippleAdmin = props.permissionDefaults
          u.authorizations.rippleAdmin.libraries = JSON.parse(JSON.stringify(props.authManifest.libraries))
        }
        // Am I passing in a CRM user?
        if (props.crmUser && props.crmUser.userName) {
          u.person.nameFirst = props.crmUser.firstName // Don't blame Sean
          u.person.nameLast = props.crmUser.lastName
          u.person.username = props.crmUser.userName
          u.person.email = props.crmUser.userName
          setNameFirst(props.crmUser.firstName)
          setNameLast(props.crmUser.lastName)
          setUserName(props.crmUser.userName)
        }
        setUser(u)
        setIsLoading(false)
      } else {
        Api.callRegisteredMethod('getUser', { person: id }).then((user) => {
          // Auto cleanup stuff
          if (!user.authorizations || user.authorizations.constructor === Array) {
            user.authorizations = {}
          }
          if (!user.authorizations.rippleAdmin) user.authorizations.rippleAdmin = {}
          if (!user.authorizations.rippleAdmin.roles) user.authorizations.rippleAdmin.roles = {}
          if (Array.isArray(user.authorizations.rippleAdmin.roles)) user.authorizations.rippleAdmin.roles = {}
          if (!user.authorizations.rippleAdmin.permissions) user.authorizations.rippleAdmin.permissions = {}
          if (Array.isArray(user.authorizations.rippleAdmin.permissions)) user.authorizations.rippleAdmin.permissions = {}
          if (!user.authorizations.rippleAdmin.libraries) user.authorizations.rippleAdmin.libraries = []
          if (!user.details) {
            user.details = {}
          }
          if (!user.details.integrations || Array.isArray(user.details.integrations)) {
            user.details.integrations = {}
          }
          setUser(user)
          if (user.person) {
            setNameFirst(user.person.nameFirst)
            setNameLast(user.person.nameLast)
            setUserName(user.person.email)
          }
          if (user.details) {
            setEmailProposals(user.details.emailProposals)
            setPhoneWork(user.details.phoneWork)
            setPhoneMobile(user.details.phoneMobile)
            setTitle(user.details.title)
            setBio(user.details.bio)
            setAsset(user.details.asset)
          }
          Api.callRegisteredMethod('getAssetList', {}).then((assetsConfig) => {
            assetsConfig = assetsConfig.sort((a, b) => (a.name > b.name ? 1 : -1))
            setAssetConfig(assetsConfig)
            setIsLoading(false)
          })
        })
      }
    }
    // eslint-disable-next-line
  }, [])

  const handleChange = (e) => {
    const u = JSON.parse(JSON.stringify(user))
    const name = e.target.name
    const value = e.target.value
    if (name === 'nameFirst') {
      setNameFirst(value)
      u.person.nameFirst = value
    }
    if (name === 'nameLast') {
      setNameLast(value)
      u.person.nameLast = value
    }
    if (name === 'username') {
      setUserName(value)
      u.person.username = value
      u.person.email = value
    }
    if (name === 'password') {
      if (password !== undefined) {
        setPassword(value)
        u.person.password = value
      }
    }
    if (name.match(/emailProposals|bio|asset|phoneWork|phoneMobile|title/)) {
      u.details[name] = value
    }
    setUser(u)
  }

  const handleChangeManifest = (variant, name, value) => {
    const u = JSON.parse(JSON.stringify(user))
    if (variant === 'role') {
      u.authorizations.rippleAdmin.roles[name] = value
    } else if (variant === 'perm') {
      u.authorizations.rippleAdmin.permissions[name] = value
      // if the name is sign_proposal, check to see if there is a user.details.signature and user.details.initials.
      // If there is not a signature, create a signature using the nameFirst, nameList by calling convertTextToBase64('nameFirst nameLast', 'Allura', 568, 112)
      // If there is not an initial, take the first letter of the nameFirst and nameLast and call convertTextToBase64('FL', 'Allura', 568, 112).
      if (name === 'sign_proposal') {
        if (!u.details.signatures || !u.details.signatures.length) {
          const base64 = convertTextToBase64(`${u.person.nameFirst} ${u.person.nameLast}`, 'Allura', 568, 112)
          u.details.signatures = [{ base64Encoded: base64, variant: 'draw' }]
        }
        if (!u.details.initials || !u.details.initials.length) {
          // If I have a hyphenated last name, I want it in the format "F LL" for the initials.
          // Otherwise, it will be regular initials.
          let initials = `${u.person.nameFirst.charAt(0)}${u.person.nameLast.charAt(0)}`
          if (u.person.nameLast.indexOf('-') > -1) {
            initials = `${u.person.nameFirst.charAt(0)} ${u.person.nameLast.charAt(0)}${u.person.nameLast.charAt(u.person.nameLast.indexOf('-') + 1)}`
          }
          const base64 = convertTextToBase64(initials, 'Allura', 568, 112)
          u.details.initials = [{ base64Encoded: base64, variant: 'draw' }]
        }
      }
    }

    setUser(u)
  }

  const handleChangeIntegrations = (app, value) => {
    const u = JSON.parse(JSON.stringify(user))
    const i = u.details.integrations
    i[app] = value
    setUser(u)
  }

  const handleChangeLibrary = (id, value) => {
    const u = JSON.parse(JSON.stringify(user))
    const libraries = u.authorizations.rippleAdmin.libraries
    const index = libraries.findIndex((o) => o.libraryId === id)
    if (index > -1) {
      libraries[index].access = value
    }
    setUser(u)
  }

  const handleChangeAsset = (asset) => {
    const u = JSON.parse(JSON.stringify(user))
    u.details.asset = asset.id
    setUser(u)
    setAsset(asset.id)
  }

  const handleRemoveAsset = () => {
    const u = JSON.parse(JSON.stringify(user))
    delete u.details.asset
    setUser(u)
    setAsset(null)
  }

  const handleSave = () => {
    setIsSaving(true)
    if (Permissions.hasPermission('add_users')) {
      if ('id' in user.person) {
        Api.callRegisteredMethod('saveUser', { user }).then(() => {
          if (user.person.password !== undefined && user.person.password === '') {
            delete user.person.password
          }
          setIsSaving(false)
        })
      } else {
        Api.callRegisteredMethod('newUser', { user }).then((rsp) => {
          if (!rsp || (rsp && rsp.status === 'error')) {
            setError(rsp.error !== undefined ? JSON.stringify(rsp.error) : 'Some error occurred on newUser API')
            setIsSaving(false)
          } else {
            setHasSaved(true)
            setIsSaving(false)
          }
        })
      }
    } else {
      setIsSaving(false)
    }
    props.onSave && props.onSave(user)
  }

  const handleSetShowDialogSignatures = () => {
    setShowDialogSignatures(!showDialogSignatures)
  }

  const handleSetShowDialogInitials = () => {
    setShowDialogInitials(!showDialogInitials)
  }

  const handleSubmitSignatures = ({ variant, obj }) => {
    handleSetShowDialogSignatures()
    const u = JSON.parse(JSON.stringify(user))
    delete u.details.signature
    if (!Array.isArray(u.details.signatures)) {
      u.details.signatures = []
    }
    /* FUTURE?  In case we want to store "draw" and "typed"
    const index = u.details.signatures.findIndex(sig => sig.variant === variant)
    if (index > -1) {
      u.details.signatures[index] = { ...obj, variant }
    } else {
      u.details.signatures.push({ ...obj, variant })
    }
    */
    u.details.signatures[0] = { ...obj, variant } // stomp on 0
    setUser(u)
    console.log('Signature submitted', obj)
  }

  const handleSubmitInitials = ({ variant, obj }) => {
    handleSetShowDialogInitials()
    const u = JSON.parse(JSON.stringify(user))
    if (!Array.isArray(u.details.initials)) {
      u.details.initials = []
    }
    /* FUTURE
    const index = u.details.initials.findIndex(sig => sig.variant === variant)
    if (index > -1) {
      u.details.initials[index] = { ...obj, variant }
    } else {
      u.details.initials.push({ ...obj, variant })
    }
    */
    u.details.initials[0] = { ...obj, variant } // stomp on 0
    setUser(u)
    console.log('Initials submitted', obj)
  }

  const handleDeleteSignature = () => {
    const u = JSON.parse(JSON.stringify(user))
    delete u.details.signatures
    setUser(u)
  }

  const handleDeleteInitials = () => {
    const u = JSON.parse(JSON.stringify(user))
    delete u.details.initials
    setUser(u)
  }

  if (hasSaved) {
    const currentCompany = Company.getCurrent()
    return <Redirect to={{ pathname: '/Company/' + currentCompany.id + '/Users' }} />
  }

  if (isLoading) return <LoadingPleaseWait />

  let assetInfo = null
  if (asset) {
    const a = getAssetInfo(asset, assetsConfig)
    if (a && a.previewURL) {
      assetInfo = a
    }
  }

  let hasLibraries = false
  if (user.authorizations.rippleAdmin && user.authorizations.rippleAdmin.libraries && user.authorizations.rippleAdmin.libraries.length > 0) {
    hasLibraries = true
  }
  if (manifest?.libraries?.length > 0) {
    hasLibraries = true
  }

  return (
    <Box sx={{ pb: 2 }}>
      {error
        ? (
          <Alert variant='filled' severity='error'>
            {error}
          </Alert>
          )
        : null}
      <Paper elevation={0} sx={{ p: 2 }}>
        <FormContainer
          defaultValues={{
            username,
            emailProposals
          }}
          onSuccess={(data) => {
            handleSave()
          }}
        >
          <Grid container>
            <Grid item xs={6} sx={{ pb: 2, pr: 2 }}>
              <TextFieldElement
                disabled={id !== -1}
                id='username'
                name='username'
                label='Username'
                fullWidth
                value={username || ''}
                onChange={handleChange}
              />
            </Grid>
            {Permissions.hasPermission('add_users')
              ? (
                <>
                  <Grid item xs={3}>
                    <TextField id='password' fullWidth label='Change Password' defaultValue={password || ''} name='password' type={showPassword ? 'text' : 'password'} onChange={handleChange} />
                  </Grid>
                  <Grid item xs={3} sx={{ pl: 1, pt: 1 }}>
                    <FormControlLabel
                      key='show-password'
                      control={<Checkbox id='showPassword' name='showPassword' onChange={() => setShowPassword(!showPassword)} checked={showPassword} />}
                      label={<Typography variant='body2'>Show Password</Typography>}
                    />
                  </Grid>
                </>
                )
              : null}

            <Grid item xs={6} sx={{ pr: 2, mr: 2, mb: 2 }}>
              <TextFieldElement
                id='emailProposals'
                name='emailProposals'
                label='Email for Proposals'
                fullWidth
                value={emailProposals || ''}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} sx={{ pb: 2, pr: 2 }}>
              <TextField id='nameFirst' fullWidth label='First Name' defaultValue={nameFirst} name='nameFirst' onChange={handleChange} />
            </Grid>
            <Grid item xs={6}>
              <TextField id='nameLast' fullWidth label='Last Name' defaultValue={nameLast} name='nameLast' onChange={handleChange} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} sx={{ pb: 2, pr: 2 }}>
              <TextField id='phoneWork' fullWidth label='Work Phone' defaultValue={phoneWork} name='phoneWork' onChange={handleChange} />
            </Grid>
            <Grid item xs={6}>
              <TextField id='phoneMobile' fullWidth label='Cell Phone' defaultValue={phoneMobile} name='phoneMobile' onChange={handleChange} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6} sx={{ pr: 2, pb: { xs: 2, sm: 0 } }}>
              <TextField id='title' sx={{ pb: 2 }} fullWidth label='Title' defaultValue={title} name='title' onChange={handleChange} />
              <TextField id='bio' fullWidth multiline rows={4} label='Bio' defaultValue={bio} name='bio' onChange={handleChange} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container sx={{ height: '100%' }}>
                <Grid item xs={5} sx={{ border: '1px dotted rgba(0,0,0,.32)', background: 'rgba(0,0,0,.02)' }}>
                  <Grid container sx={{ height: '100%' }} alignItems='center' justifyContent='center'>
                    {assetInfo && assetInfo.previewURL
                      ? (
                        <Grid item>
                          <a href={assetInfo.path} target='_blank' rel='noreferrer'>
                            <CardMedia component='img' sx={{ objectFit: 'contain' }} image={assetInfo.previewURL} alt={assetInfo.previewURL} />
                          </a>
                        </Grid>
                        )
                      : null}
                  </Grid>
                </Grid>
                <Grid item xs={7} sx={{ pl: 2 }}>
                  <Typography variant='body2' sx={{ pb: 2 }}>
                    <code style={{ color: 'rgba(0,0,0,.60)' }}>{assetInfo && assetInfo.title} </code>
                  </Typography>
                  <Box sx={{ display: 'flex', button: { flex: 'none' } }}>
                    <Button variant='contained' sx={{ mr: 1 }} onClick={() => setShowAddDialog(!showAddDialog)}>
                      Edit
                    </Button>
                    <Button variant='contained' onClick={handleRemoveAsset}>
                      Remove
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {variant === 'admin'
            ? (
              <>
                <Divider sx={{ pt: 2, pb: 2 }} />
                <Box sx={{ pt: 2 }}>
                  <Typography variant='h5'>Access</Typography>
                </Box>
                <Grid container>
                  <Grid item xs={12}>
                    <Manifest user={user} manifest={manifest} onChange={handleChangeManifest} />
                  </Grid>
                </Grid>
              </>
              )
            : null}

          {user.authorizations?.rippleAdmin?.permissions?.sign_proposal
            ? (
              <>
                <Divider sx={{ pt: 1, pb: 2 }} />
                <Box sx={{ pt: 2 }}>
                  <Stack>
                    <Typography variant='h5'>Signature and Initial</Typography>
                    <Typography variant='body2'>Set your signature and initial that will be used during automated company representative signature processes.</Typography>
                  </Stack>
                </Box>
                <Grid container sx={{ pt: 2 }}>
                  {/** Give me a button that will call handleSetShowDialogSignatures  */}
                  <Grid item xs={6} sx={{ pb: 2, pr: 2 }}>
                    {user.details?.signatures?.[0]?.base64Encoded && (
                      <Box sx={{ pt: 2, textAlign: 'center', border: '1px solid rgba(0,0,0,.32)' }}>
                        <img
                          src={user.details.signatures[0].base64Encoded} alt='User Signature'
                          style={{ maxWidth: '100%', height: 'auto' }}
                        />
                      </Box>
                    )}
                    <Button variant='contained' sx={{ mt: 1 }} onClick={handleSetShowDialogSignatures}>Set Signature</Button>
                    {Permissions.hasRole('super_user') && user.details.signatures
                      ? (
                        <Button
                          color='error'
                          variant='contained'
                          onClick={handleDeleteSignature}
                          startIcon={<AdminIcon />}
                          sx={{ mt: 1, ml: 1 }}
                        >
                          <Typography>Delete</Typography>
                        </Button>
                        )
                      : null}
                  </Grid>
                  <Grid item xs={6} sx={{ pb: 2, pr: 2 }}>
                    {user.details?.initials?.[0]?.base64Encoded && (
                      <Box sx={{ pt: 2, border: '1px solid rgba(0,0,0,.32)' }}>
                        <img
                          src={user.details.initials[0].base64Encoded} alt='User Signature'
                          style={{ maxWidth: '100%', height: 'auto' }}
                        />
                      </Box>
                    )}
                    <Button variant='contained' sx={{ mt: 1 }} onClick={handleSetShowDialogInitials}>Set Initials</Button>
                    {Permissions.hasRole('super_user') && user.details.initials
                      ? (
                        <Button
                          color='error'
                          variant='contained'
                          onClick={handleDeleteInitials}
                          startIcon={<AdminIcon />}
                          sx={{ mt: 1, ml: 1 }}
                        >
                          <Typography>Delete</Typography>
                        </Button>
                        )
                      : null}
                  </Grid>
                </Grid>

                <DialogSignature
                  open={showDialogSignatures}
                  variant='signature'
                  showRemember={false}
                  onSubmit={handleSubmitSignatures}
                  submitText='Submit'
                  onClose={handleSetShowDialogSignatures}
                />

                <DialogSignature
                  open={showDialogInitials}
                  variant='initials'
                  showRemember={false}
                  onSubmit={handleSubmitInitials}
                  submitText='Submit'
                  onClose={handleSetShowDialogInitials}
                />
              </>
              )
            : null}

          {variant === 'admin' && hasLibraries
            ? (
              <>
                <Divider sx={{ pt: 2 }} />
                <Box sx={{ pt: 2 }}>
                  <Stack>
                    <Typography variant='h6'>Libraries</Typography>
                    <Typography variant='body'>Please select all of the libraries that this user can access.</Typography>
                  </Stack>
                  <Libraries user={user} manifest={manifest} onChange={handleChangeLibrary} />
                </Box>
              </>
              )
            : null}

          {variant === 'admin'
            ? (
              <>

                <Divider sx={{ pt: 2 }} />
                <Box sx={{ pt: 2 }}>
                  <Typography variant='h5'>Integrations</Typography>
                  <Integrations user={user} onChange={handleChangeIntegrations} />
                </Box>
              </>
              )
            : null}

          <Box sx={{ mt: 8 }}>
            <FooterRightPanel onCancel={props.onCancel} useFormSubmit isSaving={isSaving} />
          </Box>
        </FormContainer>
      </Paper>

      {showAddDialog
        ? (
          <DialogGeneric
            title='Select Presentation Asset'
            content={
              <SelectorWithPreview
                selectedConfig={user && user.details ? user.details : null}
                fieldName='asset'
                config={assetsConfig}
                assetType={['Image']}
                onUpdate={(asset) => {
                  handleChangeAsset(asset)
                }}
              />
          }
            fullWidth
            onChange={() => {
              setShowAddDialog(false)
            }}
            onClose={() => setShowAddDialog(false)}
          />
          )
        : null}
    </Box>
  )
}
export default UserEdit

const Manifest = ({ user, manifest, onChange }) => {
  const roleManifest = manifest.roleManifest
  const permissionManifest = manifest.permissionManifest
  const categorizedPermissions = manifest.defaultCategorizedPermissions
  return (
    <Box sx={{ pt: 2 }}>
      {roleManifest
        ? (
          <>
            <FormGroup>
              {Object.values(roleManifest).map((role, idx) => {
                let hasRole = false
                if (user.authorizations.rippleAdmin.roles[role.key]) {
                  hasRole = user.authorizations.rippleAdmin.roles[role.key]
                }
                return (
                  <FormControlLabel
                    key={`role-${idx}`}
                    control={<Checkbox id={role.key} name={role.key} onChange={() => onChange('role', role.key, !hasRole)} checked={hasRole} />}
                    label={role.label}
                  />
                )
              })}
            </FormGroup>
            <Grid container>
              {Object.values(roleManifest).map((role, idx) => {
                let hasRole = false
                if (user.authorizations.rippleAdmin.roles[role.key]) {
                  hasRole = user.authorizations.rippleAdmin.roles[role.key]
                }
                // Super user override
                if (user.authorizations.rippleAdmin.roles.super_user) {
                  hasRole = true
                }
                if (hasRole && categorizedPermissions[role.key]) {
                  return (
                    <Grid item key={`role-sub-${idx}`} xs={12} md={6}>
                      <Box sx={{ pt: 2, pb: 2 }}>
                        <Stack>
                          <Typography variant='h6'>{role.label}</Typography>
                          <Typography variant='body1'>{role.description}</Typography>
                        </Stack>
                      </Box>
                      <Box>
                        <FormGroup>
                          {/** Loop categorizedPermissions, since the data isn't organized by admin vs client */}
                          {Object.values(categorizedPermissions[role.key]).map((perm, idx2) => {
                            if (permissionManifest[perm]) {
                              if (permissionManifest[perm].editability && permissionManifest[perm].editability.roles) {
                                const roles = permissionManifest[perm].editability.roles
                                let hasRequiredRole = false
                                for (let x = 0; x < roles.length; x++) {
                                  if (user.authorizations.rippleAdmin.roles[roles[x]]) hasRequiredRole = true
                                }
                                if (!hasRequiredRole) return null
                              }

                              let hasPerm = false
                              if (user.authorizations.rippleAdmin.permissions[perm]) hasPerm = true
                              const label = permissionManifest[perm].label
                              return (
                                <FormControlLabel
                                  key={`perm-${idx}-${idx2}`}
                                  control={<Checkbox id={perm} name={perm} onChange={() => onChange('perm', perm, !hasPerm)} checked={hasPerm} />}
                                  label={label}
                                />
                              )
                            }
                            return null
                          })}
                        </FormGroup>
                      </Box>
                    </Grid>
                  )
                }
                return null
              })}
            </Grid>
          </>
          )
        : (
          <Typography variant='body2'>No roles defined.</Typography>
          )}
    </Box>
  )
}

const Libraries = ({ user, manifest, onChange }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [libraries, setLibraries] = useState([])

  useEffect(() => {
    if (isLoading) {
      if (user?.authorizations?.rippleAdmin?.libraries?.length > 0) {
        setLibraries(user.authorizations.rippleAdmin.libraries)
      } else {
        if (manifest?.libraries?.length > 0) {
          setLibraries(manifest.libraries.map((lib) => { lib.access = true; return lib }))
        }
      }
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleChange = (id, access) => {
    const libs = JSON.parse(JSON.stringify(libraries))
    const index = libs.findIndex((o) => o.libraryId === id)
    libs[index].access = access
    setLibraries(libs)
    onChange(id, access)
  }

  if (isLoading) return <LoadingPleaseWait />

  if (!libraries.length) {
    return <Typography variant='body2'>This organization does not have libraries.</Typography>
  }

  return (
    <Box sx={{ pt: 2 }}>
      <FormGroup>
        {libraries.map((library, idx) => {
          let hasAccess = false
          if (library.access !== undefined) {
            hasAccess = library.access
          }
          return (
            <FormControlLabel
              key={`library-${idx}`}
              control={
                <Checkbox id={`lib-${library.libraryId}`} name='library' onChange={() => handleChange(library.libraryId, !hasAccess)} checked={hasAccess} />
              }
              label={library.libraryName}
            />
          )
        })}
      </FormGroup>
    </Box>
  )
}

const Integrations = ({ user, onChange }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [config, setConfig] = useState(null)
  const [integrations, setIntegrations] = useState({})

  useEffect(() => {
    if (isLoading) {
      setIntegrations(user.details.integrations)
      Api.callRegisteredMethod('getLatestConfigByTag', { tag: 'integrations', startsWith: false }).then((c) => {
        if (c && c.contentJson) {
          setConfig(JSON.parse(c.contentJson))
        }
        setIsLoading(false)
      })
    }
    // eslint-disable-next-line
  }, [])

  const handleChange = (e, app) => {
    const value = e.target.value
    onChange(app, value)
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <Box sx={{ pt: 2 }}>
      {!config
        ? (
          <Typography variant='body2'>There are no integrations.</Typography>
          )
        : (
          <>
            {config.map((app, idx) => {
              const email = integrations[app.app] || ''
              if (app.app.match(/zapier|gpsinsight/i)) { // RT6413
                return null
              }
              return (
                <Grid key={`app-${idx}`} container sx={{ pb: 2 }}>
                  <Grid item xs={12} sx={{ pb: 2 }}>
                    <Typography>{app.app} User</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField id='email' fullWidth label='Email' defaultValue={email} name='email' onChange={(e) => handleChange(e, app.app)} />
                  </Grid>
                </Grid>
              )
            })}
          </>
          )}
    </Box>
  )
}
