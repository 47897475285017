import React, { useEffect, useState } from 'react'
import { Box, FormControl, InputLabel, ListItem, ListItemButton, ListItemText, MenuItem, Paper, Select, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import Api, { ApiBase } from 'library/Api'
import CompanyConfigEdit from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import { LoadingPleaseWait } from '@supportworks/react-components'
import RightPanelCategories from './Products/RightPanelCategories'
import RightPanelProperties from './Products/RightPanelProperties'
import RightPanelWarranties from './Products/RightPanelWarranties'
import RightPanelMaterialLabor from './Products/RightPanelMaterialLabor'
import RightPanelMaterialMarkup from './Products/RightPanelMaterialMarkup'
import RightPanelContractGroups from './Products/RightPanelContractGroups'
import { DialogGeneric } from '../../components/Reusable/DialogGeneric'

const config = {
  title: 'Product & Material Features',
  variant: 'productFeatures',
  tag: 'productFeatures',
  name: 'Features',
  lockTag: true,
  lockName: true,
  lockTitle: true
}

const CompanyConfigProductFeatures = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [productFeatures, setProductFeatures] = useState({})
  const [originalSettings, setOriginalSettings] = useState({})
  const [showDialog, setShowDialog] = useState(false)
  const [variant, setVariant] = useState(null)
  const [measurementSystem, setMeasurementSystem] = useState('metric')
  const [displaySettings, setDisplaySettings] = useState({})

  useEffect(() => {
    if (props.config && props.config.contentJson) {
      // Auto fix, if my contentJson is set to an array, which it SHOULD NOT because this config is a hash with keys.
      let cj = props.config.contentJson
      if (cj && cj.match(/^\[/)) {
        cj = '{}'
      }
      const pf = JSON.parse(cj)
      // Cpre forces arrays on every element inside of productFeatures.  This is a hack to embrace that.
      if (pf.settings?.[0]?.drawingOptions?.measurementSystem) {
        setMeasurementSystem(pf.settings[0].drawingOptions?.measurementSystem)
      } else {
        pf.settings = [{ drawingOptions: { measurementSystem: 'imperial' } }]
        setMeasurementSystem('imperial')
        if (props.onChange) {
          props.onChange(JSON.stringify(pf))
        }
      }
      setProductFeatures(pf)
      setOriginalSettings(pf)

      Api.callRegisteredMethod('getLatestConfigByTag', { tag: 'displaySettings', startsWith: false }).then(c => {
        if (c && c.contentJson) {
          setDisplaySettings(JSON.parse(c.contentJson))
          setIsLoading(false)
        } else {
          setIsLoading(false)
        }
      })
    }
  }, []) // eslint-disable-line

  const handleChange = async () => {
    if (props.onChange) {
      props.onChange(JSON.stringify(productFeatures), variant)
    }
  }

  const handleChangeSetting = (name, value) => {
    const pf = JSON.parse(JSON.stringify(productFeatures))
    if (!pf.settings) {
      pf.settings = [{ drawingOptions: {} }]
    }
    pf.settings[0].drawingOptions[name] = value
    if (name === 'measurementSystem') {
      setMeasurementSystem(value)
    }
    setProductFeatures(pf)
    if (props.onChange) {
      props.onChange(JSON.stringify(pf))
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <Box>
      <Paper key='Paper-Product' elevation={1} spacing={1} sx={{ mb: 2, p: 2 }}>
        <Typography variant='h6' sx={{ pb: 2 }}>
          Product
        </Typography>

        <ListItem
          id='feature-cat'
          style={styles.listItem}
          onClick={() => {
            setShowDialog(true)
            setVariant('categories')
          }}
          disablePadding
        >
          <ListItemButton role={undefined}>
            <ListItemText>Categories</ListItemText>
            <KeyboardArrowRightIcon fontSize='small' />
          </ListItemButton>
        </ListItem>
        <ListItem
          id='feature-prop'
          style={styles.listItem}
          onClick={() => {
            setShowDialog(true)
            setVariant('properties')
          }}
          disablePadding
        >
          <ListItemButton role={undefined}>
            <ListItemText>Properties</ListItemText>
            <KeyboardArrowRightIcon fontSize='small' />
          </ListItemButton>
        </ListItem>
        <ListItem
          id='feature-war'
          style={styles.listItem}
          onClick={() => {
            setShowDialog(true)
            setVariant('warranties')
          }}
          disablePadding
        >
          <ListItemButton role={undefined}>
            <ListItemText>Warranties</ListItemText>
            <KeyboardArrowRightIcon fontSize='small' />
          </ListItemButton>
        </ListItem>

        <ListItem
          id='feature-contract-groups'
          style={styles.listItem}
          onClick={() => {
            setShowDialog(true)
            setVariant('contractGroups')
          }}
          disablePadding
        >
          <ListItemButton role={undefined}>
            <ListItemText>Contract Groups</ListItemText>
            <KeyboardArrowRightIcon fontSize='small' />
          </ListItemButton>
        </ListItem>

      </Paper>

      {displaySettings && displaySettings.productEditor?.showAssemblies
        ? (
          <Paper key='Paper-ML' elevation={1} spacing={1} sx={{ mb: 2, p: 2 }}>
            <Typography variant='h6' sx={{ pb: 2 }}>
              Materials & Labor
            </Typography>

            <ListItem id='feature-materials' style={styles.listItem} disablePadding component={Link} to={ApiBase.resolvePath('/Company/:organizationId/Configs?variant=materials')}>
              <ListItemButton role={undefined}>
                <ListItemText>Materials</ListItemText>
                <KeyboardArrowRightIcon style={styles.icon1} fontSize='small' />
              </ListItemButton>
            </ListItem>

            <ListItem
              id='feature-labor'
              style={styles.listItem}
              onClick={() => {
                setShowDialog(true)
                setVariant('labor')
              }}
              disablePadding
            >
              <ListItemButton role={undefined}>
                <ListItemText>Labor</ListItemText>
                <KeyboardArrowRightIcon fontSize='small' />
              </ListItemButton>
            </ListItem>
            <ListItem
              id='feature-markup'
              style={styles.listItem}
              onClick={() => {
                setShowDialog(true)
                setVariant('markup')
              }}
              disablePadding
            >
              <ListItemButton role={undefined}>
                <ListItemText>Markup</ListItemText>
                <KeyboardArrowRightIcon fontSize='small' />
              </ListItemButton>
            </ListItem>
          </Paper>
          )
        : null}

      {showDialog && variant === 'categories'
        ? (
          <DialogGeneric
            title='Edit Product Categories'
            content={<RightPanelCategories productFeatures={productFeatures} />}
            fullScreen
            showBackArrow
            onChange={() => {
              handleChange()
              setShowDialog(false)
            }}
            onClose={() => {
              setProductFeatures(originalSettings)
              setShowDialog(false)
            }}
          />
          )
        : null}

      {showDialog && variant === 'properties'
        ? (
          <DialogGeneric
            title='Edit Product Properties'
            content={<RightPanelProperties productFeatures={productFeatures} />}
            fullScreen
            showBackArrow
            onChange={() => {
              handleChange()
              setShowDialog(false)
            }}
            onClose={() => {
              setProductFeatures(originalSettings)
              setShowDialog(false)
            }}
          />
          )
        : null}

      {showDialog && variant === 'warranties'
        ? (
          <DialogGeneric
            title='Edit Product Warranties'
            content={<RightPanelWarranties productFeatures={productFeatures} />}
            fullScreen
            showBackArrow
            onChange={() => {
              handleChange()
              setShowDialog(false)
            }}
            onClose={() => {
              setProductFeatures(originalSettings)
              setShowDialog(false)
            }}
          />
          )
        : null}

      {showDialog && variant === 'contractGroups'
        ? (
          <DialogGeneric
            title='Edit Contract Groups'
            content={<RightPanelContractGroups productFeatures={productFeatures} />}
            fullScreen
            showBackArrow
            onChange={() => {
              handleChange()
              setShowDialog(false)
            }}
            onClose={() => {
              setProductFeatures(originalSettings)
              setShowDialog(false)
            }}
          />
          )
        : null}

      {showDialog && variant === 'labor'
        ? (
          <DialogGeneric
            title='Edit Material Labor'
            content={<RightPanelMaterialLabor productFeatures={productFeatures} />}
            fullScreen
            showBackArrow
            onChange={() => {
              handleChange()
              setShowDialog(false)
            }}
            onClose={() => {
              setProductFeatures(originalSettings)
              setShowDialog(false)
            }}
          />
          )
        : null}

      {showDialog && variant === 'markup'
        ? (
          <DialogGeneric
            title='Edit Material Markup'
            content={<RightPanelMaterialMarkup productFeatures={productFeatures} />}
            fullScreen
            showBackArrow
            onChange={() => {
              handleChange()
              setShowDialog(false)
            }}
            onClose={() => {
              setProductFeatures(originalSettings)
              setShowDialog(false)
            }}
          />
          )
        : null}

      <Paper key='Paper-DO' elevation={1} spacing={1} sx={{ mb: 2, p: 2 }}>
        <Typography variant='h6' sx={{ pb: 2 }}>
          Drawing Options
        </Typography>

        {/* Dropdown for selecting measurement system */}
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Measurement System</InputLabel>
          <Select value={measurementSystem} onChange={e => handleChangeSetting('measurementSystem', e.target.value)}>
            <MenuItem value='metric'>Metric</MenuItem>
            <MenuItem value='imperial'>Imperial</MenuItem>
          </Select>
        </FormControl>
      </Paper>
    </Box>
  )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Edit: props => <CompanyConfigEdit {...config} {...props} editorComponent={CompanyConfigProductFeatures} />,
  Index: props => <CompanyConfigIndex {...config} {...props} />
}

const styles = {
  container: {
    marginTop: 16
  },
  listItem: {
    marginBottom: 16,
    backgroundColor: '#FAFAFA',
    color: 'black'
  },
  icon2: {
    color: 'rgba(0,0,0,.24)'
  }
}
