import React from 'react'
import { Button, Box, Grid, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { Redirect, Link } from 'react-router-dom'
import * as dayjs from 'dayjs'
import Api from 'library/Api'
import ApiBase from '../../library/Api'
import Company from 'screens/Company'
import DataGrid from 'components/Reusable/DataGrid'
import { DialogSettingsProduct } from './Products/DialogSettingsProduct'
import { LoadingOverlay } from '../../components/Reusable'
import { LoadingPleaseWait } from '@supportworks/react-components'
import Permissions from '../../library/Permissions'
import withScreenWrapper from '../withScreenWrapper'
import Helper from '@supportworks/helper'

class CompanyConfigIndex extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: true,
      isDeleting: false,
      isCloning: false,
      showProductSettings: false,
      configList: props.configList ? props.configList : null,
      tag: props.tag ? props.tag : null,
      name: props.name ? props.name : null,
      startsWith: !!props.startsWith,
      variant: props.variant ? props.variant : null,
      title: props.title ? props.title : 'Configuration',
      lockName: !!props.lockName,
      readonly: false
    }
    if (!Permissions.canAccessScreen(props.tag, 'write')) {
      this.state.readonly = true
    }
    this.handleClone = this.handleClone.bind(this)
  }

  componentDidMount () {
    Api.callRegisteredMethod('getConfigListByTag', { tag: this.state.tag, startsWith: this.state.startsWith }).then((configList) => {
      this.setState({
        configList,
        isLoading: false
      })
      LoadingOverlay.hide()
    })
  }

  handleDelete (configId) {
    const r = window.confirm('Are you sure you want to permanently delete this configuration?')
    if (!r) return
    this.setState(
      {
        isDeleting: true
      },
      function () {
        Api.callRegisteredMethod('deleteConfig', {
          configId
        }).then((rsp) => {
          Api.callRegisteredMethod('getConfigListByTag', { tag: this.state.tag, startsWith: this.state.startsWith }).then((configList) => {
            this.setState({
              configList,
              isDeleting: false
            })
          })
        })
      }
    )
  }

  async handleClone (data) {
    try {
      let rsp = await Api.callRegisteredMethod('getConfigById', { configId: data.configId })
      if (rsp.status !== 'success') {
        throw new Error('Could not clone.')
      }

      const config = {
        tag: this.props.tag,
        title: this.props.title,
        name: 'Copy of ' + rsp.data[0].name,
        contentJson: rsp.data[0].contentJson,
        importJson: ''
      }

      if (this.props.tag === 'appointment_type') {
        const meetingConfig = JSON.parse(rsp.data[0].contentJson)

        if (meetingConfig.types.length > 0) {
          meetingConfig.types.forEach((m, idx) => {
            m.id = this.props.organizationId + '-' + Helper.guid()
            if ('library' in m) {
              delete m.library
            }
            if ('status' in m) {
              delete m.status
            }
            if ('changedByUser' in m) {
              delete m.changedByUser
            }
          })
        }

        delete config.contentJson
        config.contentJson = JSON.stringify(meetingConfig)
      }

      rsp = await Api.callRegisteredMethod('saveConfig', {
        organizationId: this.props.organizationId,
        configId: undefined,
        ...config
      })

      if (rsp.status !== 'success') {
        throw new Error('Encountered an error cloning.')
      }

      const configList = await Api.callRegisteredMethod('getConfigListByTag', { tag: this.state.tag, startsWith: this.state.startsWith })
      this.setState({
        configList,
        isCloning: false
      })
    } catch (err) {
      this.setState({ isCloning: false })
      throw new Error('Encountered an error getting clone.')
    }
  }

  render () {
    const cRef = this
    if (this.state.isCloning) {
      return (
        <div className='CompanyConfigIndex'>
          <Company.Header title={this.state.title} />
          {this.props.tag === 'pricing' && <LoadingPleaseWait message='Copying Pricing List' />}
          {this.props.tag === 'appointment_type' && <LoadingPleaseWait message='Copying Meeting' />}
        </div>
      )
    }
    if (this.state.isDeleting) {
      return (
        <div className='CompanyConfigIndex'>
          <Company.Header title={this.state.title} />
          <LoadingPleaseWait message='Deleting, Please Wait' />
        </div>
      )
    }
    if (this.state.isLoading) {
      return (
        <div className='CompanyConfigIndex'>
          <Company.Header title={this.state.title} />
          <LoadingPleaseWait />
        </div>
      )
    }
    if (this.state.lockName) {
      if (this.state.configList != null) {
        if (this.state.configList.length === 0) {
          return (
            <>
              <div className='CompanyConfigIndex'>
                <Company.Header title={this.state.title} />
                <LoadingPleaseWait />
              </div>
              <Redirect to={ApiBase.resolvePath('/Company/:organizationId/Configs/new?variant=' + this.state.variant, this.props)} />
            </>
          )
        } else {
          for (let i = 0; i < this.state.configList.length; i++) {
            if (this.state.configList[i].status !== 'Deleted') {
              return (
                <>
                  <div className='CompanyConfigIndex'>
                    <Company.Header title={this.state.title} />
                    <LoadingPleaseWait />
                  </div>
                  <Redirect
                    to={ApiBase.resolvePath(
                      '/Company/:organizationId/Configs/' + this.state.configList[i].configId + '?variant=' + this.state.variant,
                      this.props
                    )}
                  />
                </>
              )
            }
          }
          return (
            <>
              <div className='CompanyConfigIndex'>
                <Company.Header title={this.state.title} />
                <LoadingPleaseWait />
              </div>

              <Redirect to={ApiBase.resolvePath('/Company/:organizationId/Configs/new?variant=' + this.state.variant, this.props)} />
            </>
          )
        }
      }
    }

    const columns = [
      {
        flex: 1,
        type: 'string',
        field: 'name',
        headerName: 'Name',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'status',
        headerName: 'Status',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'updated',
        headerName: 'Updated',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        field: 'action',
        headerName: 'Actions',
        headerAlign: 'right',
        align: 'right',
        editable: false,
        sortable: false,
        disableExport: true,
        renderCell: (params) => {
          if (params.row) {
            const c = params.row.config
            return (
              <Grid container wrap='nowrap' justifyContent='flex-end'>
                <Grid item>
                  <Link to={ApiBase.resolvePath('/Company/:organizationId/Configs/' + c.configId + '?variant=' + cRef.state.variant, c)}>
                    <IconButton title='Edit' aria-label='edit'>
                      <EditIcon fontSize='small' />
                    </IconButton>
                  </Link>
                </Grid>
                {this.props.canClone
                  ? (
                    <IconButton
                      title='Copy'
                      aria-label='copy'
                      onClick={() => {
                        this.setState({ isCloning: true })
                        this.handleClone(c)
                      }}
                    >
                      <ContentCopyIcon variant='icon' fontSize='small' />
                    </IconButton>
                    )
                  : null}
                {this.state.readonly
                  ? null
                  : (
                    <Grid item>
                      <IconButton
                        title='Delete'
                        aria-label='delete'
                        onClick={() => {
                          this.handleDelete(params.row.configId)
                        }}
                      >
                        <DeleteIcon variant='icon' fontSize='small' />
                      </IconButton>
                    </Grid>
                    )}
              </Grid>
            )
          }
        }
      }
    ]

    const rows = []
    for (let x = 0; x < this.state.configList.length; x++) {
      const c = this.state.configList[x]
      if (c.status === 'Deleted') continue
      let modDate = '-'
      if (c.modified) {
        modDate = dayjs(c.modified.date).format('YYYY-MM-DD h:mm A')
      } else if (c.created) {
        modDate = dayjs(c.created.date).format('YYYY-MM-DD h:mm A')
      }
      rows.push({
        id: x,
        configId: c.configId,
        name: c.name,
        status: c.status,
        updated: modDate,
        config: c
      })
    }

    return (
      <Box>
        <Company.Header title={this.state.title}>
          {this.state.readonly
            ? null
            : (
              <Link to={ApiBase.resolvePath('/Company/:organizationId/Configs/new?variant=' + this.state.variant, this.props)}>
                <Button id='add-action' className='mui-btn' color='success' variant='contained' startIcon={<AddIcon />}>
                  {this.state.title}
                </Button>
              </Link>
              )}
        </Company.Header>

        <DataGrid
          rows={rows}
          columns={columns}
          showToolbar
          showSearch
          onShowSettings={this.props.showProductSettings
            ? () => {
                if (this.props.showProductSettings) {
                  this.setState({ showProductSettings: !this.state.showProductSettings })
                }
              }
            : null}
        />
        {rows.length === 0 ? <Box sx={{ p: 2 }}>No configurations have been defined yet. Please add one.</Box> : null}

        {this.state.showProductSettings
          ? (
            <DialogSettingsProduct
              organizationId={this.props.organizationId}
              onClose={displaySettings => {
                this.setState({ displaySettings, showProductSettings: !this.state.showProductSettings })
              }}
            />
            )
          : null}
      </Box>
    )
  }
}

export default withScreenWrapper(CompanyConfigIndex)
