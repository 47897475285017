import React, { useState, useEffect } from 'react'
import Config from 'config'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { Link, Redirect } from 'react-router-dom'
import Api from 'library/Api'
import DataGrid from 'components/Reusable/DataGrid'
import { LoadingPleaseWait } from '@supportworks/react-components'
import LocalStorage from 'library/LocalStorage'
import withScreenWrapper from '../withScreenWrapper'

/**
 * Displays a list of companies with which the user is directly affiliated.
 *
 * @return {*}
 */
const CompanySelectFromList = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [myOrgs, setMyOrgs] = useState([])
  const [currentOrgId, setCurrentOrgId] = useState(null)

  useEffect(() => {
    // DEV-5590
    Api.callRegisteredMethod('stopImpersonation').then((response) => {
      const fetchedOrgs = LocalStorage.retrieve(Config.myOrgsKey)
      fetchedOrgs.sort((a, b) => a.name.localeCompare(b.name))
      setMyOrgs(fetchedOrgs)

      const fetchedCurrentOrgId = LocalStorage.retrieve(Config.currentOrgId)
      setCurrentOrgId(fetchedCurrentOrgId)
      setIsLoading(false)
    })
  }, [])

  if (myOrgs.length === 1) {
    return <Redirect to={{ pathname: '/Company/' + myOrgs[0].id }} />
  }

  if (isLoading) {
    return <LoadingPleaseWait />
  }

  const columns = [
    {
      field: 'orgId',
      headerName: 'Id',
      headerAlign: 'right',
      editable: false,
      sortable: true,
      width: 1,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <Typography variant='body2'>{params.row.orgId}</Typography>
              </Grid>
            </Grid>
          )
        }
      }
    },
    {
      field: 'name',
      headerName: 'Organization Name',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Link to={'/Company/' + params.row.orgId} style={{ textDecoration: 'none' }}>
              <Typography variant='body2' style={{ fontWeight: currentOrgId === params.row.orgId ? 'bold' : null }}>
                {params.row.name}
                {params.row.workspace ? ' (' + params.row.workspace + ')' : null}
                {currentOrgId === params.row.orgId ? ' (Current)' : null}
              </Typography>
            </Link>
          )
        }
      }
    },
    {
      field: 'fromSupport',
      headerName: 'Support',
      headerAlign: 'left',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: (params) => {
        if (params.row) {
          const fromSupportText = params.row.fromSupport === true ? params.row.fromSupportOrg : ''
          const workspace = (params.row.fromSupport === true && params.row.workspace) ? (' (' + params.row.workspace + ')') : ''
          return (
            <Grid container wrap='nowrap' justifyContent='flex-start'>
              <Grid item>
                <Tooltip title={fromSupportText} arrow>
                  <Typography
                    variant='body2'
                    noWrap
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    {fromSupportText}{workspace}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < myOrgs.length; x++) {
    rows.push({
      id: x,
      name: myOrgs[x].name,
      orgId: myOrgs[x].id,
      workspace: myOrgs[x].workspace,
      fromSupport: myOrgs[x].fromSupport === true,
      fromSupportOrg: myOrgs[x].fromSupportOrg
    })
  }

  return (
    <>
      <Box sx={{ p: 2, borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Typography style={styles.title}>
          Select Company
        </Typography>
      </Box>

      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
          columns={columns}
          rows={rows}
          defaultPageSize={100}
          density='compact'
          onRowClick={(params) => {
            if (params.row) {
              setCurrentOrgId(params.row.orgId)
              LocalStorage.store(Config.currentOrgId, params.row.orgId)
            }
          }}
        />
      </Box>
    </>
  )
}
export default withScreenWrapper(CompanySelectFromList)

const styles = {
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  }
}
