import React, { useState, useEffect } from 'react'
import { Box, Button, Chip, FormControlLabel, Grid, IconButton, Stack, Switch, TextField, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Company from 'screens/Company'
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import H from '../../../library/helper'
import { LoadingPleaseWait } from '@supportworks/react-components'

const RightPanelAdvancedFeatures = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [product, setProduct] = useState({}) // For object manipulation
  const [list, setList] = useState([])
  const [customQuantityLabel, setCustomQuantityLabel] = useState('') // State to capture the label
  const [clause, setClause] = useState(props.product.productClause)

  useEffect(() => {
    if (isLoading) {
      const p = JSON.parse(JSON.stringify(props.product))
      // Product id's that are included at no extra cost to this product go in the includes array
      if (!p.includes) {
        p.includes = []
      }
      if (p.customerDisplay === undefined) {
        p.customerDisplay = true
      }
      setProducts(props.products) // All products that can be incuded
      setProduct(p) // this product that we're configuring
      setList(p.includes) // The product inclusion list
      setCustomQuantityLabel(p.customQuantityLabel || '')
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = ({ list }) => {
    const p = JSON.parse(JSON.stringify(product))
    p.includes = list
    setProduct(p) // Update the local copy
    props.setProduct(p) // Update the parent
    setList(list)
  }

  const handleChangeFeature = (event) => {
    const { name, value } = event.target
    const p = JSON.parse(JSON.stringify(product))
    const c = JSON.parse(JSON.stringify(clause))

    if (name === 'customQuantityLabel') {
      p[name] = value
      setCustomQuantityLabel(value)
    } else if (name === 'customerDisplay') {
      p[name] = event.target.checked
    }

    if (event.target.id === 'productClause') {
      c[event.target.name] = event.target.value
      p.productClause = c
    }
    setProduct(p) // Update the local copy
    setClause(c)
    props.setProduct(p) // Update the parent
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <Box sx={{ p: 2, backgroundColor: '#fff' }}>
      <Box sx={{ pb: 2 }}>
        <Typography style={styles.title}>{props.title || 'Included Products'}</Typography>
      </Box>
      <Box style={styles.container} sx={{ pb: 2 }}>
        <Typography variant='body2'>
          When a product is added below, if it is added to the project, it will be marked as "included" and will not be charged individually.
        </Typography>
      </Box>
      <Box style={styles.container}>
        <DataGridProducts {...props} list={list} products={products} onChange={handleChange} />
      </Box>

      <Grid container sx={{ pt: 2, pb: 2 }}>
        <Grid item xs={12}>
          <Box>
            <Typography sx={{ pb: 1, fontWeight: 'bold' }}>Custom Quantity Display</Typography>
          </Box>
          <Box>
            <Typography variant='body2' sx={{ pb: 1 }}>
              Customize the display of the quantities of your product on all customer facing screens in the app and on the generated PDFs.  One common use-case is for
              customers that are proposing products that have an estimated quantity use this feature.  It allows for the company to display something like "1" instead
              of the estimated quantity.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={{ pb: 3 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            label='Display Quantity'
            name='customQuantityLabel'
            variant='outlined'
            fullWidth
            value={customQuantityLabel}
            onChange={handleChangeFeature}
          />
        </Grid>
      </Grid>

      {/** Only show on master list */}
      {props.variant.match(/^(master)/i)
        ? (
          <Grid container sx={{ pb: 3 }}>
            <Grid item xs={12}>
              <Box>
                <Typography sx={{ pb: 1, fontWeight: 'bold' }}>Display for Customer</Typography>
              </Box>
              <Box>
                <Typography variant='body2' sx={{ pb: 1 }}>
                  By choosing to hide this product from the customer, it will not appear in the Project Review and Project Comparison Screens,
                  or the Customer Contract PDF. The product will still display in Project Setup, and on the Job Information PDF.
                </Typography>
                <Typography variant='body2' sx={{ pb: 1 }}>
                  This feature is not recommended if you display itemized pricing in the Project Review and Project Comparison Screens, or the Customer Contract PDF.
                </Typography>
                <Typography variant='body2' sx={{ pb: 2, fontWeight: 'bold' }}>
                  If the product is not displayed, the Project Total will include the product, but the displayed itemized pricing will not add up to the Project Total.
                </Typography>
              </Box>
              <Box>
                <FormControlLabel
                  sx={{ m: 0, p: 0 }}
                  control={
                    <Switch
                      checked={product.customerDisplay}
                      onChange={handleChangeFeature}
                      name='customerDisplay'
                    />
              }
                  label={<Typography style={styles.textField}>Display for Customer</Typography>}
                  labelPlacement='start'
                />
              </Box>
            </Grid>
          </Grid>
          )
        : null}

      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ pb: 2 }}>
            <Typography sx={{ pb: 1, fontWeight: 'bold' }}>Product Clause</Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={{ pb: 2 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            size='small'
            id='productClause'
            label='Title'
            name='title'
            variant='outlined'
            fullWidth
            value={clause.title}
            onChange={handleChangeFeature}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ pb: 2 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            size='small'
            label='Description'
            id='productClause'
            name='body'
            variant='outlined'
            fullWidth
            multiline
            rows={8}
            value={clause.body}
            onChange={handleChangeFeature}
          />
        </Grid>
      </Grid>

      <Box>
        <Typography variant='h6' sx={{ pb: 2, fontSize: '16px' }}>Codes</Typography>
        <Typography variant='h6' sx={{ fontSize: '15px', color: '#495057db' }}>$qty$</Typography>
        <Typography sx={{ pb: 2, fontSize: '15px', color: '#808080cc' }}>
          Add the text above into the description to have the quantity from the proposal print<br />
          into the description.
        </Typography>

        <Typography variant='h6' sx={{ fontSize: '15px', color: '#495057db' }}>$price$</Typography>
        <Typography sx={{ pb: 2, fontSize: '15px', color: '#808080cc' }}>
          Add the text above into the description to have the itemized price from the proposal<br /> print into the description.
        </Typography>
      </Box>

      <Box>
        <Typography variant='h6' sx={{ pb: 2, fontSize: '16px' }}>Example</Typography>
        <Typography variant='h6' sx={{ fontSize: '15px', color: '#495057db' }}>Admin</Typography>
        <Typography sx={{ pb: 2, fontSize: '15px', color: '#808080cc' }}>
          If amount installed exceeds $qty$ lbs, there will be an additional charge of $$price$/lb.
        </Typography>

        <Typography variant='h6' sx={{ fontSize: '15px', color: '#495057db' }}>Proposal</Typography>
        <Typography sx={{ fontSize: '15px', color: '#808080cc' }}>
          If amount installed exceeds 68.2 lbs, there will be an additional charge of $2.00/lb.
        </Typography>
      </Box>
    </Box>
  )
}
export default RightPanelAdvancedFeatures

export const DataGridProducts = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [pageSize, setPageSize] = useState(10)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [list, setList] = useState([])

  useEffect(() => {
    setList(props.list)
    setIsLoading(false)
  }, [props.list])

  const handleAdd = (l) => {
    handleChange(l)
  }

  const handleChange = (l) => {
    setList(l)
    props.onChange({ list: l })
  }

  const handleDelete = (idx) => {
    const r = window.confirm('Are you sure you want to delete?')
    if (r) {
      const l = JSON.parse(JSON.stringify(list))
      l.splice(idx, 1)
      handleChange(l)
    }
  }

  const handleMoveUp = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx - 1, 0, ele)
    handleChange(l)
  }

  const handleMoveDown = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx + 1, 0, ele)
    handleChange(l)
  }

  const columns = [
    { field: 'id', headerName: 'Id', hide: true },
    /* { field: 'productId', headerName: 'Product Id', hide: true }, */
    {
      field: 'title',
      headerName: 'Product',
      flex: 2,
      editable: false,
      sortable: true,
      renderCell: (params) => {
        return (
          <Box sx={{ pl: 1 }}>
            <strong>{params.row.title}</strong>
          </Box>
        )
      }
    },
    {
      field: 'price',
      headerName: 'Master List Price',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: (params) => {
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>${Number(params.value).toFixed(2)}</Box>
      }
    },
    {
      field: 'library',
      headerName: 'Library',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: (params) => {
        return (
          <Box>{params.row.library}</Box>
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id)}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton size='small' disabled={!(params.row.id < list.length - 1)} onClick={() => handleMoveDown(params.row.id)}>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < list.length; x++) {
    const p = props.products.find((o) => o.id === list[x])

    let libraryName
    if (p.library) {
      libraryName = p.library.libraryName
    } else {
      const currentCompany = Company.getCurrent()
      libraryName = currentCompany.name
    }

    rows.push({
      id: x,
      productId: list.id,
      title: p.title,
      price: p.price,
      library: libraryName,
      actions: ''
    })
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Box>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false
              }
            }
          }}
        />
      </Box>
      <Box sx={{ pt: 2, pb: 2 }}>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            setShowAddDialog(!showAddDialog)
          }}
        >
          Add Product
        </Button>
      </Box>

      {showAddDialog
        ? (
          <DialogProductSelector
            title='Select Products'
            list={list}
            products={props.products}
            onSave={(l) => {
              handleAdd(l)
              setShowAddDialog(!showAddDialog)
            }}
            onClose={() => setShowAddDialog(!showAddDialog)}
          />
          )
        : null}
    </>
  )
}

export const DialogProductSelector = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [pageSize, setPageSize] = useState(10)
  const [filterChips, setFilterChips] = useState([]) // Array of unique library names and selected boolean status for filtering the display
  const [rows, setRows] = useState([]) // Array of row objects that can be selected
  const [selectedRows, setSelectedRows] = useState([]) // Array of row idx's of things that have been checked
  const [filteredRows, setFilteredRows] = useState([]) // Array of row objects filtered thru filterChips

  useEffect(() => {
    if (isLoading) {
      const sr = []
      const r = []
      let filter = []
      const prefilter = [
        { id: 'All', selected: true },
        { id: 'Selected', selected: false }
      ]
      for (let x = 0; x < props.products.length; x++) {
        const product = props.products[x]
        if (props.variant && props.variant === 'library') {
          const l = props.list.find((o) => o.id === product.id)
          if (l && l.id && l.status.match(/^(added|edited)/)) {
            sr.push(x)
          }
        } else {
          const l = props.list.indexOf(product.id)
          if (l > -1) {
            sr.push(x)
          }
        }
        let chipName
        if (product && product.library) {
          chipName = product.library.libraryName
        } else {
          const currentCompany = Company.getCurrent()
          chipName = currentCompany.name
        }
        const exists = filter.find((o) => o.id === chipName)
        if (!exists) {
          filter.push({
            id: chipName,
            selected: false
          })
        }
        r.push({
          id: x,
          title: product.title ? product.title : '-',
          price: product.price ? product.price : 0,
          library: chipName
        })
      }
      filter.sort((a, b) => (a.id > b.id ? 1 : -1))
      filter = [...prefilter, ...filter]
      setRows(r)
      setFilteredRows(r)
      setSelectedRows(sr)
      setFilterChips(filter)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleSave = () => {
    const list = []
    for (let x = 0; x < selectedRows.length; x++) {
      const row = props.products[selectedRows[x]]
      list.push(row.id)
    }
    props.onSave(list)
  }

  const handleChange = (r) => {
    setSelectedRows(r)
  }

  const handleChipSelect = (value) => {
    const [fr, fc] = H.filterDataGridUsingChipFilter({ value, rows, selectedRows, filterChips })
    setFilterChips(fc)
    setFilteredRows(fr)
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Grid container>
          <Grid item xs={12}>
            <Grid container sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <GridToolbarQuickFilter size='small' variant='outlined' xs={6} sx={{ pt: 1, pl: 2, pr: 2, pb: 1 }} />
            </Grid>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    )
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    /* { field: 'id', headerName: 'Id', hide: true }, */
    {
      field: 'title',
      headerName: 'Title',
      flex: 2,
      renderCell: (params) => {
        return (
          <Stack>
            <Typography>
              <strong>{params.row.title}</strong>
            </Typography>
          </Stack>
        )
      }
    },
    {
      field: 'price',
      headerName: 'Price',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: (params) => {
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>${Number(params.row.price).toFixed(2)}</Box>
      }
    },
    { field: 'library', headerName: 'Library', flex: 1 }
  ]

  const jsx = (
    <Box>
      <Box sx={{ pb: 1 }}>
        {filterChips.map((f) => {
          return (
            <Chip
              key={`Chip-${f.id}`}
              sx={{ p: 1, m: 0.5 }}
              color={f.selected === true ? 'primary' : 'default'}
              size='small'
              label={f.id}
              onClick={() => handleChipSelect(f.id)}
            />
          )
        })}
      </Box>
      <Box sx={{ height: 500 }}>
        <DataGrid
          sx={{
            '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
              color: '#A6B0CF',
              backgroundColor: 'transparent'
            }
          }}
          density='comfortable'
          rows={filteredRows}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50]}
          disableSelectionOnClick
          checkboxSelection
          rowSelectionModel={selectedRows}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onRowSelectionModelChange={(rows) => {
            handleChange(rows)
          }}
          keepNonExistentRowsSelected
          slots={{ toolbar: CustomToolbar }}
        />
      </Box>
    </Box>
  )

  return <DialogGeneric title={props.title} content={jsx} fullWidth onChange={handleSave} onClose={props.onClose} />
}

const styles = {
  container: {
    width: '100%'
    // backgroundColor: 'white',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  textField: {
    fontSize: 14,
    paddingRight: 4
  }
}
