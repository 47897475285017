import React, { useState, useEffect } from 'react'
import '../../../css/forms.css'
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Typography,
  TextField
} from '@mui/material'
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded'
import UnitFuncDialog from './Reusables/UnitFuncDialog'
import DefaultDropdown from './Reusables/DefaultDropdown'
import { AddValue } from './FieldTypes/MultiSelection/AddValue'

function FieldTypeAddLogicPanel (props) {
  const [visibleInFindings, setVisible] = useState('includeSummary' in props.field ? props.field.includeSummary : true)
  // const [quote, setQuote] = useState(false)
  // const [crm, setCRM] = useState('')
  const [unit, setUnit] = useState('units' in props.field ? props.field.units : '')
  const [visibleInApp, setVisibleInApp] = useState('display' in props.field ? props.field.display : true)
  const [includeJobInfo, setJobInfo] = useState('includeJobInfo' in props.field ? props.field.includeJobInfo : true)
  const [sendToCrm, setSendToCrm] = useState('sendToCrm' in props.field ? props.field.sendToCrm : false)
  const [sortByCategory, setSortByCategory] = useState('sortByCategory' in props.field ? props.field.sortByCategory : true)
  const [displayAllProjectSolutions, setDisplayAllProjectSolutions] = useState('displayAllProjectSolutions' in props.field ? props.field.displayAllProjectSolutions : true)
  const [editable, setEditable] = useState('editable' in props.field ? props.field.editable : true)

  const changeHandlerVisible = (event) => {
    setVisible(event.target.checked)
    props.onChangeVisible(event.target.checked)
  }

  const changeHandlerQuote = (event) => {
    // setQuote(event.target.checked)
    props.onChangeQuote(event.target.checked)
  }

  const changeHandlerCRM = (event) => {
    // setCRM(event.target.value)
    props.onChangeCRM(event.target.value)
  }

  const changeHandlerUnits = (val) => {
    setUnit(val)
    props.onChangeUnits(val)
  }

  const createHandlerUnit = (unitArr) => {
    props.onCreateUnit(unitArr)
  }

  /* const deleteHandlerUnit = (unitArr) => {
    props.onDeleteUnit(unitArr);
  } */

  const addLogic = (e) => {
    props.onAddLogic(e)
  }

  const changeHandlerDefaultVal = (val) => {
    props.onChangeDefault(val)
  }

  const changeHandlerDefaultValText = (event) => {
    props.onChangeDefault(event.target.value)
  }

  const changeHandlerVisibleInApp = (event) => {
    setVisibleInApp(event.target.checked)
    props.onChangeVisibleInApp(event.target.checked)
  }

  const changeHandlerJobInfo = (event) => {
    setJobInfo(event.target.checked)
    props.onChangeJobInfo(event.target.checked)
  }

  const changeHandlerSendToCrm = (event) => {
    setSendToCrm(event.target.checked)
    props.onChangeSendToCrm(event.target.checked)
  }

  const changeHandlerSortByCategory = (event) => {
    setSortByCategory(event.target.checked)
    props.onChangeSortByCategory(event.target.checked)
  }

  const changeHandlerDisplayAll = (event) => {
    setDisplayAllProjectSolutions(event.target.checked)
    props.onChangeDisplayAll(event.target.checked)
  }

  const changeHandlerEditable = (event) => {
    setEditable(event.target.checked)
    props.onChangeEditable(event.target.checked)
  }

  /* const addValue = (e) => {
    props.onAddValue(e)
  } */

  const changeHandlerAddValue = (val) => {
    props.onChangeAddValue(val)
  }

  useEffect(() => {
    if (!('includeSummary' in props.field)) {
      setVisible(true)
      props.onChangeVisible(true)
    }

    if (props.field.type === 'freeFormCapture') {
      if (!('includeJobInfo' in props.field)) {
        setVisibleInApp(true)
        props.onChangeJobInfo(true)
      }
      if (!('sendToCrm' in props.field)) {
        setSendToCrm(false)
        props.onChangeSendToCrm(false)
      }
    }

    if (props.field.type === 'date' && !('default' in props.field)) {
      props.onChangeDefault(7)
    }

    if (props.field.type === 'time' && !('default' in props.field)) {
      props.onChangeDefault('8:30 am')
    }
  }, [props]) // eslint-disable-line

  // console.log(props);

  return (
    <>
      <div style={{
        borderColor: '#0000003b',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '0px 0px 5px 5px',
        backgroundColor: '#fafafa'
      }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center'
        }}
        >
          {
            'onAddLogic' in props &&
              <Button
                style={{ ...styles.addLogicBtn, ...styles.borderRight }}
                color='primary'
                variant='text'
                startIcon={<AccountTreeRoundedIcon />}
                onClick={addLogic}
              >
                Add Logic
              </Button>
          }

          {
            props.field.type === 'checkbox' &&
              <AddValue
                onChangeAddValue={(val) => changeHandlerAddValue(val)}
              />
          }

          <FormControlLabel
            style={{
              ...styles.visibleMain
            }}
            control={<Checkbox
              checked={visibleInFindings}
              onChange={changeHandlerVisible}
              size='small'
              color='primary'
                     />}
            label={<Typography style={styles.visibleLabel}>Visible in Findings Review</Typography>}
          />

          {
            'onChangeJobInfo' in props &&
              <FormControlLabel
                style={{
                  ...styles.visibleMain
                }}
                control={<Checkbox
                  checked={includeJobInfo}
                  onChange={changeHandlerJobInfo}
                  size='small'
                  color='primary'
                         />}
                label={<Typography style={styles.visibleLabel}>Include Job Info</Typography>}
              />
          }

          {
            'onChangeSendToCrm' in props &&
              <FormControlLabel
                style={{ ...styles.visibleMain }}
                control={
                  <Checkbox
                    checked={sendToCrm}
                    onChange={changeHandlerSendToCrm}
                    size='small'
                    color='primary'
                  />
                }
                label={<Typography style={styles.visibleLabel}>Send To CRM</Typography>}
              />
          }

          {
            'onChangeSortByCategory' in props &&
              <FormControlLabel
                style={{ ...styles.visibleMain }}
                control={
                  <Checkbox
                    name='sortByCategory'
                    checked={sortByCategory}
                    onChange={changeHandlerSortByCategory}
                    size='small'
                    color='primary'
                  />
                }
                label={<Typography style={styles.visibleLabel}>Sort by Category</Typography>}
              />
          }

          {
            'onChangeDisplayAll' in props &&
              <FormControlLabel
                style={{ ...styles.visibleMain }}
                control={
                  <Checkbox
                    name='displayAllProjectSolutions'
                    checked={displayAllProjectSolutions}
                    onChange={changeHandlerDisplayAll}
                    size='small'
                    color='primary'
                  />
                }
                label={<Typography style={styles.visibleLabel}>Display All Added Project Solutions</Typography>}
              />
          }

          {
            'onChangeEditable' in props &&
              <FormControlLabel
                style={{ ...styles.visibleMain }}
                control={
                  <Checkbox
                    name='editable'
                    checked={editable}
                    onChange={changeHandlerEditable}
                    size='small'
                    color='primary'
                  />
                }
                label={<Typography style={styles.visibleLabel}>Editable</Typography>}
              />
          }

          {
            'onChangeCRM' in props &&
              <div style={{
                ...styles.flexRow,
                ...styles.borderLeft,
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '10px',
                paddingLeft: '10px'
              }}
              >
                <p style={{
                  ...styles.centerVertical,
                  fontSize: '13px',
                  marginRight: '5px'
                }}
                >Map w/ CRM Field -
                </p>
                <div>
                  <FormControl variant='standard'>
                    <Select
                      labelId='demo-simple-select-standard-label'
                      id='demo-simple-select-standard'
                      value={('includeCRMFields' in props.field && props.field.includeCRMFields === true) ? props.field.crm : 'none'}
                      // small text size
                      onChange={changeHandlerCRM}
                      disableUnderline
                      sx={{
                        fontSize: '0.8rem'
                      }}
                    >
                      <MenuItem value='none'>
                        <em>Not Defined</em>
                      </MenuItem>
                      {
                      'crmFieldsConfig' in props &&
                       props.crmFieldsConfig.map((crmField, idx) => {
                         return (
                           <MenuItem
                             key={`crm-${idx}`}
                             value={crmField.value}
                           >
                             {crmField.name}
                           </MenuItem>
                         )
                       })
                    }
                    </Select>
                  </FormControl>
                </div>
              </div>
          }

          {
            (props.field.type === 'boolean' ||
            props.field.type === 'checkbox' ||
            props.field.type === 'choice') &&
              <div style={{
                ...styles.flexRow,
                ...styles.borderLeft,
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '10px',
                paddingLeft: '10px'
              }}
              >
                <p style={{
                  ...styles.centerVertical,
                  fontSize: '13px',
                  marginRight: '5px',
                  minWidth: '90px'
                }}
                >Default Value:
                </p>
                <DefaultDropdown
                  field={props.field}
                  onChange={(val) => changeHandlerDefaultVal(val)}
                />
              </div>
          }

          {
            (props.field.type === 'text' ||
            props.field.type === 'type' ||
            props.field.type === 'numeric' ||
            props.field.type === 'date' ||
            props.field.type === 'time') &&
              <div style={{
                ...styles.flexRow,
                ...styles.borderLeft,
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '10px',
                paddingLeft: '10px'
              }}
              >
                <p style={{
                  ...styles.centerVertical,
                  fontSize: '13px',
                  marginRight: '5px',
                  minWidth: '90px'
                }}
                >Default Value:
                </p>
                <TextField
                  style={styles.numInput}
                  className='default-field'
                  id='default-field'
                  placeholder=''
                  variant='standard'
                  size='small'
                  value={props.field.default || ''}
                  onChange={changeHandlerDefaultValText}
                />
              </div>
          }

          {
            'type' in props.field && (props.field.type === 'numeric' ||
            props.field.type === 'calc') &&
              <>
                <div style={{
                  ...styles.flexRow,
                  ...styles.borderLeft,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: '10px',
                  paddingLeft: '10px'
                }}
                >
                  <p style={{
                    ...styles.centerVertical,
                    fontSize: '13px',
                    marginRight: '5px'
                  }}
                  >Unit:
                  </p>
                  <UnitFuncDialog
                    onChange={(val) => changeHandlerUnits(val)}
                    unit={unit}
                    unitArr={props.unitArr}
                    numericUnitsConfig={props.numericUnitsConfig}
                    onCreateUnit={(newUnitMenu) => createHandlerUnit(newUnitMenu)}
                  // onDeleteUnit={(newUnitMenu)=>deleteHandlerUnit(newUnitMenu)}
                    guid={props.guid}
                  />
                </div>
              </>
          }

          {
            'type' in props.field && (props.field.type === 'calc' || props.field.type === 'numeric') &&
              <FormControlLabel
                style={{
                  ...styles.visibleMain,
                  ...styles.borderLeft
                }}
                control={<Checkbox
                  checked={visibleInApp}
                  onChange={changeHandlerVisibleInApp}
                  size='small'
                  color='primary'
                         />}
                label={<Typography style={styles.visibleLabel}>Visible in App</Typography>}
              />
          }

          {
            'type' in props.field && props.field.type === 'media' &&
              <>
                <Divider style={styles.leftGridDivider} orientation='vertical' variant='middle' flexItem />
                <div style={styles.leftGrid}>
                  <FormControlLabel
                    style={styles.visibleMain}
                    control={<Checkbox
                      checked={'quote' in props.field ? props.field.quote : false}
                      onChange={changeHandlerQuote}
                      size='small'
                      color='primary'
                             />}
                    label={<Typography style={styles.visibleLabel}>Quote</Typography>}
                  />
                </div>
              </>
          }
        </div>
      </div>
    </>
  )
}

const styles = {
  borderLeft: {
    borderLeft: '1px solid #e0e0e0'
  },
  borderRight: {
    borderRight: '1px solid #e0e0e0'
  },
  centerVertical: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  divideX: {
    borderLeftWidth: '1px'
  },
  mainPanel: {
    fontSize: '13px',
    background: '#F2F2F2',
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #0000003b',
    marginRight: '96.19px'
  },
  visibleMain: {
    marginTop: '7px',
    marginRight: '0',
    marginLeft: '4px'
  },
  visibleLabel: {
    fontSize: '13px',
    color: '#000000a3'
  },
  leftGrid: {
    display: 'flex',
    height: '56px',
    lineHeight: '56px'
  },
  leftGridDivider: {
    display: 'flex',
    lineHeight: '56px',
    marginTop: '10px',
    marginBottom: '10px'
  },
  addLogicBtn: {
    fontSize: '13px',
    textTransform: 'none',
    marginLeft: '16px'
  },
  crmSelect: {
    fontSize: '13px',
    color: '#3f51b5'
  },
  unitsMain: {
    minWidth: '65px'
  },
  unitSelect: {
    fontSize: '13px',
    color: '#3f51b5'
  },
  numInput: {
    maxWidth: '90px',
    marginRight: '8px',
    verticalAlign: 'middle'
  },
  addValueBtn: {
    fontSize: '13px',
    textTransform: 'none',
    marginLeft: '4px'
  }
}

export default FieldTypeAddLogicPanel
