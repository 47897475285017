import { useEffect, useState } from 'react'
import { Badge, Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { getAssetInfo } from 'screens/Config/CompanyConfigPresentations'

const LeftPanel = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [resource, setResource] = useState({})
  const [title, setTitle] = useState('')
  const [type, setType] = useState('')
  const [subtitle, setSubtitle] = useState('')

  useEffect(() => {
    if (isLoading) {
      const resource = JSON.parse(JSON.stringify(props.resource))
      // Auto heal
      if (!resource.title) {
        resource.title = ''
      }
      if (!resource.subtitle) {
        resource.subtitle = ''
      }
      if (!resource.type) {
        resource.type = 'pdf'
      }
      if (!resource.levels) {
        resource.levels = []
      }
      if (!resource.levelIds) {
        resource.levelIds = []
      }
      if (!resource.asset) {
        resource.asset = ''
      }
      setTitle(resource.title)
      setType(resource.type)
      setSubtitle(resource.subtitle)
      props.setResource(resource)
      setIsLoading(false)
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    setResource(props.resource)
    setTitle(props.resource.title)
    setSubtitle(props.resource.subtitle)
    setType(props.resource.type)
  }, [props.resource])

  const handleChange = (e) => {
    const obj = JSON.parse(JSON.stringify(resource))
    const name = e.target.name
    const value = e.target.value
    if (name === 'title') {
      setTitle(value)
      obj[name] = value
    } else if (name === 'subtitle') {
      setSubtitle(value)
      obj[name] = value
    } else if (name === 'type') {
      // If type is changed, reset everything
      setType(value)
      obj.asset = ''
      obj[name] = value
    }
    setResource(obj)
    props.setResource(obj)
  }

  const handleSetTitleUsingFilename = async (e) => {
    if (resource.asset) {
      const a = getAssetInfo(resource.asset, props.assetsConfig)
      // title name can have an extension or not
      const title = a.title.includes('.') ? a.title.split('.').slice(0, -1).join('.') : a.title
      const obj = JSON.parse(JSON.stringify(resource))
      obj.title = title
      setTitle(title)
      setResource(obj)
      props.setResource(obj)
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Grid container sx={{ pt: 2, pl: 2, pr: 2 }}>
        <Grid item xs={12} sx={{ pb: 1 }}>
          <TextField
            fullWidth
            id='title'
            name='title'
            variant='outlined'
            label='Resource Title'
            value={title}
            sx={{ 'input:focus': { border: 'none' } }}
            onChange={handleChange}
          />
          {resource.asset && (
            <Button size='small' onClick={handleSetTitleUsingFilename}>
              Use Resource Title
            </Button>
          )}
        </Grid>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <TextField
            fullWidth
            id='subtitle'
            name='subtitle'
            variant='outlined'
            label='Subtitle'
            value={subtitle}
            sx={{ 'input:focus': { border: 'none' } }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ pl: 2, pr: 2 }}>
        <Grid item xs={12} sx={{ pb: 1 }}>
          <FormControl fullWidth sx={{ pb: { sm: 1 }, 'input:focus': { border: 'none' } }}>
            <InputLabel id='unit-type'>Resource Type</InputLabel>
            <Select id='type' name='type' label='Resource Type' labelId='type' variant='outlined' value={type} onChange={handleChange}>
              {resourceTypes.map((pt, idx) => {
                return (
                  <MenuItem key={`unit-${idx}`} value={pt.id}>
                    <Typography variant='subtitle'>{pt.title}</Typography>
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/** TODO If we get more steps for this, uncomment this section
       *
      <Grid container sx={{ borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Grid item xs={12}>
          <Selection
            {...props}
            subVariant='resource'
            title='Resource Details'
            step={1}
            onClick={() => {
              props.setRightPanelVariant('resource')
            }}
          />
        </Grid>
      </Grid>
      **/}
    </>
  )
}
export default LeftPanel

// TODO future? I do not want to have to look this up or rewrite it 20240816PSB
// If you are looking at this in 2025 and it's still here remove it
const Selection = (props) => { // eslint-disable-line 
  const [isLoading, setIsLoading] = useState(true)
  const [isHover, setIsHover] = useState(false)
  const [title, setTitle] = useState('')

  useEffect(() => {
    const key = `${props.subVariant}Title`
    if (props.resource[key]) {
      setTitle(props.resource[key])
    } else {
      setTitle(props.title)
    }
    setIsLoading(false)
    // eslint-disable-next-line
  }, [])

  if (isLoading) return <></>

  let selected = false
  if (props.rightPanelVariant === props.subVariant) selected = true

  return (
    <Box
      key={props.title}
      onClick={props.onClick}
      style={{ ...styles.selectionContainer, backgroundColor: isHover ? '#F2F2F2' : 'white' }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Grid container sx={{ pl: 2, pt: props.onClick ? 1 : 1, pb: props.onClick ? 1 : 1 }} style={selected ? styles.selected : null} alignItems='center'>
        <Grid item sm={10}>
          <Grid container wrap='nowrap'>
            <Grid item sx={{ pl: 1 }}>
              <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
                {props.step ? <Badge badgeContent={props.step} color='primary' sx={{ mr: 3 }} /> : null}
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={1}>
          <IconButton>
            {props.rightPanelVariant === props.subVariant
              ? (
                <PlayCircleFilledIcon style={props.onClick ? styles.icon : styles.iconHidden} />
                )
              : (
                <ArrowRightIcon style={props.onClick ? styles.icon : styles.iconHidden} />
                )}
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  )
}

const resourceTypes = [
  { id: 'pdf', title: 'PDF' },
  { id: 'video', title: 'Video' },
  { id: 'audio', title: 'Audio' }
]

const styles = {
  textField: {
    fontSize: 14
  },
  icon: {
    color: 'rgba(0,0,0,.66)'
  },
  iconHidden: {
    color: 'rgba(0,0,0,0)'
  }
}
