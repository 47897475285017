import { publish, subscribe } from 'components/Reusable/Subscriber'

/**
 * Pub-sub topics.
 * @type {string}
 */

export const LoadingTopic = 'App.LoadingOverlay'
export const LoginSuccessTopic = 'Auth.LoginSuccess'
export const LogoutTopic = 'Auth.Logout'
export const LogoutSuccessTopic = 'Auth.LogoutSuccess'
export const ValidateSessionTopic = 'Auth.ValidateSession'
export const SideBar = 'Runtime.SideBar'
export const StatusTopic = 'Runtime.Status'
export const ModalTopic = 'Runtime.Modal'
export const StatusUploadTopic = 'Runtime.StatusUploadTopic'

export const Topics = {
  publish,
  subscribe,
  Loading: LoadingTopic,
  Login: LoginSuccessTopic,
  Logout: LogoutTopic,
  ValidateSession: ValidateSessionTopic,
  Modal: ModalTopic,
  Status: StatusTopic,
  StatusUpload: StatusUploadTopic,
  SideBar,
  ChangeOrg: 'Organization.Change'
}
