import React, { useEffect, useState } from 'react'
import { Box, Chip, Stack, Typography } from '@mui/material'
import Company from '../../../Company'
import H from '../../../../library/helper'
import { DialogGeneric } from '../../../../components/Reusable/DialogGeneric'
import { DataGrid } from '@mui/x-data-grid'
import { LoadingPleaseWait } from '@supportworks/react-components'

export const DialogFormsSelector = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [pageSize, setPageSize] = useState(10)
  const [filterChips, setFilterChips] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [rows, setRows] = useState([])
  const [filteredRows, setFilteredRows] = useState([])

  useEffect(() => {
    if (isLoading) {
      const sr = []
      const r = []
      let filter = []
      const prefilter = [
        { id: 'All', selected: true },
        { id: 'Selected', selected: false }
      ]
      for (let x = 0; x < props.formsList.length; x++) {
        const fc = props.formsList[x]
        // console.log(fc);

        if (props.variant && props.variant === 'library') {
          const l = props.list.find((o) => o.id === fc.id)
          if (l && l.id && l.status.match(/^(added|edited)/)) {
            sr.push(x)
          } else {
            const l = props.list.indexOf(fc.id)
            if (l > -1) {
              sr.push(x)
            }
          }
        }

        let chipName
        if (fc.library) {
          chipName = fc.library.libraryName
        } else {
          const currentCompany = Company.getCurrent()
          chipName = currentCompany.name
        }
        const exists = filter.find((o) => o.id === chipName)
        if (!exists) {
          filter.push({
            id: chipName,
            selected: false
          })
        }
        r.push({
          id: x,
          title: fc && fc.formTitle ? fc.formTitle : '',
          type: fc && fc.formType ? fc.formType : '',
          library: chipName
        })
      }

      filter.sort((a, b) => (a.id > b.id ? 1 : -1))
      filter = [...prefilter, ...filter]
      setRows(r)
      setFilteredRows(r)
      setSelectedRows(sr)
      setFilterChips(filter)
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleChipSelect = (value) => {
    const [fr, fc] = H.filterDataGridUsingChipFilter({ value, rows, selectedRows, filterChips })
    setFilterChips(fc)
    setFilteredRows(fr)
  }

  const handleChange = (r) => {
    setSelectedRows(r)
  }

  const handleSave = () => {
    const list = []
    for (let x = 0; x < selectedRows.length; x++) {
      const row = props.formsList[selectedRows[x]]
      // console.log(row);
      list.push(row.id)
    }
    props.onSave(list)
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    { field: 'id', headerName: 'Id', hide: true },
    { field: 'type', headerName: 'Type', flex: 1, sortable: true },
    {
      field: 'title',
      headerName: 'Title',
      flex: 2,
      renderCell: (params) => {
        return (
          <Stack>
            <Typography>
              <strong>{params.row.title}</strong>
            </Typography>
          </Stack>
        )
      }
    },
    { field: 'library', headerName: 'Library', flex: 1 }
  ]

  const jsx = (
    <Box>
      <Box sx={{ pb: 2 }}>
        {filterChips.map((f) => {
          return <Chip key={`Chip-${f.id}`} sx={{ p: 2, borderRadius: 1, border: '4px solid white' }} color={f.selected === true ? 'primary' : 'default'} size='small' label={f.id} onClick={() => handleChipSelect(f.id)} />
        })}
      </Box>
      <Box sx={{ height: 500 }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50]}
          checkboxSelection
          rowSelectionModel={selectedRows}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onRowSelectionModelChange={(rows) => {
            handleChange(rows)
          }}
          keepNonExistentRowsSelected
          sx={{
            '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
              color: '#A6B0CF',
              backgroundColor: 'transparent',
              display: props.singleUseOnly ? 'none' : ''
            }
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false
              }
            }
          }}
        />
      </Box>
    </Box>
  )

  return <DialogGeneric title={props.title} content={jsx} fullWidth onChange={handleSave} onClose={props.onClose} />
}
