import React, { useEffect, useState } from 'react'
import { Box, Grid, InputAdornment, Paper, Stack, Switch, Typography, FormControlLabel, TextField, MenuItem } from '@mui/material'
import CompanyConfigEdit from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import { LoadingPleaseWait } from '@supportworks/react-components'

const config = {
  title: 'Drawing Tool Setup',
  variant: 'pptLibrary',
  tag: 'pptLibrary',
  name: 'Drawing Tool Setup',
  lockTag: true,
  lockName: true,
  lockTitle: true,
  ref: React.createRef(null)
}

const colorSchemeOptions = ['red', 'green', 'blue', 'magenta', 'orange', 'black']
const measurementSystems = ['imperial', 'metric']
const minLength = 10
const maxLength = 500

const CompanyConfigDrawingToolEditor = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [contentJson, setContentJson] = useState({})
  const [useDrawingTool, setUseDrawingTool] = useState(false)
  const [showGrid, setShowGrid] = useState(false)
  const [showRuler, setShowRuler] = useState(false)
  const [showMeasurements, setShowMeasurements] = useState(false)
  const [showAssetCount, setShowAssetCount] = useState(false)
  const [showCameraPlacementTool, setShowCameraPlacementTool] = useState(false)
  const [showProductPlacementTool, setShowProductPlacementTool] = useState(false)
  const [showHighlighterTool, setShowHighlighterTool] = useState(false)
  const [showLayerTool, setShowLayerTool] = useState(false)
  const [showDragSelectTool, setShowDragSelectTool] = useState(false)
  const [showSnapTool, setShowSnapTool] = useState(false)
  const [showImagePlacementTool, setShowImagePlacementTool] = useState(false)
  const [colorScheme, setColorScheme] = useState('blue')
  const [showProductLineQuantity, setShowProductLineQuantity] = useState(false)
  const [showProductAreaQuantity, setShowProductAreaQuantity] = useState(false)
  const [showProductSvgQuantity, setShowProductSvgQuantity] = useState(false)
  const [measurementSystem, setMeasurementSystem] = useState('imperial')
  const [gridWidth, setGridWidth] = useState(120)
  const [gridHeight, setGridHeight] = useState(120)
  const [initialSnapVariant, setInitialSnapVariant] = useState('none')
  const [widthError, setWidthError] = useState(false)
  const [heightError, setLengthError] = useState(false)

  useEffect(() => {
    if (props.config && props.config.contentJson) {
      const c = JSON.parse(props.config.contentJson)
      if (!c.settings) {
        c.settings = {}
      }
      if (!c.settings.canvasScale) {
        c.settings.canvasScale = {
          width: 60,
          height: 40,
          system: 'imperial'
        }
      }
      setUseDrawingTool(c.settings?.useDrawingTool || false)
      setShowGrid(c.settings?.showGrid || false)
      setShowRuler(c.settings?.showRuler || false)
      setShowMeasurements(c.settings?.showMeasurements || false)
      setShowAssetCount(c.settings?.showAssetCount || false)
      setShowCameraPlacementTool(c.settings?.showCameraPlacementTool || false)
      setShowProductPlacementTool(c.settings?.showProductPlacementTool || false)
      setShowHighlighterTool(c.settings?.showHighlighterTool || false)
      setShowLayerTool(c.settings?.showLayerTool || false)
      setShowDragSelectTool(c.settings?.showDragSelectTool || false)
      setShowSnapTool(c.settings?.showSnapTool || false)
      setShowImagePlacementTool(c.settings?.showImagePlacementTool || false)
      setShowProductLineQuantity(c.settings?.showProductLineQuantity || false)
      setShowProductAreaQuantity(c.settings?.showProductAreaQuantity || false)
      setShowProductSvgQuantity(c.settings?.showProductSvgQuantity || false)
      setColorScheme(c.settings?.colorScheme || 'blue')
      setMeasurementSystem(c.settings?.canvasScale?.system || 'imperial')
      setGridWidth(Number(c.settings?.canvasScale?.width))
      setGridHeight(Number(c.settings?.canvasScale?.height))
      setInitialSnapVariant(c.settings?.initialSnapVariant || 'none')
      if (props.onChange) {
        props.onChange(JSON.stringify(c))
      }
      setContentJson(c)
      setIsLoading(false)
    }
  }, [])

  const handleChange = (name) => (event) => {
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value

    if (name === 'canvasScale.width' || name === 'canvasScale.height') {
      if (!/^\d*$/.test(value)) {
        return
      }
    }
    if (name === 'canvasScale.width' && value > maxLength) {
      value = maxLength
      setWidthError(true)
    } else if (name === 'canvasScale.width') {
      setWidthError(false)
    }
    if (name === 'canvasScale.height' && value > maxLength) {
      value = maxLength
      setLengthError(true)
    } else if (name === 'canvasScale.height') {
      setLengthError(false)
    }
    if (value && (name === 'canvasScale.width' || name === 'canvasScale.height')) {
      value = Number(value)
    }
    const c = { ...contentJson }
    if (name.startsWith('canvasScale.')) {
      const key = name.split('.')[1]
      c.settings.canvasScale = {
        ...c.settings.canvasScale,
        [key]: value
      }
    } else {
      c.settings[name] = value
    }
    setContentJson(c)
    if (props.onChange) {
      props.onChange(JSON.stringify(c))
    }
    switch (name) {
      case 'useDrawingTool':
        setUseDrawingTool(value)
        break
      case 'showGrid':
        setShowGrid(value)
        break
      case 'showRuler':
        setShowRuler(value)
        break
      case 'showMeasurements':
        setShowMeasurements(value)
        break
      case 'showAssetCount':
        setShowAssetCount(value)
        break
      case 'showCameraPlacementTool':
        setShowCameraPlacementTool(value)
        break
      case 'showProductPlacementTool':
        setShowProductPlacementTool(value)
        break
      case 'showHighlighterTool':
        setShowHighlighterTool(value)
        break
      case 'showLayerTool':
        setShowLayerTool(value)
        break
      case 'showDragSelectTool':
        setShowDragSelectTool(value)
        break
      case 'showSnapTool':
        setShowSnapTool(value)
        break
      case 'showImagePlacementTool':
        setShowImagePlacementTool(value)
        break
      case 'showProductLineQuantity':
        setShowProductLineQuantity(value)
        break
      case 'showProductAreaQuantity':
        setShowProductAreaQuantity(value)
        break
      case 'showProductSvgQuantity':
        setShowProductSvgQuantity(value)
        break
      case 'canvasScale.system':
        setMeasurementSystem(value)
        break
      case 'canvasScale.width':
        setGridWidth(value)
        break
      case 'canvasScale.height':
        setGridHeight(value)
        break
      case 'initialSnapVariant':
        setInitialSnapVariant(value)
        break
      default:
        break
    }
  }

  const handleBlur = (name) => () => {
    let value
    if (name === 'canvasScale.width') {
      value = gridWidth < minLength ? minLength : gridWidth
      setGridWidth(value)
    } else if (name === 'canvasScale.height') {
      value = gridHeight < minLength ? minLength : gridHeight
      setGridHeight(value)
    }
    const c = { ...contentJson }
    if (name.startsWith('canvasScale.')) {
      const key = name.split('.')[1]
      c.settings.canvasScale = {
        ...c.settings.canvasScale,
        [key]: value
      }
    }
    setContentJson(c)
    if (props.onChange) {
      props.onChange(JSON.stringify(c))
    }
  }

  const handleColorSchemeChange = (color) => {
    setColorScheme(color)
    const c = { ...contentJson }
    c.settings.colorScheme = color
    setContentJson(c)
    if (props.onChange) {
      props.onChange(JSON.stringify(c))
    }
  }

  useEffect(() => {
    if (contentJson) {
      props.onRef({
        hasFinalizeHook: () => true,
        finalizeHook: () => JSON.stringify(contentJson)
      })
    }
  }, [contentJson])

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Paper elevation={1} sx={{ mt: 1, p: 2 }}>
        <Box sx={{ pb: 2 }}>
          <Typography variant='h6'>Drawing Tool</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              name='useDrawingTool'
              control={<Switch checked={useDrawingTool} onChange={handleChange('useDrawingTool')} />}
              label='Enable Feature'
              labelPlacement='end'
            />
          </Grid>
        </Grid>

        {useDrawingTool
          ? (
            <>
              <Grid container spacing={2} sx={{ pt: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography variant='h6' sx={{ pb: 2 }}>Measurement Settings</Typography>
                  <Stack>
                    <TextField
                      select
                      label='Measurement System'
                      value={measurementSystem}
                      onChange={handleChange('canvasScale.system')}
                      margin='normal'
                    >
                      {measurementSystems.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option.charAt(0).toUpperCase() + option.slice(1)}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          label='Default Grid Width'
                          value={gridWidth}
                          onChange={handleChange('canvasScale.width')}
                          onBlur={handleBlur('canvasScale.width')}
                          fullWidth
                          margin='normal'
                          InputProps={{
                            endAdornment: <InputAdornment position='end'>{measurementSystem === 'imperial' ? 'LFT' : 'LM'}</InputAdornment>
                          }}
                          error={widthError}
                          helperText={widthError ? `Cannot exceed ${maxLength}` : ''}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label='Default Grid Height'
                          value={gridHeight}
                          onChange={handleChange('canvasScale.height')}
                          onBlur={handleBlur('canvasScale.height')}
                          fullWidth
                          margin='normal'
                          InputProps={{
                            endAdornment: <InputAdornment position='end'>{measurementSystem === 'imperial' ? 'LFT' : 'LM'}</InputAdornment>
                          }}
                          error={heightError}
                          helperText={heightError ? `Cannot exceed ${maxLength}` : ''}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          select
                          label='Initial Snap Variant'
                          value={initialSnapVariant}
                          onChange={handleChange('initialSnapVariant')}
                          margin='normal'
                          helperText={measurementSystem === 'imperial' ? 'Initial ruler snap variant (Long = 1LF)' : 'Initial ruler snap variant (Long = 1LM)'}
                          fullWidth
                        >
                          <MenuItem value='none'>None</MenuItem>
                          <MenuItem value='short'>Short</MenuItem>
                          <MenuItem value='half'>Medium</MenuItem>
                          <MenuItem value='long'>Long</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  </Stack>
                  <Typography variant='h6' sx={{ pt: 2, pb: 2 }}>Color Scheme</Typography>
                  <Box sx={{ display: 'flex' }}>
                    {colorSchemeOptions.map(color => (
                      <Box
                        key={color}
                        onClick={() => handleColorSchemeChange(color)}
                        sx={{
                          mr: 1,
                          width: '2rem',
                          height: '2rem',
                          backgroundColor: color,
                          border: color === colorScheme ? '4px solid ' + (color === 'blue' ? 'lightblue' : 'blue') : 'none',
                          cursor: 'pointer'
                        }}
                      />
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant='h6' sx={{ pb: 2 }}>Global Settings Defaults</Typography>
                  <Stack>
                    <FormControlLabel
                      name='showGrid'
                      control={<Switch checked={showGrid} onChange={handleChange('showGrid')} />}
                      label='Show Grid'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      name='showRuler'
                      control={<Switch checked={showRuler} onChange={handleChange('showRuler')} />}
                      label='Show Ruler'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      name='showMeasurements'
                      control={<Switch checked={showMeasurements} onChange={handleChange('showMeasurements')} />}
                      label='Show Measurements'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      name='showAssetCount'
                      control={<Switch checked={showAssetCount} onChange={handleChange('showAssetCount')} />}
                      label='Show Asset Count'
                      labelPlacement='end'
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ pt: 2 }}>
                <Grid item xs={12}>
                  <Typography variant='h6' sx={{ pb: 2 }}>Tools</Typography>
                  <Stack>
                    <FormControlLabel
                      name='showProductPlacementTool'
                      control={<Switch checked={showProductPlacementTool} onChange={handleChange('showProductPlacementTool')} />}
                      label='Show Product Placement Tool'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      name='showCameraPlacementTool'
                      control={<Switch checked={showCameraPlacementTool} onChange={handleChange('showCameraPlacementTool')} />}
                      label='Show Camera Placement Tool'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      name='showImagePlacementTool'
                      control={<Switch checked={showImagePlacementTool} onChange={handleChange('showImagePlacementTool')} />}
                      label='Show Image Placement Tool'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      name='showHighlighterTool'
                      control={<Switch checked={showHighlighterTool} onChange={handleChange('showHighlighterTool')} />}
                      label='Show Highlighter Tool'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      name='showLayerTool'
                      control={<Switch checked={showLayerTool} onChange={handleChange('showLayerTool')} />}
                      label='Show Layer Tool'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      name='showDragSelectTool'
                      control={<Switch checked={showDragSelectTool} onChange={handleChange('showDragSelectTool')} />}
                      label='Show Drag Select Tool'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      name='showSnapTool'
                      control={<Switch checked={showSnapTool} onChange={handleChange('showSnapTool')} />}
                      label='Show Snap Tool'
                      labelPlacement='end'
                    />
                  </Stack>
                </Grid>
              </Grid>

              {showProductPlacementTool
                ? (
                  <Grid container spacing={2} sx={{ pt: 2 }}>
                    <Grid item xs={12}>
                      <Typography variant='h6' sx={{ pb: 2 }}>Product Quantity Display</Typography>
                      <Stack>
                        <FormControlLabel
                          name='showProductLineQuantity'
                          control={<Switch checked={showProductLineQuantity} onChange={handleChange('showProductLineQuantity')} />}
                          label='Show Product Line Quantity'
                          labelPlacement='end'
                        />
                        <FormControlLabel
                          name='showProductAreaQuantity'
                          control={<Switch checked={showProductAreaQuantity} onChange={handleChange('showProductAreaQuantity')} />}
                          label='Show Product Area Quantity'
                          labelPlacement='end'
                        />
                        <FormControlLabel
                          name='showProductSvgQuantity'
                          control={<Switch checked={showProductSvgQuantity} onChange={handleChange('showProductSvgQuantity')} />}
                          label='Show Product SVG Quantity'
                          labelPlacement='end'
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                  )
                : null}
            </>
            )
          : null}
      </Paper>
    </>
  )
}

export default {
  Edit: props => (
    <CompanyConfigEdit
      {...config}
      {...props}
      editorComponent={CompanyConfigDrawingToolEditor}
    />
  ),
  Index: props => <CompanyConfigIndex {...config} {...props} />
}
