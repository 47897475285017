import React, { useState, useEffect } from 'react'
import { Box, Button, Drawer, Grid, IconButton, Typography } from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'
import { DialogPresentationSelector } from 'components/Reusable/DialogPresentationSelector'
import DrawerHeader from '../Reusables/DrawerHeader'
import DrawerFooter from '../Reusables/DrawerFooter'
import { LoadingPleaseWait } from '@supportworks/react-components'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { DataGrid } from '@mui/x-data-grid'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const DrawerPresentations = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [top, setTop] = useState([])
  const [bottom, setBottom] = useState([])

  useEffect(() => {
    if (isLoading) {
      const f = props.form
      if (f && f.always_present) {
        setTop(f.always_present.top)
        setBottom(f.always_present.bottom)
      } else {
        // Auto heal stuff
        const f = JSON.parse(JSON.stringify(props.form))
        f.always_present = { top: [], bottom: [] }
        props.onChange(f)
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const stopPropagationForTab = (e) => {
    if (e.key === 'Tab') {
      e.stopPropagation()
    }
  }

  const handleChange = (variant, l) => {
    if (variant === 'top') {
      setTop(l)
    } else {
      setBottom(l)
    }
  }

  const handleClose = (e) => {
    props.onClose()
  }

  const handleDone = () => {
    const f = JSON.parse(JSON.stringify(props.form))
    f.always_present.top = top
    f.always_present.bottom = bottom
    props.onDone(f)
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Drawer
        key='forms-drawerformsettings'
        open={props.open}
        PaperProps={{
          sx: { width: '560px !important' }
        }}
        anchor='right'
        variant='temporary'
        onKeyDown={stopPropagationForTab}
        onClose={handleClose}
      >
        <DrawerHeader icon={<TuneIcon style={{ color: 'rgba(0,0,0,.66)' }} />} title='Default Presentations' onClose={handleClose} />

        <Box sx={{ pt: 2 }} style={styles.content}>
          <Presentations
            title='Starting Presentations'
            subTitle='Presentations added here will be placed before any added solutions.  Once you add presentations, sort the presentations to your preferred order.'
            list={top}
            presentations={props.currentConfig.presentations}
            assets={props.currentConfig.assets2}
            onChange={(l) => handleChange('top', l)}
          />

          <Presentations
            title='Final Presentations'
            subTitle='Presentations added here will be placed after any added solutions.  Once you add presentations, sort the presentations to your preferred order.'
            list={bottom}
            presentations={props.currentConfig.presentations}
            assets={props.currentConfig.assets2}
            onChange={(l) => handleChange('bottom', l)}
          />
        </Box>

        <DrawerFooter onClose={handleClose} onDone={handleDone} />
      </Drawer>
    </>
  )
}
export default DrawerPresentations

const Presentations = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [list, setList] = useState([])

  useEffect(() => {
    if (isLoading) {
      setList(props.list)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleMoveUp = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx - 1, 0, ele)
    setList(l)
    props.onChange(l)
  }

  const handleMoveDown = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx + 1, 0, ele)
    setList(l)
    props.onChange(l)
  }

  const handleDelete = (idx) => {
    const r = window.confirm('Are you sure you want to delete?')
    if (r) {
      const l = JSON.parse(JSON.stringify(list))
      l.splice(idx, 1)
      setList(l)
      props.onChange(l)
    }
  }

  const handleChange = (l) => {
    setList(l)
    props.onChange(l)
  }

  const handleShowAddDialog = () => {
    setShowAddDialog(!showAddDialog)
  }

  const columns = [
    { field: 'id', headerName: 'Id', hide: true },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: (params) => {
        return <>{params.row.type}</>
      }
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 2,
      editable: false,
      sortable: true,
      renderCell: (params) => {
        return <>{params.row.title}</>
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id)}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton size='small' disabled={!(params.row.id < list.length - 1)} onClick={() => handleMoveDown(params.row.id)}>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < list.length; x++) {
    // lookup the id to get the presentations title
    const p = props.presentations.find((p) => p.id === list[x])
    if (p) {
      rows.push({
        id: x,
        type: p.type && p.type.charAt(0).toUpperCase() + p.type.slice(1),
        title: p.title,
        actions: ''
      })
    } else {
      console.log(`Skipping ${list[x]}, not in presentations library`)
    }
  }

  return (
    <>
      <Box sx={{ pl: 2, pr: 2 }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='body2' style={{ fontWeight: 'bold' }}>
              {props.title}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ pt: 2 }}>
            <Typography variant='body2'>
              {props.subTitle}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ pt: 2, pb: 1 }}>
            <DataGrid
              autoHeight
              rows={rows}
              columns={columns}
              pagination
              pageSize={10}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false
                  }
                }
              }}
            />
            <Button size='small' variant='contained' onClick={handleShowAddDialog} sx={{ mt: 2, mb: 2 }}>
              Add Presentations
            </Button>
          </Grid>
        </Grid>
      </Box>
      {showAddDialog
        ? (
          <DialogPresentationSelector
            title='Select Presentations'
            list={list}
            presentations={props.presentations}
            assets={props.assets}
            onSave={(l) => {
              handleChange(l)
              setShowAddDialog(!showAddDialog)
            }}
            onClose={() => setShowAddDialog(!showAddDialog)}
          />
          )
        : null}
    </>
  )
}

const styles = {
  content: {
    overflowX: 'hidden'
  },
  icon: {
    color: 'rgba(0,0,0,.66)'
  }
}
