import React from 'react'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  IconButton,
  Paper,
  TextField,
  Grid
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import CompanyConfigEdit, { CompanyConfigBaseEditor } from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import Helper from '@supportworks/helper'
import UpIcon from '@mui/icons-material/KeyboardArrowUp'
import DownIcon from '@mui/icons-material/KeyboardArrowDown'
import DataGrid from '../../components/Reusable/DataGrid'

const config = {
  title: 'Distribution List',
  variant: 'DistributionList',
  tag: 'distributionList',
  name: 'Distribution List',
  lockTag: true,
  lockName: false
}

class DistributionListEditor extends CompanyConfigBaseEditor {
  constructor (props) {
    super(props)

    let listObject = {
      list: [],
      sold: [],
      pending: [],
      referral: []
    }

    if (typeof props.contentJson === 'string') {
      const contentParsed = JSON.parse(props.contentJson)
      // if content is a string (old style), let's parse it into the new format
      if (typeof contentParsed === 'string') {
        listObject = {
          sold: contentParsed.split(','),
          pending: contentParsed.split(','),
          list: contentParsed.split(','),
          referral: contentParsed.split(',')
        }
      } else if (typeof contentParsed === 'object') {
        // Check for valid object keys, then assign values accordingly
        if (Object.prototype.hasOwnProperty.call(contentParsed, 'sold') &&
            Object.prototype.hasOwnProperty.call(contentParsed, 'pending') &&
            Object.prototype.hasOwnProperty.call(contentParsed, 'list') &&
            Object.prototype.hasOwnProperty.call(contentParsed, 'referral')) {
          listObject = {
            sold: contentParsed.sold.split(','),
            pending: contentParsed.pending.split(','),
            referral: contentParsed.referral.split(','),
            list: contentParsed.list.split(',')
          }
        } else { // If object is empty or has the wrong keys, set to empty array
          listObject = {
            sold: [],
            pending: [],
            list: [],
            referral: []
          }
        }
      }
    }
    this.state = {
      listObject,
      dialogOpen: false
    }
    this.handleChange = this.handleChange.bind(this)
  }

  scrubString (str) {
    return str.replace(/\s/g, '').replace(/\\/g, '').replace(/\}/g, '').replace(/"/g, '').replace(/\{/g, '')
  }

  handleChange = (e) => {
    const newObject = { ...this.state.listObject }
    switch (e.target.name) {
      case 'sold':
        if (newObject.sold.includes(e.target.value)) {
          newObject.sold.splice(newObject.sold.indexOf(e.target.value), 1)
        } else {
          newObject.sold.push(e.target.value)
        }
        break
      case 'pending':
        if (newObject.pending.includes(e.target.value)) {
          newObject.pending.splice(newObject.pending.indexOf(e.target.value), 1)
        } else {
          newObject.pending.push(e.target.value)
        }
        break
      case 'referral':
        if (newObject.referral.includes(e.target.value)) {
          newObject.referral.splice(newObject.referral.indexOf(e.target.value), 1)
        } else {
          newObject.referral.push(e.target.value)
        }
        break
      default:
        break
    }
    this.setState({ listObject: newObject })
  }

  moveUp (index) {
    // move item up one step in .list
    const newObject = { ...this.state.listObject }
    const temp = newObject.list[index]
    newObject.list[index] = newObject.list[index - 1]
    newObject.list[index - 1] = temp
    this.setState({ listObject: newObject })
  }

  moveDown (index) {
    // move item down one step in .list
    const newObject = { ...this.state.listObject }
    const temp = newObject.list[index]
    newObject.list[index] = newObject.list[index + 1]
    newObject.list[index + 1] = temp
    this.setState({ listObject: newObject })
  }

  deleteHandler = (ind) => {
    const newObject = this.state.listObject
    newObject.list.splice(ind, 1)
    this.setState({ listObject: newObject })
  }

  addHandler = (email) => {
    if (email !== null && this.state.listObject.list.indexOf(email) === -1) {
      this.setState({
        ...this.state,
        dialogOpen: false,
        newEmailValue: '',
        listObject: {
          sold: [...this.state.listObject.sold, email],
          pending: [...this.state.listObject.pending, email],
          list: [...this.state.listObject.list, email],
          referral: [...this.state.listObject.referral, email]
        }
      })
    }
  }

  handleSetTextValue = (event) => {
    this.setState({ newEmailValue: event.target.value })
  }

  finalize () {
    const listObj = this.state.listObject

    // if list is empty, return empty string
    if (listObj.list.length === 0) {
      return '{}'
    }

    // if email is in pending or sold but not in list, remove it
    for (let i = 0; i < listObj.sold.length; i++) {
      if (listObj.list.indexOf(listObj.sold[i]) === -1) {
        listObj.sold.splice(i, 1)
      }
    }
    for (let i = 0; i < listObj.pending.length; i++) {
      if (listObj.list.indexOf(listObj.pending[i]) === -1) {
        listObj.pending.splice(i, 1)
      }
    }
    for (let i = 0; i < listObj.referral.length; i++) {
      if (listObj.list.indexOf(listObj.referral[i]) === -1) {
        listObj.referral.splice(i, 1)
      }
    }

    const finalObject = {
      sold: listObj.sold.join(','),
      pending: listObj.pending.join(','),
      referral: listObj.referral.join(','),
      list: listObj.list.join(',')
    }

    return JSON.stringify(finalObject)
  }

  hasFinalize () {
    return true
  }

  render () {
    const rows = []
    if ('list' in this.state.listObject) {
      for (let x = 0; x < this.state.listObject.list.length; x++) {
        const row = {
          id: x,
          email: this.state.listObject.list[x]
        }
        rows.push(row)
      }
    }

    const columns = [
      {
        flex: 1,
        type: 'string',
        field: 'email',
        headerName: 'Email',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'body',
        headerName: 'Recieve Emails',
        editable: false,
        sortable: false,
        renderCell: (params) => {
          if (params.row) {
            return (
              <Grid container direction='column'>
                <Grid item>
                  <Checkbox
                    color='primary'
                    name='sold'
                    disableRipple
                    sx={{
                      paddingLeft: 0,
                      paddingY: 0
                    }}
                    value={params.row.email}
                    onChange={this.handleChange}
                    checked={this.state.listObject.sold.includes(params.row.email)}
                  />
                  <span className=''>Sold Proposals</span>
                </Grid>
                <Grid item>
                  <Checkbox
                    color='primary'
                    name='pending'
                    disableRipple
                    sx={{
                      paddingLeft: 0,
                      paddingY: 0
                    }}
                    value={params.row.email}
                    onChange={this.handleChange}
                    checked={this.state.listObject.pending.includes(params.row.email)}
                  />
                  <span className=''>Pending Proposals</span>
                </Grid>
                <Grid item>
                  <Checkbox
                    color='primary'
                    name='referral'
                    disableRipple
                    sx={{
                      paddingLeft: 0,
                      paddingY: 0
                    }}
                    value={params.row.email}
                    onChange={this.handleChange}
                    checked={this.state.listObject.referral.includes(params.row.email)}
                  />
                  <span className=''>Customer Referrals</span>
                </Grid>
              </Grid>
            )
          }
        }
      },
      {
        flex: 1,
        field: 'action',
        headerName: 'Actions',
        headerAlign: 'right',
        align: 'right',
        editable: false,
        sortable: false,
        renderCell: (params) => {
          if (params.row) {
            return (
              <Grid container wrap='nowrap' justifyContent='flex-end'>
                <Grid item>
                  <IconButton
                    title='Up'
                    aria-label='up'
                    onClick={() => this.moveUp(params.row.id)}
                    disabled={params.row.id < 1}
                  >
                    <UpIcon variant='icon' fontSize='small' />
                  </IconButton>
                  <IconButton
                    title='Down'
                    aria-label='down'
                    onClick={() => this.moveDown(params.row.id)}
                    disabled={params.row.id === this.state.listObject.list.length - 1}
                  >
                    <DownIcon variant='icon' fontSize='small' />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    title='Delete'
                    aria-label='delete'
                    onClick={() => this.deleteHandler(params.row.id)}
                  >
                    <DeleteIcon variant='icon' fontSize='small' />
                  </IconButton>
                </Grid>
              </Grid>
            )
          }
        }
      }
    ]

    return (
      <Paper sx={{ pt: 2 }} elevation={0}>
        <Box sx={{ p: 0 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            useStandardRowHeight
            rowHeight={82}
          />
        </Box>
        <Box sx={{ pb: 1, pt: 2, display: 'flex', justifyContent: 'flex-start' }}>
          <Button
            id='add-action'
            color='success'
            variant='contained'
            onClick={() => {
              this.setState({ ...this.state, dialogOpen: true })
            }}
            startIcon={<AddIcon />}
          >
            Add Email
          </Button>
        </Box>

        <Dialog open={this.state.dialogOpen} fullWidth>
          <DialogTitle sx={{ borderBottom: '1px solid rgba(0,0,0,.04)' }}>Add Email</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ mt: 2 }}>Add a new email address to receive updates.</DialogContentText>
            <Box sx={{ pt: 2 }}>
              <TextField
                sx={{ mt: 1 }}
                autoFocus
                onChange={this.handleSetTextValue}
                id='email_entry'
                label='Email Address'
                type='email'
                fullWidth
                variant='outlined'
                margin='dense'
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ p: 2, borderTop: '1px solid rgba(0,0,0,.04)' }} color='primary' style={{ justifyContent: 'space-between' }}>
            <Button
              variant='outlined'
              onClick={() => {
                this.setState({
                  ...this.state,
                  dialogOpen: false
                })
              }}
            >
              Close
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={(e) => {
                this.addHandler(this.state.newEmailValue)
              }}
              disabled={
                this.state.newEmailValue === '' ||
                this.state.listObject.list.includes(this.state.newEmailValue) ||
                !Helper.validateEmail(this.state.newEmailValue)
              }
            >
              Add Email
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    )
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} editorComponent={DistributionListEditor} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}
