import React, { useEffect, useState } from 'react'
import { DialogGeneric } from '../../../components/Reusable/DialogGeneric'
import { Autocomplete, Box, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

export const Surcharges = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [showDialog, setShowDialog] = useState(props.open)

  const [label, setLabel] = useState('')
  const [crmId, setCrmId] = useState('')
  const [type, setType] = useState('pct')
  const [amount, setAmount] = useState(0)
  const [pct, setPct] = useState(0)
  const [taxable, setTaxable] = useState(false)
  const [separateLineItem, setSeparateLineItem] = useState(false)
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (isLoading) {
      let options = []
      if (props.masterList) {
        options = props.masterList.map((p) => {
          return {
            id: p.crmId ? p.crmId : p.value,
            label: p.name,
            title: p.name
          }
        })
        options = options.sort((a, b) => a.label.localeCompare(b.label))
        setOptions(options)
      }

      setLabel(props.surchargesConfig.label)
      setAmount(props.surchargesConfig.amount)
      setType(props.surchargesConfig.type)
      setPct(props.surchargesConfig.pct)
      setTaxable(props.surchargesConfig.taxable)
      setSeparateLineItem(props.surchargesConfig.separateLineItem)
      if ('crmID' in props.surchargesConfig) {
        setCrmId(props.surchargesConfig.crmID) // YES, this is crmID for the CRM.  DEV-4855
      }
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleClose = () => {
    setShowDialog(!showDialog)
    props.onClose()
  }

  const handleSave = () => {
    const c = JSON.parse(JSON.stringify(props.surchargesConfig))
    c.label = label
    c.type = type
    c.amount = amount
    c.crmID = crmId
    c.pct = pct
    c.taxable = taxable
    c.separateLineItem = separateLineItem
    setShowDialog(!showDialog)
    props.onSave(c)
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'label') {
      setLabel(value)
    } else if (name === 'amount') {
      setAmount(value)
      setPct('')
    } else if (name === 'pct') {
      setPct(value)
      setAmount('')
    } else if (name === 'taxable') {
      setTaxable(!taxable)
    } else if (name === 'separateLineItem') {
      setSeparateLineItem(!separateLineItem)
    }
  }

  const handleChangeSelect = (e) => {
    if (e.target.name === 'type') {
      setType(e.target.value)
    }
  }

  const handleChangeProduct = (e, newProd) => {
    setCrmId(newProd)
  }

  const getSelectedProduct = (pid) => {
    let title = ''
    if (pid !== -1 && pid !== '') {
      const p = options.find((o) => o.id === pid)
      title = p.title
    }
    return title
  }

  const jsx = (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id='label'
            name='label'
            variant='outlined'
            label='Title'
            onChange={handleChange}
            value={label}
            size='small'
            sx={{
              marginBottom: '25px',
              marginTop: '25px'
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl sx={{ marginTop: '25px', marginBottom: '5px' }} size='small' fullWidth>
            <InputLabel id='demo-simple-select-label'>Deposit Type</InputLabel>
            <Select labelId='type' name='type' id='type' value={type} size='small' label='Deposit Type' onChange={handleChangeSelect}>
              <MenuItem key='amount' value='amount'>
                Amount
              </MenuItem>
              <MenuItem key='pct' value='pct'>
                Percentage
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {type === 'amount' && (
          <Grid item xs={3}>
            <TextField
              fullWidth
              id='amount'
              name='amount'
              variant='outlined'
              label='Amount'
              onChange={handleChange}
              value={amount}
              size='small'
              sx={{
                marginBottom: '25px',
                marginTop: '25px'
              }}
            />
          </Grid>
        )}

        {type === 'pct' && (
          <Grid item xs={3}>
            <TextField
              fullWidth
              id='pct'
              name='pct'
              variant='outlined'
              label='Percentage'
              onChange={handleChange}
              value={pct}
              size='small'
              sx={{
                marginBottom: '25px',
                marginTop: '25px'
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Autocomplete
            id='autocomplete-maptocrm-commission'
            name='mapToCRM'
            isOptionEqualToValue={(option, value) => {
              if (value === '') return true
              return option.title === value
            }}
            options={options}
            size='small'
            value={getSelectedProduct(crmId)}
            onChange={(e, newValue) => {
              handleChangeProduct(e, newValue && newValue.id ? newValue.id : -1)
            }}
            renderInput={(params) =>
              <TextField
                {...params}
                label='Map to Product'
              />}
            renderOption={(props, option) => {
              return (
                <Box component='li' {...props} key={option.id}>
                  <Typography variant='body'>{option.title}</Typography>
                </Box>
              )
            }}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item>
          <FormControlLabel
            name='taxable'
            sx={{ m: 0, width: '100%' }}
            control={<Switch checkedIcon={<CheckCircleRoundedIcon fontSize='small' />} onChange={handleChange} checked={!!taxable} />}
            label={<Typography variant='body2'>Taxable</Typography>}
            labelPlacement='start'
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            name='separateLineItem'
            control={<Switch checkedIcon={<CheckCircleRoundedIcon fontSize='small' />} onChange={handleChange} checked={!!separateLineItem} />}
            label={<Typography variant='body2'>Display Surcharge as Itemized Product</Typography>}
            labelPlacement='start'
          />
        </Grid>
      </Grid>
    </Box>
  )

  return <>{showDialog ? <DialogGeneric title='Surcharges' onClose={handleClose} content={jsx} fullWidth onChange={handleSave} /> : null}</>
}
