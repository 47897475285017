import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import { DialogGeneric } from './DialogGeneric'
import { LoadingPleaseWait } from '@supportworks/react-components'
// import Company from 'screens/Company'
// import H from '../../library/helper'

export const DialogCategorySelector = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [pageSize, setPageSize] = useState(10)
  const [selectedRows, setSelectedRows] = useState([]) // Array of row idx's of things that have been checked
  // const [rows, setRows] = useState([]) // Array of row objects that can be selected
  const [filteredRows, setFilteredRows] = useState([]) // Array of row objects filtered thru filterChips

  useEffect(() => {
    if (isLoading) {
      const sr = []
      const r = []
      // console.log(props)
      for (let x = 0; x < props.categoryConfig.length; x++) {
        const fc = props.categoryConfig[x]
        const l = props.list.find((o) => {
          if ('id' in o) {
            return o.id === fc.id
          } else if ('id' in o) {
            return o.id === fc.id
          } else {
            return null
          }
        })
        if (l && (l.id || l.id)) {
          sr.push(x)
        }

        r.push({
          id: x,
          title: l && l.title ? l.title : fc.title
        })
      }

      // setRows(r)
      setFilteredRows(r)
      setSelectedRows(sr)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleSave = () => {
    const list = []

    for (let x = 0; x < selectedRows.length; x++) {
      const row = props.categoryConfig[selectedRows[x]]
      list.push(row.id)
    }

    props.onSave(list)
  }

  const handleChange = (r) => {
    setSelectedRows(r)
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    { field: 'id', headerName: 'Id', hide: true },
    { field: 'title', headerName: 'Title', flex: 1 }
  ]

  const jsx = (
    <Box>
      <Box sx={{ height: 500 }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50]}
          checkboxSelection
          rowSelectionModel={selectedRows}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onRowSelectionModelChange={(rows) => {
            handleChange(rows)
          }}
          keepNonExistentRowsSelected
          sx={{
            '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
              color: '#A6B0CF',
              backgroundColor: 'transparent',
              display: props.singleUseOnly ? 'none' : ''
            }
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false
              }
            }
          }}
        />
      </Box>
    </Box>
  )

  return <DialogGeneric title={props.title} content={jsx} fullWidth onChange={handleSave} onClose={props.onClose} />
}
