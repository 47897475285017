import React, { useState, useEffect } from 'react'
import { Box, Grid, Typography, Dialog, DialogTitle, DialogContent, Button, TextField, Menu, MenuItem } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import CircleIcon from '@mui/icons-material/Circle'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { findFieldNumber } from '../PDFDocumentEditor'
import { guid } from '@supportworks/helper'

const FieldNavSelections = (props) => {
  const [showSelections, setShowSelections] = useState(false)

  // Auto-expand the form selections when clicked on a field from the CenterCanvas
  useEffect(() => {
    if (props.fieldSubIndex > -1) {
      setShowSelections(true)
    }
    // eslint-disable-next-line
  }, [props.fieldSubIndex])

  const handleSelectionAdd = () => {
    const s = {
      id: `selection-${guid()}`,
      type: 'selection',
      title: `New User Selections for Page ${props.pagenum || 1}`,
      page: props.pagenum || 1,
      selections: [{ id: `choice-${guid()}`, choice: `choice-${guid()}`, subtitle: 'New Selection' }]
    }
    setShowSelections(true)
    props.document.fields.push(s)
    props.onChange('generic', props.document)
  }

  const selections = props.field.selections
  return (
    <>
      <Box>
        <Grid container spacing={0} sx={{ p: 1 }} style={styles.navContainer}>
          <Grid item sm={1} sx={{ pl: 1 }}>
            <CheckIcon />
          </Grid>
          <Grid item sm={selections.length > 0 ? 9 : 10} sx={{ pl: 1 }}>
            <Typography sx={{ pl: 1 }} style={styles.navText}>
              User Selections
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={handleSelectionAdd}>
              <AddCircleOutlineIcon fontSize='small' />
            </IconButton>
          </Grid>
          {selections.length > 0
            ? (
              <Grid item xs={1}>
                <IconButton onClick={() => setShowSelections(!showSelections)}>{showSelections ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
              </Grid>
              )
            : null}
        </Grid>
      </Box>
      {showSelections
        ? (
          <>
            {selections.map((selection, idx) => {
              return <FieldNavSelection key={`fns-${idx}`} idx={idx} selections={selections} selection={selection} {...props} />
            })}
          </>
          )
        : null}
    </>
  )
}
export default FieldNavSelections

const FieldNavSelection = (props) => {
  const [showConfig, setShowConfig] = useState(!!(props.selection.page === props.pagenum && props.fieldSubIndex))
  const [showDialogEdit, setShowDialogEdit] = useState(false)

  // Auto-expand the form selections when clicked on a field from the CenterCanvas
  useEffect(() => {
    if (props.fieldSubIndex > -1) {
      setShowConfig(true)
    }
    // eslint-disable-next-line
  }, [props.fieldSubIndex])

  const handleAddChoice = () => {
    const idx = props.document.fields.findIndex((o) => o.id === props.selection.id)
    if (idx > -1) {
      const selection = props.document.fields[idx]
      const selections = selection.selections || []
      const c = {
        choice: `choice-${guid()}`,
        subtitle: 'New Selection'
      }
      selections.push(c)
      props.document.fields[idx] = selection
      props.onChange('generic', props.document)
    }
  }

  const handleDeleteSelection = (sel) => {
    if (window.confirm(`There is no undo for deleting a selection.  Are you sure you want to delete "${sel.title}"?`)) {
      const idx = props.document.fields.findIndex((o) => o.id === sel.id)
      if (idx > -1) {
        props.document.fields.splice(idx, 1)
      }
      props.onChange('generic', props.document)
    }
  }

  const sel = props.selection
  const idx = props.idx
  return (
    <div key={`fns-${idx}`}>
      <Box>
        <Grid container spacing={0} sx={{ p: 1, pl: 1 }} style={{ ...styles.navContainer, ...styles.navSelectionContainer }} wrap='nowrap'>
          <Grid item sm={1}>
            <FactCheckOutlinedIcon fontSize='small' />
          </Grid>
          <Grid item sm={8}>
            <Typography sx={{ pl: 1, pr: 1 }} style={styles.navText}>
              {sel.title}
            </Typography>
          </Grid>
          <Grid item sm={1}>
            <IconButton
              onClick={() => {
                setShowDialogEdit(!showDialogEdit)
              }}
            >
              <EditIcon fontSize='small' />
            </IconButton>
          </Grid>
          <Grid item sm={1}>
            <IconButton onClick={() => handleDeleteSelection(sel)}>
              <RemoveCircleOutlineIcon fontSize='small' />
            </IconButton>
          </Grid>
          <Grid item sm={1}>
            <IconButton onClick={() => setShowConfig(!showConfig)}>{showConfig ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
          </Grid>
        </Grid>
      </Box>
      {showConfig
        ? (
          <>
            <Box style={styles.navSelectionContainer}>
              <Grid container spacing={0} sx={{ p: 1 }} wrap='nowrap'>
                <Grid item sm={11} sx={{ p: 1 }}>
                  <Typography style={styles.navText}>Selections</Typography>
                </Grid>
                <Grid item sm={1}>
                  {sel.page === props.pagenum
                    ? (
                      <IconButton
                        onClick={() => {
                          handleAddChoice(idx)
                        }}
                      >
                        <AddCircleOutlineIcon fontSize='small' />
                      </IconButton>
                      )
                    : (
                      <div style={{ position: 'relative', display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                        <IconButton
                          onClick={() => {
                            props.onPageSelect(sel.page - 1)
                          }}
                        >
                          <BookmarkIcon style={{ color: 'black' }} />
                          <Typography style={styles.iconText}>{sel.page}</Typography>
                        </IconButton>
                      </div>
                      )}
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ pb: 1 }} style={styles.navSelectionContainer}>
              {sel.selections.map((choice, idx2) => {
                return <FieldNavSelectionChoice key={`fnsc-${idx}-${idx2}`} idx={idx} idx2={idx2} selection={sel} choice={choice} {...props} />
              })}
            </Box>
          </>
          )
        : null}
      {showDialogEdit ? <DialogEditSelection onClose={() => setShowDialogEdit(!showDialogEdit)} {...props} /> : null}
    </div>
  )
}

const DialogEditSelection = (props) => {
  const [title, setTitle] = useState()
  const [selection, setSelection] = useState()

  // Used to set the correct section on the LeftPanel when a field is clicked on in the CenterCanvas
  useEffect(() => {
    setTitle(props.selection.title)
    setSelection(props.selection)
    // eslint-disable-next-line
  }, [])

  const handleChange = (e) => {
    setTitle(e.target.value)
    const s = props.selection
    s.title = e.target.value
    setSelection(s)
  }

  const handleSave = () => {
    props.onChange('field', selection)
    props.onClose()
  }

  return (
    <Dialog onClose={props.onClose} open fullWidth maxWidth='md'>
      <DialogTitle className='dialog-title'>
        Edit Title
        <IconButton
          className='dialog-close'
          aria-label='close'
          onClick={() => {
            props.onClose()
          }}
          sx={{ position: 'absolute', right: 12, top: 12 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className='dialog-content' style={{ paddingTop: 8 }}>
        <Box>
          <TextField
            fullWidth
            required
            multiline
            rows={8}
            id='selection-title'
            variant='outlined'
            label='Title'
            helperText='Please enter the text to display as the title.'
            defaultValue={title}
            onChange={handleChange}
            sx={{
              'input:focus': {
                border: 'none'
              }
            }}
          />
        </Box>
      </DialogContent>
      <Box style={styles.bottomNavigation}>
        <Button
          id='cancel'
          variant='outlined'
          color='primary'
          sx={{ mr: 2 }}
          onClick={() => {
            props.onClose()
          }}
        >
          Close
        </Button>
        <Button id='save' variant='contained' color='primary' sx={{ marginLeft: 'auto' }} onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Dialog>
  )
}

const FieldNavSelectionChoice = (props) => {
  const [isDragging, setIsDragging] = useState(false)
  const [showDialogEdit, setShowDialogEdit] = useState(false)

  const handleDeleteChoice = (idx) => {
    if (window.confirm('There is no undo for deleting a selection.  Are you sure you want to delete this choice?')) {
      const idx1 = props.document.fields.findIndex((o) => o.id === props.selection.id)
      const selection = props.document.fields[idx1]
      selection.selections.splice(idx, 1)
      props.onChange('generic', props.document)
    }
  }

  const handleAdd = (variant, idx) => {
    const idx1 = props.document.fields.findIndex((o) => o.id === props.selection.id)
    const selection = props.document.fields[idx1]
    const c = { id: `choice-${guid()}`, choice: `choice-${guid()}`, subtitle: 'New Selection' }
    if (variant === 'above') {
      selection.selections.splice(idx, 0, c)
    } else {
      selection.selections.splice(idx + 1, 0, c)
    }
    props.onChange('generic', props.document)
  }

  const idx = props.idx
  const idx2 = props.idx2
  const sel = props.selection
  const choice = props.choice

  const number = findFieldNumber(props.document, props.field.selections[idx], idx2)
  return (
    <div key={`div-ffns-${idx}-${idx2}`}>
      <Grid
        container
        spacing={0}
        sx={{ pl: 2 }}
        onClick={() => {
          props.onFieldSelect(props.field.selections[idx], idx2)
        }}
      >
        <Grid item sm={1} style={{ borderLeft: '4px solid black' }}>
          &nbsp;
        </Grid>
        <Grid
          item
          sm={7}
          sx={{ p: 1 }}
          style={isDragging ? styles.fieldDragging : styles.field}
          draggable={sel.page === props.pagenum}
          onDragStart={(e) => {
            setIsDragging(true)
            props.onDragStart({ formId: props.formId, type: 'selection', field: sel, fieldValue: idx2 })
          }}
          onDragEnd={() => {
            setIsDragging(false)
            props.onDragEnd()
          }}
          onDragExit={props.onDragExit}
        >
          <Typography style={styles.navText}>
            {idx2 + 1}&nbsp;&nbsp;&nbsp;{choice.subtitle}
          </Typography>
        </Grid>

        {sel.page === props.pagenum
          ? (
            <>
              <Grid item sm={1} sx={{ ml: 2 }}>
                <IconButton onClick={() => setShowDialogEdit(!showDialogEdit)}>
                  <EditIcon fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item sm={1}>
                {number > -1
                  ? (
                    <IconButton>
                      <CircleIcon fontSize='small' style={props.fieldSubIndex === idx2 ? { color: 'blue' } : { color: 'black' }} />
                      <Typography style={styles.iconText}>{number}</Typography>
                    </IconButton>
                    )
                  : null}
              </Grid>
              <Grid item sm={1}>
                <FieldActionSelection
                  {...props}
                  selectionIndex={-1}
                  onDelete={() => handleDeleteChoice(idx2)}
                  onAddAbove={() => handleAdd('above', idx2)}
                  onAddBelow={() => handleAdd('below', idx2)}
                />
              </Grid>
            </>
            )
          : null}
      </Grid>
      {showDialogEdit ? <DialogEditChoice onClose={() => setShowDialogEdit(!showDialogEdit)} choice={choice} {...props} /> : null}
    </div>
  )
}

const DialogEditChoice = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [subtitle, setSubTitle] = useState()
  const [choice, setChoice] = useState()

  // Used to set the correct section on the LeftPanel when a field is clicked on in the CenterCanvas
  useEffect(() => {
    if (isLoading) {
      setSubTitle(props.choice.subtitle)
      setChoice(props.choice)
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleChange = (e, field) => {
    const s = props.choice
    s.subtitle = e.target.value
    setSubTitle(e.target.value)
    setChoice(s)
  }

  const handleSave = () => {
    const idx1 = props.selection.selections.findIndex((o) => o.choice === choice.choice)
    props.selection.selections[idx1] = choice
    props.onChange('field', props.selection)
    props.onClose()
  }

  if (isLoading) return <></>

  return (
    <Dialog onClose={props.onClose} open fullWidth maxWidth='md'>
      <DialogTitle className='dialog-title'>
        Edit Selection
        <IconButton
          className='dialog-close'
          aria-label='close'
          onClick={() => {
            props.onClose()
          }}
          sx={{ position: 'absolute', right: 12, top: 12 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className='dialog-content' style={{ paddingTop: 8 }}>
        <Box>
          <TextField
            fullWidth
            required
            multiline
            rows={4}
            id='choice-subtitle'
            variant='outlined'
            label='Text Description'
            helperText='Please enter the text description to display for the selection.'
            defaultValue={subtitle}
            onChange={(e) => {
              handleChange(e, 'subtitle')
            }}
            sx={{
              'input:focus': {
                border: 'none'
              }
            }}
          />
        </Box>
      </DialogContent>
      <Box style={styles.bottomNavigation}>
        <Button
          id='cancel'
          variant='outlined'
          color='primary'
          sx={{ mr: 2 }}
          onClick={() => {
            props.onClose()
          }}
        >
          Close
        </Button>
        <Button id='save' variant='contained' color='primary' sx={{ marginLeft: 'auto' }} onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Dialog>
  )
}

const FieldActionSelection = ({ field, selectionIndex = null, onFieldSelect, onDelete, onAddAbove, onAddBelow }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu id='action-menu' open={open} onClose={handleClose} anchorEl={anchorEl}>
        <MenuItem
          onClick={() => {
            onAddAbove()
            onFieldSelect(field, selectionIndex) // Force refresh
            handleClose()
          }}
        >
          Add Selection Above
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDelete()
            onFieldSelect(field, selectionIndex) // Force refresh
            handleClose()
          }}
        >
          Remove Selection
        </MenuItem>
        <MenuItem
          onClick={() => {
            onAddBelow()
            onFieldSelect(field, selectionIndex) // Force refresh
            handleClose()
          }}
        >
          Add Selection Below
        </MenuItem>
      </Menu>
    </>
  )
}

const styles = {
  navContainer: {
    alignItems: 'center',
    userSelect: 'none',
    WebkitUserSelect: 'none'
  },
  navSelectionContainer: {
    backgroundColor: 'rgba(0,0,0,.08)'
  },
  navText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 14,
    userSelect: 'none'
  },
  bottomNavigation: {
    display: 'flex',
    width: '100%',
    padding: '16px',
    borderTop: 1,
    borderColor: 'rgba(0,0,0,.16)',
    backgroundColor: 'white'
  },
  borderBottom: {
    borderBottom: 1,
    borderColor: 'rgba(0,0,0,.16)'
  },
  fieldNumber: {
    fontSize: 8
  },
  iconText: {
    position: 'absolute',
    fontSize: 8,
    fontWidth: 'bold',
    fontFamily: 'sans-serif',
    color: 'white'
  },
  field: {
    cursor: 'pointer',
    border: '1px dashed rgba(0,0,0,.04)'
  },
  fieldDragging: {
    border: '1px dashed rgba(0,0,0,.24)'
  }
}
