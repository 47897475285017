import React, { useState, useEffect } from 'react'
import { Box, Grid, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, Typography, Drawer, TextField } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import TuneIcon from '@mui/icons-material/Tune'
import DrawerHeader from '../Reusables/DrawerHeader'
import DrawerFooter from '../Reusables/DrawerFooter'
import { LoadingPleaseWait } from '@supportworks/react-components'

/** Config constant data.
 *
 * You want to change stuff in here if you are adding field types, formats, or default values.
 *
 */
const fieldHasSetting = (field, setting) => {
  // prettier-ignore
  const config = {
    media: ['includeSummary', 'includeJobInfo', 'subArea', 'subAreaOnly', 'areaValueDefault', 'quote'],
    boolean: ['includeSummary', 'includeJobInfo', 'defaultValue', 'crm', 'subArea', 'subAreaOnly', 'areaValueDefault', 'showInCustomerWhy'],
    radio: ['includeSummary', 'includeJobInfo', 'defaultValue', 'crm', 'subArea', 'subAreaOnly', 'areaValueDefault', 'showInCustomerWhy'],
    checkbox: ['includeSummary', 'includeJobInfo', 'defaultValue', 'crm', 'subArea', 'subAreaOnly', 'areaValueDefault', 'showInCustomerWhy'],
    choice: ['includeSummary', 'includeJobInfo', 'defaultValue', 'crm', 'subArea', 'subAreaOnly', 'areaValueDefault', 'showInCustomerWhy'],
    numeric: ['includeSummary', 'includeJobInfo', 'display', 'defaultValue', 'crm', 'units', 'subArea', 'subAreaOnly', 'areaValueDefault', 'format', 'showInCustomerWhy'],
    calc: ['includeSummary', 'includeJobInfo', 'display', 'defaultValue', 'crm', 'units', 'subArea', 'subAreaOnly', 'areaValueDefault', 'format', 'editable', 'showInCustomerWhy'],
    text: ['includeSummary', 'includeJobInfo', 'defaultValue', 'crm', 'subArea', 'subAreaOnly', 'areaValueDefault', 'showInCustomerWhy'],
    type: ['includeSummary', 'includeJobInfo', 'defaultValue', 'crm', 'subArea', 'subAreaOnly', 'areaValueDefault', 'showInCustomerWhy'],
    productRecommendation: ['subArea', 'subAreaOnly'],
    productRecommendationProductList: ['includeSummary', 'includeJobInfo', 'subArea', 'subAreaOnly', 'areaValueDefault'],
    title: ['subArea', 'subAreaOnly'],
    noresponse: ['subArea', 'subAreaOnly'],
    checklist: ['subArea', 'subAreaOnly'],
    pdf: ['subArea', 'subAreaOnly'],
    image: ['subArea', 'subAreaOnly'],
    video: ['subArea', 'subAreaOnly'],
    contentBlock: ['subArea', 'subAreaOnly'],
    date: ['includeSummary', 'includeJobInfo', 'defaultValue', 'crm', 'subArea', 'subAreaOnly', 'areaValueDefault', 'format', 'showInCustomerWhy'],
    time: ['includeSummary', 'includeJobInfo', 'defaultValue', 'crm', 'subArea', 'subAreaOnly', 'areaValueDefault', 'format', 'showInCustomerWhy'],
    areaName: ['includeSummary', 'includeJobInfo', 'defaultValue', 'crm', 'subArea', 'subAreaOnly', 'areaValueDefault'],
    area: ['includeSummary', 'includeJobInfo'],
    addAreaCheckbox: ['includeSummary', 'includeJobInfo'],
    relatedInspectAreas: ['includeSummary', 'includeJobInfo', 'subArea', 'subAreaOnly'],
    freeFormCapture: ['subArea', 'subAreaOnly']
  }
  if (config[field] && config[field].includes(setting)) {
    return true
  }
  return false
}

const displayFormats = [
  { name: 'Default - As Is', value: '' },
  { name: 'No Decimals (1,000)', value: '0,0' },
  { name: '2 Decimals (1,000.23)', value: '0,0.00' },
  { name: 'Currency ($1,000.23)', value: '$0,0.00' },
  { name: 'Percentage (97.8492%)', value: '0.0000%' }
]

const displayFormatsDate = [
  { name: '8/4/2035 (Month / Day / Year)', value: 'M/D/YYYY' },
  { name: '4/8/2035 (Day / Month / Year)', value: 'D/M/YYYY' },
  { name: '2035/08/04 (Year / Month / Day)', value: 'YYYY/M/D' },
  { name: 'August 4, 2035', value: 'MMMM D, YYYY' },
  { name: 'Saturday, August 4nd, 2035', value: 'dddd, MMMM DDDo, YYYY' },
  { name: 'Saturday, August 4nd', value: 'dddd, MMMM DDDo' },
  { name: '2023 (Year Only)', value: 'YYYY' }
]

const displayFormatsTime = [
  { name: '12 Hour (02:34 PM)', value: '12hr' },
  { name: '24 Hour (14:34)', value: '24hr' }
]

const defaultValues = {
  boolean: ['Yes', 'No']
}

const DrawerFieldSettings = (props) => {
  const [isLoading, setIsLoading] = useState(true)

  const [defaultValue, setDefaultValue] = useState('')
  const [crm, setCrm] = useState('')
  const [editable, setEditable] = useState(false)
  // Display section
  const [units, setUnits] = useState('')
  const [format, setFormat] = useState('')
  const [display, setDisplay] = useState(true)
  const [includeSummary, setIncludeSummary] = useState(true) // show in findings
  const [showInCustomerWhy, setShowInCustomerWhy] = useState(false) // show in customer why
  const [quote, setQuote] = useState(true) // show on contract page
  const [includeJobInfo, setIncludeJobInfo] = useState(true) // show in job information page
  // Sub Areas section
  const [subArea, setSubArea] = useState(false) // include in sub area
  const [subAreaOnly, setSubAreaOnly] = useState(false) // include in sub area only
  const [areaValueDefault, setAreaValueDefault] = useState(false) // duplicate primary form values into sub area

  useEffect(() => {
    if (isLoading) {
      const f = props.field
      if (f.defaultValue !== undefined) {
        setDefaultValue(f.default)
      }
      if (f.crm !== undefined) {
        setCrm(f.crm)
      }
      if (f.editable !== undefined) {
        setEditable(f.editable)
      }
      if (f.units !== undefined) {
        setUnits(f.units)
      }
      if (f.format !== undefined) {
        setFormat(f.format)
      }
      if (f.display !== undefined) {
        setDisplay(f.display)
      }
      if (f.includeSummary !== undefined) {
        setIncludeSummary(f.includeSummary)
      }
      if (f.showInCustomerWhy !== undefined) {
        setShowInCustomerWhy(f.showInCustomerWhy)
      }
      if (f.quote !== undefined) {
        setQuote(f.quote)
      }
      if (f.includeJobInfo !== undefined) {
        setIncludeJobInfo(f.includeJobInfo)
      }
      if (f.subArea !== undefined) {
        setSubArea(f.subArea)
      }
      if (f.subAreaOnly !== undefined) {
        setSubAreaOnly(f.subAreaOnly)
      }
      if (f.areaValueDefault !== undefined) {
        setAreaValueDefault(f.areaValueDefault)
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const handleClose = (e) => {
    props.onClose()
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'defaultValue') {
      setDefaultValue(value)
    } else if (name === 'crm') {
      setCrm(value)
    } else if (name === 'editable') {
      setEditable(!editable)
    } else if (name === 'units') {
      setUnits(value)
    } else if (name === 'format') {
      setFormat(value)
    } else if (name === 'display') {
      setDisplay(!display)
    } else if (name === 'includeSummary') {
      setIncludeSummary(!includeSummary)
    } else if (name === 'showInCustomerWhy') {
      setShowInCustomerWhy(!showInCustomerWhy)
    } else if (name === 'quote') {
      setQuote(!quote)
    } else if (name === 'includeJobInfo') {
      setIncludeJobInfo(!includeJobInfo)
    } else if (name === 'subArea') {
      setSubArea(!subArea)
    } else if (name === 'subAreaOnly') {
      setSubAreaOnly(!subAreaOnly)
    } else if (name === 'areaValueDefault') {
      setAreaValueDefault(!areaValueDefault)
    }
  }

  const handleDone = (e) => {
    const field = JSON.parse(JSON.stringify(props.field))
    if (fieldHasSetting(props.field.type, 'defaultValue')) field.default = defaultValue
    if (fieldHasSetting(props.field.type, 'crm')) field.crm = crm
    if (fieldHasSetting(props.field.type, 'editable')) field.editable = editable
    if (fieldHasSetting(props.field.type, 'units')) field.units = units
    if (fieldHasSetting(props.field.type, 'format')) field.format = format
    if (fieldHasSetting(props.field.type, 'display')) field.display = display
    if (fieldHasSetting(props.field.type, 'includeSummary')) field.includeSummary = includeSummary
    if (fieldHasSetting(props.field.type, 'showInCustomerWhy')) field.showInCustomerWhy = showInCustomerWhy
    if (fieldHasSetting(props.field.type, 'quote')) field.quote = quote
    if (fieldHasSetting(props.field.type, 'includeJobInfo')) field.includeJobInfo = includeJobInfo
    if (fieldHasSetting(props.field.type, 'subArea')) field.subArea = subArea
    if (fieldHasSetting(props.field.type, 'subAreaOnly')) field.subAreaOnly = subAreaOnly
    if (fieldHasSetting(props.field.type, 'areaValueDefault')) field.areaValueDefault = areaValueDefault
    // console.log(JSON.stringify(field, null, 2));
    props.onDone(field)
  }

  if (isLoading) return <LoadingPleaseWait />

  let dFormats = displayFormats
  if (props.field.type === 'date') {
    dFormats = displayFormatsDate
  } else if (props.field.type === 'time') {
    dFormats = displayFormatsTime
  }

  // Figure out default drop down values based on stuff.
  let defValues = []
  if (defaultValues[props.field.type] !== undefined) {
    // I have something that is hardcoded as a constant above.
    defValues = defaultValues[props.field.type]
  } else if (Array.isArray(props.field.list)) {
    // I have a field that has a list.  Use that list.
    defValues = props.field.list
  } else if (Array.isArray(props.field.choices)) {
    defValues = props.field.choices
  }

  return (
    <Drawer
      key='drawer-field-settings'
      open={props.open}
      PaperProps={{
        sx: { width: '560px !important' }
      }}
      anchor='right'
      variant='temporary'
      onKeyDown={stopPropagationForTab}
      onClose={handleClose}
    >
      <DrawerHeader icon={<TuneIcon style={{ color: 'rgba(0,0,0,.66)' }} />} title='Field Settings' onClose={handleClose} />
      <Box sx={{ p: 2 }} style={styles.content}>
        <Grid container>
          {fieldHasSetting(props.field.type, 'defaultValue')
            ? (
              <>
                {defValues.length > 0
                  ? (
                    <Grid item xs={12} sx={{ pb: 2 }}>
                      <FormControl fullWidth sx={{ pb: { sm: 1 }, 'input:focus': { border: 'none' } }}>
                        <InputLabel id='label-defaultValue'>Default Value</InputLabel>
                        <Select
                          fullWidth
                          disabled={props.selectedIndex > -1}
                          id='defaultValue'
                          name='defaultValue'
                          label='Default Value'
                          labelId='defaultValue'
                          variant='outlined'
                          value={defaultValue || ''}
                          onChange={handleChange}
                          sx={{ height: 50 }}
                        >
                          {defValues.map((d, idx) => {
                            if (d && d.id) {
                              return (
                                <MenuItem key={`MenuItem-defaultValue-choice-${idx}`} value={d.id}>
                                  <span style={styles.textField}>{d.title}</span>
                                </MenuItem>
                              )
                            } else {
                              return (
                                <MenuItem key={`MenuItem-defaultValue-list-${idx}`} value={d}>
                                  <span style={styles.textField}>{d}</span>
                                </MenuItem>
                              )
                            }
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    )
                  : (
                    <Grid item xs={12} sx={{ pb: 2 }}>
                      <TextField
                        id='defaultValue'
                        name='defaultValue'
                        label='Default Value'
                        fullWidth
                        variant='outlined'
                        value={defaultValue}
                        onChange={handleChange}
                      />
                    </Grid>
                    )}
              </>
              )
            : null}

          {fieldHasSetting(props.field.type, 'crm')
            ? (
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ pb: { sm: 2 }, 'input:focus': { border: 'none' } }}>
                  <InputLabel id='label-crm'>Map to CRM Field</InputLabel>
                  <Select
                    fullWidth
                    disabled={props.selectedIndex > -1}
                    id='crm'
                    name='crm'
                    label='Map to CRM Field'
                    labelId='crm'
                    variant='outlined'
                    value={crm || ''}
                    onChange={handleChange}
                    sx={{ height: 50 }}
                  >
                    {props.crmFieldsConfig.map((p, idx) => {
                      return (
                        <MenuItem key={`MenuItem-crmId-${idx}`} value={p.value}>
                          <span style={styles.textField}>{p.name}</span>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              )
            : null}
        </Grid>

        {fieldHasSetting(props.field.type, 'editable')
          ? (
            <Grid container>
              <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Typography variant='body2'>Allow Users to Edit Calculation</Typography>
              </Grid>
              <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <FormControlLabel
                  name='editable'
                  sx={{ pr: 0, mr: -1 }}
                  control={
                    <Switch
                      checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                      id='editable'
                      checked={editable === true}
                      color='primary'
                      onClick={handleChange}
                      sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    />
                }
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            )
          : null}

        <Grid container spacing={1}>
          {fieldHasSetting(props.field.type, 'units') ||
          fieldHasSetting(props.field.type, 'format') ||
          fieldHasSetting(props.field.type, 'display') ||
          fieldHasSetting(props.field.type, 'includeSummary') ||
          fieldHasSetting(props.field.type, 'showInCustomerWhy') ||
          fieldHasSetting(props.field.type, 'includeJobInfo')
            ? (
              <Grid item xs={12} sx={{ pb: 1 }}>
                <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                  Display
                </Typography>
              </Grid>
              )
            : null}

          {fieldHasSetting(props.field.type, 'units')
            ? (
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ pb: { sm: 1 }, 'input:focus': { border: 'none' } }}>
                  <InputLabel id='label-units'>Unit Type</InputLabel>
                  <Select
                    fullWidth
                    disabled={props.selectedIndex > -1}
                    id='units'
                    name='units'
                    label='Unit Type'
                    labelId='units'
                    variant='outlined'
                    value={units || ''}
                    onChange={handleChange}
                    sx={{ height: 50 }}
                  >
                    {props.numericUnitsConfig && props.numericUnitsConfig.map((p, idx) => {
                      return (
                        <MenuItem key={`MenuItem-units-${idx}`} value={p.id}>
                          <span style={styles.textField}>{p.title}</span>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              )
            : null}

          {fieldHasSetting(props.field.type, 'format')
            ? (
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ pb: { sm: 1 }, 'input:focus': { border: 'none' } }}>
                  <InputLabel id='label-format'>Display Format</InputLabel>
                  <Select
                    fullWidth
                    disabled={props.selectedIndex > -1}
                    id='format'
                    name='format'
                    label='Display Format'
                    labelId='format'
                    variant='outlined'
                    value={format || ''}
                    onChange={handleChange}
                    sx={{ height: 50 }}
                  >
                    {dFormats.map((p, idx) => {
                      return (
                        <MenuItem key={`MenuItem-format-${idx}`} value={p.value}>
                          <span style={styles.textField}>{p.name}</span>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              )
            : null}
        </Grid>

        {fieldHasSetting(props.field.type, 'display')
          ? (
            <Grid container>
              <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Typography variant='body2'>Visible in App</Typography>
              </Grid>
              <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <FormControlLabel
                  name='display'
                  sx={{ pr: 0, mr: -1 }}
                  control={
                    <Switch
                      checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                      id='display'
                      checked={display === true}
                      color='primary'
                      onClick={handleChange}
                      sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    />
                }
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            )
          : null}

        {fieldHasSetting(props.field.type, 'includeSummary')
          ? (
            <Grid container>
              <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Typography variant='body2'>Show in Findings Screen</Typography>
              </Grid>
              <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <FormControlLabel
                  name='includeSummary'
                  sx={{ pr: 0, mr: -1 }}
                  control={
                    <Switch
                      checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                      id='includeSummary'
                      checked={includeSummary === true}
                      color='primary'
                      onClick={handleChange}
                      sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    />
                }
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            )
          : null}

        {fieldHasSetting(props.field.type, 'showInCustomerWhy')
          ? (
            <Grid container>
              <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Typography variant='body2'>Show in Customer Why</Typography>
              </Grid>
              <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <FormControlLabel
                  name='showInCustomerWhy'
                  sx={{ pr: 0, mr: -1 }}
                  control={
                    <Switch
                      checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                      id='showInCustomerWhy'
                      checked={showInCustomerWhy === true}
                      color='primary'
                      onClick={handleChange}
                      sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    />
                }
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            )
          : null}

        {fieldHasSetting(props.field.type, 'quote')
          ? (
            <Grid container>
              <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Typography variant='body2'>Show on Contract Cover Page</Typography>
              </Grid>
              <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <FormControlLabel
                  name='quote'
                  sx={{ pr: 0, mr: -1 }}
                  control={
                    <Switch
                      checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                      id='quote'
                      checked={quote === true}
                      color='primary'
                      onClick={handleChange}
                      sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    />
                }
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            )
          : null}

        {fieldHasSetting(props.field.type, 'includeJobInfo')
          ? (
            <Grid container>
              <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Typography variant='body2'>Show on Job Information Page</Typography>
              </Grid>
              <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <FormControlLabel
                  name='includeJobInfo'
                  sx={{ pr: 0, mr: -1 }}
                  control={
                    <Switch
                      checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                      id='includeJobInfo'
                      checked={includeJobInfo === true}
                      color='primary'
                      onClick={handleChange}
                      sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    />
                }
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            )
          : null}

        <Grid container>
          {fieldHasSetting(props.field.type, 'subArea') ||
          fieldHasSetting(props.field.type, 'subAreaOnly') ||
          fieldHasSetting(props.field.type, 'areaValueDefault')
            ? (
              <Grid item xs={12} sx={{ pt: 1, pb: 1 }}>
                <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                  Sub Areas
                </Typography>
              </Grid>
              )
            : null}

          {fieldHasSetting(props.field.type, 'subArea')
            ? (
              <Grid container>
                <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Typography variant='body2'>Include in Sub Area</Typography>
                </Grid>
                <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <FormControlLabel
                    name='subArea'
                    sx={{ pr: 0, mr: -1 }}
                    control={
                      <Switch
                        checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                        id='subArea'
                        checked={subArea === true}
                        color='primary'
                        onClick={handleChange}
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                      />
                  }
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              )
            : null}

          {fieldHasSetting(props.field.type, 'subAreaOnly')
            ? (
              <Grid container>
                <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Typography variant='body2'>Include in Sub Only</Typography>
                </Grid>
                <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <FormControlLabel
                    name='subAreaOnly'
                    sx={{ pr: 0, mr: -1 }}
                    control={
                      <Switch
                        checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                        id='subAreaOnly'
                        checked={subAreaOnly === true}
                        color='primary'
                        onClick={handleChange}
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                      />
                  }
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              )
            : null}

          {fieldHasSetting(props.field.type, 'areaValueDefault')
            ? (
              <Grid container>
                <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Typography variant='body2'>Duplicate Primary Form Values into Sub Area</Typography>
                </Grid>
                <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <FormControlLabel
                    name='areaValueDefault'
                    sx={{ pr: 0, mr: -1 }}
                    control={
                      <Switch
                        checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                        id='areaValueDefault'
                        checked={areaValueDefault === true}
                        color='primary'
                        onClick={handleChange}
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                      />
                  }
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              )
            : null}
        </Grid>
      </Box>

      <DrawerFooter onClose={handleClose} onDone={handleDone} />
    </Drawer>
  )
}
export default DrawerFieldSettings

const styles = {
  content: {
    overflowX: 'hidden'
  },
  textField: {
    fontSize: 14,
    paddingRight: 4
  }
}
