import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Collapse,
  Grid,
  Paper,
  Popover,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography
  , Dialog, DialogContent, DialogTitle, IconButton, TableCell
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser'
import ReadMoreIcon from '@mui/icons-material/ReadMore'
import Api from '../../library/Api'
import Company from '../../screens/Company'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import ErrorMessage from 'components/Reusable/ErrorMessage'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import H from '../../library/helper'
import Helper from '@supportworks/helper'
import { JSONEditor } from '../../components/Reusable'
import LazyLoad from 'react-lazyload'
import { LoadingPleaseWait } from '@supportworks/react-components'
import withScreenWrapper from '../../screens/withScreenWrapper'
import { fieldTypeConfig } from '../../screens/Config/index'
import '../../scss/proposal.scss'

const paymentXLat = {
  Cash: 'Cash Deposit',
  Credit: 'Credit Card Deposit',
  Check: 'Check Deposit',
  Financing: 'Financing Total'
}

const ProposalView = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [proposal, setProposal] = useState({})
  const [version, setVersion] = useState()
  const [viewProposalContent, setViewProposalContent] = useState(false)
  const [showGrantAccessDialog, setShowGrantAccessDialog] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    console.log('useEffect start [] triggered')
    if (isLoading) {
      if (!props.proposal) {
        Api.callRegisteredMethod('getProposalFull', { proposalId: props.proposalId }).then((proposal) => {
          if (proposal) {
            setProposal(proposal)
            let version = '1.0'
            if (proposal.content?.appInfo?.version) {
              version = proposal.content.appInfo.version || 0
              version = version.replace('-test', '')
            }
            setVersion(version)
            if (proposal.error) {
              setError(proposal.error)
            }
          } else {
            setError('There was an error encountered loading this proposal.')
          }
          setIsLoading(false)
        })
      } else {
        setProposal(props.proposal)
        setIsLoading(false)
      }
    }
    // eslint-disable-next-line
  }, []) 

  const handleGrantAccess = async (value) => {
    await Api.callRegisteredMethod('getProposalAccess', { proposalId: proposal.id, value }).then((results) => {
      if (results && results[0] && results[0].status === 'success') {
        window.alert(`Emails or text messages have been triggered for: ${value}`)
      } else {
        setError('There was an error trying to grant access to this proposal.  Please contact your account representative.')
      }
      setShowGrantAccessDialog(false)
    })
  }

  if (isLoading) return <LoadingPleaseWait />
  if (error) return <ErrorMessage description={error} />

  const customerInfo = proposal.customer_info
  let attachments = []
  if (proposal.attachments) {
    attachments = proposal.attachments.filter((o) => o.contentType.match('jpg') && o.contentUri)
  }

  return (
    <>
      <div className='ProposalView'>
        <Company.Header title={`${customerInfo.firstName} ${customerInfo.lastName} Proposal`} />
      </div>
      <AppointmentInformationView proposal={proposal} />
      <DocumentsView proposal={proposal} />
      <ImagesView attachments={attachments} />
      {Helper.compareVersion(version, '1.1.31') > -1 ? <AppointmentBreakdownView proposal={proposal} /> : null}
      <Box>
        {proposal
          ? (
            <Button variant='contained' onClick={() => setViewProposalContent(!viewProposalContent)} sx={{ mr: 1 }}>
              {viewProposalContent ? 'Hide' : 'Show'} Proposal Content
            </Button>
            )
          : (
            <h2>Proposal Content Unavailable</h2>
            )}
        <Button variant='contained' onClick={() => setShowGrantAccessDialog(!showGrantAccessDialog)} startIcon={<OpenInBrowserIcon />}>
          Send Customer Portal Access to Customer
        </Button>
      </Box>
      {viewProposalContent
        ? (
          <div className='row'>
            <div className='col'>
              <JSONEditor value={JSON.stringify(proposal, null, 2)} />
            </div>
          </div>
          )
        : null}

      {showGrantAccessDialog
        ? (
          <DialogGrantAccess
            email={customerInfo.email}
            onChange={(email) => handleGrantAccess(email)}
            onClose={() => setShowGrantAccessDialog(!showGrantAccessDialog)}
          />
          )
        : null}
    </>
  )
}

const DialogGrantAccess = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [email, setEmail] = useState(null)

  useEffect(() => {
    if (isLoading) {
      setEmail(props.email)
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'email') {
      setEmail(value)
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  const jsx = (
    <Grid container sx={{ pt: 1 }}>
      <Grid item xs={12} sx={{ pb: 3 }}>
        <Typography variant='body2'>
          Please enter email addresses or telephone numbers for text messages, separated by a comma. A notification message will be sent to each with a code and
          URL to access the customer portal.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          id='email'
          name='email'
          variant='outlined'
          label='Notification Targets'
          helperText='Please enter the emails and/or telephone numbers separated by a comma.'
          value={email || ''}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  )

  return (
    <DialogGeneric
      title='Send Customer Portal Access via Email or Text Message'
      content={jsx}
      fullWidth
      onChange={() => props.onChange(email)}
      onClose={props.onClose}
    />
  )
}

const AppointmentInformationView = ({ proposal }) => {
  const [open, setOpen] = useState(true)

  let jsx
  try {
    const customerInfo = proposal.customer_info
    const apptInfo = proposal.content.appt_info
    const disposition = proposal.content.disposition
    const totals = proposal.content.full.new.options.totals.find((o) => o.selected === true)
    jsx = (
      <Collapse in={open} timeout='auto' unmountOnExit>
        <Table aria-label='customized table' sx={{ borderBottom: '2px solid #2a3042' }}>
          <TableBody>
            <TableRow>
              <TableCell>
                <strong>Customer</strong>
              </TableCell>
              <TableCell>
                {customerInfo.firstName} {customerInfo.lastName}
              </TableCell>
              <TableCell>
                <strong>Appointment</strong>
              </TableCell>
              <TableCell>
                {customerInfo.crmIdText}&nbsp;
                {apptInfo && apptInfo.start
                  ? (
                    <>
                      {Helper.formatDate(apptInfo.start, 'mmmm dd, yyyy')} at {Helper.formatDate(apptInfo.start, 'hh:MM TT')}
                    </>
                    )
                  : (
                      '-'
                    )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Address</strong>
              </TableCell>
              <TableCell>
                {[customerInfo.address1, customerInfo.address2, customerInfo.address3].filter(Boolean).join('\r\n')}
                <br />
                {customerInfo.city} {customerInfo.state} {customerInfo.zip}
              </TableCell>
              <TableCell>
                <strong>Contact</strong>
              </TableCell>
              <TableCell>
                {customerInfo.email
                  ? (
                    <>
                      <a href={`mailto:${customerInfo.email}`}>{customerInfo.email}</a>
                      <br />
                    </>
                    )
                  : (
                    <>
                      No email
                      <br />
                    </>
                    )}
                {customerInfo.email2
                  ? (
                    <>
                      {customerInfo.email2} (secondary)
                      <br />
                    </>
                    )
                  : null}
                {customerInfo.mobile
                  ? (
                    <>
                      {Helper.formatPhoneNumber(customerInfo.mobile)} (Mobile)
                      <br />
                    </>
                    )
                  : (
                    <>
                      No mobile telephone
                      <br />
                    </>
                    )}
                {customerInfo.mobilePreferred
                  ? (
                    <>
                      {Helper.formatPhoneNumber(customerInfo.mobilePreferred)} (Mobile preferred)
                      <br />
                    </>
                    )
                  : null}
                {customerInfo.mobile2
                  ? (
                    <>
                      {Helper.formatPhoneNumber(customerInfo.mobile2)} (Mobile secondary)
                      <br />
                    </>
                    )
                  : null}
                {customerInfo.mobile2Preferred
                  ? (
                    <>
                      {Helper.formatPhoneNumber(customerInfo.mobile2Preferred)} (Mobile secondary preferred)
                      <br />
                    </>
                    )
                  : null}
                {customerInfo.landline
                  ? (
                    <>
                      {Helper.formatPhoneNumber(customerInfo.landline)} (Home)
                      <br />
                    </>
                    )
                  : (
                    <>
                      No home telephone
                      <br />
                    </>
                    )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Prepared By</strong>
              </TableCell>
              <TableCell>
                {[proposal.author.nameLast, proposal.author.nameFirst].filter(Boolean).join(', ')} <br />
                {proposal.author.title
                  ? (
                    <>
                      {proposal.author.title}
                      <br />
                    </>
                    )
                  : null}
                {proposal.author.phoneWork
                  ? (
                    <>
                      {proposal.author.phoneWork}
                      <br />
                    </>
                    )
                  : null}
                {proposal.author.email
                  ? (
                    <>
                      {proposal.author.email}
                      <br />
                    </>
                    )
                  : null}
              </TableCell>
              <TableCell>
                <strong>Status</strong>
              </TableCell>
              <TableCell>
                {disposition.result}
                <br />
                {totals !== undefined
                  ? (
                    <>
                      {Helper.formatDollar(totals.total)} (List Price)
                      <br />
                      {totals.discount > 0
                        ? (
                          <>
                            {Helper.formatDollar(totals.discount)} (Discount)
                            <br />
                          </>
                          )
                        : null}
                      {Helper.formatDollar(totals.total_w_taxes)} (Contract Price)
                    </>
                    )
                  : null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Created</strong>
              </TableCell>
              <TableCell>
                {proposal.created
                  ? (
                    <>
                      {Helper.formatDate(proposal.created, 'mmmm dd, yyyy')} at {Helper.formatDate(proposal.created, 'hh:MM TT')}
                    </>
                    )
                  : (
                      '-'
                    )}
              </TableCell>
              <TableCell>
                <strong>Last Updated</strong>
              </TableCell>
              <TableCell>
                {proposal.updated
                  ? (
                    <>
                      {Helper.formatDate(proposal.updated, 'mmmm dd, yyyy')} at {Helper.formatDate(proposal.updated, 'hh:MM TT')}
                    </>
                    )
                  : (
                      '-'
                    )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Collapse>
    )
  } catch (err) {
    console.log(`AppointmentInformationView ERROR: ${err.stack}`)
    jsx = <Box sx={{ p: 2 }}>Data is not available.</Box>
  }
  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer component={Paper} elevation={1}>
        <Table aria-label='customized table' sx={{ borderBottom: '2px solid #2a3042' }}>
          <TableHead>
            <TableRow onClick={() => setOpen(!open)}>
              <TableCell>Customer Overview</TableCell>
              <TableCell align='right'>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {jsx}
      </TableContainer>
    </Box>
  )
}

const DocumentsView = ({ proposal }) => {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [attachments, setAttachments] = useState([])
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('created')
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    console.log('useEffect start [] triggered')
    if (isLoading) {
      if (proposal.attachments) {
        const arr = proposal.attachments.filter((o) => (o.tag === null || o.isMerged === true) && o.contentType === 'application/pdf' && o.contentUri)
        arr.sort((a, b) => (a.created < b.created ? 1 : -1))
        setAttachments(arr)
      }
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  const handlePopup = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handlePopupClose = () => {
    setAnchorEl(null)
  }

  const handleRequestSort = (property) => {
    if (order === 'asc') {
      setOrder('desc')
    } else {
      setOrder('asc')
    }
    setOrderBy(property)
  }

  const sorted = attachments || []
  let jsx
  try {
    const sendHistory = proposal.content.full.send_history || []
    if (order === 'asc') {
      sorted.sort((a, b) => (a[orderBy] > b[orderBy] ? 1 : -1))
    } else {
      sorted.sort((a, b) => (a[orderBy] < b[orderBy] ? 1 : -1))
    }
    jsx = (
      <Collapse in={open} timeout='auto' unmountOnExit>
        <Table aria-label='simple table' sx={{ borderBottom: '2px solid #2a3042' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 245 }}>
                <TableSortLabel
                  active={orderBy === 'created'}
                  direction={orderBy === 'created' ? order : 'asc'}
                  onClick={(e) => {
                    handleRequestSort('created')
                  }}
                >
                  <span style={{ color: '#A6B0CF' }}>Sent to SolutionView Cloud</span>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={(e) => {
                    handleRequestSort('name')
                  }}
                >
                  <span style={{ color: '#A6B0CF' }}>Files</span>
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: '10px' }}>
                <TableSortLabel
                  active={orderBy === 'result'}
                  direction={orderBy === 'result' ? order : 'asc'}
                  onClick={(e) => {
                    handleRequestSort('result')
                  }}
                >
                  <span style={{ color: '#A6B0CF' }}>Status</span>
                </TableSortLabel>
              </TableCell>
              <TableCell>Project</TableCell>
              {/* eslint-disable-next-line no-constant-condition */}
              {true
                ? null
                : <TableCell>Comment</TableCell>}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sorted.map((attachment, idx) => {
              const history = sendHistory.find((o) => o.version === attachment.version)
              let timestamp = attachment.created
              let result = '-'
              let projectName = ''
              if ('projectId' in attachment) {
                const projectOption = proposal.content?.totals?.option_details?.find((o) => o.projectId === attachment.projectId)
                if (projectOption) {
                  projectName = projectOption.projectName
                }
              }
              let comment
              if (history) {
                ({ timestamp, result, comment } = history)
              }
              return (
                <TableRow key={`DocView-${idx}`}>
                  <TableCell>
                    {Helper.formatDate(timestamp, 'mmmm dd, yyyy')} at {Helper.formatDate(timestamp, 'h:MM TT')}
                    <br />
                  </TableCell>
                  <TableCell>
                    {attachment.contentUri
                      ? (
                        <a href={attachment.contentUri} target='_blank' rel='noreferrer'>
                          {attachment.name}
                        </a>
                        )
                      : (
                          attachment.name
                        )}
                    &nbsp; {Helper.abbreviateNumber(attachment.size)}
                  </TableCell>
                  <TableCell>{result}</TableCell>
                  <TableCell>{projectName}</TableCell>
                  {/* eslint-disable-next-line no-constant-condition */}
                  {true
                    ? null
                    : (
                      <TableCell>
                        {comment
                          ? (
                            <>
                              {comment.length > 50
                                ? (
                                  <>
                                    <span id={`popover-${idx}`} onClick={handlePopup}>
                                      {comment.substring(0, 50) + '...'} <ReadMoreIcon />
                                    </span>
                                    <Popover
                                      id={`popover-${idx}`}
                                      open={Boolean(anchorEl)}
                                      anchorEl={anchorEl}
                                      onClose={handlePopupClose}
                                      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                    >
                                      <Typography sx={{ p: 2, width: 240 }}>{comment}</Typography>
                                    </Popover>
                                  </>
                                  )
                                : (
                                  <span>{comment}</span>
                                  )}
                            </>
                            )
                          : (
                              '-'
                            )}
                      </TableCell>
                      )}
                  <TableCell>
                    {attachment.contentUri
                      ? (
                        <a href={attachment.contentUri} target='_blank' rel='noreferrer'>
                          Download Packet
                        </a>
                        )
                      : (
                          'Not available'
                        )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Collapse>
    )
  } catch (err) {
    console.log(`DocumentsView ERROR: ${err.stack}`)
    jsx = <Box sx={{ p: 2 }}>Data is not available.</Box>
  }
  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer component={Paper}>
        <Table aria-label='customized table' sx={{ borderBottom: '2px solid #2a3042' }}>
          <TableHead>
            <TableRow onClick={() => setOpen(!open)}>
              <TableCell>Documents</TableCell>
              <TableCell align='right'>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {jsx}
      </TableContainer>
    </Box>
  )
}

const ImagesView = ({ attachments }) => {
  const [open, setOpen] = useState(false)

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer component={Paper}>
        <Table aria-label='customized table' sx={{ borderBottom: '2px solid #2a3042' }}>
          <TableHead>
            <TableRow onClick={() => setOpen(!open)}>
              <TableCell>Images ({attachments.length})</TableCell>
              <TableCell align='right'>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <ImagesGrid images={attachments} />
      </Collapse>
    </Box>
  )
}

const ImagesGrid = ({ images, attachments }) => {
  return (
    <Grid container spacing={0}>
      {images.map((row, idx) => {
        if (row.ref) {
          const lookup = attachments.find((o) => parseInt(o.externalRef, 10) === row.ref)
          if (lookup && lookup.contentUri) {
            row.contentUri = lookup.contentUri
            row.description = lookup.description
            row.name = lookup.name
          }
        }
        return (
          <Grid key={`image-${idx}`} item xs={5} sm={4} lg={3} className='grid-container'>
            <Grid container direction='column' className='grid-sub-container'>
              <Grid item className='grid-item-image-container'>
                <center>
                  <LazyLoad>
                    {row.contentUri
                      ? (
                        <a href={row.contentUri} target='_blank' rel='noreferrer'>
                          <img className='grid-item-image' src={row.contentUri} alt={row.name} />
                        </a>
                        )
                      : (
                          'No Image'
                        )}
                  </LazyLoad>
                </center>
              </Grid>
              <Grid item className='grid-item-info'>
                <center>
                  <div style={{ paddingBottom: '16px' }}>
                    <span className='image-title'>Title</span>
                    <br />
                    <span className='image-title-value'>{row.name}</span>
                    <br />
                  </div>
                  {row.description
                    ? (
                      <div>
                        <span className='image-title'>Description</span>
                        <br />
                        <span className='image-title-value'>{row.description}</span>
                      </div>
                      )
                    : null}
                </center>
              </Grid>
            </Grid>
          </Grid>
        )
      })}
    </Grid>
  )
}

const AppointmentBreakdownView = ({ proposal }) => {
  const [open, setOpen] = useState(true)

  return (
    <Box sx={{ width: '100%' }}>
      <Paper elevation={1}>
        <TableContainer component={Paper}>
          <Table aria-label='customized table' sx={{ borderBottom: '2px solid #2a3042' }}>
            <TableHead>
              <TableRow onClick={() => setOpen(!open)}>
                <TableCell>Appointment Breakdown</TableCell>
                <TableCell align='right'>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <AppointmentOverview proposal={proposal} />
          <AppointmentInspect proposal={proposal} />
          <AppointmentPresent proposal={proposal} />
          <AppointmentProject proposal={proposal} />
          <AppointmentComplete proposal={proposal} />
        </Collapse>
      </Paper>
    </Box>
  )
}

const AppointmentOverview = ({ proposal }) => {
  const [showGrantAccessDialog, setShowGrantAccessDialog] = useState('')

  // I'm throwing all this in a try/catch because we have a massive JSON object, where all the parts are supposed to be there, with no guarantee.
  let jsx
  try {
    // We're all mad here.  Go into the rabbit hole.
    const full = proposal.content.full
    const fullConfig = full.full_config
    const appointmentType = fullConfig.appointment_type
    const types = appointmentType.types.filter((o) => o.id === full.apptType)
    const forms = fullConfig.forms
    const masterAnswers = full.new.reasons.form
    jsx = (
      <>
        {types[0].intro.map((appt, idx) => {
          const formId = appt.formId
          /** Go through all my form sections, all fields, do a total count
           *  while bouncing the matching answers off the master list in full.new.reasons.form
           *  while bouncing the matching answers off the master list in full.new.reasons.form
           *
           */
          const form = forms.find((o) => o.id === formId)
          const masterForm = fullConfig.forms.find((o) => o.id === appt.formId)
          let answered = 0
          let total = 0
          if (form && form.formSection) {
            // console.log(`FIGURING OUT ${masterForm.formTitle}`)
            const formSection = form.formSection
            for (let x = 0; x < formSection.length; x++) {
              const f = formSection[x]
              const fields = f.fields
              for (let y = 0; y < fields.length; y++) {
                if (isCompletedField(masterForm, fields[y].id)) {
                  total = total + 1
                  if (masterAnswers[fields[y].id]) {
                    answered = answered + 1 // hooray!
                  }
                }
              }
            }
          }
          return (
            <div key={`overview-${idx}`} className='breakdown-container'>
              <span className='breakdown-title'>{appt.title}</span>
              <br />
              {total > 0
                ? (
                  <span
                    className='breakdown-completed'
                    onClick={() => {
                      setShowGrantAccessDialog(appt.formId)
                    }}
                  >
                    {answered} of {total} Fields Completed
                  </span>
                  )
                : (
                  <span>No user input fields</span>
                  )}
              {showGrantAccessDialog === appt.formId ? <DialogFormAnswers form={form} answers={masterAnswers} onClose={setShowGrantAccessDialog} /> : null}
            </div>
          )
        })}
      </>
    )
  } catch (err) {
    console.log(`AppointmentOverview ERROR: ${err.stack}`)
    jsx = <Box>Data is not available.</Box>
  }
  return (
    <TableContainer component={Paper}>
      <Table style={{ flex: 1, flexDirection: 'row' }}>
        <TableBody>
          <TableRow className='table-row'>
            <TableCell className='breakdown-table-cell-left'>
              <span className='breakdown-section-title'>Overview</span>
            </TableCell>
            <TableCell className='breakdown-table-cell-right'>{jsx}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const AppointmentInspect = ({ proposal }) => {
  const [showGrantAccessDialog, setShowGrantAccessDialog] = useState('')
  const [showGrantAccessDialogImages, setShowGrantAccessDialogImages] = useState(null)

  let attachments = []
  if (proposal.attachments) {
    attachments = proposal.attachments.filter((o) => o.contentType.match('jpg') && o.contentUri)
  }

  let jsx
  try {
    const full = proposal.content.full
    const fullConfig = full.full_config
    const appointmentType = fullConfig.appointment_type
    const types = appointmentType.types.filter((o) => o.id === full.apptType)
    const inspect = types[0].inspect.filter((o) => o.visible === true || o.visible === false)
    jsx = (
      <>
        {inspect.map((appt, idx) => {
          const form = full.new.inspect.types[appt.type]
          const masterForm = fullConfig.forms.find((o) => o.id === appt.formId)
          const jsxArray = []
          console.log(`FIGURING OUT ${masterForm.formTitle}`)
          Object.keys(form).forEach((key, idx2) => {
            const display = displayInspectType(form[key])
            if (!display) {
              return
            }
            let answered = 0
            let total = 0
            Object.entries(form[key].form).forEach(([fieldId, value]) => {
              try {
                // Check if this is a "completed field"
                if (isCompletedField(masterForm, fieldId)) {
                  total++
                  if ((typeof value === 'string' && value) || (typeof value === 'object' && value.length > 0)) {
                    console.log(`ANSWERED Field ${fieldId} is a string or object. It is a ${typeof value} with a value of ${value}`)
                    answered++
                  } else {
                    console.log(`SKIPPING Field ${fieldId} is not a string or object. It is a ${typeof value} with a value of ${value}`)
                  }
                }
              } catch (err) {
                console.log(`AppointmentInspect ERROR: ${err.stack}`)
              }
            })
            console.log(`${masterForm.formTitle} answered: ${answered} total: ${total}`)

            // Category & x of x display
            const uniqueId = `${appt.formId}-${appt.type}-${key}`
            let jsxInner = (
              <Box key={`inspect-${idx}-${key}}`}>
                <Typography sx={{ pt: idx2 === 0 ? 0 : 1, fontWeight: 'bold' }}>{form[key].name ? form[key].name : appt.title}</Typography>
                {total > 0
                  ? (
                    <span
                      className='breakdown-completed'
                      onClick={() => {
                        setShowGrantAccessDialog(uniqueId)
                      }}
                    >
                      {answered} of {total} Fields Completed
                    </span>
                    )
                  : (
                    <span>No user input fields</span>
                    )}
                {showGrantAccessDialog === uniqueId
                  ? (
                    <DialogFormAnswers form={masterForm} answers={form[key].form} onClose={setShowGrantAccessDialog} />
                    )
                  : null}
              </Box>
            )
            jsxArray.push(jsxInner)

            // Issues display
            jsxInner = ''
            const issues = form[key].issues
            jsxInner = (
              <Box key={`issues-${idx}-${key}`} className='breakdown-subcontainer'>
                {
                  issues.length > 0 && (
                    // ? (
                    <div key={`div-${issues}-${idx}-${key}`}>
                      <span className='breakdown-subtitle'>
                        <li key={`li-issues-${idx}-${key}`}>Issues</li>
                      </span>
                      <ul>
                        {issues &&
                          issues.map((issue, idx2) => {
                            const photos = issue.photos.length || 0
                            const i = fullConfig.issues.find((o) => o.id === issue.id)
                            return (
                              <div key={`issue-${idx2}`}>
                                <li key={`li-issue-${i.title}-${idx2}`}>
                                  {i.title}
                                  {photos > 0
                                    ? (
                                      <span>
                                        {' '}
                                        - {photos} {photos === 1 ? 'Photo' : 'Photos'}
                                      </span>
                                      )
                                    : (
                                        'No Photos Added'
                                      )}{' '}
                                </li>
                              </div>
                            )
                          })}
                      </ul>
                    </div>
                  )
                  /* ) : (
                    <span>
                      <li key={`li-no-issues-${idx}-${key}`}>No Issues Added</li>
                    </span>
                  ) */
                }
              </Box>
            )
            jsxArray.push(jsxInner)

            // Solutions display
            jsxInner = ''
            const solutions = form[key].solutions
            jsxInner = (
              <Box key={`solutions-${idx}-${key}`}>
                {solutions.length > 0
                  ? (
                    <div className='breakdown-subcontainer'>
                      <span className='breakdown-subtitle'>
                        <li key={`solutions-${idx}-${key}`}>Solutions</li>
                      </span>
                      <ul>
                        {solutions &&
                        solutions.map((solution, idx2) => {
                          const products = []
                          let numberOfProducts = 0
                          let totalPrice = 0
                          const fix = solution.products.fix
                          for (let x = 0; x < fix.length; x++) {
                            if (!fix[x].quantity) {
                              continue
                            }
                            let p = fullConfig.product_list.find((o) => o.id === fix[x].id)
                            if (p && p.price) {
                              numberOfProducts++
                              totalPrice += fix[x].quantity * p.price
                              products.push({ ...fix[x], price: p.price, title: p.title, description: p.description })
                            } else if (fix[x].configuration) {
                              // Need to look up the products here from master config, loop, and get prices.
                              const config = H.getProductsFromConfig(fullConfig.product_config, fix[x])
                              // DEV-4597 Skip the thing if it's not in the master
                              const cp = config.product
                              if (cp !== undefined) {
                                for (let y = 0; y < cp.length; y++) {
                                  numberOfProducts++
                                  p = fullConfig.product_list.find((o) => o.id === cp[y])
                                  if (p) {
                                    totalPrice += fix[x].quantity * p.price
                                    products.push({ ...fix[x], price: p.price, title: p.title, description: p.description })
                                  }
                                }
                              } else {
                                console.log(`Could not find product for ${JSON.stringify(config)}`)
                              }
                            }
                          }
                          const s = fullConfig.solutions.find((o) => o.id === solution.id)
                          const uniqueId = `${s.id}-${idx2}-${key}`
                          return (
                            <div key={`solution-${idx2}`}>
                              <li
                                key={`li-${s.title}-${idx2}`}
                                onClick={() => {
                                  setShowGrantAccessDialog(uniqueId)
                                }}
                              >
                                {s.title}
                                <span className='breakdown-info'>
                                  {' '}
                                  - {numberOfProducts} {numberOfProducts === 1 ? 'Product' : 'Products'},{' '}
                                </span>
                                <span className='breakdown-info'>{Helper.formatDollar(totalPrice)} msrp</span>
                              </li>
                              {showGrantAccessDialog === uniqueId
                                ? (
                                  <DialogProductBreakdown solution={s} products={products} onClose={setShowGrantAccessDialog} />
                                  )
                                : null}
                            </div>
                          )
                        })}
                      </ul>
                    </div>
                    )
                  : (
                    <span>
                      <li key={`no-solutions-${idx}-${key}`}>No Solutions Added</li>
                    </span>
                    )}
              </Box>
            )
            jsxArray.push(jsxInner)

            // Photos display
            const photos = form[key].photos
            const uniqueIdPhotos = `photos-${idx}-${key}`
            if (photos && photos.length > 0) {
              jsxInner = (
                <div key={uniqueIdPhotos}>
                  <span className='breakdown-completed'>
                    <li
                      key={`li-photos-${idx}-${key}`}
                      onClick={() => {
                        setShowGrantAccessDialogImages(uniqueIdPhotos)
                      }}
                    >
                      Photos ({photos.length})
                    </li>
                  </span>
                  {showGrantAccessDialogImages === uniqueIdPhotos
                    ? (
                      <DialogImages
                        images={photos}
                        attachments={attachments}
                        onClose={() => {
                          setShowGrantAccessDialogImages(null)
                        }}
                      />
                      )
                    : null}
                </div>
              )
            } else {
              jsxInner = <li key={`no-photos-${idx}-${key}`}>No Photos Added</li>
            }
            jsxArray.push(jsxInner)
          })

          if (jsxArray.length > 0) {
            return (
              <Box key={`inspect-${idx}`} className='breakdown-container'>
                {jsxArray}
              </Box>
            )
          }
          return null
        })}
      </>
    )
  } catch (err) {
    console.log(`AppointmentInspect ERROR: ${err.stack}`)
    jsx = <Box>Data is not available.</Box>
  }
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow className='table-row'>
            <TableCell className='breakdown-table-cell-left'>
              <span className='breakdown-section-title'>Inspect</span>
            </TableCell>
            <TableCell className='breakdown-table-cell-right'>{jsx}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const AppointmentPresent = ({ proposal }) => {
  const jsxArray = []
  try {
    const full = proposal.content.full
    const appointmentType = full.full_config.appointment_type
    const appointment = appointmentType.types.find((o) => o.id === full.apptType)
    const presentation = appointment.presentation
    const presentations = full.new.presentations || []
    const overviewVideo = appointment.findings.find((o) => o.type === 'overview')
    let overviewResource
    const bucketed = []
    if (full && full.resourceUsage && overviewVideo.asset) {
      overviewResource = full.resourceUsage[overviewVideo.asset]
      bucketed.push(overviewVideo.asset)
    }
    let jsx = (
      <div key='presentations' style={{ flex: 1 }}>
        {presentation.viewAboutUs
          ? (
            <div className='breakdown-container'>
              <span className='breakdown-title'>About Us</span>
              <br />
              <div className='breakdown-subcontainer'>
                <span>{presentation.viewAboutUs}</span>
              </div>
            </div>
            )
          : null}
        {overviewVideo
          ? (
            <div className='breakdown-container'>
              <span className='breakdown-title'>Overview Video</span>
              <br />
              <div className='breakdown-subcontainer'>
                <span>{overviewVideo.header.title}</span>
                {overviewResource
                  ? (
                    <>
                      {overviewResource.viewed ? <span>, Viewed </span> : <span>, Not Viewed</span>}
                      <span>({(overviewResource.progress * 100).toFixed(0)}%)</span>
                    </>
                    )
                  : (
                    <>
                      <span>Missing overview resource data</span>
                    </>
                    )}
              </div>
            </div>
            )
          : null}
        {presentations.length > 0
          ? (
            <div className='breakdown-container'>
              <span className='breakdown-title'>Presentations</span>
              <br />
              <div className='breakdown-subcontainer'>
                {presentations.map((pres, idx) => {
                  const p = full.full_config.presentations.find((o) => o.id === pres.id)
                  if (p && p.asset) {
                    bucketed.push(p.asset)
                    let usage = { progress: 0 }
                    if (full.resourceUsage && full.resourceUsage[p.asset]) {
                      usage = full.resourceUsage[p.asset]
                    }
                    return (
                      <div key={`pres-${idx}`}>
                        <span>{p.title}</span>
                        {pres.viewed ? <span>, Viewed </span> : <span>, Not Viewed</span>}
                        {usage.progress ? <span> ({(usage.progress * 100).toFixed(0)}%)</span> : null}
                        <br />
                      </div>
                    )
                  }
                  return null
                })}
              </div>
            </div>
            )
          : (
            <span>
              <li key='li-no-presentations'>No Presentations Viewed</li>
            </span>
            )}
      </div>
    )
    jsxArray.push(jsx)

    const assets2 = proposal.content.full.full_config.assets2
    const additional = []
    if (full.resourceUsage) {
      Object.keys(full.resourceUsage).forEach((key) => {
        if (bucketed.includes(key)) return
        additional.push(key)
      })
    }
    if (additional.length > 0) {
      jsx = (
        <div key='additionals' className='breakdown-container' style={{ flex: 1 }}>
          <span className='breakdown-title'>Additional Resources</span>
          <br />
          {additional.map((key, idx) => {
            const resource = assets2.find((o) => o.id === key)
            if (resource) {
              return (
                <div key={`additional-${idx}`}>
                  <span>{resource.title}</span>
                  {full.resourceUsage[key] && full.resourceUsage[key].viewed ? <span>, Viewed </span> : <span>, Not Viewed</span>}
                  {full.resourceUsage[key] && full.resourceUsage[key].progress ? <span> ({(full.resourceUsage[key].progress * 100).toFixed(0)}%)</span> : null}
                  <br />
                </div>
              )
            } else {
              return null
            }
          })}
        </div>
      )
      jsxArray.push(jsx)
    }
  } catch (err) {
    console.log(`AppointmentPresent ERROR: ${err.stack}`)
    jsxArray.push(<Box>Data is not available.</Box>)
  }
  return (
    <TableContainer component={Paper}>
      <Table aria-label='customized table'>
        <TableBody>
          <TableRow className='table-row'>
            <TableCell className='breakdown-table-cell-left'>
              <span className='breakdown-section-title'>Presentation</span>
            </TableCell>
            <TableCell className='breakdown-table-cell-right'>
              <div style={{ display: 'flex' }}>{jsxArray}</div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const AppointmentProject = ({ proposal }) => {
  let jsx
  try {
    const full = proposal.content.full
    const totals = proposal.content.totals.option_details
    const projectCount = totals.length

    // Figure finance.
    const financeLabels = Array(projectCount);
    [...Array(projectCount)].map((x, idx) => {
      if ('finance' in totals[idx]) {
        financeLabels[idx] = totals[idx].finance.label
      } else {
        const financeid = full.new.options.settings.finance
        const finance = full.full_config.contract.finance.find((o) => o.id === financeid)
        if (finance && finance.label) {
          financeLabels[idx] = finance.label
        } else {
          financeLabels[idx] = 'No Financing Plan Applied'
        }
      }
      return null
    })

    // Figure tax output.
    const taxLabels = Array(projectCount);
    [...Array(projectCount)].map((x, idx) => {
      if ('tax_info' in totals[idx]) {
        taxLabels[idx] = totals[idx].tax_info.label
      } else {
        const taxid = full.new.options.settings.taxes
        const taxes = full.full_config.contract.taxes.find((o) => o.id === taxid)
        if (taxes && taxes.length > 0) {
          taxLabels[idx] = `${taxes.label}, ${taxes.amount}`
        } else {
          taxLabels[idx] = 'No Taxes Applied'
        }
      }
      return null
    })

    jsx = (
      <Table aria-label='customized table'>
        <TableBody>
          <TableRow>
            <TableCell>
              <strong>Customer Projects</strong>
            </TableCell>
            {[...Array(projectCount)].map((x, idx) => (
              <ProjectSolutions key={`cp-${idx}`} proposal={proposal} index={idx} />
            ))}
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Project Total</strong>
            </TableCell>
            {[...Array(projectCount)].map((x, idx) => (
              <TableCell key={`totals-${idx}`}>{Helper.formatDollar(totals[idx].total)}</TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Discounts</strong>
              <br />
            </TableCell>
            {[...Array(projectCount)].map((x, idx) => (
              <ProjectDiscounts key={`pd-${idx}`} proposal={proposal} index={idx} />
            ))}
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Taxes</strong>
            </TableCell>
            {[...Array(projectCount)].map((x, idx) => (
              <TableCell key={`tax-${idx}`} sx={{ whiteSpace: 'nowrap' }}>
                {taxLabels[idx]}
                <br />
                {Helper.formatDollar(totals[idx].taxes)}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Financing Plans</strong>
              <br />
              Estimated payment per month
            </TableCell>
            {[...Array(projectCount)].map((x, idx) => (
              <TableCell key={`estpay-${idx}`} sx={{ whiteSpace: 'nowrap' }}>
                {financeLabels[idx]}
                <br />
                {totals[idx].monthly ? <>{Helper.formatDollar(totals[idx].monthly)}/mo</> : 'N/A'}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Total Contract Price</strong>
            </TableCell>
            {[...Array(projectCount)].map((x, idx) => (
              <TableCell key={`tcp-${idx}`}>{Helper.formatDollar(totals[idx].total_w_taxes)}</TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    )
  } catch (err) {
    console.log(`AppointmentProject ERROR: ${err.stack}`)
    jsx = <Box>Data is not available.</Box>
  }
  return (
    <TableContainer component={Paper}>
      <Table aria-label='customized table'>
        <TableBody>
          <TableRow className='table-row'>
            <TableCell className='breakdown-table-cell-left'>
              <span className='breakdown-section-title'>Project</span>
            </TableCell>
            <TableCell className='breakdown-table-cell-right'>{jsx}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const ProjectDiscounts = ({ proposal, index }) => {
  const totals = proposal.content.totals.option_details[index]
  const discountDetails = totals.discount_details
  const discounts = discountDetails.discounts || []
  return (
    <TableCell>
      {Object.keys(discounts).map((key, idx) => {
        return (
          <div key={`discount-${key}-${idx}`} style={Object.keys(discounts).length !== idx + 1 ? { paddingBottom: '8px' } : null}>
            {discounts[key].title}
            <br />
            {Helper.formatDollar(discounts[key].discount)}
          </div>
        )
      })}
    </TableCell>
  )
}

/** Used to loop display 3 project options for the AppointmentProject display */
const ProjectSolutions = ({ proposal, index }) => {
  const totals = proposal.content.totals.option_details[index]
  let projects = []
  // need to get actual prduct options as they aren't in order of selected
  let projectCorrectedIndex = 0
  if (proposal.content && proposal.content.subarea_products) {
    projectCorrectedIndex = proposal.content.subarea_products.findIndex((s) => s.projectId === proposal.content.totals.option_details[index].projectId)
  }

  if (proposal.content.all_products) {
    projects = proposal.content.all_products[projectCorrectedIndex]
  }
  let lastAreaTitle = ''
  const projectName = totals.projectName && totals.projectName.trim() !== '' ? totals.projectName : 'Project ' + (projectCorrectedIndex + 1)
  return (
    <TableCell>
      <strong>{projectName}</strong>
      {totals.selected
        ? (
          <>
            <CheckIcon sx={{ color: 'green' }} />
            <span style={{ color: 'green' }}>Selected</span>
          </>
          )
        : null}
      <br />
      <br />
      {projects?.length === 0 ? <span>N/A</span> : null}
      {projects?.map((project, idx) => {
        const products = project.products
        let jsxAreaTitle = ''
        if (lastAreaTitle !== project.areaTitle) {
          lastAreaTitle = project.areaTitle
          jsxAreaTitle = (
            <span className='font-xs-b'>
              {project.areaTitle}
              <br />
              <br />
            </span>
          )
        }
        return (
          <div key={`ps-project-${idx}`}>
            {jsxAreaTitle}
            <span className='font-s-b'>
              {project.solutionTitle}
              <br />
            </span>
            {products.map((product, idx2) => {
              return (
                <div className='font-xs-n' key={`ps-project-${idx2}`}>
                  {product.fullName} QTY {product.quantity}, {Helper.formatDollar(product.totalPrice)}
                  <br />
                  {idx2 === products.length - 1 ? <br /> : null}
                </div>
              )
            })}
          </div>
        )
      })}
    </TableCell>
  )
}

const AppointmentComplete = ({ proposal }) => {
  const [showGrantAccessDialog, setShowGrantAccessDialog] = useState('')

  let jsx
  try {
    const full = proposal.content.full
    const payment = full.proposal.payment
    const proposalDrawing = full.proposalDrawing
    const esigTypes = full.esigTypes
    const otherSigs = []
    const pdfPlacement = full.full_config.pdf_placement
    const disposition = full.disposition
    jsx = (
      <>
        <div className='breakdown-container'>
          <span className='breakdown-title'>Payment</span>
          <br />
          <div className='breakdown-subcontainer'>
            {payment && payment.list.length === 0 ? <span>No payments</span> : null}
            {payment.list.map((item, idx) => {
              return (
                <span key={`item-${idx}`}>
                  {paymentXLat[item.type]} of ${item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  {item.memo ? <>, Memo: {item.memo}</> : ', No memo included'}
                  <br />
                </span>
              )
            })}
          </div>
        </div>
        <div className='breakdown-container'>
          <span className='breakdown-title'>Drawings</span>
          <br />
          {proposalDrawing && proposalDrawing.main
            ? (
              <>
                {proposalDrawing.main.map((drawing, idx) => {
                  return (
                    <div key={`drawing-${idx}`}>
                      <span className='breakdown-completed' key={`drawing-${idx}`} onClick={() => setShowGrantAccessDialog(drawing.ref)}>
                        View Drawing - {drawing.name}
                      </span>
                      {showGrantAccessDialog === drawing.ref
                        ? (
                          <DialogDrawing
                            drawing={drawing}
                            attachments={proposal.attachments}
                            onClose={() => {
                              setShowGrantAccessDialog(null)
                            }}
                          />
                          )
                        : null}
                    </div>
                  )
                })}
              </>
              )
            : (
              <span>No drawing</span>
              )}
        </div>
        <div className='breakdown-container'>
          <span className='breakdown-title'>Signatures</span>
          <br />
          {esigTypes
            ? (
              <>
                <span>Customer</span>
                {esigTypes.main && esigTypes.main.data ? <CheckIcon sx={{ color: 'green' }} /> : ' - none'}
                <br />
                <span>Sales Rep</span>
                {esigTypes.authorized && esigTypes.authorized.data ? <CheckIcon sx={{ color: 'green' }} /> : ' - none'}
                <br />
                {Object.entries(esigTypes).forEach(([key, value]) => {
                  if (!key.match(/main|authorized/)) {
                    let title = 'User Doc'
                    let sig = 'Customer Sig'
                    const [idRef, sigRef] = key.split(':')
                    const lookupTitle = pdfPlacement.find((o) => (o.id = idRef))
                    if (lookupTitle && lookupTitle.title) {
                      title = lookupTitle.title
                      const lookupSig = lookupTitle.fields.find((o) => o.id === sigRef)
                      if (lookupSig) sig = lookupSig.title
                    }
                    otherSigs.push(
                      <span key={`ac-span-${key}`}>
                        {title}, {sig} <CheckIcon sx={{ color: 'green' }} />
                        <br />
                      </span>
                    )
                  }
                })}
                {otherSigs}
              </>
              )
            : (
              <span>No signatures</span>
              )}
        </div>
        <div className='breakdown-container'>
          <span className='breakdown-title'>Result</span>
          <br />
          <div className='breakdown-subcontainer'>
            <span>{disposition && disposition.result ? disposition.result : '-'}</span>
          </div>
        </div>
        <div className='breakdown-container'>
          <span className='breakdown-title'>Comment</span>
          <br />
          <div className='breakdown-subcontainer'>
            <span>{disposition && disposition.comment ? disposition.comment : '-'}</span>
          </div>
        </div>
      </>
    )
  } catch (err) {
    console.log(`AppointmentComplete ERROR: ${err.stack}`)
    jsx = <Box>Data is not available.</Box>
  }
  return (
    <TableContainer component={Paper}>
      <Table aria-label='customized table'>
        <TableBody>
          <TableRow className='table-row'>
            <TableCell className='breakdown-table-cell-left'>
              <span className='breakdown-section-title'>Complete</span>
            </TableCell>
            <TableCell className='breakdown-table-cell-right'>{jsx}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const DialogFormAnswers = ({ form, answers, onClose }) => {
  let jsx
  try {
    if (form && form.formSection) {
      jsx = (
        <>
          {form.formSection.map((section, idx) => {
            const sectionTitle = section.sectionTitle
            let sectionText = section.sectionTitle
            if (typeof sectionText === 'object') {
              // WT*
              sectionText = ''
              for (let x = 0; x < sectionTitle.length; x++) {
                sectionText = sectionText + `${sectionTitle[x].title} `
              }
            }
            return (
              <div key={`dialog-form-answers-section-${idx}`} style={{ paddingBottom: '24px' }}>
                {sectionText ? <div className='dialog-section-title'>{sectionText}</div> : null}
                {section.fields.map((field, idx2) => {
                  const answer = answers[field.id]
                  let jsx = <>{answer}</>
                  if (typeof answer !== 'string') {
                    jsx = <>Complex Answer</>
                    if (typeof answer === 'object') {
                      if (Array.isArray(answer)) {
                        jsx = <>Image</>
                      } else {
                        jsx = <>{Object.keys(answer).join(', ')}</>
                      }
                    } else if (!answer) {
                      jsx = <>-</>
                    }
                  }
                  return (
                    <div key={`dialog-form-answers-${idx2}`} className='dialog-content-fq'>
                      <div style={{ flex: 3 }}>{field.title || ''}</div>
                      <div style={{ flex: 1 }}>{jsx}</div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </>
      )
    }
  } catch (err) {
    console.log(`DialogFormAnswers error: ${err.stack}`)
    jsx = <Box>Data is not available.</Box>
  }
  return (
    <Dialog onClose={onClose} open fullWidth maxWidth='md'>
      <DialogTitle className='dialog-title'>
        {form.formTitle}
        <IconButton className='dialog-close' aria-label='close' onClick={onClose} sx={{ position: 'absolute', right: 12, top: 12 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className='dialog-content'>{jsx}</DialogContent>
    </Dialog>
  )
}

const DialogImages = ({ images, attachments, onClose }) => {
  let jsx
  try {
    jsx = <ImagesGrid images={images} attachments={attachments} />
  } catch (err) {
    console.log(`DialogImages error: ${err.stack}`)
    jsx = <Box sx={{ p: 2 }}>Data is not available.</Box>
  }

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth='md'>
      <DialogTitle className='dialog-title'>
        Photos
        <IconButton className='dialog-close' aria-label='close' onClick={onClose} sx={{ position: 'absolute', right: 12, top: 12 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className='dialog-content'>{jsx}</DialogContent>
    </Dialog>
  )
}

const DialogDrawing = ({ drawing, attachments, onClose }) => {
  const lookup = attachments.find((o) => parseInt(o.externalRef, 10) === drawing.ref)
  let contentUri
  let name
  if (lookup && lookup.contentUri) {
    contentUri = lookup.contentUri
    name = lookup.name
  }
  let jsx
  try {
    jsx = (
      <>
        {contentUri
          ? (
            <a href={contentUri} target='_blank' rel='noreferrer'>
              <img style={{ maxWidth: '100%', maxHeight: 'calc(100vh - 172px)' }} src={contentUri} alt={name} />
            </a>
            )
          : (
            <span>Image could not be found.</span>
            )}
      </>
    )
  } catch (err) {
    console.log(`DialogDrawing error: ${err.stack}`)
    jsx = <Box sx={{ p: 2 }}>Data is not available.</Box>
  }

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth='md'>
      <DialogTitle className='dialog-title'>
        Drawing
        <IconButton className='dialog-close' aria-label='close' onClick={onClose} sx={{ position: 'absolute', right: 12, top: 12 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className='dialog-content'>{jsx}</DialogContent>
    </Dialog>
  )
}

const DialogProductBreakdown = ({ solution, products, onClose }) => {
  let jsx
  try {
    let total = 0
    jsx = (
      <TableContainer component={Paper}>
        <Table style={{ flex: 1, flexDirection: 'row' }}>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell align='right'>Price</TableCell>
              <TableCell align='right'>Quantity</TableCell>
              <TableCell align='right'>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, idx) => {
              total += product.price * product.quantity
              return (
                <TableRow key={`dpr-str-${idx}`}>
                  <TableCell>{product.title}</TableCell>
                  <TableCell align='right'>{Helper.formatDollar(product.price)}</TableCell>
                  <TableCell align='right'>{product.quantity}</TableCell>
                  <TableCell align='right'>{Helper.formatDollar(product.price * product.quantity)}</TableCell>
                </TableRow>
              )
            })}
            <TableRow key='dpr-str-total' variant='footer'>
              <TableCell colspan={3}>Total</TableCell>
              <TableCell align='right'>{Helper.formatDollar(total)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  } catch (err) {
    console.log(`DialogProductBreakdown error: ${err.stack}`)
    jsx = <Box sx={{ p: 2 }}>Data is not available.</Box>
  }

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth='md'>
      <DialogTitle className='dialog-title'>
        {solution.title}
        <IconButton className='dialog-close' aria-label='close' onClick={onClose} sx={{ position: 'absolute', right: 12, top: 12 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className='dialog-content'>{jsx}</DialogContent>
    </Dialog>
  )
}

/**
 * displayInspectType()
 * DEV-2999
 * Figure out if the full.new.inspect.types[key] has complete data.  This is used in the Inspect & Project logic.
 * - If valid has something and it's false, do not display.
 * - If none of the forms[key] elements have data, do not display
 *
 * @param {*} type
 * @returns
 */
const displayInspectType = (type) => {
  if (type.valid !== undefined && type.valid === false) return false
  let found = 0;
  ['form', 'issues', 'photos', 'settings', 'solutions'].forEach((k) => {
    if (type[k] !== undefined) {
      if (type[k].constructor === Array && type[k].length > 0) {
        found++
      } else if (type[k].constructor === Object) {
        found++
      }
    }
  })
  if (!found) return false
  return true
}

const isCompletedField = (form, fieldId) => {
  const formSection = form.formSection
  for (let x = 0; x < formSection.length; x++) {
    const f = formSection[x]
    const fields = f.fields
    for (let y = 0; y < fields.length; y++) {
      if (fields[y].id === fieldId) {
        // console.log(JSON.stringify(fieldTypeConfig));
        if (fieldTypeConfig[fields[y].type] && fieldTypeConfig[fields[y].type].completedFieldInProposal === true) {
          // console.log(`YES! Field ${fields[y].type} ${JSON.stringify(fieldTypeConfig[fields[y].type])} is a completed field`)
          return true
        } else {
          // console.log(`NO! Field ${fields[y].type} ${JSON.stringify(fieldTypeConfig[fields[y].type])} is a completed field`)
          return false
        }
      }
    }
  }
  return false
}

export default withScreenWrapper(ProposalView)
