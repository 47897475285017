import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import Api, { ApiPluginAuth, ApiPluginRipple } from 'library/Api'
import { ApiPluginUsers } from 'library/Api/ApiPluginUsers'
import { ApiPluginRippleConfig } from './library/Api/ApiPluginRippleConfig'
import { ApiPluginAppointments } from './library/Api/ApiPluginAppointments'
import { ApiPluginRippleProposal } from 'library/Api/ApiPluginRippleProposal'
import { ApiPluginRippleMedia } from './library/Api/ApiPluginRippleMedia'
import { ApiPluginRippleCloud } from './library/Api/ApiPluginRippleCloud'
import Company from './screens/Company'
import Config from 'config'
import LocalStorage from 'library/LocalStorage'
import { LoginScreenHook } from 'screens/LoginScreen'
import Reusable, { LoadingOverlay } from './components/Reusable'
import AppRunning from './AppRunning'
import SplashScreen from 'screens/SplashScreen'
import StatusOverlay from 'components/Reusable/StatusOverlay'
import { Topics } from 'config/topics'
import UploadOverlay from './components/Upload/UploadOverlay'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import './scss/styles.scss'

/**
 * Main application framework.
 */
class App extends React.Component {
  static PHASE_INIT = 'init'
  static PHASE_LOGIN = 'login'
  static PHASE_RUNNING = 'running'

  /**
   * @type {ApiBase}
   */
  api

  /**
   * @type {Object.<string, Subscription>}
   */
  subs

  /**
   *
   * @param {Object} props
   * @param {string} props.topic Topic on which to listen for status messages. The messages can be a string or object
   *      with a "message" property, a "className" property (error), and an optional timeout value (30) after which
   *      the message will disappear.
   */
  constructor (props) {
    super(props)

    this.api = Api.factory({
      baseUrl: process.env.REACT_APP_CORE_URL,
      appShortCode: 'rippleAdmin'
    })
    this.api.registerPlugin(ApiPluginAuth, { baseUrl: this.api.getBaseUrl() + '/authapi/v1' })
    this.api.registerPlugin(ApiPluginRipple, { baseUrl: this.api.getBaseUrl() + '/api/rippleadmin/v1' })
    this.api.registerPlugin(ApiPluginUsers, { baseUrl: this.api.getBaseUrl() + '/api/rippleadmin/v1' })
    this.api.registerPlugin(ApiPluginRippleConfig, { baseUrl: this.api.getBaseUrl() + '/api/rippleadmin/v1' })
    this.api.registerPlugin(ApiPluginAppointments, { baseUrl: this.api.getBaseUrl() + '/api/ripple/v1' })
    this.api.registerPlugin(ApiPluginRippleProposal, { baseUrl: this.api.getBaseUrl() + '/api/rippleadmin/v1' })
    this.api.registerPlugin(ApiPluginRippleMedia, { baseUrl: this.api.getBaseUrl() + '/api/rippleadmin/v1' })
    this.api.registerPlugin(ApiPluginRippleCloud, { baseUrl: this.api.getBaseUrl() + '/api/rippleadmin/v1' })
    this.api.registerMethod('app-logout', this.logout.bind(this))

    this.state = {
      phase: AppRunning.PHASE_INIT,
      hasToken: false,
      hasValidToken: false
    }

    this.subs = {
      hasSecurityToken: Topics.subscribe(Topics.Login, this.hasSecurityToken.bind(this)),
      logoutSuccess: Topics.subscribe(Topics.Logout, this.logout.bind(this)),
      validSession: Topics.subscribe(Topics.ValidateSession, this.receiveValidateSessionResponse.bind(this)),
      logout: Topics.subscribe(Topics.Logout, this.logout.bind(this)),
      login: Topics.subscribe(Topics.Login, this.receiveLoginResponse.bind(this))
    }
  }

  hasSecurityToken (data) {
    // Need to validate session
    LoadingOverlay.message('Verifying session. Please wait.', 30)
  }

  logout (data) {
    Api.callRegisteredMethod('logout')
    LocalStorage.clear()
    StatusOverlay.message('Logging out...', 3)
    this.setState({ phase: App.PHASE_LOGIN })
  }

  getBaseUrl (data) {
    return Api.getBaseUrl()
  }

  componentDidMount () {
    if (!this.state.phase || this.state.phase === App.PHASE_INIT) {
      window.localStorage.setItem('currentScreen', 'Dashboard')
      // Check for token
      if (Api.callRegisteredMethod('isAuthenticated')) {
        // validate session
        StatusOverlay.message('Verifying session...', 5)
        // This will publish its findings to the topic passed.
        Api.callRegisteredMethod('validateSession', { topic: Topics.ValidateSession })
      } else {
        StatusOverlay.message('Login Required...', 5)
        this.setState({ phase: App.PHASE_LOGIN })
      }
    }
  }

  /**
   * Receives a response from the authentication API call. If the data is invalid or false, this will
   * show an error and trigger the login phase.
   *
   * @param {Object|boolean} data
   */
  receiveLoginResponse (data) {
    const rsp = data.data
    if (rsp.status === 'success') {
      // validate session
      StatusOverlay.message('Verifying session...', 5)
      // This will publish its findings to the topic passed.
      Api.callRegisteredMethod('validateSession', { topic: Topics.ValidateSession })
    } else {
      StatusOverlay.message('Failed to log in.', 5)
    }
  }

  /**
   * Receives a response from the validateSession API call.  If the data is invalid or false, this will
   * trigger the login phase. Otherwise, it will trigger the run phase.
   *
   * @param {Object|boolean} data
   */
  receiveValidateSessionResponse (data) {
    LoadingOverlay.hide()
    const rsp = data.data
    if (rsp.status === 'success') {
      // Iterate over response, placing items into LocalStorage.
      // person
      if (rsp.data && rsp.data[0] && rsp.data[0].person) {
        LocalStorage.store(Config.personKey, rsp.data[0].person)
      } else {
        LocalStorage.remove(Config.personKey)
      }
      // organizations
      if (rsp.data && rsp.data[0] && rsp.data[0].organizations) {
        LocalStorage.store(Config.myOrgsKey, rsp.data[0].organizations)
        if (rsp.data[0].organizations.length === 1) {
          Company.SelectIdNC(rsp.data[0].organizations[0].id)
          // LocalStorage.store(Config.currentOrgId, rsp.data[0].organizations[0].id);
        }
      } else {
        LocalStorage.remove(Config.myOrgsKey)
      }

      StatusOverlay.message('Session validated', 5)
      this.setState({ phase: App.PHASE_RUNNING })
    } else {
      StatusOverlay.message('Session is not valid, login required', 5)
      this.setState({ phase: App.PHASE_LOGIN })
    }
  }

  /**
   * Unsubscribes from all subscriptions.
   */
  componentWillUnmount () {
    for (const sub in this.subs) {
      this.subs[sub].unsubscribe()
    }
  }

  render () {
    return (
      <ThemeProvider theme={theme}>
        {!this.state.phase || this.state.phase === App.PHASE_INIT ? <SplashScreen /> : null}
        {this.state.phase === App.PHASE_LOGIN ? <LoginScreenHook topic={Topics.Login} /> : null}
        {this.state.phase === App.PHASE_RUNNING ? <AppRunning /> : null}
        <Reusable.LoadingOverlayListener topic={Topics.Loading} visible={false} imageUrl='/images/loader_256x256.gif' />
        <Reusable.ModalOverlayListener topic={Topics.Modal} visible={false} />
        <StatusOverlay topic={Topics.Status} />
        <UploadOverlay topic={Topics.StatusUpload} />
      </ThemeProvider>
    )
  }
}

const theme = createTheme({
  label: {
    textTransform: 'none'
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      main: '#1871E7',
      contrastText: '#fff'
    },
    secondary: {
      main: '#f44336',
      contrastText: '#fff'
    },
    icon: {
      main: 'rgba(0,0,0,.60)'
    }
  },
  components: {
    MUIDataTableFilterList: {
      styleOverrides: {
        root: {
          paddingBottom: '0px'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: '#2a3042 !important',
          color: '#A6B0CF !important'
        }
      }
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        sortActive: {
          color: '#FFFFFF !important'
        }
      }
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        root: {
          position: 'relative'
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          color: '#FFFFFF !important'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          backgroundColor: 'white'
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: '#2a3042'
        },
        columnHeaderTitle: {
          color: '#A6B0CF'
        },
        sortIcon: {
          color: '#A6B0CF'
        },
        menuIconButton: {
          color: '#A6B0CF'
        },
        iconSeparator: {
          color: '#A6B0CF',
          visibility: 'hidden'
        },
        activeSortIcon: {
          color: '#A6B0CF'
        },
        inactiveSortIcon: {
          color: '#A6B0CF'
        },
        columnHeaderCheckbox: {
          color: 'red'
        }
      }
    }
  }
})

export default App
