import React from 'react'
import { Box, Grid, IconButton } from '@mui/material'
import DataGrid from 'components/Reusable/DataGrid'
import GroupIcon from '@mui/icons-material/Group'
import PersonIcon from '@mui/icons-material/Person'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import Company from 'screens/Company'
import Api, { ApiBase } from 'library/Api'
import withScreenWrapper from '../withScreenWrapper'
import { withRouter } from 'react-router-dom'
import { LoadingPleaseWait } from '@supportworks/react-components'

/**
 * Displays a list of users, providing facilities to edit/deactivate them or create new.
 */
class UserImpersonateIndex extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: true,
      persons: [],
      session: null
    }
  }

  async componentDidMount () {
    const users = await Api.callRegisteredMethod('getUsers')
    const session = await Api.callRegisteredMethod('getSession')
    let myEmail = null
    let impEmail = null
    if (session.extra) {
      myEmail = session.extra.email
      if (session.extra.impersonate) {
        impEmail = session.extra.impersonate.email
      }
    } else if (session.data?.data[0]?.person?.email) {
      myEmail = session.data.data[0].person.email
      impEmail = session.data.data[0].person.email
    }

    this.setState({
      persons: users && users.persons ? users.persons : [],
      session,
      isLoading: false,
      myEmail,
      impEmail
    })
  }

  async impersonate (personId) {
    // Find the person information from personId
    const person = this.state.persons.find((p) => p.person.id === personId)
    if (person.person.email === this.state.myEmail) {
      await Api.callRegisteredMethod('stopImpersonation')
      console.log(`Stopping impersonation myEmail: ${this.state.myEmail} impEmail: ${this.state.impEmail}`)
    } else {
      await Api.callRegisteredMethod('impersonateUser', { personId })
      console.log(`Impersonating user ${personId} myEmail: ${this.state.myEmail} impEmail: ${this.state.impEmail}`)
    }
    const session = await Api.callRegisteredMethod('getSession')
    this.setState({ session })
    this.props.history.push(ApiBase.resolvePath('/Company/:organization/Dashboard', { organization: Company.getCurrentOrgId() }))
  }

  render () {
    if (this.state.isLoading) { return <LoadingPleaseWait /> }

    const columns = [
      {
        flex: 1,
        type: 'string',
        field: 'email',
        headerName: 'Email',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'lastName',
        headerName: 'Last Name',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'firstName',
        headerName: 'FirstName',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'action',
        headerName: 'Action',
        headerAlign: 'right',
        editable: false,
        sortable: true,
        renderCell: (params) => {
          if (params.row) {
            let userIcon = null
            let color = 'action'
            if (params.row.hasOauth === true) {
              color = 'primary'
            }
            if (params.row.action === 'myEmail') {
              userIcon = <PersonIcon variant='icon' color={color} fontSize='small' />
            } else if (params.row.action === 'impEmail') {
              userIcon = <PersonOutlineIcon variant='icon' color={color} fontSize='small' />
            } else {
              userIcon = <GroupIcon variant='icon' color={color} fontSize='small' />
            }
            return (
              <Grid container wrap='nowrap' justifyContent='flex-end'>
                <Grid item>
                  <IconButton title='Impersonate User' aria-label='Impersonate User' onClick={() => this.impersonate(params.row.userId)}>
                    {userIcon}
                  </IconButton>
                </Grid>
              </Grid>
            )
          }
        }
      }
    ]

    const myEmail = this.state.myEmail
    const impEmail = this.state.impEmail
    const rows = []
    for (let x = 0; x < this.state.persons.length; x++) {
      const person = this.state.persons?.[x]?.person
      const hasOauth = this.state.persons?.[x]?.hasOauth
      const personEmail = person?.email
      const row = {
        id: x,
        userId: person.id,
        email: person.email,
        lastName: person.nameLast,
        firstName: person.nameFirst,
        hasOauth,
        action: personEmail === myEmail ? 'myEmail' : personEmail === impEmail ? 'impEmail' : 'user'
      }
      rows.push(row)
    }

    return (
      <Box>
        <Company.Header title='Select User to Impersonate' />
        <DataGrid
          rows={rows}
          columns={columns}
        />
      </Box>
    )
  }
}
export default withRouter(withScreenWrapper(UserImpersonateIndex))
