import React, { useState, useEffect } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'
import TuneIcon from '@mui/icons-material/Tune'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import DrawerFormSettings from './DrawerFormSettings'
import DrawerEditValues from './DrawerEditValues'
import DrawerSolutionList from './DrawerSolutionList'
import DrawerPresentations from './DrawerPresentations'
import Helper from '@supportworks/helper'
import PopoverTables from './PopoverTables'
import PopoverModules from './PopoverModules'

const FormActionBar = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [showDrawerFormSettings, setShowFormSettings] = useState(false)
  const [showDrawerEditValues, setShowEditValues] = useState(false)
  const [showDrawerSolutionList, setShowDrawerSolutionList] = useState(false)
  const [showDrawerPresentations, setShowDrawerPresentations] = useState(false)
  const [defaultPresentations, setDefaultPresentations] = useState(0)

  useEffect(() => {
    if (isLoading) {
      _setDefaultPresentations()
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    _setDefaultPresentations()
  }, [props.form]) // eslint-disable-line

  const handleShowDrawerFormSettings = () => {
    setShowFormSettings(!showDrawerFormSettings)
  }

  const handleShowDrawerEditValues = () => {
    setShowEditValues(!showDrawerEditValues)
  }

  const handleShowDrawerSolutionList = () => {
    setShowDrawerSolutionList(!showDrawerSolutionList)
  }

  const handleShowDrawerPresentations = () => {
    setShowDrawerPresentations(!showDrawerPresentations)
  }

  const _setDefaultPresentations = () => {
    if (props.form && props.form.always_present) {
      let count = 0
      if (props.form.always_present.top) {
        count = props.form.always_present.top.length
      }
      if (props.form.always_present.bottom) {
        count += props.form.always_present.bottom.length
      }
      setDefaultPresentations(count)
    }
  }

  return (
    <>
      <Grid container sx={{ pb: 1 }} style={styles.container}>
        <Grid item style={styles.pointer}>
          <Button startIcon={<TuneIcon style={styles.icon} />} onClick={handleShowDrawerFormSettings}>
            <Typography style={styles.title}>Form Settings</Typography>
          </Button>
        </Grid>
        {props.form && props.form.context
          ? (
            <Grid item sx={{ pl: 2 }} style={styles.pointer}>
              <Button startIcon={<AppRegistrationIcon style={styles.icon} />} onClick={handleShowDrawerEditValues}>
                <Typography style={styles.title}>Edit Values</Typography>
              </Button>
            </Grid>
            )
          : null}
        <Grid item sx={{ pl: 2 }} style={styles.pointer}>
          <Button startIcon={<VerifiedUserIcon style={styles.icon} />} onClick={handleShowDrawerSolutionList}>
            <Typography style={styles.title}>{props.form.solutions ? props.form.solutions.length : '0'} Solutions</Typography>
          </Button>
        </Grid>
        {props.form?.formType === 'Inspect'
          ? (
            <Grid item sx={{ pl: 2 }} style={styles.pointer}>
              <Button startIcon={<PlayCircleFilledIcon style={styles.icon} />} onClick={handleShowDrawerPresentations}>
                <Typography style={styles.title}>{defaultPresentations} Default Presentations</Typography>
              </Button>
            </Grid>
            )
          : null}
        {props.form.tables
          ? (
            <Grid item sx={{ pl: 2 }} style={styles.pointer}>
              <PopoverTables tables={props.form.tables} />
            </Grid>
            )
          : null}
        {props.form.modules
          ? (
            <Grid item sx={{ pl: 2 }} style={styles.pointer}>
              <PopoverModules modules={props.form.modules} />
            </Grid>
            )
          : null}
      </Grid>

      <DrawerFormSettings
        key={Helper.guid()}
        {...props}
        open={showDrawerFormSettings}
        onClose={() => {
          setShowFormSettings(!showDrawerFormSettings)
        }}
        onDone={(form) => {
          props.onChange(form)
          setShowFormSettings(!showDrawerFormSettings)
        }}
      />

      {props.form && props.form.context
        ? (
          <DrawerEditValues
            key={Helper.guid()}
            {...props}
            open={showDrawerEditValues}
            onClose={() => {
              setShowEditValues(!showDrawerEditValues)
            }}
            onDone={(form) => {
              props.onChange(form)
              setShowEditValues(!showDrawerEditValues)
            }}
          />
          )
        : null}

      {props.form && props.form.solutions
        ? (
          <DrawerSolutionList
            {...props}
            open={showDrawerSolutionList}
            onClose={() => {
              setShowDrawerSolutionList(!showDrawerSolutionList)
            }}
            onChange={(form) => {
              props.onChange(form)
            }}
          />
          )
        : null}

      <DrawerPresentations
        key={Helper.guid()}
        {...props}
        open={showDrawerPresentations}
        onClose={() => {
          setShowDrawerPresentations(!showDrawerPresentations)
        }}
        onDone={(form) => {
          props.onChange(form)
          setShowDrawerPresentations(!showDrawerPresentations)
        }}
      />

    </>
  )
}
export default FormActionBar

const styles = {
  container: {
    borderBottom: '1px solid rgba(0,0,0,.08)'
  },
  title: {
    fontSize: '16px',
    letterSpacing: '.15px',
    lineHeight: '22px',
    color: 'rgba(0,0,0,.66)'
  },
  icon: {
    color: 'rgba(0,0,0,.66)'
  },
  pointer: {
    cursor: 'pointer'
  }
}
