import React, { useEffect, useState } from 'react'
import { Box, Grid, IconButton } from '@mui/material'
import DataGrid from 'components/Reusable/DataGrid'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import Helper from '@supportworks/helper'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CompanyConfigEdit from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import { LoadingPleaseWait } from '@supportworks/react-components'

const config = {
  title: 'Numeric Units',
  variant: 'numericUnits',
  tag: 'numericUnits',
  name: 'Numeric Units',
  lockTag: true,
  lockName: true
}

const SettingsNumericUnits = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState({})

  useEffect(() => {
    if (props.config && props.config.contentJson) {
      // Fix the contentJson.
      const cj = JSON.parse(props.config.contentJson)
      if (!Array.isArray(cj)) {
        props.config.contentJson = '[]'
      }
      setList(JSON.parse(props.config.contentJson))
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleAdd = () => {
    const guid = Helper.guid()
    const l = JSON.parse(JSON.stringify(list))
    l.unshift({
      id: guid,
      title: 'New Unit'
    })
    handleChange(l)
  }

  const handleChange = (l) => {
    const c = JSON.parse(JSON.stringify(l))
    setList(c)
    if (props.onChange) {
      props.onChange(JSON.stringify(c))
    }
  }

  const handleDelete = (idx) => {
    const r = window.confirm('Are you sure you want to delete?')
    if (r) {
      const l = JSON.parse(JSON.stringify(list))
      l.splice(idx, 1)
      handleChange(l)
    }
  }

  const handleMoveUp = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx - 1, 0, ele)
    handleChange(l)
  }

  const handleMoveDown = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx + 1, 0, ele)
    handleChange(l)
  }

  const handleProcessRowUpdate = (row) => {
    if (row) {
      const l = JSON.parse(JSON.stringify(list))
      l[row.id].title = row.title
      setList(l)
      handleChange(l)
      return row
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      editable: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <>{params.row.title}</>
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id)}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton size='small' disabled={!(params.row.id < list.length - 1)} onClick={() => handleMoveDown(params.row.id)}>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < list.length; x++) {
    rows.push({
      id: x,
      title: list[x].title,
      actions: ''
    })
  }

  return (
    <Box sx={{ m: 0 }}>
      <DataGrid
        rows={rows}
        columns={columns}
        onAdd={handleAdd}
        onProcessRowUpdate={(row) => {
          handleProcessRowUpdate(row)
        }}
      />
    </Box>
  )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} editorComponent={SettingsNumericUnits} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}
