import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Box, Grid, IconButton, Stack, Tabs, Tab, Typography } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DrawerFooter from './DrawerFooter'
import Search from '../../../../components/Reusable/Search'

const DrawerProductSelect = ({ open: defaultOpen, onChange, onClose, setProduct, product, productList, productConfig }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const [tab, setTab] = useState(0)

  const [filteredProductConfig, setFilteredProductConfig] = useState([])
  const [filteredProductList, setFilteredProductList] = useState([])

  useEffect(() => {
    console.log('useEffect start [] triggered')
    // Initialize all things on the component with existing product data.
    if (isLoading) {
      setFilteredProductConfig(productConfig)
      setFilteredProductList(productList)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (name, value) => {
    // console.log(`${name} ${value}`);
    onChange(name, value)
    setIsOpen(false)
    // onClose();
  }

  const handleChangeTab = (event, value) => {
    setTab(value)
  }

  const toggleDrawer = (event) => {
    event.preventDefault()
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setIsOpen(false)
    onClose()
  }

  const stopPropagationForTab = (event) => {
    // Tab in the Search and other input fields closes the whole drawer!!! Material UI bug or feature?
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const searchItems = (variant, searchValue) => {
    if (variant === 'productGroup') {
      const newArr = productConfig.filter((row) => {
        return Object.values(row).join('').toLowerCase().includes(searchValue.toLowerCase())
      })
      setFilteredProductConfig(newArr)
    } else {
      const newArr = productList.filter((row) => {
        return Object.values(row).join('').toLowerCase().includes(searchValue.toLowerCase())
      })
      setFilteredProductList(newArr)
    }
  }

  if (isLoading) {
    return <></>
  }

  return (
    <Drawer
      key='DPS'
      anchor='right'
      variant='persistent'
      open={isOpen}
      onKeyDown={stopPropagationForTab}
      onClose={(event) => {
        toggleDrawer(event)
      }}
    >
      <div className='sa-container sa-no-padding'>
        <div className='sa-product-select-tab-container' style={{ position: 'sticky', top: '0' }}>
          <Box sx={{ paddingLeft: '16px', marginBottom: '24px', borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleChangeTab} aria-label='choose product' variant='fullWidth'>
              <Tab label='Product Groups' />
              <Tab label='Products' />
            </Tabs>
          </Box>
        </div>
        <div>
          <TabPanel value={tab} index={0}>
            <div style={styles.searchBar}>
              <Search
                onChange={(searchValue) => {
                  searchItems('productGroup', searchValue)
                }}
              />
            </div>
            <div className='sa-indent' style={{ borderBottom: '1px solid rgba(0,0,0,0.12)', paddingTop: '16px', paddingBottom: '16px' }}>
              <span>
                <strong>Please select a product group:</strong>
              </span>
            </div>
            {filteredProductConfig
              .filter((o) => o.specifications && o.specifications.length > 0)
              .map((row, idx) => {
                const spec = row.specifications
                // Special logic just to put and for the last level display.
                let levelString
                for (let x = 0; x < spec.length; x++) {
                  if (x === 0) levelString = spec[x].title
                  if (x > 0 && x < spec.length) {
                    if (spec.length > 2 && x === spec.length - 1) {
                      levelString += ` and ${spec[x].title}`
                    } else {
                      levelString += `, ${spec[x].title}`
                    }
                  }
                }
                return (
                  <Grid
                    key={`DPS-${row.id}`}
                    container
                    sx={{ pl: 2, pt: 1, pb: 1, borderBottom: '1px solid rgba(0,0,0,.08)' }}
                    onClick={() => {
                      handleChange('productGroup', row.id)
                    }}
                  >
                    <Grid item xs={11} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <Stack>
                        <span className='sa-product-text-title'>{row.title}</span>
                        <span className='sa-text10'>Includes triggers for {levelString}</span>
                      </Stack>
                    </Grid>
                    <Grid item sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                      <IconButton>
                        <ChevronRightIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )
              })}
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <div style={styles.searchBar}>
              <Search
                onChange={(searchValue) => {
                  searchItems('product', searchValue)
                }}
              />
            </div>
            <div className='sa-indent' style={{ borderBottom: '1px solid rgba(0,0,0,0.08)', paddingTop: '16px', paddingBottom: '16px' }}>
              <span>
                <strong>Please select a product:</strong>
              </span>
            </div>
            {filteredProductList.map((row, idx) => {
              return (
                <Grid
                  key={`DPS-${row.id}`}
                  container
                  sx={{ pl: 2, pt: 1, pb: 1, borderBottom: '1px solid rgba(0,0,0,.08)' }}
                  onClick={() => {
                    handleChange('product', row.id)
                  }}
                >
                  <Grid item xs={11} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Typography variant='body2'>{row.title}</Typography>
                  </Grid>
                  <Grid item sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <IconButton>
                      <ChevronRightIcon size='small' />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            })}
          </TabPanel>
        </div>
      </div>
      <DrawerFooter onBack={toggleDrawer} />
    </Drawer>
  )
}

const styles = {
  searchBar: {
    marginRight: '16px',
    marginLeft: '16px',
    position: 'sticky',
    top: 0,
    zIndex: 1
  }
}

export default DrawerProductSelect

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <div>{children}</div>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}
