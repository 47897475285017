import React from 'react'
import { Box, Popover, Typography } from '@mui/material'

export const PopoverGeneric = ({ icon, content }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  return (
    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
      <Typography
        style={{ cursor: 'pointer' }}
        aria-owns={open ? 'click-popover' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        {icon || 'Info'}
      </Typography>
      <Popover
        id='click-popover'
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        onClose={handleClose}
      >
        <Box sx={{ p: 2 }}>
          {content}
        </Box>
      </Popover>
    </Box>
  )
}
