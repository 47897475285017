import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, Chip, IconButton, Grid, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import CheckIcon from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import ExportIcon from '@mui/icons-material/FileDownload'
import ImportIcon from '@mui/icons-material/FileUpload'
import SellIcon from '@mui/icons-material/Sell'
import SellOutlinedIcon from '@mui/icons-material/SellOutlined'
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'
import Api from 'library/Api'
import Company from 'screens/Company'
import CompanyConfigEdit from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import ErrorMessage from 'components/Reusable/ErrorMessage'
import Helper from 'library/helper'
import { DialogGeneric } from '../../components/Reusable/DialogGeneric'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { PopoverGeneric } from 'components/Reusable/PopoverGeneric'

import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

const config = {
  title: 'Product Mapping',
  variant: 'libraryProductMapping',
  tag: 'libraryProductMapping',
  name: 'Product Mapping',
  lockTag: true,
  lockName: true,
  lockTitle: true
}

/**
 * CompanyConfigProductMapping()
 *
 * Primary component displayed when Product Mapping is selected in the left panel index.
 *
 * @param {*} props
 * @return {*}
 */
const CompanyConfigProductMapping = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')

  const [contentJson, setContentJson] = useState({}) // The libraryProductMapping tag JSON
  const [products, setProducts] = useState([]) // The CRM products that we map to the library.
  const [library, setLibrary] = useState([]) // The library data on the left of the screen.

  const [importedProducts, setImportedProducts] = useState([]) // products imported from Import
  const [stagedStats, setStagedStats] = useState({})
  const [showImportConfirm, setShowImportConfirm] = useState(false)
  // const [hasImportError, setHasImportError] = useState(false);

  useEffect(() => {
    if (props.config && props.config.contentJson) {
      // For mapping I need an array in contentJson.  Force it to an array if someone changed it to a hash.
      let cJson = JSON.parse(props.config.contentJson)
      if (!Array.isArray(cJson)) {
        cJson = []
      }

      // Load the product stuff.  This is what we map.
      Api.callRegisteredMethod('getConfigListByTag', { tag: 'products', startsWith: false }).then((configs) => {
        const res = Math.max.apply(
          Math,
          configs.map(function (o) {
            return o.configId
          })
        )
        const c = configs.filter((obj) => {
          return obj.configId === res
        })
        if (c && c.length && c[0].configId) {
          Api.callRegisteredMethod('getConfigById', { configId: c[0].configId }).then((rsp) => {
            if (rsp && rsp.data[0] && rsp.data[0].contentJson) {
              const products = JSON.parse(rsp.data[0].contentJson)
              setProducts(products)
              Api.callRegisteredMethod('getLibraryMaster').then((library) => {
                setLibrary(library)
                // DEV-4270 Autocreate contentJson stuff.
                //
                // Loop all my libraries, if I do not have a matching config create one with an empty product.
                //
                let autofix = false
                for (let x = 0; x < library.length; x++) {
                  const index = cJson.findIndex((o) => o.libraryProduct === library[x].id)
                  if (index === -1) {
                    autofix = true
                    cJson.push({
                      libraryProduct: library[x].id,
                      libraryProductTitle: library[x].title,
                      libraryId: library[x].library.libraryId,
                      orgProduct: '',
                      autoCreated: true
                    })
                  } else {
                    cJson[index].libraryProductTitle = library[x].title
                  }
                }
                // Mark to ORPHAN anything in contentJson that is a library and not in the library
                //
                for (let x = 0; x < cJson.length; x++) {
                  const index = library.findIndex((o) => o.id === cJson[x].libraryProduct)
                  if (index === -1) {
                    // console.log(`Setting ${cJson[x].libraryProduct} ${cJson[x].libraryId}} to orphan status`);
                    autofix = true
                    cJson[x].orphan = true
                  }
                }
                if (autofix) {
                  props.onChange(JSON.stringify(cJson))
                }
                setContentJson(cJson)
                setIsLoading(false)
              })
            } else {
              setError('There was an error loading products.')
              setIsLoading(false)
            }
          })
        } else {
          setError('There was an error loading products.')
          setIsLoading(false)
        }
      })
    }
    // eslint-disable-next-line
  }, [])

  const handleChange = (contentJson) => {
    // Clone stuff to force a react update.  The dirty, easy, approved? way.
    if (props.onChange) {
      const c = JSON.parse(JSON.stringify(contentJson))
      setContentJson(c)
      props.onChange(JSON.stringify(c))
    }
  }

  const handleExport = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'

    // Arrange the desired column order
    const columnOrder = ['libraryProduct', 'libraryId', 'libraryProductTitle', 'libraryName', 'libraryShortName', 'orgProduct', 'orphan', 'autoCreated']

    // DEV-5086 Rearrange the contentJson objects based on column order
    const orderedContent = contentJson.map((item) => {
      const orderedItem = {}
      columnOrder.forEach((column) => {
        orderedItem[column] = item[column]
      })
      return orderedItem
    })

    // Create the worksheet and workbook
    const ws = XLSX.utils.json_to_sheet(orderedContent)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }

    // Convert workbook to Excel buffer and save
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, 'Product_Mapping_Full_' + Helper.getDateString() + fileExtension)
  }

  const handleImport = (json) => {
    const fileUpload = document.getElementById('fileButton')
    if (fileUpload.files) {
      fileUpload.value = null
    }
    fileUpload.click()
    fileUpload.onchange = (e) => {
      if (typeof FileReader !== 'undefined') {
        // eslint-disable-next-line no-undef
        const reader = new FileReader()
        if (reader.readAsBinaryString) {
          reader.onload = (e) => {
            processExcel(reader.result)
          }
          reader.readAsBinaryString(fileUpload.files[0])
        }
      }
    }
  }

  const processExcel = (data) => {
    const workbook = XLSX.read(data, { type: 'binary' })
    const worksheet = workbook.Sheets[workbook.SheetNames[0]]
    const header = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
    const firstSheet = workbook.SheetNames[0]
    const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet], { raw: false })

    /**
     * Loop thru my EXCEL spreadsheet rows.
     *
     * For each try to find an existing
     * 1. Check validity of column names
     * 2. If different, check for changes and count as changed.
     * 3. If new, count as new
     * 4. Show a dialog with different, new, and unchanged results.
     *
     * This is a full overlay, so the dialog box is just a courtesy.
     *
     */
    const columnNames = header[0]
    const validColumnNames = ['libraryProduct', 'libraryId', 'libraryProductTitle', 'libraryName', 'libraryShortName', 'orgProduct', 'orphan', 'autoCreated']
    const isColumnNamesValid = columnNames.every((name) => validColumnNames.includes(name))
    if (isColumnNamesValid) {
      // console.log('valid!');
    } else {
      // DEV-5086, ignore the validation logic
      // setHasImportError(true);
      // setShowImportConfirm(!showImportConfirm);
      // return;
    }

    const products = excelRows.map((elm) => {
      const obj = {}
      if (elm.libraryProduct !== undefined) obj.libraryProduct = elm.libraryProduct
      if (elm.libraryId !== undefined) obj.libraryId = Number(elm.libraryId)
      if (elm.libraryProductTitle) obj.libraryProductTitle = elm.libraryProductTitle
      if (elm.libraryName !== undefined) obj.libraryName = elm.libraryName
      if (elm.libraryShortName !== undefined) obj.libraryShortName = elm.libraryShortName
      if (elm.orgProduct !== undefined) obj.orgProduct = elm.orgProduct
      if (elm.orphan && elm.orphan.match(/true/i)) obj.orphan = Boolean(elm.orphan)
      if (elm.autoCreated && elm.autoCreated.match(/true/i)) obj.autoCreated = Boolean(elm.autoCreated)
      return obj
    })
    setImportedProducts(products)

    const stagedStats = {
      updated: 0,
      added: 0,
      unchanged: 0
    }
    for (let x = 0; x < products.length; x++) {
      const idx = contentJson.findIndex((o) => o.libraryProduct === products[x].libraryProduct)
      if (idx === -1) {
        stagedStats.added = stagedStats.added + 1
      } else {
        const p1 = JSON.stringify(JSON.parse(JSON.stringify(contentJson[idx])))
        const p2 = JSON.stringify(JSON.parse(JSON.stringify(products[x])))
        if (p1 !== p2) {
          stagedStats.updated = stagedStats.updated + 1
        } else {
          stagedStats.unchanged = stagedStats.unchanged + 1
        }
      }
    }
    setStagedStats(stagedStats)
    setShowImportConfirm(!showImportConfirm)
  }

  const handleImportConfirm = () => {
    const c = JSON.parse(JSON.stringify(importedProducts))
    setContentJson(c)
    if (props.onChange) {
      props.onChange(JSON.stringify(c))
    }
    setImportedProducts({})
    setShowImportConfirm(!showImportConfirm)
  }

  if (error) return <ErrorMessage description={error} />

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Paper elevation={0} sx={{ mb: 2, p: 2 }}>
        <Grid container sx={{ pb: 2 }}>
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography variant='h6'>Product Mapping</Typography>
              <Typography variant='body2'>
                Map your {library.length} SolutionView library products to your {products.length} CRM products.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box style={{ float: 'right' }}>
              <Button id='export-action' variant='contained' onClick={handleExport} startIcon={<ExportIcon />} sx={{ float: 'right', marginLeft: '8px' }}>
                Export
              </Button>
              <input id='fileButton' type='file' accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' hidden />
              <Button id='import-action' variant='contained' onClick={handleImport} startIcon={<ImportIcon />} sx={{ float: 'right', marginLeft: '8px' }}>
                Import
              </Button>
            </Box>
          </Grid>
        </Grid>

        <DataGridProductMapping {...props} contentJson={contentJson} library={library} products={products} onChange={handleChange} />

        {showImportConfirm
          ? (
            <DialogImportConfirm stagedStats={stagedStats} onChange={handleImportConfirm} onClose={() => setShowImportConfirm(!showImportConfirm)} />
            )
          : null}
      </Paper>
    </>
  )
}

const DialogImportConfirm = (props) => {
  let jsx
  if (props.hasImportError) {
    jsx = (
      <Box>
        <Typography>
          There was an error processing your spreadsheet. Please make sure the header columns and data is correct for a product mapping upload.
        </Typography>
      </Box>
    )
  } else {
    jsx = (
      <Box>
        <Stack>
          <Typography>
            You are about to add {props.stagedStats.added} and modify {props.stagedStats.updated} product mappings. {props.stagedStats.unchanged} mappings will
            remain unchanged.
          </Typography>
          <Typography sx={{ pt: 2 }}>
            When you Save on the main screen, the product mapping data will be completely replaced with what is in your spreadsheet.
          </Typography>
          <Typography sx={{ pt: 2, fontWeight: 'bold' }}>Are you sure you want to continue?</Typography>
        </Stack>
      </Box>
    )
  }
  return (
    <DialogGeneric
      title='Import Product Mappings'
      content={jsx}
      fullWidth
      maxWidth='sm'
      titleDone='Done'
      onChange={!props.hasImportError ? props.onChange : null}
      titleClose={props.hasImportError ? 'Close' : 'Cancel'}
      onClose={props.onClose}
    />
  )
}

/**
 * DataGridProductMapping()
 *
 * Component that displays a DataGrid with mixed library, products, and mapped data.  It contains icon buttons for mapping and various utilities.
 *
 */
const DataGridProductMapping = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [pageSize, setPageSize] = useState(10)
  const [filterChips, setFilterChips] = useState([]) // Array of filter chips that are selected
  const [showMapDialog, setShowMapDialog] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(null) // The library that you are editing
  const [rows, setRows] = useState([]) // Array of datagrid row objects that can be filtered
  const [filteredRows, setFilteredRows] = useState([]) // Array of row objects filtered thru filterChips

  useEffect(() => {
    if (isLoading) {
      refresh(props)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    refresh(props)
    // eslint-disable-next-line
  }, [props.contentJson])

  const refresh = (props) => {
    const [fr, fc] = updateFilterChipData(props.library, props.contentJson)
    setRows(fr)
    setFilteredRows(fr)
    setFilterChips(fc)
    setIsLoading(false)
  }

  const handleChange = (row) => {
    const c = JSON.parse(JSON.stringify(props.contentJson))
    const lib = props.library[selectedIndex]
    const mappedIndex = c.findIndex((o) => o.libraryProduct === lib.id)
    if (mappedIndex > -1) {
      c[mappedIndex].orgProduct = row.productId
    } else {
      c.push({
        libraryProduct: lib.id,
        libraryId: lib.library.libraryId,
        // libraryName: lib.library.libraryName,
        // libraryShortName: lib.library.libraryShortname,
        orgProduct: row.productId
      })
    }
    // Update the DataGrid stuff so the change is reflected.
    // eslint-disable-next-line no-unused-vars
    const [fr, fc] = updateFilterChipData(props.library, c, filterChips)
    setFilterChips(fc)
    rows[selectedIndex].orgProductTitle = row.title
    rows[selectedIndex].orgProductDescr = row.description
    rows[selectedIndex].orgProductId = row.productId
    rows[selectedIndex].orgProductUnits = row.units
    setSelectedIndex(-1)
    setRows(rows)
    props.onChange(c)
  }

  const handleQuickChange = (index, productId) => {
    if (productId === -1) {
      handleMapClear(index)
      return
    }
    const c = JSON.parse(JSON.stringify(props.contentJson))
    const lib = props.library[index]
    const p = props.products.find((o) => o.id === productId)
    if (p && p.id) {
      const mappedIndex = c.findIndex((o) => o.libraryProduct === lib.id)
      if (mappedIndex > -1) {
        c[mappedIndex].orgProduct = productId
      } else {
        c.push({
          libraryProduct: lib.id,
          libraryId: lib.library.libraryId,
          orgProduct: productId
        })
      }
      // Update the DataGrid stuff so the change is reflected.
      const [, fc] = updateFilterChipData(props.library, c, filterChips)
      setFilterChips(fc)
      rows[index].orgProductTitle = p.title
      rows[index].orgProductDescr = p.description
      rows[index].orgProductId = p.id
      rows[index].orgProductUnits = getUnitsText(p)
      setSelectedIndex(-1)
      setRows(rows)
      props.onChange(c)
    }
  }

  const handleMapClear = (idx) => {
    const c = JSON.parse(JSON.stringify(props.contentJson))
    const lib = props.library[idx]
    const mappedIndex = props.contentJson.findIndex((o) => o.libraryProduct === lib.id)
    c[mappedIndex].orgProduct = ''
    const [, fc] = updateFilterChipData(props.library, c, filterChips)
    setFilterChips(fc)
    rows[idx].orgProductTitle = ''
    rows[idx].orgProductDescr = ''
    rows[idx].orgProductId = ''
    rows[idx].orgProductUnits = ''
    setRows(rows)
    props.onChange(c)
  }

  const handleNotOffered = (idx) => {
    const c = JSON.parse(JSON.stringify(props.contentJson))
    const lib = props.library[idx]
    const mappedIndex = props.contentJson.findIndex((o) => o.libraryProduct === lib.id)
    rows[idx].notoffered = !rows[idx].notoffered
    if (rows[idx].notoffered) {
      c[mappedIndex].orgProduct = 'notoffered'
      rows[idx].orgProductTitle = 'Not Offered'
      const [, fc] = updateFilterChipData(props.library, c, filterChips)
      setFilterChips(fc)
      setRows(rows)
      props.onChange(c)
    } else {
      handleMapClear(idx)
    }
  }

  const handleShowMapDialog = (idx) => {
    setSelectedIndex(idx)
    setShowMapDialog(!showMapDialog)
  }

  const handleChipSelect = (value) => {
    const [fr, fc] = filterDataGridUsingChipFilter({ value, rows, filterChips })
    setFilterChips(fc)
    setFilteredRows(fr)
  }

  const updateFilterChipData = (library, contentJson, filterChips) => {
    let filter = []
    const prefilter = [
      { id: 'All', selected: filterChips === undefined, count: 0, mapped: 0 },
      { id: 'Unmapped', selected: false }
    ]

    // Loop all my library stuff to build unique filter chips using the libraryShortname
    //
    const currentCompany = Company.getCurrent()
    for (let x = 0; x < library.length; x++) {
      let chip
      prefilter[0].count = prefilter[0].count + 1
      const lib = library[x]
      if (lib && lib.library) {
        const ll = lib.library // short cut
        chip = {
          id: ll.libraryName ? ll.libraryName : currentCompany.name,
          libraryId: ll.libraryId,
          libraryName: ll.libraryName ? ll.libraryName : currentCompany.name
        }
      } else {
        chip = {
          id: currentCompany.name,
          libraryId: 99999,
          libraryName: currentCompany.name
        }
      }
      const index = filter.findIndex((o) => o.libraryId === chip.libraryId)
      if (index < 0) {
        let selected = false
        // Get the original selections
        if (filterChips && filterChips.length) {
          const cIndex = filterChips.findIndex((o) => o.id === chip.id)
          if (cIndex > -1) {
            selected = filterChips[cIndex].selected
          }
        }
        chip = { ...chip, selected, count: 1, mapped: 0 }
        filter.push(chip)
      } else {
        filter[index].count = filter[index].count + 1
      }
    }

    // Loop all my contentJson records and increment the mapped count that is displayed on the matching filterChip
    //
    for (let x = 0; x < contentJson.length; x++) {
      const c = contentJson[x]
      const index = filter.findIndex((o) => o.libraryId === c.libraryId && c.orgProduct)
      if (c.orphan) {
        console.log(`skipping orphan ${JSON.stringify(c)}`)
        continue
      }
      if (index > -1) {
        filter[index].mapped = filter[index].mapped + 1
        prefilter[0].mapped = prefilter[0].mapped + 1 // All, yeah array access === bad mojo
      } else {
        // console.log(`${x} No index found for ${c.libraryId} ${c.libraryProduct} ${c.libraryName}`)
      }
    }

    // Get all my default rows for the DataGrid.
    //
    const r = []
    for (let x = 0; x < library.length; x++) {
      const lib = library[x]
      const ll = lib.library
      const obj = {
        id: x,
        detail: lib.description ? lib.description : '',
        libraryProduct: lib.title,
        libraryName: ll.libraryName ? `${ll.libraryName} (${ll.libraryId})` : '',
        libraryId: ll.libraryId ? ll.libraryId : -1,
        orgProductTitle: '',
        orgProductDescr: '',
        orgProductUnits: '',
        orgProductId: '',
        notoffered: false,
        action: ''
      }
      const c = props.contentJson.find((o) => o.libraryProduct === lib.id)
      if (c && c.orgProduct) {
        if (c.orgProduct !== 'notoffered') {
          const p = props.products.find((o) => o.id === c.orgProduct)
          // console.log(`${x} is mapped: ${c.orgProduct} ${p.id} ${p.title}`)
          if (p) {
            obj.orgProductTitle = p.title ? p.title : 'No title'
            obj.orgProductDescr = p.description ? p.description : ''
            obj.orgProductUnits = getUnitsText(p)
            obj.orgProductId = p.id ? p.id : ''
          } else {
            // console.log(`Could not find product: ${c.orgProduct}`)
          }
        } else {
          obj.orgProductTitle = 'Not Offered'
          obj.orgProductUnits = ''
          obj.notoffered = true
        }
      } else {
        // console.log(`${x} is NOT mapped: ${lib.id} ${ll.libraryId} ${ll.libraryName}`);
      }
      r.push(obj)
    }
    filter.sort((a, b) => (a.id > b.id ? 1 : -1))
    filter = [...prefilter, ...filter]
    return [r, filter]
  }

  /**
   * filterDataGridUsingChipFilter()
   *
   * Method specific to mapping that filters the "rows" based on the chip selection.
   * This method returns the new filteredRows array and a replacement filterChips array.
   *
   * @param {*} { value, rows, filterChips }
   * @return [ filteredRows, filterChips ]
   */
  const filterDataGridUsingChipFilter = ({ value, rows, filterChips }) => {
    const fr = [] // filteredRows
    const fc = JSON.parse(JSON.stringify(filterChips))
    const f = fc.find((o) => o.id === value)
    f.selected = !f.selected
    if (value.match(/All|Unmapped/) && f.selected === true) {
      fc.map((o) => (o.id !== value ? (o.selected = false) : null))
    } else {
      fc.map((o) => (o.id === 'All' ? (o.selected = false) : null))
    }
    const fAll = fc.find((o) => o.id === 'All')
    const fUnmapped = fc.find((o) => o.id === 'Unmapped')
    const selections = fc.filter((o) => o.selected === true)
    if (fAll && fAll.selected) {
      return [rows, fc]
    } else {
      for (let x = 0; x < rows.length; x++) {
        let filtered = false
        // If we have a library that is selected, include the right rows..
        if (!fc.find((o) => o.selected === true && o.libraryId === rows[x].libraryId)) {
          filtered = true
        }
        // If Unmapped is selected, make sure we include the right rows...
        if (fUnmapped.selected && !filtered) {
          if (rows[x].orgProductTitle) {
            filtered = true
          }
        }
        // If Unmapped is the only thing I have selected
        if (fUnmapped.selected && selections.length === 1) {
          if (!rows[x].orgProductTitle) {
            filtered = false
          }
        }
        if (!filtered) {
          fr.push(rows[x])
        }
      }
    }
    return [fr, fc]
  }

  const CustomToolbar = (filterChips) => {
    return (
      <GridToolbarContainer>
        <Grid container>
          <Grid item xs={12} md={9}>
            {filterChips}
          </Grid>
          <Grid item xs={12} md={3}>
            <Grid container sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <GridToolbarQuickFilter size='small' variant='outlined' sx={{ p: 1 }} />
            </Grid>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    )
  }

  let options = props.products.map((p) => {
    const ut = getUnitsText(p)
    return {
      id: p.id,
      label: `${p.title}__${p.id}__${ut}`,
      name: `${p.title} ${ut ? ` ${ut}` : ''}`,
      title: p.title
    }
  })
  options = options.sort((a, b) => a.label.localeCompare(b.label))

  const columns = [
    { field: 'id', headerName: 'Id', hide: true },
    {
      field: 'detail',
      headerName: 'Detail',
      editable: false,
      sortable: true,
      cellClassName: 'detail-popover',
      renderCell: (params) => {
        return (
          <PopoverGeneric
            icon={<DescriptionOutlinedIcon fontSize='small' />}
            content={
              <Stack style={{ width: '320px' }}>
                <Typography variant='body2'>{params.row.detail}</Typography>
              </Stack>
            }
          />
        )
      }
    },
    {
      field: 'libraryProduct',
      headerName: 'Title',
      flex: 2,
      sortable: true,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.libraryProduct}>
            <span>{params.row.libraryProduct}</span>
          </Tooltip>
        )
      }
    },
    {
      field: 'libraryName',
      headerName: 'Library',
      flex: 2,
      sortable: true,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.libraryName}>
            <span>{params.row.libraryName}</span>
          </Tooltip>
        )
      }
    },
    {
      field: 'orgProductTitle',
      headerName: 'Map to Master List',
      flex: 3,
      sortable: true,
      renderCell: (params) => {
        if (params.row.notoffered) {
          return <span>{params.row.orgProductTitle}</span>
        }
        return (
          <Box
            sx={{ height: 1, width: 1 }}
            onKeyDown={(event) => {
              if (event.key === ' ' || event.key.match(/ArrowDown|ArrowUp/)) {
                event.stopPropagation()
              }
            }}
          >
            <Autocomplete
              id='autocomplete-map-to-master'
              isOptionEqualToValue={(option, value) => {
                if (value === '') return true
                return option.title === value
              }}
              value={params.row.orgProductTitle}
              fullWidth
              size='small'
              options={options}
              onChange={(e, newValue) => {
                handleQuickChange(params.row.id, newValue && newValue.id ? newValue.id : -1)
              }}
              renderInput={(params) => <TextField {...params} label='Master List Product' />}
              renderOption={(props, option) => (
                <Box component='li' {...props} key={option.id}>
                  <Typography variant='body2'>{option.name}</Typography>
                </Box>
              )}
              sx={{ mt: 2 }}
            />
          </Box>
        )
      }
    },
    {
      width: 128,
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      headerAlign: 'right',
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              {params.row.notoffered
                ? null
                : (
                  <Grid item>
                    <IconButton size='small' onClick={() => handleShowMapDialog(params.row.id)}>
                      {params.row.orgProductTitle ? <EditIcon fontSize='small' /> : <AddIcon fontSize='small' />}
                    </IconButton>
                  </Grid>
                  )}
              <Grid item>
                <IconButton size='small' onClick={() => handleNotOffered(params.row.id)}>
                  {params.row.notoffered ? <SellOutlinedIcon fontSize='small' /> : <SellIcon fontSize='small' />}
                </IconButton>
              </Grid>
              {params.row.orgProductTitle && !params.row.notoffered
                ? (
                  <Grid item>
                    <IconButton size='small' onClick={() => handleMapClear(params.row.id)}>
                      <DeleteIcon fontSize='small' />
                    </IconButton>
                  </Grid>
                  )
                : null}
            </Grid>
          )
        }
      }
    }
  ]

  if (isLoading) return <LoadingPleaseWait />

  const jsxFilterChips = (
    <Box sx={{ pb: 1, m: 1 }}>
      {filterChips.map((f) => {
        let left = f.count - f.mapped
        if (f.id === 'Unmapped') left = filterChips[0].count - filterChips[0].mapped
        if (f.id === 'All') left = filterChips[0].count
        if (left < 0) left = 0
        const label = (
          <Grid container>
            <Grid item>
              <Typography variant='body'>{f.id}</Typography>
            </Grid>
            {left === 0
              ? (
                <Grid item sx={{ ml: 1 }}>
                  <CheckIcon fontSize='small' />
                </Grid>
                )
              : (
                <Grid item sx={{ ml: 1 }}>
                  <Box sx={{ pl: 1, pr: 1 }} style={styles.chipNumber}>
                    <Typography variant='body' style={{ color: 'white' }}>
                      {left}
                    </Typography>
                  </Box>
                </Grid>
                )}
          </Grid>
        )
        return (
          <Chip
            key={`Chip-${f.id}`}
            sx={{ p: 0, m: 0.5 }}
            color={f.selected === true ? 'primary' : left > 0 ? 'default' : 'default'}
            style={f.selected !== true && left > 0 ? { backgroundColor: '#FDEDED' } : null}
            label={label}
            onClick={() => handleChipSelect(f.id)}
          />
        )
      })}
    </Box>
  )

  return (
    <>
      <Box>
        <DataGrid
          autoHeight
          rowHeight={72}
          rows={filteredRows}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          slots={{ toolbar: () => CustomToolbar(jsxFilterChips) }}
          sx={{
            '& .MuiDataGrid-columnHeaderTitle': {
              paddingRight: '16px'
            },
            '& .detail-popover': {
              display: 'flex'
            }
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false
              }
            }
          }}
          autosizeOptions={{
            columns: ['action'],
            includeOutliers: true,
            includeHeaders: false
          }}
        />
      </Box>

      {showMapDialog
        ? (
          <DialogProductSelector
            title={`Map Product to ${rows[selectedIndex].libraryProduct}, ${rows[selectedIndex].libraryName}`}
            products={props.products}
            row={rows[selectedIndex]}
            onSave={(row) => {
              if (row && row.productId) {
                handleChange(row)
              }
              setShowMapDialog(!showMapDialog)
            }}
            onClose={() => setShowMapDialog(!showMapDialog)}
          />
          )
        : null}
    </>
  )
}

/**
 *  DialogProductSelector()
 *
 * Component used for displaying a dialog with products that can be selected for the primary mapping feature.
 *
 */
const DialogProductSelector = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [pageSize, setPageSize] = useState(10)
  const [rows, setRows] = useState([]) // Array of row objects that can be selected
  const [selectedRows, setSelectedRows] = useState([]) // Array of row idx's of things that have been checked

  useEffect(() => {
    if (isLoading) {
      const r = []
      for (let x = 0; x < props.products.length; x++) {
        const product = props.products[x]
        if (!product.deleted) {
          r.push({
            id: x,
            productId: product.id,
            crmId: product.crmId,
            title: product.title,
            description: product.description,
            units: getUnitsText(product)
          })
          // Only 1 row can be selected, so set it.
          if (props.row.orgProductId === product.id) {
            setSelectedRows([x])
          }
        }
      }
      setRows(r)
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleSave = () => {
    const row = rows[selectedRows[0]]
    props.onSave(row)
  }

  const handleChange = (rows) => {
    const f = rows.filter((x) => !selectedRows.includes(x))
    setSelectedRows(f)
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Grid container>
          <Grid item xs={12}>
            <Grid container sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <GridToolbarQuickFilter size='small' variant='outlined' xs={6} sx={{ pt: 1, pl: 2, pr: 2, pb: 1 }} />
            </Grid>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    )
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    { field: 'id', headerName: 'Id', hide: true },
    {
      field: 'productId',
      flex: 1,
      headerName: 'SKU',
      sortable: true,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.productId ? params.row.productId : 'No SKU.'} placement='bottom-start'>
            <Typography variant='body' sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {params.row.productId}
            </Typography>
          </Tooltip>
        )
      }
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 2,
      sortable: true,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.title ? `${params.row.title} ${params.row.units}` : 'No title.'} placement='bottom-start'>
            <Typography variant='body' sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {params.row.title} {params.row.units}
            </Typography>
          </Tooltip>
        )
      }
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 2,
      sortable: true,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.description ? params.row.description : 'No description.'} placement='bottom-start'>
            <Typography variant='body' sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {params.row.description}
            </Typography>
          </Tooltip>
        )
      }
    }
  ]

  const jsx = (
    <Box style={{ height: 500 }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 25, 50]}
        checkboxSelection
        rowSelectionModel={selectedRows}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onRowSelectionModelChange={(rows) => {
          handleChange(rows)
        }}
        keepNonExistentRowsSelected
        slots={{ toolbar: CustomToolbar }}
        sx={{
          '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
            color: '#A6B0CF',
            backgroundColor: 'transparent',
            display: 'none'
          }
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false
            }
          }
        }}
      />
    </Box>
  )

  return <DialogGeneric title={props.title} content={jsx} fullWidth maxWidth='lg' onChange={handleSave} onClose={props.onClose} />
}

const getUnitsText = (p) => {
  let ut = ''
  if (p.price !== undefined && p.price >= 0) {
    ut = `($${p.price}`
    if (p.units !== undefined) {
      ut += `/${p.units}`
    }
    ut = `${ut})`
  } else if (p.units !== undefined) {
    ut = `(${p.units})`
  }
  return ut
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} editorComponent={CompanyConfigProductMapping} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}

const styles = {
  chipNumber: {
    backgroundColor: '#2a3042',
    borderRadius: 16
  }
}
