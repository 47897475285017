import React, { useState, useEffect } from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { Box, Chip, Grid, Stack, Typography } from '@mui/material'
import { DialogGeneric } from './DialogGeneric'
import { LoadingPleaseWait } from '@supportworks/react-components'
import Company from 'screens/Company'
import H from '../../library/helper'

export const DialogSolutionSelector = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [pageSize, setPageSize] = useState(10)
  const [filterChips, setFilterChips] = useState([]) // Array of unique library names and selected boolean status for filtering the display
  const [selectedRows, setSelectedRows] = useState([]) // Array of row idx's of things that have been checked
  const [rows, setRows] = useState([]) // Array of row objects that can be selected
  const [filteredRows, setFilteredRows] = useState([]) // Array of row objects filtered thru filterChips
  // console.log(props)
  useEffect(() => {
    if (isLoading) {
      const sr = []
      const r = []
      let filter = []
      const prefilter = [
        { id: 'All', selected: true },
        { id: 'Selected', selected: false }
      ]
      for (let x = 0; x < props.solutions.length; x++) {
        const sc = props.solutions[x]
        if (props.variant && props.variant === 'library') {
          const l = props.list.find(o => o.id === sc.id)
          if (l && l.id && l.status.match(/^(added|edited)/)) {
            sr.push(x)
          }
        } else {
          const l = props.list.indexOf(sc.id)
          if (l > -1) {
            sr.push(x)
          }
        }
        let chipName
        if (sc.library) {
          chipName = sc.library.libraryName
        } else {
          const currentCompany = Company.getCurrent()
          chipName = currentCompany.name
        }
        const exists = filter.find((o) => o.id === chipName)
        if (!exists) {
          filter.push({
            id: chipName,
            selected: false
          })
        }
        let previewURL
        let path
        if (props.assets && sc.asset) {
          const asset = props.assets.find(o => o.id === sc.asset)
          if (asset) {
            previewURL = asset.previewURL
            path = asset.path
          }
        }
        r.push({
          id: x,
          previewURL: previewURL || null,
          path: path || null,
          title: sc.title ? sc.title : '-',
          library: chipName,
          products: sc.product ? sc.product.length : 0
        })
      }
      filter.sort((a, b) => (a.id > b.id ? 1 : -1))
      filter = [...prefilter, ...filter]
      setRows(r)
      setFilteredRows(r)
      setSelectedRows(sr)
      setFilterChips(filter)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleSave = () => {
    const list = []
    for (let x = 0; x < selectedRows.length; x++) {
      const row = props.solutions[selectedRows[x]]
      list.push(row.id)
    }
    if ('type' in props) {
      props.onSave(list, props.type)
    } else {
      props.onSave(list)
    }
  }

  const handleChange = (r) => {
    setSelectedRows(r)
  }

  const handleChipSelect = (value) => {
    const [fr, fc] = H.filterDataGridUsingChipFilter({ value, rows, selectedRows, filterChips })
    setFilterChips(fc)
    setFilteredRows(fr)
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Grid container>
          <Grid item xs={12}>
            <Grid container sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <GridToolbarQuickFilter size='small' variant='outlined' xs={6} sx={{ pt: 1, pl: 2, pr: 2, pb: 1 }} />
            </Grid>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    )
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    { field: 'id', headerName: 'Id', hide: true },
    {
      field: 'previewURL',
      headerName: 'Preview',
      renderCell: (params) => {
        if (params.row.previewURL) {
          return (
            <a href={params.row.path} target='_blank' rel='noreferrer'>
              <img src={params.row.previewURL} alt={params.row.title} width='100%' />
            </a>
          )
        }
        return null
      }
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 2,
      renderCell: (params) => {
        return (
          <Stack>
            <Typography><strong>{params.row.title}</strong></Typography>
            <Typography variant='body2'>{params.row.products ? params.row.products : 'No'} Default Product(s)</Typography>
          </Stack>
        )
      }
    },
    { field: 'library', headerName: 'Library', flex: 1 }
  ]

  const jsx = (
    <Box>
      <Box sx={{ pb: 1 }}>
        {filterChips.map((f) => {
          return (
            <Chip
              key={`Chip-${f.id}`}
              sx={{ p: 1, m: 0.5 }}
              color={f.selected === true ? 'primary' : 'default'}
              size='small'
              label={f.id}
              onClick={() => handleChipSelect(f.id)}
            />
          )
        })}
      </Box>
      <Box sx={{ height: 500 }}>
        <DataGrid
          sx={{
            '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
              color: '#A6B0CF',
              backgroundColor: 'transparent'
            }
          }}
          density='comfortable'
          rows={filteredRows}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50]}
          checkboxSelection
          rowSelectionModel={selectedRows}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onRowSelectionModelChange={(rows) => {
            handleChange(rows)
          }}
          keepNonExistentRowsSelected
          slots={{ toolbar: CustomToolbar }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false
              }
            }
          }}
        />
      </Box>
    </Box>
  )

  return <DialogGeneric title={props.title} content={jsx} fullWidth onChange={handleSave} onClose={props.onClose} />
}
