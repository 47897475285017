import React, { useState, useEffect } from 'react'
import { Checkbox, Container, Box, Button, Dialog, DialogTitle, DialogContent, Grid, IconButton, Paper, Stack, TextField, Typography, FormGroup, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SquareIcon from '@mui/icons-material/Square'
import moment from 'moment'
import { LoadingPleaseWait } from '@supportworks/react-components'
import SelectorWithPreview from '../../../components/Reusable/SelectorWithPreview'
import EditorHeader from 'components/Reusable/EditorHeader'
import CompanyConfigModal from '../CompanyConfigModal'
import ColorPicker from 'components/Reusable/ColorPicker'

const StandardDocumentEditor = ({ document, assets, productFeatures, displaySettings, onChange, onClose }) => {
  // console.log(document);
  const [isLoading, setIsLoading] = useState(true)
  const [showDialogCoverPhoto, setShowDialogCoverPhoto] = useState(false)
  const [showDialogEsign, setShowDialogEsign] = useState(false)
  const [showMinFontSize, setShowMinFontSize] = useState(false)

  const [state, setState] = React.useState({
    title: '',
    include: false,
    merge: true,
    sendCustomer: false,
    sendDistributionList: false,
    s__invoice: false,
    s__jobinfo: false,
    s__summary: false,
    pd__productDescriptions: false,
    pd__itemizedPricing: false,
    showProductImages: false,
    po__contractGroups: false,
    po__defaultGroup: null,
    showProductSpecifications: false,
    showRecommendations: false,
    showSolutionRecommendations: false,
    srp__title: 'Solutions Recommendations',
    srp__disclaimer: '',
    showAllOptions: false,
    dateFormatShort: '',
    dateFormatLong: '',
    includeProjectStartDate: false,
    includeProjectFinishDate: false,
    includeFooterOnPage1: false,
    jip__showDrawing: false,
    // jip__showDiscountPercentage: false,
    // jip__showDiscountItems: false,
    // jip__showTotal: false,
    minFontSize: undefined
  })

  useEffect(() => {
    if (!('merge' in document)) {
      document.merge = true
      // setState({merge: true});
      onChange()
    }
    if (isLoading) {
      // Auto clean up
      const sections = document.sections
      if (!document.jobInformationPage) {
        document.jobInformationPage = {
          showDrawing: false
          // showDiscountPercentage: false,
          // showDiscountItems: false,
          // showTotal: false
        }
      }
      if (!document.productDisplay) {
        document.productDisplay = {
          productDescriptions: false,
          itemizedPricing: false,
          productImages: false
        }
      }
      if (!document.projectOverview) {
        // Get the first productFeatures.contract_groups id for default if there is one.
        let defaultGroup = null
        if (productFeatures && productFeatures.contract_groups && productFeatures.contract_groups.length > 0) {
          defaultGroup = productFeatures.contract_groups[0].id
        }
        document.projectOverview = {
          contractGroups: false,
          defaultGroup
        }
      }
      if (!document.solutionRecommendationsPage) {
        document.solutionRecommendationsPage = {
          title: '',
          disclaimer: ''
        }
      }
      // Default the productImages to what is in displaySettings, if it's empty, and displaySettings has a value.
      if (document.productDisplay.productImages === undefined && displaySettings?.productEditor?.showImageryProductImages !== undefined) {
        document.productDisplay.productImages = displaySettings.productEditor.showImageryProductImages
      }
      if (!document.esignTags) {
        document.esignTags = {
          company_sign: '',
          company_date: '',
          company_initial: '',
          customer_sign: '',
          customer_date: '',
          customer_initial: ''
        }
      }

      onChange() // Force an update of any self-healing stuff above...

      setState({
        title: document.title,
        include: document.include,
        merge: 'merge' in document ? document.merge : true,
        sendCustomer: document.sendCustomer,
        sendDistributionList: document.sendDistributionList,
        s__invoice: sections.indexOf('invoice') !== -1,
        s__jobinfo: sections.indexOf('jobinfo') !== -1,
        s__summary: sections.indexOf('summary') !== -1,
        pd__productDescriptions: document.productDisplay.productDescriptions,
        pd__itemizedPricing: document.productDisplay.itemizedPricing,
        pd__productImages: document.productDisplay.productImages,
        po__contractGroups: document.projectOverview.contractGroups,
        po__defaultGroup: document.projectOverview.defaultGroup,
        showProductSpecifications: document.showProductSpecifications,
        showRecommendations: document.showRecommendations,
        showSolutionRecommendations: document.showSolutionRecommendations,
        srp__title: document.solutionRecommendationsPage.title || 'Solutions Recommendations',
        srp__disclaimer: document.solutionRecommendationsPage.disclaimer || '',
        showAllOptions: document.showAllOptions,
        dateFormatShort: document.dateFormatShort || 'M-D-YY',
        dateFormatLong: document.dateFormatLong || 'Month D, YYYY',
        includeProjectStartDate: document.includeProjectStartDate,
        includeProjectFinishDate: document.includeProjectFinishDate,
        includeFooterOnPage1: document.includeFooterOnPage1 || false,
        jip__showDrawing: document.jobInformationPage.showDrawing,
        // jip__showDiscountPercentage: document.jobInformationPage.showDiscountPercentage,
        // jip__showDiscountItems: document.jobInformationPage.showDiscountItems,
        // jip__showTotal: document.jobInformationPage.showTotal,
        minFontSize: document.minFontSize !== undefined ? Number(document.minFontSize) : undefined
      })
      if (document.minFontSize !== undefined) {
        setShowMinFontSize(true)
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (event) => {
    const name = event.target.name
    const type = event.target.type
    // Some checkboxes are in the sections array instead of variables.  Handle these appropriatelly
    const sections = document.sections
    if (type === 'checkbox') {
      if (name === 'showMinFontSize') {
        if (showMinFontSize === false) {
          setShowMinFontSize(true)
        } else {
          setShowMinFontSize(false)
          delete document.minFontSize
          setState({ ...state, minFontSize: undefined })
        }
      }
      if (name.match(/s__/)) {
        const s = name.split(/__/)
        const idx = sections.indexOf(s[1])
        if (idx === -1) {
          sections.push(s[1])
        } else {
          sections.splice(idx, 1)
        }
        document.sections = sections
      } else if (name.match(/pd__/)) {
        const s = name.split(/__/)
        document.productDisplay[s[1]] = !document.productDisplay[s[1]]
      } else if (name.match(/jip__/)) {
        const s = name.split(/__/)
        document.jobInformationPage[s[1]] = !document.jobInformationPage[s[1]]
      } else if (name.match(/po__/)) {
        const s = name.split(/__/)
        document.projectOverview[s[1]] = !document.projectOverview[s[1]]
      } else {
        document[name] = !document[name]
      }
      setState({ ...state, [event.target.name]: event.target.checked })
    } else {
      if (name === 'minFontSize') {
        const value = event.target.value
        const numericValue = value.replace(/[^0-9]/g, '')
        document[name] = Number(numericValue)
        setState({ ...state, [name]: Number(numericValue) })
      } else if (event.target.type === 'text' || name.match(/dateFormatShort|dateFormatLong|merge/)) {
        if (name === 'srp__title') {
          document.solutionRecommendationsPage.title = event.target.value
        } else {
          document[name] = event.target.value
        }
        setState({ ...state, [name]: event.target.value })
      } else if (name === 'po__defaultGroup') {
        document.projectOverview.defaultGroup = event.target.value
        setState({ ...state, [name]: event.target.value })
      } else if (name === 'srp__disclaimer') {
        document.solutionRecommendationsPage.disclaimer = event.target.value
        setState({ ...state, [name]: event.target.value })
      }
    }
    onChange()
  }

  const getAssetInfo = (id) => {
    const assetConfig = assets.filter((asset) => {
      return asset.id === id
    })
    if (assetConfig.length !== 0) {
      return assetConfig[0]
    } else {
      return id
    }
  }

  const { title, include, merge, sendCustomer, sendDistributionList } = state
  const { s__invoice, s__jobinfo, s__summary } = state // eslint-disable-line
  const { pd__productDescriptions, pd__itemizedPricing, pd__productImages } = state // eslint-disable-line
  const { showProductSpecifications } = state // eslint-disable-line
  const { po__contractGroups, po__defaultGroup } = state // eslint-disable-line
  const { showRecommendations, showAllOptions, showSolutionRecommendations } = state
  const { srp__title, srp__disclaimer } = state //eslint-disable-line
  const { dateFormatShort, dateFormatLong, includeProjectStartDate, includeProjectFinishDate } = state
  const { jip__showDrawing } = state // eslint-disable-line
  // const { jip__showDrawing, jip__showDiscountPercentage, jip__showDiscountItems, jip__showTotal } = state // eslint-disable-line
  const { minFontSize } = state // dev-4951
  const { includeFooterOnPage1 } = state // dev-4952

  if (isLoading) {
    return <LoadingPleaseWait />
  }

  let selectedAsset
  if (document.heroImageDefault && document.heroImageDefault.asset) {
    selectedAsset = getAssetInfo(document.heroImageDefault.asset)
  }

  let jsx
  try {
    jsx = (
      <>
        <EditorHeader
          title='Edit Standard Document'
          fullScreen
          showFullScreenOption={false}
          onBack={() => {
            onChange()
            onClose()
          }}
        />
        <Paper elevation={0} sx={{ pt: 2, pb: 2 }}>
          <Container maxWidth={false}>
            <Box>
              <Typography variant='h5'>Setup</Typography>
            </Box>
            <Box sx={{ pb: 3 }}>
              <Typography paragraph>
                The standard document is the default PDF generated in the SolutionView App at the end of the appointment. Customize the document to your
                preference and determine how it will be distributed.
              </Typography>
            </Box>
            <Box sx={{ mb: 2, pb: 2, ...styles.borderBottom }}>
              <TextField
                required
                id='outlined-required'
                label='Title Document'
                defaultValue={title}
                sx={{ width: '50%' }}
                name='title'
                onChange={handleChange}
              />
            </Box>

            <Grid container spacing={0} sx={{ pb: 1 }}>
              <Grid item xs={12} sm={3} sx={{ pt: 1 }}>
                Delivery Settings
              </Grid>
              <Grid item xs={12} sm={9}>
                <FormControl sx={{ pb: { sm: 1 }, 'input:focus': { border: 'none' }, width: '350px' }}>
                  <InputLabel id='unit-type'>Who will have access to document?</InputLabel>
                  <Select id='merge' name='merge' label='Who will have access to document?' variant='outlined' onChange={handleChange} value={merge}>
                    <MenuItem key='true' value>
                      Customer & Company
                    </MenuItem>
                    <MenuItem key='false' value={false}>
                      Company Only
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name='include' onChange={handleChange} checked={include} />}
                    label='Include Document Every Appointment'
                  />
                  <FormControlLabel
                    control={<Checkbox name='sendCustomer' onChange={handleChange} checked={sendCustomer} />}
                    label='Email Customer when User Sends to Cloud'
                  />
                  <FormControlLabel
                    control={<Checkbox name='sendDistributionList' onChange={handleChange} checked={sendDistributionList} />}
                    label='Send to Distribution List'
                  />
                  <FormControlLabel
                    control={<Checkbox name='merge' onChange={handleChange} checked={merge} />}
                    label='Include Document in Merged PDF'
                  />
                </FormGroup>
              </Grid>
            </Grid>

            <Grid container spacing={0} sx={{ pb: 1 }}>
              <Grid item xs={12} sm={3} sx={{ pt: 1 }}>
                Document Settings
              </Grid>
              <Grid item xs={12} sm={9}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name='includeFooterOnPage1' onChange={handleChange} checked={includeFooterOnPage1} />}
                    label='Include Footer on Page 1 of Document'
                  />
                  <FormControlLabel
                    control={<Checkbox name='includeProjectStartDate' onChange={handleChange} checked={includeProjectStartDate} />}
                    label='Include Project Start Date'
                  />
                  <FormControlLabel
                    control={<Checkbox name='includeProjectFinishDate' onChange={handleChange} checked={includeProjectFinishDate} />}
                    label='Include Project Finish Date'
                  />
                  <FormControlLabel
                    control={<Checkbox name='showMinFontSize' onChange={handleChange} checked={!!showMinFontSize} />}
                    label='Set Minimum Font Size'
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ pb: 2 }}>
              <Grid item sm={3}>
                &nbsp;
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth sx={{ pb: { sm: 1 }, 'input:focus': { border: 'none' } }}>
                  <TextField
                    disabled={!showMinFontSize}
                    id='minFontSize'
                    label='Font Size'
                    InputLabelProps={{
                      style: {
                        color: showMinFontSize ? 'inherit' : 'rgba(0, 0, 0, 0.38)' // Lighter text color
                      },
                      shrink: true
                    }}
                    value={minFontSize === undefined ? '' : minFontSize}
                    name='minFontSize'
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ pb: 2 }}>
              <Grid item sm={3}>
                &nbsp;
              </Grid>
              <Grid item sm={6}>
                <FormControl fullWidth sx={{ pb: { sm: 1 }, 'input:focus': { border: 'none' } }}>
                  <InputLabel id='unit-type'>Short Date Format</InputLabel>
                  <Select
                    id='dateFormatShort'
                    name='dateFormatShort'
                    label='Short Date Format'
                    labelId='dateFormatShort'
                    variant='outlined'
                    value={dateFormatShort}
                    onChange={handleChange}
                  >
                    {dateFormatsShort.map((f, idx) => {
                      const df = moment().format(f.mask)
                      return (
                        <MenuItem key={`unit-${idx}`} value={f.value}>
                          <Grid container justify='space-between'>
                            <Grid item sm={6}>
                              <Typography variant='subtitle'>{f.value}</Typography>
                            </Grid>
                            <Grid item>ex. {df}</Grid>
                          </Grid>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ pb: 2, mb: 2, ...styles.borderBottom }}>
              <Grid item sm={3}>
                &nbsp;
              </Grid>
              <Grid item sm={6}>
                <FormControl fullWidth sx={{ pb: { sm: 1 }, 'input:focus': { border: 'none' } }}>
                  <InputLabel id='unit-type'>Long Date Format</InputLabel>
                  <Select
                    id='dateFormatLong'
                    name='dateFormatLong'
                    label='Long Date Format'
                    labelId='dateFormatLong'
                    variant='outlined'
                    value={dateFormatLong}
                    onChange={handleChange}
                  >
                    {dateFormatsLong.map((f, idx) => {
                      const df = moment().format(f.mask)
                      return (
                        <MenuItem key={`unit-${idx}`} value={f.value}>
                          <Grid container justify='space-between'>
                            <Grid item sm={6}>
                              <Typography variant='subtitle'>{f.value}</Typography>
                            </Grid>
                            <Grid item>ex. {df}</Grid>
                          </Grid>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={0} sx={{ mb: 2, ...styles.borderBottom }}>
              <Grid item xs={12} sm={3} sx={{ pt: 1 }}>
                Product Display Settings
              </Grid>
              <Grid item xs={12} sm={9}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name='pd__productDescriptions' onChange={handleChange} checked={pd__productDescriptions} />} //eslint-disable-line
                    label='Show Product Descriptions'
                  />
                  <FormControlLabel
                    control={<Checkbox name='pd__itemizedPricing' onChange={handleChange} checked={pd__itemizedPricing} />} //eslint-disable-line
                    label='Show Itemized Pricing'
                  />
                  <FormControlLabel
                    control={<Checkbox name='showProductSpecifications' onChange={handleChange} checked={showProductSpecifications} />}
                    label='Show Product Specifications'
                  />
                  <FormControlLabel
                    control={<Checkbox name='pd__productImages' onChange={handleChange} checked={pd__productImages} />} // eslint-disable-line
                    label='Include Product Images in Contract'
                  />
                </FormGroup>
              </Grid>
            </Grid>

            <Grid container spacing={0} sx={{ mb: 2, ...styles.borderBottom }}>
              <Grid item xs={12} sm={3} sx={{ pt: 1 }}>
                Project Overview Settings
              </Grid>
              <Grid item xs={12} sm={9} sx={{ pb: 2 }}>
                <FormGroup sx={{ pb: 2 }}>
                  <FormControlLabel
                    control={<Checkbox name='po__contractGroups' onChange={handleChange} checked={po__contractGroups} />} //eslint-disable-line
                    label='Categorize Project into Contract Groups'
                  />
                </FormGroup>
                {productFeatures && productFeatures.contract_groups && (
                  <FormControl>
                    <InputLabel id='po-contract-group-label'>Contract Group</InputLabel>
                    <Select
                      labelId='po-contract-group-label'
                      value={po__defaultGroup} //eslint-disable-line
                      label='Contract Group'
                      onChange={handleChange}
                      name='po__defaultGroup'
                    >
                      {productFeatures.contract_groups.map((group, index) => (
                        <MenuItem key={index} value={group.id}>
                          {group.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={0} sx={{ mb: 2, ...styles.borderBottom }}>
              <Grid item xs={12} sm={3} sx={{ pt: 1 }}>
                Project Overview Section
              </Grid>
              <Grid item xs={12} sm={9}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name='showRecommendations' onChange={handleChange} checked={showRecommendations} />}
                    label='Show Project Recommendations'
                  />
                  {
                    showRecommendations &&
                      <FormControlLabel
                        control={<Checkbox name='showAllOptions' onChange={handleChange} checked={showAllOptions} />}
                        label='Show All Projects (Includes Projects of Smaller Size)'
                      />
                  }
                  <FormControlLabel
                    control={<Checkbox name='showSolutionRecommendations' onChange={handleChange} checked={showSolutionRecommendations} />}
                    label='Show Solution Recommendations'
                  />
                  {
                    showSolutionRecommendations &&
                      <>
                        <Grid container spacing={0} sx={{ mt: 2, pb: 2 }}>
                          <Grid item sm={4}>
                            <FormControl fullWidth sx={{ pb: { sm: 1 }, 'input:focus': { border: 'none' } }}>
                              <TextField
                                id='srp__title'
                                label='Solution Recommendations Title'
                                defaultValue={srp__title} // eslint-disable-line
                                name='srp__title'
                                onChange={handleChange}
                              />
                            </FormControl>
                          </Grid>
                          <Grid container spacing={0} sx={{ mt: 1, pb: 2 }}>
                            <Grid item sm={8}>
                              <FormControl fullWidth sx={{ pb: { sm: 1 }, 'input:focus': { border: 'none' } }}>
                                <TextField
                                  id='srp__disclaimer'
                                  label='Solution Recommendations Disclaimer'
                                  defaultValue={srp__disclaimer} // eslint-disable-line
                                  name='srp__disclaimer'
                                  onChange={handleChange}
                                  multiline
                                  rows={5}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                  }
                </FormGroup>
              </Grid>
            </Grid>

            <Grid container spacing={0} sx={{ mb: 2, ...styles.borderBottom }}>
              <Grid item xs={12} sm={3} sx={{ pt: 1 }}>
                Select Pages to be Included
              </Grid>
              <Grid item xs={12} sm={9}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox name='s__invoice' onChange={handleChange} checked={s__invoice} />} label='Project Overview' /> {/* eslint-disable-line */}
                  <FormControlLabel control={<Checkbox name='s__jobinfo' onChange={handleChange} checked={s__jobinfo} />} label='Job Information' /> {/* eslint-disable-line */}
                  <FormControlLabel control={<Checkbox name='s__summary' onChange={handleChange} checked={s__summary} />} label='Appointment Summary' /> {/* eslint-disable-line */}
                </FormGroup>
              </Grid>
            </Grid>

            <Grid container spacing={0} sx={{ mb: 2, ...styles.borderBottom }}>
              <Grid item xs={12} sm={3} sx={{ pt: 1 }}>
                Job Information Page Settings
              </Grid>
              <Grid item xs={12} sm={9}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox name='jip__showDrawing' onChange={handleChange} checked={jip__showDrawing} />} label='Include Project Drawing' />  {/* eslint-disable-line */}
                  {/* <FormControlLabel control={<Checkbox name='jip__showDiscountPercentage' onChange={handleChange} checked={jip__showDiscountPercentage} />} label='Show Discount Percentage' />
                  <FormControlLabel control={<Checkbox name='jip__showDiscountItems' onChange={handleChange} checked={jip__showDiscountItems} />} label='Show Discount Items' />
                  <FormControlLabel control={<Checkbox name='jip__showTotal' onChange={handleChange} checked={jip__showTotal} />} label='Show Total' /> */}
                </FormGroup>
              </Grid>
            </Grid>

            <Grid container spacing={0} sx={{ pt: 1, mb: 5, pb: 4 }}>
              <Grid item xs={12} sm={3} sx={{ p: 1, mr: 1, borderColor: 'rgba(0,0,0,.24)', backgroundColor: 'rgba(0,0,0,.08)' }}>
                {document.heroImageDefault && document.heroImageDefault.asset
                  ? (
                    <img src={selectedAsset.previewURL} className='selected-Asset' alt={selectedAsset.title} width='95%' />
                    )
                  : (
                    <span>No Photo</span>
                    )}
              </Grid>
              <Grid item xs={12} sm={8}>
                <Button
                  id='add-cover-photo'
                  color='primary'
                  variant='contained'
                  sx={{ mr: 2 }}
                  onClick={() => {
                    setShowDialogCoverPhoto(!showDialogCoverPhoto)
                  }}
                >
                  Modify Default Cover Photo
                </Button>
              </Grid>
            </Grid>

            <Box sx={{ display: 'flex', ...styles.bottomNavigation }}>
              <Button
                id='add-esign'
                color='primary'
                variant='contained'
                sx={{ mr: 2 }}
                onClick={() => {
                  setShowDialogEsign(!showDialogEsign)
                }}
              >
                Add E-Sign Tags
              </Button>
              <Button
                id='done'
                variant='contained'
                color='primary'
                sx={{ marginLeft: 'auto' }}
                onClick={() => {
                  onChange()
                  onClose()
                }}
              >
                Back
              </Button>
            </Box>
          </Container>
        </Paper>

        {showDialogCoverPhoto
          ? (
            <CompanyConfigModal
              level={999}
              open
              body={
                <SelectorWithPreview
                  selectedConfig={document.heroImageDefault}
                  fieldName='asset'
                  config={assets}
                  assetType={['Image']}
                  onUpdate={(asset) => {
                    if (asset && asset.id) {
                      document.heroImageDefault.asset = asset.id
                    }
                  }}
                />
            }
              header='Select Asset'
              update={() => {
                setShowDialogCoverPhoto(!showDialogCoverPhoto)
                onChange()
              }}
              cancel={() => {
                setShowDialogCoverPhoto(!showDialogCoverPhoto)
              }}
            />
            )
          : null}

        {showDialogEsign
          ? (
            <DialogEsign
              document={document}
              assets={assets}
              onChange={() => {}}
              onClose={() => setShowDialogEsign(!showDialogEsign)}
            />
            )
          : null}
      </>
    )
  } catch (err) {
    console.log(`StandardDocumentEditor error: ${err.stack}`)
    jsx = <div>Data is not available.</div>
  }
  return (
    <>
      <Dialog open onClose={onClose} fullScreen PaperProps={{ sx: { width: '100%', height: '100%' } }} scroll='paper'>
        {jsx}
      </Dialog>
    </>
  )
}
export default StandardDocumentEditor

const DialogEsign = ({ document, onChange, onClose }) => {
  const [showColorPicker, setShowColorPicker] = useState(false)

  const handleChange = (event) => {
    document.esignTags[event.target.name] = event.target.value
    onChange()
  }

  const esignTags = document.esignTags
  return (
    <Dialog onClose={onClose} open maxWidth='sm'>
      <DialogTitle className='dialog-title'>
        eSign Tags
        <IconButton className='dialog-close' aria-label='close' onClick={onClose} sx={{ position: 'absolute', right: 12, top: 12 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2, pb: 2 }}>
          <Stack>
            <Typography paragraph>
              Place tags into the fields next to each placement. These tags will be rendered onto the PDF and can then be mapped to your prefered 3rd-Party
              e-signature application.
            </Typography>
            <Typography paragraph>
              <strong>
                We recommend placing a symbol within your tags to avoid external applications from placing signatures incorrectly (e.g. //csig// for Customer
                Signature).
              </strong>
            </Typography>
          </Stack>
        </Box>
        <Grid container spacing={0} alignItems='flex-start'>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <TextField
              fullWidth
              id='customer_sign'
              label='Customer Signature'
              InputLabelProps={{ shrink: true }}
              defaultValue={esignTags.customer_sign}
              name='customer_sign'
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <TextField
              fullWidth
              id='customer_date'
              label='Customer Signature Date'
              InputLabelProps={{ shrink: true }}
              defaultValue={esignTags.customer_date}
              name='customer_date'
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <TextField
              fullWidth
              id='customer_initial'
              label='Customer Initial'
              InputLabelProps={{ shrink: true }}
              defaultValue={esignTags.customer_initial}
              name='customer_initial'
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <TextField
              fullWidth
              id='company_sign'
              label='Company Representative Signature'
              InputLabelProps={{ shrink: true }}
              defaultValue={esignTags.company_sign}
              name='company_sign'
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <TextField
              fullWidth
              id='company_date'
              variant='outlined'
              label='Company Representative Date'
              InputLabelProps={{ shrink: true }}
              defaultValue={esignTags.company_date}
              name='company_date'
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <TextField
              fullWidth
              id='outlined-required'
              label='Company Representative Initial'
              InputLabelProps={{ shrink: true }}
              defaultValue={esignTags.company_initial}
              name='company_initial'
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} sx={{ pb: 2 }}>
            {showColorPicker
              ? (
                <ColorPicker
                  disableAlpha
                  color={esignTags.color}
                  onChange={(color) => {
                    esignTags.color = color
                    setShowColorPicker(!showColorPicker)
                  }}
                  onClose={() => setShowColorPicker(!showColorPicker)}
                />
                )
              : (
                <TextField
                  id='color-picker'
                  name='color'
                  label='Color'
                  InputLabelProps={{ shrink: true }}
                  value={esignTags.color}
                  onClick={() => setShowColorPicker(!showColorPicker)}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='' sx={{ mr: 1 }}>
                        <IconButton style={{ width: 24, height: 24, border: 1, borderColor: 'black', borderRadius: 0, backgroundColor: esignTags.color }}>
                          <SquareIcon sx={{ color: esignTags.color }} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                )}
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex' }}>
          <Button
            id='done'
            variant='contained'
            color='primary'
            sx={{ marginLeft: 'auto' }}
            onClick={() => {
              onChange()
              onClose()
            }}
          >
            Done
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

const dateFormatsShort = [
  { value: 'M-D-YY', mask: 'M-D-YY' },
  { value: 'M-D-YYYY', mask: 'M-D-YYYY' },
  { value: 'D-M-YYYY', mask: 'D-M-YYYY' },
  { value: 'YYYY-M-D', mask: 'YYYY-M-D' }
]

const dateFormatsLong = [
  { value: 'Month D, YYYY', mask: 'MMMM D, YYYY' },
  { value: 'YYYY, Month D', mask: 'YYYY, MMMM D' }
]

const styles = {
  bottomNavigation: {
    position: 'fixed',
    width: '100%',
    left: 0,
    bottom: 0,
    padding: '16px',
    borderTop: 1,
    borderColor: 'rgba(0,0,0,.16)',
    backgroundColor: 'white'
  },
  borderBottom: {
    borderBottom: 1,
    borderColor: 'rgba(0,0,0,.16)',
    paddingBottom: 2
  },
  textField: {
    fontSize: 14
  }
}
