import React, { useState, useEffect } from 'react'
import { Button, Grid, Box, Paper, Dialog, AppBar, Toolbar, IconButton, Stack, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Permissions from '../../../library/Permissions'
import { LoadingPleaseWait } from '@supportworks/react-components'
import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel'
import DrawerDebug from 'components/Reusable/DrawerDebug'

export const MeetingEditor = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [rightPanelVariant, setRightPanelVariant] = useState(null)
  const [meeting, setMeeting] = useState({})

  useEffect(() => {
    if (isLoading) {
      setMeeting(props.meeting)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleSave = () => {
    setIsSaving(true)
    props.onSave(meeting)
  }

  const handleClose = () => {
    props.onClose()
  }

  if (isLoading) return <LoadingPleaseWait />

  let jsx
  try {
    jsx = (
      <>
        <Paper elevation={0} sx={{ p: 2, display: { xs: 'block', sm: 'none' } }}>
          <Grid container spacing={0}>
            For the best user experience, please use a larger resolution.
          </Grid>
          <Box sx={{ display: 'flex', ...styles.bottomNavigation }}>
            <Button id='close-editor' sx={{ mr: 2 }} onClick={props.onClose}>
              Close
            </Button>
          </Box>
        </Paper>
        <Paper elevation={0} sx={{ pt: 0, pb: 0, pl: 0, pr: 0, display: { xs: 'none', sm: 'block' } }}>
          <AppBar position='relative'>
            <Toolbar>
              <IconButton title='Previous' aria-label='Previous' sx={{ pl: 1, mr: 2 }} onClick={handleClose}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant='h6' component='div' sx={{ flexGrow: 2 }}>
                Meeting Editor
              </Typography>
            </Toolbar>
          </AppBar>

          {!props.config
            ? (
              <Grid container spacing={0} direction='row' alignItems='center' justifyContent='center' style={{ minHeight: '50vh' }}>
                <Stack alignItems='center'>
                  <Typography sx={{ pb: 1 }}>We apologize there was an error of some kind.</Typography>
                  <Typography sx={{ pb: 1 }}>The meeting is missing configuration data.</Typography>
                  <Typography>Please notify your representative.</Typography>
                </Stack>
              </Grid>
              )
            : (
              <Grid container spacing={0} style={{ height: 'calc(100vh - 65px)' }} wrap='nowrap'>
                <Grid item style={styles.leftPanel}>
                  <LeftPanel
                    {...props}
                    isAdding={props.isAdding}
                    rightPanelVariant={rightPanelVariant}
                    setRightPanelVariant={setRightPanelVariant}
                    meeting={meeting}
                    setMeeting={setMeeting}
                  />
                </Grid>
                <Grid item sm={11} sx={{ mb: 8 }} style={styles.rightPanel}>
                  <RightPanel {...props} rightPanelVariant={rightPanelVariant} meeting={meeting} setMeeting={setMeeting} />
                </Grid>
                {Permissions.hasRole('super_user') ? <DrawerDebug json={[{ name: 'meeting', json: meeting }]} /> : null}
              </Grid>
              )}

          <Box sx={{ display: 'flex', ...styles.bottomNavigation }}>
            <Button id='close-editor' sx={{ mr: 2 }} variant='outlined' onClick={handleClose}>
              Cancel
            </Button>
            <Button id='save-editor' disabled={!!isSaving} variant='contained' color='primary' sx={{ marginLeft: 'auto' }} onClick={handleSave}>
              {isSaving ? <LoadingPleaseWait className='loading-please-wait-button' /> : <>Done</>}
            </Button>
          </Box>
        </Paper>
      </>
    )
  } catch (err) {
    console.log(`MeetingEditor error: ${err.stack}`)
    jsx = <div>Data is not available.</div>
  }
  return (
    <Dialog open onClose={props.onClose} fullScreen PaperProps={{ sx: { width: '100%', height: '100%' } }} scroll='paper'>
      {jsx}
    </Dialog>
  )
}

const styles = {
  bottomNavigation: {
    position: 'fixed',
    width: '100%',
    left: 0,
    bottom: 0,
    maxHeight: '69px',
    padding: '16px',
    borderTop: 1,
    borderColor: 'rgba(0,0,0,.16)',
    backgroundColor: 'white'
  },
  leftPanel: {
    width: '336px',
    maxHeight: '100vh',
    overflow: 'auto',
    WebkitUserSelect: 'none',
    resize: 'horizontal',
    marginBottom: 68
  },
  rightPanel: {
    maxHeight: '100vh',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,.04)',
    outline: '1px solid rgba(0,0,0,.08)',
    WebkitUserSelect: 'none'
  }
}
