import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, IconButton, Link, TextField, Tooltip, Typography } from '@mui/material'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import Api from 'library/Api'
import Company from 'screens/Company'
import DataGrid from 'components/Reusable/DataGrid'
import DateRangePicker from '../../components/Reusable/DateRangePicker'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import Helper from '@supportworks/helper'
import { LoadingPleaseWait } from '@supportworks/react-components'
import withScreenWrapper from 'screens/withScreenWrapper'
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser'

const daysBack = 7

/**
 * Provides a list of existing configurations for the specified company, links to maintain them and create new configs.
 */
class ProposalsIndex extends React.Component {
  constructor (props) {
    super(props)

    const to = new Date(Date.now() + 3600 * 1000 * 24) // DEV-2916
    const ninety = new Date().setDate(to.getDate() - daysBack)
    this.state = {
      author: '',
      dateFrom: Helper.formatDate(ninety, 'yyyy-mm-dd'),
      dateTo: Helper.formatDate(to, 'yyyy-mm-dd'),
      advancedSearch: null,
      mine: false,
      limit: 10,
      searchMeta: null,
      searchResults: null,
      isLoading: true,
      showGrantAccessDialog: false,
      proposal: {}
    }
    this.handleSearch = this.handleSearch.bind(this)
    this.handleChangeDate = this.handleChangeDate.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount () {
    Api.callRegisteredMethod('getProposalSearchMeta').then((searchMeta) => {
      this.setState({ searchMeta })
      Api.callRegisteredMethod('getProposals', { from: this.state.dateFrom, to: this.state.dateTo }).then((searchResults) => {
        // console.log(searchResults);
        this.setState({
          isLoading: false,
          searchResults: searchResults && searchResults.length ? searchResults.sort((a, b) => Date.parse(b.created) - Date.parse(a.created)) : []
        })
      })
    })
  }

  handleChange (event) {
    if (event.target.name === 'advancedSearch') {
      this.setState({ advancedSearch: event.target.value })
    }
  }

  handleChangeDate (dateFrom, dateTo) {
    const df = Helper.formatDate(dateFrom, 'yyyy-mm-dd')
    // console.log(df)
    this.setState({
      dateFrom: df,
      dateTo: Helper.formatDate(dateTo, 'yyyy-mm-dd')
    })
  }

  handleSearch () {
    let dateFrom = this.state.dateFrom
    let dateTo = this.state.dateTo
    if (!dateFrom) {
      const current = new Date()
      const ninety = new Date().setDate(current.getDate() - daysBack)
      dateFrom = Helper.formatDate(ninety, 'yyyy-mm-dd')
      dateTo = Helper.formatDate(current, 'yyyy-mm-dd')
    }
    this.setState({ isLoading: true }, () => {
      Api.callRegisteredMethod('getProposals', {
        from: dateFrom,
        to: dateTo,
        author: this.state.author,
        searchString: this.state.advancedSearch
      }).then((searchResults) => {
        this.setState({ isLoading: false, searchResults })
      })
    })
  }

  handleGrantAccess = async (id, value) => {
    await Api.callRegisteredMethod('getProposalAccess', { proposalId: id, value }).then((results) => {
      console.log(`email result: ${JSON.stringify(results)}`)
      if (results && results[0] && results[0].status === 'success') {
        window.alert(`Emails or text messages have been triggered for: ${value}`)
      }
      this.setState({ proposal: {}, showGrantAccessDialog: false })
    })
  }

  render () {
    const columns = [
      {
        flex: 2,
        type: 'string',
        field: 'customer',
        headerName: 'Customer',
        editable: false,
        sortable: true,
        renderCell: (params) => {
          return (
            <Tooltip title={params.value}>
              <Box>{params.value}</Box>
            </Tooltip>
          )
        }
      },
      {
        flex: 1,
        type: 'string',
        field: 'address',
        headerName: 'Address',
        editable: false,
        sortable: true,
        renderCell: (params) => {
          return (
            <Tooltip title={params.value}>
              <Box>{params.value}</Box>
            </Tooltip>
          )
        }
      },
      {
        flex: 1,
        type: 'string',
        field: 'telephone',
        headerName: 'Telephone',
        editable: false,
        sortable: true,
        renderCell: (params) => {
          return (
            <Tooltip title={params.value}>
              <Box>{params.value}</Box>
            </Tooltip>
          )
        }
      },
      {
        flex: 1,
        type: 'string',
        field: 'crmId',
        headerName: 'Id',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'status',
        headerName: 'Status',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'personnel',
        headerName: 'Personnel',
        editable: false,
        sortable: true,
        renderCell: (params) => {
          return (
            <Tooltip title={params.value}>
              <Box>{params.value}</Box>
            </Tooltip>
          )
        }
      },
      {
        flex: 1,
        type: 'string',
        field: 'created',
        headerName: 'Created',
        editable: false,
        sortable: true,
        renderCell: (params) => {
          return (
            <Tooltip title={params.value}>
              <Box>{params.value}</Box>
            </Tooltip>
          )
        }
      },
      {
        flex: 1,
        type: 'string',
        field: 'updated',
        headerName: 'Updated',
        editable: false,
        sortable: true,
        renderCell: (params) => {
          return (
            <Tooltip title={params.value}>
              <Box>{params.value}</Box>
            </Tooltip>
          )
        }
      },
      {
        flex: 1,
        type: 'string',
        field: 'totalPrice',
        headerName: 'Total Price',
        editable: false,
        sortable: true,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => {
          return <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>${Number(params.value).toFixed(2)}</Box>
        }
      },
      {
        width: 128,
        field: 'action',
        headerName: 'Actions',
        headerAlign: 'right',
        align: 'right',
        editable: false,
        sortable: false,
        disableExport: true,
        renderCell: (params) => {
          if (params.row) {
            return (
              <Grid container wrap='nowrap' justifyContent='flex-end'>
                <Grid item>
                  <Link
                    href={Api.resolvePath('/Company/:organizationId/Proposals/:proposalId', {
                      organizationId: this.props.organizationId,
                      proposalId: params.row.proposalId
                    })}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <IconButton>
                      <FindInPageIcon fontSize='small' />
                    </IconButton>
                  </Link>
                </Grid>
                <Grid item>
                  <IconButton
                    title='Send Customer Portal Access to Customer'
                    aria-label='Send Customer Portal Access to Customer'
                    onClick={() => {
                      this.setState({ proposal: params.row.proposal, showGrantAccessDialog: !this.state.showGrantAccessDialog })
                    }}
                  >
                    <OpenInBrowserIcon fontSize='small' />
                  </IconButton>
                </Grid>
              </Grid>
            )
          }
        }
      }
    ]

    const searchResults = this.state.searchResults || []
    const rows = []
    for (let x = 0; x < searchResults.length; x++) {
      const sr = searchResults[x]
      const row = {}
      row.id = x
      row.customer = sr.customer_info ? [sr.customer_info.lastName, sr.customer_info.firstName].filter(Boolean).join(', ') : ''
      row.address = sr.customer_info ? [sr.customer_info.address1, sr.customer_info.address2, sr.customer_info.address3].filter(Boolean).join('\r\n') : ''
      row.telephone = sr.customer_info
        ? [Helper.formatPhoneNumber(sr.customer_info.mobile), Helper.formatPhoneNumber(sr.customer_info.landline)].filter(Boolean).join('\r\n')
        : ''
      row.crmId = sr.customer_info?.crmIdText ? sr.crmIdText : ''
      row.status = sr.disposition
      row.personnel = sr.author ? [sr.author.nameLast, sr.author.nameFirst].filter(Boolean).join(', ') : ''
      row.created = sr.created
      row.updated = sr.updated
      row.totalPrice = sr.totalPrice
      row.proposal = sr
      row.proposalId = sr.id
      rows.push(row)
    }

    if (this.state.isLoading) return <LoadingPleaseWait />

    return (
      <Box sx={{ p: 2 }}>
        <Company.Header title='Proposals' />
        <Box sx={{ pb: 1 }}>
          <Grid container spacing={1} alignItems='flex-end'>
            <Grid item>
              <label>Advanced Search</label>
              <input type='text' className='form-control' name='advancedSearch' placeholder='' onChange={this.handleChange} />
            </Grid>
            <Grid item>
              <DateRangePicker dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} onChangeDate={this.handleChangeDate} />
            </Grid>
            <Grid item>
              <Button size='medium' variant='contained' color='primary' onClick={this.handleSearch}>
                <span style={{ textTransform: 'none' }}>Go</span>
              </Button>
            </Grid>
            <Grid item>
              <label>Personnel</label>
              <select
                className='form-control'
                value={this.state.author}
                onChange={(e) => {
                  this.setState({ author: e.target.value }, () => this.handleSearch())
                }}
              >
                <option value=''>Personnel</option>
                {this.state.searchMeta.authors.map((opt, idx) => {
                  return (
                    <option key={'searchMeta-Authors-' + idx} value={opt.id}>
                      {opt.nameLast}, {opt.nameFirst}
                    </option>
                  )
                })}
              </select>
            </Grid>
          </Grid>
        </Box>

        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            columns: {
              columnVisibilityModel: {
                updated: true
              }
            }
          }}
          autosizeOptions={{
            columns: ['action'],
            includeOutliers: true,
            includeHeaders: false
          }}
        />
        {this.state.showGrantAccessDialog
          ? (
            <DialogGrantAccess
              email={this.state.proposal && this.state.proposal.customer_info ? this.state.proposal.customer_info.email : ''}
              onChange={(email) => this.handleGrantAccess(this.state.proposal.id, email)}
              onClose={() => this.setState({ showGrantAccessDialog: false })}
            />
            )
          : null}
      </Box>
    )
  }
}
export default withScreenWrapper(ProposalsIndex)

export const DialogGrantAccess = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [email, setEmail] = useState(null)

  useEffect(() => {
    if (isLoading) {
      setEmail(props.email)
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'email') {
      setEmail(value)
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  const jsx = (
    <Grid container sx={{ pt: 1 }}>
      <Grid item xs={12} sx={{ pb: 3 }}>
        <Typography variant='body2'>
          Please enter email addresses or telephone numbers for text messages, separated by a comma. A notification message will be sent to each with a code and
          URL to access the customer portal.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          id='email'
          name='email'
          variant='outlined'
          label='Notification Targets'
          helperText='Please enter the emails and/or telephone numbers separated by a comma.'
          value={email || ''}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  )

  return (
    <DialogGeneric
      title='Send Customer Portal Access via Email or Text Message'
      content={jsx}
      fullWidth
      onChange={() => props.onChange(email)}
      onClose={props.onClose}
    />
  )
}
