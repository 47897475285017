import React, { useState, useEffect } from 'react'
import { Box, Grid, IconButton } from '@mui/material'
import { LoadingPleaseWait } from '@supportworks/react-components'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import PercentIcon from '@mui/icons-material/Percent'
import DataGrid from 'components/Reusable/DataGrid'
import Helper from '@supportworks/helper'

const RightPanelMaterialMarkup = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState([]) // For screen manipulation

  useEffect(() => {
    if (isLoading) {
      if (!props.productFeatures.materialMarkup) {
        props.productFeatures.materialMarkup = []
      }
      setList(props.productFeatures.materialMarkup)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleAdd = () => {
    const l = JSON.parse(JSON.stringify(list))
    l.unshift({ id: Helper.guid(), title: 'New Material Markup', markup: 0 })
    props.productFeatures.materialMarkup = l
    setList(l)
  }

  const handleDelete = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    l.splice(idx, 1)
    props.productFeatures.materialMarkup = l
    setList(l)
  }

  const handleMoveUp = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx - 1, 0, ele)
    props.productFeatures.materialMarkup = l
    setList(l)
  }

  const handleMoveDown = (idx) => {
    const l = JSON.parse(JSON.stringify(list))
    const ele = l.splice(idx, 1)[0]
    l.splice(idx + 1, 0, ele)
    props.productFeatures.materialMarkup = l
    setList(l)
  }

  const handleProcessRowUpdate = (row) => {
    if (row) {
      const l = JSON.parse(JSON.stringify(props.productFeatures.materialMarkup))
      l[row.id].title = row.title
      l[row.id].markup = row.markup
      props.productFeatures.materialMarkup = l
      setList(l)
      return row
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    {
      flex: 3,
      type: 'string',
      field: 'title',
      headerName: 'Title',
      editable: true,
      sortable: true
    },
    {
      type: 'number',
      field: 'markup',
      headerName: 'Markup %',
      editable: true,
      sortable: true,
      preProcessEditCellProps: (params) => {
        const isValid = !isNaN(params.props.value)
        return { ...params.props, error: !isValid }
      },
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {params.value}
            <PercentIcon fontSize='small' sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
          </Box>
        )
      }
    },
    {
      flex: 1,
      field: 'action',
      headerName: 'Actions',
      headerAlign: 'right',
      align: 'right',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id)}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton size='small' disabled={!(params.row.id < list.length - 1)} onClick={() => handleMoveDown(params.row.id)}>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < list.length; x++) {
    const row = {
      id: x,
      rowId: list[x].id,
      title: list[x].title,
      markup: list[x].markup
    }
    rows.push(row)
  }

  return (
    <Box>
      <DataGrid
        rows={rows}
        columns={columns}
        onAdd={handleAdd}
        onProcessRowUpdate={(row) => {
          handleProcessRowUpdate(row)
        }}
      />
    </Box>
  )
}
export default RightPanelMaterialMarkup
