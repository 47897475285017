import React from 'react'
import '../../../css/forms.css'
import { Menu, MenuItem, Divider, IconButton } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined'
import Button from '@mui/material/Button'
import { fieldTypeConfig } from '../index'

function FieldTypeSelect (props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const changeHandler = (type) => {
    setAnchorEl(null)
    props.onChange(type)
  }

  const setIcon = (currentType) => {
    if (currentType in fieldTypeConfig) {
      return (
        fieldTypeConfig[currentType].icon
      )
    } else {
      return null
    }
  }

  const setLabel = (currentType) => {
    if (currentType in fieldTypeConfig) {
      return (
        fieldTypeConfig[currentType].label
      )
    } else {
      return currentType
    }
  }
  // console.log(props);
  return (
    <>
      {
                props.type === 'fieldLevel' &&
                  <Button
                    className='field-type-selected'
                    onClick={handleClick}
                    variant='text'
                    startIcon={setIcon(props.currentType)}
                    endIcon={<ArrowDropDownIcon className='field-type-select-end-icon' />}
                  >
                    {setLabel(props.currentType)}
                  </Button>
            }
      {
                props.type === 'formLevel' &&
                  <IconButton
                    disabled={props.disabled}
                    style={{ marginLeft: '8px' }}
                    onClick={handleClick}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
            }
      {
                props.type === 'table' &&
                props.tableType === '2-Column' &&
                  <IconButton>
                    <ViewWeekOutlinedIcon />
                  </IconButton>
            }
      <div>
        <Menu
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
        >
          <div className='field-type-menu-left' style={{ paddingTop: '10px', paddingBottom: '10px', width: '240px' }}>
            <MenuItem
              onClick={() => changeHandler('media')}
              selected={props.currentType === 'media'}
              disabled={props.parentType === 'table'}
            >
              <span style={styles.icon}>{fieldTypeConfig.media.icon}</span>
              {fieldTypeConfig.media.label}
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('freeFormCapture')}
              selected={props.currentType === 'freeFormCapture'}
              disabled={props.parentType === 'table'}
            >
              <span style={styles.icon}>{fieldTypeConfig.freeFormCapture.icon}</span>
              {/* {fieldTypeConfig.productRecommendation.label} */}
              <span>Sketch</span>
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('addSolutions')}
              selected={props.currentType === 'addSolutions'}
              disabled={props.parentType === 'table'}
            >
              <span style={styles.icon}>{fieldTypeConfig.addSolutions.icon}</span>
              <span>Select Solutions</span>
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('customerWhy')}
              selected={props.currentType === 'customerWhy'}
              disabled={props.parentType === 'table'}
            >
              <span style={styles.icon}>{fieldTypeConfig.customerWhy.icon}</span>
              <span>Customer Why</span>
            </MenuItem>

            <Divider className='divider-horizontal-menu' style={styles.marginHor} />

            <MenuItem
              onClick={() => changeHandler('boolean')}
              selected={props.currentType === 'boolean'}
            >
              <span style={styles.icon}>{fieldTypeConfig.boolean.icon}</span>
              {fieldTypeConfig.boolean.label}
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('radio')}
              selected={props.currentType === 'radio'}
            >
              <span style={styles.icon}>{fieldTypeConfig.radio.icon}</span>
              {fieldTypeConfig.radio.label}
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('checkbox')}
              selected={props.currentType === 'checkbox'}
            >
              <span style={styles.icon}>{fieldTypeConfig.checkbox.icon}</span>
              {fieldTypeConfig.checkbox.label}
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('choice')}
              selected={props.currentType === 'choice'}
            >
              <span style={styles.icon}>{fieldTypeConfig.choice.icon}</span>
              {fieldTypeConfig.choice.label}
            </MenuItem>

            <Divider className='divider-horizontal-menu' style={styles.marginHor} />

            <MenuItem
              onClick={() => changeHandler('numeric')}
              selected={props.currentType === 'numeric'}
            >
              <span style={styles.icon}>{fieldTypeConfig.numeric.icon}</span>
              Numeric
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('calc')}
              selected={props.currentType === 'calc'}
            >
              <span style={styles.icon}>{fieldTypeConfig.calc.icon}</span>
              Calculator
            </MenuItem>

            <Divider className='divider-horizontal-menu' style={styles.marginHor} />

            <MenuItem
              onClick={() => changeHandler('text')}
              selected={props.currentType === 'text'}
            >
              <span style={styles.icon}>{fieldTypeConfig.text.icon}</span>
              Short Text
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('type')}
              selected={props.currentType === 'type'}
            >
              <span style={styles.icon}>{fieldTypeConfig.type.icon}</span>
              Long Text
            </MenuItem>

            <Divider className='divider-horizontal-menu' style={styles.marginHor} />

            <MenuItem
              onClick={() => changeHandler('table_2')}
              selected={props.currentType === 'table_2'}
              disabled={props.parentType === 'table'}
            >
              <span style={styles.icon}>{fieldTypeConfig.table_2.icon}</span>
              2-Column Row
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('table_3')}
              selected={props.currentType === 'table_3'}
              disabled={props.parentType === 'table'}
            >
              <span style={styles.icon}>{fieldTypeConfig.table_3.icon}</span>
              3-Column Row
            </MenuItem>

            <Divider className='divider-horizontal-menu' style={styles.marginHor} />

            <MenuItem
              onClick={() => changeHandler('productRecommendation')}
              selected={props.currentType === 'productRecommendation'}
              disabled={props.parentType === 'table'}
            >
              <span style={styles.icon}>{fieldTypeConfig.productRecommendation.icon}</span>
              {/* {fieldTypeConfig.productRecommendation.label} */}
              <span>Product Recommendation</span>
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('productRecommendationProductList')}
              selected={props.currentType === 'productRecommendationProductList'}
              disabled={props.parentType === 'table'}
            >
              <span style={styles.icon}>{fieldTypeConfig.productRecommendationProductList.icon}</span>
              <span>Product Dropdown</span>
            </MenuItem>
          </div>

          <div className='field-type-menu-center'>
            <Divider
              className='divider-vertical-menu' orientation='vertical' style={{ left: 'auto' }}
              flexItem
            />
          </div>

          <div
            className='field-type-menu-right'
            style={{ paddingTop: '10px', paddingBottom: '10px', width: '216px' }}
          >
            {/* <MenuItem
                            onClick={() => changeHandler('media')}
                            selected={props.currentType === 'media'}
                        >
                            <span style={styles.icon}>{fieldTypeConfig.media.icon}</span>
                            {fieldTypeConfig.media.label}
                        </MenuItem> */}

            <MenuItem
              onClick={() => changeHandler('title')}
              selected={props.currentType === 'title'}
              disabled={props.parentType === 'table'}
            >
              <span style={styles.icon}>{fieldTypeConfig.title.icon}</span>
              Section Formatting
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('noresponse')}
              selected={props.currentType === 'noresponse'}
              disabled={props.parentType === 'table'}
            >
              <span style={styles.icon}>{fieldTypeConfig.noresponse.icon}</span>
              {fieldTypeConfig.noresponse.label}
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('checklist')}
              selected={props.currentType === 'checklist'}
              disabled={props.parentType === 'table'}
            >
              <span style={styles.icon}>{fieldTypeConfig.checklist.icon}</span>
              {fieldTypeConfig.checklist.label}
            </MenuItem>
            <Divider className='divider-horizontal-menu' style={styles.marginHor} />

            <MenuItem
              onClick={() => changeHandler('pdf')}
              selected={props.currentType === 'pdf'}
              disabled={props.parentType === 'table'}
            >
              <span style={styles.icon}>{fieldTypeConfig.pdf.icon}</span>
              {fieldTypeConfig.pdf.label}
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('image')}
              selected={props.currentType === 'image'}
              disabled={props.parentType === 'table'}
            >
              <span style={styles.icon}>{fieldTypeConfig.image.icon}</span>
              {fieldTypeConfig.image.label}
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('video')}
              selected={props.currentType === 'video'}
              disabled={props.parentType === 'table'}
            >
              <span style={styles.icon}>{fieldTypeConfig.video.icon}</span>
              {fieldTypeConfig.video.label}
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('contentBlock')}
              selected={props.currentType === 'contentBlock'}
              disabled={props.parentType === 'table'}
            >
              <span style={styles.icon}>{fieldTypeConfig.contentBlock.icon}</span>
              {fieldTypeConfig.contentBlock.label}
            </MenuItem>

            <Divider className='divider-horizontal-menu' style={styles.marginHor} />

            <MenuItem
              onClick={() => changeHandler('date')}
              selected={props.currentType === 'date'}
            >
              <span style={styles.icon}>{fieldTypeConfig.date.icon}</span>
              Date Selector
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('time')}
              selected={props.currentType === 'time'}
            >
              <span style={styles.icon}>{fieldTypeConfig.time.icon}</span>
              Time Selector
            </MenuItem>

            <Divider className='divider-horizontal-menu' style={styles.marginHor} />

            <MenuItem
              onClick={() => changeHandler('areaName')}
              selected={props.currentType === 'areaName'}
              disabled={props.parentType === 'table'}
            >
              <span style={styles.icon}>{fieldTypeConfig.areaName.icon}</span>
              {fieldTypeConfig.areaName.label}
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('addAreaListAdvanced')}
              selected={props.currentType === 'addAreaListAdvanced'}
              disabled={props.parentType === 'table'}
            >
              <span style={styles.icon}>{fieldTypeConfig.addAreaListAdvanced.icon}</span>
              <span>Form Automation</span>
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('area')}
              selected={props.currentType === 'area'}
              disabled={props.parentType === 'table'}
            >
              <span style={styles.icon}>{fieldTypeConfig.area.icon}</span>
              {fieldTypeConfig.area.label}
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('addAreaCheckbox')}
              selected={props.currentType === 'addAreaCheckbox'}
              disabled={props.parentType === 'table'}
            >
              <span style={styles.icon}>{fieldTypeConfig.addAreaCheckbox.icon}</span>
              {fieldTypeConfig.addAreaCheckbox.label}
            </MenuItem>
            <MenuItem
              onClick={() => changeHandler('relatedInspectAreas')}
              selected={props.currentType === 'relatedInspectAreas'}
              disabled={props.parentType === 'table'}
            >
              <span style={styles.icon}>{fieldTypeConfig.relatedInspectAreas.icon}</span>
              {fieldTypeConfig.relatedInspectAreas.label}
            </MenuItem>
          </div>
        </Menu>
      </div>
    </>
  )
}

const styles = {
  icon: {
    paddingRight: '16px'
  },
  marginHor: {
    // marginLeft: "10px",
    // marginRight: "10px"
  },
  marginVer: {
    // marginTop: "10px",
    // marginBottom: "10px"
  }
}

export default FieldTypeSelect
