import React, { useState, useEffect } from 'react'
import { Box, Button, CardMedia, Grid, Stack, Typography } from '@mui/material'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import SelectorWithPreview from 'components/Reusable/SelectorWithPreview'
import { getAssetInfo } from 'screens/Config/CompanyConfigPresentations'

export const RightPanelPoster = (props) => {
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [asset, setAsset] = useState({})
  const [type, setType] = useState('')
  const [assetId, setAssetId] = useState('') // tmp until save.

  useEffect(() => {
    if (props.solution.asset) {
      const a = getAssetInfo(props.solution.asset, props.assetsConfig)
      if (a && a.previewURL) {
        setAsset(a)
      }
    }
    // eslint-disable-next-line
  }, [])

  const handleUpdate = (a) => {
    // SelectorWithPreview does not have a cancel, so I'm using the DialogGeneric exit strategy.
    if (a) {
      setAssetId(a.id)
    } else {
      setAssetId(null)
    }
  }

  const handleSave = () => {
    if (assetId) {
      const s = JSON.parse(JSON.stringify(props.solution))
      s.asset = assetId
      const a = getAssetInfo(assetId, props.assetsConfig)
      setAsset(a)
      props.setSolution(s)
    }
  }

  return (
    <>
      <Box sx={{ p: 2, borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Typography style={styles.title}>{props.title || 'Poster Setup'}</Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <Grid container wrap='nowrap'>
          <Grid item xs={12} sm={6}>
            <Stack>
              <Box>
                <Typography sx={{ pb: 1 }}>Select Poster Image</Typography>
              </Box>
              {asset.previewURL
                ? (
                  <Box style={styles.imageContainer}>
                    {asset.previewURL
                      ? (
                        <a href={asset.previewURL} target='_blank' rel='noreferrer'>
                          <CardMedia component='img' height='160' sx={{ objectFit: 'contain' }} image={asset.previewURL} alt={asset.previewURL} />
                        </a>
                        )
                      : (
                          ''
                        )}
                  </Box>
                  )
                : (
                  <Typography variant='body2'>There is no poster image. Please add one.</Typography>
                  )}
              {asset.filename
                ? (
                  <Box sx={{ pt: 2 }}>
                    <code style={{ color: 'black' }}>{asset.filename}</code>
                  </Box>
                  )
                : null}
              <Box sx={{ pt: 2 }}>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    setType('poster')
                    setShowAddDialog(!showAddDialog)
                  }}
                >
                  {props.solution.asset ? 'Replace Poster' : 'Add Poster'}
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      {showAddDialog
        ? (
          <DialogGeneric
            title='Select Poster Asset'
            content={
              <SelectorWithPreview
                selectedConfig={props.solution}
                fieldName={type}
                config={props.config.assets2}
                assetType='Image'
                onUpdate={(a) => {
                  handleUpdate(a)
                }}
              />
          }
            fullWidth
            onChange={() => {
              handleSave()
              setShowAddDialog(false)
            }}
            onClose={() => setShowAddDialog(false)}
          />
          )
        : null}
    </>
  )
}

const styles = {
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  textField: {
    fontSize: 14
  },
  imageContainer: {
    display: 'flex',
    padding: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,.04)',
    border: '1px dashed rgba(0,0,0,.16)'
  }
}
