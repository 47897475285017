import React from 'react'
import { Box, Button, Chip, Grid, IconButton, Stack, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import WarningIcon from '@mui/icons-material/Warning'
import Api from 'library/Api'
import CompanyConfigEdit, { CompanyConfigBaseEditor } from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import DataGrid from 'components/Reusable/DataGrid'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import { LoadingPleaseWait } from '@supportworks/react-components'
import { PresentationEditor } from './Presentation/PresentationEditor'
import { DialogPresentationSelector } from 'components/Reusable/DialogPresentationSelector'
import '../../css/select-search.css'
import '../../css/selector-material-ui.css'
import brokenImageSvg from '../../images/broken-image-128.png'

const config = {
  title: 'Presentations',
  variant: 'Presentations',
  tag: 'presentations',
  name: 'Presentations',
  lockTag: true,
  lockName: true
}

class CompanyConfigPresentations extends CompanyConfigBaseEditor {
  constructor (props) {
    super(props)
    let presentationsInfo = []
    if (props.contentJson && typeof props.contentJson === 'string') {
      presentationsInfo = JSON.parse(props.contentJson)
    }
    this.state = {
      isLoading: true,
      isAdding: false,
      presentationsList: presentationsInfo,
      config: null,
      assetsConfig: null,
      solutionsConfig: null,
      presentationsConfig: null,
      filter: '',
      presentation: null,
      showPresentationEditor: false,
      showImportLibraryDialog: false,
      showLibraryUpdateDialog: false,
      libraryUpdateIndex: -1
    }

    this.getItemTitle = this.getItemTitle.bind(this)
    this.handleLibraryAdd = this.handleLibraryAdd.bind(this)
  }

  componentDidMount () {
    super.componentDidMount()
    this.state.presentationsList.forEach((m) => {
      this.addExistingGuid(m.id)
    })

    Api.callRegisteredMethod('getAssetList', {}).then((assetList) => {
      const assets = assetList

      Api.callRegisteredMethod('getCurrentConfig', { tag: 'all', startsWith: false }).then((config) => {
        const presentationsC = []
        if (config && 'presentations' in config) {
          config.presentations.forEach((listItem) => {
            presentationsC.push({ value: listItem.id, name: listItem.title })
          })
        }

        const solutionsC = []
        if (config && 'solutions' in config) {
          config.solutions.forEach((listItem) => {
            solutionsC.push({ value: listItem.id, name: listItem.title })
          })
        }

        const presentationsList = this.state.presentationsList.map((presentation) => {
          if ('presentations' in presentation.followUp && Array.isArray(presentation.followUp.presentations)) {
            presentation.followUp.presentations = presentation.followUp.presentations.filter((pres) => {
              if (presentationsC.filter((pc) => pc.value === pres).length > 0) {
                return true
              } else {
                return false
              }
            })
          }
          if ('presentations' in presentation.followUp && Array.isArray(presentation.followUp.solutions)) {
            presentation.followUp.solutions = presentation.followUp.solutions.filter((sol) => {
              if (solutionsC.filter((sc) => sc.value === sol).length > 0) {
                return true
              } else {
                return false
              }
            })
          }

          if (presentation.status === 'notadded') {
            presentation.followUp = presentation.library.currentVersion.followUp
          }

          return presentation
        })

        this.setState({
          isLoading: false,
          config, // Just pass the whole thing and let the child component pick what it wants
          presentationsList: presentationsList.sort((a, b) => (a.title > b.title ? 1 : -1)),
          presentationsConfig: presentationsC.sort((a, b) => (a.name > b.name ? 1 : -1)),
          assetsConfig: assets.sort((a, b) => (a.name > b.name ? 1 : -1)),
          solutionsConfig: solutionsC.sort((a, b) => (a.name > b.name ? 1 : -1))
        })
      })
    })
  }

  handleAdd = () => {
    const presentation = {
      id: this.guid(),
      status: 'custom', // default
      title: '',
      poster: '',
      asset: '',
      followUp: {
        solutions: [],
        presentations: []
      }
    }
    this.setState({
      isAdding: true,
      presentation,
      showPresentationEditor: !this.state.showPresentationEditor
    })
  }

  handleDelete (indexes) {
    const count = indexes.length
    const confirmMessage = count === 1 ? 'Are you sure you want to delete this item?' : `Are you sure you want to delete these ${count} items?`
    const r = window.confirm(confirmMessage)
    if (r) {
      const updatedPresentationsList = this.state.presentationsList.map((item, index) => {
        // Set status to 'notadded' for items that are being 'deleted'
        if (indexes.includes(index)) {
          return { ...item, status: 'notadded' }
        }
        return item
      })
      this.setState({ presentationsList: updatedPresentationsList }, () => {
        this.props.onChange(JSON.stringify(updatedPresentationsList))
      })
    }
  }

  handleSave = (p) => {
    const list = JSON.parse(JSON.stringify(this.state.presentationsList))
    if (this.state.isAdding) {
      list.push(p)
    } else {
      if (p.status === 'added') {
        p.status = 'edited'
        p.changedByUser = true
      } else if (p.status === 'added1') {
        p.changedByUser = true
      }
      const idx = list.map((o) => o.id).indexOf(p.id)
      if (idx > -1) {
        list[idx] = p
      }
    }
    this.setState({
      presentationsList: list,
      presentation: null,
      showPresentationEditor: false,
      isAdding: false
    })
    const str = JSON.stringify(list)
    this.props.onChange(str) // Trigger unsaved changes.
  }

  handlePresentationSelect = (p) => {
    this.setState({ presentation: p, showPresentationEditor: !this.state.showPresentationEditor })
  }

  handleClose = () => {
    this.setState({ presentation: null, showPresentationEditor: !this.state.showPresentationEditor })
  }

  handleLibraryAdd = () => {
    this.setState({ showImportLibraryDialog: !this.state.showImportLibraryDialog })
  }

  handleLibrarySave = (l) => {
    const list = JSON.parse(JSON.stringify(this.state.presentationsList))
    for (let x = 0; x < list.length; x++) {
      if (l.includes(list[x].id)) {
        if (list[x].status && list[x].status !== 'custom') {
          list[x].status = 'added1'
        }
      } else {
        if (list[x].status && list[x].status.match(/^(added|edited)/)) {
          list[x].status = 'deleted'
        }
      }
    }
    this.setState({ presentationsList: list }, () => {
      this.props.onChange(JSON.stringify(list))
    })
  }

  triggerLibraryUpdateDialog = (idx) => {
    this.setState({ showLibraryUpdateDialog: !this.state.showLibraryUpdateDialog, libraryUpdateIndex: idx })
  }

  handleLibraryUpdate = () => {
    const idx = this.state.libraryUpdateIndex
    if (idx > -1) {
      const list = JSON.parse(JSON.stringify(this.state.presentationsList))
      if (list[idx].library && list[idx].library.currentVersion) {
        const libcopy = JSON.parse(JSON.stringify(list[idx].library))
        libcopy.changed = false
        list[idx] = list[idx].library.currentVersion
        list[idx].library = libcopy
        list[idx].status = 'added1'
      }
      this.setState({ presentationsList: list, showLibraryUpdateDialog: false, libraryUpdateIndex: -1 }, () => {
        this.props.onChange(JSON.stringify(list))
      })
    }
  }

  getItemTitle (id, config) {
    let a
    config.forEach((c) => {
      if (c.value === id) {
        a = c
      }
    })
    if (a && 'name' in a) {
      return a.name
    }
  }

  getAsset (id) {
    const x = this.state.assetsConfig.filter((arr) => {
      return arr.guid === id
    })
    if (x.length !== 0) {
      return x[0]
    } else {
      return undefined
    }
  }

  handleDefaultThumbnail = (e) => {
    e.target.src = brokenImageSvg
  }

  hasFinalize () {
    return true
  }

  finalize () {
    return JSON.stringify(this.state.presentationsList)
  }

  render () {
    if (this.state.isLoading) {
      return <LoadingPleaseWait />
    }

    const columns = [
      {
        type: 'string',
        field: 'poster',
        headerName: 'Poster',
        editable: false,
        sortable: false,
        renderCell: (params) => {
          if (params.row) {
            const asset = params.row.asset
            return (
              <Box>
                {asset && asset.previewURL
                  ? (
                    <a target='_blank' href={asset.path ? asset.path : asset.previewURL} rel='noreferrer'>
                      <img onError={this.handleDefaultThumbnail} src={asset.previewURL} alt={asset.previewURL} width='96' style={{ maxHeight: '53px' }} />
                    </a>
                    )
                  : null}
              </Box>
            )
          }
        }
      },
      {
        type: 'string',
        field: 'type',
        headerName: 'Type',
        editable: false,
        sortable: true,
        renderCell: (params) => {
          if (params.row) {
            const formattedType = params.row.type ? params.row.type.charAt(0).toUpperCase() + params.row.type.slice(1) : ''
            return (
              <Box sx={{ p: 1 }}>
                <Typography variant='body' noWrap>
                  {formattedType}
                </Typography>
              </Box>
            )
          }
        }
      },
      {
        flex: 2,
        type: 'string',
        field: 'title',
        headerName: 'Title',
        editable: false,
        sortable: true,
        renderCell: (params) => (
          <Typography noWrap variant='body2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {params.value}
          </Typography>
        )
      },
      {
        width: 128,
        type: 'string',
        field: 'followUps',
        headerName: 'Follow-Ups',
        editable: false,
        sortable: false,
        renderCell: (params) => {
          if (params.row) {
            return (
              <Box>
                {params.row.followUps.solutions.length === 0
                  ? (
                    <Chip key='chip-fup' sx={{ mb: 0.5 }} label='No Solutions' size='small' />
                    )
                  : (
                    <Chip key='chip-fup' sx={{ mb: 0.5 }} label={`${params.row.followUps.solutions.length} Solutions`} size='small' />
                    )}
                <br />
                {params.row.followUps.presentations.length === 0
                  ? (
                    <Chip key='chip-fus' label='No Presentations' size='small' />
                    )
                  : (
                    <Chip key='chip-fus' label={`${params.row.followUps.presentations.length} Presentations`} size='small' />
                    )}
              </Box>
            )
          }
        }
      },
      {
        type: 'string',
        field: 'status',
        headerName: 'Status',
        editable: false,
        sortable: true,
        renderCell: (params) => {
          if (params.row) {
            return (
              <Box>
                {params.row.changed
                  ? (
                    <IconButton
                      title='Update Library'
                      aria-label='Update Library'
                      onClick={() => {
                        this.triggerLibraryUpdateDialog(params.row.id)
                      }}
                      size='small'
                    >
                      <WarningIcon size='small' style={{ color: 'red' }} />
                    </IconButton>
                    )
                  : params.row.status === 'added'
                    ? (
                      <CheckIcon size='small' style={{ color: 'green' }} sx={{ pr: 1 }} />
                      )
                    : params.row.status === 'edited'
                      ? (
                        <CheckIcon size='small' style={{ color: 'green' }} sx={{ pr: 1 }} />
                        )
                      : params.row.status === 'added1'
                        ? (
                          <CheckIcon size='small' style={{ color: 'green' }} sx={{ pr: 1 }} />
                          )
                        : params.row.status === 'custom'
                          ? (
                            <Chip key='chip-custom' label='Custom' size='small' />
                            )
                          : params.row.status === 'deleted'
                            ? (
                              <Chip key='chip-deleted' label='Deleted' size='small' />
                              )
                            : params.row.status === 'notadded'
                              ? (
                                <Chip key='chip-notadded' label='Not Added' size='small' />
                                )
                              : null}
                {params.row.changedByUser ? <Chip key='chip-modified' label='Modified' size='small' /> : null}
              </Box>
            )
          }
        }
      },
      {
        flex: 2,
        type: 'string',
        field: 'library',
        headerName: 'Library',
        editable: false,
        sortable: true,
        renderCell: (params) => (
          <Typography noWrap variant='body2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {params.value}
          </Typography>
        )
      },
      {
        width: 168,
        field: 'action',
        headerName: 'Actions',
        headerAlign: 'right',
        align: 'right',
        editable: false,
        sortable: false,
        disableExport: true,
        renderCell: (params) => {
          if (params.row) {
            const present = this.state.presentationsList.find((o) => o.id === params.row.configId)
            return (
              <Grid container wrap='nowrap' justifyContent='flex-end'>
                <Grid item>
                  <IconButton
                    title='Edit'
                    disabled={present?.id === undefined}
                    aria-label='edit'
                    onClick={() => {
                      this.handlePresentationSelect(present)
                    }}
                  >
                    <EditIcon fontSize='small' />
                  </IconButton>
                </Grid>
                {this.state.readonly
                  ? null
                  : (
                    <Grid item>
                      <IconButton
                        title='Delete'
                        aria-label='delete'
                        onClick={() => {
                          this.handleDelete([params.row.id])
                        }}
                      >
                        <DeleteIcon variant='icon' fontSize='small' />
                      </IconButton>
                    </Grid>
                    )}
              </Grid>
            )
          }
        }
      }
    ]

    const rows = []
    for (let x = 0; x < this.state.presentationsList.length; x++) {
      const p = this.state.presentationsList[x]
      if (p.library) {
        if (p.status.match(/notadded|deleted/)) {
          continue
        }
      }

      let asset = null
      if (p.asset) {
        asset = this.getAsset(p.asset)
      } else if (p.poster) {
        asset = this.getAsset(p.poster)
      }
      const row = {
        id: x,
        configId: p.id,
        asset,
        type: p.type,
        title: p.title,
        followUps: { solutions: p.followUp.solutions ? p.followUp.solutions : [], presentations: p.followUp.presentations ? p.followUp.presentations : [] },
        status: p.status,
        changed: p.library?.changed ? p.library.changed : false,
        changedByUser: p.changedByUser ? p.changedByUser : false,
        library: p.library ? p.library.libraryName : ''
      }
      rows.push(row)
    }

    return (
      <>
        <Box sx={{ p: 0 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            useStandardRowHeight={false}
            showToolbarDensity={false}
            defaultPageSize={50}
            onAdd={this.handleAdd}
            onDelete={(indexes) => {
              this.handleDelete(indexes)
            }}
            autosizeOptions={{
              columns: ['action'],
              includeOutliers: true,
              includeHeaders: false
            }}
          />
        </Box>

        {this.state.showPresentationEditor
          ? (
            <PresentationEditor
              config={this.state.config}
              currentPresentations={this.state.presentationsList.filter((o) => !o.status || (o.status && o.status.match(/^(added|edited|custom)/)))}
              assetsConfig={this.state.assetsConfig}
              presentation={this.state.presentation}
              onClose={this.handleClose}
              onSave={this.handleSave}
              isAdding={this.state.isAdding}
            />
            )
          : null}

        {this.state.showImportLibraryDialog
          ? (
            <DialogPresentationSelector
              title='Select Presentations'
              variant='library'
              list={this.state.presentationsList.filter((o) => o.status && o.status.match(/^(notadded|added|edited)/))}
              presentations={this.state.presentationsList.filter((o) => o.status && o.status !== 'custom')}
              assets={this.state.assetsConfig}
              onSave={(l) => {
                this.handleLibrarySave(l)
                this.setState({ showImportLibraryDialog: !this.state.showImportLibraryDialog })
              }}
              onClose={() => this.setState({ showImportLibraryDialog: !this.state.showImportLibraryDialog })}
            />
            )
          : null}

        {this.state.showLibraryUpdateDialog
          ? (
            <DialogGeneric
              title='Update Presentation'
              content={
                <Box>
                  <Stack>
                    <Typography variant='body1' sx={{ pb: 2 }}>
                      You are about to update this presentation: <strong>{this.state.presentationsList[this.state.libraryUpdateIndex].title}</strong>.
                    </Typography>
                    <Typography variant='body1' sx={{ pb: 2 }}>
                      By updating the presentation, you will replace the presentation in its current state.
                    </Typography>
                    <Typography variant='body1'>
                      If you have modified the presentation, it is encouraged that you document any custom changes so that you can update the presentation.
                    </Typography>
                  </Stack>
                </Box>
            }
              fullWidth
              onChange={this.handleLibraryUpdate}
              onClose={() => this.setState({ showLibraryUpdateDialog: !this.state.showLibraryUpdateDialog })}
              titleDone='Update'
              titleClose='Cancel'
            />
            )
          : null}
        <Box sx={{ pt: 1 }}>
          <Button variant='contained' color='success' onClick={() => this.handleAdd()} startIcon={<AddIcon />}>
            Add Presentation
          </Button>
        </Box>
      </>
    )
  }
}

/**
 * getAssetInfo()
 *
 * Importable function that will return asset information from an ID and full assetsConfig structure.
 *
 * @param {*} id
 * @param {*} config
 * @return {*}
 */
const getAssetInfo = (id, config) => {
  const x = config.find((o) => o.id === id)
  if (x) {
    return x
  }
  return null
}
export { getAssetInfo }

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} editorComponent={CompanyConfigPresentations} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}
