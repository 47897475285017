import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { RightPanelPresentations } from './RightPanelPresentations'
import { RightPanelPoster } from './RightPanelPoster'
import { RightPanelProducts } from './RightPanelProducts'

const RightPanel = (props) => {
  return (
    <Grid container style={{ height: '100%' }}>
      {!props.rightPanelVariant
        ? (
          <Grid container spacing={0} direction='row' alignItems='center' justifyContent='center' style={{ minHeight: '50vh' }}>
            <Typography variant='h7'>Please select an editor step on the left.</Typography>
          </Grid>
          )
        : null}
      <Box style={styles.container}>
        {props.rightPanelVariant === 'presentation' ? <RightPanelPresentations {...props} /> : null}
        {props.rightPanelVariant === 'poster' ? <RightPanelPoster {...props} /> : null}
        {props.rightPanelVariant === 'products' ? <RightPanelProducts {...props} /> : null}
      </Box>
    </Grid>
  )
}
export default RightPanel

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'white'
  }
}
