import React, { useState, useEffect } from 'react'
import { FormControl, FormControlLabel, TextField, RadioGroup, Radio, Button } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutline'

const numFieldCond = [
  { value: 'eq', name: 'Equal to' },
  { value: 'gt', name: 'Greater than' },
  { value: 'gte', name: 'Greater than, equal to' },
  { value: 'lt', name: 'Less than' },
  { value: 'lte', name: 'Less than, equal to' }
]

const MapFormFieldNumeric = ({ config: defaultConfig, setProduct, product, mappedFieldObject, variant, setShowSaveButton, showSpecParagraph = true }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [config, setConfig] = useState(defaultConfig)
  const [conditional, setConditional] = useState('')
  const [quantity, setQuantity] = useState('')
  const [conditionals, setConditionals] = useState([])
  const [mode, setMode] = useState()
  const [conditionalIdx, setConditionalIdx] = useState(-1)
  const [showConditionSelection, setShowConditionSelection] = useState(false)

  useEffect(() => {
    console.log('useEffect start [] triggered')
    // Initialize all things on the component with existing product data.
    if (isLoading) {
      try {
        let conditionals = []
        if (!config.values) {
          /**
           * The config format that is needed in MapFormFieldNumericSpec is odd, so we need special code.
           * It basically does not put the conditional key/value pairs inside of another values: [] array,
           * so these values sit at the same level in the hash as the name, which is called "config".
           */
          if (variant === 'specification') {
            Object.keys(config).forEach((k) => {
              if (k !== 'config') {
                conditionals.push({ numFieldCond: k, [k]: config[k] })
              }
            })
          } else {
            config.field = mappedFieldObject.id
            config.values = []
          }
        } else {
          conditionals = config.values
        }
        setConfig(config)
        setConditionals(conditionals)
        setProduct({ ...product })
      } catch (err) {
        console.log(err)
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line 

  useEffect(() => {
    console.log('useEffect conditionalIdx triggered')
    if (conditionalIdx >= 0) {
      setConditional(conditionals[conditionalIdx].numFieldCond)
      setQuantity(conditionals[conditionalIdx][conditionals[conditionalIdx].numFieldCond])
    }
  }, [conditionalIdx]) // eslint-disable-line 

  const handleChange = (event) => {
    const { name, value } = event.target
    if (name === 'conditional') {
      setConditional(value)
    } else if (name === 'quantity') {
      setQuantity(value)
    }
  }

  const handleSave = () => {
    /**
     * The config format that is needed in MapFormFieldNumericSpec is different.  It basically does not throw
     * the conditional key/value pair inside of another values: [] array.
     */
    const c = conditionals.find((o) => { return o.numFieldCond === conditional })
    const cond = numFieldCond.find((o) => { return o.value === conditional })
    const error = `There is already an existing conditional for '${cond.name}'. \r\n\r\nPlease try another.`
    if (conditionalIdx >= 0) {
      // EDIT - Do not allow changing to something that exists. DEV-2749
      if (c) {
        window.alert(error)
      } else {
        conditionals[conditionalIdx] = {}
        conditionals[conditionalIdx].numFieldCond = conditional
        conditionals[conditionalIdx][conditionals[conditionalIdx].numFieldCond] = quantity
      }
    } else {
      // NEW - Do not allow dupes. DEV-2749
      if (c) {
        window.alert(error)
      } else {
        conditionals.push({ [conditional]: quantity || 0, numFieldCond: conditional })
      }
    }
    if (variant === 'specification') {
      config[conditional] = quantity
    } else {
      setConfig({ ...config, values: conditionals })
    }
    setShowConditionSelection(false)
    setConditionals(conditionals)
    setConditionalIdx(-1)
    if (setShowSaveButton) setShowSaveButton(true)
    setProduct({ ...product })
    setMode('')
  }

  const handleNew = () => {
    setShowConditionSelection(true)
    setConditionalIdx(-1)
    setConditional('')
    setQuantity('')
    setMode('NEW')
  }

  const handleEdit = (idx) => {
    setShowConditionSelection(true)
    setConditionalIdx(idx)
    setMode('EDIT')
  }

  const handleDelete = (idx, cond) => {
    const c = conditionals
    c.splice(idx, 1)
    setConditionals(c)
    if (variant === 'specification') {
      delete config[cond]
      setProduct({ ...product })
      setConfig({ ...config })
    } else {
      setProduct({ ...product })
      setConfig({ ...config, values: c })
    }
    if (setShowSaveButton) setShowSaveButton(true)
  }

  const handleCancel = () => {
    setConditionalIdx(-1)
    setConditional('')
    setQuantity('')
    setShowConditionSelection(false)
  }

  let disabled = true
  if (conditional && quantity) disabled = false

  return (
    <>
      {showSpecParagraph === true
        ? (
          <div className='sa-section'>
            <div className='sa-spec-paragraph'>
              <span>Maintain conditional statements that will trigger the products to be added to the solution.</span>
            </div>
          </div>
          )
        : null}
      {showConditionSelection
        ? (
          <div className='sa-section'>
            <div className='sa-heading'>
              Edit the numeric range, then press <span><strong>{mode === 'NEW' ? 'Add' : 'Save'}</strong></span>
            </div>
            <div className='sa-section'>
              <div className='sa-indent'>
                <FormControl key='FormControl' variant='outlined' fullWidth>
                  <RadioGroup name='conditional' value={conditional} onChange={handleChange}>
                    {numFieldCond.map((row, idx) => {
                      let exists = conditionals.find((o) => { return (o.numFieldCond === row.value) })
                      if (conditionalIdx !== -1 && conditionals[conditionalIdx].numFieldCond === row.value) {
                        exists = false
                      }
                      return (
                        <div key={`MFFNNFC-${idx}`} style={{ maxHeight: '42px' }}>
                          <div style={{ float: 'left' }}>
                            <FormControlLabel
                              disabled={exists ? true : null}
                              key={row.value}
                              value={row.value}
                              label={row.name}
                              id={row.value}
                              control={<Radio size='small' color='primary'> </Radio>}
                            />
                          </div>
                          <div style={{ marginLeft: '0px', marginTop: '5px' }}>
                            {exists
                              ? <span className='sa-text12' style={{ color: '#AAA' }}>(Currently set to  {exists[exists.numFieldCond]})</span>
                              : null}
                          </div>
                        </div>
                      )
                    })}
                  </RadioGroup>
                  <TextField
                    name='quantity'
                    id='quantity'
                    value={quantity}
                    label='Enter Numeric Value'
                    variant='outlined'
                    onChange={handleChange}
                  />
                </FormControl>

              </div>
            </div>

            <div className='sa-footer-container' style={{ zIndex: '3' }}>
              <div className='sa-button-left'>
                <Button color='primary' variant='contained' onClick={handleCancel}>
                  <span className='sa-button'>Cancel</span>
                </Button>
              </div>
              <div className='sa-button-right'>
                <Button color='primary' variant='contained' onClick={handleSave} disabled={disabled}>
                  <span className='sa-button'>{mode === 'NEW' ? 'Add' : 'Save'}</span>
                </Button>
              </div>
              <div style={{ clear: 'both' }} />
            </div>
          </div>
          )
        : (
          <div className='sa-section'>
            <div className='sa-heading'>
              Click <strong>New</strong> to add a conditional statement and complete the instructions.
            </div>
            <div className='sa-button-left'>
              <Button style={{ marginTop: '8px' }} color='primary' variant='contained' onClick={handleNew}>
                <span className='sa-button'>New</span>
              </Button>
            </div>
            <div style={{ clear: 'both' }} />
          </div>
          )}

      {!showConditionSelection && conditionals && conditionals.length > 0
        ? <Conditionals
            conditionals={conditionals}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        : null}
    </>
  )
}
export default MapFormFieldNumeric

function Conditionals ({ conditionals, onEdit, onDelete }) {
  if (conditionals && conditionals.length === 0) {
    return <></>
  }

  return (
    <div className='sa-section'>
      <div className='sa-section'>
        <div className='sa-heading'>Conditionals</div>
      </div>
      {
                conditionals.map((row, idx) => {
                  const cond = numFieldCond.find((o) => { return o.value === row.numFieldCond })
                  return (
                    <div key={`MFFNC-${idx}`} className='sa-conditional-container'>
                      <div className='sa-conditional-left'>
                        <span>
                          {cond && cond.name ? cond.name : null} {row[row.numFieldCond]}
                        </span>
                      </div>
                      <div>
                        <span className='sa-icon'><EditIcon onClick={() => { onEdit(idx) }} /></span>
                        <span className='sa-icon'><DeleteIcon onClick={() => { onDelete(idx, row.numFieldCond) }} /></span>
                      </div>
                    </div>
                  )
                })
            }
    </div>
  )
};
