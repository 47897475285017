import React, { useState, useEffect } from 'react'
import { Alert, AlertTitle, Box, Button, Grid, TextField, Typography } from '@mui/material'
import Api from 'library/Api'
import { LoadingOverlay } from 'components/Reusable'
import { LogoMark } from 'components/LogoMark'
import { Topics } from 'config/topics'
import { LoadingPleaseWait } from '@supportworks/react-components'

export const LoginScreenHook = ({ username, context, topic }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [state, setState] = useState({
    username: username || '',
    password: '',
    context: context || '',
    selectedContext: null,
    contexts: null,
    errors: null
  })

  useEffect(() => {
    if (state.contexts) {
      setState({ ...state })
      setIsLoading(false)
    }
    fetchData()
    // eslint-disable-next-line
  }, [])

  const fetchData = () => {
    Api.callRegisteredMethod('getAuthContexts', {
      callback: (contexts) => {
        setState({ ...state, contexts })
        setIsLoading(false)
      }
    })
  }
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    submitLogin()
  }

  const submitLogin = async () => {
    if (state.contexts && Object.keys(state.contexts).length === 0) {
      setTimeout(() => {
        fetchData()
      }, 1)
      return
    }

    if (!checkValidity()) {
      return reportClientError()
    }
    LoadingOverlay.message('Logging In...')

    // DEV-3252 reset left navigation to collapsed
    delete window.localStorage.toggles

    let contextObj = null
    if (state.contexts && state.context in state.contexts) {
      contextObj = state.contexts[state.context]
    } else {
      contextObj = {
        shortcode: null,
        collectUsername: false,
        collectPassword: false
      }
    }
    const payload = { context: state.context }
    if (contextObj.collectUsername) {
      payload.username = state.username
      if (contextObj.collectPassword) {
        payload.password = state.password
      }
    } else {
      payload.redirectUrl = window.location.href
    }

    try {
      const loginResponse = await Api.callRegisteredMethod('authenticate', payload)
      if (loginResponse.status === 'error') {
        LoadingOverlay.message('Error logging in.', 2)
        setState((prevState) => ({ ...prevState, errors: loginResponse.error }))
      } else {
        // Handle successful login
        LoadingOverlay.hide()
        if (topic) {
          Topics.publish(topic, loginResponse)
        }
      }
    } catch (err) {
      // Handle error
      // console.log(err);
    }
  }

  const checkValidity = () => {
    let contextObj = null
    if (state.contexts && state.context in state.contexts) {
      contextObj = state.contexts[state.context]
    } else {
      contextObj = {
        shortcode: null,
        collectUsername: false,
        collectPassword: false
      }
    }

    let valid = false
    if (
      state.context === contextObj.shortcode &&
      (!contextObj.collectUsername || state.username !== '') &&
      (!contextObj.collectPassword || state.password !== '')
    ) {
      valid = true
    }

    return valid
  }

  const reportClientError = () => {
    const errors = []
    if (state.username === '') {
      errors.push('Username/Email is required')
    }
    if (state.password === '') {
      errors.push('Password is required')
    }
    if (state.context === '') {
      errors.push('Authentication context is required')
    }

    if (errors.length) {
      setState((prevState) => ({ ...prevState, errors }))
      return true
    }
    return false
  }

  if (isLoading) {
    return (
      <Grid container sx={{ pt: 3 }}>
        <Grid item xs={4}>
          &nbsp;
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <LogoMark />
            <Box sx={{ pt: 2 }}>
              <LoadingPleaseWait />
            </Box>
          </Box>
        </Grid>
      </Grid>
    )
  }

  let contextObj = null
  if (state.contexts && state.context in state.contexts) {
    contextObj = state.contexts[state.context]
  } else {
    contextObj = {
      shortcode: null,
      collectUsername: false,
      collectPassword: false
    }
  }

  return (
    <Grid container sx={{ pt: 3 }}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <LogoMark />
          <Typography variant='h6' textAlign='center' sx={{ mt: 2 }}>
            Administration Login
          </Typography>
        </Box>
      </Grid>

      <Grid item sm={4}>
        &nbsp;
      </Grid>

      <Grid item xs={12} sm={4}>
        <Box sx={{ pt: 2, pl: 2, pr: 2 }}>
          {state.errors && state.errors.length
            ? (
              <Box fullWidth sx={{ pb: 2 }}>
                <Alert severity='error'>
                  <AlertTitle>Please correct the following errors:</AlertTitle>
                  {state.errors.map((err, index) => (
                    <Typography key={index}>&#8226; {err}</Typography>
                  ))}
                </Alert>
              </Box>
              )
            : null}
          <form onSubmit={handleSubmit} method='post'>
            <TextField
              type='text'
              placeholder='Workspace'
              label='Workspace'
              id='login-context'
              name='context'
              value={state.context}
              onInput={(e) => {
                e.target.value = e.target.value.toLowerCase().replace(/ /g, '')
                handleChange(e)
              }}
              onChange={handleChange}
              fullWidth
              variant='outlined'
              sx={{ pb: 1.5 }}
              data-lpignore='true'
            />

            {contextObj.collectUsername && (
              <TextField
                type='text'
                placeholder='Username / Email'
                label='Username / Email'
                id='login-username'
                name='username'
                value={state.username}
                onChange={handleChange}
                fullWidth
                variant='outlined'
                sx={{ pb: 1.5 }}
              />
            )}

            {contextObj.collectPassword && (
              <TextField
                type='password'
                placeholder='Password'
                id='login-password'
                name='password'
                label='Password'
                value={state.password}
                onChange={handleChange}
                fullWidth
                variant='outlined'
                sx={{ pb: 2 }}
              />
            )}

            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <SubmitIfValid value={contextObj.loginLabel ? contextObj.loginLabel : 'Login'} validityChecker={checkValidity} />
            </Box>

            {contextObj.passwordResetURL && (
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start' }}>
                <Button size='large' href={contextObj.passwordResetURL} target='_blank' rel='noreferrer' color='primary' fullWidth>
                  Forgot password?
                </Button>
              </Box>
            )}
          </form>
        </Box>
      </Grid>

      <Grid item sm={4}>
        &nbsp;
      </Grid>
    </Grid>
  )
}

const SubmitIfValid = ({ validityChecker, classNameInvalid, classNameValid, value }) => {
  if (validityChecker && !validityChecker()) {
    return (
      <Button variant='contained' className={classNameInvalid} type='submit' size='large' fullWidth>
        {value}
      </Button>
    )
  } else {
    return (
      <Button variant='contained' style={{ whiteSpace: 'normal' }} className={classNameValid} type='submit' size='large' fullWidth>
        {value}
      </Button>
    )
  }
}
