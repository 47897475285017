import React, { useEffect, useState } from 'react'
import CompanyConfigEdit from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import {
  Box, Button, FormControl, FormControlLabel,
  Grid, IconButton,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DataGrid from '../../components/Reusable/DataGrid'
import { LoadingPleaseWait } from '@supportworks/react-components'

const config = {
  title: 'Customer Referrals',
  variant: 'referrals',
  tag: 'referrals',
  name: 'Referrals',
  lockTag: true,
  lockName: true
}

const CompanyConfigReferrals = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [customerReferral, setCustomerReferral] = useState({})
  const [available, setAvailable] = useState(true)
  const [sendToCRM, setSendToCRM] = useState(true)
  const [sendToDistributionList, setSendToDistributionList] = useState(true)

  useEffect(() => {
    if (isLoading) {
      if (props.config && props.config.contentJson !== '{}' && props.config.contentJson !== 'null') {
        const cj = JSON.parse(props.config.contentJson)
        setCustomerReferral(cj)
        if ('available' in cj) { setAvailable(cj.available) }
        if ('sendToCRM' in cj) { setSendToCRM(cj.sendToCRM) }
        if ('sendToDistributionList' in cj) { setSendToDistributionList(cj.sendToDistributionList) }
      } else {
        const newCustomerReferral = {
          available: true,
          sendToCRM: true,
          sendToDistributionList: true,
          source: '',
          referralTitle: '',
          referralDescription: '',
          referralButton: '',
          reasons: [
            'Concrete',
            'Foundation',
            'Garage',
            'Gutters',
            'Waterproofing'
          ]
        }
        handleChangeMain(newCustomerReferral)
      }

      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleChange = (e) => {
    const newCustomerReferral = JSON.parse(JSON.stringify(customerReferral))
    const name = e.target.name
    const value = e.target.value

    if (name === 'available') {
      newCustomerReferral.available = !newCustomerReferral.available
      setAvailable(!available)
    } else if (name === 'sendToCRM') {
      newCustomerReferral.sendToCRM = !newCustomerReferral.sendToCRM
      setSendToCRM(!sendToCRM)
    } else if (name === 'sendToDistributionList') {
      newCustomerReferral.sendToDistributionList = !newCustomerReferral.sendToDistributionList
      setSendToDistributionList(!sendToDistributionList)
    } else {
      newCustomerReferral[name] = value
    }
    handleChangeMain(newCustomerReferral)
  }

  const handleChangeMain = (referralConfig) => {
    const c = JSON.parse(JSON.stringify(referralConfig))
    setCustomerReferral(c)
    if (props.onChange) {
      props.onChange(JSON.stringify(c))
    }
  }

  const handleAdd = () => {
    const newCustomerReferral = JSON.parse(JSON.stringify(customerReferral))
    const r = newCustomerReferral.reasons
    r.push('')
    newCustomerReferral.reasons = r
    handleChangeMain(newCustomerReferral)
  }

  const handleMoveUp = (idx) => {
    const newCustomerReferral = JSON.parse(JSON.stringify(customerReferral))
    const r = newCustomerReferral.reasons
    const ele = r.splice(idx, 1)[0]
    r.splice(idx - 1, 0, ele)
    newCustomerReferral.reasons = r
    handleChangeMain(newCustomerReferral)
  }

  const handleMoveDown = (idx) => {
    const newCustomerReferral = JSON.parse(JSON.stringify(customerReferral))
    const r = newCustomerReferral.reasons
    const ele = r.splice(idx, 1)[0]
    r.splice(idx + 1, 0, ele)
    newCustomerReferral.reasons = r
    handleChangeMain(newCustomerReferral)
  }

  const handleDelete = (idx) => {
    const newCustomerReferral = JSON.parse(JSON.stringify(customerReferral))
    const r = newCustomerReferral.reasons
    r.splice(idx, 1)
    newCustomerReferral.reasons = r
    handleChangeMain(newCustomerReferral)
  }

  const handleProcessRowUpdate = (row) => {
    if (row) {
      const newCustomerReferral = JSON.parse(JSON.stringify(customerReferral))
      newCustomerReferral.reasons[row.id] = row.reason
      handleChangeMain(newCustomerReferral)
      return row
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    {
      field: 'reason',
      headerName: 'Reasons for Referral',
      flex: 1,
      editable: true,
      sortable: false,
      renderCell: (params) => {
        return <>{params.row.reason}</>
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id)}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton size='small' disabled={!(params.row.id < customerReferral.reasons.length - 1)} onClick={() => handleMoveDown(params.row.id)}>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  if ('reasons' in customerReferral && customerReferral.reasons.length > 0) {
    for (let i = 0; i < customerReferral.reasons.length; i++) {
      rows.push({
        id: i,
        reason: customerReferral.reasons[i],
        actions: ''
      })
    }
  }

  return (
    <Paper elevation={3} sx={{ mt: 2, mb: 2, p: 3, width: '900px' }}>
      <Box sx={{ mb: 1 }}>
        <Stack>
          <Typography variant='h6'>Customer Referral Setup</Typography>
        </Stack>
      </Box>

      <Grid container spacing={5}>
        <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
          <Typography style={{ fontSize: '17px', fontWeight: '500' }}>App Settings</Typography>

          <FormControl sx={{ mt: 2, mb: 2 }}>
            <FormControlLabel
              sx={{ m: 0, width: '100%' }}
              control={
                <Switch
                  name='available'
                  sx={{ display: 'flex' }}
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  onChange={handleChange}
                  checked={available}
                />
              }
              label={
                <Typography sx={{ flex: 1 }}>
                  Enable Feature
                </Typography>
              }
              labelPlacement='start'
            />
          </FormControl>

          <TextField
            fullWidth
            id='referralTitle'
            name='referralTitle'
            variant='outlined'
            label='Title'
            size='small'
            value={customerReferral.referralTitle || ''}
            onChange={handleChange}
            sx={{ mb: 3 }}
          />

          <TextField
            fullWidth
            id='referralDescription'
            name='referralDescription'
            variant='outlined'
            label='Description'
            value={customerReferral.referralDescription || ''}
            onChange={handleChange}
            rows={4}
            multiline
            sx={{ mb: 3 }}
          />

          <TextField
            fullWidth
            id='referralButton'
            name='referralButton'
            variant='outlined'
            label='Button Title'
            size='small'
            value={customerReferral.referralButton || ''}
            onChange={handleChange}
            sx={{ mb: 3 }}
          />

          <DataGrid
            autoHeight
            columns={columns}
            rows={rows}
            showToolbar={false}
            defaultPageSize={10}
            onProcessRowUpdate={(row) => handleProcessRowUpdate(row)}
          />

          <Button
            sx={{ mt: 2, mb: 2 }}
            id='add-action'
            color='primary'
            variant='contained'
            onClick={handleAdd}
          >
            Add Reason
          </Button>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
          <Typography style={{ fontSize: '17px', fontWeight: '500' }}>Delivery Settings</Typography>

          <FormControl sx={{ mt: 2, mb: 2 }}>
            <FormControlLabel
              sx={{ m: 0 }}
              control={
                <Switch
                  name='sendToDistributionList'
                  sx={{ display: 'flex' }}
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  onChange={handleChange}
                  checked={sendToDistributionList}
                />
              }
              label={
                <Typography sx={{ flex: 1 }}>
                  Send To Distribution List
                </Typography>
              }
              labelPlacement='start'
            />
          </FormControl>

          <Typography style={{ fontSize: '17px', fontWeight: '500' }}>CRM Integration</Typography>

          <FormControl sx={{ mt: 2, mb: 2 }}>
            <FormControlLabel
              sx={{ m: 0, width: '100%' }}
              control={
                <Switch
                  name='sendToCRM'
                  sx={{ display: 'flex' }}
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  onChange={handleChange}
                  checked={sendToCRM}
                />
              }
              label={
                <Typography sx={{ flex: 1 }}>
                  Send To CRM
                </Typography>
              }
              labelPlacement='start'
            />
          </FormControl>

          <TextField
            fullWidth
            id='source'
            name='source'
            variant='outlined'
            label='CRM Source Description'
            size='small'
            value={customerReferral.source || ''}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

// eslint-disable-next-line
export default {
  Edit: (props) => <CompanyConfigEdit {...config} {...props} editorComponent={CompanyConfigReferrals} />,
  Index: (props) => <CompanyConfigIndex {...config} {...props} />
}
