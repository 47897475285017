import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Grid, IconButton, Link, Tooltip, Typography } from '@mui/material'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import SignIcon from '@mui/icons-material/BorderColor'
import Api, { ApiBase } from 'library/Api'
import Company from 'screens/Company'
import DataGrid from 'components/Reusable/DataGrid'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import Helper from '@supportworks/helper'
import { LoadingPleaseWait } from '@supportworks/react-components'
import Permissions from 'library/Permissions'
import withScreenWrapper from 'screens/withScreenWrapper'

const ProposalsSigned = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [errorSign, setErrorSign] = useState(null)
  const [proposals, setProposals] = useState({})
  const [selectedProposalId, setSelectedProposalId] = useState(-1)
  const [canSign, setCanSign] = useState(false)
  const [signature, setSignature] = useState(null)
  const [initial, setInitial] = useState(null)
  const [showDialogAttachments, setShowDialogAttachments] = useState(false)
  const [pending, setPending] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      if (isLoading) {
        try {
          const results = await Api.callRegisteredMethod('getProposalsSigned', {})
          if (results.length > 0) {
            setProposals(results)
          }
          const sessionUser = await Api.callRegisteredMethod('getSessionUser')
          if (sessionUser.id) {
            const user = await Api.callRegisteredMethod('getUser', { person: sessionUser.id })
            // setUser(user)
            if (Permissions.hasPermission('sign_proposal')) {
              // Even if we have the sign_proposal permission, we need to have a signature on our user
              if (user.details?.signatures?.length > 0 && user.details?.initials?.length > 0) {
                // Signatures is an array with "variant"'s of signature or initial.  Find my signature variant.
                const signature = user.details.signatures[0]
                const initial = user.details.initials[0]
                if (signature && initial) {
                  setSignature(signature)
                  setInitial(initial)
                  setCanSign(true)
                } else {
                  setErrorSign('You do not have a signature or initial on file.  Please add them under your user settings.')
                  setCanSign(false)
                }
              }
            } else {
              setErrorSign('You do not have authority to sign proposals.')
            }
          }
        } catch (error) {
          console.error('Failed to fetch data:', error)
        } finally {
          setIsLoading(false)
        }
      }
    }
    fetchData()
  }, []) // eslint-disable-line

  const handleSelectProposal = idx => {
    if (idx === -1) {
      setSelectedProposalId(-1)
    } else {
      const id = proposals[idx].id
      setSelectedProposalId(id)
    }
  }

  const handleResetSignature = async (proposalId) => {
    if (window.confirm('This will reset all signed documents for this proposal.  The customer will need to redo all signing actions.  Are you sure?')) {
      const result = await Api.callRegisteredMethod('resetSignatures', {
        proposalId
      })
      setIsLoading(true)
      if (result === 1) {
        const results = await Api.callRegisteredMethod('getProposalsSigned', {})
        if (results && results.length > 0) {
          setProposals(results)
        } else {
          setProposals([])
        }
      }
      setIsLoading(false)
      handleShowDialogAttachments()
    }
  }

  const handleShowDialogAttachments = () => {
    setShowDialogAttachments(!showDialogAttachments)
  }

  if (isLoading) return <LoadingPleaseWait />

  const columns = [
    {
      field: 'customer',
      headerName: 'Customer',
      flex: 2,
      editable: false,
      sortable: true,
      renderCell: params => {
        return (
          <Tooltip title={params.row[params.field]} placement='top-start'>
            <Box style={styles.nowrap}>{params.row[params.field]}</Box>
          </Tooltip>
        )
      }
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: params => {
        return (
          <Tooltip title={params.row[params.field]} placement='top-start'>
            <Box style={styles.nowrap}>{params.row[params.field]}</Box>
          </Tooltip>
        )
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: params => {
        return (
          <Tooltip title={params.row[params.field]} placement='top-start'>
            <Box style={styles.nowrap}>{params.row[params.field]}</Box>
          </Tooltip>
        )
      }
    },
    {
      field: 'customerSigned',
      headerName: 'Customer Signed',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: params => {
        return <Box style={styles.nowrap}>{params.row[params.field]}</Box>
      }
    },
    {
      field: 'customerSignedDate',
      headerName: 'Date',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: params => {
        let d = ''
        if (params.row.customerSignedDate) {
          d = Helper.formatDate(params.row.customerSignedDate, 'yyyy-mm-dd HH:MM:ss')
        }
        return (
          <Tooltip title={d} placement='top-start'>
            <Box style={styles.nowrap}>{d}</Box>
          </Tooltip>
        )
      }
    },
    {
      field: 'representativeSigned',
      headerName: 'Rep Signed',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: params => {
        return <Box style={styles.nowrap}>{params.row[params.field]}</Box>
      }
    },
    {
      field: 'representativeSignedDate',
      headerName: 'Date',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: params => {
        let d = ''
        if (params.row.representativeSignedDate) {
          d = Helper.formatDate(params.row.representativeSignedDate, 'yyyy-mm-dd HH:MM:ss')
        }
        return (
          <Tooltip title={d} placement='top-start'>
            <Box style={styles.nowrap}>{d}</Box>
          </Tooltip>
        )
      }
    },
    {
      flex: 1,
      field: 'action',
      headerName: 'Actions',
      headerAlign: 'right',
      align: 'right',
      editable: false,
      sortable: false,
      disableExport: true,
      renderCell: params => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton
                  onClick={() => {
                    handleSelectProposal(params.row.id)
                    handleShowDialogAttachments()
                  }}
                >
                  <FindInPageIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < proposals.length; x++) {
    const p = proposals[x]
    const customerInfo = p.customer_info
    let strRepSigned = 'No'
    // Check the pending array for proposal.id.  If it has a match, set tmpRepSigned to 'Pending'
    if (p.representativeSigned) {
      strRepSigned = 'Yes'
    } else if (pending.includes(p.id)) {
      strRepSigned = 'Pending'
    }
    rows.push({
      id: x,
      customer: customerInfo ? [customerInfo.lastName, customerInfo.firstName].filter(Boolean).join(', ') : '',
      address: customerInfo ? [customerInfo.address1, customerInfo.address2, customerInfo.address3].filter(Boolean).join('\r\n') : '',
      crmId: customerInfo?.crmIdText ? customerInfo.crmIdText : '',
      status: p.disposition,
      customerSigned: p.customerSigned === true ? 'Yes' : 'No',
      customerSignedDate: p.customerSignedDate ? p.customerSignedDate : '',
      representativeSigned: strRepSigned,
      representativeSignedDate: p.representativeSignedDate ? p.representativeSignedDate : ''
    })
  }

  return (
    <Box>
      <Company.Header title='Proposals Pending Signatures' />
      <Box sx={{ pt: 2, pb: 2 }}>
        <Typography variant='body2'>Please review the proposals below. If any attachments on the proposals require signing, press the View Action button to get the attachment list.</Typography>
      </Box>
      <Box sx={{ pb: 2 }}>
        <DataGrid rows={rows} columns={columns} />
      </Box>
      {showDialogAttachments
        ? (
          <DialogAttachments
            proposalId={selectedProposalId}
            errorSign={errorSign}
            canSign={canSign}
            setCanSign={setCanSign}
            signature={signature}
            initial={initial}
            onPending={(p) => setPending(p)}
            onClose={() => {
              handleSelectProposal(-1)
              handleShowDialogAttachments()
            }}
            onResetSignature={handleResetSignature}
          />
          )
        : null}
    </Box>
  )
}
export default withScreenWrapper(ProposalsSigned)

const DialogAttachments = ({ proposalId, canSign, signature, initial, errorSign, setCanSign, onPending, onClose, onResetSignature }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [proposal, setProposal] = useState({}) // eslint-disable-line
  const [attachments, setAttachments] = useState([])
  const [pending, setPending] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      if (isLoading) {
        try {
          const results = await Api.callRegisteredMethod('getProposalFull', { proposalId })
          if (results) {
            setProposal(results)
            setAttachments(results.attachments)
            if (results && results.representativeSigned) {
              setCanSign(false)
            }
          }
        } catch (error) {
          console.error('Failed to fetch data:', error)
          setError(error.message)
        } finally {
          setIsLoading(false)
        }
      }
    }
    fetchData()
  }, []) // eslint-disable-line

  const handleSignProposal = async idx => {
    const attachment = attachments[idx]

    if (window.confirm('A process will be initiated that will use your signature to sign this proposal.  Are you sure you want to sign as the representative?')) {
      const pdfTags = [
        {
          type: 'signature',
          width: 120,
          height: 36,
          base64Image: signature.base64Encoded
        },
        {
          type: 'intitial',
          width: 120,
          height: 36,
          base64Image: initial.base64Encoded
        }
      ]
      // Remember my proposal id so I can show "Pending"
      const p = JSON.parse(JSON.stringify(pending))
      p.push(proposal.id)
      setPending(p)
      setCanSign(false)
      const result = await ApiBase.callRegisteredMethod('postSignPDFRepresentative', {
        guid: attachment.guid,
        proposalId: proposal.id,
        pdfTags
      })
      onPending(p)
      if (result) {
        console.log(`result status: ${result.status}`)
      }
    } else {
      console.log('CANCEL SIGNING')
    }
  }

  if (isLoading) return <LoadingPleaseWait />
  if (error) return <Alert severity='error'>{error}</Alert>

  const columns = [
    {
      field: 'name',
      headerName: 'Document',
      flex: 2,
      editable: false,
      sortable: true,
      renderCell: params => {
        return (
          <Tooltip title={params.row[params.field]} placement='top-start'>
            <Box style={styles.nowrap}>{params.row[params.field]}</Box>
          </Tooltip>
        )
      }
    },
    {
      field: 'customerSigned',
      headerName: 'Customer Signed',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: params => {
        return (
          <Box style={styles.nowrap}>
            {params.row[params.field]}
            {params.row.customerSigned === 'Yes' && (
              <Link href={params.row.customerUri} target='_blank' rel='noopener noreferrer'>
                <IconButton>
                  <CloudDownloadIcon fontSize='small' />
                </IconButton>
              </Link>
            )}
          </Box>
        )
      }
    },
    {
      field: 'customerSignedDate',
      headerName: 'Date',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: params => {
        let d = ''
        if (params.row.customerSignedDate) {
          d = Helper.formatDate(params.row.customerSignedDate, 'yyyy-mm-dd HH:MM:ss')
        }
        return (
          <Tooltip title={d} placement='top-start'>
            <Box style={styles.nowrap}>{d}</Box>
          </Tooltip>
        )
      }
    },
    {
      field: 'representativeSigned',
      headerName: 'Rep Signed',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: params => {
        return (
          <Box style={styles.nowrap}>
            {params.row[params.field]}
            {params.row.representativeSigned === 'Yes'
              ? (
                <Link href={params.row.representativeUri} target='_blank' rel='noopener noreferrer'>
                  <IconButton>
                    <CloudDownloadIcon fontSize='small' />
                  </IconButton>
                </Link>
                )
              : null}
          </Box>
        )
      }
    },
    {
      field: 'representativeSignedDate',
      headerName: 'Date',
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: params => {
        let d = ''
        if (params.row.representativeSignedDate) {
          d = Helper.formatDate(params.row.representativeSignedDate, 'yyyy-mm-dd HH:MM:ss')
        }
        return (
          <Tooltip title={d} placement='top-start'>
            <Box style={styles.nowrap}>{d}</Box>
          </Tooltip>
        )
      }
    },
    {
      flex: 1,
      field: 'action',
      headerName: 'Actions',
      headerAlign: 'right',
      align: 'right',
      editable: false,
      sortable: false,
      disableExport: true,
      renderCell: params => {
        if (params.row) {
          const disabled = !canSign
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <Button variant='contained' size='small' onClick={() => onResetSignature(proposalId)}>Reject</Button>
              </Grid>
              <Grid item>
                <IconButton
                  disabled={disabled}
                  onClick={() => {
                    handleSignProposal(params.row.id)
                  }}
                >
                  <SignIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rows = []
  for (let x = 0; x < attachments.length; x++) {
    const attachment = attachments[x]
    // If the attachment has a childrenUri that has a type customerSigned, insert it as a row
    if (attachment.childrenUris) {
      const customerSigned = attachment.childrenUris.find(child => child.type === 'customerSigned')
      const representativeSigned = attachment.childrenUris.find(child => child.type === 'representativeSigned')
      if (customerSigned) {
        let strRepSigned = 'No'
        // Check the pending array for proposal.id.  If it has a match, set tmpRepSigned to 'Pending'
        if (representativeSigned) {
          strRepSigned = 'Yes'
        } else if (pending.includes(proposal.id)) {
          strRepSigned = 'Pending'
        }
        rows.push({
          id: x,
          name: attachment.name,
          customerSigned: 'Yes',
          customerUri: customerSigned.uri,
          customerSignedDate: customerSigned.created,
          representativeSigned: strRepSigned,
          representativeSignedDate: representativeSigned ? representativeSigned.created : '',
          representativeUri: representativeSigned ? representativeSigned.uri : ''
        })
      }
    }
  }

  const jsx = (
    <Box>
      <Box sx={{ pb: 2 }}>
        <Typography variant='body2'>Please review the attachments below and click the pencil icon to sign.</Typography>
      </Box>
      <DataGrid rows={rows} columns={columns} />

      {errorSign
        ? (
          <Box sx={{ pt: 1 }}>
            <Alert severity='warning'>{errorSign}</Alert>
          </Box>
          )
        : null}
    </Box>
  )

  return <DialogGeneric title='Attachments for Signing' content={jsx} fullWidth onClose={onClose} />
}

const styles = {
  nowrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%'
  }
}
