import React, { useEffect, useState } from 'react'
import { Dialog, Link, Menu, MenuItem, TextField } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Button from '@mui/material/Button'
import Company from 'screens/Company'
import Divider from '@mui/material/Divider'
import H from 'library/helper'

function UnitFuncDialog (props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [unitMenu, setUnitMenu] = useState([])
  // const [unitMenu, setUnitMenu] = useState(props.numericUnitsConfig !== 'undefined' ? props.numericUnitsConfig : [])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const changeHandler = (value) => {
    props.onChange(value)
    setAnchorEl(null)
  }

  const getUnitName = (val) => {
    if (val) {
      const elem = unitMenu.filter((el) => {
        return el.id === val
      })
      if (elem.length === 0) {
        return 'n/a'
      } else {
        return elem[0].title
      }
    } else {
      return 'n/a'
    }
  }

  const saveHandlerUnit = (unit) => {
    const newUnitMenu = [...unitMenu]
    newUnitMenu.push({ id: props.guid(), title: unit })
    setUnitMenu(newUnitMenu)

    const config = {
      title: 'Numeric Units',
      variant: 'numericUnits',
      tag: 'numericUnits',
      name: 'Numeric Units',
      lockTag: true,
      lockName: true
    }
    H.saveConfig(Company.getCurrentOrgId(), config, newUnitMenu)
    // console.log(Company.getCurrentOrgId(), config, newUnitMenu);
    props.onCreateUnit(newUnitMenu)
  }

  useEffect(() => {
    // console.log(props);
    if (props.numericUnitsConfig !== null) {
      if (props.unitArr.length > props.numericUnitsConfig.length) {
        setUnitMenu(props.unitArr)
      } else {
        setUnitMenu(props.numericUnitsConfig)
      }
    }
  }, [])

  useEffect(() => {
    if (props.numericUnitsConfig !== null) {
      if (props.unitArr.length > props.numericUnitsConfig.length) {
        setUnitMenu(props.unitArr)
      }
    }
  }, [props.unitArr])

  return (
    <>
      <Button onClick={handleClick} variant='text' endIcon={<ArrowDropDownIcon />}>
        {getUnitName(props.unit)}
      </Button>
      <div>
        <Menu
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}
          PaperProps={{
            sx: {
              width: '220px',
              // height: "190px",
              paddingTop: '10px',
              paddingBottom: '10px'
            }
          }}
        >
          <MenuItem value='n/a' onClick={() => changeHandler('n/a')} selected={props.unit === 'n/a' || props.unit === ''}>
            Not Applicable (n/a)
          </MenuItem>
          <Divider />
          {unitMenu !== null &&
                        unitMenu.map((u, idx) => {
                          return (
                            <div key={`unit-${idx}`}>
                              <MenuItem value={u.id} onClick={() => changeHandler(u.id)} selected={props.unit === u.id}>
                                {u.title}
                                {/* <ListItemSecondaryAction variant="outlined">
                                            <IconButton edge="end" aria-label="delete" onClick={(e) => deleteUnit(idx, e)}>
                                                <HighlightOffIcon/>
                                            </IconButton>
                                        </ListItemSecondaryAction> */}
                              </MenuItem>
                            </div>
                          )
                        })}
          <AddUnitDialog onSave={(unit) => saveHandlerUnit(unit)} />
        </Menu>
      </div>
    </>
  )
}

const styles = {
  headerDiv: {
    marginBottom: '10px',
    fontWeight: '600',
    fontSize: '15px'
  },
  header: {
    fontSize: '15px',
    fontWeight: '800',
    paddingBottom: '10px'
    // color: "#000000a3"
  },
  subHeader: {
    fontSize: '13px',
    color: '#000000a3',
    display: 'block',
    marginBottom: '6px'
  },
  textInput: {
    marginTop: '10px'
  },
  buttons: {
    marginTop: '15px'
  },
  cancelBtn: {
    float: 'right'
  },
  saveBtn: {
    float: 'right'
  },
  menuUnit: {
    display: 'inlineBlock'
  }
}

export default UnitFuncDialog

function AddUnitDialog (props) {
  const [open, setOpen] = useState(false)
  const [unit, setUnit] = useState('')

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const changeHandler = (event) => {
    setUnit(event.target.value)
  }

  const cancelHandler = () => {
    setOpen(false)
  }

  const createHandler = () => {
    setOpen(false)
    setUnit('')
    props.onSave(unit)
  }

  return (
    <div>
      <MenuItem>
        <Link onClick={handleClick} underline='hover'>
          + Create Custom Unit
        </Link>
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: '350px',
            height: '175px',
            padding: '20px'
          }
        }}
      >
        <span style={styles.header}>Create Custom Unit</span>
        <TextField key='create-unit-input' style={styles.textInput} id='filled-basic' size='small' variant='filled' onChange={changeHandler} value={unit} fullWidth />
        <div style={styles.buttons}>
          <Button style={styles.saveBtn} size='small' onClick={createHandler}>
            Create
          </Button>
          <Button style={styles.cancelBtn} size='small' onClick={cancelHandler}>
            Cancel
          </Button>
        </div>
      </Dialog>
    </div>
  )
}
