/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { Box } from '@mui/material'
import CompanyConfigEdit, { CompanyConfigBaseEditor } from './CompanyConfigEdit'
import CompanyConfigIndex from './CompanyConfigIndex'
import Api from 'library/Api'
import { LoadingPleaseWait } from '@supportworks/react-components'
import DataGrid from 'components/Reusable/DataGrid'

const crmFieldsConfig = {
  title: 'CRM Fields',
  variant: 'crmFields',
  tag: 'crmFields',
  name: 'CRM Fields',
  lockTag: true,
  lockName: true
}

class CRMFieldsEditor extends CompanyConfigBaseEditor {
  constructor (props) {
    super(props)
    this.state = {
      fieldList: [],
      isLoading: true,
      filter: ''
    }
  }

  componentDidMount () {
    const cRef = this
    super.componentDidMount()

    Api.callRegisteredMethod('getCRMFields').then((fieldList) => {
      console.log(fieldList)
      if (!fieldList) {
        this.setState({ isLoading: false })
      } else {
        if (fieldList.length === 0 || (fieldList.length === 1 && fieldList[0] == null)) {
          cRef.setState({ fieldList: [], isLoading: false })
        } else {
          cRef.setState({ fieldList: fieldList.sort((a, b) => (a.fieldName > b.fieldName ? 1 : -1)), isLoading: false })
        }
      }
    })
  }

  hasFinalize () {
    return true
  }

  finalize () {
    return JSON.stringify(this.state.fieldList)
  }

  render () {
    if (this.state.isLoading) {
      return <LoadingPleaseWait />
    }

    const columns = [
      {
        flex: 1,
        type: 'string',
        field: 'type',
        headerName: 'Type',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'id',
        headerName: 'Id',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'title',
        headerName: 'Title',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'field_type',
        headerName: 'Field Type',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'object',
        headerName: 'Object',
        editable: false,
        sortable: true
      },
      {
        flex: 1,
        type: 'string',
        field: 'object_type',
        headerName: 'Object Type',
        editable: false,
        sortable: true
      }
    ]

    const rows = []
    this.state.fieldList.forEach((f) => {
      rows.push({
        id: f.id,
        type: f.$type,
        title: f.fieldName,
        field_type: f.fieldType,
        object: f.object,
        object_type: f.objectType
      })
    })

    return (
      <Box>
        <DataGrid rows={rows} columns={columns} />
      </Box>
    )
  }
}

export default {
  Edit: (props) => <CompanyConfigEdit {...crmFieldsConfig} {...props} editorComponent={CRMFieldsEditor} />,
  Index: (props) => <CompanyConfigIndex {...crmFieldsConfig} {...props} />
}
