import React, { useState } from 'react'
import { Alert, Box } from '@mui/material'
import Company from 'screens/Company'
import Api from 'library/Api'
import withScreenWrapper from '../withScreenWrapper'
import { LoadingPleaseWait } from '@supportworks/react-components'
import UserEdit from './UserEdit'

const SelfUserEdit = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState(null)
  const [persons, setPersons] = useState(null)
  const [authManifest, setAuthManifest] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [permissionDefaults, setPermissionDefaults] = useState()

  const reset = () => {
    Api.callRegisteredMethod('getAuthorizationManifest').then((au) => {
      Api.callRegisteredMethod('getUsers').then((users) => {
        const permManifest = au.permissionManifest
        au.permissionManifest = permManifest
        setAuthManifest(au)

        setPersons(users && users.persons ? users.persons : [])
        if (users && users.default) {
          setPermissionDefaults(users.default)
        }
        /*
        if (users && users.crm === undefined) {
          setCrm(null) // crm is NOT in the response
        } else if (users && users.crm !== undefined) {
          setCrm(users.crm)
        }
        */
        Api.callRegisteredMethod('getSessionUser').then((sessionUser) => {
          if (sessionUser.id) {
            for (let x = 0; x < users.persons.length; x++) {
              const p = users.persons[x].person
              if (p && String(p.id) === String(sessionUser.id)) {
                setSelectedIndex(x)
                break
              }
            }
          } else {
            setIsError(true)
            setError('No user found in session.  Please log out and log back in.')
          }
          setIsLoading(false)
        })
      })
    })
  }

  useState(() => {
    if (isLoading) {
      reset()
    }
  }, []) // eslint-disable-line

  const handleCancel = () => {
    setIsLoading(true)
    reset()
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <Box>
      <Company.Header title='User Settings' />
      {isError && <Alert severity='error'>{error}</Alert>}
      {!isError &&
        <UserEdit
          {...props}
          variant='self'
          authManifest={authManifest}
          users={persons}
          selectedIndex={selectedIndex}
          permissionDefaults={permissionDefaults}
          onCancel={() => {
            handleCancel()
          }}
        />}
    </Box>
  )
}
export default withScreenWrapper(SelfUserEdit)
