import React, { useState, useEffect, useRef } from 'react'
import FieldTypeExpandMarker from '../Reusables/FieldTypeExpandMarker'
import { Button, Grid, IconButton, Paper } from '@mui/material'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import TextInputBasic from '../Reusables/TextInputBasic'
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { DataGrid } from '@mui/x-data-grid'
import { DialogFormSelector } from '../../../../components/Reusable/DialogFormSelector'

function AreaListAdvanced (props) {
  const [options, setOptions] = useState(props.list)
  const inputRefs = useRef([])
  const [field, setField] = useState(props.field)
  const [optIndex, setOptIndex] = useState(null)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [formsConfig, setFormsConfig] = useState([])
  const [formsConfigOverview, setFormsConfigOverview] = useState([])
  const [formsConfigInspect, setFormsConfigInspect] = useState([])
  const [listOverview, setListOverview] = useState([])
  const [listInspect, setListInspect] = useState([])
  const [formType, setFormType] = useState('')
  const [expanded, setExpanded] = useState([])

  useEffect(() => {
    if (!('list' in props.field)) {
      const newField = JSON.parse(JSON.stringify(field))
      newField.list = []
      setField(newField)
    }

    if (props.formsConfig) {
      setFormsConfig(props.formsConfig)

      const overview = props.formsConfig.filter((f) => f.formType.match(/overview/i))
      setFormsConfigOverview(overview)

      const inspect = props.formsConfig.filter((f) => f.formType.match(/inspect/i))
      setFormsConfigInspect(inspect)
    }

    if ('list' in props.field && props.field.list.length > 0) {
      const newExpanded = JSON.parse(JSON.stringify(expanded))
      for (let i = 0; i < props.field.list.length; i++) {
        newExpanded[i] = false
      }
      setExpanded(newExpanded)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const newListInspect = []
    const newListOverview = []

    for (let x = 0; x < options.length; x++) {
      const newOverview = []
      if (field.list[x].overviewForms.length > 0) {
        for (let i = 0; i < field.list[x].overviewForms.length; i++) {
          const f = field.list[x].overviewForms[i]
          const form = formsConfig.filter((fc, i) => {
            if (fc.id === f) {
              return fc
            } else {
              return false
            }
          })
          if (0 in form) {
            newOverview.push({ formId: form[0].id, type: form[0].formType, title: form[0].formTitle })
          }
        }
        newListOverview[x] = newOverview
      } else {
        newListOverview[x] = []
      }

      if (field.list[x].inspectForms.length > 0) {
        const newInspect = []
        // field.list[x].inspectForms.map((f, i) => {
        for (let i = 0; i < field.list[x].inspectForms.length; i++) {
          const f = field.list[x].inspectForms[i]
          const form = formsConfig.filter((fc, i) => {
            if (fc.id === f) {
              return fc
            } else {
              return false
            }
          })
          if (0 in form) {
            newInspect.push({ formId: form[0].id, type: form[0].formType, title: form[0].formTitle })
          }
        }
        // )
        newListInspect[x] = newInspect
      } else {
        newListInspect[x] = []
      }
    }

    setListOverview(newListOverview)
    setListInspect(newListInspect)
    // eslint-disable-next-line
  }, [expanded])

  const addOption = () => {
    const newField = JSON.parse(JSON.stringify(props.field))
    if (!('list' in newField)) {
      newField.list = []
    }

    const newOptions = { title: 'newOption', overviewForms: [], inspectForms: [] }
    setOptions(options => [...options, newOptions])
    newField.list = [...options, newOptions]
    setField(newField)
    props.onChange(newField)
  }

  const changeHandlerTitle = (value, idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    const newOptionsArr = [...options]

    newOptionsArr[idx].title = value
    setOptions(newOptionsArr)

    newField.list = newOptionsArr
    setField(newField)

    props.onChange(newField)
  }

  const keyPressHandler = (event, idx) => {
    if (event.key === 'Enter') {
      if (inputRefs.current[idx + 1]) {
        inputRefs.current[idx + 1].focus()
      } else {
        const newField = JSON.parse(JSON.stringify(props.field))
        if (!('list' in newField)) {
          newField.list = []
        }

        const newOptions = { title: 'newOption', overviewForms: [], inspectForms: [] }
        newField.list.push(newOptions)

        setOptions(options => [...options, newOptions])
        setField(newField)
        props.onChange(newField)
      }
    }
  }

  const deleteHandler = (idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    const newOptionsArr = [...options]

    newOptionsArr.splice(idx, 1)
    newField.list = newOptionsArr

    if (idx in listOverview) {
      const newListOverview = JSON.parse(JSON.stringify(listOverview))
      newListOverview.splice(idx, 1)
      setListOverview(newListOverview)
    }
    if (idx in listInspect) {
      const newListInspect = JSON.parse(JSON.stringify(listInspect))
      newListInspect.splice(idx, 1)
      setListInspect(newListInspect)
    }
    setOptions(newOptionsArr)
    setOptIndex(idx)
    setField(newField)
    props.onChange(newField)
  }

  const handleMoveUp = (idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    const noptions = JSON.parse(JSON.stringify(options))
    if (idx > 0) {
      [noptions[idx], noptions[idx - 1]] = [noptions[idx - 1], noptions[idx]]
    }
    setOptions(noptions)
    newField.list = noptions
    setField(newField)

    props.onChange(newField)
  }

  const handleMoveDown = (idx) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    const noptions = JSON.parse(JSON.stringify(options))
    if (idx < options.length) {
      [noptions[idx], noptions[idx + 1]] = [noptions[idx + 1], noptions[idx]]
    }
    setOptions(noptions)
    newField.list = noptions
    setField(newField)

    props.onChange(newField)
  }

  const setShowArrow = (val, idx) => {
    const newExpanded = JSON.parse(JSON.stringify(expanded))
    newExpanded[idx] = !newExpanded[idx]
    setExpanded(newExpanded)

    setOptIndex(idx)
  }

  const getFormTitle = (fid) => {
    const form = formsConfig.filter((f, idx) => {
      if (f.id === fid) {
        return f
      } else {
        return false
      }
    })
    return form[0].formTitle
  }

  const columnsOverview = [
    { field: 'id', headerName: 'Id', hide: true },
    {
      field: 'title',
      headerName: 'Overview Forms',
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return <>{params.row.title}</>
      }
    },
    {
      field: 'actions',
      headerName: '',
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton
                  size='small'
                  disabled={params.row.id === 0}
                  onClick={() => handleMoveUpForms(params.row.id, params.row.formIndex, 'overviewForms')}
                >
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton
                size='small'
                disabled={!(params.row.id < listOverview[params.row.formIndex].length - 1)}
                onClick={() => handleMoveDownForms(params.row.id, params.row.formIndex, 'overviewForms')}
              >
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton
                  size='small'
                  onClick={() => handleDeleteForms(params.row.id, params.row.formIndex, 'overviewForms')}
                >
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rowsOverview = []
  for (let y = 0; y < options.length; y++) {
    const rows = []
    if (y in listOverview) {
      for (let x = 0; x < listOverview[y].length; x++) {
        const formId = listOverview[y][x].formId
        rows.push({
          id: x,
          title: getFormTitle(formId),
          actions: '',
          formIndex: y
        })
      }
    }
    rowsOverview.push(rows)
  }

  const columnsInspect = [
    { field: 'id', headerName: 'Id', hide: true },
    {
      field: 'title',
      headerName: 'Inspect Forms',
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return <>{params.row.title}</>
      }
    },
    {
      field: 'actions',
      headerName: '',
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton
                  size='small'
                  disabled={params.row.id === 0}
                  onClick={() => handleMoveUpForms(params.row.id, params.row.formIndex, 'inspectForms')}
                >
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton
                size='small'
                disabled={!(params.row.id < listInspect[params.row.formIndex].length - 1)}
                onClick={() => handleMoveDownForms(params.row.id, params.row.formIndex, 'inspectForms')}
              >
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton
                  size='small'
                  onClick={() => handleDeleteForms(params.row.id, params.row.formIndex, 'inspectForms')}
                >
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  const rowsInspect = []
  for (let y = 0; y < options.length; y++) {
    const rows = []
    if (y in listInspect) {
      for (let x = 0; x < listInspect[y].length; x++) {
        const formId = listInspect[y][x].formId
        rows.push({
          id: x,
          title: getFormTitle(formId),
          actions: '',
          formIndex: y
        })
      }
    }
    rowsInspect.push(rows)
  }

  const handleMoveUpForms = (idx, formIndex, type) => {
    const newField = JSON.parse(JSON.stringify(field))

    if (type === 'overviewForms') {
      const l = JSON.parse(JSON.stringify(listOverview))
      const ele = l[formIndex].splice(idx, 1)[0]
      l[formIndex].splice(idx - 1, 0, ele)
      setListOverview(l)

      const ele2 = newField.list[formIndex].overviewForms.splice(idx, 1)[0]
      newField.list[formIndex].overviewForms.splice(idx - 1, 0, ele2)
    }

    if (type === 'inspectForms') {
      const l = JSON.parse(JSON.stringify(listInspect))
      const ele = l[formIndex].splice(idx, 1)[0]
      l[formIndex].splice(idx - 1, 0, ele)
      setListInspect(l)

      const ele2 = newField.list[formIndex].inspectForms.splice(idx, 1)[0]
      newField.list[formIndex].inspectForms.splice(idx - 1, 0, ele2)
    }

    setField(newField)
    props.onChange(newField)
  }

  const handleMoveDownForms = (idx, formIndex, type) => {
    // console.log(optIndex);
    const newField = JSON.parse(JSON.stringify(field))

    if (type === 'overviewForms') {
      const l = JSON.parse(JSON.stringify(listOverview))
      const ele = l[formIndex].splice(idx, 1)[0]
      l[formIndex].splice(idx + 1, 0, ele)
      setListOverview(l)

      const ele2 = newField.list[formIndex].overviewForms.splice(idx, 1)[0]
      newField.list[formIndex].overviewForms.splice(idx + 1, 0, ele2)
    }

    if (type === 'inspectForms') {
      const l = JSON.parse(JSON.stringify(listInspect))
      const ele = l[formIndex].splice(idx, 1)[0]
      l[formIndex].splice(idx + 1, 0, ele)
      setListInspect(l)

      const ele2 = newField.list[formIndex].inspectForms.splice(idx, 1)[0]
      newField.list[formIndex].inspectForms.splice(idx + 1, 0, ele2)
    }

    setField(newField)
    props.onChange(newField)
  }

  const handleDeleteForms = (idx, formIndex, type) => {
    const newField = JSON.parse(JSON.stringify(field))

    if (type === 'overviewForms') {
      const overview = JSON.parse(JSON.stringify(listOverview))
      overview[formIndex].splice(idx, 1)
      setListOverview(overview)
      newField.list[formIndex].overviewForms.splice(idx, 1)
    }
    if (type === 'inspectForms') {
      const inspect = JSON.parse(JSON.stringify(listInspect))
      inspect[formIndex].splice(idx, 1)
      setListInspect(inspect)
      newField.list[formIndex].inspectForms.splice(idx, 1)
    }
    setField(newField)
    props.onChange(newField)
  }

  const saveForms = (l) => {
    const newField = JSON.parse(JSON.stringify(field))
    const newList = []

    if (l.length > 0) {
      for (let i = 0; i < l.length; i++) {
        newList.push(l[i].formId)
      }
    }

    if (formType === 'overviewForms') {
      newField.list[optIndex].overviewForms = newList
      const newListOverview = JSON.parse(JSON.stringify(listOverview))
      newListOverview[optIndex] = l
      setListOverview(newListOverview)
    }
    if (formType === 'inspectForms') {
      newField.list[optIndex].inspectForms = newList
      const newListInspect = JSON.parse(JSON.stringify(listInspect))
      newListInspect[optIndex] = l
      setListInspect(newListInspect)
    }

    setOptions(newField.list)
    setField(newField)
    props.onChange(newField)

    setShowAddDialog(!showAddDialog)
  }

  // console.log(optIndex);

  return (
    <>
      {
                props.expandMarker &&
                  <FieldTypeExpandMarker />
            }

      {
                options.map((item, idx) => {
                  return (
                    <div key={`radio-main-${idx}`}>
                      <div style={{ display: 'flex' }} key={`radio-opt-${idx}`}>
                        <FieldTypeExpandMarker />
                        <Paper elevation={0} square style={styles.singleSelectionPaper}>
                          <div style={styles.optionsMainDivIcon}>
                            {/* <IconButton style={styles.addOptionIconBtn} onClick={()=>toggleFormSection()}>
                                            <KeyboardArrowRightIcon style={styles.addOptionIcon}/>
                                        </IconButton> */}
                            <IconButton
                              className={'field-arrow-icon-' + (!expanded[idx] ? 'show' : 'hidden')}
                              onClick={() => setShowArrow('showArrowRight', idx)}
                            >
                              <KeyboardArrowRightIcon style={styles.addOptionIcon} />
                            </IconButton>
                            <IconButton
                              className={'field-arrow-icon-' + (expanded[idx] ? 'show' : 'hidden')}
                              onClick={() => setShowArrow('showArrowDown', idx)}
                              color='primary'
                            >
                              <ExpandCircleDownIcon style={styles.addOptionIcon} />
                            </IconButton>
                          </div>
                          <div style={styles.optionsMainDiv}>
                            <TextInputBasic
                              className={`single-selection-text-${idx}`}
                              id={`single-selection-text-${idx}`}
                              placeholder='Untitled Option'
                              inputValue={item.title !== 'newOption' ? item.title : ''}
                              onChange={(value) => changeHandlerTitle(value, idx)}
                              onKeyPress={(event) => keyPressHandler(event, idx)}
                              ref={ref => inputRefs.current[idx] = (ref)} // eslint-disable-line
                            />
                          </div>
                          <div style={styles.optionsMainDivIcon}>
                            <IconButton onClick={() => deleteHandler(idx)}>
                              <DeleteIcon />
                            </IconButton>
                            <IconButton
                              disabled={!(idx < options.length - 1)}
                              onClick={() => {
                                handleMoveDown(idx)
                              }}
                            >
                              <ArrowDownwardIcon />
                            </IconButton>
                            <IconButton
                              disabled={!(idx > 0)} onClick={() => {
                                handleMoveUp(idx)
                              }}
                            >
                              <ArrowUpwardIcon />
                            </IconButton>
                          </div>
                        </Paper>
                      </div>
                      {
                                expanded[idx] &&
                                  <div style={styles.mainDiv}>
                                    <div style={styles.fieldTypeDiv}>
                                      <FieldTypeExpandMarker />
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          flexGrow: 1,
                                          height: '100%'
                                        }}
                                      >
                                        <FieldTypeExpandMarker />
                                        <Paper elevation={0} square style={styles.formArea}>
                                          <Grid container>
                                            <Grid item xs={12}>
                                              <DataGrid
                                                autoHeight
                                                rows={idx in rowsOverview ? rowsOverview[idx] : []}
                                                columns={columnsOverview}
                                                pagination
                                                pageSize={5}
                                                sx={{
                                                  '& .MuiDataGrid-columnHeader': {
                                                    backgroundColor: '#89a2c530',
                                                    height: '50px'
                                                  },
                                                  '& .MuiDataGrid-columnHeaderTitle': {
                                                    fontSize: '14px',
                                                    fontWeight: 400,
                                                    color: '#212529'
                                                  }
                                                }}
                                                initialState={{
                                                  columns: {
                                                    columnVisibilityModel: {
                                                      id: false
                                                    }
                                                  }
                                                }}
                                              />
                                            </Grid>
                                            <Grid item xs={12} sx={{ lineHeight: '56px' }}>
                                              <Button
                                                variant='text'
                                                onClick={() => {
                                                  setShowAddDialog(!showAddDialog)
                                                  setOptIndex(idx)
                                                  setFormType('overviewForms')
                                                }}
                                              >
                                                + Add Overview Form
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        </Paper>
                                      </div>
                                    </div>
                                    <div style={styles.fieldTypeDiv}>
                                      <FieldTypeExpandMarker />
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          flexGrow: 1,
                                          height: '100%'
                                        }}
                                      >
                                        <FieldTypeExpandMarker />
                                        <Paper elevation={0} square style={styles.formArea}>
                                          <Grid container>
                                            <Grid item xs={12}>
                                              <DataGrid
                                                autoHeight
                                                rows={idx in rowsInspect ? rowsInspect[idx] : []}
                                                columns={columnsInspect}
                                                pagination
                                                pageSize={5}
                                                sx={{
                                                  '& .MuiDataGrid-columnHeader': {
                                                    backgroundColor: '#89a2c530',
                                                    height: '50px'
                                                  },
                                                  '& .MuiDataGrid-columnHeaderTitle': {
                                                    fontSize: '14px',
                                                    fontWeight: 400,
                                                    color: '#212529'
                                                  }
                                                }}
                                                initialState={{
                                                  columns: {
                                                    columnVisibilityModel: {
                                                      id: false
                                                    }
                                                  }
                                                }}
                                              />
                                            </Grid>
                                            <Grid item xs={12} sx={{ lineHeight: '56px' }}>
                                              <Button
                                                variant='text'
                                                onClick={() => {
                                                  setShowAddDialog(!showAddDialog)
                                                  setOptIndex(idx)
                                                  setFormType('inspectForms')
                                                }}
                                              >
                                                + Add Inspect Form
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        </Paper>
                                      </div>
                                    </div>
                                  </div>
                            }
                    </div>
                  )
                })
            }

      {
                showAddDialog &&
                formType === 'overviewForms' &&
                  <DialogFormSelector
                    title='Select Forms'
                    formsConfig={formsConfigOverview}
                    list={optIndex in listOverview ? listOverview[optIndex] : []}
                    onClose={() => setShowAddDialog(!showAddDialog)}
                    onSave={(l) => saveForms(l)}
                  />
            }

      {
                showAddDialog &&
                formType === 'inspectForms' &&
                  <DialogFormSelector
                    title='Select Forms'
                    formsConfig={formsConfigInspect}
                    list={optIndex in listInspect ? listInspect[optIndex] : []}
                    onClose={() => setShowAddDialog(!showAddDialog)}
                    onSave={(l) => saveForms(l)}
                  />
            }

      <div style={styles.logicPanel}>
        <div style={{ display: 'flex' }}>
          <FieldTypeExpandMarker />
          <Paper elevation={0} square style={styles.addOptionPaper}>
            <Button
              className='add-option-single-selection-btn'
              style={styles.addOptionBtn}
              color='primary'
              variant='text'
              startIcon={<RadioButtonUncheckedIcon />}
              onClick={addOption}
            >
              Add Option
            </Button>
          </Paper>
        </div>
      </div>
    </>
  )
}

const styles = {
  mainDiv: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%'
  },
  fieldTypeDiv: {
    display: 'flex'
  },
  relatedMain: {
    display: 'flex'
  },
  logicPanel: {
    // width: "100%"
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%'
  },
  optionsMainDiv: {
    display: 'flex',
    flexBasis: '100%'
  },
  optionsMainDivIcon: {
    marginLeft: '12px',
    whiteSpace: 'nowrap'
  },
  singleSelectionPaper: {
    borderTop: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    borderLeft: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    // marginRight: "96.19px",
    // width: "100%"
    height: '50px',
    flexBasis: '100%'
  },
  addOptionPaper: {
    borderTop: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    borderLeft: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    // marginRight: "96.19px",
    // width: "100%",
    height: '50px',
    flexBasis: '100%'
  },
  addOptionBtn: {
    textTransform: 'none',
    marginLeft: '16px',
    fontSize: '13px'
  },
  formArea: {
    borderTop: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    borderLeft: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    // marginRight: "96.19px",
    // width: "100%"
    // height: "50px",
    flexBasis: '100%'
  },
  formHeading: {
    paddingTop: '8px',
    paddingLeft: '8px',
    paddingBottom: '8px',
    fontSize: '14px',
    borderBottom: '1px solid #0000003b'
  }
}

export default AreaListAdvanced
