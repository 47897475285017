import React, { useEffect, useState } from 'react'
import FieldTypeAddLogicPanel from 'screens/Config/Forms/FieldTypeAddLogicPanel'
import FieldTypeExpandMarker from 'screens/Config/Forms/Reusables/FieldTypeExpandMarker'
import SelectorWithPreview from '../../../../components/Reusable/SelectorWithPreview'
import { Button, Checkbox, FormControlLabel, IconButton, Paper, Typography, TextField } from '@mui/material'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'
import { AddPhotoAlternate } from '@mui/icons-material'
import CompanyConfigModal from '../../CompanyConfigModal'
import MovieCreationTwoToneIcon from '@mui/icons-material/MovieCreationTwoTone'
import PictureAsPdfTwoToneIcon from '@mui/icons-material/PictureAsPdfTwoTone'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import DeleteIcon from '@mui/icons-material/Delete'

function ContentBlock (props) {
  // const [hasLogic, setHasLogic] = useState(false)
  const [sendToCustomer, setSendToCustomer] = useState(false)
  const [description, setDescription] = useState('')
  const [openAssetModal, setOpenAssetModal] = useState(false)
  const [openThumbnailModal, setOpenThumbnailModal] = useState(false)
  const [selectedAsset, setSelectedAsset] = useState(null)
  const [selectedPoster, setSelectedPoster] = useState(null)
  const [field, setField] = useState(props.field)

  const changeHandlerVisible = (visible) => {
    props.onChangeVisible(visible)
  }

  const changeHandlerCRM = (crm) => {
    props.onChangeCRM(crm)
  }

  const changeHandlerUnits = (units) => {
    props.onChangeUnits(units)
  }

  const changeHandlerSendToCustomer = (event) => {
    setSendToCustomer(event.target.checked)
    props.onChange(event.target.checked, 'sendToCustomer')
  }

  const changeHandlerDescription = (event) => {
    setDescription(event.target.value)
    props.onChange(event.target.value, 'description')
  }

  const videoAddHandler = () => {
    setOpenAssetModal(true)
  }

  const cancelVideo = () => {
    setOpenAssetModal(false)
  }

  const thumbnailAddHandler = () => {
    setOpenThumbnailModal(true)
  }

  const cancelThumbnail = () => {
    setOpenThumbnailModal(false)
  }

  const updateAssetHandler = (asset) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    newField.asset = asset.id
    setSelectedAsset(asset)
    setField(newField)
    props.onChange(newField, 'newField')
  }

  const deleteAssetHandler = () => {
    const newField = JSON.parse(JSON.stringify(props.field))
    if ('asset' in newField) {
      newField.asset = ''
    }
    setOpenAssetModal(false)
    setField(newField)
    props.onChange(newField, 'newField')
  }

  const updatePosterHandler = (poster) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    newField.poster = poster.id
    setSelectedPoster(poster)
    setField(newField)
    props.onChange(newField, 'newField')
  }

  const deletePosterHandler = () => {
    const newField = JSON.parse(JSON.stringify(props.field))
    if ('poster' in newField) {
      newField.poster = ''
    }
    setOpenThumbnailModal(false)
    setField(newField)
    props.onChange(newField, 'newField')
  }

  /* const addLogic = (e) => {
    if (e) {
      setHasLogic(true)
      alert('under construction ..')
    }
  } */

  useEffect(() => {
    const newField = props.field

    if (!('asset' in newField)) {
      newField.asset = ''
    }
    if (!('poster' in newField)) {
      newField.poster = ''
    }
    setField(newField)

    if (newField.asset !== null || newField.asset !== '') {
      const selectedAsset = getAssetInfo(newField.asset)
      setSelectedAsset(selectedAsset)
    }
    if (newField.poster !== null || newField.poster !== '') {
      const selectedPoster = getAssetInfo(newField.poster)
      setSelectedPoster(selectedPoster)
    }
    // eslint-disable-next-line
  }, [])

  const getAssetInfo = (id) => {
    const assetConfig = props.assetsConfig.filter((asset) => {
      return (asset.id === id)
    })
    if (assetConfig.length !== 0) {
      return assetConfig[0]
    } else {
      return id
    }
  }

  // console.log(openThumbnailModal);
  // console.log(field);
  // console.log(selectedPoster);

  return (
    <>
      <FieldTypeAddLogicPanel
        field={props.field}
        onChangeVisible={(visible) => changeHandlerVisible(visible)}
        onChangeCRM={(crm) => changeHandlerCRM(crm)}
        onChangeUnits={(units) => changeHandlerUnits(units)}
        // onAddLogic={(e) => addLogic(e)}
      />

      <div style={styles.mainDiv}>
        <FieldTypeExpandMarker />
        <Paper elevation={0} square className='contentBlock-paper' style={styles.contentBlockPaper}>
          <div style={styles.leftDiv}>
            <div style={styles.addVideo}>
              {
                                field.asset !== '' && selectedAsset !== null &&
                                  <ImageList cols={1}>
                                    <ImageListItem>
                                      {
                                            selectedAsset.type === 'Image' &&
                                              <img src={selectedAsset.previewURL} alt='asset' className='selected-Asset' />
                                        }
                                      {
                                            selectedAsset.type === 'Video' &&
                                              <div style={styles.assetVideoPdf}>
                                                <MovieCreationTwoToneIcon fontSize='large' className='selected-Asset' style={styles.videoPdfIcon} />
                                              </div>
                                        }
                                      {
                                            selectedAsset.type === 'PDF' &&
                                              <div style={styles.assetVideoPdf}>
                                                <PictureAsPdfTwoToneIcon fontSize='large' className='selected-Asset' style={styles.videoPdfIcon} />
                                              </div>
                                        }
                                      <ImageListItemBar
                                        sx={{
                                          background:
                                                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
                                        }}
                                            // title={item.title}
                                        position='top'
                                        actionIcon={
                                          <IconButton
                                            sx={{ color: 'white' }}
                                            aria-label='delete'
                                            onClick={deleteAssetHandler}
                                          >
                                            <DeleteIcon sx={{ color: 'white' }} />
                                          </IconButton>
                                            }
                                        actionPosition='right'
                                      />
                                    </ImageListItem>
                                  </ImageList>
                            }

              {
                                field.asset === '' &&
                                  <Button
                                    style={styles.btnVideo}
                                    onClick={videoAddHandler}
                                  >
                                    <PlayCircleFilledIcon
                                      style={styles.playIcon}
                                      fontSize='large'
                                      color='primary'
                                    />
                                    Choose Video from Library
                                  </Button>
                            }

              {
                                openAssetModal &&
                                  <CompanyConfigModal
                                    open={openAssetModal}
                                    cancel={cancelVideo}
                                    header='Select Video'
                                    body={
                                      <SelectorWithPreview
                                        selectedConfig={field}
                                        fieldName='asset'
                                        config={props.assetsConfig}
                                        assetType={['Video']}
                                        onUpdate={(asset) => updateAssetHandler(asset)}
                                      />
                                    }
                                  />
                            }
            </div>
            <div style={styles.sendToCustomer}>
              <FormControlLabel
                className='send-to-customer-btn'
                control={
                  <Checkbox
                    checked={'sendToCustomer' in props.field ? props.field.sendToCustomer : sendToCustomer}
                    onChange={changeHandlerSendToCustomer}
                    fontSize='small'
                    color='primary'
                  />
                }
                label={
                  <Typography
                    className='send-to-customer-label'
                    style={styles.sendToCustomerLabel}
                  >
                    Add Send to Customer Button
                  </Typography>
                }
              />
            </div>
          </div>

          <div style={styles.rightDiv}>
            <div style={styles.description}>
              <TextField
                id='outlined-multiline-desc'
                label='Description'
                multiline
                fullWidth
                rows={3}
                variant='outlined'
                value={'description' in props.field ? props.field.description : description}
                onChange={changeHandlerDescription}
                sx={{
                  '& label': {
                    backgroundColor: '#F2F2F2'
                  }
                }}
              />
            </div>
            <div style={styles.thumbnail}>
              <span style={styles.thumbnailHeader}>
                Thumbnail
              </span>
              <span style={styles.thumbnailDesc}>
                Select or upload a picture that shows what's in your video.
              </span>
              <span style={styles.thumbnailUpload}>
                {
                                    field.poster !== '' && selectedPoster !== null &&
                                      <ImageList cols={1}>
                                        <ImageListItem>
                                          {
                                                selectedPoster.type === 'Image' &&
                                                  <div style={styles.thumbnailIconDiv}>
                                                    <img src={selectedPoster.previewURL} alt='poster' className='selected-Poster' style={styles.thumbnailImageIcon} />
                                                  </div>
                                            }
                                          {
                                                selectedPoster.type === 'Video' &&
                                                  <div style={styles.thumbnailIconDiv}>
                                                    <MovieCreationTwoToneIcon fontSize='large' className='selected-Poster' style={styles.thumbnailIcon} />
                                                  </div>
                                            }
                                          {
                                                selectedPoster.type === 'PDF' &&
                                                  <div style={styles.thumbnailIconDiv}>
                                                    <PictureAsPdfTwoToneIcon fontSize='large' className='selected-Poster' style={styles.thumbnailIcon} />
                                                  </div>
                                            }
                                          <ImageListItemBar
                                            sx={{
                                              background:
                                                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
                                            }}
                                                // title={item.title}
                                            position='top'
                                            actionIcon={
                                              <IconButton
                                                sx={{ color: 'white' }}
                                                aria-label='delete'
                                                onClick={deletePosterHandler}
                                              >
                                                <DeleteIcon sx={{ color: 'white' }} />
                                              </IconButton>
                                                }
                                            actionPosition='right'
                                          />
                                        </ImageListItem>
                                      </ImageList>
                                }

                {
                                    field.poster === '' &&
                                      <Button
                                        onClick={thumbnailAddHandler}
                                      >
                                        <AddPhotoAlternate fontSize='small' />
                                        Upload thumbnail
                                      </Button>
                                }

                {
                                    openThumbnailModal &&
                                      <CompanyConfigModal
                                        open={openThumbnailModal}
                                        cancel={cancelThumbnail}
                                        header='Select Thumbnail'
                                        body={
                                          <SelectorWithPreview
                                            selectedConfig={field}
                                            fieldName='poster'
                                            config={props.assetsConfig}
                                            assetType={['Image']}
                                            onUpdate={(poster) => updatePosterHandler(poster)}
                                          />
                                        }
                                      />
                                }
              </span>
            </div>
          </div>
        </Paper>
      </div>
    </>
  )
}

const styles = {
  mainDiv: {
    /* height: "375px", */
    display: 'flex'
  },
  contentBlockPaper: {
    borderLeft: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F7F7F7',
    display: 'flex',
    alignItems: 'center',
    width: '1002.11px',
    height: '300px'
  },
  leftDiv: {
    width: '40%'
  },
  rightDiv: {
    width: '60%',
    height: 'inherit'
  },
  addVideo: {
    width: '390px',
    height: '215px',
    backgroundColor: '#fff',
    marginLeft: '17px',
    marginTop: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px dotted #ced4da'
  },
  playIcon: {
    marginBottom: '15px'
  },
  btnVideo: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column'
  },
  sendToCustomer: {
    marginLeft: '25px',
    marginTop: '15px'
  },
  sendToCustomerLabel: {
    fontSize: '13px',
    color: '#000000a3'
  },
  descDiv: {

  },
  description: {
    marginLeft: '25px',
    marginRight: '17px',
    marginBottom: '16px',
    marginTop: '25px'
  },
  assetVideoPdf: {
    width: '150px',
    height: '100px',
    border: '1px dotted #ced4da'
  },
  videoPdfIcon: {
    marginTop: '31px',
    marginLeft: '54px'
  },
  thumbnail: {
    marginLeft: '25px',
    marginRight: '17px',
    marginBottom: '14px'
  },
  thumbnailIconDiv: {
    width: '125px',
    height: '56px'
  },
  thumbnailIcon: {
    marginTop: '20px',
    marginLeft: '45px'
  },
  thumbnailImageIcon: {
    width: '125px',
    height: '56px'
  },
  thumbnailHeader: {
    fontSize: '13px',
    fontWeight: '600',
    color: '#000000a3'
  },
  thumbnailDesc: {
    fontSize: '13px',
    color: '#000000a3',
    display: 'block',
    marginBottom: '6px'
  },
  thumbnailUpload: {
    display: 'inline-block',
    border: '1px dotted #ced4da'
  }
}

export default ContentBlock
