import React, { useState, useEffect } from 'react'
import FieldTypeExpandMarker from '../../Reusables/FieldTypeExpandMarker'
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField
} from '@mui/material'
import Chip from '@mui/material/Chip'
import PinIcon from '@mui/icons-material/Pin'
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'
import { DialogGeneric } from '../../../../../components/Reusable/DialogGeneric'

export const AddValueChipsPanel = (props) => {
  const [dataDescription, setDataDescription] = useState(props.dataDescription)
  const [dataValues, setDataValues] = useState(props.dataValues) // eslint-disable-line
  const [editValue, setEditValue] = useState(false)
  const [value, setValue] = useState({})
  const [type, setType] = useState('')
  const [title, setTitle] = useState('')
  const [desc, setDesc] = useState('')

  const setIcon = (val) => {
    switch (val) {
      case 'number':
        return <PinIcon fontSize='small' />
        // break
      case 'boolean':
        return <ThumbsUpDownIcon fontSize='small' />
        // break
      case 'string':
        return <FormatQuoteIcon fontSize='small' />
        // break
      default:
        break
    }
  }

  const editHandler = (item) => {
    setEditValue(!editValue)
    setValue(item)
  }

  const deleteHandler = (item, idx) => {
    // console.log(props);
    const newDataDesc = JSON.parse(JSON.stringify(dataDescription))
    const index = newDataDesc.findIndex(o => o.id === item.id)
    newDataDesc.splice(index, 1)

    const newDataValues = JSON.parse(JSON.stringify(dataValues))
    for (let i = 0; i < newDataValues.length; i++) {
    // newDataValues.map((val) => {
      newDataValues[i].splice(index, 1)
    }
    // )
    props.onSave(newDataDesc, newDataValues)
    // console.log(newDataDesc);
  }

  const handleSaveValue = () => {
    const newDataDesc = JSON.parse(JSON.stringify(dataDescription))
    const newDataValues = JSON.parse(JSON.stringify(dataValues))
    // let index = newDataDesc.findIndex(x => x.id === value.id)
    props.onSave(newDataDesc, newDataValues)
    setEditValue(!editValue)
  }

  const handleClose = () => {
    setEditValue(!editValue)
  }

  const changeHandlerType = (e) => {
    const newDataDesc = JSON.parse(JSON.stringify(dataDescription))

    const index = newDataDesc.findIndex(x => x.id === value.id)
    newDataDesc[index].type = e.target.value
    setDataDescription(newDataDesc)

    setType(e.target.value)
  }

  const changeHandlerTitle = (e) => {
    const newDataDesc = JSON.parse(JSON.stringify(dataDescription))
    // console.log(newDataDesc);
    const index = newDataDesc.findIndex(x => x.id === value.id)
    newDataDesc[index].title = e.target.value
    setDataDescription(newDataDesc)

    setTitle(e.target.value)
  }

  const changeHandlerDesc = (e) => {
    const newDataDesc = JSON.parse(JSON.stringify(dataDescription))

    const index = newDataDesc.findIndex(x => x.id === value.id)
    newDataDesc[index].description = e.target.value
    setDataDescription(newDataDesc)

    setDesc(e.target.value)
  }

  useEffect(() => {
    setType(value.type)
    setTitle(value.title)
    setDesc(value.description)
  }, [value])

  useEffect(() => {
    setDataDescription(props.dataDescription)
  }, [props.dataDescription])

  // console.log(props);

  const jsx = (
    <Box sx={{ width: '370px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl sx={{ marginTop: '25px', marginBottom: '5px' }} size='small' fullWidth>
            <InputLabel id='demo-simple-select-label'>Type</InputLabel>
            <Select
              labelId='type'
              id='type'
              value={type}
              size='small'
              label='Type'
              onChange={changeHandlerType}
            >
              <MenuItem key='number' value='number' selected={value.type === 'number'}>
                <span style={styles.icon}><PinIcon fontSize='small' /></span>
                numeric value
              </MenuItem>
              <MenuItem key='boolean' value='boolean' selected={value.type === 'boolean'}>
                <span style={styles.icon}><ThumbsUpDownIcon fontSize='small' /></span>
                yes / no
              </MenuItem>
              <MenuItem key='string' value='string' selected={value.type === 'string'}>
                <span style={styles.icon}><FormatQuoteIcon fontSize='small' /></span>
                custom expression
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id='title'
            name='title'
            variant='outlined'
            label='Title'
            onChange={changeHandlerTitle}
            value={title}
            size='small'
            sx={{
              marginTop: '25px'
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id='description'
            name='description'
            variant='outlined'
            label='Description'
            multiline
            rows={4}
            onChange={changeHandlerDesc}
            value={desc}
            size='small'
            sx={{
              marginTop: '25px'
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )

  return (
    <>
      <div style={styles.mainDiv}>
        <FieldTypeExpandMarker />
        <Paper elevation={0} square style={styles.paper}>
          <div style={styles.paperItems}>
            {
                        props.dataDescription.map((item, idx) => {
                          return (
                            <Chip
                              key={`addValue-${item.id}`}
                              style={styles.relatedQuestionMain}
                              icon={setIcon(item.type)}
                              label={item.title || `value ${idx + 1}`}
                              variant='outlined'
                                    // deleteIcon={<EditIcon style={{fontSize: "medium"}}/>}
                                    // onDelete={() => editHandler(item)}
                              onClick={() => editHandler(item)}
                              onDelete={() => deleteHandler(item, idx)}
                            />
                          )
                        })
                    }
          </div>
        </Paper>
      </div>

      {
                editValue &&
                  <DialogGeneric
                    title='Value'
                    subTitle={
                      <span>
                        A value that can be used as part of a calculation <br />
                        based on selection within this field type.
                      </span>
                    }
                    onClose={() => handleClose()}
                    onChange={handleSaveValue}
                    content={jsx}
                  />
            }
    </>
  )
}

const styles = {
  mainDiv: {
    display: 'flex'
  },
  paper: {
    // height: "89px",
    borderLeft: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    // marginRight: "96.19px",
    // width: "100%"
    flexBasis: '100%'
  },
  paperItems: {
    width: '100%',
    color: '#000000a3'
  },
  relatedQuestionMain: {
    background: '#fff',
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: '5px',
    marginRight: '5px',
    paddingLeft: '4px'
  },
  icon: {
    paddingRight: '16px'
  }
}
