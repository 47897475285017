import { useEffect, useState } from 'react'
import { Badge, Box, FormControlLabel, Grid, IconButton, Switch, TextField, Typography } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { LoadingPleaseWait } from '@supportworks/react-components'

const LeftPanel = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [solution, setSolution] = useState({})
  const [title, setTitle] = useState('')
  const [subTitle, setSubTitle] = useState('')
  const [editTitle, setEditTitle] = useState(false)

  useEffect(() => {
    if (isLoading) {
      setSolution(props.solution)
      setTitle(props.solution.title || 'No Title')
      setSubTitle(props.solution.subtitle || '')
      setEditTitle(props.solution.editTitle || false)
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, []) 

  useEffect(() => {
    setSolution(props.solution)
    setTitle(props.solution.title)
    setSubTitle(props.solution.subtitle)
  }, [props.solution])

  const handleChange = (e) => {
    const s = JSON.parse(JSON.stringify(solution))
    const name = e.target.name
    const value = e.target.value
    if (name === 'title') {
      setTitle(value)
      s[name] = value
    } else if (name === 'subtitle') {
      setSubTitle(value)
      s[name] = value
    } else if (name === 'editTitle') {
      setEditTitle(!editTitle)
      s[name] = !editTitle
    }
    setSolution(s)
    props.setSolution(s)
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <>
      <Grid container sx={{ pt: 2, pl: 2, pr: 2 }}>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <TextField
            fullWidth
            id='title'
            name='title'
            variant='outlined'
            label='Solution Title'
            value={title}
            sx={{ 'input:focus': { border: 'none' } }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ pt: 2, pl: 2, pr: 2 }}>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <TextField
            fullWidth
            id='subtitle'
            name='subtitle'
            variant='outlined'
            label='Solution Subtitle'
            value={subTitle}
            sx={{ 'input:focus': { border: 'none' } }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ pl: 2, borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Grid item sm={12}>
          <Grid item>
            <FormControlLabel
              name='editTitle'
              control={
                <Switch
                  checkedIcon={<CheckCircleRoundedIcon fontSize='small' />}
                  id='tax'
                  checked={!!editTitle}
                  color='primary'
                  onClick={handleChange}
                />
              }
              label={<Typography style={styles.textField}>Editable in App</Typography>}
              labelPlacement='start'
              onChange={handleChange}
              sx={{ ml: 0, p: 0 }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container sx={{ borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Grid item xs={12}>
          <Selection
            {...props}
            subVariant='presentation'
            title='Presentation'
            step={1}
            onClick={() => {
              props.setRightPanelVariant('presentation')
            }}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Grid item xs={12}>
          <Selection
            {...props}
            subVariant='poster'
            title='Poster'
            step={2}
            onClick={() => {
              props.setRightPanelVariant('poster')
            }}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Grid item xs={12}>
          <Selection
            {...props}
            subVariant='products'
            title='Default Products'
            step={3}
            onClick={() => {
              props.setRightPanelVariant('products')
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}
export default LeftPanel

const Selection = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isHover, setIsHover] = useState(false)
  const [title, setTitle] = useState('')

  useEffect(() => {
    const key = `${props.subVariant}Title`
    if (props.solution[key]) {
      setTitle(props.solution[key])
    } else {
      setTitle(props.title)
    }
    setIsLoading(false)
    // eslint-disable-next-line
  }, [])

  if (isLoading) return <></>

  let selected = false
  if (props.rightPanelVariant === props.subVariant) selected = true

  return (
    <Box
      key={props.title}
      onClick={props.onClick}
      style={{ ...styles.selectionContainer, backgroundColor: isHover ? '#F2F2F2' : 'white' }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Grid container sx={{ pl: 2, pt: props.onClick ? 1 : 1, pb: props.onClick ? 1 : 1 }} style={selected ? styles.selected : null} alignItems='center'>
        <Grid item sm={10}>
          <Grid container wrap='nowrap'>
            <Grid item sx={{ pl: 1 }}>
              <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
                {props.step ? <Badge badgeContent={props.step} color='primary' sx={{ mr: 3 }} /> : null}
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={1}>
          <IconButton>
            {props.rightPanelVariant === props.subVariant
              ? (
                <PlayCircleFilledIcon style={props.onClick ? styles.icon : styles.iconHidden} />
                )
              : (
                <ArrowRightIcon style={props.onClick ? styles.icon : styles.iconHidden} />
                )}
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  )
}

const styles = {
  icon: {
    color: 'rgba(0,0,0,.66)'
  },
  iconHidden: {
    color: 'rgba(0,0,0,0)'
  }
}
