import React from 'react'
import { Box } from '@mui/material'
import AdminIcon from '@mui/icons-material/AdminPanelSettings'
import TuneIcon from '@mui/icons-material/Tune'
import IconButton from '@mui/material/IconButton'
import FieldTypeSelect from 'screens/Config/Forms/FieldTypeSelect'
import FieldTypeAddLogicPanel from 'screens/Config/Forms/FieldTypeAddLogicPanel'
import SingleSelection from 'screens/Config/Forms/FieldTypes/SingleSelection'
import MultiSelection from 'screens/Config/Forms/FieldTypes/MultiSelection'
import DropdownSelection from 'screens/Config/Forms/FieldTypes/DropdownSelection'
import Numeric from 'screens/Config/Forms/FieldTypes/Numeric'
import TextInputBasic from 'screens/Config/Forms/Reusables/TextInputBasic'
import OptionsDialogField from 'screens/Config/Forms/Reusables/OptionsDialogField'
import ShortText from 'screens/Config/Forms/FieldTypes/ShortText'
import LongText from 'screens/Config/Forms/FieldTypes/LongText'
import Photo from 'screens/Config/Forms/FieldTypes/Photo'
import Date from 'screens/Config/Forms/FieldTypes/Date'
import Time from 'screens/Config/Forms/FieldTypes/Time'
import YesNo from 'screens/Config/Forms/FieldTypes/YesNo'
import Calculator from 'screens/Config/Forms/FieldTypes/Calculator'
import ContentBlock from 'screens/Config/Forms/FieldTypes/ContentBlock'
import Area from 'screens/Config/Forms/FieldTypes/Area'
import AreaCheckbox from 'screens/Config/Forms/FieldTypes/AreaCheckbox'
import RelatedInspectAreas from 'screens/Config/Forms/FieldTypes/RelatedInspectAreas'
import ProductRec from 'screens/Config/Forms/FieldTypes/ProductRec'
import FreeFormCapture from 'screens/Config/Forms/FieldTypes/FreeFormCapture'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CheckList from './FieldTypes/CheckList'
import Document from './FieldTypes/Document'
import Image from './FieldTypes/Image'
import Video from './FieldTypes/Video'
import LockIcon from '@mui/icons-material/Lock'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Permissions from 'library/Permissions'
import Popover from '@mui/material/Popover'
import DrawerFieldSettings from './ActionBar/DrawerFieldSettings'
import AreaListAdvanced from './FieldTypes/AreaListAdvanced'
import TableColumn2 from './FieldTypes/TableColumn2'
import AddSolutions from './FieldTypes/AddSolutions'
import CustomerWhy from './FieldTypes/CustomerWhy'
import { DialogGeneric } from 'components/Reusable/DialogGeneric'
import { JSONEditor } from 'components/Reusable'
import ProductRecDropdown from './FieldTypes/ProductRecDropdown'

class SingleFieldEditor extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      field: props.field,
      expanded: false,
      elemArr: [],
      questionArr: [],
      operatorArr: [],
      minMax: {},
      funcExp: '',
      currentQuestion: {},
      unitArr: [],
      openLock: false,
      lockedText: '',
      anchorEl: null,
      tabItem: null,
      showDrawerFieldSettings: false,
      showJSONEditor: false
    }

    this.changeHandlerTitle = this.changeHandlerTitle.bind(this)
    this.changeHandlerType = this.changeHandlerType.bind(this)
    this.changeHandlerVisible = this.changeHandlerVisible.bind(this)
    this.changeHandlerVisibleInApp = this.changeHandlerVisibleInApp.bind(this)
    this.changeHandlerDefault = this.changeHandlerDefault.bind(this)
    this.changeHandlerCRM = this.changeHandlerCRM.bind(this)
    this.changeHandlerUnits = this.changeHandlerUnits.bind(this)
    this.changeHandlerUnitArr = this.changeHandlerUnitArr.bind(this)
    this.changeHandlerQuote = this.changeHandlerQuote.bind(this)
    this.changeHandlerAddRelated = this.changeHandlerAddRelated.bind(this)
    this.changeHandlerSingleSelection = this.changeHandlerSingleSelection.bind(this)
    this.changeHandlerMultiSelection = this.changeHandlerMultiSelection.bind(this)
    this.changeHandlerDropdownSelection = this.changeHandlerDropdownSelection.bind(this)
    this.changeHandlerContentBlock = this.changeHandlerContentBlock.bind(this)
    this.toggleExpand = this.toggleExpand.bind(this)
    this.deleteField = this.deleteField.bind(this)
    this.saveSolutions = this.saveSolutions.bind(this)
    this.changeHandlerElemArr = this.changeHandlerElemArr.bind(this)
    this.changeHandlerQuestion = this.changeHandlerQuestion.bind(this)
    this.deleteHandlerQuestion = this.deleteHandlerQuestion.bind(this)
    this.changeHandlerOperator = this.changeHandlerOperator.bind(this)
    this.deleteHandlerOperator = this.deleteHandlerOperator.bind(this)
    this.changeHandlerMinMax = this.changeHandlerMinMax.bind(this)
    this.setCurrentQuestion = this.setCurrentQuestion.bind(this)
    this.onChangeLocked = this.setLocked.bind(this)
    this.openLockDialog = this.openLockDialog.bind(this)
    this.handleCloseLocked = this.handleCloseLocked.bind(this)
    this.handleSaveLocked = this.handleSaveLocked.bind(this)
    this.changeHandlerLocked = this.changeHandlerLocked.bind(this)
    this.changeHandlerFreeForm = this.changeHandlerFreeForm.bind(this)
    this.changeHandlerAreaListAdvanced = this.changeHandlerAreaListAdvanced.bind(this)
    this.changeHandlerTable = this.changeHandlerTable.bind(this)
    this.changeHandlerSendToCrm = this.changeHandlerSendToCrm.bind(this)
    this.changeHandlerAddSolutions = this.changeHandlerAddSolutions.bind(this)
  }

  changeHandlerTitle (title) {
    const field = JSON.parse(JSON.stringify(this.state.field))
    field.title = title
    this.setState({ field })
    this.props.onChange(field)
  }

  changeHandlerType (type) {
    const field = JSON.parse(JSON.stringify(this.state.field))

    switch (type) {
      case 'areaName':
        field.id = 'areaName'
        field.type = 'text'
        break
      case 'table_2':
        field.type = 'table'
        field.tableType = '2-Column'
        field.subArea = true
        field.rows = [
          {
            columns: [
              {
                id: this.props.guid(),
                subArea: true,
                areaValueDefault: true,
                type: '',
                title: ''
              },
              {
                id: this.props.guid(),
                subArea: true,
                areaValueDefault: true,
                type: '',
                title: ''
              }
            ]
          }
        ]
        break
      case 'table_3':
        field.type = 'table'
        field.tableType = '3-Column'
        field.subArea = true
        field.subAreaOnly = true
        field.rows = [
          {
            columns: [
              {
                id: this.props.guid(),
                subArea: true,
                subAreaOnly: true,
                type: '',
                title: ''
              },
              {
                id: this.props.guid(),
                subArea: true,
                subAreaOnly: true,
                type: '',
                title: ''
              },
              {
                id: this.props.guid(),
                subArea: true,
                subAreaOnly: true,
                type: '',
                title: ''
              }
            ]
          }
        ]
        break
      default:
        field.type = type
    }

    this.setState({ field })
    this.props.onChange(field)
  }

  changeHandlerVisible (visible) {
    const field = JSON.parse(JSON.stringify(this.state.field))
    field.includeSummary = visible
    this.setState({ field })
    this.props.onChange(field)
  }

  changeHandlerVisibleInApp (display) {
    const field = JSON.parse(JSON.stringify(this.state.field))
    field.display = display
    this.setState({ field })
    this.props.onChange(field)
  }

  changeHandlerJobInfo (jobInfo) {
    const field = JSON.parse(JSON.stringify(this.state.field))
    field.includeJobInfo = jobInfo
    this.setState({ field })
    this.props.onChange(field)
  }

  changeHandlerSendToCrm (val) {
    const field = JSON.parse(JSON.stringify(this.state.field))
    field.sendToCrm = val
    this.setState({ field })
    this.props.onChange(field)
  }

  changeHandlerDefault (val) {
    const field = JSON.parse(JSON.stringify(this.state.field))
    field.default = val
    this.setState({ field })
    this.props.onChange(field)
  }

  changeHandlerQuote (quote) {
    const field = JSON.parse(JSON.stringify(this.state.field))
    field.quote = quote
    this.setState({ field })
    this.props.onChange(field)
  }

  changeHandlerCRM (crm) {
    const field = JSON.parse(JSON.stringify(this.state.field))

    field.includeCRMFields = false
    if (crm !== '') {
      field.includeCRMFields = true
    }

    field.crm = crm
    this.setState({ field })
    this.props.onChange(field)
  }

  changeHandlerUnits (units) {
    const field = JSON.parse(JSON.stringify(this.state.field))

    field.units = units
    this.setState({ field })
    this.props.onChange(field)
  }

  changeHandlerUnitArr (arr) {
    // eslint-disable-next-line no-unused-vars
    const field = JSON.parse(JSON.stringify(this.state.field))
    this.setState({ field: arr })
    this.props.onChange(arr)
  }

  changeHandlerAddRelated (fieldWithRelated) {
    const field = JSON.parse(JSON.stringify(fieldWithRelated))
    this.setState({ field })
    this.props.onChange(field)
  }

  changeHandlerSingleSelection (newField) {
    const field = JSON.parse(JSON.stringify(newField))
    this.setState({ field })
    this.props.onChange(field)
  }

  changeHandlerMultiSelection (newField) {
    const field = JSON.parse(JSON.stringify(newField))
    this.setState({ field })
    this.props.onChange(field)
  }

  changeHandlerDropdownSelection (newField) {
    const field = JSON.parse(JSON.stringify(newField))
    this.setState({ field })
    this.props.onChange(field)
  }

  changeHandlerNumeric (newField) {
    const field = JSON.parse(JSON.stringify(newField))
    this.setState({ field })
    this.props.onChange(field)
  }

  changeHandlerContentBlock (value, type) {
    if (type === 'description') {
      const field = JSON.parse(JSON.stringify(this.state.field))
      field.description = value
      this.setState({ field })
      this.props.onChange(field)
    }

    if (type === 'sendToCustomer') {
      const field = JSON.parse(JSON.stringify(this.state.field))
      field.sendToCustomer = value
      this.setState({ field })
      this.props.onChange(field)
    }

    if (type === 'newField') {
      const field = JSON.parse(JSON.stringify(value))
      this.setState({ field })
      this.props.onChange(field)
    }
  }

  changeHandlerCalc (newField) {
    const field = JSON.parse(JSON.stringify(newField))
    this.setState({ field })
    this.props.onChange(field)
  }

  changeHandlerFreeForm (newField) {
    const field = JSON.parse(JSON.stringify(newField))
    this.setState({ field })
    this.props.onChange(field)
  }

  changeHandlerAreaListAdvanced (newField) {
    const field = JSON.parse(JSON.stringify(newField))
    this.setState({ field })
    this.props.onChange(field)
  }

  changeHandlerTable (newField) {
    const field = JSON.parse(JSON.stringify(newField))
    this.setState({ field })
    this.props.onChange(field)
  }

  changeHandlerElemArr (elemArr) {
    this.setState({ elemArr })
  }

  changeHandlerQuestion (f, elemIdx, elemArr) {
    // console.log(f, elemIdx, elemArr);
    const newQuestionArr = JSON.parse(JSON.stringify(this.state.questionArr))
    if (newQuestionArr.length === 0) {
      if (f.type === 'numerical') {
        newQuestionArr.push({ idx: elemIdx, numerical: f })
      } else {
        newQuestionArr.push({ idx: elemIdx, question: f })
      }
    } else {
      const checkIdx = obj => obj.idx === elemIdx
      const found = newQuestionArr.some(checkIdx)

      if (found) {
        const currIdx = newQuestionArr.findIndex(obj => obj.idx === elemIdx)
        // newQuestionArr.splice(currIdx, 1, {idx: elemIdx, question: f});
        if (f.type === 'numerical') {
          newQuestionArr.splice(currIdx, 1, { idx: elemIdx, numerical: f })
        } else {
          newQuestionArr.splice(currIdx, 1, { idx: elemIdx, question: f })
        }
      } else {
        // newQuestionArr.push({idx: elemIdx, question: f});
        if (f.type === 'numerical') {
          newQuestionArr.push({ idx: elemIdx, numerical: f })
        } else {
          newQuestionArr.push({ idx: elemIdx, question: f })
        }
      }
    }
    // console.log('singleField - newQuestionArr');
    this.setState({ questionArr: newQuestionArr })
  }

  deleteHandlerQuestion (elem) {
    const newQuestionArr = JSON.parse(JSON.stringify(this.state.questionArr))
    let currIdx

    if ('id' in elem) {
      currIdx = newQuestionArr.findIndex(obj => 'question' in obj && obj.question.id === elem.id)
    }
    if ('val' in elem) {
      // console.log(newQuestionArr);
      currIdx = newQuestionArr.findIndex(obj => 'numerical' in obj && obj.numerical.val === elem.val)
    }

    newQuestionArr.splice(currIdx, 1)
    this.setState({ questionArr: newQuestionArr })
  }

  setCurrentQuestion (elem) {
    this.setState({ currentQuestion: elem })
  }

  deleteHandlerOperator (idx) {
    const newOperatorArr = JSON.parse(JSON.stringify(this.state.operatorArr))
    const currIdx = newOperatorArr.findIndex(obj => obj.idx === idx)
    newOperatorArr.splice(currIdx, 1)
    this.setState({ operatorArr: newOperatorArr })
  }

  changeHandlerOperator (operator, elemIdx) {
    const newOperatorArr = JSON.parse(JSON.stringify(this.state.operatorArr))

    if (newOperatorArr.length === 0) {
      newOperatorArr.push({ idx: elemIdx, operator })
    } else {
      const checkIdx = obj => obj.idx === elemIdx
      const found = newOperatorArr.some(checkIdx)

      if (found) {
        const currIdx = newOperatorArr.findIndex(obj => obj.idx === elemIdx)
        newOperatorArr.splice(currIdx, 1, { idx: elemIdx, operator })
      } else {
        newOperatorArr.push({ idx: elemIdx, operator })
      }
    }

    this.setState({ operatorArr: newOperatorArr })
  }

  changeHandlerMinMax (minMax) {
    this.setState({ minMax })
  }

  saveSolutions (solutions) {
    this.props.onSaveSolutions(solutions)
  }

  toggleExpand () {
    this.setState({ expanded: !this.state.expanded })
  }

  deleteField () {
    this.props.onDelete()
  }

  setLocked (locked) {
    const field = JSON.parse(JSON.stringify(this.state.field))
    field.locked = locked
    this.setState({ field })
    this.props.onChange(field)
  }

  openLockDialog () {
    this.setState({ openLock: true })
  }

  handleCloseLocked () {
    this.setState({ openLock: false })
  }

  handleSaveLocked () {
    const field = JSON.parse(JSON.stringify(this.state.field))
    // field['lockedMessage'] = this.state.lockedText;
    this.setState({ openLock: false })
    this.props.onChange(field)
  }

  changeHandlerLocked (event) {
    const field = JSON.parse(JSON.stringify(this.state.field))
    field.lockedMessage = event.target.value
    this.setState({ field })
    // this.props.onChange(field);
  }

  changeHandlerAddSolutions (newField) {
    const field = JSON.parse(JSON.stringify(newField))
    this.setState({ field })
    this.props.onChange(field)
  }

  changeHandlerCustomerWhy (newField) {
    const field = JSON.parse(JSON.stringify(newField))
    this.setState({ field })
    this.props.onChange(field)
  }

  handlePopoverOpen = (event) => {
    // console.log(event.currentTarget);
    this.setState({ anchorEl: event.currentTarget })
  }

  handlePopoverClose = () => {
    this.setState({ anchorEl: null })
  }

  componentDidMount () {
    // console.log('component did mount');
    // console.log(this.props);
    if (this.props.field.type === 'calc') {
      const field = JSON.parse(JSON.stringify(this.props.field))

      if (!('locked' in field)) {
        field.locked = false
      }
      if (!('lockedMessage' in field)) {
        field.lockedMessage = ''
      }
      this.setState({ field })
    }

    if (this.props.field.type === 'calc' && 'calc' in this.props.field) {
      const arr = (this.props.field.calc.trim()).split(' ')
      arr.reverse()

      if (typeof arr[0] !== 'undefined') {
        const newMinMax = { ...this.state.minMax }

        let mathLength = 0
        for (let x = 0; x < arr.length - 1; x++) {
          if (arr[x].match(/min|max|round|floor|ceil/)) {
            mathLength++
          }
        }

        for (let i = 0; i <= mathLength; i++) {
          if (arr[0].match(/min|max|round|floor|ceil/)) {
            if (arr[0] === 'min' || arr[0] === 'max' || arr[0] === 'round') {
              if (arr[0] === 'round' && arr[1] === undefined) {
                newMinMax[arr[0]] = 2
              } else {
                newMinMax[arr[0]] = arr[1]
              }
              arr.splice(0, 2)
            } else if (arr[0] === 'ceil' || arr[0] === 'floor') {
              newMinMax[arr[0]] = true
              arr.splice(0, 1)
            }
          }
        }
        // console.log(newMinMax)
        this.setState({ minMax: newMinMax })
      }
      arr.reverse()

      const s = []
      const operandArr = []
      const operatorArr = []

      // console.log(arr)
      arr.forEach((e) => {
        if (e !== '+' && e !== '-' && e !== '*' && e !== '/') {
          s.push(e)
        } else {
          // console.log(s);
          const op1 = s.pop()
          const op2 = s.pop()
          // s.pop();
          s.push(op2 + ' ' + e + ' ' + op1)
        }
      })

      if (s.length > 0) {
        const infix = s[s.length - 1]
        const arrInfix = infix.split(' ')

        let i = 0

        if (typeof this.props.allFields !== 'undefined') {
          arrInfix.forEach((e, idx) => {
            // console.log(e);
            if (e !== '+' && e !== '-' && e !== '*' && e !== '/') {
              if (e !== '' || e !== undefined) {
                const elemObj = this.props.allFields.filter(a => {
                  return a.id === e
                })

                if (Object.keys(elemObj).length === 1) {
                  operandArr.push({ idx: i, question: elemObj[0] })
                } else {
                  operandArr.push({ idx: i, numerical: { val: e, type: 'numerical' } })
                }
                i = i + 2
              }
            } else {
              operatorArr.push({ idx, operator: e })
            }
          })
        }
      }
      // console.log(operatorArr, operandArr)
      this.setState({ questionArr: operandArr })
      this.setState({ operatorArr })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    console.log('component did update')

    const newQuestionArr = [...this.state.questionArr]
    // console.log(newQuestionArr)
    newQuestionArr.sort((a, b) => parseFloat(a.idx) - parseFloat(b.idx))

    const newOperatorArr = [...this.state.operatorArr]
    const infixArr = []
    let infix = ''
    if (newQuestionArr.length > 0) {
      // newQuestionArr.map((q, idx) => {
      for (let idx = 0; idx < newQuestionArr.length; idx++) {
        const q = newQuestionArr[idx]
        if (q.question !== undefined || q.numerical !== undefined) {
          if ('question' in q) {
            infixArr.push(q.question.id)
          }
          if ('numerical' in q) {
            infixArr.push(q.numerical.val)
          }

          if (newOperatorArr[idx] !== undefined) {
            infixArr.push(newOperatorArr[idx].operator)
          }

          if ('question' in q) {
            infix += q.question.id + (newOperatorArr[idx] !== undefined ? (' ' + newOperatorArr[idx].operator + ' ') : '')
          }
          if ('numerical' in q) {
            infix += q.numerical.val + (newOperatorArr[idx] !== undefined ? (' ' + newOperatorArr[idx].operator + ' ') : '')
          }
        }
      }
      // )
    }
    infix = infix.trim()
    let infixToPost = true
    if (infix === '' || infix === 'array.sum' || infix === 'call.module') {
      infixToPost = false
    }

    let postfix = ''
    const stackArr = []
    const precedence = (operator) => {
      switch (operator) {
        case '*':
        case '/':
          return 2
        case '+':
        case '-':
          return 1
        default:
          return 0
      }
    }

    infixArr.forEach((e) => {
      if (e !== '+' && e !== '-' && e !== '*' && e !== '/') {
        postfix += e + ' '
      } else {
        while (!(stackArr.length === 0) &&
                (precedence(e) <= precedence(stackArr[0]))) {
          postfix += stackArr.pop() + ' '
        }
        stackArr.push(e)
      }
    })
    // console.log("'"+postfix+"'");
    // postfix = postfix.trim();
    // console.log("'"+postfix+"'");

    while (!(stackArr.length === 0)) {
      if (stackArr.length !== 1) {
        postfix += stackArr.pop() + ' '
      } else {
        postfix += stackArr.pop()
      }
    }

    if (this.state.field.type === 'calc' && infixToPost && !(this.state.field.locked)) {
      const field = JSON.parse(JSON.stringify(this.state.field))

      if (Object.keys(this.state.minMax).length !== 0) {
        let funcExp = ''
        let i = 0
        for (const [key, value] of Object.entries(this.state.minMax)) {
          if (i === 0) {
            if (key === 'min' || key === 'max' || key === 'round') {
              funcExp = value + ' ' + key
            } else if (key === 'floor' || key === 'ceil') {
              funcExp = key
            }
          } else {
            if (key === 'min' || key === 'max' || key === 'round') {
              funcExp += ' ' + value + ' ' + key
            } else if (key === 'floor' || key === 'ceil') {
              funcExp += ' ' + key
            }
          }
          i++
        }
        postfix = postfix.trim()
        postfix = postfix + ' ' + funcExp
      }

      field.calc = postfix

      // console.log(postfix);

      if (prevState.questionArr !== this.state.questionArr ||
                prevState.operatorArr !== this.state.operatorArr ||
                prevState.field.calc !== field.calc) {
        this.setState({ field })
        this.changeHandlerCalc(field)
      }
    }
  }

  /**
   * getDervicedStateFromProps()
   *
   * Force screen updates based on field changes.
   */
  static getDerivedStateFromProps (props, state) {
    if (props.field !== state.lastPropsField) {
      return {
        field: props.field,
        lastPropsField: props.field
      }
    }
    return null
  }

  toggleDrawerFieldSettings () {
    this.setState({ showDrawerFieldSettings: !this.state.showDrawerFieldSettings })
  }

  handleShowJSONEditor = () => {
    this.setState({ showJSONEditor: !this.state.showJSONEditor })
  }

  render () {
    const field = this.state.field
    const currentType = (field) => {
      if (field.id === 'areaName') {
        return 'areaName'
      } else if (field.type === 'table') {
        if (field.tableType === '2-Column') {
          return 'table_2'
        }
        if (field.tableType === '3-Column') {
          return 'table_3'
        }
      } else {
        return field.type
      }
    }

    const renderFieldTypes = (type) => {
      if (type === 'table') {
        if (this.state.field.tableType === '2-Column') {
          type = 'table_2'
        }
        if (this.state.field.tableType === '3-Column') {
          type = 'table_3'
        }
      }

      switch (type) {
        case 'boolean':
          return (
            <YesNo
              {...this.props}
              field={field}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              onChangeDefault={(val) => this.changeHandlerDefault(val)}
              onChangeCRM={(crm) => this.changeHandlerCRM(crm)}
              onChangeUnits={(units) => this.changeHandlerUnits(units)}
              guid={this.props.guid}
              askQuestion={(related) => this.changeHandlerAddRelated(related)}
              form={this.props.form}
              onSaveSolutions={(solutions) => this.saveSolutions(solutions)}
              allFieldsSolutions={this.props.allFieldsSolutions}
              crmFieldsConfig={this.props.crmFieldsConfig}
              numericUnitsConfig={this.props.numericUnitsConfig}
              allFields={this.props.allFields}
            />
          )
        case 'radio':
          return (
            <SingleSelection
              {...this.props}
              field={field}
              list={'list' in field ? field.list : []}
              onChange={(options) => this.changeHandlerSingleSelection(options)}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              onChangeCRM={(crm) => this.changeHandlerCRM(crm)}
              onChangeUnits={(units) => this.changeHandlerUnits(units)}
              guid={this.props.guid}
              askQuestion={(related) => this.changeHandlerAddRelated(related)}
              form={this.props.form}
              onSaveSolutions={(solutions) => this.saveSolutions(solutions)}
              allFieldsSolutions={this.props.allFieldsSolutions}
              crmFieldsConfig={this.props.crmFieldsConfig}
              numericUnitsConfig={this.props.numericUnitsConfig}
              allFields={this.props.allFields}
              setTabItem={(tab) => {
                this.setState({ tabItem: tab })
              }}
              tabItem={this.state.tabItem}
            />
          )
        case 'checkbox':
          return (
            <MultiSelection
              {...this.props}
              field={field}
              list={'list' in field ? field.list : []}
              onChange={(options) => this.changeHandlerMultiSelection(options)}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              onChangeDefault={(val) => this.changeHandlerDefault(val)}
              onChangeCRM={(crm) => this.changeHandlerCRM(crm)}
              onChangeUnits={(units) => this.changeHandlerUnits(units)}
              guid={this.props.guid}
              askQuestion={(related) => this.changeHandlerAddRelated(related)}
              form={this.props.form}
              onSaveSolutions={(solutions) => this.saveSolutions(solutions)}
              allFieldsSolutions={this.props.allFieldsSolutions}
              crmFieldsConfig={this.props.crmFieldsConfig}
              numericUnitsConfig={this.props.numericUnitsConfig}
              allFields={this.props.allFields}
              setTabItem={(tab) => {
                this.setState({ tabItem: tab })
              }}
              tabItem={this.state.tabItem}
            />
          )
        case 'choice':
          return (
            <DropdownSelection
              {...this.props}
              field={field}
              choices={'choices' in field ? field.choices : []}
              onChange={(options) => this.changeHandlerDropdownSelection(options)}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              onChangeDefault={(val) => this.changeHandlerDefault(val)}
              onChangeCRM={(crm) => this.changeHandlerCRM(crm)}
              onChangeUnits={(units) => this.changeHandlerUnits(units)}
              guid={this.props.guid}
              askQuestion={(related) => this.changeHandlerAddRelated(related)}
              form={this.props.form}
              onSaveSolutions={(solutions) => this.saveSolutions(solutions)}
              allFieldsSolutions={this.props.allFieldsSolutions}
              crmFieldsConfig={this.props.crmFieldsConfig}
              numericUnitsConfig={this.props.numericUnitsConfig}
              allFields={this.props.allFields}
              setTabItem={(tab) => {
                this.setState({ tabItem: tab })
              }}
              tabItem={this.state.tabItem}
            />
          )
        case 'numeric':
          return (
            <Numeric
              {...this.props}
              field={field}
              onChange={(options) => this.changeHandlerNumeric(options)}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              onChangeVisibleInApp={(display) => this.changeHandlerVisibleInApp(display)}
              onChangeDefault={(val) => this.changeHandlerDefault(val)}
              onChangeCRM={(crm) => this.changeHandlerCRM(crm)}
              onChangeUnits={(units) => this.changeHandlerUnits(units)}
              onChangeUnitArr={(unitArr) => this.changeHandlerUnitArr(unitArr)}
              guid={this.props.guid}
              askQuestion={(related) => this.changeHandlerAddRelated(related)}
              form={this.props.form}
              onSaveSolutions={(solutions) => this.saveSolutions(solutions)}
              allFieldsSolutions={this.props.allFieldsSolutions}
              crmFieldsConfig={this.props.crmFieldsConfig}
              numericUnitsConfig={this.props.numericUnitsConfig}
              allFields={this.props.allFields}
            />
          )
        case 'media':
          return (
            <Photo
              {...this.props}
              field={field}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              onChangeQuote={(quote) => this.changeHandlerQuote(quote)}
              onChangeCRM={(crm) => this.changeHandlerCRM(crm)}
              onChangeUnits={(units) => this.changeHandlerUnits(units)}
            />
          )
        case 'contentBlock':
          return (
            <ContentBlock
              {...this.props}
              field={field}
              onChange={(value, type) => this.changeHandlerContentBlock(value, type)}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              onChangeCRM={(crm) => this.changeHandlerCRM(crm)}
              onChangeUnits={(units) => this.changeHandlerUnits(units)}
              assetsConfig={this.props.assetsConfig}
            />
          )
        case 'calc':
          return (
            <Calculator
              {...this.props}
              field={field}
              onChange={(field) => this.changeHandlerCalc(field)}
              onChangeElemArr={(elemArr) => this.changeHandlerElemArr(elemArr)}
              onChangeOperatorArr={(operator, elemIdx) => this.changeHandlerOperator(operator, elemIdx)}
              onChangeQuestionArr={(f, elemIdx, elemArr) => this.changeHandlerQuestion(f, elemIdx, elemArr)}
              onDeleteQuestionArr={(elem) => this.deleteHandlerQuestion(elem)}
              onDeleteOperatorArr={(idx) => this.deleteHandlerOperator(idx)}
              onChangeMinMax={(minMax) => this.changeHandlerMinMax(minMax)}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              onChangeVisibleInApp={(display) => this.changeHandlerVisibleInApp(display)}
              onChangeCRM={(crm) => this.changeHandlerCRM(crm)}
              onChangeUnits={(units) => this.changeHandlerUnits(units)}
              onChangeUnitArr={(unitArr) => this.changeHandlerUnitArr(unitArr)}
              onSetCurrentQuestion={(elem) => this.setCurrentQuestion(elem)}
              allFields={this.props.allFields}
              allFieldsSolutions={this.props.allFieldsSolutions}
              askQuestion={(related) => this.changeHandlerAddRelated(related)}
              form={this.props.form}
              elemArr={this.state.elemArr}
              questionArr={this.state.questionArr}
              operatorArr={this.state.operatorArr}
              minMax={this.state.minMax}
              currentQuestion={this.state.currentQuestion}
              crmFieldsConfig={this.props.crmFieldsConfig}
              numericUnitsConfig={this.props.numericUnitsConfig}
              guid={this.props.guid}
              onSaveSolutions={(solutions) => this.saveSolutions(solutions)}
            />
          )
        case 'text':
          return (
            <ShortText
              {...this.props}
              field={field}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              onChangeDefault={(val) => this.changeHandlerDefault(val)}
              onChangeCRM={(crm) => this.changeHandlerCRM(crm)}
            />
          )
        case 'type':
          return (
            <LongText
              {...this.props}
              field={field}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              onChangeDefault={(val) => this.changeHandlerDefault(val)}
              onChangeCRM={(crm) => this.changeHandlerCRM(crm)}
            />
          )
        case 'date':
          return (
            <Date
              {...this.props}
              field={field}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              onChangeCRM={(crm) => this.changeHandlerCRM(crm)}
              onChangeUnits={(units) => this.changeHandlerUnits(units)}
              onChangeDefault={(val) => this.changeHandlerDefault(val)}
            />
          )
        case 'time':
          return (
            <Time
              {...this.props}
              field={field}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              onChangeCRM={(crm) => this.changeHandlerCRM(crm)}
              onChangeUnits={(units) => this.changeHandlerUnits(units)}
              onChangeDefault={(val) => this.changeHandlerDefault(val)}
            />
          )
        case 'area':
          return (
            <Area
              {...this.props}
              field={field}
              choices={'choices' in field ? field.choices : []}
              onChange={(options) => this.changeHandlerSingleSelection(options)}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              guid={this.props.guid}
              form={this.props.form}
            />
          )
        case 'addAreaCheckbox':
          return (
            <AreaCheckbox
              {...this.props}
              field={field}
              onChange={(options) => this.changeHandlerSingleSelection(options)}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              guid={this.props.guid}
              form={this.props.form}
            />
          )
        case 'relatedInspectAreas':
          return (
            <RelatedInspectAreas
              {...this.props}
              field={field}
              onChange={(options) => this.changeHandlerSingleSelection(options)}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              guid={this.props.guid}
              form={this.props.form}
            />
          )
        case 'productRecommendation':
          return (
            <ProductRec
              {...this.props}
              field={field}
              onChange={(options) => this.changeHandlerSingleSelection(options)}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              guid={this.props.guid}
              form={this.props.form}
                            // onSaveSolutions={(solutions)=>this.saveSolutions(solutions)}
              allFieldsSolutions={this.props.allFieldsSolutions}
              solutionsConfig={this.props.solutionsConfig}
            />
          )
        case 'productRecommendationProductList':
          return (
            <ProductRecDropdown
              {...this.props}
              field={field}
              onChange={(options) => this.changeHandlerSingleSelection(options)}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              guid={this.props.guid}
              form={this.props.form}
                            // onSaveSolutions={(solutions)=>this.saveSolutions(solutions)}
              allFieldsSolutions={this.props.allFieldsSolutions}
              solutionsConfig={this.props.solutionsConfig}
            />
          )
        case 'checklist':
          return (
            <CheckList
              {...this.props}
              field={field}
              list={'list' in field ? field.list : []}
              onChange={(options) => this.changeHandlerSingleSelection(options)}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              onChangeCRM={(crm) => this.changeHandlerCRM(crm)}
              guid={this.props.guid}
              askQuestion={(related) => this.changeHandlerAddRelated(related)}
              form={this.props.form}
              onSaveSolutions={(solutions) => this.saveSolutions(solutions)}
              allFields={this.props.allFields}
            />
          )
        case 'pdf':
          return (
            <Document
              {...this.props}
              field={field}
              onChange={(options) => this.changeHandlerSingleSelection(options)}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              guid={this.props.guid}
              form={this.props.form}
              allFields={this.props.allFields}
              assetsConfig={this.props.assetsConfig}
            />
          )
        case 'image':
          return (
            <Image
              {...this.props}
              field={field}
              onChange={(options) => this.changeHandlerSingleSelection(options)}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              guid={this.props.guid}
              form={this.props.form}
              allFields={this.props.allFields}
              assetsConfig={this.props.assetsConfig}
            />
          )
        case 'video':
          return (
            <Video
              {...this.props}
              field={field}
              onChange={(options) => this.changeHandlerSingleSelection(options)}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              guid={this.props.guid}
              form={this.props.form}
              allFields={this.props.allFields}
              assetsConfig={this.props.assetsConfig}
            />
          )
        case 'freeFormCapture':
          return (
            <FreeFormCapture
              {...this.props}
              field={field}
              onChange={(field) => this.changeHandlerFreeForm(field)}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
              onChangeJobInfo={(jobInfo) => this.changeHandlerJobInfo(jobInfo)}
              onChangeSendToCrm={(val) => this.changeHandlerSendToCrm(val)}
            />
          )
        case 'addAreaListAdvanced':
          return (
            <AreaListAdvanced
              {...this.props}
              field={field}
              list={'list' in field ? field.list : []}
              guid={this.props.guid}
              onChange={(options) => this.changeHandlerAreaListAdvanced(options)}
            />
          )
        case 'table_2':
          return (
            <TableColumn2
              {...this.props}
              field={field}
              onChange={(field) => this.changeHandlerTable(field)}
              onSaveSolutions={(solutions) => this.saveSolutions(solutions)}
            />
          )
        case 'table_3':
          return (
            <TableColumn2
              {...this.props}
              field={field}
              onChange={(field) => this.changeHandlerTable(field)}
              onSaveSolutions={(solutions) => this.saveSolutions(solutions)}
            />
          )
        case 'addSolutions':
          return (
            <AddSolutions
              {...this.props}
              field={field}
              onChange={(field) => this.changeHandlerAddSolutions(field)}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
            />
          )
        case 'customerWhy':
          return (
            <CustomerWhy
              {...this.props}
              field={field}
              onChange={(field) => this.changeHandlerCustomerWhy(field)}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
            />
          )
        default:
          return (
            <FieldTypeAddLogicPanel
              {...this.props}
              field={field}
              onChangeVisible={(visible) => this.changeHandlerVisible(visible)}
            />
          )
      }
    }

    return (
      <>
        <div style={styles.fieldContainer}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexGrow: 1,
                height: '100%'
              }}
            >
              <div>
                {'locked' in field && field.locked === true
                  ? (
                    <IconButton
                      onClick={() => this.openLockDialog()}
                      onMouseEnter={(event) => this.handlePopoverOpen(event)}
                      onMouseLeave={() => this.handlePopoverClose()}
                    >
                      {/* <i class="fas fa-chevron-circle-right"></i> */}
                      <LockIcon color='primary' />
                    </IconButton>
                    )
                // }

                // {
                //! ('locked' in field)
                // || ('locked' in field && field.locked === false) &&
                  : (
                    <>
                      <IconButton className={'field-arrow-icon-' + (!this.state.expanded ? 'show' : 'hidden')} onClick={() => this.toggleExpand()}>
                        <ChevronRightIcon />
                      </IconButton>
                      <IconButton className={'field-arrow-icon-' + (this.state.expanded ? 'show' : 'hidden')} onClick={() => this.toggleExpand()}>
                        <ExpandCircleDownIcon color='primary' />
                      </IconButton>
                    </>
                    )}
              </div>

              <TextInputBasic
                name='field-type-title'
                placeholder='Untitled Field Type'
                inputValue={'title' in field ? field.title : ''}
                onChange={(fieldTitle) => this.changeHandlerTitle(fieldTitle)}
                style={{
                  width: '100%',
                  marginLeft: '10px'
                }}
              />
            </div>
            <div style={styles.flex}>
              <FieldTypeSelect
                onChange={(fieldType) => this.changeHandlerType(fieldType)}
                    // currentType={"type" in field ? field.type : "boolean"}
                currentType={currentType(field)}
                type='fieldLevel'
              />
              <Box sx={{ pt: 1 }}>
                <IconButton onClick={() => this.toggleDrawerFieldSettings()} disabled={!!(field && field.type === null)}>
                  <TuneIcon />
                </IconButton>
              </Box>
              <OptionsDialogField
                onDelete={() => this.deleteField()}
                onMoveUp={this.props.onMoveUp}
                onMoveDown={this.props.onMoveDown}
                onChangeLocked={(locked) => this.onChangeLocked(locked)}
                locked={'locked' in field ? field.locked : false}
                fieldType={'type' in field ? field.type : ''}
              />
            </div>
          </div>

          {Permissions.hasRole('super_user')
            ? (
              <Dialog open={this.state.openLock} onClose={() => this.handleCloseLocked()}>
                <DialogTitle>Locked Text</DialogTitle>
                <DialogContent>
                  <DialogContentText>This text will be available to a user when they press the locked icon.</DialogContentText>
                  <TextField
                    autoFocus
                    margin='dense'
                    id='name'
                    label=''
                    fullWidth
                    multiline
                    minRows={2}
                    maxRows={4}
                    variant='filled'
                    value={'lockedMessage' in field ? field.lockedMessage : ''}
                    onChange={(event) => this.changeHandlerLocked(event)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => this.handleCloseLocked()}>Cancel</Button>
                  <Button onClick={(event) => this.handleSaveLocked(event)}>Save</Button>
                </DialogActions>
              </Dialog>
              )
            : (
              <Popover
                open={Boolean(this.state.anchorEl) && field.lockedMessage !== ''}
                anchorEl={this.state.anchorEl}
                sx={{
                  pointerEvents: 'none',
                  width: '1080px'
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                onClose={() => this.handlePopoverClose()}
                disableRestoreFocus
              >
                <div style={{ margin: '16px' }}>{field.lockedMessage}</div>
              </Popover>
              )}

          {Permissions.hasRole('super_user')
            ? (
              <Box sx={{ ml: 0, pl: 0, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {/** Pop open a modal with the JSON from this field */}
                <IconButton onClick={this.handleShowJSONEditor}>
                  <AdminIcon size='small' style={{ color: 'red' }} />
                </IconButton>
              </Box>
              )
            : null}

          {this.state.showJSONEditor
            ? <DialogGeneric
                title='JSON Field Editor'
                fullWidth
                maxWidth='md'
                content={
                  <JSONEditor
                    value={JSON.stringify(this.props.field)}
                    onChange={(value) => {
                      this.changeHandlerFreeForm(JSON.parse(value))
                    }}
                    saveOnlyOnDone
                  />
                    }
                onClose={this.handleShowJSONEditor}
                onSave={this.handleShowJSONEditor}
              />
            : null}
        </div>

        <DrawerFieldSettings
          {...this.props}
          key='drawer-field-settings'
          field={this.state.field}
          open={this.state.showDrawerFieldSettings}
          onClose={() => {
            this.toggleDrawerFieldSettings()
          }}
          onDone={(field) => {
            this.props.onChange(field)
            this.toggleDrawerFieldSettings()
          }}
        />

        {(!('locked' in field) || ('locked' in field && field.locked === false)) && this.state.expanded && renderFieldTypes(field.type)}
      </>
    )
  }
}

const styles = {
  flex: {
    display: 'flex'
  },
  fieldContainer: {
    backgroundColor: 'white',
    paddingLeft: '0',
    paddingRight: '0',
    display: 'flex',
    border: '1px solid #0000003b'
  },
  fieldContainerGridTitle: {
    borderTop: '1px solid #0000003b',
    borderLeft: '1px solid #0000003b'
  },
  fieldContainerGridTypemenu: {
    borderLeft: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    // borderBottom: "1px solid #0000003b",
    borderTop: '1px solid #0000003b'
  },
  fieldContainerGridOptions: {
    display: 'flex',
    alignItems: 'center'
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400, // Adjust width as needed
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2
  }
}

export default SingleFieldEditor
