import React, { useState, useEffect } from 'react'
import { Box, Button } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify'
import RestoreIcon from '@mui/icons-material/Restore'
import DownloadIcon from '@mui/icons-material/Download'
import AceEditor from 'react-ace'
/* eslint-disable-next-line */
import jsonWorkerUrl from 'file-loader!ace-builds/src-noconflict/worker-json';
import { LoadingPleaseWait } from '@supportworks/react-components'
/* eslint-disable-next-line */
import 'ace-builds/src-noconflict/mode-json';
/* eslint-disable-next-line */
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-searchbox'

/* eslint-disable-next-line */
ace.config.setModuleUrl('ace/mode/json_worker', jsonWorkerUrl);

const JSONEditor = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [json, setJSON] = useState({})
  const [original, setOriginal] = useState({})

  useEffect(() => {
    if (isLoading) {
      setJSON(JSON.stringify(JSON.parse(props.value), null, 2))
      setOriginal(JSON.stringify(JSON.parse(props.value), null, 2))
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleSave = (value) => {
    setOriginal(json)
    // If I pass onChange, I want to send these back.
    if (props.onChange) {
      props.onChange(json)
    }
  }

  const handleExport = () => {
    const blob = new Blob([json], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'exported.json'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }

  const handleChange = (value) => {
    setJSON(value)
    if (isValidJSON(value)) {
      if (props.onChange && !props.saveOnlyOnDone) {
        props.onChange(value)
      }
    }
  }

  const handleBeautify = () => {
    const perty = JSON.stringify(JSON.parse(json), null, 2)
    setJSON(perty)
    if (props.onChange) {
      props.onChange(perty)
    }
  }

  const isValidJSON = (value) => {
    try {
      JSON.parse(value)
    } catch (e) {
      return false
    }
    return true
  }

  if (isLoading) return <LoadingPleaseWait />

  return (
    <Box sx={{ border: '1px solid rgba(0,0,0,.16)' }}>
      <Box style={styles.editorContainer}>
        <AceEditor
          name={props.name ? props.name : 'editor'}
          mode='json'
          onChange={handleChange}
          onLoad={(editorInstance) => {
            editorInstance.container.style.resize = 'both'
            document.addEventListener('mouseup', (e) => editorInstance.resize())
          }}
          theme={props.theme ? props.theme : 'monokai'}
          value={json}
          minLines={2}
          width='100%'
        />
      </Box>
      <Box style={styles.bottomContainer}>
        <Button color='primary' variant='outlined' onClick={handleBeautify} startIcon={<FormatAlignJustifyIcon />}>
          Format
        </Button>
        <Button sx={{ ml: 1 }} color='primary' variant='outlined' onClick={handleExport} startIcon={<DownloadIcon />}>
          Export
        </Button>
        {props.onChange
          ? (
            <>
              {original !== json
                ? (
                  <Button
                    id='cancel'
                    variant='contained'
                    color='primary'
                    sx={{ ml: 2 }}
                    onClick={() => {
                      setJSON(original)
                    }}
                    startIcon={<RestoreIcon />}
                  >
                    Restore
                  </Button>
                  )
                : null}
              <Button
                id='save'
                disabled={!(original !== json && isValidJSON(json))}
                variant='contained'
                color='primary'
                sx={{ marginLeft: 'auto' }}
                onClick={handleSave}
                startIcon={<DoneIcon />}
              >
                Done
              </Button>
            </>
            )
          : null}
      </Box>
    </Box>
  )
}
export default JSONEditor

const styles = {
  editorContainer: {
    display: 'flex',
    width: '100%'
  },
  bottomContainer: {
    display: 'flex',
    width: '100%',
    padding: '16px',
    borderTop: '1px solid rgba(0,0,0,.16)',
    backgroundColor: 'white'
  }
}
