import React, { useState, useEffect } from 'react'
import { Box, Button, Checkbox, FormGroup, FormControlLabel, Grid, IconButton, Stack, TextField, Typography } from '@mui/material'
import { LoadingPleaseWait } from '@supportworks/react-components'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'
import EditIcon from '@mui/icons-material/Edit'
import { DialogGeneric } from '../../../components/Reusable/DialogGeneric'
import { SelectionHeader } from './components/SelectionHeader'
import Helper from '@supportworks/helper'

const RightPanelCategories = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [product, setProduct] = useState({}) // For object manipulation
  const [categories, setCategories] = useState([])
  const [productCategories, setProductCategories] = useState([])
  const [selectedIdx, setSelectedIdx] = useState(-1)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [variant, setVariant] = useState('solo-editor')

  useEffect(() => {
    if (isLoading) {
      if (!props.productFeatures.product_categories) {
        props.productFeatures.product_categories = []
      }
      setProductCategories(props.productFeatures.product_categories)
      // Logic for product editor only, not feature edit
      if (props.product) {
        if ('categories' in props.product) {
          setCategories(props.product.categories || [])
        } else {
          props.product.categories = []
        }

        setProduct(props.product)
        setVariant('product-editor')
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    // Logic for product editor only from Master List/Price Lists
    if (variant === 'product-editor') {
      setCategories(props.product.categories)
      setProduct(props.product)
    }
    // eslint-disable-next-line
  }, [props.product])

  const handleCheck = (e) => {
    const p = JSON.parse(JSON.stringify(product))
    if (p.categories.includes(e.target.id)) {
      const idx = p.categories.indexOf(e.target.id)
      p.categories.splice(idx, 1)
    } else {
      p.categories.push(e.target.id)
      const ancestors = props.getAncestors(e.target.id)
      if (ancestors && ancestors.length) {
        ancestors.forEach((a) => {
          if (!p.categories.includes(a)) {
            p.categories.push(a)
          }
        })
      }
    }
    setCategories(p.categories)
    setProduct(p)
    props.setProduct(p)
  }

  const handleDelete = (idx) => {
    const pc = JSON.parse(JSON.stringify(productCategories))
    pc.splice(idx, 1)
    props.productFeatures.product_categories = pc

    // Logic for product editor only from Master List/Price Lists.  Fix orphan categories.
    if (variant === 'product-editor') {
      const p = JSON.parse(JSON.stringify(product))
      let x = props.product.categories.length
      while (x--) {
        const id = props.product.categories[x]
        if (!pc.find((o) => o.id === id)) {
          p.categories.splice(x, 1)
        }
      }
      setCategories(p.categories)
      setProduct(p)
      props.setProduct(p)
    }
    setProductCategories(pc)
    setSelectedIdx(-1)
  }

  const handleSort = () => {
    const pc = JSON.parse(JSON.stringify(productCategories))
    pc.sort((a, b) => a.title.localeCompare(b.title))
    props.productFeatures.product_categories = pc
    setProductCategories(pc)
  }

  /* FUTURE?
  const handleMoveUp = (idx) => {
    const obj = JSON.parse(JSON.stringify(productCategories))
    const ele = obj.splice(idx, 1)[0]
    obj.splice(idx - 1, 0, ele)
    props.productFeatures.product_categories = obj
    setProductCategories(obj)
  }

  const handleMoveDown = (idx) => {
    const obj = JSON.parse(JSON.stringify(productCategories))
    const ele = obj.splice(idx, 1)[0]
    obj.splice(idx + 1, 0, ele)
    props.productFeatures.product_categories = obj
    setProductCategories(obj)
  }
  */

  if (isLoading) return <LoadingPleaseWait />

  let disabled = false
  if (!product.categories_ovrd) {
    disabled = true
  }

  return (
    <>
      <Grid container wrap='nowrap'>
        {productCategories.length
          ? (
            <Box component='div' style={styles.gridContainer}>
              <SelectionHeader
                title='Categories'
                onAdd={
                !disabled
                  ? () => {
                      setShowAddDialog(!showAddDialog)
                    }
                  : null
              }
                onSort={!disabled ? handleSort : null}
              />

              <Grid item xs={12}>
                {productCategories.map((cat, idx) => {
                  let checked = false
                  if (categories.includes(cat.id)) checked = true
                  return (
                    <Selection
                      disabled={disabled}
                      key={`rpc-${idx}`}
                      idx={idx}
                      selectedIdx={selectedIdx}
                      setSelectedIdx={setSelectedIdx}
                      category={cat}
                      checked={checked}
                      onCheck={props.product ? handleCheck : undefined}
                      onDelete={() => handleDelete(idx)}
                    />
                  )
                })}
              </Grid>
            </Box>
            )
          : null}

        {showAddDialog
          ? (
            <DialogEditCategory
              variant='new'
              title='Categories'
              categories={productCategories}
              onSave={() => {
                setSelectedIdx(-1)
                setShowAddDialog(!showAddDialog)
                // Logic for product editor only, not feature edit
                if (props.onProductFeaturesChange) {
                  props.onProductFeaturesChange('productCategories', productCategories)
                }
              }}
              onClose={() => setShowAddDialog(!showAddDialog)}
            />
            )
          : null}

        {selectedIdx > -1
          ? (
            <Box component='div' style={styles.boxScroll}>
              <Box component='div' style={styles.boxContainer}>
                <SubCategoryRecursion
                  key={`scr-${selectedIdx}`}
                  disabled={disabled}
                  categories={categories}
                  category={productCategories[selectedIdx]}
                  onCheck={variant === 'product-editor' ? handleCheck : undefined}
                  onProductFeaturesChange={() => {
                    if (props.onProductFeaturesChange) {
                      props.onProductFeaturesChange('productCategories', productCategories)
                    }
                  }}
                />
              </Box>
            </Box>
            )
          : null}
      </Grid>
      {!productCategories.length
        ? (
          <Grid container spacing={0} direction='row' alignItems='center' justifyContent='center' style={{ minHeight: '50vh' }}>
            <Stack spacing={2}>
              <Typography style={styles.stackItem}>There are no product categories.</Typography>
              <span style={styles.stackItem}>
                <Button variant='contained' onClick={() => setShowAddDialog(!showAddDialog)}>
                  Add One
                </Button>
              </span>
            </Stack>
          </Grid>
          )
        : null}
    </>
  )
}
export default RightPanelCategories

const SubCategoryRecursion = (props) => {
  const [selectedIdx, setSelectedIdx] = useState(-1)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [category, setCategory] = useState({})
  const [subCategories, setSubCategories] = useState([])

  useEffect(() => {
    if (selectedIdx > -1) {
      if (!props.category[selectedIdx]) {
        setSelectedIdx(-1)
      }
    }
    setCategory(props.category)
    setSubCategories(props.category.subCategories)
  }, []) // eslint-disable-line

  const handleDelete = (idx) => {
    if (props.category && props.category.subCategories) {
      const sc = JSON.parse(JSON.stringify(props.category.subCategories))
      if (sc[idx].custom) {
        // paranoid check
        sc.splice(idx, 1)
        props.category.subCategories = sc
        setSelectedIdx(-1)
        setCategory(props.category)
        setSubCategories(sc)
      }
    }
  }

  const handleSort = () => {
    if (props.category && props.category.subCategories) {
      const sc = JSON.parse(JSON.stringify(props.category.subCategories))
      sc.sort((a, b) => a.title.localeCompare(b.title))
      props.category.subCategories = sc
      setCategory(props.category)
      setSubCategories(sc)
    }
  }

  return (
    <React.Fragment key={`scr-${category.id}`}>
      <Box component='div' style={styles.gridContainer}>
        <SelectionHeader
          title={category.title}
          onAdd={
            !props.disabled
              ? () => {
                  setShowAddDialog(!showAddDialog)
                }
              : null
          }
          onSort={!props.disabled ? handleSort : null}
        />

        {subCategories.map((cat, idx) => {
          let checked = false
          if (props.categories.includes(cat.id)) checked = true
          return (
            <Selection
              key={`scr-${selectedIdx}-${idx}`}
              disabled={props.disabled}
              idx={idx}
              selectedIdx={selectedIdx}
              setSelectedIdx={setSelectedIdx}
              category={cat}
              checked={checked}
              onEdit={() => setShowEditDialog(!showEditDialog)}
              onCheck={props.onCheck}
              onDelete={() => handleDelete(idx)}
            />
          )
        })}
      </Box>

      {showAddDialog
        ? (
          <DialogEditCategory
            variant='new'
            title={category.title}
            categories={category.subCategories}
            onSave={() => {
              setSelectedIdx(-1)
              setShowAddDialog(!showAddDialog)
              props.onProductFeaturesChange()
            }}
            onClose={() => {
              setShowAddDialog(!showAddDialog)
            }}
          />
          )
        : null}

      {selectedIdx > -1
        ? (
          <Grid item>
            <Box container='div' style={styles.boxContainer}>
              <SubCategoryRecursion
                key={`scs-${selectedIdx}-${props.idx}`}
                disabled={props.disabled}
                categories={props.categories}
                category={subCategories[selectedIdx]}
                onCheck={props.onCheck}
                onProductFeaturesChange={props.onProductFeaturesChange}
              />
            </Box>
          </Grid>
          )
        : null}
    </React.Fragment>
  )
}

const Selection = (props) => {
  const [showEditDialog, setShowEditDialog] = useState(false)
  let selected = false
  if (props.selectedIdx === props.idx) selected = true
  return (
    <>
      <Grid container wrap='nowrap' sx={{ pl: 2, pt: 1, pr: 1, pb: 1 }} style={Object.assign({}, styles.selectionContainer, selected ? styles.selected : null)}>
        <Grid item xs={8}>
          <Grid container justifyContent='flex-start' wrap='nowrap'>
            {props.onCheck
              ? (
                <Grid item>
                  <FormGroup>
                    <FormControlLabel
                      sx={{ mb: 0, mr: 0 }}
                      disabled={props.disabled}
                      control={<Checkbox id={props.category.id} name={props.category.id} checked={props.checked} onClick={props.onCheck} />}
                      label=''
                    />
                  </FormGroup>
                </Grid>
                )
              : null}
            <Grid
              xs={11}
              item
              sx={{ pt: 1.25 }}
              style={styles.hideOverflow}
              onClick={() => {
                if (props.category.subCategories) {
                  if (props.selectedIdx === props.idx) {
                    props.setSelectedIdx(-1)
                  } else {
                    props.setSelectedIdx(props.idx)
                  }
                }
              }}
            >
              <Typography style={{ ...styles.hideOverflow, ...styles.textField }}>
                {props.category.title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} sx={{ ml: 2 }}>
          {!props.disabled
            ? (
              <IconButton onClick={() => setShowEditDialog(!showEditDialog)}>
                <EditIcon />
              </IconButton>
              )
            : null}
        </Grid>
        <Grid item xs={1} sx={{ ml: 2 }}>
          {props.category.subCategories
            ? (
              <IconButton
                onClick={() => {
                  if (props.selectedIdx === props.idx) {
                    props.setSelectedIdx(-1)
                  } else {
                    props.setSelectedIdx(props.idx)
                  }
                }}
              >
                {props.selectedIdx === props.idx ? <PlayCircleFilledIcon /> : <ArrowRightIcon />}
              </IconButton>
              )
            : null}
        </Grid>
      </Grid>

      {showEditDialog
        ? (
          <DialogEditCategory
            variant='edit'
            title={props.category.title}
            category={props.category}
            onSave={() => setShowEditDialog(!showEditDialog)}
            onClose={() => setShowEditDialog(!showEditDialog)}
            onDelete={
            props.category.custom
              ? () => {
                  setShowEditDialog(!showEditDialog)
                  props.onDelete()
                }
              : null
          }
          />
          )
        : null}
    </>
  )
}

const DialogEditCategory = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [title, setTitle] = useState(null)
  const [hasSubCategories, setHasSubCategories] = useState(false)

  useEffect(() => {
    if (isLoading) {
      if (props.variant === 'edit') {
        setTitle(props.category.title)
      } else if (props.variant === 'new') {
        if (props.category && props.category.subCategories) {
          setHasSubCategories(true)
        }
      }
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleSave = (e) => {
    if (props.variant === 'new') {
      const guid = Helper.guid()
      const cat = {
        id: guid,
        title: title || 'No title',
        custom: true // To track in case we want to prevent edits on non-custom values
      }
      if (hasSubCategories) {
        cat.subCategories = []
      }
      props.categories.unshift(cat)
    } else {
      const t = title || 'No title'
      setTitle(t)
      props.category.title = t
    }
    props.onSave()
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'title') {
      setTitle(value)
    } else if (name === 'subcats') {
      setHasSubCategories(!hasSubCategories)
    }
  }

  if (isLoading) return <LoadingPleaseWait />

  const jsx = (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          id='title'
          name='title'
          variant='outlined'
          label='Title'
          helperText='Please enter the title.'
          value={title || ''}
          onChange={handleChange}
        />
      </Grid>
      {props.variant === 'new'
        ? (
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox id='subcats' name='subcats' checked={hasSubCategories} onClick={handleChange} />}
                label={<Typography style={styles.textField}>Will Include Sub-Categories</Typography>}
              />
            </FormGroup>
          </Grid>
          )
        : null}
    </Grid>
  )

  return (
    <DialogGeneric
      title={props.variant === 'edit' ? 'Edit Category' : 'Add New Category'}
      content={jsx}
      fullWidth
      onChange={handleSave}
      onClose={props.onClose}
      onDelete={props.onDelete ? props.onDelete : null}
    />
  )
}

const styles = {
  gridContainer: {
    width: 300,
    minWidth: 300
  },
  boxContainer: {
    flex: 1,
    display: 'flex',
    alignContent: 'flex-start',
    overflow: 'auto'
  },
  boxScroll: {
    overflow: 'auto'
  },
  selectionContainer: {
    border: '1px solid rgba(0,0,0,.16)',
    marginTop: -1,
    backgroundColor: 'white',
    userSelect: 'none',
    WebkitUserSelect: 'none'
  },
  hideOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  textField: {
    fontSize: 14
  },
  selected: {
    backgroundColor: '#e7f0fc'
  },
  stackItem: {
    textAlign: 'center'
  }
}
