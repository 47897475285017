import React, { useState, useEffect } from 'react'
import { Box, ListItem, ListItemButton, ListItemText, Paper, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import Api, { ApiBase } from 'library/Api'
import Company from '../Company'
import Permissions from 'library/Permissions'
import withScreenWrapper from '../withScreenWrapper'
import { LoadingPleaseWait } from '@supportworks/react-components'

const configTags = [
  {
    section: 'Features',
    options: [
      { title: 'Calendar', variant: 'calendar', superUserOnly: true, adminIcon: false },
      { title: 'Product Features', variant: 'productFeatures', superUserOnly: false, adminIcon: true }
    ]
  },
  {
    section: 'Dictionary Lists',
    options: [
      { title: 'Numeric Units', variant: 'numericUnits', superUserOnly: false, adminIcon: false }
    ]
  },
  {
    section: 'User Experience',
    options: [{ title: 'Display Settings', variant: 'displaySettings', superUserOnly: true, adminIcon: true }]
  }
]

const CompanyConfigSettings = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  // eslint-disable-next-line no-unused-vars
  const [config, setConfig] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [unPublishedConfigCounts, setUnPublishedConfigCounts] = useState([]) // FUTURE???
  // eslint-disable-next-line no-unused-vars
  const [publishedConfigCounts, setPublishedConfigCounts] = useState([]) // FUTURE???

  useEffect(() => {
    if (isLoading) {
      // This whole section was ripped out of Dashboard, TBD if we want to try and display Published/not next to things that can be. PSB 10/04
      Promise.all([
        Api.callRegisteredMethod('getCurrentConfig', { tag: 'all', startsWith: false }).then((config) => {
          setConfig(config)
        }),
        Api.callRegisteredMethod('getConfigList', {}).then((configs) => {
          if (configs) {
            const publishedConfigCounts = {}
            const unPublishedConfigCounts = {}
            configs
              .filter((c) => c.status === 'Published')
              .map((c) => {
                if (!(c.tag in publishedConfigCounts)) publishedConfigCounts[c.tag] = 0
                publishedConfigCounts[c.tag] += 1
                return { tag: c.tag, value: c.configId, name: c.name }
              })
            configs
              .filter((c) => c.status === 'Unpublished')
              .map((c) => {
                if (!(c.tag in unPublishedConfigCounts)) unPublishedConfigCounts[c.tag] = 0
                unPublishedConfigCounts[c.tag] += 1
                return { tag: c.tag, value: c.configId, name: c.name }
              })
            setUnPublishedConfigCounts(unPublishedConfigCounts)
            setPublishedConfigCounts(publishedConfigCounts)
          }
        })
      ])
        .then((results) => {
          setIsLoading(false)
        })
        .catch((err) => console.log(err))
    }
    // eslint-disable-next-line
  }, [])

  if (isLoading) {
    return <LoadingPleaseWait />
  }

  return (
    <>
      <Box>
        <Company.Header title='General Application Settings' />
        <Box sx={{ flexGrow: 1, pt: 1 }}>
          {configTags.map((main, idx1) => {
            // Do I have any non-super user stuff in this list?
            const cnt = main.options.filter((o) => o.superUserOnly === false)
            if (!cnt.length && !Permissions.hasRole('super_user')) {
              // eslint-disable-next-line react/jsx-key
              return <></>
            }

            return (
              <Paper key={`Paper-${idx1}`} elevation={1} spacing={1} sx={{ mb: 2, p: 2 }}>
                <Typography variant='h6' sx={{ pb: 2 }}>
                  {main.section}
                </Typography>

                {main.options.map((selection, idx2) => {
                  let to
                  if (selection.variant) {
                    to = ApiBase.resolvePath('/Company/:organizationId/Configs?variant=' + selection.variant)
                  } else {
                    to = ApiBase.resolvePath(`/Company/:organizationId/Configs/${selection.path}`)
                  }

                  if (Permissions.hasRole('super_user')) {
                    return (
                      <ListItem key={`li-${idx1}-${idx2}`} id={`li-${idx1}-${idx2}`} button style={styles.listItem} disablePadding component={Link} to={to}>
                        <ListItemButton role={undefined}>
                          <ListItemText>
                            <span>
                              {selection.title} {selection.superUserOnly}
                              {selection.superUserOnly && selection.adminIcon ? <AdminPanelSettingsIcon style={styles.icon2} fontSize='small' /> : null}
                            </span>
                          </ListItemText>
                          <KeyboardArrowRightIcon style={styles.icon1} fontSize='small' />
                        </ListItemButton>
                      </ListItem>
                    )
                  }
                  if (!Permissions.hasRole('super_user') && !selection.superUserOnly) {
                    return (
                      <ListItem key={`li-${idx1}-${idx2}`} id={`li-${idx1}-${idx2}`} button style={styles.listItem} disablePadding component={Link} to={to}>
                        <ListItemButton role={undefined}>
                          <ListItemText>
                            <span>
                              {selection.title} {selection.superUserOnly}
                              {selection.superUserOnly && selection.adminIcon ? <AdminPanelSettingsIcon style={styles.icon2} fontSize='small' /> : null}
                            </span>
                          </ListItemText>
                          <KeyboardArrowRightIcon style={styles.icon1} fontSize='small' />
                        </ListItemButton>
                      </ListItem>
                    )
                  }
                  return null
                })}
              </Paper>
            )
          })}
        </Box>
      </Box>
    </>
  )
}
export default withScreenWrapper(CompanyConfigSettings)

const styles = {
  container: {
    marginTop: 16
  },
  listItem: {
    marginBottom: 16,
    backgroundColor: '#FAFAFA'
  },
  icon1: {
    color: 'black'
  },
  icon2: {
    color: 'rgba(0,0,0,.24)'
  }
}
