import React, { useEffect, useState } from 'react'
import { DialogGeneric } from '../../../components/Reusable/DialogGeneric'
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Switch,
  FormControlLabel
} from '@mui/material'

export const Taxes = (props) => {
  const [showDialog, setShowDialog] = useState(props.open)
  const [label, setLabel] = useState('')
  const [amount, setAmount] = useState(0)
  const [crmId, setCrmId] = useState('')
  const [crmName, setCrmName] = useState('')
  const [userEdit, setUserEdit] = useState(false)

  const closeHandler = () => {
    setShowDialog(!showDialog)
    props.onClose()
  }

  const handleSave = () => {
    const newTaxConfig = props.taxConfig

    newTaxConfig.label = label
    newTaxConfig.amount = amount
    newTaxConfig.editable = userEdit

    if (crmId !== '') {
      newTaxConfig.crmID = crmId // YES, this is crmID for the CRM.  DEV-4855
      newTaxConfig.crmName = crmName
    }
    props.onSave(newTaxConfig)
  }

  const changeHandler = (e) => {
    if (e.target.name === 'label') {
      setLabel(e.target.value)
    }
    if (e.target.name === 'amount') {
      setAmount(e.target.value)
    }
  }

  const changeHandlerSelect = (e) => {
    setCrmId(e.target.value)
    setCrmName(e.target.name)
  }

  const onChangeAllowUserEdit = (e) => {
    setUserEdit(!userEdit)
  }

  useEffect(() => {
    setLabel(props.taxConfig.label)
    setAmount(props.taxConfig.amount)

    if ('crmID' in props.taxConfig) {
      setCrmId(props.taxConfig.crmID) // YES, this is crmID for the CRM.  DEV-4855
    }

    if ('editable' in props.taxConfig) {
      setUserEdit(props.taxConfig.editable)
    }
  }, [])

  const jsx = (
    <Box sx={{ width: '440px' }}>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id='label'
            name='label'
            variant='outlined'
            label='Label'
            onChange={changeHandler}
            value={label}
            size='small'
            sx={{
              marginTop: '16px'
            }}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <FormControl sx={{ mt: 1 }}>
            <FormControlLabel
              sx={{ ml: 0 }}
              label={
                <Typography variant='body2' sx={{ flex: 1, fontSize: '1rem', color: '#000000a3' }}>
                  Allow User to Edit
                </Typography>
                }
              labelPlacement='start'
              value='start'
              control={
                <Switch
                  color='primary'
                  sx={{ m: 0 }}
                  checked={userEdit}
                  onChange={onChangeAllowUserEdit}
                />
                }
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id='amount'
            name='amount'
            variant='outlined'
            label='Amount'
            onChange={changeHandler}
            value={amount}
            size='small'
            sx={{
              marginTop: '16px'
            }}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <FormControl sx={{ marginTop: '16px', marginBottom: '5px' }} size='small' fullWidth>
            <InputLabel id='demo-simple-select-label'>Crm Tax Rate</InputLabel>
            <Select labelId='crmTaxRate' id='crmTaxRate' value={crmId} size='small' label='Crm Tax Rate' onChange={changeHandlerSelect}>
              {props.taxesList.map((option, idx) => {
                console.log(option)
                return (
                  <MenuItem key={idx} value={option.value}>
                    {option.crmName}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )

  return (
    <>
      {showDialog && (
        <DialogGeneric
          title='Tax Rates'
          onClose={() => closeHandler()}
          content={jsx}
          // fullWidth={"50%"}
          onChange={handleSave}
        />
      )}
    </>
  )
}
