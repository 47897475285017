import React, { useState, useEffect } from 'react'
import { Grid, TextField, Typography, InputAdornment } from '@mui/material'
import { LoadingPleaseWait } from '@supportworks/react-components'

const RightPanelEstimateProject = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [product, setProduct] = useState({}) // For object manipulation
  const [hoursPerJob, setHoursPerJob] = useState(null)
  const [hoursPerUnit, setHoursPerUnit] = useState(null)

  useEffect(() => {
    if (isLoading) {
      const p = _initialize(props.product)
      if (p.pricingOptions.eplh.hoursPerJob) {
        setHoursPerJob(p.pricingOptions.eplh.hoursPerJob)
      }
      if (p.pricingOptions.eplh.hoursPerUnit) {
        setHoursPerUnit(p.pricingOptions.eplh.hoursPerUnit)
      }
      setProduct(p)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    setProduct(props.product)
    try {
      if (props.product.pricingOptions.eplh.hoursPerJob) {
        setHoursPerJob(props.product.pricingOptions.eplh.hoursPerJob)
      }
      if (props.product.pricingOptions.eplh.hoursPerUnit > -1) {
        setHoursPerUnit(props.product.pricingOptions.eplh.hoursPerUnit)
      }
    } catch (err) {
      // Avoiding a crappy multi-line if conditional for the nested JSON
    }
  }, [props.product])

  const handleChange = (e) => {
    const p = _initialize(product)
    const name = e.target.name
    const value = Number(e.target.value)
    if (value) {
      p.pricingOptions.eplh[name] = value
    } else {
      delete p.pricingOptions.eplh[name]
    }
    if (name === 'hoursPerJob') setHoursPerJob(value)
    if (name === 'hoursPerUnit') setHoursPerUnit(value)
    setProduct(p)
    props.setProduct(p)
  }

  const _initialize = (product) => {
    const p = JSON.parse(JSON.stringify(product))
    if (!p.pricingOptions) p.pricingOptions = {}
    if (!p.pricingOptions.eplh) p.pricingOptions.eplh = {}
    return p
  }

  if (isLoading) return <LoadingPleaseWait />

  let disabled = false
  if (props.variant === 'pricing' && !product.eplh_ovrd) {
    disabled = true
  }

  return (
    <Grid container style={styles.gridContainer}>
      <Grid item xs={12} sx={{ p: 2, borderBottom: '1px solid rgba(0,0,0,.16)' }}>
        <Typography style={styles.title}>Estimated Project Labor Hours</Typography>
      </Grid>
      {props.displaySettings && (props.displaySettings.showEplhHoursPerJob)
        ? (
          <Grid item xs={12} sx={{ p: 2 }}>
            <TextField
              id='hoursPerJob'
              name='hoursPerJob'
              disabled={disabled}
              type='number'
              variant='outlined'
              label={<Typography style={styles.textField}>Fixed Hours per Job</Typography>}
              value={hoursPerJob || ''}
              InputProps={{
                style: styles.textField,
                endAdornment: (
                  <InputAdornment position='end'>
                    <Typography>hour(s)</Typography>
                  </InputAdornment>
                )
              }}
              onChange={handleChange}
              sx={{ 'input:focus': { border: 'none' } }}
            />
          </Grid>
          )
        : null}
      {props.displaySettings && (props.displaySettings.showEplhHoursPerUnit)
        ? (
          <Grid item xs={12} sx={{ p: 2 }}>
            <TextField
              id='hoursPerUnit'
              name='hoursPerUnit'
              disabled={disabled}
              type='number'
              variant='outlined'
              label={<Typography style={styles.textField}>Install Hours per Unit</Typography>}
              value={hoursPerUnit || ''}
              InputProps={{
                style: styles.textField,
                endAdornment: (
                  <InputAdornment position='end'>
                    <Typography>hour(s)</Typography>
                  </InputAdornment>
                )
              }}
              onChange={handleChange}
              sx={{ 'input:focus': { border: 'none' } }}
            />
          </Grid>
          )
        : null}
    </Grid>
  )
}
export default RightPanelEstimateProject

const styles = {
  gridContainer: {
    backgroundColor: 'white'
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  textField: {
    fontSize: 14
  }
}
