import React, { useState, useEffect } from 'react'
import { Autocomplete, Box, Grid, IconButton, TextField, Typography } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DataGrid from 'components/Reusable/DataGrid'
import { LoadingPleaseWait } from '@supportworks/react-components'

const AssembleMaterials = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState([]) // For screen manipulation
  const [rows, setRows] = useState([]) // For DataGrid
  const [materialsTotal, setMaterialsTotal] = useState(0)

  useEffect(() => {
    if (isLoading) {
      updateList('load', props.product.pricingOptions.assembly.materials)
      setIsLoading(false)
    }
  }, []) // eslint-disable-line

  const handleAdd = () => {
    const l = [...list, { id: '', qty: 0 }]
    updateList('add', l)
  }

  const handleDelete = (idx) => {
    const l = [...list]
    l.splice(idx, 1)
    updateList('delete', l)
  }

  const handleMoveUp = (idx) => {
    if (idx > 0) {
      const l = [...list];
      [l[idx - 1], l[idx]] = [l[idx], l[idx - 1]]
      updateList('move', l)
    }
  }

  const handleMoveDown = (idx) => {
    if (idx < list.length - 1) {
      const l = [...list];
      [l[idx], l[idx + 1]] = [l[idx + 1], l[idx]]
      updateList('move', l)
    }
  }

  const handleProcessRowUpdate = (row) => {
    if (row) {
      const l = JSON.parse(JSON.stringify(list))
      const master = props.config.find((o) => o.id === row.recordId)
      if (master !== undefined) {
        l[row.id].id = row.recordId
        l[row.id].qty = row.qty
        l[row.id].units = master.units
        l[row.id].price = master.price
        l[row.id].total = row.qty * master.price
        row.total = l[row.id].total
      } else {
        console.log(`Must have bad data for ${row.recordId} - could not find master.`)
      }
      updateList('change', l)
      return row // Return row, so the screen updates!
    }
  }

  const handleChange = (index, id) => {
    const l = JSON.parse(JSON.stringify(list))
    l[index].id = id
    updateList('change', l)
  }

  const updateList = (variant, updatedList) => {
    // Build the rows for the DataGrid
    const rows = []
    let materialsTotal = 0
    for (let x = 0; x < updatedList.length; x++) {
      let total = 0
      let price = 0
      let units = ''
      let title = updatedList[x].title
      const master = props.config.find((o) => o.id === updatedList[x].id)
      if (master !== undefined) {
        total = updatedList[x].qty * master.price
        materialsTotal = materialsTotal + total
        price = master.price
        units = master.units
        title = master.title
      }
      const row = {
        id: x,
        recordId: updatedList[x].id,
        qty: updatedList[x].qty,
        title,
        price,
        units
      }
      rows.push(row)
    }
    setRows(rows)
    setList(updatedList)
    const p = JSON.parse(JSON.stringify(props.product))
    p.pricingOptions.assembly.materials = updatedList
    // Avoid updates when loading
    if (variant !== 'load') {
      props.setProduct(p)
    }
    setMaterialsTotal(materialsTotal)
    props.setMaterialsTotal(materialsTotal) // Must be last to force update
  }

  if (isLoading) return <LoadingPleaseWait />

  // Build the columns for the DataGrid
  const columns = [
    {
      flex: 3,
      type: 'string',
      field: 'recordId',
      headerName: 'Material',
      // editable: true, // This kills Autocomplete!!!! Do not use -pbaker 08/10/23
      sortable: true,
      renderCell: (params) => {
        if (params.row.recordId === 'totals') {
          return (
            <Box>
              <Typography variant='body2'>Total is the sum of all totals</Typography>
            </Box>
          )
        }

        // Build the list of options
        let options = props.config
          .filter((p) => !list.some((item) => item.id === p.id)) // Filter out existing IDs
          .map((p) => {
            return { id: p.id, title: p.title, label: p.title, price: p.price }
          })
        options.unshift({ id: params.row.id, title: params.row.title, label: params.row.title, price: params.row.price }) // Add the current row to the list
        options = options.sort((a, b) => a.title.localeCompare(b.title))

        return (
          <Box
            sx={{ width: 1 }}
            onKeyDown={(event) => {
              if (event.key === ' ' || event.key.match(/ArrowDown|ArrowUp/)) {
                event.stopPropagation()
              }
            }}
          >
            <Autocomplete
              id='material-autocomplete'
              size='small'
              value={params.row.title}
              options={options}
              onChange={(e, newValue) => {
                handleChange(params.row.id, newValue?.id || -1)
              }}
              renderInput={(params) => <TextField {...params} />}
              renderOption={(props, option) => {
                return (
                  <Box
                    {...props}
                    key={`key-${option.id}`}
                    component='li'
                    sx={{
                      borderBottom: '1px solid rgba(0, 0, 0, 0.08)' // Add bottom border
                    }}
                  >
                    <Typography variant='body2'>{option.title} (${Number(option.price).toFixed(2)})</Typography>
                  </Box>
                )
              }}
              isOptionEqualToValue={(option, value) => {
                if (value === '') return true
                return option.title === value
              }}
            />
          </Box>
        )
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'qty',
      headerName: 'Quantity',
      editable: true,
      sortable: true,
      preProcessEditCellProps: (params) => {
        const isValid = !isNaN(params.props.value)
        return { ...params.props, error: !isValid }
      },
      renderCell: (params) => {
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>{params.value}</Box>
      }
    },
    {
      flex: 1,
      type: 'number',
      field: 'total',
      headerName: 'Total',
      editable: false,
      sortable: true,
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AttachMoneyIcon fontSize='small' sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            {Number(params.row.qty * params.row.price).toFixed(2) || 0}
          </Box>
        )
      }
    },
    {
      flex: 1,
      field: 'action',
      headerName: 'Actions',
      headerAlign: 'right',
      align: 'right',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        if (params.row) {
          return (
            <Grid container wrap='nowrap' justifyContent='flex-end'>
              <Grid item>
                <IconButton size='small' disabled={params.row.id === 0} onClick={() => handleMoveUp(params.row.id)}>
                  <KeyboardArrowUpIcon fontSize='small' />
                </IconButton>
              </Grid>
              <IconButton size='small' disabled={!(params.row.id < list.length - 1)} onClick={() => handleMoveDown(params.row.id)}>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
              <Grid item>
                <IconButton size='small' onClick={() => handleDelete(params.row.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]

  return (
    <Box>
      <Box>
        <Typography sx={{ fontWeight: 'bold', pb: 1 }}>Materials</Typography>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        defaultPageSize={100}
        onAdd={handleAdd}
        onProcessRowUpdate={(row) => {
          handleProcessRowUpdate(row)
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: true
            }
          }
        }}
      />
      <Grid container style={styles.materialsTotalContainer}>
        <Grid item style={styles.materialsTotalDescription}>
          <Typography variant='body2' style={{ fontWeight: 'bolder' }}>
            Totals
          </Typography>
        </Grid>
        <Grid item style={styles.materialsTotalFiller}>
          &nbsp;
        </Grid>
        <Grid item style={styles.materialsTotalAmount}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AttachMoneyIcon fontSize='small' sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <Typography variant='body2' style={{ fontWeight: 'bolder' }}>
              {materialsTotal.toFixed(2)}
            </Typography>
          </Box>
        </Grid>
        <Grid item style={styles.materialsTotalAction}>
          <IconButton size='small' onClick={handleAdd} sx={{ mr: 1 }}>
            <AddCircleOutlineIcon fontSize='small' />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  )
}
export default AssembleMaterials

const styles = {
  materialsTotalContainer: {
    borderLeft: '1px solid rgba(0,0,0,.16)',
    borderBottom: '1px solid rgba(0,0,0,.16)',
    borderRight: '1px solid rgba(0,0,0,.16)',
    backgroundColor: '#f5f5f5'
  },
  materialsTotalDescription: {
    display: 'flex',
    flex: 3,
    paddingLeft: '12px',
    paddingTop: '12px',
    paddingBottom: '12px',
    alignItems: 'center'
  },
  materialsTotalFiller: {
    display: 'flex',
    flex: 1,
    borderRight: '1px solid rgba(0,0,0,.16)',
    borderLeft: '1px solid rgba(0,0,0,.16)'
  },
  materialsTotalAmount: {
    display: 'flex',
    flex: 1,
    paddingRight: '12px',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  materialsTotalAction: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderLeft: '1px solid rgba(0,0,0,.16)'
  }
}
