import React from 'react'
import { ModalOverlay } from './ModalOverlay'

export default class HelpSystem extends React.Component {
  render () {
    return (
      <span
        className={'HelpSystem ' + (this.props.className ? this.props.className : 'badge badge-info')}
        onClick={() => {
          ModalOverlay.setContent(
            typeof this.props.content === 'function' ? this.props.content() : this.props.content,
            { title: this.props.title }
          )
        }}
      >
        {this.props.icon ? this.props.icon : <i className='fas fa-question' />}
      </span>
    )
  }
}
