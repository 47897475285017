import React, { useEffect, useState } from 'react'
import FieldTypeAddLogicPanel from '../FieldTypeAddLogicPanel'
import FieldTypeExpandMarker from '../Reusables/FieldTypeExpandMarker'
// import AddLogic from '../AddLogic'
import { FormControl, InputLabel, MenuItem, Paper, Select } from '@mui/material'
import TextInputBasic from '../Reusables/TextInputBasic'
// import RelatedFieldTypeEditor from '../Reusables/RelatedFieldTypeEditor'

function AreaCheckbox (props) {
  // const [field, setField] = useState(props.field)
  const [areaType, setAreaType] = useState('areaType' in props ? props.field.areaType : '')
  const [subTitle, setSubTitle] = useState('')

  const changeHandlerVisible = (visible) => {
    props.onChangeVisible(visible)
  }

  const changeHandlerAreaType = (e) => {
    const newField = JSON.parse(JSON.stringify(props.field))

    const form = props.formsConfig.filter((f, idx) => {
      return f.id === e.target.value
    })

    newField.areaType = e.target.value
    newField.areaTitle = form[0].formTitle
    setAreaType(e.target.value)
    setSubTitle(form[0].formTitle)
    props.onChange(newField)
  }

  const changeHandlerSubTitle = (val) => {
    const newField = JSON.parse(JSON.stringify(props.field))
    newField.areaTitle = val
    setSubTitle(val)
    props.onChange(newField)
  }

  /* useEffect(() => {
    setField(props.field)
  }, [props.field]) */

  useEffect(() => {
    setAreaType(props.field.areaType)
    setSubTitle(props.field.areaTitle)
  }, [props.field])

  return (
    <>
      {
                props.expandMarker &&
                  <FieldTypeExpandMarker />
            }

      <div style={styles.logicPanel}>
        <FieldTypeAddLogicPanel
          field={props.field}
          onChangeVisible={(visible) => changeHandlerVisible(visible)}
        />
        <div style={{ display: 'flex' }}>
          <FieldTypeExpandMarker />
          <Paper elevation={0} square style={styles.addOptionPaper}>
            <div style={styles.areaTypeLabel}>Form Title</div>
            <div style={styles.optionsMainDiv}>
              <TextInputBasic
                placeholder='Enter Title For Added Form Here'
                inputValue={subTitle || ''}
                onChange={(value) => changeHandlerSubTitle(value)}
              />
            </div>
          </Paper>
        </div>
        <div style={{ display: 'flex' }}>
          <FieldTypeExpandMarker />
          <Paper elevation={0} square style={styles.addOptionPaper}>
            {/* <div style={styles.areaTypeLabel}>Area Type</div>
                        <div style={styles.optionsMainDiv}>
                            <TextInputBasic
                                placeholder={'Enter Area Type Here'}
                                inputValue={areaType || ""}
                                onChange={(value) => changeHandlerAreaType(value)}
                            />
                        </div> */}
            <FormControl sx={{ marginLeft: 3, width: '350px' }}>
              <InputLabel sx={{ backgroundColor: '#F4F4F4', lineHeight: '15px' }}>Area Type</InputLabel>
              <Select
                labelId='areaType'
                id='areaType'
                name='areaType'
                size='small'
                label='Area Type'
                value={areaType}
                onChange={changeHandlerAreaType}
              >
                {
                                    'formsConfig' in props &&
                                    props.formsConfig.map((f, idx) => {
                                      return (
                                        <MenuItem
                                          key={idx}
                                          value={f.id}
                                        >
                                          {f.formTitle}
                                        </MenuItem>
                                      )
                                    })
                                }
              </Select>
            </FormControl>
          </Paper>
        </div>
      </div>
    </>
  )
}

const styles = {
  relatedMain: {
    display: 'flex'
  },
  logicPanel: {
    // width: "100%"
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%'
  },
  singleSelectionPaper: {
    borderTop: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    borderLeft: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    // marginRight: "96.19px",
    // width: "100%"
    height: '50px',
    flexBasis: '100%',
    paddingRight: '5px'
  },
  addOptionPaper: {
    borderTop: '1px solid #0000003b',
    borderRight: '1px solid #0000003b',
    borderBottom: '1px solid #0000003b',
    borderLeft: '1px solid #0000003b',
    fontSize: '13px',
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    // marginRight: "96.19px",
    // width: "100%",
    height: '70px',
    flexBasis: '100%',
    paddingRight: '5px'
  },
  addOptionBtn: {
    textTransform: 'none',
    marginLeft: '5px',
    fontSize: '13px'
  },
  addOptionIconBtn: {
    padding: '8px'
  },
  addOptionIcon: {
    fontSize: '20px'
  },
  areaTypeLabel: {
    fontSize: '13px',
    color: '#000000a3',
    marginLeft: '10px',
    marginRight: '5px',
    width: '10%'
  },
  optionsMainDiv: {
    display: 'flex',
    flexBasis: '100%'
  },
  optionsMainDivIcon: {
    marginLeft: '12px',
    whiteSpace: 'nowrap'
  }
}
export default AreaCheckbox
