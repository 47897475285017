import React from 'react'
import { Box, Grid, Dialog, DialogTitle, DialogContent, Button, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export const DialogGeneric = ({
  title,
  subTitle,
  content,
  open = true,
  titleDone = 'Done',
  titleClose = 'Close',
  fullScreen = false,
  fullWidth = false,
  maxWidth = 'md',
  showBackArrow = false,
  onClose,
  onChange,
  onDelete
}) => {
  return (
    <Dialog open={open} fullScreen={fullScreen} fullWidth={fullWidth} maxWidth={maxWidth} onClose={onClose}>
      <DialogTitle className='dialog-title' sx={{ pl: 0 }}>
        <Grid container alignItems='center'>
          {showBackArrow
            ? (
              <Grid item sx={{ pl: 1 }}>
                <IconButton title='Previous' aria-label='Previous' onClick={onClose}>
                  <ArrowBackIcon />
                </IconButton>
              </Grid>
              )
            : null}
          <Grid item sx={{ pl: 2 }}>
            <Typography variant='h6' style={{ height: '32px' }}>
              {title || null}
            </Typography>
            <Typography variant='body2'>{subTitle || null}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              className='dialog-close'
              aria-label='close'
              onClick={() => {
                onClose()
              }}
              sx={{ position: 'absolute', right: 8, top: 12 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ pl: 2, pr: 2, ml: 0 }}>
        <Box sx={{ pt: 2, pl: 0, pr: 0 }}>{content}</Box>
      </DialogContent>
      <Box style={styles.bottomNavigation}>
        <Button id='cancel' variant='outlined' color='primary' sx={{ mr: 2 }} onClick={onClose}>
          {titleClose}
        </Button>
        {onDelete
          ? (
            <Button id='delete' variant='outlined' color='primary' sx={{ mr: 2 }} onClick={onDelete}>
              Delete
            </Button>
            )
          : null}
        {onChange
          ? (
            <Button id='save' variant='contained' color='primary' sx={{ marginLeft: 'auto' }} onClick={onChange}>
              {titleDone}
            </Button>
            )
          : null}
      </Box>
    </Dialog>
  )
}

const styles = {
  bottomNavigation: {
    display: 'flex',
    width: '100%',
    padding: '16px',
    borderTop: '1px solid rgba(0,0,0,.16)',
    backgroundColor: 'white'
  }
}
