import LocalStorage from 'library/LocalStorage'
import Config from 'config'
import Api from 'library/Api'

export default class Permissions {
  /**
   *
   * @param screen
   * @param accessType
   * @return {boolean}
   */
  static canAccessScreen (screen, accessType) {
    const myOrgs = LocalStorage.retrieve(Config.myOrgsKey)
    const currentOrgId = LocalStorage.retrieve(Config.currentOrgId)
    let accessControl = null
    if (myOrgs && myOrgs.length) {
      for (const org of myOrgs) {
        if (org.id === currentOrgId) {
          if ('appConfig' in org && 'accessControl' in org.appConfig) {
            accessControl = org.appConfig.accessControl
          }
        }
      }
    }
    if (accessControl === null) {
      return true
    }
    for (const ac of accessControl) {
      if (ac.screen === screen) {
        if (accessType === 'read' && 'readRequiredRoles' in ac) {
          for (const r of ac.readRequiredRoles) {
            if (Permissions.companyIsSelected() && Permissions.hasRole(r)) {
              return true
            }
          }
          return false
        }
        if (accessType === 'write' && 'writeRequiredRoles' in ac) {
          for (const r of ac.writeRequiredRoles) {
            if (Permissions.companyIsSelected() && Permissions.hasRole(r)) {
              return true
            }
          }
          return false
        }
      }
    }
    return true
  }

  /**
   *
   * @param screen
   * @param field
   * @param accessType
   * @return {boolean}
   */
  static canAccessScreenField (screen, field, accessType) {
    const myOrgs = LocalStorage.retrieve(Config.myOrgsKey)
    const currentOrgId = LocalStorage.retrieve(Config.currentOrgId)
    let accessControl = null
    if (myOrgs && myOrgs.length) {
      for (const org of myOrgs) {
        if (org.id === currentOrgId) {
          if ('appConfig' in org && 'accessControl' in org.appConfig) {
            accessControl = org.appConfig.accessControl
          }
        }
      }
    }
    if (accessControl == null) {
      return true
    }
    for (const ac of accessControl) {
      if (ac.screen === screen) {
        if ('fields' in ac) {
          for (const f of ac.fields) {
            if (f.field === field) {
              if (accessType === 'read' && 'readRequiredRoles' in f) {
                for (const r of f.readRequiredRoles) {
                  if (Permissions.companyIsSelected() && Permissions.hasRole(r)) {
                    return true
                  }
                }
                return false
              }
              if (accessType === 'write' && 'writeRequiredRoles' in f) {
                for (const r of f.writeRequiredRoles) {
                  if (Permissions.companyIsSelected() && Permissions.hasRole(r)) {
                    return true
                  }
                }
                return false
              }
            }
          }
        }
      }
    }
    return true
  }

  /**
   *
   * @param {string} role
   * @param {string} appShortCode
   * @return {boolean}
   */
  static hasRole (role, appShortCode = null) {
    if (!this.companyIsSelected()) {
      return false
    }
    try {
      return Api.callSyncRegisteredMethod('hasRole', {
        // orgId: LocalStorage.retrieve(Config.currentOrgId),
        role,
        appShortCode
      })
    } catch (e) {
      console.log('WARNING!')
      console.warn(e)
    }
    return false
  }

  /**
   *
   * @param {string} permission
   * @param {boolean} defaultValue
   * @param {string} appShortCode
   * @return {boolean}
   */
  static hasPermission (permission, defaultValue = false, appShortCode = null) {
    if (!this.companyIsSelected()) {
      return defaultValue
    }
    try {
      return Api.callSyncRegisteredMethod('hasPermission', {
        permission,
        defaultValue,
        appShortCode
      })
    } catch (e) {
      console.warn(e)
    }

    return defaultValue
  }

  /**
   * Tests whether or not the current user has rights to edit a permission.
   *
   * @param {string} permission name of permission to check
   * @param {string} appShortCode App Identifier (shortcode)
   */
  static canEditPermission (permission, appShortCode) {
    return Api.callRegisteredMethod('canEditPermission', {
      orgId: LocalStorage.retrieve(Config.currentOrgId),
      permission,
      appShortCode
    })
  }

  /**
   * Whether a company is selected.
   * @return {boolean}
   */
  static companyIsSelected () {
    return Api.callSyncRegisteredMethod('companyIsSelected')
  }

  /**
   * Tests whether or not the current user can create a user on the Company/User screen.
   *
   * @return {boolean}
   */
  static canCreateUser () {
    const myOrgs = LocalStorage.retrieve(Config.myOrgsKey)
    const currentOrgId = LocalStorage.retrieve(Config.currentOrgId)
    if (myOrgs && myOrgs.length) {
      const org = myOrgs.find((o) => o.id === currentOrgId)
      if (org && 'canCreateUser' in org) {
        return org.canCreateUser
      } else {
        return false
      }
    }
  }
}
