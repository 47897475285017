import React, { useState, useEffect, useRef } from 'react'
import { Tooltip } from '@mui/material'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Permissions from '../../../../../library/Permissions'
import { findInForm } from '../PDFDocumentEditor'
import { HelperHLine, HelperVLine, Vertice, calculateOffset } from './Field'

const Standard = (props) => {
  const [dimensions, setDimensions] = useState()
  const [showJSON, setShowJSON] = useState(false)
  const fieldRef = useRef()

  useEffect(() => {
    if (fieldRef.current) {
      setDimensions(fieldRef.current.getBoundingClientRect())
    }
  }, [fieldRef])

  useEffect(() => {
    if (dimensions && dimensions.height && dimensions.width) {
      if (!props.field.width) {
        props.field.width = dimensions.width
        props.field.height = dimensions.height
        props.onChange('field', props.field)
      }
    }
    // eslint-disable-next-line
  }, [dimensions])

  const handleMoveVertex = (variant) => {
    props.onMoveVertex(variant)
  }

  const handleRightClick = (e) => {
    e.preventDefault()
    console.log(props.field)
    console.log('We are not there yeti.')
  }

  const field = props.field
  let [x, y] = [0, 0];
  [x, y] = calculateOffset(field.x, field.y, props.zoom, field.choice ? 'selection' : null)

  let variant = props.variant
  if (!field.choice && !findInForm(props.sforms, field) && !findInForm(props.forms, field)) {
    variant = 'zombie'
  }

  return (
    <>
      {props.helperHLine ? <HelperHLine y={y} field={field} {...props} /> : null}
      {props.helperVLine ? <HelperVLine x={x} dimensions={dimensions} {...props} /> : null}
      <div style={{ ...styles.fieldContainer, bottom: y, left: x, translate: `scale:${props.zoom}` }} onContextMenu={handleRightClick}>
        <div style={!props.selected ? { ...styles.fieldCircle } : { ...styles.fieldCircle, ...styles.selectedBackground }} onClick={() => setShowJSON(!showJSON)}>
          <span style={styles.fieldCircleNumber}>{props.number || 1}</span>
        </div>

        <div ref={fieldRef} key={field.id} style={!props.selected ? { ...styles[variant] } : { ...styles[variant], ...styles.selectedBorder }}>
          {props.selected && field.width && !field.choice && !(field.source && field.source.display === 'checkmark')
            ? (
              <>
                <Vertice
                  top={-8}
                  left={field.width * props.zoom}
                  fill='blue'
                  onMouseDown={() => {
                    handleMoveVertex('TR')
                  }}
                />
                <Vertice
                  top={field.height * props.zoom - 4}
                  left={field.width * props.zoom}
                  fill='blue'
                  onMouseDown={() => {
                    handleMoveVertex('BR')
                  }}
                />
              </>
              )
            : null}

          {field.choice
            ? (
              <span>
                <Tooltip title={field.subtitle.substring(0, 18) + '...'}>
                  <CheckBoxIcon style={{ fontSize: 16 * props.zoom }} />
                </Tooltip>
              </span>
              )
            : field.source && field.source.display === 'checkmark'
              ? (
                <span>
                  <CheckBoxIcon style={{ fontSize: 16 * props.zoom }} />
                </span>
                )
              : (
                <div style={{ ...styles.field, width: field.width ? field.width * props.zoom : 96, height: field.height ? field.height * props.zoom : 24 }}>
                  <span style={{ ...styles.fieldSpan, fontSize: 10 * props.zoom }}>{field.title ? field.title : field.id}</span>
                </div>
                )}
          <div style={!props.selected ? { ...styles.delete } : { ...styles.delete, ...styles.selectedColor }}>
            <span onClick={props.onDelete}>x</span>
          </div>
        </div>
      </div>
      {Permissions.hasRole('super_user') && showJSON
        ? (
          <div style={{ ...styles.jsonContainer, top: props.pdfHeight - y, left: x + 16 + field.width }}>
            <pre style={styles.jsonText}>{JSON.stringify(field, null, 2)}</pre>
          </div>
          )
        : null}
    </>
  )
}
export default Standard

const styles = {
  fieldContainer: {
    position: 'absolute',
    border: '1px dotted rgba(0,0,0,.16)',
    backgroundColor: 'rgba(255,100,100,0)'
  },
  field: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'left',
    paddingTop: 8,
    paddingLeft: 8
  },
  fieldSpan: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  highlight: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 10,
    fontWeight: 'bold',
    border: '2px solid rgba(0,0,0,.16)',
    borderRadius: '4px',
    backgroundColor: 'rgba(255,255,0,.66)',
    userSelect: 'none'
  },
  values: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 10,
    fontWeight: 'bold',
    border: '2px solid rgba(0,0,0,.16)',
    borderRadius: '4px',
    backgroundColor: 'rgba(0,0,0,.08)',
    userSelect: 'none',
    WebkitUserSelect: 'none'
  },
  checkmark: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 10,
    border: '2px solid rgba(0,0,0,.16)',
    borderRadius: '4px',
    backgroundColor: 'rgba(0,0,0,.08)',
    userSelect: 'none'
  },
  zombie: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 10,
    fontWeight: 'bold',
    border: '2px solid rgba(255,0,0,1)',
    borderRadius: '4px',
    backgroundColor: 'rgba(0,0,0,.08)',
    userSelect: 'none'
  },
  selectedBorder: {
    border: '2px solid blue'
  },
  selectedBackground: {
    backgroundColor: 'blue'
  },
  selectedColor: {
    color: 'blue'
  },
  delete: {
    position: 'absolute',
    fontSize: 14,
    bottom: -10,
    left: -4,
    userSelect: 'none'
  },
  navCircle: {
    borderRadius: '100%',
    height: '16px',
    width: '16px',
    backgroundColor: 'black',
    alignItems: 'center',
    verticalAlign: 'center',
    textAlign: 'center'
  },
  fieldCircle: {
    position: 'absolute',
    borderRadius: '100%',
    top: '-8px',
    left: '-8px',
    height: '16px',
    width: '16px',
    backgroundColor: 'black',
    alignItems: 'center',
    verticalAlign: 'center',
    textAlign: 'center'
  },
  fieldCircleNumber: {
    position: 'relative',
    top: '-8px',
    fontSize: 8,
    fontWidth: 'bold',
    fontFamily: 'sans-serif',
    color: 'white'
  },
  jsonContainer: {
    position: 'absolute',
    padding: 12,
    borderRadius: 4,
    border: '1px dotted rgba(255,255,0,1)',
    backgroundColor: 'rgba(0,0,0,1)'
  },
  jsonText: {
    fontSize: 12,
    color: 'yellow'
  }
}
