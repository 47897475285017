import React, { useState, useEffect } from 'react'
import { DialogSettings } from 'components/Reusable/DialogSettings'
import Api from '../../../library/Api'
import { LoadingPleaseWait } from '@supportworks/react-components'
import H from 'library/helper'

const config = {
  tag: 'displaySettings',
  key: 'productEditor',
  title: 'Display Settings',
  list: [
    {
      id: 'showImageryProductImages',
      category: 'Imagery',
      title: 'Product Images',
      type: 'boolean',
      default: false
    },
    {
      id: 'showImageryDrawingProductPlacement',
      category: 'Imagery',
      title: 'Drawing Product Placement',
      type: 'boolean',
      default: false
    },
    {
      id: 'showTieredUnits',
      category: 'Tiered Pricing',
      title: 'Unit Based Pricing',
      type: 'boolean',
      default: false
    },
    {
      id: 'showTieredIncremental',
      category: 'Tiered Pricing',
      title: 'Incremental Pricing',
      type: 'boolean',
      default: false
    },
    {
      id: 'showMarginProductCost',
      category: 'Price Margin',
      title: 'Product Cost',
      type: 'boolean',
      default: false
    },
    {
      id: 'showMarginMarkupPercentage',
      category: 'Price Margin',
      title: 'Markup Percentage',
      type: 'boolean',
      default: false
    },
    {
      id: 'showEplhHoursPerJob',
      category: 'Estimate Project Labor Hours (EPLH)',
      title: 'Fixed Hours per Job',
      type: 'boolean',
      default: false
    },
    {
      id: 'showEplhHoursPerUnit',
      category: 'Estimate Project Labor Hours (EPLH)',
      title: 'Install Hours per Unit',
      type: 'boolean',
      default: false
    },
    {
      id: 'showSalesSPIFF',
      category: 'Sales Commission',
      title: 'Sales SPIFF',
      type: 'boolean',
      default: false
    },
    {
      id: 'showSelectionSummary',
      category: 'Miscellaneous',
      title: 'Show Selection Summary',
      type: 'boolean',
      default: false
    },
    {
      id: 'showAssemblies',
      category: 'Product Assemblies',
      title: 'Show Product Assemblies: Materials, Labor & Markup',
      type: 'boolean',
      default: false
    }
  ]
}

export const DialogSettingsProduct = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [displaySettings, setDisplaySettings] = useState({})
  const [organizationId, setOrganizationId] = useState(null)

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await Api.callRegisteredMethod('getLatestConfigByTag', { tag: config.tag, startsWith: false })
        let newDisplaySettings = {}

        if (response && response.contentJson) {
          newDisplaySettings = JSON.parse(response.contentJson)
        }

        if (!newDisplaySettings[config.key]) {
          newDisplaySettings[config.key] = {}
        }

        config.list.forEach(item => {
          if (newDisplaySettings[config.key][item.id] === undefined) {
            newDisplaySettings[config.key][item.id] = item.default
          }
        })

        setDisplaySettings(newDisplaySettings)
      } catch (error) {
        console.error('Failed to fetch or parse settings:', error)
        const defaultSettings = {}
        defaultSettings[config.key] = {}
        config.list.forEach(item => {
          defaultSettings[config.key][item.id] = item.default
        })
        setDisplaySettings(defaultSettings)
      }

      setOrganizationId(props.organizationId)
      setIsLoading(false)
    }

    if (isLoading) {
      fetchSettings()
    }
  }, []) // eslint-disable-line

  const handleSave = (json) => {
    H.saveConfig(organizationId, config, json)
    props.onClose(json)
  }

  if (isLoading) return <LoadingPleaseWait />

  return <DialogSettings config={config} displaySettings={displaySettings} onClose={props.onClose} onSave={handleSave} fullWidth />
}
